// import { StorageHandlerService } from 'src/app/shared/storage-handler.service';//, private storageService: StorageHandlerService
import { Injectable } from '@angular/core';
import { ProfileDataHandlerService } from './profile-data-handler.service';
// TODO Why can't we do this import pc from 'bit-uibl-data-driver';
const pc = require('bit-uibl-data-driver');
const CLASS_NAME = ":StorageService:"
export const constants = {
  client_id: 'client_id',
  customer_id: 'customer_id',
  application_stage_app: 'Application',
  application_stage_lead: 'Lead',
  application_stage_payment: 'Payment',
  application_stage_policy_denied: 'Policy Denied',
  application_stage_policy_issued: 'Policy Issued',
  application_stage_quote: 'Quote',
  bubble_dob: 'bubble-dob',
  bubble_email: "bubble-email",
  email: 'email',
  bubble_fname: 'bubble-fname',
  firstname: 'firstname',
  comfirmDetailsFirstName: 'comfirmDetailsFirstName',
  bubble_lname: 'bubble-lname',
  comfirmDetailsLastName: 'comfirmDetailsLastName',
  lastname: 'lastname',
  bubble_gender: 'bubble-gender',
  bubble_heightFt: 'bubble-heightFt',
  bubble_heightIn: 'bubble-heightIn',
  bubble_phone: 'bubble-phone',
  bubble_weight: 'bubble-weight',
  home_latest_premium_amount: 'home_latest_premium_amount',
  home_insurance_carrier: 'home_insurance_carrier',
  home_policy_number: 'home_policy_number',
  home_quote_amount: 'home_quote_amount',
  last_updated_at: 'last_updated_at',
  latest_quote_amount: 'latest_quote_amount',
  lead_source: 'lead_source',
  life_latest_premium_amount: 'life_latest_premium_amount',
  life_insurance_carrier: 'life_insurance_carrier',
  life_policy_number: 'life_policy_number',
  life_quote_amount: 'life_quote_amount',
  localStorage: 'localStorage',
  next_term_life: 'Next Term Life',
  placeholder_clientid_email: 'placeholder_clientid_email',
  placeholder_customerid_email: 'placeholder_customerid_email',
  pacLife_contingentBeneficiaries: 'PacLife-contingentBeneficiaries'
};
//   "Lead: When the user fills out a Lead gen form from the Landing Pages or Contact us page
// Quote: When the user enters personal details during the Quote journey or drops off from Home/Life Quote journey. 
// Application*: When the user enters the Application Journey or drops off from Home/Life App journey.
// Payment: When the user completes the Application and redirects to the Payment page or Drops off during Payment flow.
// Policy Issued: After the Payment is completed successfully.
// Policy Denied: If the user gets knocked out."
// "utmSource": "utm_source",
// "utmMedium": "utm_medium",
// "utmCampaign": "utm_campaign",
// "utmTerm": "utm_term",
// "utmContent": "utm_content",
// "age": "age",
// "gender": "gender"
@Injectable({
  providedIn: 'root'
})
export class StorageHandlerService {

  constructor() { }
  clientId: any;
  setItem(key, value, storageName = constants.localStorage) {
    if (storageName === constants.localStorage) {
      localStorage.setItem(key, value);
    }
  }
  public setItemReplace(key, replaceWithKeyItem, value, storageName = constants.localStorage) {
    if (storageName === constants.localStorage) {
      let newKey = key.replace('placeholder', replaceWithKeyItem)
      localStorage.setItem(key, value);
    }
  }
  public getItemReplace(key, replaceWithKeyItem, storageName = constants.localStorage) {
    if (storageName === constants.localStorage) {
      let newKey = key.replace('placeholder', replaceWithKeyItem)
      return localStorage.getItem(key);
    }
  }
  getItem(key, storageName = constants.localStorage) {
    if (storageName === constants.localStorage) {
      return localStorage.getItem(key);
    }
  }
  resetLocalStorage() {
    debugger;
    localStorage.clear();
    localStorage.setItem('accessChecked', '1');
    localStorage.setItem('appDebugFlow', '1');

    pc.getClientId().then(
      clientId => {
        this.clientId = clientId;
        localStorage.setItem("clientID", clientId);
        localStorage.setItem("homecopilotuserid", clientId);

      }
    );


  }
  set2StorageFromQueryParams(params) {
    let addressLocal = params['address'];
    const clientIdLocal = params['clientId'];
    let jsonLocal = {
      'uniqueId': params['uniqueId'],
      'clientId': clientIdLocal,
      'displayAddress': addressLocal,
      'insurancetype': params['insurancetype'],
      'sourcePageLink': params['sourcePageLink']
    };
    const profileDataHandlerService = new ProfileDataHandlerService();
    const cleanedoutputJson = profileDataHandlerService.getSetCityZipStateFromURL(addressLocal);


    let inputJson4Db = {
      'unique_id': params['uniqueId'],
      'client_id': clientIdLocal,
      'clientId': clientIdLocal,
      'insurancetype': params['insurancetype'],
      'sourcePageLink': params['sourcePageLink'],
      'eventStr': 'QuoteFlow Step 0 QueryParams',
      'fullAddress': cleanedoutputJson.displayAddress,
      'stateCode': cleanedoutputJson.insuredState,
      'zip': cleanedoutputJson.insuredZipcode,
      'city': cleanedoutputJson.city
    };
    pc.BackendService.saveUpdateClientData(clientIdLocal, inputJson4Db)
    // recalculateLifeInsuranceAmount


    return jsonLocal;
  }
  getSetEmailFor(inputJson) {
    // Logic: For input <clientid>-email check if any email is associated
    // Check 2: From postgres get the client-email mapping
    // If available set it in localStorage as bubble-email
    //inputs:
    let clientIdLocal = inputJson.clientId;
    let customerIdLocal = inputJson.customerId;


    let email = this.getItemReplace(constants.placeholder_clientid_email, clientIdLocal, constants.localStorage);
    if (!email) email = this.getItemReplace(constants.placeholder_customerid_email, customerIdLocal, constants.localStorage);
    if (email) localStorage.setItem(constants.bubble_email, email);
    return email;
  }
  flushSetLocalStorageFromClientData(clientData) {
    let METHOD_NAME = ":flushSetLocalStorageFromClientData:";
    if (!clientData) {
      console.log('Invalid input: clientData', METHOD_NAME, CLASS_NAME);
      return;
    }
    if (clientData['insuredInfo']) {
      localStorage.setItem('bubble-state', clientData['insuredInfo']['insuredState']);
      localStorage.setItem('bubble-zip', clientData['insuredInfo']['insuredZipcode']);
      localStorage.setItem('bubble-city', clientData['insuredInfo']['insuredCity']);
      console.log("Done with:bubble-state, bubble-zip, bubble-city flush", METHOD_NAME, CLASS_NAME);
    }
    if (clientData['paclifeQuote']) {
      localStorage.setItem('bubble-QouteAmount', clientData['paclifeQuote']['premiumAmount']);
      console.log("Done with:bubble-QouteAmount flush", METHOD_NAME, CLASS_NAME);
    }
  }
}
