import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-quote-flow',
  templateUrl: './quote-flow.component.html',
  styleUrls: ['./quote-flow.component.css']
})
export class QuoteFlowComponent implements OnInit {

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options = '{types: [\'address\'], componentRestrictions: { country: \'USA\'}}';
  scoreCardJSON: any;
  tipsandInsights: any;
  reqheight: string;
  formattedAddress: string;
  displayAddress: any;
  uniqueId: any;
  clientId: any;
  disableAddress = false;
  scoreCard = true;
  gender: any;
  age: any;
  allScores: any;

  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  lifeScoreData: any;
  hazardScoreData: any;
  houseHoldScoreData: any;
  UrbanPopulationScoreData: any;
  mortgageAmount: any;

  latitude = 39.7789467;
  longitude = -105.0477277;
  addresstype = 'Home Address';


  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: false,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    lazyLoad: false,
    margin: 30,
    smartSpeed: 500,
    autoplay: false,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 4
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    }
  };

  ngOnInit(): void {
    debugger;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.gender = params['gender'];
      this.age = params['age'];
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('gender : ', this.gender);
      console.log('age : ', this.age);
      this.pc.getCachedData2();
      const county = 'Autauga County';
      const state = 'AL';
      this.scoreApi.getLifeScoreWithGenderAge(county, state, this.gender.toString(), this.age.toString());
      this.scoreApi.castLifescore.subscribe(
        (data: any) => {
          this.lifeScoreData = data;
          this.lifeScoreData.showScore = true;
          this.allScores = this.lifeScoreData.scores;
          console.log('all scores for score card : ', this.allScores);
        },
        (error) => console.log(error)
      );
    }
    );
    if (window.innerWidth < 600) {
      this.reqheight = 'height:40vh;';
    } else {
      this.reqheight = 'height:100vh;';
    }


  }

  displayque(urldetails) {
    console.log('success');
    console.log('urldetails :', urldetails);
    this.uniqueId = urldetails.uniqueId;
    this.clientId = urldetails.clientId;
    this.displayAddress = urldetails.displayAddress;
    this.disableAddress = true;
    this.scoreCard = false;
  }

}
