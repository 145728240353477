// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  envname: 'local',
  PASSCODESHA2048: '$W%Ld0os$3',
  googleMapApiKey: 'AIzaSyB_DcLM8ITEURJDmtF5T8CQrQBCOqkCc3o',
  googleAnalyticsTrackingCode: 'GTM-MM8XGLV',
  carrierErrorRedirectURL: 'http://stage-www.getmybubble.io/',
  Nest_API_URL: 'https://app.getmybubble.co/api',
  BO_URL: 'https://bo.getmybubble.co/api/app-tracking/',
  envObject: {
    devint: {
      url: 'https://www.getmybubble.co',
      paclifeUrl: 'https://stage.getmybubble.io',
      api2Domain: 'https://api2.getmybubble.io/fetch1',
      nest_app: 'http://localhost:3000',
    },
    uat: {
      url: 'https://uat.getmybubble.co',
      paclifeUrl: 'https://production.getmybubble.io',
      api2Domain: 'https://api2.getmybubble.io/prod/pfetch1',
      nest_app: 'https://uat-app.getmybubble.co/api',
    },
    prod: {
      url: 'https://www.getmybubble.com',
      paclifeUrl: 'https://production.getmybubble.io',
      api2Domain: 'https://api2.getmybubble.io/prod/pfetch1',
      nest_app: 'https://app.getmybubble.com/api',
    },
    app: {
      url: 'https://www.getmybubble.com',
      paclifeUrl: 'https://production.getmybubble.io',
      api2Domain: 'https://api2.getmybubble.io/prod/pfetch1',
      nest_app: 'https://app.getmybubble.co/api',
    },
    stageapp: {
      url: 'https://stage.getmybubble.co',
      paclifeUrl: 'https://stage.getmybubble.io',
      api2Domain: 'https://api2.getmybubble.io/fetch1',
      nest_app: 'https://app.getmybubble.co/api',
    },
    track2: {
      url: 'https://track2.getmybubble.co',
      paclifeUrl: 'https://stage.getmybubble.io',
      api2Domain: 'https://api2.getmybubble.io/fetch1'
    },
    track3: {
      url: 'https://track3.getmybubble.co',
      paclifeUrl: 'https://stage.getmybubble.io',
      api2Domain: 'https://api2.getmybubble.io/fetch1'
    },
    feature: {
      url: 'https://feature.getmybubble.co',
      paclifeUrl: 'https://production.getmybubble.io',
      api2Domain: 'https://api2.getmybubble.io/prod/pfetch1',
      nest_app: 'https://feature-app.getmybubble.co/api',
    },
    feature2: {
      url: 'https://feature2.getmybubble.co',
      paclifeUrl: 'https://production.getmybubble.io',
      api2Domain: 'https://api2.getmybubble.io/prod/pfetch1',
      nest_app: 'https://feature2-app.getmybubble.co/api',
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
