import { Component, OnDestroy, OnInit,EventEmitter,Output,  ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AnyARecord } from 'dns';

@Component({
  selector: 'app-question7',
  templateUrl: './question7.component.html',
  styleUrls: ['./question7.component.css']
})
export class Question7Component implements OnInit {

  @Output() nextquestion7 = new EventEmitter();
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  pc: any;
  coverage: any;
  termLength: string;

  constructor(private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
     this.pc.castpredictedScores.subscribe(
      (data: any) => {
        this.coverage = data.calculatedCoverage;
        if (this.coverage===null || this.coverage===undefined) {
          this.coverage = 0;
         }
         console.log("calculatedCoverage : ", this.coverage);
      },
      (error) => console.log(error)
    );

    if(localStorage.getItem('bubble-coverage') !== null) {
      //this.coverage = localStorage.getItem('bubble-coverage');
      this.termLength = localStorage.getItem('bubble-termLength');
    }
    if (this.coverage==undefined||this.coverage==null||this.coverage=="undefined") {
      //this.coverage = "";
    }
    if (this.termLength==undefined||this.termLength==null||this.termLength=="undefined") {
      this.termLength = "";
    }

    if (localStorage.getItem('bubble-street') !== null && localStorage.getItem('bubble-zip') !== null) {
      this.coverage = '500000';
    }

    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      console.log("uniqueId : ", this.uniqueId);
      console.log("clientId : ", this.clientId);
      console.log("displayAddress : ", this.displayAddress);  

      

      // let inputJson = {
      //   "lastName": "shetty",
      //   "firstName": "sanath",
      //   "middleName": "",
      //   "addressType": "PhysicalRisk",
      //   "address": "4529 winona ct",
      //   "city": "Denver",
      //   "state": "Colorado",
      //   "zipcode": "80212"
      // };
      // this.processCache.getQuickQuotes(this.uniqueId,"stillWater");
      // //this.processCache.invokeStillWater(this.uniqueId, "home");      
    }
    ) 
  }

  next() {
    localStorage.setItem('bubble-coverage',this.coverage);
    localStorage.setItem('bubble-termLength', this.termLength);
    let etty = {
      "questionNo": "7",
      "coverage": this.coverage,
      "termLength": this.termLength,
      "nextprev": 1
    }   
    this.nextquestion7.emit(etty);
  }

  previous() {
    let etty = {
      "questionNo": "7",
      "coverage": this.coverage,
      "termLength": this.termLength,
      "nextprev": 0
    }   
    this.nextquestion7.emit(etty);
  }

}
