import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

@Component({
  selector: 'app-country-born',
  templateUrl: './country-born.component.html',
  styleUrls: ['./country-born.component.css']
})
export class CountryBornComponent implements OnInit {
  disable = true;

  constructor(
    private pc: ProcessCacheService,
    private utilityService:UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,
    private service:ParentChildService) { }
  @Input() questionDetails: [];
  //@Output() countryBornOutput = new EventEmitter();
  @Output() public countryBornOutput =
    new EventEmitter<{ component_id: any, answer: any }>();
  answer_options: any;
  statesAnswer_options: any;
  countryName: any;
  stateName: any;
  USStateVis: boolean;
  searchAddress: any;
  ngOnInit(): void {
    debugger;
    this.service.callComponentMethod();
    // this.searchAddress=localStorage.getItem('searchAddress');
    // if(this.searchAddress!=undefined ){
    //   this.disable = false;
    // }else{
    //   this.disable = true;
    // }


    this.countryName = 'Select Country';
    // this.answer_options = this.questionDetails["answer_options"];
    // this.statesAnswer_options = this.questionDetails["child_question_1"]["answer_options"];
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_4_Birth'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

  }


  public GetCountry(data) {
    debugger;
    // this.countryName=data.target.value;
    this.countryName = data.name;
    if (this.countryName == 'United States') {
      this.USStateVis = true;
    } else {
      this.USStateVis = false;
    }
  }


  public getState(data) {
    this.stateName = data.target.value;
  }
  inputJson: any;
  public onSubmit(data) {
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);
      this.countryBornOutput.emit(this.questionDetails['component_id']);
      this.inputJson = {
        'id': localStorage.getItem('clientID'),
        'countryName': this.countryName,
        'stateName': this.stateName
      };
      this.countryBornOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
      //  this.submitdata(this.inputJson);
    } else {
      alert('Declined!');
    }
    this.$gaService.event('Next', 'event', 'LifeAppApplicantBorn');
    const eventOptions: EventOption = {
      page_title: 'Applicant Born',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppApplicantBorn');


  }
  public submitdata(inputJson) {

    this.pc.saveUpdateClientData(inputJson);
  }


  public goBack() {
    // this.countryBornOutput.emit(this.questionDetails["component_id"]+",Goback");
    this.countryBornOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }


  onChange(even) {
    debugger;
    this.disable = even.enableButton;
  }

}
