import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-generaldatepicker',
  templateUrl: './generaldatepicker.component.html',
  styleUrls: ['./generaldatepicker.component.css']
})
export class GeneraldatepickerComponent implements OnInit {

  constructor() { }
  policyOwnerDob:any;
  ngOnInit(): void {

    this.policyOwnerDob=new Date('01/01/1995');
  }

}
