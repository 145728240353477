import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {PrefillserviceService} from 'src/app/shared/prefillservice.service';

@Component({
  selector: 'app-prefill-to-property-details',
  templateUrl: './prefill-to-property-details.component.html',
  styleUrls: ['./prefill-to-property-details.component.css']
})
export class PrefillToPropertyDetailsComponent implements OnInit {
  loader:boolean;
  constructor(private service:PrefillserviceService,private router:Router) { }

  ngOnInit(): void {
    debugger
    this.loader=true;
    this.service.ngOnInit(this.router.url);
  }

}
