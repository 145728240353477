import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';

@Component({
  selector: 'app-sbli02601100',
  templateUrl: './sbli02601100.component.html',
  styleUrls: ['./sbli02601100.component.css']
})
export class Sbli02601100Component implements OnInit {
  showLoader = true;
  showsecondoption: boolean;
  showthirdoption: boolean;
  NoneoftheaboveSelected: string;
  showcheckboxs: boolean;

  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }
  questions: any;
  checkbox_options: any;
  SelectedCheckBox = [];
  clientId: any;
  existingData: any;
  isDisable: boolean;
  multislectedoption = [];
  @Output() changeQuestion = new EventEmitter();
  ngOnInit(): void {
    debugger;

    this.clientId = localStorage.getItem('clientID');

    this.pc.getAppData(this.clientId).then(data => {
      debugger;
      const dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli02601100')[0];
        this.checkbox_options = this.questions['checkbox_options'];
        for (let i = 0; i < this.checkbox_options.length; i++) {
          this.checkbox_options[i].Selected = false;
        }
      } else {
        this.questions = getQuestionAnswerJson.questions[20];
        this.checkbox_options = getQuestionAnswerJson.questions[20]['checkbox_options'];
        for (let i = 0; i < this.checkbox_options.length; i++) {
          this.checkbox_options[i].Selected = false;
        }
      }




      this.existingData = dataFrom.body.SBLI;



      // for (const point in this.existingData) {
      //   if (point == 'sbli_Noneoftheabove' && this.existingData[point] == 'Yes') {
      //     for (let i = 0; i < this.checkbox_options.length; i++) {
      //       if (this.checkbox_options[i].answer_key == point) {
      //         this.checkbox_options[i].Selected = true;
      //         this.SelectedCheckBox.push(this.checkbox_options[i].answer_key);
      //       }
      //       else {
      //         this.checkbox_options[i].Selected = false;
      //       }
      //     }
      //     //   }
      //   }
      //   else {
      //     if (point == 'Diabi234' && this.existingData[point] == 'Yes') {
      //       for (let i = 0; this.checkbox_options.length; i++) {
      //         for (let k = 0; k < this.checkbox_options[i].sub_questions.length; k++) {
      //           for (let l = 0; l < this.checkbox_options[i].sub_questions[k].radio_options.length; l++) {
      //             for (const item in this.existingData) {
      //               if (item == this.checkbox_options[i].sub_questions[k].radio_options[l].answer_key && this.existingData[item] == 'Yes') {
      //                 this.checkbox_options[i].sub_questions[k].radio_options.Selected = true;
      //               }

      //             }



      //           }
      //         }
      //       }
      //     }
      //     else if (point == 'Major123' || point == 'Rheumatoid234' || point == 'Sleep234' || point == 'Asthma234' && (this.existingData[point] == 'Yes')) {
      //       for (let j = 0; j < this.checkbox_options.length; j++) {
      //         if (this.checkbox_options[j].answer_key == 'Major123' || this.checkbox_options[j].answer_key == 'Rheumatoid234' || this.checkbox_options[j].answer_key == 'Sleep234' || this.checkbox_options[j].answer_key == 'Asthma234') {
      //           for (let k = 0; k < this.checkbox_options[j].radio_options.length; k++) {
      //             for (const test in this.existingData) {
      //               if (test == this.checkbox_options[j].radio_options[k].answer_key) {
      //                 !this.SelectedCheckBox.includes(this.checkbox_options[j].answer_key) && this.SelectedCheckBox.push(this.checkbox_options[j].answer_key);

      //                 this.checkbox_options[j].Selected = true;
      //               }
      //             }
      //           }
      //         }


      //       }
      //     }
      //     else {
      //       for (let i = 0; i < this.checkbox_options.length; i++) {
      //         if (this.checkbox_options[i].answer_key == point && this.existingData[point] == 'Yes') {
      //           this.checkbox_options[i].Selected = true;
      //           !this.SelectedCheckBox.includes(this.checkbox_options[i].answer_key) && this.SelectedCheckBox.push(this.checkbox_options[i].answer_key);
      //         }
      //         else {
      //           if(this.checkbox_options[i].answer_key)
      //           this.checkbox_options[i].Selected = false;
      //         }
      //       }
      //     }
      //   }
      // }
      // for (const point in this.existingData) {
      //   if (point == 'sbli_Noneoftheabove' && this.existingData[point]) {
      //     for (let i = 0; i < this.checkbox_options.length; i++) {
      //       if (this.checkbox_options[i].answer_key == point) {
      //         this.checkbox_options[i].checked = 'Yes';

      //       }
      //       else {
      //         this.checkbox_options[i].checked = 'No';
      //       }
      //     }
      //   }
      //   else {
      //     for (let i = 0; i < this.checkbox_options.length; i++) {
      //       if (point == this.checkbox_options[i].answer_key) {
      //         if (this.existingData[point]) {
      //           this.checkbox_options[i].checked = 'Yes';
      //           if (point == 'Diabi234') {
      //             for (let k = 0; k < this.checkbox_options[i].sub_questions.length; k++) {
      //               for (let l = 0; l < this.checkbox_options[i].sub_questions[k].radio_options.length; l++) {
      //                 if (this.existingData[point] == this.checkbox_options[i].sub_questions[k].radio_options[l].answer_key) {
      //                   if (this.existingData[point] == 'Yes')
      //                     this.checkbox_options[i].checked = 'Yes';
      //                 }


      //               }
      //             }
      //           }
      //           else if (point == 'Major123' || point == 'Rheumatoid234' || point == 'Sleep234' || point == 'Asthma234') {
      //             let selectedradiooptions = this.checkbox_options.filter(x => x.answer_key == point)[0];
      //             for (let j = 0; j < selectedradiooptions.radio_options.length; j++) {
      //               for(const item in this.existingData){
      //                 if(item==selectedradiooptions.radio_options[j].answer_key){
      //                   this.checkbox_options[i].radio_options.answer_value = this.existingData[item];
      //                 }
      //                }
      //             }
      //           }

      //         }
      //         else {
      //           this.checkbox_options[i].checked = 'No';
      //         }

      //       }
      //       else {
      //         if (this.checkbox_options[i].answer_key == 'Diabi234') {
      //           for (let k = 0; k < this.checkbox_options[i].sub_questions.length; k++) {
      //             for (let l = 0; l < this.checkbox_options[i].sub_questions[k].radio_options.length; l++) {
      //               if (this.existingData[point] == this.checkbox_options[i].sub_questions[k].radio_options[l].answer_key) {
      //                 if (this.existingData[point] == 'Yes')
      //                   this.checkbox_options[i].checked = 'Yes';
      //               }


      //             }
      //           }
      //         }
      //         else if (this.checkbox_options[i].answer_key == 'Major123' || this.checkbox_options[i].answer_key == 'Rheumatoid234' || this.checkbox_options[i].answer_key == 'Sleep234' || this.checkbox_options[i].answer_key == 'Asthma234') {
      //           for (let j = 0; j < this.checkbox_options[i].radio_options.length; j++) {
      //             if (this.existingData[point] == this.checkbox_options[i].radio_options[j].answer_key) {
      //               if (this.existingData[point] == 'Yes')
      //                 this.checkbox_options[i].checked = 'Yes';
      //             }
      //             // else {
      //             //   this.checkbox_options[i].checked = 'No';
      //             // }
      //           }
      //         }

      //       }

      //     }
      //   }
      // }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    this.isDisable = true;
  }

  public CheckSecond(data, index, radiooptions) {
    debugger;
    if (data.answer_text == 'Yes') {
      this.showcheckboxs = true;
      data.answer_selected = 'Yes';
      radiooptions[index + 1].answer_selected = 'Yes';
      !this.SelectedCheckBox.includes(data.answer_key) && this.SelectedCheckBox.push(data.answer_key);
      if (this.SelectedCheckBox.includes('sbli_depression')) {
        this.SelectedCheckBox = []
      }
    }
    else {
      this.showcheckboxs = false;
      data.answer_selected = 'No';
      radiooptions[index - 1].answer_selected = 'No';
      !this.SelectedCheckBox.includes(data.answer_key) && this.SelectedCheckBox.push(data.answer_key);
    }


  }
  public Checkmajor(data, selectedoption, all) {
    debugger;
    this.SelectedCheckBox;
    if (selectedoption == 'Yes') {
      data.Selected = true;
      data.answer_selected = 'Yes';
      !this.multislectedoption.includes(data.answer_key) && this.multislectedoption.push(data.answer_key);
      for (let u = 0; u < all.length; u++) {
        if (data.answer_key == 'sbli_none_of_above') {
          if (all[u].answer_key !== 'sbli_none_of_above') {
            all[u].Selected = false;
          }
          else {
            all[u].Selected = true;
            this.multislectedoption = [];
            this.multislectedoption.push(data.answer_key);
          }

        }
      }
    }
    else {
      data.Selected = false;
      data.answer_selected = 'No';
      this.multislectedoption.includes(data.answer_key) && this.multislectedoption.splice(this.multislectedoption.indexOf(data.answer_key), 1);
    }
    if (this.multislectedoption.length > 0) {
      !this.SelectedCheckBox.includes(data.answer_key) && this.SelectedCheckBox.push(data.answer_key);
    }
    else {
      this.SelectedCheckBox = []
    }
  }
  public CheckFirst(showoption, selectoption, i) {
    debugger;
    const id = Number(i) + 1;
    if (selectoption.answer_text == 'Yes') {
      showoption[i].radio_options[0].answer_value = 'Yes';
      showoption[i].radio_options[1].answer_value = 'Yes';
      selectoption.answer_value = 'Yes';
      if (id == showoption.length - 2) {
        showoption[i + 1].nextshow = false;
        showoption[i + 2].nextshow = false;
        showoption[i + 1].idtobind = 'radio' + id;
      }
      if (id == 1) {
        !this.SelectedCheckBox.includes(selectoption.answer_key) && this.SelectedCheckBox.push(selectoption.answer_key);
      }
      else if (id == 2) {
        const unselecteditem = this.SelectedCheckBox.indexOf(showoption[2].radio_options[0].answer_key);
        if (unselecteditem > -1) {
          !this.SelectedCheckBox.includes(selectoption.answer_key) && this.SelectedCheckBox.push(selectoption.answer_key);
        }
        else {
          this.SelectedCheckBox = [];
        }

      }
      else {
        !this.SelectedCheckBox.includes(selectoption.answer_key) && this.SelectedCheckBox.push(selectoption.answer_key);
      }
    }
    else {
      showoption[i].radio_options[0].answer_value = 'No';
      showoption[i].radio_options[1].answer_value = 'No';
      if (id == 1) {
        const unselecteditem = this.SelectedCheckBox.indexOf(selectoption.answer_key);
        if (unselecteditem > -1) {
          this.SelectedCheckBox.splice(unselecteditem, 1);
        }
      }
      else if (id == 2) {
        const unselecteditem = this.SelectedCheckBox.indexOf(showoption[2].radio_options[0].answer_key);
        if (unselecteditem > -1) {
          !this.SelectedCheckBox.includes(selectoption.answer_key) && this.SelectedCheckBox.push(selectoption.answer_key);
        }
        else {
          this.SelectedCheckBox = [];
        }
      }
      else {
        !this.SelectedCheckBox.includes(selectoption.answer_key) && this.SelectedCheckBox.push(selectoption.answer_key);
      }
      if (id == showoption.length - 2) {
        showoption[i + 1].nextshow = true;
        showoption[i + 2].nextshow = true;
      }



    }
  }



  SurgeryCollapse: boolean;

  CheckYesOrNoForSurgeryData: any;
  public CheckYesOrNoForSurgery(data, selectedoption) {
    debugger;
    if (selectedoption == 'Yes') {
      data.Selected = true;
      if (data.answer_key == 'sbli_Noneoftheabove') {
        for (let i = 0; i < this.checkbox_options.length; i++) {
          if (this.checkbox_options[i].answer_key == data.answer_key) {
            this.checkbox_options[i].Selected = true;

          }
          else {
            this.checkbox_options[i].Selected = false;
          }
        }
      }
      else {
        for (let j = 0; j < this.checkbox_options.length; j++) {
          if (this.checkbox_options[j].answer_key == 'sbli_Noneoftheabove') {
            this.checkbox_options[j].Selected = false;
          }
        }
      }

      if (data.answer_key != 'Major123' && data.answer_key != 'Rheumatoid234' && data.answer_key != 'Sleep234' && data.answer_key != 'Asthma234' && data.answer_key != 'Diabi234') {
        !this.SelectedCheckBox.includes(data.answer_key) && this.SelectedCheckBox.push(data.answer_key);
      }

    } else {
      data.Selected = false;
      this.NoneoftheaboveSelected = 'No';
      this.CheckYesOrNoForSurgeryData = '';
      this.SurgeryCollapse = false;
      const unselecteditem = this.SelectedCheckBox.indexOf(data.answer_key);
      if (unselecteditem > -1) {
        this.SelectedCheckBox.splice(unselecteditem, 1);
      }


      if (data.answer_key == 'Major123' || data.answer_key == 'Rheumatoid234' || data.answer_key == 'Sleep234' || data.answer_key == 'Asthma234') {
        for (let i = 0; i < data.radio_options.length; i++) {
          const unselecteditem = this.SelectedCheckBox.indexOf(data.radio_options[i].answer_key);
          if (unselecteditem > -1) {
            this.SelectedCheckBox.splice(unselecteditem, 1);
          }
        }
      }
      else if (data.answer_key == 'Diabi234') {
        for (let k = 0; k < data.sub_questions.length; k++) {
          for (let l = 0; l < data.sub_questions[k].radio_options.length; l++) {
            const unselecteditem = this.SelectedCheckBox.indexOf(data.sub_questions[k].radio_options[l].answer_key);
            if (unselecteditem > -1) {
              this.SelectedCheckBox.splice(unselecteditem, 1);
            }
          }
        }
      }

      for (let i = 0; i < this.checkbox_options.length; i++) {
        if (this.checkbox_options[i].answer_key == 'sbli_Noneoftheabove') {
          this.checkbox_options[i].Selected = false;
          this.SelectedCheckBox = [];

        }
        else {
          if (this.checkbox_options[i].answer_key == data.answer_key) {
            // this.checkbox_options[i].Selected = true;
          }

        }
      }
    }
    if (data.sub_questions) {
      data.sub_questions[0].nextshow = true;
      data.sub_questions[0].idtobind = 'radio0';
    }

  }



  next() {
    debugger
    this.$gaService.event('SBLI LifeApplication Diabetes', 'event', 'SBLI-LifeApplication-Diabetes');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Surgery',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Surgery', 'event', 'SBLI-LifeApplication-Surgery');
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    // for (let m = 0; m < this.checkbox_options.length; m++) {
    //   if (this.checkbox_options[m].answer_key == 'sbli_Noneoftheabove') {
    //     for (let z = 0; z < this.checkbox_options.length; z++) {
    //       if (this.checkbox_options[z].answer_key == 'sbli_Noneoftheabove' && this.checkbox_options[z].Selected) {
    //         json.SBLI[this.checkbox_options[z].answer_key] = 'Yes';
    //       }
    //     }
    //   }
    // }
    for (let i = 0; i < this.checkbox_options.length; i++) {
      if (this.checkbox_options[i].Selected) {
        if (this.checkbox_options[i].answer_key == 'Diabi234') {
          for (let k = 0; k < this.checkbox_options[i].sub_questions.length; k++) {
            for (let l = 0; l < this.checkbox_options[i].sub_questions[k].radio_options.length; l++) {
              json.SBLI[this.checkbox_options[i].sub_questions[k].radio_options[l].answer_key] = this.checkbox_options[i].sub_questions[k].radio_options[l].answer_value;
            }
          }
        }
        else if (this.checkbox_options[i].answer_key == 'Major123' || this.checkbox_options[i].answer_key == 'Rheumatoid234' || this.checkbox_options[i].answer_key == 'Sleep234' || this.checkbox_options[i].answer_key == 'Asthma234') {
          for (let j = 0; j < this.checkbox_options[i].radio_options.length; j++) {
            if (this.checkbox_options[i].Selected) {
              // json.SBLI[this.checkbox_options[i].answer_key] = 'Yes';
              json.SBLI[this.checkbox_options[i].radio_options[j].answer_key] = this.checkbox_options[i].radio_options[j].answer_selected;
              if (this.checkbox_options[i].answer_key == 'Major123') {
                for (let b = 0; b < this.checkbox_options[i].checkbox_option.length; b++) {
                  json.SBLI[this.checkbox_options[i].checkbox_option[b].answer_key] = this.checkbox_options[i].checkbox_option[b].answer_selected;
                }
              }
            }
          }
        }
        else {
          for (let m = 0; m < this.checkbox_options.length; m++) {
            if (this.checkbox_options[m].Selected && (this.checkbox_options[i].answer_key != 'Major123' || this.checkbox_options[i].answer_key != 'Diabi234' || this.checkbox_options[i].answer_key != 'Rheumatoid234' || this.checkbox_options[i].answer_key != 'Sleep234' || this.checkbox_options[i].answer_key != 'Asthma234')) {
              json.SBLI[this.checkbox_options[m].answer_key] = 'Yes';
            }

          }
        }
      }
    }

    for (const propName in json.SBLI) {
      if (json.SBLI[propName] === null || json.SBLI[propName] === undefined || json.SBLI[propName] === '') {
        delete json.SBLI[propName];
      }
    }

    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);

  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

}
