import { Component, OnInit } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { response } from 'express';
import { environment } from './../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from './../../components/lifescore-error-alert/lifescore-error-alert.component';
import { TrackerService } from 'src/app/shared/tracker.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-select-home-carrier',
  templateUrl: './select-home-carrier.component.html',
  styleUrls: ['./select-home-carrier.component.css']
})
export class SelectHomeCarrierComponent implements OnInit {

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  gender: any;
  age: any;
  lifeScoreData: any;
  allScores: any;
  insurancetype: any;
  deductible: any;
  replacementCost: any;
  swquote: any[];
  sw_essentials: any;
  sw_bestvalue: any;
  sw_expanded: any;
  loader: boolean;
  selectedQuote: any;
  inputAddress: any;
  ci_address: string;
  ci_city: string;
  ci_state: string;
  ci_zip: string;
  selectedCard: number;
  imgwid: string;
  carrierName: string;
  carrierAmount: string;
  disableDiscount: number;
  previous_economy: any;
  previous_best: any;
  previous_expanded: any;
  showModal = false;
  modalImg: any;
  rightcardselected: number;
  modalLabel: any;
  modalText: any;
  quoteLength: any;
  errorMessage: any;
  selectedCarrierHead: string;
  disableBackButton: boolean;


  constructor(
    private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private $gaService: GoogleAnalyticsService,
    private modalService: NgbModal,
    private tracker: TrackerService,
    private activatedroute: ActivatedRoute,
    private utilityService: UtilityFunctionsService) { }

  ngOnInit(): void {
    debugger;
    this.loader = true;

    ///////////////// GA pageview Implementation starts
    let path, page_title;
    path = 'SelectHomeCarrier';
    page_title = 'SelectHomeCarrier';
    const subdomainDomain = this.utilityService.getSubdomainDomain(window.location.href);
    //this.utilityService.setUTMInPageview(path, page_title, subdomainDomain);
    console.log('Done with this.utilityService.setUTMInPageview');
    ///////////////// GA pageview Implementation Ends

    this.activatedroute.params.subscribe(params => {
      debugger
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      localStorage.setItem('insuranceType', this.insurancetype);
      this.inputAddress = this.uniqueId.split('-');
      this.inputAddress.shift();
      this.inputAddress = this.inputAddress.join(' ');
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
    }
    );
    console.log('envCred : ', this.pc.envCred);
    this.deductible = '$2,000';
    this.replacementCost = ' $393,750';

    this.selectedCard = 0;
    this.carrierName = '';
    this.carrierAmount = '';
    this.imgwid = '60px';

    this.disableDiscount = 0;

    this.pc.parseAddressLocalAndPatch(this.inputAddress, this.clientId).then(
      data => {
        this.ci_address = data.door_number + ' ' + data.street;// + " " + data.street_type;
        this.ci_city = data.city_name;
        this.ci_state = data.state_code;
        this.ci_zip = data.zip;

        let maxReplacementCost = 0;
        if (this.ci_state == 'CA') {
          maxReplacementCost = 10;
        }
        else {
          maxReplacementCost = 10;
        }

        this.replacementCost = localStorage.getItem('dwellingResult');
        this.replacementCost = this.replacementCost == 'No Data' || this.replacementCost == null ? maxReplacementCost : this.replacementCost;

        this.pc.getAppData(this.clientId).then(data => {
          const dataFrom = JSON.parse(data.Payload);
          this.inputAddress = dataFrom.body.formatted_address == undefined ? this.inputAddress : dataFrom.body.formatted_address;
          console.log('getAppData : ', dataFrom);
          if (localStorage.getItem('commonInput') === null) {
            localStorage.setItem('commonInput', JSON.stringify(dataFrom.body.commonInput));
            localStorage.setItem('propertyDetails', JSON.stringify(dataFrom.body.stillwater.propertyDetails));
          }
          const commonInput = JSON.parse(localStorage.getItem('commonInput'));
          console.log('Resume CommonInput 1', JSON.parse(localStorage.getItem('commonInput')));
          if (!commonInput['email']) {
            console.log('Resume CommonInput no email', JSON.parse(localStorage.getItem('commonInput')));
            commonInput['email'] = dataFrom.body.bubble_email;
            commonInput['phone'] = dataFrom.body.bubble_phone;
            localStorage.setItem('commonInput', JSON.stringify(commonInput));
            if (localStorage.getItem('propertyDetails') === null) {
              localStorage.setItem('propertyDetails', JSON.stringify(dataFrom.body.stillwater.propertyDetails));
            }
          }
          console.log('Resume CommonInput 2', JSON.parse(localStorage.getItem('commonInput')));
          this.getSwQuote();
        }).catch(error => {
          console.log(error);
        });

      }
    ).catch(error => console.log('parseAddressLocal error', error));
    // this.selectcarrier('0');
    this.tracker.changeProgressStatus({ totalPages: 4, currentPage: 2 });
  }

  getSwQuote() {
    this.loader = true;
    this.disableBackButton = true;
    const quickquote = new pc.Quotes(this.uniqueId);
    // let commonInput = {
    //   address: this.ci_address,
    //   addressType: "PhysicalRisk",
    //   city: this.ci_city,
    //   coverage: this.replacementCost,
    //   dob: "01/01/2000",
    //   firstName: "Anonymous",
    //   lastName: "Lastname",
    //   middleName: "User",
    //   premiumFrequency: "m",
    //   state: this.ci_state,
    //   zip: this.ci_zip,
    //   replacementCost: this.replacementCost,
    //   yearbuilt: localStorage.getItem("sw_yearBuilt"),
    //   sw_propertyDetails:JSON.parse(localStorage.getItem("propertyDetails")),
    //   sw_discounts : [],//[4965,61, 4200, 4255, 4305, 9099],
    //   sw_appQuestions: [], //
    //   ca_builder: null,
    //   propertyDetails:''
    // }
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['firstName'] = localStorage.getItem('bubble-fname');
    commonInput['lastName'] = localStorage.getItem('bubble-lname');
    if (commonInput['propertyDetails'] == undefined || commonInput['propertyDetails'] == null || commonInput['propertyDetails'] == '') {
      commonInput['propertyDetails'] = JSON.parse(localStorage.getItem('propertyDetails'));
    }
    this.disableBackButton = commonInput['disableBack'];
    console.log('disableBackButton:', this.disableBackButton);
    commonInput['coverage'] = '10';
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    console.log('getStillWaterQuote commonInput:', commonInput);
    quickquote.getStillWaterQuote(commonInput)
      .then(response => {
        debugger;
        console.log('output SWquote: ', response);
        if (response[0].hasError && response[0].hasError == true) {
          this.loader = false;
          // this.errorMessage = response[0].errorMessage;
          const msgwc = response[0].errorMessage.split(':');
          this.errorMessage = msgwc[1];
          this.openModal();
        }
        this.swquote = response;
        this.quoteLength = this.swquote.length;
        if (this.quoteLength == 1) {
          this.quoteLength = 'one';
        }
        else if (this.quoteLength == 2) {
          this.quoteLength = 'two';
        }
        else {
          this.quoteLength = 'three';
        }
        this.sw_essentials = response.filter(item => item.carrierId == 'economy')[0];
        this.sw_bestvalue = response.filter(item => item.carrierId == 'best')[0];

        if (this.swquote.length == 3) {
          this.sw_expanded = response.filter(item => item.carrierId == 'expanded')[0];
        }
        else {
          this.previous_expanded = 0;
        }
        console.log('sw_essentials', this.sw_essentials);
        console.log('sw_bestvalue', this.sw_bestvalue);
        this.selectedQuote = this.sw_bestvalue;
        //this.selectedQuote = "";
        this.selectedCarrierHead = '';
        if (localStorage.getItem('applyDiscount') == '0' || localStorage.getItem('applyDiscount') == null || localStorage.getItem('applyDiscount') == undefined || localStorage.getItem('applyDiscount') == '') {
          localStorage.setItem('applyDiscount', '0');
          this.disableDiscount = 0;
          //debugger
          this.previous_economy = this.swquote[0].head.quote_amount;
          this.previous_best = this.swquote[1].head.quote_amount;

          localStorage.setItem('previous_economy', this.swquote[0].head.quote_amount);
          localStorage.setItem('previous_best', this.swquote[1].head.quote_amount);

          if (this.swquote.length == 3) {
            this.previous_expanded = this.swquote[2].head.quote_amount;
            localStorage.setItem('previous_expanded', this.previous_expanded);
          }
        }
        else {
          this.previous_economy = localStorage.getItem('previous_economy');
          this.previous_best = localStorage.getItem('previous_best');
          this.swquote[0]['previous_value'] = this.previous_economy;
          this.swquote[1]['previous_value'] = this.previous_best;
          if (this.swquote.length == 3) {
            this.previous_expanded = localStorage.getItem('previous_expanded');
            this.swquote[2]['previous_value'] = this.previous_expanded;
          }
          localStorage.setItem('applyDiscount', '1');
          this.disableDiscount = 1;
        }

        this.carrierAmount = '$' + this.selectedQuote.head.quote_amount;
        let url;
        if (window && window.location && window.location.href) {
          url = window.location.href;
        }
        const dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
        const carrierLocal = this.selectedQuote.head.home_insurance_carrier;
        debugger;

        const hubSpotData = {
          customer_id: localStorage.getItem('customer_id'),
          client_id: localStorage.getItem('clientID'),
          email: localStorage.getItem('bubble-email'),
          // application_stage: "Quote",
          home_insurance_carrier: carrierLocal,
          home_quote_amount: this.carrierAmount,
          home_latest_premium_amount: this.carrierAmount,
          dropoff_url: dropoffURL
        };
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

        this.loader = false;
        this.rightcardselected = 1;
      })
      .catch(error => {
        debugger;
        console.log('SW Quote Error:', error);
        this.loader = false;
        this.errorMessage = 'Our Servers are taking too long to respond please come back later.';
        this.openModal();
      });
  }

  assignModalContent(img, details) {
    this.modalImg = img;
    this.modalLabel = details.title;
    this.modalText = details.text;
    this.modalText = this.modalText.replace(/\n/g, '<br/>');
  }

  assignModalContent1(details) {
    this.modalLabel = details.title;
    this.modalText = details.text;
    this.modalText = this.modalText.replace(/\n/g, '<br/>');
  }

  selectcarrier(eve, sw) {
    this.selectedQuote = sw;
    this.selectedCarrierHead = this.selectedQuote.head.carrier_name;
    this.carrierName = this.selectedQuote.head.carrier_name;
    this.carrierAmount = '$' + this.selectedQuote.head.quote_amount;
    this.selectedCard = 1;
    this.rightcardselected = 1;
    let url;
    if (window && window.location && window.location.href) {
      url = window.location.href;
    }
    const dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
    let carrierLocal = this.selectedQuote.head.home_insurance_carrier;
    debugger;
    if (!carrierLocal && this.selectedQuote &&
      this.selectedQuote.planDetails && this.selectedQuote.planDetails.text &&
      this.selectedQuote.planDetails.text.includes('Stillwater')) carrierLocal = 'Stillwater';

    const hubSpotData = {
      customer_id: localStorage.getItem('customer_id'),
      client_id: localStorage.getItem('clientID'),
      email: localStorage.getItem('bubble-email'),
       application_stage: 'Quote',
      home_insurance_carrier: carrierLocal,
      home_quote_amount: this.carrierAmount,
      home_latest_premium_amount: this.carrierAmount,
      dropoff_url: dropoffURL
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

  }


  bluronhow() {
    document.getElementById('we_are_sorry').style.display = 'block';
    //document.getElementById('headmakeblur').style.filter = "blur(10px)";
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
  }

  bluroffhow() {
    document.getElementById('we_are_sorry').style.display = 'none';
    //document.getElementById('headmakeblur').style.filter = "none";
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }


  modaltwoopen() {
    //document.getElementById('headmakeblur').style.filter = "blur(10px)";
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
    document.getElementById('selected_plan_details').style.display = 'block';
    document.getElementById('mod_body').style.height = '85vh';
    document.getElementById('mod_body').style.overflowY = 'auto';
    document.getElementById('mod_body').style.position = 'unset';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }


  modaltwoclose() {
    //document.getElementById('headmakeblur').style.filter = "none";
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';
    document.getElementById('selected_plan_details').style.display = 'none';
    document.getElementById('mod_body').style.overflowY = 'hidden';
    document.getElementById('mod_body').style.position = 'fixed';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }

  openDiscountModal() {
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
    document.getElementById('discount_modal').style.display = 'block';
    document.getElementById('dis_mod_body').style.height = '90vh';
    document.getElementById('dis_mod_body').style.overflowY = 'auto';
    document.getElementById('dis_mod_body').style.position = 'unset';
  }



  //tobe triggered from child

  triggerParentFunc(even) {
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'unset';
    document.getElementById('discount_modal').style.display = 'none';
    document.getElementById('dis_mod_body').style.overflowY = 'hidden';
    document.getElementById('dis_mod_body').style.position = 'fixed';
    this.loader = true;
    if (even.type == 1) {

      // let commonInput = {
      //   address: this.ci_address,
      //   addressType: "PhysicalRisk",
      //   city: this.ci_city,
      //   coverage: this.replacementCost,
      //   dob: "01/01/2000",
      //   firstName: "Anonymous",
      //   lastName: "Lastname",
      //   middleName: "User",
      //   premiumFrequency: "m",
      //   state: this.ci_state,
      //   zip: this.ci_zip,
      //   replacementCost: this.replacementCost,
      //   yearbuilt: localStorage.getItem("sw_yearBuilt"),
      //   sw_propertyDetails:JSON.parse(localStorage.getItem("propertyDetails")),
      //   sw_discounts : JSON.parse(even.selectedDiscounts),//[4965,61, 4200, 4255, 4305, 9099],
      //   sw_appQuestions: [], //
      //   ca_builder: even.builderId
      // }
      debugger;
      const dicountsSelected = JSON.parse(even.selectedDiscounts);
      if (dicountsSelected.length == 0 && this.disableDiscount == 1) {
        const quickquote = new pc.Quotes(this.uniqueId);
        const commonInput = JSON.parse(localStorage.getItem('commonInput'));
        commonInput['sw_discounts'] = JSON.parse(even.selectedDiscounts);
        commonInput['ca_builder'] = even.builderId;
        localStorage.setItem('commonInput', JSON.stringify(commonInput));
        console.log('getStillWaterQuote discount commonInput:', commonInput);
        console.log('discount SWquote input: ', commonInput);
        quickquote.getStillWaterQuote(commonInput)
          .then(response => {
            debugger;
            console.log('output SWquote: ', response);
            this.quoteLength = this.swquote.length;
            if (this.quoteLength == 1) {
              this.quoteLength = 'one';
            }
            else if (this.quoteLength == 2) {
              this.quoteLength = 'two';
            }
            else {
              this.quoteLength = 'three';
            }
            this.swquote = response;
            debugger;
            if (this.selectedQuote != '') {
              this.selectedQuote = response.filter(item => item.carrierId == this.selectedQuote.carrierId)[0];
              this.selectedCarrierHead = this.selectedQuote.head.carrier_name;
              this.rightcardselected = 1;
            }
            else {
              this.rightcardselected = 0;
            }


            this.previous_economy = localStorage.getItem('previous_economy');
            this.previous_best = localStorage.getItem('previous_best');
            this.swquote[0]['previous_value'] = this.previous_economy;
            this.swquote[1]['previous_value'] = this.previous_best;
            if (this.swquote.length == 3) {
              this.previous_expanded = localStorage.getItem('previous_expanded');
              this.swquote[2]['previous_value'] = this.previous_expanded;
            }
            localStorage.setItem('applyDiscount', '0');

            this.loader = false;
            this.disableDiscount = 0;
            //this.saveDiscount();
          })
          .catch(error => { console.log('SW Quote Error:', error); });
      }
      else if (dicountsSelected.length != 0) {
        const quickquote = new pc.Quotes(this.uniqueId);
        const commonInput = JSON.parse(localStorage.getItem('commonInput'));
        commonInput['sw_discounts'] = JSON.parse(even.selectedDiscounts);
        commonInput['ca_builder'] = even.builderId;
        localStorage.setItem('commonInput', JSON.stringify(commonInput));
        console.log('getStillWaterQuote discount commonInput:', commonInput);
        console.log('discount SWquote input: ', commonInput);
        quickquote.getStillWaterQuote(commonInput)
          .then(response => {
            debugger;
            console.log('output SWquote: ', response);
            this.quoteLength = this.swquote.length;
            if (this.quoteLength == 1) {
              this.quoteLength = 'one';
            }
            else if (this.quoteLength == 2) {
              this.quoteLength = 'two';
            }
            else {
              this.quoteLength = 'three';
            }
            this.swquote = response;
            debugger;
            if (this.selectedQuote != '') {
              this.selectedQuote = response.filter(item => item.carrierId == this.selectedQuote.carrierId)[0];
              this.selectedCarrierHead = this.selectedQuote.head.carrier_name;
              this.rightcardselected = 1;
            }
            else {
              this.rightcardselected = 0;
            }

            this.previous_economy = localStorage.getItem('previous_economy');
            this.previous_best = localStorage.getItem('previous_best');
            this.swquote[0]['previous_value'] = this.previous_economy;
            this.swquote[1]['previous_value'] = this.previous_best;
            if (this.swquote.length == 3) {
              this.previous_expanded = localStorage.getItem('previous_expanded');
              this.swquote[2]['previous_value'] = this.previous_expanded;
            }

            localStorage.setItem('applyDiscount', '1');
            this.loader = false;
            this.disableDiscount = 1;
            //this.saveDiscount();
          })
          .catch(error => { console.log('SW Quote Error:', error); });
      }
      else {
        this.closeDiscountModal();
      }


    }
    else {
      this.closeDiscountModal();
    }
  }

  closeDiscountModal() {
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'unset';
    document.getElementById('discount_modal').style.display = 'none';
    document.getElementById('dis_mod_body').style.overflowY = 'hidden';
    document.getElementById('dis_mod_body').style.position = 'fixed';
    this.loader = false;
  }
  saveDiscount() {
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
    document.getElementById('discount_modal').style.display = 'none';
    document.getElementById('dis_mod_body').style.overflowY = 'hidden';
    document.getElementById('dis_mod_body').style.position = 'fixed';
    document.getElementById('congrats_discount').style.display = 'block';
  }



  closeCongratesModal() {
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';
    document.getElementById('congrats_discount').style.display = 'none';

  }

  openModal() {
    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: this.errorMessage
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
    document.getElementById('errorModal').style.display = 'block';
    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //     // keyboard: false,
    //     // backdrop: 'static'
    //   });

    // let data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   //message: "Our Servers are taking too long to respond please come back later.",
    //   message: this.errorMessage,
    //   image: 'assets/images/images/education2.png'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   //redirect url
    //   window.location.href = environment.carrierErrorRedirectURL;
    //   console.log("Modal Close Output: ", result);
    // }, (reason) => {
    //   console.log("Modal Close Reason: ", reason);
    // });
  }

  closeModal() {
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';
    document.getElementById('errorModal').style.display = 'none';
    //window.location.href = environment.carrierErrorRedirectURL;
    window.location.href = this.pc.envCred.url;
  }


  selectPlan() {
    const eventOptionsPart1 = {
      'path': 'selectHomeCarrierPlan',
      'page_title': 'selectHomeCarrierPlan', 'url': window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', this.insurancetype, {
      'event_category': 'ButtonClicked', 'event_label': 'HomePlanSelect'
    });
    this.$gaService.event('Select Plan', 'event', 'HomePlanSelect');
    console.log('Done with this.utilityService.gtagWrapped');
    ///////////////// GA pageview Implementation Ends

    this.loader = true;
    //quote id to be retrieved from respone of selected quote
    // const quoteId = this.processCache.getQuoteId(this.clientId);
    const quoteId = this.selectedQuote.quoteId;
    console.log('homeQuoteId : ', quoteId);
    localStorage.setItem('quoteId', quoteId);
    const details = JSON.stringify(this.selectedQuote);
    localStorage.setItem('selectedHomeCarrier', details);
    let dwelleing = this.selectedQuote.body.other_amounts.dwelling.text;
    dwelleing = dwelleing.replace('$', '').replace(/,/g, '');
    console.log('dwelleing :', dwelleing);

    let replacementCost = this.selectedQuote.replacementCost;
    replacementCost = replacementCost.replace('$', '').replace(/,/g, '');
    console.log('replacementCost :', replacementCost);
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['replacementCost'] = replacementCost;
    commonInput['coverage'] = dwelleing;
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    localStorage.setItem('minDwellingCost', dwelleing);
    debugger;
    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.swSaveSelectedPlanType(this.selectedQuote.carrierId).then(data => {
      const clientIdLocal = localStorage.getItem('clientID');
      const pacificLifeQuoteId = this.pc.getQuoteId('');// pass emptystring to get -wer324 kind string
      /////// id mapping
      const idMappingJson = {
        client_id: clientIdLocal,
        customer_id: localStorage.getItem('customer_id'),
        email_id: localStorage.getItem('bubble-email'),
        paclife_quote_id: pacificLifeQuoteId,
        status: 'LifeQuote',
        dob: localStorage.getItem('bubble-dob'),
      };
      localStorage.setItem('paclife_quote_id', pacificLifeQuoteId);

      this.pc.createIdMapping(idMappingJson);
      // const Helper = require('bit-uibl-data-driver');
      // Helper.Lead.createIdMapping(idMappingJson).then((data) => {
      //   if (data) { console.log("Lead:createIdMapping Successful:data: ", data) }
      // });
      /////// id mapping ends
      let carrierLocal = this.selectedQuote.head.home_insurance_carrier;
      debugger;
      if (!carrierLocal && this.selectedQuote &&
        this.selectedQuote.planDetails && this.selectedQuote.planDetails.text &&
        this.selectedQuote.planDetails.text.includes('Stillwater')) carrierLocal = 'Stillwater';
      const hubSpotData = {
        customer_id: localStorage.getItem('customer_id'),
        email: localStorage.getItem('bubble-email'),
        application_stage: 'Quote',
        home_insurance_carrier: carrierLocal,
        home_quote_amount: this.selectedQuote.head.quote_amount,
        home_latest_premium_amount: this.selectedQuote.head.quote_amount
      };
      try {
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      } catch (error) {
        console.error('hubSpotSyncAfterEmailFetch:hubSpotData: ', hubSpotData, ' # error:', error);
      }
      if (this.insurancetype == 'homeLife') {

        location.href = 'QuoteFlowV2Life/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
      }
      if (this.insurancetype == 'home') {
        location.href = `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      }
      debugger;
    }).catch(error => {
      console.log(error);
    });


  }

  previousscreen() {
    const eventOptionsPart1 = {
      'path': 'selectHomeCarrierPlan',
      'page_title': 'selectHomeCarrierPlan', 'url': window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', this.insurancetype, {
      'event_category': 'BackButton', 'event_label': 'Back'
    });
    location.href = `QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/previous`;
    //this.router.navigate(['/QuoteFlowV2', this.uniqueId, this.clientId,this.displayAddress,'life','previous',1]);
  }

}
