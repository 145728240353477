import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-wnspayment',
  templateUrl: './wnspayment.component.html',
  styleUrls: ['./wnspayment.component.css']
})
export class WnspaymentComponent implements OnInit {
  showLoader = true;
  isDisable: boolean;
  questions: any;
  paymentType: any;
  premium: any;
  sameBillingAddress: any;
  // lastName: any;
  // firstName: any;
  address: any;
  expirationDate: any;
  cvv: any;
  @Output() changeQuestion = new EventEmitter();
  clientId: string;
  existingData: any;
  Show: boolean;
  bankRoutingNo: any;
  bankAccountType: any;
  Bankname: any;
  bankAccountNumber: any;
  anualPremium: string;
  monthlyPremium: any;
  sameAddress: any;
  firstName: any;
  lastName: any;
  disableForm = true;
  cardNumber: any;
  CVV: any;
  expiryDate: any;
  loader: boolean;
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  options = "{types: ['address', 'place'], componentRestrictions: { country: 'USA'}}";
  errormessage: string;
  showerrormessage: boolean;
  cardNumberError: boolean;
  carderrormessage: string;
  cvverror: string;
  anualPremiumtoshow: any;
  monthlyPremiumtoshow: string;
  formattedaddress: any;
  address1: any;
  locality: any;
  state: any;
  postalcode: any;
  email: any;
  phoneno: any;
  country: any;
  lastFourDigits: any;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger;
    this.sameAddress = 'Yes';
    this.cvverror = '';
    this.clientId = localStorage.getItem('clientID');
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      application_stage: 'WNS_Payment'
    }
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    //this.isDisable = true;
    this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli-payment')[0];
    console.log('app-wns6pt54 questions : ', this.questions);
    //this.paymentType = 'EFT';
    this.bankAccountType = 'Bank Acoount Type';


    this.pc.getAppData(this.clientId).then(data => {
      debugger
      const dataFrom = JSON.parse(data.Payload);
      this.formattedaddress = dataFrom.body.formatted_address;
      if (this.formattedaddress.includes(',')) {
        let address = this.formattedaddress.split(',');
        this.address1 = address[0];
        this.locality = dataFrom.body.locality_ln;
        this.state = (dataFrom.body.stateCode == undefined) ? dataFrom.body.state_name : dataFrom.body.stateCode;
      }
      this.country = dataFrom.body.country_sn;
      this.postalcode = dataFrom.body.zip_code;
      this.email = dataFrom.body.bubble_email;
      this.phoneno = dataFrom.body.bubble_phone.replace(/-/g, '');

      // this.anualPremium = "Annually ($" + dataFrom.body["sbli_premiumAnnually"] + ")";
      this.anualPremiumtoshow = parseFloat(dataFrom.body['sbli_premiumAnnually']).toFixed(2);
      this.anualPremium = parseFloat(dataFrom.body['sbli_premiumAnnually']).toFixed(2);
      this.anualPremium = 'Annually ($' + this.anualPremium + ')';

      this.monthlyPremiumtoshow = parseFloat(dataFrom.body['sbli_premiumMonthly']).toFixed(2);
      this.monthlyPremium = parseFloat(dataFrom.body['sbli_premiumMonthly']).toFixed(2);
      this.monthlyPremium = 'Monthly ($' + this.monthlyPremium + ')';

      this.premium = 0;

      this.existingData = dataFrom.body.SBLI;
      // if (this.existingData.sbli_paymentDetails.sbli_paymentFirstName != undefined) {
      //   this.premium = this.existingData.sbli_paymentDetails.sbli_paymentFrequency;
      //   this.firstName = this.existingData.sbli_paymentDetails.sbli_paymentFirstName;
      //   this.lastName = this.existingData.sbli_paymentDetails.sbli_paymentLastName;
      //   this.bankAccountType = this.existingData.sbli_paymentDetails.sbli_bankAccountType;
      //   this.bankRoutingNo = this.existingData.sbli_paymentDetails.sbli_bankRoutingNo;
      //   this.Bankname = this.existingData.sbli_paymentDetails.sbli_bankName;
      //   this.bankAccountNumber = this.existingData.sbli_paymentDetails.sbli_bankAccountNumber;
      // }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
  }





  selectpayment(event) {
    debugger;
    this.paymentType = event;

  }

  selectpremium(event) {
    debugger;
    this.premium = event;

  }


  slectBankType(event) {
    this.bankAccountType = event.target.value;
  }

  billing(event) {
    debugger
    this.sameAddress = event;

  }



  public handleAddressChange(address: Address) {
    debugger
    this.loader = true;
    console.log("billingaddresshandles", address);
    console.log("formatted_billing_address ", address.formatted_address);
    this.sameBillingAddress = address.formatted_address;
    let addressToFormat = address.formatted_address;
    addressToFormat = addressToFormat.replace(/,/g, "");
    console.log("addressToFormat ", addressToFormat);
    this.pc.parseAddressLocalAndPatch(addressToFormat, this.clientId).then(
      data => {
        console.log("split lenderaddress : ", data);
        var entity = {
          "fullAddress": address.formatted_address,
          "address": data.door_number + " " + data.street,// + " " + data.street_type,
          "state": data.state_code,
          "city": data.city_name,
          "zip": data.zip
        }
        console.log("addres object : ", entity);
        // this.billingAddressObject = entity;
        //this.customAddress = address.formatted_address;
        this.loader = false;
      }
    ).catch(error => console.log("error", error));
  }


  next() {
    this.pc.lifesummary({ 'payment' :'submit' });
    debugger;
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    this.paymentType;
    var cardnumber = this.cardNumber.replace(/ /g, '');
    var cardnostr = cardnumber.toString();
    this.lastFourDigits = cardnostr.substring(cardnostr.length - 4);
    const wns_paymentDetails = {
      "cardNumber": this.cardNumber.replace(/ /g, ''),
      "cardExpirationMonth": this.expiryDate.split('/')[0],
      "cardExpirationYear": this.expiryDate.split('/')[1],
      "totalAmount": (this.premium) == 1 ? this.monthlyPremiumtoshow : this.anualPremiumtoshow,
      "currency": "USD",
      "firstName": this.firstName,
      "lastName": this.lastName,
      "address1": this.address1,
      "locality": this.locality,
      "administrativeArea": this.state,
      "postalCode": this.postalcode,
      "country": this.country,
      "email": this.email,
      "phoneNumber": this.phoneno
    };

    json.SBLI['sbli_paymentDetails'] = wns_paymentDetails;
    json.SBLI['samebilling'] = this.sameAddress;
    if (this.sameAddress == 'No') {
      json.SBLI['sameBillingAddress'] = this.sameBillingAddress;
    }
    console.log('sbli_paymentDetails', wns_paymentDetails);
    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1,
      'APICall': 6
    };


    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

  public dateChange() {
    debugger

    var currentTime = new Date()
    var month = currentTime.getMonth() + 1
    var year = currentTime.getFullYear()
    var j = this.expiryDate.replace(/[{(/)}]/g, '');
    var A = j.substring(0, 2);
    var B = j.substring(2, 6);
    if (B == "") {
      if (A.length == 2) {
        if (A > 12 || A < month) {
          this.errormessage = 'Invalid Date';
          this.showerrormessage = true;
        }
      }
      else {
        this.expiryDate = A;
        this.errormessage = '';
        this.showerrormessage = true;
      }

    }
    else if (B != "" && A.length == 2) {
      if (B.length == 4) {
        if ((Number(B) <= year && Number(A) < month) || A > 12) {
          this.errormessage = 'Invalid Date';
          this.showerrormessage = true;
        }
        else {
          this.errormessage = '';
          this.showerrormessage = false;
        }
        this.expiryDate = A + "/" + B;
      }
      else {
        if (j.length != 6) {
          this.expiryDate = A + "/" + B;
        }
      }
    }

  }

  public cardChange() {
    if (this.cardNumber.toString().length === 4) {
      this.cardNumber = this.cardNumber + " ";
      this.cardNumberError = true;
      this.carderrormessage = 'Invalid Card Number';
    }
    else if (this.cardNumber.toString().length === 9) {
      this.cardNumber = this.cardNumber + " ";
      this.cardNumberError = true;
      this.carderrormessage = 'Invalid Card Number';
    }
    else if (this.cardNumber.toString().length === 14) {
      this.cardNumber = this.cardNumber + " ";
      this.cardNumberError = true;
      this.carderrormessage = 'Invalid Card Number';
    }
    else if (this.cardNumber.toString().length < 19) {
      this.cardNumberError = true;
      this.carderrormessage = 'Invalid Card Number';
    }
    else {
      this.cardNumberError = false;
      this.carderrormessage = '';
    }

  }

  public checkCVV() {
    if (this.CVV.length < 3) {
      this.cvverror = "Invalid CVV";
    }
    else {
      this.cvverror = "";
    }
  }
}
