import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-personal-details-question1',
  templateUrl: './personal-details-question1.component.html',
  styleUrls: ['./personal-details-question1.component.css']
})
export class PersonalDetailsQuestion1Component implements OnInit {
  Name:string;
  email:string;
  heroForm:any;
  authorised: any;

  constructor() { }

  ngOnInit(): void {

    this.heroForm = new FormGroup({
      Name: new FormControl(this.Name),
      email: new FormControl(this.email, Validators.required),
    });

  }

}
