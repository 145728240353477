import { Component, OnInit } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { SecurityService } from 'src/app/shared/security.service';

const submitLifeScore = gql`
  mutation createLifeScore(
    $get1: String!, $get2: String!, $get3: String!, $get4: String!, $get5: String!,
    $put1: String!, $put2: String!, $put3: String!, $put4: String!, $put5: String!,
    $dget1: String!, $dget2: String!, $dget3: String!, $dget4: String!, $dget5: String!,
    $dput1: String!, $dput2: String!, $dput3: String!, $dput4: String!, $dput5: String!,
    ){
    createLifeScore(
      input:{
        data: {
          get1: $get1, get2: $get2, get3: $get3, get4: $get4, get5: $get5,
          put1: $put1, put2: $put2, put3: $put3, put4: $put4, put5: $put5,
          dget1: $dget1, dget2: $dget2, dget3: $dget3, dget4: $dget4, dget5: $dget5,
          dput1: $dput1, dput2: $dput2, dput3: $dput3, dput4: $dput4, dput5: $dput5,
        }
      }
    ){
      lifeScore{
        put1
        put2
        put3
      }
    }
  }
`;

const createLifeScore = gql`
mutation createLifeScore($input: createLifeScoreInput){
  createLifeScore(input:$input){
    lifeScore{
      get1
    }
  }
}`

const getLifeScore = gql`
  query($get1: String!){
    scoreData(where:{ get1: $get1}){
      get1
      put1
      put2
    }
  }
`

@Component({
  selector: 'app-ed-lifescore',
  templateUrl: './ed-lifescore.component.html',
  styleUrls: ['./ed-lifescore.component.css']
})
export class EdLifescoreComponent implements OnInit {

  constructor(private apollo: Apollo,
    private security: SecurityService,
              /* private ps: PapaParseService */) { }

  csvArray = [];
  graphql_data = [];
  upload = false;
  data_category = "";
  version = "";
  status = "";
  data_dictionary = "";
  totalRows = 0;
  success = 0;
  failure = 0;

  ngOnInit(): void {
    console.log('In EdLifescoreComponent');
    // this.newLifeScore();
  }

  newLifeScore() {
    /* console.log("newLifeScore");
    let enc_var = this.security.encryptCodes('newLifeScore');
    console.log('Encryption: ', enc_var);
    let numberToArray = this.security.getNumberArrayFrom(enc_var)
    console.log('getNumberArrayFrom: ', numberToArray);
    console.log('Decryption: ', this.security.decryptCodes(numberToArray));
    let getDataObject = {
      get1: this.security.encryptCodes('Autauga'),
      get2: this.security.encryptCodes('787.5311'),
      get3: this.security.encryptCodes('Average')
    };
    console.log(getDataObject); */
    /* this.apollo.mutate({
      mutation: submitLifeScore,
      variables: {
        get1: 'Graph-Strapi',
        get2: 'G2 Body',
        get3: 'G2_class'
      }
    }).subscribe(({ data }) => {
      console.log('got data', data);
    },(error) => {
      console.log('there was an error sending the query', error);
    }); */

    /* this.apollo.watchQuery<any>({
      query: getLifeScore,
      variables: {get1: this.security.encryptCodes('Autauga').toString()},
    })
     .valueChanges
     .subscribe(({ data, loading }) => {
       let response = data.scoreData[0];
       
        console.log(response);
        console.log(this.security.decryptCodes(JSON.parse(response.get1)));
        console.log(this.security.decryptCodes(JSON.parse(response.put1)));
        console.log(this.security.decryptCodes(JSON.parse(response.put2)));
    }); */
  }

  ConvertCSVtoJSON() {
    console.log(JSON.stringify(this.csvArray));
  }

  handleFileSelect(evt) {
    var files = evt.target.files; // FileList object
    var file = files[0];
    var reader = new FileReader();
    reader.readAsText(file);
    reader.onload = (event: any) => {
      var csv = event.target.result; // Content of CSV file
      console.log(csv);
      /* Data_Dictionary
        dput1=>GEOID_fip
        dput2=>County_Name
        dput3=>County_Name_2
        dput4=>County_Name_3
        dput5=>state_code
        dput6=>weighted_avg_ls
        dput7=>weighted_avg_ls_perc
      */
      let csvToRowArray = csv.split("\n");
      // console.log(csvToRowArray);
      let singleRow = [];
      let singleObject = {};
      let headerLength = 0;
      for (let i = 1; i < csvToRowArray.length; i++) {
        singleRow = [];
        singleObject = {};
        singleRow = csvToRowArray[i].split(",");
        // console.log(singleRow);
        if (!singleRow[0]) {
          break;
        }
        headerLength = singleRow.length;
        console.log("count: ", headerLength);
        singleRow[0] = /^\d+$/.test(singleRow[0]) && singleRow[0].length < 5 ? singleRow[0] : '0' + singleRow[0];

        for (let j = 0; j < headerLength; j++) {
          singleObject['get' + (j + 1)] = this.security.encryptCodes(singleRow[j]);
          singleObject['put' + (j + 1)] = this.security.encryptCodes(singleRow[j]);
          singleObject['dget' + (j + 1)] = singleRow[j];
          singleObject['dput' + (j + 1)] = singleRow[j];
        }
        /* singleObject = {
          'get1': this.security.encryptCodes(singleRow[0]),
          'get2': this.security.encryptCodes(singleRow[1]),
          'get3': this.security.encryptCodes(singleRow[2]),
          'get4': this.security.encryptCodes(singleRow[3]),
          'get5': this.security.encryptCodes(singleRow[4]),
          'put1': this.security.encryptCodes(singleRow[0]),
          'put2': this.security.encryptCodes(singleRow[1]),
          'put3': this.security.encryptCodes(singleRow[2]),
          'put4': this.security.encryptCodes(singleRow[3]),
          'put5': this.security.encryptCodes(singleRow[4]),
          'dget1': singleRow[0],
          'dget2': singleRow[1],
          'dget3': singleRow[2],
          'dget4': singleRow[3],
          'dget5': singleRow[4],
          'dput1': singleRow[0],
          'dput2': singleRow[1],
          'dput3': singleRow[2],
          'dput4': singleRow[3],
          'dput5': singleRow[4],
        }; */
        this.graphql_data.push(singleObject);
        /* this.apollo.mutate({
          mutation: createLifeScore,
          variables: {
            "input": {"data":singleObject}
          }
        }).subscribe(({ data }) => {
          console.log('got data', data);
        },(error) => {
          console.log('there was an error sending the query', error);
        }); */
      }
      console.log(this.graphql_data);
      this.upload = true;

    }
  }
  chunk(arr, size) {
    return arr.reduce((acc, e, i) => (i % size ? acc[acc.length - 1].push(e) : acc.push([e]), acc), []);
  }

  uploadToServer() {
    this.upload = false;
    console.log(this.data_category);
    console.log(this.version);
    console.log(this.status);
    console.log(this.data_dictionary);
    this.totalRows = this.graphql_data.length;
    let uploadData = this.chunk(this.graphql_data, 50);
    console.log(uploadData);
    let delay = 3; //seconds;
    for (let k = 0; k < uploadData.length; k++) {
      // const element = uploadData[k];
      console.log(uploadData[k]);
      setTimeout(() => {
        for (let i = 0; i < uploadData[k].length; i++) {
          this.upload = false;
          uploadData[k][i]['version'] = this.version;
          uploadData[k][i]['status'] = this.status;
          uploadData[k][i]['data_category'] = this.data_category;
          uploadData[k][i]['data_dictionary'] = this.data_dictionary;
          this.apollo.mutate({
            mutation: createLifeScore,
            variables: {
              "input": { "data": uploadData[k][i] }
            }
          }).subscribe(({ data }) => {
            // console.log('got data', data);
            this.success = this.success + 1;
            console.log('success');
          }, (error) => {
            this.failure = this.failure + 1;
            console.log('failure');
            // console.log('there was an error sending the query', error);
          });
        }
      }, delay * 1000);
      delay = delay + 3;
    }

    this.upload = true;
  }

}
