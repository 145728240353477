import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from "../../../appUtility/appUtility.js";
@Component({
  selector: 'app-sbli00301011',
  templateUrl: './sbli00301011.component.html',
  styleUrls: ['./sbli00301011.component.css']
})
export class Sbli00301011Component implements OnInit {

  annuity: any;
  questions: any;
  clientId: any;
  existingData: any;
  @Output() changeQuestion = new EventEmitter();
  showLoader: boolean = true;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');
    // this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli00301011")[0];
    // console.log("app-sbli00301011 questions : ", this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        let que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == "app-sbli00301011")[0];
        console.log("app-sbli00301011 questions : ", this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli00301011")[0];
        console.log("app-sbli00301011 questions : ", this.questions);
      }

      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_annuity != undefined) {
        this.annuity = this.existingData.sbli_annuity;
      }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
  }



  next(value) {
    debugger
    this.annuity = value;
    var json = {
      'id': this.clientId,
      SBLI: this.existingData
    }
    json.SBLI[this.questions.radio_options[0].answer_key] = value;
    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
