import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "./../../../environments/environment";
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-prefill-resume-flow',
  templateUrl: './prefill-resume-flow.component.html',
  styleUrls: ['./prefill-resume-flow.component.css']
})
export class PrefillResumeFlowComponent implements OnInit {
  email: any;
  insuranceType: any;
  loader: boolean;
  clientId: any;
  iType: string;
  recoType: string;

  constructor(public router: Router, private Http: HttpClient, private pc: ProcessCacheService, private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    debugger;
    this.loader = true;
    this.activatedroute.params.subscribe(params => {
      this.email = params['email'];
      this.insuranceType = params['insuranceType'];
      // if (this.insuranceType.toLowercase().startsWith("home")) this.insuranceType = "home";
      // if (this.insuranceType.toLowercase().startsWith("life")) this.insuranceType = "life";
      if (this.insuranceType == "home") {
        this.iType = "home-quote";
        this.recoType = "SelectHomeCarrier";
      }
      else if (this.insuranceType == "life") {
        this.iType = "life-quote";
        this.recoType = "SelectLifeCarrierV2";
      }
      else {
        this.iType = "home-life-quote";
        this.recoType = "SelectHomeCarrier";
      }
      this.Http.get(`${this.pc.envCred.api2Domain}?data_category=id_mapping&dget3=${this.email}`).subscribe(data => {
        debugger
        let dataList = (data['output_data']);
        dataList = dataList.reverse();
        console.log("latest data for email : ", dataList[0]);
        this.clientId = dataList[0].dput1;
        localStorage.setItem("clientID", this.clientId);
        localStorage.setItem("clientId", this.clientId);
        console.log("clientId : ", this.clientId);
        this.pc.getAppData(this.clientId).then(data => {
          debugger
          let dataFrom = JSON.parse(data.Payload);
          let clientData = dataFrom.body;
          console.log("dataFrom get app data : ", clientData);
          let commonInput = clientData.commonInput;
          debugger
          let CommonInputHome = {};
          if (this.insuranceType == "home") {
            let propDetails = {
              "construction_type": commonInput["propertyDetails.construction_type"],
              "home_type": commonInput["propertyDetails.home_type"],
              "roof_type": commonInput["propertyDetails.roof_type"],
              "stories": commonInput["propertyDetails.stories"],
              "swimming_pool": commonInput["propertyDetails.swimming_pool"],
              "roof_constructed": commonInput["propertyDetails.roof_constructed"],
              "year_built": commonInput["propertyDetails.year_built"],
              "living_size": commonInput["propertyDetails.living_size"]
            }
            CommonInputHome = {
              address: commonInput.address,
              addressType: "PhysicalRisk",
              ca_builder: "",
              city: commonInput.city,
              coverage: commonInput.coverage,
              date_of_birth: commonInput.dob,
              dob: commonInput.dob,
              email: this.email,
              firstName: commonInput.firstName,
              lastName: commonInput.lastName,
              middleName: "",
              phone: clientData.bubble_phone,
              premiumFrequency: 'm',
              construction_type: commonInput["propertyDetails.construction_type"],
              home_type: commonInput["propertyDetails.home_type"],
              property_living_area: commonInput["propertyDetails.living_size"],
              roof_updated_year: commonInput["propertyDetails.roof_constructed"],
              roof_type: commonInput["propertyDetails.roof_type"],
              stories: commonInput["propertyDetails.stories"],
              yearbuilt: commonInput["propertyDetails.year_built"],
              swimming_pool: commonInput["propertyDetails.swimming_pool"],
              sw_discounts: [],
              sw_appQuestions: [],
              replacementCost: 10,
              state: commonInput.state,
              property_year_built: commonInput.yearbuilt,
              zip: commonInput.zip,
              propertyDetails: propDetails,
              sw_propertyDetails: propDetails,
            }
            localStorage.setItem('commonInput', JSON.stringify(CommonInputHome));
            console.log("common input : ", commonInput);
            console.log("CommonInputHome : ", CommonInputHome);
          }
          else if (this.insuranceType == "life") {
            localStorage.setItem('bubble-mortgage', clientData.bubble_mortgage);
            localStorage.setItem('bubble-kids', clientData.bubble_kids);
            localStorage.setItem('bubble-annualIncome', clientData.bubble_annualIncome);
            var dob = new Date(clientData.comfirmDetailsDateOfBirth);
            //calculate month difference from current date in time
            var month_diff = Date.now() - dob.getTime();
            //convert the calculated difference in date format
            var age_dt = new Date(month_diff);
            //extract year from date    
            var year = age_dt.getUTCFullYear();
            //now calculate the age of the user
            var age = Math.abs(year - 1970);
            localStorage.setItem('bubble-age', age.toString());
            if (!clientData.bubble_mortgage) clientData.bubble_mortgage = 0;
            if (!clientData.bubble_annualIncome) clientData.bubble_annualIncome = 0;
            if (!clientData.bubble_kids) clientData.bubble_kids = 0;
            this.pc.getCoverageRecommendation(clientData.bubble_mortgage, clientData.bubble_annualIncome, age, clientData.bubble_kids).then(response => {
              let CommonInputLife = {
                lastName: clientData.bubble_lname,
                firstName: clientData.bubble_fname,
                middleName: "",
                addressType: "PhysicalRisk",
                address: clientData.fullAddress,//splitAddress[(splitAddress.length - 3)],
                city: clientData.city,
                gender: clientData.paclifeQuote.gender == 'Female' ? "F" : "M", //inputJson.gender
                dob: clientData.comfirmDetailsDateOfBirth,//inputJson.dob
                term: 47,//inputJson.term
                zip: clientData.zip,//inputJson.zip
                state: clientData.insuredInfo.insuredState,//inputJson.state
                height: clientData.comfirmDetailsHeight,//inputJson.height
                weight: clientData.comfirmDetailsWeight,//inputJson.weight
                tobaccoUse: +clientData.tobacoUser,//inputJson.tobaccoUse
                riskClass: clientData.paclifeQuote.riskClass,
                premiumFrequency: "m",
              }
              CommonInputLife["coverage"] = response.data.output;
              localStorage.setItem('commonInput', JSON.stringify(CommonInputLife));
              console.log("common input : ", commonInput);
              console.log("CommonInputLife : ", CommonInputLife);
            })
              .catch(error => {
                console.log("getCoverageRecommendation", error);
              });
          }
          else {
            let propDetails = {
              "construction_type": commonInput["propertyDetails.construction_type"],
              "home_type": commonInput["propertyDetails.home_type"],
              "roof_type": commonInput["propertyDetails.roof_type"],
              "stories": commonInput["propertyDetails.stories"],
              "swimming_pool": commonInput["propertyDetails.swimming_pool"],
              "roof_constructed": commonInput["propertyDetails.roof_constructed"],
              "year_built": commonInput["propertyDetails.year_built"],
              "living_size": commonInput["propertyDetails.living_size"]
            }
            CommonInputHome = {
              address: commonInput.address,
              addressType: "PhysicalRisk",
              ca_builder: "",
              city: commonInput.city,
              coverage: commonInput.coverage,
              date_of_birth: commonInput.dob,
              dob: commonInput.dob,
              email: this.email,
              firstName: commonInput.firstName,
              lastName: commonInput.lastName,
              middleName: "",
              phone: clientData.bubble_phone,
              premiumFrequency: 'm',
              construction_type: commonInput["propertyDetails.construction_type"],
              home_type: commonInput["propertyDetails.home_type"],
              property_living_area: commonInput["propertyDetails.living_size"],
              roof_updated_year: commonInput["propertyDetails.roof_constructed"],
              roof_type: commonInput["propertyDetails.roof_type"],
              stories: commonInput["propertyDetails.stories"],
              yearbuilt: commonInput["propertyDetails.year_built"],
              swimming_pool: commonInput["propertyDetails.swimming_pool"],
              sw_discounts: [],
              sw_appQuestions: [],
              replacementCost: 10,
              state: commonInput.state,
              property_year_built: commonInput.yearbuilt,
              zip: commonInput.zip,
              propertyDetails: propDetails,
              sw_propertyDetails: propDetails,
            }
            localStorage.setItem('commonInput', JSON.stringify(CommonInputHome));
            console.log("common input : ", commonInput);
            console.log("CommonInputHome : ", CommonInputHome);
          }
          let address = clientData.fullAddress;
          let resumeURL = `${this.recoType}/${clientData.unique_id}/${this.clientId}/${address}/${this.insuranceType}`;
          console.log("resumeURL generated: ", resumeURL);
          this.resumeFlow(resumeURL);
          this.loader = false;
        }).catch(error => {
          console.log(error);
        })
      })
    });
  }

  async resumeFlow(resumeURL) {
    this.loader = true;
    let createJson = {
      insuranceType: this.iType,
      carrierName: "stillwater",
      appStartStatus: false,
      appCompletedStatus: false,
      questionStartStatus: false,
      questionCompletedStatus: false,
      questionSequence: "0",
      applicationId: "",
      otherAppflowScreen: "home-question1",
      resumeUrl: resumeURL
    }
    let createResumeData = await this.pc.checkforResumeInit(createJson, "create");
    console.log("createResumeData: ", createResumeData);
    this.router.navigate(['/resumeFlow', this.clientId]);
  }

}
