import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getQuestionAnswerJson,getQuestionAnswerByPassingInput} from "../../../../appUtility/appUtility";
import { ProcessCacheService } from '../../../../shared/process-cache.service';
@Component({
  selector: 'app-disability-benefits',
  templateUrl: './disability-benefits.component.html',
  styleUrls: ['./disability-benefits.component.css']
})
export class DisabilityBenefitsComponent implements OnInit {
  @Output() nextquestion2 = new EventEmitter();
  constructor() { }
  questionAnswersJSON:[];
  ngOnInit(): void {
    this.questionAnswersJSON= getQuestionAnswerJson;
    console.log(getQuestionAnswerJson);

    
  }

  SelectedOption:any;
   public GetOptionsyesno(data){
  
     this.SelectedOption=data;
     if(this.SelectedOption==0){
       document.getElementById('No').style.border='solid 2px orange';
       document.getElementById('yes').style.border='solid 1px #dae7ec';
      
     }else{
      document.getElementById('yes').style.border='solid 2px orange';
      document.getElementById('No').style.border='solid 1px #dae7ec';
     }
   }

  next() {
    let etty = {
      "questionNo": "2",
      "nextprev": 1
    }
    this.nextquestion2.emit(etty);
  }


}
