import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getQuestionAnswerJson,getQuestionAnswerByPassingInput} from "../../../../appUtility/appUtility";
import { ProcessCacheService } from '../../../../shared/process-cache.service';

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.css']
})
export class EmploymentComponent implements OnInit {
  @Output() nextquestion1 = new EventEmitter();
  constructor(private pc: ProcessCacheService) { }
  questionAnswersJSON:[];
  aswersJSON 
  Question
  OptionsList
  FirstQuestion:any;
  ShowYesOrNo:boolean;
  ShowYesOrNo1:boolean;
  ngOnInit(): void {
    this.ShowYesOrNo=true;
    this.ShowYesOrNo1=false;
    this.questionAnswersJSON= getQuestionAnswerJson;
    // this.FirstQuestion=this.questionAnswersJSON["questions"].q1[0].qestion;


  }


  SelectedOption:any;
  SecondQuestion:any;
   public GetOptions(data){
     this.SelectedOption=data;
     if(this.SelectedOption==0){
       document.getElementById('0').style.border='solid 2px orange';
       document.getElementById('1').style.border='solid 1px #dae7ec';
       this.SecondQuestion=this.questionAnswersJSON["questions"]["q1"][3]["No"][0]["q"];
       this.ShowYesOrNo1=true;
     }else{
      document.getElementById('1').style.border='solid 2px orange';
      document.getElementById('0').style.border='solid 1px #dae7ec';
      this.ShowYesOrNo1=false;
      this.ShowYesOrNo2=false;
     }
   }
   OptionValues:any;
   ShowYesOrNo2:boolean;
   ThirdQuestion:any;
   public SecondQuestionOptions(data){
    
     this.OptionValues=data.target.value;
     if(this.OptionValues=='Retired'){
       this.ShowYesOrNo2=false;

     }else if(this.OptionValues=='spouse'){

      this.ShowYesOrNo2=false;
     }else if(this.OptionValues=='student'){
       this.ShowYesOrNo2=true;
       this.ThirdQuestion=this.questionAnswersJSON["questions"]["q1"][3]["No"][4]["option3"][1]["q"]
        
     }else{
      this.ShowYesOrNo2=false;
     }

   }

  
   public Save(){
    let inputjson = {
      "Question": this.Question,
      "OptionsList":this.OptionsList,
      "Answer":this.aswersJSON
    }
    this.aswersJSON= getQuestionAnswerByPassingInput();
    this.pc.saveUpdateClientData(inputjson);
   }
  

  option1:any;
  option2:any;
  option3:any;
  option4:any;
  public NextQuestion(){
   
    if(this.SelectedOption==0){
     
    }else{
    }
  }

  // RadioOptions:any;
  // public getOptions(data){
  //   this.RadioOptions=data.target.value;
  //   if(this.RadioOptions=='student'){


  //   }else{

  //   }
  // }

  SecondRadioOptions:any;
  public ThirdQuestionOptions(data){
    this.SecondRadioOptions=data.target.value;

  }

  next() {
    localStorage.setItem('emp-1',this.SelectedOption);
    localStorage.setItem('emp-2',this.OptionValues);
    localStorage.setItem('emp-3',this.SecondRadioOptions);
    let Entity = {
      "questionNo": "1",
      "nextprev": 1,
      "YesOrNoOption":this.SelectedOption,
      "SelectedOption":this.OptionValues,
      "RadioOption":this.SecondRadioOptions
    }
    this.nextquestion1.emit(Entity);
  }



}
