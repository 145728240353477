import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { ActivatedRoute } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-sw4pt7',
  templateUrl: './sw4pt7.component.html',
  styleUrls: ['./sw4pt7.component.css']
})
export class Sw4pt7Component implements OnInit {

  @Output() nextquestion2 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  existing: any;
  policyStartDate: any;
  replacementCost: any;
  existingPolicy: any;
  existingPolicyNumber: any;
  homePurchaseDate: string;
  curDate: Date;
  maxDate: Date;
  minDate: Date;
  message: any;
  is_valid: any;
  state: any;
  maxReplacementCost: any;
  minReplacementCost: any;
  predictedScoreData: any;
  inputAddress: any;
  homePurchaseMonth: any;
  homePurchaseYear: any;
  existingData: any;
  previousMailingAddress: string;
  previousMail: number;
  previousMailingAddressObject: { fullAddress: string; address: string; state: any; city: any; zip: any; };
  errorMessage: string;
  yearError: string;
  replacementCosttodisaply: string;
  loader: boolean;
  disablebutton: boolean;
  montherror: any;
  showmontherror: boolean;
  mailerrormsg: string;
  existingPolicyType: string;

  constructor(private $gaService: GoogleAnalyticsService,private scoreApi: ScorecardApiService,
    private modalService: NgbModal,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    debugger;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    // this.uniqueId = 'tbinq2-285-Bryant-St-Palo-Alto-CA-94301';
    // this.clientId = 'tbinq2';
    this.loader = true;
    let fullAddress = this.uniqueId.split('-');
    fullAddress.shift();
    fullAddress = fullAddress.join(' ');
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt7')[0];
    console.log('sw questions app-sw4pt7 :', this.questions);

    this.pc.parseAddressLocalAndPatch(fullAddress, this.clientId).then(
      data => {
        console.log('splitAddressNew : ', data);
        this.state = data.state_code;
        const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
        console.log('selectedHome :', selectedHome);
        let dwelleing = selectedHome.body.other_amounts.dwelling.text;
        dwelleing = dwelleing.replace('$', '').replace(/,/g, '');
        console.log('dwelleing :', dwelleing);
        let replacementCost = selectedHome.replacementCost;
        replacementCost = replacementCost.replace('$', '').replace(/,/g, '');
        console.log('replacementCost :', replacementCost);
        this.maxReplacementCost = replacementCost;

        // let commonInput = JSON.parse(localStorage.getItem("commonInput"));
        // commonInput['replacementCost'] = replacementCost;
        // commonInput['coverage'] = dwelleing;
        // localStorage.setItem("commonInput", JSON.stringify(commonInput));
        // if (this.state == "CA") {
        //   this.maxReplacementCost = 1500000;
        // }
        // else {
        //   this.maxReplacementCost = 1000000;
        // }
        this.pc.castpredictedScores.subscribe(
          (data: any) => {
            this.predictedScoreData = data;
            this.minReplacementCost = this.predictedScoreData.dwellingResult;
            localStorage.setItem('dwellingResult', this.predictedScoreData.dwellingResult);
            localStorage.setItem('dwellingResults', this.predictedScoreData);
            if (this.minReplacementCost == 'No Data' || this.minReplacementCost == undefined) {
              this.minReplacementCost = this.maxReplacementCost;
            }

            this.pc.getAppData(this.clientId).then(data => {
              const dataFrom = JSON.parse(data.Payload);
              this.existingData = dataFrom.body.stillwater;
              console.log('data from getAppData sw : ', this.existingData);

              if (this.existingData['sw_ExistingPolicy'] == 'yes') {
                this.existingPolicy = this.existingData['sw_ExistingPolicy'];
                this.questions.sub_questions.question_4.radio_options[0].active = true;
                this.questions.sub_questions.question_4.radio_options[1].active = false;
                this.existing = '1';
              }
              else if (this.existingData['sw_ExistingPolicy'] == 'no'){
                this.existingPolicy = this.existingData['sw_ExistingPolicy'];
                this.questions.sub_questions.question_4.radio_options[0].active = false;
                this.questions.sub_questions.question_4.radio_options[1].active = true;
                this.existing = '0';
              }
              else {
                this.existingPolicy = '';
                this.questions.sub_questions.question_4.radio_options[0].active = false;
                this.questions.sub_questions.question_4.radio_options[1].active = false;
                this.existing = '0';
              }
              if (this.existingData['sw_PolicyStartDate']==null || this.existingData['sw_PolicyStartDate']==undefined || this.existingData['sw_PolicyStartDate']==''){
                this.policyStartDate = new Date();
              }
              else {
                this.policyStartDate = this.existingData['sw_PolicyStartDate'];
                if (new Date(this.policyStartDate)<new Date()) {
                  this.policyStartDate = new Date();
                }
              }

              if (this.existingData['sw_homePurchasetDate']==null || this.existingData['sw_homePurchasetDate']==undefined || this.existingData['sw_homePurchasetDate']=='') {
                this.homePurchaseDate = '';
                this.previousMail = 0;
              }
              else {

                this.homePurchaseDate = this.existingData['sw_homePurchasetDate'];
                const sep = this.homePurchaseDate.split('/');
                this.homePurchaseMonth = sep[0];
                this.homePurchaseYear = sep[1];
                if (this.homePurchaseMonth.length==1) {
                  this.homePurchaseMonth = '0' + this.homePurchaseMonth;
                }
                const currentYear = new Date().getFullYear();
                const diff = Number(currentYear) - Number(this.homePurchaseYear);
                if (diff <= 2) {
                  this.previousMail = 1;
                }
                else {
                  this.previousMail = 0;
                }
              }
               if (this.existingData['sw_previousMailingAddress']==null || this.existingData['sw_previousMailingAddress']==undefined || this.existingData['sw_previousMailingAddress']=='') {
                this.previousMailingAddress = '';
              }
              else {
                this.previousMailingAddress = this.existingData['sw_previousMailingAddress']['fullAddress'];
                this.disablebutton = false;
                this.pc.parseAddressLocalAndPatch(this.previousMailingAddress, this.clientId).then(
                  data => {
                    console.log('split lenderaddress : ', data);
                    const entity = {
                      'fullAddress': this.previousMailingAddress,
                      'address': data.door_number + ' ' + data.street, //+ " " + data.street_type,
                      'state': data.state_code,
                      'city': data.city_name,
                      'zip': data.zip
                    };
                    console.log('addres object : ', entity);
                    this.previousMailingAddressObject = entity;
                  }
                ).catch(error =>
                  console.log('error', error)
                );
              }



              if (this.existingData['sw_ReplacementCost']==null || this.existingData['sw_ReplacementCost']==undefined || this.existingData['sw_ReplacementCost']=='') {
                this.replacementCost = this.minReplacementCost;
              }
              else {
                this.replacementCost = this.existingData['sw_ReplacementCost'];
              }
              if (this.existingData['sw_ExistingPolicyNumber']==null || this.existingData['sw_ExistingPolicyNumber']==undefined || this.existingData['sw_ExistingPolicyNumber']=='') {
                this.existingPolicyNumber = '';
              }
              else {
                this.existingPolicyNumber = this.existingData['sw_ExistingPolicyNumber'];
              }
              if (this.existingData['sw_ExistingPolicyType']==null || this.existingData['sw_ExistingPolicyType']==undefined || this.existingData['sw_ExistingPolicyType']=='') {
                this.existingPolicyType = '';
              }
              else {
                this.existingPolicyType = this.existingData['sw_ExistingPolicyType'];
              }

              this.replacementCosttodisaply = '$' + this.replacementCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

              this.curDate = new Date(this.policyStartDate);
              const years = Number(this.curDate.getFullYear());
              let months = '' + (this.curDate.getMonth() + 1);
              let days = '' + this.curDate.getDate();
              if (months.length < 2)
               months = '0' + months;
              if (days.length < 2)
               days = '0' + days;
              this.policyStartDate=new Date(months + '/'+ days + '/' + years);

              this.curDate = new Date();
              const year = Number(this.curDate.getFullYear());
              let month = '' + (this.curDate.getMonth() + 4);
              let day = '' + this.curDate.getDate();
              if (month.length < 2)
               month = '0' + month;
              if (day.length < 2)
               day = '0' + day;

              this.maxDate=new Date(month + '/'+ day + '/' + year);

              const yearm = Number(this.curDate.getFullYear());
              const monthm = '' + (this.curDate.getMonth() + 1);
              const daym = '' + this.curDate.getDate();
              if (month.length < 2)
               month = '0' + month;
              if (day.length < 2)
               day = '0' + day;

              this.minDate = new Date(monthm + '/' + daym + '/' + yearm);
              this.loader = false;
              this.checkDate(this.policyStartDate)
            }).catch(error => {
              console.log(error);
            });
          },
          (error) => console.log(error)
        );
      }
    ).catch(error => console.log('error', error));

    console.log('Invoking dwellingResult');
    this.pc.getCostFromCache('dwellingResult');


    this.inputAddress = this.uniqueId.split(' ').join('-');

    this.pc.initCaching(this.inputAddress, 'SWQuoteFlow'); //LifeScore

    this.pc.getCachedData2();

    this.errorMessage = '';
    this.yearError = '';





    // if (localStorage.getItem('sw_PolicyStartDate')==null || localStorage.getItem('sw_PolicyStartDate')==undefined || localStorage.getItem('sw_PolicyStartDate')==''){
    //   this.policyStartDate = new Date();
    // }
    // else {
    //   this.policyStartDate = localStorage.getItem('sw_PolicyStartDate');
    // }

    // if (localStorage.getItem('sw_homePurchasetDate')==null || localStorage.getItem('sw_homePurchasetDate')==undefined || localStorage.getItem('sw_homePurchasetDate')=='') {
    //   this.homePurchaseDate = '';
    // }
    // else {
    //   this.homePurchaseDate = localStorage.getItem('sw_homePurchasetDate');
    //   let sep = this.homePurchaseDate.split('/');
    //   this.homePurchaseMonth = sep[0];
    //   this.homePurchaseYear = sep[1];
    //   if (this.homePurchaseMonth.length==1) {
    //     this.homePurchaseMonth = '0' + this.homePurchaseMonth;
    //   }
    // }

    // if (localStorage.getItem('sw_ReplacementCost')==null || localStorage.getItem('sw_ReplacementCost')==undefined || localStorage.getItem('sw_ReplacementCost')=='') {
    //   this.replacementCost = this.minReplacementCost;
    // }
    // else {
    //   this.replacementCost = localStorage.getItem('sw_ReplacementCost');
    // }

    // if (localStorage.getItem('sw_ExistingPolicyNumber')==null || localStorage.getItem('sw_ExistingPolicyNumber')==undefined || localStorage.getItem('sw_ExistingPolicyNumber')=='') {
    //   this.existingPolicyNumber = '';
    // }
    // else {
    //   this.existingPolicyNumber = localStorage.getItem('sw_ExistingPolicyNumber');
    // }

    // if (localStorage.getItem('sw_ExistingPolicy') == 'yes') {
    //   this.existingPolicy = localStorage.getItem('sw_ExistingPolicy');
    //   this.questions.sub_questions.question_4.radio_options[0].active = true;
    //   this.questions.sub_questions.question_4.radio_options[1].active = false;
    //   this.existing = '1';
    // }
    // else if (localStorage.getItem('sw_ExistingPolicy') == 'no'){
    //   this.existingPolicy = localStorage.getItem('sw_ExistingPolicy');
    //   this.questions.sub_questions.question_4.radio_options[0].active = false;
    //   this.questions.sub_questions.question_4.radio_options[1].active = true;
    //   this.existing = '0';
    // }
    // else {
    //   this.existingPolicy = '';
    //   this.questions.sub_questions.question_4.radio_options[0].active = false;
    //   this.questions.sub_questions.question_4.radio_options[1].active = false;
    //   this.existing = '0';
    // }

  }

  validateDate() {
    if (this.homePurchaseDate.length == 2) {
      if (Number(this.homePurchaseDate)<1) {
        this.homePurchaseDate = '1';
      }
      else if (Number(this.homePurchaseDate)>12) {
         this.homePurchaseDate = '12';
      }
      else{
        this.homePurchaseDate = this.homePurchaseDate + '/';
      }
    }
    if (this.homePurchaseDate.length == 7) {
      const year = this.homePurchaseDate.split('/');
      const years = year[1];
      if (years.length == 4) {
        if (Number(years) < 2000) {
          this.homePurchaseDate = year[0] + '/2000' ;
        }
        else if (Number(years) > new Date().getFullYear()) {
          this.homePurchaseDate = year[0] + '/' + new Date().getFullYear();
        }
        else {

        }
      }
    }
  }

  validateMonth() {
    if (this.homePurchaseMonth.length == 2) {
      this.showmontherror=false;
      this.montherror='';
      if (Number(this.homePurchaseMonth) < 1) {
        this.homePurchaseMonth = 1;
      }
      if (Number(this.homePurchaseMonth) > 12) {
        this.homePurchaseMonth = 12;
      }
    }
    else{
      this.showmontherror=true;
      this.montherror='Please Enter Correct Format';
    }
  }

  validateYear() {
    const currentYear = new Date().getFullYear();
    if (this.homePurchaseYear.length == 4) {
      if (Number(this.homePurchaseYear) < (Number(currentYear) - 150)) {
        this.homePurchaseYear = (Number(currentYear) - 150);
      }
      if (Number(this.homePurchaseYear) > Number(currentYear)) {
        this.homePurchaseYear = Number(currentYear);
      }
      const diff = Number(currentYear) - Number(this.homePurchaseYear);
      if (diff <= 2) {
        this.previousMail = 1;
      }
      else {
        this.previousMail = 0;
      }
      if (Number(this.homePurchaseYear)<Number(this.existingData.propertyDetails.year_built)) {
        this.yearError = 'Year purchased must be greater than year built.';
        this.homePurchaseYear = Number(this.existingData.propertyDetails.year_built);
      }
      else {
        this.yearError = '';
      }
    }
  }

  validateReplacementCost() {
    debugger;
    if (this.replacementCost.length>6) {
      if (Number(this.replacementCost) < Number(this.minReplacementCost)) {
        this.replacementCost = this.minReplacementCost;
      }
      if (Number(this.replacementCost) > Number(this.maxReplacementCost)) {
        this.replacementCost = this.maxReplacementCost;
      }
    }
  }

  onYes() {
    this.existingPolicy = 'yes';
    this.questions.sub_questions.question_4.radio_options[0].active = true;
    this.questions.sub_questions.question_4.radio_options[1].active = false;
    this.existing = '1';
  }

  onNo() {
    this.existingPolicy = 'no';
    this.questions.sub_questions.question_4.radio_options[0].active = false;
    this.questions.sub_questions.question_4.radio_options[1].active = true;
    this.existing = '0';
  }



  inputdatechange() {
    debugger
    var K = this.policyStartDate.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.errorMessage = "Invalid Date"
      }
      else {
        this.errorMessage = "";
      }
      this.policyStartDate = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        this.policyStartDate = A + '/' + B + '/';
      }
      else {
        this.policyStartDate = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.policyStartDate = A + '/' + B + '/' + C;
    }
    else {
      this.policyStartDate = this.policyStartDate;
    }
    if (this.policyStartDate.length >= 10&& this.errorMessage!="Invalid Date") {
      this.checkDate(this.policyStartDate)
    }

  }

  checkDate(even) {
    debugger;
    if (even==null) {
      // this.curDate = new Date();
      // const year = Number(this.curDate.getFullYear());
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate() + 30;
      // if (month.length < 2)
      //  month = '0' + month;
      // if (day.length < 2)
      //  day = '0' + day;
      // this.policyStartDate=new Date(month + '/'+ day + '/' + year);
      this.policyStartDate = this.pc.momentdob(this.curDate);
    }
    else{
      const cur_d = new Date();
      let d = new Date(even),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
      const curYear =  cur_d.getFullYear();
      year = d.getFullYear();

     if (month.length < 2)
      month = '0' + month;
     if (day.length < 2)
      day = '0' + day;
      if (day == 'NaN' || month == 'NaN' || year.toString() == 'NaN') {
        this.errorMessage = 'Invalid Date';
        this.is_valid = false;
        this.policyStartDate=''
      }
      else {
        this.policyStartDate = this.pc.momentdob(even);
      }



    }
    debugger;
    if ((new Date(this.policyStartDate) > this.maxDate || new Date(this.policyStartDate) < this.minDate)) {
      // this.curDate = new Date();
      // let years = Number(this.curDate.getFullYear());
      // let months = '' + (this.curDate.getMonth() + 1);
      // let days = '' + this.curDate.getDate();
      // if (months.length < 2)
      //  months = '0' + months;
      // if (days.length < 2)
      //  days = '0' + days;
      // this.policyStartDate = new Date(months + '/' + days + '/' + years);
      this.errorMessage = 'We are unable to quote more than 90 days into the future.';
    }
    else{
      this.errorMessage = '';
    }

    // let newDate = new Date(even)
    // console.log("DOB entered: ", newDate);
    // let checkAge = this.validationService.validate_dob("common_age", newDate);
    // console.log("DOB entered: checkAge", checkAge);
    // this.message=checkAge.message;
    // this.is_valid=checkAge.is_valid;


  }

  public handleAddressChange(address: Address) {
    this.loader = true;
    console.log('billingaddresshandles', address);
    console.log('formatted_billing_address ', address.formatted_address);
    let addressToFormat = address.formatted_address;
    //debugger
    let currentAddress = this.uniqueId.split('-');
    currentAddress.shift();
    currentAddress = currentAddress.join('-');
    let addressToCompare: any;
    addressToCompare= addressToFormat.replace(/,/g, '').replace(/ /g, '-');
    addressToCompare=addressToCompare.split('-');
    addressToCompare.pop();
    addressToCompare = addressToCompare.join('-');
    if (currentAddress == addressToCompare) {
      this.disablebutton = true;
      this.mailerrormsg = 'Previous mailing address cannot be same as property address.';
      this.loader = false;
    }
    else {
      this.disablebutton = false;
      this.mailerrormsg = '';
      addressToFormat = addressToFormat.replace(/,/g, '');
      console.log('addressToFormat ', addressToFormat);
      const google_address = {
        'street_number_ln': '',
        'street_number_sn': '',
        'route_ln': '',
        'route_sn': '',
        'neighborhood_ln': '',
        'neighborhood_sn': '',
        'locality_ln': '',
        'locality_sn': '',
        'administrative_area_level_2_ln': '',
        'administrative_area_level_2_sn': '',
        'administrative_area_level_1_ln': '',
        'administrative_area_level_1_sn': '',
        'country_ln': '',
        'country_sn': '',
        'postal_code_ln': '',
        'postal_code_sn': '',
        'postal_code_suffix_ln': '',
        'postal_code_suffix_sn': '',
        'formatted_address': '',
        'clientId': '',
        'id': ''
      };
      //debugger
      for (let i = 0; i < address.address_components.length; i++) {
        debugger;
        const type = address.address_components[i].types[0];
        debugger;
        switch (type) {
          case 'street_number':
            //debugger
            google_address.street_number_ln = address.address_components[i].long_name;
            google_address.street_number_sn = address.address_components[i].short_name;
            break;
          case 'route':
            //debugger
            google_address.route_ln = address.address_components[i].long_name;
            google_address.route_sn = address.address_components[i].short_name;
            break;
          case 'neighborhood':
            //debugger
            google_address.neighborhood_ln = address.address_components[i].long_name;
            google_address.neighborhood_sn = address.address_components[i].short_name;
            break;
          case 'locality':
            //debugger
            google_address.locality_ln = address.address_components[i].long_name;
            google_address.locality_sn = address.address_components[i].short_name;
            break;
          case 'administrative_area_level_2':
            //debugger
            google_address.administrative_area_level_2_ln = address.address_components[i].long_name;
            google_address.administrative_area_level_2_sn = address.address_components[i].short_name;
            break;
          case 'administrative_area_level_1':
            //debugger
            google_address.administrative_area_level_1_ln = address.address_components[i].long_name;
            google_address.administrative_area_level_1_sn = address.address_components[i].short_name;
            break;
          case 'country':
            //debugger
            google_address.country_ln = address.address_components[i].long_name;
            google_address.country_sn = address.address_components[i].short_name;
            break;
          case 'postal_code':
            //debugger
            google_address.postal_code_ln = address.address_components[i].long_name;
            google_address.postal_code_sn = address.address_components[i].short_name;
            break;
          case 'postal_code_suffix':
            //debugger
            google_address.postal_code_suffix_ln = address.address_components[i].long_name;
            google_address.postal_code_suffix_sn = address.address_components[i].short_name;
            break;
        }
      }
      google_address.formatted_address = address.formatted_address;
      google_address.clientId = this.clientId;
      google_address.id = this.clientId;
      this.loader = false;
      if (google_address.street_number_sn == '' || google_address.route_sn == '' || google_address.locality_sn == '' || google_address.administrative_area_level_2_sn == '' || google_address.administrative_area_level_1_sn == '' || google_address.postal_code_sn == '') {
        this.disablebutton = true;
        this.openModal();
      }
      // this.pc.parseAddressLocalAndPatch(addressToFormat, this.clientId).then(
      //   data => {
      //     debugger;
      //     console.log('split lenderaddress : ', data);
      //     if (data.door_number == undefined || data.street == undefined ||
      //       data.state_code == undefined || data.city_name == undefined || data.zip == undefined) {
      //       this.openModal();
      //       this.previousMailingAddress = '';
      //     }
      //     else {
      //       const entity = {
      //         'fullAddress': address.formatted_address,
      //         'address': data.door_number + ' ' + data.street + ' ' + data.street_type,
      //         'state':data.state_code,
      //         'city': data.city_name,
      //         'zip':data.zip
      //       };
      //       console.log('addres object : ', entity);
      //       this.previousMailingAddressObject = entity;
      //     }
      //     this.loader = false;
      //   }
      // ).catch( error => console.log('error', error));
    }

  }

  openModal() {
    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: 'The address you entered could not be validated'
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: 'The address you entered could not be validated. Please enter a full address.',
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion2.emit(etty);
  }

  next() {
    this.loader = true;
    if (this.homePurchaseMonth.length==1) {
      this.homePurchaseMonth = '0' + this.homePurchaseMonth;
    }
    this.homePurchaseDate = this.homePurchaseMonth + '/' + this.homePurchaseYear;

    localStorage.setItem('sw_PolicyStartDate', this.policyStartDate);
    localStorage.setItem('sw_homePurchasetDate', this.homePurchaseDate);
    localStorage.setItem('sw_ReplacementCost', this.replacementCost);
    localStorage.setItem('sw_ExistingPolicy', this.existingPolicy);
    localStorage.setItem('sw_ExistingPolicyNumber', this.existingPolicyNumber);
    localStorage.setItem('sw_ExistingPolicyType', this.existingPolicyType);
    localStorage.setItem('sw_previousMailingAddress', this.previousMailingAddress);
    localStorage.setItem('sw_previousMailingAddressObject', JSON.stringify(this.previousMailingAddressObject));

    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };
    json.stillwater[this.questions.sub_questions.question_1.answer_key] = localStorage.getItem(this.questions.sub_questions.question_1.answer_key);
    json.stillwater[this.questions.sub_questions.question_2.answer_key] = localStorage.getItem(this.questions.sub_questions.question_2.answer_key);
    json.stillwater[this.questions.sub_questions.question_3.answer_key] = localStorage.getItem(this.questions.sub_questions.question_3.answer_key);
    json.stillwater[this.questions.sub_questions.question_5.answer_key] = this.previousMailingAddressObject;
    json.stillwater[this.questions.sub_questions.question_4.radio_options[0].answer_key] = localStorage.getItem(this.questions.sub_questions.question_4.radio_options[0].answer_key);
    json.stillwater[this.questions.sub_questions.question_4.sub_questions.question_1.answer_key] = localStorage.getItem(this.questions.sub_questions.question_4.sub_questions.question_1.answer_key);
    json.stillwater[this.questions.sub_questions.question_4.sub_questions.question_2.answer_key] = localStorage.getItem(this.questions.sub_questions.question_4.sub_questions.question_2.answer_key);
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(data => {
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0
      };
      this.nextquestion2.emit(etty);
    }).catch(error => {
      console.log(error);
    });
    this.$gaService.event('Next', 'event', 'HomeAppResidenceInfo');
  }
public policyType(input1,input2){
  debugger;
  document.getElementById('driverLicenseIssuedState').innerHTML=input2;
  this.existingPolicyType=input1;
}

}
