import { IAnswerReturn } from '../interfaces/answer.return.interface';

class BaseAnswerReturnModel implements IAnswerReturn {
    questionId: number;
    answerOptionId: number | string | undefined | null;
    answerValue: number | string | boolean | undefined | null;

    constructor(questionId: number) {
        this.questionId = questionId;
    }
}

export class AnswerReturnModel extends BaseAnswerReturnModel { 
    constructor(questionId: number) {
        super(questionId);
    }
}