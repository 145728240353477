import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    //document.getElementById("disclaimer").style.display = "none";
  }

  opendisc(){
    document.getElementById("disclaimer").style.display="block";
    document.getElementById('makeblur').style.filter = "blur(10px)";
  }

}
