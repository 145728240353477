import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-sw4pt10',
  templateUrl: './sw4pt10.component.html',
  styleUrls: ['./sw4pt10.component.css']
})
export class Sw4pt10Component implements OnInit {
  @Output() nextquestion5 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  existingData: any;
  loader: boolean;

  constructor(private $gaService: GoogleAnalyticsService, private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    this.loader = true;
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt10')[0];
    debugger;
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    if (commonInput.state != 'NJ') {
      this.questions.radio_options = this.questions.radio_options.filter(x => x.answer_key != 'sw_ocean');
    }
    console.log('sw questions app-sw4pt10 : ', this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.stillwater;
      console.log('data from getAppData sw : ', this.existingData);
      const selected = [];
      for (let i = 0; i < this.questions.radio_options.length - 1; i++) {

        if (this.existingData[this.questions.radio_options[i].answer_key] == null || this.existingData[this.questions.radio_options[i].answer_key] == undefined || this.existingData[this.questions.radio_options[i].answer_key] == '') {
          this.questions.radio_options[i]['value'] = this.questions.radio_options[i]['inactive_ans'];
          this.questions.radio_options[i]['selected'] = false;
        }
        else if (this.existingData[this.questions.radio_options[i].answer_key] == this.questions.radio_options[i]['inactive_ans']) {
          this.questions.radio_options[i]['selected'] = false;
          this.questions.radio_options[i]['value'] = this.questions.radio_options[i]['inactive_ans'];
        }
        else {
          this.questions.radio_options[i]['value'] = this.questions.radio_options[i]['active_ans'];
          this.questions.radio_options[i]['selected'] = true;
          selected.push(this.questions.radio_options[i]);
        }

      }

      if (selected.length == 0) {
        this.questions.radio_options[this.questions.radio_options.length - 1]['value'] = this.questions.radio_options[this.questions.radio_options.length - 1]['active_ans'];
        this.questions.radio_options[this.questions.radio_options.length - 1]['selected'] = true;
      }
      else {
        this.questions.radio_options[this.questions.radio_options.length - 1]['value'] = this.questions.radio_options[this.questions.radio_options.length - 1]['inactive_ans'];
        this.questions.radio_options[this.questions.radio_options.length - 1]['selected'] = false;
      }
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });
  }

  cardClick(details) {
    if (details['value'] == details['inactive_ans']) {
      details['value'] = details['active_ans'];
      details['selected'] = true;
    }
    else {
      details['value'] = details['inactive_ans'];
      details['selected'] = false;
    }

    if (details.answer_key == 'sw_underwritingNone' && details['value'] == details['active_ans']) {
      for (let i = 0; i < this.questions.radio_options.length; i++) {
        this.questions.radio_options[i]['value'] = this.questions.radio_options[i]['inactive_ans'];
        this.questions.radio_options[i]['selected'] = false;
      }
      this.questions.radio_options[this.questions.radio_options.length - 1]['value'] = this.questions.radio_options[this.questions.radio_options.length - 1]['active_ans'];
      this.questions.radio_options[this.questions.radio_options.length - 1]['selected'] = true;
    }
    else {
      this.questions.radio_options[this.questions.radio_options.length - 1]['value'] = this.questions.radio_options[this.questions.radio_options.length - 1]['inactive_ans'];
      this.questions.radio_options[this.questions.radio_options.length - 1]['selected'] = false;
    }

    const selected = [];
    for (let i = 0; i < this.questions.radio_options.length; i++) {
      if (this.questions.radio_options[i]['value'] == this.questions.radio_options[i]['active_ans']) {
        selected.push(this.questions.radio_options[i]);
      }
    }

    if (selected.length == 0) {
      this.questions.radio_options[this.questions.radio_options.length - 1]['value'] = this.questions.radio_options[this.questions.radio_options.length - 1]['active_ans'];
      this.questions.radio_options[this.questions.radio_options.length - 1]['selected'] = true;
    }
  }

  next() {
    debugger;
    this.loader = true;
    this.$gaService.gtag('event', 'HoIUnderwriting', {
      'event_category': 'ButtonClicked', 'event_label': 'HomeAppUnderwriting'
    });

    this.$gaService.event('Next', 'event', 'HomeAppUnderwriting');

    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };
    // code to be uncomented after library is published
    for (let i = 0; i < this.questions.radio_options.length - 1; i++) {
      json.stillwater[this.questions.radio_options[i].answer_key] = this.questions.radio_options[i]['value'];
    }
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(data => {
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0,
        'knockout': 1
      };
      this.nextquestion5.emit(etty);
    }).catch(error => {
      console.log(error);
    });
  }

  previous() {
    this.loader = true;
    if (localStorage.getItem('RemoveKOData') == '1') {
      const json = {
        'id': this.clientId,
        stillwater: this.existingData
      };
      this.pc.saveUpdateClientData(json).then(data => {
        this.loader = false;
        const etty = {
          'next': 0,
          'previous': 1
        };
        this.nextquestion5.emit(etty);
      }).catch(error => {
        console.log(error);
      });
    }
    else {
      this.loader = false;
      const etty = {
        'next': 0,
        'previous': 1,
      };
      this.nextquestion5.emit(etty);
    }
  }

}
