import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { ProcessCacheService } from '../../shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';

@Component( {
  selector: 'app-cost-predictor-v2',
  templateUrl: './cost-predictor-v2.component.html',
  styleUrls: ['./cost-predictor-v2.component.css']
} )
export class CostPredictorV2Component implements OnInit {

  @Output() checkedLife = new EventEmitter();
  @Input() inputAddress;
  @Input() uniqueId;
  @Input() selectLife;

  tipsandInsights: any;
  predictedScoreData: any;
  homeownercost: any;
  insurancecost: any;
  // uniqueId: any;
  clientId: any;
  displayAddress: any;
  homeScore: number | string = 0;
  mortgage: any;
  homeInsurance: any;
  lifeInsurance: any;
  taxes: any;
  insurancetype: any;
  lifeScoreData: any;
  county = 'Santa Clara County';// Denver County";
  stateCode = 'CA';// CO";
  gender = '1';
  age = '25-35';
  navigationToHomeScore: any;
  //selectLife: any;
  private _data: any;
  navigationToLifeScore: any;
  constructor( private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private scoreApi: ScorecardApiService ) {
  }

  storedPosts = []

  onPostAdded( event ) {
    // //debugger
    this.storedPosts.push( event );
    // //debugger
  }

  getHomeScore = async( zip ) =>{
    const response = await fetch( `${this.pc.envCred.api2Domain}?data_category=HomeScore&version=v1&dget1=${zip}` );
    try{
      let { output } = await response.json();
      //    let output: any = localStorage.getItem('ans');
      output = JSON.parse( output );
      this.homeScore = +this.scoreApi.getDecryptedJsonData( output )['Home Score Percentile'];
      this.homeScore = +this.homeScore.toFixed( 2 );
    } catch( e ) {
      console.log( e.toString() );
    }

  }

  goToHomeScore( event: Event ) {
    event.preventDefault();
    localStorage.setItem( 'uniqueId', this.uniqueId );
    window.open( this.navigationToHomeScore );
  }


  ngOnInit(): void {
    //debugger;
    //  lifescore/:uniqueId/:clientId/:address/:zipcode/:insurancetype
    this.activatedroute.params.subscribe( params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      console.log( 'uniqueId : ', this.uniqueId );
      console.log( 'clientId : ', this.clientId );
      console.log( 'displayAddress : ', this.displayAddress );

      //this.selectLife = Number(this.sLife);

      // this.processCache.getquickquotes(this.uniqueId);
      // let inputJson = {
      //   "lastName": "shetty",
      //   "firstName": "sanath",
      //   "middleName": "",
      //   "addressType": "PhysicalRisk",
      //   "address": "4529 winona ct",
      //   "city": "Denver",
      //   "state": "Colorado",
      //   "zipcode": "80212"
      // };
      // this.processCache.getQuickQuotes(this.uniqueId,"stillWater");
      // //this.processCache.invokeStillWater(this.uniqueId, "home");




    } );
    this.selectLife = 0;
    this.pc.clientId = this.clientId;

    this.scoreApi.castLifescore.subscribe(
      ( data: any ) => {
        this.lifeScoreData = data;
        console.log( 'lifeScoreData', this.lifeScoreData );
      } );
    // this.displayAddress = "27-Ludina-Way-Redwood-City-CA-94061";
    // this.inputAddress = "4529-Winona-Ct-Denver-CO-80212";
    this.pc.parseAddressLocalAndPatch( this.inputAddress, this.clientId ).then(
      data => {
        this.county = data.county;// +" County";
        this.stateCode = data.state_code;

        console.log( 'this.county', this.county );
        this.scoreApi.getLifeScoreWithGenderAge( this.county, this.stateCode, this.gender, this.age );
        const { zip } = data;
        this.getHomeScore( zip );
      }
    ).catch( error => console.log( 'error', error ) );

    this.inputAddress = this.inputAddress.split( ' ' ).join( '-' );

    this.pc.initCaching( this.inputAddress, 'SWQuoteFlow' ); //LifeScore

    this.navigationToLifeScore = `lifescoreV/${this.uniqueId}/${this.clientId}/${this.inputAddress}/${this.insurancetype}`;
    this.navigationToHomeScore = `/homescore?address=${ this.inputAddress || ''}`;

    this.pc.castpredictedScores.subscribe(
      ( data: any ) => {
        this.predictedScoreData = data;
        console.log( 'values :', this.predictedScoreData );
        this.insurancecost = Number( this.predictedScoreData.homeInsuranceAmount ) + Number( this.predictedScoreData.lifeInsuranceAmount );
        this.mortgage = Number( this.predictedScoreData.mortgageAmount );
        this.homeInsurance = Number( this.predictedScoreData.homeInsuranceAmount );
        if ( this.homeInsurance == '0' ) {
          this.homeInsurance = 'NaN';
        }
        this.lifeInsurance = Number( this.predictedScoreData.lifeInsuranceAmount );
        this.taxes = /* Number(this.predictedScoreData.utilityAmount) +  */Number( this.predictedScoreData.propertyTaxAmount );

        this.homeownercost = Number( this.predictedScoreData.mortgageAmount )/* +Number(this.predictedScoreData.utilityAmount) */ + Number( this.predictedScoreData.propertyTaxAmount ) + Number( this.predictedScoreData.homeInsuranceAmount ) + Number( this.predictedScoreData.lifeInsuranceAmount );

        this.homeownercost = this.homeownercost.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
        this.insurancecost = this.insurancecost.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
        this.mortgage = this.mortgage.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );

        this.homeInsurance = this.homeInsurance.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
        this.lifeInsurance = this.lifeInsurance.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );
        this.taxes = this.taxes.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' );

        localStorage.setItem( 'dwellingResult', this.predictedScoreData.dwellingResult );
      },
      ( error ) => console.log( error )
    );
    setTimeout( () => {
      this.pc.getCachedData2();
      setTimeout( () => {
        console.log( 'Called after 2 seconds' );
        this.pc.getCachedData2();
      }, 2000 );
    }, 2000 );
    setTimeout( () => {
      console.log( 'Invoking dwellingResult' );
      this.pc.getCostFromCache( 'dwellingResult' );
    }, 5000 );
    this.tipsandInsights = {
      tip: 'That\'s a great place to live!',
      insightTitle: 'Location Matters',
      insightText: 'Inyo has a quality of life score that\'s better than 90% of neighbouring counties in California.'
    };
  }

  onCheck( even ) {
    debugger;
    this.selectLife = even;
    if ( even == true ) {
      const etty = {
        'type': 'homeLife'
      };
      this.checkedLife.emit( etty );
      localStorage.setItem( 'insuranceType', 'homeLife' );
      this.pc.callComponentMethod();
    }
    else {
      const etty = {
        'type': 'home'
      };
      this.checkedLife.emit( etty );
      localStorage.setItem( 'insuranceType', 'home' );
      this.pc.callComponentMethod();
    }

  }

}
