import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { TrackerService } from 'src/app/shared/tracker.service';
import { getHubSpotInterestType } from 'src/app/shared/UtilityFunctions.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

@Component({
  selector: 'app-paclife-application-complete',
  templateUrl: './paclife-application-complete.component.html',
  styleUrls: ['./paclife-application-complete.component.css']
})
export class PaclifeApplicationCompleteComponent implements OnInit {

  constructor(
    public router: Router,
    private utilityService:UtilityFunctionsService,
    private pc: ProcessCacheService,
    private tracker: TrackerService,
    private $gaService: GoogleAnalyticsService) { }
  ApplicationID: any;
  policyNo: any;
  disAllowTrack: boolean;
  ngOnInit(): void {
    debugger;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_14_TicketSubmitted',
      application_stage:'Ticket submitted'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.tracker.changeProgressStatus({ totalPages: 44, currentPage: 45 });

    this.disAllowTrack = false;
    this.ApplicationID = localStorage.getItem('bubble-ApplicationID');
    this.policyNo = localStorage.getItem('policyNo');
    if (this.policyNo == 'undefined' || this.policyNo == null) {
      this.disAllowTrack = true;
      this.policyNo = 'Sorry could not fetch Policy Number!';
    } else {
      this.policyNo = localStorage.getItem('policyNo');
      const updateJson = {
        appCompletedStatus: true
      };
      this.checkforResumeInit(updateJson, 'update');

      const inputJson={
        'id': localStorage.getItem('clientID'),
        'pacLife_PolicyNumber': this.policyNo
      };

      this.pc.saveUpdateClientData(inputJson);


    }


  }

  gotoAppStatus() {

    /////// id mapping
    const clientIdLocal = localStorage.getItem('clientID');

    const customerIdLocal = localStorage.getItem('customer_id');

    const emailLocal = localStorage.getItem('bubble-email');
    const idMappingJson = {
      client_id: clientIdLocal,
      customer_id: customerIdLocal,
      email_id: emailLocal,
    };

    this.pc.createIdMapping(idMappingJson);

    const hubSpotData = {
      customer_id: customerIdLocal,
      client_id: clientIdLocal,
      email: emailLocal,
      life_policy_number: localStorage.getItem('policyNo'),
      application_stage: 'Payment',

    };
    this.$gaService.gtag('event', 'LifeApplication', {
      'event_category': 'life_policy_number', 'event_label': localStorage.getItem('policyNo')
    });

    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    this.router.navigate(['appstatus']);

    this.$gaService.event('Track Application', 'event', 'LifeAppPolicyTrack');
    const eventOptions: EventOption = {
      page_title: 'Checkout - Track Application',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Next', 'event','LifeAppPolicyTrack');


  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }

}
