import { Component, EventEmitter, OnInit, Output, ViewChild, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';

@Component({
  selector: 'app-address-input-zip',
  templateUrl: './address-input-zip.component.html',
  styleUrls: ['./address-input-zip.component.css']
})

export class AddressInputZipComponent implements OnInit, OnChanges {

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options = '{types: [\'address\', \'place\'], componentRestrictions: { country: \'USA\'}}';

  @Output() addressSelected = new EventEmitter();
  @Input() defaultZip;
  @Input() label;
  @Input() todisable;

  zip: string;

  constructor(private scoreApi: ScorecardApiService, private pc: ProcessCacheService) { }

  ngOnInit(): void {
    this.zip = this.defaultZip;
    console.log('AddressInputComponent', this.zip);
  }

  public handleAddressChange(address: Address) {

    this.addressSelected.emit(address);
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('AddressInputComponent changes: ', changes);
    for (const property in changes) {
      if (property === 'defaultAddress') {
        this.zip = changes[property].currentValue;
        console.log('AddressInputComponent new address: ', this.zip);
      }
    }
  }

}