import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-has-heart-disease',
  templateUrl: './has-heart-disease.component.html',
  styleUrls: ['./has-heart-disease.component.css']
})
export class HasHeartDiseaseComponent implements OnInit {
  @Output() nextquestion8 = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }



  SelectedOption:any;
  public GetOptionsyesno(data){
   
    this.SelectedOption=data;
    if(this.SelectedOption==0){
      document.getElementById('HeartDiseaseNo').style.border='solid 2px orange';
      document.getElementById('HeartDiseaseyes').style.border='solid 1px #dae7ec';
     
    }else{
     document.getElementById('HeartDiseaseyes').style.border='solid 2px orange';
     document.getElementById('HeartDiseaseNo').style.border='solid 1px #dae7ec';
    }
  }




  next() {
    let etty = {
      "questionNo": "8",
      "nextprev": 1
    }
    this.nextquestion8.emit(etty);
  }
}
