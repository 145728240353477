import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {
  url: any;
  @Input() loaderText = '';
  @Input() whiteLoader = false;
  @Input () showImage = false;

  constructor(private router:Router) { }

  ngOnInit(): void {
    this.url = this.router.url.split('?');
    console.log('url',this.url);

  }

}
