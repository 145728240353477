import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-has-tissue-disease',
  templateUrl: './has-tissue-disease.component.html',
  styleUrls: ['./has-tissue-disease.component.css']
})
export class HasTissueDiseaseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
