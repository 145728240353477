import { Component, OnInit, isDevMode } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { ProcessCacheService } from '../shared/process-cache.service';
import { ScorecardApiService } from '../shared/scorecard-api.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from './../components/lifescore-error-alert/lifescore-error-alert.component';
import { ToastService } from '../shared/toast.service';
import { ImageCacheService } from '../shared/image-cache.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ActivatedRoute } from '@angular/router';
import { formatAmount } from '../shared/UtilityFunctions.js';
import { Router } from '@angular/router';
import * as pc from 'bit-uibl-data-driver';

declare let google: any;
declare let require: any;
const criticalInput = require('./../../../critical-input.json');

@Component({
  selector: 'app-lifescore',
  templateUrl: './lifescore.component.html',
  styleUrls: ['./lifescore.component.css'],
  host: {
    '(window:resize)': 'onWindowResize($event)'
  }
})
export class LifescoreComponent implements OnInit {
  disableAddress = false;
  /* progressBar = {
    indicator: "",
    name: '',
    percentage: {
      outputPercentage: "0",
      barType: "danger"
    },
    image_path: '',
    type: "negative"
  }; */
  utmSource: any;
  utmMedium: any;
  utmCampaign: any;
  utmTerm: any;
  utmContent: any;
  insurancetype: any;
  width: number = window.innerWidth;
  height: number = window.innerHeight;
  addresschangecount: any = 0;
  positiveScores = [
    {
      name: '',
      indicator: 'Pending',
      extras: {
        percentage: {
          outputPercentage: '0',
          barType: 'danger'
        },
        image_path: '',
        type: ''
      }
    }
  ];
  negativeScores = this.positiveScores;
  addresstype = 'Home Address or Location';
  eventIdStrPrefix = 'LifeScore';
  county = 'Santa Clara County';// Denver County";
  stateCode = 'CA';// CO";
  stateLongName = 'California';// Colorado";
  gender = '1';
  age = '25-35';
  fullAddress: any;
  displayAddress = '285 Bryant St Palo Alto CA 94301';// 4529 Winona Ct, Denver, CO 80212, USA";
  cityStateCode = 'Palo Alto, CA';// Denver, CO";
  cityLongName = 'Palo Alto';// Denver";
  latitude = 37.4419; // 39.7789467;
  longitude = -122.1430; // -105.0477277;
  reqheight = 'height:50px;';
  placeId = 'ChIJaaFLW5qHa4cRzr3YClNc7xM';
  placeImages = [];
  googleImageInput = {
    id: '174a01046221b2351751388b156130-4529-Winona-ct-Denver-CO-80212',
    city: 'Palo Alto',
    stateCode: 'CA',
    zip: '94301'
  };
  zip = '94301';
  circleText = 'A+';
  circlePercentage = 'progress-90';
  circleTextClass = '';
  ringColor = '00a12f';
  photoReference: string;
  documentPositionStyle: string;
  lifeScoreData: any;
  lifeInsuranceAmt: any;
  sectionData = {
    life_in_paradise: [],
    key_highlights: []
  };

  lgLeaflet = true;
  mdLeaflet = false;
  smLeaflet = false;

  /* foodEnvironmentIndex = this.progressBar;
  medianIncome = this.progressBar;
  familyUnity = this.progressBar;
  accessToExerciseOpportunities = this.progressBar;
  informalCivicActivity = this.progressBar;
  violentCrimeIndicator = this.progressBar;
  injuryDeathIndicator = this.progressBar;
  airPollutionIndicator = this.progressBar;
  mentalDistress = this.progressBar;
  adultObesity = this.progressBar; */

  maleIcon = 'assets/images/active/icon-male-active.svg';
  femaleIcon = 'assets/images/inactive/icon-female-inactive.svg';
  maleIconactive = true;
  femaleIconactive = false;
  clientId: any;
  uniqueId: string;
  address: string;
  iframeSrc = 'assets/bubble_states.html?state=CO&stateLongName=Colorado&county=Denver&latitude=39.7789467&longitude=-105.0477277';

  urlSafe: SafeResourceUrl;

  divShowHide = {
    div1: false,
    div2: false,
    div3: true,
    circularScore: false,
    scoreQuoteText: false,
    keyPoints: false,
    positiveScore: false,
    negativeScore: false,
    placePhotos: true,
    keyHighlights: true,
    customizebutton: true,
    customizebuttonV2: true
  };

  loader = true;

  landinText = {
    pageOne: {
      bgColor: '#jhbdfy',
      text: 'text here'
    },
    pageTwo: {
      bgColor: '#jhbdfy',
      text: 'text here'
    },
    pageThree: {
      bgColor: '#jhbdfy',
      text: 'text here'
    }
  };

  lifescoreBlob = '';

  // showmap: any = "map";
  showmap: any = 'map';

  requestCallBack = false;
  street: any;
  scity: any;
  sstate: any;
  szip: any;
  scountry: any;
  addressToSend: string;
  lifescoreVurl: any;
  displayAddressFromParams: any;
  httpParams: HttpParams;
  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    public sanitizer: DomSanitizer,
    private modalService: NgbModal,
    public toastService: ToastService,
    private imageService: ImageCacheService,
    private $gaService: GoogleAnalyticsService,
    private activatedroute: ActivatedRoute,
    private router: Router
  ) { }

  addSeoData() {
    const head = document.querySelector('head');
    const fragment = new DocumentFragment();

    const metaData = [{
      name: 'robots',
      content: 'index'
    },
    {
      name: 'robots',
      content: 'follow'
    },
    {
      name: 'keywords',
      content: 'life insurance score, credit check for life insurance, life insurance credit score, life insurance risk score'
    },
    {
      name: 'title',
      content: 'Life Insurance Score- Credit Check for Life Insurance Online'
    },
    {
      name: 'og:title',
      content: 'Life Insurance Score- Credit Check for Life Insurance Online'
    },
    {
      name: 'og:description',
      content: 'Bubble LifeScore ™ reflects life expectancy, mortality &amp; overall quality of health in the USA specific to location, age groups and gender. A higher Bubble life insurance score implies higher life expectancy, lower mortality & better prospects for health and life.'
    },
    {
      name: 'description',
      content: 'Bubble LifeScore ™ reflects life expectancy, mortality &amp; overall quality of health in the USA specific to location, age groups and gender. A higher Bubble life insurance score implies higher life expectancy, lower mortality & better prospects for health and life.'
    }
    ];

    const desc = document.querySelector('meta[name=description]');
    desc?.remove();

    metaData.forEach(({ name, content }) => {
      const meta = document.createElement('meta');
      meta.name = name;
      meta.content = content;
      fragment.appendChild(meta);
    });

    document.title = 'Life Insurance Score- Credit Check for Life Insurance Online';

    const link = document.createElement('link');
    link.rel = 'canonical';
    link.href = 'https://app.getmybubble.com/lifescore';

    fragment.appendChild(link);

    head.appendChild(fragment);
  }

  removeAllParams() {
    const currURL = window.location.href;
    const url = (currURL.split(window.location.host)[1]).split('?')[0];
    window.history.replaceState({}, document.title, url);
  }

  ngOnInit(): void {

    this.$gaService.event('pageLoad', 'page', 'lifescore_page');
    this.addSeoData();
    setTimeout(this.removeAllParams, 5000);
    // const AWS = require("aws-sdk");
    // const pcLocal = require('bit-uibl-data-driver');
    // const CriticalInput = pcLocal.CriticalInput;
    // const criticalInputLocal = require("./../../../critical-input.json");
    // AWS.config.credentials = new AWS.Credentials(CriticalInput.accessKeyId, CriticalInput.secretAccessKey);




    // AWS.config.credentials = new AWS.Credentials(criticalInputLocal.accessKeyId, criticalInputLocal.secretAccessKey);


    this.activatedroute.params.subscribe(params => {
      //utm_source=HomePage, utm_medium=direct,utm_campaign=no_campaign, utm_term=no_term
      //:utm_source/:utm_medium/:utm_campaign/:utm_term
      //HomePage/direct/no_campaign/no_term
      this.pc.utmSource = params['utm_source'];
      this.pc.utmMedium = params['utm_medium'];
      this.pc.utmCampaign = params['utm_campaign'];
      this.pc.utmTerm = params['utm_term'];
      this.pc.utmContent = params['utm_content'];
    }
    );

    if (localStorage.getItem('bubblelead_name') !== null) {
      this.requestCallBack = false;
    } else {
      this.requestCallBack = true;
    }

    const url = window.location.href;
    let paramValue;
    let paramValueV2;
    // //
    if (url.includes('?')) {
      this.httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = this.httpParams.get('customize');
      paramValueV2 = this.httpParams.get('customizeV2');
      this.pc.utmSource = this.httpParams.get('utm_source');
      this.pc.utmMedium = this.httpParams.get('utm_medium');
      this.pc.utmCampaign = this.httpParams.get('utm_campaign');
      this.pc.utmTerm = this.httpParams.get('utm_term');
      this.pc.utmContent = this.httpParams.get('utm_content');

      this.displayAddressFromParams = this.httpParams.get('address');

      //TODO Invoke method to collect
      this.divShowHide.customizebutton = paramValue;
      this.divShowHide.customizebuttonV2 = paramValueV2;
    }
    //this.divShowHide.customizebutton = false;
    document.getElementById('myModal').style.display = 'none';
    console.log('show urls');
    //this.lifescoreVurl = this.router.url.includes('lifescoreV') && this.router.url.includes('address') && this.router.url.includes('age') && this.router.url.includes('gender');
    this.lifescoreVurl = this.router.url.includes('lifescoreV');
    if (this.lifescoreVurl != true) {
      this.lifescoreVurl = this.router.url.includes('address') || this.router.url.includes('age') || this.router.url.includes('gender');
    }
    if (this.lifescoreVurl != true) {
      this.getAddressFromLatLng();
    } else {
      if (!this.displayAddressFromParams) {
        this.displayAddressFromParams = this.activatedroute.snapshot.paramMap.get('address');
      }
      const add = this.displayAddressFromParams.replace('-', '+');
      this.pc.getGoogleAddressDetails(add).then(
        res => {

          const addressDetails = res.data.results[0];
          this.handleAddressChange(addressDetails);

        }).catch(error => {

        });
    }

    if (this.divShowHide.positiveScore == false && this.divShowHide.negativeScore == false) {
      this.scoreApi.castLifescore.subscribe(
        (data: any) => {
          this.lifeScoreData = data;
          this.lifeInsuranceAmt = data.quoteAmount;
          if (this.lifeScoreData.scores) {
            this.positiveScores = this.lifeScoreData.scores.filter((item) => item.extras.type == 'positive' && criticalInput.LIFESCORE_CARDS_POSITIVE.includes(item.name));
            this.negativeScores = this.lifeScoreData.scores.filter((item) => item.extras.type == 'negative' && criticalInput.LIFESCORE_CARDS_NEGATIVE.includes(item.name));
          }


          /* this.medianIncome = data.medianIncome ? data.medianIncome : this.medianIncome;
          this.foodEnvironmentIndex = data.foodEnvironmentIndex ? data.foodEnvironmentIndex : this.foodEnvironmentIndex;
          this.familyUnity = data.familyUnity ? data.familyUnity : this.familyUnity;
          this.accessToExerciseOpportunities = data.accessToExerciseOpportunities ? data.accessToExerciseOpportunities : this.accessToExerciseOpportunities;
          this.informalCivicActivity = data.informalCivicActivity ? data.informalCivicActivity : this.informalCivicActivity;

          this.violentCrimeIndicator = data.violentCrimeIndicator ? data.violentCrimeIndicator : this.violentCrimeIndicator;
          this.injuryDeathIndicator = data.injuryDeathIndicator ? data.injuryDeathIndicator : this.injuryDeathIndicator;
          this.airPollutionIndicator = data.airPollutionIndicator ? data.airPollutionIndicator : this.airPollutionIndicator;
          this.mentalDistress = data.mentalDistress ? data.mentalDistress : this.mentalDistress;
          this.adultObesity = data.adultObesity ? data.adultObesity : this.adultObesity; */




          /* let blob = this.imageService.getImageBlob("25", "black", "100");
          this.lifescoreBlob = blob.src; */

          if (this.lifeScoreData.showScore) {
            this.loader = false;
          }
          if (this.lifeScoreData.success && this.lifeScoreData.showScore) {
            //this.toastService.show('LifeScore successfully updated!');
          }
          if ((this.scountry != 'US')) {
            if (!this.lifeScoreData.success && this.lifeScoreData.showScore) {
              this.openModal(this.lifeScoreData.alertPopup);
            }
          }

          if (!this.lifeScoreData.success) {
            this.openModal('ZERO_VALUE');
          }

          this.handleCircularBar(this.lifeScoreData.lifeScore);
        },

      );
    }

    this.pc.castPhotoReference.subscribe(
      (data: any) => {
        this.photoReference = data;

      },

    );

    if (this.divShowHide.scoreQuoteText == false) {
      this.scoreApi.castSectionData.subscribe(
        (data: any) => {
          if (data.life_in_paradise) {
            data.life_in_paradise.pop();
            this.sectionData.life_in_paradise = data.life_in_paradise;
          } else if (data.key_highlights) {
            this.sectionData.key_highlights = data.key_highlights;
          }

        },

      );
    }

    // this.pageLoadInvoke();
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);

    // For testing
    // setTimeout(()=>{
    //   this.iframeSrc ="assets/bubble_states.html?state=US&county=ABCDE";
    //   this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);

    // }, 10000);
    this.leafletDisplay();
    console.log('show leaflet');
    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {

        const clientDataFiltered = JSON.parse(data.Payload);
        if (clientDataFiltered.body.bubble_email != undefined || clientDataFiltered.body.bubble_email != null) {
          this.requestCallBack = true;
        } else {
          this.requestCallBack = false;
        }

      }).catch(error => {

      });
    this.gender = this.httpParams.get('gender');
    if (this.httpParams.get('age') != null) {
      this.ageClick(Number(this.httpParams.get('age')));
    }
    if (this.httpParams.get('gender') != null) {

      this.genderClick(Number(this.httpParams.get('gender')));
    }
    localStorage.setItem('bubble-gender', this.gender);
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.height = event.target.innerHeight;
    // this.isMobile = this.width < this.mobileWidth;

    this.leafletDisplay();
  }

  hideRequestCallback() {
    this.requestCallBack = false;

    //  document.body.style.position = '';
  }

  leafletDisplay() {

    if (this.width <= 576) {
      // Small Screem : sm
      this.lgLeaflet = false;
      this.mdLeaflet = false;
      this.smLeaflet = true;
    } else if (this.width <= 768) {
      // Medium Screen : md
      this.lgLeaflet = false;
      this.mdLeaflet = true;
      this.smLeaflet = false;
    } else {
      // large Screen : lg
      this.lgLeaflet = true;
      this.mdLeaflet = false;
      this.smLeaflet = false;
    }
  }

  checkage() {
    if (this.age < '20') {

    }
    else {

    }
  }

  onGenderAgeChange() {

    this.pc.gender = this.gender;
    let invokeAPIFlag = false;

    if (this.age.toString().length > 1 && this.age.indexOf('-') != -1) {
      invokeAPIFlag = true;
    } else if (this.age.toString().length > 1 && Number(this.age) >= 20 && Number(this.age) <= 75) {
      invokeAPIFlag = true;
    } else if ((this.age.toString().length > 1) && (Number(this.age) <= 20 || Number(this.age) >= 75)) {
      this.age = '26';
    }
    /* if(this.fullAddress) {
      this.pc.initCaching(this.fullAddress.formatted_address, this.eventIdStrPrefix);
    } */

    //this.scoreApi.newLifeScoreAPI(this.gender, this.age, this.zip);
    this.scoreApi.getScoreFromCounty({ gender: this.gender.toString(), ageRange: this.age.toString(), county: this.county, state: this.stateLongName });

    if (invokeAPIFlag) {
      this.pc.age = this.age;
      this.pc.invokeProcess(this.eventIdStrPrefix);
      if (this.zip == '' && this.street == '') {
        this.pc.invokerecalculateLifeInsurance(500000, this.stateCode, this.age, this.gender);
      }
      // this.scoreApi.getLifeScoreWithGenderAge(this.county, this.stateCode, this.gender, this.age);
    }
    setTimeout(() => {
      this.pc.getCostFromCache('lifeInsuranceAmount');
    }, 1000);
    setTimeout(() => {
      this.toastService.show('LifeScore successfully updated!');
    }, 1500);
  }

  pageLoadInvoke() {
    this.pc.gender = this.gender;
    this.pc.age = this.age;
    this.pc.lat = this.latitude;
    this.pc.lng = this.longitude;
    this.pc.placeId = this.placeId;
    this.pc.initCaching(this.displayAddress, this.eventIdStrPrefix);
    // this.pc.uniqueId = '174a01046221b2351751388b156130-4529-Winona-ct-Denver-CO-80212';
    this.scoreApi.getScoreFromCounty({ gender: '1', ageRange: '25-35', county: 'Santa Clara County', state: 'California', });
    //this.scoreApi.newLifeScoreAPI('1', '25-35', 90221);
    //this.scoreApi.getLifeScoreWithGenderAge('1', '25-35');
    localStorage.setItem('bubble-street', '285 Bryant St');
    localStorage.setItem('bubble-city', 'Palo Alto');
    this.pc.getCostFromCache('lifeInsuranceAmount');
    setTimeout(() => {
      this.pc.invokeLambda(this.googleImageInput);
      this.getLocationImages();
    }, 1000);

    if (!this.divShowHide.keyPoints) {
      this.scoreApi.getSectionData('life_in_paradise');
    }
    if (!this.divShowHide.keyHighlights) {
      this.scoreApi.getSectionData('key_highlights');
    }
  }

  ageClick(ageInput) {

    switch (ageInput) {
      case 0:
        this.age = '25-35';
        break;
      case 1:
        this.age = '35-45';
        break;
      case 2:
        this.age = '45-55';
        break;
      case 3:
        this.age = '55-65';
        break;
      case 4:
        this.age = '65-75';
        break;
      default:
        this.age = '25-35';
        break;
    }

    this.$gaService.event('ageClick', 'event', `lifescore_page:ageChangedTo:${this.age}`);
    this.onGenderAgeChange();
  }

  genderClick(gender) {

    this.$gaService.event('genderClick', 'event', `lifescore_page:gendarChangedTo:${gender}`);
    if (gender == 1) {
      this.maleIconactive = !this.maleIconactive;
      this.maleIcon = this.maleIconactive ? 'assets/images/active/icon-male-active.svg' : 'assets/images/inactive/icon-male-inactive.svg';
      if (this.maleIconactive) {
        this.gender = '1';
        this.femaleIconactive = false;
        this.femaleIcon = 'assets/images/inactive/icon-female-inactive.svg';
        this.onGenderAgeChange();
      }
    }
    if (gender == 0) {
      this.femaleIconactive = !this.femaleIconactive;
      this.femaleIcon = this.femaleIconactive ? 'assets/images/active/icon-female-active.svg' : 'assets/images/inactive/icon-female-inactive.svg';
      if (this.femaleIconactive) {
        this.gender = '0';
        this.maleIconactive = false;
        this.maleIcon = 'assets/images/inactive/icon-male-inactive.svg';
        this.onGenderAgeChange();
      }
    }
    localStorage.setItem('bubble-gender', this.gender);
  }

  async handleAddressChange(address) {


    if (this.lifescoreVurl == true) {
      this.latitude = address.geometry.location.lat;
      this.longitude = address.geometry.location.lng;
      this.placeId = address.place_id;
    } else {
      this.latitude = address.geometry.location.lat();
      this.longitude = address.geometry.location.lng();
      this.placeId = address.place_id;
    }

    this.pc.lat = this.latitude;
    this.pc.lng = this.longitude;
    this.pc.placeId = this.placeId;
    console.log(address);
    // Extract ZIP, State and City from Address component if possible
    let address_components = address.address_components;
    const { addressJson, google_address: googleAddress } = await pc.BackendService.getBubbleFormattedAddress({ address_components });
    var google_address = googleAddress;

    this.pc.city = google_address.locality_ln;
    this.scity = google_address.locality_ln;
    this.scity = this.scity.replace(/ /g, '__');
    this.pc.stateCode = google_address.administrative_area_level_1_sn;
    this.stateLongName = google_address.administrative_area_level_1_ln;
    this.sstate = google_address.administrative_area_level_1_sn;
    this.pc.zip = google_address.postal_code_sn;
    this.szip = google_address.postal_code_sn;
    this.scountry = google_address.country_sn;

    this.addressToSend = `${this.scity} ${this.sstate} ${this.szip}`;
    this.addressToSend = this.addressToSend.trim();
    this.addressToSend = this.addressToSend.replace(/ {2}/g, ' ');


    this.fullAddress = address;


    this.displayAddress = this.fullAddress.formatted_address;

    this.$gaService.event('addressClick', 'event', `lifescore_page: addressChangedTo: ${this.displayAddress} `);

    if (this.scountry == 'US' || this.scountry == 'USA') {
      this.pc.initCaching(this.fullAddress.formatted_address, this.eventIdStrPrefix);
    } else {
      console.log('Not an USA state');
      this.loadDefaultAddress();
    }
    /*
      {
  "long_name": "4529",  "short_name": "4529",  "types": [  "street_number"  ]
},  {  "long_name": "Winona Court",
  "short_name": "Winona Ct",
  "types": [
    "route"
  ]
},
{
  "long_name": "Northwest",
  "short_name": "Northwest",
  "types": [
    "neighborhood",
    "political"
  ]
},
{
  "long_name": "Denver",
  "short_name": "Denver",
  "types": [
    "locality",
    "political"
  ]
},
{
  "long_name": "Denver County",
  "short_name": "Denver County",
  "types": [
    "administrative_area_level_2",
    "political"
  ]
},
{
  "long_name": "Colorado",
  "short_name": "CO",
  "types": [
    "administrative_area_level_1",
    "political"
  ]
},
{
  "long_name": "United States",
  "short_name": "US",
  "types": [
    "country",
    "political"
  ]
},   {
  "long_name": "80212",
  "short_name": "80212",
  "types": [
    "postal_code"
  ]
},   {
  "long_name": "2512",
  "short_name": "2512",
  "types": [
    "postal_code_suffix"
  ]
}
]
  */

    let street = addressJson.Addr1;
    let county = google_address.administrative_area_level_2_sn;
    let city = google_address.locality_sn;
    let cityLongName = google_address.locality_ln;
    let stateCodeLocal = google_address.administrative_area_level_1_sn;
    this.stateLongName = google_address.administrative_area_level_1_ln;
    let zip = google_address.postal_code_sn;
    let country = google_address.country_sn;

    if (county == '') {
      this.openModal('', 'Address Details not available for searched text');
      return;
    }
    if (country != 'US') {

      this.openModal('INVALID_ADDRESS', '');
      return;
    }

    localStorage.setItem('bubble-zip', zip);
    localStorage.setItem('bubble-street', street);
    localStorage.setItem('bubble-city', cityLongName);
    this.county = county;
    this.zip = zip;

    this.street = street;
    this.stateCode = stateCodeLocal;
    this.googleImageInput.zip = zip;
    this.googleImageInput.stateCode = stateCodeLocal;
    this.googleImageInput.city = city;
    // county = county.replace('County', '').replace(/\s/g, '');
    this.iframeSrc = `assets / bubble_states.html ? state = ${stateCodeLocal}& stateLongName=${this.stateLongName}& county=${county}& latitude=${this.latitude}& longitude=${this.longitude} `;
    this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);

    this.cityStateCode = `${city}, ${stateCodeLocal} `;
    if (!city) this.cityStateCode = `${county}, ${stateCodeLocal} `;
    this.cityLongName = cityLongName;
    // this.scoreApi.newLifeScoreAPI(this.gender.toString(), this.age.toString(), this.zip);

    this.scoreApi.getScoreFromCounty({ gender: this.gender.toString(), ageRange: this.age.toString(), county: this.county, state: this.stateLongName });
    //this.scoreApi.getLifeScoreWithGenderAge(county, stateCodeLocal, this.gender.toString(), this.age.toString());
    // this.foodEnvironmentIndex = '50';

    this.pc.getCostFromCache('lifeInsuranceAmount');


    setTimeout(() => {
      this.pc.invokeLambda(this.googleImageInput);
      this.getLocationImages();
    }, 1000);

    if (!this.divShowHide.keyPoints) {
      this.scoreApi.getSectionData('life_in_paradise');
    }
    if (!this.divShowHide.keyHighlights) {
      this.scoreApi.getSectionData('key_highlights');
    }
    this.addresschangecount = this.addresschangecount + 1;

    if (this.addresschangecount > 1) {
      setTimeout(() => {
        this.toastService.show('LifeScore successfully updated!');
      }, 2000);
    }

    google_address.formatted_address = address.formatted_address;
    google_address.clientId = this.pc.clientId;
    google_address.id = this.pc.clientId;


    this.pc.saveUpdateClientData(google_address);

  }

  getLocationImages() {

    if (!this.divShowHide.placePhotos) {

      let interval;
      let count = 1;
      this.photoReference = '';

      interval = setInterval(() => {
        if (count < 3 && !this.photoReference) {
          this.pc.getPhotosFromCache('place_id');
          this.pc.getPhotosFromCache('separator');
          this.pc.getPhotosFromCache('photos');
          count++;
        } else {
          clearInterval(interval);
          if (this.photoReference) {
            const tmpImages = [];
            this.placeImages = this.photoReference.split('__-__').slice(0, 4);
            for (let i = 0; i < this.placeImages.length; i++) {
              tmpImages[i] = `https://maps.googleapis.com/maps/api/place/photo?maxwidth=127&maxheight=109&key=${environment.googleMapApiKey}&photoreference=${this.placeImages[i]}`;
            }
            this.placeImages = tmpImages;

          }
        }
      }, 1000);
    }

  }

  lifeScoreColor(data) {
    this.ringColor = data;

  }

  handleCircularBar(score) {
    score = +score;

    if (0 < score && score <= 200) {
      this.circlePercentage = 'progress-20';
      this.circleText = 'B';
      this.circleTextClass = 'text-20';
    } else if (200 < score && score <= 400) {
      this.circlePercentage = 'progress-40';
      this.circleText = 'B+';
      this.circleTextClass = 'text-40';
    } else if (400 < score && score <= 600) {
      this.circlePercentage = 'progress-60';
      this.circleText = 'A-';
      this.circleTextClass = 'text-60';
    } else if (600 < score && score <= 900) {
      this.circlePercentage = 'progress-80';
      this.circleText = 'A';
      this.circleTextClass = 'text-80';
    } else if (900 < score) {
      this.circlePercentage = 'progress-100';
      this.circleText = 'A+';
      this.circleTextClass = 'text-100';
    } else {

      this.circlePercentage = 'progress-0';
      this.circleText = '';
      this.circleTextClass = 'text-0';
    }





  }

  navigateTo() {
    //this.pc.initCaching(this.displayAddress, this.eventIdStrPrefix);
    // //
    this.clientId = this.pc.clientId;
    this.uniqueId = this.pc.uniqueId;
    this.address = this.displayAddress;



    location.href = `QuoteFlow/${this.uniqueId}/${this.clientId}/${this.address}/${this.gender}/${this.age}`;
  }

  navigateToV2(insuranceType) {
    //this.pc.initCaching(this.displayAddress, this.eventIdStrPrefix);
    // //
    this.clientId = this.pc.clientId;
    this.uniqueId = this.pc.uniqueId;
    this.address = this.displayAddress;



    location.href = `QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.addressToSend}/${insuranceType}/lifescore`;
  }
  gotoSBLI() {
    location.href = 'Sbliappflow';
  }

  toPrefill() {

    // https://app.getmybubble.co/PrefillResumeFlowLife?firstname=AarunTest&marital_status=Single&has_medical_conditions=No&date_of_birth=1980-05-31&lastname=Rodriguez&active_retired_military_personnel=No&email=Prodlead0131test1@gmbubble.com&desired_life_coverage=100000&full_address=620%2030TH%20ST%20APT%20REAR,%20Lubbock,%20TX,%2079412&tobacco_user=No&alzheimers_or_dementia=No&weight=120&gender=Female&number_of_children=0&interest_type=Life&desired_life_term=20&height=5%272%22&phone=8064735112&heart_disease=No&zip=79412&cancer=No&life_insurance_type=Term%2020%20Years&lead_source=MediaAlpha

    window.location.href = `PrefillResumeFlowLife?interest_type=Life&zip=${this.zip}&full_address=${this.displayAddress}`;
  }

  openHomeScore() {
    window.open(`/homescore?address=${this.displayAddress || ''}`, '__blank');
  }

  getAddressFromLatLng() {

    /* let latitude= 19.21086;
  let longitude= 72.96082;
  latitude = 39.7789467;
  longitude = -105.0477277; */
    if (this.displayAddressFromParams != null) {

      const add = this.displayAddressFromParams.replace('-', '+');
      this.pc.getGoogleAddressDetails(add).then(
        res => {

          const addressDetails = res.data.results[0];
          this.handleAddressChange(addressDetails);

        }).catch(error => {

        });
    }
    else {

      navigator.geolocation.getCurrentPosition(
        (data) => {

          this.latitude = data.coords.latitude;
          this.longitude = data.coords.longitude;

          /** this is static Ip for USA  */
          /* this.latitude = 39.7789467;
        this.longitude = -105.0477277; */
          /** this is static Ip for USA  */

          const geocoder = new google.maps.Geocoder();

          const latlng = new google.maps.LatLng(this.latitude, this.longitude);
          const request = { latLng: latlng };

          geocoder.geocode(request, (results, status) => {

            if (status === google.maps.GeocoderStatus.OK) {

              this.displayAddress = results[0].formatted_address;
              this.handleAddressChange(results[0]);



              /* let result = results[0];
              let rsltAdrComponent = result.address_components;
              let resultLength = rsltAdrComponent.length;
              if (result != null) {
                this.address = rsltAdrComponent[resultLength - 8].short_name;
              } else {
                alert('No address available!');
              } */
            }
          });

        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:

              break;
            case error.POSITION_UNAVAILABLE:

              break;
            case error.TIMEOUT:

              break;
            default:

              break;
          }


          this.loadDefaultAddress();


        }
      );
    }



    /* var marker = new google.maps.Marker({position: {lat:latitude, lng:longitude }});
    console.log("marker: ", marker); */
  }

  openModal(alertType, message = '') {
    debugger;
    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: message
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    if (alertType == 'ZERO_VALUE') {
      message = 'We can\'t give you a Bubble LifeScore for that particular location just yet. Please check back later';
    } else if (alertType == 'INVALID_ADDRESS') {
      message = 'Bubble LifeScore is only available for locations within the United States of America';
    }

    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
        // keyboard: false,
        // backdrop: 'static'
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: message,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      this.loader = false;
      //this.loadDefaultAddress();
      // this.loadDefaultAddress();

    }, (reason) => {

    });
  }

  loadDefaultAddress() {
    this.gender = '1';
    this.age = '25-35';
    this.latitude = 37.4419; //39.7789467;
    this.longitude = -122.1430; // -105.0477277;
    this.placeId = 'ChIJaaFLW5qHa4cRzr3YClNc7xM';
    this.displayAddress = '285 Bryant St Palo Alto CA 94301'; // 4529 Winona Ct, Denver, CO 80212, USA";
    this.stateCode = 'CA';
    this.cityStateCode = 'Palo Alto, CA';
    this.stateLongName = 'California';
    this.county = 'Santa Clara County';
    this.cityLongName = 'Palo Alto';
    this.scity = this.cityLongName;
    this.sstate = this.stateCode;
    this.szip = '94301';
    this.scountry = 'USA';
    this.addressToSend = `${this.scity}-${this.sstate}-${this.szip}-${this.scountry}`;
    this.googleImageInput = {
      id: '174a01046221b2351751388b156130-4529-Winona-ct-Denver-CO-80212',
      city: 'Palo Alto',
      stateCode: 'CA',
      zip: '94301'
    };
    this.pageLoadInvoke();
  }

  callback(divtype) {

    this.showmap = divtype;
    if (this.showmap == 'thankyou') {
      document.getElementById('myModal').style.display = 'block';
      document.getElementById('makeblur').style.filter = 'blur(10px)';
    }
    if (this.showmap == 'map' || this.showmap == 'form') {

      if (window && window.innerWidth < 420)
        document.body.style.position = 'fixed';
      document.getElementById('myModal').style.display = 'none';
      document.getElementById('makeblur').style.filter = 'none';
    }
  }

  bluroffDisclaimer() {
    document.getElementById('disclaimer').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
  }

  bluronhow() {
    document.getElementById('how_its_calulated').style.display = 'block';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
  }

  bluroffhow() {
    document.getElementById('how_its_calulated').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
  }

  notblur(event) {
    document.getElementById('how_its_calulated').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
  }


  googleaddressjson() {
    const results = [];//used only for declaration this would be the address returned by google
    //ref object
    const google_address = {
      'street_number_ln': '',
      'street_number_sn': '',
      'route_ln': '',
      'route_sn': '',
      'neighborhood_ln': '',
      'neighborhood_sn': '',
      'locality_ln': '',
      'locality_sn': '',
      'administrative_area_level_2_ln': '',
      'administrative_area_level_2_sn': '',
      'administrative_area_level_1_ln': '',
      'administrative_area_level_1_sn': '',
      'country_ln': '',
      'country_sn': '',
      'postal_code_ln': '',
      'postal_code_sn': '',
      'postal_code_suffix_ln': '',
      'postal_code_suffix_sn': '',
      'formatted_address': ''
    };

    for (let i = 0; i < results[0].address_components.length; i++) {

      const type = results[0].address_components[i].types[0];
      switch (type) {
        case 'street_number':
          google_address.street_number_ln = results[0].address_components[i].long_name;
          google_address.street_number_sn = results[0].address_components[i].short_name;
          break;
        case 'route':
          google_address.route_ln = results[0].address_components[i].long_name;
          google_address.route_sn = results[0].address_components[i].short_name;
          break;
        case 'neighborhood':
          google_address.neighborhood_ln = results[0].address_components[i].long_name;
          google_address.neighborhood_sn = results[0].address_components[i].short_name;
          break;
        case 'locality':
          google_address.locality_ln = results[0].address_components[i].long_name;
          google_address.locality_sn = results[0].address_components[i].short_name;
          break;
        case 'administrative_area_level_2':
          google_address.administrative_area_level_2_ln = results[0].address_components[i].long_name;
          google_address.administrative_area_level_2_sn = results[0].address_components[i].short_name;
          break;
        case 'administrative_area_level_1':
          google_address.administrative_area_level_1_ln = results[0].address_components[i].long_name;
          google_address.administrative_area_level_1_sn = results[0].address_components[i].short_name;
          break;
        case 'country':
          google_address.country_ln = results[0].address_components[i].long_name;
          google_address.country_sn = results[0].address_components[i].short_name;
          break;
        case 'postal_code':
          google_address.postal_code_ln = results[0].address_components[i].long_name;
          google_address.postal_code_sn = results[0].address_components[i].short_name;
          break;
        case 'postal_code_suffix':
          google_address.postal_code_suffix_ln = results[0].address_components[i].long_name;
          google_address.postal_code_suffix_sn = results[0].address_components[i].short_name;
          break;
      }

    }

    google_address.formatted_address = results[0].formatted_address;


  }


}
