import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sbli01501190',
  templateUrl: './sbli01501190.component.html',
  styleUrls: ['./sbli01501190.component.css']
})
export class Sbli01501190Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
