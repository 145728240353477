import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-medical-advise',
  templateUrl: './medical-advise.component.html',
  styleUrls: ['./medical-advise.component.css']
})
export class MedicalAdviseComponent implements OnInit {
  @Output() nextquestion4 = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  next() {
    let etty = {
      "questionNo": "4",
      "nextprev": 1
    }
    this.nextquestion4.emit(etty);
  }

  
}
