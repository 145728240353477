import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { TrackerService } from 'src/app/shared/tracker.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-home-question2',
  templateUrl: './home-question2.component.html',
  styleUrls: ['./home-question2.component.css']
})
export class HomeQuestion2Component implements OnInit {

  @Output() onfinish = new EventEmitter();
  livingsizeSqft: any;
  homeType: any;
  additionalInfo: any;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  state: any;
  questions: any;
  knockOut: number;
  inputAddress: any;
  ci_address: string;
  ci_city: any;
  ci_state: any;
  ci_zip: any;
  loader: boolean;

  constructor(
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService,
    private tracker: TrackerService,
    private utilityService: UtilityFunctionsService) { }

  ngOnInit(): void {
    this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 2 });
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];

      this.inputAddress = this.uniqueId.split('-');
      this.inputAddress.shift();
      this.inputAddress = this.inputAddress.join(' ');

      const address = this.displayAddress.split('-');
      this.state = address[address.length - 3];
      console.log('Knockout state: ', this.state);

    }
    );
    debugger;
    const quickquote = new pc.Quotes(this.uniqueId);
    const response = quickquote.getSWKnockoutQuestionsByState(localStorage.getItem('sw_state'), localStorage.getItem('sw_yearBuilt'));
    console.log('Knockout Questions: ', response);
    this.questions = response;

    for (let i = 0; i < this.questions.length; i++) {
      if (i == 0) {
        this.questions[i].value = 'yes';
      }
      else {
        this.questions[i].value = 'no';
      }
    }

    this.pc.parseAddressLocalAndPatch(this.inputAddress, this.clientId).then(
      data => {
        this.ci_address = data.door_number + ' ' + data.street;// + " " + data.street_type;
        this.ci_city = data.city_name;
        this.ci_state = data.state_code;
        this.ci_zip = data.zip;
      }
    ).catch(error => console.log('error', error));

  }


  onInfoChange(val, ind) {

    if (val.value == 'no' && ind == 0) {
      val.value = 'yes';
      for (let i = 1; i < this.questions.length; i++) {
        this.questions[i].value = 'no';
      }
    }
    else {
      if (val.value == 'no') {
        val.value = 'yes';
      }
      else {
        val.value = 'no';
      }
    }
    let myvalue = 0;
    for (let i = 1; i < this.questions.length; i++) {
      if (this.questions[i].value == 'yes') {
        myvalue = 1;
        break;
      }
    }
    if (myvalue != 0) {
      this.questions[0].value = 'no';
      this.knockOut = 1;
    }
    if (myvalue == 0) {
      this.questions[0].value = 'yes';
      this.knockOut = 0;
    }
  }

  next() {
    debugger;

    this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 4 });
    this.loader = true;
    let replacementCost = localStorage.getItem('dwellingResult');
    replacementCost = replacementCost == 'No Data' || replacementCost == null ? '90000' : replacementCost;
    const qid = [];
    for (let i = 1; i < this.questions.length; i++) {
      if (this.questions[i].value == 'yes') {
        qid.push(this.questions[i].sw_questionId);
      }
    }
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['swquote_question'] = qid;
    commonInput['propertyDetails'] = JSON.parse(localStorage.getItem('propertyDetails'));
    commonInput['disableBack'] = false;
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    console.log('SW knockout commonInput:', commonInput);

    this.loader = false;
    this.knockOut = 0;
    const etty = {
      'questionNo': '1',
      'nextprev': 1,
      'knockOut': this.knockOut
    };
    const eventOptionsPart1 = {
      'path': 'HomeAppUnderwriting',
      'page_title': 'HomeAppUnderwriting', 'url': window.location.href
    };

    this.$gaService.event('Next', 'event', 'HomeAppUnderwriting');
    this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed', 'event', 'MyHomeStep2Proceed');

    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeAppUnderwriting', {
      'event_category': 'ButtonClicked', 'event_label': 'Next'
    });
    this.onfinish.emit(etty);
    // let quickquote = new pc.Quotes(this.uniqueId);
    // quickquote.swQuoteKnockout(commonInput)
    //   .then(response => {
    //     //debugger
    //     console.log("SW knockout output: ", response);
    //     if (response.knockout) {
    //       this.knockOut = 1;
    //       let errorCode = response.message;
    //       errorCode = errorCode.split(":");
    //       errorCode = errorCode[0].replace(" ", "");
    //       if (errorCode == "QUA0080") {
    //         localStorage.setItem("KOerrorMessage", "Unfortunately we cannot insure you right now because of prior damage to the house.");
    //       }
    //       else if (errorCode == "QUA0118") {
    //         localStorage.setItem("KOerrorMessage", "Unfortunately we cannot insure you right now because 1 or more units are occupied by more than 2 families");
    //       }
    //       else if (errorCode == "QUA0021") {
    //         localStorage.setItem("KOerrorMessage", "Unfortunately we cannot insure you right now because there is a business being run on property");
    //       }
    //       else if (errorCode == "QUA0003") {
    //         localStorage.setItem("KOerrorMessage", "Unfortunately we cannot insure you right now because the house is vacant");
    //       }
    //       else {
    //         localStorage.setItem("KOerrorMessage", "Unfortunately we require a couple more steps before completing your application.");
    //       }
    //     }
    //     else {
    //       this.knockOut = 0;
    //     }
    //     this.loader = false;
    //     let etty = {
    //       "questionNo": "1",
    //       "nextprev": 1,
    //       "knockOut": this.knockOut
    //     }
    //     this.onfinish.emit(etty);
    //   })
    //   .catch(error => {
    //     console.log("SW knockout Error:", error)
    //   });
    // if (this.knockOut == 1) {

    // }
    // else {
    //   let etty = {
    //     "questionNo": "1",
    //     "nextprev": 1,
    //     "knockOut": this.knockOut
    //   }
    //   this.onfinish.emit(etty);
    // }
  }


}

