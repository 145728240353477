//src/app/shared/UtilityFunctions.js
const CLASS_NAME = "UtilityFunctions:";
function formatAmountCommaDollarLocal(coverage) {
    let outputVar;
    coverage = coverage.toString() || '';
    coverage = coverage.replace('$', '').replace(/,/g, '');
    outputVar = coverage.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    outputVar = `$${outputVar}`;
    return outputVar;
}
function formatAmountLocal(amountData) {
    let outputAmountStr = '' + amountData;
    if (outputAmountStr.indexOf('.') !== -1) {
        let splitedAmount = outputAmountStr.split('.');
        switch (splitedAmount[1].length) {
            case 0:
                outputAmountStr += '.00';
                break;
            case 1:
                outputAmountStr += '0';
                break;
            case 2:
            default:
                break;
        }
    } else {
        outputAmountStr += '.00';
    }
    return outputAmountStr;
}
function getHubSpotInterestTypeLocal(insurancetype) {
    let interestTypeOutput;
    if (!insurancetype) interestTypeOutput = "Life";
    if (insurancetype === "life") interestTypeOutput = "Life";
    else if (insurancetype === "home") interestTypeOutput = "Home";
    else interestTypeOutput = "Home+Life";
    return interestTypeOutput;
}
//output = formatAmountLocal(0);
// output = formatAmountLocal(2);
// output = formatAmountLocal(12.3);
// output = formatAmountLocal("$123");
// output = formatAmountLocal("90.9");
// output = formatAmountLocal("88.99");
// output = formatAmountLocal("1.1");

function getHubSpotAppStageLocal(url) {
    let application_stage;
    let QuoteFlow = url.toString().includes("QuoteFlowV2");
    let Appflowstart = url.toString().includes("Appflowstart");
    let HomeAppFlow = url.toString().includes("HomeAppFlow");

    if (Appflowstart == true || HomeAppFlow == true) {
        application_stage = "Application"
    } else {
        application_stage = "Quote";
    }
    // if (QuoteFlow) application_stage = "Quote"; else application_stage = "Application"
    return application_stage;
}

exports.formatAmount = function (amount) { return formatAmountLocal(amount); }
//output = formatAmountCommaDollarLocal(0);
// output = formatAmountCommaDollarLocal(12345678);
// output = formatAmountCommaDollarLocal(1234567);
// output = formatAmountCommaDollarLocal("$123456");
// output = formatAmountCommaDollarLocal("12345");
// output = formatAmountCommaDollarLocal("1234");
// output = formatAmountCommaDollarLocal("123");
exports.formatAmountCommaDollar = function (amount) { return formatAmountCommaDollarLocal(amount); }

exports.getHubSpotInterestType = function (insuranceTypeInput) { return getHubSpotInterestTypeLocal(insuranceTypeInput); }
exports.getHubSpotAppStage = function (url) { return getHubSpotAppStageLocal(url); }
