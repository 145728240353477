import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { formatDate } from '@angular/common';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

const PAYMENT_ERROR = 'Payment details provided could not be validated.';
const pc = require('bit-uibl-data-driver');

interface StillwaterSuccess{
  'insuredId': string,
  'policyNumber': string,
  'decPage_url': string,
  'acordApp_url': string,
  'policyConfirm_url': string,
  'emailDoc_url': string,
  'accountSetup_url': string,
  key: any
}
@Component({
  selector: 'app-sw4pt45',
  templateUrl: './sw4pt45.component.html',
  styleUrls: ['./sw4pt45.component.css']
})
export class Sw4pt45Component implements OnInit {

  @Output() nextquestion28 = new EventEmitter();
  @Output() paymentError = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  consumerReports: number;
  underWriting: number;
  agree: number;
  loader: boolean;
  existingAppData: any;
  paymentDetails: any;
  paymentOption: string;
  paymentOptionPlan: string;
  paymentPlanDetails: any;
  depositAmount: any;
  totalPremium: any;
  instalmentAmount: number;
  policyStartDate: any;
  paymentAmount: any;
  billingAddress: any;
  otherbillingAddress: string;
  routing: string;
  accountNumber: string;
  confirmAccountNumber: string;
  accountType: string;
  agreeTerms: any;
  cardNumberToShow: any;
  emailId: any;

  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private modalService: NgbModal,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService,
    private utilityService: UtilityFunctionsService) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    // this.uniqueId = 'nkowu4-285-Bryant-St-Palo-Alto-CA-94301';
    // this.clientId = 'nkowu4';
    this.loader = true;
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingAppData = dataFrom.body.stillwater;
      this.emailId = this.existingAppData.sw_EmailID;
      console.log('data from getAppData sw : ', this.existingAppData);
      this.paymentDetails = this.existingAppData.paymentDetails;
      console.log('paymentPlanDetails : ', this.paymentDetails);
      this.cardNumberToShow = this.paymentDetails.cardNumber;
      this.cardNumberToShow = this.cardNumberToShow.substr(this.cardNumberToShow.length - 4);
      this.agreeTerms = 0;

      this.policyStartDate = this.existingAppData.sw_PolicyStartDate;
      const format = 'MM/dd/yyyy';
      const myDate = new Date(this.policyStartDate);
      const locale = 'en-US';
      this.policyStartDate = formatDate(myDate, format, locale);
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });
  }

  agreechange(value) {
    this.agreeTerms = value;
  }

  next() {
    debugger;
    this.loader = true;
    const quickquote = new pc.Quotes(this.uniqueId);
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    console.log('submitSWPaymentDetails commonInput: ', commonInput);
    quickquote.submitSWPaymentDetails(commonInput).then(response => {
      console.log('submitSWPaymentDetails response:', response);
      if (response.hasError) {
        this.loader = false;

        const clientIdLocal = localStorage.getItem('clientID');
        const customerIdLocal = localStorage.getItem('customer_id');
        const emailLocal = localStorage.getItem('bubble-email');
        const home_knock_out_reason = PAYMENT_ERROR;
        const hubSpotData = {
          client_id: clientIdLocal,
          customer_id: customerIdLocal,
          email: emailLocal,
          home_knock_out_reason: home_knock_out_reason
        };
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

        this.openModal();
      }
      else {
        const stillwater_profile_setup_url = response.data && response.data.accountSetup_url;
        if (this.insurancetype == 'home') {
          const updateJson = {
            appCompletedStatus: true,
            stillwater_profile_setup_url
          };
          this.checkforResumeInit(updateJson, 'update');
        } else if (this.insurancetype == 'homeLife') {
          const updateJson = {
            appCompletedStatus: false,
            questionCompletedStatus: true,
            stillwater_profile_setup_url
          };
          this.checkforResumeInit(updateJson, 'update');
        }

        debugger;
        const clientIdLocal = localStorage.getItem('clientID');
        const customerIdLocal = localStorage.getItem('customer_id');
        const emailLocal = localStorage.getItem('bubble-email');

        const hubSpotData = {
          customer_id: customerIdLocal,
          email: emailLocal,
          application_stage: 'Payment',
          stillwater_profile_setup_url
        };
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

        const json = {
          'id': this.clientId,
          stillwater: this.existingAppData
        };
        json.stillwater['sw_totalPremium'] = this.paymentDetails.totalPremium;
        json['sw_totalPremium'] = this.paymentDetails.totalPremium;
        json.stillwater['sw_policyNumber'] = response.data.policyNumber;
        json['sw_policyNumber'] = response.data.policyNumber;
        console.log('json : ', json);
        this.pc.saveUpdateClientData(json);

        const etty = {
          'next': 1,
          'previous': 0,
          'details': response
        };
        this.nextquestion28.emit(etty);
      }
    })
      .catch(error => {
        console.log('submitSWPaymentDetails Error:', error);
        this.loader = false;
        this.openModal();
      });
    this.$gaService.event('Purchase Policy', 'event', 'HomeAppPurchasePolicy');
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion28.emit(etty);
  }

  openModal() {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      // message: "Our Servers are taking too long to respond please come back later.",
      message: PAYMENT_ERROR,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      this.paymentError.emit();
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }

}
