import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-state-country-search',
  templateUrl: './state-country-search.component.html',
  styleUrls: ['./state-country-search.component.css']
})
export class StateCountrySearchComponent implements OnInit {
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  options = "{ types: ['administrative_area_level_1', 'political'], componentRestrictions: { country: 'US'}}";

  @Output() addressSelected = new EventEmitter();

  label: string = "Enter your birth state and country";
  queryMatchList: Array<any> = [];  //list of address suggestions
  deviceInfo = null;
  searchAddress = " ";
  showAddressList = false; // to show or hide the address list

  constructor(private deviceService: DeviceDetectorService, private pc: ProcessCacheService, private http: HttpClient) { }

  ngOnInit(): void {
     
    console.log(this.pc.getClientId());
    this.deviceInfo = this.deviceService.getDeviceInfo();
  //  this.searchAddress=localStorage.getItem('searchAddress');
  }


  getAddress() {
    debugger;
    // get AddresList to display suggestion
    this.getAddressSuggestion(this.searchAddress);
    if (this.searchAddress.length === 0) {
      this.queryMatchList = [];
    }
  }

  hideList(address) {
    // debugger;
    this.searchAddress = address.description;
    this.getDetails(address.place_id)
    this.showAddressList = false
  }

  getAddressSuggestion(input) {
    const displaySuggestions = (
      predictions: google.maps.places.QueryAutocompletePrediction[] | null,
      status: google.maps.places.PlacesServiceStatus
    ) => {
      if (status != google.maps.places.PlacesServiceStatus.OK || !predictions) {
        return;
      }

      predictions.forEach((prediction: any) => {
        if (prediction.types.includes('administrative_area_level_1') || prediction.types.includes('locality')) {
          if (!this.queryMatchList.some(address => address.description === prediction.description)) {
            this.queryMatchList.push(prediction)
            this.showAddressList = true
          }
        }
      });
    };

    const service = new google.maps.places.AutocompleteService();
    service.getPlacePredictions({ input }, displaySuggestions);
  }

  // GetKey(data){
  //   debugger;
  //   if(data.target.value==""){
  //     var etty = {
  //       "addressDetails": "",
  //       "enableButton": true
  //     }
  //     this.addressSelected.emit(etty);
  //   }
  // }


  private getDetails(placeId) { // get Address State Code, State and Country
    const map = new google.maps.Map(document.getElementById("map") as HTMLElement,
      {
        center: { lat: -33.866, lng: 151.196 },
        zoom: 15,
      }
    );

    var placesService = new google.maps.places.PlacesService(map);

    placesService.getDetails(
      { placeId, fields: ["address_components"], },
      (results) => {
        this.saveUpdateClientData(results)
      }
    );

  }

  public saveUpdateClientData(address: any) {
    sessionStorage.setItem('IsCountrySelected', '1');
    let json = {
      id: localStorage.getItem("clientID"),
      deviceInfo: this.deviceInfo
    };
    for (let i = 0; i < address.address_components.length; i++) {
      if (address.address_components[i].types) {
        if (address.address_components[i].types.includes('administrative_area_level_1')) {
          json['stateBorn'] = address.address_components[i].long_name;
          json['stateCodeAtBirth'] = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('country')) {
          json['countryBorn'] = address.address_components[i].long_name;
          json['countryBornCode'] = address.address_components[i].short_name;
        }
      }
    }
    console.log("countryborn ", json);
    this.pc.saveUpdateClientData(json);
  }




  public handleAddressChange(address: Address) {
    debugger
    console.log("selected address : ", address);
    this.searchAddress = address.formatted_address;
    localStorage.setItem('searchAddress',this.searchAddress)
    if (this.searchAddress == "United States") {
      this.searchAddress = "";
      localStorage.setItem('searchAddress',this.searchAddress)
      alert("Please key in state name to select from suggestions");
    } else {
      this.getDetails(address.place_id);
      this.showAddressList = false
      let json = {
        id: localStorage.getItem("clientID"),
        deviceInfo: this.deviceInfo
      };
      for (let i = 0; i < address.address_components.length; i++) {
        if (address.address_components[i].types) {
          if (address.address_components[i].types.includes('administrative_area_level_1')) {
            json['stateBorn'] = address.address_components[i].long_name;
            json['stateCodeAtBirth'] = address.address_components[i].short_name;
          } else if (address.address_components[i].types.includes('country')) {
            json['countryBorn'] = address.address_components[i].long_name;
            json['countryBornCode'] = address.address_components[i].short_name;

          }
        }
      }
      console.log("countryborn ", json);
      this.pc.saveUpdateClientData(json);
      var etty = {
        "addressDetails": address,
        "enableButton": false
      }
      this.addressSelected.emit(etty);
    }

  }


}
