import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sbli02401950',
  templateUrl: './sbli02401950.component.html',
  styleUrls: ['./sbli02401950.component.css']
})
export class Sbli02401950Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
