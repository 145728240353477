import { Component, OnInit,Output, Input } from '@angular/core';

@Component({
  selector: 'app-selected-insurance-footer',
  templateUrl: './selected-insurance-footer.component.html',
  styleUrls: ['./selected-insurance-footer.component.css']
})
export class SelectedInsuranceFooterComponent implements OnInit {

  @Input() insuranceType;

  @Input() selected;
  @Input() carrier;
  @Input() imgURL;
  @Input() amount;
  @Input() imgwidth;

  @Input() hselected;
  @Input() hcarrier;
  @Input() himgURL;
  @Input() hamount;
  @Input() himgwidth;

  constructor() { }

  ngOnInit(): void {
  }

}
