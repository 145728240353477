import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-sw4pt46',
  templateUrl: './sw4pt46.component.html',
  styleUrls: ['./sw4pt46.component.css']
})
export class Sw4pt46Component implements OnInit {

  @Input() inputDetails;
  @Output() nextquestion29 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  consumerReports: number;
  underWriting: number;
  agree: number;
  declarationPageUrl: any;
  paymentConfirmationUrl: any;
  underwritingQuestionsUrl: any;
  policyNumber: any;
  emailDoc_url: any;

  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    localStorage.setItem('bubble-lifejourneystart','false');
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    debugger;
    this.policyNumber = this.inputDetails.data.policyNumber;
    this.declarationPageUrl = this.inputDetails.data.decPage_url;
    this.paymentConfirmationUrl = this.inputDetails.data.policyConfirm_url;
    this.underwritingQuestionsUrl = this.inputDetails.data.acordApp_url;
    this.emailDoc_url = this.inputDetails.data.emailDoc_url;
    const hubSpotData = {
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Issued',
      home_policy_number:this.inputDetails.data.policyNumber
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    /////// id mapping
    const idMappingJson = {
      client_id: this.clientId,
      sw_policy_id: this.policyNumber,
      interest_type: this.insurancetype,
      status: this.insurancetype == 'home' ? 'HomeApplication' : 'ApplicationStarted'
    };
    this.pc.createIdMapping(idMappingJson);
    // const Helper = require('bit-uibl-data-driver');
    // Helper.Lead.createIdMapping(idMappingJson)
    //   .then((data) => {
    //     if (data) { console.log("Lead:createIdMapping:data: ", data) }
    //   });
    console.log('Lead:createIdMapping:idMappingJson:', idMappingJson);
    /////// id mapping ends
    this.$gaService.event('On Page Load', 'page', 'HomeInsuranceThankyou');
  }


  next() {
    debugger;
    localStorage.setItem('bubble-lifejourneystart','true');
    const updateJson = {
      appCompletedStatus : true
    };
    this.checkforResumeInit(updateJson, 'update');
    const etty = {
      'next': 1,
      'previous': 0,
    };
    this.nextquestion29.emit(etty);

  }

  previous() {


    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion29.emit(etty);
  }


  async checkforResumeInit(inputJson, type){
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }
}
