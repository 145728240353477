import { Options } from '@angular-slider/ngx-slider';
import { formatDate } from '@angular/common';
import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, OnInit, Output, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import axios from 'axios';
import pc from 'bit-uibl-data-driver';
import { environment } from 'src/environments/environment';
import carriers from '../../assets/carriers_data.json';
import { DrawerComponent } from '../components/drawer/drawer.component';
import { KoPersonalPopupComponent } from '../components/ko-personal-popup/ko-personal-popup.component';
import { ProcessCacheService } from '../shared/process-cache.service';

@Component({
  selector: 'app-life-recommendation-screen',
  templateUrl: './life-recommendation-screen.component.html',
  styleUrls: ['./life-recommendation-screen.component.css'],
})
export class LifeRecommendationScreenComponent implements OnInit, AfterContentChecked {
  @ViewChildren('checkboxes') checkboxes: QueryList<ElementRef>;
  @ViewChild('wnssbliRediect') wnssbliRediect: ElementRef<HTMLElement>;
  @ViewChild('back9Modal') back9Modal: ElementRef;

  coverageoptions: Options = {
    showTicksValues: false,
    showSelectionBar: true,
    showTicks: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
    keyboardSupport: false,
    animateOnMove: true,
    getPointerColor: (value: number): string => {
      return 'rgb(255, 112, 7)';
    },
    getSelectionBarColor: (value: number): string => {
      return 'rgb(255, 112, 7)';
    },
    stepsArray: [
      {
        value: 25000,
        legend: '25,000'
      }, {
        value: 50000,
        legend: '50,000'
      }, {
        value: 100000,
        legend: '100,000'
      }, {
        value: 150000,
        legend: '150,000'
      },
      {
        value: 200000,
        legend: '200,000'
      }, {
        value: 250000,
        legend: '250,000'
      },
      {
        value: 500000,
        legend: '500,000'
      },
      {
        value: 750000,
        legend: '750,000'
      },
      {
        value: 1000000,
        legend: '1,000,000'
      },
      {
        value: 1250000,
        legend: '1,250,000'
      },
      {
        value: 1500000,
        legend: '1,500,000'
      },
      {
        value: 1750000,
        legend: '1,750,000'
      },
      {
        value: 2000000,
        legend: '2,000,000'
      },
      {
        value: 2500000,
        legend: '2,500,000'
      },
      {
        value: 5000000,
        legend: '5,000,000'
      },
      {
        value: 7500000,
        legend: '7,500,000'
      },
      {
        value: 10000000,
        legend: '10,000,000'
      }
    ]
  };
  termoptions: Options = {
    showTicksValues: false,
    showSelectionBar: true,
    showTicks: false,
    hidePointerLabels: true,
    hideLimitLabels: true,
    keyboardSupport: false,
    animateOnMove: true,
    getPointerColor: (value1: number): string => {
      return '#ff7007';
    },
    getSelectionBarColor: (value1: number): string => {
      return '#ff7007';
    },
    stepsArray: [{
      value: 10,
      legend: ''
    }, {
      value: 15,
      legend: ''
    }, {
      value: 20,
      legend: ''
    }, {
      value: 25,
      legend: ''
    }, {
      value: 30,
      legend: ''
    },
    {
      value: 35,
      legend: ''
    },
    {
      value: 40,
      legend: ''
    },
    {
      value: 65,
      legend: ''
    }]
  };
  hours = ['08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07'];
  minutes = ['00', '30'];
  merdiaum = ['AM', 'PM'];
  addItem = ['Add a Plan', 'Add a Plan', 'Add a Plan'];
  uniqueId: any;
  modalopened: any;
  clientid: any;
  clientdata: any;
  displayAddress: any;
  fullAddress: any;
  appData: any;
  fullAddressData: any;
  commonInput: any;
  riskClass: any;
  health: any;
  termLength: any;
  coverage: any;
  smoker: any;
  termType: any;
  input_array: any;
  dob: any;
  yearm: any;
  policytype: any;
  carrierJsonData: any;
  quoteData: any;
  quotes: any;
  loader: any;
  modal_data: any;
  carrier_price: any;
  age: any;
  product_name: any;
  allquotes: any;
  payfrequency: any;
  sortby: any;
  filterdquotes: any;
  emailid: any;
  phoneEvent: any;
  DateOfBirth: any;
  message: string;
  disablerefreshbutton: boolean;
  popUpApiCall = false;
  sbli: boolean;
  wns: boolean;
  wnssbliRediecturl: string;
  clientData: any;
  ridersloop: any;
  allDetailsAvailable: boolean;
  carrierData = [];
  coveragedisplay: any;
  coverageforslider: any;
  @Output() loaderText = [
    {
      'title1': 'Know your area\'s access to exercise & fresh food; both are vital for a healthy lifestyle!',
      //'Subtitle1': 'Add more at a later time if you need to',
      'title2': 'Check out the life and health-impacting factors in your neighborhood',
      //'Subtitle2': 'Extend it or reduce as you see needed',
      'title3': '30-min of daily walking lowers blood pressure & stress',
      'title4': 'Practice deep breathing while we get your quotes!'
      //'Subtitle3': 'Lock it in for decades'
    }];
  decisiontext = 'Our AI guidance system, LifePal<sup>TM</sup>, is analyzing your data';
  detailsText = 'More plan details';
  normalloadertext = 'Thank you for providing us with your responses. Based on your answers, we will recommend policies that you are most likely to qualify for. Please wait while we generate your personalized recommendations.';
  popupclosed: boolean;
  schedulecall: boolean;
  appointmentdate: string;
  callback_hour: string;
  callback_minutes: string;
  dateValidation: string;
  callback_time: string;
  selectedmeridum: any;
  carrierName: any;
  Online: boolean;
  firstname: any;
  lastname: any;
  phone: any;
  termdisplay: any;
  productname: any;
  redirecturl: any;
  quoteamount: any;
  recopageheadtext: string;
  showLoader: boolean;
  quotesData: any;
  rating: any;
  disableCompareButton = true;
  footerHeight: string;
  insurancetype: any;
  liferedirecturl: any;
  leadsource: any;
  topthreelessamountquotes: any;
  ridershtml: string;
  allriders: any;
  returnofprmium: any;
  wnsproduct: any;
  ridersdata: any[];
  constructor(private pc: ProcessCacheService, private modalService: NgbModal, private activatedroute: ActivatedRoute, private cdref: ChangeDetectorRef, private modal: NgbModal) {

  }

  async ngOnInit(): Promise<void> {

    this.recopageheadtext = localStorage.getItem('recopageheadtext') ? localStorage.getItem('recopageheadtext') : 'Based on your information, our LifePal’s AI engine recommends these policies';
    this.quotes = [];
    localStorage.getItem('recopageheadtext') ? this.showLoader = true : this.loader = true;
    this.policytype = '1';
    this.sortby = '2';
    this.payfrequency = 2;
    this.disablerefreshbutton = true;
    this.carrierJsonData = carriers['carrier_details'];
    console.log('json', this.carrierJsonData);
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientid = params['clientId'];
      this.displayAddress = params['address'];
      this.fullAddress = this.displayAddress.split('-');
      this.fullAddress.shift();
      this.fullAddress = this.fullAddress.join(' ');
      this.insurancetype = params['insuranceType'];
    }
    );

    this.termType = ' Year Term';

    try {
      const idData = await this.pc.getAppData(this.clientid, true);
      this.clientData = JSON.parse(idData?.Payload)?.body;
    } catch {
      const idData = await this.pc.getAppData(this.clientid, true);
      this.clientData = JSON.parse(idData?.Payload)?.body;
    }
    console.log('client response', this.clientData);
    if (this.clientData.bubble_email && this.clientData.dob && this.clientData.tobacoUser && this.clientData.bubble_phone && this.clientData.bubble_heightFt && this.clientData.bubble_heightIn && this.clientData.bubble_weight && this.clientData.desired_life_coverage && this.clientData.desired_life_term && this.clientData.gender && (this.clientData.zipcode || this.clientData.zip_code || this.clientData.zip)) {
      await this.getUserDetails();
      this.allDetailsAvailable = true;
      //localStorage.getItem('recopageheadtext') ? this.showLoader = true : this.loader = true;
    }
    else {
      this.allDetailsAvailable = false;
      this.showLoader = false;
      this.loader = false;
      this.personalDetailsPopup();
    }
  }



  public async getQuotes(input_array, commonInput) {

    this.allquotes = '';
    this.quotes = '';
    console.log('input back9 array', input_array);
    const functionarray = (localStorage.getItem('lead_source') == 'Afficiency') ? [this.getQuotess(commonInput, this.sbli, this.wns)] : [this.getback9Data(input_array)];
    let bothback9andquickquote = await Promise.all(functionarray).then(function (values) {
      console.log(values);
      return values;
    }).catch(function (err) {

      return err;
    });
    console.log('bothback9andquickquote', bothback9andquickquote);
    bothback9andquickquote = ((localStorage.getItem('lead_source') == 'Afficiency')) ? (await this.arraymove(bothback9andquickquote, 0, 1)) : bothback9andquickquote;
    this.allquotes = bothback9andquickquote;
    if (bothback9andquickquote[0] == undefined && localStorage.getItem('lead_source') != 'Afficiency') {
      this.allquotes.push(await this.getQuotess(commonInput, this.sbli, this.wns));
    }
    this.prepareQuotes(this.allquotes, this.payfrequency, 1, bothback9andquickquote[0] != undefined ? 1 : 0, commonInput);
  }

  getback9Data = async (input_array) => {

    let back9quotes;
    await axios.post(`${environment.Nest_API_URL}/back9/getback9`, input_array).then(data => {
      back9quotes = data['data'][0];
    }).catch(function (err) {
      console.log('backpquoteerr', err);
    });
    if (back9quotes != undefined && back9quotes?.length > 0) {
      this.loader = false;
      this.showLoader = false;
    }
    return back9quotes;
  };


  public async getQuotess(commonInput, sbli, wns) {
    let afficencyresponse;
    const quickquote = new pc.Quotes(commonInput.uniqueId);
    await quickquote.getQuickQuote2(commonInput, sbli, wns).then(response => {
      afficencyresponse = response;
    }).catch(function (err) {
      console.log('afficencyerr', err);
    });
    await this.pc.getArcid(this.clientid);
    this.loader = false;
    this.showLoader = false;
    return afficencyresponse;
  }

  getback9returnData = async (returninput_array) => {
    let returnofpremiumresponse;
    await axios.post(`${environment.Nest_API_URL}/back9/getback9`, returninput_array).then(data => {
      returnofpremiumresponse = data['data'][0];
    }).catch(function (err) {
      console.log('returnofpremiumerr', err);
    });
    return returnofpremiumresponse;
  };


  async getUserDetails() {
    const userDetails = await Promise.all([this.pc.getAppData(this.clientid), this.pc.parseAddressLocalAndPatch(this.fullAddress, this.clientid)]).then(function (values) {
      return values;
    }).catch(function (err) {
      return err;
    });
    this.appData = Object.assign({}, JSON.parse(userDetails[0].Payload).body);
    this.fullAddressData = Object.assign({}, userDetails[1]);
    console.log('appData', this.appData, 'this.fullAddressData', this.fullAddressData);
    this.dob = this.appData.dob;
    const timeDiff = Number(Math.abs(Date.now() - new Date(this.dob).getTime()));
    //Used Math.floor instead of Math.ceil
    //so 26 years and 140 days would be considered as 26, not 27.
    this.age = Number(Math.floor((timeDiff / (1000 * 3600 * 24)) / 365));
    // if (this.appData.age == '25-35' || this.appData.age=='0') {
    //   const today = new Date();
    //   const birthDate = new Date(this.dob);
    //   this.age = today.getFullYear() - birthDate.getFullYear();
    // }
    // else {
    //   this.age = this.appData.age;
    // }


    if (this.appData.makewnscall == 'false' && this.appData.makesblicall == 'false') {
      this.wns = false;
      this.sbli = false;
    }
    else if (this.appData.makewnscall == 'false' && this.appData.makesblicall != 'false') {
      this.wns = false;
      this.sbli = true;//true
    }
    else if (this.appData.makesblicall == 'false' && this.appData.makewnscall != 'false') {
      this.wns = true;//true
      this.sbli = false;
    }
    else if (this.appData.makesblicall != 'false' && this.appData.makewnscall != 'false') {
      this.wns = true;//true
      this.sbli = true;//true
    }
    if (this.appData.filterSbliWns == 'true') {
      this.wns = false, this.sbli = false;
    }
    //this.appData.filterSbliWns == 'true' ? (this.wns = false, this.sbli = false) : (this.wns = true, this.sbli = true);
    // this.appData.makewnscall == 'false'&& this.appData.makesblicall== 'false' ? (this.wns = false, this.sbli = false) :  this.appData.makewnscall == 'false' ?(this.wns = false, this.sbli = true):this.appData.makesblicall == 'false'?(this.wns = false, this.sbli = true):(this.wns = false, this.sbli = false)
    await this.createInputsAndCallApi();
  }

  async createInputsAndCallApi() {
    await this.createCommonInput(this.popUpApiCall);
    await this.input_array_smoker();
    await this.getQuotes(this.input_array, this.commonInput);
  }

  createCommonInput(event) {

    this.appData.desired_life_coverage ? (this.coverage = this.appData.desired_life_coverage, localStorage.setItem('bubble-coverage', this.appData.desired_life_coverage)) : (localStorage.setItem('bubble-coverage', '250000'), this.coverage = '250000');
    this.appData.desired_life_term ? (this.termLength = this.appData.desired_life_term, localStorage.setItem('bubble-termLength', this.appData.desired_life_term)) : (localStorage.setItem('bubble-termLength', '20'), this.termLength = '20');
    // const recomendedCoverage = '100000';
    // if (localStorage.getItem('bubble-coverage') == null || localStorage.getItem('bubble-coverage') == undefined || localStorage.getItem('bubble-coverage') == '') {
    //   localStorage.setItem('bubble-coverage', recomendedCoverage);
    //   this.coverage = recomendedCoverage.toString();
    // }
    // else {
    //   this.coverage = localStorage.getItem('bubble-coverage').toString();
    // }

    // if (localStorage.getItem('bubble-termLength') == null || localStorage.getItem('bubble-termLength') == undefined || localStorage.getItem('bubble-termLength') == '') {
    //   this.termLength = 10;
    //   localStorage.setItem('bubble-termLength', this.termLength);
    // }
    // else {
    //   this.termLength = localStorage.getItem('bubble-termLength');
    // }

    this.riskClass = localStorage.getItem('riskClass');
    if (this.riskClass === 'Super Preferred Non-Tobacco') {
      this.health = 5;
    }
    else if (this.riskClass === 'Preferred Plus Non-Tobacco') {
      this.health = 4;
    }
    else if (this.riskClass === 'Preferred Non-Tobacco') {
      this.health = 3;
    }
    else if (this.riskClass === 'Preferred Tobacco') {
      this.health = 2;
    }
    else {
      this.health = 5;
    }
    let term;
    if (Number(this.appData.desired_life_term) == 65) {
      term = 65 - this.age;
    }
    else {
      term = this.appData.desired_life_term.toString();
    }
    let cov = Number(this.coverage);
    cov = cov / Number(term);
    cov = cov / 12;
    let wnscoverage;
    if (cov > 10000) {
      wnscoverage = 10000;
    } else if (cov < 500) {
      wnscoverage = 500;
    } else {
      wnscoverage = cov;
    }
    wnscoverage = wnscoverage.toString();
    if (wnscoverage.includes('.')) {
      const wnscov = wnscoverage.split('.');
      wnscoverage = wnscov[0];
    }
    if (this.appData.tobacoselected === 'No') {
      this.smoker = 'Never';
    }
    else {
      if (this.appData.tobacoUsageDuration == '1') {
        this.smoker = 'Currently';
      }
      else {
        this.smoker = 'Previously';
      }

    }
    localStorage.setItem('bubble-wnscoverage', wnscoverage);

    this.commonInput = {
      emailid: this.appData.bubble_email,
      displayAddress: this.displayAddress,
      insurancetype: this.appData.insurancetype,
      clientid: this.clientid,
      uniqueId: this.uniqueId,
      lastName: this.appData.bubble_lname ? this.appData.bubble_lname : 'Lastname',
      firstName: this.appData.bubble_fname ? this.appData.bubble_fname : 'Firstname',
      middleName: '',
      addressType: 'PhysicalRisk',
      city: this.fullAddressData.city_name,
      gender: this.appData.gender != '0' ? 'M' : 'F', //inputJson.gender
      dob: this.appData.dob,//inputJson.dob
      term: term,
      wnsterm: term,//inputJson.term
      zip: this.fullAddressData.zip,//inputJson.zip
      state: this.fullAddressData.state_code,//inputJson.state
      height: this.appData.bubble_heightFt + 'ft ' + this.appData.bubble_heightIn + 'inch',//inputJson.height
      weight: this.appData.bubble_weight,//inputJson.weight
      back9hight: Number(this.appData.bubble_heightFt) * 12 + Number(this.appData.bubble_heightIn),
      tobaccoUse: this.appData.tobacoUser,//inputJson.tobaccoUse
      riskClass: this.riskClass,
      wnscoverage: wnscoverage,
      smoker: this.smoker,
      coverage: this.appData.desired_life_coverage.toString(),//inputJson.coverage
      phone: this.appData.bubble_phone.includes('-') ? this.appData.bubble_phone.replace(/\$|-/g, '') : this.appData.bubble_phone,//inputJson.email
      premiumFrequency: 'm',
      mode: 12,
      health: this.health,
      selectedtype: 'term',
      producttypes: 'term',
      termtodisplay: this.appData.desired_life_term,
      returnselectedtype: 'ropTerm',
      returnproducttypes: 'rop_term',
    };
    this.coverageforslider = this.commonInput.coverage;
    console.log('commonInput', this.commonInput);
    // event ?
    //   (localStorage.setItem('bubble-coverage', this.appData.desired_life_coverage.toString()),
    //     localStorage.setItem('bubble-termLength', this.appData.desired_life_term.toString())) : 0;
    // this.coverage = localStorage.getItem('bubble-coverage').toString();
    // this.termLength = localStorage.getItem('bubble-termLength').toString();
  }


  public input_array_smoker() {
    // this.coverage = this.appData.coverage;
    let dob;
    if (this.dob != undefined) {
      if (this.dob.includes('/')) {
        const date_birth = this.dob.split('/');
        dob = date_birth[2] + '-' + date_birth[0] + '-' + date_birth[1];
      }
    }

    if (this.appData.tobacoselected === 'No') {
      this.smoker = 'Never';
      this.input_array = {
        'quotes': [{
          'face_amounts': [this.coverage],
          'gender': (this.appData.gender == '1') ? 'Male' : 'Female',
          'birthdate': dob,
          'state': this.appData.state_name ? this.appData.state_name : this.appData.stateCode,
          'health': 5,
          'smoker': this.smoker,
          'mode': 12,
          //"product_categories":["30 Year Return of Premium Term"]
          'product_categories': [`${this.commonInput.term}${this.termType}`, `${this.commonInput.term} Year Return of Premium Term`]
        }]
      };
      console.log('back9_input_Never', this.input_array);
    }
    else {
      if (this.appData.tobacoUsageDuration == '1') {
        this.smoker = 'Currently';
        this.input_array = {
          'quotes': [{
            'face_amounts': [this.coverage],
            'gender': (this.appData.gender == '1') ? 'Male' : 'Female',
            'birthdate': dob,
            'state': this.appData.state_name ? this.appData.state_name : this.appData.stateCode,
            'health': 5,
            'smoker': this.smoker,
            'usages': [{
              'status': 'Currently',
              'frequency': 2,
              'category': 'Cigarettes'
            }
            ],
            'mode': 12,
            //"product_categories":["30 Year Return of Premium Term"]
            'product_categories': [`${this.commonInput.term} Year term`, `${this.commonInput.term} Year Return of Premium Term`]
          }]
        };
        console.log('back9_input_Currently', this.input_array);
      }
      else {
        this.smoker = 'Previously';
        if (this.appData.tobacoUsageDuration == '2') {
          const format = 'yyyy-MM-dd';
          var d = new Date();
          const locale = 'en-US';
          d.setMonth(d.getMonth() - 13);
          this.yearm = formatDate(d, format, locale);
        }
        else if (this.appData.tobacoUsageDuration == '3') {

          const format = 'yyyy-MM-dd';
          var d = new Date();
          const locale = 'en-US';
          d.setMonth(d.getMonth() - 25);
          this.yearm = formatDate(d, format, locale);
        }
        else if (this.appData.tobacoUsageDuration == '4') {
          const format = 'yyyy-MM-dd';
          var d = new Date();
          const locale = 'en-US';
          d.setMonth(d.getMonth() - 37);
          this.yearm = formatDate(d, format, locale);
        }
        else {
          const format = 'yyyy-MM-dd';
          var d = new Date();
          const locale = 'en-US';
          d.setMonth(d.getMonth() - 60);
          this.yearm = formatDate(d, format, locale);
        }
        this.input_array = {
          'quotes': [{
            'face_amounts': [this.coverage],
            'gender': (this.appData.gender == '1') ? 'Male' : 'Female',
            'birthdate': dob,
            'state': this.appData.state_name ? this.appData.state_name : this.appData.stateCode,
            'health': 5,
            'smoker': this.smoker,
            'usages': [{
              'status': 'Previously',
              'last_use_date': this.yearm,
              'frequency': 2,
              'category': 'Cigarettes'
            }
            ],
            'mode': 12,
            //"product_categories":["30 Year Return of Premium Term"]
            'product_categories': [`${this.commonInput.term}${this.termType}`, `${this.commonInput.term} Year Return of Premium Term`]
          }]
        };
        console.log('back9_input_Previously', this.input_array);
      }
    }
    // if (this.policytype == '4') {
    //   if (Number(this.commonInput.term != 20) || Number(this.commonInput.term != 30)) {
    //     this.input_array['quotes'][0].product_categories = ['20 Year Return of Premium Term'];
    //     console.log('Reutn_back9_input', this.input_array);
    //   }
    // }
  }


  async prepareQuotes(quotesData, payfrequnecy, type, calledfrom, commonInput = {}) {
    this.leadsource = localStorage.getItem('lead_source');
    const quote = [];
    let testquotes = [];
    this.quotes = [];
    this.quoteData = quotesData[1];
    if (this.quoteData?.length) {
      for (let i = 0; i < this.quoteData.length; i++) {

        this.quoteData[i]['head']['quote_amount'] = this.quoteData[i]['head']['quote_amount'] == undefined ? '$0' : this.quoteData[i]['head']['quote_amount'];
        // const product_name = i == 0 ? 'Next Term Life' : i == 1 ? 'Simplified Issue Term Life ' : 'Western & Southern';
        // const carrier_name = i == 0 ? 'Next By Pacific Life' : i == 1 ? 'SBLI' : 'W&S';
        const product_name = i == 0 ? 'Simplified Issue Term Life ' : 'Western & Southern';
        const carrier_name = i == 0 ? 'SBLI' : 'W&S';
        quote[i] = {
          'carrier': {
            'avatar': this.quoteData[i].head?.carrier_icon,
            'avatar_url': this.quoteData[i].head?.carrier_icon,
            'name': carrier_name,
            'am_best_rating': 'A+'
          },
          'product': {
            'name': product_name
          },
          'premium': this.quoteData[i].head.quote_amount.toString().includes('$') ? this.quoteData[i].head.quote_amount.replace('$', '') : this.quoteData[i].head.quote_amount,
          'annual_premium': this.quoteData[i].head.annual_amount?.replace('$', ''),
          'monthly_premium': this.quoteData[i].head.quote_amount.toString().includes('$') ? this.quoteData[i].head.quote_amount.replace('$', '') : this.quoteData[i].head.quote_amount,
          'appflow': true,
          'carrierId': this.quoteData[i].carrierId
        };

        (i == 0 || i == 2) && (Number(quote[i].premium) != 0) ? this.quotes.push(quote[i]) : i == 1 && (Number(quote[i].premium) != 0 && Number(this.commonInput.term) != 65) ? this.quotes.push(quote[i]) : 0;
      }
      console.log('quote', quote);
      if (true) {
        (Number(quote[0]?.premium) != 0 || Number(quote[1]?.premium) != 0) || Number(quote[2]?.premium) != 0 ? this.quotes = this.quotes?.reverse() : 0;
      }
      else {
      }
      if (quotesData[0] != undefined) {
        for (let k = 0; k < testquotes.length; k++) {
          if (type == 1) {
            testquotes[k]['monthly_premium'] = testquotes[k]['premium'];
          }
          else {
            testquotes[k]['monthly_premium'] = testquotes[k]['monthly_premium'];
          }

        }
        testquotes = testquotes.concat(quotesData[0], this.quotes);
      }
      else {
        testquotes = this.quotes;
      }
    }
    else {
      if (quotesData[2] != undefined) {
        testquotes = testquotes.concat(quotesData[0], quotesData[2]);
        for (let k = 0; k < testquotes.length; k++) {
          if (type == 1) {
            testquotes[k]['monthly_premium'] = testquotes[k]['premium'];
          }
          else {
            testquotes[k]['monthly_premium'] = testquotes[k]['monthly_premium'];
          }

        }
      }
      else {
        if (quotesData[1] != undefined) {
          for (let k = 0; k < quotesData[1].length; k++) {
            if (type == 1) {
              testquotes[k]['monthly_premium'] = testquotes[k]['premium'];
            }
            else {
              testquotes[k]['monthly_premium'] = testquotes[k]['monthly_premium'];
            }

          }
          testquotes = quotesData[1];
        }
        else {
          testquotes = quotesData[0];
          for (let k = 0; k < testquotes.length; k++) {
            if (type == 1) {
              testquotes[k]['monthly_premium'] = testquotes[k]['premium'];
            }
            else {
              testquotes[k]['monthly_premium'] = testquotes[k]['monthly_premium'];
            }

          }
        }
      }
    }
    this.quotes = testquotes;
    console.log('unfiltered quotes', testquotes);
    this.quotes = this.quotes?.filter(x => x.carrier['name'] === 'Banner' || x.carrier['name'] === 'Next By Pacific Life' || x.carrier['name'] === 'W&S' || x.carrier['name'] === 'SBLI' || x.carrier['name'] === 'Transamerica' || x.carrier['name'] === 'North American' || x.carrier['name'] === 'Prudential Financial' || x.product['name'] === `${this.commonInput.term} Year Return of Premium Term` || x.carrier['name'] === 'Nationwide' || x.product['name'] === `Classic Choice Term ${this.commonInput.term}` || x.carrier['name'] === 'Pacific Life - Lynchburg' || x.carrier['name'] === 'Corebridge Financial' || x.carrier['name'] === 'Life Insurance Company of the Southwest');
    for (let i = 0; i < this.quotes.length; i++) {
      //this.quotes[i]['monthly_premium']=this.quotes[i]['premium'];
      if (payfrequnecy == 1) {
        this.quotes[i]['premium'] = Number(this.quotes[i]['monthly_premium']) / 30;
        this.quotes[i]['premium'] = (Math.round(Number(this.quotes[i]['premium']) * 100) / 100).toFixed(2);
      }
      else if (payfrequnecy == 2) {
        this.quotes[i]['premium'] = Number(this.quotes[i]['monthly_premium']);
      }
      else {
        this.quotes[i]['premium'] = Number(this.quotes[i]['annual_premium']);
      }
      if (this.quotes[i]['premium'].toString().includes('.')) {
        const spt_amt = this.quotes[i]['premium'].toString().split('.');
        if (spt_amt[1].length == 1) {
          spt_amt[1] = spt_amt[1] + '0';
        }
        this.quotes[i]['premium'] = `${spt_amt[0]}.${spt_amt[1]}`;

      }
      else {
        this.quotes[i]['premium'] = `${this.quotes[i]['premium']}.00`;
      }
      this.quotes[i]['term'] = this.commonInput['term'];
      // this.quotes[i]['premium'] = this.quotes[i]['premium'].toString().replace(/^\s+/g, '').toString();
      // this.quotes[i]['premium'] = Number(this.quotes[i]['premium']);
      this.quotes[i]['carrier_details'] = this.carrierJsonData.filter(x => x.carrier_name == this.quotes[i].carrier['name']);

      if (['SBLI', 'W&S'].includes(this.quotes[i].carrier['name'])) {

        this.quotes[i].ourproducts = true;

        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : '/dynamic-questions/questions';
        this.quotes[i].lifeflowredirecturl = '/dynamic-questions/questions';
      }

      else if (this.quotes[i].carrier['name'] === 'Banner') {
        if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 270; }
        else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 472; }
        else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 105; }
        else if (Number(this.commonInput.term) == 25) { this.quotes[i].productid = 2499; }
        else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 323; }
        else if (Number(this.commonInput.term) == 35) { this.quotes[i].productid = 2721; }
        else if (Number(this.commonInput.term) == 40) { this.quotes[i].productid = 2742; }
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        this.quotes[i].riders = 'Accelerated death benefit Rider,Available at extra cost<br>Term rider,Available at extra cost<br>Waiver of premium,Available at extra cost<br>Children’s rider,Available at extra cost';
      }
      else if (this.quotes[i].carrier['name'] === 'Next By Pacific Life') {
        this.quotes[i].pdflink = 'https://www.getmybubble.com/next-by-pacific-life/';
        this.quotes[i].redirectUrl = `Appflowstart/${this.commonInput.uniqueId}/${this.commonInput.clientid}/${this.commonInput.displayAddress}/life/paclife`;
      }
      else if (this.quotes[i].carrier['name'] === 'Nationwide') {
        this.quotes[i]['carrier_details'] = this.carrierJsonData.filter(x => x.product_name.includes('YourLife'));
        this.quotes[i]['product'].name = `YourLife ${this.commonInput.term} Year Term`;
        this.quotes[i].ourproducts = false;
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 438; }
        else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 594; }
        else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 284; }
        else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 187; }
        this.quotes[i].riders = 'Term Rider,Available at extra cost<br>Children’s Term Insurance Rider,Available at extra cost<br>Chronic Illness Rider,Available at extra cost<br>Critical Illness Rider,Available at extra cost<br>Terminal Illness Rider,Available at extra cost<br>Premium Waiver Rider,Available at extra cost<br>Extended Death Benefit Guarantee (EDBG) Rider,Available at extra cost<br>Extended No-Lapse Guarantee (ENLG) Rider,Available at extra cost<br>Long-Term Care Rider (if residing in the U.S. 12 months or more),Available at extra cost';
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }
      else if (this.quotes[i].carrier['name'] === 'Protective') {
        this.quotes[i]['carrier_details'] = this.carrierJsonData.filter(x => x.product_name.includes('Classic Choice Term'));
        this.quotes[i]['product'].name = `Classic Choice Term ${this.commonInput.term}`;
        this.quotes[i].ourproducts = false;
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 2613; }
        else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 2614; }
        else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 2612; }
        else if (Number(this.commonInput.term) == 25) { this.quotes[i].productid = 2615; }
        else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 2616; }
        else if (Number(this.commonInput.term) == 35) { this.quotes[i].productid = 2844; }
        else if (Number(this.commonInput.term) == 40) { this.quotes[i].productid = 2845; }
        this.quotes[i].riders = 'Accidental Death Benefit (ADB) Rider,Available at extra cost<br> Children\'s term rider,Available at extra cost<br>ExtendCareSM accelerated death benefit rider,Available at extra cost<br>Lapse protection rider,Available at extra cost<br>Overloan protection rider,Available at extra cost <br>Protected insurability rider,Available at extra cost<br>Waiver of specified premium rider,Available at extra cost';
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }
      else if (this.quotes[i].carrier['name'] === 'Pacific Life - Lynchburg') {
        this.quotes[i]['carrier_details'] = this.carrierJsonData.filter(x => x.product_name.includes('Promise Term'));
        this.quotes[i]['product'].name = `Promise Term ${this.commonInput.term}`;
        this.quotes[i].ourproducts = false;
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 2773; }
        else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 2751; }
        else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 2774; }
        else if (Number(this.commonInput.term) == 25) { this.quotes[i].productid = 2775; }
        else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 2776; }
        this.quotes[i].riders = 'Accelerated death benefit rider,Available at no extra cost<br>Children\'s Level Term Insurance Rider,Available at extra cost<br> Waiver of premium rider,Available at extra cost';
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }

      else if (this.quotes[i].carrier['name'] === 'Life Insurance Company of the Southwest') {
        this.quotes[i]['carrier_details'] = this.carrierJsonData.filter(x => x.product_name === 'Term 10');
        this.quotes[i]['product'].name = `Life Insurance Company of the Southwest Term ${this.commonInput.term}`;
        this.quotes[i].ourproducts = false;
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 355; }
        else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 2803; }
        else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 697; }
        else if (Number(this.commonInput.term) == 25) { this.quotes[i].productid = 2687; }
        this.quotes[i].riders = 'Accelerated death benefit rider,Available at extra cost<br>Children’s Term Rider,Available at extra cost<br>Waiver of Premium Rider,Available at extra cost<br>Terminal Illness,Available at extra cost<br>Critical Illness or Critical Injury,Available at extra cost<br>Waiver of Premium Rider (WP),Available at extra cost';
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }


      else if (this.quotes[i].carrier['name'] === 'Corebridge Financial') {
        this.quotes[i]['carrier_details'] = this.carrierJsonData.filter(x => x.product_name.includes('SAT -'));
        this.quotes[i]['product'].name = `SAT - ${this.commonInput.term}`;
        this.quotes[i].ourproducts = false;
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 303; }
        else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 344; }
        else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 11; }
        else if (Number(this.commonInput.term) == 25) { this.quotes[i].productid = 139; }
        else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 545; }
        else if (Number(this.commonInput.term) == 35) { this.quotes[i].productid = 2530; }
        this.quotes[i].riders = 'Accidental Death Benefit (ADB) Rider,Available at extra cost<br>Accelerated Access Solution (AAS),Available at extra cost<br> Chronic Illness Rider,Available at extra cost';
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }

      else if (this.quotes[i].carrier['name'] === 'Transamerica') {
        this.quotes[i].riders = 'Accidental Death Benefit (ADB) Rider,Available at extra cost<br>Children’s Benefit Rider/ Children’s Insurance Rider (CBR/CIR)**,Available at no extra cost<br>Disability Waiver Of Premium (DWP) Rider**,Available at extra cost<br>Income Protection Option (IPO) Ride,Available at extra costr<br>Terminal Illness Rider/ Accelerated Death Benefit,Available at no extra cost';
        if (this.quotes[i].product['name'] === `Trendsetter LB ${this.commonInput.term}`) {
          this.quotes[i]['carrier_details'] = this.carrierJsonData.filter(x => x.product_name.includes('Trendsetter LB'));
          this.quotes[i]['product'].name = `Trendsetter LB ${this.commonInput.term}`;
          this.quotes[i].ourproducts = false;
          this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
          if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 502; }
          else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 167; }
          else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 441; }
          else if (Number(this.commonInput.term) == 25) { this.quotes[i].productid = 217; }
          else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 716; }
          this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
          this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        }
        else {
          this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
          this.quotes[i]['carrier_details'] = this.carrierJsonData.filter(x => x.product_name.includes('Trendsetter Super'));
          this.quotes[i]['product'].name = `Trendsetter Super (${this.commonInput.term} Yrs)`;
          if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 425; }
          else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 480; }
          else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 531; }
          else if (Number(this.commonInput.term) == 25) { this.quotes[i].productid = 573; }
          else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 640; }
          this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
          this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        }
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }
      else if (this.quotes[i].carrier['name'] === 'North American') {
        this.quotes[i].riders = 'Accidental Death Benefit (ADB) Rider,Available at extra cost<br>Waiver of Term Premium for Disability Rider,Available at extra cost<br>Waiver of Monthly Deductions Rider,Available at extra cost';
        this.quotes[i].ourproducts = false;
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 440; }
        else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 499; }
        else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 550; }
        else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 610; }
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }
      else if (this.quotes[i].carrier['name'] === 'Prudential Financial') {
        this.quotes[i].ourproducts = false;
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        if (Number(this.commonInput.term) == 10) { this.quotes[i].productid = 585; }
        else if (Number(this.commonInput.term) == 15) { this.quotes[i].productid = 649; }
        else if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 665; }
        else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 579; }
        this.quotes[i].riders = 'Accidental Death Benefit (ADB) Rider,Available at extra cost<br> Children\'s protection rider,Available at extra cost <br> Living needs benefit rider,Available at extra cost<br> Waiver of premium rider,Available at extra cost';
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }
      else if (this.quotes[i].product['name'] === `${this.commonInput.term} Year Return of Premium Term`) {
        this.quotes[i].riders = 'Accelerated death benefit Rider,Available at no extra cost<br>Accident Only Disability Income Rider,Available at extra cost <br>Children\'s term rider,Available at extra cost<br>Critical Illness Benefit Rider,Available at extra cost<br>Disability Waiver of Premium Rider,Available at extra cost<br>Endowment Benefit Rider,Available at extra cost <br>Monthly Income Benefit Rider,Available at extra cost <br>Other insured level term rider,Available at extra cost';
        this.quotes[i]['carrier_details'] = this.carrierJsonData.filter(x => x.product_name.includes('Year Return of Premium Term'));
        this.quotes[i]['product'].name = `${this.commonInput.term} Year Return of Premium Term`;
        this.quotes[i].ourproducts = false;
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        if (Number(this.commonInput.term) == 20) { this.quotes[i].productid = 2788; }
        else if (Number(this.commonInput.term) == 30) { this.quotes[i].productid = 2787; }
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }
      else {
        this.quotes[i].pdflink = this.quotes[i].link_to_pdf;
        this.quotes[i]['carrier_details'] = this.carrierJsonData[length - 1];
        this.quotes[i].lifeflowredirecturl = `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
        this.quotes[i].redirectUrl = (this.insurancetype === 'homeLife') ? `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}` : `applicationflow?clientid=${this.clientid}&firstname=${this.commonInput.firstName}&lastname=${this.commonInput.lastName}&state=${this.commonInput.state}&gender=${this.commonInput.gender}&health=${this.commonInput.health}&smoker=${this.commonInput.smoker}&height=${this.commonInput.back9hight}&weight=${this.commonInput.weight}&dob=${this.commonInput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commonInput.phone}&zip=${this.commonInput.zip}&birth_state_or_country=${this.commonInput.state}&selectedtype=${this.commonInput.selectedtype}&producttypes=${this.commonInput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commonInput.mode}&term_duration=${this.commonInput.term}&coverage=${this.commonInput.coverage}`;
      }
      if (this.quotes[i].carrier['name'] == 'W&S') {
        this.quotes[i].coverage = this.commonInput.wnscoverage;
        this.quotes[i].pdflink = 'https://www.getmybubble.co/wns/';
        this.quotes[i].riders = '';
      }
      else if (this.quotes[i].carrier['name'] == 'SBLI') {
        this.quotes[i].coverage = this.commonInput.coverage;
        this.quotes[i].pdflink = 'https://www.getmybubble.com/SBLI/';
        this.quotes[i].riders = 'Accelerated death benefit rider,Available at no extra cost';
      }
      else {
        this.quotes[i].coverage = this.commonInput.coverage;
      }
    }

    this.quotes.reverse();
    this.returnofprmium = this.quotes.filter(x => x.product['name'] === `${this.commonInput.term} Year Return of Premium Term`);
    this.wnsproduct = this.quotes.filter(x => x.carrier['name'] == 'W&S');
    const sbliandwns = this.quotes.filter(x => x.carrier['name'] == 'W&S' || x.carrier['name'] == 'SBLI');
    const back9quotes = this.quotes.filter(x => x.carrier['name'] != 'W&S' && x.carrier['name'] != 'SBLI');
    let onlyafficeny;
    let combinedquotes = [];
    if (sbliandwns?.length == 1) {
      onlyafficeny = sbliandwns[0].carrier['name'];
    }
    combinedquotes = sbliandwns?.length > 1 ? back9quotes ? combinedquotes.concat(sbliandwns.reverse(), back9quotes.slice(0, 1)) : sbliandwns.reverse() : onlyafficeny ? combinedquotes.concat(sbliandwns, back9quotes.slice(0, 2)) : this.quotes?.slice(0, 3);
    this.filterdquotes = JSON.stringify(this.quotes);
    this.changepolicyType(this.policytype, 1);
    this.topthreelessamountquotes = combinedquotes;
    this.ridersloop = this.getcommaseparted();
    this.ridershtml = this.ridersdataloop();
    const lowest_premium_amount = this.quotes[0]?.premium;
    const lowestpremiumcarrier = this.quotes[0]?.product.name;
    console.log('finalised Quotes', this.quotes);
    const carrierWithPrice = {};
    const month = this.payfrequency == 1 ? 'day' : this.payfrequency == 2 ? 'month' : 'year';
    this.quotes.forEach(function (item, index) {
      carrierWithPrice[item.product.name] = `${item.premium}/${month}`;
    });
    carrierWithPrice['coverage'] = this.commonInput?.coverage;
    carrierWithPrice['term'] = this.commonInput?.termtodisplay;

    console.log(' carrierWithPrice', carrierWithPrice);
    const hubSpotData = {
      application_stage: 'Quote',
      user_landed_reco_page: 'Yes',
      email: this.commonInput.emailid,
      lowest_premium_amount: lowest_premium_amount,
      lowest_premium_carrier: lowestpremiumcarrier,
      all_recommended_quotes__life_: JSON.stringify(carrierWithPrice),
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    setTimeout(() => {
      const hubSpotData = {
        email: this.commonInput.emailid,
        life_coverage_comparison_html: document.getElementById('quotehtml').innerHTML,
        HTML_Lowest3_Life_carriers_Array: JSON.stringify(this.topthreelessamountquotes)
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }, 10000);
    if (calledfrom == 1) {
      const allquotes = quotesData;
      allquotes.push(await this.getQuotess(commonInput, this.sbli, this.wns));
      this.prepareQuotes(allquotes, payfrequnecy, type, 0, commonInput);
      this.allquotes = allquotes;
    }
    console.log("final Quotes", this.quotes)
  }


  modalReference: any;
  openVerticallyCentered(modal, content, premium, product) {

    this.modalReference = this.modalService.open(modal, { centered: true, backdrop: 'static', keyboard: false, windowClass: 'reco-modal' });
    this.modal_data = content;
    this.rating = this.modal_data.rating ? this.modal_data.rating : 'A++';
    this.carrier_price = premium;
    this.product_name = product;
  }

  personalDetailsPopup(event?: any) {

    this.pc.lifesummary({ 'new_recco_edit_btn_click': 'Yes' });
    console.log('this.commonInput', this.commonInput);
    this.modalopened = event ? false : true;
    const modalRef = this.modalService.open(KoPersonalPopupComponent, { centered: true, backdrop: 'static', keyboard: false });
    const data =
      this.allDetailsAvailable ?
        { emailid: this.commonInput.emailid, phone: this.commonInput.phone, dob: this.commonInput.dob, sbligender: this.appData.gender, zipcode: this.commonInput.zip, tobaccoUsage: this.appData.tobaccoUsage, heightFt: this.appData.bubble_heightFt, heightIn: this.appData.bubble_heightIn, weight: this.commonInput.weight, coverage: this.commonInput.coverage, termLength: this.commonInput.term }
        : { emailid: this.clientData.bubble_email ? this.clientData.bubble_email : this.clientData.email, phone: this.clientData.bubble_phone ? this.clientData.bubble_phone : this.clientData.phone, dob: this.clientData.dob, sbligender: this.clientData.gender, zipcode: this.clientData.zip ? this.clientData.zip : this.clientData.zip_code, tobaccoUsage: this.clientData.tobaccoUsage, heightFt: this.clientData.bubble_heightFt, heightIn: this.clientData.bubble_heightIn, weight: this.clientData.bubble_weight, coverage: this.clientData.desired_life_coverage, termLength: this.clientData.desired_life_term };
    modalRef.componentInstance.personalDetails = data;
    console.log('___data', JSON.stringify(data));
    console.log('this.clientData', this.clientData);
    modalRef.componentInstance.summary = event;
    modalRef.componentInstance.detailsAvailable = this.allDetailsAvailable;
    modalRef.componentInstance.clientid = this.clientid;
    modalRef.componentInstance.callApi.subscribe(($e) => {
      this.modalopened = false;
      this.loader = true;
      this.allDetailsAvailable = true;
      setTimeout(() => {
        this.getUserDetails();
        this.popUpApiCall = true;
      }, 1000);
    });
  }

  async koQuestionsPopup(quote) {

    const details = JSON.stringify(this.allquotes[1][(quote.carrierId == 'sbli') ? 0 : 1]);
    localStorage.setItem('selectedCarrier', details);
    localStorage.setItem('selectedLifeCarrier', details);
    localStorage.setItem('uniqueIdDQ', this.uniqueId);
    localStorage.setItem('clientIdDQ', this.clientid);
    localStorage.setItem('recopageurl', `SelectLifeCarrierV2/${this.uniqueId}/${this.clientid}/${this.displayAddress}/life`);
    if (quote.carrierId === 'sbli') {
      localStorage.setItem('carrierDQ', 'SBLI');
    }
    else {
      localStorage.setItem('carrierDQ', 'WNS');
    }
    localStorage.setItem('stateDQ', this.commonInput.state);
    localStorage.setItem('displayAddressDQ', this.displayAddress);
    localStorage.setItem('insuranceTypeDQ', 'life');
    this.wnssbliRediecturl = '/dynamic-questions/questions';
    //this.wnssbliRediecturl = quote.carrierId == 'sbli' ? `lifeCarrierTwo/${this.commonInput.uniqueId}/${this.commonInput.clientid}/${this.commonInput.displayAddress}/${this.commonInput.insurancetype}` : `lifeCarrierThree/${this.commonInput.uniqueId}/${this.commonInput.clientid}/${this.commonInput.displayAddress}/${this.commonInput.insurancetype}`;
    console.log(quote.carrierId + 'Rediecturl ', this.wnssbliRediecturl);
    setTimeout(async () => {
      // const el: HTMLElement = this.wnssbliRediect.nativeElement;
      // el.click();
      this.pc.lifesummary({ 'selected_product': quote.carrierId == 'sbli' ? 'Simplified Issue Term Life' : 'Western & Southern' });
      const hubSpotData = {
        email: localStorage.getItem('bubble-email'),
        life_latest_premium_amount: `$${quote.premium}`,
        life_quote_amount: `$${quote.premium}`,
        application_stage: 'Application',
        life_insurance_carrier: quote.carrierId == 'sbli' ? 'Simplified Issue Term Life' : 'Western & Southern'
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }, 50);
    if (quote.carrierId == 'sbli') {
      const json = {
        'id': this.clientid,
        'age': this.age.toString(),
        'selectedLife': 'sbli',
        'selectedCarrier': details,
        'displayAddress': this.displayAddress,
        'unique_id': this.uniqueId,
        'coverage': this.coverage
      };
      await this.pc.saveUpdateClientData(json);

    }
    else {
      const json = {
        'id': this.clientid,
        'age': this.age.toString(),
        'selectedLife': 'wns',
        'selectedCarrier': details,
        'displayAddress': this.displayAddress,
        'unique_id': this.uniqueId,
        'wns_coverage': localStorage.getItem('bubble-wnscoverage')
      };
      await this.pc.saveUpdateClientData(json);

    }
  }


  async refreshQuotes(id = null) {

    if (id != null) {
      this.policytype = id;
      document.getElementById('coverageError').style.display = 'none';
      this.modalopened = false;
    }
    if (this.policytype == '4' && ![20, 30].includes(this.termLength)) {
      document.getElementById('coverageError').style.display = 'block';
      document.getElementById('coveragemodal').style.display = 'none';
      this.modalopened = true;
    }
    else {
      this.carrierData = [];
      this.pc.lifesummary({ 'coverage_refresh': 'Yes' });
      document.getElementById('coveragemodal').style.display = 'none';

      this.modalopened = false;
      if (this.commonInput.coverage != this.coverage || this.commonInput.term != this.termLength) {
        this.coverageforslider = this.commonInput.coverage;
        this.disablerefreshbutton = true;
        this.loader = true;
        localStorage.setItem('bubble-termLength', this.termLength);
        localStorage.setItem('bubble-coverage', this.coverage);
        const savedata = {
          'id': this.clientid,
          'clientId': this.clientid,
          'coverage': this.coverage,
          'term': this.termLength,
          'desired_life_term': this.termLength,
          'desired_life_coverage': this.coverage
        };
        this.popUpApiCall = false;
        await this.pc.saveUpdateClientData(savedata);
        const hubSpotData = {
          email: localStorage.getItem('bubble-email'),
          desired_life_term: this.termLength.toString(),
          desired_life_coverage: this.coverage.toString(),
          coverage_term_life: this.coverage.toString()
        };
        await this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
        this.getUserDetails();
      }
    }

  }

  ngAfterContentChecked() {
    this.cdref?.detectChanges();

  }



  public getpayFrequency(even) {

    this.payfrequency = even.target.value;
    this.carrierData = [];
    this.disableCompareButton = true;
    this.prepareQuotes(this.allquotes, even.target.value, 2, 0);
  }

  sortQuotes(even, id) {
    if (id == 1) {
      this.sortby = even;
    }
    else {
      this.sortby = even.target.value;
      this.pc.lifesummary({ 'sort': (this.sortby == 1) ? 'Recommended' : (this.sortby == 2) ? 'Premium (low-high)' : 'Premium (high-low)' });
    }
    if (this.sortby == '1') {
      this.quotes = (this.policytype == '1') ? JSON.parse(this.filterdquotes) : this.quotes;
      if (this.appData.filterSbliWns == 'true') {
        this.quotes = this.quotes.filter(x => x.carrierId != 'sbli' && x.carrierId != 'wns');
      }
    }
    else if (this.sortby == '2') {
      this.quotes = this.quotes.sort((a, b) => a.premium - b.premium);
      if (this.appData.filterSbliWns == 'true') {
        this.quotes = this.quotes.filter(x => x.carrierId != 'sbli' && x.carrierId != 'wns');
      }
    }
    else {
      this.quotes = this.quotes.sort((a, b) => b.premium - a.premium);
      if (this.appData.filterSbliWns == 'true') {
        this.quotes = this.quotes.filter(x => x.carrierId != 'sbli' && x.carrierId != 'wns');
      }
    }
  }

  public changepolicyType(even, id) {
    this.carrierData = [];

    if (id == 1) {
      this.policytype = even;
    }
    else {
      this.policytype = even.target.value;
      this.pc.lifesummary({ 'sort': (this.policytype == 1) ? 'All policies' : (this.policytype == 2) ? 'Term Life' : (this.policytype == 3) ? 'Decreasing Term' : 'Return of premium' });
    }
    if (this.policytype == '1') {
      this.quotes = JSON.parse(this.filterdquotes);
      if (this.appData.filterSbliWns == 'true') {
        this.quotes = this.quotes.filter(x => x.carrierId != 'sbli' && x.carrierId != 'wns');
      }
    }
    else if (this.policytype == '2') {
      this.quotes = JSON.parse(this.filterdquotes);
      this.quotes = JSON.parse(this.filterdquotes).filter(x => x.carrier['name'] === 'Banner' || x.carrier['name'] === 'Next By Pacific Life' || x.carrier['name'] === 'SBLI' || x.carrier['name'] === 'Transamerica' || x.carrier['name'] === 'North American' || x.carrier['name'] === 'Prudential Financial' || x.carrier['name'] === 'Nationwide' || x.product['name'] === `Classic Choice Term ${this.commonInput.term}` || x.carrier['name'] === 'Pacific Life - Lynchburg' || x.carrier['name'] === 'Corebridge Financial' || x.carrier['name'] === 'Life Insurance Company of the Southwest');
      if (this.appData.filterSbliWns == 'true') {
        this.quotes = this.quotes.filter(x => x.carrierId != 'sbli' && x.carrierId != 'wns');
      }
    }
    else if (this.policytype == '3') {
      this.quotes = JSON.parse(this.filterdquotes).filter(x => x.carrier['name'] === 'W&S');
      if (this.appData.filterSbliWns == 'true') {
        this.quotes = this.quotes.filter(x => x.carrierId != 'wns');
      }
    }
    else if (this.policytype == '4') {
      this.quotes = JSON.parse(this.filterdquotes).filter(x => x.product['name'] === `${this.commonInput.term} Year Return of Premium Term`);
      // if (this.quotes.length == 0) {
      //   this.quotes = JSON.parse(this.filterdquotes);
      //   if (this.appData.filterSbliWns == 'true') {
      //     this.quotes = this.quotes.filter(x => x.carrierId != 'sbli' && x.carrierId != 'wns');
      //   }
      // }
    }
    else if (this.policytype == '5') {
      this.quotes = JSON.parse(this.filterdquotes).filter(x => x.carrier['name'] === 'W&S' || x.carrier['name'] === 'Banner' || x.carrier['name'] === 'SBLI');
      if (this.appData.filterSbliWns == 'true') {
        this.quotes = this.quotes.filter(x => x.carrierId != 'sbli' && x.carrierId != 'wns');
      }
    }
    else if (this.policytype == '6') {
      this.quotes = JSON.parse(this.filterdquotes).filter(x => x.product['name'] === `${this.commonInput.term} Year Return of Premium Term` || x.carrier['name'] === 'W&S' || x.carrier['name'] === 'SBLI' || x.carrier['name'] === 'Transamerica' || x.carrier['name'] === 'North American' || x.carrier['name'] === 'Prudential Financial' || x.carrier['name'] === 'Pacific Life - Lynchburg');
      if (this.appData.filterSbliWns == 'true') {
        this.quotes = this.quotes.filter(x => x.carrierId != 'sbli' && x.carrierId != 'wns');
      }
    }
    else {
      this.quotes = JSON.parse(this.filterdquotes).filter(x => x.carrier['name'] === 'W&S' || x.carrier['name'] === 'Banner' || x.carrier['name'] === 'SBLI' || x.carrier['name'] === 'North American' || x.carrier['name'] === 'Prudential Financial' || x.carrier['name'] === 'Nationwide' || x.product['name'] === `Classic Choice Term ${this.commonInput.term}` || x.carrier['name'] === 'Pacific Life - Lynchburg' || x.carrier['name'] === 'Life Insurance Company of the Southwest');
      if (this.appData.filterSbliWns == 'true') {
        this.quotes = this.quotes.filter(x => x.carrierId != 'sbli' && x.carrierId != 'wns');
      }
    }
    this.sortQuotes(this.sortby, 1);
    // this.carrierData = [];
    this.disableCompareButton = true;
  }

  public sliderEvent(even) {

    this.coverage = even.value;
    this.coverageforslider = even.value;
    this.disablerefreshbutton = false;
  }
  public sliderEvent1(even) {

    this.termLength = even.value;
    this.disablerefreshbutton = false;
  }

  openVerticallyCentered2() {
    this.modalopened = true;
    document.getElementById('coveragemodal').style.display = 'block';
  }
  public closemodal() {
    this.modalopened = false;
    document.getElementById('coveragemodal').style.display = 'none';
  }

  public async Savedata(qts) {

    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_latest_premium_amount: `$${qts.premium}`,
      life_quote_amount: `$${qts.premium}`,
      life_insurance_carrier: `${qts.carrier.name}_${qts.product.name}`
    };
    await this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
  }

  public closepopup() {
    this.schedulecall = false;
    this.modalopened = false;
    // document.getElementById('back9modal').style.display = 'none';
    this.appointmentdate = '';
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';
    this.dateValidation = '';
    this.modal.dismissAll();
  }
  public scheduleacall() {
    this.schedulecall = true;
  }
  callBack() {
    this.pc.lifesummary({ 'callback_request': 'yes' });
    //this.schedulecall = true;
    this.callback_time = `${this.callback_hour}:${this.callback_minutes}:${this.selectedmeridum}`;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      callback_request__life_: 'Yes',
      appointment_date: this.appointmentdate,
      callback_time: this.callback_time,
      application_stage: 'Application',
      life_insurance_carrier: this.carrierName
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    // document.getElementById('back9modal').style.display = 'none';
    this.modal.dismissAll();
    this.popupclosed = false;
    document.getElementById('thankyouModalreco').style.display = 'block';
    this.appointmentdate = '';
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';
    this.dateValidation = '';
  }
  public callnow() {
    this.pc.lifesummary({ 'call_initiated': 'yes' });
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      call_initiated_by_life_user: 'Yes',
      application_stage: 'Application',
      life_insurance_carrier: this.carrierName
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
  }

  public async applyOnline() {

    let back9details;

    let details;
    ['SBLI', 'W&S'].includes(this.carrierName) ? this.koQuestionsPopup(this.quotesData) : (back9details = {
      'carrierId': '', 'head': { 'carrier_name': `${this.quotesData.carrier.name}`, 'quote_amount': `${this.quotesData.premium}`, 'carrier_icon': `${this.quotesData.carrier.avatar_url}` }, 'body': { 'points': [] }, 'selectedDetails': {
        'term': `${this.quotesData.term}`, 'coverage': `${this.quotesData.coverage}`, 'title': 'Key Benefits', 'selectedDetails': {
          'title': 'Key Benefits',
          'text': 'An easy online application that you can start and finish in minutes, with a decision on the spot.',
          'key_benefits': [],
          'footer_text': [
            {
              'text': 'Policies are issued by The Savings Bank Mutual Life Insurance Company of Massachusetts (“SBLI”) on policy form series 21-P-SIT. Centrian Life Insurance is a registered trade name and Connecticut d/b/a of SBLI. Policies sold in the state of Connecticut will be issued under Centrian Life Insurance ("Centrian*"). The premium quotes displayed here are non-binding and based on some preliminary personal information. The final insurance policy premium for any policy is determined following the applicant completing the full application process.'
            }
          ]
        }
      }
    }, back9details.body.points = this.quotesData.carrier_details[0]?.check_points, back9details.selectedDetails.key_benefits = this.quotesData.carrier_details[0]?.included_benefits, details = JSON.stringify(back9details), localStorage.setItem('selectedCarrier', details), localStorage.setItem('selectedLifeCarrier', details));
    localStorage.setItem('bubble-QouteAmount', this.quoteamount);
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_latest_premium_amount: this.quoteamount,
      life_quote_amount: this.quoteamount,
      life_insurance_carrier: this.carrierName
    };
    await this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    const json = {
      'id': this.clientid,
      'lifeflowurl': this.liferedirecturl
    };
    await this.pc.saveUpdateClientData(json);
    this.pc.lifesummary({ 'selected_product': this.carrierName });
    this.modal.dismissAll();
    // document.getElementById('back9modal').style.display = 'none';
    this.modalopened = false;
  }

  public closeschedule() {
    this.schedulecall = false;
    this.appointmentdate = '';
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';
    this.dateValidation = '';
  }

  public closethankyou() {
    this.modalopened = false;
    this.schedulecall = false;
    document.getElementById('thankyouModalreco').style.display = 'none';
  }
  closecoverage() {
    this.modalopened = false;
    document.getElementById('coverageError').style.display = 'none';
  }
  public getHours(even) {

    if (even.target.value == '10' || even.target.value == '11' || even.target.value == '08' || even.target.value == '09') {
      if (even.target.value == '08') {
        this.minutes = ['30'];
      }
      else {
        this.minutes = ['00', '30'];
      }
      this.selectedmeridum = 'AM';
    }
    else {
      if (even.target.value == '07') {
        this.minutes = ['00'];
      }
      else {
        this.minutes = ['00', '30'];
      }
      this.selectedmeridum = 'PM';
    }
  }
  mindate: any;
  async showback9popup(qts, back9Modal) {

    this.quotesData = qts;
    this.modalopened = true;
    const d = new Date();
    const day = d.getDay();
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    if (day < 6 && day > 0 && hours >= 8 && hours < 20) {
      if (hours == 19 && minutes >= 30) {
        this.Online = false;
      } else if (hours == 8 && minutes >= 30) {
        this.Online = true;
      }
      else {
        this.Online = true;
      }
    } else {
      this.Online = false;
    }
    if (qts.carrierId) {
      this.carrierName = qts['carrier'].name;
    }
    else {
      this.carrierName = `${qts['carrier'].name}_${qts['product'].name}`;
    }
    this.quoteamount = qts.premium;
    this.mindate = new Date();
    this.firstname = this.commonInput.firstName;
    this.lastname = this.commonInput.lastName;
    this.phone = this.commonInput.phone;
    this.coveragedisplay = qts.coverage;
    this.termdisplay = qts.term;
    this.emailid = localStorage.getItem('bubble-email');
    this.productname = qts.product['name'];
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';
    this.dateValidation = '';
    this.modal.open(back9Modal, { centered: true, backdrop: 'static', keyboard: false });
    const idData = await this.pc.getAppData(this.clientid);
    const resumedata = JSON.parse(idData?.Payload)?.body;
    this.redirecturl = (resumedata?.appResumeData?.resumeUrl.includes('lifeCarrierTwo') && qts?.carrierId == 'sbli') ? resumedata.appResumeData.resumeUrl : (resumedata?.appResumeData?.resumeUrl.includes('lifeCarrierThree') && qts?.carrierId == 'wns') ? resumedata.appResumeData.resumeUrl : qts?.redirectUrl;
    localStorage.setItem('currentGroupIndex', qts?.carrierId == 'sbli' ? resumedata.sbli_questionNumber ? resumedata.sbli_questionNumber : '0' : resumedata.wns_questionNumber ? resumedata.wns_questionNumber : '0');
    this.liferedirecturl = qts.lifeflowredirecturl;
    this.selectedmeridum = 'PM';

  }

  _appointmentdate(appointmentdate) {

    const day = new Date(appointmentdate).getDay();
    if (day == 6 || day == 0) {
      this.dateValidation = 'Sat/Sun (Not Available)';
    } else {
      this.dateValidation = '';
    }

  }
  public seeMore(details) {

    if (details.seemore) {
      details.seemore = false;
      details.type_text = 'View more >';
    }
    else {
      details.seemore = true;
      details.type_text = 'View Less <';
    }
  }


  async openDrawer() {

    const alldata = [];
    for (let m = 0; m < this.carrierData.length; m++) {
      alldata.push.apply(alldata, this.carrierData[m].comparerawriders);
    }
    const unique = [...new Set(alldata.map(item => item))];
    this.ridersdata = await this.GetridersData(unique);
    const options: NgbModalOptions = {
      modalDialogClass: 'modalFullScreen',
      backdropClass: 'modalBackDrop',
      keyboard: false,
      backdrop: 'static'
    };
    this.carrierData[0].Allridersdata = this.ridersdata;
    const modalRef = this.modalService.open(DrawerComponent, options);
    modalRef.componentInstance.carrierData = this.carrierData;
    modalRef.componentInstance.payfrequency = this.payfrequency;

    modalRef.result.then((quote) => {
      if (quote) {
        this.showback9popup(quote, this.back9Modal);
      }
    });

  }


  public GetridersData(rawriders) {

    const ridersdata = [];
    for (let y = 0; y < rawriders.length; y++) {
      const obj = {};
      const val = [];
      for (let n = 0; n < this.carrierData.length; n++) {
        if (this.carrierData[n].riders.includes(rawriders[y])) {
          const indexxs = this.carrierData[n].comparerawriders.filter(x => x.includes(rawriders[y]));
          if (indexxs[0].split(',')[1] == 'Available at no extra cost') {
            val.push(rawriders[y].split(',')[1]);
          }
          else {
            val.push(rawriders[y].split(',')[1]);
          }

        }
        else {
          val.push('Not Included');
        }
      }
      obj['coverage_name'] = rawriders[y].split(',')[0];
      obj['coverage_value'] = val;
      ridersdata.push(obj);
    }
    return ridersdata;
  }

  async changeValue(event, product) {

    if (this.addItem?.length > 3) {
      this.addItem.slice(1, 3);
    }
    this.addItem = await this.poupcount();
    if (event.target.checked && this.carrierData.length < 3) {
      const riders = product.riders.split('<br>');
      product.comparerawriders = riders;
      this.carrierData.push(product);
      this.disableCompareButton = this.carrierData.length > 3 || this.carrierData.length < 2 ? true : false;
      this.addItem.pop();
    }
    else if (!event.target.checked && this.carrierData.length < 4) {
      this.carrierData = this.carrierData.filter(x => x.product.name != product.product.name);
      this.disableCompareButton = this.carrierData.length >= 2 && this.carrierData.length < 4 ? false : true;
      this.poupcount();
    }
    else {
      event.target.checked = false;
    }
    console.log('this.addItem', this.addItem);
  }

  numberWithCommas(x) {
    const y = '$' + x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return y;
  }


  removeCompare(product_name) {

    this.carrierData = this.carrierData.filter(x => x.product.name != product_name);
    const x = document.getElementById(product_name) as HTMLElement;
    x.click();

  }
  async poupcount() {

    if (this.carrierData?.length >= 3) {
      this.addItem = [];
    }
    else if (this.carrierData?.length == 2) {
      this.addItem = ['Add a Plan'];
    }
    else if (this.carrierData?.length == 1) {
      this.addItem = ['Add a Plan', 'Add a Plan'];
    }
    else {
      this.addItem = ['Add a Plan', 'Add a Plan', 'Add a Plan'];
    }
    return this.addItem;
  }

  RemoveSelectedItems() {

    this.carrierData = [];
    this.checkedEvnt();
    this.addItem = ['Add a Plan', 'Add a Plan', 'Add a Plan'];
    // for (const carrierSelected of this.carrierData) {
    //   this.removeCompare(carrierSelected.carrier.name);
    // }

  }

  onResize(e) {

    const compareplanWrap = document.querySelector('#compareplanWrap');
    const compStyles = window.getComputedStyle(compareplanWrap);
    this.footerHeight = compStyles.getPropertyValue('height');
  }


  public arraymove(arr, fromIndex, toIndex) {

    const element = arr[fromIndex];
    arr = [];
    arr[fromIndex] = undefined;
    arr[toIndex] = element;
    this.loader = false;
    this.showLoader = false;
    return arr;
  }
  checkedEvnt() {

    this.checkboxes.forEach((element) => {
      element.nativeElement.checked = false;
    });
  }

  getcommaseparted() {

    let allriders = [];
    const rawridersarray = [];
    let ridersdata = '';
    for (let y = 0; y < this.topthreelessamountquotes.length; y++) {
      const riders = this.topthreelessamountquotes[y].riders.split('<br>');
      const rawriders = this.topthreelessamountquotes[y].riders.split('<br>');
      for (let v = 0; v < riders.length; v++) {
        if (riders[v].includes(',')) {
          riders[v] = riders[v].split(',')[0];
        }
      }
      if (riders.length > 0 && riders[0] != '') {
        allriders.push.apply(allriders, riders);
      }
      if (rawriders.length > 0 && rawriders[0] != '') {
        rawridersarray.push.apply(rawridersarray, rawriders);
      }
      if (rawriders.length > 0) {
        this.topthreelessamountquotes[y].rawriders = rawridersarray;
        this.topthreelessamountquotes[y].rawriders = this.topthreelessamountquotes[y].rawriders.filter(function (item, index, inputArray) { return inputArray.indexOf(item) == index; });
      }
      else {
        this.topthreelessamountquotes[y].rawriders = '';
      }
    }

    allriders = allriders.filter(function (item, index, inputArray) { return inputArray.indexOf(item) == index; });
    this.allriders = allriders;
    for (let m = 0; m < allriders.length; m++) {
      ridersdata += `<p style="padding:5px 3px;color:#646464;"> ${allriders[m]}</p>`;
    }
    return ridersdata;
  }


  ridersdataloop() {

    let riderhtml = '';
    for (let u = 0; u < this.topthreelessamountquotes.length; u++) {
      riderhtml += `<td style="padding:8px 5px;border-right:1px solid #dae7ec;word-break:normal">
     ${this.includedloop(this.topthreelessamountquotes[u].riders, u)}
     </td>`;
    }
    return riderhtml;
  }


  includedloop(riders, index) {

    this.topthreelessamountquotes[index].includeddata = '';
    for (let b = 0; b < this.allriders.length; b++) {
      if (riders.includes(this.allriders[b]) && riders != '') {
        if (b < this.topthreelessamountquotes[index].rawriders.length) {
          if (this.topthreelessamountquotes[index].rawriders[b].includes(',')) {
            if (this.topthreelessamountquotes[index].rawriders[b].split(',')[1] == 'Available at no extra cost') {
              this.topthreelessamountquotes[index].includeddata += '<p style="padding:5px 3px;color:#003399;">Available at no extra cost</p>';
            }
            else {
              this.topthreelessamountquotes[index].includeddata += '<p style="padding:5px 3px;color:#003399;">Available at extra cost</p>';
            }
          }
          else {
            this.topthreelessamountquotes[index].includeddata += '<p style="padding:5px 3px;color:#003399;">Included</p>';
          }
        }
        else {
          const indexxs = this.topthreelessamountquotes[index].rawriders.filter(x => x.includes(this.allriders[b]));
          if (indexxs[0].split(',')[1] == 'Available at no extra cost') {
            this.topthreelessamountquotes[index].includeddata += '<p style="padding:5px 3px;color:#003399;">Available at no extra cost</p>';
          }
          else {
            this.topthreelessamountquotes[index].includeddata += '<p style="padding:5px 3px;color:#003399;">Available at extra cost</p>';
          }

        }

      }
      else {
        this.topthreelessamountquotes[index].includeddata += '<p style="padding:5px 3px;color:#003399;">Not Included</p>';
      }
    }
  }
}
