import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ViewChild,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';

@Component({
  selector: 'app-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.css'],
})
export class AddressInputComponent implements OnInit, OnChanges {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options =
    '{types: [\'address\', \'place\'], componentRestrictions: { country: \'USA\'}}';

  @Output() addressSelected = new EventEmitter();
  @Output() addressEmpty = new EventEmitter();
  @Output() addressSubmitted = new EventEmitter<any>();
  @Input() defaultAddress;
  @Input() showError;
  @Input() label;
  @Input() type;
  @Input() todisable;
  @Input() isBtnDisabled: boolean;
  @Input() parent: string | undefined;

  address: string;

  constructor(
    private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService
  ) { }

  ngOnInit(): void {
    this.address = this.defaultAddress;
    this.showError = this.showError == 0 ? 0 : 1;
  }

  getHomeScoreData() {
    this.addressSubmitted.emit(this.type);
  }

  public handleAddressChange(address: Address) {
    this.addressSelected.emit(address);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'defaultAddress') {
        this.address = changes[property].currentValue;
      }
    }
  }

  checkEmptyAddress() {
    if (this.address == '') {
      this.addressEmpty.emit();
      this.showError = 1;
    }
  }
}
