import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-has-hiv-disease',
  templateUrl: './has-hiv-disease.component.html',
  styleUrls: ['./has-hiv-disease.component.css']
})
export class HasHivDiseaseComponent implements OnInit {
  @Output() nextquestion10 = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  SelectedOption:any;
  public GetOptionsyesno(data){
    
    this.SelectedOption=data;
    if(this.SelectedOption==0){
      document.getElementById('HIVDiseaseNo').style.border='solid 2px orange';
      document.getElementById('HIVDiseaseyes').style.border='solid 1px #dae7ec';
     
    }else{
     document.getElementById('HIVDiseaseyes').style.border='solid 2px orange';
     document.getElementById('HIVDiseaseNo').style.border='solid 1px #dae7ec';
    }
  }



  next() {
    let etty = {
      "questionNo": "10",
      "nextprev": 1
    }
    this.nextquestion10.emit(etty);
  }

}
