import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SecurityService {
  constructor() { }

  encryptCodes(content, passcode = '') {
    // console.log("Input:content: ", content);
    let passcodeLocal = passcode === '' ? environment.PASSCODESHA2048 : passcode;
    var result = []; var passLen = passcodeLocal.length;
    for (var i = 0; i < content.length; i++) {
      var passOffset = i % passLen;
      var calAscii = (content.charCodeAt(i) + passcodeLocal.charCodeAt(passOffset));
      result.push(calAscii);
    }
    // console.log("Before getUniform..result: ", result);
    return this.getUniformNumberString(result);
    // return JSON.stringify(result);
    // return result;
  }

  decryptCodes(codesArr, passcode = '') {
    let result = []; let str = '';
    // let codesArr = content.split('-');
    let passcodeLocal = passcode === '' ? environment.PASSCODESHA2048 : passcode;
    let passLen = passcodeLocal.length;
    for (let i = 0; i < codesArr.length; i++) {
      let passOffset = i % passLen;
      let calAscii = (codesArr[i] - passcodeLocal.charCodeAt(passOffset));
      result.push(calAscii);
    }
    for (let i = 0; i < result.length; i++) {
      let ch = String.fromCharCode(result[i]); str += ch;
    }
    return str;
  }


  getUniformNumberString(inputArray) {
    let array = [1];
    let numStr;
    inputArray.forEach((num) => {
      if (num < 10) {
        numStr = `00${num.toString()}`;
      } else if (num < 100) {
        numStr = `0${num.toString()}`;
      } else {
        numStr = num.toString();
      }
      // console.log("Input:number: ", num, " # numStr: ", numStr, " # number type: ", typeof num);
      array.push(numStr);
    });
    return array.join('');
  }

  getNumberArrayFrom(inputStr) {
    // console.log("inputStr:", inputStr);
    let outputArr = [];
    let tmp = "";
    let count = 0;

    for (let i = 1; i < inputStr.length; i++) {
      tmp += inputStr.charAt(i)
      count++;

      if (count === 3) {
        outputArr.push(Number(tmp));
        count = 0;
        tmp = "";
      }
    }
    return outputArr;
  }

}
