import { Component, OnInit,Output ,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-sbli00651130',
  templateUrl: './sbli00651130.component.html',
  styleUrls: ['./sbli00651130.component.css']
})
export class Sbli00651130Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  next() {
    debugger
    let etty = {
      "next": 1,
      "previous": 0,
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
