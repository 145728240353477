import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-welcome-to-pac-life',
  templateUrl: './welcome-to-pac-life.component.html',
  styleUrls: ['./welcome-to-pac-life.component.css']
})
export class WelcomeToPacLifeComponent implements OnInit {

  constructor(private $gaService: GoogleAnalyticsService,
    private utilityService:UtilityFunctionsService) { }
  @Output() public welcomeToPacLife =
    new EventEmitter<{ component_id: any }>();
  ApllicationID: any;
  url: any;
  ngOnInit(): void {
    this.ApllicationID = localStorage.getItem('bubble-ApplicationID');
    this.url = window.location.href;
    if (this.url.includes('Appflowstart')) {
      localStorage.setItem('insurancetypeText', 'Life Application');
    }

  }


  public onSubmit() {

    this.welcomeToPacLife.emit({ component_id: 'welcome-to-pac-life' });
    const eventOptions: EventOption = {
      page_title: 'Lets get Started',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Lets get started', 'event', 'LifeAppStart');
    this.$gaService.event( 'Lets get started', 'event', 'LifeAppStart');
  }

}
