import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-has-emphysema',
  templateUrl: './has-emphysema.component.html',
  styleUrls: ['./has-emphysema.component.css']
})
export class HasEmphysemaComponent implements OnInit {
  @Output() nextquestion6 = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  SelectedOption:any;
  public GetOptionsyesno(data){
    
    this.SelectedOption=data;
    if(this.SelectedOption==0){
      document.getElementById('EmphysemaNo').style.border='solid 2px orange';
      document.getElementById('Emphysemayes').style.border='solid 1px #dae7ec';
     
    }else{
     document.getElementById('Emphysemayes').style.border='solid 2px orange';
     document.getElementById('EmphysemaNo').style.border='solid 1px #dae7ec';
    }
  }


  next() {
    let etty = {
      "questionNo": "6",
      "nextprev": 1
    }
    this.nextquestion6.emit(etty);
  }
}
