import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-personal-details-question2',
  templateUrl: './personal-details-question2.component.html',
  styleUrls: ['./personal-details-question2.component.css']
})
export class PersonalDetailsQuestion2Component implements OnInit {
  @Output() nextquestion1 = new EventEmitter();
  FirstName:any;
  LastName:any;
  EmailID:any;
  DateOfBirth:any;
  HomeAddress:any;
  City:any;
  State:any;
  Zipcode:any;
  constructor() { }

  ngOnInit(): void {
  }

  next() {
    let etty = {
      "questionNo": "1",
      "nextprev": 1
    }
    this.nextquestion1.emit(etty);
  }

}
