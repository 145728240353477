import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from "../../../appUtility/appUtility.js";
@Component({
  selector: 'app-sbli00701001',
  templateUrl: './sbli00701001.component.html',
  styleUrls: ['./sbli00701001.component.css']
})
export class Sbli00701001Component implements OnInit {
  questions: any;
  heightFt: any;
  heightIn: any;
  weight: any;
  isDisable: any;
  clientId: any;
  existingData: any;
  @Output() changeQuestion = new EventEmitter();
  showLoader: boolean = true;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger;

    this.clientId = localStorage.getItem('clientID');
    // this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli00701001")[0];
    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        let que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == "app-sbli00701001")[0];
        console.log("app-sbli00701001 questions : ", this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli00701001")[0];
        console.log("app-sbli00701001 questions : ", this.questions);
      }

      this.existingData = dataFrom.body.SBLI;

      if (this.existingData.sbli_height != undefined) {
        this.heightFt = this.existingData.sbli_height.split('ft')[0];
        this.heightIn = this.existingData.sbli_height.split('ft')[1].replace(/^.*?(\d+).*/, '$1')
        this.weight = this.existingData.sbli_weight.replace(/^.*?(\d+).*/, '$1');
      } else {
        this.heightFt = localStorage.getItem("bubble-heightFt");
        this.heightIn = localStorage.getItem("bubble-heightIn");
        this.weight = localStorage.getItem("bubble-weight");
      }


      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    console.log("app-sbli00601020 questions", this.questions);
  }

  next() {
    debugger;

    var json = {
      'id': this.clientId,
      SBLI: this.existingData

    }

    json.SBLI[this.questions.question_1.answer_key] = this.heightFt.trim() + ' ' + 'ft' + ' ' + this.heightIn.trim() + ' ' + 'in';
    json.SBLI[this.questions.question_2.answer_key] = this.weight.trim() + ' ' + 'lbs';

    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
