import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ValidationService } from 'src/app/shared/validation.service';
import { StorageHandlerService } from 'src/app/shared/storage-handler.service'; //, private storageService: StorageHandlerService
import { constants } from 'src/app/shared/storage-handler.service';
import {
  formatAmount,
  formatAmountCommaDollar,
} from '../../../shared/UtilityFunctions.js';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { formatDate } from '@angular/common';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
declare let require: any;
const pc = require('bit-uibl-data-driver');
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-details-confirmation-and-ssn',
  templateUrl: './details-confirmation-and-ssn.component.html',
  styleUrls: ['./details-confirmation-and-ssn.component.css']
})
export class DetailsConfirmationAndSSNComponent implements OnInit {
  message: string;
  addresstype: string;
  addressError: number;
  usageDuration1: string;
  usageDuration2: string;
  usageDuration3: string;
  usageDuration4: string;
  usageDuration5: string;
  disablestate: boolean;
  disablezipcode: boolean;
  whiteLoader: boolean;
  carrier: string;
  SSN: any;
  ssnLength: any;
  errormessage: string;
  showerrormessage: boolean;
  @Output() triggerpopup = new EventEmitter();
  hidenextbutton: boolean;
  constructor(
    private pc: ProcessCacheService,
    public validationService: ValidationService,
    private utilityService: UtilityFunctionsService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService,
    private storageService: StorageHandlerService,
    private modalService: NgbModal,
    private router: Router
  ) { }

  FirstName: any;
  LastName: any;
  prevEmailId: any;
  EmailID: any;
  PhoneNumber: any;
  DateOfBirth: any;
  Gender: any;
  Height: any;
  Weight: any;
  maleIcon = 'assets/images/active/icon-male-active.svg';
  femaleIcon = 'assets/images/inactive/icon-female-inactive.svg';
  maleIconactive = true;
  femaleIconactive = false;
  inputJson: any;
  curDate: any;
  minDate;
  is_valid: boolean;
  heightFt: any;
  heightIn: any;
  clientId: any;
  insurancetype: any;
  gender;
  addressToShow: any;
  showloader: boolean;
  existingData: any;
  homeAddress: any;

  commonAddress: any;
  city: any;
  displayAddress: any;
  termLength: any;
  state: any;
  zipcode: any;
  height: any;
  weight: any;
  coverageAmount: any;
  uniqueId: any;
  quickQuoteData: any;
  carrierName: any;
  selectedCardData: any;
  paclifeQuote: any;
  entertedAddress: any;
  addresserror: any;
  private unsubscriber: Subject<void> = new Subject<void>();
  @Output() changeQuestion = new EventEmitter();
  ngOnInit(): void {
    debugger;
    this.carrier = localStorage.getItem('carrierDQ');
    const updateJson = {
      questionSequence: 1,
      resumeUrl: this.router.url
    };

    this.checkforResumeInit(updateJson, 'update');
    document.getElementById('index_title_id').innerText = 'Life Insurance powered by Bubble';
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Application'
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    Object.keys(localStorage).forEach(function (key) {
      if (key.match('^dq_page'))
        localStorage.removeItem(key);
    });
    this.showloader = true;
    this.whiteLoader = true;
    this.counter = 0;
    this.addresserror = 'Address is required';
    this.storageService.setItem('premilumDirtyFlag', 'false');
    this.activatedroute.params.subscribe((params) => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];
      this.carrierName = params['carrierName'];
      this.addressToShow = this.displayAddress.replace(/-/g, ' ');
      this.storageService.setItem('displayAddress', this.displayAddress);
      this.storageService.setItem('carrierName', this.carrierName);
      this.addresstype = 'Home Address (No P.O. Boxes)';
      this.entertedAddress = '';
      this.addressError = 1;
      this.getValues();

      let fullAddress = this.uniqueId.split('-');
      fullAddress.shift();
      fullAddress = fullAddress.join(' ');
      this.homeAddress = '';

    });
    history.pushState(null, '');
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');

    });
    this.phoneerror = false;
    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear()) - 18;
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    this.minDate = new Date(month + '/' + day + '/' + year);

    this.storageService.setItem('premilumDirtyFlag', 'false');

    //Data by GetAppData();
    this.pc
      .getAppData(this.clientId)
      .then((data) => {
        debugger;
        const dataFrom = JSON.parse(data.Payload);
        this.FirstName = dataFrom.body.bubble_fname ? dataFrom.body.bubble_fname : '';
        this.LastName = dataFrom.body.bubble_lname ? dataFrom.body.bubble_lname : '';
        this.EmailID = dataFrom.body.bubble_email;
        this.prevEmailId = dataFrom.body.bubble_email;
        this.PhoneNumber = dataFrom.body.bubble_phone;
        this.DateOfBirth = dataFrom.body.dob;
        this.gender = dataFrom.body.gender; // ? dataFrom.body.gender : "1";
        this.heightFt = dataFrom.body.bubble_heightFt;
        this.heightIn = dataFrom.body.bubble_heightIn;
        this.Weight = dataFrom.body.bubble_weight;

        let fullAddress = this.uniqueId.split('-');
        fullAddress.shift();
        fullAddress = fullAddress.join(' ');
        this.SSN = dataFrom.body.SBLI.sbli_ssn;
        if (this.SSN != null) {
          this.ssnLength = this.SSN.length;
        }
        if (this.carrier == 'WNS') {
          this.hidenextbutton = dataFrom.body['maxswnsssncallexceed'] ? dataFrom.body['maxswnsssncallexceed'] : false;
        }
        else{
          this.hidenextbutton = dataFrom.body['maxsblissncallexceed'] ? dataFrom.body['maxsblissncallexceed'] : false;
        }

        this.pc.parseAddressLocalAndPatch(fullAddress, this.clientId).then(
          data => {
            console.log('splitAddressNew : ', data);
            this.state = data.state_code ? data.state_code : '';
            this.city = data.city_name ? data.city_name : '';
            this.zipcode = data.zip ? data.zip : '';
            if (this.state == undefined) {
              this.disablestate = false;
            }
            else if (this.state == null) {
              this.disablestate = false;
            }
            else if (this.state == '') {
              this.disablestate = false;
            }
            else {
              this.disablestate = true;
            }
            if (this.zipcode == undefined) {
              this.disablezipcode = false;
            }
            else if (this.zipcode == null) {
              this.disablezipcode = false;
            }
            else if (this.zipcode == '') {
              this.disablezipcode = false;
            }
            else {
              this.disablezipcode = true;
            }
            this.homeAddress = dataFrom.body.SBLI ? dataFrom.body.SBLI['SBLI_confirmDetailsHomeAddress'] : '';
            this.addressChange();
            //this.homeAddress = this.homeAddress ? this.homeAddress : "";
            this.showloader = false;
          }
        ).catch(error => console.log('error', error));

        this.onDateChange(this.DateOfBirth);
        //this.genderClick(this.gender)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  verifyInch() {
    this.storageService.setItem('premilumDirtyFlag', 'true');
    //this.storageService.setItem('premiumAmount ','Recalculating');
    if (Number(this.heightIn) > 11) {
      this.heightIn = '11';
    }
    if (Number(this.heightIn) < 0) {
      this.heightIn = '1';
    }
  }

  moveDatePicker() {
    setTimeout(() => {
      const x = document.getElementsByTagName('bs-datepicker-container')[0];
      console.log(x);
      document.getElementById('inputDatePicker').appendChild(x);
    }, 100);
  }

  genderClick(gender) {
    this.storageService.setItem('premilumDirtyFlag', 'true');
    if (gender == 1) {
      this.maleIconactive = !this.maleIconactive;
      this.maleIcon = this.maleIconactive
        ? 'assets/images/active/icon-male-active.svg'
        : 'assets/images/inactive/icon-male-inactive.svg';
      if (this.maleIconactive) {
        this.gender = '1';
        this.femaleIconactive = false;
        this.femaleIcon = 'assets/images/inactive/icon-female-inactive.svg';
      }
    }
    if (gender == 0) {
      this.femaleIconactive = !this.femaleIconactive;
      this.femaleIcon = this.femaleIconactive
        ? 'assets/images/active/icon-female-active.svg'
        : 'assets/images/inactive/icon-female-inactive.svg';
      if (this.femaleIconactive) {
        this.gender = '0';
        this.maleIconactive = false;
        this.maleIcon = 'assets/images/inactive/icon-male-inactive.svg';
      }
    }
    this.storageService.setItem('bubble-gender', this.gender);
  }

  WeightVerify() {
    console.log('WeightVerify');
    this.storageService.setItem('premilumDirtyFlag', 'true');
    // this.storageService.setItem('premiumAmount ','Recalculating');
  }

  heightVerify() {
    this.storageService.setItem('premilumDirtyFlag', 'true');
    //this.storageService.setItem('premiumAmount ','Recalculating');
  }

  genderVerify() {
    this.storageService.setItem('premilumDirtyFlag', 'true');
    // this.storageService.setItem('premiumAmount ','Recalculating');
  }

  counter: any;
  inputdatechange() {
    debugger;
    const K = this.DateOfBirth.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.message = 'Invalid Date';
      } else {
        this.message = '';
      }
      this.DateOfBirth = A + '/';
    } else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.message = 'Invalid Date';
        } else if (A <= 12) {
          this.DateOfBirth = A + '/' + B + '/';
          this.message = '';
        } else {
          this.message = 'Invalid Date';
        }
      } else {
        this.DateOfBirth = A + '/' + B;
      }
    } else if (C != '' && B.length == 2) {
      this.DateOfBirth = A + '/' + B + '/' + C;
    } else {
      this.DateOfBirth = this.DateOfBirth;
    }
    if (this.DateOfBirth.length >= 10 && this.message != 'Invalid Date') {
      this.onDateChange(this.DateOfBirth);
    } else {
      this.is_valid = false;
    }
  }
  onDateChange(even) {
    debugger;
    if (even == null) {
      this.DateOfBirth = this.pc.momentdob(this.curDate);
    } else {
      this.DateOfBirth = this.pc.momentdob(even);
    }
    if (this.DateOfBirth == 'NaN/NaN/NaN') {
      this.message = 'Invalid Date';
      this.is_valid = false;
      this.DateOfBirth = '';
    } else {
      const newDate = new Date(even);
      console.log('DOB entered: ', newDate);
      const checkAge = this.validationService.validate_dob(
        'common_age',
        newDate
      );
      this.message = checkAge.message;
      this.is_valid = checkAge.is_valid;
    }

    this.counter = this.counter + 1;
    if (this.counter > 2) {
      this.storageService.setItem('premilumDirtyFlag', 'true');
    } else {
      this.storageService.setItem('premilumDirtyFlag', 'false');
    }
  }

  selectedCarrierData: any;
  coverageAmountText: string;
  disclaimerScript1: string;
  disableterm: boolean;
  carrierRules: any;
  tyod;
  smoker;
  selectedHomeCarrierData;
  usageDuration: string;
  coveragetodisaply;
  age;
  getValues() {
    if (this.insurancetype === 'homeLife' || this.insurancetype === 'life') {
      this.selectedCarrierData = localStorage.getItem('selectedCarrier');
      this.selectedCardData = JSON.parse(this.selectedCarrierData);
      this.carrierName = this.selectedCardData.head.carrier_name;
      this.coverageAmountText = formatAmountCommaDollar(
        this.selectedCardData.selectedDetails.coverage
      );
      this.selectedCardData.selectedDetails.term =
        '' + this.selectedCardData.selectedDetails.term;
      if (
        this.selectedCardData.selectedDetails.term &&
        !this.selectedCardData.selectedDetails.term.endsWith('Years')
      ) {
        this.selectedCardData.selectedDetails.term = `${this.selectedCardData.selectedDetails.term} Years`;
      }
    }
    if (this.insurancetype === 'homeLife') {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      this.minDate = new Date(month + '/' + day + '/' + year);
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);
      this.tyod = date;
      const format = 'yyyy-MM-dd';
      const myDate = this.tyod;
      const locale = 'en-US';
      this.tyod = formatDate(myDate, format, locale);
      this.selectedCarrierData = localStorage.getItem('selectedCarrier');
      this.selectedCarrierData = JSON.parse(this.selectedCarrierData);
      this.selectedCardData = this.selectedCarrierData;
      this.selectedCardData['head']['quote_amount'] = formatAmount(
        this.selectedCardData['head']['quote_amount']
      );
      this.gender = localStorage.getItem('bubble-gender');
      this.smoker = localStorage.getItem('bubble-tobacco');
      if (this.smoker != '5') {
        this.usageDuration = localStorage.getItem(
          'bubble-tobaccoUsageDuration'
        );
        this.smoker = '0';
      } else {
        this.usageDuration = '1';
      }
      this.DateOfBirth = new Date(localStorage.getItem('bubble-dob'));
      this.age = localStorage.getItem('bubble-age');
      this.height = localStorage.getItem('bubble-height');
      this.heightFt = localStorage.getItem('bubble-heightFt');
      this.heightIn = localStorage.getItem('bubble-heightIn');
      this.weight = localStorage.getItem('bubble-weight');
      this.termLength = localStorage.getItem('bubble-termLength');
      this.coverageAmount = localStorage.getItem('bubble-coverage');
      const formatednumber = this.coverageAmount;
      this.coveragetodisaply = formatednumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.selectedHomeCarrierData = localStorage.getItem(
        'selectedHomeCarrier'
      );
      this.selectedHomeCarrierData = JSON.parse(this.selectedHomeCarrierData);
      this.selectedHomeCarrierData['head']['quote_amount'] = formatAmount(
        this.selectedHomeCarrierData['head']['quote_amount']
      );
      const selectedHome = JSON.parse(
        localStorage.getItem('selectedHomeCarrier')
      );
      let dwelleing = selectedHome.body.other_amounts.dwelling.text;
      dwelleing = dwelleing.replace('$', '').replace(/,/g, '');
      let replacementCost = selectedHome.replacementCost;
      replacementCost = replacementCost.replace('$', '').replace(/,/g, '');
      const commonInput = JSON.parse(localStorage.getItem('commonInput'));
      commonInput['replacementCost'] = replacementCost;
      commonInput['coverage'] = dwelleing;
      localStorage.setItem('commonInput', JSON.stringify(commonInput));
    } else if (this.insurancetype === 'life') {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      this.minDate = new Date(month + '/' + day + '/' + year);

      if (
        this.carrierName === 'Pac Life Next' ||
        this.carrierName === 'Next Term Life'
      ) {
        this.disableterm = true;
        const quickquotes = new pc.Quotes(this.uniqueId);
        this.carrierRules = quickquotes.getCarrierRules('paclife');
        console.log('carrier rules :', this.carrierRules);
      } else {
        this.disableterm = false;
      }
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);

      this.tyod = date;
      const format = 'yyyy-MM-dd';
      const myDate = this.tyod;
      const locale = 'en-US';
      this.tyod = formatDate(myDate, format, locale);
      this.selectedCarrierData = localStorage.getItem('selectedCarrier');
      this.selectedCarrierData = JSON.parse(this.selectedCarrierData);
      this.selectedCardData = this.selectedCarrierData;
      this.selectedCardData['head']['quote_amount'] = formatAmount(
        this.selectedCardData['head']['quote_amount']
      );
      this.gender = localStorage.getItem('bubble-gender');
      this.smoker = localStorage.getItem('bubble-tobacco');
      if (this.smoker != '5') {
        this.usageDuration = localStorage.getItem(
          'bubble-tobaccoUsageDuration'
        );
        this.smoker = '0';
      } else {
        this.usageDuration = '1';
      }

      this.DateOfBirth = new Date(localStorage.getItem('bubble-dob'));
      this.age = localStorage.getItem('bubble-age');
      this.height = localStorage.getItem('bubble-height');
      this.heightFt = localStorage.getItem('bubble-heightFt');
      this.heightIn = localStorage.getItem('bubble-heightIn');
      this.weight = localStorage.getItem('bubble-weight');
      this.termLength = localStorage.getItem('bubble-termLength');
      this.coverageAmount = localStorage.getItem('bubble-coverage');
      const formatednumber = this.coverageAmount;
      this.coveragetodisaply = formatednumber
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else if (this.insurancetype === 'home') {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;

      this.minDate = new Date(month + '/' + day + '/' + year);

      if (
        this.carrierName === 'Pac Life Next' ||
        this.carrierName === 'Next Term Life'
      ) {
        this.disableterm = true;
        const quickquotes = new pc.Quotes(this.uniqueId);
        this.carrierRules = quickquotes.getCarrierRules('paclife');
        console.log('carrier rules :', this.carrierRules);
      } else {
        this.disableterm = false;
      }
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);

      this.tyod = date;
      const format = 'yyyy-MM-dd';
      const myDate = this.tyod;
      const locale = 'en-US';
      this.tyod = formatDate(myDate, format, locale);

      this.selectedHomeCarrierData = localStorage.getItem(
        'selectedHomeCarrier'
      );
      this.selectedHomeCarrierData = JSON.parse(this.selectedHomeCarrierData);
      console.log('selected home : ', this.selectedHomeCarrierData);
      this.selectedHomeCarrierData['head']['quote_amount'] = formatAmount(
        this.selectedHomeCarrierData['head']['quote_amount']
      );
      console.log('selected home : ', this.selectedHomeCarrierData);

      const selectedHome = JSON.parse(
        localStorage.getItem('selectedHomeCarrier')
      );
      console.log('selectedHome :', selectedHome);

      let dwelleing = selectedHome.body.other_amounts.dwelling.text;
      dwelleing = dwelleing.replace('$', '').replace(/,/g, '');
      console.log('dwelleing :', dwelleing);

      let replacementCost = selectedHome.replacementCost;
      replacementCost = replacementCost.replace('$', '').replace(/,/g, '');
      console.log('replacementCost :', replacementCost);

      const commonInput = JSON.parse(localStorage.getItem('commonInput'));
      commonInput['replacementCost'] = replacementCost;
      commonInput['coverage'] = dwelleing;
      localStorage.setItem('commonInput', JSON.stringify(commonInput));
    }
  }

  public handleAddressChange(address) {
    debugger;
    this.showloader = true;
    let stateCodeLocal = ''; //'AL';
    let city = ''; //'Autauga';
    let zip = '';
    let street = '';
    let country = '';

    const google_address = {
      street_number_ln: '',
      street_number_sn: '',
      route_ln: '',
      route_sn: '',
      neighborhood_ln: '',
      neighborhood_sn: '',
      locality_ln: '',
      locality_sn: '',
      administrative_area_level_2_ln: '',
      administrative_area_level_2_sn: '',
      administrative_area_level_1_ln: '',
      administrative_area_level_1_sn: '',
      country_ln: '',
      country_sn: '',
      postal_code_ln: '',
      postal_code_sn: '',
      postal_code_suffix_ln: '',
      postal_code_suffix_sn: '',
      formatted_address: '',
      clientId: '',
      id: '',
    };
    //debugger
    for (let i = 0; i < address.address_components.length; i++) {
      debugger;
      const type = address.address_components[i].types[0];
      debugger;
      switch (type) {
        case 'street_number':
          //debugger
          google_address.street_number_ln =
            address.address_components[i].long_name;
          google_address.street_number_sn =
            address.address_components[i].short_name;
          street = street + address.address_components[i].short_name + ' ';
          break;
        case 'route':
          //debugger
          google_address.route_ln = address.address_components[i].long_name;
          google_address.route_sn = address.address_components[i].short_name;
          street = street + address.address_components[i].long_name;
          break;
        case 'neighborhood':
          //debugger
          google_address.neighborhood_ln =
            address.address_components[i].long_name;
          google_address.neighborhood_sn =
            address.address_components[i].short_name;
          break;
        case 'locality':
          //debugger
          google_address.locality_ln = address.address_components[i].long_name;
          google_address.locality_sn = address.address_components[i].short_name;
          city = address.address_components[i].short_name;
          break;
        case 'administrative_area_level_2':
          //debugger
          google_address.administrative_area_level_2_ln =
            address.address_components[i].long_name;
          google_address.administrative_area_level_2_sn =
            address.address_components[i].short_name;
          break;
        case 'administrative_area_level_1':
          //debugger
          google_address.administrative_area_level_1_ln =
            address.address_components[i].long_name;
          google_address.administrative_area_level_1_sn =
            address.address_components[i].short_name;
          stateCodeLocal = address.address_components[i].short_name;
          break;
        case 'country':
          //debugger
          google_address.country_ln = address.address_components[i].long_name;
          google_address.country_sn = address.address_components[i].short_name;
          country = address.address_components[i].short_name;
          break;
        case 'postal_code':
          //debugger
          google_address.postal_code_ln =
            address.address_components[i].long_name;
          google_address.postal_code_sn =
            address.address_components[i].short_name;
          zip = address.address_components[i].short_name;
          break;
        case 'postal_code_suffix':
          //debugger
          google_address.postal_code_suffix_ln =
            address.address_components[i].long_name;
          google_address.postal_code_suffix_sn =
            address.address_components[i].short_name;
          break;
      }
    }
    google_address.formatted_address = address.formatted_address;
    google_address.clientId = this.clientId;
    google_address.id = this.clientId;
    if (country != 'USA' && country != 'US') {
      this.state = '';
      this.city = '';
      this.zipcode = '';
      this.homeAddress = '';
      this.entertedAddress = '';
      this.addressError = 1;
      this.openModal('Please enter address inside USA.');
    } else if (
      google_address.street_number_sn == '' ||
      google_address.route_sn == '' ||
      google_address.locality_sn == '' ||
      google_address.administrative_area_level_2_sn == '' ||
      google_address.administrative_area_level_1_sn == '' ||
      google_address.postal_code_sn == ''
    ) {
      this.state = '';
      this.city = '';
      this.zipcode = '';
      this.homeAddress = '';
      this.entertedAddress = '';
      this.addressError = 1;
      this.openModal();
    } else {
      this.entertedAddress = address.formatted_address;
      this.state = stateCodeLocal;
      this.city = city;
      this.zipcode = zip;
      this.addressError = 0;
      this.homeAddress = street;
    }
    this.showloader = false;
  }

  openModal(
    msg = 'The address you entered could not be validated. Please enter a full address.'
  ) {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent, {
      scrollable: true,
      size: 'sm',
      centered: true,
    });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: msg,
      image: 'assets/images/images/education2.png',
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then(
      (result) => {
        console.log('Modal Close Output: ', result);
      },
      (reason) => {
        console.log('Modal Close Reason: ', reason);
      }
    );
  }

  disableButton() {
    this.state = '';
    this.city = '';
    this.zipcode = '';
    this.homeAddress = '';
  }

  fullName: any;
  public async Submit() {
    debugger;
    this.pc.lifesummary({ 'verify_detail_page': 'submit' });
    const today = new Date();
    const birthDate = new Date(this.DateOfBirth);
    const age = today.getFullYear() - birthDate.getFullYear();
    this.showloader = true;
    if (this.EmailID != this.prevEmailId) {
      console.log('calling replicateHubspotData');
      await this.pc.replicateHubspotData(this.prevEmailId, this.EmailID);
    }

    const hubSpotData = {
      application_stage: 'Application',
      email: this.EmailID,
      phone: this.PhoneNumber,
      dob_life: this.DateOfBirth,
      gender: this.gender,
      age: age.toString()
    };
    hubSpotData['firstname'] = this.FirstName;
    hubSpotData['lastname'] = this.LastName;
    hubSpotData['height'] = this.heightFt + '\'' + this.heightIn + '"';
    hubSpotData['weight'] = this.weight;
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    if (localStorage.getItem('premilumDirtyFlag') == 'true') {
      //document.getElementById('SBLI_QuoteID').innerHTML = 'Recalculating';
      this.storageService.setItem('premilumDirtyFlag', 'false');
      this.coveragetodisaply = this.coveragetodisaply
        .replace(/,/g, '')
        .replace('$', '');
      if (this.coveragetodisaply > 10000000) {
        this.coveragetodisaply = '10,000,000';
        this.coverageAmount = 10000000;
        localStorage.setItem('bubble-coverage', this.coverageAmount);
        const ctd = this.coveragetodisaply;
        this.coveragetodisaply = ctd
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else if (this.coveragetodisaply < 250000) {
        this.coveragetodisaply = '250,000';
        this.coverageAmount = 250000;
        localStorage.setItem('bubble-coverage', this.coverageAmount);
        const ctd = this.coveragetodisaply;
        this.coveragetodisaply = ctd
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        this.coverageAmount = this.coveragetodisaply;
        localStorage.setItem('bubble-coverage', this.coverageAmount);
        const ctd = this.coveragetodisaply;
        this.coveragetodisaply = ctd
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      }
      const today = new Date();
      const birthDate = new Date(this.DateOfBirth);
      this.age = today.getFullYear() - birthDate.getFullYear();
      localStorage.setItem('bubble-gender', this.gender);
      localStorage.setItem('bubble-tobacco', this.smoker);
      localStorage.setItem('bubble-dob', this.DateOfBirth);
      localStorage.setItem('bubble-age', this.age);
      localStorage.setItem('bubble-height', this.height);
      localStorage.setItem('bubble-heightFt', this.heightFt);
      localStorage.setItem('bubble-heightIn', this.heightIn);
      localStorage.setItem('bubble-weight', this.weight);
      localStorage.setItem('bubble-termLength', this.termLength);
      localStorage.setItem('bubble-coverage', this.coverageAmount);
      if (this.smoker == '0') {
        localStorage.setItem('bubble-tobacco', this.usageDuration);
        localStorage.setItem(
          'bubble-tobaccoUsageDuration',
          this.usageDuration
        );
      } else {
        localStorage.setItem('bubble-tobacco', this.smoker);
      }
      this.height = this.heightFt + 'ft ' + this.heightIn + 'inch';
      this.commonAddress = localStorage.getItem('bubble-street');
      let fullAddress = this.uniqueId.split('-');
      fullAddress.shift();
      fullAddress = fullAddress.join(' ');
      const sbligender = this.gender;
      const sblitobacco = localStorage.getItem('bubble-tobacco');
      const d = new Date(this.DateOfBirth);
      const year = d.getFullYear();
      console.log('commonInput : ', year, this.age, this.DateOfBirth);
      const sbliDob = '01/01/' + year;
      let splitname;
      if (this.fullName) splitname = this.fullName.split(' ');
      this.invokeGetQuickQuote2(
        '',
        splitname,
        sbligender,
        sbliDob,
        sblitobacco
      );
      this.pc
        .getAppData(this.clientId)
        .then((data) => {
          debugger;
          const dataFrom = JSON.parse(data.Payload);
          this.existingData = dataFrom.body.SBLI;
          if (this.existingData == undefined) {
            this.existingData = {};
          }
          this.storageService.setItem('bubble-heightFt', this.heightFt);
          this.storageService.setItem('bubble-heightIn', this.heightIn);
          this.storageService.setItem('bubble-weight', this.Weight);
          this.storageService.setItem('bubble-gender', this.gender);
          this.storageService.setItem('bubble-email', this.EmailID);
          this.storageService.setItem('bubble-fname', this.FirstName);
          this.storageService.setItem('bubble-lname', this.LastName);
          this.storageService.setItem('bubble-dob', this.DateOfBirth);
          localStorage.setItem('bubble-city', this.city);
          localStorage.setItem('bubble-state', this.state);
          localStorage.setItem('bubble-zip', this.zipcode);
          const json = {
            id: this.clientId,
            SBLI: this.existingData,
          };
          json.SBLI['SBLI_comfirmDetailsFirstName'] = this.FirstName;
          json.SBLI['SBLI_comfirmDetailsLastName'] = this.LastName;
          json.SBLI['SBLI_comfirmDetailsEmailID'] = this.EmailID;
          json.SBLI['SBLI_comfirmDetailsPhoneNumber'] = this.PhoneNumber;
          json.SBLI['SBLI_comfirmDetailsDateOfBirth'] = this.DateOfBirth;
          json.SBLI['SBLI_comfirmDetailsGender'] = this.gender;
          json.SBLI['SBLI_comfirmDetailsHeight'] =
            this.heightFt + ' ft ' + this.heightIn + 'in';
          json.SBLI['SBLI_comfirmDetailsWeight'] = this.Weight + 'lbs';
          json.SBLI['SBLI_confirmDetailsHomeAddress'] = this.homeAddress;
          json.SBLI['SBLI_confirmDetailsCity'] = this.city;
          json.SBLI['SBLI_confirmDetailsState'] = this.state;
          json.SBLI['SBLI_confirmDetailsZipCode'] = this.zipcode;
          json['bubble_fname'] = this.FirstName;
          json['bubble_lname'] = this.LastName;
          json['bubble_email'] = this.EmailID;
          json['bubble_phone'] = this.PhoneNumber;
          json['dob'] = this.DateOfBirth;
          json['gender'] = this.gender;
          json['bubble_heightFt'] = this.heightFt;
          json['bubble_heightIn'] = this.heightIn;
          json['bubble_weight'] = this.Weight;
          json.SBLI['sbli_height'] =
            this.heightFt + ' ft ' + this.heightIn + 'in';
          json.SBLI['sbli_weight'] = this.weight + ' ' + 'lbs';
          const usageduration = localStorage.getItem('bubble-tobacco');
          if (usageduration == '1') {
            this.usageDuration1 = 'Within the last year';
            this.usageDuration2 = '';
            this.usageDuration3 = '';
            this.usageDuration4 = '';
            this.usageDuration5 = '';
          } else if (usageduration == '2') {
            this.usageDuration1 = '';
            this.usageDuration2 = '13-24 months ago';
            this.usageDuration3 = '';
            this.usageDuration4 = '';
            this.usageDuration5 = '';
          } else if (usageduration == '3') {
            this.usageDuration1 = '';
            this.usageDuration2 = '';
            this.usageDuration3 = '25-36 months ago';
            this.usageDuration4 = '';
            this.usageDuration5 = '';
          } else if (usageduration == '4') {
            this.usageDuration1 = '';
            this.usageDuration2 = '';
            this.usageDuration3 = '';
            this.usageDuration4 = 'More than 36 months ago';
            this.usageDuration5 = '';
          } else {
            this.usageDuration1 = '';
            this.usageDuration2 = '';
            this.usageDuration3 = '';
            this.usageDuration4 = '';
            this.usageDuration5 = 'Never';
          }
          json.SBLI['tobacco_usage_one'] =
            this.usageDuration1 != '' ? 'Yes' : 'No';
          json.SBLI['tobacco_usage_two'] =
            this.usageDuration2 != '' ? 'Yes' : 'No';
          json.SBLI['tobacco_usage_three'] =
            this.usageDuration3 != '' ? 'Yes' : 'No';
          json.SBLI['tobacco_usage_four'] =
            this.usageDuration4 != '' ? 'Yes' : 'No';
          json.SBLI['tobacco_usage_five'] =
            this.usageDuration5 != '' ? 'Yes' : 'No';
          // if (dataFrom.body.selectedLife === 'sbli') {
          //   const etty = {
          //     next: 1,
          //     previous: 0,
          //     APICall: 1,
          //     sbli_answer_data: json,
          //     saveData: 1,
          //   };
          //   this.changeQuestion.emit(etty);
          // }
          // else {
          // json['sbli_questionNumber'] = 15;

          let term;
          if (Number(localStorage.getItem('bubble-termLength')) == 65) {
            term = 65 - this.age;
          }
          else {
            term = localStorage.getItem('bubble-termLength').toString();
          }

          json['wns_coverage'] = localStorage.getItem('bubble-wnscoverage');
          json['wns_term'] = localStorage.getItem('bubble-termLength');
          json['sbli_term'] = term;
          json['sbli_coverage'] = localStorage.getItem('bubble-coverage'),
            json['wns_coverage'] = localStorage.getItem('bubble-wnscoverage');
          json['term'] = term;
          json['coverage'] = localStorage.getItem('bubble-coverage');
          const etty = {
            'next': 1,
            'sbli_answer_data': json,
            'previous': 0,
            'APICall': ((this.carrier == 'WNS' && !dataFrom.body.WNSsubmitAPPQCalled) || (this.carrier == 'SBLI' && !dataFrom.body.SBLIsubmitAPPQCalled)) ? 2 : 0,
            'saveData': 1
          };
          this.changeQuestion.emit(etty);

        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      this.pc
        .getAppData(this.clientId)
        .then((data) => {
          const dataFrom = JSON.parse(data.Payload);
          this.existingData = dataFrom.body.SBLI;
          if (this.existingData == undefined) {
            this.existingData = {};
          }
          this.storageService.setItem('bubble-heightFt', this.heightFt);
          this.storageService.setItem('bubble-heightIn', this.heightIn);
          this.storageService.setItem('bubble-weight', this.Weight);
          this.storageService.setItem('bubble-gender', this.gender);
          this.storageService.setItem('bubble-email', this.EmailID);
          this.storageService.setItem('bubble-fname', this.FirstName);
          this.storageService.setItem('bubble-lname', this.LastName);
          this.storageService.setItem('bubble-dob', this.DateOfBirth);
          localStorage.setItem('bubble-city', this.city);
          localStorage.setItem('bubble-state', this.state);
          localStorage.setItem('bubble-zip', this.zipcode);
          const json = {
            id: this.clientId,
            SBLI: this.existingData,

          };
          json.SBLI['SBLI_comfirmDetailsFirstName'] = this.FirstName;
          json.SBLI['SBLI_comfirmDetailsLastName'] = this.LastName;
          json.SBLI['SBLI_comfirmDetailsEmailID'] = this.EmailID;
          json.SBLI['SBLI_comfirmDetailsPhoneNumber'] = this.PhoneNumber;
          json.SBLI['SBLI_comfirmDetailsDateOfBirth'] = this.DateOfBirth;
          json.SBLI['SBLI_comfirmDetailsGender'] = this.gender;
          (json.SBLI['SBLI_comfirmDetailsHeight'] =
            this.heightFt + ' ft ' + this.heightIn + 'in'),
            (json.SBLI['SBLI_comfirmDetailsWeight'] = this.Weight + 'lbs');
          json.SBLI['SBLI_confirmDetailsHomeAddress'] = this.homeAddress;
          json.SBLI['SBLI_confirmDetailsCity'] = this.city;
          json.SBLI['SBLI_confirmDetailsState'] = this.state;
          json.SBLI['SBLI_confirmDetailsZipCode'] = this.zipcode;
          json['bubble_fname'] = this.FirstName;
          json['bubble_lname'] = this.LastName;
          json['bubble_email'] = this.EmailID;
          json['bubble_phone'] = this.PhoneNumber;
          json['dob'] = this.DateOfBirth;
          json['gender'] = this.gender;
          json['bubble_heightFt'] = this.heightFt;
          json['bubble_heightIn'] = this.heightIn;
          json['bubble_weight'] = this.Weight;
          json.SBLI['sbli_height'] =
            this.heightFt + ' ' + 'ft' + ' ' + this.heightIn + ' ' + 'in';
          json.SBLI['sbli_weight'] = this.weight + ' ' + 'lbs';
          const usageduration = localStorage.getItem('bubble-tobacco');
          if (usageduration == '1') {
            this.usageDuration1 = 'Within the last year';
            this.usageDuration2 = '';
            this.usageDuration3 = '';
            this.usageDuration4 = '';
            this.usageDuration5 = '';
          } else if (usageduration == '2') {
            this.usageDuration1 = '';
            this.usageDuration2 = '13-24 months ago';
            this.usageDuration3 = '';
            this.usageDuration4 = '';
            this.usageDuration5 = '';
          } else if (usageduration == '3') {
            this.usageDuration1 = '';
            this.usageDuration2 = '';
            this.usageDuration3 = '25-36 months ago';
            this.usageDuration4 = '';
            this.usageDuration5 = '';
          } else if (usageduration == '4') {
            this.usageDuration1 = '';
            this.usageDuration2 = '';
            this.usageDuration3 = '';
            this.usageDuration4 = 'More than 36 months ago';
            this.usageDuration5 = '';
          } else {
            this.usageDuration1 = '';
            this.usageDuration2 = '';
            this.usageDuration3 = '';
            this.usageDuration4 = '';
            this.usageDuration5 = 'Never';
          }
          json.SBLI['tobacco_usage_one'] =
            this.usageDuration1 != '' ? 'Yes' : 'No';
          json.SBLI['tobacco_usage_two'] =
            this.usageDuration2 != '' ? 'Yes' : 'No';
          json.SBLI['tobacco_usage_three'] =
            this.usageDuration3 != '' ? 'Yes' : 'No';
          json.SBLI['tobacco_usage_four'] =
            this.usageDuration4 != '' ? 'Yes' : 'No';
          json.SBLI['tobacco_usage_five'] =
            this.usageDuration5 != '' ? 'Yes' : 'No';
          let term;
          if (Number(localStorage.getItem('bubble-termLength')) == 65) {
            term = 65 - this.age;
          }
          else {
            term = localStorage.getItem('bubble-termLength').toString();
          }
          json['wns_coverage'] = localStorage.getItem('bubble-wnscoverage');
          json['wns_term'] = term;
          json['sbli_term'] = term,
            json['sbli_coverage'] = localStorage.getItem('bubble-coverage'),
            json['wns_coverage'] = localStorage.getItem('bubble-wnscoverage');
          json['term'] = term;
          json['coverage'] = localStorage.getItem('bubble-coverage');
          const etty = {
            'next': 1,
            'sbli_answer_data': json,
            'previous': 0,
            'APICall': 8,
            'saveData': 1
          };
          this.changeQuestion.emit(etty);
        })
        .catch((error) => {
          console.log(error);
        });
    }

    this.$gaService.event(
      'SBLI LifeApplication Start',
      'event',
      'SBLI-LifeApplication-Start'
    );
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Start', 'event', 'SBLI-LifeApplication-Start');
  }

  invokeGetQuickQuote2(
    riskClassInput,
    splitname,
    sbligender,
    sbliDob,
    sblitobacco
  ) {
    splitname =
      splitname && splitname.length > 2 ? splitname : ['Anonymous', 'User', ''];
    console.log(
      ' Customized RiskClass =  ',
      ' Somking:',
      sblitobacco,
      this.smoker,
      ' Gender:',
      sbligender,
      ' Height:',
      this.height,
      ' Weight:',
      this.weight,
      ' Riskclass:',
      riskClassInput
    );
    const commonInput = {
      lastName: splitname[2] ? splitname[2] : 'Lastname',
      firstName: splitname[0] ? splitname[0] : 'Firstname',
      middleName: splitname[1] ? splitname[1] : 'MiddleName',
      addressType: 'PhysicalRisk',
      address: this.commonAddress, //splitAddress[(splitAddress.length - 3)],
      city: this.city,
      gender: sbligender == '1' ? 'M' : 'F', //inputJson.gender
      dob: sbliDob, //inputJson.dob
      term: this.termLength, //inputJson.term
      zip: this.zipcode, //inputJson.zip
      state: this.state, //inputJson.state
      height: this.height, //inputJson.height
      weight: +this.weight, //inputJson.weight
      tobaccoUse: +sblitobacco, //inputJson.tobaccoUse
      riskClass: riskClassInput,
      coverage: this.coverageAmount, //inputJson.coverage
      premiumFrequency: 'm',
    };

    console.log('commonInput : ', commonInput);
    const quickquote = new pc.Quotes(this.uniqueId);

    if (this.selectedCardData.carrierId == 'sbli') {
      quickquote
        .getSbliQuote(commonInput)
        .then((response) => {
          console.log('utput quickquote: ', response);
          this.quickQuoteData = response;
          this.selectedCardData = this.quickQuoteData[0];
          localStorage.setItem(
            'lifeCarrierName',
            this.selectedCardData.head.carrier_name
          );
          localStorage.setItem(
            'selectedCarrier',
            JSON.stringify(this.selectedCardData)
          );

          const carrierLocal = this.selectedCardData['head']['carrier_name'];
          const quoteAmountLocal = this.selectedCardData['head'][
            'quote_amount'
          ];
          if (quoteAmountLocal != null) {
            document.getElementById(
              'SBLI_QuoteID'
            ).innerHTML = quoteAmountLocal;
          }

          localStorage.setItem('bubble-QouteAmount', quoteAmountLocal);
        })
        .catch((error) => {
          if (error) console.log(error);
          else
            console.log(
              'select-life-carrier select-life-carrier.component.ts:catch(error):getQuickQuote2;Unknown error'
            );
        });
    }
  }

  public goBack() {
    const etty = {
      next: 0,
      previous: 1,
    };
    this.changeQuestion.emit(etty);
  }

  phoneerror: boolean;

  phonelength() {

    let count = 0;
    this.PhoneNumber = this.PhoneNumber.replace(/^0+/, '');
    const phonestring = this.PhoneNumber.toString().slice(
      0,
      this.PhoneNumber.length
    );
    this.PhoneNumber = phonestring.replace(/(\d{3})-?/g, function (m, a) {
      return ++count <= 2 ? a + '-' : m;
    });
    this.PhoneNumber = this.PhoneNumber.slice(0, 12);
    if (this.PhoneNumber.length < 12) {
      this.phoneerror = true;
    } else {
      this.phoneerror = false;
    }
  }

  nameerror: boolean;
  checkfirstNamelength() {
    if (this.FirstName.length <= 1) {
      this.nameerror = true;
    } else if (this.FirstName === '') {
      this.nameerror = true;
    } else {
      this.nameerror = false;
    }
  }

  public addressChange() {
    if (this.homeAddress.length <= 1) {
      this.addresserror = 'Invalid Address';
    } else if (this.homeAddress === '') {
      this.addresserror = 'Address is required';
    }
    else if (this.homeAddress == null) {
      this.addresserror = 'Address is required';
    }
    else if (this.homeAddress == undefined) {
      this.addresserror = 'Address is required';
    } else {
      this.addresserror = '';
    }
  }
  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }
  ssnlength() {
    debugger;
    const j = this.SSN.replace(/-/g, '');
    const A = j.substring(0, 3);
    const B = j.substring(3, 5);
    const C = j.substring(5, 9);
    if (B == '') {
      this.SSN = A;
    }
    else if (B != '' && C == '') {
      this.SSN = A + '-' + B;
    }
    else if (B != '' && C != '') {
      this.SSN = A + '-' + B + '-' + C;
    }
    else {
      this.SSN = j;
    }
    this.ssnLength = this.SSN.toString().length;
    if (this.ssnLength > 10) {
      this.errormessage = '';
      this.showerrormessage = false;
    }
    else {
      this.errormessage = 'Invalid SSN';
      this.showerrormessage = true;
    }
    this.ssnLength = this.SSN.toString().length;

  }
  public stoploader() {
    this.showloader = false;
  }

  public showcallbutton() {
    this.hidenextbutton = true;
    this.showloader = false;
  }
  public showPopup(): void {
    this.pc.lifesummary({ 'contact_us': 'yes' });
    this.triggerpopup.next();
  }
}
