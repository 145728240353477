import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { LifescoreErrorAlertComponent } from './../../components/lifescore-error-alert/lifescore-error-alert.component';

import { environment } from './../../../environments/environment';
import { StorageHandlerService } from 'src/app/shared/storage-handler.service';
import { stat } from 'fs';
import { formatAmount } from 'src/app/shared/UtilityFunctions';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

@Component({
  selector: 'app-application-status-tracker',
  templateUrl: './application-status-tracker.component.html',
  styleUrls: ['./application-status-tracker.component.css']
})
export class ApplicationStatusTrackerComponent implements OnInit {

	@Input() applicationNumber: string;
	insuranceDetails: any = {};
	pendingTaskNum = 0;
	applicationDetails: any;
	heplerArray = [];
	loader: boolean;
	applicationStatus: Array<any>;
	status: any = {};
	//status count in application details
	mostRecentTaskNum: number;
	aboutApplicationDetails: any;
	phoneNumber: any;
	driverLicenseDetails: any;
	isActiveMilitary: any;
	isInsuredDependent: any;
	thirdPartyNotified: any;
	hasExistingLifeInsurance: any;
	insurancePurpose: any;
	//beneficieries:any;
	ownerInsuredDifferent: any;
	insuredLegalNameChanged: any;
	policyOwnerFirstName: any;
	policyOwnerLastName: any;
	policyOwnerDob: any;
	policyOwnerState: any;
	policyOwnerSSN: any;
	policyOwnerEmail: any;
	policyOwnerStreetAddress: any;
	policyOwnerZipcode: any;
	policyOwnerCity: any;
	hasDriverLicense: any;
	driverLicenseNumber: any;
	driverLicenseIssuedState: any;
	showTerms = false;
	showAssumptions = false;
	pageToOpen = '';
	paclifeQuote = '0';
	termLength = '0';
	coverageAmt = '0';
	terminatingExistingPolicies: any;
	hasPendingApplications: any;
	hasExistingLifeInsuranceDetails: any;
	existingPolicyInfo;
	useFundsFromExistingPolicies;
	hasPendingApplicationDetails: any;
	hasPendingApplicationList = [];
	policyOwnerInfo = [];
	driverLicenseInfo = [];
	beneficieries = [];
	contingentBeneficiaries: any;
	contingentBeneficiaryInfo: any;
	stateCodeAtBirth: any;
	authorised: boolean;
	lastCompleted = 0;
	StatusCount: any;
	constructor(
    private pc: ProcessCacheService,
    private utilityService:UtilityFunctionsService,
    private activatedroute: ActivatedRoute,
    private sh: StorageHandlerService,
		private modalService: NgbModal,
    private $gaService: GoogleAnalyticsService) { }

	ngOnInit(): void {


	  this.loader = true;

	  document.getElementById('index_title_id').innerText = 'Application Tracker powered by Bubble';
	  this.activatedroute.queryParams.subscribe(params => {
	    this.applicationNumber = params['appid'];
	    console.log('applicationNumber r: ', this.applicationNumber);
	  });
	  this.applicationNumber = this.applicationNumber ? this.applicationNumber : localStorage.getItem('bubble-ApplicationID');
	  /* if (!this.applicationNumber) {
			this.applicationNumber = 'BBL129-343-21243';
		} */
	  console.log('applicationNumber: ', this.applicationNumber);
	  this.getApplicationStatus();
	  this.pc.getAppData(this.applicationNumber.split('-')[0]).then(
	    (data: any) => {
	      //debugger
	      const clientDataFiltered = JSON.parse(data.Payload);
	      this.authorised = false;
	      this.loader = false;
	      this.stateCodeAtBirth = clientDataFiltered.body.stateCodeAtBirth != '' ? clientDataFiltered.body.stateCodeAtBirth : 'Other';

	      this.policyOwnerInfo = clientDataFiltered.body.policyOwnerInfo;
	      this.ownerInsuredDifferent = clientDataFiltered.body.ownerInsuredDifferent;
	      this.insuredLegalNameChanged = clientDataFiltered.body.insuredLegalNameChanged;

	      this.driverLicenseInfo = clientDataFiltered.body.driverLicenseInfo;
	      this.hasDriverLicense = clientDataFiltered.body.hasDriverLicense;

	      this.phoneNumber = clientDataFiltered.body.comfirmDetailsPhoneNumber;

	      this.isActiveMilitary = clientDataFiltered.body.isActiveMilitary;

	      this.isInsuredDependent = clientDataFiltered.body.isInsuredDependent;

	      this.thirdPartyNotified = clientDataFiltered.body.thirdPartyNotified;

	      this.hasExistingLifeInsurance = clientDataFiltered.body.hasExistingLifeInsurance;

	      this.insurancePurpose = clientDataFiltered.body.insurancePurpose;

	      this.beneficieries = clientDataFiltered.body.addBeneficiaryInfo;

	      //Contengent Beneficiary
	      this.contingentBeneficiaries = clientDataFiltered.body.contingentBeneficiaries;
	      this.contingentBeneficiaryInfo = clientDataFiltered.body.contingentBeneficiaryInfo;

	      this.hasPendingApplications = clientDataFiltered.body.hasPendingApplications;
	      this.hasPendingApplicationList = clientDataFiltered.body.pendingApplicationInfo;

	      this.terminatingExistingPolicies = clientDataFiltered.body.terminatingExistingPolicies;
	      this.useFundsFromExistingPolicies = clientDataFiltered.body.useFundsFromExistingPolicies;

	      this.existingPolicyInfo = clientDataFiltered.body.existingPolicyInfo;

	      // console.log("clientDataFiltered.body: ", clientDataFiltered.body);
	      this.paclifeQuote = clientDataFiltered.body.paclifeQuote.premiumAmount;
	      let policyNumberLocal;
	      const policyTicketTemp = clientDataFiltered.body.paclifeTicket;
	      policyNumberLocal = policyTicketTemp != null && policyTicketTemp.policyNumber != null ? policyTicketTemp.policyNumber : null;
	      policyNumberLocal = !policyNumberLocal && policyTicketTemp != null && policyTicketTemp[0] != null ? policyTicketTemp[0].policyNumber : policyNumberLocal;
	      if (policyNumberLocal) localStorage.setItem('policyNo', policyNumberLocal);
	    }
	  ).catch(error => {
	    console.log(error);
	  });


	  this.$gaService.event('On Page Load', 'page', 'LifeApplicationStatus');
	  const eventOptions: EventOption = {
	    page_title: 'Application Status',
	    path: 'appstatus',
	    url: window.location.href
	  };
	  this.utilityService.eventWrapped(eventOptions, 'Next', 'event','LifeApplicationStatus');
	}

	setStatus(application_status) {
	  debugger;
	  localStorage.setItem('StatusCount', application_status.status_count);
	  const appStatus = JSON.parse(JSON.stringify(application_status));
	  console.log(appStatus, 'appStatus');
	  delete appStatus.status_count;
	  //ApplicationSubmitted => submittedDate
	  //status_1 Health Check => quoteDate
	  let lastCompleted = 0;
	  let pendingTaskNum = 0;
	  Object.keys(appStatus).forEach(element => {
	    debugger;
	    if ('ApplicationSubmitted' === appStatus[element].status_name.replace(' ', '')) {
	      debugger;
	      this.status.quoteDate = appStatus[element].status_date;
	      this.status.applicationDate = appStatus[element].status_date;
	      this.status.submittedDate = appStatus[element].status_date;
	      this.status.submittedMsg = appStatus[element].status_message;
	      this.status.submitStatus = appStatus[element].status_progress;
	      if ('completed' === appStatus[element].status_progress) {
	        lastCompleted = 2;
	      } else if ('pending' === appStatus[element].status_progress) {
	        pendingTaskNum = 2;
	      }
	    } else if ('HealthCheck' === appStatus[element].status_name) {
	      this.status.healthCheckUpDate = appStatus[element].status_date;
	      this.status.healthCheckMsg = appStatus[element].status_message;
	      this.status.checkUpStatus = appStatus[element].status_progress;
	      if ('completed' === appStatus[element].status_progress) {
	        lastCompleted = 3;
	      } else if ('pending' === appStatus[element].status_progress) {
	        pendingTaskNum = 3;
	      }
	    } else if ('Underwriting' === appStatus[element].status_name) {
	      this.status.underwritingDate = appStatus[element].status_date;
	      this.status.underwritingMsg = appStatus[element].status_message;
	      this.status.underwritingStatus = appStatus[element].status_progress;
	      if ('completed' === appStatus[element].status_progress) {
	        lastCompleted = 4;
	      } else if ('pending' === appStatus[element].status_progress) {
	        pendingTaskNum = 4;
	      }
	    } else if ('PolicyDelivery' === appStatus[element].status_name) {
	      this.status.deliveryMsg = appStatus[element].status_message;
	      this.status.deliveryDate = appStatus[element].status_date;
	      this.status.deliveryStatus = appStatus[element].status_progress;

	      if ('completed' === appStatus[element].status_progress) {
	        lastCompleted = 5;
	      } else if ('pending' === appStatus[element].status_progress) {
	        pendingTaskNum = 5;
	      }
	    }

	    this.lastCompleted = Math.max(lastCompleted, this.lastCompleted);
	    console.log(this.lastCompleted, 'lastCompleted');

	  });//end of foreach

	  this.pendingTaskNum = Math.max(pendingTaskNum, this.pendingTaskNum);
	  console.log(this.lastCompleted, 'lastCompleted');
	  this.createSvg();
	}
	getApplicationStatus() {
	  console.log('getting data');
	  this.pc.getApplicationStatus(this.applicationNumber)
	    .then((data: any) => {
	      console.log('hasError: ', data.hasError);
	      if (data.hasError && data.hasError == true) {
	        this.loader = false;
	        this.openModal();
	        console.log(data, 'data');
	        // alert("Our Servers are not taking too long to respond please come back later.");
	      } else {
	        this.loader = false;
	        this.applicationDetails = data;
	        this.applicationDetails['quote_amount_value'] = formatAmount(this.applicationDetails['quote_amount_value']);
	        this.applicationStatus = [];//data.details.application_status;
	        this.insuranceDetails = data.insured_details;
	        console.log(data.details.application_status, 'data');
	        this.setStatus(data.details.application_status);
	        Object.entries(data.details.application_status).forEach(([key, tempValue]) => {
	          // let tempValue = data.details.application_status[key];
	          //						console.log("key: ", key, "#tempValue: ", tempValue);
	          // {{ x.status_name }}&nbsp;{{ x.status_event }}
	          const jsonItem = {};
	          let nameEvent;
	          if (typeof tempValue === 'object') {
	            Object.entries(tempValue).forEach(([key2, value2]) => {
	              // value2 = "Medical records requested on 05/19/2021 at 12:00am.."
	              if (key2 === 'status_message' &&
									value2.indexOf(' on ') !== -1 &&
									value2.indexOf('/') !== -1 &&
									value2.indexOf(' at ') !== -1) {
	                jsonItem[`${key2}_original`] = value2;
	                value2 = value2.split(' on ')[0];
	              }
	              jsonItem[key2] = value2;
	            });
	            nameEvent = jsonItem['status_name'];
	            if (jsonItem['status_event'] &&
								jsonItem['status_event'] !== jsonItem['status_name'] &&
								jsonItem['status_event'].length > 0) { nameEvent = `${jsonItem['status_name']} (${jsonItem['status_event']})`; }
	            jsonItem['status_name_event'] = nameEvent;
	            this.applicationStatus.push(jsonItem);

	          }
	        });
	        this.mostRecentTaskNum = data.details.application_status.status_count;
	        //	console.log(this.mostRecentTaskNum, 'details');
	        // let pendindTask = this.applicationStatus.findIndex(element => {
	        // //	console.log(element, "app details");
	        // 	return element.status_progress == 'pending';
	        // });

	        // //if all tasks done set pending task to number larger than total steps
	        // if (pendindTask === -1) {
	        // 	this.pendingTaskNum = this.mostRecentTaskNum + 1;
	        // } else {
	        // 	this.pendingTaskNum = pendindTask + 1;
	        // }

	        //this.createSvg();
	        this.loader = false;
	      }
	    });
	}

	viewDetails(): void {
	  console.log('view details');
	}

	downloadQuotes(): void {
	  console.log('download Quotes');
	}

	/**
	 * Create figure showing application status with green ticks,
	 * circle and orange circle
	 *
	 */
	createSvg(): void {
	  debugger;
	  const svgns = 'http://www.w3.org/2000/svg';
	  const svg: SVGSVGElement = document.querySelector('svg');
	  //starting point
	  let y = 10;
	  let i: number;
	  let distance = 20;
	  // 6 => total number of steps to display
	  console.log(this.pendingTaskNum, 'pendigtaskNum');
	  //sets green ticks to completed tasks
	  for (i = 0; i < this.lastCompleted + 1; i++) {
	    const newImage = document.createElementNS(svgns, 'image');
	    //height and width of image
	    const width = 24;
	    const height = 24;
	    //height of status element + margin - height of circle
	    distance = 46 + 16 - 24;
	    if (i < this.lastCompleted) {
	      newImage.setAttribute('href', '..\\assets\\images\\appflow\\group-3-copy.svg');

	    } else {
	      newImage.setAttribute('href', '..\\assets\\images\\appflow\\group-3.svg');
	      this.StatusCount = localStorage.getItem('StatusCount');






	    }
	    newImage.setAttribute('x', '8');
	    //set where next image will be placed
	    newImage.setAttribute('y', `${y}`);
	    newImage.setAttribute('width', `${width}`);
	    newImage.setAttribute('height', `${height}`);
	    y += (distance + height);
	    //console.log(y, 'completed task')
	    svg.appendChild(newImage);
	  }
	  i--;
	  // 6 => total number of steps to display
	  //if (this.pendingTaskNum < 5) {
	  //set orange mark to  pending task
	  if (this.pendingTaskNum !== 0) {
	    console.log('create orange, pending task');
	    const newImage = document.createElementNS(svgns, 'image');
	    const width = 20;
	    const height = 20;
	    distance = 60;
	    newImage.setAttribute('href', '..\\assets\\images\\appflow\\group-3.svg');
	    newImage.setAttribute('x', '10');
	    newImage.setAttribute('y', `${y + 10}`);
	    newImage.setAttribute('width', `${width}`);
	    newImage.setAttribute('height', `${height}`);
	    y += (distance + height);
	    svg.appendChild(newImage);
	    i++;
	  } else {
	    y += 20;
	  }
	  console.log(i, 'i');
	  //sets gray circle to tasks not started
	  for (; i < 6; i++) {
	    //console.log(this.pendingTaskNum < 5, 'is pending status');
	    const newImage = document.createElementNS(svgns, 'circle');
	    const height = 50;
	    distance = 12;
	    newImage.setAttribute('cx', '20');
	    newImage.setAttribute('r', '6');
	    newImage.setAttribute('cy', `${y}`);
	    newImage.setAttribute('fill', '#d8d8d8');
	    // newImage.setAttribute('margin-top', `-20px`);
	    y += (12 + height);
	    //console.log(y, 'not started')
	    svg.appendChild(newImage);
	  }
	  //}
	}

	makeCircle(distance: number, height: number, width: number, image: string, svg: SVGSVGElement, x: number, y: number): void {
	  const svgns = 'http://www.w3.org/2000/svg';
	  const newImage = document.createElementNS(svgns, 'image');
	  //height and width of image
	  //height of status element + margin - height of circle
	  distance = 46 + 16 - 24;
	  newImage.setAttribute('href', image);
	  newImage.setAttribute('x', `${x}`);
	  //set where next image will be placed
	  newImage.setAttribute('y', `${y}`);
	  newImage.setAttribute('width', `${width}`);
	  newImage.setAttribute('height', `${height}`);
	  y += (distance + height);
	  console.log(y, 'completed task');
	  svg.appendChild(newImage);
	}


	openModal() {

	  const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
	    {
	      scrollable: true,
	      size: 'sm',
	      centered: true,
	      // keyboard: false,
	      // backdrop: 'static'
	    });

	  const data = {
	    head: 'Please Note',
	    title: 'Sorry!',
	    message: 'We are unable to fetch your policy status, please come back later.',
	    image: 'assets/images/images/education2.png'
	  };

	  modalRef.componentInstance.fromParent = data;
	  modalRef.result.then((result) => {
	    //redirect url
	    //window.location.href = environment.carrierErrorRedirectURL;
	    window.location.href = this.pc.envCred.url;
	    console.log('Modal Close Output: ', result);
	  }, (reason) => {
	    console.log('Modal Close Reason: ', reason);
	  });
	}


	opendisc() {
	  this.pc.getAppData(localStorage.getItem('clientID')).then(
	    (data: any) => {
	      //debugger;
	      document.getElementById('disclaimer').style.display = 'block';
	      document.getElementById('makeblur').style.filter = 'blur(20px)';

	      this.termLength = localStorage.getItem('bubble-termLength');
	      this.coverageAmt = localStorage.getItem('bubble-coverage');
	      const clientDataFiltered = JSON.parse(data.Payload);
	      this.policyOwnerInfo = clientDataFiltered.body.policyOwnerInfo;
	      this.ownerInsuredDifferent = clientDataFiltered.body.ownerInsuredDifferent;
	      this.insuredLegalNameChanged = clientDataFiltered.body.insuredLegalNameChanged;

	      this.phoneNumber = localStorage.getItem('bubble-phone');

	      this.isActiveMilitary = clientDataFiltered.body.isActiveMilitary;

	      this.isInsuredDependent = clientDataFiltered.body.isInsuredDependent;

	      this.thirdPartyNotified = clientDataFiltered.body.thirdPartyNotified;

	      this.hasExistingLifeInsurance = clientDataFiltered.body.hasExistingLifeInsurance;

	      this.insurancePurpose = clientDataFiltered.body.insurancePurpose;

	      this.beneficieries = clientDataFiltered.body.addBeneficiaryInfo;

	      this.hasPendingApplications = clientDataFiltered.body.hasPendingApplications;
	      this.hasPendingApplicationList = clientDataFiltered.body.pendingApplicationInfo;
	      this.terminatingExistingPolicies = clientDataFiltered.body.terminatingExistingPolicies;
	      this.useFundsFromExistingPolicies = clientDataFiltered.body.useFundsFromExistingPolicies;

	      this.existingPolicyInfo = clientDataFiltered.body.existingPolicyInfo;



	      this.driverLicenseInfo = clientDataFiltered.body.driverLicenseInfo;
	      this.hasDriverLicense = clientDataFiltered.body.hasDriverLicense;

	      this.paclifeQuote = clientDataFiltered.body.paclifeQuote.premiumAmount;
	      localStorage.setItem('policyNo', clientDataFiltered.body.paclifeTicket.policyNumber);

	    }
	  ).catch((error: unknown) => {
	    console.error(error);
	  });
	}

	closedisc() {
	  document.getElementById('disclaimer').style.display = 'none';
	  document.getElementById('makeblur').style.filter = 'none';
	}

	numberWithCommas(x) {
	  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
	  // return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	}
}
