import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from "../../../appUtility/appUtility.js";
@Component({
  selector: 'app-sbli01001080',
  templateUrl: './sbli01001080.component.html',
  styleUrls: ['./sbli01001080.component.css']
})
export class Sbli01001080Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  isdisable: boolean;
  usageDuration1: any;
  usageDuration2: any;
  usageDuration3: any;
  usageDuration4: any;
  usageDuration5: any;
  i: number = 1;
  questions: any;
  clientId: any;
  existingData: any;
  showLoader: boolean = true;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger
    this.clientId = localStorage.getItem('clientID');

    this.pc.getAppData(this.clientId).then(data => {
      debugger
      let dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        let que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == "app-sbli01001080")[0];
        console.log("app-sbli01001080 questions : ", this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli01001080")[0];
        console.log("app-sbli01001080 questions : ", this.questions);
      }


      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.tobacco_usage_one != undefined) {
        this.usageDuration1 = this.existingData.tobacco_usage_one;
        this.usageDuration2 = this.existingData.tobacco_usage_two;
        this.usageDuration3 = this.existingData.tobacco_usage_three;
        this.usageDuration4 = this.existingData.tobacco_usage_four;
        this.usageDuration5 = this.existingData.tobacco_usage_five;

        if (this.usageDuration1 == 'Yes') {
          this.getUsageDuration1(this.questions.radio_options_1[0].answer_text);
        }
        if (this.usageDuration2 == 'Yes') {
          this.getUsageDuration2(this.questions.radio_options_1[1].answer_text);
        }
        if (this.usageDuration3 == 'Yes') {
          this.getUsageDuration3(this.questions.radio_options_1[2].answer_text);
        }
        if (this.usageDuration4 == 'Yes') {
          this.getUsageDuration4(this.questions.radio_options_1[3].answer_text);
        }
        if (this.usageDuration5 == 'Yes') {
          this.getUsageDuration5(this.questions.radio_options_1[4].answer_text);
        }


      }
      else {
        let usageduration = localStorage.getItem('bubble-tobacco');
        if (usageduration == '1') {
          this.usageDuration1 = 'Within the last year';
          this.usageDuration2 = '';
          this.usageDuration3 = '';
          this.usageDuration4 = '';
          this.usageDuration5 = '';
        }
        else if (usageduration == '2') {
          this.usageDuration1 = '';
          this.usageDuration2 = '13-24 months ago';
          this.usageDuration3 = '';
          this.usageDuration4 = '';
          this.usageDuration5 = '';
        }
        else if (usageduration == '3') {
          this.usageDuration1 = '';
          this.usageDuration2 = '';
          this.usageDuration3 = '25-36 months ago';
          this.usageDuration4 = '';
          this.usageDuration5 = '';
        }
        else if (usageduration == '4') {
          this.usageDuration1 = '';
          this.usageDuration2 = '';
          this.usageDuration3 = '';
          this.usageDuration4 = 'More than 36 months ago';
          this.usageDuration5 = '';
        }
        else {
          this.usageDuration1 = '';
          this.usageDuration2 = '';
          this.usageDuration3 = '';
          this.usageDuration4 = '';
          this.usageDuration5 = 'Never';
        }


      }

      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });

  }


  getUsageDuration1(event) {
    debugger;
    this.isdisable = false;
    this.usageDuration1 = event;
    this.usageDuration2 = '';
    this.usageDuration3 = '';
    this.usageDuration4 = '';
    this.usageDuration5 = '';
    if (this.usageDuration1 != '') {
      this.isdisable = false;
    }
    localStorage.setItem('bubble-tobacco1', this.usageDuration1);
  }


  getUsageDuration2(event) {
    debugger;
    this.isdisable = false;
    this.usageDuration2 = event;
    this.usageDuration1 = '';
    this.usageDuration3 = '';
    this.usageDuration4 = '';
    this.usageDuration5 = '';
    if (this.usageDuration2 != '') {
      this.isdisable = false;
    }
    localStorage.setItem('bubble-tobacco2', this.usageDuration2);
  }

  getUsageDuration3(event) {
    debugger;
    this.isdisable = false;
    this.usageDuration3 = event;
    this.usageDuration1 = '';
    this.usageDuration2 = '';
    this.usageDuration4 = '';
    this.usageDuration5 = '';
    if (this.usageDuration3 != '') {
      this.isdisable = false;
    }
    localStorage.setItem('bubble-tobacco3', this.usageDuration3);
  }

  getUsageDuration4(event) {
    debugger;
    this.isdisable = false;
    this.usageDuration4 = event;
    this.usageDuration1 = '';
    this.usageDuration2 = '';
    this.usageDuration3 = '';
    this.usageDuration5 = '';
    if (this.usageDuration4 != '') {
      this.isdisable = false;
    }
    localStorage.setItem('bubble-tobacco4', this.usageDuration4);
  }

  getUsageDuration5(event) {
    debugger;
    this.isdisable = false;
    this.usageDuration5 = event;
    this.usageDuration1 = '';
    this.usageDuration2 = '';
    this.usageDuration3 = '';
    this.usageDuration4 = '';
    if (this.usageDuration5 != '') {
      this.isdisable = false;
    }
    localStorage.setItem('bubble-tobacco5', this.usageDuration5);
  }

  next() {
    debugger;
    var json = {
      'id': this.clientId,
      SBLI: this.existingData

    }

    json.SBLI[this.questions.radio_options_1[0].answer_key] = this.usageDuration1 != "" ? 'Yes' : "No";
    json.SBLI[this.questions.radio_options_1[1].answer_key] = this.usageDuration2 != "" ? 'Yes' : "No"
    json.SBLI[this.questions.radio_options_1[2].answer_key] = this.usageDuration3 != "" ? 'Yes' : "No"
    json.SBLI[this.questions.radio_options_1[3].answer_key] = this.usageDuration4 != "" ? 'Yes' : "No"
    json.SBLI[this.questions.radio_options_1[4].answer_key] = this.usageDuration5 != "" ? 'Yes' : "No"

    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
