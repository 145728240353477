import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from "../../components/lifescore-error-alert/lifescore-error-alert.component";
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-sbliknockout',
  templateUrl: './sbliknockout.component.html',
  styleUrls: ['./sbliknockout.component.css']
})
export class SbliknockoutComponent implements OnInit {

  @Input() errorMessage;
  @Input() backButton;
  @Output() closeKnock = new EventEmitter();
  uniqueId: any;
  clientId: any;
  showLoader: boolean;
  docURLs: any;
  base64JsonPdf: any;
  combinedBase64DataString: string;
  combinedPdfArray: any;
  pdfUrl: SafeResourceUrl;
  redirecturl: string;
  displayAddress: any;
  insurancetype: any;
  carrierName: any;
  constructor(
    private pc: ProcessCacheService,
    private modalService: NgbModal,
    private activatedroute: ActivatedRoute,
    private router: Router,
    public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    debugger
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];
    }
    );
    let hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'SBLI_Application_Declined',
      application_stage: 'Application Declined',

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.showLoader = true;
    let quickquote = new pc.Quotes(this.uniqueId);

    quickquote.sbliupdateSESSION(101).then(response => {
      debugger
      console.log("sbliupdateSESSION res", response);
      if (response.sbliError) {
        this.showLoader = false;
        if (this.errorMessage == undefined || this.errorMessage == null) {
          this.errorMessage = response.msgText;
        }
      }
      else {
        this.showLoader = false;
        // this.combineBase64Data();
      }
      const json = {
        'id': this.clientId,
        'sbliknockout': 'true',
        'sbli_knockout_message': this.errorMessage
      }
      this.pc.saveUpdateClientData(json).then(data => {
        this.showLoader = false;
      }).catch(error => {
        console.log(error);
      });
    })
      .catch(error => {
        this.showLoader = false;
        console.log("sbliinitApp Error:", error);
      });
    // quickquote.sbligetFORMS(3).then(response => {
    //   debugger
    //   console.log("sbligetFORMS res", response);
    //   if (response.sbliError) {
    //     if (response.msgText == 'Invalid or Closed application') {
    //       this.showLoader = false;
    //     } else {
    //       this.openModal(response.msgText, "Sorry!");
    //     }
    //   }
    //   else {
    //     this.docURLs = JSON.parse(response.formsJson);
    //     console.log("sbligetFORMS res formsJson", JSON.parse(response.formsJson));
    //     this.docURLs.forEach(element => {
    //       console.log(element);
    //       for (const item in element) {
    //         console.log(item);
    //         this.base64JsonPdf = element[item];
    //         // this.combinedBase64DataString = this.combinedBase64DataString+(atob(element[item]));
    //         // this.combinedPdfArray.push(atob(element[item]));
    //         this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64,${element[item]}`);
    //       }
    //     });
    //     console.log("base64JsonPdf", this.base64JsonPdf);
    //     console.log("pdfUrl", this.pdfUrl);
    //     quickquote.sbliupdateSESSION(101).then(response => {
    //       debugger
    //       console.log("sbliupdateSESSION res", response);
    //       if (response.sbliError) {
    //         this.openModal(response.msgText, "Sorry!");
    //       }
    //       else {
    //         this.showLoader = false;
    //         // this.combineBase64Data();
    //       }
    //     })
    //       .catch(error => {
    //         this.showLoader = false;
    //         console.log("sbliinitApp Error:", error);
    //       });
    //     this.showLoader = false;
    //     // this.combineBase64Data();
    //   }
    // })
    //   .catch(error => {
    //     this.showLoader = false;
    //     console.log("sbliinitApp Error:", error);
    //   });
  }

  public DownloadPDF() {
    //console.log("DownloadPDF", id);
    // window.location.href = 'data:application/octet-stream;base64,' + this.base64JsonPdf[id];
    const source = `data:application/pdf;base64,${this.base64JsonPdf}`;
    let filename = "Important Underwriting Notice";
    const link = document.createElement("a");
    link.href = source;
    link.download = `${filename}.pdf`
    link.click();
  }

  openModal(msg, title) {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    let data = {
      head: 'Please Note',
      title: title,
      message: msg,
      image: 'assets/images/images/education2.png'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log("Modal Close Output: ", result);
    }, (reason) => {
      console.log("Modal Close Reason: ", reason);
    });
  }

  public async gotohomepage() {
    debugger
    // let domain = window.location.hostname;
    // if (domain === 'app.getmybubble.co') {
    //   this.redirecturl = 'https://www.getmybubble.co'
    // }
    // else if (domain === 'uat-app.getmybubble.co') {
    //   this.redirecturl = 'https://uat.getmybubble.co'
    // }
    // else if (domain === 'app.getmybubble.com') {
    //   this.redirecturl = 'https://www.getmybubble.com'
    // }
    // else {
    //   this.redirecturl = 'http://localhost:4200/lifescore?customizeV2=true'
    // }
    this.showLoader = true;
    var json = {
      'id': this.clientId,
      'makesblicall': 'false'
    }
    await this.pc.saveUpdateClientData(json);
    let redirecturl = `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`
    window.location.href = redirecturl;
  }

  previous() {
    this.closeKnock.emit();
  }

}
