import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { constants } from 'src/app/shared/storage-handler.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { ValidationService } from 'src/app/shared/validation.service';
@Component({
  selector: 'app-contact-information',
  templateUrl: './contact-information.component.html',
  styleUrls: ['./contact-information.component.css']
})



export class ContactInformationComponent implements OnInit {
  @Output() nextquestion5 = new EventEmitter();
  @Output() invokeHomeQuote = new EventEmitter();
  @Input() contactLoader;
  @Output() stopLoader = new EventEmitter();
  @Input() hidden: any;
  name: any;
  phone: any;
  email: any;
  authorise: any;
  heroForm: any;
  nameerror: any;
  phoneerror: any;
  mapLeadSource = [
    'Pacific Wholesale Mortgage',
    'Toner Group',
    'HomeSmart California',
    'Barnstable',
    'Ben Anderson 365',
    'PRMG',
    'Briggs Freeman',
    'Flyhomes',
    'Homeward',
    'Low Rate Co.',
    'Olympic Media',
    'Royal United Mortgage',
    'United Wholesale Mortgage',
    'Bubble',
    'TheAgency',
    'SearchLight',
    'HomePros',
    'Lonepeaklending',
    'Afficiency'
  ];
  // Consent to Contact
  authorised = true;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  carrierName: any;
  fname: string;
  lname: string;
  lnameerror: boolean;
  btnDisable = true;
  url: any;
  existingData: any;
  loader: boolean;
  minDate: any;
  curDate;
  prevEmailId: any;
  addresscomponent: object;
  quinstreetemail: any;
  address: any;
  city: any;
  state: any;
  country: any;
  pincode: any;
  desired_life_coverage: any;
  desired_life_term: any;
  shorturl: any;
  constructor(private http: HttpClient, public validationService: ValidationService,
    private pc: ProcessCacheService,
    private $gaService: GoogleAnalyticsService,
    private activatedroute: ActivatedRoute,
    private utilityService: UtilityFunctionsService
  ) { }

  ngOnInit(): void {

    this.pc.logPageLandingtoBO({ page_name: 'personal_contact_details' });
    this.loader = this.contactLoader == false ? this.contactLoader : true;
    this.url = window.location.href;
    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear());
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.prevEmailId = localStorage.getItem(constants.bubble_email);
    this.minDate = new Date(month + '/' + day + '/' + year);
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];
      if (this.insurancetype == undefined || this.insurancetype == null || this.insurancetype == '') {
        this.insurancetype = params['insurancetype'];
      }
      //this.carrierName = params['carrierName'];
      // Create customer_id only if the email id provided
      const clientIdLocal = this.clientId;
      if (clientIdLocal) {
        localStorage.setItem('clientID', this.clientId);
      }
    }

    );
    // console.log("LeadCapture: ", this.pc.getUniquiId());
    this.heroForm = new FormGroup({
      name: new FormControl(this.name),
      phone: new FormControl(this.phone),
      email: new FormControl(this.email, Validators.required),
      authorise: new FormControl(this.authorise, Validators.required)
    });
    this.authorised = false;
    this.fname = '';
    this.lname = '';
    this.email = '';
    this.phone = '';


    this.pc.getAppData(this.clientId).then(data => {

      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body;
      console.log('data from getAppData sw : ', this.existingData);
      this.fname = this.existingData['bubble_fname'];
      this.lname = this.existingData['bubble_lname'];
      this.desired_life_coverage = this.existingData['desired_life_coverage'];
      this.desired_life_term = this.existingData['desired_life_term'];
      this.quinstreetemail = this.existingData['Qunistreet_Email'];
      console.log('quinstreetemail', this.quinstreetemail);
      this.email = (!this.existingData['bubble_email']) ? this.existingData['email'] : this.existingData['bubble_email'];
      this.phone = (!this.existingData['bubble_phone']) ? this.existingData['phone'] : this.existingData['bubble_phone'];
      this.dob = this.existingData['dob'];
      if (this.fname == null || this.fname == undefined || this.fname == '' || this.fname == 'null') {
        this.fname = '';
      }
      if (this.lname == null || this.lname == undefined || this.lname == '' || this.lname == 'null') {
        this.lname = '';
      }
      if (this.email == null || this.email == undefined || this.email == '' || this.email == 'null') {
        this.email = '';
      }
      if (this.phone == null || this.phone == '' || this.phone == '') {
        this.phone = '';
      } else {
        this.phonelength();
      }
      if (this.dob == null || this.dob == '' || this.dob == '' || this.dob == undefined) {
        this.dob = '';
      }
      else[
        this.checkDate(this.dob)
      ];

      this.loader = false;
      this.stopLoader.emit();

    }).catch(error => {
      console.log(error);
    });


    //   if (!this.hidden) this.tracker.changeProgressStatus({ totalPages: 30, currentPage: 16 })
    // this.checkProceedBtn();

    let addr = this.uniqueId?.replace(/%20/g, ' ');
    addr = addr.split('-');
    addr.shift();
    addr = addr.join(' ');
    this.parseAddressLocalAndPatchLocal(addr);
    const urls = {
      'urls': [`${window.location.host}/resumeFlow/${this.clientId}`]
    };
    console.log('urls', urls);
    this.getshortURL(urls);
  }

  checkProceedBtn() {
    if (this.fname == '' || this.lname == '' || this.email == '' || this.phone == '') {
      this.btnDisable = true;
    } else {
      this.btnDisable = false;
    }
  }

  checknamelength() {
    if (this.fname.length <= 1) {
      this.nameerror = true;
    }
    else if (this.fname === '') {
      this.nameerror = false;
    }
    else {
      this.nameerror = false;
    }
    // this.checkProceedBtn()
  }

  checklnamelength() {
    if (this.lname.length <= 1) {
      this.lnameerror = true;
    }
    else if (this.lname === '') {
      this.lnameerror = false;
    }
    else {
      this.lnameerror = false;
    }
    // this.checkProceedBtn()
  }

  phonelength() {


    // if (this.phone.length === 3) {
    //   this.phone = this.phone + "-";
    //   this.phoneerror = true;
    // }
    // else if (this.phone.length === 7) {
    //   this.phone = this.phone + "-";
    //   this.phoneerror = true;
    // }
    // else if (this.phone.length < 12) {
    //   this.phoneerror = true;
    // }
    // else {
    //   this.phoneerror = true;
    // }
    // debugger
    // let phonesplit = this.phone.split('');
    // if (phonesplit[3] == '-' && phonesplit[7] == '-') {
    //   // this.phoneerror = true;
    //   phonesplit[3] = '-';
    //   phonesplit[7] = '-';
    //   this.phone = phonesplit.join('');
    //   if (this.phone.length == 12) {
    //     this.phoneerror = false;
    //   }

    // }
    // else {
    //   this.phoneerror = true;
    // }
    // this.checkProceedBtn()
    let count = 0;
    this.phone = this.phone.replace(/^0+/, '');
    const phonestring = this.phone.toString().slice(0, this.phone.length);
    this.phone = phonestring.replace(/(\d{3})-?/g, function (m, a) {
      return ++count <= 2 ? a + '-' : m;
    });
    this.phone = this.phone.slice(0, 12);
    if (this.phone.length < 12) {
      this.phoneerror = true;
    }
    else {
      this.phoneerror = false;
    }
  }

  changeauth(even) {
    if (even.target.checked == true) {
      this.authorised = true;
    }
    else {
      this.authorised = false;
    }
  }

  async beginApplication() {

    this.pc.lifesummary({ 'personal_detail_page': 'Submit' });
    const savedata = {
      'id': this.clientId,
      'clientId': this.clientId,
      'organicFlow': 'true',
      'showPopup': 'true',
      'desired_life_term': this.desired_life_term || '20',
      'desired_life_coverage': this.desired_life_coverage ? this.desired_life_coverage : '250000'
    };
    this.pc.saveUpdateClientData(savedata);
    this.prevEmailId = sessionStorage.getItem('bubble-email');
    if (this.email != this.prevEmailId && this.prevEmailId != null) {
      // replicated data
      console.log('calling replicateHubspotData');
      await this.pc.replicateHubspotData(this.prevEmailId, this.email);
    }
    let customerIdLocal, lifeInsuranceAmount, carrierName;
    carrierName = localStorage.getItem('carrierName');
    if (carrierName == 'undefined' || carrierName == 'paclife') {
      carrierName = '';
    }

    lifeInsuranceAmount = localStorage.getItem('bubble_lifeInsuranceAmount');
    this.email = this.email.toLowerCase();
    localStorage.setItem('bubble-name', `${this.fname} ${this.lname}`);
    localStorage.setItem('bubble-fname', this.fname);
    localStorage.setItem('bubble-lname', this.lname);
    localStorage.setItem('bubble-email', this.email);
    sessionStorage.setItem('bubble-email', this.email);
    localStorage.setItem('bubble-phone', this.phone);
    localStorage.setItem('lifeRecoBackButton', 'quoteflow');
    // Get lifeInsuranceAmount from storage if available


    const x = ['email', 'website', 'company', 'phone', 'city', 'zip', 'state', 'address', 'firstname', 'lastname'];
    // Create customer_id only if the email id provided
    customerIdLocal = localStorage.getItem('customer_id');
    if (!customerIdLocal || customerIdLocal == 'undefined') {
      // possibility 1: for the input email there is
      // already one customer_id if not create new one
      try {
        const response = this.pc.getCustomerId4Email(this.email);
        if (response) {

          for (const point in response) {
            if (point == 'data') {
              const outputData = response['data']['output_data'];
              customerIdLocal = outputData && outputData.length > 0 ? outputData[0].dput2 : null;
              console.log('customerIdLocalcustomerIdLocal:', customerIdLocal);
            }
          }
        }
      } catch (error) {
        console.log('error: ', error);
      }
      //possibility 2 even id-mapping doesn't have it so create new one!
      if (!customerIdLocal) {
        customerIdLocal = this.pc.getQuoteId('');// pass emptystring to get -wer324 kind string
      }
      customerIdLocal = customerIdLocal.replace('-', '');
      localStorage.setItem('customer_id', customerIdLocal);
    }
    const interestType = this.utilityService.getHubSpotInterestType(this.insurancetype);
    // LogRocket.identify(this.clientId, {
    //   name: this.fname+ ' '+this.lname,
    //   email: this.email,
    // });
    // let application_stage = this.utilityService.getHubSpotAppStage(this.url);
    /////// id mapping
    const idMappingJson = {
      client_id: localStorage.getItem('clientID'),
      customer_id: customerIdLocal,
      email_id: this.email,
      firstname: this.fname,
      lastname: this.lname,
      status: 'Email Captured',
      interest_type: interestType,
      dob: localStorage.getItem('bubble-dob'),
    };

    this.pc.createIdMapping(idMappingJson);

    // const Helper = require('bit-uibl-data-driver');
    // Helper.Lead.createIdMapping(idMappingJson)
    //   .then((data) => {
    //     if (data) { console.log("Lead:createIdMapping:data: ", data) }
    //   });
    console.log('Lead:createIdMapping:idMappingJson:', idMappingJson);
    const timeDiff = Number(Math.abs(Date.now() - new Date(this.dob).getTime()));
    const age = Number(Math.floor((timeDiff / (1000 * 3600 * 24)) / 365));
    /////// id mapping ends
    const hubSpotData: any = {
      address: this.address,
      city: this.city,
      state: this.state,
      country: this.country,
      zip: this.pincode,
      age: age,
      interest_type: interestType,
      //   customer_id: customerIdLocal,
      //latest_quote_amount: lifeInsuranceAmount,
      //life_quote_amount: lifeInsuranceAmount,
      //life_latest_premium_amount: lifeInsuranceAmount,
      life_insurance_carrier: carrierName,
      application_stage: 'Lead',
      email: this.email,
      phone: this.phone,
      // Remove or true when checkbox is added
      consent_to_contact: JSON.stringify(true),
      full_address: this.address,
      client_id: localStorage.getItem('clientID'),
      customer_id: customerIdLocal,
      dob_life: localStorage.getItem('bubble-dob'),
    };
    // Overwriting lead_source in case of lead coming from website
    let leadSourceLocal = localStorage.getItem('lead_source');
    const upper = this.mapLeadSource.map(element => {
      return element.toUpperCase();
    });
    if (!leadSourceLocal || 'Bubble' === leadSourceLocal) {
      leadSourceLocal = 'Bubble';
      hubSpotData['lead_source'] = leadSourceLocal;
      localStorage.setItem('lead_source', leadSourceLocal);
    } else if (this.mapLeadSource.includes(leadSourceLocal)) {
      hubSpotData['lead_source'] = leadSourceLocal;
    }

    console.log(leadSourceLocal, 'leadSourceLocal');

    x.forEach(key => {
      if (this[key])
        hubSpotData[key] = this[key];
    });
    const gender = localStorage.getItem('bubble-gender');
    const heightFt = localStorage.getItem('bubble-heightFt'); //, this.heightFt);
    const heightIn = localStorage.getItem('bubble-heightIn'); //', this.heightIn);
    const weight = localStorage.getItem('bubble-weight'); //, this.weight);
    const tobacco = localStorage.getItem('bubble-tobacco');
    const annualIncome = localStorage.getItem('bubble-annualIncome');
    const number_of_children = localStorage.getItem('bubble-kids');
    const coverage_term_life = localStorage.getItem('bubble-coverage') || '250000';

    if (annualIncome) {
      hubSpotData.annual_income = annualIncome?.replace(/,/g, '');
    }

    // if (tobacco == '0' || tobacco == '5') {
    //   hubSpotData.smoker = tobacco == '0' ? 'Yes' : 'No';
    // }

    if (tobacco == '5') {
      hubSpotData.smoker = 'No';
    }
    else {
      hubSpotData.smoker = 'Yes';
    }

    if (number_of_children) {
      hubSpotData.number_of_children = number_of_children;
    }

    if (coverage_term_life) {
      hubSpotData.coverage_term_life = coverage_term_life;
    }

    if (gender == '0' || gender == '1') {
      hubSpotData.gender = gender;
    }

    if (heightFt && heightIn) {
      hubSpotData.height = this.pc.validateHeightHubspot(`${heightFt}'${heightIn}"`);
    }

    if (weight) {
      hubSpotData.weight = weight;
    }

    hubSpotData['firstname'] = this.fname;
    hubSpotData['lastname'] = this.lname;
    hubSpotData['short_url'] = this.shorturl;
    if (interestType == 'Life') {
      hubSpotData['zip'] = localStorage.getItem('bubble-zip');
      hubSpotData['postal_code_life'] = localStorage.getItem('bubble-zip');
    } else {

      hubSpotData['dob_home'] = this.dob;

    }
    let url;
    if (window && window.location && window.location.href) {
      url = window.location.href;
    }
    const dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);

    hubSpotData['dropoff_url'] = dropoffURL;
    // if (hubSpotData['address'])
    //   hubSpotData['address'] = hubSpotData['address'].replace(this.city, '').replace(this.state, '').replace(this.zip, '').trim();

    // hubSpotData["create_contact"] = true;
    console.log('this.pc.hubSpotSync:hubspotData:=>', hubSpotData);
    //make a request to server
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    //debugger
    //location.href = `Appflowstart/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.carrierName}`;

    // BAsed on flow save and redirect
    if (this.insurancetype == 'home' || this.insurancetype == 'homeLife') {
      //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
      this.saveData();
      this.invokeHomeQuote.emit();
    }
    else {
      this.saveData();
      const etty = {
        'questionNo': '5',
        'bubble-fname': this.fname,
        'bubble-lname': this.lname,
        'bubble-email': this.email,
        'bubble-phone': this.phone,
        'nextprev': 1
      };
      this.nextquestion5.emit(etty);
    }
  }

  processIdMappingGAHubSpotSync(customerIdLocal, carrierName) {
    const interestType = this.utilityService.getHubSpotInterestType(this.insurancetype);
    // let application_stage = this.utilityService.getHubSpotAppStage(this.url);
    /////// id mapping
    const idMappingJson = {
      client_id: localStorage.getItem('clientID'),
      customer_id: customerIdLocal,
      email_id: this.email,
      firstname: this.fname,
      lastname: this.lname,
      status: 'Email Captured',
      interest_type: interestType,
      dob: localStorage.getItem('bubble-dob'),
    };

    this.pc.createIdMapping(idMappingJson);

    // const Helper = require('bit-uibl-data-driver');
    // Helper.Lead.createIdMapping(idMappingJson)
    //   .then((data) => {
    //     if (data) { console.log("Lead:createIdMapping:data: ", data) }
    //   });
    console.log('Lead:createIdMapping:idMappingJson:', idMappingJson);
    /////// id mapping ends
    const hubSpotData = {
      interest_type: interestType,
      // customer_id: customerIdLocal,
      //latest_quote_amount: lifeInsuranceAmount,
      //life_quote_amount: lifeInsuranceAmount,
      //life_latest_premium_amount: lifeInsuranceAmount,
      life_insurance_carrier: carrierName,
      application_stage: 'Lead',
      email: this.email,
      phone: this.phone,
      consent_to_contact: JSON.stringify(true),
      full_address: this.displayAddress,
      client_id: localStorage.getItem('clientID'),
      customer_id: customerIdLocal

    };
    // Add home_knock_out_reason if available

    if (this.existingData['brushForestErrorDescription']) hubSpotData['home_knock_out_reason'] = this.existingData['brushForestErrorDescription'];
    if (this.dob) hubSpotData['dob_life'] = this.dob;
    let addr = this.uniqueId;
    addr = addr.split('-');
    addr.shift();
    addr = addr.join(' ');
    // if (this.existingData['formatted_address']) {
    //   hubSpotData["full_address"] = this.existingData['formatted_address'];
    //   this.pc.fullAddress = this.existingData['formatted_address'];
    // }
    hubSpotData['full_address'] = addr;
    if (this.existingData['street_number_sn'] && this.existingData['route_sn']) hubSpotData['address'] = `${this.existingData['street_number_sn']} ${this.existingData['route_sn']}`;
    if (this.existingData['city_name']) hubSpotData['city'] = this.existingData['city_name'];
    if (this.existingData['stateCode']) hubSpotData['state'] = this.existingData['stateCode'];
    //if (this.existingData['zip_code']) hubSpotData["zip_code"] = this.existingData['zip_code'];
    if (this.existingData['zip_code']) hubSpotData['zip'] = this.existingData['zip_code'];
    if (this.existingData['country_sn']) hubSpotData['country'] = this.existingData['country_sn'];


    // Overwriting lead_source in case of lead coming from website
    let leadSourceLocal = localStorage.getItem('lead_source');
    if (!leadSourceLocal || 'Bubble' === leadSourceLocal) {
      leadSourceLocal = 'Bubble';
      hubSpotData['lead_source'] = leadSourceLocal;
      localStorage.setItem('lead_source', leadSourceLocal);
    } else if (this.mapLeadSource.includes(leadSourceLocal)) {
      hubSpotData['lead_source'] = leadSourceLocal;
    }
    const y = ['email', 'website', 'company', 'phone', 'city', 'zip', 'state', 'address', 'firstname', 'lastname'];
    y.forEach(key => {
      if (this[key])
        hubSpotData[key] = this[key];
    });

    hubSpotData['firstname'] = this.fname;
    hubSpotData['lastname'] = this.lname;
    if (interestType == 'Life') {
      hubSpotData['zip'] = localStorage.getItem('bubble-zip');
      hubSpotData['postal_code_life'] = localStorage.getItem('bubble-zip');
    } else {
      let url;
      if (window && window.location && window.location.href) {
        url = window.location.href;
      }
      const dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
      hubSpotData['dropoff_url'] = dropoffURL;
      hubSpotData['dob_home'] = this.dob;
    }

    // if (hubSpotData['address'])
    //   hubSpotData['address'] = hubSpotData['address'].replace(this.city, '').replace(this.state, '').replace(this.zip, '').trim();

    // hubSpotData["create_contact"] = true;
    // Make variable when checkbox is added
    hubSpotData['consent_to_contact'] = 'yes';
    console.log('this.pc.hubSpotSync:hubspotData:', hubSpotData);
    //
    //make a request to server
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    //debugger
    //location.href = `Appflowstart/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.carrierName}`;

    // LifeLeadSubmit
    const eventOptionsPart1 = { 'path': `contactInformation${interestType}`, 'page_title': 'prefillResumeFlowHoi', 'url': window.location.href };
    //this.$gaService.event("Proceed", "event", "LeadSubmit");
    if (!this.insurancetype || this.insurancetype == 'life') {
      this.$gaService.event('Proceed', 'event', 'LifeLeadSubmit');
      this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed', 'event', 'LifeLeadSubmit');
    }

    // BAsed on flow save and redirect
    if (this.insurancetype == 'home' || this.insurancetype == 'homeLife') {
      //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
      this.saveData();
      this.$gaService.event('Proceed', 'event', 'LeadSubmit');
      this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed', 'event', 'LeadSubmit');
      this.invokeHomeQuote.emit();
    }
    else {
      this.saveData();
      const etty = {
        'questionNo': '5',
        'bubble-fname': this.fname,
        'bubble-lname': this.lname,
        'bubble-email': this.email,
        'bubble-phone': this.phone,
        'nextprev': 1
      };
      this.nextquestion5.emit(etty);
    }
  }

  saveData() {

    if (this.insurancetype == 'home' || this.insurancetype == 'homeLife') {
      const jsonhome = {
        'id': this.clientId,
        'bubble_fname': this.fname,
        'bubble_lname': this.lname,
        'bubble_email': this.email,
        'bubble_phone': this.phone,
        'dob': this.dob
      };
      console.log('json : ', jsonhome);
      this.pc.saveUpdateClientData(jsonhome).then(data => {
      }).catch(error => {
        console.log(error);
      });
    } else {
      const json = {
        'id': this.clientId,
        'bubble_fname': this.fname,
        'bubble_lname': this.lname,
        'bubble_email': this.email,
        'bubble_phone': this.phone,
      };
      console.log('json : ', json);
      this.pc.saveUpdateClientData(json).then(data => {
      }).catch(error => {
        console.log(error);
      });
    }

    localStorage.setItem('bubble-dob', this.dob);
  }

  previousscreen() {
    location.href = `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.carrierName}`;
  }
  inputdatechange() {
    const K = this.dob.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.message = 'Invalid Date';
      }
      else {
        this.message = '';
      }
      this.dob = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.message = 'Invalid Date';
        }
        else if (A <= 12) {
          this.dob = A + '/' + B + '/';
          this.message = '';
        }
        else {
          this.message = 'Invalid Date';
        }


      }
      else {
        this.dob = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.dob = A + '/' + B + '/' + C;
    }
    else {
      this.dob = this.dob;
    }

    if (this.dob.length >= 10 && this.message != 'Invalid Date') {
      this.isdisable = false;
      this.checkDate(this.dob);
    }
    else {
      this.isdisable = true;
    }
  }

  isdisable: boolean;
  showspanfordob: boolean;
  dob: any;
  message: any;
  is_valid: boolean;
  checkDate(even) {

    if (even == null) {
      this.isdisable = true;
      // this.isdisable = false;
      // this.curDate = new Date();
      // let year = Number(this.curDate.getFullYear()) - 18;
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.dob = new Date(month + '/' + day + '/' + year);
      this.dob = this.pc.momentdob(this.curDate);
    }
    else {
      this.isdisable = false;
      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();

      const ageYear = Number(curYear) - Number(year);

      if (ageYear >= 18 && ageYear <= 55) {
        this.showspanfordob = false;
        this.isdisable = false;
      } else {
        this.showspanfordob = true;
        this.isdisable = true;
      }

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dob = this.pc.momentdob(even);

    }
    const newDate = new Date(even);
    console.log('DOB entered: ', newDate);
    const checkAge = this.validationService.validate_dob('beneficiary_dob', newDate);
    console.log('DOB entered: checkAge', checkAge);
    this.message = checkAge.message;
    this.is_valid = checkAge.is_valid;
    // let checkDob = this.validationService.validate_dob("beneficiary_dob", newDate);
    // console.log("DOB entered: checkDob", checkDob);
    // const format = 'yyyy-MM-dd';
    // const myDate = new Date();
    // const locale = 'en-US';
    // let ctyod = formatDate(myDate, format, locale);

    // let date = new Date(this.dob);
    // let enteredYear = date.getFullYear();
    // let dates = new Date();
    // let currentYear = dates.getFullYear();

    // if (this.dob >= ctyod) {
    //   this.dob = this.tyod;
    // }
    // else if ((Number(currentYear)-Number(enteredYear))<18) {
    //   this.dob = this.tyod;
    // }



  }




  async parseAddressLocalAndPatchLocal(address) {

    console.log('parseAddressLocalAndPatchLocal', address.formatted_address, this.clientId);
    this.pc.parseAddressLocalAndPatch(address.formatted_address, this.clientId).then(
      async data => {
        console.log('split lenderaddress : ', data);
        this.address = data['fullAddress'];
        this.city = data['city_name'];
        this.state = data['stateCode'];
        this.country = data['county'];
        this.pincode = data['zip'];
      }
    ).catch(error => {
      this.loader = false;
      console.log('error', error);
    });
  }
  async getshortURL(url) {

    await this.http.post<any>('https://app.getmybubble.co/api/url-shortener', url).subscribe(async data => {

      this.shorturl = data[0].shortUrl;
    });
  }


}
