import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lifescore-empty-page',
  templateUrl: './lifescore-empty-page.component.html',
  styleUrls: ['./lifescore-empty-page.component.css']
})
export class LifescoreEmptyPageComponent implements OnInit {
  landinText: { pageOne: { bgColor: string; text: string; }; pageTwo: { bgColor: string; text: string; }; pageThree: { bgColor: string; text: string; }; };
  
  constructor() { }


  ngOnInit(): void {
    this.landinText= {
      pageOne: {
        bgColor: "#3c1aff",
        text:"fits your lifestyle|"
      },
      pageTwo: {
        bgColor: "#ffa61a",
        text:"has great options to work out|"
      },
      pageThree: {
        bgColor: "#ff7007",
        text:"is safe for your little ones|"
      }
    };
  }

}
