import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { json } from 'express';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { TrackerService } from 'src/app/shared/tracker.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-home-question1-v2',
  templateUrl: './home-question1-v2.component.html',
  styleUrls: ['./home-question1-v2.component.css']
})
export class HomeQuestion1V2Component implements OnInit {

  @Output() nextQuestion = new EventEmitter();
  @Input() inputAddress;
  @Input() uniqueId;
  livingsizeSqft: any;
  homeType: any;
  yearBuilt: any;
  stories: any;
  swimmingPool: any;
  constructionType: any;
  roofType: any;
  roofConstructed: any;
  displayAddress: string;
  constructionList: any;
  homeTypeList: any;
  roofTypeList: any;
  storiesList: any;
  clientId: any;
  insurancetype: any;
  address: any;
  city: any;
  state: any;
  zip: any;
  replacementCost: any;
  existingData: any;
  selectedConstructionType: any;
  selectedStories: any;
  selectedSwimmingPool: any;
  swimmingPoolList: any;
  selectedRoofType: any;
  selectedHomeType: any;
  livingSizeError: string;
  yearBuiltError: string;
  roofConstructedError: string;
  disableyear: boolean;
  loader: boolean;
  dataPrefilled: any;
  minArea: number;
  disableLivingArea: boolean;
  livingAreaCopy: any;
  dontKnowRoofConstructed: number;
  disableRoofConstructed: boolean;
  roofUpdated: any;
  selectedRoofUpdated: any;
  roofDD: any;
  roofConstructedFromParam: any;
  email: any;
  dropoffURL: any;
  constructor(private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private tracker: TrackerService,
    private modalService: NgbModal,
    private $gaService: GoogleAnalyticsService,
    private utilityService: UtilityFunctionsService) { }




  ngOnInit(): void {
    debugger;
    this.loader = true;

    this.homeType = 'DW';
    // this.livingsizeSqft = "1200";
    this.minArea = 50;

    this.swimmingPool = 'No';
    this.constructionType = 'F';
    this.roofType = 'ARCH';
    // this.roofConstructed = '2000';
    this.livingSizeError = '';
    this.yearBuiltError = '';
    this.roofConstructedError = '';
    this.dontKnowRoofConstructed = 0;
    this.disableRoofConstructed = false;

    const quickquote = new pc.Quotes(this.uniqueId);
    const optionsList = quickquote.getSWPropertyOptions();

    this.constructionList = optionsList.construction_type;
    console.log('constructionList : ', this.constructionList);
    // this.homeTypeList = optionsList.home_type;
    // console.log("homeTypeList : ", this.homeTypeList);
    this.roofTypeList = optionsList.roof_type;
    console.log('roofTypeList : ', this.roofTypeList);

    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address']; 'prefill-QuoteFlowV2/:uniqueId/:clientId/:address/:insurancetype/:yearbuilt/:livingArea/:roofupdated';
      this.insurancetype = params['insurancetype'];
      this.yearBuilt = params['yearbuilt'];
      this.livingsizeSqft = params['livingArea'];
      this.roofConstructedFromParam = params['roofupdated'];
      this.inputAddress = this.uniqueId.split('-');
      this.inputAddress.shift();
      this.inputAddress = this.inputAddress.join(' ');
      this.minArea = this.livingsizeSqft == null || this.livingsizeSqft == undefined || this.livingsizeSqft == '' ? 500 : Number(this.livingsizeSqft);

      this.roofDD = [
        {
          text: 'Within last 1 year',
          value: '6m'
        },
        {
          text: 'Within last 10 years',
          value: '5'
        },
        {
          text: 'Within last 20 years',
          value: '15'
        },
        {
          text: 'More than 20 years ago',
          value: 'yb'
        },
        {
          text: 'I don’t know',
          value: ''
        }
      ];
      this.storiesList = [
        {
          'value': '1',
          'text': '1'
        },
        {
          'value': '1H',
          'text': '1.5 (attic)'
        },
        {
          'value': '2',
          'text': '2'
        },
        {
          'value': '2H',
          'text': '2.5 (attic)'
        },
        {
          'value': '3',
          'text': '3'
        },
        {
          'value': '4',
          'text': '4+'
        }
      ];
      console.log('storiesList : ', this.storiesList);
      this.swimmingPoolList = [
        {
          'value': 'Yes',
          'text': 'Yes'
        }, {
          'value': 'No',
          'text': 'No'
        }

      ];
      this.homeTypeList = [
        {
          'text': 'Single Family Home',
          'value': 'DW'
        },
        {
          'text': 'Townhouse',
          'value': 'Townhouse'
        },
        {
          'text': 'Row house',
          'value': 'Rowhouse'
        },
        {
          'text': 'Condo',
          'value': 'Condo'
        },
        {
          'text': 'Apartment',
          'value': 'Apartment'
        },
        {
          'text': 'Duplex',
          'value': 'Duplex'
        },
        {
          'text': 'Mobile Home',
          'value': 'MobileHome'
        },
        {
          'text': 'Manufactured Home',
          'value': 'ManufacturedHome'
        }
      ];
      console.log('homeTypeList : ', this.homeTypeList);
      this.stories = localStorage.getItem('storiestoprefill');
      if (this.livingsizeSqft == 'null' || this.livingsizeSqft == 'undefined' || this.livingsizeSqft == null || this.livingsizeSqft == undefined) {
        this.livingsizeSqft = '';
      }
      if (this.yearBuilt == 'null' || this.yearBuilt == 'undefined' || this.yearBuilt == null || this.yearBuilt == undefined) {
        this.yearBuilt = '';
      }
      debugger;
      // this.roofConstructed = "";
      if (localStorage.getItem('roofConstructedPrefill') != undefined || localStorage.getItem('roofConstructedPrefill') != null) {
        this.roofConstructed = localStorage.getItem('roofConstructedPrefill');
      }
      if (localStorage.getItem('roofUpdatedPrefill') != undefined || localStorage.getItem('roofUpdatedPrefill') != null) {
        this.roofUpdated = localStorage.getItem('roofUpdatedPrefill');
      }
      if (this.roofConstructed == undefined || this.roofConstructed == null || this.roofConstructed == '0' || this.roofConstructed == 'null' || this.roofConstructed == 'undefined') {
        this.roofConstructed = new Date().getFullYear() - 15;
        this.roofUpdated = '15';
        this.dontKnowRoofConstructed = 0;
      }
      if (this.roofConstructed == '') {
        this.roofConstructed = '';
        this.roofUpdated = 'dn';
        this.dontKnowRoofConstructed = 1;
      }
      if (this.roofUpdated == 'dn') {
        this.roofConstructed = '';
        this.roofUpdated == '';
        this.selectedRoofUpdated = 'I don’t know';
        this.dontKnowRoofConstructed = 1;
      }
      else {
        this.selectedRoofUpdated = this.roofDD.filter(x => x.value == this.roofUpdated)[0].text;
      }



      if (localStorage.getItem('selectedHomeType') != undefined || localStorage.getItem('selectedHomeType') != null) {
        this.selectedHomeType = localStorage.getItem('selectedHomeType');
        this.homeType = localStorage.getItem('sw_homeType');
      }
      else {
        this.selectedHomeType = this.homeTypeList[0].text;
        this.homeType = this.homeTypeList[0].value;
      }

      if (localStorage.getItem('sw_stories') != undefined || localStorage.getItem('sw_stories') != null) {
        this.stories = localStorage.getItem('sw_stories');
        this.selectedStories = this.storiesList.filter(x => x.value == this.stories)[0].text;
      }
      else {
        this.selectedStories = this.storiesList[0].text;
        this.stories = this.storiesList[0].value;
      }

      if (localStorage.getItem('livingsizeSqft') != undefined || localStorage.getItem('livingsizeSqft') != null) {
        this.livingsizeSqft = localStorage.getItem('livingsizeSqft');
      }
      if (localStorage.getItem('yearBuilt') != undefined || localStorage.getItem('yearBuilt') != null) {
        this.yearBuilt = localStorage.getItem('yearBuilt');
      }

      // this.selectedHomeType = this.homeTypeList.filter(x => x.value == this.homeType)[0].text;
      // let storiesavilable = this.storiesList.filter(x => x.text == this.stories).length;

      // if (this.stories == 'null' || this.stories == null || this.stories == undefined || storiesavilable == 0) {
      //   this.stories = '1';
      //   this.selectedStories = this.stories;
      // }
      // else {
      //   this.selectedStories = this.storiesList.filter(x => x.text == this.stories)[0].text;
      // }


      // localStorage.setItem("selectedHomeType", this.selectedHomeType);
      // localStorage.setItem("sw_homeType", this.homeType);
      // localStorage.setItem("selectedStories", this.selectedStories);
      // localStorage.setItem("sw_stories", this.stories);
      // localStorage.setItem("livingsizeSqft", this.livingsizeSqft);
      // localStorage.setItem("yearBuilt", this.yearBuilt);


      localStorage.setItem('roofConstructedPrefill', this.roofConstructed);
      localStorage.setItem('roofUpdatedPrefill', this.roofUpdated == '' ? 'dn' : this.roofUpdated);
    }
    );



    // this.pc.getAppData(this.clientId).then(data => {
    //   debugger
    //   let dataFrom = JSON.parse(data.Payload);
    //   console.log("data from getAppData : ", dataFrom.body);
    //   this.yearBuilt = dataFrom.body.yearBuilt;
    //   this.livingsizeSqft = dataFrom.body.livingArea;
    //   this.roofConstructed = dataFrom.body.roofBuiltYear;
    //   this.dataPrefilled = dataFrom.body.dataPrefilled;
    //   this.livingAreaCopy = dataFrom.body.livingAreaCopy;

    //   if (this.livingsizeSqft == undefined || this.livingsizeSqft == '' || this.livingsizeSqft == null || this.livingsizeSqft == "0") {
    //     this.livingsizeSqft = "";
    //   }
    //   if (this.roofConstructed == undefined || this.roofConstructed == '' || this.roofConstructed == null || this.roofConstructed == "0") {
    //     this.roofConstructed = "";
    //   }
    //   if (this.yearBuilt == undefined || this.yearBuilt == '' || this.yearBuilt == null || this.yearBuilt == "0") {
    //     this.disableyear = false;
    //     this.yearBuilt = "";
    //   }

    //   this.disableLivingArea = false;
    //   if (this.dataPrefilled == "yes") {
    //     if (this.livingAreaCopy == undefined || this.livingAreaCopy == '' || this.livingAreaCopy == null) {
    //       this.livingAreaCopy = this.livingsizeSqft;
    //       this.minArea = Number(this.livingsizeSqft);
    //     }
    //     else {
    //       this.minArea = Number(this.livingAreaCopy);
    //     }
    //     //this.minArea = Number(this.livingsizeSqft);
    //     if (Number(this.livingsizeSqft) > 10000) {
    //       this.disableLivingArea = true;
    //     }
    //   }
    //   else {
    //     this.minArea = 50;
    //   }

    //   this.roofConstructed = this.yearBuilt;
    //   this.existingData = dataFrom.body.stillwater;
    //   console.log("data from getAppData sw : ", this.existingData);
    //   if (this.existingData == undefined || this.existingData == null || this.existingData == "") {
    //     this.existingData = {};
    //   }
    //   console.log("data from getAppData sw : ", this.existingData);
    //   this.loader = false;
    // }).catch(error => {
    //   console.log(error);
    // });

    // let commonInput = JSON.parse(localStorage.getItem("commonInput"));
    // if (commonInput == null || commonInput == undefined || commonInput == ''){
    // }
    // else {
    //   if (commonInput.propertyDetails == null || commonInput.propertyDetails == undefined || commonInput.propertyDetails == ''){
    //     this.homeType = "DW";
    //     this.livingsizeSqft = "1200";
    //     this.yearBuilt = '2000';
    //     this.stories = '2';
    //     this.swimmingPool = 'Yes';
    //     this.constructionType = "F";
    //     this.roofType = 'ARCH';
    //     this.roofConstructed = '2000';
    //     this.livingSizeError = "";
    //     this.yearBuiltError = "";
    //     this.roofConstructedError = "";
    //   }
    //   else {
    //     this.homeType = commonInput.propertyDetails.home_type;
    //     this.livingsizeSqft = commonInput.propertyDetails.living_size;
    //     this.yearBuilt = commonInput.propertyDetails.year_built;
    //     this.stories = commonInput.propertyDetails.stories;
    //     this.swimmingPool = commonInput.propertyDetails.swimming_pool;
    //     this.constructionType = commonInput.propertyDetails.construction_type;
    //     this.roofType = commonInput.propertyDetails.roof_type;
    //     this.roofConstructed = commonInput.propertyDetails.roof_constructed;
    //     this.livingSizeError = "";
    //     this.yearBuiltError = "";
    //     this.roofConstructedError = "";
    //   }
    //   this.pc.getAppData(this.clientId).then(data => {
    //     debugger
    //     let dataFrom = JSON.parse(data.Payload);
    //     this.existingData = dataFrom.body.stillwater;
    //     console.log("data from getAppData sw : ", this.existingData);
    //     if (this.existingData == undefined || this.existingData == null || this.existingData == "") {
    //       this.existingData = {};
    //     }
    //     console.log("data from getAppData sw : ", this.existingData);
    //     this.loader = false;
    //   }).catch(error => {
    //     console.log(error);
    //   });
    // }




    // this.inputAddress = this.inputAddress.split(" ").join("-");
    // this.processCache.getPropertyData(this.inputAddress).then(data => {
    //   console.log("getPropertyData propData success", data);
    //   this.livingsizeSqft = data['data.property.0.building.size.livingsize'];
    //   this.stories = data['data.property.0.building.summary.levels'];
    //   this.yearBuilt = data['data.property.0.building.summary.yearbuilt'];

    //   if (this.livingsizeSqft==undefined || this.livingsizeSqft=='' || this.livingsizeSqft==null) {
    //     this.livingsizeSqft = "";
    //   }
    //   if (this.stories==undefined || this.stories=='' || this.stories==null) {
    //     this.stories = "2";
    //   }
    //   if (this.yearBuilt==undefined || this.yearBuilt=='' || this.yearBuilt==null) {
    //     this.disableyear=false;
    //     this.yearBuilt = "";
    //   }
    //   this.roofConstructed = this.yearBuilt;
    //   this.loader = false;

    // }).catch(error =>
    //   console.log("getPropertyData propData error", error)
    // );

    // document.getElementById("hometype").style.display = "none";
    // this.selectedHomeType = this.homeTypeList.filter(x => x.value == this.homeType)[0].text;
    // let storiesavilable = this.storiesList.filter(x => x.text == this.stories).length;

    // if (this.stories == 'null' || this.stories == null || this.stories == undefined || storiesavilable == 0) {
    //   this.stories = '1';
    //   this.selectedStories = this.stories;
    // }
    // else {
    //   this.selectedStories = this.storiesList.filter(x => x.text == this.stories)[0].text;
    // }

    // document.getElementById("stories").style.display = "none";




    // document.getElementById("swimmingpool").style.display = "none";
    this.selectedSwimmingPool = this.swimmingPoolList.filter(x => x.value == this.swimmingPool)[0].text;

    // document.getElementById("construction").style.display = "none";
    this.selectedConstructionType = this.constructionList.filter(x => x.value == this.constructionType)[0].text;
    if (this.selectedConstructionType.length > 18) {
      this.selectedConstructionType = this.selectedConstructionType.substring(0, 18) + '...';
    }
    // document.getElementById("rooftype").style.display = "none";
    this.selectedRoofType = this.roofTypeList.filter(x => x.value == this.roofType)[0].text;
    if (this.selectedRoofType.length > 20) {
      this.selectedRoofType = this.selectedRoofType.substring(0, 20) + '...';
    }
    this.pc.parseAddressLocalAndPatch(this.inputAddress, this.clientId).then(
      data => {
        debugger;
        debugger;
        console.log('parseAddressLocalAndPatch success', data);

        const dstt = '';
        const dno = data.door_number.replace(/-/g, '');
        const dst = data.street.replace(/-/g, '');
        // if (data.street_type) {
        //   dstt = data.street_type.replace(/-/g, " ")
        // }
        const dcity = data.city_name.replace(/-/g, ' ');
        debugger;
        this.address = dno + ' ' + dst;// + " " + dstt;
        this.city = dcity;
        this.state = data.state_code;
        this.zip = data.zip;
        const rooflist = this.roofTypeList;
        this.roofTypeList = [];
        localStorage.setItem('sw_state', this.state);
        for (let i = 0; i < rooflist.length; i++) {
          if (rooflist[i].states.includes(this.state)) {
            this.roofTypeList.push(rooflist[i]);
          }
        }
        console.log('roofTypeList : ', this.roofTypeList);
        let maxReplacementCost = 0;
        if (this.state == 'CA') {
          maxReplacementCost = 10;
        }
        else {
          maxReplacementCost = 10;
        }
        this.replacementCost = localStorage.getItem('dwellingResult');
        this.replacementCost = this.replacementCost == 'No Data' || this.replacementCost == null || this.replacementCost == 'undefined' ? maxReplacementCost : this.replacementCost;
        ////debugger
        const ci = JSON.parse(localStorage.getItem('commonInput'));
        if (ci == null || ci == undefined || ci == '') {
          const commonInput = {
            address: this.address,
            addressType: 'PhysicalRisk',
            city: this.city,
            coverage: this.replacementCost,
            dob: '01/01/2000',
            firstName: 'Anonymous',
            lastName: 'Lastname',
            middleName: 'User',
            premiumFrequency: 'm',
            state: this.state,
            zip: this.zip,
            replacementCost: this.replacementCost,
            yearbuilt: this.yearBuilt,
            sw_discounts: [],
            sw_appQuestions: [],
            ca_builder: '',
            propertyDetails: ''
          };
          localStorage.setItem('commonInput', JSON.stringify(commonInput));
        }
      }
    ).catch(error =>
      console.log('error', error)
    );

    this.loader = false;


  }

  next() {
    if (this.homeType != 'DW') {
      this.email = localStorage.getItem('bubble-email');
      document.getElementById('knockOutModal').style.display = 'block';
    }
    else {
      this.nextOld();
    }
  }

  closeModal(even) {
    debugger;
    this.loader = true;
    if (even == 0) {
      document.getElementById('knockOutModal').style.display = 'none';
      window.location.href = this.pc.envCred.url;
    }
    else {
      this.nextNew();
    }
  }

  nextNew() {
    debugger;
    this.loader = true;
    document.getElementById('knockOutModal').style.display = 'none';
    let fa = this.uniqueId;
    fa = fa.split('-');
    fa.shift();
    fa = fa.join(' ');

    const hsData = {
      'email': this.email,
      'address': this.address,
      'city': this.city,
      'state': this.state,
      'country': 'US',
      'zip': this.zip,
      'full_address': fa,
      'home_type': this.homeType == 'DW' ? 'SingleFamilyHome' : this.homeType,
      'property_year_built': this.yearBuilt,
      'property_living_area': this.livingsizeSqft,
      'stories': this.stories,
      'roof_updated_year': this.roofConstructed,
      'interest_type': 'Home',
      'home_insurance_type': 'Homeowner',
      // "lead_source": "Bubble",
      'application_stage': 'Policy Denied',
      'home_knock_out_reason': 'Bubble only insures Single Family Homes online'
    };
    this.pc.hubSpotSyncAfterEmailFetch(hsData);

    hsData['id'] = this.clientId;
    this.pc.saveUpdateClientData(hsData);

    setTimeout(() => {
      window.location.href = this.pc.envCred.url;
    }, 5000);
  }

  nextOld() {
    debugger;
    localStorage.setItem('applyDiscount', '0');
    const hsData = {
      'email': localStorage.getItem('bubble-email'),
      'home_type': this.homeType == 'DW' ? 'SingleFamilyHome' : this.homeType,
      'property_year_built': this.yearBuilt,
      'property_living_area': this.livingsizeSqft,
      'stories': this.stories,
      'roof_updated_year': this.roofConstructed,
    };
    this.pc.hubSpotSyncAfterEmailFetch(hsData);
    this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 2 });
    this.loader = true;
    const propertyDetails = {
      'construction_type': this.constructionType,
      'home_type': this.homeType,
      'roof_type': this.roofType,
      'stories': this.stories,
      'swimming_pool': this.swimmingPool,
      'roof_constructed': this.roofConstructed,
      'year_built': this.yearBuilt,
      'living_size': this.livingsizeSqft
    };
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['yearbuilt'] = this.yearBuilt;
    commonInput['propertyDetails'] = propertyDetails;
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    localStorage.setItem('propertyDetails', JSON.stringify(propertyDetails));
    console.log('getStillWaterQuote commonInput :', commonInput);

    localStorage.setItem('bubble-livingSizeSQFT', this.livingsizeSqft);
    localStorage.setItem('bubble-homeType', this.homeType);
    localStorage.setItem('sw_livingSizeSQFT', this.livingsizeSqft);
    localStorage.setItem('sw_homeType', this.homeType);
    localStorage.setItem('sw_yearBuilt', this.yearBuilt);
    localStorage.setItem('sw_stories', this.stories);
    localStorage.setItem('sw_swimmingPool', this.swimmingPool);
    localStorage.setItem('sw_constructionType', this.constructionType);
    localStorage.setItem('sw_roofType', this.roofType);
    localStorage.setItem('sw_roofConstructed', this.roofConstructed);

    localStorage.setItem('roofConstructedPrefill', this.roofConstructed);
    localStorage.setItem('roofUpdatedPrefill', this.roofUpdated == '' ? 'dn' : this.roofUpdated);
    localStorage.setItem('selectedHomeType', this.selectedHomeType);
    localStorage.setItem('selectedStories', this.selectedStories);
    localStorage.setItem('livingsizeSqft', this.livingsizeSqft);
    localStorage.setItem('yearBuilt', this.yearBuilt);

    const json = {
      'id': this.clientId,
      'sw_questionNumber': 1,
      'yearBuilt': this.yearBuilt,
      'livingArea': this.livingsizeSqft,
      'roofBuiltYear': this.roofConstructed,
      'roofUpdateddd': this.roofUpdated == '' ? 'dn' : this.roofUpdated,
      // 'livingAreaCopy': this.livingAreaCopy,
      stillwater: {}
    };
    json.stillwater['propertyDetails'] = propertyDetails;
    console.log('propertyDetails :', propertyDetails);
    this.pc.saveUpdateClientData(json).then(data => {
      console.log('saveed UpdateClientData sw : ', data);
      const etty = {
        'questionNo': '1',
        'livingSizeSQFT': this.livingsizeSqft,
        'homeType': this.homeType,
        'nextprev': 1
      };
      this.nextQuestion.emit(etty);
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });

    // this code was previously used to invoke quote api

    // let quickquote = new pc.Quotes(this.uniqueId);
    // quickquote.getStillWaterQuote(commonInput, true)
    //   .then(response => {
    //     localStorage.setItem("homeKnockOutMsg", "We can’t provide you with Home Insurance currently. Leave us your details and we will get you insured offline");
    //     console.log("output SWquote: ", response);
    //     if (response[0].hasError && response[0].hasError == true) {
    //       this.loader = false;
    //       let msg = response[0].errorMessage;
    //       msg = msg.split(":");
    //       //let rejectError = msg[0].replace(/s/g, "");
    //       //debugger
    //       localStorage.setItem("homeKnockOutMsg", msg[1]);
    //       if (msg[0] == "FNI0170 ") {
    //         localStorage.setItem("homeKnockOutMsg", "The current address entered needs underwriting approval and cannot be issued with an online quote as it exceeds the permissible square footage and/or coverage limit. Please give us your contact details and we can get in touch with you.");
    //         location.href = "HomeKnockOut/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
    //       }
    //       location.href = "HomeKnockOut/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
    //       // if (msg[0]=="FNI0170 ") {
    //       //   location.href = "HomeKnockOut/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
    //       // }
    //       // else{
    //       //   this.openModal(msg[1]);
    //       // }
    //     }
    //     else {
    //       localStorage.setItem("bubble-livingSizeSQFT", this.livingsizeSqft);
    //       localStorage.setItem("bubble-homeType", this.homeType);
    //       localStorage.setItem("sw_livingSizeSQFT", this.livingsizeSqft);
    //       localStorage.setItem("sw_homeType", this.homeType);
    //       localStorage.setItem("sw_yearBuilt", this.yearBuilt);
    //       localStorage.setItem("sw_stories", this.stories);
    //       localStorage.setItem("sw_swimmingPool", this.swimmingPool);
    //       localStorage.setItem("sw_constructionType", this.constructionType);
    //       localStorage.setItem("sw_roofType", this.roofType);
    //       localStorage.setItem("sw_roofConstructed", this.roofConstructed);

    //       var json = {
    //         'id': this.clientId,
    //         'sw_questionNumber': 1,
    //         stillwater: this.existingData
    //       }
    //       json.stillwater['propertyDetails'] = propertyDetails;
    //       console.log("propertyDetails :", propertyDetails)
    //       this.processCache.saveUpdateClientData(json).then(data => {
    //         console.log("saveed UpdateClientData sw : ", data);
    //       }).catch(error => {
    //         console.log(error);
    //       });

    //       let etty = {
    //         "questionNo": "1",
    //         "livingSizeSQFT": this.livingsizeSqft,
    //         "homeType": this.homeType,
    //         "nextprev": 1
    //       }
    //       this.nextQuestion.emit(etty);
    //     }
    //   })
    //   .catch(error => {
    //     console.log("SW Quote Error:", error);
    //     this.loader = false;
    //     this.openModal("Our Servers are taking too long to respond please come back later.");
    //   });
    const eventOptionsPart1 = {
      'path': 'homeQuoteStep1',
      'page_title': 'homeQuoteStep1', 'url': window.location.href
    };
    this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed', 'event', 'MyHomeStep1Proceed');
    this.$gaService.event('Proceed', 'event', 'MyHomeStep1Proceed');

  }

  openModal(msg) {

    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
        // keyboard: false,
        // backdrop: 'static'
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: msg,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  selectConstructionType(even) {
    debugger;
    this.selectedConstructionType = even.text;
    this.constructionType = even.value;
    if (this.selectedConstructionType.length > 18) {
      this.selectedConstructionType = this.selectedConstructionType.substring(0, 18) + '...';
    }
    document.getElementById('construction').style.display = 'none';
  }



  selectHomeType(even) {
    debugger;
    this.selectedHomeType = even.text;
    this.homeType = even.value;
    document.getElementById('hometype').style.display = 'none';
  }


  selectStories(even) {
    this.selectedStories = even.text;
    this.stories = even.value;
    document.getElementById('stories').style.display = 'none';
  }



  selectRoofType(even) {

    this.selectedRoofType = even.text;
    if (this.selectedRoofType.length > 20) {
      this.selectedRoofType = this.selectedRoofType.substring(0, 20) + '...';
    }
    this.roofType = even.value;
    document.getElementById('rooftype').style.display = 'none';
  }


  selectSwimmingPool(even) {
    this.selectedSwimmingPool = even.text;
    this.swimmingPool = even.value;
    document.getElementById('swimmingpool').style.display = 'none';
  }


  checkArea() {
    debugger;
    if (this.livingsizeSqft.toString().length >= 2) {
      if (Number(this.livingsizeSqft) < this.minArea || Number(this.livingsizeSqft) > 10000) {
        this.livingSizeError = `Area should be between ${this.minArea} and 10,000 Sq Ft`;
      }
      else {
        this.livingSizeError = '';

      }
    }
    else {
      this.livingSizeError = `Area should be between ${this.minArea} and 10,000 Sq Ft`;
    }
  }

  checkYearBuilt() {
    debugger;
    const todayDate = new Date();
    const currentYear = todayDate.getFullYear();
    if (this.yearBuilt.toString().length == 4) {
      if (Number(this.yearBuilt) > currentYear || Number(this.yearBuilt) < 1800) {
        this.yearBuiltError = 'Year Built value should be between 1800 and ' + currentYear;
        // this.yearBuilt = currentYear;
      }

      else {
        this.yearBuiltError = '';
      }
    }
    else {
      this.yearBuiltError = 'Enter Valid Year';
    }

    // if (this.disableRoofConstructed == false) {
    //   if (this.roofConstructed.toString().length == 4) {
    //     if (Number(this.yearBuilt) > Number(this.roofConstructed)) {
    //       this.roofConstructedError = "The value should be greater than or equal to the Year Built";
    //     }
    //     else if (Number(this.roofConstructed) > currentYear) {
    //       this.roofConstructedError = "Roof constructed cannot be greater than " + currentYear;

    //     }
    //     else {
    //       this.roofConstructedError = "";
    //     }
    //   }

    //   else {
    //     this.roofConstructedError = "Enter Valid Year";
    //   }
    // }
    // else {
    //   this.roofConstructedError = "";
    // }


  }

  checkRoofYear() {
    debugger;
    const todayDate = new Date();
    const currentYear = todayDate.getFullYear();
    if (this.roofConstructed.toString().length == 4) {
      if (Number(this.roofConstructed >= Number(this.yearBuilt))) {
        if (Number(this.roofConstructed) < this.yearBuilt) {
          this.roofConstructed = this.yearBuilt;
        }
        else if (Number(this.roofConstructed) > currentYear) {
          this.roofConstructedError = 'Roof constructed cannot be greater than ' + currentYear;

        }
        else {
          this.roofConstructedError = '';
          this.yearBuiltError = '';
        }
      }
      else {
        //this.roofConstructedError = "Roof constructed cannot be less than " + this.yearBuilt;
        this.roofConstructedError = 'The value should be greater than or equal to the Year Built';
      }
    }
    else {
      this.roofConstructedError = 'Enter Valid Year';
      // if(Number(this.roofConstructed>=Number(this.yearBuilt))){
      //   this.roofConstructedError="";
      // }

      // else{
      //   this.roofConstructedError = "Roof constructed cannot be less than Year Built"
      // }
    }

    if (this.yearBuilt.toString().length == 4) {
      this.yearBuiltError = '';
    }
    else {
      this.yearBuiltError = 'Enter Valid Year';
    }
  }



  dontKnowRoof(even) {
    this.dontKnowRoofConstructed = even;
    if (this.dontKnowRoofConstructed == 1) {
      this.disableRoofConstructed = true;
      this.roofConstructed = '';
      this.roofConstructedError = '';
    }
    else {
      this.disableRoofConstructed = false;
      this.roofConstructedError = 'Enter Valid Year';
    }
  }


  roofUpdatedSelected(even) {
    this.roofUpdated = even;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    this.dontKnowRoofConstructed = 0;
    this.selectedRoofUpdated = this.roofDD.filter(x => x.value == this.roofUpdated)[0].text;
    switch (this.roofUpdated) {
      case '6m':
        if (Number(currentMonth) >= 6) {
          this.roofConstructed = Number(currentYear);
        }
        else {
          this.roofConstructed = Number(currentYear) - 1;
        }
        break;
      case '5':
        this.roofConstructed = Number(currentYear) - 5;
        break;
      case '15':
        this.roofConstructed = Number(currentYear) - 15;
        break;
      case 'yb':
        this.roofConstructed = this.yearBuilt;
        break;
      case '':
        this.roofConstructed = '';
        this.dontKnowRoofConstructed = 1;
        break;
    }
    console.log('roof_updated_year :', this.roofConstructed);
  }

}
