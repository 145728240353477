import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

declare var require: any;
const pc = require('bit-uibl-data-driver');

@Component({
  selector: 'app-details4ids',
  templateUrl: './details4ids.component.html',
  styleUrls: ['./details4ids.component.css']
})

export class Details4idsComponent implements OnInit {
  id: any;
  generatedId: any;
  userInputId: any;
  secretKey: string;
  keyValueArray = [];
  BackendService: any;
  showtable: number;
  constructor(
    private activatedroute: ActivatedRoute) { }

  public populateData(inputId) {
    let existing = [];
    pc.BackendService.getAppData(inputId)
      .then(response => {
        let retrievedData = response.Payload;
        let test = JSON.parse(retrievedData);
        existing.push(test)
        for (let key in existing[0].body) {
          let entit;
          if (typeof existing[0].body[key] === 'object') {
            Object.entries(existing[0].body[key]).forEach(([key2, value2]) => {

              if (typeof existing[0].body[key][key2] === 'object') {
                Object.entries(existing[0].body[key][key2]).forEach(([key3, value3]) => {
                  entit = {
                    Key: `${key}_${key2}_${key3}`,
                    Value: existing[0].body[key][key2][key3]
                  }
                  this.keyValueArray.push(entit);
                });
              } else {
                entit = {
                  Key: `${key}_${key2}`,
                  Value: existing[0].body[key][key2]
                }
                this.keyValueArray.push(entit);
              }
            });

          } else {
            entit = {
              Key: key,
              Value: existing[0].body[key]
            }
            this.keyValueArray.push(entit);
          }
        }

      })
      .catch(error => {
        console.log(error);
      });
  }
  ngOnInit(): void {

    this.generatedId = Math.random().toString(36).substr(3, 6).replace(/0/g, '1').replace(/o/g, 'p');
    console.log("generatedId:", this.generatedId);
    this.showtable = 0;
    this.activatedroute.params.subscribe(params => {
      //:id
      this.userInputId = params['id'];
      console.log("this.id : ", this.userInputId);
      if (this.userInputId) {
        this.showtable = 1;
        this.populateData(this.userInputId);
      }
    });
  }


  public getData() {
    this.showtable = 1;
    if (this.generatedId === this.secretKey) {
      this.populateData(this.userInputId);
    } else {
      this.keyValueArray = [];
      let entit = {
        Key: 'FAILURE',
        Value: 'Invalid Secret Key'
      }
      this.keyValueArray.push(entit);
      entit = {
        Key: 'this.generatedId',
        Value: this.generatedId
      }
      this.keyValueArray.push(entit);
      entit = {
        Key: 'this.secretKey',
        Value: this.secretKey
      }
      this.keyValueArray.push(entit);
    }

  }

}
