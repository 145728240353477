import { Component, OnInit, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ProcessCacheService } from '../shared/process-cache.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from '../shared/utility-functions.service';

declare const window: any;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let _hsq: any;
const url = 'https://api.hsforms.com/submissions/v3/integration/submit/8926444';

interface HubSpotInputJSON {
  firstname: string
  email: string
  lastname: string
  full_address: string
  id: string
  name: string
  age: string
  gender: string
  phone: string
  application_stage: string
  interest_type: string
  fullAddress: string
  [key: string]: any
}
@Component({
  selector: 'app-lifescore-lead-capture',
  templateUrl: './lifescore-lead-capture.component.html',
  styleUrls: ['./lifescore-lead-capture.component.css']
})
export class LifescoreLeadCaptureComponent implements OnInit {

  @Output() divchange = new EventEmitter();
  @Output() hideButton = new EventEmitter();
  @Input() age;
  @Input() parent: string | undefined;
  @Input() gender;
  @Input() state: string;
  @Input() city: string;
  @Input() zip: any;
  @Input() address: string;
  deviceInfo = null;
  name: any;
  phone: string;
  email: any;
  authorise: any;
  heroForm: any;
  nameerror: any;
  phoneerror: any;
  firstname: string;
  lastname: string;
  authorised: any;
  nameerrorbutton: boolean;
  phoneerrorbutton: boolean;
  firstnameerror: boolean;
  firstnameerrorbutton: boolean;
  lastnameerrorbutton: boolean;
  lastnameerror: boolean;
  id: string;

  constructor(private pc: ProcessCacheService,
    private deviceService: DeviceDetectorService,
    private utilityService: UtilityFunctionsService) { }

  ngOnInit(): void {
    // console.log("LeadCapture: ", this.pc.getUniquiId());
    this.heroForm = new FormGroup({
      name: new FormControl(this.name),
      phone: new FormControl(this.phone),
      email: new FormControl(this.email, Validators.required),
      authorise: new FormControl(this.authorise, Validators.required)
    });
    this.authorised = false;
    this.nameerrorbutton = true;
    this.phoneerrorbutton = true;
    console.log('authorised :', this.authorised);

    _hsq = window._hsq = window._hsq || [];
    _hsq.push(['setPath', '/home']);
  }

  checkfirstnamelength() {

    if (!this.firstname) {
      return;
    }

    if (this.firstname.length <= 1) {
      this.firstnameerror = true;
      this.firstnameerrorbutton = true;
    }
    else if (this.firstname.length > 30) {
      this.firstnameerror = true;
      this.firstnameerrorbutton = true;
    }
    else if (this.firstname === '') {
      this.firstnameerror = false;
      this.firstnameerrorbutton = true;
    }
    else {
      this.firstnameerror = false;
      this.firstnameerrorbutton = false;
    }
  }

  checklastnamelength() {

    if (!this.lastname) {
      return;
    }

    if (this.lastname.length <= 1) {
      this.lastnameerror = true;
      this.lastnameerrorbutton = true;
    }
    else if (this.lastname.length > 30) {
      this.lastnameerror = true;
      this.lastnameerrorbutton = true;
    }
    else if (this.lastname === '') {
      this.lastnameerror = false;
      this.lastnameerrorbutton = true;
    }
    else {
      this.lastnameerror = false;
      this.lastnameerrorbutton = false;
    }
  }

  phonelength() {
    // debugger

    if (this.phone?.length > 12) {
      this.phone = this.phone?.replace('+1', '').replace('+', '').replace(/\(/g, '').replace(/\)/g, '').replace(/ /g, '').replace(/-/g, '');
      console.log(this.phone);
      this.phone = this.phone.substring(0, 3) + '-' + this.phone.substring(3, 6) + '-' + this.phone.substring(6);
    }
    if (!this.phone) {
      return;
    }

    if (this.phone.length === 3) {
      this.phone = this.phone + '-';
      this.phoneerror = true;
      this.phoneerrorbutton = true;
    }
    else if (this.phone.length === 7) {
      this.phone = this.phone + '-';
      this.phoneerror = true;
      this.phoneerrorbutton = true;
    }
    else if (this.phone.length < 12) {
      this.phoneerror = true;
      this.phoneerrorbutton = true;
    } else if (this.phone.length > 12) {
      this.phoneerror = true;
      this.phoneerrorbutton = true;
    } else {
      this.phoneerror = false;
      this.phoneerrorbutton = false;
    }
  }

  changeauth(even) {
    if (even.target.checked == true) {
      this.authorised = true;
    }
    else {
      this.authorised = false;
    }
    console.log('authorised :', this.authorised);
    console.log('phoneerror :', this.phoneerror);
    console.log('nameerror :', this.nameerror);
  }

  callback(divtype: string, form: NgForm) {
    // debugger
    //this.divchange.emit(divtype);
    if (divtype == 'thankyou') {
      const inputjson = {
        'id': this.pc.getUniquiId(),
        'firstname': this.firstname,
        'lastname': this.lastname,
        'name': `${this.firstname} ${this.lastname}`,
        'age': this.age,
        'gender': this.gender,
        'phone': this.phone,
        'email': this.email,
        deviceInfo: ''
      } as unknown as HubSpotInputJSON;
      this.epicFunction();
      inputjson.deviceInfo = this.deviceInfo;
      //debugger
      //console.log("details :",json)
      //debugger
      this.submitdata(inputjson, form);
      this.hideButton.emit();
    }
    else {
      this.divchange.emit(divtype);
    }

    if (window && window.innerWidth < 420)
      document.body.style.position = 'static';

  }
  epicFunction() {
    console.log('hello `Home` component');
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();
    const isDesktopDevice = this.deviceService.isDesktop();
    console.log('deviceService : ', this.deviceInfo);
    console.log('deviceService isMobile: ', isMobile);  // returns if the device is a mobile device (android / iPhone / windows-phone etc)
    console.log('deviceService isTablet: ', isTablet);  // returns if the device us a tablet (iPad etc)
    console.log('deviceService isDesktopDevice: ', isDesktopDevice); // returns if the app is running on a Desktop browser.
  }

  resetForm(form: NgForm) {
    form.reset();
    this.authorised = false;
    this.firstnameerrorbutton = true;
    this.lastnameerrorbutton = true;
    this.phoneerrorbutton = true;
    this.phone = '';
    this.email = '';
    this.lastname = '';
    this.firstname = '';

    if ('homeScore' === this.parent) {
      this.authorised = true;
    }
  }

  public submitdata(inputjson: HubSpotInputJSON, form: NgForm) {

    let leadType = 'life';

    if ('homeScore' === this.parent) {
      inputjson['interest_type'] = 'Home';
      leadType = 'home';
    }

    const eventOptionsPart1 = {
      'path': `${leadType}ScoreLeadCapture`,
      'page_title': `${leadType}ScoreLeadCapture`,
      'url': window.location.href
    };
    this.utilityService.eventWrapped(eventOptionsPart1, 'formSubmit', 'request_a_callback', 'event-label:request_a_callback');
    console.log('LeadCapture:inputjson: ', inputjson);
    // debugger;
    localStorage.setItem('bubble-name', inputjson.name);
    localStorage.setItem('bubble-email', inputjson.email);
    localStorage.setItem('bubble-phone', inputjson.phone);
    // this.hubSpotSaveLead()

    if (inputjson.id) {
      this.id = inputjson.id;
      inputjson['application_stage'] = 'Lead';
      inputjson['interest_type'] = 'Life';
      inputjson['full_address'] = this['fullAddress'];
      inputjson['lead_source'] = 'Bubble';

      if ('homeScore' === this.parent) {
        inputjson['interest_type'] = 'Home';
      }

      this.pc.saveUpdateClientData(inputjson);
      this.pc.hubSpotSyncAfterEmailFetch(inputjson);

      setTimeout(() => this.divchange.emit('thankyou'), 100);

      console.log(inputjson, 'inputJSON');

      this.resetForm(form);
    }
  }
}
