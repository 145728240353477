import { Options } from '@angular-slider/ngx-slider';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import pc from 'bit-uibl-data-driver';
import { environment } from 'src/environments/environment';
import { formatDate } from '@angular/common';
import axios from 'axios';
@Component({
  selector: 'app-termlifeback9',
  templateUrl: './termlifeback9.component.html',
  styleUrls: ['./termlifeback9.component.css']
})
export class Termlifeback9Component implements OnInit {
  @Output() stoploader = new EventEmitter<any>();
  @Output() startloader = new EventEmitter<any>();
  @Output() changepolicy = new EventEmitter<any>();
  @Output() checkpopup = new EventEmitter<any>();
  @Output() emitback9popup = new EventEmitter<any>();
  @Output() openModal = new EventEmitter<any>();
   @ViewChild('wnssbliRediect') wnssbliRediect: ElementRef<HTMLElement>;
   commaninput: any;
   input_array: any;
   coveragedisplay: any;
   termdisplay: any;
   termplanstartat: any;
   wnsquoteamount: any;
   returntermLength: any;
   coveragetodisplayonslider: any;
   backButton: string;
   disablerefreshbutton: any;
   allplanstartat: any;
   appointmentdate: any;
   callback_time: any;
   phone: any;
   schedulecall: boolean;
   callback_hour: any;
   callback_minutes: any;
   popupclosed: boolean;
   redirecturl: any;
   selectedmeridum: string;
   selectednonapply: any;
   wnssbliRediecturl: string;
   constructor(private activatedroute: ActivatedRoute, public router: Router, private modalService: NgbModal, private pc: ProcessCacheService, private http: HttpClient, private utilityService: UtilityFunctionsService) { }
   detailsText = 'More plan details';
   hide: boolean;
   quotes = [];
   clientid: any;
   existingData: any;
   mortgage: string;
   kids: string;
   annualIncome: string;
   dob: string;
   age: any;
   termLength: any;
   showLoader: boolean;
   coverage: any;
   returnpremium: any;
   quickQuoteData: any;
   uniqueId: any;
   commonAddress: any;
   city: any;
   zipcode: any;
   state: any;
   height: any;
   weight: any;
   riskClass: string;
   fullName: any;
   emailid: string;
   selectedCardData: any;
   displayAddress: any;
   insurancetype: any;
   policytype: any;
   termtype: any;
   sortby: any;
   allquotes: any;
   firstname: any;
   lastname: any;
   sorttext: any;
   makesblicall: any;
   makewnscall: any;
   hours = ['Hr', '08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07'];
   minutes = ['Mi', '00', '30'];
   merdiaum = ['AM', 'PM'];
   coverageoptions: Options = {
     showTicksValues: false,
     showSelectionBar: true,
     showTicks: false,
     hidePointerLabels: true,
     hideLimitLabels: true,
     keyboardSupport: false,
     animateOnMove: true,
     getPointerColor: (value: number): string => {
       return 'rgb(255, 112, 7)';
     },
     getSelectionBarColor: (value: number): string => {
       return 'rgb(255, 112, 7)';
     },
     stepsArray: [
       {
         value: 25000,
         legend: '25,000'
       }, {
         value: 50000,
         legend: '50,000'
       }, {
         value: 100000,
         legend: '100,000'
       }, {
         value: 150000,
         legend: '150,000'
       },
       {
         value: 200000,
         legend: '200,000'
       }, {
         value: 250000,
         legend: '250,000'
       },
       {
         value: 500000,
         legend: '500,000'
       },
       {
         value: 750000,
         legend: '750,000'
       },
       {
         value: 1000000,
         legend: '1,000,000'
       },
       {
         value: 1250000,
         legend: '1,250,000'
       },
       {
         value: 1500000,
         legend: '1,500,000'
       },
       {
         value: 1750000,
         legend: '1,750,000'
       },
       {
         value: 2000000,
         legend: '2,000,000'
       },
       {
         value: 2500000,
         legend: '2,500,000'
       },
       {
         value: 5000000,
         legend: '5,000,000'
       },
       {
         value: 7500000,
         legend: '7,500,000'
       },
       {
         value: 10000000,
         legend: '10,000,000'
       }
     ]
   };
   termoptions: Options = {
     showTicksValues: false,
     showSelectionBar: true,
     showTicks: true,
     hidePointerLabels: true,
     hideLimitLabels: true,
     keyboardSupport: false,
     animateOnMove: true,
     getPointerColor: (value1: number): string => {
       return '#ff7007';
     },
     getSelectionBarColor: (value1: number): string => {
       return '#ff7007';
     },
     stepsArray: [{
       value: 10,
       legend: ''
     }, {
       value: 15,
       legend: ''
     }, {
       value: 20,
       legend: ''
     }, {
       value: 25,
       legend: ''
     }, {
       value: 30,
       legend: ''
     },
     {
       value: 35,
       legend: ''
     },
     {
       value: 40,
       legend: ''
     },
     {
       value: 65,
       legend: ''
     }]
   };
   ngOnInit(): void {
     this.selectedmeridum = 'PM';
     const format = 'yyyy-MM-dd';
     const myDate = new Date();
     const locale = 'en-US';
     this.appointmentdate = formatDate(myDate, format, locale);
     this.callback_hour = 'Hr';
     this.callback_minutes = 'Mi';
     this.policytype = '1';
     this.termtype = ' Year Term';
     this.sortby = '1';
     this.sorttext = 'Recommended';
     this.returnpremium = localStorage.getItem('returnpremium');
     this.termplanstartat = localStorage.getItem('termpremium');
     this.wnsquoteamount = localStorage.getItem('wnspremium');
     this.allplanstartat = localStorage.getItem('allpolicypremium');
     this.backButton = localStorage.getItem('lifeRecoBackButton');
     this.disablerefreshbutton = true;
     this.activatedroute.params.subscribe(params => {
       this.uniqueId = params['uniqueId'];
       this.clientid = params['clientId'];
       this.displayAddress = params['address'];
       this.insurancetype = params['insuranceType'];
     }
     );
   }
   openVerticallyCentered(content) {
     this.modalService.open(content, { centered: true, windowClass: 'custom-modal' });
   }
   openVerticallyCentered1(contentpolicy) {
     this.modalService.open(contentpolicy, { centered: true, windowClass: 'custom-modal' });
   }
   openVerticallyCentered2(coverage) {
     document.getElementById('coveragemodal').style.display = 'block';
   }




   public async onlyback9(input_array, commonInput) {

     this.input_array = input_array;
     this.selectednonapply = commonInput['selectednonapply'];
     this.coverage = commonInput.coverage;
     this.termLength = commonInput.term;
     this.coveragedisplay = commonInput.coverage;
     this.coveragetodisplayonslider = commonInput.coverage;
     this.termdisplay = commonInput.term;

 if (Number(this.termLength) > 20 && (65 - this.age) > 20) {
  this.makesblicall = false;
  this.makewnscall = true;
}
else {
  this.makesblicall = true;
  this.makewnscall = true;
}
if (commonInput.filtersbli) {
  this.makesblicall = false;
  this.makewnscall = false;
}

     commonInput['selectedtype'] = 'term';
     commonInput['producttypes'] = 'term';
     this.quotes = [];
     this.allquotes = [];
     this.coverage = commonInput.coverage;
     if (Number(this.termLength) == 30) {
       this.returntermLength = this.termLength;
     }
     else {
       this.returntermLength = 20;
     }
     this.commaninput = commonInput;
     const returninput_array = JSON.parse(JSON.stringify(input_array));
     returninput_array['quotes'][0].product_categories = [`${this.returntermLength} Year Return of Premium Term`];
     if (Number(this.termLength != 65) && this.insurancetype == 'life') {
       const bothback9andquickquote = await Promise.all([this.getback9Data(input_array), this.getQuotess(commonInput, this.makesblicall, this.makewnscall), this.getback9returnData(returninput_array)]).then(function (values) {
         console.log(values);
         return values;
       }).catch(function (err) {

         return err;
       });
       this.stoploader.next();
       setTimeout(() => {
         (this.checkpopup.emit());
       }, 10000);
       let testquotes = [];
       this.quickQuoteData = bothback9andquickquote[1];
       if (this.quickQuoteData != undefined) {
         if (this.quickQuoteData[0]['head']['quote_amount'] == undefined) {
           this.quickQuoteData[0]['head']['quote_amount'] = '$0';
         }
         if (this.quickQuoteData[1]['head']['quote_amount'] == undefined) {
           this.quickQuoteData[1]['head']['quote_amount'] = '$0';
         }
         const qoute1 =
        {
          'carrier': {
            'avatar': this.quickQuoteData[0].head.carrier_icon,
            'avatar_url': this.quickQuoteData[0].head.carrier_icon,
            'name': 'Next By Pacific Life'
          },
          'product': {
            'name': 'Next Term Life'
          },
          'premium': this.quickQuoteData[0].head.quote_amount.replace('$', ''),
          'appflow': true,
          'carrierId': this.quickQuoteData[0].carrierId
        };

         const qout2 =
        {
          'carrier': {
            'avatar': this.quickQuoteData[1].head.carrier_icon,
            'avatar_url': this.quickQuoteData[1].head.carrier_icon,
            'name': 'SBLI'
          },
          'product': {
            'name': 'Simplified Issue Term Life '
          },
          'premium': this.quickQuoteData[1].head.quote_amount.replace('$', ''),
          'appflow': true,
          'carrierId': this.quickQuoteData[1].carrierId
        };

         (Number(qoute1.premium) != 0) ? this.quotes.push(qoute1) : 0;
         (Number(qout2.premium) != 0 && Number(commonInput.term) != 65) ? this.quotes.push(qout2) : 0;
         (Number(qoute1.premium) != 0 || Number(qout2.premium) != 0) ? this.quotes = this.quotes.reverse() : 0;
         testquotes = testquotes.concat(bothback9andquickquote[0], this.quotes);
       }
       else {
         if (bothback9andquickquote[2] != undefined) {
           testquotes = testquotes.concat(bothback9andquickquote[0], bothback9andquickquote[2]);
         }
         else {
           testquotes = bothback9andquickquote[1];
         }

       }
       this.quotes = testquotes;
       this.quotes = testquotes;
       for (let i = 0; i < this.quotes.length; i++) {
         if (this.quotes[i]?.['premium']?.toString().includes('.')) {
           this.quotes[i]['premium'] = Number(this.quotes[i]['premium']) / 30;
           this.quotes[i]['premium'] = (Math.round(this.quotes[i]['premium'] * 100) / 100).toFixed(2);
           const spt_amt = this.quotes[i]['premium'].toString().split('.');
           if (spt_amt[1].length == 1) {
             spt_amt[1] = spt_amt[1] + '0';
           }
           this.quotes[i].before_dcimal = spt_amt[0];
           this.quotes[i].after_dcimal = '.' + spt_amt[1];
         }
         else {
           this.quotes[i]['premium'] = Number(this.quotes[i]?.['premium']) / 30;
           this.quotes[i]['premium'] = (Math.round(this.quotes[i]['premium'] * 100) / 100).toFixed(2);
           this.quotes[i].before_dcimal = this.quotes[i]['premium'];
           if (!this.quotes[i]['premium'].toString().includes('.')) {
             this.quotes[i].after_dcimal = '.00';
           }
           else {
             const spt_amt = this.quotes[i]?.['premium'].toString().split('.');
             this.quotes[i].before_dcimal = spt_amt[0];
             this.quotes[i].after_dcimal = '.' + spt_amt[1];
           }
         }
         if (this.quotes[i].carrier['name'] === 'SBLI') {
           this.quotes[i].highlight = 'Instant Decision';
           this.quotes[i].point1 = 'Completely digital experience with no medical exam, phone calls or in-person appointments required.';
           this.quotes[i].point2 = 'Easy online application that you can start and finish in minutes, with a decision on the spot.';
           this.quotes[i].benefit1 = '20-day risk-free cancellation period';
           this.quotes[i].benefit2 = 'No medical exam ever';
           this.quotes[i].benefit3 = 'Coverage from $25,000 to $1M';
           this.quotes[i].benefit4 = 'Guaranteed Level Premium Term (10, 15, and 20 years)';
           this.quotes[i].benefit5 = 'Accelerated Death Benefit Rider';
           this.quotes[i].benefit6 = '';
           this.quotes[i].policy_type = 'Term Life';
           this.quotes[i].policy_type1 = '30 days easy refund';
           this.quotes[i].policy_type2 = 'Completely online';
           this.quotes[i].pdflink = 'https://www.getmybubble.com/SBLI/';
           this.quotes[i].showtooltip1 = false;
           this.quotes[i].showtooltip2 = false;
           this.quotes[i].showtooltip3 = true;
           this.quotes[i].showtooltip4 = false;
           this.quotes[i].showtooltip5 = false;
           this.quotes[i].showtooltip6 = false;
           this.quotes[i].tooltipcontent1 = '';
           this.quotes[i].tooltipcontent2 = '';
           this.quotes[i].tooltipcontent3 = 'Early access to a portion of the death benefit if the insured is diagnosed with a terminal illness';
           this.quotes[i].tooltipcontent4 = '';
           this.quotes[i].tooltipcontent5 = '';
           this.quotes[i].tooltipcontent6 = '';
           this.quotes[i].ourproducts = true;
           this.quotes[i].redirectUrl = `lifeCarrierTwo/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}`;
         }

         else if (this.quotes[i].carrier['name'] === 'Banner') {
           this.quotes[i].highlight = 'Instant Decision';
           this.quotes[i].point1 = 'You may be eligible for an instant decision.';
           this.quotes[i].point2 = 'Based on your answers to underwriting questions, an exam may not be required.';
           this.quotes[i].benefit1 = 'Term periods of 10, 15, 20, 25, 30, 35 and 40 year durations.';
           this.quotes[i].benefit2 = 'Term Rider';
           this.quotes[i].benefit3 = 'Policy Conversion';
           this.quotes[i].benefit4 = 'Accelerated Death Benefit Rider';
           this.quotes[i].benefit5 = 'Waiver of Premium';
           this.quotes[i].benefit6 = '';
           this.quotes[i].policy_type = 'Term Life';
           this.quotes[i].policy_type1 = '';
           this.quotes[i].policy_type2 = '';
           this.quotes[i].pdflink = 'https://cdn.back9ins.com/product-guides/105-LAA2233.pdf';
           this.quotes[i].showtooltip1 = false;
           this.quotes[i].showtooltip2 = true;
           this.quotes[i].showtooltip3 = true;
           this.quotes[i].showtooltip4 = true;
           this.quotes[i].showtooltip5 = true;
           this.quotes[i].showtooltip6 = false;
           this.quotes[i].ourproducts = false;
           this.quotes[i].tooltipcontent1 = '';
           this.quotes[i].tooltipcontent2 = 'An additional insurance rider that provides temporary coverage for a period shorter than what the base policy provides.';
           this.quotes[i].tooltipcontent3 = 'Conversions can be requested anytime within the duration of the guaranteed level premium period, or up to attained age 70, whichever comes first. Policies issued at age 66 or over are convertible during the first five policy years.';
           this.quotes[i].tooltipcontent4 = 'The death benefit can be paid prior to the insured’s death if he or she is facing a qualifying terminal illness. The maximum accelerated death benefit amount is the lesser of $500,000 or 75% of the policy’s primary death benefit.';
           this.quotes[i].tooltipcontent5 = 'We will waive all premiums that are due during total disability if we are given proof of total disability and such total disability has then existed continuously for at least six months.';
           this.quotes[i].tooltipcontent6 = '';
           if (Number(this.termLength) == 10) { this.quotes[i].productid = 270; }
           else if (Number(this.termLength) == 15) { this.quotes[i].productid = 472; }
           else if (Number(this.termLength) == 20) { this.quotes[i].productid = 105; }
           else if (Number(this.termLength) == 25) { this.quotes[i].productid = 2499; }
           else if (Number(this.termLength) == 30) { this.quotes[i].productid = 323; }
           else if (Number(this.termLength) == 35) { this.quotes[i].productid = 2721; }
           else if (Number(this.termLength) == 40) { this.quotes[i].productid = 2742; }
           this.quotes[i].redirectUrl = `applicationflow?clientid=${this.clientid}&firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
         }
         else if (this.quotes[i].carrier['name'] === 'Next By Pacific Life') {
           this.quotes[i].highlight = 'Covers upto Age 65';
           this.quotes[i].point1 = 'Let’s you easily increase or decrease your coverage to keep up with life’s changes.';
           this.quotes[i].point2 = 'Locks in your original health rating so future increases are more affordable.';
           this.quotes[i].benefit1 = 'Coverage from $250,000 to $10M';
           this.quotes[i].benefit2 = 'Easily increase or decrease coverage to keep up with life’s changes';
           this.quotes[i].benefit3 = 'Conversion Options';
           this.quotes[i].benefit4 = 'Terminal Illness Rider';
           this.quotes[i].benefit5 = '';
           this.quotes[i].benefit6 = '';
           this.quotes[i].policy_type1 = '$3B Benefits Paid';
           this.quotes[i].policy_type2 = '';
           this.quotes[i].policy_type = 'Term Life';
           this.quotes[i].showtooltip1 = false;
           this.quotes[i].showtooltip2 = false;
           this.quotes[i].showtooltip3 = true;
           this.quotes[i].showtooltip4 = true;
           this.quotes[i].showtooltip5 = false;
           this.quotes[i].showtooltip6 = false;
           this.quotes[i].ourproducts = true;
           this.quotes[i].tooltipcontent1 = '';
           this.quotes[i].tooltipcontent2 = '';
           this.quotes[i].tooltipcontent3 = 'Prior to age 65, you can convert all or part of your coverage to a number of eligible cash value policies offered by Pacific Life.';
           this.quotes[i].tooltipcontent4 = ' Access up to 75% of the policy’s death benefit if the insured is diagnosed with a terminal illness.';
           this.quotes[i].tooltipcontent5 = '';
           this.quotes[i].tooltipcontent6 = '';
           this.quotes[i].pdflink = 'https://www.getmybubble.com/next-by-pacific-life/';
           this.quotes[i].redirectUrl = `Appflowstart/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}/paclife`;
         }
         else if (this.quotes[i].carrier['name'] === 'W&S') {
           this.quotes[i].highlight = 'Instant Decision';
           this.quotes[i].point1 = 'Completely digital experience with no medical exam, phone calls or in-person appointments required.';
           this.quotes[i].point2 = 'Decreasing term policy with monthly payment coverage up to $10,000 depending on your age and policy duration.';
           this.quotes[i].benefit1 = 'Completely digital';
           this.quotes[i].benefit2 = 'Monthly payout for your family';
           this.quotes[i].benefit3 = 'A+ rating from A.M. Best';
           this.quotes[i].benefit4 = 'Easy online application';
           this.quotes[i].benefit5 = 'Monthly insurance premium guaranteed not to increase';
           this.quotes[i].benefit6 = 'Coverage duration up to 30 years (not beyond age 65)';
           this.quotes[i].policy_type = 'Decreasing term policy';
           this.quotes[i].policy_type1 = '30 days easy refund';
           this.quotes[i].policy_type2 = 'Completely online';
           this.quotes[i].showtooltip1 = false;
           this.quotes[i].showtooltip2 = false;
           this.quotes[i].showtooltip3 = false;
           this.quotes[i].showtooltip4 = false;
           this.quotes[i].showtooltip5 = false;
           this.quotes[i].showtooltip6 = false;
           this.quotes[i].tooltipcontent1 = '';
           this.quotes[i].tooltipcontent2 = '';
           this.quotes[i].tooltipcontent3 = '';
           this.quotes[i].tooltipcontent4 = '';
           this.quotes[i].tooltipcontent5 = '';
           this.quotes[i].tooltipcontent6 = '';
           this.quotes[i].ourproducts = true;
           this.quotes[i].pdflink = 'https://www.getmybubble.co/wns/';
           this.quotes[i].redirectUrl = `lifeCarrierThree/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}`;
         }
         else if (this.quotes[i].carrier['name'] === 'Transamerica') {
           if (this.quotes[i].product['name'] === `Trendsetter LB ${this.termLength}`) {
             this.quotes[i].highlight = 'Living Benefits';
             this.quotes[i].point1 = 'You can accelerate your death benefit for chronic and critical illness.';
             this.quotes[i].point2 = 'Based on your answers to underwriting questions, an exam may not be required.';
             this.quotes[i].pdflink = 'https://cdn.back9ins.com/product-guides/441-TransamericaTrendsetterLBBrochure.pdf';
             this.quotes[i].benefit1 = 'Available for issue ages:18-80';
             this.quotes[i].benefit2 = 'Coverage available:$25,000 to $2,000,000';
             this.quotes[i].benefit3 = 'Non-med underwriting* available:Up to $249,999*';
             this.quotes[i].benefit4 = 'Choice of level term periods:10, 15, 20, 25, or 30 years';
             this.quotes[i].benefit5 = 'Terminal Illness ADB';
             this.quotes[i].benefit6 = '';
             this.quotes[i].policy_type = 'Term Life';
             this.quotes[i].policy_type1 = '';
             this.quotes[i].policy_type2 = '';
             this.quotes[i].showtooltip1 = false;
             this.quotes[i].showtooltip2 = false;
             this.quotes[i].showtooltip3 = false;
             this.quotes[i].showtooltip4 = false;
             this.quotes[i].showtooltip5 = false;
             this.quotes[i].showtooltip6 = false;
             this.quotes[i].tooltipcontent1 = '';
             this.quotes[i].tooltipcontent2 = '';
             this.quotes[i].tooltipcontent3 = '';
             this.quotes[i].tooltipcontent4 = '';
             this.quotes[i].tooltipcontent5 = '';
             this.quotes[i].tooltipcontent6 = '';
             this.quotes[i].ourproducts = false;
             if (Number(this.termLength) == 10) { this.quotes[i].productid = 502; }
             else if (Number(this.termLength) == 15) { this.quotes[i].productid = 167; }
             else if (Number(this.termLength) == 20) { this.quotes[i].productid = 441; }
             else if (Number(this.termLength) == 25) { this.quotes[i].productid = 217; }
             else if (Number(this.termLength) == 30) { this.quotes[i].productid = 716; }
             this.quotes[i].redirectUrl = `applicationflow?clientid=${this.clientid}&firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
           }
           else {
             this.quotes[i].highlight = 'No Physical Exam*';
             this.quotes[i].point1 = 'Terminal Illness Accelerated Death Benefit (ADB) Endorsement';
             this.quotes[i].point2 = '';
             this.quotes[i].pdflink = 'https://cdn.back9ins.com/product-guides/531-133039_1119_Consumer_Guide_to_Transamerica_Trendsetter_Super_Series_Brochure_FINAL_DIGITAL.pdf';
             this.quotes[i].benefit1 = 'Available for issue ages:18-80';
             this.quotes[i].benefit2 = 'Coverage available:$25,000 to $2,000,000';
             this.quotes[i].benefit3 = 'Non-med underwriting* available:Up to $249,999*';
             this.quotes[i].benefit4 = 'Choice of level term periods:10, 15, 20, 25, or 30 years';
             this.quotes[i].benefit5 = 'Terminal Illness ADB';
             this.quotes[i].benefit6 = '';
             this.quotes[i].policy_type = 'Term Life';
             this.quotes[i].policy_type1 = '';
             this.quotes[i].policy_type2 = '';
             this.quotes[i].showtooltip1 = false;
             this.quotes[i].showtooltip2 = false;
             this.quotes[i].showtooltip3 = false;
             this.quotes[i].showtooltip4 = false;
             this.quotes[i].showtooltip5 = false;
             this.quotes[i].showtooltip6 = false;
             this.quotes[i].ourproducts = false;
             this.quotes[i].tooltipcontent1 = '';
             this.quotes[i].tooltipcontent2 = '';
             this.quotes[i].tooltipcontent3 = '';
             this.quotes[i].tooltipcontent4 = '';
             this.quotes[i].tooltipcontent5 = '';
             this.quotes[i].tooltipcontent6 = '';
             if (Number(this.termLength) == 10) { this.quotes[i].productid = 425; }
             else if (Number(this.termLength) == 15) { this.quotes[i].productid = 480; }
             else if (Number(this.termLength) == 20) { this.quotes[i].productid = 531; }
             else if (Number(this.termLength) == 25) { this.quotes[i].productid = 573; }
             else if (Number(this.termLength) == 30) { this.quotes[i].productid = 640; }
             this.quotes[i].redirectUrl = `applicationflow?clientid=${this.clientid}&firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
           }
           this.quotes[i].benefit1 = 'Accelerated Death Benefit Rider';
           this.quotes[i].benefit2 = 'Accidental death';
           this.quotes[i].benefit3 = 'Waiver of premium';
           this.quotes[i].benefit4 = 'Child term';
           this.quotes[i].benefit5 = '';
           this.quotes[i].benefit6 = '';
           this.quotes[i].policy_type = 'Term Life';
           this.quotes[i].policy_type1 = '';
           this.quotes[i].policy_type2 = '';
           this.quotes[i].showtooltip1 = false;
           this.quotes[i].showtooltip2 = false;
           this.quotes[i].showtooltip3 = false;
           this.quotes[i].showtooltip4 = false;
           this.quotes[i].showtooltip5 = false;
           this.quotes[i].showtooltip6 = false;
           this.quotes[i].tooltipcontent1 = '';
           this.quotes[i].tooltipcontent2 = '';
           this.quotes[i].tooltipcontent3 = '';
           this.quotes[i].tooltipcontent4 = '';
           this.quotes[i].tooltipcontent5 = '';
           this.quotes[i].ourproducts = false;
           this.quotes[i].tooltipcontent6 = '';
           this.quotes[i].redirectUrl = `applicationflow?clientid=${this.clientid}&firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
         }
         else if (this.quotes[i].carrier['name'] === 'North American') {
           this.quotes[i].highlight = 'Living Benefits';
           this.quotes[i].point1 = 'Accelerated death benefits can accelerate a portion of the death benefit, should the insured become critically, chronically, or terminally ill.';
           this.quotes[i].point2 = 'Children’s insurance rider allows you to insure your child’s life at great rates too.';
           this.quotes[i].benefit1 = 'Accelerated death benefit ';
           this.quotes[i].benefit2 = 'Children’s insurance rider';
           this.quotes[i].benefit3 = 'Waiver of premium rider';
           this.quotes[i].benefit4 = '';
           this.quotes[i].benefit5 = '';
           this.quotes[i].benefit6 = '';
           this.quotes[i].policy_type = 'Term Life';
           this.quotes[i].policy_type1 = '';
           this.quotes[i].policy_type2 = '';
           this.quotes[i].pdflink = 'https://cdn.back9ins.com/product-guides/550-608NM-1%2BADDvantage%2BTerm%2BConsumer%2BGuide.pdf';
           this.quotes[i].showtooltip1 = false;
           this.quotes[i].showtooltip2 = false;
           this.quotes[i].showtooltip3 = false;
           this.quotes[i].showtooltip4 = false;
           this.quotes[i].showtooltip5 = false;
           this.quotes[i].showtooltip6 = false;
           this.quotes[i].tooltipcontent1 = '';
           this.quotes[i].tooltipcontent2 = '';
           this.quotes[i].tooltipcontent3 = '';
           this.quotes[i].tooltipcontent4 = '';
           this.quotes[i].tooltipcontent5 = '';
           this.quotes[i].tooltipcontent6 = '';
           this.quotes[i].ourproducts = false;
           if (Number(this.termLength) == 10) { this.quotes[i].productid = 440; }
           else if (Number(this.termLength) == 15) { this.quotes[i].productid = 499; }
           else if (Number(this.termLength) == 20) { this.quotes[i].productid = 550; }
           else if (Number(this.termLength) == 30) { this.quotes[i].productid = 610; }
           this.quotes[i].redirectUrl = `applicationflow?clientid=${this.clientid}&firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
         }
         else if (this.quotes[i].carrier['name'] === 'Prudential Financial') {
           this.quotes[i].highlight = 'No Physical Exam*';
           this.quotes[i].point1 = 'Conversion privilege allows you to convert the policy to any permanent policy we offer, guaranteed.';
           this.quotes[i].point2 = 'If you become terminally ill, our Living Needs Benefit will pay out a portion of your death, benefit while you’re still living.';
           this.quotes[i].benefit1 = 'Accidental Death Benefit';
           this.quotes[i].benefit2 = ' Waiver of Premium Rider';
           this.quotes[i].benefit3 = 'Children’s Protection Rider';
           this.quotes[i].benefit4 = '';
           this.quotes[i].benefit5 = '';
           this.quotes[i].benefit6 = '';
           this.quotes[i].policy_type = 'Term Life';
           this.quotes[i].policy_type1 = '';
           this.quotes[i].policy_type2 = '';
           this.quotes[i].pdflink = 'https://cdn.back9ins.com/product-guides/665-1010826-00002_Term%2BEssential%2BTerm%2BElite%2BConsumer%2BBrochure%2B(1).pdf';
           this.quotes[i].showtooltip1 = false;
           this.quotes[i].showtooltip2 = false;
           this.quotes[i].showtooltip3 = false;
           this.quotes[i].showtooltip4 = false;
           this.quotes[i].showtooltip5 = false;
           this.quotes[i].showtooltip6 = false;
           this.quotes[i].tooltipcontent1 = '';
           this.quotes[i].tooltipcontent2 = '';
           this.quotes[i].tooltipcontent3 = '';
           this.quotes[i].tooltipcontent4 = '';
           this.quotes[i].tooltipcontent5 = '';
           this.quotes[i].tooltipcontent6 = '';
           this.quotes[i].ourproducts = false;
           if (Number(this.termLength) == 10) { this.quotes[i].productid = 585; }
           else if (Number(this.termLength) == 15) { this.quotes[i].productid = 649; }
           else if (Number(this.termLength) == 20) { this.quotes[i].productid = 665; }
           else if (Number(this.termLength) == 30) { this.quotes[i].productid = 579; }
           this.quotes[i].redirectUrl = `applicationflow?clientid=${this.clientid}&firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
         }
         else if (this.quotes[i].product['name'] === `${this.termLength} Year Return of Premium Term`) {
           this.quotes[i].highlight = 'No Physical Exam*';
           this.quotes[i].point1 = 'When your policy ends, the additional premium for the return of premium benefit will be returned to you in a lump sum.';
           this.quotes[i].point2 = 'Convertible to age 65 and 2 years prior to end of the level term to any non medical permanent product';
           this.quotes[i].benefit1 = '';
           this.quotes[i].policy_type = 'Return of Premium';
           this.quotes[i].policy_type1 = '';
           this.quotes[i].policy_type2 = '';
           this.quotes[i].benefit2 = '';
           this.quotes[i].benefit3 = '';
           this.quotes[i].benefit4 = '';
           this.quotes[i].ourproducts = false;
           if (Number(this.termLength) == 20) { this.quotes[i].productid = 2788; }
           else if (Number(this.termLength) == 30) { this.quotes[i].productid = 2787; }
           this.quotes[i].redirectUrl = `applicationflow?clientid=${this.clientid}&firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
         }
         else {
           this.quotes[i].highlight = 'No Physical Exam*';
           this.quotes[i].point1 = 'you may receive an instant decision.';
           this.quotes[i].point2 = 'Flexible coverage to Age 65';
           this.quotes[i].benefit1 = 'Accelerated Death Benefit Rider';
           this.quotes[i].benefit2 = 'Accidental death';
           this.quotes[i].benefit3 = 'Waiver of premium';
           this.quotes[i].benefit4 = 'Child term';
           this.quotes[i].benefit5 = '';
           this.quotes[i].benefit6 = '';
           this.quotes[i].policy_type = 'Term Life';
           this.quotes[i].policy_type1 = '';
           this.quotes[i].policy_type2 = '';
           this.quotes[i].showtooltip1 = false;
           this.quotes[i].showtooltip2 = false;
           this.quotes[i].showtooltip3 = false;
           this.quotes[i].showtooltip4 = false;
           this.quotes[i].showtooltip5 = false;
           this.quotes[i].showtooltip6 = false;
           this.quotes[i].tooltipcontent1 = '';
           this.quotes[i].tooltipcontent2 = '';
           this.quotes[i].tooltipcontent3 = '';
           this.quotes[i].tooltipcontent4 = '';
           this.quotes[i].tooltipcontent5 = '';
           this.quotes[i].tooltipcontent6 = '';
           this.quotes[i].ourproducts = false;
           this.quotes[i].redirectUrl = `applicationflow?clientid=${this.clientid}&firstname=${this.commaninput.firstName}&lastname=${this.commaninput.lastName}&state=${this.commaninput.state}&gender=${this.commaninput.gender}&health=${this.commaninput.health}&smoker=${this.commaninput.smoker}&height=${this.commaninput.back9hight}&weight=${this.commaninput.weight}&dob=${this.commaninput.dob}&email=${localStorage.getItem('bubble-email')}&phone=${this.commaninput.phone}&zip=${this.commaninput.zip}&birth_state_or_country=${this.commaninput.state}&selectedtype=${this.commaninput.selectedtype}&producttypes=${this.commaninput.producttypes}&productid=${this.quotes[i].productid}&mode=${this.commaninput.mode}&term_duration=${this.commaninput.term}&coverage=${this.commaninput.coverage}`;
         }
       }
       const test = [];
       this.quotes = this.quotes.filter(x => x.carrier['name'] === 'Banner' || x.carrier['name'] === 'Next By Pacific Life' || x.carrier['name'] === 'SBLI' || x.carrier['name'] === 'Transamerica' || x.carrier['name'] === 'North American' || x.carrier['name'] === 'Prudential Financial');
       const sbliquotes = this.quotes.filter(x => x.carrierId === 'sbli');
       // let wnsquotes = this.quotes.filter(x => x.carrierId === 'wns');
       const paclife = this.quotes.filter(x => x.carrierId === 'paclife');
       const banner = this.quotes.filter(x => x.carrier['name'] === 'Banner');
       const other = this.quotes.filter(x => x.carrierId != 'paclife' && x.carrierId != 'sbli' && x.carrier['name'] != 'Banner');
       this.quotes = test.concat(sbliquotes, paclife, banner, other);
       // let onlylifeterm = this.quotes.filter(x => x.carrierId != 'wns');
       //this.quotes = this.quotes.sort((a, b) => a.premium - b.premium);
       console.log('heyy ran');
       this.allquotes = JSON.stringify(this.quotes);
       if (this.sortby == '1') {
         this.quotes = JSON.parse(this.allquotes);
       }
       else if (this.sortby == '2') {
         this.quotes = this.quotes.sort((a, b) => a.premium - b.premium);
       }
       else if (this.sortby == '3') {
         this.quotes = this.quotes.sort((a, b) => b.premium - a.premium);
       }

       this.firstname = this.commaninput.firstName;
       this.coverage = commonInput.coverage;
       this.stoploader.next();

     }
     else {
       this.onlyquickquote(commonInput, false, false);
     }
   }



   public async onlyquickquote(commonInput, sbli, wns) {

     this.firstname = commonInput['firstName'];
     this.emailid = localStorage.getItem('bubble-email');
     this.firstname = commonInput.firstName;
     console.log(commonInput, 'commonInput');
     this.lastname = commonInput.lastName;
     this.phone = commonInput.phone;
     if (commonInput.term == 65) {
       this.age = localStorage.getItem('bubble-age');
       const calculatedTerm = 65 - Number(this.age);
       commonInput['wnsterm'] = calculatedTerm;
     }
     this.commaninput = commonInput;
     this.showLoader = true;
     const quickquote = new pc.Quotes(commonInput.uniqueId);
     await quickquote.getQuickQuote2(commonInput, sbli, wns)
       .then(response => {
         this.stoploader.next();
         console.log('output quickquote: ', response);
         console.log('commaninputafterresponse: ', commonInput);
         this.quickQuoteData = response;
         if (this.quickQuoteData[0]['head']['quote_amount'] == undefined) {
           this.quickQuoteData[0]['head']['quote_amount'] = '$0';
         }
         if (this.quickQuoteData[1]['head']['quote_amount'] == undefined) {
           this.quickQuoteData[1]['head']['quote_amount'] = '$0';
         }
         if (wns) {
           if (this.quickQuoteData[2]['head']['quote_amount'] == undefined) {
             this.quickQuoteData[2]['head']['quote_amount'] = '$0';
           }
         }

         if (wns) {
           this.wnsquoteamount = this.quickQuoteData[2]['head']['quote_amount'].replace('$', '');
           this.wnsquoteamount = Number(this.wnsquoteamount) / 30;
           this.wnsquoteamount = (Math.round(this.wnsquoteamount * 100) / 100).toFixed(2);
         }
         const qoute1 =
        {
          'carrier': {
            'avatar': this.quickQuoteData[0].head.carrier_icon,
            'avatar_url': this.quickQuoteData[0].head.carrier_icon,
            'name': 'Next By Pacific Life'
          },
          'product': {
            'name': 'Next Term Life'
          },
          'premium': this.quickQuoteData[0].head.quote_amount.replace('$', ''),
          'appflow': true,
          'carrierId': this.quickQuoteData[0].carrierId
        };

         const qout2 =
        {
          'carrier': {
            'avatar': this.quickQuoteData[1].head.carrier_icon,
            'avatar_url': this.quickQuoteData[1].head.carrier_icon,
            'name': 'SBLI'
          },
          'product': {
            'name': 'Simplified Issue Term Life '
          },
          'premium': this.quickQuoteData[1].head.quote_amount.replace('$', ''),
          'appflow': true,
          'carrierId': this.quickQuoteData[1].carrierId
        };
         (Number(qoute1.premium) != 0) ? this.quotes.push(qoute1) : 0;
         (Number(qout2.premium) != 0 && Number(commonInput.term) != 65) ? this.quotes.push(qout2) : 0;
         (Number(qoute1.premium) != 0 || Number(qout2.premium) != 0) ? this.quotes = this.quotes.reverse() : 0;
         if ((commonInput.smoker != 'Never' && commonInput.term != 65) || (this.insurancetype != 'life')) {
           for (let i = 0; i < this.quotes.length; i++) {
             if (this.quotes[i]['premium'].toString().includes('.')) {
               // this.quotes[i]['premium'] = Number(this.quotes[i]['premium']) / 30;
               // this.quotes[i]['premium'] = (Math.round(this.quotes[i]['premium'] * 100) / 100).toFixed(2);
               const spt_amt = this.quotes[i]['premium'].toString().split('.');
               if (spt_amt[1].length == 1) {
                 spt_amt[1] = spt_amt[1] + '0';
               }
               this.quotes[i].before_dcimal = spt_amt[0];
               this.quotes[i].after_dcimal = '.' + spt_amt[1];
             }
             else {
               // this.quotes[i]['premium'] = Number(this.quotes[i]['premium']) / 30;
               // this.quotes[i]['premium'] = (Math.round(this.quotes[i]['premium'] * 100) / 100).toFixed(2);
               this.quotes[i].before_dcimal = this.quotes[i]['premium'];
               this.quotes[i].after_dcimal = '.00';
             }
             if (this.quotes[i].carrier['name'] === 'SBLI') {
               this.quotes[i].highlight = 'Instant Decision';
               this.quotes[i].point1 = 'Completely digital experience with no medical exam, phone calls or in-person appointments required.';
               this.quotes[i].point2 = 'Easy online application that you can start and finish in minutes, with a decision on the spot.';
               this.quotes[i].benefit1 = '20-day risk-free cancellation period';
               this.quotes[i].benefit2 = 'No medical exam ever';
               this.quotes[i].benefit3 = 'Coverage from $25,000 to $1M';
               this.quotes[i].benefit4 = 'Guaranteed Level Premium Term (10, 15, and 20 years)';
               this.quotes[i].benefit5 = 'Accelerated Death Benefit Rider';
               this.quotes[i].benefit6 = '';
               this.quotes[i].policy_type = 'Term Life';
               this.quotes[i].policy_type1 = '30 days easy refund';
               this.quotes[i].policy_type2 = 'Completely online';
               this.quotes[i].pdflink = 'https://www.getmybubble.com/SBLI/';
               this.quotes[i].showtooltip1 = false;
               this.quotes[i].showtooltip2 = false;
               this.quotes[i].showtooltip3 = true;
               this.quotes[i].showtooltip4 = false;
               this.quotes[i].showtooltip5 = false;
               this.quotes[i].showtooltip6 = false;
               this.quotes[i].tooltipcontent1 = '';
               this.quotes[i].tooltipcontent2 = '';
               this.quotes[i].tooltipcontent3 = 'Early access to a portion of the death benefit if the insured is diagnosed with a terminal illness';
               this.quotes[i].tooltipcontent4 = '';
               this.quotes[i].tooltipcontent5 = '';
               this.quotes[i].tooltipcontent6 = '';
               this.quotes[i].ourproducts = true;
               this.quotes[i].redirectUrl = `lifeCarrierTwo/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}`;
             }
             else if (this.quotes[i].carrier['name'] === 'Next By Pacific Life') {
               this.quotes[i].highlight = 'Covers upto Age 65';
               this.quotes[i].point1 = 'Let’s you easily increase or decrease your coverage to keep up with life’s changes.';
               this.quotes[i].point2 = 'Locks in your original health rating so future increases are more affordable.';
               this.quotes[i].benefit1 = 'Coverage from $250,000 to $10M';
               this.quotes[i].benefit2 = 'Easily increase or decrease coverage to keep up with life’s changes';
               this.quotes[i].benefit3 = 'Conversion Options';
               this.quotes[i].benefit4 = 'Terminal Illness Rider';
               this.quotes[i].benefit5 = '';
               this.quotes[i].benefit6 = '';
               this.quotes[i].policy_type = 'Term Life';
               this.quotes[i].policy_type1 = '$3B Benefits Paid';
               this.quotes[i].policy_type2 = '';
               this.quotes[i].showtooltip1 = false;
               this.quotes[i].showtooltip2 = false;
               this.quotes[i].showtooltip3 = true;
               this.quotes[i].showtooltip4 = true;
               this.quotes[i].showtooltip5 = false;
               this.quotes[i].showtooltip6 = false;
               this.quotes[i].tooltipcontent1 = '';
               this.quotes[i].tooltipcontent2 = '';
               this.quotes[i].tooltipcontent3 = 'Prior to age 65, you can convert all or part of your coverage to a number of eligible cash value policies offered by Pacific Life.';
               this.quotes[i].tooltipcontent4 = ' Access up to 75% of the policy’s death benefit if the insured is diagnosed with a terminal illness.';
               this.quotes[i].tooltipcontent5 = '';
               this.quotes[i].tooltipcontent6 = '';
               this.quotes[i].ourproducts = true;
               this.quotes[i].pdflink = 'https://www.getmybubble.com/next-by-pacific-life/';
               this.quotes[i].redirectUrl = `Appflowstart/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}/paclife`;
             }

             // if (this.insurancetype != 'life') {
             //   let asdsad = this.quotes.map(x => x.premium);
             //   this.termplanstartat = Math.min.apply(null, asdsad.filter(Boolean));
             //   if (Number(this.termLength) == 30) {
             //     this.returntermLength = this.termLength;
             //   }
             //   else {
             //     this.returntermLength = 20;
             //   }
             //   let returminput_array = this.input_array
             //   returminput_array['quotes'][0].product_categories = [`${this.returntermLength} Year Return of Premium Term`];
             //   this.http.post<any>(`${environment.Nest_API_URL}/back9/getback9`, returminput_array).subscribe(async data1 => {
             //     let returnquote = data1[0];
             //     this.returnpremium = returnquote[0].premium;
             //     localStorage.setItem('returnpremium', this.returnpremium);
             //     localStorage.setItem('termpremium', this.termplanstartat);
             //     localStorage.setItem('wnspremium', this.wnsquoteamount);
             //     this.stoploader.next();
             //   }, () => {
             //     console.log('completed');
             //   });
             // }

           }
         }
         if (commonInput.term == 65) {
           this.quotes[0].highlight = 'Covers upto Age 65';
           this.quotes[0].point1 = 'Let’s you easily increase or decrease your coverage to keep up with life’s changes.';
           this.quotes[0].point2 = 'Locks in your original health rating so future increases are more affordable.';
           this.quotes[0].benefit1 = 'Coverage from $250,000 to $10M';
           this.quotes[0].benefit2 = 'Easily increase or decrease coverage to keep up with life’s changes';
           this.quotes[0].benefit3 = 'Conversion Options';
           this.quotes[0].benefit4 = 'Terminal Illness Rider';
           this.quotes[0].benefit5 = '';
           this.quotes[0].benefit6 = '';
           this.quotes[0].policy_type = 'Term Life';
           this.quotes[0].policy_type1 = '$3B Benefits Paid';
           this.quotes[0].policy_type2 = '';
           this.quotes[0].showtooltip1 = false;
           this.quotes[0].showtooltip2 = false;
           this.quotes[0].showtooltip3 = true;
           this.quotes[0].showtooltip4 = true;
           this.quotes[0].showtooltip5 = false;
           this.quotes[0].showtooltip6 = false;
           this.quotes[0].tooltipcontent1 = '';
           this.quotes[0].tooltipcontent2 = '';
           this.quotes[0].tooltipcontent3 = 'Prior to age 65, you can convert all or part of your coverage to a number of eligible cash value policies offered by Pacific Life.';
           this.quotes[0].tooltipcontent4 = ' Access up to 75% of the policy’s death benefit if the insured is diagnosed with a terminal illness.';
           this.quotes[0].tooltipcontent5 = '';
           this.quotes[0].tooltipcontent6 = '';
           this.quotes[0].ourproducts = true;
           this.quotes[0].pdflink = 'https://www.getmybubble.com/next-by-pacific-life/';
           this.quotes[0].redirectUrl = `Appflowstart/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}/paclife`;
           this.quotes[0].quote_amount = this.quickQuoteData[0]['head']['quote_amount'].replace('$', '');
           this.termplanstartat = this.quotes[0].quote_amount;
           this.coverage = this.quickQuoteData[0]['selectedDetails']['coverage'];
           this.coveragedisplay = this.coverage;
           if (this.quotes[0]['quote_amount'].toString().includes('.')) {
             const spt_amt = this.quotes[0]['quote_amount'].toString().split('.');
             if (spt_amt[1].length == 1) {
               spt_amt[1] = spt_amt[1] + '0';
             }
             this.quotes[0].before_dcimal = spt_amt[0];
             this.quotes[0].after_dcimal = '.' + spt_amt[1];
           }
           else {
             this.quotes[0].before_dcimal = this.quotes[0]['quote_amount'];
             this.quotes[0].after_dcimal = '.00';
           }
           this.stoploader.next();
         }
         this.stoploader.next();
       }).catch(error => {
         this.coveragedisplay = this.coverage;
         this.termdisplay = this.termLength;
         this.stoploader.next();
         console.log(error);
       });
   }

   public changepolicyType(id) {

     this.policytype = id;
     if (id == '1') {
       this.changepolicy.next(id);
     }
     else if (id == '2') {
       this.changepolicy.next(id);
     }
     else if (id == '3') {
       this.changepolicy.next(id);
     }
     else if (id == '4') {
       this.changepolicy.next(id);
     }
     this.modalService.dismissAll();
   }

   public refreshQuote() {
    this.pc.lifesummary({'coverage_refresh':'Yes'});
     document.getElementById('coveragemodal').style.display = 'none';
     this.startloader.emit();
     this.commaninput['term'] = this.termLength.toString();
     this.commaninput['coverage'] = this.coverage.toString();
     this.input_array['quotes'][0].face_amounts = [this.coverage.toString()];
     this.input_array['quotes'][0].product_categories = [`${this.termLength} Year Term`];
     this.pc.back9coverage = this.coverage;
     this.pc.back9term = this.termLength;
     localStorage.setItem('bubble-coverage', this.coverage);
     localStorage.setItem('bubble-termLength', this.termLength);
     const json = {
       'id': this.commaninput.clientid,
       'sbli_term': this.termLength,
       'coverage': this.coverage
     };
     this.pc.saveUpdateClientData(json);
     if (this.termLength == 65) {
       this.quotes = [];
       //this.onlyquickquote(this.commaninput, true, true);
       this.onlyquickquote(this.commaninput, false, false);
     }
     else {
       this.quotes = [];
       this.onlyback9(this.input_array, this.commaninput);
     }

   }

   public closemodal() {
     document.getElementById('coveragemodal').style.display = 'none';
   }

   sortQuotes(id, text) {
     this.sortby = id;
     this.sorttext = text;
     if (id == '1') {
       this.quotes = JSON.parse(this.allquotes);
     }
     else if (id == '2') {
       this.quotes = this.quotes.sort((a, b) => a.premium - b.premium);
     }
     else {
       this.quotes = this.quotes.sort((a, b) => b.premium - a.premium);
     }
     this.pc.lifesummary({'sort':'Yes'})
   }

   async selectPlan(qts) {

     // this.startloader.emit(1);
     const coverageAmount = this.coverage;
     localStorage.setItem('bubble-coverage', coverageAmount);

     let redirectUrl = '';
     let appflowScreen = '';
     const eventOptionsPart1 = {
       'path': 'selectLifeCarrier',
       'page_title': 'selectLifeCarrier', 'url': window.location.href
     };
     this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifePlanSelect', {
       'event_category': 'ButtonClicked', 'event_label': 'LifePlanSelect'
     });

     if (qts.carrierId == undefined) {
       // document.getElementById('back9modal').style.display = 'block';
       this.popupclosed = false;
       // this.redirecturl = qts.redirectUrl;
       const hubSpotData = {
         email: localStorage.getItem('bubble-email'),
         life_quote_amount: `$${qts.before_dcimal}${qts.after_dcimal}`,
         life_insurance_carrier: `${qts.carrier.name}_${qts.product.name}`,
         desired_life_coverage: coverageAmount,
         coverage_term_life: this.termLength,
         desired_life_term: this.termLength,
         life_insurance_type: `Term ${this.termLength} Years`
       };
       qts.coverage = coverageAmount;
       qts.term = this.termLength;
       this.emitback9popup.emit(qts);
       this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

     }
     else if (qts.carrierId == 'paclife') {
       this.selectedCardData = this.quickQuoteData[0];
       document.getElementById('back9modal').style.display = 'block';
       this.redirecturl = qts.redirectUrl;
       this.popupclosed = false;
       const hubSpotData = {
         email: localStorage.getItem('bubble-email'),
         life_quote_amount: `$${qts.before_dcimal}${qts.after_dcimal}`,
         life_insurance_carrier: 'Next by Pacific Life',
         desired_life_coverage: coverageAmount,
         coverage_term_life: this.termLength,
         desired_life_term: this.termLength,
         life_insurance_type: `Term ${this.termLength} Years`

       };
       qts.coverage = coverageAmount;
       qts.term = this.termLength;
       this.emitback9popup.emit(qts);
       this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
     }
     else if (qts.carrierId == 'sbli') {
       this.selectedCardData = this.quickQuoteData[1];
       const hubSpotData = {
         email: localStorage.getItem('bubble-email'),
         life_quote_amount: `$${qts.before_dcimal}${qts.after_dcimal}`,
         life_insurance_carrier: 'SBLI',
         desired_life_coverage: coverageAmount,
         coverage_term_life: this.termLength,
         desired_life_term: this.termLength,
         life_insurance_type: `Term ${this.termLength} Years`

       };
       this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
       // this.checkpopup.emit();
       this.openModal.emit(qts.carrierId);
     }
     else if (qts.carrierId == 'wns') {
       this.selectedCardData = this.quickQuoteData[2];
       const hubSpotData = {
         email: localStorage.getItem('bubble-email'),
         life_quote_amount: `$${qts.before_dcimal}${qts.after_dcimal}`,
         life_insurance_carrier: 'W&S',
         desired_life_coverage: coverageAmount,
         coverage_term_life: this.termLength,
         desired_life_term: this.termLength,
         life_insurance_type: `Term ${this.termLength} Years`

       };
       this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
     }

     const details = JSON.stringify(this.selectedCardData);
     localStorage.setItem('selectedCarrier', details);
     localStorage.setItem('selectedLifeCarrier', details);

     const fullName = `${localStorage.getItem('bubble-fname')} ${localStorage.getItem('bubble-lname')}`;

     ///// Deal with selected Quote persistence
     let pacificLifeQuoteId = this.pc.getQuoteId('');// pass emptystring to get -wer324 kind string
     pacificLifeQuoteId = pacificLifeQuoteId.replace('-', '');
     const clientIdLocal = localStorage.getItem('clientID');
     const carrierLocal = this.selectedCardData['head']['carrier_name'];
     const quoteAmountLocal = this.selectedCardData['head']['quote_amount'];
     localStorage.setItem('bubble-QouteAmount', quoteAmountLocal);
     const hubSpotData = {
       email: localStorage.getItem('bubble-email'),
       life_quote_amount: quoteAmountLocal,
       life_latest_premium_amount: quoteAmountLocal,
       desired_life_coverage: coverageAmount,
       coverage_term_life: this.termLength,
       desired_life_term: localStorage.getItem('bubble-termLength')

     };
     this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);



     const json = {
       'id': this.clientid,
       'sbli_totalPremium': this.selectedCardData.carrierId == 'sbli' ? this.selectedCardData['head']['quote_amount'] : 'NA',
       'paclife_totalPremium': this.selectedCardData.carrierId == 'paclife' ? this.selectedCardData['head']['quote_amount'] : 'NA',
       'selectedLife': this.selectedCardData.carrierId,
       'policytypeid': 1
     };
     this.pc.saveUpdateClientData(json);

     const inputJson4Db = {
       id: pacificLifeQuoteId,
       clientId: clientIdLocal,
       uid: pacificLifeQuoteId,
       carrier: 'Next',
       carrier_logo: this.selectedCardData['head']['carrier_icon'],
       policyName: 'Life Insurance',
       policyType: 'Life',
       policyAmount: quoteAmountLocal,
       policyNumber: '',
       coverageAmount: this.selectedCardData['selectedDetails']['coverage'],
       term: this.selectedCardData['selectedDetails']['term'],
       'policyDetails_PolicyName': carrierLocal,
       'policyDetails_HomeownerName': fullName,
       'policyDetails_ZIPCode': localStorage.getItem('bubble-zip'),
       'policyDetails_stateCode': localStorage.getItem('bubble-state'),
       'dataCategory': 'LifeQuote',
       'eventStr': 'Selected LifeQuote',
       Expires: '',
       selectedPlanDate: new Date().toISOString(),
     };
     console.log('Lead:saveAppData:inputJson4Db:', inputJson4Db);
     pc.BackendService.saveAppData(inputJson4Db).then(saveResponse => {
       console.log('Lead:createIdMapping:saveResponse: ', saveResponse);
       /////// id mapping
       const idMappingJson = {
         client_id: clientIdLocal,
         customer_id: localStorage.getItem('customer_id'),
         email_id: localStorage.getItem('bubble-email'),
         paclife_quote_id: pacificLifeQuoteId,
         status: 'LifeQuote',
         dob: localStorage.getItem('bubble-dob'),
       };
       localStorage.setItem('paclife_quote_id', pacificLifeQuoteId);

       this.pc.createIdMapping(idMappingJson);
     }).catch(error => {
       console.log('Lead:createIdMapping:error: ', error);
       this.showLoader = false;
     });

     appflowScreen = 'app-flow-start';


     if (this.insurancetype == 'life') {
       setTimeout(() => {
         if (this.selectedCardData.carrierId == 'paclife') {
           const pacjson = {
             'id': this.commaninput.clientid,
             'selectedLife': 'paclife'
           };
           this.pc.saveUpdateClientData(pacjson);

           redirectUrl = `Appflowstart/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}/${this.selectedCardData.carrierId}`;
         }
         if (this.selectedCardData.carrierId == 'sbli') {
           const sblijson = {
             'id': this.commaninput.clientid,
             'sbli_questionNumber': 1,
             'sbli_term': localStorage.getItem('bubble-termLength'),
             'sbli_coverage': localStorage.getItem('bubble-coverage'),
             'sbli_appFlowBackButton': '0',
             'selectedLife': 'sbli'
           };
           this.pc.saveUpdateClientData(sblijson).then(data => {
           }).catch(error => {
             console.log(error);
             this.showLoader = false;
           });

           redirectUrl = `lifeCarrierTwo/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}`;
         }
         if (this.selectedCardData.carrierId == 'wns') {
           const sblijson = {
             'id': this.commaninput.clientid,
             'sbli_questionNumber': 1,
             'sbli_term': localStorage.getItem('bubble-termLength'),
             'sbli_coverage': localStorage.getItem('bubble-coverage'),
             'sbli_appFlowBackButton': '0',
             'selectedLife': 'wns'
           };
           this.pc.saveUpdateClientData(sblijson).then(data => {
           }).catch(error => {
             console.log(error);
           });

           redirectUrl = `lifeCarrierThree/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}`;
         }

         // const updateJson = {
         //   otherAppflowScreen: appflowScreen,
         //   resumeUrl: redirectUrl
         // };
         // this.checkforResumeInit(updateJson, 'update').then(
         //   data => {
         //     this.stoploader.emit(2);
         //     location.href = redirectUrl;
         //     if (qts.carrierId != undefined) {
         //       let newTab = window.open();
         //       newTab.location.href=redirectUrl;
         //     window.open(redirectUrl, '_blank');
         //     }
         //     this.router.navigate([redirectUrl]);
         //   }
         // ).catch(error => console.log(error));
       }, 3000);
     }
     else {
       const updateJson = {
         otherAppflowScreen: 'selected-carriers',
         resumeUrl: `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}/${this.selectedCardData.head.carrier_name}`
       };
       this.checkforResumeInit(updateJson, 'update').then(
         data => {
           setTimeout(() => {
             this.stoploader.emit(2);
             if (qts.carrierId != undefined) {
               const newTab = window.open();
               newTab.location.href = `SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}/${this.selectedCardData.head.carrier_name}`;
             }
             // window.open(`SelectedCarriers/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}/${this.selectedCardData.head.carrier_name}`, '_blank');

           }, 2000);
         }
       ).catch(error => console.log(error));
     }



   }
   async checkforResumeInit(inputJson, type) {
     const clientData = await this.pc.checkforResumeInit(inputJson, type);
     return clientData;
   }
   public sliderEvent(even) {

     this.coveragetodisplayonslider = even.value;
     this.disablerefreshbutton = false;
   }
   public sliderEvent1(even) {

     this.termLength = even.value;
     this.disablerefreshbutton = false;
   }
   previousscreen() {


     if (this.backButton == 'PrefillResumeFlowLife') {
       location.href = 'PrefillResumeFlowLife';
     }
     else if (this.backButton == 'life-insurance') {
       location.href = 'life-insurance';
     }
     else if (this.backButton == 'CoupleAboutToGetMArriedLife') {
       location.href = 'CoupleAboutToGetMArriedLife';
     }
     else if (this.backButton == 'CoupleWithChildrenLife') {
       location.href = 'CoupleWithChildrenLife';
     }
     else if (this.backButton == 'SingleMotherLife') {
       location.href = 'SingleMotherLife';
     }
     else if (this.backButton == 'SingleParentLife') {
       location.href = 'SingleParentLife';
     }
     else if (this.backButton == 'PrefillKVLife') {
       location.href = 'bubble-Life-kellerwilliams';
     }
     else {
       location.href = `QuoteFlowV2Life/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}/previous/1`;
     }

     //this.router.navigate(['/QuoteFlowV2', this.uniqueId, this.clientId,this.displayAddress,'life','previous',1]);
   }
   public closepopup() {
     this.popupclosed = true;
     this.schedulecall = false;
     document.getElementById('back9modal').style.display = 'none';
   }
   public scheduleacall() {
     this.schedulecall = true;
   }
   callBack() {

     //this.schedulecall = true;
     this.callback_time = `${this.callback_hour}:${this.callback_minutes}:${this.selectedmeridum}`;
     const hubSpotData = {
       email: localStorage.getItem('bubble-email'),
       callback_request__life_: 'Yes',
       appointment_date: this.appointmentdate,
       callback_time: this.callback_time
     };
     this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
     document.getElementById('back9modal').style.display = 'none';
     this.popupclosed = false;
     document.getElementById('thankyouModalreco').style.display = 'block';
   }
   public callnow() {
     const hubSpotData = {
       email: localStorage.getItem('bubble-email'),
       call_initiated_by_life_user: 'Yes'
     };
     this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
   }

   public applyOnline() {
     document.getElementById('back9modal').style.display = 'none';
     this.popupclosed = true;
   }

   public closeschedule() {
     this.schedulecall = false;
   }

   public closethankyou() {
     this.popupclosed = true;
     this.schedulecall = false;
     document.getElementById('thankyouModalreco').style.display = 'none';
   }

   public getHours(even) {

     if (even.target.value == '10' || even.target.value == '11' || even.target.value == '08' || even.target.value == '09') {
       if (even.target.value == '08') {
         this.minutes = ['30'];
       }
       else {
         this.minutes = ['00', '30'];
       }
       this.selectedmeridum = 'AM';
     }
     else {
       if (even.target.value == '07') {
         this.minutes = ['00'];
       }
       else {
         this.minutes = ['00', '30'];
       }
       this.selectedmeridum = 'PM';
     }
   }


   getback9Data = async (input_array) => {
     let back9quotes;
     input_array['quotes'][0].product_categories = [`${(this.termLength == 65) ? 65 - this.age : this.termLength} Year Term`, `${(this.termLength == 65) ? 65 - this.age : this.termLength} Year Return of Premium Term`];
     await axios.post(`${environment.Nest_API_URL}/back9/getback9`, input_array).then(data => {
       back9quotes = data['data'][0];
     }).catch(function (err) {

     });
     return back9quotes;
   };


   public async getQuotess(commonInput, sbli, wns) {

     let afficencyresponse;
     const quickquote = new pc.Quotes(commonInput.uniqueId);
     await quickquote.getQuickQuote2(commonInput, sbli, false).then(response => {
       afficencyresponse = response;
     }).catch(function (err) {

     });
     return afficencyresponse;
   }

   getback9returnData = async (returninput_array) => {
     let returnofpremiumresponse;
     await axios.post(`${environment.Nest_API_URL}/back9/getback9`, returninput_array).then(data => {
       returnofpremiumresponse = data['data'][0];
     }).catch(function (err) {

     });
     return returnofpremiumresponse;
   };

   removesbliandwns() {
     this.quotes = this.quotes.filter(x => x.carrierId != 'sbli');
     this.commaninput['filtersbli'] = true;
     this.makewnscall =  false;
   }
   public popupopend(even) {

     this.selectednonapply = even;
   }

   redirectWnsSbliAfterPopup(carrier)
   {
     debugger;
     this.wnssbliRediecturl = carrier == 'sbli' ?  `lifeCarrierTwo/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}` : `lifeCarrierThree/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}`;
     console.log( carrier+'Rediecturl ', this.wnssbliRediecturl );
     setTimeout(() => {
       const el: HTMLElement = this.wnssbliRediect.nativeElement;
       el.click();
     }, 50);



   }

}
