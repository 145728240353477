import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-life-click',
  templateUrl: './life-click.component.html',
  styleUrls: ['./life-click.component.css']
})
export class LifeClickComponent implements OnInit {
  phoneerror: boolean;
  weighterror: string;
  coverageerror: string;
  termerror: string;

  constructor() { }
  gender: any;
  maleIconactive = true;
  femaleIconactive = false;
  smoker: any;
  EmailID: any;
  usageDuration: any;
  zipcode: any;
  lifecoverage: any;
  heightIn: any;
  PhoneNumber: any;
  dateofbirth: any;
  heightFt: any;
  Weight: any;

  coverageterm: any;
  ngOnInit(): void {
    this.coverageerror = "";
    this.weighterror = "";
    this.termerror="";
    this.gender = 0;
    this.smoker = 5;
  }
  public getUsageDuration(id) {
    this.usageDuration = id;
  }
  public genderClick(id) {
    debugger
    this.gender = id;
    console.log('genderClick', this.gender);
  }
  public smokerClick(id) {
    this.smoker = id;
  }

  phonelength() {
    // debugger
    if (this.PhoneNumber.length === 3) {
      this.PhoneNumber = this.PhoneNumber + "-";
      this.phoneerror = true;
    }
    else if (this.PhoneNumber.length === 7) {
      this.PhoneNumber = this.PhoneNumber + "-";
      this.phoneerror = true;
    }
    else if (this.PhoneNumber.length < 12) {
      this.phoneerror = true;
    }
    else {
      this.phoneerror = false;
    }
  }

  public checkWeight() {
    if (this.Weight < 10 || this.Weight > 999) {
      this.weighterror = "Weight should be in between 10 and 1000";
    }
    else {
      this.weighterror = "";
    }
  }

  public checkCoverage() {
    debugger
    this.lifecoverage = this.lifecoverage.toString().replace(/,/g, '');
    this.lifecoverage = this.lifecoverage.toString().replace('$', '');
    if (Number(this.lifecoverage) > 10000000 || Number(this.lifecoverage) < 250000) {
      this.coverageerror = "Coverage should be between $10,000,000 and $250,000";
    }
    else {
      this.coverageerror = "";
    }

    this.lifecoverage = this.lifecoverage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.lifecoverage = "$" + this.lifecoverage;
  }

  public checkTerm() {
    if (this.coverageterm > 65 || this.coverageterm < 10) {
      this.termerror = "Term should be in between 10 and 65";
    }
    else {
      this.termerror = "";
    }
  }

}
