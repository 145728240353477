var json;
function getQuestionAnswer() {
  json = require('../../assets/questionAnswers.json');
  return json;
}
export function getQuestionAnswerByPassingInput() {
  json = require('../../assets/questionAnswers.json');
  return json;
}
var getQuestionAnswerJson = new getQuestionAnswer();
export { getQuestionAnswerJson };


function getWNSQuestionAnswer() {
  json = require('../../assets/wnsquestionAnswers.json');
  return json;
}
var getWNSQuestionAnswerJson = new getWNSQuestionAnswer();
export { getWNSQuestionAnswerJson };

export function getStillWaterQuestions() {
  json = require('../../assets/StillWaterQuestions.json');
  return json;
}
var getSWQuestionAnswerJson = new getStillWaterQuestions();
export { getSWQuestionAnswerJson };
