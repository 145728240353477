import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { formatAmount, formatAmountCommaDollar } from './../../../shared/UtilityFunctions.js';

@Component({
  selector: 'app-wnssummary',
  templateUrl: './wnssummary.component.html',
  styleUrls: ['./wnssummary.component.css']
})
export class WnssummaryComponent implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  sblimaxAllowedCoverage: any;
  age: number;
  termtoshow: any;
  selectedterm: any;
  selectedcov: any;
  buttontext: string;
  constructor(private pc: ProcessCacheService, private activatedroute: ActivatedRoute) { }
  coverage: any;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  amount: any;
  maxct = [];
  existingData: any;
  @Output() callgetpremium = new EventEmitter();
  ngOnInit(): void {

    this.showerrormessage = false;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];

    }
    );

    this.buttontext = 'Next';
    const todayDate = new Date();
    const urlDob = new Date(localStorage.getItem('bubble-dob'));
    this.age = todayDate.getFullYear() - urlDob.getFullYear();
    if (this.age >= 20 && this.age <= 34) {
      this.maxct = ['30', '20', '15', '10'];
    }
    else if (this.age >= 35 && this.age <= 40) {
      this.maxct = [' Up to age 65', '30', '20', '15', '10'];
    }
    else if (this.age >= 41 && this.age <= 45) {
      this.maxct = [' Up to age 65', '20', '15', '10'];
    }
    else if (this.age >= 46 && this.age <= 50) {
      this.maxct = [' Up to age 65', '15', '10'];

    }
    else {
      this.maxct = [' Up to age 65', '10'];
    }

    console.log(this.maxct, 'maxct');
    try {
      this.setValues();
    } catch (error) {
      console.error(error);
    }



    let wnsmaxAllowedCoverage = localStorage.getItem('wnsmaxAllowedCoverage') || '';
    wnsmaxAllowedCoverage = wnsmaxAllowedCoverage.replace(/'/g, '"');
    console.log(wnsmaxAllowedCoverage);
    this.sblimaxAllowedCoverage = JSON.parse(wnsmaxAllowedCoverage);
    console.log('this.wnsmaxAllowedCoverage :', this.sblimaxAllowedCoverage);


    const maxCoverageAllowed = [];
    if (this.sblimaxAllowedCoverage) {
      for (let i = 0; i < this.sblimaxAllowedCoverage; i++) {
        maxCoverageAllowed.push(this.sblimaxAllowedCoverage[i]);
      }
    }
    else {
      let commonInput = localStorage.getItem('sbliCommonInput');
      commonInput = JSON.parse(commonInput);
      const json = {
        term: commonInput['term'],
        coverage_limit: commonInput['coverage']
      };
      maxCoverageAllowed.push(json);
    }


    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);
    this.coverage = commonInput['coverage'];
    this.coverage = formatAmountCommaDollar(this.coverage).replace('$', '');
    this.term = commonInput['term'];
    this.termtoshow = this.term;
    this.selectedterm = this.term;
    this.selectedcov = this.coverage;
    if (this.termtoshow == '65') {
      this.termtoshow = 'to age 65';
    }
    this.amount = commonInput['premium'];
    console.log(this.amount, 'amount', commonInput);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.SBLI;

    }).catch(error => {
      console.log(error);
    });
  }



  setValues() {
    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);
    console.log('common input', commonInput);
    this.coverage = commonInput['coverage'];
    this.coverage = formatAmountCommaDollar(this.coverage).replace('$', '');
    this.term = commonInput['term'];
    this.amount = commonInput['premium'];
    console.log({ term: this.term, amount: this.amount, coverage: this.coverage });
  }

  checkchildren(term, id) {


    if (id == 0) {
      const index = this.maxct.findIndex(x => x.term == term);
      this.coverage = this.maxct[index - 1].coverage_limit;
      this.term = this.maxct[index - 1].term;
    }
    else if (id == 1) {
      const index = this.maxct.findIndex(x => x.term == term);
      this.coverage = this.maxct[index + 1].coverage_limit;
      this.term = this.maxct[index + 1].term;
    }


  }


  async next() {

    this.pc.lifesummary({ 'final_confirmation': 'submit' });

    const covTest = Number(this.coverage.replace(/,/g, ''));
    const json = {
      'id': this.clientId,
      SBLI: this.existingData,
      'sbli_coverage': covTest.toString(),
      'wns_coverage': covTest.toString(),
      'sbli_term': this.term,
      'wns_term': this.term,
      'sbli_PolicyNumber': ''
    };
    if (Number(this.selectedcov.replace(/,/g, '')) != Number(this.coverage.replace(/,/g, '')) || Number(this.selectedterm != Number(this.term))) {
      await this.pc.saveUpdateClientData(json);
      this.callgetpremium.emit();
    }
    else {
      const etty = {
        'next': 1,
        'previous': 0,
        'APICall': 4,
        'sbli_answer_data': json,
        'saveData': 1

      };
      this.changeQuestion.emit(etty);
    }

    //json.SBLI["sbli_PolicyNumber"] = '';

  }


  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }


  term: any;
  Getterm(event) {

    this.term = event;
    this.termtoshow = event;
    if (this.term == 'to age 65') {
      const calculatedTerm = 65 - Number(this.age);
      this.term = calculatedTerm;
    }
    if (Number(this.selectedcov.replace(/,/g, '')) != Number(this.coverage.replace(/,/g, '')) || Number(this.selectedterm != Number(this.term))) {
      this.buttontext = 'Refresh';
    }
    else {
      this.buttontext = 'Next';
    }
    // this.coverage = event.coverage_limit;
  }

  errormessage: any;
  showerrormessage: boolean;
  coverageMaxAmount() {

    const coverage = Number(this.coverage.replace(/,/g, ''));
    console.log(coverage, 'coverage');
    const index = this.maxct.findIndex(x => x.term == this.term);
    const checkCoverage = this.maxct[index].coverage_limit;
    const checkCoverageDisplayText = formatAmountCommaDollar(checkCoverage).replace('$', '');

    if (Number(coverage) <= Number(checkCoverage)) {
      const matchcoverage = Number(this.coverage.replace(',', ''));
      this.coverage = formatAmountCommaDollar(this.coverage).replace('$', '');
      this.showerrormessage = false;
      if (matchcoverage < 500) {
        this.errormessage = 'Minimum coverage amount is 500';
        this.showerrormessage = true;
      }
    } else {
      // this.coverage = 0;
      this.showerrormessage = true;
      this.errormessage = 'Maximum coverage amount is ' + '$' + checkCoverageDisplayText;
      this.coverage = formatAmountCommaDollar(this.coverage).replace('$', '');
    }
    console.log(this.coverage, 'coverage');
    if (Number(this.selectedcov.replace(/,/g, '')) != Number(this.coverage.replace(/,/g, '')) || Number(this.selectedterm != Number(this.term))) {
      this.buttontext = 'Refresh';
    }
    else {
      this.buttontext = 'Next';
    }
  }
  public quoteAmount() {

    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);
    this.coverage = commonInput['coverage'];
    this.coverage = formatAmountCommaDollar(this.coverage).replace('$', '');
    this.term = commonInput['term'];
    this.termtoshow = this.term;
    this.selectedterm = this.term;
    this.selectedcov = this.coverage;
    if (this.termtoshow == '65') {
      this.termtoshow = 'to age 65';
    }
    this.amount = commonInput['premium'];
    this.buttontext = 'Next';
  }

}
