import { Component, OnInit } from '@angular/core';
import { ValidationService } from 'src/app/shared/validation.service';

const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-home-click',
  templateUrl: './home-click.component.html',
  styleUrls: ['./home-click.component.css']
})
export class HomeClickComponent implements OnInit {
  EmailID: any;
  PhoneNumber: any;
  phoneerror: boolean;
  dateofbirth: any;
  city: any;
  state: any;
  streetaddress: any;
  zipcode: any;
  yearbuilt: any;
  totallivingarea: any;
  stories: any;
  rooftype: any;
  constructiontype: any;
  roofupdatedyear: any;
  propertdetails = [];
  curDate: any;
  minDate;
  message: string;
  is_valid: boolean;
  yearBuiltError: string;
  roofupdatedyearError: string;
  uniqueId:any;
  constructionList: any;
  roofTypeList: any;
  storiesList: any;
  roofConstructedError:any;
  minArea: number;
  livingSizeError: string;
  clientid:any;
  constructor(public validationService: ValidationService) { }

  ngOnInit(): void {
    this.clientid='ruzndf';
    this.minArea = 250;
    this.yearBuiltError = "";
    let quickquote = new pc.Quotes(this.clientid);
    let optionsList = quickquote.getSWPropertyOptions();
    this.constructionList = optionsList.construction_type;
    console.log("constructionList : ", this.constructionList);
    this.storiesList = optionsList.stories;
    console.log("storiesList : ", this.storiesList);
    this.roofTypeList = optionsList.roof_type;
    console.log("roofTypeList : ", this.roofTypeList);


    this.curDate = new Date();
    let year = Number(this.curDate.getFullYear()) - 18;
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.minDate = new Date(month + '/' + day + '/' + year);
  }


  phonelength() {
    debugger
    var j = this.PhoneNumber.replace(/-/g, '');
    var K = j.replace(/[{()}]/g, '');
    var K = K.replace(/ /g, '');
    var A = K.substring(0, 3);
    var B = K.substring(3, 6);
    var C = K.substring(6, 10);
    if(A!="" &&K.length<=3){
      if(A.length==3){
        this.PhoneNumber='('+ A+') ';
      }
      else{
        this.PhoneNumber='('+ A;
      }      
    }
    else if(B!="" && K.length>3 && K.length<7) {
      this.PhoneNumber='('+ A+')'+B;
    }
    else if(B!="" && K.length>=7){
      this.PhoneNumber='('+ A+')'+B+'-'+C;
    }    
    else{
      this.PhoneNumber=K;
    }  
    
  }

  public SelectCard(id) {
    this.propertdetails.push(id)
  }

  public GetStories(id) {
    document.getElementById('stories').innerHTML = id;
    this.stories = id;
  }

  public GetRooftype(id) {
    document.getElementById('rooftype').innerHTML = id;
    this.rooftype = id;
  }

  public GetConstructiontype(id) {
    document.getElementById('constructiontype').innerHTML = id;
    this.constructiontype = id;
  }


  onDateChange(even) {  
    if (even == null) {
      this.curDate = new Date();
      let year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dateofbirth = new Date(month + '/' + day + '/' + year);
    } else {

      var cur_d = new Date();
      var d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      var curYear = cur_d.getFullYear();
      year = d.getFullYear();
      var ageYear = Number(curYear) - Number(year)
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dateofbirth = month + '/' + day + '/' + year;
    }

    let newDate = new Date(even)
    console.log("DOB entered: ", newDate);
    let checkAge = this.validationService.validate_dob("common_age", newDate);
    this.message = checkAge.message;
    this.is_valid = checkAge.is_valid;   

  }


  checkyearbuilt() {
    debugger
    let todayDate = new Date();
    let currentYear = todayDate.getFullYear();
    if (this.yearbuilt.toString().length == 4) {
      if (Number(this.yearbuilt) > currentYear || Number(this.yearbuilt) < 1800) {
        this.yearBuiltError = "Year Built value should be between 1800 and " + currentYear;
        // this.yearbuilt = currentYear;
      }

      else {
        this.yearBuiltError = "";
      }
    }
    else {
      this.yearBuiltError = "Enter Valid Year";
    }

    if (this.roofupdatedyear.toString().length == 4) {
      if (Number(this.yearbuilt) > Number(this.roofupdatedyear)) {
        this.roofupdatedyearError = "The value should be greater than or equal to the Year Built";
      }
      else if (Number(this.roofupdatedyear) > currentYear) {
        this.roofupdatedyearError = "Roof constructed cannot be greater than " + currentYear;

      }
      else {
        this.roofupdatedyearError = "";
      }
    }

    else {
      this.roofupdatedyearError = "Enter Valid Year";
    }


  }

  checkRoofYear() {
    debugger
    let todayDate = new Date();
    let currentYear = todayDate.getFullYear();
    if (this.roofupdatedyear.toString().length == 4) {
      if (Number(this.roofupdatedyear >= Number(this.yearbuilt))) {
        if (Number(this.roofupdatedyear) < this.yearbuilt) {
          this.roofupdatedyear = this.yearbuilt;
        }
        else if (Number(this.roofupdatedyear) > currentYear) {
          this.roofupdatedyearError = "Roof constructed cannot be greater than " + currentYear;

        }
        else {
          this.roofupdatedyearError = "";
          this.yearBuiltError = "";
        }
      }
      else {
       
        this.roofupdatedyearError = "The value should be greater than or equal to the Year Built";
      }
    }
    else {
      this.roofupdatedyearError = "Enter Valid Year";
     
    }

    if (this.yearbuilt.toString().length == 4) {
      this.yearBuiltError = "";
    }
    else {
      this.yearBuiltError = "Enter Valid Year";
    }
  }

  checkArea() {
    debugger
    if (this.totallivingarea.toString().length >= 2) {
      if (Number(this.totallivingarea) < this.minArea || Number(this.totallivingarea) > 10000) {
        this.livingSizeError = `Total Living Area cannot be less than 250 Sq Ft`;
      }
      else {
        this.livingSizeError = "";

      }
    }
    else {
      this.livingSizeError = `Total Living Area cannot be less than 250 Sq Ft`;
    }
  }
  public saveupdateclient() {
    var entity = {
      hs_preview:"Vmzbnocw-51729807737",
      bubble_email:this.EmailID,
      bubble_fname:'Anonymous',
      bubble_lname:'T',
      fullAddress:this.streetaddress,
      city:this.city,
      state:this.state,
      country:'US',
      zip:this.zipcode,
      bubble_phone:this.PhoneNumber,
      dob:this.dateofbirth,
      gender:'Female',
      marital_status:'Partnered',
      interest_type:'Home',
      home_type:'SingleFamilyHome',
      dwelling_use:'primary_fulltime_residence',
      number_of_units:1,
      lead_source:'QuinStreet',
      lead_form_url:'www.xyz.com',
      lead_source_time_stamp:'2021-08-09%2022:14%20PST',
      newly_acquired_property:'Yes',
      number_of_claims:1,
      yearBuilt:this.yearbuilt,
      livingArea:this.totallivingarea,
      home_insurance_type:'Homeowners',
      credit_rating:'good',
      bundle_auto_with_home:'Yes',
      stories:this.stories,
      roofBuiltYear:this.roofupdatedyear
    }
  }

}
