import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-sw4pt41',
  templateUrl: './sw4pt41.component.html',
  styleUrls: ['./sw4pt41.component.css']
})
export class Sw4pt41Component implements OnInit {

 @Output() nextquestion24 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  full: number;
  scheduled: number;
  downpayment: number;
  selected: string;
  showScheduledDetails: number;
  showDownPaymentDetails: number;
  scheduletype: any;
  downpaymenttype: any;
  paymentOptions: any;
  paymentPlanDetails: any;
  existingAppData: any;
  loader: boolean;
  payType: any;
  totalCost: any;
  installmentAmount: any;

  constructor(private $gaService: GoogleAnalyticsService,private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    this.loader = true;
    // this.uniqueId = 'nkowu4-285-Bryant-St-Palo-Alto-CA-94301';
    // this.clientId = 'nkowu4';
    // if (localStorage.getItem("paymentOption") == '' || localStorage.getItem("paymentOption") == undefined || localStorage.getItem("paymentOption") == null) {
    //   this.selected = 'full';
    //   this.showScheduledDetails = 0;
    // }
    // else {
    //   this.selected = localStorage.getItem("paymentOption");
    // }
    // if (localStorage.getItem("paymentOptionPlan") == '' || localStorage.getItem("paymentOptionPlan") == undefined || localStorage.getItem("paymentOptionPlan") == null) {
    //   this.scheduletype = '';
    // }
    // else {
    //   this.scheduletype = localStorage.getItem("paymentOptionPlan");
    //   this.showScheduledDetails = 1;
    // }
    const quickquote = new pc.Quotes(this.uniqueId);
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    console.log('getSWPaymentOptions commonInput: ', commonInput);
    quickquote.getSWPaymentOptions(commonInput).then(response => {
      this.paymentOptions = response;
      console.log('getSWPaymentOptions response:', this.paymentOptions);
      this.selected = 'full';
      this.paymentPlanDetails = this.paymentOptions.fullPayDetails[0];
      this.installmentAmount = this.paymentOptions.fullPayDetails[0].installmentFeeAmt;
      this.showScheduledDetails = 0;
      this.showDownPaymentDetails = 0;
      this.pc.getAppData(this.clientId).then(data => {
        const dataFrom = JSON.parse(data.Payload);
        this.existingAppData = dataFrom.body.stillwater;
        this.loader = false;
      }).catch(error => {
      console.log(error);
    });
    })
    .catch(error => {
      console.log('getSWPaymentOptions Error:', error);
    });
  }

  onselect(val) {
    this.selected = val;
    if (this.selected == 'full') {
      this.scheduletype = '';
      this.downpaymenttype = 0;
      this.paymentPlanDetails = this.paymentOptions.fullPayDetails[0];
      this.payType = this.paymentOptions.fullPayDetails[0].payType;
      this.installmentAmount = 0;
    }
    if (this.selected == 'scheduled') {
      debugger;
      this.showScheduledDetails = 1;
      this.paymentPlanDetails = this.paymentOptions.schedulePayDetails[0];
      this.scheduletype = this.paymentOptions.schedulePayDetails[0].payType;
      this.downpaymenttype = 0;
      this.payType = this.paymentOptions.schedulePayDetails[0].payType;
      if (this.payType != 'com.stillwater_DB16') {
        let tp = this.paymentPlanDetails.totalPremium.replace(/,/g, '');
        tp = Number(tp);
        const insamt = Number(this.paymentPlanDetails.installmentFeeAmt) * Number(this.paymentPlanDetails.numInstallments);
        let dp = this.paymentPlanDetails.installmentPay.replace(/,/g, '');
        dp = Number(dp);
        let ia = this.paymentPlanDetails.installmentInfo[0].InstallmentAmount._text.replace(/,/g, '');
        ia = Number(ia) * Number(this.paymentPlanDetails.numInstallments);
        this.totalCost = Number(insamt) + Number(dp) + Number(ia);
        this.totalCost = this.totalCost.toFixed(2);
        this.totalCost = this.totalCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
      this.installmentAmount = this.paymentPlanDetails.installmentInfo[0].InstallmentAmount._text;
    }
    if (this.selected == 'downPayment') {
      this.showDownPaymentDetails = 1;
      this.downpaymenttype = 1;
      this.scheduletype = '';
    }
  }

  onScheduleArrow(val) {
    this.showScheduledDetails = val;
  }

  selectscheduletype(val, list) {
    debugger;
    this.scheduletype = val;
    this.selected = 'scheduled';
    this.paymentPlanDetails = list;
    this.payType = val;
    if (this.payType != 'com.stillwater_DB16') {
      let tp = this.paymentPlanDetails.totalPremium.replace(/,/g, '');
      tp = Number(tp);
      const insamt = Number(this.paymentPlanDetails.installmentFeeAmt) * Number(this.paymentPlanDetails.numInstallments);
      let dp = this.paymentPlanDetails.installmentPay.replace(/,/g, '');
      dp = Number(dp);
      let ia = this.paymentPlanDetails.installmentInfo[0].InstallmentAmount._text.replace(/,/g, '');
      this.installmentAmount = ia;
      ia = Number(ia) * Number(this.paymentPlanDetails.numInstallments);
      this.totalCost = Number(insamt) + Number(dp) + Number(ia);
      this.totalCost = this.totalCost.toFixed(2);
      this.totalCost = this.totalCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    else {
      this.installmentAmount = 0;
    }
  }

  onDownPaymentArrow(val) {
    this.showDownPaymentDetails = val;
  }

  selectdownpaymenttype(val) {
    this.downpaymenttype = val;
    this.selected = 'downPayment';
  }

  next() {
    this.loader = true;
    localStorage.setItem('paymentOption', this.paymentPlanDetails.paymentPlan);
    localStorage.setItem('paymentOptionPlan', this.scheduletype);
    localStorage.setItem('paymentPlanDetails', JSON.stringify(this.paymentPlanDetails));
    const paymentDetails = {
      'payType':this.paymentPlanDetails['payType'],
      'paymentOption': this.paymentPlanDetails.paymentPlan,
      'paymentOptionPlan': this.paymentPlanDetails,
      'depositAmount': this.paymentPlanDetails.installmentPay,
      'noOfInstallments': this.paymentPlanDetails.numInstallments,
      'installmentAmount':this.installmentAmount,
      'totalPremium':this.paymentPlanDetails.totalPremium,
    };
    const json = {
      'id': this.clientId,
      stillwater: this.existingAppData
    };
    json.stillwater['paymentDetails'] = paymentDetails;
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(data => {
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0,
      };
      this.nextquestion24.emit(etty);
    }).catch(error => {
      console.log(error);
    });
    this.$gaService.event('Continue to Purchase Policy', 'event', 'HomeAppPolicyCheckout');
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion24.emit(etty);
  }

}
