import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-personal-details-question8',
  templateUrl: './personal-details-question8.component.html',
  styleUrls: ['./personal-details-question8.component.css']
})
export class PersonalDetailsQuestion8Component implements OnInit {
  @Output() nextquestion7= new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  next() {
    let etty = {
      "questionNo": "7",
      "nextprev": 1
    }
    this.nextquestion7.emit(etty);
  }

}
