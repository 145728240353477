import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackerService } from 'src/app/shared/tracker.service';
import { environment } from './../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../components/lifescore-error-alert/lifescore-error-alert.component';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
const pc = require('bit-uibl-data-driver');
const axios = require('axios').default;
@Component({
  selector: 'app-prefill-quote-flow-v2-home',
  templateUrl: './prefill-quote-flow-v2-home.component.html',
  styleUrls: ['./prefill-quote-flow-v2-home.component.css']
})
export class PrefillQuoteFlowV2HomeComponent implements OnInit {
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  gender: any;
  age: any;
  lifeScoreData: any;
  allScores: any;
  insurancetype: any;
  question: number;
  sourcePageLink: any;
  totalPages = 4;
  inputAddress: any;
  selectedCard: number;
  previous: any;
  loader: boolean;
  knockOut: any;
  homeInsurance = '';
  public isMobileResolution: boolean;
  public isDescktopResolution: boolean;

  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private tracker: TrackerService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService,
    public router: Router,
    private modalService: NgbModal,
    private utilityService: UtilityFunctionsService,
    private http: HttpClient) { }

  ngOnInit(): void {


    if (window.innerWidth < 600) {
      this.isMobileResolution = true;
      this.isDescktopResolution = false;
    } else {
      this.isMobileResolution = false;
      this.isDescktopResolution = true;
    }

    console.log('QuoteFlowV2HomeComponent');
    console.log('envCred : ', this.pc.envCred);

    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      this.sourcePageLink = params['sourcePageLink'];
      localStorage.setItem('clientID', this.clientId);
      //this.previous = params['previous'];
      this.inputAddress = this.uniqueId.split('-');
      this.inputAddress.shift();
      this.inputAddress = this.inputAddress.join(' ');
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
    }

    );

    // Prefill Quote Popup code
    const swMinQuotePopupShown = localStorage.getItem('swMinQuotePopupShown');
    if (swMinQuotePopupShown && swMinQuotePopupShown == 'no') {
      this.prefillQuoteShow();

      const swData = JSON.parse(localStorage.getItem('swMinQuoteData'));
      /* this.http.get(swURL).subscribe(
        (data: any) => {console.log("swMinQuotePopupShown data", data)},
        (error: any) => {console.log("swMinQuotePopupShown error", error)}
      ); */

      //console.log("swData getMinimalHomeQuoteFromStillWater", swData, " # urlTemp: ", urlTemp);
      axios.get(this.pc.envCred.api2Domain, { params: swData }).then(
        data => {
          console.log('swMinQuotePopupShown data', data);
          const res = JSON.parse(data.data.output);
          if (res.hasError) {
            console.log('Cannot show quote');
            this.homeInsurance = 'error';
            const eventOptionsPart1 = {
              'path': 'prefillService',
              'page_title': 'prefillsQuoteFlow', 'url': window.location.href
            };
            this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
              'event_category': 'Error', 'event_label': 'HoI premium Display Error'
            });
            // this.prefillQuoteHide();
          } else {
            // this.homeInsurance = res.quoteAmt;
            const newMonthlyPremium = Number(res.yearlyQuoteAmt.replace(/[^0-9.-]+/g, ''));
            this.homeInsurance = (+newMonthlyPremium / 12).toFixed(2);
            const eventOptionsPart1 = {
              'path': 'prefillService',
              'page_title': 'prefillsQuoteFlow', 'url': window.location.href
            };
            this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
              'event_category': 'page_load', 'event_label': 'HoI premium popup Amount Displayed'
            });

            /// Hubspot premium amount push
            const hubSpotData = {
              customer_id: localStorage.getItem('customer_id'),
              email: localStorage.getItem('bubble-email'),
              application_stage: 'Quote',
              home_insurance_carrier: 'Stillwater',
              home_quote_amount: this.homeInsurance,
              home_latest_premium_amount: this.homeInsurance,
            };
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
          }
          //this.prefillQuoteShow();
          //this.loader = false;
        }
      ).catch(
        error => {
          console.log('swMinQuotePopupShown error', error);
          this.homeInsurance = 'error';
          // this.prefillQuoteHide();
          //this.loader = false;
        }
      );

      localStorage.setItem('swMinQuotePopupShown', 'yes');
    }

    this.selectedCard = 0;
    if (this.sourcePageLink == 'previous' &&
      localStorage.getItem('knockout') == '1' &&
      localStorage.getItem('KOType') != '' &&
      localStorage.getItem('KOType') != undefined) {
      if (localStorage.getItem('KOType') == 'pp') {
        this.question = 1;
        localStorage.setItem('KOType', '');
        localStorage.setItem('knockout', '');
        localStorage.setItem('KOerrorMessage', '');
      }
      else if (localStorage.getItem('KOType') == 'ko') {
        localStorage.setItem('KOType', '');
        localStorage.setItem('knockout', '');
        localStorage.setItem('KOerrorMessage', '');
        this.question = 2;
      }
      else {
        this.question = 3;
      }
    }
    else if (this.sourcePageLink == 'previous' && localStorage.getItem('knockout') != '1') {
      this.question = 3;
    }
    else {
      this.question = 1;
    }
    //this.question = 1;

    if (localStorage.getItem('appFlowLinkResume') == '1') {
      localStorage.setItem('appFlowLinkResume', '0');
      this.checkforResumeInit({}, 'fetch').then(
        resumeData => {
          console.log('checkforResumeInit home-life-quote', resumeData);
          if (resumeData['insuranceType'] == 'home-life-quote' || resumeData['insuranceType'] == 'home-quote') {
            if (resumeData['otherAppflowScreen'] == 'home-question1') {
              this.question = 1;
            } else if (resumeData['otherAppflowScreen'] == 'home-question2') {
              this.question = 2;
            } else {
              this.question = 1;
            }
          } else {
            console.log('checkforResumeInit home-life-quote');
            this.question = 1;
          }
        }).catch(error => console.log(error));
    } else {
      console.log('checkforResumeInit home-life-quote else');
      const createJson = {
        insuranceType: 'home-life-quote',
        carrierName: 'stillwater',
        appStartStatus: false,
        appCompletedStatus: false,
        questionStartStatus: false,
        questionCompletedStatus: false,
        questionSequence: '0',
        applicationId: '',
        otherAppflowScreen: 'home-question1',
        resumeUrl: this.router.url
      };
      this.checkforResumeInit(createJson, 'create');
    }

    // this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 3 })
    this.tracker.changeTrackingType(this.insurancetype);
    this.pc.getAppData(this.clientId).then(data => {
      debugger;
      const dataFrom = JSON.parse(data.Payload);
      this.inputAddress = dataFrom.body.formatted_address == undefined ? this.inputAddress : dataFrom.body.formatted_address;
      console.log('getAppData : ', dataFrom);

    }).catch(error => {
      console.log(error);
    });

  }

  prefillQuoteShow() {
    const eventOptionsPart1 = {
      'path': 'prefillService',
      'page_title': 'prefillsQuoteFlow', 'url': window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'page_load', 'event_label': 'HoI premium popup'
    });
    document.getElementById('prefill_quote_popup').style.display = 'flex';
    document.getElementById('makeblur').style.filter = 'blur(30px)';
  }
  /**
   * This is premium amount display-popup-close function
   */
  prefillQuoteHide() {
    const eventOptionsPart1 = {
      'path': 'prefillService',
      'page_title': 'prefillsQuoteFlow', 'url': window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'ButtonClicked', 'event_label': 'CustomizeAndBuy'
    });
    document.getElementById('prefill_quote_popup').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
  }

  bluronhow() {
    document.getElementById('are_u_sure').style.display = 'flex';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
  }

  bluroffhow1() {
    const eventOptionsPart1 = {
      'path': 'prefillsQuoteFlow',
      'page_title': 'prefillsQuoteFlow', 'url': window.location.href
    };
    //this.loader = true;
    localStorage.setItem('insuranceType', 'homeLife');
    this.pc.callComponentMethod();
    document.getElementById('are_u_sure').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
    this.insurancetype = 'homeLife';
    localStorage.setItem('insuranceType', 'homeLife');
    this.question = this.question + 1;
    //this.loader = false;
    //location.href = "select-home-carrierv2/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/"+this.insurancetype ;
    this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed with Home and Life', 'event', 'ProceedWithHomeLife');
    this.$gaService.event('Proceed with Home and Life', 'event', 'ProceedWithHomeLife');
  }
  bluroffhow2() {
    const eventOptionsPart1 = {
      'path': 'prefillsQuoteFlow',
      'page_title': 'prefillsQuoteFlow', 'url': window.location.href
    };
    //this.loader = true;
    document.getElementById('are_u_sure').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
    this.insurancetype = 'home';
    this.question = this.question + 1;
    //this.loader = false;
    this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed with just Home', 'event', 'ProceedWithHome');
    this.$gaService.event('Proceed with Home and Life', 'event', 'ProceedWithHomeLife');
    //location.href = "select-home-carrierv2/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/"+this.insurancetype ;
  }

  nextQuestion(even) {
    debugger;

    this.StatusTrackerPercentage(even.questionNo);
    //knockout logic
    console.log('sent object : ', even);

    if (even.knockOut == 1) {
      location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
    } else if (this.question == 1 && this.insurancetype == 'home') {
      this.bluronhow();
    } else if (this.question == 3) {
      if (this.insurancetype == 'homeLife') {
        this.loader = true;
        const updateJson = {
          otherAppflowScreen: 'select-home-carrier',
          resumeUrl: 'select-home-carrierv2/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype
        };
        this.checkforResumeInit(updateJson, 'update').then(data => {
          //this.loader = false;
          location.href = 'select-home-carrierv2/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
          //location.href = "ContactInformation/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
          this.question = this.question + 1;
        }).catch(error => {
          console.log(error);
        });
      }
      else {
        location.href = 'select-home-carrierv2/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
        // this.bluronhow();
      }
    }
    else {
      this.question = this.question + 1;
    }


    const updateJson = {
      otherAppflowScreen: 'home-question2',
      resumeUrl: this.router.url
    };
    this.checkforResumeInit(updateJson, 'update');
    // this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.question });

  }

  StatusTrackerPercentage(question) {
    if (question == '1') {
      this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 5 });
    }
  }

  invokeHomeQuote() {
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    if (commonInput['swquote_question'].includes(111)) {
      localStorage.setItem('KOType', 'ko');
      localStorage.setItem('KOerrorMessage', 'Bubble only insures full-time Primary residences.');
      location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
    }
    else {
      const eventOptionsPart1 = {
        'path': 'prefillsQuoteFlowInvokeHomeQuote',
        'page_title': 'prefillsQuoteFlowInvokeHomeQuote', 'url': window.location.href
      };
      //alert("home quote invoked;");
      this.loader = true;
      const commonInput = JSON.parse(localStorage.getItem('commonInput'));
      commonInput['firstName'] = localStorage.getItem('bubble-fname');
      commonInput['lastName'] = localStorage.getItem('bubble-lname');
      commonInput['email'] = localStorage.getItem('bubble-email');
      commonInput['phone'] = localStorage.getItem('bubble-phone');
      commonInput['disableBack'] = false;
      localStorage.setItem('commonInput', JSON.stringify(commonInput));
      localStorage.setItem('knockout', '0');
      console.log('SW quote invoke knockout commonInput:', commonInput);
      localStorage.setItem('KOerrorMessage', 'Unfortunately we require a couple more steps before completing your application.');
      const quickquote = new pc.Quotes(this.uniqueId);
      quickquote.getStillWaterQuote(commonInput, false, true)
        .then(response => {
        debugger;
        console.log('SW knockout output: ', response);
        if (response[0].hasError && response[0].hasError == true) {
          this.utilityService.gtagWrapped(eventOptionsPart1, 'event', this.insurancetype, {
            'event_category': 'Error', 'event_label': 'KnockoutPopup'
          });
          this.knockOut = 1;
          let errorCode = response[0].errorMessage;
          errorCode = errorCode.split(':');
          const errorMsg = errorCode[1];
          localStorage.setItem('KOerrorMessage', errorMsg);
          const hubSpotData = {
            customer_id: localStorage.getItem('customer_id'),
            email: localStorage.getItem('bubble-email'),
            application_stage: 'Policy Denied',
            home_knock_out_reason: errorMsg[1]
          };
          this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
          errorCode = errorCode[0].replace(' ', '');
          if (errorCode == 'QUA0080') {
            localStorage.setItem('KOType', 'ko');
            localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because of prior damage to the house.');
          }
          else if (errorCode == 'QUA0118') {
            localStorage.setItem('KOType', 'ko');
            localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because 1 or more units are occupied by more than 2 families');
          }
          else if (errorCode == 'QUA0021') {
            localStorage.setItem('KOType', 'ko');
            localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because there is a business being run on property');
          }
          else if (errorCode == 'QUA0003') {
            localStorage.setItem('KOType', 'ko');
            localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because the house is vacant');
          }
          else if (errorCode == 'QUA0110') {
            localStorage.setItem('KOType', 'ko');
            localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because applicant has experienced a lapse in coverage in the past 36 months.');
          }
          else if (errorCode == 'FNI0170') {
            localStorage.setItem('KOType', 'pp');
            localStorage.setItem('KOerrorMessage', 'The current address entered needs underwriting approval and cannot be issued with an online quote as it exceeds permissible square footage and/or coverage limit.');
          }
          else if (errorCode == 'FNI0052') {
            localStorage.setItem('KOType', 'ko');
            localStorage.setItem('KOerrorMessage', 'Unable to quote - Insurance Bureau Score not available.');
            this.openModalssn_number();
          }
          else {
            localStorage.setItem('KOType', 'pp');
            localStorage.setItem('KOerrorMessage', errorMsg);
          }
          if (errorCode != 'FNI0052') {
            // this.openModalssn_number();
            location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
          }

        }
        else {
          // if (this.insurancetype == "home") {
          //   this.bluronhow();
          // }
          // else {
          //   location.href = "select-home-carrierv2/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
          // }
          location.href = 'select-home-carrierv2/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
        }
        this.loader = false;
        // let etty = {
        //   "questionNo": "1",
        //   "nextprev": 1,
        //   "knockOut": this.knockOut
        // }
        // this.nextQuestion(etty);
        })
        .catch(error => {
        console.log('SW knockout Error:', error);
        this.openModal();
        });

    }
  }

  gotoHome() {
    window.location.href = this.pc.envCred.url;
  }

  openModal() {

    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
        // keyboard: false,
        // backdrop: 'static'
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: 'Our Servers are taking too long to respond please come back later.',
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      window.location.href = this.pc.envCred.url;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  previousQuestion() {
    const eventOptionsPart1 = {
      'path': 'prefillsQuoteFlow',
      'page_title': 'prefillsQuoteFlow', 'url': window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', this.insurancetype, {
      'event_category': 'BackButton', 'event_label': 'Back'
    });
    this.StatusTrackerPrev(this.question);
    if (this.question == 1) {
      location.href = this.pc.envCred.url;//"https://stage-www.getmybubble.io";
    }
    else if (this.question != 1) {
      this.question = this.question - 1;
      // this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.question })
    }
    else {

    }
  }

  StatusTrackerPrev(questionNo) {
    if (questionNo == '2') {
      this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 1 });
    }

  }


  insuranceTypeChange(even) {
    this.insurancetype = even.type;
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }

  openModalssn_number() {
    document.getElementById('ssn_number').style.display = 'flex';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
  }

  SSN: any;
  ssnLength: any;
  SSNerror: boolean;
  ssnlength() {
    debugger;
    const j = this.SSN.replace(/-/g, '');
    const A = j.substring(0, 3);
    const B = j.substring(3, 5);
    const C = j.substring(5, 9);
    if (B == '') {
      this.SSN = A;
    }
    else if (B != '' && C == '') {
      this.SSN = A + '-' + B;
    }
    else if (B != '' && C != '') {
      this.SSN = A + '-' + B + '-' + C;
    }
    else {
      this.SSN = j;
    }

    this.ssnLength = this.SSN.toString().length;
    if (this.ssnLength > 10) {
      this.SSNerror = false;
    }
    else {
      this.SSNerror = true;
    }

  }

  public onSubmit() {
    this.loader = true;
    document.getElementById('ssn_number').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['firstName'] = localStorage.getItem('bubble-fname');
    commonInput['lastName'] = localStorage.getItem('bubble-lname');
    commonInput['email'] = localStorage.getItem('bubble-email');
    commonInput['phone'] = localStorage.getItem('bubble-phone');
    commonInput['disableBack'] = false;
    commonInput['ssn'] = this.SSN;

    localStorage.setItem('commonInput', JSON.stringify(commonInput));


    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.getStillWaterQuote(commonInput, false, true)
      .then(response => {
        debugger;

        const jsonhome = {
          'id': this.clientId,
          'ssn': this.SSN
        };
        console.log('json : ', jsonhome);
        this.pc.saveUpdateClientData(jsonhome).then(data => {
          this.loader = false;
          const hubSpotData = {
            client_id: localStorage.getItem('clientID'),
            customer_id: localStorage.getItem('customer_id'),
            email: localStorage.getItem('bubble-email'),
            application_stage: 'Policy Denied',
            home_knock_out_reason: localStorage.getItem('KOerrorMessage'),
            social_security_number__ssn_: this.SSN
          };
          this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
          location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
        }).catch(error => {
          console.log(error);
        });


      })
      .catch(error => {

      });



  }

}
