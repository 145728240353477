import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { environment } from '../environments/environment';
import { NgxGoogleAnalyticsModule, NgxGoogleAnalyticsRouterModule } from 'ngx-google-analytics';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { QuoteFlowComponent } from './pages/quote-flow/quote-flow.component';
import { ScoreCardComponent } from './pages/score-card/score-card.component';
import { CustomiseComponent } from './pages/customise/customise.component';
import { LifescoreComponent } from './lifescore/lifescore.component';
import { HeaderComponent } from './header/header.component';
import { EdLifescoreComponent } from './components/ed-lifescore/ed-lifescore.component';
import { GraphQLModule } from './modules/graphql.module';
import { HttpClientModule } from '@angular/common/http';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { CostPredictorComponent } from './pages/cost-predictor/cost-predictor.component';
import { AddressInputComponent } from './components/address-input/address-input.component';
import { GoogleMapComponent } from './components/google-map/google-map.component';
import { Question1Component } from './pages/customise-questions/question1/question1.component';
import { Question2Component } from './pages/customise-questions/question2/question2.component';
import { Question3Component } from './pages/customise-questions/question3/question3.component';
import { Question4Component } from './pages/customise-questions/question4/question4.component';
import { Question5Component } from './pages/customise-questions/question5/question5.component';
import { Question6Component } from './pages/customise-questions/question6/question6.component';
import { Question7Component } from './pages/customise-questions/question7/question7.component';
import { SectionOneComponent } from './components/section-one/section-one.component';
import { SectionTwoComponent } from './components/section-two/section-two.component';

// import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';
import { LifeScoreGradeComponent } from './components/life-score-grade/life-score-grade.component';
import { QuickquoteComponent } from './pages/quickquote/quickquote.component';
import { LeafletMapComponent } from './components/leaflet-map/leaflet-map.component';
import { LifescoreErrorAlertComponent } from './components/lifescore-error-alert/lifescore-error-alert.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { LifescoreLeadCaptureComponent } from './lifescore-lead-capture/lifescore-lead-capture.component';
import { FooterComponent } from '../app/components/footer/footer.component';
import { LifescoreEmptyPageComponent } from './pages/lifescore-empty-page/lifescore-empty-page.component';
import { ToastComponent } from './components/toast/toast.component';
import { LeadNurtureComponent } from './pages/lead-nurture/lead-nurture.component';
import { TextToImageComponent } from './components/text-to-image/text-to-image.component';
import { QuoteFlowV2Component } from './v2/quote-flow-v2/quote-flow-v2.component';
import { ScoreCardV2Component } from './v2/score-card-v2/score-card-v2.component';
import { CostPredictorV2Component } from './v2/cost-predictor-v2/cost-predictor-v2.component';
import { HomeQuestion1Component } from './v2/questions/home-question1/home-question1.component';
import { HomeQuestion2Component } from './v2/questions/home-question2/home-question2.component';
import { SelectHomeCarrierComponent } from './v2/select-home-carrier/select-home-carrier.component';
import { AppFlowComponent } from './applicationFlow/pages/app-flow/app-flow.component';
import { PersonalDetailsQuestion1Component } from './applicationFlow/pages/personalDetails/personal-details-question1/personal-details-question1.component';
import { ApplicationCodeComponent } from './applicationFlow/pages/personalDetails/application-code/application-code.component';
import { PersonalDetailsQuestion2Component } from './applicationFlow/pages/personalDetails/personal-details-question2/personal-details-question2.component';
import { PersonalDetailsQuestion3Component } from './applicationFlow/pages/personalDetails/personal-details-question3/personal-details-question3.component';
import { PersonalDetailsQuestion4Component } from './applicationFlow/pages/personalDetails/personal-details-question4/personal-details-question4.component';
import { PersonalDetailsQuestion5Component } from './applicationFlow/pages/personalDetails/personal-details-question5/personal-details-question5.component';
import { PersonalDetailsQuestion6Component } from './applicationFlow/pages/personalDetails/personal-details-question6/personal-details-question6.component';
import { PersonalDetailsQuestion7Component } from './applicationFlow/pages/personalDetails/personal-details-question7/personal-details-question7.component';
import { PersonalDetailsQuestion8Component } from './applicationFlow/pages/personalDetails/personal-details-question8/personal-details-question8.component';
import { StatusCompletionComponent } from './applicationFlow/status-completion/status-completion.component';

import { BubbleTipsComponent } from './applicationFlow/bubble-tips/bubble-tips.component';
import { GreetingNotesComponent } from './applicationFlow/greeting-notes/greeting-notes.component';
import { GeneralQuestionsComponent } from './applicationFlow/general-questions/general-questions.component';
import { DisabilityBenefitsComponent } from './applicationFlow/pages/questions/disability-benefits/disability-benefits.component';
import { EmploymentComponent } from './applicationFlow/pages/questions/employment/employment.component';
import { RiskLevelComponent } from './applicationFlow/pages/questions/risk-level/risk-level.component';
import { DiabetesComponent } from './applicationFlow/pages/questions/diabetes/diabetes.component';
import { HasEmphysemaComponent } from './applicationFlow/pages/questions/has-emphysema/has-emphysema.component';
import { MedicalAdviseComponent } from './applicationFlow/pages/questions/medical-advise/medical-advise.component';
import { QuoteFlowV2LifeComponent } from './v2/quote-flow-v2-life/quote-flow-v2-life.component';
import { LifeQuestion1Component } from './v2/questions/life-question1/life-question1.component';
import { LifeQuestion2Component } from './v2/questions/life-question2/life-question2.component';
import { LifeQuestion3Component } from './v2/questions/life-question3/life-question3.component';
import { LifeQuestion4Component } from './v2/questions/life-question4/life-question4.component';
import { SelectLifeCarrierComponent } from './v2/select-life-carrier/select-life-carrier.component';
import { HomeLifeApplyComponent } from './components/home-life-apply/home-life-apply.component';
import { SelectedCarriersComponent } from './v2/selected-carriers/selected-carriers.component';
import { InsightsComponent } from './v2/insights/insights.component';
import { CustomizeHomeComponent } from './v2/customize-home/customize-home.component';
import { CustomizeLifeComponent } from './v2/customize-life/customize-life.component';
import { AsthmaComponent } from './applicationFlow/pages/questions/asthma/asthma.component';
import { ContactInformationComponent } from './v2/contact-information/contact-information.component';
import { HomeInsuranceDetailsComponent } from './v2/home-insurance-details/home-insurance-details.component';
import { LifeInsuranceDetailsComponent } from './v2/life-insurance-details/life-insurance-details.component';
import { QuoteFlowV2HomeComponent } from './v2/quote-flow-v2-home/quote-flow-v2-home.component';

import { HasHeartDiseaseComponent } from './applicationFlow/pages/questions/has-heart-disease/has-heart-disease.component';
import { HasPeripheralDiseaseComponent } from './applicationFlow/pages/questions/has-peripheral-disease/has-peripheral-disease.component';
import { HasHivDiseaseComponent } from './applicationFlow/pages/questions/has-hiv-disease/has-hiv-disease.component';
import { HasChronicDiseaseComponent } from './applicationFlow/pages/questions/has-chronic-disease/has-chronic-disease.component';
import { HasLiverPancreaseDiseaseComponent } from './applicationFlow/pages/questions/has-liver-pancrease-disease/has-liver-pancrease-disease.component';
import { HasNeurologicalDiseaseComponent } from './applicationFlow/pages/questions/has-neurological-disease/has-neurological-disease.component';
import { HasStrokeComponent } from './applicationFlow/pages/questions/has-stroke/has-stroke.component';
import { HasTissueDiseaseComponent } from './applicationFlow/pages/questions/has-tissue-disease/has-tissue-disease.component';
import { HasCancerDiseaseComponent } from './applicationFlow/pages/questions/has-cancer-disease/has-cancer-disease.component';
import { AlcoholDrugAbuseComponent } from './applicationFlow/pages/questions/alcohol-drug-abuse/alcohol-drug-abuse.component';
import { HasCoronavirusComponent } from './applicationFlow/pages/questions/has-coronavirus/has-coronavirus.component';
import { IsPleadGuiltyComponent } from './applicationFlow/pages/questions/is-plead-guilty/is-plead-guilty.component';
import { SsnComponent } from './applicationFlow/PacLife/ssn/ssn.component';
import { IsActiveMilitaryComponent } from './applicationFlow/PacLife/is-active-military/is-active-military.component';
import { IsDependentComponent } from './applicationFlow/PacLife/is-dependent/is-dependent.component';
import { AddBeneficiariesComponent } from './applicationFlow/PacLife/add-beneficiaries/add-beneficiaries.component';
import { InsurancePurposeComponent } from './applicationFlow/PacLife/insurance-purpose/insurance-purpose.component';
import { CountryBornComponent } from './applicationFlow/PacLife/country-born/country-born.component';
import { PacLifeComponent } from './applicationFlow/PacLife/pac-life/pac-life.component';
import { AppflowstartComponent } from './applicationFlow/appflowstart/appflowstart.component';
import { QuoteFlowStatusTrackerComponent } from './v2/quote-flow-status-tracker/quote-flow-status-tracker.component';
import { QuoteFlowHeaderComponent } from './v2/quote-flow-header/quote-flow-header.component';
import { SelectedInsuranceFooterComponent } from './v2/selected-insurance-footer/selected-insurance-footer.component';
import { StateCountrySearchComponent } from './components/state-country-search/state-country-search.component';
import { PizzaTrackerComponent } from './components/pizza-tracker/pizza-tracker.component';
import { InsuredInformationComponent } from './applicationFlow/PacLife/insured-information/insured-information.component';
import { PolicyOwnerInformationComponent } from './applicationFlow/PacLife/policy-owner-information/policy-owner-information.component';
import { PolicyOwnerComponent } from './applicationFlow/PacLife/policy-owner/policy-owner.component';
import { AppFlowHeaderComponent } from './applicationFlow/app-flow-header/app-flow-header.component';
import { AppFlowFooterComponent } from './applicationFlow/app-flow-footer/app-flow-footer.component';
import { PaclifeApplicationCompleteComponent } from './applicationFlow/PacLife/paclife-application-complete/paclife-application-complete.component';
import { IsInsuredDependentComponent } from './applicationFlow/PacLife/is-insured-dependent/is-insured-dependent.component';
import { ThirdPartyNotifiedComponent } from './applicationFlow/PacLife/third-party-notified/third-party-notified.component';
import { ContingentBeneficiariesComponent } from './applicationFlow/PacLife/contingent-beneficiaries/contingent-beneficiaries.component';
import { HasDriverLicenseComponent } from './applicationFlow/PacLife/has-driver-license/has-driver-license.component';
import { HasPendingApplicationsComponent } from './applicationFlow/PacLife/has-pending-applications/has-pending-applications.component';
import { ApplicationStatusTrackerComponent } from './components/application-status-tracker/application-status-tracker.component';
import { HasExistingLifeInsuranceComponent } from './applicationFlow/PacLife/has-existing-life-insurance/has-existing-life-insurance.component';
import { WelcomeToPacLifeComponent } from './applicationFlow/PacLife/welcome-to-pac-life/welcome-to-pac-life.component';
import { DetailsConfirmationComponent } from './applicationFlow/PacLife/details-confirmation/details-confirmation.component';
import { SbliCarrierPlanComponent } from './components/carrier-plan/carrier-plan.component';
import { ElectronicConsentComponent } from './applicationFlow/PacLife/electronic-consent/electronic-consent.component';
import { LocationStrategy } from '@angular/common';
import { PathLocationStrategy } from '@angular/common';
import { EConsentComponent } from './applicationFlow/PacLife/e-consent/e-consent.component';
import { ConsentTermsPageComponent } from './components/consent-terms-page/consent-terms-page.component';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { Details4idsComponent } from './v2/details4ids/details4ids.component';
import { AssumptionsPageComponent } from './components/assumptions-page/assumptions-page.component';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { GeneraldatepickerComponent } from './applicationFlow/PacLife/generaldatepicker/generaldatepicker.component';
import { FirewallScreenComponent } from './pages/firewall-screen/firewall-screen.component';
import { LifeInsuranceCardComponent } from './LeadNurtureAngular/life-insurance-card/life-insurance-card.component';
import { HomeInsuranceCardComponent } from './LeadNurtureAngular/home-insurance-card/home-insurance-card.component';
import { ProfileHomePageComponent } from './LeadNurtureAngular/profile-home-page/profile-home-page.component';
import { HomeInsuranceDiscountsComponent } from './v2/home-insurance-discounts/home-insurance-discounts.component';
import { Sw4pt6Component } from './SWApplicationFlow/questions/sw4pt6/sw4pt6.component';
import { Sw4pt7Component } from './SWApplicationFlow/questions/sw4pt7/sw4pt7.component';
import { Sw4pt12Component } from './SWApplicationFlow/questions/sw4pt12/sw4pt12.component';
import { Sw4pt14Component } from './SWApplicationFlow/questions/sw4pt14/sw4pt14.component';
import { Sw4pt20Component } from './SWApplicationFlow/questions/sw4pt20/sw4pt20.component';
import { Sw4pt28Component } from './SWApplicationFlow/questions/sw4pt28/sw4pt28.component';
import { Sw4pt30Component } from './SWApplicationFlow/questions/sw4pt30/sw4pt30.component';
import { Sw4pt35Component } from './SWApplicationFlow/questions/sw4pt35/sw4pt35.component';
import { Sw4pt38Component } from './SWApplicationFlow/questions/sw4pt38/sw4pt38.component';
import { Sw4pt40Component } from './SWApplicationFlow/questions/sw4pt40/sw4pt40.component';
import { SwAppFlowHeaderComponent } from './SWApplicationFlow/sw-app-flow-header/sw-app-flow-header.component';
import { SwAppFlowFooterComponent } from './SWApplicationFlow/sw-app-flow-footer/sw-app-flow-footer.component';
import { StillWaterAppFlowComponent } from './SWApplicationFlow/still-water-app-flow/still-water-app-flow.component';
import { Sw4pt1Component } from './SWApplicationFlow/questions/sw4pt1/sw4pt1.component';
import { Sw4pt3Component } from './SWApplicationFlow/questions/sw4pt3/sw4pt3.component';
import { FlowResumeComponent } from './pages/flow-resume/flow-resume.component';
import { HomeScoreComponent } from './homescore/homescore-old.component';
import { Sw4pt41Component } from './SWApplicationFlow/questions/sw4pt41/sw4pt41.component';
import { Sw4pt43Component } from './SWApplicationFlow/questions/sw4pt43/sw4pt43.component';
import { KnockOutComponent } from './SWApplicationFlow/knock-out/knock-out.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { Sw4pt45Component } from './SWApplicationFlow/questions/sw4pt45/sw4pt45.component';
import { Sw4pt46Component } from './SWApplicationFlow/questions/sw4pt46/sw4pt46.component';
import { ApplicationStatusTrackerV2Component } from './components/application-status-tracker-v2/application-status-tracker-v2.component';
import { HomelifeappflowsComponent } from './components/homelifeappflows/homelifeappflows.component';
import { Sbli00101260Component } from './SBLIAppFlow/questions/sbli00101260/sbli00101260.component';
import { Sbli0015ssnComponent } from './SBLIAppFlow/questions/sbli0015ssn/sbli0015ssn.component';
import { Sbli00201010Component } from './SBLIAppFlow/questions/sbli00201010/sbli00201010.component';
import { Sbli00301011Component } from './SBLIAppFlow/questions/sbli00301011/sbli00301011.component';
import { Sbli00401120Component } from './SBLIAppFlow/questions/sbli00401120/sbli00401120.component';
import { Sbli00501030Component } from './SBLIAppFlow/questions/sbli00501030/sbli00501030.component';
import { Sbli00601020Component } from './SBLIAppFlow/questions/sbli00601020/sbli00601020.component';
import { Sbli00651130Component } from './SBLIAppFlow/questions/sbli00651130/sbli00651130.component';
import { Sbli00661135Component } from './SBLIAppFlow/questions/sbli00661135/sbli00661135.component';
import { Sbli00701001Component } from './SBLIAppFlow/questions/sbli00701001/sbli00701001.component';
import { Sbli00801002Component } from './SBLIAppFlow/questions/sbli00801002/sbli00801002.component';
import { Sbli00901003Component } from './SBLIAppFlow/questions/sbli00901003/sbli00901003.component';
import { Sbli01001080Component } from './SBLIAppFlow/questions/sbli01001080/sbli01001080.component';
import { Sbli01051040Component } from './SBLIAppFlow/questions/sbli01051040/sbli01051040.component';
import { Sbli01101140Component } from './SBLIAppFlow/questions/sbli01101140/sbli01101140.component';
import { Sbli01201150Component } from './SBLIAppFlow/questions/sbli01201150/sbli01201150.component';
import { Sbli01301160Component } from './SBLIAppFlow/questions/sbli01301160/sbli01301160.component';
import { Sbli01401180Component } from './SBLIAppFlow/questions/sbli01401180/sbli01401180.component';
import { Sbli01501190Component } from './SBLIAppFlow/questions/sbli01501190/sbli01501190.component';
import { Sbli01601210Component } from './SBLIAppFlow/questions/sbli01601210/sbli01601210.component';
import { Sbli01801220Component } from './SBLIAppFlow/questions/sbli01801220/sbli01801220.component';
import { Sbli01901230Component } from './SBLIAppFlow/questions/sbli01901230/sbli01901230.component';
import { Sbli02001240Component } from './SBLIAppFlow/questions/sbli02001240/sbli02001240.component';
import { Sbli02101270Component } from './SBLIAppFlow/questions/sbli02101270/sbli02101270.component';
import { Sbli02201930Component } from './SBLIAppFlow/questions/sbli02201930/sbli02201930.component';
import { Sbli02301940Component } from './SBLIAppFlow/questions/sbli02301940/sbli02301940.component';
import { Sbli02401950Component } from './SBLIAppFlow/questions/sbli02401950/sbli02401950.component';
import { Sbli02501250Component } from './SBLIAppFlow/questions/sbli02501250/sbli02501250.component';
import { Sbli02601100Component } from './SBLIAppFlow/questions/sbli02601100/sbli02601100.component';
import { Sbli02701061Component } from './SBLIAppFlow/questions/sbli02701061/sbli02701061.component';
import { Sbli02801170Component } from './SBLIAppFlow/questions/sbli02801170/sbli02801170.component';
import { Sbli02851175Component } from './SBLIAppFlow/questions/sbli02851175/sbli02851175.component';
import { Sbli02901680Component } from './SBLIAppFlow/questions/sbli02901680/sbli02901680.component';
import { SbliAppFlowFooterComponent } from './SBLIAppFlow/sbli-app-flow-footer/sbli-app-flow-footer.component';
import { HomeKnockOutComponent } from './v2/questions/home-knock-out/home-knock-out.component';
import { SbliappflowComponent } from './SBLIAppFlow/sbliappflow/sbliappflow.component';
import { Sbli6pt54Component } from './SBLIAppFlow/questions/sbli6pt54/sbli6pt54.component';
import { HomeCarrierRecommendationComponent } from './v2/home-carrier-recommendation/home-carrier-recommendation.component';
import { LifeCarrierRecommendationComponent } from './v2/life-carrier-recommendation/life-carrier-recommendation.component';
import { Sw4pt10Component } from './SWApplicationFlow/questions/sw4pt10/sw4pt10.component';
import { SbliPaymentComponent } from './SBLIAppFlow/questions/sbli-payment/sbli-payment.component';
import { PrefillResumeFlowComponent } from './pages/prefill-resume-flow/prefill-resume-flow.component';
import { LifeClickComponent } from './v2/life-click/life-click.component';
import { HomeClickComponent } from './v2/home-click/home-click.component';
import { PrefillResumeFlowHoiComponent } from './pages/prefill-resume-flow-hoi/prefill-resume-flow-hoi.component';
import { SbliCongratsComponent } from './SBLIAppFlow/questions/sbli-congrats/sbli-congrats.component';
import { Sbli6pt58Component } from './SBLIAppFlow/questions/sbli6pt58/sbli6pt58.component';
import { SbliTermsComponent } from './SBLIAppFlow/questions/sbli-terms/sbli-terms.component';
import { SbliReviewComponent } from './SBLIAppFlow/questions/sbli-review/sbli-review.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { SbliDetailsConfirmationComponent } from './SBLIAppFlow/questions/sbli-details-confirmation/sbli-details-confirmation.component';
import { SbliWelcomeComponent } from './SBLIAppFlow/questions/sbli-welcome/sbli-welcome.component';
import { SbliSummaryComponent } from './SBLIAppFlow/questions/sbli-summary/sbli-summary.component';
import { LeadComponent } from './pages/lead/lead.component';
import { SbliknockoutComponent } from './SBLIAppFlow/sbliknockout/sbliknockout.component';
import { HomeQuestion1V2Component } from './v2/questions/home-question1-v2/home-question1-v2.component';
import { PrefillQuoteFlowV2Component } from './v2/prefill-quote-flow-v2/prefill-quote-flow-v2.component';
import { PrefillQuoteFlowV2HomeComponent } from './v2/prefill-quote-flow-v2-home/prefill-quote-flow-v2-home.component';
import { SelectHomeCarrierv2Component } from './v2/select-home-carrierv2/select-home-carrierv2.component';
import { CompareHomePlansComponent } from './v2/compare-home-plans/compare-home-plans.component';
import { PrefillToPropertyDetailsComponent } from './pages/prefill-to-property-details/prefill-to-property-details.component';
import { ErroralertModalComponent } from './components/erroralert-modal/erroralert-modal.component';
import { AddressInputZipComponent } from '../app/components/address-input-zip/address-input-zip.component';
import { HomeScoreModalComponent } from '../app/components/home-score-modal/home-score-modal.component';
import { PrefillQuinstreetComponent } from './pages/prefill-quinstreet/prefill-quinstreet.component';
import { ThankYouComponent } from './components/thank-you/thank-you.component';
import { PrefillResumeFlowLifeComponent } from './pages/prefill-resume-flow-life/prefill-resume-flow-life.component';
import { SelectLifeCarrierV2Component } from './v2/select-life-carrier-v2/select-life-carrier-v2.component';
import { QuinstreetLifeComponent } from './pages/quinstreet-life/quinstreet-life.component';
import { WnsappflowComponent } from './SBLIAppFlow/wnsappflow/wnsappflow.component';
import { CoupleMarriedHOIComponent } from './Prefill pages/couple-married-hoi/couple-married-hoi.component';
import { CoupleBuyingHomeHOIComponent } from './Prefill pages/couple-buying-home-hoi/couple-buying-home-hoi.component';
import { InterstitialLoaderComponent } from './components/interstitial-loader/interstitial-loader.component';
import { SingleParentLifeComponent } from './Prefill pages/single-parent-life/single-parent-life.component';
import { CoupleWithChildrenLifeComponent } from './Prefill pages/couple-with-children-life/couple-with-children-life.component';
import { CoupleAboutToGetMArriedLifeComponent } from './Prefill pages/couple-about-to-get-married-life/couple-about-to-get-married-life.component';
import { QuoteWidgetComponent } from './components/quote-widget/quote-widget.component';
import { HomeInsuranceComponent } from './Prefill pages/home-insurance/home-insurance.component';
import { LifeInsuranceComponent } from './Prefill pages/life-insurance/life-insurance.component';
import { SingleMotherHOIComponent } from './Prefill pages/single-mother-hoi/single-mother-hoi.component';
import { WnstermsComponent } from './SBLIAppFlow/wnsQuestions/wnsterms/wnsterms.component';
import { Wns6pt54Component } from './SBLIAppFlow/wnsQuestions/wns6pt54/wns6pt54.component';
import { WnssummaryComponent } from './SBLIAppFlow/wnsQuestions/wnssummary/wnssummary.component';
import { WnspaymentComponent } from './SBLIAppFlow/wnsQuestions/wnspayment/wnspayment.component';
import { WnsreviewComponent } from './SBLIAppFlow/wnsQuestions/wnsreview/wnsreview.component';
import { WnscongratsComponent } from './SBLIAppFlow/wnsQuestions/wnscongrats/wnscongrats.component';
import { WnsknockoutComponent } from './SBLIAppFlow/wnsQuestions/wnsknockout/wnsknockout.component';
import { Wns02201930Component } from './SBLIAppFlow/wnsQuestions/wns02201930/wns02201930.component';
import { Wns1280Component } from './SBLIAppFlow/wnsQuestions/wns1280/wns1280.component';
import { Wns1282Component } from './SBLIAppFlow/wnsQuestions/wns1282/wns1282.component';
import { KOPopup2Component } from './SWApplicationFlow/KOPages/kopopup2/kopopup2.component';
import { Kopopup1Component } from './SWApplicationFlow/KOPages/kopopup1/kopopup1.component';
import { KOpopup3Component } from './SWApplicationFlow/KOPages/kopopup3/kopopup3.component';
import { SingleMotherLifeComponent } from './Prefill pages/single-mother-life/single-mother-life.component';
import { PrefillToRecoPageComponent } from './Prefill pages/prefill-to-reco-page/prefill-to-reco-page.component';
import { Wns00601020Component } from './SBLIAppFlow/wnsQuestions/wns00601020/wns00601020.component';
import { Wns00101260Component } from './SBLIAppFlow/wnsQuestions/wns00101260/wns00101260.component';
import { Back9RecoScreenComponent } from './components/back9-reco-screen/back9-reco-screen.component';
import { Back9QuoteComponent } from './components/back9-quote/back9-quote.component';
import { Back9HeaderComponent } from './components/back9-header/back9-header.component';
import { WnsComponent } from './pages/wns/wns.component';
import { WNSback9Component } from './components/wnsback9/wnsback9.component';
import { QuestionsModule } from './dq-questions/elements/questions/questions.module';
import { PrefillHomeKVComponent } from './Prefill pages/prefill-home-kv/prefill-home-kv.component';
import { PrefillLifeKVComponent } from './Prefill pages/prefill-life-kv/prefill-life-kv.component';
import { Termlifeback9Component } from './components/termlifeback9/termlifeback9.component';
import { Returnback9Component } from './components/returnback9/returnback9.component';
import { HomescoreNewComponent } from './app/homescore-new/homescore-new.component';
import { Back9AllQuotesComponent } from './components/back9-all-quotes/back9-all-quotes.component';
import { LifeRecommendationScreenComponent } from './life-recommendation-screen/life-recommendation-screen.component';
import { KoPersonalPopupComponent } from './components/ko-personal-popup/ko-personal-popup.component';
import { KoQuestionsPopupComponent } from './components/ko-questions-popup/ko-questions-popup.component';
import { LifeComparePlansComponent } from './components/life-compare-plans/life-compare-plans.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { DetailsConfirmationAndSSNComponent } from './SBLIAppFlow/questions/details-confirmation-and-ssn/details-confirmation-and-ssn.component';
import { HomescoreWithCoBrandedComponent } from './homescore-with-co-branded/homescore-with-co-branded.component';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
@NgModule({
  declarations: [
    AppComponent,
    QuoteFlowComponent,
    ScoreCardComponent,
    CustomiseComponent,
    LifescoreComponent,
    HeaderComponent,
    ConsentTermsPageComponent,
    EdLifescoreComponent,
    CustomiseComponent,
    CostPredictorComponent,
    AddressInputComponent,
    GoogleMapComponent,
    Question1Component,
    Question2Component,
    Question3Component,
    Question4Component,
    Question5Component,
    Question6Component,
    Question7Component,
    SectionOneComponent,
    SectionTwoComponent,
    LifeScoreGradeComponent,
    QuickquoteComponent,
    LeafletMapComponent,
    LifescoreErrorAlertComponent,
    LifescoreLeadCaptureComponent,
    FooterComponent,
    LifescoreEmptyPageComponent,
    ToastComponent,
    LeadNurtureComponent,
    TextToImageComponent,
    QuoteFlowV2Component,
    ScoreCardV2Component,
    CostPredictorV2Component,
    HomeQuestion1Component,
    HomeQuestion2Component,
    SelectHomeCarrierComponent,
    AppFlowComponent,
    PersonalDetailsQuestion1Component,
    ApplicationCodeComponent,
    PersonalDetailsQuestion2Component,
    PersonalDetailsQuestion3Component,
    PersonalDetailsQuestion4Component,
    PersonalDetailsQuestion5Component,
    PersonalDetailsQuestion6Component,
    PersonalDetailsQuestion7Component,
    PersonalDetailsQuestion8Component,
    StatusCompletionComponent,
    BubbleTipsComponent,
    GreetingNotesComponent,
    GeneralQuestionsComponent,
    DisabilityBenefitsComponent,
    EmploymentComponent,
    RiskLevelComponent,
    DiabetesComponent,
    HasEmphysemaComponent,
    MedicalAdviseComponent,
    StateCountrySearchComponent,
    QuoteFlowV2LifeComponent,
    LifeQuestion1Component,
    LifeQuestion2Component,
    LifeQuestion3Component,
    LifeQuestion4Component,
    SelectLifeCarrierComponent,
    HomeLifeApplyComponent,
    SelectedCarriersComponent,
    InsightsComponent,
    CustomizeHomeComponent,
    CustomizeLifeComponent,
    AsthmaComponent,
    ContactInformationComponent,
    HomeInsuranceDetailsComponent,
    LifeInsuranceDetailsComponent,
    QuoteFlowV2HomeComponent,
    HasHeartDiseaseComponent,
    HasPeripheralDiseaseComponent,
    HasHivDiseaseComponent,
    HasChronicDiseaseComponent,
    HasLiverPancreaseDiseaseComponent,
    HasNeurologicalDiseaseComponent,
    HasStrokeComponent,
    HasTissueDiseaseComponent,
    HasCancerDiseaseComponent,
    AlcoholDrugAbuseComponent,
    HasCoronavirusComponent,
    IsPleadGuiltyComponent,
    SsnComponent,
    IsActiveMilitaryComponent,
    IsDependentComponent,
    AddBeneficiariesComponent,
    InsurancePurposeComponent,
    CountryBornComponent,
    PacLifeComponent,
    AppflowstartComponent,
    QuoteFlowStatusTrackerComponent,
    QuoteFlowHeaderComponent,
    SelectedInsuranceFooterComponent,
    PizzaTrackerComponent,
    InsuredInformationComponent,
    PolicyOwnerInformationComponent,
    PolicyOwnerComponent,
    AppFlowHeaderComponent,
    AppFlowFooterComponent,
    PaclifeApplicationCompleteComponent,
    IsInsuredDependentComponent,
    ThirdPartyNotifiedComponent,
    ContingentBeneficiariesComponent,
    HasDriverLicenseComponent,
    HasPendingApplicationsComponent,
    ApplicationStatusTrackerComponent,
    HasExistingLifeInsuranceComponent,
    WelcomeToPacLifeComponent,
    DetailsConfirmationComponent,
    SbliCarrierPlanComponent,
    ElectronicConsentComponent,
    EConsentComponent,
    Details4idsComponent,
    AssumptionsPageComponent,
    GeneraldatepickerComponent,
    FirewallScreenComponent,
    LifeInsuranceCardComponent,
    HomeInsuranceCardComponent,
    ProfileHomePageComponent,
    HomeInsuranceDiscountsComponent,
    Sw4pt6Component,
    Sw4pt7Component,
    Sw4pt12Component,
    Sw4pt14Component,
    Sw4pt20Component,
    Sw4pt28Component,
    Sw4pt30Component,
    Sw4pt35Component,
    Sw4pt38Component,
    Sw4pt40Component,
    SwAppFlowHeaderComponent,
    SwAppFlowFooterComponent,
    StillWaterAppFlowComponent,
    Sw4pt1Component,
    Sw4pt3Component,
    FlowResumeComponent,
    HomeScoreComponent,
    Sw4pt41Component,
    Sw4pt43Component,
    KnockOutComponent,
    NumberInputComponent,
    Sw4pt45Component,
    Sw4pt46Component,
    ApplicationStatusTrackerV2Component,
    Sbli00101260Component,
    Sbli0015ssnComponent,
    Sbli00201010Component,
    Sbli00301011Component,
    Sbli00401120Component,
    Sbli00501030Component,
    Sbli00601020Component,
    Sbli00651130Component,
    Sbli00661135Component,
    Sbli00701001Component,
    Sbli00801002Component,
    Sbli00901003Component,
    Sbli01001080Component,
    Sbli01051040Component,
    Sbli01101140Component,
    Sbli01201150Component,
    Sbli01301160Component,
    Sbli01401180Component,
    Sbli01501190Component,
    Sbli01601210Component,
    Sbli01801220Component,
    Sbli01901230Component,
    Sbli02001240Component,
    Sbli02101270Component,
    Sbli02201930Component,
    Sbli02301940Component,
    Sbli02401950Component,
    Sbli02501250Component,
    Sbli02601100Component,
    Sbli02701061Component,
    Sbli02801170Component,
    Sbli02851175Component,
    Sbli02901680Component,
    HomelifeappflowsComponent,
    Sbli00101260Component,
    SbliAppFlowFooterComponent,
    HomeKnockOutComponent,
    SbliappflowComponent,
    Sbli6pt54Component,
    HomeCarrierRecommendationComponent,
    LifeCarrierRecommendationComponent,
    Sw4pt10Component,
    SbliPaymentComponent,
    PrefillResumeFlowComponent,
    LifeClickComponent,
    HomeClickComponent,
    PrefillResumeFlowHoiComponent,
    SbliCongratsComponent,
    Sbli6pt58Component,
    SbliTermsComponent,
    SbliReviewComponent,
    SbliDetailsConfirmationComponent,
    SbliWelcomeComponent,
    SbliSummaryComponent,
    LeadComponent,
    SbliknockoutComponent,
    LeadComponent,
    HomeQuestion1V2Component,
    PrefillQuoteFlowV2Component,
    PrefillQuoteFlowV2HomeComponent,
    SelectHomeCarrierv2Component,
    CompareHomePlansComponent,
    PrefillToPropertyDetailsComponent,
    CompareHomePlansComponent,
    ErroralertModalComponent,
    AddressInputZipComponent,
    HomeScoreModalComponent,
    PrefillQuinstreetComponent,
    ThankYouComponent,
    PrefillResumeFlowLifeComponent,
    SelectLifeCarrierV2Component,
    QuinstreetLifeComponent,
    HomeInsuranceComponent,
    LifeInsuranceComponent,
    WnsappflowComponent,
    SingleMotherHOIComponent,
    CoupleMarriedHOIComponent,
    CoupleBuyingHomeHOIComponent,
    InterstitialLoaderComponent,
    SingleParentLifeComponent,
    CoupleWithChildrenLifeComponent,
    CoupleAboutToGetMArriedLifeComponent,
    QuoteWidgetComponent,
    WnstermsComponent,
    Wns6pt54Component,
    WnssummaryComponent,
    WnspaymentComponent,
    WnsreviewComponent,
    WnscongratsComponent,
    WnsknockoutComponent,
    Wns02201930Component,
    Wns1280Component,
    Wns1282Component,
    KOPopup2Component,
    Kopopup1Component,
    KOpopup3Component,
    SingleMotherLifeComponent,
    PrefillToRecoPageComponent,
    Wns00601020Component,
    Wns00101260Component,
    Back9RecoScreenComponent,
    Back9QuoteComponent,
    Back9HeaderComponent,
    WnsComponent,
    WNSback9Component,
    Termlifeback9Component,
    Returnback9Component,
    HomescoreNewComponent,
    PrefillHomeKVComponent,
    PrefillLifeKVComponent,
    Back9AllQuotesComponent,
    LifeRecommendationScreenComponent,
    KoPersonalPopupComponent,
    KoQuestionsPopupComponent,
    LifeComparePlansComponent,
    DrawerComponent,
    DetailsConfirmationAndSSNComponent,
    HomescoreWithCoBrandedComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    CommonModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    GraphQLModule,
    HttpClientModule,
    CarouselModule,
    NgbModule,
    GooglePlaceModule,
    NgxSliderModule,
    QuestionsModule,
    AgmCoreModule.forRoot({
      apiKey: environment.googleMapApiKey, //'AIzaSyDt2HM0PNAkw1XY5anITxTQt9L_EVAPwCE'
      libraries: ['places']
    }),
    // AngularStickyThingsModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsTrackingCode),
    NgxGoogleAnalyticsRouterModule,
    BsDatepickerModule.forRoot(),
    DatepickerModule.forRoot(),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    NgbTooltipModule
  ],
  providers: [
    // Below line is optional as default LocationStrategy is PathLocationStrategy
    { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule { }
