import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { environment } from './../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from './../../components/lifescore-error-alert/lifescore-error-alert.component';
import { TrackerService } from 'src/app/shared/tracker.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-still-water-app-flow',
  templateUrl: './still-water-app-flow.component.html',
  styleUrls: ['./still-water-app-flow.component.css']
})
export class StillWaterAppFlowComponent implements OnInit {
  question: number;
  selectedCard: number;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  carrierName: string;
  hcarrierAmount: any;
  hcarrierName: any;
  homeselectedCard: number;
  imgwid: string;
  lifeselectedCard: number;
  lcarrierName: any;
  lcarrierAmount: any;
  lcarriericon: any;
  knockout: boolean;
  loader: boolean;
  dataToSend: any;
  showFooter: number;
  existingData: any;
  totalPages: number;
  currentPage: number;
  application_stage: any;
  errorMessage: any;
  // totalPages: number = 22 * 2; //change if number of pages changes
  // currentPage: number = this.totalPages / 2 + 3;
  flag: any;
  lifeCarrier: any;
  blur: boolean;
  showNumber: number;
  timer: NodeJS.Timeout;
  time: any;
  autoRedirectUrl: any;
  homeAutoAppUrl: any;
  clientData: any;
  constructor(private $gaService: GoogleAnalyticsService, private activatedroute: ActivatedRoute,
    private modalService: NgbModal,
    private pc: ProcessCacheService,
    private router: Router, private tracker: TrackerService) { }

  async ngOnInit() {

    debugger;
    document.getElementById('errorServer').style.display = 'none';
    this.showNumber = 0;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    this.question = 1;
    this.loader = true;
    this.flag = 'YES';
    await this.pc.getSWSelectedFormattedQuote().then(data => {
      console.log('selectedHomeCarrier', data);
      localStorage.setItem('selectedHomeCarrier', JSON.stringify(data));
      const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
      console.log('selectedHomeCarrier', selectedHome);
      this.hcarrierName = selectedHome.head.carrier_name;
      //this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
      this.captureHomeAmount(selectedHome);
      this.homeselectedCard = 1;
      this.pc.getCommonInput().then(data => {
        debugger;
        console.log('commonInput', data);
        localStorage.setItem('commonInput', JSON.stringify(data));
        return true;
      });
    });
    await this.getSelectedCarriersDetails();
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      const qno = dataFrom.body;
      const lastQuestion = qno['sw_questionNumber'];
      if (lastQuestion == undefined || lastQuestion == null || lastQuestion == '') {
        this.question = 1;
      }
      else {
        this.question = lastQuestion;
      }
      if (this.insurancetype == 'home_auto') {
        this.autoRedirectUrl = qno.pageDataJson.proceedToStillWater_Link;
      }
      if (qno.newAppURL) {
        this.homeAutoAppUrl = qno.newAppURL;
      }



      this.loader = false;
    }).catch(error => {
      console.log(error);
    });

    this.imgwid = '60px';
    if (this.insurancetype == 'homeLife' && localStorage.getItem('selectedCarrier') !== null) {
      const selectedLife = JSON.parse(localStorage.getItem('selectedCarrier'));
      console.log('selectedCarrier life : ', JSON.parse(localStorage.getItem('selectedCarrier')));
      this.lifeCarrier = selectedLife.carrierId;
      this.lcarrierName = selectedLife.head.carrier_name;
      this.lcarrierAmount = selectedLife.head.quote_amount;
      this.lcarriericon = selectedLife.head.carrier_icon;
      this.lifeselectedCard = 1;
    }
    else {
      this.lifeselectedCard = 0;
    }




    if (localStorage.getItem('appFlowLinkResume') == '1') {
      localStorage.setItem('appFlowLinkResume', '0');
      this.checkforResumeInit({}, 'fetch').then(
        resumeData => {
          console.log('SW:checkforResumeInit:Resume Step 0', resumeData);
          console.log('SW:checkforResumeInit:Resume Step 1');
          if (resumeData['questionStartStatus'] == true && resumeData['questionCompletedStatus'] == false) {
            console.log('Resume Step 2 question no: ', +resumeData['questionSequence']);
            this.question = +resumeData['questionSequence'];
          } else if (resumeData['questionCompletedStatus'] == true && resumeData['appCompletedStatus'] == true) {
            if (this.insurancetype == 'homeLife') {
              localStorage.setItem('insurancetype', this.insurancetype);
              location.href = `Appflowstart/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.carrierName}`;
            } else if (this.insurancetype == 'home') {
              location.href = 'https://development.d81dpjw7shcd2.amplifyapp.com/profile-home';
            }
          }
          else {
            this.question = 1;
          }
        }
      );
    } else {
      const createJson = {
        insuranceType: 'SBLI-appflow',
        carrierName: 'SBLI Term',
        appStartStatus: true,
        appCompletedStatus: false,
        questionStartStatus: true,
        questionCompletedStatus: false,
        questionSequence: '1',
        applicationId: '',
        otherAppflowScreen: '',
        resumeUrl: this.router.url
      };
      this.checkforResumeInit(createJson, 'create');
    }
    this.carrierName = localStorage.getItem('carrierName');
    this.knockout = false;
    this.showFooter = 1;


    const RefreshSSNwithoutEntering = localStorage.getItem('RefreshSSNwithoutEntering');
    if (RefreshSSNwithoutEntering == 'Yes') {
      this.flag = 'NO';
      localStorage.setItem('KOType', 'ko');
      localStorage.setItem('KOerrorMessage', 'Unable to quote - Insurance Bureau Score not available.');
      localStorage.setItem('RefreshSSNwithoutEntering', 'Yes');
      this.loader = false;
      this.knockout = true;
      this.openModalssn_number();
      console.log('RefreshSSNwithoutEntering', RefreshSSNwithoutEntering);
    }

    if (this.insurancetype == 'home') {
      this.totalPages = 20 * 2;
      this.currentPage = this.totalPages / 2 + 3;
    } else {
      this.totalPages = 22 * 2;
      this.currentPage = this.totalPages / 2 + 3;
    }




  }




  //   ngAfterViewInit(): void {
  //     document.body.addEventListener("mouseleave", (e) => { this.exitPopup(); });
  //     document.body.addEventListener("mousemove", (e) => { this.idlePopup(); });
  //     document.body.addEventListener("keypress", (e) => { this.idlePopup(); });
  //   }

  //   exitPopup() {

  //     if (this.showNumber == 0) {
  //       document.getElementById("errorModal").style.display = "block";
  //       this.showNumber = 1;
  //     }
  //   }

  //   closePopup() {
  //     document.getElementById("errorModal").style.display = "none";
  //   }


  //   closetab() {
  //     debugger;
  //     window.open("https://stage.getmybubble.co/", "_self");
  //   }


  //   idlePopup () {
  //     debugger;

  //     clearTimeout( this.time);
  //     this.time = setTimeout(()=>{ this.exitPopup(); },15000);

  // }

  async getSelectedCarriersDetails() {
    let selectedHome;
    if (localStorage.getItem('selectedHomeCarrier') === null) {
      await this.pc.getSWSelectedFormattedQuote().then(data => {
        console.log('selectedHomeCarrier', data);
        localStorage.setItem('selectedHomeCarrier', JSON.stringify(data));
        selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
        console.log('selectedHomeCarrier', selectedHome);
        this.hcarrierName = selectedHome.head.carrier_name;
        //this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
        this.captureHomeAmount(selectedHome);
        this.homeselectedCard = 1;

        this.pc.getCommonInput().then(data => {
          debugger;
          console.log('commonInput', data);
          localStorage.setItem('commonInput', JSON.stringify(data));
          return true;
        });
      });

    } else {
      debugger;
      selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
      console.log('selectedHomeCarrier', selectedHome);
      this.hcarrierName = selectedHome.head.carrier_name;
      //this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
      this.captureHomeAmount(selectedHome);
      this.homeselectedCard = 1;
      return true;
    }
  }

  navigateToAuto() {
    window.open(this.autoRedirectUrl, '_blank');
  }


  errorCodeForModal: any;
  async changeQuestion(etty) {
    debugger;
    this.loader = true;
    if (this.question < 16) {
      this.showNumber = 0;
    }
    else {
      this.showNumber = 1;
    }
    const idData = await this.pc.getAppData(this.clientId);
    this.clientData = JSON.parse(idData?.Payload)?.body;


    if (etty.next == 1) {
      //this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
      this.StatusTrackerPercentage(this.question);
      if (this.question == 17) {
        if (this.insurancetype == 'home_auto') {
          window.open(this.autoRedirectUrl, '_blank');
        }
        // else if (this.lifeCarrier == 'paclife') {
        //   location.href = `Appflowstart/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.carrierName}`;
        // }
        // else if (this.lifeCarrier == 'sbli') {
        //   location.href = `lifeCarrierTwo/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
        // }
        else {
          location.href = this.clientData['lifeflowurl'];
        }
      }
      else if (this.question == 16) {

        this.dataToSend = etty.details;
        const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
        const hubSpotData = {
          customer_id: localStorage.getItem('customer_id'),
          email: localStorage.getItem('bubble-email'),
          application_stage: 'Policy Issued',
          home_policy_number: this.dataToSend.data.policyNumber
        };
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
        this.pc.createIdMapping({
          client_id: this.clientId,
          customer_id: localStorage.getItem('customer_id'),
          email_id: localStorage.getItem('bubble-email'),
          sw_quote_id: 'sw_quote_id',
          sw_application_id: 'sw_application_id',
          sw_policy_id: this.dataToSend.data.policyNumber,
          status: 'HomeApplication',

        });
        this.$gaService.gtag('event', 'HomeApplication', {
          'event_category': 'home_policy_number', 'event_label': this.dataToSend.data.policyNumber
        });

        this.incrementScreen();
      }
      else if (etty.knockout == 1 && this.question == 3) {
        this.knockout = true;


        const hubSpotData = {
          customer_id: localStorage.getItem('customer_id'),
          email: localStorage.getItem('bubble-email'),
          application_stage: 'Policy Denied'
        };
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

      }
      else if (etty.knockout == 1) {
        debugger;
        this.loader = true;
        this.$gaService.gtag('event', this.insurancetype, {
          'event_category': 'Error', 'event_label': 'KnockoutPopup'
        });
        // setTimeout(() => {
        //   this.question = this.question + 1;
        //   this.loader = false;
        // }, 3000);
        const quickquote = new pc.Quotes(this.uniqueId);
        const commonInput = JSON.parse(localStorage.getItem('commonInput'));
        console.log('knockout call commonInput: ', commonInput);
        quickquote.checkSWAppFlowKnockout(commonInput).then(response => {

          localStorage.setItem('KOerrorMessage', 'Unfortunately we require a couple more steps before completing your application.');
          console.log('checkSWAppFlowKnockout response:', response);
          if (response.hasError) {
            this.loader = false;
            this.openModal();
          }
          else if (response.knockout) {
            debugger;
            this.flag = response.counterOfferFlag[response.counterOfferFlag.length - 1].flag;
            let errorMsg = response.message;
            errorMsg = errorMsg.split(':');
            const errorCode = errorMsg[0].replace(' ', '');
            this.errorCodeForModal = errorCode;
            console.log('errorCode1', errorCode);
            if (errorCode == 'FNI0052') {
              localStorage.setItem('KOType', 'ko');
              localStorage.setItem('KOerrorMessage', 'Unable to quote - Insurance Bureau Score not available.');
              localStorage.setItem('RefreshSSNwithoutEntering', 'Yes');
              this.loader = false;
              this.knockout = false;
              this.openModalssn_number();
            } else {
              const hubSpotData = {
                customer_id: localStorage.getItem('customer_id'),
                email: localStorage.getItem('bubble-email'),
                counter_offer: this.flag,
                application_stage: 'Policy Denied',
                home_knock_out_reason: errorMsg[1]
              };
              this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
              this.knockout = true;
              localStorage.setItem('KOerrorMessage', errorMsg[1]);
              localStorage.setItem('RemoveKOData', '1');
              this.loader = false;
            }

            const hubSpotData = {
              customer_id: localStorage.getItem('customer_id'),
              email: localStorage.getItem('bubble-email'),
              counter_offer: this.flag,
              application_stage: 'Policy Denied',
              home_knock_out_reason: errorMsg[1]
            };
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
            this.knockout = true;
            debugger;

            localStorage.setItem('KOerrorMessage', errorMsg[1]);
            localStorage.setItem('RemoveKOData', '1');
            this.loader = false;
          }
          else {
            //this.question = this.question + 1;
            this.loader = false;
            this.knockout = false;
            this.pc.getSWSelectedFormattedQuote().then(data => {
              debugger;
              console.log('selectedHomeCarrier', data);
              localStorage.setItem('selectedHomeCarrier', JSON.stringify(data));
              selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
              this.hcarrierName = selectedHome.head.carrier_name;
              //this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
              this.captureHomeAmount(selectedHome);
              const hubSpotData = {
                customer_id: localStorage.getItem('customer_id'),
                email: localStorage.getItem('bubble-email'),
                application_stage: 'Application',
                home_insurance_carrier: selectedHome.head.home_insurance_carrier,
                home_latest_premium_amount: '$' + selectedHome.head.quote_amount
              };
              this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
              this.homeselectedCard = 1;
              localStorage.setItem('RemoveKOData', '0');
              this.updateFooterAmount(selectedHome);
            });
            this.incrementScreen();
          }
        })
          .catch(error => {
            console.log('checkSWAppFlowKnockout Error:', error);
            this.loader = false;

            if (this.errorCodeForModal == 'FNI0052') {

            } else {
              this.openModal();
            }



          });
      }
      else {
        debugger;
        this.incrementScreen();
        if (this.question > 19) this.application_stage = 'Payment';
        else this.application_stage = 'Application';

        if (this.question <= 10) {
          this.pc.getSWSelectedFormattedQuote().then(data => {
            debugger
            console.log('selectedHomeCarrier', data);
            localStorage.setItem('selectedHomeCarrier', JSON.stringify(data));
            selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
            this.hcarrierName = selectedHome.head.carrier_name;
            //this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
            this.captureHomeAmount(selectedHome);
            const hubSpotData = {
              customer_id: localStorage.getItem('customer_id'),
              email: localStorage.getItem('bubble-email'),
              application_stage: this.application_stage,
              home_insurance_carrier: selectedHome.head.home_insurance_carrier,
              home_latest_premium_amount: '$' + selectedHome.head.quote_amount
            };
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
            this.updateFooterAmount(selectedHome);
            this.homeselectedCard = 1;
          });
        }



      }

      if (this.question <= 13) {
        //this.question = this.question;
        const updateJson = {
          otherAppflowScreen: '',
          questionSequence: this.question,
          resumeUrl: this.router.url
        };
        this.checkforResumeInit(updateJson, 'update');
      }


    }

    else {
      if (this.question == 1) {

        if (this.homeAutoAppUrl) {
          location.href = this.homeAutoAppUrl;
        }
        else {
          location.href = `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
        }
      }
      else {
        this.$gaService.gtag('event', this.insurancetype, {
          'event_category': 'BackButton', 'event_label': 'Back'
        });
        if (localStorage.getItem('RemoveKOData') != '1') {

          if (this.question == 4) {
            this.question = this.question - 2;
          }
          else {
            this.question = this.question - 1;
          }
          this.StatusTrackerPercentage(this.question - 1);
          this.updateScreenNumber();
        }
      }
    }


    if (this.question <= 10) {
      this.showFooter = 1;
    }
    else {
      this.showFooter = 0;
    }

    let selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
    this.hcarrierName = selectedHome.head.carrier_name;
    //this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
    this.captureHomeAmount(selectedHome);
    this.homeselectedCard = 1;


  }

  incrementScreen() {
    if (this.question == 2) {
      this.question = this.question + 2;
    }
    else {
      this.question = this.question + 1;
    }
    this.updateScreenNumber();
  }

  updateFooterAmount(even) {
    let selectedHome;
    localStorage.setItem('selectedHomeCarrier', JSON.stringify(even));
    selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
    console.log('selectedHomeCarrier', selectedHome);
    this.hcarrierName = selectedHome.head.carrier_name;
    //this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
    this.captureHomeAmount(selectedHome);
    this.homeselectedCard = 1;
  }


  updateScreenNumber() {
    this.loader = true;
    const json = {
      'id': this.clientId,
      'sw_questionNumber': this.question
    };
    //json.stillwater['sw_questionNumber'] = this.question;
    this.pc.saveUpdateClientData(json).then(data => {
      console.log(data);
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });
  }

  StatusTrackerPercentage(question) {
    debugger;
    if (question == 1) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 24 });
    }
    else if (question == 2) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 26 });
    }
    else if (question == 3) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 27 });
    }
    else if (question == 4) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 28 });
    }
    else if (question == 5) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 29 });
    }
    else if (question == 6) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 30 });
    }
    else if (question == 7) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 31 });
    }
    else if (question == 8) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 32 });
    }
    else if (question == 9) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 33 });
    }
    else if (question == 10) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 34 });
    }
    else if (question == 11) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 35 });
    }
    else if (question == 12) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 40 });
    }
    else if (question == 13) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 42 });
    }
    else if (question == 14) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 44 });
    }
    else if (question == 15) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 46 });
    }
    else if (question == 16) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 49 });
    }
    else if (question == 17) {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 49 });
    }
    // else if (question == 18) {
    //   this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 44 });
    // }
    // else if (question == 19) {
    //   this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 46 });
    // }
    // else if (question == 20) {
    //   this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 48 });
    // }
    // else if (question == 21) {
    //   this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 49 });
    // }
    // else if (question == 22) {
    //   this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 46 });
    // }
    // else if (question == 23) {
    //   this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 48 });
    // }
    // else if (question == 24) {
    //   this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 49 });
    // }
  }

  showPreBindKnockOut() {
    this.knockout = true;
  }

  ssnLength: any;
  SSNerror: boolean;
  ssnlength() {
    debugger;
    const j = this.SSN.replace(/-/g, '');
    const A = j.substring(0, 3);
    const B = j.substring(3, 5);
    const C = j.substring(5, 9);
    if (B == '') {
      this.SSN = A;
    }
    else if (B != '' && C == '') {
      this.SSN = A + '-' + B;
    }
    else if (B != '' && C != '') {
      this.SSN = A + '-' + B + '-' + C;
    }
    else {
      this.SSN = j;
    }

    this.ssnLength = this.SSN.toString().length;
    if (this.ssnLength > 10) {
      this.SSNerror = false;
    } else if (this.ssnLength == 0) {
      this.SSNerror = false;
    }
    else {
      this.SSNerror = true;
    }

  }

  backToPayment() {
    debugger;
    this.question = 16;
  }

  closeKnockout() {
    this.knockout = false;
    if (this.question == 13) {
      this.question = this.question - 1;
    }
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }


  tryAgain() {

    document.getElementById('errorServer').style.display = 'none';
    location.reload();
  }

  gotoHome() {
    document.getElementById('errorServer').style.display = 'none';
    window.location.href = this.pc.envCred.url;
  }

  openModal() {
    this.errorMessage = 'Our Servers are taking too long to respond please come back later.';
    document.getElementById('errorServer').style.display = 'block';

    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //   });

    // const data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   message: 'Our Servers are taking too long to respond please come back later.',
    //   image: 'assets/images/images/education2.png'
    // };

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   //redirect url
    //   //window.location.href = environment.carrierErrorRedirectURL;
    //   //window.location.href = this.pc.envCred.url;
    //   console.log('Modal Close Output: ', result);
    // }, (reason) => {
    //   console.log('Modal Close Reason: ', reason);
    // });
  }


  openModalssn_number() {
    document.getElementById('ssn_number_discountsPage').style.display = 'flex';
    //document.getElementById('knockout_id').style.filter = "blur(10px)";
    this.blur = true;
  }

  SSN: any;
  public onSubmit() {
    debugger;
    localStorage.setItem('RefreshSSNwithoutEntering', '');
    this.knockout = false;
    this.loader = true;

    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['disableBack'] = false;
    commonInput['ssn'] = this.SSN.replaceAll('-', '');

    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    document.getElementById('ssn_number_discountsPage').style.display = 'none';
    this.blur = false;
    //document.getElementById('knockout_id').style.filter = "none";
    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.checkSWAppFlowKnockout(commonInput)
      .then(response => {
        debugger;

        const jsonhome = {
          'id': this.clientId,
          'ssn': this.SSN
        };
        console.log('json : ', jsonhome);
        this.pc.saveUpdateClientData(jsonhome).then(data => {
          localStorage.setItem('KOerrorMessage', 'Unfortunately we require a couple more steps before completing your application.');
          console.log('checkSWAppFlowKnockout response:', response);
          if (response.hasError) {
            this.loader = false;
            this.openModal();
          }
          else if (response.knockout) {
            debugger;
            this.flag = response.counterOfferFlag[response.counterOfferFlag.length - 1].flag;
            let errorMsg = response.message;
            errorMsg = errorMsg.split(':');
            const errorCode = errorMsg[0].replace(' ', '');
            console.log('errorCode2', errorCode);

            if (errorCode == 'FNI0052') {
              // localStorage.setItem('KOType', 'ko');
              // localStorage.setItem('KOerrorMessage', 'Unable to quote - Insurance Bureau Score not available.');
              // this.loader = true;
              // this.knockout = false;
              console.log('TestOnUAT', errorCode);
              this.onSubmit();
            }

            // } else {
            //   const hubSpotData = {
            //     customer_id: localStorage.getItem('customer_id'),
            //     email: localStorage.getItem('bubble-email'),
            //     counter_offer: this.flag,
            //     application_stage: 'Policy Denied',
            //     home_knock_out_reason: errorMsg[1]
            //   };
            //   this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
            //   this.knockout = true;

            //   localStorage.setItem('KOerrorMessage', errorMsg[1]);
            //   localStorage.setItem('RemoveKOData', '1');
            //   this.loader = false;
            // }


            // const hubSpotData = {
            //   customer_id: localStorage.getItem('customer_id'),
            //   email: localStorage.getItem('bubble-email'),
            //   counter_offer: this.flag,
            //   application_stage: 'Policy Denied',
            //   home_knock_out_reason: errorMsg[1]
            // };
            // this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
            // this.knockout = true;

            // localStorage.setItem('KOerrorMessage', errorMsg[1]);
            // localStorage.setItem('RemoveKOData', '1');
            // this.loader = false;
          }
          else {
            //this.question = this.question + 1;
            this.loader = false;
            this.knockout = false;
            this.pc.getSWSelectedFormattedQuote().then(data => {
              debugger;
              console.log('selectedHomeCarrier', data);
              localStorage.setItem('selectedHomeCarrier', JSON.stringify(data));
              const selectedHome1 = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
              this.hcarrierName = selectedHome1.head.carrier_name;
              //this.hcarrierAmount = '$' + selectedHome1.head.quote_amount;
              this.captureHomeAmount(selectedHome1);
              const hubSpotData = {
                customer_id: localStorage.getItem('customer_id'),
                email: localStorage.getItem('bubble-email'),
                application_stage: 'Application',
                home_insurance_carrier: selectedHome1.head.home_insurance_carrier,
                home_latest_premium_amount: '$' + selectedHome1.head.quote_amount
              };
              this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
              this.homeselectedCard = 1;
              localStorage.setItem('RemoveKOData', '0');
              this.updateFooterAmount(selectedHome1);
            });
            this.incrementScreen();
          }


        }).catch(error => {
          console.log(error);
        });


      })
      .catch(error => {

      });



  }


  captureHomeAmount(selectedHome) {
    if (this.insurancetype == 'home_auto') {
      // this.pc.getAppData(this.clientId).then(data => {
      //   debugger
      //   debugger
      //   const dataFrom = JSON.parse(data.Payload);
      //   const qno = dataFrom.body;
      //   let selectedDetails = qno.pageDataJson.ha_recommendation_details;

      //   if (selectedDetails.selectedAutoQuoteQuoteAmount && selectedDetails.selectedAutoQuoteQuoteAmount != 0 && selectedDetails.selectedAutoQuoteQuoteAmount != '0' && selectedDetails.selectedAutoQuoteQuoteAmount != '$0') {
      //     this.hcarrierAmount = '$' + selectedHome.head.packagePremiumDiscounted;
      //   }
      //   else {
      //     this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
      //   }


      // }).catch(error => {
      //   console.log(error);
      // });
      this.hcarrierAmount = '$' + selectedHome.head.packagePremiumDiscounted;
    }
    else {
      this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
    }

  }

}
