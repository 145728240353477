import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-is-plead-guilty',
  templateUrl: './is-plead-guilty.component.html',
  styleUrls: ['./is-plead-guilty.component.css']
})
export class IsPleadGuiltyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
