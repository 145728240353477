import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli01101140',
  templateUrl: './sbli01101140.component.html',
  styleUrls: ['./sbli01101140.component.css']
})
export class Sbli01101140Component implements OnInit {
  questions: any;
  isDisease: any;
  isDeath: any;
  isTwoOrMoreDeath: any;
  isDisable: boolean;
  clientId: any;
  existingData: any;
  @Output() changeQuestion = new EventEmitter();
  showLoader = true;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');
    this.isDisable = true;
    // this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli01101140")[0];
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);



      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli01101140')[0];
        console.log('app-sbli01101140 questions : ', this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli01101140')[0];
        console.log('app-sbli01101140 questions : ', this.questions);
      }

      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_parentsibling_disease != undefined) {
        this.isDisease = this.existingData.sbli_parentsibling_disease;
        if (this.isDisease == 'Yes') {
          this.isDiseased('Yes');
          this.isDeath = this.existingData.sbli_disease_sibling_died;
          if (this.isDeath == 'Yes') {
            this.isDied('Yes');
            this.isTwoOrMoreDeath = this.existingData.sbli_disease_two_or_more_sibling_died;
            if (this.isTwoOrMoreDeath == 'Yes') {
              this.isTwoOrMoreDied('Yes');
            } else {
              this.isDisable = false;
            }
          } else {
            this.isDisable = false;
          }
        } else {
          this.isDisable = false;
        }
      }


      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    console.log('app-sbli01101140 questions', this.questions);
  }


  isDiseased(input) {
    debugger;
    this.isDisease = input;
    this.isDeath = '';
    if (this.isDisease != '' && this.isDisease == this.questions.radio_options[1].answer_text) {
      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
  }


  isDied(input) {
    debugger;
    this.isDeath = input;
    this.isTwoOrMoreDeath = '';
    if (this.isDisease == this.questions.radio_options[0].answer_text && this.isDeath != this.questions.radio_options_1[0].answer_text) {
      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
  }

  isTwoOrMoreDied(input) {
    debugger;
    this.isTwoOrMoreDeath = input;
    if (this.isTwoOrMoreDeath != '') {
      this.isDisable = false;
    }
  }
  next() {
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };

    json.SBLI[this.questions.radio_options[0].answer_key] = this.isDisease;
    json.SBLI[this.questions.radio_options_1[0].answer_key] = this.isDeath;
    json.SBLI[this.questions.radio_options_2[0].answer_key] = this.isTwoOrMoreDeath;

    this.$gaService.event('SBLI LifeApplication Disorder', 'event','SBLI-LifeApplication-Disorder');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Disorder',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions,'SBLI LifeApplication Disorder', 'event','SBLI-LifeApplication-Disorder');

    for (const propName in json.SBLI) {
      if (json.SBLI[propName] === null || json.SBLI[propName] === undefined || json.SBLI[propName] === '') {
        delete json.SBLI[propName];
      }
    }


    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

}
