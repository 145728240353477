import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli02501250',
  templateUrl: './sbli02501250.component.html',
  styleUrls: ['./sbli02501250.component.css']
})
export class Sbli02501250Component implements OnInit {
  showLoader = true;
  issurgerychecked: boolean;
  isnonofabove: any;
  showtextbox: boolean;
  isedit: boolean;
  showtesttextbox: boolean;
  option12: any;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }
  @Output() changeQuestion = new EventEmitter();
  questions: any;
  closedDiv3: boolean;
  UnCheckedIcon: boolean;
  CheckedIcon: boolean;
  ShowSectionYes = true;
  HideSectionYes: boolean;
  SurgeryOptions: any;
  selectedSurgeryList = [];
  anyTestList = [];
  dynamicdiv = [];
  testdynamicdiv = [];
  testoptions = [];
  clientId: any;
  existingData: any;
  isDisable: boolean;
  istestchecked: boolean;
  option11: any;
  ngOnInit(): void {
    debugger;
    this.isedit = false;
    this.issurgerychecked = false;
    this.istestchecked = false;
    this.isDisable = true;
    this.clientId = localStorage.getItem('clientID');
    const qestentity = {
      'Other': ''
    };
    this.dynamicdiv.push(qestentity);
    console.log('dynamicdiv', this.dynamicdiv);
    this.UnCheckedIconForSurgery = true;
    this.CheckedIconForSurgery = false;
    this.showTest = true;
    this.hideTest = false;
    // this.questions = getQuestionAnswerJson.questions;
    // this.SurgeryOptions = this.questions[0]["sub_questions"]["question_1"]["checkbox_options"];
    // this.testoptions = this.questions[0]["sub_questions"]["question_2"]["checkbox_options"];
    this.pc.getAppData(this.clientId).then(data => {
      debugger;
      const dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli02501250');
        this.SurgeryOptions = this.questions[0]['sub_questions']['question_1']['checkbox_options'];
        this.testoptions = this.questions[0]['sub_questions']['question_2']['checkbox_options'];
        console.log('app-sbli02501250 questions : ', this.questions);
      } else {
        this.questions = getQuestionAnswerJson.questions;
        this.SurgeryOptions = this.questions[0]['sub_questions']['question_1']['checkbox_options'];
        this.testoptions = this.questions[0]['sub_questions']['question_2']['checkbox_options'];
      }



      this.existingData = dataFrom.body.SBLI;
      for (const point in this.existingData) {
        if (point == 'sbli_Surgery_noneOfTheAbove' && this.existingData[point] == 'Yes') {
          this.isedit = true;
          this.ShowNone = false;
          this.HideNone = true;
          this.AnyTestOption12 = 'Yes';
        }
        else {
          for (let i = 0; i < this.SurgeryOptions.length; i++) {
            for (const point in this.existingData) {
              if (point == this.SurgeryOptions[i].answer_key) {
                if (this.existingData[point] == 'Yes') {
                  this.isedit = true;
                  this.selectedSurgeryList.push(this.existingData[point]);
                  this.issurgerychecked = true;
                  this.UnCheckedIconForSurgery = false;
                  this.CheckedIconForSurgery = true;
                  this.isDisable = false;
                  this.SurgeryCollapse = true;
                  this.SurgeryOptions[i].checked = 'Yes';
                }
                else {
                  this.SurgeryOptions[i].checked = 'No';
                }
              }
            }
          }
          for (let i = 0; i < this.testoptions.length; i++) {
            for (const point in this.existingData) {
              if (point == this.testoptions[i].answer_key) {
                if (this.existingData[point] == 'Yes') {
                  this.isedit = true;
                  this.anyTestList.push(this.existingData[point]);
                  this.showTest = false;
                  this.hideTest = true;
                  this.ShowCollapse = true;
                  this.ShowNone = true;
                  this.HideNone = false;
                  this.istestchecked = true;
                  this.isDisable = false;
                  this.testoptions[i].checked = 'Yes';
                }
                else {
                  this.testoptions[i].checked = 'No';
                }
              }
            }

          }
        }
      }


      this.showLoader = false;




    }).catch(error => {
      console.log(error);
    });

  }

  public ExpandCollapse3(event) {
    debugger;
    if (event.target.id == 'Open') {
      this.closedDiv3 = true;
    }
    else {
      this.closedDiv3 = false;
    }
  }


  UnCheckedIconForSurgery: boolean;
  CheckedIconForSurgery: boolean;
  SurgeryCollapse: boolean;
  public CheckYesOrNoForSurgery(data) {
    debugger;
    this.AnyTestOption12 = undefined;
    if (data == 'Yes') {
      this.issurgerychecked = true;
      this.UnCheckedIconForSurgery = false;
      this.CheckedIconForSurgery = true;
      this.SurgeryCollapse = true;
      this.ShowNone = true;

      this.HideNone = false;

      if (this.selectedSurgeryList.includes('sbli_surgeryInfo_Option11')) {
        this.showtextbox = true;
      } else {
        this.showtextbox = false;
      }




    } else {
      // if (this.anyTestList.length == 0 && (this.AnyTestOption12 == 'No' || this.AnyTestOption12 == undefined || this.AnyTestOption12 == "")) {
      //   this.isDisable = true;
      // }

      this.dynamicdiv.length = 0;
      const qestentity = {
        'Other': ''
      };
      this.dynamicdiv.push(qestentity);

      this.selectedSurgeryList = [];
      this.showtextbox = false;
      for (let i = 0; i < this.SurgeryOptions.length; i++) {
        this.SurgeryOptions[i].checked = 'No';
      }


      if (this.anyTestList.length == 0) {
        this.isDisable = true;
      }
      else {
        if (this.AnyTestOption12 != undefined) {
          this.isDisable = false;
        }
        else {
          this.isDisable = true;
        }

      }




      this.UnCheckedIconForSurgery = true;
      this.issurgerychecked = false;
      this.CheckedIconForSurgery = false;
      this.SurgeryCollapse = false;
      this.ShowNone = true;
      this.HideNone = false;


    }
  }


  public SurgerySelectedItems(data, selectedoption, id) {
    debugger;
    if (selectedoption == 'Yes') {
      document.getElementById(id).style.display = 'block';
    } else {
      document.getElementById(id).style.display = 'none';
    }

  }

  option1: any;
  public SurgerySelectedItemsForBorn(data, selectedoption) {
    debugger;

    // data.checked = yesorno;
    if (selectedoption == 'Yes') {
      data.checked = 'Yes';
      this.isDisable = false;
      this.selectedSurgeryList.push(data.answer_key);
      if (data.answer_key == 'sbli_surgeryInfo_Option11') {
        this.showtextbox = true;
      }

      if (this.dynamicdiv.length == 0) {
        const qestentity = {
          'Other': ''
        };
        this.dynamicdiv.push(qestentity);
        console.log('dynamicdiv', this.dynamicdiv);
      }

    }
    else {
      data.checked = 'No';
      if (data.answer_key == 'sbli_surgeryInfo_Option11') {
        this.showtextbox = false;
      }
      const unselecteditem = this.selectedSurgeryList.indexOf(data.answer_key);
      if (unselecteditem > -1) {
        this.selectedSurgeryList.splice(unselecteditem, 1);
      }
      this.dynamicdiv = [];
    }

    // if (selectedoption == 'Yes') {
    //   this.ShowBoneYes = false;
    //   this.HideBoneYes = true;
    //   this.selectedSurgeryList.push(data);
    //   if (this.selectedSurgeryList.length > 0) {
    //     this.isDisable = false;
    //   }
    //   else {
    //     this.isDisable = true;
    //   }
    //   this.option1 = selectedoption;

    // } else {
    //   this.option1 = "";
    //   this.ShowBoneYes = true;
    //   this.HideBoneYes = false;

    //   let unselecteditem = this.selectedSurgeryList.indexOf(data);
    //   if (unselecteditem > -1) {
    //     this.selectedSurgeryList.splice(unselecteditem, 1);
    //   }
    //   if (this.selectedSurgeryList.length > 0) {
    //     this.isDisable = false;
    //   }
    //   else {
    //     this.isDisable = true;
    //   }
    // }

  }


  showTest: boolean;
  hideTest: boolean;
  ShowCollapse: boolean;

  public CheckYesOrNoForTest(data) {
    debugger;
    this.AnyTestOption12 = undefined;
    if (data == 'Yes') {
      debugger;
      this.showTest = false;
      this.hideTest = true;
      this.ShowCollapse = true;
      this.ShowNone = true;
      this.HideNone = false;
      this.istestchecked = true;

    } else {
      // if (this.selectedSurgeryList.length == 0 && (this.AnyTestOption12 == 'No' || this.AnyTestOption12 == undefined || this.AnyTestOption12 == "")) {
      //   this.isDisable = true;
      // }

      this.selectedSurgeryList = [];
      if (this.anyTestList.length == 0) {
        this.isDisable = true;
      }
      else {
        if (this.AnyTestOption12 != undefined) {
          this.isDisable = false;
        }
        else {
          this.isDisable = true;
        }

      }
      this.showTest = true;
      this.hideTest = false;
      this.ShowCollapse = false;
      this.ShowNone = true;
      this.HideNone = false;
      this.istestchecked = false;
      this.anyTestList.length = 0;
      this.showtesttextbox = false;
      this.testdynamicdiv = [];
      for (let i = 0; i < this.testoptions.length; i++) {
        this.testoptions[i].checked = 'No';
      }
    }

  }




  AnyTestOption1: any;
  public AnyTestSelectedItemsAllergy(data, selectedoption) {
    debugger;
    if (selectedoption == 'Yes') {
      data.checked = 'Yes';
      this.anyTestList.push(data.answer_key);
      this.isDisable = false;
      if (this.anyTestList.includes('sbli_anyTestInfo_Option12')) {
        this.showtesttextbox = true;
      } else {
        this.showtesttextbox = false;
      }
      if (this.testdynamicdiv.length == 0) {
        const testqestentity = {
          'Other': ''
        };
        this.testdynamicdiv.push(testqestentity);
        console.log('dynamicdiv', this.testdynamicdiv);
      }
    }
    else {
      data.checked = 'No';
      this.testdynamicdiv = [];
      const unselecteditem = this.anyTestList.indexOf(data.answer_key);
      if (unselecteditem > -1) {
        this.anyTestList.splice(unselecteditem, 1);
      }
      if (this.anyTestList.includes('sbli_anyTestInfo_Option12')) {
        this.showtesttextbox = true;
      } else {
        this.showtesttextbox = false;
      }
    }

  }


  ShowNone = true;
  HideNone: boolean;
  AnyTestOption12: any;
  public CheckYesOrNoForNoneOfAbove(data) {
    debugger;
    if (data == 'Yes') {
      this.ShowNone = false;
      this.HideNone = true;
      this.UnCheckedIconForSurgery = true;
      this.CheckedIconForSurgery = false;
      this.showTest = true;
      this.hideTest = false;
      this.SurgeryCollapse = false;
      this.ShowCollapse = false;
      this.AnyTestOption12 = data;
      this.anyTestList.length = 0;
      this.selectedSurgeryList.length = 0;
      this.issurgerychecked = false;
      this.istestchecked = false;
      this.isnonofabove = true;
      this.isDisable = false;
      for (let i = 0; i < this.testoptions.length; i++) {
        this.testoptions[i].checked = 'No';
      }
      for (let j = 0; j < this.SurgeryOptions.length; j++) {
        this.SurgeryOptions[j].checked = 'No';
      }
    } else {
      this.isnonofabove = false;
      this.ShowNone = true;
      this.HideNone = false;
      this.AnyTestOption12 = data;
      this.isDisable = true;
    }

  }


  onKey(event, index, property) {
    debugger;
    this.dynamicdiv[index][property] = event.target.value;

  }
  ontestKey(event, index, property) {
    debugger;
    this.testdynamicdiv[index][property] = event.target.value;

  }

  AddData() {
    debugger;
    const qestentity = {
      'Other': ''
    };

    this.option11 = this.dynamicdiv.push(qestentity);

  }
  AddtestData() {
    debugger;
    const testqestentity = {
      'Other': ''
    };

    this.option12 = this.testdynamicdiv.push(testqestentity);

  }


  removedata(event, index, property) {
    debugger;
    if (index > -1) {
      this.option11 = this.dynamicdiv.splice(index, 1);
    }
    if (this.dynamicdiv.length == 0) {
      const qestentity = {
        'Other': ''
      };
      this.option11 = this.dynamicdiv.push(qestentity);
    }
  }
  removetestdata(event, index, property) {
    debugger;
    if (index > -1) {
      this.option12 = this.testdynamicdiv.splice(index, 1);
    }
    if (this.testdynamicdiv.length == 0) {
      const testqestentity = {
        'Other': ''
      };
      this.option12 = this.testdynamicdiv.push(testqestentity);
    }

  }


  next() {
    debugger
    this.$gaService.event('SBLI LifeApplication Surgery', 'event', 'SBLI-LifeApplication-Surgery');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Surgery',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Surgery', 'event', 'SBLI-LifeApplication-Surgery');
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };

    if (this.AnyTestOption12 == 'No' || this.AnyTestOption12 == undefined || this.AnyTestOption12 == '') {
      delete json.SBLI.sbli_Surgery_noneOfTheAbove;
      delete json.SBLI.sbli_surgeryInfo_Option1;
      delete json.SBLI.sbli_surgeryInfo_Option2;
      delete json.SBLI.sbli_surgeryInfo_Option3;
      delete json.SBLI.sbli_surgeryInfo_Option4;
      delete json.SBLI.sbli_surgeryInfo_Option5;
      delete json.SBLI.sbli_surgeryInfo_Option6;
      delete json.SBLI.sbli_surgeryInfo_Option7;
      delete json.SBLI.sbli_surgeryInfo_Option8;
      delete json.SBLI.sbli_surgeryInfo_Option9;
      delete json.SBLI.sbli_surgeryInfo_Option10;
      delete json.SBLI.sbli_surgeryInfo_Option11;


      delete json.SBLI.sbli_anyTestInfo_Option1;
      delete json.SBLI.sbli_anyTestInfo_Option2;
      delete json.SBLI.sbli_anyTestInfo_Option3;
      delete json.SBLI.sbli_anyTestInfo_Option4;
      delete json.SBLI.sbli_anyTestInfo_Option5;
      delete json.SBLI.sbli_anyTestInfo_Option6;
      delete json.SBLI.sbli_anyTestInfo_Option7;
      delete json.SBLI.sbli_anyTestInfo_Option8;
      delete json.SBLI.sbli_anyTestInfo_Option9;
      delete json.SBLI.sbli_anyTestInfo_Option10;
      delete json.SBLI.sbli_anyTestInfo_Option11;
      delete json.SBLI.sbli_anyTestInfo_Option12;



      json.SBLI[this.questions[0].sub_questions.question_3.answer_key] = this.AnyTestOption12;
      json.SBLI[this.questions[0].sub_questions.question_1.checkbox_options[0].answer_key] = this.option1;
      json.SBLI[this.questions[0].sub_questions.question_2.checkbox_options[0].answer_key] = this.AnyTestOption1;

      for (const propName in json.SBLI) {
        if (json.SBLI[propName] === null || json.SBLI[propName] === undefined || json.SBLI[propName] === '') {
          delete json.SBLI[propName];
        }
      }



      // for (let m = 0; m < this.selectedSurgeryList.length; m++) {
      //   json.SBLI[this.selectedSurgeryList[m]] = 'Yes';
      // }

      for (let m = 0; m < this.SurgeryOptions.length; m++) {
        if (this.SurgeryOptions[m].checked == 'Yes') {
          json.SBLI[this.SurgeryOptions[m].answer_key] = 'Yes';
        }
        else {
          json.SBLI[this.SurgeryOptions[m].answer_key] = 'No';
        }

      }

      for (let o = 0; o < this.testoptions.length; o++) {
        if (this.testoptions[o].checked == 'Yes') {
          json.SBLI[this.testoptions[o].answer_key] = 'Yes';
        }
        else {
          json.SBLI[this.testoptions[o].answer_key] = 'No';
        }
      }

      // for (let m = 0; m < this.anyTestList.length; m++) {
      //   json.SBLI[this.anyTestList[m]] = 'Yes';
      // }
      console.log('Testdata', this.anyTestList);
      const selectedOtherOptions = [];

      for (let j = 0; j < this.dynamicdiv.length; j++) {
        selectedOtherOptions.push(this.dynamicdiv[j].Other);
      }

      if (this.dynamicdiv.length > 0) {
        json.SBLI[this.questions[0].sub_questions.question_1.checkbox_options[10].answer_key] = selectedOtherOptions;
      }
      const selectedtestOtherOptions = [];

      for (let j = 0; j < this.testdynamicdiv.length; j++) {
        selectedtestOtherOptions.push(this.testdynamicdiv[j].Other);
      }

      if (this.testdynamicdiv.length > 0) {
        json.SBLI[this.questions[0].sub_questions.question_2.checkbox_options[11].answer_key] = selectedtestOtherOptions;
      }

      const etty = {
        'next': 1,
        'previous': 0,
        'sbli_answer_data': json,
        'saveData': 1

      };
      this.changeQuestion.emit(etty);

    } else {
      json.SBLI[this.questions[0].sub_questions.question_3.answer_key] = this.AnyTestOption12;
      delete json.SBLI.sbli_surgeryInfo_Option1;
      delete json.SBLI.sbli_surgeryInfo_Option2;
      delete json.SBLI.sbli_surgeryInfo_Option3;
      delete json.SBLI.sbli_surgeryInfo_Option4;
      delete json.SBLI.sbli_surgeryInfo_Option5;
      delete json.SBLI.sbli_surgeryInfo_Option6;
      delete json.SBLI.sbli_surgeryInfo_Option7;
      delete json.SBLI.sbli_surgeryInfo_Option8;
      delete json.SBLI.sbli_surgeryInfo_Option9;
      delete json.SBLI.sbli_surgeryInfo_Option10;
      delete json.SBLI.sbli_surgeryInfo_Option11;


      delete json.SBLI.sbli_anyTestInfo_Option1;
      delete json.SBLI.sbli_anyTestInfo_Option2;
      delete json.SBLI.sbli_anyTestInfo_Option3;
      delete json.SBLI.sbli_anyTestInfo_Option4;
      delete json.SBLI.sbli_anyTestInfo_Option5;
      delete json.SBLI.sbli_anyTestInfo_Option6;
      delete json.SBLI.sbli_anyTestInfo_Option7;
      delete json.SBLI.sbli_anyTestInfo_Option8;
      delete json.SBLI.sbli_anyTestInfo_Option9;
      delete json.SBLI.sbli_anyTestInfo_Option10;
      delete json.SBLI.sbli_anyTestInfo_Option11;
      delete json.SBLI.sbli_anyTestInfo_Option12;

      const etty = {
        'next': 1,
        'previous': 0,
        'sbli_answer_data': json,
        'saveData': 1

      };
      this.changeQuestion.emit(etty);

    }
  }


  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }
}
