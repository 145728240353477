import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli01051040',
  templateUrl: './sbli01051040.component.html',
  styleUrls: ['./sbli01051040.component.css']
})
export class Sbli01051040Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  questions: any;
  disableBenefits: any;
  clientId: any;
  existingData: any;
  showLoader = true;
  questions2: any;
  isHospitalised: any;
  last_disablity_claim: any;
  monthdisablity: any;
  chronic_illness: any;
  hospitalisedreason: any;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    debugger
    this.clientId = localStorage.getItem('clientID');

    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli01051040')[0];
        this.questions2 = que.filter(x => x.component_id == 'app-sbli02901680')[0];
      } else {
        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli01051040')[0];
        this.questions2 = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli02901680')[0];
      }
      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_disablility_benefits != undefined) {
        this.disableBenefits = this.existingData.sbli_disablility_benefits;
      }
      if (this.existingData.sbli_hospitalized_other_reason != undefined) {
        this.isHospitalised = this.existingData.sbli_hospitalized_other_reason;
      }
      if (this.isHospitalised == 'Yes') {
        this.chronic_illness = this.existingData.sbli_chronic_illness;
      }
      if (this.chronic_illness == 'Yes') {
        this.hospitalisedreason = this.existingData.sbli_hospitalized_reason;
      }
      if (this.disableBenefits == 'Yes') {
        if (this.existingData.sbli_within_last_year == 'Yes') {
          this.last_disablity_claim = 'sbli_within_last_year';

        } else if (this.existingData.sbli_one_to_two_years_ago == 'Yes') {
          this.last_disablity_claim = 'sbli_one_to_two_years_ago';

        } else if (this.existingData.sbli_two_to_three_years_ago == 'Yes') {
          this.last_disablity_claim = 'sbli_two_to_three_years_ago';
        }
        else if (this.existingData.sbli_three_to_four_years_ago == 'Yes') {
          this.last_disablity_claim = 'sbli_three_to_four_years_ago';

        } else if (this.existingData.sbli_four_to_five_years_ago == 'Yes') {
          this.last_disablity_claim = 'sbli_four_to_five_years_ago';
        }
        this.monthdisablity = this.existingData.sbli_disablitymonth;
      }
      else {
        this.last_disablity_claim = '';
        this.monthdisablity = '';
      }

      // if (this.disableBenefits == 'Yes') {
      //   this.last_disablity_claim = this.existingData.last_disablity_claim;
      //   this.monthdisablity = this.existingData.sbli_disablitymonth;
      // }
      // if (this.isHospitalised == 'Yes') {
      //   this.chronic_illness = this.existingData.sbli_chronic_illness;
      // }
      // if (this.chronic_illness == 'Yes') {
      //   this.hospitalisedreason = this.existingData.sbli_hospitalized_reason;
      // }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    console.log('app-sbli01051040 questions', this.questions);

  }

  capturedisableBenefits(value) {
    debugger
    this.disableBenefits = value;
  }
  captureisHospitalised(value) {
    debugger
    this.isHospitalised = value;
    if (this.isHospitalised == 'No') {
      this.hospitalisedreason = '';
      this.chronic_illness = '';
    }
  }

  getlastdisablityclaim(value) {
    debugger
    this.last_disablity_claim = value;

  }

  public getchronicillness(value) {
    debugger
    this.chronic_illness = value;
    if (this.chronic_illness == 'No') {
      this.hospitalisedreason = '';
    }
  }

  next() {
    debugger
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    this.$gaService.event('SBLI LifeApplication Disability', 'event', 'SBLI-LifeApplication-Disability');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Disability',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Disability', 'event', 'SBLI-LifeApplication-Disability');
    json.SBLI[this.questions.radio_options[0].answer_key] = this.disableBenefits;
    json.SBLI[this.questions2.radio_options[0].answer_key] = this.isHospitalised;
    // if (this.disableBenefits == 'Yes') {
    //   json.SBLI[this.questions.radio_options_1[0].answer_key] = this.last_disablity_claim;
    //   json.SBLI[this.questions.input.answer_key] = this.monthdisablity;
    // }
    // if (this.isHospitalised == 'Yes') {
    //   json.SBLI[this.questions2.radio_options_1[0].answer_key] = this.chronic_illness;
    // }
    // if (this.chronic_illness == 'Yes') {
    //   json.SBLI[this.questions2.input.answer_key] = this.hospitalisedreason;
    // }
    if (this.disableBenefits == 'Yes') {
      if (this.last_disablity_claim == this.questions.radio_options_1[0].answer_key) {
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[4].answer_key] = 'No';
      }
      else if (this.last_disablity_claim == this.questions.radio_options_1[1].answer_key) {
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[4].answer_key] = 'No';
      }
      else if (this.last_disablity_claim == this.questions.radio_options_1[2].answer_key) {
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[4].answer_key] = 'No';
      }
      else if (this.last_disablity_claim == this.questions.radio_options_1[3].answer_key) {
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[4].answer_key] = 'No';
      }
      else if (this.last_disablity_claim == this.questions.radio_options_1[4].answer_key) {
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[4].answer_key] = 'Yes';
      }
      json.SBLI[this.questions.input.answer_key] = this.monthdisablity;
    }

    if (this.isHospitalised == 'Yes') {
      json.SBLI[this.questions2.radio_options_1[0].answer_key] = this.chronic_illness;
    }
    if (this.chronic_illness == 'Yes') {
      json.SBLI[this.questions2.input.answer_key] = this.hospitalisedreason;
    }
    // } else if (this.userOccupation.trim() == 'Non-working spouse' || this.userOccupation.trim() == 'A non-working spouse') {
    //   json.SBLI[this.questions.radio_options_1[1].answer_key] = 'Yes';
    //   json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
    //   json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
    //   json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';
    //   json.SBLI[this.questions.input.answer_key] = this.householdincome;

    // } else if (this.userOccupation.trim() == 'Full-time Student' || this.userOccupation.trim() == 'A full-time student') {
    //   json.SBLI[this.questions.radio_options_1[2].answer_key] = 'Yes';
    //   json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
    //   json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
    //   json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';

    //   if (this.userLookingFor.trim() == 'Bachelor’s Degree' || this.userLookingFor.trim() == 'Bachelor’s or Associates Degree') {
    //     json.SBLI[this.questions.radio_options_2[0].answer_key] = 'Yes';
    //     json.SBLI[this.questions.radio_options_2[1].answer_key] = 'No';
    //     json.SBLI[this.questions.radio_options_2[2].answer_key] = 'No';
    //     json.SBLI[this.questions.radio_options_2[3].answer_key] = 'No';

    //   } else if (this.userLookingFor.trim() == 'Master’s Degree' || this.userLookingFor.trim() == 'Master\'s degree') {
    //     json.SBLI[this.questions.radio_options_2[1].answer_key] = 'Yes';
    //     json.SBLI[this.questions.radio_options_2[0].answer_key] = 'No';
    //     json.SBLI[this.questions.radio_options_2[2].answer_key] = 'No';
    //     json.SBLI[this.questions.radio_options_2[3].answer_key] = 'No';

    //   } else if (this.userLookingFor.trim() == 'Doctorate' || this.userLookingFor.trim() == 'Doctorate') {
    //     json.SBLI[this.questions.radio_options_2[2].answer_key] = 'Yes';
    //     json.SBLI[this.questions.radio_options_2[0].answer_key] = 'No';
    //     json.SBLI[this.questions.radio_options_2[1].answer_key] = 'No';
    //     json.SBLI[this.questions.radio_options_2[3].answer_key] = 'No';

    //   } else if (this.userLookingFor.trim() == 'Other Qualification' || this.userLookingFor.trim() == 'Other qualification') {
    //     json.SBLI[this.questions.radio_options_2[3].answer_key] = 'Yes';
    //     json.SBLI[this.questions.radio_options_2[0].answer_key] = 'No';
    //     json.SBLI[this.questions.radio_options_2[1].answer_key] = 'No';
    //     json.SBLI[this.questions.radio_options_2[2].answer_key] = 'No';
    //   }
    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
  }



  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

}
