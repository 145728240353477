import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-sbli6pt58',
  templateUrl: './sbli6pt58.component.html',
  styleUrls: ['./sbli6pt58.component.css']
})
export class Sbli6pt58Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  paymentType: any;
  isDisable: boolean;
  showLoader: boolean = true;
  clientId: any;
  existingData: any;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger
    this.isDisable = true;
    this.showLoader = false;
    this.clientId = localStorage.getItem('clientID');

    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.SBLI;
      if (this.existingData == undefined) {
        this.existingData = [];
      } else {
        this.paymentType = this.existingData.sbli_paymentDetails.sbli_paymentType;

        if (this.paymentType != undefined) {
          this.isDisable = false;
        } else {
          this.isDisable = true;
        }
      }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
  }

  payment(event) {
    this.paymentType = event;
    this.isDisable = false
  }

  next() {
    debugger
    var json = {
      'id': this.clientId,
      SBLI: this.existingData
    }

    var paymentTypes = {
      sbli_paymentType: this.paymentType
    }

    json.SBLI["sbli_paymentDetails"] = paymentTypes;

    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }

}
