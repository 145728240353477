import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

declare var require: any;
const pc = require('bit-uibl-data-driver');
const businessLogicService2 = new pc.BusinessLogicService2();
@Injectable({
  providedIn: 'root'
})
export class ProfileDataHandlerService {

  constructor() {

  }

  public GetPolicyData() {
    let data = pc.getMyPolicies();
    return data;
  }
  public getSetCityZipStateFromURL(displayAddress) {
    //TODO: Add logic to get te address from the localStorage
    // If not parse from the URL
    // Palo%20Alto-CA-94301-USA
    let cleanedoutputJson;
    displayAddress = displayAddress.replace('-USA', '');
    displayAddress = displayAddress.replace('-US', '');
    displayAddress = displayAddress.replace(/-/g, ' ');
    displayAddress = displayAddress.replace(/%20/g, ' ');
    displayAddress = displayAddress.replace(/  /g, ' ');
    displayAddress = displayAddress.trim();
    let splitAddress = displayAddress.split(" ");
    // Palo Alto CA 94301
    let insuredZipcode = splitAddress[(splitAddress.length - 1)];
    splitAddress.pop();//remove zip
    let insuredState = splitAddress[(splitAddress.length - 1)];
    splitAddress.pop();// remove state code
    let city = splitAddress.join(' ');
    cleanedoutputJson = {
      displayAddress: displayAddress, insuredState: insuredState, insuredZipcode: insuredZipcode
      , city: city
    };
    return cleanedoutputJson;
  }
  public async getSetInsuredInfoFromZip(zipCode) {
    // Logic: For input zip code get details
    //set them in insuredInfo object and saveUpdateClient Data
    let addressJson = { 'targetZip': zipCode };
    console.log('Input:insuredInfo:addressJson: ', addressJson);
    let addressDetails = await businessLogicService2.getAddressDetails(addressJson, "all");
    // {\"id\":\"6239069\",\"dget1\":\"94588\",\"dput2\":\"CA\",\"dget3\":\"Alameda County\",\"dput5\":\"Pleasanton\"}
    return { insuredZipcode: addressDetails.dget1, insuredCity: addressDetails.dput5, insuredState: addressDetails.dput2 };
  }
}
