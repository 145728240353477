import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-quote-widget',
  templateUrl: './quote-widget.component.html',
  styleUrls: ['./quote-widget.component.scss']
})
export class QuoteWidgetComponent implements OnInit {

  constructor() {
    //
   }

   @Input() quoteValue: number;

  ngOnInit(): void {
    //
  }
}
