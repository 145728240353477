import { Component, OnInit ,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-sbli02851175',
  templateUrl: './sbli02851175.component.html',
  styleUrls: ['./sbli02851175.component.css']
})
export class Sbli02851175Component implements OnInit {

  constructor() { }
  @Output() changeQuestion = new EventEmitter();
  ngOnInit(): void {
  }
  next() {
    debugger
    let etty = {
      "next": 1,
      "previous": 0,
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
