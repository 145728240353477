import { Component } from '@angular/core';
import { Renderer2, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment'
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-back9-quote',
  templateUrl: './back9-quote.component.html',
  styleUrls: ['./back9-quote.component.css']
})
export class Back9QuoteComponent implements OnInit {
  firstname: any;
  lastname: any;
  showLoader: boolean;
  httpParamsGlobal: any;
  state: any;
  gender: any;
  health: any;
  smoker: any;
  height: any;
  weight: any;
  dob: any;
  email: any;
  phone: any;
  zip: any;
  birth_state_or_country: any;
  selectedtype: any;
  producttypes: any;
  productid: any;
  mode: any;
  termduration: any;
  private unsubscriber: Subject<void> = new Subject<void>();
  coverage: any;
  showNumber: number;
  clientid: any;
  constructor(private _renderer2: Renderer2, private pc: ProcessCacheService,
    @Inject(DOCUMENT) private _document: Document, private activatedroute: ActivatedRoute, private http: HttpClient
  ) { }

  ngOnInit(): void {
    debugger
    this.pc.logPageLandingtoBO({ page_name: 'back9_applicationpage' });
    this.showLoader = true
    this.showNumber = 0;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Application'
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.http.get(`${environment.Nest_API_URL}/back9`).subscribe(key => {
      debugger
      let strip_key = key['back9key'];
      history.pushState(null, '');
      fromEvent(window, 'popstate').pipe(
        takeUntil(this.unsubscriber)
      ).subscribe((_) => {
        history.pushState(null, '');
        alert(`You can't make changes or go back at this time.`);
      });
      let url;
      if (window && window.location && window.location.href) {
        url = window.location.href;
      }
      this.pc.lifesummary({ 'bridgeover_url': url });
      if (url.includes('?')) {
        this.httpParamsGlobal = new HttpParams({ fromString: url.split('?')[1] });
        const httpParams = this.httpParamsGlobal;
        this.firstname = httpParams.get('firstname');
        this.lastname = httpParams.get('lastname');
        this.state = httpParams.get('state');
        this.gender = httpParams.get('gender');
        this.health = httpParams.get('health');
        this.smoker = httpParams.get('smoker');
        this.height = httpParams.get('height');;
        this.weight = httpParams.get('weight');
        this.dob = httpParams.get('dob');
        this.email = localStorage.getItem('bubble-email');
        this.phone = httpParams.get('phone');
        this.birth_state_or_country = httpParams.get('birth_state_or_country');
        this.selectedtype = httpParams.get('selectedtype');
        this.producttypes = httpParams.get('producttypes');
        this.producttypes = `${this.producttypes}`
        this.productid = httpParams.get('productid');
        this.mode = httpParams.get('mode');
        this.termduration = httpParams.get('term_duration');
        this.zip = httpParams.get('zip');
        this.coverage = httpParams.get('coverage');
        this.clientid = httpParams.get('clientid');
      }
      if (this.dob.includes('/')) {
        const date_birth = this.dob.split('/');
        this.dob = date_birth[2] + '-' + date_birth[0] + '-' + date_birth[1];
      }
      let metadata = { "Agentid": this.clientid, "Campaign": "Quote-Life" };

      //https://cdn.quoteandapply.io/widget.js?prefill&first_name=John&last_name=Doe&test=true
      const div = document.createElement('div');
      div.setAttribute("id", "container-id");
      const div1 = document.createElement('div');
      let script = this._renderer2.createElement('script');
      script.setAttribute("id", "strife");
      script.src = `https://cdn.quoteandapply.io/widget.js?prefill&first_name=${this.firstname}&last_name=${this.lastname}&state=${this.state}&gender=${this.gender === 'M' ? 'Male' : 'Female'}&health=${this.health}&smoker=${this.smoker}&height=${this.height}&weight=${this.weight}&birthdate=${this.dob}&email=${this.email}&phone=${this.phone}&zip=${this.zip}&birth_state_or_country=${this.birth_state_or_country}&us_citizen=yes&step=quote&selected_type=${this.selectedtype}&product_types=${this.producttypes}&product_id=${this.productid}&mode=${this.mode}&term_duration=${this.termduration}&death_benefit=${this.coverage}&back9key=${strip_key}&metadata=${JSON.stringify(metadata)}`;
      //let test="&zip=${this.zip}&birth_state_or_country=${this.birth_state_or_country}&us_citizen=yes&step=quote&selected_type=${this.selectedtype}&product_types=${this.producttypes}&product_id=${this.productid}&mode=${this.mode}&&product_category=${this.productcategory}"
      script.setAttribute("data-strife-key", strip_key);
      script.setAttribute("data-strife-container-id", "container-id");
      script.async = true;
      script.defer = true;
      console.log('script.src', script.src);
      this._renderer2.appendChild(this._document.body, script);
      setTimeout(() => {
        this.showLoader = false
      }, 5000);
    })



  }
  ngOnDestroy(): void {
    this.unsubscriber.next();
    this.unsubscriber.complete();
  }

  // ngAfterViewInit(): void {
  //   debugger
  //   document.body.addEventListener('mouseleave', (e) => { this.exitPopup(e); });
  // }

  exitPopup(e) {
    if (this.showNumber == 0 && e.clientY <= -1) {
      document.getElementById('exiterrorModalwns').style.display = 'block';
    }
  }

  closePopup() {
    debugger;
    document.getElementById('exiterrorModalwns').style.display = 'none';
    this.showNumber = 1;
  }
}
