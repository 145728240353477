import { formatDate } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ValidationService } from '../../shared/validation.service';
@Component({
  selector: 'app-customize-life',
  templateUrl: './customize-life.component.html',
  styleUrls: ['./customize-life.component.css']
})
export class CustomizeLifeComponent implements OnInit {

  @Output() invokeLifeCarrierV2 = new EventEmitter();

  coverageAmount: any;
  termLength: any;
  gender: any;
  dateOfBirth: any;
  weight: any;
  heightFt: any;
  heightIn: any;
  smoker: any;
  age: any;
  height: any;
  ShowYesChecked: any;
  ShowNoChecked: any;
  usageDuration: any;
  coveragetodisaply: any;
  selectedCardData: any;
  showlabel: any;
  dateOfBirthDisplay: any;
  curDate: any;
  minDate: any;
  maxDate: any;
  termError: string;
  message: any;
  is_valid: boolean;

  constructor(private validationService: ValidationService) { }

  ngOnInit(): void {
    debugger;
    this.message = '';
    // this.gender = localStorage.getItem('bubble-gender');
    // this.gender = this.gender == '1' ? "Male": "Female";
    // this.smoker = localStorage.getItem('bubble-tobacco');
    // this.smoker = this.smoker == '1' ? "1": "5";
    // this.dateOfBirth = localStorage.getItem('bubble-dob');
    // this.age = localStorage.getItem('bubble-age');
    // this.height = localStorage.getItem('bubble-height');
    // this.heightFt = localStorage.getItem('bubble-heightFt');
    // this.heightIn = localStorage.getItem('bubble-heightIn');
    // this.weight = localStorage.getItem('bubble-weight');
    // this.termLength = localStorage.getItem('bubble-termLength');
    // this.coverageAmount = localStorage.getItem('bubble-coverage');
    debugger;
    this.curDate = new Date();
    const yearm = Number(this.curDate.getFullYear()) - 55;
    let monthm = '' + (this.curDate.getMonth() + 1);
    let daym = '' + this.curDate.getDate();
    if (monthm.length < 2)
      monthm = '0' + monthm;
    if (daym.length < 2)
      daym = '0' + daym;

    this.minDate = new Date(monthm + '/' + daym + '/' + yearm);
    debugger;

    const year = Number(this.curDate.getFullYear());
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.maxDate = new Date(month + '/' + day + '/' + year);

    debugger;
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    const myDate = new Date(this.minDate);
    this.minDate = formatDate(myDate, format, locale);
    const myeDate = new Date(this.maxDate);
    this.maxDate = formatDate(myeDate, format, locale);

    this.gender = localStorage.getItem('bubble-gender');
    console.log('on modal open : ', this.gender);

    debugger;
    // this.gender = this.gender == '1' ? "Male" : "Female";
    this.smoker = localStorage.getItem('bubble-tobacco');
    //this.smoker = this.smoker == '1' ? "1" : "5";
    if (this.smoker != '5') {

      this.ShowYesChecked = true;
      this.ShowNoChecked = false;

      this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');
      this.smoker = '0';
    }
    else {
      this.ShowYesChecked = false;
      this.ShowNoChecked = true;
      this.usageDuration = '1';
    }
    this.smoker = Number(this.smoker);
    debugger;

    this.dateOfBirth = localStorage.getItem('bubble-dob');
    this.age = localStorage.getItem('bubble-age');
    const formats = 'MM/dd/yyyy';
    const locales = 'en-US';
    const myDates = new Date(localStorage.getItem('bubble-dob'));
    this.dateOfBirthDisplay = formatDate(myDates, formats, locales);
    this.checkDateNew();
    this.height = localStorage.getItem('bubble-height');
    this.heightFt = localStorage.getItem('bubble-heightFt');
    this.heightIn = localStorage.getItem('bubble-heightIn');
    this.weight = localStorage.getItem('bubble-weight');
    this.termLength = localStorage.getItem('bubble-termLength');
    this.coverageAmount = localStorage.getItem('bubble-coverage');
    this.calculateTerm();
    const formatednumber = this.coverageAmount;
    this.coveragetodisaply = formatednumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (this.coverageAmount > 10000000) {
      this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
    } else if (this.coverageAmount < 250000) {
      this.showlabel = 'Coverage amount cannot be less than $250,000';
    } else {
      this.showlabel = '';
    }

    console.log('this.termlength', this.termLength);

    if (Number(this.termLength) < 10) {
      this.termLength = 10;
    }
    else if (Number(this.termLength) >= 10 && Number(this.termLength) < 15) {
      this.termLength = 10;
    }
    else if (Number(this.termLength) >= 15 && Number(this.termLength) < 20) {
      this.termLength = 15;
    }
    else if (Number(this.termLength) >= 20) {
      this.termLength = 20;
    }
    else {
      this.termLength = 20;
    }

  }

  formatCoverage() {
    debugger;
    this.coveragetodisaply = this.coveragetodisaply.replace(/,/g, '');
    this.coverageAmount = Number(this.coveragetodisaply);
    // localStorage.setItem('bubble-coverage', this.coverageAmount);
    const ctd = this.coveragetodisaply;
    this.coveragetodisaply = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (this.coverageAmount > 10000000) {
      this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
    } else if (this.coverageAmount < 25000) {
      this.showlabel = 'Coverage amount cannot be less than $250,000';
    } else {
      this.showlabel = '';
    }
  }

  public genderClick(id) {
    debugger;
    this.gender = id;
    console.log('genderClick', this.gender);
  }

  verifyFt() {
    // if (Number(this.heightFt)>6) {
    //   this.heightFt = '6';
    // }
    // if (Number(this.heightFt)<4) {
    //   this.heightFt = '4';
    // }
  }

  verifyInch() {
    debugger;
    if (Number(this.heightIn) > 11) {
      this.heightIn = '11';
    }
    if (Number(this.heightIn) < 0) {
      this.heightIn = '1';
    }
  }

  verifyweight() {
    if (Number(this.weight) > 999) {
      this.heightIn = '150';
    }
    if (Number(this.heightIn) == 0) {
      this.heightIn = '150';
    }
    if (Number(this.heightIn) == null || Number(this.heightIn) == undefined) {
      this.heightIn = '150';
    }
  }

  getTermlength(even) {
    this.termLength = even;
    this.calculateTerm();
  }
  public getUsageDuration(id) {
    this.usageDuration = id;
  }



  inputdatechange() {
    debugger;
    const K = this.dateOfBirthDisplay.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.message = 'Invalid Date';
        this.is_valid = false;
      }
      else {
        this.message = '';
        this.is_valid = true;
      }
      this.dateOfBirthDisplay = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.message = 'Invalid Date';
          this.is_valid = false;
        }
        else if (A <= 12) {
          this.dateOfBirthDisplay = A + '/' + B + '/';
          this.message = '';
          this.is_valid = true;
        }
        else {
          this.message = 'Invalid Date';
          this.is_valid = false;

        }


      }
      else {
        this.dateOfBirthDisplay = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.dateOfBirthDisplay = A + '/' + B + '/' + C;
    }
    else {
      this.dateOfBirthDisplay = this.dateOfBirthDisplay;
    }

    if (this.dateOfBirthDisplay.length >= 10 && this.message != 'Invalid Date') {
      this.is_valid = true;
      this.checkDateNew();
    }
    else {
      this.is_valid = false;
    }
  }

  checkDateNew() {
    debugger;
    const dob = new Date(this.dateOfBirthDisplay);
    let year = Number(dob.getFullYear());
    let month = '' + (dob.getMonth() + 1);
    let day = '' + dob.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (month == 'NaN' || year.toString() == 'Nan' || month == 'NaN') {
      this.message = 'Invalid Date';
      this.is_valid = false;
    }
    else {
      this.dateOfBirth = new Date(month + '/' + day + '/' + year);

      console.log('DOB entered: ', dob);
      const checkAge = this.validationService.validate_dob('common_age', dob);
      console.log('DOB entered: checkAge', checkAge);
      this.message = checkAge.message;
      this.is_valid = checkAge.is_valid;
      const d = new Date(this.dateOfBirth);
      const cur_d = new Date();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();

      const ageYear = Number(curYear) - Number(year);
      if (ageYear >= 18 && ageYear <= 55) {
        this.is_valid = true;
      } else {
        this.is_valid = false;
      }
      //this.calculateTerm(1);
    }




  }

  async calculateTerm(type = 0) {
    debugger;
    const todayDate = new Date();
    const urlDob = new Date(this.dateOfBirth);
    this.age = todayDate.getFullYear() - urlDob.getFullYear();
    const calculatedTerm = 65 - Number(this.age);
    this.termError = '';

    if (type == 1) {
      this.termLength = Number(calculatedTerm) > 20 ? 20 : Number(calculatedTerm);
      this.termLength = Number(this.termLength) >= 15 && Number(this.termLength) < 20 ? '15' : Number(this.termLength);
      this.termLength = Number(this.termLength) >= 10 && Number(this.termLength) < 15 ? '10' : Number(this.termLength);
      this.termLength = Number(this.termLength) < 10 ? 10 : Number(this.termLength);
    }
    else {
      if (Number(this.termLength) <= Number(calculatedTerm)) {
        this.termLength = Number(calculatedTerm) > 20 ? 20 : Number(calculatedTerm);
        this.termLength = Number(this.termLength) >= 15 && Number(this.termLength) < 20 ? '15' : Number(this.termLength);
        this.termLength = Number(this.termLength) >= 10 && Number(this.termLength) < 15 ? '10' : Number(this.termLength);
        this.termLength = Number(this.termLength) < 10 ? 10 : Number(this.termLength);
      }
      else {
        // this.termLength = Number(calculatedTerm) > 20 ? 20 : Number(calculatedTerm);
        // this.termLength = Number(this.termLength) >= 15 && Number(this.termLength) < 20 ? "15" : Number(this.termLength);
        // this.termLength = Number(this.termLength) >= 10 && Number(this.termLength) < 15 ? "10" : Number(this.termLength);
        // this.termLength = Number(this.termLength) < 10 ? 10 : Number(this.termLength);
        this.termError = 'Based on the Date of Birth provided the selected term cannot be applied.';
      }
    }

    return true;
  }

  public smokerClick(id) {
    debugger;
    this.smoker = id;
    this.smoker = Number(this.smoker);
  }

  saveCustomize() {
    this.coveragetodisaply = this.coveragetodisaply.replace(/,/g, '').replace('$', '');
    this.coverageAmount = this.coveragetodisaply;
    localStorage.setItem('bubble-coverage', this.coverageAmount);
    const ctd = this.coveragetodisaply;
    this.coveragetodisaply = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const today = new Date();
    const birthDate = new Date(this.dateOfBirth);
    this.age = today.getFullYear() - birthDate.getFullYear();

    localStorage.setItem('bubble-gender', this.gender);
    localStorage.setItem('bubble-tobacco', this.smoker);
    localStorage.setItem('bubble-dob', this.dateOfBirth);
    localStorage.setItem('bubble-age', this.age);
    localStorage.setItem('bubble-height', this.height);
    localStorage.setItem('bubble-heightFt', this.heightFt);
    localStorage.setItem('bubble-heightIn', this.heightIn);
    localStorage.setItem('bubble-weight', this.weight);
    localStorage.setItem('bubble-termLength', this.termLength);
    localStorage.setItem('bubble-coverage', this.coverageAmount);
    if (this.smoker == '0') {
      localStorage.setItem('bubble-tobacco', this.usageDuration);
      localStorage.setItem('bubble-tobaccoUsageDuration', this.usageDuration);
    }
    else {
      localStorage.setItem('bubble-tobacco', this.smoker);
    }
    this.invokeLifeCarrierV2.emit('1');
  }

  closeModal() {
    this.invokeLifeCarrierV2.emit('0');
  }

}
