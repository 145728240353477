import { Component, OnDestroy, OnInit,EventEmitter,Output,  ViewChild } from '@angular/core';
import { formatDate } from "@angular/common";

@Component({
  selector: 'app-question3',
  templateUrl: './question3.component.html',
  styleUrls: ['./question3.component.css']
})
export class Question3Component implements OnInit {

  @Output() nextquestion3 = new EventEmitter();
  tyod: any;
  dob: string;
  fullName: string;

  constructor() { }

  ngOnInit(): void {
    // debugger
    this.fullName = localStorage.getItem('bubble-fullName');
    let date = new Date();
    date.setFullYear(date.getFullYear() - 20);
    
    this.tyod = date;

    const format = 'yyyy-MM-dd';
    const myDate = this.tyod;
    const locale = 'en-US';
    this.tyod = formatDate(myDate, format, locale);
    console.log("min date :", this.tyod);

    if (localStorage.getItem('bubble-dob') !== null) {
      this.dob = localStorage.getItem('bubble-dob');
    }
    if (this.dob==undefined||this.dob==null||this.dob=="undefined") {
      this.dob = this.tyod;
    }
  }

  next() {
    localStorage.setItem('bubble-dob', this.dob);
    let etty = {
      "questionNo": "3",
      "dob": this.dob,
      "nextprev": 1
    }
    this.nextquestion3.emit(etty);
  }

  previous() {
    let etty = {
      "questionNo": "3",
      "dob": this.dob,
      "nextprev": 0
    }
    this.nextquestion3.emit(etty);
  }

}
