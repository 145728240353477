import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from "../../../components/lifescore-error-alert/lifescore-error-alert.component";
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-wnscongrats',
  templateUrl: './wnscongrats.component.html',
  styleUrls: ['./wnscongrats.component.css']
})
export class WnscongratsComponent implements OnInit {

  @Output() changeQuestion = new EventEmitter();
  uniqueId: any;
  clientId: any;
  policyNumber: any;
  emailId: string;
  existingData: any;
  interest_type: any;
  constructor(private pc: ProcessCacheService,
    private modalService: NgbModal,
    private activatedroute: ActivatedRoute, private router: Router) { }
  showLoader: boolean = true;
  ngOnInit(): void {
    debugger
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.interest_type = params['insuranceType'];
    }
    );
    this.showLoader = false;
    this.showLoader = true;
    this.emailId = localStorage.getItem("bubble-email");

    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.SBLI;
      let pn = this.existingData["WNS_PolicyNumber"];
      if (pn == null || pn == undefined || pn == '') {
        let quickquote = new pc.Quotes(this.uniqueId);


        // quickquote.sbliupdateSESSION(200).then(response => {
        //   debugger
        //   console.log("sbliupdateSESSION res", response);
        quickquote.wnsupdateSESSION(200).then(response => {
          debugger
          console.log("wnsgetPOLICYNUMBER res", response);
          if (response.sbliError) {
            if (response.msgText == undefined) {
              response.msgText = 'There seems to be an issue while processing your application. Please reach out to our licensed agents who will help you complete your purchase.'
            }
            this.openModal(response.msgText, "Sorry!");
          }
          else {
            this.pc.lifesummary({ 'policy_number': response.policyNumber,'policy_issuance' :'yes'});
            this.policyNumber = response.policyNumber;

            localStorage.setItem("WNS_PolicyNumber", this.policyNumber);
            var json = {
              'id': this.clientId,
              SBLI: this.existingData,
              "WNS_PolicyNumber": this.policyNumber
            }
            json.SBLI["WNS_PolicyNumber"] = this.policyNumber;
            json["WNS_PolicyNumber"] = this.policyNumber;
            this.pc.saveUpdateClientData(json).then(data => {
              this.showLoader = false;
            }).catch(error => {
              console.log(error);
            });
            const hubSpotData = {
              email: localStorage.getItem('bubble-email'),
              life_policy_number: this.policyNumber,
              application_stage: 'Application complete'
            };
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
            /////// id mapping
            let customerIdLocal = localStorage.getItem('customer_id');
            const idMappingJson = {
              client_id: this.clientId,
              customer_id: customerIdLocal,
              email_id: this.emailId,
              status: this.interest_type,
              interest_type: this.interest_type,
              sbli_policy_id: this.policyNumber
            };
            this.pc.createIdMapping(idMappingJson);
            console.log("Lead:createIdMapping:idMappingJson:", idMappingJson);
            /////// id mapping ends


            quickquote.wnsupdateSESSION(100).then(response => {
              debugger
              console.log("wnsupdateSESSION res", response);
            })
              .catch(error => {
                console.log("wnsupdateSESSION Error:", error);
              });


            let updateJson = {
              appCompletedStatus: true
            }
            this.checkforResumeInit(updateJson, "update");
          }
        })
          .catch(error => {
            console.log("wnsgetPOLICYNUMBER Error:", error);
            this.showLoader = false;
          });
        // })
        // .catch(error => {
        //   console.log("sbliupdateSESSION Error:", error);
        // });





      }
      else {
        this.policyNumber = pn;
        this.showLoader = false;
      }

    }).catch(error => {
      console.log(error);
    });
  }



  openModal(msg, title) {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    let data = {
      head: 'Please Note',
      title: title,
      message: msg,
      image: 'assets/images/images/education2.png'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log("Modal Close Output: ", result);
    }, (reason) => {
      console.log("Modal Close Reason: ", reason);
    });
  }

  // next() {
  //   let etty = {
  //     "next": 1,
  //     "previous": 0,
  //   };
  //   this.changeQuestion.emit(etty);
  // }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }

  async checkforResumeInit(inputJson, type) {
    let clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }

}
