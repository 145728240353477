import { AfterViewInit, Component, OnInit, Output } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from './../../shared/toast.service';
import { formatAmount, formatAmountCommaDollar } from './../../shared/UtilityFunctions.js';
import { TrackerService } from 'src/app/shared/tracker.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
declare let require: any;
declare let window: any;
const pc = require('bit-uibl-data-driver');

window._loq = window._loq || [];

interface SelectedData {
  'carrier_name': string
  'quote_amount': string
  'carrier_icon': string
}

interface SelectedCardData {
  head?: SelectedData,
  [key: string]: any
}

@Component({
  selector: 'app-select-life-carrier-v2',
  templateUrl: './select-life-carrier-v2.component.html',
  styleUrls: ['./select-life-carrier-v2.component.css']
})
export class SelectLifeCarrierV2Component implements OnInit, AfterViewInit {
  heightFT: any;
  heightIN: any;
  usageDuration: any;
  smoker: any;
  showNumber: any;
  time: any;
  uniqueId: any;
  clientId: any;
  display: any;
  displayAddress: any;
  gender: any;
  genderString: string;
  age: any;
  lifeScoreData: any;
  allScores: any;
  insurancetype: any;
  maxCoverageAmont: any;
  miniumTermLength: any;
  fullName: string;
  emailId: string;
  tobacco: string;
  termLength: any;
  dob: string;
  height: string;
  weight: string;
  coverage: string;
  commonAddress: string;
  city: string;
  state: any;
  zipcode: any;
  quickQuoteData: any;
  carrier1: any;
  carrier2: any;
  carrier3: any;
  carrierSelected = 'pac-life';
  loader: boolean;
  selectedCardData: any;
  selectedCard: number;
  showselectedcarrier: number;
  imgwid: any;
  calculatedTerm: number;
  mortgage: string;
  kids: string;
  annualIncome: string;
  riskClass: string;
  termLengthToDisplay: string;
  coveragetodisaply: any;
  coverageAmountText: string;
  disclaimerScript1: string;
  showSBLI: number;
  url: any;
  addressToShow: any;
  selectedCarrier: number;
  showModal = false;
  isMobileResolution: boolean;
  isDescktopResolution: boolean;
  carrierName: any;
  carrierAmount: any;
  homeselectedCard: number;
  pageToOpen = '';
  showAssumptions = false;
  homeURL: string;
  backButton: string;
  dropdownCoverage: any;
  recaluclateButton: boolean;
  dropdowvalues: any;
  disableCarrierOne: boolean;
  disableCarrierTwo: boolean;
  covergaeError: string;
  sbliUptoAge: number;
  showQuoteAmount: any;
  content = [];
  quouteAmount1: any;
  quouteAmount2: any;
  headreLable: any;
  showCustomizeLife: boolean;
  quotesZero: boolean;
  carrierDetailsToShow: any;
  detailsModal: boolean;
  spinner: boolean;
  NewDate;
  Date;
  dateError: any;
  counter = 0;
  @Output() loaderText = [
    {
      'title1': 'Calculating coverage amount',
      'Subtitle1': 'Add more at a later time if you need to',
      'title2': 'Calculating term',
      'Subtitle2': 'Extend it or reduce as you see needed',
      'title3': 'Estimating premium',
      'Subtitle3': 'Lock it in for decades'
    }];
  termError: string;
  formatCoverage: any;
  refreshbuttonclicked: boolean;
  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private $gaService: GoogleAnalyticsService,
    private activatedroute: ActivatedRoute,
    private modalService: NgbModal, private tracker: TrackerService,
    public toastService: ToastService, public router: Router
    , private utilityService: UtilityFunctionsService) { }


  customOptions: OwlOptions = {
    loop: false,
    dots: true,
    nav: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    lazyLoad: false,
    margin: 0,
    autoWidth: true,
    //smartSpeed: 1200,
    autoplay: false,
    //autoplayTimeout: 1000,
    autoplayHoverPause: false,
    //navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };



  ngOnInit(): void {
    debugger
    this.spinner = true;
    this.display = 'none';
    this.showNumber = 0;
    this.refreshbuttonclicked = false;
    this.loader = true;
    this.homeURL = this.pc.envCred.url;
    this.covergaeError = '';
    this.quotesZero = false;
    document.getElementById('index_title_id').innerText = 'Life Insurance powered by Bubble';
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      this.addressToShow = this.displayAddress.replace(/-/g, ' ');
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
    }
    );
    this.backButton = localStorage.getItem('lifeRecoBackButton');
    this.pc.hubSpotSyncAfterEmailFetch({
      email: localStorage.getItem('bubble-email'),
      user_landed_reco_page: 'Yes',
      application_stage: 'Lead'
    });


    if (window.innerWidth < 600) {
      this.isMobileResolution = true;
      this.isDescktopResolution = false;

    } else {
      this.isMobileResolution = false;
      this.isDescktopResolution = true;

    }

    this.url = window.location.href;

    // this.loader = true;
    if (localStorage.getItem('selectedHomeCarrier') !== null) {
      const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
      this.carrierName = selectedHome.head.carrier_name;
      this.carrierAmount = selectedHome.head.quote_amount;
    }
    this.homeselectedCard = 1;
    this.imgwid = '60px';
    this.showSBLI = 0;

    this.age = localStorage.getItem('bubble-age');
    this.mortgage = localStorage.getItem('bubble-mortgage');
    this.kids = localStorage.getItem('bubble-kids');
    this.annualIncome = localStorage.getItem('bubble-annualIncome');
    this.dob = localStorage.getItem('bubble-dob');
    this.mortgage = this.mortgage.split(',').join('');
    this.annualIncome = this.annualIncome.split(',').join('');
    this.age = this.age.split(',').join('');
    this.kids = this.kids.split(',').join('');

    this.pc.getCoverageRecommendation(this.mortgage, this.annualIncome, this.age, this.kids).then(response => {
      const recomendedCoverage = response.data.output;

      console.log('mortgage :', this.mortgage, '#this.annualIncome: ', this.annualIncome, '#this.age:', this.age, '#this.kids: ', this.kids);
      console.log('recomendedCoverage :', recomendedCoverage);
      if (localStorage.getItem('bubble-coverage') == null || localStorage.getItem('bubble-coverage') == undefined || localStorage.getItem('bubble-coverage') == '') {
        localStorage.setItem('bubble-coverage', recomendedCoverage);
      }
      this.age = localStorage.getItem('bubble-age');
      this.calculatedTerm = (65 - Number(this.age));
      this.termLength = localStorage.getItem('bubble-termLength') ? localStorage.getItem('bubble-termLength') : this.calculatedTerm.toString();
      if (this.termLength >= 10 && this.termLength < 15) {
        this.calculateTerm(1, this.termLength);
      }
      else if (this.termLength >= 15 && this.termLength < 20) {
        this.calculateTerm(1, this.termLength);
      }
      else if (this.termLength >= 20) {
        this.calculateTerm(1, this.termLength);
      }
      this.getQuote();
    })
      .catch(error => {
        console.log('getCoverageRecommendation', error);
      });


    if (this.insurancetype == 'life') {
      this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 6 });
    } else {
      this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 9 });
    }
    this.recaluclateButton = false;


    this.dropdowvalues = [
      {
        'ID': 1,
        'value': '25,000'
      },
      {
        'ID': 2,
        'value': '50,000'
      },
      {
        'ID': 3,
        'value': '100,000'
      },
      {
        'ID': 4,
        'value': '250,000'
      },
      {
        'ID': 5,
        'value': '500,000'
      },
      {
        'ID': 6,
        'value': '1,000,000'
      },
      {
        'ID': 7,
        'value': '2,500,000'
      },
      {
        'ID': 8,
        'value': '5,000,000'
      },
      {
        'ID': 9,
        'value': '10,000,000'
      },
    ];


    this.content = [{
      'modalLabel': 'Policy length',
      'modalText': 'The length of time during which an insurance policy remains in force.'
    },
    {
      'modalLabel': 'Coverage Range',
      'modalText': 'The amount of risk, liability, or potential loss that can be protected by insurance.'
    },
    {
      'modalLabel': 'Free look period',
      'modalText': 'The required time period in which a new life insurance policy owner can terminate the policy without any penalties.'
    },
    {
      'modalLabel': 'Add Coverage Without Underwriting',
      'modalText': 'When you purchase a Next Term Life policy, your health rating is locked in. This allows you to easily increase coverage for Qualifying Life Events, up to your personal Maximum Face Amount.**'
    },
    {
      'modalLabel': 'Medical Checkup Required',
      'modalText': 'If needed, the carrier may schedule a medical exam and/or request additional information.'
    },
    {
      'modalLabel': 'Policy Conversion Rider Sbli',
      'modalText': 'At the end of this level period, the policy can be continued to age 85 at annually increasing rates.PacLife: Prior to age 65, you can convert to a number of eligible Pacific Life products'
    },
    {
      'modalLabel': 'Policy Conversion Rider paclife',
      'modalText': 'Prior to age 65, you can convert to a number of eligible Pacific Life products'
    },
    {
      'modalLabel': 'terminal illness paclife',
      'modalText': 'Access up to 75% of the policy’s death benefitif the insured is diagnosed with a terminal illness†'
    },
    {
      'modalLabel': 'terminal illness sbli',
      'modalText': 'Accelerated Death Benefit Rider included at no additional cost, providing early access to a portion of the death benefit if the insured is diagnosed with a terminal illness'
    },
    ];
  }



  ngAfterViewInit(): void {
    document.body.addEventListener('mouseleave', (e) => { this.exitPopup(e); });
    // document.body.addEventListener('mousemove', (e) => { this.idlePopup(e); });
    // document.body.addEventListener('keypress', (e) => { this.idlePopup(e); });
  }

  exitPopup(e) {
    if (this.showNumber == 0 && e.clientY <= 0 && !this.loader) {
      document.getElementById('exiterrorModal').style.display = 'block';
      // this.showNumber = 1;
    }
  }

  closePopup() {
    document.getElementById('exiterrorModal').style.display = 'none';
  }

  dontShow() {
    this.showNumber = 1;
    document.getElementById('exiterrorModal').style.display = 'none';
  }

  closetab() {
    window.open('https://stage.getmybubble.co/', '_self');
  }

  idlePopup(e) {

    clearTimeout(this.time);
    this.time = setTimeout(() => { this.exitPopup(e); }, 15000);

  }

  getQuote() {
    debugger;
    this.quotesZero = false;
    console.log('loader : ', this.loader);
    if (localStorage.getItem('bubble-fullName') !== null) {
      this.fullName = localStorage.getItem('bubble-fullName');
      this.emailId = localStorage.getItem('bubble-email');
    }
    else {
      this.fullName = 'Anonymous User';
    }

    this.gender = localStorage.getItem('bubble-gender');
    // this.gender = this.gender == '1' ? "Male" : "Female";
    this.tobacco = localStorage.getItem('bubble-tobacco');
    this.tobacco = this.tobacco == '1' ? 'Yes' : 'No';
    this.dob = localStorage.getItem('bubble-dob');
    this.age = localStorage.getItem('bubble-age');
    this.height = localStorage.getItem('bubble-heightFt') + 'ft ' + localStorage.getItem('bubble-heightIn') + 'inch';
    this.weight = localStorage.getItem('bubble-weight');
    this.termLength = localStorage.getItem('bubble-termLength');
    this.coverage = localStorage.getItem('bubble-coverage');
    this.dropdownCoverage = this.coverage.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    console.log('dropdownCoverage', this.dropdownCoverage);
    this.commonAddress = localStorage.getItem('bubble-street');

    // let splitAddress = this.displayAddress.split("-");
    // this.city = splitAddress[(splitAddress.length - 4)];
    // this.state = splitAddress[(splitAddress.length - 3)];
    // this.zipcode = splitAddress[(splitAddress.length - 2)];
    let fullAddress = this.uniqueId.split('-');

    fullAddress.shift();
    fullAddress = fullAddress.join(' ');
    this.pc.parseAddressLocalAndPatch(fullAddress, this.clientId).then(
      data => {
        console.log('splitAddressNew : ', data);
        this.state = data.state_code;
        this.city = data.city_name;
        this.zipcode = data.zip;
        console.log('fullAddressNew : ', fullAddress);
        //console.log("splitAddressNew : ", splitAddressNew);
        const sbligender = localStorage.getItem('bubble-gender');// == "Male" ? "1" : "0";
        const sblitobacco = localStorage.getItem('bubble-tobacco');
        const d = new Date(this.dob);
        const year = d.getFullYear();
        console.log('year : ', year, '# this.age:', this.age, '#this.dob:', this.dob);
        //let sbliDob = "01/01/" + year;
        const sbliDob = this.dob;
        const splitname = this.fullName.split(' ');
        // //debugger

        const localterm = localStorage.getItem('bubble-termLength');
        if (localterm == null || localterm == undefined) {
          this.calculatedTerm = (65 - Number(this.age));
          this.termLength = this.calculatedTerm.toString();
        } else {
          this.termLength = localStorage.getItem('bubble-termLength');
        }


        localStorage.setItem('bubble-termLength', this.termLength);
        this.riskClass = localStorage.getItem('riskClass');
        const commonInput = {
          lastName: splitname[2] ? splitname[2] : 'Lastname',
          firstName: splitname[0] ? splitname[0] : 'Firstname',
          middleName: splitname[1] ? splitname[1] : 'MiddleName',
          addressType: 'PhysicalRisk',
          address: this.commonAddress,//splitAddress[(splitAddress.length - 3)],
          city: this.city,
          gender: sbligender == '1' ? 'M' : 'F', //inputJson.gender
          dob: sbliDob,//inputJson.dob
          term: this.termLength,//inputJson.term
          zip: this.zipcode,//inputJson.zip
          state: this.state,//inputJson.state
          height: this.height,//inputJson.height
          weight: +this.weight,//inputJson.weight
          tobaccoUse: +sblitobacco,//inputJson.tobaccoUse
          riskClass: this.riskClass,
          coverage: this.coverage,//inputJson.coverage
          premiumFrequency: 'm',
        };

        this.heightFT = localStorage.getItem('bubble-heightFt');
        this.heightIN = localStorage.getItem('bubble-heightIn');
        this.onDateChange(this.dob);


        if (+sblitobacco != 5) {
          this.smoker = 'Yes';
          this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');
        } else {
          this.usageDuration = '1';
          this.smoker = 'No';
        }

        this.genderString = sbligender == '1' ? 'Male' : 'Female';
        console.log('commonInput before getQuickQuote2 invoke : ', commonInput);
        this.pc.hubSpotSyncAfterEmailFetch({
          email: localStorage.getItem('bubble-email'),
          user_landed_reco_page: 'Yes',
          application_stage: 'Lead',
          desired_life_term: localStorage.getItem('bubble-termLength'),
          gender: this.gender,
          height: this.pc.validateHeightHubspot(`${this.heightFT}'${this.heightIN}"`),
          dob_life: this.dob,
          weight: +this.weight,
          smoker: this.smoker,
          tobacco_user: this.smoker,
          age: this.age.toString()
          // life_latest_premium_amount: this.selectedCardData.head.quote_amount


        });
        const quickquote = new pc.Quotes(this.uniqueId);


        // //debugger
        //getQuickQuote2(commonInput, true) => to enable SBLI Quote
        //getQuickQuote2(commonInput, false) => to disable SBLI Quote
        //getQuickQuote2(commonInput) => to disable SBLI Quote
        quickquote.getQuickQuote2(commonInput, true)
          .then(response => {
            console.log('commonInput after getQuickQuote2 invoke : ', commonInput);
            console.log('output quickquote: ', response);
            this.quickQuoteData = response;
            this.carrier1 = this.quickQuoteData[0];
            this.carrier2 = this.quickQuoteData[1];


            this.sbliUptoAge = Number(this.age) + Number(this.quickQuoteData[1].selectedDetails.term);
            let cone; let ctwo;
            if (this.quickQuoteData[0]['head']['quote_amount'] != undefined) {
              cone = this.quickQuoteData[0]['head']['quote_amount'].replace('$', '');
            }
            if (this.quickQuoteData[1]['head']['quote_amount'] != undefined) {
              ctwo = this.quickQuoteData[1]['head']['quote_amount'].replace('$', '');
            }

            if (Number(cone) > Number(ctwo)) {
              this.carrier1 = this.quickQuoteData[1];
              this.carrier2 = this.quickQuoteData[0];
            }
            if (this.carrier1['head']['quote_amount'] != undefined) {
              this.carrier1['head']['quote_amount'] = formatAmount(this.carrier1['head']['quote_amount']);
            }
            if (this.carrier2['head']['quote_amount'] != undefined) {
              this.carrier2['head']['quote_amount'] = formatAmount(this.carrier2['head']['quote_amount']);
            }

            const paclife_quote = this.carrier1['head']['quote_amount'];
            const SBLI_quote = this.carrier2['head']['quote_amount'];
            if (this.emailId) {
              window._loq.push(['custom', {
                paclife_quote,
                SBLI_quote,
                client_id: this.clientId,
                email: this.emailId
              }
              ]);
            }
            console.log('carrier1: ', this.carrier1);
            console.log('carrier2: ', this.carrier2);

            this.disableCarrierOne = false;
            this.disableCarrierTwo = false;

            if (this.carrier1.hasError == true && this.carrier2.hasError == true) {
              this.loader = false;
              this.spinner = false;
              this.openModal();
            }
            if (this.carrier1['head']['quote_amount'] != undefined && this.carrier2['head']['quote_amount'] != undefined) {
              if (this.carrier1['head']['quote_amount'].includes('$0') && this.carrier2['head']['quote_amount'].includes('$0')) {
                this.loader = false;
                this.spinner = false;
                this.quotesZero = true;
                //this.openModal();
                return;
              }
            }
            if (this.carrier1['head']['quote_amount'] == undefined) {
              this.disableCarrierOne = true;
            }
            else {
              if (this.carrier1['head']['quote_amount'].includes('$0')) {
                this.disableCarrierOne = true;
              }
            }
            if (this.carrier2['head']['quote_amount'] == undefined) {
              this.disableCarrierTwo = true;
            }
            else {
              if (this.carrier2['head']['quote_amount'].includes('$0')) {
                this.disableCarrierTwo = true;
              }
            }

            if (this.disableCarrierOne == false && this.disableCarrierTwo == false) {
              this.headreLable = 'are two';
            }
            else {
              this.headreLable = 'is a';
            }

            if (this.carrier1.head.quote_amount != undefined) {
              this.carrier1.head.quote_amount = this.carrier1.head.quote_amount.split('.')[0];
            } if (this.carrier2.head.quote_amount != undefined) {
              this.carrier2.head.quote_amount = this.carrier2.head.quote_amount.split('.')[0];
            }


            this.loader = false;
            this.spinner = false;
            this.counter = this.counter + 1;
            if (this.counter == 1) {
              //document.getElementById('optionmodal').style.display = 'block';
            }

            this.showNumber = 1;
            let showcoverage;
            if (this.carrier1.head.quote_amount != undefined) {
              showcoverage = this.carrier1.head.quote_amount.replace('$', '');
            }
            this.showQuoteAmount = (showcoverage / 30);
            this.selectedCardData = this.carrier1;
            this.coveragetodisaply = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
            this.selectedCard = 0;
            //document.getElementById("car1").click();
            //this.selectcarrier('0');
            if (document.getElementById('car1') !== null) {
              document.getElementById('car1').style.border = 'none';
              document.getElementById('car1').style.borderRadius = 'none';
            }
            if (document.getElementById('cari1') !== null) document.getElementById('cari1').style.display = 'none';
            if (document.getElementById('car2') !== null) {
              document.getElementById('car2').style.border = 'none';
              document.getElementById('car2').style.borderRadius = 'none';
            }
            if (document.getElementById('cari2') !== null) document.getElementById('cari2').style.display = 'none';
            this.spinner = false;
          })
          .catch(error => {
            this.spinner = false;
            if (error) console.log(error);
            else console.log('select-life-carrier\\select-life-carrier.component.ts:catch(error):getQuickQuote2;Unknown error');
          });
      }
    ).catch(error => {
      console.log('error', error);
      this.spinner = false;
      this.openModal();
    });

  }

  /**
     * @deprecated There is no dropdown for paclife
     * @param even
     */
  onTermChange(even) {

    this.loader = true;
    this.termLength = even;//.target.value
    localStorage.setItem('bubble-termLength', this.termLength);
    this.getQuote();
  }

  selectcarrier(eve) {
    if (eve == '1') {
      this.selectedCardData = this.carrier1;
      this.selectedCard = 1;
      this.selectedCarrier = 1;
      const { carrierId } = this.carrier1;
      this.carrierSelected = carrierId;
      this.coveragetodisaply = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
      ////////////////Deal with term and coverageAmount for Life kind insuranceType
      // This block is to be updated in select-life-carrier and select-carriers
      if (this.insurancetype === 'homeLife' ||
        this.insurancetype === 'life') {
        if (this.selectedCardData.carrierId === 'paclife') {
          this.selectedCardData.selectedDetails.term = '65 Years';
          this.coverageAmountText = 'Min: $250,000 - Max: $10M';
          if (this.selectedCardData.selectedDetails.text.indexOf('*(1)') !== -1) {
            this.selectedCardData.selectedDetails.text = this.selectedCardData.selectedDetails.text.replace('*(1)', '');
            this.disclaimerScript1 = '1 ';
          }
        } else {
          this.coverageAmountText = this.coveragetodisaply;
          this.selectedCardData.selectedDetails.term = '' + this.selectedCardData.selectedDetails.term;
          if (this.selectedCardData.selectedDetails.term &&
            !this.selectedCardData.selectedDetails.term.endsWith('Years')) {
            this.selectedCardData.selectedDetails.term = `${this.selectedCardData.selectedDetails.term} Years`;
          }
        }
      }
      ////////////////Deal with term and coverageAmount for Life kind insuranceType Ended
    }
    else if (eve == '2') {
      this.selectedCardData = this.carrier2;
      this.selectedCard = 1;
      this.selectedCarrier = 2;
      const { carrierId } = this.carrier2;
      this.carrierSelected = carrierId;
      this.selectedCardData.selectedDetails.term = '' + this.selectedCardData.selectedDetails.term;
      if (this.selectedCardData.selectedDetails.term &&
        !this.selectedCardData.selectedDetails.term.endsWith('Years')) {
        this.selectedCardData.selectedDetails.term = `${this.selectedCardData.selectedDetails.term} Years`;
      }
      this.coveragetodisaply = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
      this.coverageAmountText = this.coveragetodisaply;
    }
    else if (eve == '3') {
      this.selectedCardData = this.carrier3;
      this.selectedCard = 1;
      console.log('carrier selected', 3);
      //  this.carrierSelected = 'sbli';
      // document.getElementById("rightcardunselected").style.display = "none";
      // document.getElementById("rightcardselected").style.display = "block";
      // document.getElementById("car1").style.border = "none";
      // document.getElementById("car1").style.borderRadius = "none";
      // document.getElementById("cari1").style.display = "none";
      // document.getElementById("car2").style.border = "none";
      // document.getElementById("car2").style.borderRadius = "none";
      // document.getElementById("cari2").style.display = "none";
      // document.getElementById("car3").style.border = "2px solid #ff7007";
      // document.getElementById("car3").style.borderRadius = "10px";
      // document.getElementById("cari3").style.display = "block";
    } else {
      this.selectedCardData = '';
      this.selectedCard = 0;
      this.selectedCarrier = 0;
      // document.getElementById("rightcardunselected").style.display = "none";
      // document.getElementById("rightcardselected").style.display = "block";
      document.getElementById('car1').style.border = 'none';
      document.getElementById('car1').style.borderRadius = 'none';
      document.getElementById('cari1').style.display = 'none';
      if (document.getElementById('car2')) {
        document.getElementById('car2').style.border = 'none';
        document.getElementById('car2').style.borderRadius = 'none';
        document.getElementById('cari2').style.display = 'none';
      }
      if (document.getElementById('car3')) {
        document.getElementById('car3').style.border = 'none';
        document.getElementById('car3').style.borderRadius = 'none';
        document.getElementById('cari3').style.display = 'none';
      }
    }



    // const hubSpotData = {
    //   customer_id: localStorage.getItem('customer_id'),
    //   client_id: localStorage.getItem('clientID'),
    //   email: localStorage.getItem('bubble-email'),
    //   application_stage: 'Quote',
    //   life_insurance_carrier: this.selectedCardData.head.carrier_name,
    //   life_quote_amount: this.selectedCardData.head.quote_amount,
    //   life_latest_premium_amount: this.selectedCardData.head.quote_amount
    // };
    // this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    console.log('this.selectedCardData: ', this.selectedCardData);
  }


  bluronhow() {
    document.getElementById('we_are_sorry').style.display = 'block';
    //document.getElementById('headmakeblurs').style.filter = "blur(10px)";
    document.getElementById('makeblurs').style.filter = 'blur(10px)';
    document.getElementById('makeblurs').style.overflowY = 'hidden';
    document.getElementById('makeblurs').style.position = 'fixed';
  }

  bluroffhow() {
    document.getElementById('we_are_sorry').style.display = 'none';
    //document.getElementById('headmakeblurs').style.filter = "none";
    document.getElementById('makeblurs').style.filter = 'none';
    document.getElementById('makeblurs').style.overflowY = 'auto';
    document.getElementById('makeblurs').style.position = 'unset';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }


  modaltwoopen() {
    ////debugger
    //document.getElementById('headmakeblurs').style.filter = "blur(10px)";
    this.showModal = true;
    document.getElementById('makeblurs').style.filter = 'blur(10px)';
    // document.getElementById('makeblurs').style.overflowY = 'hidden';
    document.getElementById('makeblurs').style.position = 'fixed';
    document.getElementById('selected_plan_details').style.display = 'block';
    document.getElementById('mod_body').style.height = '85vh';
    document.getElementById('mod_body').style.overflowY = 'auto';
    document.getElementById('mod_body').style.position = 'unset';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }


  modaltwoclose() {
    //document.getElementById('headmakeblurs').style.filter = "none";
    document.getElementById('makeblurs').style.filter = 'none';
    // document.getElementById('makeblurs').style.overflowY = 'auto';
    document.getElementById('makeblurs').style.position = 'unset';
    document.getElementById('selected_plan_details').style.display = 'none';
    document.getElementById('mod_body').style.overflowY = 'hidden';
    document.getElementById('mod_body').style.position = 'fixed';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }

  previousscreen() {
    const eventOptionsPart1 = {
      'path': 'selectLifeCarrier',
      'page_title': 'selectLifeCarrier', 'url': window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', this.insurancetype, {
      'event_category': 'BackButton', 'event_label': 'Back'
    });
    if (this.backButton == 'PrefillResumeFlowLife') {
      location.href = 'PrefillResumeFlowLife';
    }
    else if (this.backButton == 'life-insurance') {
      location.href = 'life-insurance';
    }
    else if (this.backButton == 'CoupleAboutToGetMArriedLife') {
      location.href = 'CoupleAboutToGetMArriedLife';
    }
    else if (this.backButton == 'CoupleWithChildrenLife') {
      location.href = 'CoupleWithChildrenLife';
    }
    else if (this.backButton == 'SingleMotherLife') {
      location.href = 'SingleMotherLife';
    }
    else if (this.backButton == 'SingleParentLife') {
      location.href = 'SingleParentLife';
    }
    else {
      location.href = `QuoteFlowV2Life/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/previous/1`;
    }

    //this.router.navigate(['/QuoteFlowV2', this.uniqueId, this.clientId,this.displayAddress,'life','previous',1]);
  }

  async selectPlan(evn) {
    debugger
    const coverageAmount = this.dropdownCoverage.replace(/\$|,/g, '');
    if (this.refreshbuttonclicked) {
      localStorage.setItem('bubble-coverage', coverageAmount);
    }
    let redirectUrl = '';
    let appflowScreen = '';
    this.selectcarrier(evn);
    const eventOptionsPart1 = {
      'path': 'selectLifeCarrier',
      'page_title': 'selectLifeCarrier', 'url': window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifePlanSelect', {
      'event_category': 'ButtonClicked', 'event_label': 'LifePlanSelect'
    });
    if (this.carrier2.carrierId == 'sbli') {
      this.$gaService.event('Proceed', 'event', 'SBLI-SelectPolicy');
      this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed', 'event', 'SBLI-SelectPolicy');
    } else if (this.carrier2.carrierId == 'paclife') {
      this.$gaService.event('Proceed', 'event', 'LifePlanSelect');
      this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed', 'event', 'LifePlanSelect');
    }


    this.loader = true;
    const quoteId = this.pc.getQuoteId(this.clientId);
    console.log('quoteId : ', quoteId);

    if (this.emailId) {
      window._loq.push(['customData', {
        selected_carrier: this.selectedCardData.head.carrier_name,
        selected_carrier_quote: this.selectedCardData.head.quote_amount,
        email: this.emailId,
        client_id: this.clientId
      }]);
    }
    localStorage.setItem('quoteId', quoteId);
    const details = JSON.stringify(this.selectedCardData);
    localStorage.setItem('selectedCarrier', details);
    localStorage.setItem('selectedLifeCarrier', details);
    /*
const selectedLifeCarrier = {
  "quoteId": "nsqs9n-h9sdiv",
  "carrierId": "paclife",
  "head": {
    "carrier_name": "Next Term Life",
    "quote_amount": "$66.27",
    "carrier_icon": "https://public-bubble.s3-us-west-2.amazonaws.com/images/images/paclife-logo.png"
  },
  "body": {
    "points": [{ "highlight": "One policy that keeps you covered to age 65 (typically when your mortgage is paid off and the kids are out of the house.)" },
    { "highlight": "Buy what you need today and increase or decrease your coverage in the future with a simple process and no additional underwriting.", "tooltip": "Locking in your health rating is beneficial because the younger and healthier you are, typically the lower the premium. When you purchase Next Term Life, your health rating is locked in. For every qualifying life event thereafter, you can increase coverage without additional medical underwriting and the premium for the increased coverage will be calculated at the original locked-in health rating and age at time of increase (up to attained age of 55). Qualifying life events require proof of life event & a signed change form, authorization, and terminal illness attestation." },
    { "highlight": "Innovative health lock feature allows you to add coverage in the future based on your original health rating.", "tooltip": "Locking in your health rating is beneficial because the younger and healthier you are, typically the lower the premium. When you purchase Next Term Life, your health rating is locked in. For every qualifying life event thereafter, you can increase coverage without additional medical underwriting and the premium for the increased coverage will be calculated at the original locked-in health rating and age at time of increase (up to attained age of 55). Qualifying life events require proof of life event & a signed change form, authorization, and terminal illness attestation." },
    { "highlight": "Online application and phone interview." }
    ]
  }
  ,
  "selectedDetails": {
    "term": "20", "coverage": "1200000",
    "title": "Key Benefits",
    "text": "With Next Term Life, protect your loved ones until you're age 65. And simply adjust your coverage as life changes along the way.",
    "key_benefits": [{ "title": "Scalable", "text": "Start with the coverage you need now and increase or decrease it to keep up with life changes." },
    { "title": "Simple", "text": "Take advantage of our “one and done” underwriting so you can increase or decrease coverage with a simple online process." },
    { "title": "Smart", "text": "Automatically lock in your health rating at purchase to help make increasing your coverage in the future easy and potentially more affordable." }],
    "footer_text": [{ "text": "Next Term Life is a term life insurance product–policy form series ICC18 P18IFT and ICC18 S18IFT or P18IFT and S18IFT. Policy form number may vary by state of issue." },
    {
      "text": "This rate is based on our Next Term Life Insurance policy with an assumed risk class of Super Preferred Non-Tobacco. This rate is also based on your selected criteria of Male, 18 years old, in CA, and the following assumptions. It is not a guarantee to offer or insure and may change based on the information gathered during the underwriting process."
    }]
  }, "hasError": false
};
*/
    const fullName = `${localStorage.getItem('bubble-fname')} ${localStorage.getItem('bubble-lname')}`;

    ///// Deal with selected Quote persistence
    let pacificLifeQuoteId = this.pc.getQuoteId('');// pass emptystring to get -wer324 kind string
    pacificLifeQuoteId = pacificLifeQuoteId.replace('-', '');
    const clientIdLocal = localStorage.getItem('clientID');
    const carrierLocal = this.selectedCardData['head']['carrier_name'];
    const quoteAmountLocal = this.selectedCardData['head']['quote_amount'];
    localStorage.setItem('bubble-QouteAmount', quoteAmountLocal);

    if (this.insurancetype == 'life') {
      const hubSpotData = {
        email: localStorage.getItem('bubble-email'),
        life_quote_amount: quoteAmountLocal,
        life_latest_premium_amount: quoteAmountLocal,
        application_stage: 'Application',
        life_insurance_carrier: this.selectedCardData.carrierId == 'sbli' ? 'SBLI' : 'Next by Pacific Life',
        desired_life_coverage: coverageAmount,
        desired_life_term: localStorage.getItem('bubble-termLength')

      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    else {

      const hubSpotData = {
        customer_id: localStorage.getItem('customer_id'),
        email: localStorage.getItem('bubble-email'),
        life_insurance_carrier: carrierLocal,
        life_quote_amount: quoteAmountLocal,
        life_latest_premium_amount: quoteAmountLocal,
        application_stage: 'Quote',
        desired_life_coverage: coverageAmount,
        desired_life_term: localStorage.getItem('bubble-termLength')
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    }

    const json = {
      'id': this.clientId,
      'sbli_totalPremium': this.selectedCardData.carrierId == 'sbli' ? this.selectedCardData['head']['quote_amount'] : 'NA',
      'paclife_totalPremium': this.selectedCardData.carrierId == 'paclife' ? this.selectedCardData['head']['quote_amount'] : 'NA',
      'selectedLife': this.selectedCardData.carrierId
    };
    this.pc.saveUpdateClientData(json);


    const inputJson4Db = {
      id: pacificLifeQuoteId,
      clientId: clientIdLocal,
      uid: pacificLifeQuoteId,
      carrier: 'Next',
      carrier_logo: this.selectedCardData['head']['carrier_icon'],
      policyName: 'Life Insurance',
      policyType: 'Life',
      policyAmount: quoteAmountLocal,
      policyNumber: '',
      coverageAmount: this.selectedCardData['selectedDetails']['coverage'],
      term: this.selectedCardData['selectedDetails']['term'],
      'policyDetails_PolicyName': carrierLocal,
      'policyDetails_HomeownerName': fullName,
      'policyDetails_ZIPCode': localStorage.getItem('bubble-zip'),
      'policyDetails_stateCode': localStorage.getItem('bubble-state'),
      'dataCategory': 'LifeQuote',
      'eventStr': 'Selected LifeQuote',
      Expires: '',
      selectedPlanDate: new Date().toISOString(),
    };
    /*
    const inputJson4Db = {
      id: 'dt6rgz',
      client_id: '7re83p',
      carrier: "Next",
      carrier_logo: "https://public-bubble.s3-us-west-2.amazonaws.com/images/images/paclife-logo.png",
      policyName: "Life Insurance",
      policyType: "Life",
      policyAmount: "$78.90",
      policyNumber: "",
      coverageAmount: "$1,200,000",
      term: "20 year",
      "policyDetails.PolicyName": "Term Life Next",
      "policyDetails.HomeownerName": "Test 1",
      "policyDetails.ZIPCode": "94301",
      "policyDetails.stateCode": "CA",
      "dataCategory": "LifeQuote",
      "eventStr": "Selected LifeQuote",
      Expires: "",
      selectedPlanDate: new Date().toISOString(),
      clientID: '7re83p'
    };
    */
    console.log('Lead:saveAppData:inputJson4Db:', inputJson4Db);
    pc.BackendService.saveAppData(inputJson4Db).then(saveResponse => {
      console.log('Lead:createIdMapping:saveResponse: ', saveResponse);
      /////// id mapping
      const idMappingJson = {
        client_id: clientIdLocal,
        customer_id: localStorage.getItem('customer_id'),
        email_id: localStorage.getItem('bubble-email'),
        paclife_quote_id: pacificLifeQuoteId,
        status: 'LifeQuote',
        dob: localStorage.getItem('bubble-dob'),
      };
      localStorage.setItem('paclife_quote_id', pacificLifeQuoteId);
      // const Helper = require('bit-uibl-data-driver');
      // Helper.Lead.createIdMapping(idMappingJson).then((data) => {
      //   if (data) { console.log("Lead:createIdMapping Successful:data: ", data) }
      // });

      this.pc.createIdMapping(idMappingJson);

      /////// id mapping ends
      //hubSpotSync(hubSpotData);
    }).catch(error => {
      console.log('Lead:createIdMapping:error: ', error);
    });
    ///// Deal with selected Quote persistence ends
    let tl = this.selectedCardData.selectedDetails.term;
    tl = tl.substring(0, 2);
    localStorage.setItem('bubble-termLength', tl);
    if (this.insurancetype == 'life') {

      const eventOptions: EventOption = {
        path: 'SelectedCarriers',
        page_title: 'Begin Application',
        url: window.location.href
      };
      this.$gaService.event('Begin Application', 'event', 'LifeBeginApplication');
      this.utilityService.eventWrapped(eventOptions, 'Begin Application', 'event', 'LifeBeginApplication');

      appflowScreen = 'app-flow-start';
      setTimeout(() => {
        if (this.selectedCardData.carrierId == 'paclife') {
          const pacjson = {
            'id': this.clientId,
            'selectedLife': 'paclife'
          };
          this.pc.saveUpdateClientData(pacjson);
          redirectUrl = `Appflowstart/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.selectedCardData.carrierId}`;
        }
        if (this.selectedCardData.carrierId == 'sbli') {
          const sblijson = {
            'id': this.clientId,
            'sbli_questionNumber': 0,
            'sbli_term': localStorage.getItem('bubble-termLength'),
            'sbli_coverage': localStorage.getItem('bubble-coverage'),
            'sbli_appFlowBackButton': '0',
            'selectedLife': 'sbli'
          };
          this.pc.saveUpdateClientData(sblijson).then(data => {
          }).catch(error => {
            console.log(error);
          });

          redirectUrl = `lifeCarrierTwo/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
        }

        const updateJson = {
          otherAppflowScreen: appflowScreen,
          resumeUrl: redirectUrl
        };
        this.checkforResumeInit(updateJson, 'update').then(
          data => {
            //location.href = redirectUrl;
            this.router.navigate([redirectUrl]);
          }
        ).catch(error => console.log(error));
        // const eventOptionsPart1 = {
        //   'path': 'selectedCarrier',
        //   'page_title': 'selectedCarrier', 'url': window.location.href
        // };
        // this.utilityService.eventWrapped(eventOptionsPart1, 'Begin Application', 'event', 'BeginApplication');
      }, 3000);

    }
    else {

      const updateJson = {
        otherAppflowScreen: 'selected-carriers',
        resumeUrl: `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.selectedCardData.head.carrier_name}`
      };
      this.checkforResumeInit(updateJson, 'update').then(
        data => {
          setTimeout(() => {
            location.href = `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.selectedCardData.head.carrier_name}`;
          }, 2000);
        }
      ).catch(error => console.log(error));

      this.utilityService.eventWrapped(eventOptionsPart1, 'Select Plan', 'event', 'LeadSelect');
      console.log('Done with this.utilityService.eventWrapped');

    }


  }


  openModal() {
    this.showNumber = 1;
    document.getElementById('errorModal').style.display = 'block';
    document.getElementById('makeblurs').style.filter = 'blur(10px)';
    document.getElementById('makeblurs').style.overflowY = 'hidden';
  }

  closeModal() {
    document.getElementById('makeblurs').style.filter = 'none';
    document.getElementById('makeblurs').style.overflowY = 'auto';
    //document.getElementById('makeblurs').style.position = 'unset';
    document.getElementById('errorModal').style.display = 'none';
    //window.location.href = environment.carrierErrorRedirectURL;
    window.location.href = this.pc.envCred.url;
  }

  tryAgain() {
    document.getElementById('makeblurs').style.filter = 'none';
    document.getElementById('makeblurs').style.overflowY = 'auto';
    document.getElementById('errorModal').style.display = 'none';
    location.reload();
  }

  gotoHome() {
    document.getElementById('makeblurs').style.filter = 'none';
    document.getElementById('makeblurs').style.overflowY = 'auto';
    document.getElementById('errorModal').style.display = 'none';
    window.location.href = this.pc.envCred.url;
  }

  showToast() {
    // alert("Hi");
    this.toastService.show('For Pacific Life Next product Term is fixed based on Age');
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }
  openRiskClass() {
    this.pageToOpen = 'riskPage';
    this.showAssumptions = true;
  }

  openAssumptions() {
    this.pageToOpen = 'assumptions';
    this.showAssumptions = true;
  }

  closeAssumption() {
    this.showAssumptions = false;
    this.pageToOpen = '';
  }


  getDropdownCoverageValues(even) {
    debugger
    this.recaluclateButton = true;
    this.dropdownCoverage = even;
    this.covergaeError = '';
  }

  recaluclateCoverage() {
    debugger
    this.refreshbuttonclicked = true;
    const coverageAmount = this.dropdownCoverage.replace(/\$|,/g, '');
    console.log('coverageAmount', coverageAmount);
    localStorage.setItem('bubble-coverage', coverageAmount);
    this.covergaeError = '';

    const hubSpotData = {
      'desired_life_coverage': coverageAmount,
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.ngOnInit();
  }

  changeDropdownvalue(even) {
    debugger
    this.formatCoverage = even.replace(/,/g, '');
    console.log('eventvalue', even);
    console.log('eventlength', even.length);
    if (Number(this.formatCoverage) < 25000) {
      this.recaluclateButton = false;
      this.covergaeError = 'Coverage should be between $25,000 and $10,000,000';
    }
    else if (Number(this.formatCoverage) > 10000000) {
      this.recaluclateButton = false;
      this.covergaeError = 'Coverage should be between $25,000 and $10,000,000';
    }
    else {
      this.recaluclateButton = true;
      this.covergaeError = '';
    }
    // if (even.length >= 6) {
    //   this.recaluclateButton = true;
    // }
    // else {
    //   //debugger
    //   this.recaluclateButton = false;
    // }
    this.dropdownCoverage = this.formatCoverage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }


  modalLabel: any;
  modalText: any;

  assignModalContent1(details) {
    this.modalLabel = details.modalLabel;
    this.modalText = details.modalText;
    this.modalText = this.modalText.replace(/\n/g, '<br/>');
  }

  customizeQuote() {
    this.showCustomizeLife = true;
    document.getElementById('customiseModal').style.display = 'block';
    this.showNumber = 1;
  }


  saveCustomize(even) {
    debugger
    this.calculateTerm(1, this.termLength);
    if (even == '0') {
      this.showCustomizeLife = false;
      document.getElementById('customiseModal').style.display = 'none';
    }
    else {
      this.showCustomizeLife = false;
      document.getElementById('customiseModal').style.display = 'none';
      this.loader = true;
      this.getQuote();
    }
  }

  //  openCarrierDetails(even) {
  //     if (even==1) {
  //       this.carrierDetailsToShow = this.carrier1;
  //     }
  //     else {
  //       this.carrierDetailsToShow = this.carrier2;
  //     }
  //     this.showNumber = 1;
  //     this.detailsModal = true;
  //     document.getElementById("selected_plan_details").style.visibility = "visible";
  //     document.getElementById("selected_plan_details").style.overflowY = "scroll";
  //   }

  //   closeCarrierDetails() {
  //    this.detailsModal = false;
  //     document.getElementById("selected_plan_details").style.visibility = "hidden";
  //   }


  public openCarrierDetails(even) {
    this.display = 'block';
    if (even == 1) {
      this.carrierDetailsToShow = this.carrier1;
    }
    else {
      this.carrierDetailsToShow = this.carrier2;
    }
    this.detailsModal = true;
    document.getElementById('selected_plan_details').style.overflowY = 'scroll';
  }

  closeCarrierDetails() {
    this.detailsModal = false;
    this.display = 'none';

  }


  onDateChange(data) {
    debugger;

    this.NewDate = data ? new Date(data) : new Date();
    const year = Number(this.NewDate.getFullYear());
    this.Date = new Date();
    const yearNewDate = Number(this.Date.getFullYear());
    this.age = yearNewDate - year;
    if (this.age > 18 && this.age < 55) {
      this.dateError = '';
      const newdob = new Date(data);
      const yearnew = Number(newdob.getFullYear());
      let monthnew = '' + (newdob.getMonth() + 1);
      let daynew = '' + newdob.getDate();
      if (monthnew.length < 2)
        monthnew = '0' + monthnew;
      if (daynew.length < 2)
        daynew = '0' + daynew;
      this.dob = monthnew + '/' + daynew + '/' + yearnew;
    } else {
      this.dob = '';
      this.dateError = 'Insured should be between 18 years and 55 years of age';
      //this.loader = false;
    }

  }

  async calculateTerm(type = 0, termLength) {
    debugger
    const todayDate = new Date();
    let urlDob = new Date(localStorage.getItem('bubble-dob'));
    this.age = todayDate.getFullYear() - urlDob.getFullYear();
    let calculatedTerm = 65 - Number(this.age);
    this.termError = "";
    this.termLength = termLength;
    if (Number(this.termLength) <= Number(calculatedTerm)) {

      localStorage.setItem('bubble-termLength', this.termLength);
      if (type == 0) {
        this.spinner = true;
        this.getQuote();
      }
      else {
        if (Number(this.termLength) == 10 || Number(this.termLength) == 15 || Number(this.termLength) == 20) {
          localStorage.setItem('bubble-termLength', this.termLength);
        }
        else {
          this.termLength = Number(calculatedTerm) > 20 ? 20 : Number(calculatedTerm);
          this.termLength = Number(this.termLength) >= 15 && Number(this.termLength) < 20 ? "15" : Number(this.termLength);
          this.termLength = Number(this.termLength) >= 10 && Number(this.termLength) < 15 ? "10" : Number(this.termLength);
          this.termLength = Number(this.termLength) < 10 ? 10 : Number(this.termLength);
          localStorage.setItem('bubble-termLength', this.termLength);
        }

      }
    }
    else {
      this.termError = "Based on your age the selected term can not be applied.";
    }

    return true;
  }

  public showquotes(id) {
    debugger
    this.carrier1
    this.carrier2
    if (this.carrier1.carrierId == 'sbli') {
      if (id == 'SBLI') {
        this.disableCarrierOne = false;
        this.disableCarrierTwo = true;
        document.getElementById('optionmodal').style.display = 'none';
        this.showNumber = 0;
      }
      else if (id == 'NEXT') {
        this.disableCarrierOne = true;
        this.disableCarrierTwo = false;
        document.getElementById('optionmodal').style.display = 'none';
        this.showNumber = 0;
      }
      else {
        this.disableCarrierOne = false;
        this.disableCarrierTwo = false;
        document.getElementById('optionmodal').style.display = 'none';
        this.showNumber = 0;

      }

    }
    else {
      if (id == 'SBLI') {
        this.disableCarrierOne = true;
        this.disableCarrierTwo = false;
        document.getElementById('optionmodal').style.display = 'none';
        this.showNumber = 0;
      }
      else if (id == 'NEXT') {
        this.disableCarrierOne = false;
        this.disableCarrierTwo = true;
        document.getElementById('optionmodal').style.display = 'none';
        this.showNumber = 0;
      }
      else {
        this.disableCarrierOne = false;
        this.disableCarrierTwo = false;
        document.getElementById('optionmodal').style.display = 'none';
        this.showNumber = 0;

      }

    }


  }

}
