import { Component, OnInit } from '@angular/core';
import { TrackerService } from 'src/app/shared/tracker.service';
import { Subscription } from 'rxjs/internal/Subscription';
@Component({
  selector: 'app-pizzatracker',
  templateUrl: './pizzatracker.component.html',
  styleUrls: ['./pizzatracker.component.css']
})
export class PizzatrackerComponent implements OnInit {

  constructor(private tracker: TrackerService) { }
  percent;
  sub: Subscription;
  pathOrange: string;
  pathPaleGrey: string;
  ApllicationID: any;
  clientid: any;
  ngOnInit(): void {
    debugger;
    this.sub = this.tracker.getProgressStatus().subscribe(value => {
      this.percent = value;
      if (this.percent > 100) this.percent = 100;
      this.pathOrange = this.describeArc(24, 24, 18, 0, this.percent * 3.6);
      this.pathPaleGrey = this.describeArc(24, 24, 18, this.percent * 3.6, 360);
      this.ApllicationID = localStorage.getItem('bubble-ApplicationID');

      if (this.ApllicationID == null||this.ApllicationID==undefined||this.ApllicationID=== 'undefined') {
        let random = this.randomnumber(5);
        this.clientid = localStorage.getItem('clientID');
        if (this.clientid == null) {
          this.clientid = localStorage.getItem('clientId');
        }
        this.ApllicationID = this.clientid + '-' + random;
      }
      else {
        if (this.ApllicationID.includes('-')) {
          let app = this.ApllicationID.split('-');
          if (app[0] === 'null'|| app[0] == undefined || app[0] === 'undefined') {
            let clientid = localStorage.getItem('clientID');
            if (clientid == null) {
              let clientid = localStorage.getItem('clientId');
              app[0] = clientid;
            }
            else {
              app[0] = clientid;
            }
            this.ApllicationID = app[0] + '-' + app[1];
            localStorage.setItem('bubble-ApplicationID', this.ApllicationID);
          }
          else {
            this.ApllicationID = this.ApllicationID;
          }
        }
      }
    });
  }

  polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string {

    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');

    return d;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  public randomnumber(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }
}
