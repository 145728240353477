import { Component, OnInit, Input, ViewChild } from '@angular/core';

declare let google: any;
@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.css']
})
export class GoogleMapComponent implements OnInit {

  @Input() latitude;
  @Input() longitude;
  @Input() height;
  @ViewChild('mapPlaceDiv') mapPlaceDiv; 
  
  constructor() { }


  ngOnInit(): void {
  }

  
  /* mapReady(){
    this.mapsAPILoader.load().then((data) => { 
      console.log("GoogleMapsAddress: ", data);
     });
  } */

  mapReady() {
    /* let geocoder = new google.maps.Geocoder();
    console.log("mapReady: ", geocoder);
      let latlng = new google.maps.LatLng(this.latitude, this.longitude);
      let request = { latLng: latlng };
      console.log("mapReady 1");
      geocoder.geocode(request, (results, status) => {
        console.log("mapReady 2: ", request, results, status);
        if (status === google.maps.GeocoderStatus.OK) {
          console.log("mapReady 3: ",results);
        }
      }); */
  }

/*   getPlacePhotos(){
    var placeService = new google.maps.places.PlacesService(document.createElement('div'));
    placeService.getDetails({
     placeId: "ChIJaaFLW5qHa4cRzr3YClNc7xM"
   }, (placeResult, status) => {
     console.log("placeResult: ", placeResult);
     console.log("placeResult status : ", status);
     if(status === 'OK') {
       console.log(placeResult.photos);
       var photos = placeResult.photos;
       var urls = []; // we will store the urls here

       photos.forEach((photo) => {
         urls.push(photo.getUrl({
           maxWidth: 500, // at least set one or the other - mandatory
           maxHeight: undefined
         }));
       });
       console.log("placeResult Photo Urls: ", urls);
     }
   });
  } */

}
