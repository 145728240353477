import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { QuinstreetService } from '../../shared/quinstreet.service'
@Component({
  selector: 'app-prefill-quinstreet',
  templateUrl: './prefill-quinstreet.component.html',
  styleUrls: ['./prefill-quinstreet.component.css']
})
export class PrefillQuinstreetComponent implements OnInit {
  incomingid: any;
  loader: boolean;
  constructor(private router: Router, private service: QuinstreetService) { }
  httpParamsGlobal: any;
  ngOnInit(): void {
    debugger
    this.loader = true;
    if (this.router.url.includes('?')) {
      this.httpParamsGlobal = new HttpParams({ fromString: this.router.url.split('?')[1] });
      const httpParams = this.httpParamsGlobal;
      this.incomingid = httpParams.get('pf');
      this.service.ngOnInit(this.incomingid);
    }
  }

}
