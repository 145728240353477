import { Component, OnInit } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
declare var require: any;
const pc = require('bit-uibl-data-driver');

interface Quickquote {
  head: {
    category_name: string;
    icon: string;
    text1: string;
    text1_icon: string;
    text2: string;
  },
  body: {
    section1: {
      text1: string;
      text2: string;
    },
    section2: [
      {
        carrier_name: string;
        quote_amount: string;
        carrier_icon: string;
      },
      {
        carrier_name: string;
        quote_amount: string;
        carrier_icon: string;
      }
    ]
  }
}

@Component({
  selector: 'app-quickquote',
  templateUrl: './quickquote.component.html',
  styleUrls: ['./quickquote.component.css']
})

export class QuickquoteComponent implements OnInit {
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  quickQuoteData: any;
  disableAddress = true;
  fullAddress: any;
  latitude: number = 39.7789467;
  longitude: number = -105.0477277;
  reqheight: any;
  addresstype = "Home Address";
  fastData: Quickquote;
  affordableData: Quickquote;
  flexiData: Quickquote;
  state: string;
  zipcode: string;

  fullName: string;
  emailId: string;
  gender: string;
  tobacco: string;
  termLength: string;
  dob: string;
  age: string;
  height: string;
  weight: string;
  coverage: string;
  commonAddress: string;
  city: string;
  loader: boolean;

  constructor(private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('bubble-fullName') !== null) {
      this.fullName = localStorage.getItem('bubble-fullName');
      this.emailId = localStorage.getItem('bubble-email');
      this.gender = localStorage.getItem('bubble-gender');
      this.gender = this.gender == '1' ? "Male" : "Female";
      this.tobacco = localStorage.getItem('bubble-tobacco');
      this.tobacco = this.tobacco == '1' ? "Yes" : "No";
      this.termLength = localStorage.getItem('bubble-termLength');
      this.dob = localStorage.getItem('bubble-dob');
      this.age = localStorage.getItem('bubble-age');
      this.height = localStorage.getItem('bubble-height');
      this.weight = localStorage.getItem('bubble-weight');
      this.coverage = localStorage.getItem('bubble-coverage');
      this.commonAddress = localStorage.getItem('bubble-street');
      this.city = localStorage.getItem('bubble-city');
    }
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      console.log("uniqueId : ", this.uniqueId);
      console.log("clientId : ", this.clientId);
      console.log("displayAddress : ", this.displayAddress);
      this.loader = true;
      let splitAddress = this.uniqueId.split("-");
      this.state = splitAddress[(splitAddress.length - 2)];
      this.zipcode = splitAddress[(splitAddress.length - 1)];
      let sbligender = localStorage.getItem('bubble-gender');
      let sblitobacco = localStorage.getItem('bubble-tobacco');
      var d = new Date(this.dob);
      var year = d.getFullYear();
      console.log("commonInput : ", year, this.age, this.dob);
      let sbliDob = "01/01/" + year;
      let splitname = this.fullName.split(" ");
      let commonInput = {
        lastName: splitname[2] ? splitname[2] : "Lastname",
        firstName: splitname[0] ? splitname[0] : "Firstname",
        middleName: splitname[1] ? splitname[1] : "MiddleName",
        addressType: "PhysicalRisk",
        address: this.commonAddress,//splitAddress[(splitAddress.length - 3)],
        city: this.city,
        gender: sbligender == '1' ? "M" : "F", //inputJson.gender
        dob: sbliDob,//inputJson.dob
        term: +this.termLength,//inputJson.term
        zip: this.zipcode,//inputJson.zip
        state: this.state,//inputJson.state
        height: this.height,//inputJson.height
        weight: +this.weight,//inputJson.weight
        tobaccoUse: +sblitobacco,//inputJson.tobaccoUse
        coverage: +this.coverage,//inputJson.coverage
        premiumFrequency: "m",
      }

      console.log("commonInput : ", commonInput);
      let quickquote = new pc.Quotes(this.uniqueId);
      debugger
      quickquote.getQuickQuote(commonInput)
        .then(response => {
          console.log("fast flexi output quickquote: ", response);
          this.quickQuoteData = response;
          this.fastData = this.quickQuoteData[0];
          this.flexiData = this.quickQuoteData[1];
          this.affordableData = this.quickQuoteData[2];
          this.loader = false;
        })
        .catch(error => {
          console.log(error);
        });
    }
    )

  }

}