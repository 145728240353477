import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
//import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

const PAC_LIFE = 'pac-life';
const SBLI = 'sbli';

interface DataForm {
  content: string
  style_category: string
  data_category: string
  attribute_1_key: string
  attribute_1_value: string
  attribute_2_key: string
  attribute_2_value: string
  column: string
  attribute_3_value: string
}

const getProductHighlights = gql`query( $page_name: String!, $data_category: String! ){
  pageData(where: { page_name: $page_name, data_category: $data_category}){
    id
    attribute_1_value
    attribute_2_value
    attribute_3_value
    column
    content
    attribute_1_key
    attribute_2_key
    style_category
    data_category
  }
}`;

const dataQuery = gql`query( $page_name: String! ){
  pageData(where: { page_name: $page_name }){
    id
    content
    style_category
    data_category
    attribute_1_key
    attribute_1_value
    attribute_2_key
    attribute_2_value
    attribute_3_value
    column
    version
  }
}`;
const dataQueryPacLife = gql`query{
  pageData(where: { page_name: ["faq_page", "paclife_faq_page"] }){
    id
    content
    style_category
    data_category
    attribute_1_key
    attribute_1_value
    attribute_2_key
    attribute_2_value
    attribute_3_value
    column
    version
  }
}`;

const faqSectionQuery = gql`query( $page_name: String! ){
  pageData(where: { page_name: $page_name }){
    id
    content
    style_category
    data_category
    attribute_1_key
    attribute_1_value
    attribute_2_key
    attribute_2_value
    attribute_3_value
    column
    version
  }
}`;

@Component({
  selector: 'app-carrier-plan',
  templateUrl: './carrier-plan.component.html',
  styleUrls: ['./carrier-plan.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SbliCarrierPlanComponent implements OnInit, OnChanges {
  productHighlights: any;
  section2: any;
  section1: any;
  @Input() carrier: string;
  @Input() show: string;
  section3: any;
  section_5: any;
  isSBLI: boolean;
  faq_heading = 'faq_heading';
  sbliPage = 'sbli_carrier_page';
  pacLifeNextLife = 'pacific_life_next_life_plan_page';
  pacLifePage = 'pacific_life_career_plan';
  faqHeadings: any;
  sectionArray: any = [];
  faqQuestion: any = [];
  lastOpenedFaq: number = null;
  lastOpenedQue: number = null;
  display = false;
  coversUpToAge: string;

  constructor(private apollo: Apollo, private modalService: NgbModal,) { }

  ngOnInit(): void {
    //only load data when called from select plan details
    if (true || this.show) {
      this.isSBLI = this.carrier == 'sbli' ? true : false;
      this.setCarrier();
    }
  }



  getformattedData2 = (data: Array<DataForm>) => {
    const output = {};
    console.log(data, 'section_5');
    for (let i = 0; i < data.length; i++) {
      const info = data[i];
      const key = 'column_' + info.column;

      if (undefined === output[key]) {
        output[key] = {};
      }
      const style = info.style_category;

      if (undefined === output[key][style]) {
        output[key][style] = {};
      }
      const { content, attribute_1_value } = info;
      output[key][style].content = content;
      output[key][style].src = attribute_1_value;
    }
    return output;
  };

  changeCarrier() {
    this.isSBLI = !this.isSBLI;
    this.carrier = this.isSBLI ? SBLI : PAC_LIFE;
    console.log('qqqqqq changeCarrier()',this.carrier, 'carrier');
    this.setCarrier();
  }

  getSection5() {
    this.apollo.watchQuery<any>({
      query: getProductHighlights,
      variables: {
        page_name: this.isSBLI ? this.sbliPage : this.pacLifeNextLife,
        data_category: 'section_5'
      }
    })
      .valueChanges
      .subscribe(
        ({ data }) => {
          this.section_5 = this.getformattedData2(data.pageData);
          // console.log(this.section_5, "section_5");
        },
        (error) => {
          console.log(error);
        });
    //fmtData2 = getformattedData2(pageData, uniqueSectionData);;
  }

  getLifePlanPage() {
    // FAQ Section
    this.apollo.watchQuery<any>({
      query: faqSectionQuery,
      variables: {
        page_name: this.isSBLI ? this.sbliPage : 'paclife_faq_page_section'
      }
    })
      .valueChanges
      .subscribe(
        ({ data, loading }) => {
          const { pageData } = data;
          // console.log("faqSectionQuery:pageData:", pageData);
          //           {__typename: "PageData", id: "357", content: "About Next Term Life", style_category: "faq_question", data_category: "section", …}
          // 1: {__typename: "PageData", id: "358", content: "Eligibility", style_category: null, data_category: "section", …}
          // let allData = pageData.map(e => {
          //   return {
          //     [e.attribute_3_value]: e.content,
          //   }
          // })
          const allData = {};
          Object.entries(pageData).forEach(([index, item]) => {
            // console.log("faqSectionQuery:pageData:item:", item, "# item['content']: ", item['content']);
            // console.log("allData[item['attribute_3_value']]: ", allData[item['attribute_3_value']]);
            allData[item['attribute_3_value']] = item['content'];
          });
          console.log('allData: ', allData);
          const sectionArray = new Set();
          Object.entries(allData).forEach(([key, value]) => {
            sectionArray.add(value);
          });
          this.sectionArray = [...sectionArray];
          this.getFaqHeading();
          // console.log("this.sectionArray: ", this.sectionArray);

          this.apollo.watchQuery<any>({
            query: this.isSBLI ? dataQuery : dataQueryPacLife
          })
            .valueChanges
            .subscribe(
              ({ data, loading }) => {
                const { pageData } = data;
                // console.log("pageData:", pageData)
                const allData = pageData.map(e => {
                  return {
                    content: e.content,
                    section: e.attribute_1_value,
                    style: e.style_category,
                    column: e.column,
                    [e.attribute_1_key]: e.attribute_1_value,
                    que: e.attribute_2_key,
                    questionOrder: e.attribute_3_value,
                    src: 'https://devuiassetscdn.getmybubble.io/images/chevron-down.jpg',
                  };
                });
                const formattedData = this.getFormattedFAQData(allData);
                let idx = 0;
                this.faqQuestion = [];

                // console.log(Object.entries(formattedData))
                Object.entries(formattedData).forEach(e => {
                  // console.log(e[1])
                  // console.log(e[1][1].content)
                  // console.log(e)

                  Object.values(e[1]).forEach(i => {
                    const key: string = i.title;
                    this.faqQuestion.push(i);
                    idx++;
                  });
                });
                console.log(this.faqQuestion, 'faqQuestion');
              },

              (error) => {
                console.log(error);
              });

        },

        (error) => {
          console.log(error);
        });
  }

  getProudctHighlights() {
    this.apollo.watchQuery<any>({
      query: getProductHighlights,
      variables: {
        page_name: this.isSBLI ? this.sbliPage : this.pacLifePage,
        data_category: 'product_highlights_terms'
      }
    })
      .valueChanges
      .subscribe(
        ({ data, loading }) => {
          this.productHighlights = data.pageData.map(e => e);
        },
        (error) => {
          console.log(error);
        });
  }

  getFormattedFAQData(data: Array<any>) {
    const output: any = {};

    let section: any;
    let column: any;
    /*content: e.content,
              section: e.attribute_1_value,
              style: e.style_category,
              column: e.column,
              [e.attribute_1_key]: e.attribute_1_value,
              que: e.attribute_2_key,
    */
    let tempSection;
    Object.entries(this.sectionArray).forEach(([index, thisSection]) => {
      tempSection = [];
      // console.log("thisSection: ", thisSection);
      output['' + thisSection] = {};
      section = data.filter((item) => item.section === thisSection && item);
      // console.log("section: ", section);

      // Deal with sections' question orders
      // questionOrder
      const sectionHash = {};
      for (let index1 = 0; index1 < section.length; index1 += 1) {
        // console.log("ordered-section[index1]: ", section[index1]);
        // console.log("ordered-section[index1].questionOrder: ", section[index1].questionOrder);
        sectionHash[section[index1].questionOrder] = section[index1];
      }
      Object.entries(sectionHash).forEach(([index, element]) => {
        tempSection.push(element);
      });
      // console.log("ordered-tempSection: ", tempSection);
      column = tempSection.filter((item) => item.column === 1 && item);
      // console.log("ordered-column: ", column);
      output['' + thisSection] = column;
    });
    console.log('getFormattedFAQData:output:', output);
    return output;
  }

  getFormattedData(data: Array<any>, sectionsArray: Array<any>) {
    const output: any = {};

    let section: any;
    let column: any;
    sectionsArray.forEach(thisSection => {
      output[thisSection] = {};
      section = data.filter((item) => item.section === thisSection && item);
      { /*  for (let col = 1; col < 5; col++) {
        output[thisSection]['column_' + col] = {};
        column = section.filter((item) => item.column === col && item);
        for (let j = 0; j < column.length; j++) {
          output[thisSection]['column_' + col][column[j].style] = column[j];
        }

        if (Object.keys(output[thisSection]['column_' + col]).length === 0) {
          delete output[thisSection]['column_' + col];
        }
      }*/ }

      column = section.filter((item) => item.column === 1 && item);
      // console.log(thisSection);
      output[thisSection] = column;

    });
    console.log(output);
    return output;
  }

  getSBLISection2() {
    this.apollo.watchQuery<any>({
      query: getProductHighlights,
      variables: {
        page_name: 'sbli_carrier_page',
        data_category: 'section_2'
      }
    })
      .valueChanges
      .subscribe(
        ({ data, loading }) => {
          //    console.log(data)
          this.section2 = data.pageData.map(e => e);
          //     console.log(this.section2)

        },
        (error) => {
          console.log(error);
        });
  }



  openFaqsQue(i: number) {

    if (this.lastOpenedQue !== null) {
      if (this.faqQuestion[this.lastOpenedQue].isOpen && this.lastOpenedQue != i) {
        this.faqQuestion[this.lastOpenedQue].src = 'https://devuiassetscdn.getmybubble.io/images/chevron-down.jpg';
        this.faqQuestion[this.lastOpenedQue].isOpen = false;
      }
    }

    if (this.faqQuestion[i].isOpen) {
      this.faqQuestion[this.lastOpenedQue].src = 'https://devuiassetscdn.getmybubble.io/images/chevron-down.jpg';
      this.faqQuestion[i].isOpen = false;
    } else {
      this.faqQuestion[i].isOpen = true;
      this.faqQuestion[i].src = 'https://devuiassetscdn.getmybubble.io/images/chevron-up.jpg';
    }

    if (this.lastOpenedQue != i)
      this.lastOpenedQue = i;
  }

  openFaqs(i: number) {

    if (this.lastOpenedFaq !== null) {
      if (this.faqHeadings[this.lastOpenedFaq].isOpen && this.lastOpenedFaq != i) {
        this.faqHeadings[this.lastOpenedFaq].src = 'https://devuiassetscdn.getmybubble.io/images/chevron-down.jpg';
        this.faqHeadings[this.lastOpenedFaq].isOpen = false;
      }
    }

    if (this.faqHeadings[i].isOpen) {
      this.faqHeadings[this.lastOpenedFaq].src = 'https://devuiassetscdn.getmybubble.io/images/chevron-down.jpg';
      this.faqHeadings[i].isOpen = false;
    } else {
      this.faqHeadings[i].isOpen = true;
      this.faqHeadings[i].src = 'https://devuiassetscdn.getmybubble.io/images/chevron-up.jpg';
    }

    if (this.lastOpenedFaq != i)
      this.lastOpenedFaq = i;
  }
  getFaqHeading() {
   // debugger;
    this.apollo.watchQuery<any>({
      query: getProductHighlights,
      variables: {
        page_name: this.sbliPage,
        data_category: this.faq_heading
      }
    })
      .valueChanges
      .subscribe(
        ({ data, loading }) => {
          this.faqHeadings = this.sectionArray.map((e: any) => { return { content: e, src: 'https://devuiassetscdn.getmybubble.io/images/chevron-down.jpg' }; });
          //   console.log(this.faqHeadings, 'faq')
          console.log(this.faqHeadings, 'faqQuestion faqHeading');
        },
        (error) => {
          console.log(error);
        });
  }

  getPacLifeHighlights() {
    this.apollo.watchQuery<any>({
      query: getProductHighlights,
      variables: {
        page_name: this.pacLifePage,
        data_category: 'product_highlights_terms'
      }
    })
      .valueChanges
      .subscribe(
        ({ data, loading }) => {
          //   console.log(data)
          this.productHighlights = data.pageData.map(e => e);
          //    console.log(this.productHighlights)
        },
        (error) => {
          console.log(error);
        });
  }

  getSection1() {
    this.apollo.watchQuery<any>({
      query: getProductHighlights,
      variables: {
        page_name: this.isSBLI ? this.sbliPage : 'pacific_life_career_plan',
        data_category: 'section_1'
      }
    })
      .valueChanges
      .subscribe(
        ({ data, loading }) => {
          //    console.log(data)
          this.section1 = data.pageData.map(e => e);
          //      console.log('section1', this.section1)
        },
        (error) => {
          console.log(error);
        });
  }

  getPacLifeSection3() {
    this.apollo.watchQuery<any>({
      query: getProductHighlights,
      variables: {
        page_name: this.pacLifeNextLife,
        data_category: 'section_3'
      }
    })
      .valueChanges
      .subscribe(
        ({ data }) => {

          this.section3 = data.pageData.map(
            (e: DataForm) => {
              return { content: e.content, src: e.attribute_1_value, tooltip: e.attribute_3_value };
            });
          console.log([...this.section3], 'this.section3');
        },
        (error) => {
          console.log(error);
        });
  }


  setCarrier() {
    //  this.carrier = this.isSBLI ? 'paclife' : 'sbli'
    //   this.isSBLI = this.carrier.includes('sbli')
    console.log('qqqqq isSBLI:', this.isSBLI, 'carrier:', this.carrier);
    if (this.isSBLI || this.carrier == 'sbli') {
      this.getSBLISection2();
      // this.getSBLIsection1();
    } else {
      this.getPacLifeSection3();
      //this.getSection5();
    }
    this.getSection5();
    this.getSection1();
    this.getProudctHighlights();
    this.getLifePlanPage();
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log('qqqqqq In ngOnChanges', changes, changes.carrier, changes.carrier.currentValue);
    if (changes.carrier && changes.carrier.currentValue && changes.carrier.currentValue == 'sbli'){
      this.isSBLI = true;
    } else {
      this.isSBLI = false;
    }
    //this.isSBLI = changes.carrier && changes.carrier.currentValue && changes.carrier.currentValue.includes('sbli');
    this.setCarrier();
  }
}
