import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getQuestionAnswerJson,getQuestionAnswerByPassingInput} from "../../../../appUtility/appUtility";

@Component({
  selector: 'app-has-stroke',
  templateUrl: './has-stroke.component.html',
  styleUrls: ['./has-stroke.component.css']
})
export class HasStrokeComponent implements OnInit {
  @Output() nextquestion14 = new EventEmitter();

  constructor() { }
  questionAnswersJSON:[];
  ngOnInit(): void {
    this.questionAnswersJSON= getQuestionAnswerJson;
    console.log(getQuestionAnswerJson);
  }

  SelectedOption:any;
  public GetOptionsyesno(data){
    
    this.SelectedOption=data;
    if(this.SelectedOption==0){
      document.getElementById('StrokeNo').style.border='solid 2px orange';
      document.getElementById('Strokeyes').style.border='solid 1px #dae7ec';
     
    }else{
     document.getElementById('Strokeyes').style.border='solid 2px orange';
     document.getElementById('StrokeNo').style.border='solid 1px #dae7ec';
    }
  }
  next() {
    let etty = {
      "questionNo": "14",
      "nextprev": 1
    }
    this.nextquestion14.emit(etty);
  }

}
