import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-wns00101260',
  templateUrl: './wns00101260.component.html',
  styleUrls: ['./wns00101260.component.css']
})
export class Wns00101260Component implements OnInit {
  uscitizen: any;
  questions: any;
  existingData: any;
  @Output() changeQuestion = new EventEmitter();
  sbli_answer_data = {};
  clientId: any;
  showLoader = true;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger
    this.clientId = localStorage.getItem('clientID');
    this.pc.getAppData(this.clientId).then(data => {
      debugger
      let dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-wns00101260')[0];
        console.log('app-wnsi00101260 questions : ', this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-wns00101260')[0];
        console.log('app-wnsi00101260 questions : ', this.questions);
      }


      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_us != undefined)
        this.uscitizen = this.existingData.sbli_us;
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
  }


  next(value) {
    debugger;
    // this.sbli_answer_data[this.questions.radio_options[0].answer_key]= value;
    this.uscitizen = value;
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    json.SBLI[this.questions.radio_options[0].answer_key] = value;

    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }


}
