import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-is-active-military',
  templateUrl: './is-active-military.component.html',
  styleUrls: ['./is-active-military.component.css']
})
export class IsActiveMilitaryComponent implements OnInit {
  Disablebtn: boolean;

  SelectedOption: any;
  SecondQuestion: any;
  ActiveTickIcons: boolean;
  InActiveTickIcon: boolean;
  ActiveCrossIcons: boolean;
  InActiveCrossIcons: boolean;
  selectedOptionValue: any;
  showloader:boolean;
  constructor(
    private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,
    private service:ParentChildService) { }
  @Input() questionDetails: [];
  isActiveMilitaryDetails: any;
  //@Output() isActiveMilitaryOutput = new EventEmitter();

  @Output() public isActiveMilitaryOutput =
    new EventEmitter<{ component_id: any, answer: any }>();


  ngOnInit(): void {
    debugger;
    this.service.callComponentMethod();
    this.showloader=true;
    if (localStorage.getItem('bubble-isActiveMilitary') == null || localStorage.getItem('bubble-isActiveMilitary') == undefined) {

      console.log(this.questionDetails);
      this.InActiveTickIcon = true;
      this.ActiveTickIcons=false;
      this.InActiveCrossIcons = true;
      this.ActiveCrossIcons=false;
      this.showloader=false;
    }
    else {

      this.pc.getAppData(localStorage.getItem('clientID')).then(
        data => {
          debugger;
          const clientDataFiltered = JSON.parse(data.Payload);
          this.isActiveMilitaryDetails = clientDataFiltered.body.isActiveMilitary;
          // this.isActiveMilitaryDetails = localStorage.getItem("bubble-isActiveMilitary");
          if (this.isActiveMilitaryDetails == 'No') {
            var data1 = 0;
          } else {
            var data1 = 1;
          }
          this.SelectedOption = data1;
          if (this.SelectedOption == 0) {
            this.selectedOptionValue = 'No';
            this.ActiveTickIcons = false;
            this.InActiveTickIcon = true;
            this.ActiveCrossIcons = true;
            this.InActiveCrossIcons = false;
          } else {
            this.selectedOptionValue = 'Yes';
            this.ActiveTickIcons = true;
            this.InActiveTickIcon = false;
            this.ActiveCrossIcons = false;
            this.InActiveCrossIcons = true;
          }
          this.showloader=false;
        }

      ).catch(error => {
        console.log(error);
      });
    }
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_3_Military'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
  }


  inputJson: any;
  public onSubmit(data) {
    debugger;
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);
      // this.inputJson={
      //   'id': localStorage.getItem('clientID'),
      //   'isActiveMilitary' : this.selectedOptionValue
      // }
      this.inputJson = {};
      this.inputJson['id'] = localStorage.getItem('clientID');
      this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;
      this.isActiveMilitaryOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
      this.submitdata(this.inputJson);
    } else {
      alert('Declined!');
    }

    this.$gaService.event('Next', 'event', 'LifeAppServeMiliatry');
    const eventOptions: EventOption = {
      page_title: 'Served Military',
      path: 'AppFlowStart',
      url: window.location.href
    };

    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppServeMiliatry');
  }

  public goBackToSSN() {
    debugger;
    //  this.isActiveMilitaryOutput.emit(this.questionDetails["component_id"]+",Goback");
    this.isActiveMilitaryOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }


  public submitdata(inputJson) {

    localStorage.setItem('bubble-isActiveMilitary', this.selectedOptionValue);
    this.pc.saveUpdateClientData(inputJson);
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_3_Military',
      active_retired_military_personnel : this.selectedOptionValue

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

  }
  public GetOptions(data) {

    this.SelectedOption = data;
    if (this.SelectedOption == 0) {
      this.selectedOptionValue = 'No';
      //  document.getElementById('0').style.border='solid 2px orange';
      //  document.getElementById('1').style.border='solid 1px #dae7ec';
      this.ActiveTickIcons = false;
      this.InActiveTickIcon = true;
      this.ActiveCrossIcons = true;
      this.InActiveCrossIcons = false;
    } else {
      this.selectedOptionValue = 'Yes';
      // document.getElementById('1').style.border='solid 2px orange';
      // document.getElementById('0').style.border='solid 1px #dae7ec';
      this.ActiveTickIcons = true;
      this.InActiveTickIcon = false;
      this.ActiveCrossIcons = false;
      this.InActiveCrossIcons = true;
    }
  }


}
