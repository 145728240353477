import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SecurityService } from './security.service';
const CLASS_NAME = ':scorecard-api.service';

declare let require: any;
const pc = require('bit-uibl-data-driver');

const queryLifeScoreWithGenderAge = gql`
query($get1: String, $get2: String, $get3: String, $get4: String){
  lifeScores(where:{ get1: $get1, get2: $get2, get3: $get3, get4: $get4, version: "v4"}){
    put1, put2, put3,put4,put5,put10,put11,put13,put14, put16,put17,put19,put21
  }
}
`;

const getLifeScoreSection = gql`
  query($get7: String!,$version:String!){
    lifeScores(where:{ get7: $get7, version: $version}){
      dget7, put8, put9, put10, put11
    }
  }
`;
function prepareInputs4LifeScore(thisSecurity, county, stateCode, gender = '1', age = '25-35') {
  let get1, get2, get3, get4, ageRange, ageNumber;
  // TODO ageRange = getAgeRangefromAge(age);

  ageRange = age;
  // geoid_county_fip=>get1,county=>get2,state_code=>get3,weighted_avg_is=>put4,gender=>get5,age=>get6
  if (gender == null) { gender = '0'; }
  /*  try {
     if (age.indexOf('-') != -1) { // Logic to support Age range via dropdown
       ageRange = age;
     } else { ageNumber = Number(age); }
   } catch (error) { ageNumber = 30; } */
  /* ageRange = ageNumber <= 35 ? "25-35" : null; get1->county, StateCode ->get2, ageRange -> get3, gender -> get4
  ageRange = ageRange == null && ageNumber <= 45 ? "35-45" : ageRange;
  ageRange = ageRange == null && ageNumber <= 55 ? "45-55" : ageRange;
  ageRange = ageRange == null && ageNumber <= 65 ? "55-65" : ageRange;
  ageRange = ageRange == null && ageNumber <= 75 ? "65-75" : ageRange; */
  get1 = thisSecurity.encryptCodes(county.replace('\'', ''));
  get2 = thisSecurity.encryptCodes(stateCode);
  get3 = thisSecurity.encryptCodes(ageRange);
  get4 = thisSecurity.encryptCodes(gender);






  return { get1: get1, get2: get2, get3: get3, get4: get4 };
}

// Todo : Remove processResponse4LifeScore() method if not used
/* function processResponse4LifeScore(response, thisSecurity) {
  let METHOD_NAME = ":processResponse4LifeScore:";

  let data = {
    success: true,
    lifeScore: Math.round(Number(thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put1)))),
    percentile: thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put3)),
    grade: '',
  };
  data["showScore"] = true;
  let value;
  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put4));
  data["medianIncome"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "positive"),
    'name': 'Median Income',
    'image_path': 'assets/images/images/income.svg',
    'type': "positive"
  };
  // dget8  med_income_bin
  // data["medianIncomePercent"] = pc.Utility.gradeToPercentage(value, "positive");

  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put5));
  data["familyUnity"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "positive"),
    'name': 'Family Unity',
    'image_path': 'assets/images/images/family.svg',
    'type': "positive"
  };
  // data["familyUnityPercent"] = pc.Utility.gradeToPercentage(value, "positive");

  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put11));
  data["foodEnvironmentIndex"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "positive"),
    'name': 'Food Environment Index',
    'image_path': 'assets/images/images/food.svg',
    'type': "positive"
  };
  // data["foodEnvironmentIndexPercent"] = pc.Utility.gradeToPercentage(value, "positive");

  // Access_to_Exercise_Opportunities_pct_With_Access_bin	`=>	dput17
  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put13));
  data["accessToExerciseOpportunities"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "positive"),
    'name': 'Access to exercise opportunities',
    'image_path': 'assets/images/images/exercise.svg',
    'type': "positive"
  };
  // data["accessToExerciseOpportunitiesPercent"] = pc.Utility.gradeToPercentage(value, "positive");

  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put19));
  data["informalCivicActivity"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "positive"),
    'name': 'Informal Civic Activity',
    'image_path': 'assets/images/images/civic-activity.svg',
    'type': "positive"
  };
  // data["informalCivicActivityPercent"] = pc.Utility.gradeToPercentage(value, "positive");

  // Adult_Obesity_pct_Obese_bin`=>	dput14
  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put14));
  data["adultObesity"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "negative"),
    'name': 'Adult Obesity',
    'image_path': 'assets/images/images/obesity.svg',
    'type': "negative"
  };
  // data["adultObesityPercent"] = pc.Utility.gradeToPercentage(value, "negative");

  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put10));
  data["violentCrimeIndicator"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "negative"),
    'name': 'Violent Crime Rate',
    'image_path': 'assets/images/images/crime-rate.svg',
    'type': "negative"
  };
  // data["violentCrimeIndicatorPercent"] = pc.Utility.gradeToPercentage(value, "negative");
  // Injury_Deaths 18
  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put16));
  data["injuryDeathIndicator"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "negative"),
    'name': 'Injury Death Rate',
    'image_path': 'assets/images/images/death-rate.svg',
    'type': "negative"
  };
  // data["injuryDeathIndicatorPercent"] = pc.Utility.gradeToPercentage(value, "negative");
  // Air_Pollution_Average_Daily_PM2_5_bin`=> dput21
  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put17));
  data["airPollutionIndicator"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "negative"),
    'name': 'Air Pollution',
    'image_path': 'assets/images/images/air-pollution.svg',
    'type': "negative"
  };
  // data["airPollutionIndicatorPercent"] = pc.Utility.gradeToPercentage(value, "negative");

  value = thisSecurity.decryptCodes(thisSecurity.getNumberArrayFrom(response.put21));
  data["mentalDistress"] = {
    'value': value,
    'percentage': pc.Utility.gradeToPercentage(value, "negative"),
    'name': 'Frequent mental distress',
    'image_path': 'assets/images/images/mental-distress.svg',
    'type': "negative"
  };
  // data["mentalDistressPercent"] = pc.Utility.gradeToPercentage(value, "negative");

  return data;
} */
function processResponse4LifeScore(response, thisSecurity) { }

@Injectable({
  providedIn: 'root'
})
export class ScorecardApiService {

  constructor(private apollo: Apollo,
    private security: SecurityService) { }

  private lifescore = new BehaviorSubject<any>({
    success: true,
    lifeScore: 0.00,
    percentile: 0.00,
    showScore: false
  });
  castLifescore = this.lifescore.asObservable();

  sectionData = new BehaviorSubject<any>('');
  castSectionData = this.sectionData.asObservable();

  getHomeScore(zip: string | number) {
    const get1 = this.security.encryptCodes(zip);
    return get1;
  }

  getLifeScore(county: string, state: string) {
    const data = {};
    const get1 = this.security.encryptCodes(county);
    const get2 = this.security.encryptCodes(state);
    // const [get2, get3] = ["Denver County", "CO"]



    const variables = prepareInputs4LifeScore(this.security, county, state);

    this.apollo.watchQuery<any>({
      query: queryLifeScoreWithGenderAge,
      variables: variables,
    })
      .valueChanges
      .subscribe(
        ({ data, loading }) => {
          const response = data.lifeScores[0];
          data = processResponse4LifeScore(response, this.security);
          data.showScore = true;
          this.lifescore.next(data);
        },
        (error) => {

          // return error;
          error.showScore = true;
          this.lifescore.next(error);
        });
  }

  async getScoreFromCounty({ county, state, ageRange, gender }) {
    let apiKey = 'd0ecb36d31ffbd0bf76ab7f37a2aef2d';
    if (window.location.protocol === 'http:') {
      apiKey = '8e1a386fcb52a26c711c404a1c32487a';
    }

    const res = await fetch(environment.Nest_API_URL + `/scores/life?county=${county}&state=${state}&ageRange=${ageRange}&gender=${gender}`,
      {
        headers: { 'x-api-key': apiKey }
      });
    const data = await res.json();

    this.lifeScoreData(data);
  }

  async lifeScoreData(data) {
    if (data.message) {
      this.lifescore.next({
        success: false,
        lifeScore: 0.00,
        alertPopup: 'ZERO_VALUE',
        showScore: true,
        quoteAmount: 0,
        scores: []
      });

    } else {

      const scoreArray = [];

      const scoresData = {
        success: true,
        lifeScore: data.lifeScore,
        percentile: 0,
        grade: '',
        quoteAmount: 0,
        scores: scoreArray,
        showScore: true
      };

      const dataArray = [
        {
          'name': 'Median Income',
          'indicator': 'Very High',
          'extras': {
            'percentage': {
              'outputPercentage': '100',
              'barType': 'success'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/income.svg',
            'type': 'positive',
            'tool_tip': 'The household income where half of households in a county earn more and half of households earn less.'
          }
        },
        {
          'name': 'Family Unity',
          'indicator': 'Low',
          'extras': {
            'percentage': {
              'outputPercentage': '40',
              'barType': 'danger'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/family.svg',
            'type': 'positive',
            'tool_tip': 'An index reflecting family level features, such as births to unmarried women, divorce rate, single parents, percent married living jointly etc.'
          }
        },
        {
          'name': 'Adult Obesity',
          'indicator': 'Very Low',
          'extras': {
            'percentage': {
              'outputPercentage': '20',
              'barType': 'success'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/obesity.svg',
            'type': 'negative',
            'tool_tip': 'Percentage of adult population age 20 and older that reports a BMI greater than or equal to 30'
          }
        },
        {
          'name': 'Food Environment Index',
          'indicator': 'Very High',
          'extras': {
            'percentage': {
              'outputPercentage': '100',
              'barType': 'success'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/food.svg',
            'type': 'positive',
            'tool_tip': 'Index of factors contributing to a healthy food environment based on access and proximity to grocery stores, expenditures on fast foods, food prices etc'
          }
        },
        {
          'name': 'Access to exercise opportunities',
          'indicator': 'Very High',
          'extras': {
            'percentage': {
              'outputPercentage': '100',
              'barType': 'success'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/exercise.svg',
            'type': 'positive',
            'tool_tip': 'Percentage of population with adequate access to locations for physical activity'
          }
        },
        {
          'name': 'Violent Crime Rate',
          'indicator': 'High',
          'extras': {
            'percentage': {
              'outputPercentage': '80',
              'barType': 'danger'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/crime-rate.svg',
            'type': 'negative',
            'tool_tip': 'Number of reported violent crime offenses per 100,000 population.'
          }
        },
        {
          'name': 'Injury Death Rate',
          'indicator': 'Very Low',
          'extras': {
            'percentage': {
              'outputPercentage': '20',
              'barType': 'success'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/death-rate.svg',
            'type': 'negative',
            'tool_tip': 'Number of deaths due to injury per 100,000 population.'
          }
        },
        {
          'name': 'Air Pollution',
          'indicator': 'Very High',
          'extras': {
            'percentage': {
              'outputPercentage': '100',
              'barType': 'danger'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/air-pollution.svg',
            'type': 'negative',
            'tool_tip': 'Average daily density of fine particulate matter in micrograms per cubic meter - PM 2.5'
          }
        },
        {
          'name': 'Informal Civic Activity',
          'indicator': 'Average',
          'extras': {
            'percentage': {
              'outputPercentage': '50',
              'barType': 'warning'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/civic-activity.svg',
            'type': 'positive',
            'tool_tip': 'An index reflecting participation in both formal and informal activities, such as volunteering, participation in group activities, membership in clubs, recreational group activities etc.'
          }
        },
        {
          'name': 'Frequent Mental Distress',
          'indicator': 'Very Low',
          'extras': {
            'percentage': {
              'outputPercentage': '20',
              'barType': 'success'
            },
            'image_path': 'https://public-bubble.s3-us-west-2.amazonaws.com/images/images/mental-distress.svg',
            'type': 'negative',
            'tool_tip': 'Percentage of adults reporting 14 or more days of poor physical or mental health by month'
          }
        },
      ];
      const map = {
        'Very High': 100,
        High: 75,
        'Very Low': 25,
        Low: 45,
        Average: 60,
      };
      data.positiveImpact.forEach(
        ({ factorLabel, factorScore }) => {

          const item = dataArray.find(({ name }) => {
            return name === factorLabel;
          });
          if (item) {
            item.indicator = factorScore;
            item.extras.percentage.outputPercentage = map[factorScore];
          }
          scoreArray.push(item);
        });

      data.negativeImpact.forEach(
        ({ factorLabel, factorScore }) => {

          const item = dataArray.find(({ name }) => {
            return name === factorLabel;
          });

          if (item.indicator) {
            item.indicator = factorScore;
            item.extras.percentage.outputPercentage = map[factorScore];
          }
          scoreArray.push(item);
        });
      scoresData.quoteAmount = data.life_output.monthly_quote_amt;
      scoresData.scores = scoreArray;
      scoresData['alertPopup'] = 'ZERO_VALUE';

      this.lifescore.next(scoresData);
    }
  }

  async newLifeScoreAPI(gender, ageRange, zip) {
    let apiKey = 'd0ecb36d31ffbd0bf76ab7f37a2aef2d';
    if (window.location.protocol === 'http:') {
      apiKey = '8e1a386fcb52a26c711c404a1c32487a';
    }

    if (!zip) {
      this.lifescore.next({
        success: false,
        lifeScore: 0.00,
        alertPopup: 'INVALID_ADDRESS',
        showScore: true,
        scores: []
      });
      return;
    }
    const res = await fetch(environment.Nest_API_URL + `/scores/life?zipCode=${zip}&ageRange=${ageRange}&gender=${gender}`,
      {
        headers: { 'x-api-key': apiKey }
      });
    const data = await res.json();
    this.lifeScoreData(data);
  }

  getLifeScoreWithGenderAge(county: string, state: string, gender: string, age: string) {
    let variables, scoresData;
    const scores = new pc.Scores();
    variables = prepareInputs4LifeScore(pc.SecurityHelper1, county, state, gender, age);



    scores.getScores(variables.get1, variables.get2, variables.get3, variables.get4)
      .then(response => {
        const data = response.data;
        let jsonData;
        let decryptedData;
        console.log(' getScores() success 1: ', data);
        // console.log("getScores() success: ", JSON.parse(data.output));
        if (data.output) {
          jsonData = JSON.parse(data.output);
          decryptedData = this.getDecryptedJsonData(jsonData);

          scoresData = this.processScoresData(scores.getFormattedScoresData(decryptedData));
          scoresData['alertPopup'] = 'ZERO_VALUE';
          this.lifescore.next(scoresData);

        } else {
          /* console.log("getScores() failure: ",{
            success: false,
            lifeScore: 0.00,
            alertPopup: "INVALID_ADDRESS"
          }); */
          this.lifescore.next({
            success: false,
            lifeScore: 0.00,
            alertPopup: 'INVALID_ADDRESS',
            showScore: true
          });
        }
      })
      .catch(error => {
        this.lifescore.next(error);
      });

    /* query = queryLifeScoreWithGenderAge;

    this.apollo.watchQuery<any>({
      query: query, variables: variables,
    }).valueChanges
      .subscribe(({ data, loading }) => {
        let response = data.lifeScores[0];
        if (response){
          data = processResponse4LifeScore(response, this.security);
          this.lifescore.next(data);
        } else {
          this.lifescore.next({
            success: false,
            lifeScore: 0.00
          });
        }
      },
        (error) => {
          this.lifescore.next(error);
        }); */
  }

  processScoresData(scoreArray) {
    const data = {
      success: true,
      lifeScore: 0,
      percentile: 0,
      grade: '',
      scores: scoreArray,
      showScore: true
    };

    for (let i = 0; i < scoreArray.length; i++) {
      if (scoreArray[i]['name'] == 'Weighted Avg Is2') {
        if (/\d/.test(scoreArray[i]['indicator'])) {
          data['lifeScore'] = scoreArray[i]['indicator'];
        } else {
          data['success'] = false;
        }
      }
      if (scoreArray[i]['name'] == 'Percentile') {
        data['percentile'] = +scoreArray[i]['indicator'];
      }
    }
    return data;
  }

  getDecryptedJsonData(encryptedJson) {

    const decryptedData = encryptedJson;

    for (const key in encryptedJson) {
      if (key != 'id' && !key.includes('dput')) {
        decryptedData[key] = this.getDecryptedData(encryptedJson[key]);
      }
    }

    return decryptedData;
  }

  getDecryptedData(encryptedVal) {
    return pc.SecurityHelper1.decryptCodes(pc.SecurityHelper1.getNumberArrayFrom(encryptedVal));
  }

  getSectionData(category: string) {

    let version = 'v2';
    if (category == 'life_in_paradise') {
      version = 'v3';
    }
    const categoryLocal = this.security.encryptCodes(category);
    this.apollo.watchQuery<any>({
      query: getLifeScoreSection,
      variables: { get7: categoryLocal, version: version },
    })
      .valueChanges
      .subscribe(
        ({ data, loading }) => {

          const sectiondata = {};
          sectiondata[category] = data.lifeScores.map(s => ({
            'className': this.security.decryptCodes(this.security.getNumberArrayFrom(s.put8)),
            'title': this.security.decryptCodes(this.security.getNumberArrayFrom(s.put9)),
            'body': this.security.decryptCodes(this.security.getNumberArrayFrom(s.put10)),
            'imagepath': this.security.decryptCodes(this.security.getNumberArrayFrom(s.put11)),
          }));

          this.sectionData.next(sectiondata);
        },
        (error) => {

        }
      );
  }

}
/*



*/
