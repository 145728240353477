import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';

const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-sw4pt35',
  templateUrl: './sw4pt35.component.html',
  styleUrls: ['./sw4pt35.component.css']
})
export class Sw4pt35Component implements OnInit {

 @Output() nextquestion21 = new EventEmitter();

  dynamicdiv = [];

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  showoptions: string;
  ItemClassList: any=[];
  existingData: any;
  lenderList: any;
  addanotherBtn: string;
  loader: boolean;
  addLender: boolean;
  sw_mortgage: string;
  lenderAddressObject: any;
  lenderName: any;
  lenderAddress: string;
  addLenderButton: boolean;
  idToCreate: any;

  constructor(private $gaService: GoogleAnalyticsService,private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private modalService: NgbModal,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    // this.uniqueId = 'nkowu4-285-Bryant-St-Palo-Alto-CA-94301';
    // this.clientId = 'nkowu4';
    this.loader = true;
    this.addLenderButton = true;
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt35')[0];
    console.log('sw questions app-sw4pt35 : ', this.questions);
    const quickquote = new pc.Quotes(this.uniqueId);
    this.lenderList = quickquote.getSWLenderOptions();
    console.log('lenderList : ', this.lenderList);
    this.lenderAddress = '';
    this.lenderName = '';
    this.idToCreate = '';
    // let llist = this.lenderList;
    // this.lenderList = [];
    // for (let i = 0; i < llist.length; i++){
    //   let splitdetails = llist[i].split("--");
    //   var etty = {
    //     "company": splitdetails[0],
    //     "address": splitdetails[1]
    //   }
    //   this.lenderList.push(etty);
    // }
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.stillwater;
      console.log('data from getAppData sw : ', this.existingData);
      if (this.existingData['sw_mortgage'] == 'yes') {
        this.questions.radio_options[0].active = true;
        this.questions.radio_options[1].active = false;
        this.sw_mortgage = 'yes';
        this.disable = false;
        this.showoptions = '1';
      }
      else if (this.existingData['sw_mortgage'] == 'no'){
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = true;
        this.sw_mortgage = 'no';
        this.disable = false;
        this.showoptions = '0';
      }
      else {
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = false;
        this.sw_mortgage = 'no';
        this.disable = true;
        this.showoptions = '0';
      }

      const existingData=this.existingData['sw_mortgageDetails'];
      //existingData = JSON.parse(existingData);
      console.log('existing data:',existingData);

      if (existingData == null || existingData == undefined || existingData == '') {
        const ettty = {
          'sw_mortgageLender': '',
          'sw_mortgageLoanNumber': '',
          'sw_mortgageFutureRenewal': '',
          'sw_mortgageLenderObject': ''
        };
        this.dynamicdiv.push(ettty);
      }
      else {
        this.dynamicdiv = this.existingData['sw_mortgageDetails'];
        const addedLender = this.dynamicdiv.filter(x => x.sw_mortgageLender == '0');
        if (addedLender.length > 0) {
          this.addLenderButton = false;
          this.lenderList.push(addedLender[0].sw_mortgageLenderObject);
        }
        const addedLendertwo = this.dynamicdiv.filter(x => x.sw_mortgageLender == '00');
        if (addedLendertwo.length > 0) {
          this.addLenderButton = false;
          this.lenderList.push(addedLendertwo[0].sw_mortgageLenderObject);
        }
      }

      if (this.dynamicdiv.length<2) {
        this.addanotherBtn = '1';
      }
      else {
        this.addanotherBtn = '0';
      }
      this.loader = false;
      this.addLender = false;
    }).catch(error => {
      console.log(error);
    });

    // if (localStorage.getItem('sw_mortgage') == 'yes') {
    //   this.questions.radio_options[0].active = true;
    //   this.questions.radio_options[1].active = false;
    //   this.disable = false;
    //   this.showoptions = '1';
    // }
    // else if (localStorage.getItem('sw_mortgage') == 'no'){
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = true;
    //   this.disable = false;
    //   this.showoptions = '0';
    // }
    // else {
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = false;
    //   this.disable = true;
    //   this.showoptions = '0';
    // }

    // var existingData=localStorage.getItem('sw_mortgageDetails');
    // existingData = JSON.parse(existingData);
    // console.log('existing data:',existingData);

    // if (existingData == null || existingData == undefined || existingData == '') {
    //    var ettty = {
    //     "sw_mortgageLender": '',
    //     "sw_mortgageLoanNumber": '',
    //     "sw_mortgageFutureRenewal": ''
    //   }
    //   this.dynamicdiv.push(ettty);
    // }
    // else {
    //   this.dynamicdiv = JSON.parse(localStorage.getItem('sw_mortgageDetails'));
    // }

    // if (this.dynamicdiv.length<2) {
    //   this.addanotherBtn = '1';
    // }
    // else {
    //   this.addanotherBtn = '0';
    // }

  }

  onYes() {
    this.questions.radio_options[0].active = true;
    this.questions.radio_options[1].active = false;
    localStorage.setItem('sw_mortgage', 'yes');
    this.sw_mortgage = 'yes';
    this.disable = false;
    this.showoptions = '1';
    if (this.dynamicdiv.length<2) {
      this.addanotherBtn = '1';
    }
    else {
      this.addanotherBtn = '0';
    }
  }

  onNo() {
    this.questions.radio_options[0].active = false;
    this.questions.radio_options[1].active = true;
    localStorage.setItem('sw_mortgage', 'no');
    this.sw_mortgage = 'no';
    this.disable = false;
    this.showoptions = '0';
  }

  splitLenderAddress(even) {
    //debugger
    let address = this.lenderList.filter(x => x.company == even.sw_mortgageLender);
    address = address[0].address;
    this.pc.parseAddressLocalAndPatch(address, this.clientId).then(
      data => {
        console.log('split lenderaddress : ', data);
        const entity = {
          'fullAddress': address,
          'address': data.door_number + ' ' + data.street ,//+ " " + data.street_type,
          'state': data.state_code,
          'city': data.city_name,
          'zip':data.zip
        };
        even.addressDetails = entity;
      }
    ).catch( error => console.log('error', error));
  }


  billYes(list, det) {
    list.sw_mortgageFutureRenewal = '';
    list.sw_mortgageFutureRenewal = det;
    //debugger
    if (det=='no') {
      this.dynamicdiv.find(x => x.sw_mortgageLender == list.sw_mortgageLender).sw_mortgageFutureRenewal = 'no';
      this.dynamicdiv.find(x => x.sw_mortgageLender != list.sw_mortgageLender).sw_mortgageFutureRenewal = 'yes';
    }
    if (det=='yes') {
      this.dynamicdiv.find(x => x.sw_mortgageLender == list.sw_mortgageLender).sw_mortgageFutureRenewal = 'yes';
      this.dynamicdiv.find(x => x.sw_mortgageLender != list.sw_mortgageLender).sw_mortgageFutureRenewal = 'no';
    }
  }


  AddDiv(){
    const ettty = {
      'sw_mortgageLender': '',
      'sw_mortgageLoanNumber': '',
      'sw_mortgageFutureRenewal': '',
      'sw_mortgageLenderObject': ''
    };
    this.dynamicdiv.push(ettty);
    if (this.dynamicdiv.length<2) {
      this.addanotherBtn = '1';
    }
    else {
      this.addanotherBtn = '0';
    }
  }

  RemoveRow(divindex) {
    this.dynamicdiv.splice(divindex, 1);
    if (this.dynamicdiv.length<2) {
      this.addanotherBtn = '1';
    }
    else {
      this.addanotherBtn = '0';
    }
  }



  addNewLender() {
    this.addLender = true;
    this.lenderAddress = '';
    this.lenderName = '';
  }

  closeNewLender() {
    this.addLender = false;
  }

  public handleAddressChange(address: Address) {
    this.loader = true;
    console.log('billingaddresshandles', address);
    console.log('formatted_billing_address ', address.formatted_address);
    const addressToFormat = address.formatted_address;
    this.lenderAddress = addressToFormat;
    const google_address = {
      'street_number_ln': '',
      'street_number_sn': '',
      'route_ln': '',
      'route_sn': '',
      'neighborhood_ln': '',
      'neighborhood_sn': '',
      'locality_ln': '',
      'locality_sn': '',
      'administrative_area_level_2_ln': '',
      'administrative_area_level_2_sn': '',
      'administrative_area_level_1_ln': '',
      'administrative_area_level_1_sn': '',
      'country_ln': '',
      'country_sn': '',
      'postal_code_ln': '',
      'postal_code_sn': '',
      'postal_code_suffix_ln': '',
      'postal_code_suffix_sn': '',
      'formatted_address': '',
      'clientId': '',
      'id': ''
    };
    //debugger
    for (let i = 0; i < address.address_components.length; i++) {
      debugger;
      const type = address.address_components[i].types[0];
      debugger;
      switch (type) {

        case 'street_number':
          //debugger
          google_address.street_number_ln = address.address_components[i].long_name;
          google_address.street_number_sn = address.address_components[i].short_name;
          break;
        case 'route':
          //debugger
          google_address.route_ln = address.address_components[i].long_name;
          google_address.route_sn = address.address_components[i].short_name;
          break;
        case 'neighborhood':
          //debugger
          google_address.neighborhood_ln = address.address_components[i].long_name;
          google_address.neighborhood_sn = address.address_components[i].short_name;
          break;
        case 'locality':
          //debugger
          google_address.locality_ln = address.address_components[i].long_name;
          google_address.locality_sn = address.address_components[i].short_name;
          break;
        case 'administrative_area_level_2':
          //debugger
          google_address.administrative_area_level_2_ln = address.address_components[i].long_name;
          google_address.administrative_area_level_2_sn = address.address_components[i].short_name;
          break;
        case 'administrative_area_level_1':
          //debugger
          google_address.administrative_area_level_1_ln = address.address_components[i].long_name;
          google_address.administrative_area_level_1_sn = address.address_components[i].short_name;
          break;
        case 'country':
          //debugger
          google_address.country_ln = address.address_components[i].long_name;
          google_address.country_sn = address.address_components[i].short_name;
          break;
        case 'postal_code':
          //debugger
          google_address.postal_code_ln = address.address_components[i].long_name;
          google_address.postal_code_sn = address.address_components[i].short_name;
          break;
        case 'postal_code_suffix':
          //debugger
          google_address.postal_code_suffix_ln = address.address_components[i].long_name;
          google_address.postal_code_suffix_sn = address.address_components[i].short_name;
          break;
      }
    }
    google_address.formatted_address = address.formatted_address;
    google_address.clientId = this.clientId;
    google_address.id = this.clientId;
    if (google_address.street_number_sn == '' || google_address.route_sn == '' || google_address.locality_sn == '' || google_address.administrative_area_level_2_sn == '' || google_address.administrative_area_level_1_sn == '' || google_address.postal_code_sn == '') {
      this.openModal();
      this.lenderAddress = '';
    }
    this.loader = false;
    // this.pc.parseAddressLocalAndPatch(addressToFormat, this.clientId).then(
    //   data => {
    //     debugger
    //     console.log("split lenderaddress : ", data);
    //     if (data.door_number == undefined || data.street == undefined || data.street_type == undefined ||
    //       data.state_code == undefined || data.city_name == undefined || data.zip == undefined) {
    //       this.openModal();
    //       this.lenderAddress = "";
    //     }
    //     this.loader = false;
    //   }
    // ).catch( error => console.log("error", error));
  }

  openModal() {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: 'The address you entered could not be validated. Please enter a full address.',
      image: 'assets/images/images/education2.png'
    };
    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }
  submitNewLender() {
    this.loader = true;
    this.addLender = false;
    const addressToFormat = this.lenderAddress;
    this.addLenderButton = false;
    this.idToCreate = this.idToCreate + '0';
    this.pc.parseAddressLocalAndPatch(addressToFormat, this.clientId).then(
      data => {
        debugger;
        console.log('split lenderaddress : ', data);
        const entity = {
          'id': this.idToCreate,
          'address1': '',
          'address2': data.door_number + ' ' + data.street,// + " " + data.street_type,
          'lenderName': this.lenderName,
          'state':data.state_code,
          'city': data.city_name,
          'zipcode': data.zip,
          'text': this.lenderName + '--' + addressToFormat
        };
        console.log('lenderAddress object : ', entity);
        this.lenderAddressObject = entity;
        this.lenderList.push(this.lenderAddressObject);
        if (this.dynamicdiv[this.dynamicdiv.length - 1].sw_mortgageLender == '') {
          this.dynamicdiv = [];
          var ettty = {
            'sw_mortgageLender': this.idToCreate,
            'sw_mortgageLoanNumber': '',
            'sw_mortgageFutureRenewal': '',
            'sw_mortgageLenderObject': this.lenderAddressObject
          };
          this.dynamicdiv.push(ettty);
        }
        else if (this.dynamicdiv.length == 1){
          var ettty = {
            'sw_mortgageLender': this.idToCreate,
            'sw_mortgageLoanNumber': '',
            'sw_mortgageFutureRenewal': '',
            'sw_mortgageLenderObject': this.lenderAddressObject
          };
          this.dynamicdiv.push(ettty);
        }
        else {

        }
        this.loader = false;
        console.log('dynamicdiv : ', this.dynamicdiv);
        if (this.dynamicdiv.length<2) {
          this.addanotherBtn = '1';
        }
        else {
          this.addanotherBtn = '0';
        }
      }
    ).catch( error => console.log('error', error));
  }


  next() {
    this.loader = true;
    if (this.sw_mortgage == 'yes') {
      for (let i = 0; i < this.dynamicdiv.length; i++){
        const lenderObject = this.lenderList.filter(x => x.id == this.dynamicdiv[i].sw_mortgageLender);
        this.dynamicdiv[i].sw_mortgageLenderObject = lenderObject[0];
      }
    }
    else {
      this.dynamicdiv = [];
    }
    console.log('table data : ', this.dynamicdiv);
    console.table(this.dynamicdiv);
    localStorage.setItem('sw_mortgageDetails', JSON.stringify(this.dynamicdiv));
    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };
    json.stillwater['sw_mortgage'] = this.sw_mortgage;
    json.stillwater['sw_mortgageDetails'] = this.dynamicdiv;
    //json.stillwater['sw_mortgageCustomLender'] = this.lenderAddressObject;
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(data => {
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0,
      };
      this.nextquestion21.emit(etty);
    }).catch(error => {
      console.log(error);
    });
    this.$gaService.event('Next', 'event', 'HomeAppMortgageDetails');
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion21.emit(etty);
  }

}
