import { Component, Input, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ProcessCacheService } from '../shared/process-cache.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  customiseButton: any;
  homeURL: string;
  @Input() partner:any;

  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    this.homeURL = this.pc.envCred.url;
    const url = window.location.href;
    let paramValue;
    if (url.includes('?')) {
      const httpParams = new HttpParams({ fromString: url.split('?')[1] });
      paramValue = httpParams.get('customize');
      if (paramValue=='false') {
        paramValue = false;
      }
      else if (paramValue=='true') {
        paramValue = true;
      }
      else {
        paramValue = false;
      }
      this.customiseButton = paramValue;
    }
    //document.getElementById("disclaimer").style.display="none";
  }

  opendisc(){
    document.getElementById('disclaimer').style.display='block';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
  }
}
