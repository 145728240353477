import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { constants } from 'src/app/shared/storage-handler.service';
import { ValidationService } from 'src/app/shared/validation.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';




@Component({
  selector: 'app-policy-owner',
  templateUrl: './policy-owner.component.html',
  styleUrls: ['./policy-owner.component.css']
})
export class PolicyOwnerComponent implements OnInit, AfterViewInit {
  inputJson: any;
  RelationshiptopropList: any;
  Relationshiptoproposedinsured: any;
  heroForm: any;
  cityerrormessage: any;
  constructor(
    private pc: ProcessCacheService,
    public validationService: ValidationService,
    private utilityService: UtilityFunctionsService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService,
    private service: ParentChildService) { }
  @Input() questionDetails;
  @Output() policyOwnerOutput = new EventEmitter();

  policyOwnerRelationship: any;
  policyOwnerFirstName: any;
  policyOwnerLastName: any;
  policyOwnerDob: any;
  policyOwnerSSN: any;
  policyOwnerEmail: any;
  policyOwnerStreetAddress: any;
  policyOwnerCity: any;
  policyOwnerState: any;
  policyOwnerZipcode: any;
  PolicyOwnerInformation: boolean;
  InsuredInformation: boolean;
  Show: boolean;
  formerFirstName: any;
  formerlastName: any;
  PolicyOwnerStateLists: any;
  nameerror: boolean;
  Lnameerror: boolean;
  SSNerror: boolean;
  SubmitOne: boolean;
  SubmitTwo: boolean;
  formarnameerror: boolean;
  formarlastnameerror: boolean;
  editForTheComponent: any;
  PolicyOwnerDetails: any;
  SubmitThree: boolean;
  curDate: any;
  closedDiv: boolean;
  closedDiv3: boolean;
  policyclosedDiv: boolean;
  minDate;
  displayAddress;
  city: any;
  state: any;
  zipcode: any;
  policyOwnerInfo;
  insuredInfo;
  insuredFormerInfo;
  insuredLegalNameChanged: any;
  ownerInsuredDifferent: any;
  showloader: boolean;
  streeterrormessage: any;
  ngOnInit(): void {
    debugger;
    this.message = '';

    this.service.callComponentMethod();
    this.showloader = true;
    document.getElementById('appFlowFooterID').style.display = 'block';
    this.InActiveTickIcon = true;
    this.InActiveCrossIcons = true;
    this.InActiveTickIconForSubQuestion = true;
    this.InActiveCrossIconsForSubQuestion = true;
    this.RelationshiptopropList = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_1']['dropdown_options'];
    this.PolicyOwnerStateLists = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_9']['dropdown_options'];
    this.activatedroute.params.subscribe(params => {
      this.displayAddress = params['address'];
    }
    );
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_1_PolicyOwner'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    debugger;
    const dataStr = localStorage.getItem('clientdata');
    let clientData, insuredInfo;
    if (dataStr) { clientData = JSON.parse(dataStr); }
    if (clientData && clientData.body) {
      // Edit scenario
      if ((insuredInfo = clientData.body['insuredInfo'])) {
        this.insuredZipcode = insuredInfo['insuredZipcode'];
        this.insuredState = insuredInfo['insuredState'];
        this.city = insuredInfo['insuredCity'];
      }
      // Get it from clientData
      if ((!this.insuredZipcode || this.insuredZipcode === '') &&
        clientData.body['zip_code']) this.insuredZipcode = clientData.body['zip_code'];
      else if ((!this.insuredZipcode || this.insuredZipcode === '') &&
        clientData.body['zip']) this.insuredZipcode = clientData.body['zip'];
      if ((!this.insuredState || this.insuredState === '') &&
        clientData.body['stateCode']) this.insuredState = clientData.body['stateCode'];
      if ((!this.city || this.city === '') &&
        clientData.body['city']) this.city = clientData.body['city'];
    }
    // Get it from localStorage if available
    debugger;
    if (!this.insuredZipcode || this.insuredZipcode === '') this.insuredZipcode = localStorage.getItem('bubble-zip');
    if (!this.insuredState || this.insuredState === '') this.insuredState = localStorage.getItem('bubble-state');
    if (!this.city || this.city === '') this.city = localStorage.getItem('bubble-city');
    debugger;
    this.curDate = new Date();
    const y = Number(this.curDate.getFullYear()) - 18;
    let m = '' + (this.curDate.getMonth() + 1);
    let d = '' + this.curDate.getDate();
    if (m.length < 2)
      m = '0' + m;
    if (d.length < 2)
      d = '0' + d;

    this.minDate = new Date(m + '/' + d + '/' + y);

    this.closedDiv = false;
    this.closedDiv3 = false;
    this.policyclosedDiv = false;
    this.showspanfordob = false;
    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear()) - 18;
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.policyOwnerDob = month + '/' + day + '/' + year;


    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {
        debugger;
        const clientDataFiltered = JSON.parse(data.Payload);
        this.policyOwnerInfo = clientDataFiltered.body.policyOwnerInfo;
        this.insuredInfo = clientDataFiltered.body.insuredInfo;
        this.insuredFormerInfo = clientDataFiltered.body.insuredFormerInfo;
        this.insuredLegalNameChanged = clientDataFiltered.body.insuredLegalNameChanged;
        this.ownerInsuredDifferent = clientDataFiltered.body.ownerInsuredDifferent;


        this.editForTheComponent = localStorage.getItem('editForPolicyOwner');
        if (this.editForTheComponent == 'true') {
          this.SubmitThree = true;
          // this.PolicyOwnerDetails = localStorage.getItem("PacLife-PolicyOwnerDetails");
          // this.PolicyOwnerDetails = JSON.parse(this.PolicyOwnerDetails);
          // this.PolicyOwnerDetails = this.PolicyOwnerDetails;

          this.insuredStreetAddress = this.insuredInfo.insuredStreetAddress;
          this.insuredCity = this.insuredInfo.insuredCity;
          this.RelationshiptopropList = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_1']['dropdown_options'];
          this.PolicyOwnerStateLists = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_9']['dropdown_options'];

          if (this.ownerInsuredDifferent == 'No') {
            this.selectedOptionValue = 'No';
            // document.getElementById('0').style.border = 'solid 2px orange';
            // document.getElementById('1').style.border = 'solid 1px #dae7ec';
            this.SubmitOne = false;
            this.SubmitTwo = true;
            this.PolicyOwnerInformation = false;
            this.InsuredInformation = true;
            this.ActiveTickIcons = false;
            this.InActiveTickIcon = true;
            this.ActiveCrossIcons = true;
            this.InActiveCrossIcons = false;
            this.PolicySubQuestion = false;

            this.InActiveTickIconForSubQuestion = true;
            this.InActiveCrossIconsForSubQuestion = true;

            if (this.insuredLegalNameChanged == 'Yes') {
              this.selectedOptionValueForSubQuestion = 'Yes';
              this.Show = true;
              this.formerFirstName = this.insuredFormerInfo.formerFirstName;
              this.formerlastName = this.insuredFormerInfo.formerlastName;
              this.ActiveTickIconsForSubQuestion = true;
              this.InActiveTickIconForSubQuestion = false;
              this.ActiveCrossIconsForSubQuestion = false;
              this.InActiveCrossIconsForSubQuestion = true;


            } else {
              this.selectedOptionValueForSubQuestion = 'No';
              this.Show = false;
              this.ActiveTickIconsForSubQuestion = false;
              this.InActiveTickIconForSubQuestion = true;
              this.ActiveCrossIconsForSubQuestion = true;
              this.InActiveCrossIconsForSubQuestion = false;

            }



          } else {
            this.selectedOptionValue = 'Yes';
            // document.getElementById('1').style.border = 'solid 2px orange';
            // document.getElementById('0').style.border = 'solid 1px #dae7ec';
            this.SubmitOne = true;
            this.SubmitTwo = false;
            this.PolicyOwnerInformation = true;
            this.InsuredInformation = true;
            this.ActiveTickIcons = true;
            this.InActiveTickIcon = false;
            this.ActiveCrossIcons = false;
            this.InActiveCrossIcons = true;
            this.PolicySubQuestion = true;

            this.policyOwnerRelationship = this.policyOwnerInfo.policyOwnerRelationship;
            this.policyOwnerFirstName = this.policyOwnerInfo.policyOwnerFirstName;
            this.policyOwnerLastName = this.policyOwnerInfo.policyOwnerLastName;
            this.policyOwnerDob = this.policyOwnerInfo.policyOwnerDob;
            this.policyOwnerSSN = this.policyOwnerInfo.policyOwnerSSN;
            this.policyOwnerEmail = this.policyOwnerInfo.policyOwnerEmail;
            this.policyOwnerStreetAddress = this.policyOwnerInfo.policyOwnerStreetAddress;
            this.policyOwnerCity = this.policyOwnerInfo.policyOwnerCity;
            this.policyOwnerState = this.policyOwnerInfo.policyOwnerState;
            this.policyOwnerZipcode = this.policyOwnerInfo.policyOwnerZipcode;
            this.insuredStreetAddress = this.insuredInfo.insuredStreetAddress;
            this.insuredCity = this.insuredInfo.insuredCity;

            this.InActiveTickIconForSubQuestion = true;
            this.InActiveCrossIconsForSubQuestion = true;

            if (this.ownerInsuredDifferent == 'Yes') {
              this.SelectedOption = 'Yes';
              if (this.insuredLegalNameChanged == 'Yes') {
                this.selectedOptionValueForSubQuestion = 'Yes';
                this.Show = true;
                this.formerFirstName = this.insuredFormerInfo.formerFirstName;
                this.formerlastName = this.insuredFormerInfo.formerlastName;
                this.ActiveTickIconsForSubQuestion = true;
                this.InActiveTickIconForSubQuestion = false;
                this.ActiveCrossIconsForSubQuestion = false;
                this.InActiveCrossIconsForSubQuestion = true;
              } else {
                this.selectedOptionValueForSubQuestion = 'No';
                this.Show = false;
                this.ActiveTickIconsForSubQuestion = false;
                this.InActiveTickIconForSubQuestion = true;
                this.ActiveCrossIconsForSubQuestion = true;
                this.InActiveCrossIconsForSubQuestion = false;
              }

            } else {
              this.SelectedOption = 'No';
            }

          }
        } else {



          if (this.ownerInsuredDifferent == null || this.ownerInsuredDifferent == undefined) {
            this.policyOwnerState = 'Choose';
            this.policyOwnerRelationship = 'Choose';
            this.heroForm = new FormGroup({
              policyOwnerFirstName: new FormControl(this.policyOwnerFirstName),
              policyOwnerLastName: new FormControl(this.policyOwnerLastName),
              policyOwnerDob: new FormControl(this.policyOwnerDob),
              policyOwnerSSN: new FormControl(this.policyOwnerSSN, Validators.required),
              policyOwnerEmail: new FormControl(this.policyOwnerEmail, Validators.required),
              policyOwnerStreetAddress: new FormControl(this.policyOwnerStreetAddress),
              policyOwnerCity: new FormControl(this.policyOwnerCity),
              policyOwnerState: new FormControl(this.policyOwnerState),
              policyOwnerZipcode: new FormControl(this.policyOwnerZipcode)
            });
            this.inputJson = {
            };
            this.SubmitOne = true;
            //this.policyOwnerRelationship = this.policyOwnerInfo.policyOwnerRelationship;
            this.policyOwnerState = 'Choose';
            this.PolicyOwnerInformation = false;
            this.InsuredInformation = false;
            console.log(this.questionDetails);
            this.InActiveTickIcon = true;
            this.InActiveCrossIcons = true;
            this.InActiveTickIconForSubQuestion = true;
            this.InActiveCrossIconsForSubQuestion = true;
            this.Show = false;
            this.RelationshiptopropList = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_1']['dropdown_options'];
            this.PolicyOwnerStateLists = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_9']['dropdown_options'];
          } else {

            debugger;
            this.SubmitThree = true;
            // this.PolicyOwnerDetails = localStorage.getItem("PacLife-PolicyOwnerDetails");
            // this.PolicyOwnerDetails = JSON.parse(this.PolicyOwnerDetails);
            // this.PolicyOwnerDetails = this.PolicyOwnerDetails;

            this.RelationshiptopropList = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_1']['dropdown_options'];
            this.PolicyOwnerStateLists = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_9']['dropdown_options'];

            if (this.ownerInsuredDifferent == 'No') {
              this.selectedOptionValue = 'No';
              // document.getElementById('0').style.border = 'solid 2px orange';
              // document.getElementById('1').style.border = 'solid 1px #dae7ec';
              this.SubmitOne = false;
              this.SubmitTwo = true;
              this.PolicyOwnerInformation = false;
              this.InsuredInformation = true;
              this.ActiveTickIcons = false;
              this.InActiveTickIcon = true;
              this.ActiveCrossIcons = true;
              this.InActiveCrossIcons = false;
              this.PolicySubQuestion = false;

              this.InActiveTickIconForSubQuestion = true;
              this.InActiveCrossIconsForSubQuestion = true;

              this.insuredStreetAddress = this.insuredInfo.insuredStreetAddress;
              this.insuredCity = this.insuredInfo.insuredCity;
              if (this.insuredInfo) {
                this.insuredZipcode = this.insuredInfo['insuredZipcode'];
                this.insuredState = this.insuredInfo['insuredState'];
                this.city = this.insuredInfo['insuredCity'];
              } else {
                this.insuredZipcode = localStorage.getItem('bubble-zip');
                this.insuredState = localStorage.getItem('bubble-state');
                this.city = localStorage.getItem('bubble-city');
              }


              if (this.insuredLegalNameChanged == 'Yes') {
                debugger;
                this.selectedOptionValueForSubQuestion = 'Yes';
                this.Show = true;
                // document.getElementById('s1').style.border = 'solid 2px orange';
                // document.getElementById('s0').style.border = 'solid 1px #dae7ec';
                this.formerFirstName = this.insuredFormerInfo.formerFirstName;
                this.formerlastName = this.insuredFormerInfo.formerlastName;
                this.ActiveTickIconsForSubQuestion = true;
                this.InActiveTickIconForSubQuestion = false;
                this.ActiveCrossIconsForSubQuestion = false;
                this.InActiveCrossIconsForSubQuestion = true;


              } else {
                debugger;
                // document.getElementById('s0').style.border = 'solid 2px orange';
                // document.getElementById('s1').style.border = 'solid 1px #dae7ec';
                this.selectedOptionValueForSubQuestion = 'No';
                this.Show = false;
                this.ActiveTickIconsForSubQuestion = false;
                this.InActiveTickIconForSubQuestion = true;
                this.ActiveCrossIconsForSubQuestion = true;
                this.InActiveCrossIconsForSubQuestion = false;

              }



            } else {
              this.selectedOptionValue = 'Yes';
              // document.getElementById('1').style.border = 'solid 2px orange';
              // document.getElementById('0').style.border = 'solid 1px #dae7ec';
              this.SubmitOne = true;
              this.SubmitTwo = false;
              this.PolicyOwnerInformation = true;
              this.InsuredInformation = true;
              this.ActiveTickIcons = true;
              this.InActiveTickIcon = false;
              this.ActiveCrossIcons = false;
              this.InActiveCrossIcons = true;
              this.PolicySubQuestion = true;

              this.policyOwnerRelationship = this.policyOwnerInfo.policyOwnerRelationship;
              this.policyOwnerFirstName = this.policyOwnerInfo.policyOwnerFirstName;
              this.policyOwnerLastName = this.policyOwnerInfo.policyOwnerLastName;
              this.policyOwnerDob = this.policyOwnerInfo.policyOwnerDob;
              this.policyOwnerSSN = this.policyOwnerInfo.policyOwnerSSN;
              this.policyOwnerEmail = this.policyOwnerInfo.policyOwnerEmail;
              this.policyOwnerStreetAddress = this.policyOwnerInfo.policyOwnerStreetAddress;
              this.policyOwnerCity = this.policyOwnerInfo.policyOwnerCity;
              this.policyOwnerState = this.policyOwnerInfo.policyOwnerState;
              this.policyOwnerZipcode = this.policyOwnerInfo.policyOwnerZipcode;


              this.insuredStreetAddress = this.insuredInfo.insuredStreetAddress;
              this.insuredCity = this.insuredInfo.insuredCity;
              this.insuredState = this.insuredInfo.insuredState;
              this.insuredZipcode = this.insuredInfo.insuredZipcode;



              this.InActiveTickIconForSubQuestion = true;
              this.InActiveCrossIconsForSubQuestion = true;

              if (this.ownerInsuredDifferent == 'Yes') {
                debugger;
                this.SelectedOption = 'Yes';
                if (this.insuredLegalNameChanged == 'Yes') {
                  this.selectedOptionValueForSubQuestion = 'Yes';
                  this.Show = true;
                  this.formerFirstName = this.insuredFormerInfo.formerFirstName;
                  this.formerlastName = this.insuredFormerInfo.formerlastName;
                  this.ActiveTickIconsForSubQuestion = true;
                  this.InActiveTickIconForSubQuestion = false;
                  this.ActiveCrossIconsForSubQuestion = false;
                  this.InActiveCrossIconsForSubQuestion = true;

                } else {
                  this.selectedOptionValueForSubQuestion = 'No';
                  this.Show = false;
                  this.ActiveTickIconsForSubQuestion = false;
                  this.InActiveTickIconForSubQuestion = true;
                  this.ActiveCrossIconsForSubQuestion = true;
                  this.InActiveCrossIconsForSubQuestion = false;
                }

              } else {
                this.SelectedOption = 'No';
              }

            }



          }

        }
        if (this.selectedOptionValue == undefined || this.selectedOptionValue == null) { this.selectedOptionValue = 'No'; }

        this.SubmitOne = false;
        this.SubmitTwo = true;
        this.InsuredInformation = true;
        this.ActiveTickIcons = false;
        this.InActiveTickIcon = true;
        this.ActiveCrossIcons = true;
        this.InActiveCrossIcons = false;
        this.PolicySubQuestion = false;
        this.CheckinsuredCity();
        this.CheckinsuredStreetAddress();
        this.showloader = false;
      }
    ).catch(error => {
      console.log(error);
    });



    this.inputdatechange();


  }











  ngAfterViewInit(): void {
    debugger;

    if (localStorage.getItem('PacLife-PolicyOwnerDetails') == null || localStorage.getItem('PacLife-PolicyOwnerDetails') == undefined) {
    } else {
      debugger;
      this.PolicyOwnerDetails = localStorage.getItem('PacLife-PolicyOwnerDetails');
      this.PolicyOwnerDetails = JSON.parse(this.PolicyOwnerDetails);
      this.PolicyOwnerDetails = this.PolicyOwnerDetails;
      this.RelationshiptopropList = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_1']['dropdown_options'];
      this.PolicyOwnerStateLists = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_9']['dropdown_options'];
      if (this.ownerInsuredDifferent == 'No') {
      } else {
        if (this.policyOwnerInfo != undefined) {
          document.getElementById('relationname').innerHTML = this.policyOwnerInfo.policyOwnerRelationship;
          document.getElementById('statename').innerHTML = this.policyOwnerInfo.policyOwnerState;
        }

      }



    }
  }



  checkfirstNamelength() {
    debugger;
    if (this.policyOwnerFirstName.length <= 1) {
      this.nameerror = true;
    }
    else if (this.policyOwnerFirstName === '') {
      this.nameerror = false;
    }
    else {
      this.nameerror = false;
    }
  }


  checkformerfirstNamelength() {
    if (this.formerFirstName.length <= 1) {
      this.formarnameerror = true;

    } else if (this.formerFirstName === '') {
      this.formarnameerror = false;
    }
    else {
      this.formarnameerror = false;
    }
  }

  checkformerlastNamelength() {
    if (this.formerlastName.length <= 1) {
      this.formarlastnameerror = true;

    } else if (this.formerFirstName == '') {
      this.formarlastnameerror = false;
    }
    else {
      this.formarlastnameerror = false;
    }
  }


  checklastNamelength() {
    debugger;
    if (this.policyOwnerLastName.length <= 1) {
      this.Lnameerror = true;
    }
    else if (this.policyOwnerLastName == '') {
      this.Lnameerror = false;
    }
    else {
      this.Lnameerror = false;
    }
  }

  ssnlen:any;
  ssnlength() {
    this.ssnlen = this.policyOwnerSSN.length;
    if (this.policyOwnerSSN.length === 3) {
      this.policyOwnerSSN = this.policyOwnerSSN + '-';
      this.SSNerror = true;
    }
    else if (this.policyOwnerSSN.length === 6) {
      this.policyOwnerSSN = this.policyOwnerSSN + '-';
      this.SSNerror = true;
    }
    else if (this.policyOwnerSSN.length < 10) {
      this.SSNerror = true;
    }
    else {
      this.SSNerror = false;
    }
  }

  states(region) {
    document.getElementById('statename').innerHTML = region;
    this.policyOwnerState = region;
  }

  relations(Roptions) {
    debugger;
    document.getElementById('relationname').innerHTML = Roptions;
    this.policyOwnerRelationship = Roptions;
  }


  insuredStreetAddressError: boolean;
  CheckinsuredStreetAddress() {
    debugger;
    if (this.insuredStreetAddress == undefined) {
      this.insuredStreetAddressError = true;
      this.streeterrormessage = 'This field is required';
    }
    else {
      if (this.insuredStreetAddress.length <= 1) {
        this.insuredStreetAddressError = true;
        this.streeterrormessage = 'Invalid Address';
      }
      else if (this.insuredStreetAddress === '') {
        this.insuredStreetAddressError = true;
        this.streeterrormessage = 'Invalid Address';
      }
      else {
        this.insuredStreetAddressError = false;
        this.streeterrormessage = '';
      }
    }
  }


  insuredCityError: boolean;
  CheckinsuredCity() {
    debugger;
    if (this.insuredCity == undefined) {
      this.insuredCityError = true;
      this.cityerrormessage = 'This field is required';
    }
    else {
      if (this.insuredCity.length < 2) {
        this.insuredCityError = true;
        this.cityerrormessage = 'Invalid City';
      }
      else if (this.insuredStreetAddress == '') {
        this.insuredCityError = true;
        this.cityerrormessage = 'Invalid City';
      }
      else {
        this.insuredCityError = false;
        this.cityerrormessage = '';
      }
    }

  }
  inputdatechange() {
    debugger;
    const K = this.policyOwnerDob.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.message = 'Invalid Date';
      }
      else {
        this.message = '';
      }
      this.policyOwnerDob = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.message = 'Invalid Date';
        }
        else if (A <= 12) {
          this.policyOwnerDob = A + '/' + B + '/';
          this.message = '';
        }
        else {
          this.message = 'Invalid Date';
        }
      }
      else {
        this.message = 'Invalid Date';
      }
    }
    else if (C != '' && B.length == 2) {
      this.policyOwnerDob = A + '/' + B + '/' + C;
    }
    else {
      this.policyOwnerDob = this.policyOwnerDob;
    }
    if (this.policyOwnerDob.length >= 100 && this.message != 'Invalid Date') {
      this.onDateChange(this.policyOwnerDob);
    }
    else {
      this.is_valid = false;
    }
  }

  showspanfordob: boolean;
  message: string;
  is_valid: boolean;
  onDateChange(even) {
    debugger;
    //  var cur_d = new Date();

    //  var d = new Date(newDate),
    //  month = '' + (d.getMonth() + 1),
    //  day = '' + d.getDate(),
    //  year = d.getFullYear();


    //  var curYear =  cur_d.getFullYear();
    //  year = d.getFullYear();

    //  var ageYear= Number(curYear) - Number(year)

    //  if(ageYear>=18){
    //    this.showspanfordob=false;
    //  }else{
    //   this.showspanfordob=true;
    //  }

    // if (month.length < 2)
    //  month = '0' + month;
    // if (day.length < 2)
    //  day = '0' + day;
    //  this.policyOwnerDob= month + '/'+ day + '/' + year;

    debugger;
    if (even == null) {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.policyOwnerDob = new Date(month + '/' + day + '/' + year);
    } else {

      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();
      const ageYear = Number(curYear) - Number(year);
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.policyOwnerDob = month + '/' + day + '/' + year;
    }
    if (this.policyOwnerDob == 'NaN/NaN/NaN') {
      this.message = 'Invalid Date';
      this.is_valid = false;
      this.policyOwnerDob = '';
    }
    else {
      const newDate = new Date(even);
      console.log('DOB entered: ', newDate);
      const checkAge = this.validationService.validate_dob('common_age', newDate);
      this.message = checkAge.message;
      this.is_valid = checkAge.is_valid;
    }

  }



  SelectedOption: any;
  SecondQuestion: any;
  ActiveTickIcons: boolean;
  InActiveTickIcon: boolean;
  ActiveCrossIcons: boolean;
  InActiveCrossIcons: boolean;
  selectedOptionValue: any;
  PolicySubQuestion: boolean;
  public GetOptions(even) {
    debugger;
    let data;
    if (even.target.checked == true) {
      data = 1;
    }
    else {
      data = 0;
    }
    if (this.policyOwnerDob == null || this.policyOwnerDob == undefined) {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.policyOwnerDob = month + '/' + day + '/' + year;
    }

    this.SelectedOption = data;
    if (this.SelectedOption == 0) {
      this.selectedOptionValue = 'No';
      // document.getElementById('0').style.border = 'solid 2px orange';
      // document.getElementById('1').style.border = 'solid 1px #dae7ec';
      this.SubmitOne = false;
      this.SubmitTwo = true;
      this.PolicyOwnerInformation = false;
      this.InsuredInformation = true;
      this.ActiveTickIcons = false;
      this.InActiveTickIcon = true;
      this.ActiveCrossIcons = true;
      this.InActiveCrossIcons = false;
      this.PolicySubQuestion = false;
    } else {
      this.selectedOptionValue = 'Yes';
      // document.getElementById('1').style.border = 'solid 2px orange';
      // document.getElementById('0').style.border = 'solid 1px #dae7ec';
      this.SubmitOne = true;
      this.SubmitTwo = false;
      this.PolicyOwnerInformation = true;
      this.InsuredInformation = true;
      this.ActiveTickIcons = true;
      this.InActiveTickIcon = false;
      this.ActiveCrossIcons = false;
      this.InActiveCrossIcons = true;
      this.PolicySubQuestion = true;
    }
  }



  SelectedOptionForSubQuestion: any;
  selectedOptionValueForSubQuestion: any;
  ActiveTickIconsForSubQuestion: boolean;
  InActiveTickIconForSubQuestion: boolean;
  ActiveCrossIconsForSubQuestion: boolean;
  InActiveCrossIconsForSubQuestion: boolean;
  public GetOptionsForSubQuestion(data) {
    debugger;
    this.formerFirstName='';
    this.formerlastName='';
    this.SelectedOptionForSubQuestion = data;
    if (this.SelectedOptionForSubQuestion == 0) {
      this.selectedOptionValueForSubQuestion = 'No';


      // document.getElementById('s0').style.border = 'solid 2px orange';
      // document.getElementById('s1').style.border = 'solid 1px #dae7ec';
      this.Show = false;
      this.ActiveTickIconsForSubQuestion = false;
      this.InActiveTickIconForSubQuestion = true;
      this.ActiveCrossIconsForSubQuestion = true;
      this.InActiveCrossIconsForSubQuestion = false;
      this.formarnameerror = false;
      this.formarlastnameerror = false;

    } else {
      this.selectedOptionValueForSubQuestion = 'Yes';

      // document.getElementById('s1').style.border = 'solid 2px orange';
      // document.getElementById('s0').style.border = 'solid 1px #dae7ec';
      this.Show = true;
      this.ActiveTickIconsForSubQuestion = true;
      this.InActiveTickIconForSubQuestion = false;
      this.ActiveCrossIconsForSubQuestion = false;
      this.InActiveCrossIconsForSubQuestion = true;
      this.formarnameerror = true;
      this.formarlastnameerror = true;


    }
  }


  public ExpandCollapse(event) {
    debugger;

    if (event.target.id == 'Open') {
      this.closedDiv = true;

    }
    else {
      this.closedDiv = false;
    }

  }

  public ExpandCollapsePolicy(event) {
    debugger;

    if (event.target.id == 'Open') {
      this.policyclosedDiv = true;

    }
    else {
      this.policyclosedDiv = false;
    }

  }

  public ExpandCollapse3(event) {
    debugger;

    if (event.target.id == 'Open') {
      this.closedDiv3 = true;

    }
    else {
      this.closedDiv3 = false;
    }

  }


  public goBack() {
    this.policyOwnerOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }

  groupkey: any;
  groupkeyforsubquestion: any;
  insurancegroupkey: any;
  insuredStreetAddress: any;
  insuredCity: any;
  insuredState: any;
  insuredZipcode: any;
  public onSubmit(data) {
    debugger;
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);
      this.inputJson = {};
      this.groupkey = {};
      this.insurancegroupkey = {};
      if (this.selectedOptionValue == 'No') {
        if (this.selectedOptionValueForSubQuestion == 'No') {
          this.inputJson['id'] = localStorage.getItem('clientID');

          this.inputJson[data.radio_options[1].answer_key] = this.selectedOptionValue;
          this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_1.answer_key] = this.insuredStreetAddress;
          this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_2.answer_key] = this.insuredCity;
          this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_3.answer_key] = this.insuredState;
          this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_4.answer_key] = this.insuredZipcode;
          this.inputJson[data.sub_questions.question_2.group_key] = this.insurancegroupkey;

          this.inputJson[data.sub_questions.question_3.radio_options[0].answer_key] = this.selectedOptionValueForSubQuestion;
        } else {
          this.inputJson['id'] = localStorage.getItem('clientID');
          this.inputJson[data.radio_options[1].answer_key] = this.selectedOptionValue;
          this.inputJson[data.sub_questions.question_3.radio_options[0].answer_key] = this.selectedOptionValueForSubQuestion;

          this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_1.answer_key] = this.insuredStreetAddress;
          this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_2.answer_key] = this.insuredCity;
          this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_3.answer_key] = this.insuredState;
          this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_4.answer_key] = this.insuredZipcode;
          this.inputJson[data.sub_questions.question_2.group_key] = this.insurancegroupkey;

          this.groupkey[data.sub_questions.question_3.sub_questions.question_1.group_of_inputs.answer_1.answer_key] = this.formerFirstName;
          this.groupkey[data.sub_questions.question_3.sub_questions.question_1.group_of_inputs.answer_2.answer_key] = this.formerlastName;
          this.inputJson[data.sub_questions.question_3.sub_questions.question_1.group_key] = this.groupkey;
        }

        this.submitdata(this.inputJson);
      } else {
        localStorage.setItem('questionId', data.question_sequence);
        this.groupkeyforsubquestion = {};
        this.insurancegroupkey = {};
        this.inputJson['id'] = localStorage.getItem('clientID');
        this.inputJson[data.radio_options[1].answer_key] = this.selectedOptionValue;

        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_1.answer_key] = this.policyOwnerRelationship;
        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_2.answer_key] = this.policyOwnerFirstName;
        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_3.answer_key] = this.policyOwnerLastName;
        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_4.answer_key] = this.policyOwnerDob;
        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_5.answer_key] = this.policyOwnerSSN;
        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_6.answer_key] = this.policyOwnerEmail;
        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_7.answer_key] = this.policyOwnerStreetAddress;
        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_8.answer_key] = this.policyOwnerCity;
        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_9.answer_key] = this.policyOwnerState;
        this.groupkey[data.sub_questions.question_1.group_of_inputs.answer_10.answer_key] = this.policyOwnerZipcode;





        this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_1.answer_key] = this.insuredStreetAddress;
        this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_2.answer_key] = this.insuredCity;
        this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_3.answer_key] = this.insuredState;
        this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_4.answer_key] = this.insuredZipcode;
        this.inputJson[data.sub_questions.question_2.group_key] = this.insurancegroupkey;
        this.inputJson[data.sub_questions.question_1.group_key] = this.groupkey;

        this.inputJson[data.sub_questions.question_3.radio_options[0].answer_key] = this.selectedOptionValueForSubQuestion;



        if (this.selectedOptionValueForSubQuestion == 'No') {
          this.submitdata(this.inputJson);
        } else {
          // this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_1.answer_key]=this.insuredStreetAddress;
          // this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_2.answer_key]=this.insuredCity
          // this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_3.answer_key]=this.insuredState
          // this.insurancegroupkey[data.sub_questions.question_2.group_of_inputs.answer_4.answer_key]=this.insuredZipcode
          // this.inputJson[data.sub_questions.question_3.group_key]=this.insurancegroupkey;

          this.groupkeyforsubquestion[data.sub_questions.question_3.sub_questions.question_1.group_of_inputs.answer_1.answer_key] = this.formerFirstName;
          this.groupkeyforsubquestion[data.sub_questions.question_3.sub_questions.question_1.group_of_inputs.answer_2.answer_key] = this.formerlastName;
          this.inputJson[data.sub_questions.question_3.sub_questions.question_1.group_key] = this.groupkeyforsubquestion;
          this.inputJson[data.sub_questions.question_3.radio_options[0].answer_key] = this.selectedOptionValueForSubQuestion;
          this.submitdata(this.inputJson);
        }

        // this.inputJson[data.sub_questions.question_2.sub_questions.question_1.group_of_inputs.answer_1.answer_key]=this.formerFirstName;
        // this.inputJson[data.sub_questions.question_2.sub_questions.question_1.group_of_inputs.answer_2.answer_key]=this.formerlastName;


      }
    } else {
      alert('Declined!');
    }


    this.$gaService.event('Next', 'event', 'LifeAppAddressDetails');
    const eventOptions: EventOption = {
      page_title: 'Insured Info',
      path: 'AppFlowStart',
      url: window.location.href
    };

    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppAddressDetails');
  }


  public submitdata(inputJson) {
    debugger;
    inputJson.application_stage = constants.application_stage_app;
    if (this.editForTheComponent == 'true') {
      const PolicyDetails = JSON.stringify(this.inputJson);
      localStorage.setItem('PacLife-PolicyOwnerDetails', PolicyDetails);
      this.policyOwnerOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson, afterEdit: true });
      this.pc.saveUpdateClientData(inputJson);
      // this.pc.submitpaclifeanswer(localStorage.getItem('clientID'));
    } else {
      const PolicyDetails = JSON.stringify(this.inputJson);
      localStorage.setItem('PacLife-PolicyOwnerDetails', PolicyDetails);
      this.policyOwnerOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
      this.pc.saveUpdateClientData(inputJson);
      // this.pc.submitpaclifeanswer(localStorage.getItem('clientID'));
    }
  }

  emailen:any;

  ziplen:any;
  emaillength()
  {
    this.emailen = this.policyOwnerEmail.toString().length;
  }
  ziplength()
  {
    this.ziplen = this.policyOwnerZipcode.toString().length;
  }


  moveDatePicker() {
    setTimeout(() => {
      const x = document.getElementsByTagName('bs-datepicker-container')[0];
      console.log(x);
      document.getElementById('inputDatePicker').appendChild(x);
    }, 100);
  }


}
