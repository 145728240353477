import { Options } from '@angular-slider/ngx-slider';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import pc from 'bit-uibl-data-driver';
@Component({
  selector: 'app-wnsback9',
  templateUrl: './wnsback9.component.html',
  styleUrls: ['./wnsback9.component.css']
})
export class WNSback9Component implements OnInit {
  showLoader: boolean;
  @Output() stoploader = new EventEmitter<any>();
  @Output() changepolicy = new EventEmitter<any>();
  @Output() startloader = new EventEmitter<any>();
   @Output() openModal = new EventEmitter<any>();
    @ViewChild('wnssbliRediect') wnssbliRediect: ElementRef<HTMLElement>;
    commaninput: any;
    input_array: any;
    age: any;
    wnscoverage: any;
    wnsterm: any;
    policytype: any;
    coveragedisplay: any;
    termdisplay: any;
    sortby: any;
    sorttext: any;
    selectedCardData: any;
    allquotes: string;
    termplanstartat: string;
    returnpremium: string;
    wnsquoteamount: string;
    coveragesilder: any;
    backButton: string;
    uniqueId: any;
    clientid: any;
    insurancetype: any;
    displayAddress: any;
    firstname: any;
    disablerefreshbutton: boolean;
    allplanstartat: string;
    quoteamount: any;
    wnssbliRediecturl: string;
    constructor(private activatedroute: ActivatedRoute, private modalService: NgbModal, private pc: ProcessCacheService, public router: Router, private utilityService: UtilityFunctionsService) { }
    wnsquote: any;

    wnscoverageoptions: Options = {
      showTicksValues: false,
      showSelectionBar: true,
      showTicks: false,
      hidePointerLabels: true,
      hideLimitLabels: true,
      keyboardSupport: false,
      animateOnMove: true,
      getPointerColor: (value: number): string => {
        return 'rgb(255, 112, 7)';
      },
      getSelectionBarColor: (value: number): string => {
        return 'rgb(255, 112, 7)';
      },
      stepsArray: [{
        value: 500,
        legend: '500'
      },
      {
        value: 750,
        legend: '750'
      }, {
        value: 1000,
        legend: '1,000'
      },
      {
        value: 1250,
        legend: '1,250'
      },
      {
        value: 1500,
        legend: '1,500'
      }, {
        value: 2000,
        legend: '2,000'
      },
      {
        value: 2500,
        legend: '2,500'
      },
      {
        value: 5000,
        legend: '5,000'
      }
      ]
    };
    wnstermoptions: Options = {
      showTicksValues: false,
      showSelectionBar: true,
      showTicks: true,
      hidePointerLabels: true,
      hideLimitLabels: true,
      keyboardSupport: false,
      animateOnMove: true,
      getPointerColor: (value1: number): string => {
        return '#ff7007';
      },
      getSelectionBarColor: (value1: number): string => {
        return '#ff7007';
      },
      stepsArray: this.getdynamicterm()
    };

    ngOnInit(): void {
      this.sortby = '1';
      this.sorttext = 'Recommended';
      this.returnpremium = localStorage.getItem('returnpremium');
      this.allplanstartat = localStorage.getItem('allpolicypremium');
      this.termplanstartat = localStorage.getItem('termpremium');
      this.wnsquoteamount = localStorage.getItem('wnspremium');
      this.backButton = localStorage.getItem('lifeRecoBackButton');
      this.disablerefreshbutton = true;
      debugger; this.activatedroute.params.subscribe(params => {
        this.uniqueId = params['uniqueId'];
        this.clientid = params['clientId'];
        this.displayAddress = params['address'];
        this.insurancetype = params['insuranceType'];
      }
      );
    }
    openVerticallyCentered(content) {
      this.modalService.open(content, { centered: true, windowClass: 'custom-modal' });
    }
    openVerticallyCentered1(contentpolicy) {
      this.modalService.open(contentpolicy, { centered: true, windowClass: 'custom-modal' });
    }
    openVerticallyCentered2(coverage) {
      document.getElementById('coveragemodal').style.display = 'block';
      //this.modalService.open(coverage, { centered: true, windowClass: 'custom-modal' });
    }


    public async getwnsQuote(commonInput, uniqueId) {
      debugger;
      this.firstname = commonInput['firstName'];
      this.showLoader = true;
      this.commaninput = commonInput;
      const quickquote = new pc.Quotes(uniqueId);
      await quickquote.getQuickQuote2(commonInput, false, true)
        .then(response => {
          console.log('output quickquote: ', response);
          console.log('commaninputafterresponse: ', commonInput);
          this.wnsquote = response[2];
          this.wnsquote.highlight = 'Instant Decision';
          this.wnsquote.point1 = 'Completely digital experience with no medical exam, phone calls or in-person appointments required.';
          this.wnsquote.point2 = 'Decreasing term policy with monthly payment coverage up to $10,000 depending on your age and policy duration.';
          this.wnsquote.benefit1 = 'Completely digital';
          this.wnsquote.benefit2 = 'Monthly payout for your family';
          this.wnsquote.benefit3 = 'A+ rating from A.M. Best';
          this.wnsquote.benefit4 = 'Easy online application';
          this.wnsquote.benefit5 = 'Monthly insurance premium guaranteed not to increase';
          this.wnsquote.benefit6 = 'Coverage duration up to 30 years (not beyond age 65)';
          this.wnsquote.policy_type = 'Decreasing term policy';
          this.wnsquote.policy_type1 = '30 days easy refund';
          this.wnsquote.policy_type2 = 'Completely online';
          this.wnsquote.pdflink = 'https://www.getmybubble.co/wns/';
          this.wnscoverage = this.wnsquote.selectedDetails['coverage'];
          //this.wnsterm = this.wnsquote.selectedDetails['term'];
          this.coveragedisplay = this.wnsquote.selectedDetails['coverage'];
          this.coveragesilder = this.wnsquote.selectedDetails['coverage'];
          this.termdisplay = this.wnsquote.selectedDetails['term'];
          this.quoteamount = this.wnsquote.head['quote_amount'].replace('$', '');
          this.wnsquote.redirectUrl = `lifeCarrierThree/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}`;
          this.wnsquote.head['quote_amount'] = this.wnsquote.head['quote_amount'].replace('$', '');
          if (this.wnsquote.head['quote_amount'].toString().includes('.')) {
            this.wnsquote.head['quote_amount'] = Number(this.wnsquote.head['quote_amount']) / 30;
            this.wnsquote.head['quote_amount'] = (Math.round(this.wnsquote.head['quote_amount'] * 100) / 100).toFixed(2);
            const spt_amt = this.wnsquote.head['quote_amount'].toString().split('.');
            if (spt_amt[1].length == 1) {
              spt_amt[1] = spt_amt[1] + '0';
            }
            this.wnsquote.head.before_dcimal = spt_amt[0];
            this.wnsquote.head.after_dcimal = '.' + spt_amt[1];
          }
          else {
            this.wnsquote.head['quote_amount'] = Number(this.wnsquote.head['quote_amount']) / 30;
            this.wnsquote.head['quote_amount'] = (Math.round(this.wnsquote.head['quote_amount'] * 100) / 100).toFixed(2);
            this.wnsquote.head.before_dcimal = this.wnsquote.head['quote_amount'];
            this.wnsquote.head.after_dcimal = '.00';
          }
          if (this.quoteamount === '0') {
            this.wnsquote = undefined;
          }
          this.allquotes = JSON.stringify(this.wnsquote);
          commonInput.filtersbli ? this.removesbliandwns() : 0;
          this.stoploader.next();
        }).catch(error => {
          this.stoploader.next();
          console.log(error);
        });
    }
    termlist = [];
    public getdynamicterm() {
      debugger;
      this.age = localStorage.getItem('bubble-age');
      this.age = this.age.split(',').join('');
      if (this.age >= 20 && this.age <= 34) {
        this.termlist = [{ value: 10, legend: '' }, { value: 15, legend: '' }, { value: 20, legend: '' }, { value: 30, legend: '' }];
      }
      else if (this.age >= 35 && this.age <= 40) {
        this.termlist = [{ value: 10, legend: '' }, { value: 15, legend: '' }, { value: 20, legend: '' }, { value: 65, legend: '' }];
      }
      else if (this.age >= 41 && this.age <= 45) {
        this.termlist = [{ value: 10, legend: '' }, { value: 15, legend: '' }, { value: 20, legend: '' }, { value: 65, legend: '' }];
      }
      else if (this.age >= 46 && this.age <= 50) {
        this.termlist = [{ value: 10, legend: '' }, { value: 15, legend: '' }, { value: 65, legend: '' }];
      }
      else {
        this.termlist = [{ value: 10, legend: '' }, { value: 65, legend: '' }];
      }
      return this.termlist;
    }
    public changepolicyType(id) {
      debugger;
      this.policytype = id;
      this.pc.lifesummary({'filter':'Yes'})
      if (id == '1') {
        var input = {
          id: id,
          coverage: this.wnscoverage,
          term: this.wnsterm,
        };
        this.changepolicy.next(id);
      }
      else if (id == '2') {
        var input = {
          id: id,
          coverage: this.wnscoverage,
          term: this.wnsterm,
        };
        this.changepolicy.next(id);
      }
      else if (id == '3') {
        var input = {
          id: id,
          coverage: this.wnscoverage,
          term: this.wnsterm,
        };
        this.changepolicy.next(id);
      }
      else if (id == '4') {
        this.changepolicy.next(id);
      }
      this.modalService.dismissAll();
    }
    public refreshQuote() {
      debugger;
      this.pc.lifesummary({'coverage_refresh':'Yes'});
      document.getElementById('coveragemodal').style.display = 'none';
      this.startloader.emit();
      this.commaninput['wnscoverage'] = this.wnscoverage.toString();
      if (Number(this.wnsterm) == 65) {
        this.age = localStorage.getItem('bubble-age');
        this.commaninput['term'] = (65 - Number(this.age));
        this.commaninput['wnsterm'] = (65 - Number(this.age));
      }
      else {
        this.commaninput['term'] = this.wnsterm;
        this.commaninput['wnsterm'] = this.wnsterm;
      }
      console.log('newcommaninput: ', this.commaninput);
      this.getwnsQuote(this.commaninput, this.commaninput.uniqueId);
    }
    public closemodal() {
      document.getElementById('coveragemodal').style.display = 'none';
    }

    sortQuotes(id, text) {
      debugger;
      this.sortby = id;
      this.sorttext = text;
      if (id == '1') {
        this.wnsquote = JSON.parse(this.allquotes);
      }
      else if (id == '2') {
        this.wnsquote = this.wnsquote.sort((a, b) => a.premium - b.premium);
      }
      else {
        this.wnsquote = this.wnsquote.sort((a, b) => b.premium - a.premium);
      }
      this.pc.lifesummary({'sort':'Yes'})
    }

    async selectPlan(carrierid) {
      debugger;
      //this.startloader.emit(1);
      let coverageAmount;
      if (this.wnscoverage.toString().includes(',')) {
        coverageAmount = this.wnscoverage.replace(/\$|,/g, '');
        localStorage.setItem('bubble-coverage', coverageAmount);
      } else {
        localStorage.setItem('bubble-coverage', this.wnscoverage);
        coverageAmount = this.wnscoverage;
      }

      localStorage.setItem('WNS_term', this.wnsterm);

      let redirectUrl = '';
      let appflowScreen = '';
      const eventOptionsPart1 = {
        'path': 'selectLifeCarrier',
        'page_title': 'selectLifeCarrier', 'url': window.location.href
      };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifePlanSelect', {
        'event_category': 'ButtonClicked', 'event_label': 'LifePlanSelect'
      });
      if (carrierid == 'wns') {
        this.selectedCardData = this.wnsquote;
      }
      else if (carrierid == undefined) {
        this.router.navigateByUrl(`Back9Quote/${this.commaninput.firstName}/${this.commaninput.lastName}`);
      }
      const details = JSON.stringify(this.selectedCardData);
      localStorage.setItem('selectedCarrier', details);
      localStorage.setItem('selectedLifeCarrier', details);

      const fullName = `${localStorage.getItem('bubble-fname')} ${localStorage.getItem('bubble-lname')}`;

      ///// Deal with selected Quote persistence
      let pacificLifeQuoteId = this.pc.getQuoteId('');// pass emptystring to get -wer324 kind string
      pacificLifeQuoteId = pacificLifeQuoteId.replace('-', '');
      const clientIdLocal = localStorage.getItem('clientID');
      const carrierLocal = this.selectedCardData['head']['carrier_name'];
      const quoteAmountLocal = this.selectedCardData['head']['quote_amount'];
      localStorage.setItem('bubble-QouteAmount', quoteAmountLocal);
      const hubSpotData = {
        email: localStorage.getItem('bubble-email'),
        life_quote_amount: quoteAmountLocal,
        life_latest_premium_amount: quoteAmountLocal,
        life_insurance_carrier: 'W&S',
        desired_life_coverage: coverageAmount,
        coverage_term_life: this.wnsterm,
        desired_life_term: localStorage.getItem('bubble-termLength'),
        life_insurance_type: (Number(this.wnsterm != 65)) ? `Term ${this.wnsterm} Years` : 'up to age 65'
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      const json = {
        'id': this.commaninput.clientid,
        'sbli_totalPremium': this.selectedCardData.carrierId == 'sbli' ? this.selectedCardData['head']['quote_amount'] : 'NA',
        'paclife_totalPremium': this.selectedCardData.carrierId == 'paclife' ? this.selectedCardData['head']['quote_amount'] : 'NA',
        'selectedLife': this.selectedCardData.carrierId,
        'wns_term': this.wnsterm,
        'policytypeid': 2
      };
      this.pc.saveUpdateClientData(json);

      const inputJson4Db = {
        id: pacificLifeQuoteId,
        clientId: clientIdLocal,
        uid: pacificLifeQuoteId,
        carrier: 'Next',
        carrier_logo: this.selectedCardData['head']['carrier_icon'],
        policyName: 'Life Insurance',
        policyType: 'Life',
        policyAmount: quoteAmountLocal,
        policyNumber: '',
        coverageAmount: this.selectedCardData['selectedDetails']['coverage'],
        term: this.selectedCardData['selectedDetails']['term'],
        'policyDetails_PolicyName': carrierLocal,
        'policyDetails_HomeownerName': fullName,
        'policyDetails_ZIPCode': localStorage.getItem('bubble-zip'),
        'policyDetails_stateCode': localStorage.getItem('bubble-state'),
        'dataCategory': 'LifeQuote',
        'eventStr': 'Selected LifeQuote',
        Expires: '',
        selectedPlanDate: new Date().toISOString(),
      };
      console.log('Lead:saveAppData:inputJson4Db:', inputJson4Db);
      pc.BackendService.saveAppData(inputJson4Db).then(saveResponse => {
        console.log('Lead:createIdMapping:saveResponse: ', saveResponse);
        /////// id mapping
        const idMappingJson = {
          client_id: clientIdLocal,
          customer_id: localStorage.getItem('customer_id'),
          email_id: localStorage.getItem('bubble-email'),
          paclife_quote_id: pacificLifeQuoteId,
          status: 'LifeQuote',
          dob: localStorage.getItem('bubble-dob'),
        };
        localStorage.setItem('paclife_quote_id', pacificLifeQuoteId);

        this.pc.createIdMapping(idMappingJson);
      }).catch(error => {
        console.log('Lead:createIdMapping:error: ', error);
        this.showLoader = false;
      });

      appflowScreen = 'app-flow-start';
      setTimeout(() => {
        if (this.selectedCardData.carrierId == 'wns') {
          const sblijson = {
            'id': this.commaninput.clientid,
            'sbli_questionNumber': 1,
            'sbli_term': localStorage.getItem('bubble-termLength'),
            'wns_coverage': localStorage.getItem('bubble-coverage'),
            'sbli_appFlowBackButton': '0',
            'selectedLife': 'wns'
          };
          this.pc.saveUpdateClientData(sblijson).then(data => {
          }).catch(error => {
            console.log(error);
          });

          redirectUrl = `lifeCarrierThree/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}`;
        }

        // const updateJson = {
        //   otherAppflowScreen: appflowScreen,
        //   resumeUrl: redirectUrl
        // };
        // this.checkforResumeInit(updateJson, 'update').then(
        //   data => {
        //     this.stoploader.emit(2);
        //     location.href = redirectUrl;
        //     let newTab = window.open();
        //     newTab.location.href=redirectUrl;
        //    window.open(redirectUrl, '_blank');
        //   }
        // ).catch(error => console.log(error));
      }, 300);
      this.openModal.emit(carrierid);

    }
    async checkforResumeInit(inputJson, type) {
      const clientData = await this.pc.checkforResumeInit(inputJson, type);
      return clientData;
    }

    public sliderEvent(even) {
      debugger;
      this.coveragesilder = even.value;
      this.disablerefreshbutton = false;
    }
    public sliderEvent1(even) {
      debugger;
      this.wnsterm = even.value;
      this.disablerefreshbutton = false;
    }
    previousscreen() {


      if (this.backButton == 'PrefillResumeFlowLife') {
        location.href = 'PrefillResumeFlowLife';
      }
      else if (this.backButton == 'life-insurance') {
        location.href = 'life-insurance';
      }
      else if (this.backButton == 'CoupleAboutToGetMArriedLife') {
        location.href = 'CoupleAboutToGetMArriedLife';
      }
      else if (this.backButton == 'CoupleWithChildrenLife') {
        location.href = 'CoupleWithChildrenLife';
      }
      else if (this.backButton == 'SingleMotherLife') {
        location.href = 'SingleMotherLife';
      }
      else if (this.backButton == 'SingleParentLife') {
        location.href = 'SingleParentLife';
      }
      else if (this.backButton == 'PrefillKVLife') {
        location.href = 'bubble-Life-kellerwilliams';
      }
      else {
        location.href = `QuoteFlowV2Life/${this.uniqueId}/${this.clientid}/${this.displayAddress}/${this.insurancetype}/previous/1`;
      }

      //this.router.navigate(['/QuoteFlowV2', this.uniqueId, this.clientId,this.displayAddress,'life','previous',1]);
    }


    removesbliandwns() {
      this.wnsquote = undefined;
    }


    redirectWnsSbliAfterPopup(carrier)
    {
      debugger;
      this.wnssbliRediecturl = carrier == 'sbli' ?  `lifeCarrierTwo/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}` : `lifeCarrierThree/${this.commaninput.uniqueId}/${this.commaninput.clientid}/${this.commaninput.displayAddress}/${this.commaninput.insurancetype}`;
      console.log( carrier+'Rediecturl ', this.wnssbliRediecturl );
      setTimeout(() => {
        const el: HTMLElement = this.wnssbliRediect.nativeElement;
        el.click();
      }, 50);

    }



}
