import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sbli00801002',
  templateUrl: './sbli00801002.component.html',
  styleUrls: ['./sbli00801002.component.css']
})
export class Sbli00801002Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
