import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-sw4pt1',
  templateUrl: './sw4pt1.component.html',
  styleUrls: ['./sw4pt1.component.css']
})
export class Sw4pt1Component implements OnInit {

  @Output() welcomeQuestion = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  url: any;
  constructor(private $gaService: GoogleAnalyticsService,
    private utilityService: UtilityFunctionsService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    localStorage.setItem('bubble-lifejourneystart', 'false');
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );

    this.url = window.location.href;
    if (this.url.includes('HomeAppFlow')) {
      localStorage.setItem('insurancetypeText', 'Home Application');
    }


    const idMappingJson = {
      client_id: this.clientId,
      customer_id: this.clientId,
      email_id: localStorage.getItem('bubble-email'),
      status: 'ApplicationStarted'
    };
    this.pc.createIdMapping(idMappingJson);
  }

  next() {
    const etty = {
      'next': 1,
      'previous': 0,
    };
    this.welcomeQuestion.emit(etty);
    this.$gaService.event('Lets get Started', 'event', 'HomeAppStart');
    this.$gaService.gtag('event', 'HomeAppStart', {
      'event_category': 'ButtonClicked', 'event_label': 'Lets get Started '
    });
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.welcomeQuestion.emit(etty);
  }

}
