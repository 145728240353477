import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-ssn',
  templateUrl: './ssn.component.html',
  styleUrls: ['./ssn.component.css']
})
export class SsnComponent implements OnInit {
  //@Output() ssnoutput = new EventEmitter();
  @Output() public ssnoutput =
    new EventEmitter<{ component_id: any, answer: any }>();
  ssnLength: any;
  constructor(
    private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,
    private service: ParentChildService) { }
  SSN: any;
  inputJson: any;
  SSNerror: boolean;
  ssnerror: any;
  @Input() questionDetails: [];
  ngOnInit() {
    this.SSNerror = true;
    this.ssnerror = '';
    this.service.callComponentMethod();
    if (localStorage.getItem('bubble-SSN') == null || localStorage.getItem('bubble-SSN') == undefined) {

      this.SSN = '';
      const ssnquestion = this.questionDetails;
    }
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_13_SSN'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    // else{


    // let  ssnquestion= this.questionDetails;
    // this.SSN=localStorage.getItem('bubble-SSN');

    // }






  }

  Saveddata: any;
  public onSubmit(data) {
    debugger;
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);
      // this.inputJson={
      //   'id': localStorage.getItem('clientID'),
      //   'ssn' : this.SSN
      // }
      this.inputJson = {};
      this.inputJson['id'] = localStorage.getItem('clientID');
      this.inputJson[data.answer_key] = this.SSN;
      localStorage.setItem('bubble-SSN', this.SSN);
      this.ssnoutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
      this.submitdata(this.inputJson);

    } else {
      alert('Declined!');
    }
    this.$gaService.event('Next', 'event', 'LifeAppSSN');
    const eventOptions: EventOption = {
      page_title: ' Insured SSN',
      path: 'AppFlowStart',
      url: window.location.href
    };

    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppSSN');

  }

  public submitdata(inputJson) {
    debugger;
    this.pc.saveUpdateClientData(inputJson);
  }




  SelectedOption: any;
  SecondQuestion: any;
  public GetOptions(data) {
    this.SelectedOption = data;
    if (this.SelectedOption == 0) {
      document.getElementById('0').style.border = 'solid 2px orange';
      document.getElementById('1').style.border = 'solid 1px #dae7ec';
    } else {
      document.getElementById('1').style.border = 'solid 2px orange';
      document.getElementById('0').style.border = 'solid 1px #dae7ec';
    }
  }

  ssnlength() {
    debugger;
    const j = this.SSN.replace(/-/g, '');
    const A = j.substring(0, 3);
    const B = j.substring(3, 5);
    const C = j.substring(5, 9);
    if (B == '') {
      this.SSN = A;
    }
    else if (B != '' && C == '') {
      this.SSN = A + '-' + B;
    }
    else if (B != '' && C != '') {
      this.SSN = A + '-' + B + '-' + C;
    }
    else {
      this.SSN = j;
    }

    this.ssnLength = this.SSN.toString().length;
    if (this.ssnLength > 10) {
      this.SSNerror = false;
      this.ssnerror = '';
    }
    else {
      this.SSNerror = true;
      this.ssnerror = 'Invalid SSN';
    }
    // if (this.SSN.length == 3) {
    //   this.SSN = this.SSN + "-";
    //   this.SSNerror = true;
    // }
    // else if (this.SSN.length == 6) {
    //   this.SSN = this.SSN + "-";
    //   this.SSNerror = true;
    // }
    // else if (this.SSN.length < 10) {
    //   this.SSNerror = true;
    // }
    // else {
    //   this.SSNerror = false;
    // }
  }

  public goBack() {
    debugger;
    //  this.isActiveMilitaryOutput.emit(this.questionDetails["component_id"]+",Goback");
    this.ssnoutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }

}
