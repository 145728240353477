import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ValidationService } from 'src/app/shared/validation.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-add-beneficiaries',
  templateUrl: './add-beneficiaries.component.html',
  styleUrls: ['./add-beneficiaries.component.css']
})
export class AddBeneficiariesComponent implements OnInit {
  inputJson: any;
  relationshiptype: any;
  nonIndividualRelationship: any;

  constructor(
    private pc: ProcessCacheService,
    private utilityService:UtilityFunctionsService,
    public validationService: ValidationService,
    private $gaService: GoogleAnalyticsService,
    private service:ParentChildService) { }
  individualOptons: any;
  dynamicdiv = [];
  dynamicdivNonIndiviadual = [];
  selectedIdex: any;
  Relationships: any;
  entitiesOptions: any;
  entitiesresult: any;
  Grandtotal: any;
  ShowAddBeneficiery: boolean;
  editForTheComponent: any;
  curDate: any;
  minDate;
  maxlength: any;
  BenefficiariesInfo = [];
  CompleteBeneficiaryDynamicDiv = [];
  primarybeneficiariesDetails: any;
  showloader:boolean;
  @Input() questionDetails: [];
  //@Output() addBeneficiariesOutput = new EventEmitter();
  @Output() public addBeneficiariesOutput =
    new EventEmitter();
  ShowIndividualsBeneficiary: boolean;
  ShowNonIndividualsBeneficiary: boolean;
  ngOnInit(): void {
    this.service.callComponentMethod();
    this.showloader=true;

    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_7_Beneficiary'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    document.getElementById('appFlowFooterID').style.display = 'block';
    this.BeneficiaryBitVis = 1;
    this.ShowIndividualsBeneficiary = false;
    this.ShowNonIndividualsBeneficiary = false;
    this.maxlength = 15;
    this.curDate = new Date();
    const y = Number(this.curDate.getFullYear());
    let m = '' + (this.curDate.getMonth() + 1);
    let d = '' + this.curDate.getDate();
    if (m.length < 2)
      m = '0' + m;
    if (d.length < 2)
      d = '0' + d;
    this.minDate = new Date(m + '/' + d + '/' + y);
    //this.minDate = new Date();


    this.selectedIdex = 0;
    this.ShowAddBeneficiery = true;
    this.individualOptons = this.questionDetails['group_of_inputs']['answer_3']['dropdown_options'];
    this.result = this.individualOptons.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 2);
      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }
      resultArray[chunkIndex].push(item);
      return resultArray;
    }, []);


    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear()) - 18;
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    const qestentity = {
      'firstname': '',
      'lastname': '',
      'relationship': 'Choose',
      // "dateofbirth": month + '/' + day + '/' + year,
      'percentage': ''
    };

    this.dynamicdivNonIndiviadual.push(qestentity);
    debugger;


    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {
        debugger;
        const clientDataFiltered = JSON.parse(data.Payload);
        this.primarybeneficiariesDetails = clientDataFiltered.body.addBeneficiaryInfo;

        this.editForTheComponent = localStorage.getItem('editBeneficiaryInformation');
        if (this.editForTheComponent == 'true') {
          // let maindiv = JSON.parse(localStorage.getItem("bubble-primarybeneficiariesDetails")).length != undefined || JSON.parse(localStorage.getItem("bubble-primarybeneficiariesDetails")).length > 0 ? JSON.parse(localStorage.getItem("bubble-primarybeneficiariesDetails")) : [];
          // this.dynamicdiv = maindiv.filter(x => x.lastname != "");
          // this.dynamicdivNonIndiviadual = maindiv.filter(x => x.lastname == "");

          // let total1 = 0;
          // maindiv.forEach(element => {
          //   total1 += Number(element.percentage);
          // });
          // this.Grandtotal = total1.toLocaleString();
          // localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
          const qestentity = {
            'firstname': '',
            'lastname': '',
            'relationship': 'Choose',
            // "dateofbirth": month + '/' + day + '/' + year,
            'percentage': ''
          };

          this.dynamicdiv.push(qestentity);
          const maindiv = this.primarybeneficiariesDetails.length != undefined || this.primarybeneficiariesDetails.length > 0 ? this.primarybeneficiariesDetails : [];
          this.div3 = maindiv;
          localStorage.setItem('BeneficiaryBit', '1');
          this.ShowIndividualsBeneficiary = true;
          this.ShowNonIndividualsBeneficiary = false;
          // this.dynamicdiv = maindiv.filter(x => x.lastname != "");
          // this.dynamicdivNonIndiviadual = maindiv.filter(x => x.lastname == "");
          let total1 = 0;
          maindiv.forEach(element => {
            total1 += Number(element.percentage);
          });
          this.Grandtotal = total1.toLocaleString();
          localStorage.setItem('Bubble-grandTotal', this.Grandtotal);

        } else {
          const beneficiaryDetailsTemp = this.primarybeneficiariesDetails;
          if (beneficiaryDetailsTemp == null ||
            beneficiaryDetailsTemp == 'undefined' ||
            beneficiaryDetailsTemp == undefined) {
            this.curDate = new Date();
            const year = Number(this.curDate.getFullYear()) - 18;
            let month = '' + (this.curDate.getMonth() + 1);
            let day = '' + this.curDate.getDate();
            if (month.length < 2)
              month = '0' + month;
            if (day.length < 2)
              day = '0' + day;

            this.selectedIdex = 0;
            this.ShowAddBeneficiery = true;
            this.individualOptons = this.questionDetails['group_of_inputs']['answer_3']['dropdown_options'];
            this.result = this.individualOptons.reduce((resultArray, item, index) => {
              const chunkIndex = Math.floor(index / 2);
              if (!resultArray[chunkIndex]) {
                resultArray[chunkIndex] = []; // start a new chunk
              }
              resultArray[chunkIndex].push(item);
              return resultArray;
            }, []);

            const qestentity = {
              'firstname': '',
              'lastname': '',
              'relationship': 'Choose',
              // "dateofbirth": month + '/' + day + '/' + year,
              'percentage': ''
            };


            this.dynamicdiv.push(qestentity);
            // this.dynamicdivNonIndiviadual.push(qestentity);

            let total1 = 0;
            this.dynamicdiv.forEach(element => {
              total1 += Number(element.percentage);
            });
            this.Grandtotal = total1.toLocaleString();
            localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
          }
          else {
            const qestentity = {
              'firstname': '',
              'lastname': '',
              'relationship': 'Choose',
              // "dateofbirth": month + '/' + day + '/' + year,
              'percentage': ''
            };

            this.dynamicdiv.push(qestentity);
            const maindiv = this.primarybeneficiariesDetails.length != undefined || this.primarybeneficiariesDetails.length > 0 ? this.primarybeneficiariesDetails : [];
            this.div3 = maindiv;
            localStorage.setItem('BeneficiaryBit', '1');
            this.ShowIndividualsBeneficiary = true;
            this.ShowNonIndividualsBeneficiary = false;
            // this.dynamicdiv = maindiv.filter(x => x.lastname != "");
            // this.dynamicdivNonIndiviadual = maindiv.filter(x => x.lastname == "");
            let total1 = 0;
            maindiv.forEach(element => {
              total1 += Number(element.percentage);
            });
            this.Grandtotal = total1.toLocaleString();
            localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
          }
        }


        this.showloader=false;
      }
    ).catch(error => {
      console.log(error);
    });



  }



  public isEven(value) {
    if (value % 2 === 0)
      return 1;
    else
      return 0;
  }
  adddiv() {
    debugger;


    if (this.Grandtotal < 100) {

      this.selectedIdex = 0;
      this.individualOptons = this.questionDetails['group_of_inputs']['answer_3']['dropdown_options'];
      this.result = this.individualOptons.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      let total1 = 0;
      this.dynamicdiv.forEach(element => {
        total1 += Number(element.percentage);
      });
      this.Grandtotal = total1.toLocaleString();
      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
      const qestentity = {
        'firstname': '',
        'lastname': '',
        'relationship': 'Choose',
        // "dateofbirth": new Date(),
        'percentage': ''
      };

      this.dynamicdiv.push(qestentity);
    } else {

    }

  }

  adddivnonindividual() {
    debugger;


    if (this.Grandtotal < 100) {

      this.selectedIdex = 0;
      this.individualOptons = this.questionDetails['group_of_inputs']['answer_3']['dropdown_options'];
      this.result = this.individualOptons.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      // let total1 = 0;
      // this.dynamicdivNonIndiviadual.forEach(element => {
      //   total1 += Number(element.percentage);
      // });
      // this.Grandtotal = total1.toLocaleString();
      let total1 = 0;
      let total = 0;
      this.dynamicdivNonIndiviadual.forEach(element => {
        total1 += Number(element.percentage);
      });
      this.dynamicdiv.forEach(e => {
        total += Number(e.percentage);
      });
      this.Grandtotal = Number(total) + Number(total1.toLocaleString());
      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);

      const qestentity = {
        'firstname': '',
        'lastname': '',
        'relationship': 'Choose',
        // "dateofbirth": new Date(),
        'percentage': ''
      };

      this.dynamicdivNonIndiviadual.push(qestentity);
    } else {

    }

  }


  RemoveRow(divindex) {
    {
      const index = this.dynamicdiv.findIndex(x => x.firstname == divindex);
      this.dynamicdiv.splice(index, 1);
      let total1 = 0;
      this.dynamicdiv.forEach(element => {
        total1 += Number(element.percentage);
      });
      this.Grandtotal = total1.toLocaleString();
      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
    }

  }


  RemoveAddedData(divindex) {
    {
      const index = this.div3.findIndex(x => x.firstname == divindex);
      this.div3.splice(index, 1);
      let total1 = 0;
      this.div3.forEach(element => {
        total1 += Number(element.percentage);
      });
      this.Grandtotal = total1.toLocaleString();
      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
    }

  }


  RemoveRownonindividual(divindex) {
    {
      const index = this.dynamicdivNonIndiviadual.findIndex(x => x.firstname == divindex);
      this.dynamicdivNonIndiviadual.splice(index, 1);

      let total1 = 0;
      this.dynamicdivNonIndiviadual.forEach(element => {
        total1 += Number(element.percentage);
      });
      this.Grandtotal = total1.toLocaleString();
      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
    }

  }

  result: any;
  opendisc(index) {
    debugger;
    this.selectedIdex = index;
    this.individualOptons = this.questionDetails['group_of_inputs']['answer_3']['dropdown_options'];
    this.result = this.individualOptons.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 2);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
    document.getElementById('disclaimer').style.display = 'block';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
  }


  opendiscnonindividual(index) {
    debugger;
    this.selectedIdex = index;
    this.individualOptons = this.questionDetails['group_of_inputs']['answer_3']['dropdown_options'];
    this.result = this.individualOptons.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 2);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
    document.getElementById('disclaimer1').style.display = 'block';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
  }

  closediscnonindiviadual() {
    document.getElementById('disclaimer1').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
  }

  closedisc() {
    document.getElementById('disclaimer').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
  }


  onKey1(event, index, property) {
    debugger;
    if (event.target.value > 100) {
      this.dynamicdiv[index][property] = 0;
      this.ShowAddBeneficiery = false;
    } else {
      this.dynamicdiv[index][property] = event.target.value;
      this.ShowAddBeneficiery = true;
    }

    let total1 = 0;
    this.dynamicdiv.forEach(element => {
      total1 += Number(element.percentage);
    });
    this.Grandtotal = total1.toLocaleString();
  }






  onKey(event, index, property) {
    debugger;
    if (event.target.value > 100) {
      this.dynamicdiv[index][property] = 0;
      this.ShowAddBeneficiery = false;
    } else {
      if (this.Grandtotal < 100) {
        const gt = Number(this.Grandtotal) + Number(event.target.value);
        if (gt > 100) {
          this.dynamicdiv[index][property] = 0;
          this.ShowAddBeneficiery = true;
        } else {
          this.dynamicdiv[index][property] = event.target.value;
          this.ShowAddBeneficiery = true;
        }
      } else {
        this.dynamicdiv[index][property] = 0;
      }

    }



    // let total1 = 0;
    // let total = 0;
    // this.dynamicdivNonIndiviadual.forEach(element => {
    //   total1 += Number(element.percentage);
    // });
    // this.dynamicdiv.forEach(e => {
    //   total += Number(e.percentage);
    // })
    // this.Grandtotal = Number(total) + Number(total1.toLocaleString());
    // localStorage.setItem('Bubble-grandTotal', this.Grandtotal);

    // if (this.Grandtotal > 100) {
    //   this.dynamicdiv[index][property] = 0;
    //   let total1 = 0;
    //   let total = 0;
    //   this.dynamicdivNonIndiviadual.forEach(element => {
    //     total1 += Number(element.percentage);
    //   });
    //   this.dynamicdiv.forEach(e => {
    //     total += Number(e.percentage);
    //   })
    //   this.Grandtotal = Number(total) + Number(total1.toLocaleString());
    //   localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
    // } else {
    //   let total1 = 0;
    //   let total = 0;
    //   this.dynamicdivNonIndiviadual.forEach(element => {
    //     total1 += Number(element.percentage);
    //   });
    //   this.dynamicdiv.forEach(e => {
    //     total += Number(e.percentage);
    //   })
    //   this.Grandtotal = Number(total) + Number(total1.toLocaleString());
    //   localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
    // }

    const test = this.dynamicdiv;
    debugger;
    for (let i = 0; i < this.dynamicdiv.length; i++) {
      if (this.CompleteBeneficiaryDynamicDiv.length == undefined || this.CompleteBeneficiaryDynamicDiv.length == 0) {

        this.CompleteBeneficiaryDynamicDiv.push(this.dynamicdiv[i]);
        if (this.CompleteBeneficiaryDynamicDiv.length > 0) {

          if (index == (this.CompleteBeneficiaryDynamicDiv.length - 1)) {
            this.CompleteBeneficiaryDynamicDiv[index] == this.dynamicdiv[i];
          }

        }

      } else {
        if (index == (this.CompleteBeneficiaryDynamicDiv.length - 1)) {
          this.CompleteBeneficiaryDynamicDiv[index] == this.dynamicdiv[i];
        }
        else {
          this.CompleteBeneficiaryDynamicDiv.push(this.dynamicdiv[this.dynamicdiv.length - 1]);
          break;
        }

      }
    }

  }

  CompleteBeneficiaryDynamicDiv1 = [];
  onKeynonindividual(event, index, property) {
    debugger;
    if (event.target.value > 100) {
      this.dynamicdivNonIndiviadual[index][property] = 0;
      this.ShowAddBeneficiery = false;
    } else {

      if (this.Grandtotal < 100) {
        const gt = Number(this.Grandtotal) + Number(event.target.value);
        if (gt > 100) {
          this.dynamicdivNonIndiviadual[index][property] = 0;
          this.ShowAddBeneficiery = true;
        } else {
          this.dynamicdivNonIndiviadual[index][property] = event.target.value;
          this.ShowAddBeneficiery = true;
        }
        // this.dynamicdivNonIndiviadual[index][property] = event.target.value;
        // this.ShowAddBeneficiery = true;
      } else {
        this.dynamicdivNonIndiviadual[index][property] = 0;
        //this.ShowAddBeneficiery = true;
      }


    }

    // let total1 = 0;
    // let total = 0;
    // this.dynamicdivNonIndiviadual.forEach(element => {
    //   total1 += Number(element.percentage);
    // });
    // this.dynamicdiv.forEach(e => {
    //   total += Number(e.percentage);
    // })
    // this.Grandtotal = Number(total) + Number(total1.toLocaleString());
    // localStorage.setItem('Bubble-grandTotal', this.Grandtotal);

    // if (this.Grandtotal > 100) {
    //   this.dynamicdivNonIndiviadual[index][property] = 0;
    //   let total1 = 0;
    //   let total = 0;
    //   this.dynamicdivNonIndiviadual.forEach(element => {
    //     total1 += Number(element.percentage);
    //   });
    //   this.dynamicdiv.forEach(e => {
    //     total += Number(e.percentage);
    //   })
    //   this.Grandtotal = Number(total) + Number(total1.toLocaleString());
    //   localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
    // }

    const test = this.dynamicdivNonIndiviadual;
    debugger;
    for (let i = 0; i < this.dynamicdivNonIndiviadual.length; i++) {
      if (this.CompleteBeneficiaryDynamicDiv.length == undefined || this.CompleteBeneficiaryDynamicDiv.length == 0) {

        this.CompleteBeneficiaryDynamicDiv.push(this.dynamicdivNonIndiviadual[i]);
        if (this.CompleteBeneficiaryDynamicDiv.length > 0) {

          if (index == (this.CompleteBeneficiaryDynamicDiv.length - 1)) {
            this.CompleteBeneficiaryDynamicDiv[index] == this.dynamicdivNonIndiviadual[i];
          }

        }

      } else {
        if (index == (this.CompleteBeneficiaryDynamicDiv.length - 1)) {
          if (this.dynamicdivNonIndiviadual.length > 0) {
            this.CompleteBeneficiaryDynamicDiv.push(this.dynamicdivNonIndiviadual[i]);
            break;
          }
          this.CompleteBeneficiaryDynamicDiv[index] == this.dynamicdivNonIndiviadual[i];
        }
        else {
          if (this.CompleteBeneficiaryDynamicDiv.length > this.CompleteBeneficiaryDynamicDiv.length)
            this.CompleteBeneficiaryDynamicDiv.push(this.dynamicdivNonIndiviadual[this.dynamicdivNonIndiviadual.length - 1]);

          // this.CompleteBeneficiaryDynamicDiv.push(this.dynamicdivNonIndiviadual[this.dynamicdivNonIndiviadual.length-1]);
          break;
        }

      }



    }

  }


  invalidDate: boolean;
  message: string;
  is_valid: boolean;

  onKeyFordate(even, index, property) {
    debugger;
    if (even == null) {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dynamicdiv[index][property] = month + '/' + day + '/' + year;

    } else {
      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();
      const ageYear = Number(curYear) - Number(year);
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dynamicdiv[index][property] = month + '/' + day + '/' + year;

    }

    const newDate = new Date(even);
    console.log('DOB entered: ', newDate);
    const checkAge = this.validationService.validate_dob('beneficiary_dob', newDate);
    this.message = checkAge.message;
    this.is_valid = checkAge.is_valid;

  }




  public dateCheck(even, index, property) {
    debugger;
    if (even == null) {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dynamicdiv[index][property] = month + '/' + day + '/' + year;

      // this.dynamicdiv[0][property] = "20/04/2021";
    } else {
      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();
      const ageYear = Number(curYear) - Number(year);
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dynamicdiv[index][property] = month + '/' + day + '/' + year;
    }
    const newDate = new Date(even);
    console.log('DOB entered: ', newDate);
    const checkAge = this.validationService.validate_dob('beneficiary_dob', newDate);
    this.message = checkAge.message;
    this.is_valid = checkAge.is_valid;

  }

  BeneficiaryBitVis;
  adddiv1() {
    debugger;
    localStorage.setItem('BeneficiaryBit', '1');
    this.BeneficiaryBitVis = localStorage.getItem('BeneficiaryBit');
    if (this.Grandtotal < 100) {

      this.selectedIdex = 0;
      this.individualOptons = this.questionDetails['group_of_inputs']['answer_3']['dropdown_options'];
      this.result = this.individualOptons.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      let total1 = 0;
      this.dynamicdiv.forEach(element => {
        total1 += Number(element.percentage);
      });
      this.Grandtotal = total1.toLocaleString();

      const qestentity = {
        'firstname': '',
        'lastname': '',
        'relationship': 'Choose',
        // "dateofbirth": new Date(),
        'percentage': ''
      };

      this.dynamicdiv.push(qestentity);
    } else {

    }

  }


  adddiv2() {
    localStorage.setItem('BeneficiaryBit', '2');
    this.BeneficiaryBitVis = localStorage.getItem('BeneficiaryBit');
    debugger;
    if (this.Grandtotal < 100) {

      this.selectedIdex = 0;
      this.individualOptons = this.questionDetails['group_of_inputs']['answer_3']['dropdown_options'];
      this.result = this.individualOptons.reduce((resultArray, item, index) => {
        const chunkIndex = Math.floor(index / 2);

        if (!resultArray[chunkIndex]) {
          resultArray[chunkIndex] = []; // start a new chunk
        }

        resultArray[chunkIndex].push(item);

        return resultArray;
      }, []);
      let total1 = 0;
      this.dynamicdiv.forEach(element => {
        total1 += Number(element.percentage);
      });
      this.Grandtotal = total1.toLocaleString();

      const qestentity = {
        'firstname': '',
        'lastname': '',
        'relationship': 'Choose',
        // "dateofbirth": new Date(),
        'percentage': ''
      };

      this.dynamicdiv.push(qestentity);
    } else {

    }

  }



  div3 = [];
  AddData() {
    debugger;

    this.Grandtotal = localStorage.getItem('Bubble-grandTotal');
    if (Number(this.Grandtotal) < 100) {
      for (let i = 0; i < this.dynamicdiv.length; i++) {
        if (this.dynamicdiv[i].firstname != '' && this.dynamicdiv[i].relationship != 'Choose') {
          this.div3.push(this.dynamicdiv[i]);
        }
      }

      let total = 0;
      const total1 = 0;
      this.dynamicdiv.length = 0;
      const qestentity = {
        'firstname': '',
        'lastname': '',
        'relationship': 'Choose',
        // "dateofbirth": new Date(),
        'percentage': ''
      };

      this.dynamicdiv.push(qestentity);

      this.div3.forEach(e => {
        total += Number(e.percentage);
      });
      this.Grandtotal = Number(total) + Number(total1.toLocaleString());
      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);
    } else {

    }



  }


  AddDataOne() {
    debugger;

    this.Grandtotal = localStorage.getItem('Bubble-grandTotal');
    if (Number(this.Grandtotal) < 100) {
      for (let i = 0; i < this.dynamicdivNonIndiviadual.length; i++) {
        if (this.dynamicdivNonIndiviadual[i].firstname != '' && this.dynamicdivNonIndiviadual[i].relationship != 'Choose') {
          this.div3.push(this.dynamicdivNonIndiviadual[i]);
        }

      }

      this.dynamicdivNonIndiviadual.length = 0;
      const qestentity = {
        'firstname': '',
        'lastname': '',
        'relationship': 'Choose',
        // "dateofbirth": new Date(),
        'percentage': ''
      };

      this.dynamicdivNonIndiviadual.push(qestentity);

      let total = 0;
      const total1 = 0;

      this.div3.forEach(e => {
        total += Number(e.percentage);
      });
      this.Grandtotal = Number(total) + Number(total1.toLocaleString());
      localStorage.setItem('Bubble-grandTotal', this.Grandtotal);

    } else {

    }
  }


  public onSubmit(data) {
    debugger;
    if (data.on_submit == 'proceed') {

      // for (let i = 0; i < this.dynamicdivNonIndiviadual.length; i++) {
      //   if (this.dynamicdivNonIndiviadual[i].relationship != 'Select') {
      //     this.dynamicdiv.push(this.dynamicdivNonIndiviadual[i]);
      //   }

      // }

      localStorage.setItem('questionId', data.question_sequence);
      this.inputJson = {};
      this.inputJson['id'] = localStorage.getItem('clientID');
      this.inputJson[data.group_key] = this.div3;

      this.submitdata(this.inputJson);

    } else {
      alert('Declined!');
    }

    const eventOptions = {
      page_title: 'Add Beneficiary Next',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.$gaService.event('Next', 'event', 'LifeAppAddBeneficiary');
    this.utilityService.eventWrapped( eventOptions, 'Next', 'event', 'LifeAppAddBeneficiary' );
  }

  public submitdata(inputJson) {

    debugger;
    if (this.editForTheComponent == 'true') {
      const PolicyDetails = JSON.stringify(this.div3);
      localStorage.setItem('bubble-primarybeneficiariesDetails', PolicyDetails);
      localStorage.setItem('bubble-beneficiariesGrandTotal', this.Grandtotal);
      this.addBeneficiariesOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson, afterEdit: true });
      this.pc.saveUpdateClientData(inputJson);
    } else {
      const PolicyDetails = JSON.stringify(this.div3);
      localStorage.setItem('bubble-primarybeneficiariesDetails', PolicyDetails);
      localStorage.setItem('bubble-beneficiariesGrandTotal', this.Grandtotal);
      this.addBeneficiariesOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
      this.pc.saveUpdateClientData(inputJson);
    }



  }
  public GetRadioOptionID(data) {
    debugger;
    this.relationshiptype = data;
    this.dynamicdiv[this.selectedIdex].relationship = data;
  }

  public GetRadioOptionIDNonIndividual(data) {
    debugger;
    this.nonIndividualRelationship = data;
    this.dynamicdivNonIndiviadual[this.selectedIdex].relationship = data;
    if (data == 'Trust') {
      this.maxlength = 80;
    }
    if (data === 'Estate') {
      this.dynamicdivNonIndiviadual[this.selectedIdex].firstname = 'Estate of Insured';
    } else {
      if (this.dynamicdivNonIndiviadual[this.selectedIdex].firstname === 'Estate of Insured') {
        this.dynamicdivNonIndiviadual[this.selectedIdex].firstname = '';
      }

    }

  }

  public goBack() {
    // this.addBeneficiariesOutput.emit(this.questionDetails["component_id"]+",Goback");
    this.addBeneficiariesOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }


  //New Functionality

  AddIndividualBeneficiary() {

    localStorage.setItem('BeneficiaryBit', '1');
    this.ShowIndividualsBeneficiary = true;
    this.ShowNonIndividualsBeneficiary = false;
  }

  AddNonIndividualBeneficiary() {


    localStorage.setItem('BeneficiaryBit', '2');
    this.ShowIndividualsBeneficiary = false;
    this.ShowNonIndividualsBeneficiary = true;
  }


}
