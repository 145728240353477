import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-personal-details-question5',
  templateUrl: './personal-details-question5.component.html',
  styleUrls: ['./personal-details-question5.component.css']
})
export class PersonalDetailsQuestion5Component implements OnInit {
  @Output() nextquestion4 = new EventEmitter();
  PhoneNumber:any;
  ReplacementCost:any;
  constructor() { }

  ngOnInit(): void {
    this.PhoneNumber='11/16/2020';
    this.ReplacementCost='$360,000';
  }


  next() {
  
    let etty = {
      "questionNo": "4",
      "nextprev": 1
    }
    this.nextquestion4.emit(etty);
  }
}
