import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from './../../components/lifescore-error-alert/lifescore-error-alert.component';

import { environment } from './../../../environments/environment';

@Component({
  selector: 'app-firewall-screen',
  templateUrl: './firewall-screen.component.html',
  styleUrls: ['./firewall-screen.component.css']
})
export class FirewallScreenComponent implements OnInit {
  errormessage: any;

  constructor(private http: HttpClient, private pc: ProcessCacheService, public router: Router,
    private modalService: NgbModal) { }

  carrierServerUrl: string = this.pc.envCred.paclifeUrl + '/paclife/isAlive'; //"https://stage.getmybubble.io/paclife/isAlive";// leveOne
  api2Url: string = this.pc.envCred.api2Domain + '?data_category=isAlive';// levelTwo
  carrierServerUrlAccess = false;
  api2UrlAccess = false;
  lambdaAccess = false;
  levelOne = 'Checking';
  levelTwo = 'Checking';
  levelThree = 'Checking';
  finalMsg = 'Please wait...';
  disableProceedBtn = true;
  disableHomeBtn = false;
  loader = true;

  ngOnInit(): void {
    debugger;
    this.checkAccessibility();
  }


  checkAccessibility(): void {
    // debugger;
    // this.http.get(this.carrierServerUrl).subscribe(
    //   (data: any) => {
    //     debugger;
    console.log('Paclife Url Passed');
    this.carrierServerUrlAccess = true;
    this.levelOne = 'Passed';
    this.http.get(this.api2Url).subscribe(
      (data: any) => {
        this.api2UrlAccess = true;
        console.log('api2Url Url Passed', data);
        this.levelTwo = 'Passed';
        this.pc.getLambdaAccess().then(
          data => {
            if (data === true) {
              console.log('lambdaAccess Passed', data);
              /* this.lambdaAccess = true;
              this.levelThree = "Passed";
              this.finalMsg = "You can proceed.";
              this.disableProceedBtn = false; */
              localStorage.setItem('accessChecked', '1');
              let nextURL = localStorage.getItem('accessedURL');
              const spliturl = nextURL.split('%3F');
              if (spliturl.length > 0) {
                nextURL = nextURL.replace(/\%3F/gi, '?').replace(/\%3D/gi, '=');
              }
              // nextURL = nextURL.replace("%3F", "?").replace("%3D", "=");
              console.log('accessedURL', nextURL);
              this.router.navigate([nextURL]);
            } else {
              console.log('lambdaAccess Failed', data);
              this.errormessage = 'lambdaAccess Failed';
              /* this.lambdaAccess = false;
              this.levelThree = "Failed";
              this.finalMsg = "You cannot proceed."; */
              this.loader = false;
              localStorage.setItem('accessChecked', '0');
              this.openModal();
            }
          }
        ).catch(
          error => {
            console.log('lambdaAccess Failed', error);
            this.errormessage = 'lambdaAccess Failed';
            /* this.levelThree = "Failed";
            this.finalMsg = "You cannot proceed."; */
            this.loader = false;
            localStorage.setItem('accessChecked', '0');
            this.openModal();
          }
        );

        console.log(this.carrierServerUrlAccess, this.api2UrlAccess, this.lambdaAccess);
      },
      (error: any) => {
        console.log('api2Url Url Failed', error);
        /* this.levelTwo = "Failed";
        this.levelThree = "Not Tested";
        this.finalMsg = "You cannot proceed."; */
        this.loader = false;
        this.errormessage = 'api2Url Url Failed';
        localStorage.setItem('accessChecked', '0');
        this.openModal();
      }
    );
  }
  // ,
  // (error: any) => {
  //   console.log('Paclife Url Failed', error);
  //   /* this.levelOne = "Failed";
  //   this.levelTwo = "Not Tested";
  //   this.levelThree = "Not Tested";
  //   this.finalMsg = "You cannot proceed."; */
  //   //this.loader = false;
  //   // localStorage.setItem("accessChecked", "0");
  //   //this.errormessage = 'Paclife Url Failed';
  //   //this.openModal();
  //   localStorage.setItem('accessChecked', '1');
  //   let nextURL = localStorage.getItem('accessedURL');
  //   const spliturl = nextURL.split('%3F');
  //   if (spliturl.length > 0) {
  //     nextURL = nextURL.replace(/\%3F/gi, '?').replace(/\%3D/gi, '=');
  //   }
  //   // nextURL = nextURL.replace("%3F", "?").replace("%3D", "=");
  //   console.log('accessedURL', nextURL);
  //   this.router.navigate([nextURL]);
  // }
  //     );
  // }

  gotoApp() {
    const nextURL = localStorage.getItem('accessedURL');
    console.log('accessedURL', nextURL);
    this.router.navigate([nextURL]);
  }

  gotoHome() {
    //window.location.href = environment.carrierErrorRedirectURL;
    window.location.href = this.pc.envCred.url;
  }
  openModal() {
    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: this.errormessage
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
        // keyboard: false,
        // backdrop: 'static'
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: 'We are facing some problem in reaching our server, please come back later.',
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      window.location.href = this.pc.envCred.url;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }
}
