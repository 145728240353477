import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { TrackerService } from 'src/app/shared/tracker.service';
import{ProcessCacheService} from '../../shared/process-cache.service'

@Component({
  selector: 'app-home-life-apply',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './home-life-apply.component.html',
  styleUrls: ['./home-life-apply.component.css']
})
export class HomeLifeApplyComponent implements OnInit, OnDestroy {
  sub: Subscription;
  percent: number;
  trackerType: string;
  insuranceType: string;
  trackingTypeSub: Subscription;
  type: string;
  constructor(private tracker: TrackerService,private pc :ProcessCacheService) { }

  ngOnInit(): void {
    this.pc.componentMethodCalled$.subscribe(() => {
      debugger
      const insuranceType = localStorage.getItem("insuranceType");
      switch (insuranceType) {
        case 'homeLife':
          console.log("insuranceType", insuranceType);
          this.insuranceType = 'My Home';
          this.type = 'My Life'
          break;
        case 'home':
          console.log("insuranceType", insuranceType);
          this.insuranceType = 'My Home';
          this.type = 'Select Policy'
          break;
        case 'life':
          console.log("insuranceType", insuranceType);
          this.insuranceType = 'My Life';
          this.type = 'Select Policy'
          break;
  
        default:
          break;
      }
});
    this.sub = this.tracker.getProgressStatus().subscribe(percent => {
      this.percent = percent;
    });
    const insuranceType = localStorage.getItem("insuranceType");

    switch (insuranceType) {
      case 'homeLife':
        console.log("insuranceType", insuranceType);
        this.insuranceType = 'My Home';
        this.type = 'My Life'
        break;
      case 'home':
        console.log("insuranceType", insuranceType);
        this.insuranceType = 'My Home';
        this.type = 'Select Policy'
        break;
      case 'life':
        console.log("insuranceType", insuranceType);
        this.insuranceType = 'My Life';
        this.type = 'Select Policy'
        break;

      default:
        break;
    }

    document.title = this.insuranceType + ' powered By Bubble';

    this.trackingTypeSub =
      this.tracker.getTrackingType().subscribe(type => {
        this.trackerType = type;
        console.log(this.trackerType == 'life');
        console.log('trackertype : ', this.trackerType);
      })
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.trackingTypeSub.unsubscribe();
  }



}

  // @Input() currentPage: number;
  // @Input() totalPages: number;
  // middlePage: number;
  // width1: number;
  // width2: number;

  // this.middlePage =  parseInt(`${this.totalPages/2}`);
  // this.width1 = this.currentPage > this.middlePage ? 100 : this.currentPage/this.middlePage *100;
  // this.width2 = this.currentPage > this.middlePage ? (this.currentPage - this.middlePage)/this.middlePage * 100 : 0;
