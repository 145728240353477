import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-insurance-details',
  templateUrl: './home-insurance-details.component.html',
  styleUrls: ['./home-insurance-details.component.css']
})
export class HomeInsuranceDetailsComponent implements OnInit {

  @Input() policyTyep;

  constructor() { }

  ngOnInit(): void {
  }

}
