import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  /* 
   * @param type => common_age, carrier_name(ex: paclife, sbli), beneficiary_dob etc. Default common_age
   * @param inputDate => date value in Date Object format to validate. Default today date
   * return {
      is_valid: boolean = (true or false),
      message: string = if is_valid is false then message should be displayed.
    }
  */
  validate_dob(type = "common_age", inputDate = new Date()) {
    debugger
    let is_valid = true;
    let message = "";
    let givenDate = inputDate;
    let today = new Date();
    let age = this.calculate_age(givenDate);

    switch (type) {
      case "common_age": // for type = common , dob should be >=18yrs age diff.
        if (age < 18) {
          is_valid = false;
          //message = "Minimum age required is 18 yrs";
          message = "Insured should be between 18 years and 55 years of age";
        }
        if (age > 55) {
          is_valid = false;
          // message = "Maximum age required is 55 yrs";
          message = "Insured should be between 18 years and 55 years of age";
        }
        break;
      case "oneTwentyAge": // for type = common , dob should be >=18yrs age diff.
        if (age < 18) {
          is_valid = false;
          //message = "Minimum age required is 18 yrs";
          message = "Second owner age should be between 18 years and 120 years";
        }
        if (age > 120) {
          is_valid = false;
          // message = "Maximum age required is 55 yrs";
          message = "Second owner age should be between 18 years and 120 years";
        }
        break;

      case "oneFiftyAge": // for type = common , dob should be >=18yrs age diff.
        if (age < 0) {
          is_valid = false;
          //message = "Minimum age required is 18 yrs";
          message = "Date of birth cannot be greater than todays's date.";
        }
        if (age > 150) {
          is_valid = false;
          // message = "Maximum age required is 55 yrs";
          message = "Age cannot be greater than 150";
        }
        break;

      case "beneficiary_dob": // for type = beneficiary, dob can be <=18yrs but not the future date.
        if (givenDate > today) {
          is_valid = false;
          message = "DOB cannot be greater than today.";
        }
        break;

      default:
        if (age < 18) {
          is_valid = false;
          message = "Minimum age required is 18 yrs";
        }
        break;
    }

    return {
      is_valid: is_valid,
      message: message
    }

  }


  validate_licenseDate(type = "issueDate", inputDate = new Date()) {
    let is_valid = true;
    let message = "";
    let givenDate = inputDate;
    let today = new Date();

    switch (type) {
      case "issueDate":
        if (givenDate < today) {
          is_valid = true;
          message = "Valid date";
        }
        if (givenDate > today) {
          is_valid = false;
          message = "Issue date cannot be greater than today";
        }
        break;

      case "expiryDate":
        if (givenDate > today) {
          is_valid = true;
          message = "Valid date";
        }
        if (givenDate < today) {
          is_valid = false;
          message = "Expiry date cannot be less than today.";
        }
        break;

      default:
        is_valid = false;
        message = "Please enter a valid date.";
        break;
    }

    return {
      is_valid: is_valid,
      message: message
    }

  }

  /* 
   *@param dob as a Date() object
   */
  calculate_age(dob) {
    debugger
    var diff_ms = Date.now() - dob.getTime();
    var age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

}
