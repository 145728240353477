import { Location } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import axios from 'axios';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { StorageHandlerService } from 'src/app/shared/storage-handler.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { ValidationService } from 'src/app/shared/validation.service';
import { environment } from '../../../environments/environment';
import { getActiveStatesLife, getAddressData, getErrorMessage } from '../../helpers/states-validation';
import { PrefillserviceService } from '../../shared/prefillservice.service';
const pc = require('bit-uibl-data-driver');
// eslint-disable-next-line no-var
declare var window: any;
@Component({
  selector: 'app-prefill-resume-flow-life',
  templateUrl: './prefill-resume-flow-life.component.html',
  styleUrls: ['./prefill-resume-flow-life.component.css']
})

export class PrefillResumeFlowLifeComponent implements OnInit {

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    lazyLoad: false,
    margin: 30,
    smartSpeed: 1500,
    autoplay: false,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  email: any;
  phone: any;
  dob: any;
  gender: any;
  height: any;
  weight: any;
  tobaccoUsage: any;
  coverage: any;
  term: any;
  zip: any;
  genderforhbspot: string;
  firstname: any;
  lastname: any;
  state: any;
  interest_type: any;
  desired_life_coverage: any;
  life_insurance_type: any;
  lead_form_url: any;
  lead_source_time_stamp: any;
  loader: boolean;
  heightFT: any;
  heightIN: any;
  weightLBS: any;
  annual_income: any;
  has_medical_conditions: any;
  active_retired_military_personnel: any;
  lasttermLength: any;
  full_address: any;
  marital_status: any;
  number_of_children: any;
  alzheimers_or_dementia: any;
  cancer: any;
  heart_disease: any;
  utm_source: any;
  utm_medium: any;
  utm_campaign: any;
  dclid: any;
  utm_term: any;
  utm_content: any;
  clientId: any;
  dropoffURL: any;
  httpParamsGlobal: any;
  errormessage: any;
  // lead_source: any;
  sub_id: any;
  lead_token_id: any;
  curDate: any;
  minDate: any;
  disableEmail: boolean;
  callingNavigate: boolean;
  termLength: any;
  emailError: any;
  phoneError: any;
  dobError: any;
  genderError: any;
  heightFTError: any;
  heightINError: any;
  weightLBSError: any;
  insurancetype: any;
  displayAddress: any;
  fullAddress: any;
  uniqueId: any;
  age: any;
  fullName: any;
  tobaccoUsageError: any;
  desired_life_coverageError: any;
  termError: any;
  backButtonEnable;
  message: any;
  is_valid: boolean;
  NewDate;
  Date;
  dateError: any;
  tobaccoUsageSError: any;
  gclid: any;
  latitude: any;
  longitude: any;
  placeId: any;
  addressChanged: any;
  stateLongName: any;
  city: any;
  addresscomponent: object;
  insuranceType: any;
  recoType: any;
  commonAddress: any;
  ziperror: any;
  tobacoselected: any;
  tobacoUsageDuration: any;
  shorturl: any;
  clickid: any;
  source_url: any;
  formatted_address: any;
  cognito_id: any;
  createdat: any;
  status: any;
  address: any;
  address_line_2: any;
  credit_rating: any;
  dwelling_use: any;
  email_2: any;
  mobilephone: any;
  newly_acquired_property: any;
  number_of_claims: any;
  number_of_units: any;
  leadSource: any;
  loaderText: string;
  whiteLoader: boolean;

  constructor(private http: HttpClient, private storageService: StorageHandlerService, private location: Location, private utilityService: UtilityFunctionsService, public router: Router, private modalService: NgbModal, public validationService: ValidationService, private Http: HttpClient, private pc: ProcessCacheService, private activatedroute: ActivatedRoute, private Prefillservice: PrefillserviceService) { }

  sendToLO(data) {
    if (this.gclid) {
      data.gclid = this.gclid;
    }
    window._loq.push(['custom', data]);
  }

  async ngOnInit(): Promise<void> {
    debugger;
    localStorage.removeItem('bubble-coverage');
    localStorage.removeItem('recopageheadtext');
    localStorage.removeItem('bubble-termLength');
    this.loaderText = 'You are just seconds away from getting personalized life insurance quotes to protect your loved ones';
    this.whiteLoader = true;
    this.pc.logPageLandingtoBO({ page_name: 'prefill_life' });
    window._loq = window._loq || [];

    this.ziperror = '';
    this.emailError = '';
    this.dateError = '';
    this.genderError = '';
    this.tobaccoUsageSError = '';
    this.heightFTError = '';
    this.heightINError = '';
    this.weightLBSError = '';
    this.desired_life_coverageError = '';
    this.termError = '';
    this.phoneError = '';
    this.loader = true;
    let url;
    if (window && window.location && window.location.href) {
      url = window.location.href;
    }


    if (url.includes('?')) {
      sessionStorage.setItem('accessedLifeURL', url);
      sessionStorage.setItem('accessedLifeURL', url);
      this.httpParamsGlobal = new HttpParams({ fromString: url.split('?')[1] });
      localStorage.setItem('httpParamsGlobal', JSON.stringify(this.httpParamsGlobal));
      this.callingNavigate = false;
      setTimeout(() => {
        this.createLog(url);
      }, 2000);
    }
    else if (sessionStorage.getItem('accessedLifeURL') != undefined && sessionStorage.getItem('accessedLifeURL') != null && sessionStorage.getItem('accessedLifeURL') != '') {
      const uuu = sessionStorage.getItem('accessedLifeURL');
      this.httpParamsGlobal = new HttpParams({ fromString: uuu.split('?')[1] });
      this.callingNavigate = true;
      setTimeout(() => {
        this.createLog(url);
      }, 2000);
    }
    else {
      this.loader = false;
      this.disableEmail = false;
      this.tobaccoUsage = '';
      this.callingNavigate = false;

      this.ziperror = 'This field is required';
      // this.emailError = 'This field is required';
      // this.dateError = 'This field is required';
      // this.genderError = 'This field is required';
      // this.tobaccoUsageSError = 'This field is required';
      // this.heightFTError = 'This field is required';
      // this.heightINError = 'This field is required';
      // this.weightLBSError = 'This field is required';
      // this.desired_life_coverageError = 'This field is required';
      // this.termError = 'This field is required';
      // this.phoneError = 'This field is required';
      this.clientId = this.utilityService.getNewRandomToken();
      this.dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
      this.onDateChange(null);
    }

    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear()) - 18;
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.minDate = new Date(day + '/' + month + '/' + year);
  }

  async createLog(url) {
    //this.Prefillservice.createMaLog(this.clientId, "life");
    this.doActivity(url);
  }

  async doActivity(url) {
    debugger;
    const httpParams = this.httpParamsGlobal;
    if (httpParams.get('source') == 'home_lead') {
      const responsedata = await this.getDatafromhubspot(httpParams.get('email'));
      const dataFrom = JSON.parse(responsedata['response'].data['Payload']);
      const hsData = dataFrom.body.response.data.properties;
      let alldate;
      if (hsData.dob_home?.value) {
        if (hsData.dob_home.value.includes('-')) {
          alldate = hsData.dob_home.value.replace(/-/g, '/');
        }
        else {
          alldate = hsData.dob_home?.value;
        }
      }
      this.httpParamsGlobal = new HttpParams()
        .set('firstname', (httpParams.get('firstname')) ? httpParams.get('firstname') : hsData.firstname?.value)
        .set('email', hsData.email?.value)
        .set('lastname', (httpParams.get('lastname')) ? httpParams.get('lastname') : hsData.lastname?.value)
        .set('mobilephone', (httpParams.get('mobilephone')) ? httpParams.get('mobilephone') : hsData.phone?.value)
        .set('email_2', httpParams.get('email_2'))
        .set('dwelling_use', httpParams.get('dwelling_use'))
        .set('credit_rating', httpParams.get('credit_rating'))
        .set('address_line_2', httpParams.get('address_line_2'))
        .set('status', httpParams.get('status'))
        .set('created_at', httpParams.get('created_at'))
        .set('cognito_id', httpParams.get('cognito_id'))
        .set('formatted_address', httpParams.get('formatted_address'))
        .set('source_url', httpParams.get('source_url'))
        .set('full_address', httpParams.get('full_address'))
        .set('annual_income', httpParams.get('annual_income'))
        .set('marital_status', httpParams.get('marital_status'))
        .set('number_of_children', httpParams.get('number_of_children'))
        .set('active_retired_military_personnel', httpParams.get('active_retired_military_personnel'))
        .set('alzheimers_or_dementia', httpParams.get('alzheimers_or_dementia'))
        .set('cancer', httpParams.get('cancer'))
        .set('leadsource', httpParams.get('leadsource'))
        .set('heart_disease', httpParams.get('heart_disease'))
        .set('phone', (httpParams.get('phone')) ? httpParams.get('phone') : hsData.phone?.value)
        .set('date_of_birth', (httpParams.get('date_of_birth')) ? httpParams.get('date_of_birth') : hsData.dob_life?.value ? hsData.dob_life?.value : alldate)
        .set('gender', httpParams.get('gender'))
        .set('height', httpParams.get('height'))
        .set('weight', httpParams.get('weight'))
        .set('clickkey', httpParams.get('clickkey'))
        .set('tobacco_user', (httpParams.get('tobacco_user')) ? httpParams.get('tobacco_user') : hsData.tobacco_user?.value)
        .set('tobacco_usageduration', httpParams.get('tobacco_usageduration'))
        .set('desired_life_coverage', httpParams.get('desired_life_coverage'))
        .set('zip', httpParams.get('zip') ? httpParams.get('zip') : hsData.zip?.value)
        .set('interest_type', 'Life')
        .set('life_insurance_type', httpParams.get('life_insurance_type'))
        .set('lead_source', httpParams.get('lead_source'))
        .set('lead_form_url', httpParams.get('lead_form_url'))
        .set('lead_source_time_stamp', httpParams.get('lead_source_time_stamp'))
        .set('sub_id', httpParams.get('sub_id'))
        .set('lead_token_id', httpParams.get('lead_token_id'))
        .set('utm_source', httpParams.get('utm_source'))
        .set('utm_medium', httpParams.get('utm_medium'))
        .set('utm_campaign', httpParams.get('utm_campaign'))
        .set('utm_term', httpParams.get('utm_term'))
        .set('utm_content', httpParams.get('utm_content'))
        .set('dclid', httpParams.get('dclid'))
        .set('desired_life_term', httpParams.get('desired_life_term'))
        .set('annual_income', httpParams.get('annual_income'))
        .set('client_id', httpParams.get('client_id') ? httpParams.get('client_id') : hsData.client_id?.value ? hsData.client_id?.value : this.utilityService.getNewRandomToken())
        .set('has_medical_conditions', httpParams.get('has_medical_conditions'));
      this.normalflow(url, this.httpParamsGlobal);
    }

    else {
      this.normalflow(url, httpParams);
    }

  }

  async normalflow(url, httpParams) {
    debugger;
    this.clientId = httpParams.get('client_id');
    this.interest_type = httpParams.get('interest_type');
    if ((this.clientId) && (this.interest_type == undefined || this.interest_type == '')) {
      await this.getDatabyId();
      httpParams = this.httpParamsGlobal;
    }
    this.clientId = this.clientId ? this.clientId : this.utilityService.getNewRandomToken();
    const urls = {
      'urls': [`${window.location.host}/resumeFlow/${this.clientId}`]
    };
    console.log('urls', urls);
    await this.getshortURL(urls);
    this.dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
    this.email = httpParams.get('email') ? httpParams.get('email') : '';
    localStorage.setItem('bubble-email', this.email);
    if (this.email == undefined || this.email == '' || this.email == null) {
      this.disableEmail = false;
    }
    else {
      this.disableEmail = true;
    }
    const hubSpotData = {
      'email': this.email,
      client_id: this.clientId,
      'application_stage': 'Lead',
      'lead_source_prefilled_url': url
    };
    debugger;
    const customData = {
      client_id: this.clientId,
      email: this.email
    };
    this.gclid = httpParams.get('gclid');
    if (this.email) {
      this.sendToLO(customData);
      await this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    debugger;
    this.number_of_claims = httpParams.get('number_of_claims') ? httpParams.get('number_of_claims') : '';
    this.number_of_units = httpParams.get('number_of_claims') ? httpParams.get('number_of_claims') : '';
    this.mobilephone = httpParams.get('mobilephone') ? httpParams.get('mobilephone') : '';
    this.email_2 = httpParams.get('email_2') ? httpParams.get('email_2') : '';
    this.dwelling_use = httpParams.get('dwelling_use') ? httpParams.get('dwelling_use') : '';
    this.credit_rating = httpParams.get('credit_rating') ? httpParams.get('credit_rating') : '';
    this.address_line_2 = httpParams.get('address_line_2') ? httpParams.get('address_line_2') : '';
    this.status = httpParams.get('status') ? httpParams.get('status') : '';
    this.createdat = httpParams.get('created_at') ? httpParams.get('created_at') : '';
    this.cognito_id = httpParams.get('cognito_id') ? httpParams.get('cognito_id') : '';
    this.formatted_address = httpParams.get('formatted_address') ? httpParams.get('formatted_address') : '';
    this.source_url = httpParams.get('source_url') ? httpParams.get('source_url') : '';
    this.firstname = httpParams.get('firstname') ? httpParams.get('firstname')?.replace(/[^A-Za-z]/g, '') : '';
    this.lastname = httpParams.get('lastname') ? httpParams.get('lastname')?.replace(/[^A-Za-z]/g, '') : '';
    this.full_address = httpParams.get('full_address') ? httpParams.get('full_address') : '';
    this.annual_income = httpParams.get('annual_income') ? httpParams.get('annual_income') : '';
    this.marital_status = httpParams.get('marital_status') ? httpParams.get('marital_status') : '';
    this.number_of_children = httpParams.get('number_of_children') ? httpParams.get('number_of_children') : '';
    this.active_retired_military_personnel = httpParams.get('active_retired_military_personnel') ? httpParams.get('active_retired_military_personnel') : '';
    this.alzheimers_or_dementia = httpParams.get('alzheimers_or_dementia') ? httpParams.get('alzheimers_or_dementia') : '';
    this.cancer = httpParams.get('cancer') ? httpParams.get('cancer') : '';
    this.leadSource = httpParams.get('leadsource') ? httpParams.get('leadsource') : httpParams.get('lead_source') ? httpParams.get('lead_source') : '';
    this.heart_disease = httpParams.get('heart_disease') ? httpParams.get('heart_disease') : '';

    this.phone = httpParams.get('phone') ? httpParams.get('phone') : '';

    this.dob = httpParams.get('date_of_birth') ? httpParams.get('date_of_birth') : '';
    console.log('dob1', httpParams.get('date_of_birth'));
    this.gender = httpParams.get('gender') ? httpParams.get('gender') : '';
    this.height = httpParams.get('height') ? httpParams.get('height') : '';
    debugger;
    console.log('hey buddy height', this.height);
    if (this.height != '') {
      this.heightFT = this.height.split('\'')[0];
      this.heightIN = this.height.split('\'')[1].replace('"', '');
    }
    this.weight = httpParams.get('weight') ? httpParams.get('weight') : '';
    this.clickid = httpParams.get('clickkey') ? httpParams.get('clickkey') : '';
    this.weightLBS = this.weight.split('\'')[0];
    this.tobaccoUsage = httpParams.get('tobacco_user') ? httpParams.get('tobacco_user') : '';
    if (this.tobaccoUsage) {
      this.pc.lifesummary({ 'tobacco_user': this.tobaccoUsage });
    }
    this.tobacoUsageDuration = httpParams.get('tobacco_usageduration') ? httpParams.get('tobacco_usageduration') : '4.1';
    this.coverage = httpParams.get('desired_life_coverage') ? httpParams.get('desired_life_coverage') : '';
    this.desired_life_coverage = httpParams.get('desired_life_coverage') ? httpParams.get('desired_life_coverage') : '';
    if (this.coverage == undefined || this.coverage == null || this.coverage == '') {
      this.coverage = 250000;
      this.desired_life_coverage = 250000;
    }
    this.zip = httpParams.get('zip') ? httpParams.get('zip') : '';
    this.life_insurance_type = httpParams.get('life_insurance_type') ? httpParams.get('life_insurance_type') : '';
    // this.lead_source = httpParams.get('lead_source') ? httpParams.get('lead_source') : '';
    this.lead_form_url = httpParams.get('lead_form_url') ? httpParams.get('lead_form_url') : '';
    this.lead_source_time_stamp = httpParams.get('lead_source_time_stamp') ? httpParams.get('lead_source_time_stamp') : '';
    this.sub_id = httpParams.get('sub_id') ? httpParams.get('sub_id') : '';
    this.lead_token_id = httpParams.get('lead_token_id') ? httpParams.get('lead_token_id') : '';

    this.utm_source = httpParams.get('utm_source') ? httpParams.get('utm_source') : '';
    this.utm_medium = httpParams.get('utm_medium') ? httpParams.get('utm_medium') : '';
    this.utm_campaign = httpParams.get('utm_campaign') ? httpParams.get('utm_campaign') : '';
    this.utm_term = httpParams.get('utm_term') ? httpParams.get('utm_term') : '';
    this.utm_content = httpParams.get('utm_content') ? httpParams.get('utm_content') : '';

    this.dclid = httpParams.get('dclid') ? httpParams.get('dclid') : '';
    this.term = httpParams.get('desired_life_term') ? httpParams.get('desired_life_term') : '';
    this.annual_income = httpParams.get('annual_income') ? httpParams.get('annual_income') : '';
    this.has_medical_conditions = httpParams.get('has_medical_conditions') ? httpParams.get('has_medical_conditions') : '';
    this.active_retired_military_personnel = httpParams.get('active_retired_military_personnel') ? httpParams.get('active_retired_military_personnel') : '';

    this.clickid = httpParams.get('clickkey') ? httpParams.get('clickkey') : '';
    // LogRocket.identify(this.clientId, {
    //   name: this.firstname + ' ' + this.lastname,
    //   email: this.email,
    // });
    if (this.coverage == undefined || this.coverage == null || this.coverage == '') {
      this.coverage = 250000;
      this.desired_life_coverage = 250000;
    }

    if (Number(this.coverage) < 25000) {
      this.coverage = '250000';
    }
    if (Number(this.coverage) > 10000000) {
      this.coverage = '10000000';
    }
    this.desired_life_coverage = this.coverage;
    if (this.gender != null && this.gender != undefined) {
      if (this.gender.toLowerCase() == 'male') {

        localStorage.setItem('bubble-gender', '1');
        this.genderforhbspot = '1';
        this.gender = 'Male';
      }
      else if (this.gender.toLowerCase() == 'female') {
        localStorage.setItem('bubble-gender', '0');
        this.genderforhbspot = '0';
        this.gender = 'Female';
      }
      else {

        // this.genderError = 'This field is required';
      }
    }
    if (!this.term) {
      this.term = 10;
      console.log('default term length');
      this.termLength = this.term;
    }
    else {
      this.termLength = this.term;
      await this.calculateTerm();
    }
    localStorage.setItem('bubble-termLength', this.term);
    // if (this.term != undefined && this.term != null && this.term != '') {




    // }
    // else {
    //   this.termError = 'This field is required';
    // }

    if (this.tobaccoUsage == undefined || this.tobaccoUsage == null || this.tobaccoUsage == '') {
      this.tobaccoUsage = '';
      //this.tobaccoUsageError = 'This field is required';
    }


    if (this.zip.length == 5) {
      if (this.full_address && this.full_address.includes(this.zip)) {
        await this.validateZip('0');
      }
      else {
        await this.validateZip('1');
      }
    }
    else {
      this.ziperror = 'This field is required';
      this.zip = '';
    }

    this.validateEmail();
    this.phonelength();
    this.onDateChange(this.dob);
    this.changeGender(this.gender);
    this.ChangetobaccoUsage(this.tobaccoUsage);
    this.ChangeheightFT();
    this.ChangeweightLBS();
    this.Changedesired_life_coverage();
    //this.Changeterm(this.termLength);
    this.setGAEvent();

    this.generateClientData();
    if (this.leadSource.toString() == 'mediaalpha' || this.leadSource.toString() == 'MediaAlpha') {
      this.boData();
    }
    debugger;
    this.location.go('PrefillResumeFlowLife');


    if (this.ziperror == '' || this.ziperror == undefined) {

      if (this.callingNavigate == false) {
        setTimeout(() => {
          this.navigatetoLiferecoPage();
        }, 2000);
      }
      else {
        // this.loader = false;
      }
    }
    else {

      // this.loader = false;
    }
  }

  async parseGoogleAddress(address) {
    debugger;
    this.displayAddress = address.formatted_address.replace(/#/g, '');
    const google_address = {
      'formatted_address': '',
      'policyOwnerInfo': {}
    };
    const results = [];
    results[0] = address;
    console.log('results address', results, results[0].address_components);

    let street_ln = '';
    let route_sn = '';

    for (let i = 0; i < results[0].address_components.length; i++) {

      const type = results[0].address_components[i].types[0];

      switch (type) {

        case 'street_number':
          google_address['street_number_ln'] = results[0].address_components[i].long_name;
          google_address['street_number_ln'] = results[0].address_components[i].short_name;
          street_ln = results[0].address_components[i].long_name;
          break;
        case 'route':
          google_address['route_ln'] = results[0].address_components[i].long_name;
          google_address['route_sn'] = results[0].address_components[i].short_name;
          route_sn = results[0].address_components[i].short_name;
          break;
        case 'neighborhood':
          google_address['neighborhood_ln'] = results[0].address_components[i].long_name;
          google_address['neighborhood_sn'] = results[0].address_components[i].short_name;
          break;
        case 'locality':
          google_address['locality_ln'] = results[0].address_components[i].long_name;
          google_address['locality_sn'] = results[0].address_components[i].short_name;
          this.city = results[0].address_components[i].short_name;
          break;
        case 'administrative_area_level_2':
          google_address['administrative_area_level_2_ln'] = results[0].address_components[i].long_name;
          google_address['administrative_area_level_2_sn'] = results[0].address_components[i].short_name;
          break;
        case 'administrative_area_level_1':
          google_address['administrative_area_level_1_ln'] = results[0].address_components[i].long_name;
          google_address['administrative_area_level_1_sn'] = results[0].address_components[i].short_name;
          this.state = results[0].address_components[i].short_name;
          break;
        case 'country':
          google_address['country_ln'] = results[0].address_components[i].long_name;
          google_address['country_sn'] = results[0].address_components[i].short_name;
          break;
        case 'postal_code':
          google_address['postal_code_ln'] = results[0].address_components[i].long_name;
          google_address['postal_code_sn'] = results[0].address_components[i].short_name;
          this.zip = results[0].address_components[i].short_name;
          break;
        case 'postal_code_suffix':
          google_address['postal_code_suffix_ln'] = results[0].address_components[i].long_name;
          google_address['postal_code_suffix_sn'] = results[0].address_components[i].short_name;
          break;
      }

    }
    const street_address = street_ln + ' ' + route_sn;
    localStorage.setItem('bubble-street', street_address);

    google_address.formatted_address = results[0].formatted_address;
    //this.displayAddress = results[0].formatted_address;

    google_address['id'] = this.clientId;

    google_address.policyOwnerInfo['policyOwnerCity'] = this.city;
    google_address.policyOwnerInfo['policyOwnerZipcode'] = this.zip;
    google_address.policyOwnerInfo['policyOwnerState'] = this.state;
    google_address['state_name'] = this.state;
    console.log('google_address', google_address);

    await this.pc.saveUpdateClientData(google_address);
    this.addresscomponent = google_address;
    return true;
  }

  async generateClientData() {
    debugger;
    console.log('dob_inclientdata', this.dob);
    this.loader = true;
    if (this.term == '65') {
      this.term = this.term - this.age;
    }
    const clientDataJson = {
      'client_id': this.clientId,
      'email': this.email,
      'phone': this.phone,
      'dob': this.dob,
      'height': this.height,
      'bubble_email': this.email ? this.email : '',
      'bubble_phone': this.phone ? this.phone : '',
      'bubble_heightFt': this.heightFT ? this.heightFT : '',
      'bubble_heightIn': this.heightIN ? this.heightIN : '',
      'bubble_weight': this.weight ? this.weight : '',
      'weight': this.weight,
      'tobaccoUsage': this.tobaccoUsage,
      'coverage': this.coverage,
      'desired_life_term': this.term || '10',
      'desired_life_coverage': this.coverage,
      'zip': this.zip,
      'insurancetype': this.interest_type,
      'interest_type': this.interest_type,
      'life_insurance_type': this.life_insurance_type,
      'lead_source': this.leadSource,
      'lead_source_time_stamp': this.lead_source_time_stamp,
      'term': this.term,
      'stateCode': this.state,
      'prefillLifeURL': sessionStorage.getItem('accessedLifeURL'),
      'tobacoselected': this.tobacoselected,
      'tobacoUsageDuration': this.tobacoUsageDuration,
      'tobacoUser': this.tobacoselected ? this.tobacoselected : '',
    };
    if (this.gender) {
      clientDataJson['gender'] = this.gender;
    }
    if (this.firstname && this.firstname != '') clientDataJson['bubble_fname'] = this.firstname;
    if (this.firstname && this.firstname != '') clientDataJson['firstname'] = this.firstname;
    if (this.lastname && this.lastname != '') clientDataJson['bubble_lname'] = this.lastname;
    if (this.lastname && this.lastname != '') clientDataJson['lastname'] = this.lastname;

    if (this.full_address && this.full_address != '' && this.full_address != undefined) clientDataJson['full_address'] = this.full_address;
    if (this.annual_income && this.annual_income != '') clientDataJson['annual_income'] = this.annual_income;
    if (this.marital_status && this.marital_status != '') clientDataJson['marital_status'] = this.marital_status;
    if (this.number_of_children && this.number_of_children != '') clientDataJson['number_of_children'] = this.number_of_children;
    if (this.active_retired_military_personnel && this.active_retired_military_personnel != '') clientDataJson['active_retired_military_personnel'] = this.active_retired_military_personnel;
    if (this.alzheimers_or_dementia && this.alzheimers_or_dementia != '') clientDataJson['alzheimers_or_dementia'] = this.alzheimers_or_dementia;
    if (this.cancer && this.cancer != '') clientDataJson['cancer'] = this.cancer;
    if (this.heart_disease && this.heart_disease != '') clientDataJson['heart_disease'] = this.heart_disease;
    if (this.state && this.state != '') clientDataJson['stateCode'] = this.state;
    if (this.has_medical_conditions) clientDataJson['has_medical_conditions'] = this.has_medical_conditions;

    console.log('clientDataJson', clientDataJson);
    clientDataJson['id'] = this.clientId;
    await this.saveupdateclientdatacall(clientDataJson);
    debugger;
    const hubSpotData = this.fillInHubspotDataFromFormData(clientDataJson);
    debugger;
    if (this.email) {
      const customData = {
        client_id: this.clientId,
        email: this.email
      };
      this.sendToLO(customData);
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    this.getdata();

  }

  async navigatetoLiferecoPage() {
    debugger;
    //debugger;
    if (this.zip != undefined && this.zip != '') {
      //debugger;

      this.loader = true;
      this.GetQuote();
    }
    else {
      //this.loader = false;
    }
    return true;
  }

  async saveupdateclientdatacall(json) {
    await this.pc.saveUpdateClientData(json);
    return;
  }

  fillInHubspotDataFromFormData(hubSpotData) {
    debugger;

    let hubSpot_tobacco_user;
    if (this.tobaccoUsage == '5') {
      hubSpot_tobacco_user = 'No';
      this.tobacoselected = 'No';
    }
    else {
      hubSpot_tobacco_user = 'Yes';
      this.tobacoselected = 'Yes';
    }
    if (this.term == '65') {
      this.term = this.term - this.age;
    }
    //debugger;
    if (this.email) hubSpotData['email'] = this.email;
    if (this.phone) hubSpotData['phone'] = this.phone;
    if (this.dob) hubSpotData['dob_life'] = this.dob;
    if (this.gender) hubSpotData['gender'] = this.gender == 'Male' ? '1' : '0';
    if (this.height) hubSpotData['height'] = this.pc.validateHeightHubspot(this.height);
    if (this.weight) hubSpotData['weight'] = this.weight;
    hubSpotData['tobacco_user'] = hubSpot_tobacco_user;
    hubSpotData['smoker'] = hubSpot_tobacco_user;
    //debugger;
    if (this.term) hubSpotData['desired_life_term'] = this.term;
    if (this.desired_life_coverage) hubSpotData['desired_life_coverage'] = this.desired_life_coverage;
    if (this.coverage) hubSpotData['desired_life_coverage'] = this.coverage;
    if (this.zip) hubSpotData['zip'] = this.zip;
    if (this.interest_type) hubSpotData['interest_type'] = this.interest_type;
    if (this.life_insurance_type) hubSpotData['life_insurance_type'] = this.life_insurance_type;
    if (this.leadSource) hubSpotData['lead_source'] = this.leadSource;
    if (this.lead_form_url != '') {
      if (this.lead_form_url) hubSpotData['lead_form_url'] = this.lead_form_url;
    }
    if (this.lead_source_time_stamp) hubSpotData['lead_source_time_stamp'] = this.lead_source_time_stamp;
    if (this.sub_id != '') {
      if (this.sub_id) hubSpotData['sub_id'] = this.sub_id;
    }
    if (this.lead_token_id != '') {
      if (this.lead_token_id) hubSpotData['lead_token_id'] = this.lead_token_id;
    }
    if (this.utm_source) hubSpotData['utm_source'] = this.utm_source;
    if (this.utm_medium) hubSpotData['utm_medium'] = this.utm_medium;
    if (this.utm_campaign) hubSpotData['utm_campaign'] = this.utm_campaign;
    if (this.utm_term) hubSpotData['utm_term'] = this.utm_term;
    if (this.utm_content) hubSpotData['utm_content'] = this.utm_content;
    if (this.dclid) hubSpotData['dclid'] = this.dclid;
    if (this.annual_income) hubSpotData['annual_income'] = this.annual_income;
    if (this.has_medical_conditions) hubSpotData['has_medical_conditions'] = this.has_medical_conditions;
    if (this.active_retired_military_personnel) hubSpotData['active_retired_military_personnel'] = this.active_retired_military_personnel;

    //debugger;


    //Non Mandatory Fields_start


    if (this.firstname && this.firstname != '') hubSpotData['firstname'] = this.firstname;
    if (this.lastname && this.lastname != '') hubSpotData['lastname'] = this.lastname;
    //debugger;
    if (this.full_address && this.full_address != '' && this.full_address != undefined) hubSpotData['full_address'] = this.full_address;
    //debugger;
    if (this.annual_income && this.annual_income != '') hubSpotData['annual_income'] = this.annual_income;
    if (this.marital_status && this.marital_status != '') hubSpotData['marital_status'] = this.marital_status;
    if (this.number_of_children && this.number_of_children != '') hubSpotData['number_of_children'] = this.number_of_children;
    if (this.active_retired_military_personnel && this.active_retired_military_personnel != '') hubSpotData['active_retired_military_personnel'] = this.active_retired_military_personnel;
    if (this.alzheimers_or_dementia && this.alzheimers_or_dementia != '') hubSpotData['alzheimers_or_dementia'] = this.alzheimers_or_dementia;
    if (this.cancer && this.cancer != '') hubSpotData['cancer'] = this.cancer;
    if (this.heart_disease && this.heart_disease != '') hubSpotData['heart_disease'] = this.heart_disease;
    if (this.state && this.state != '' || this.state != undefined) hubSpotData['stateCode'] = this.state;
    if (this.state && this.state != '' || this.state != undefined) hubSpotData['state'] = this.state;
    if (this.age && this.age != '' && this.age != undefined && this.age != null) hubSpotData['age'] = this.age.toString();
    hubSpotData['application_stage'] = 'Lead';
    if (this.leadSource.toLowerCase() == 'quinstreet') {
      hubSpotData['click_id'] = this.clickid;
    }
    //Non Mandatory Fields_end

    console.log('hubdob', hubSpotData['dob_life']);

    //this.Prefillservice.updateMaLog('req', hubSpotData, this.clientId);
    return hubSpotData;
  }

  changeGender(even) {
    //debugger;
    this.gender = even;
    if (this.gender == 'Male') {
      //debugger;
      localStorage.setItem('bubble-gender', '1');
      this.genderError = '';
    }
    else if (this.gender == 'Female') {
      //debugger;
      localStorage.setItem('bubble-gender', '0');
      this.genderError = '';
    }
    else {

      //  this.genderError = 'Select a valid Gender.';
    }
  }

  validateEmail() {
    // eslint-disable-next-line no-useless-escape
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const emailtest = this.email;
    if (reg.test(emailtest) == false) {
      //this.emailError = 'Please enter a valid email';
      this.disableEmail = false;
    }
    else {
      this.emailError = '';

    }
    localStorage.setItem('bubble-email', this.email);
  }

  async GetQuote() {
    debugger;
    this.backButtonEnable = 'true';
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const emailtest = this.email;
    // if (this.email == null || this.email == undefined || this.email == '') {
    //   this.emailError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (reg.test(emailtest) == false) {
    //   this.emailError = 'Please enter a valid email';
    //   this.loader = false;
    // }
    // else if (this.dob == null || this.dob == undefined || this.dob == '') {
    //   this.dateError = 'Insured should be between 18 years and 55 years of age';
    //   this.loader = false;
    // } else if (this.gender == null || this.gender == undefined || this.gender == '') {
    //   this.genderError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.heightFT == null || this.heightFT == undefined || this.heightFT == '') {
    //   this.heightFTError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.heightIN == null || this.heightIN == undefined || this.heightIN == '') {
    //   this.heightINError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.weightLBS == null || this.weightLBS == undefined || this.weightLBS == '') {
    //   this.weightLBSError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.tobaccoUsage == null || this.tobaccoUsage == undefined || this.tobaccoUsage == '' || this.tobaccoUsage == 'Yes') {
    //   this.tobaccoUsageError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.desired_life_coverage == null || this.desired_life_coverage == undefined || this.desired_life_coverage == '') {
    //   this.desired_life_coverageError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.term == null || this.term == undefined || this.term == '') {
    //   this.termError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.zip == null || this.zip == undefined || this.zip == '' && this.ziperror == '') {
    //   this.ziperror = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.ziperror != '') {
    //   this.ziperror = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.tobaccoUsage == 'Yes') {
    //   this.tobaccoUsageSError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.term == '0') {
    //   this.termError = 'This field is required';
    //   this.loader = false;
    // }
    // else if (this.dateError == 'Insured should be between 18 years and 55 years of age') {
    //   this.dateError == 'Insured should be between 18 years and 55 years of age';
    //   this.loader = false;
    // }
    // else if (this.phoneError == 'Enter valid phone number') {
    //   this.phoneError = 'Enter valid phone number';
    //   this.loader = false;
    // }
    //else {
    this.loader = true;
    localStorage.setItem('clientID', this.clientId);
    localStorage.setItem('clientId', this.clientId);
    this.Prefillservice.saveAddressDetails(this.zip, this.clientId);
    const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    let hubSpot_tobacco_user;
    if (this.tobaccoUsage == '5') {
      hubSpot_tobacco_user = 'No';
    }
    else {
      hubSpot_tobacco_user = 'Yes';
    }
    if (this.term == '65') {
      this.term = this.term - this.age;
    }
    const savedata = {
      'id': this.clientId,
      'email': this.email ? this.email : '',
      'bubble_fname': this.firstname,
      'bubble_lname': this.lastname,
      'bubble_email': this.email ? this.email : '',
      'bubble_phone': this.phone ? this.phone : '',
      'dob': this.dob ? this.dob : '',
      'bubble_heightFt': this.heightFT ? this.heightFT : '',
      'bubble_heightIn': this.heightIN ? this.heightIN : '',
      'bubble_weight': this.weight ? this.weight : '',
      'zip': this.zip,
      'zip_code': this.zip ? this.zip : '',
      'stateCode': this.state,
      'city': this.city,
      'policyOwnerInfo': {},
      'tobacoselected': this.tobacoselected,
      'tobacoUsageDuration': this.tobacoUsageDuration,
      'tobacoUser': this.tobacoselected ? this.tobacoselected : '',
      'age': this.age.toString(),
      'desired_life_term': this.term ? this.term : '20',
      'desired_life_coverage': this.desired_life_coverage.toString() ? this.desired_life_coverage.toString() : '250000'
    };
    if (this.gender) {
      savedata['gender'] = this.gender == 'Male' ? '1' : '0';
    }
    savedata.policyOwnerInfo['policyOwnerCity'] = this.city;
    savedata.policyOwnerInfo['policyOwnerZipcode'] = this.zip;
    savedata.policyOwnerInfo['policyOwnerState'] = this.state;

    localStorage.setItem('policyOwnerCity_preFill', this.city);
    localStorage.setItem('policyOwnerZipcode_preFill', this.zip);
    localStorage.setItem('policyOwnerState_preFill', this.state);

    localStorage.setItem('bubble-zip', this.zip);
    localStorage.setItem('bubble-state', this.state);
    localStorage.setItem('bubble-city', this.city);


    await this.pc.saveUpdateClientData(savedata);
    const clientDataJson = {
      'id': this.clientId,
      'email': this.email,
      'consent_to_contact': true
    };

    clientDataJson['email'] = this.email;
    clientDataJson['phone'] = this.phone;
    clientDataJson['dob_life'] = this.dob;
    if (this.gender) {
      clientDataJson['gender'] = this.gender == 'Male' ? '1' : '0';
    }
    clientDataJson['height'] = this.pc.validateHeightHubspot(this.height);//`${this.heightFT}'${this.heightIN}"`;
    console.log('clientDataJson[height]', clientDataJson['height']);
    clientDataJson['weight'] = this.weightLBS;
    clientDataJson['tobacco_user'] = hubSpot_tobacco_user;
    clientDataJson['smoker'] = hubSpot_tobacco_user;
    clientDataJson['desired_life_term'] = this.term;
    clientDataJson['desired_life_coverage'] = this.desired_life_coverage.toString();
    clientDataJson['interest_type'] = 'Life';
    clientDataJson['address'] = this.displayAddress;
    clientDataJson['customer_id'] = customerIdLocal;
    clientDataJson['application_stage'] = 'Lead';
    clientDataJson['consent_to_contact'] = true;
    clientDataJson['lead_source_time_stamp'] = new Date();
    clientDataJson['zip'] = this.zip;
    clientDataJson['dropoff_url'] = this.dropoffURL;
    clientDataJson['short_url'] = this.shorturl;
    clientDataJson['age'] = this.age.toString();
    // clientDataJson['user_clicked_the_landing_page_button'] = 'Yes';
    clientDataJson['lead_source'] = this.leadSource;
    // clientDataJson['firstname'] = this.firstname;
    // clientDataJson['lastname'] = this.lastname;
    clientDataJson['client_id'] = this.clientId;
    clientDataJson['desired_life_coverage'] = this.desired_life_coverage.toString();
    clientDataJson['coverage_term_life'] = this.desired_life_coverage.toString();
    clientDataJson['desired_life_term'] = this.termLength.toString();
    clientDataJson['life_insurance_type'] = `Term ${this.termLength} Years`;
    if (this.utm_source) clientDataJson['utm_source'] = this.utm_source;
    if (this.utm_medium) clientDataJson['utm_medium'] = this.utm_medium;
    if (this.utm_campaign) clientDataJson['utm_campaign'] = this.utm_campaign;
    if (this.utm_term) clientDataJson['utm_term'] = this.utm_term;
    if (this.utm_content) clientDataJson['utm_content'] = this.utm_content;
    debugger;
    if (this.email) {
      const customData = {
        client_id: this.clientId,
        email: this.email
      };
      this.sendToLO(customData);
      this.pc.hubSpotSyncAfterEmailFetch(clientDataJson);
    }

    this.insurancetype = 'life';

    this.uniqueId = await pc.getUniqueId4Property(this.clientId, this.displayAddress);

    if (this.firstname == '' || this.firstname == null || this.firstname == undefined || this.firstname == 'false') {
      this.firstname = '';
    }
    if (this.lastname == '' || this.lastname == undefined || this.lastname == null) {
      this.lastname = '';
    }

    this.fullName = this.firstname + ' ' + this.lastname;
    localStorage.setItem('bubble-fullName', this.fullName);

    if (localStorage.getItem('bubble-fullName') !== null) {
      this.fullName = localStorage.getItem('bubble-fullName');
    }
    else {
      this.fullName = 'Anonymous User';
    }
    if (this.term == '65') {
      this.term = this.term - this.age;
    }

    const today = new Date();
    const birthDate = new Date(this.dob);
    this.age = today.getFullYear() - birthDate.getFullYear();

    localStorage.setItem('bubble-tobacco', this.tobaccoUsage);
    localStorage.setItem('bubble-tobaccoUsageDuration', this.tobaccoUsage);
    localStorage.setItem('bubble-dob', this.dob);
    localStorage.setItem('bubble-lifejourneystart', 'true');
    localStorage.setItem('insuranceType', 'life');
    localStorage.setItem('bubble-age', this.age);
    localStorage.setItem('bubble-heightFt', this.heightFT);
    localStorage.setItem('bubble-heightIn', this.heightIN);
    localStorage.setItem('bubble-weight', this.weightLBS);
    localStorage.setItem('bubble-termLength', this.term);
    localStorage.setItem('bubble-coverage', this.desired_life_coverage);
    localStorage.setItem('bubble-fullName', this.fullName);
    localStorage.setItem('bubble_fname', this.firstname);
    localStorage.setItem('bubble_lname', this.lastname);
    localStorage.setItem('bubble-fname', this.firstname);
    localStorage.setItem('bubble-lname', this.lastname);

    localStorage.setItem('riskClass', 'Super Preferred Non-Tobacco');
    localStorage.setItem('bubble-mortgage', '0');
    localStorage.setItem('bubble-kids', this.number_of_children);
    localStorage.setItem('bubble-annualIncome', this.annual_income);
    localStorage.setItem('bubble-email', this.email);
    localStorage.setItem('bubble-street', '');
    localStorage.setItem('bubble-phone', this.phone);
    debugger;
    localStorage.setItem('backButtonEnable', this.backButtonEnable);
    localStorage.setItem('lifeRecoBackButton', 'PrefillResumeFlowLife');

    const smokings = localStorage.getItem('bubble-tobacco');
    const genders = localStorage.getItem('bubble-gender');
    const heights = localStorage.getItem('bubble-heightFt') + 'ft ' + localStorage.getItem('bubble-heightIn') + 'inch';
    const weights = localStorage.getItem('bubble-weight');
    this.pc.getPacLifeRiskClass(smokings, genders, heights, weights).then(response => {
      ////debugger
      let PacLifeRiskClass = response.data.output;
      //console.log('PacLifeRiskClass :', PacLifeRiskClass);
      const dp = JSON.parse(PacLifeRiskClass);
      console.log('PacLifeRiskClass :', dp.dput1);
      PacLifeRiskClass = dp.dput1;
      localStorage.setItem('riskClass', PacLifeRiskClass);
      this.getdata();
    })
      .catch(error => {
        console.log(error);
      });

    // }
  }

  ChangeheightFT() {
    if (this.heightFT == null || this.heightFT == undefined || this.heightFT == '') {
      // this.heightFTError = 'This field is required';
    } else {
      if (this.heightIN == null || this.heightIN == undefined || this.heightIN == '') {
        //  this.heightFTError = 'This field is required';
      } else {
        this.heightFTError = '';
      }
    }


  }

  ChangeweightLBS() {
    if (this.weightLBS == null || this.weightLBS == undefined || this.weightLBS == '') {
      // this.weightLBSError = 'This field is required';
      this.weightLBSError = '';
    } else {
      this.weightLBSError = '';
    }
  }

  Changedesired_life_coverage() {
    if (this.desired_life_coverage == null || this.desired_life_coverage == undefined || this.desired_life_coverage == '') {
      //this.desired_life_coverageError = 'This field is required';
    } else {
      this.desired_life_coverageError = '';
    }

    if (Number(this.desired_life_coverage) < 25000) {
      this.coverage = '25000';
      //this.desired_life_coverageError = 'Coverage cannot be less than 25,000';
    }
    else if (Number(this.desired_life_coverage) > 10000000) {
      this.coverage = '10000000';
      //this.desired_life_coverageError = 'Coverage cannot be greater than 10,000,000';
    }
    else {
      this.desired_life_coverageError = '';
    }

  }

  ChangetobaccoUsage(even) {
    debugger;
    if (this.tobaccoUsage == 'Yes') {
      this.tobaccoUsage = (this.tobacoUsageDuration != '') ? this.tobacoUsageDuration : this.tobaccoUsage;
      this.tobacoselected = 'Yes';
      //  this.tobaccoUsageSError = 'This field is required';
    } else if (this.tobaccoUsage == 'No') {
      this.tobaccoUsage = 5;
      this.tobacoUsageDuration = '5';
      this.tobaccoUsageSError = '';
      this.tobacoselected = 'No';
    }
    else {
      this.tobaccoUsage = even;
      if (even === '5') {
        this.tobacoselected = 'No';
      }
      else {
        this.tobacoUsageDuration = even;
        this.tobacoselected = 'Yes';
      }
      this.tobaccoUsageSError = '';
    }
  }

  Changeterm(even) {
    debugger;
    this.term = even.target.value;
    this.termError = '';
    this.calculateTerm(0);
  }

  async getdata() {
    debugger;
    this.pc.getAppData(this.clientId).then(async data => {
      const dataFrom = JSON.parse(data.Payload);
      const clientData = dataFrom.body;
      console.log('dataFrom get app data : ', clientData);
      this.insuranceType = 'life';
      localStorage.setItem('insuranceType', this.insuranceType);
      this.recoType = 'SelectLifeCarrierV2';
      this.commonAddress = localStorage.getItem('bubble-street');
      this.fullName = this.firstname + ' ' + this.lastname;
      localStorage.setItem('bubble-fullName', this.fullName);

      if (localStorage.getItem('bubble-fullName') !== null) {
        this.fullName = localStorage.getItem('bubble-fullName');
      }
      else {
        this.fullName = 'Anonymous User';
      }
      if (this.fullName != undefined) {
        const splitname = this.fullName.split(' ');
        const commonInputLife = {
          lastName: splitname[2] ? splitname[2] : 'Lastname',
          firstName: splitname[0] ? splitname[0] : 'Firstname',
          middleName: splitname[1] ? splitname[1] : 'MiddleName',
          addressType: 'PhysicalRisk',
          address: this.commonAddress,
          city: this.city,
          gender: this.gender == '1' ? 'M' : 'F',
          dob: this.dob,
          term: this.term,
          zip: this.zip,
          state: this.state,
          height: this.height,
          weight: +this.weight,
          tobaccoUse: +this.tobaccoUsage,
          riskClass: '',
          coverage: this.coverage,
          premiumFrequency: 'm',
        };
        localStorage.setItem('riskClass', 'Super Preferred Non-Tobacco');
        localStorage.setItem('bubble-tobacco', this.tobaccoUsage);
        localStorage.setItem('bubble-tobaccoUsageDuration', this.tobaccoUsage);
        localStorage.setItem('commonInput', JSON.stringify(commonInputLife));
        localStorage.setItem('bubble-dob', commonInputLife['dob']);
        localStorage.setItem('bubble-heightFt', this.heightFT);
        localStorage.setItem('bubble-heightIn', this.heightIN);
        localStorage.setItem('bubble-weight', this.weightLBS);
        localStorage.setItem('bubble-termLength', (this.term == 'NaN') ? '10' : this.term);
        localStorage.setItem('bubble-coverage', this.desired_life_coverage);
        console.log('common input : ', commonInputLife);
        localStorage.setItem('clientID', this.clientId);
        this.uniqueId = await pc.getUniqueId4Property(this.clientId, this.displayAddress);
        const resumeURL = `${this.recoType}/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insuranceType}`;
        console.log('resumeURL generated: ', resumeURL);
        if (this.errormessage != '') {
          this.loader = false;
        }
        else {
          debugger;
          console.log('commonInputLife', commonInputLife);
          this.resumeFlow(resumeURL);
        }

      }


    }).catch(error => {
      //this.loader = false;
      console.log(error);
    });
  }

  async getDatabyId() {
    await this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      const dbData = dataFrom.body;

      this.httpParamsGlobal = new HttpParams()
        .set('firstname', dbData?.firstname)
        .set('email', dbData?.email)
        .set('lastname', dbData?.lastname)
        .set('number_of_claims', dbData?.number_of_claims)
        .set('mobilephone', dbData?.phone)
        .set('email_2', dbData?.email_2)
        .set('dwelling_use', dbData?.dwelling_use)
        .set('credit_rating', dbData?.credit_rating)
        .set('address_line_2', dbData?.address_line_2)
        .set('status', dbData?.status)
        .set('created_at', dbData?.created_at)
        .set('cognito_id', dbData?.cognito_id)
        .set('formatted_address', dbData?.formatted_address)
        .set('source_url', dbData?.source_url)
        .set('full_address', dbData?.full_address)
        .set('annual_income', dbData?.annual_income)
        .set('marital_status', dbData?.marital_status)
        .set('number_of_children', dbData?.number_of_children)
        .set('active_retired_military_personnel', dbData?.active_retired_military_personnel)
        .set('alzheimers_or_dementia', dbData?.alzheimers_or_dementia)
        .set('cancer', dbData?.cancer)
        .set('leadsource', dbData?.lead_source)
        .set('heart_disease', dbData?.heart_disease)
        .set('phone', dbData?.phone)
        .set('date_of_birth', dbData?.date_of_birth)
        .set('gender', dbData?.gender)
        .set('height', dbData?.height)
        .set('weight', dbData?.weight)
        .set('clickkey', dbData?.clickkey)
        .set('tobacco_user', dbData?.tobacco_user)
        .set('tobacco_usageduration', dbData?.tobacco_usageduration)
        .set('desired_life_coverage', dbData?.desired_life_coverage)
        .set('zip', dbData?.zip ? dbData.zip : dbData.zip_code ? dbData.zip_code : dbData.postal_code_ln)
        .set('interest_type', dbData?.interest_type)
        .set('life_insurance_type', dbData?.life_insurance_type)
        .set('lead_source', dbData?.lead_source)
        .set('lead_form_url', dbData?.lead_form_url)
        .set('lead_source_time_stamp', dbData?.lead_source_time_stamp)
        .set('sub_id', dbData?.sub_id)
        .set('lead_token_id', dbData?.lead_token_id)
        .set('utm_source', dbData?.utm_source)
        .set('utm_medium', dbData?.utm_medium)
        .set('utm_campaign', dbData?.utm_campaign)
        .set('utm_term', dbData?.utm_term)
        .set('utm_content', dbData?.utm_content)
        .set('dclid', dbData?.dclid)
        .set('desired_life_term', dbData?.desired_life_term)
        .set('annual_income', dbData?.annual_income)
        .set('has_medical_conditions', dbData?.has_medical_conditions);
    }).catch(error => {
      console.log(error);
    });
  }

  async resumeFlow(resumeURL) {
    debugger;
    this.loader = true;
    const createJson = {
      insuranceType: 'life',
      carrierName: 'SBLI Term',
      appStartStatus: false,
      appCompletedStatus: false,
      questionStartStatus: false,
      questionCompletedStatus: false,
      questionSequence: '0',
      applicationId: '',
      otherAppflowScreen: '',
      resumeUrl: resumeURL
    };
    const createResumeData = await this.pc.checkforResumeInit(createJson, 'create');
    console.log('createResumeData: ', createResumeData);
    setTimeout(() => {
      debugger;
      this.router.navigate([resumeURL]);
    }, 2000);

  }

  public handleAddressChange(address: Address) {
    if ('function' === typeof address.geometry.location.lat) {
      this.latitude = address.geometry.location.lat();
      this.longitude = address.geometry.location.lng();
    }
    this.placeId = address.place_id;
    this.pc.lat = this.latitude;
    this.pc.lng = this.longitude;
    this.pc.placeId = this.placeId;
  }

  closeModal() {
    this.loader = false;
    document.getElementById('errorModal').style.display = 'none';
    document.getElementById('PreFill_id').style.filter = 'none';
  }

  inputdatechange() {
    debugger;
    const K = this.dob.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.dateError = 'Invalid Month';
      }
      else {
        this.dateError = '';
      }
      this.dob = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        this.dob = A + '/' + B + '/';
      }
      else {
        this.dob = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.dob = A + '/' + B + '/' + C;
    }
    else {
      this.dob = this.dob;
    }
    if (this.dob.length >= 10) {
      this.onDateChange(this.dob);
    }
    else {
      this.is_valid = false;
    }
    console.log('dob_entered', this.dob);
  }

  onDateChange(data) {
    debugger;
    this.NewDate = data ? new Date(data) : new Date();
    let year = Number(this.NewDate.getFullYear());
    if (isNaN(year)) {
      if (data?.includes('-')) {
        year = data.split('-')[2];
      }
      else {
        year = data.split('/')[2];
      }
    }
    this.Date = new Date();
    const yearNewDate = Number(this.Date.getFullYear());
    this.age = yearNewDate - year;
    debugger;
    if (this.age >= 18 && this.age <= 55) {
      this.dateError = '';
      this.dob = this.pc.momentdob(data);
      // this.calculateTerm();
    }
    else {
      this.dob = '';
      // this.dateError = 'Insured should be between 18 years and 55 years of age';
    }
  }

  setGAEvent() {
    this.loader = true;
    if (this.email == null) {
      const eventOptionsPart1 = { 'path': 'PrefillResumeFlowLife', 'page_title': 'PrefillResumeFlowLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'email'
      });
    }
    if (this.dob == null) {
      const eventOptionsPart1 = { 'path': 'PrefillResumeFlowLife', 'page_title': 'PrefillResumeFlowLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'dob'
      });
    }
    if (this.gender == null) {
      const eventOptionsPart1 = { 'path': 'PrefillResumeFlowLife', 'page_title': 'PrefillResumeFlowLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'gender'
      });
    }
    if (this.height == null) {
      const eventOptionsPart1 = { 'path': 'PrefillResumeFlowLife', 'page_title': 'PrefillResumeFlowLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'height'
      });
    }
    if (this.weight == null) {
      const eventOptionsPart1 = { 'path': 'PrefillResumeFlowLife', 'page_title': 'PrefillResumeFlowLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'weight'
      });
    }
    if (this.tobaccoUsage == null) {
      const eventOptionsPart1 = { 'path': 'PrefillResumeFlowLife', 'page_title': 'PrefillResumeFlowLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'tobaccoUsage'
      });
    }
    if (this.term == null) {
      const eventOptionsPart1 = { 'path': 'PrefillResumeFlowLife', 'page_title': 'PrefillResumeFlowLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'term'
      });
    }
    if (this.coverage == null) {
      const eventOptionsPart1 = { 'path': 'PrefillResumeFlowLife', 'page_title': 'PrefillResumeFlowLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'coverage'
      });
    }
    if (this.zip == null) {
      const eventOptionsPart1 = { 'path': 'PrefillResumeFlowLife', 'page_title': 'PrefillResumeFlowLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'zip'
      });
    }
  }

  phonelength() {
    let count = 0;
    if (this.phone.length == 0 || this.phone.length == undefined) {
      //this.phoneError = 'Enter valid phone number';
    } else {
      this.phone = this.phone.replace(/^0+/, '');
      this.phone = this.phone.replace(/\D/g, '');
      const phonestring = this.phone.toString().slice(0, this.phone.length);
      this.phone = phonestring.replace(/(\d{3})-?/g, function (m, a) {
        return ++count <= 2 ? a + '-' : m;
      });
      this.phone = this.phone.slice(0, 12);
      if (this.phone.length < 12) {
        // this.phoneError = 'Enter valid phone number';
      }
      else {
        this.phoneError = '';
      }
    }
  }

  async ziplength(id) {
    if (this.zip.length > 5 || this.zip.length < 5) {
      this.ziperror = 'Invalid zip code';
    } else {
      this.loader = true;
      this.ziperror = '';
      await this.validateZip('2', id);
    }
  }

  validateZip(even, rtype = 1) {
    debugger;
    return new Promise<void>((resolve, reject) => {
      pc.BackendService.getGoogleAddressDetails(even == '0' ? this.full_address : this.zip).then(
        async (res) => {
          resolve();

          console.log('BackendService.getGoogleAddressDetails success', res);
          if (res.data.results.length == 0) {
            this.errormessage = 'Please enter a zipcode inside USA.';
            this.loader = false;
            document.getElementById('errorModal').style.display = 'block';
            // this.loader = false;
            this.zip = '';
            return false;
          }
          else {

            if (rtype == 3) {
              this.loader = false;
            }

            this.displayAddress = res.data.results[0].formatted_address.replace(/#/g, '');
            const formatted_address = res.data.results[0].formatted_address;
            // let checkCountry = formatted_address.split(',');
            let checkstate = formatted_address.split(',');
            checkstate = checkstate[checkstate.length - 2].replace(/\s/g, '');
            // checkCountry = checkCountry[checkCountry.length - 1];
            // checkCountry = checkCountry.replace(/\s/g, '');
            let checkCountry;
            for (let i = 0; i < res['data'].results[0].address_components.length; i++) {

              const type = res['data'].results[0].address_components[i].types[0];

              switch (type) {
                case 'country':
                  checkCountry = res['data'].results[0].address_components[i].short_name;
                  break;

              }

            }
            this.state = checkstate.substring(0, 2);
            if (checkCountry === 'USA' || checkCountry === 'US') {
              const states = await getActiveStatesLife();
              const { state } = getAddressData(res.data.results[0]);
              if (states.includes(state)) {
                this.errormessage = '';
                this.state = state;
                this.displayAddress = res.data.results[0].formatted_address;
                if (even == '0' || even == '1') {
                  await this.parseGoogleAddress(res.data.results[0]);
                  await this.handleAddressChange(res.data.results[0]);
                }
                if (even == '2') {
                  await this.parseGoogleAddress(res.data.results[0]);
                  await this.handleAddressChange(res.data.results[0]);
                  //  this.loader = false;
                }
                this.ziperror = '';
              }
              else {
                const allowedStatesString = getErrorMessage(states);
                this.errormessage = allowedStatesString;
                document.getElementById('errorModal').style.display = 'block';
                this.zip = '';
                this.ziperror = 'Enter a valid Zip code';
              }
              //this.generateClientData();
            }
            else {
              this.errormessage = 'Please enter a zipcode inside USA.';
              document.getElementById('errorModal').style.display = 'block';
              this.loader = false;
              this.zip = '';
              this.ziperror = 'Enter a valid Zip code';
            }
          }
          return true;
        }
      );
    });


  }

  cookupCustomerId(firstname, lastname, clientId) {
    let firstNameLocal, lastNameLocal;
    try { firstNameLocal = firstname[0].toLowerCase(); } catch (error) { firstNameLocal = 'a'; }
    try { lastNameLocal = lastname[0].toLowerCase(); } catch (error) { lastNameLocal = 'b'; }
    return `${firstNameLocal}${lastNameLocal}${clientId}`;
  }

  async calculateTerm(type = 1) {
    debugger;
    const todayDate = new Date();
    const urlDob = new Date(this.dob);
    this.age = todayDate.getFullYear() - urlDob.getFullYear();
    let calculatedTerm;
    if (this.age.toString() == 'NaN' && (this.term == undefined || this.term == null || this.term == '')) {
      calculatedTerm = '10';
    }
    else {
      calculatedTerm = 65 - Number(this.age);
    }
    this.termError = '';

    if (type == 1 && this.age) {
      this.termLength = Number(calculatedTerm) > 20 ? 20 : Number(calculatedTerm);
      this.termLength = Number(this.termLength) >= 15 && Number(this.termLength) < 20 ? '15' : Number(this.termLength);
      this.termLength = Number(this.termLength) >= 10 && Number(this.termLength) < 15 ? '10' : Number(this.termLength);
      this.termLength = Number(this.termLength) < 10 ? 10 : Number(this.termLength);
    }
    else {
      if (Number(this.term) <= Number(calculatedTerm) && this.age) {
        this.termLength = this.term;
        // this.termLength = Number(this.termLength) >= 15 && Number(this.termLength) < 20 ? "15" : Number(this.termLength);
        // this.termLength = Number(this.termLength) >= 10 && Number(this.termLength) < 15 ? "10" : Number(this.termLength);
        // this.termLength = Number(this.termLength) < 10 ? 10 : Number(this.termLength);
      }
      else {
        // this.termLength = Number(calculatedTerm) > 20 ? 20 : Number(calculatedTerm);
        // this.termLength = Number(this.termLength) >= 15 && Number(this.termLength) < 20 ? "15" : Number(this.termLength);
        // this.termLength = Number(this.termLength) >= 10 && Number(this.termLength) < 15 ? "10" : Number(this.termLength);
        // this.termLength = Number(this.termLength) < 10 ? 10 : Number(this.termLength);
        //this.termError = 'Based on the Date of Birth provided the selected term cannot be applied.';
        this.termLength = this.term;
      }
    }
    this.term = this.termLength.toString();
    localStorage.setItem('bubble-termLength', (this.term == 'NaN') ? '10' : this.term);
    return true;
  }

  async getshortURL(url) {
    debugger;
    await this.http.post<any>('https://app.getmybubble.co/api/url-shortener', url).subscribe(async data => {
      debugger;
      this.shorturl = data[0].shortUrl;
    });
  }


  public boData() {
    debugger;
    const boinput = {
      first_name: this.firstname,
      last_name: this.lastname,
      email: this.email,
      phone: this.phone,
      age: this.age,
      gender: this.gender,
      dob: this.dob,
      height: this.height,
      weight: this.weight,
      smoking_status: this.tobaccoUsage,
      term: this.term,
      coverage: this.coverage,
      full_address: this.fullAddress,
      city: this.city,
      state: this.state,
      country: 'US',
      source_url: this.source_url,
      formatted_address: this.formatted_address,
      cognito_id: this.cognito_id,
      lead_source: this.leadSource,
      created_at: this.createdat,
      client_id: this.clientId,
      status: this.status,
      address: this.address,
      address_line_2: this.address_line_2,
      clickid: this.clickid,
      credit_rating: this.credit_rating,
      date_of_birth: this.dob,
      dwelling_use: this.dwelling_use,
      email_2: this.email_2,
      interest_type: 'life',
      insurance_type: 'life',
      lead_form_url: this.lead_form_url,
      lead_source_time_stamp: this.lead_source_time_stamp,
      lead_token_id: this.lead_token_id,
      marital_status: this.marital_status,
      mobilephone: this.mobilephone,
      newly_acquired_property: this.newly_acquired_property,
      number_of_claims: this.number_of_claims,
      sub_id: this.sub_id,
      uid: this.clientId,
      utm_campaign: this.utm_campaign,
      utm_content: this.utm_content,
      utm_medium: this.utm_medium,
      utm_source: this.utm_source,
      utm_term: this.utm_term,
      drop_off_url: this.dropoffURL,
      dclid: this.dclid,
      zip: this.zip,
      final_url: window.location.href
    };
    for (const data in boinput) {
      if (boinput[data] == '' || boinput[data] == undefined) {
        delete boinput[data];
      }
    }
    this.http.post<any>(`${environment.Nest_API_URL}/redirect/log`, boinput).subscribe(async data => {
      debugger;

    }, async (error) => {
      console.log(error);
    });
  }
  async getDatafromhubspot(incomingdata) {
    debugger;
    let updateresponse;
    const data = {
      'library_function': 'HSforBO',
      'arguments': {
        'email': incomingdata
      },
      'environment': 'production'
    };
    const url = 'https://api3.getmybubble.io/library';
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url,
        data,
      }).then(response => {
        resolve({ response });
      }).catch(error => {
        console.log(error.response?.data?.validationResults || error);
        reject(error.response?.data?.validationResults || error);
      });
      return updateresponse;
    });

  }
}
