import { Component, OnInit } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackerService } from 'src/app/shared/tracker.service';

@Component({
  selector: 'app-home-knock-out',
  templateUrl: './home-knock-out.component.html',
  styleUrls: ['./home-knock-out.component.css']
})
export class HomeKnockOutComponent implements OnInit {
  
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  fname: string;
  lname: string;
  email: string;
  phone: string;
  btnDisable: boolean;
  nameerror: boolean;
  lnameerror: boolean;
  phoneerror: boolean;
  errorMessage: string;

  constructor(
    private activatedroute: ActivatedRoute,
    private pc: ProcessCacheService
  ) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    )
    this.fname = "";
    this.lname = "";
    this.email = "";
    this.phone = "";
    this.errorMessage = localStorage.getItem("homeKnockOutMsg");
    if (this.errorMessage == null || this.errorMessage == undefined || this.errorMessage == '') {
      this.errorMessage = "We can’t provide you with Home Insurance currently. Leave us your details and we will get you insured offline."
    }
  }
  
  checkProceedBtn() {
    if (this.fname == "" || this.lname == "" || this.email == "" || this.phone == "") {
      this.btnDisable = true;
    } else {
      this.btnDisable = false;
    }
  }

  checknamelength() {
    if (this.fname.length <= 1) {
      this.nameerror = true;
    }
    else if (this.fname === "") {
      this.nameerror = false;
    }
    else {
      this.nameerror = false;
    }
    // this.checkProceedBtn()
  }

  checklnamelength() {
    if (this.lname.length <= 1) {
      this.lnameerror = true;
    }
    else if (this.lname === "") {
      this.lnameerror = false;
    }
    else {
      this.lnameerror = false;
    }
    // this.checkProceedBtn()
  }

  phonelength() {
    // debugger
    if (this.phone.length === 3) {
      this.phone = this.phone + "-";
      this.phoneerror = true;
    }
    else if (this.phone.length === 7) {
      this.phone = this.phone + "-";
      this.phoneerror = true;
    }
    else if (this.phone.length < 12) {
      this.phoneerror = true;
    }
    else {
      this.phoneerror = true;
    }
    debugger
    let phonesplit = this.phone.split('');
    if (phonesplit[3] == '-' && phonesplit[7] == '-') {
      // this.phoneerror = true;
      phonesplit[3] = '-';
      phonesplit[7] = '-';
      this.phone = phonesplit.join('');
      if (this.phone.length == 12) {
        this.phoneerror = false;
      }

    }
    else {
      this.phoneerror = true;
    }
    // this.checkProceedBtn()
  }

  previous() {
    location.href = `QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/knockout`;
  }
  
  continueLife() {
    location.href = `QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/knockout`;    
  }

  checkOutLife() {
    location.href = `QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/life/knockout`;      
  }

  mayBeLater() {
    location.href = this.pc.envCred.url;//"https://stage-www.getmybubble.io";
  }

  submit() {
    //localStorage.setItem('bubble-name', this.name);
    localStorage.setItem('bubble-fname', this.fname);
    localStorage.setItem('bubble-lname', this.lname);
    localStorage.setItem('bubble-email', this.email);
    localStorage.setItem('bubble-phone', this.phone);
    this.fname = "";
    this.lname = "";
    this.email = "";
    this.phone = "";
  }

}
