import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lock-consent',
  templateUrl: './lock-consent.component.html',
  styleUrls: ['./lock-consent.component.css']
})
export class LockConsentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
