
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli0015ssn',
  templateUrl: './sbli0015ssn.component.html',
  styleUrls: ['./sbli0015ssn.component.css']
})
export class Sbli0015ssnComponent implements OnInit {
  @Output() public ssnoutput =
    new EventEmitter<{ component_id: any, answer: any }>();
  questions: any;
  errormessage: any;
  showerrormessage: boolean;
  showLoader = true;
  uniqueId: any;
  displayAddress: any;
  insurancetype: any;
  state: any;
  hidenextbutton: boolean;
  carrier: string;
  constructor(private router: Router, private activatedroute: ActivatedRoute,
    private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }
  SSN: any;
  inputJson: any;
  ssnLength: any;
  SSNerror: boolean;
  clientId: any;
  existingData: any;
  sbli_answer_data = {};

  @Input() questionDetails: [];
  @Output() changeQuestion = new EventEmitter();

  ngOnInit(): void {
    debugger;
    this.hidenextbutton = false;

    this.activatedroute.params.subscribe((params) => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];
    });
    this.clientId = localStorage.getItem('clientID');
    this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli0015ssn')[0];
    console.log('app-sbli0015ssn questions : ', this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      debugger;
      this.showLoader = false;
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.SBLI;
      if (this.existingData == undefined) {
        this.existingData = {};

      } else {
        this.SSN = this.existingData.sbli_ssn;
        if (this.SSN != null) {
          this.ssnLength = this.SSN.length;
        }
        // if (this.carrier == 'WNS' && !dataFrom.body.WNSsubmitAPPQCalled) {
        //  this.showLoader = true;
        //   const etty = {
        //     'next': 1,
        //     'previous': 0,
        //     'APICall': 2
        //   };
        //   this.changeQuestion.emit(etty);
        // }
        // else if (this.carrier == 'SBLI' && !dataFrom.body.SBLIsubmitAPPQCalled) {
        //   this.showLoader = true;
        //   const etty = {
        //     'next': 1,
        //     'previous': 0,
        //     'APICall': 2
        //   };
        //   this.changeQuestion.emit(etty);
        // }
        // else {
        //   this.showLoader = false;
        // }
      }

    }).catch(error => {
      this.showLoader = false;
      console.log(error);
    });

  }

  ssnlength() {
    debugger;
    const j = this.SSN.replace(/-/g, '');
    const A = j.substring(0, 3);
    const B = j.substring(3, 5);
    const C = j.substring(5, 9);
    if (B == '') {
      this.SSN = A;
    }
    else if (B != '' && C == '') {
      this.SSN = A + '-' + B;
    }
    else if (B != '' && C != '') {
      this.SSN = A + '-' + B + '-' + C;
    }
    else {
      this.SSN = j;
    }
    this.ssnLength = this.SSN.toString().length;
    if (this.ssnLength > 10) {
      this.errormessage = '';
      this.showerrormessage = false;
    }
    else {
      this.errormessage = 'Invalid SSN';
      this.showerrormessage = true;
    }
    this.ssnLength = this.SSN.toString().length;
    this.sbli_answer_data[this.questions.answer_key] = this.SSN;
  }



  next() {
    debugger;
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    this.$gaService.event('SBLI LifeApplication SSN', 'event', 'SBLI-LifeApplication-SSN');
    const eventOptions: EventOption = {
      page_title: 'SBLI-LifeApplication-T&C',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication SSN', 'event', 'SBLI-LifeApplication-SSN');
    json.SBLI[this.questions.answer_key] = this.SSN;
    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'APICall': 7,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    debugger
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }




  public stoploader() {
    this.showLoader = false;
  }

}
