import axios from 'axios';
import { environment } from './../../environments/environment';

const stateMap = {
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  CA: 'California',
  CO: 'Colorado',
  DC: 'District of Columbia',
  CT: 'Connecticut',
  DE: 'Delaware',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MS: 'Mississippi',
  MT: 'Montana',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NV: 'Nevada',
  NY: 'NewYork',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming'
};

async function getActiveStatesLife() {
  const { data: states } = await axios.get(environment.Nest_API_URL + '/states/life');
  console.log(states);

  return states;
}

function getAddressData({ address_components }) {

  let street;
  let city;
  let state;
  let zip;
  let country;
  //debugger
  for (let i = 0; i < address_components.length; i++) {
    if (address_components[i].types) {
      if (address_components[i].types.includes('street_number')) {
        street = street + address_components[i].short_name + ' ';
      } else if (address_components[i].types.includes('route')) {
        street = street + address_components[i].long_name;
      } else if (address_components[i].types.includes('locality')) {
        city = address_components[i].long_name;
      } else if (address_components[i].types.includes('administrative_area_level_1')) {
        state = address_components[i].short_name;
      } else if (address_components[i].types.includes('postal_code')) {
        zip = address_components[i].short_name;
      } else if (address_components[i].types.includes('country')) {
        country = address_components[i].short_name;
      }
    }
  }

  return { state, city, zip, country, street };
}

function getErrorMessage(states) {
  let allowedStatesString = '';
  if (states.length == 1) {
    allowedStatesString = stateMap[states[0]];
  } else {
    for (let i = 0; i < states.length; i++) {
      allowedStatesString += stateMap[states[i]] + ', ';
    }
  }
  allowedStatesString = 'We currently serve only in ' + allowedStatesString;
  allowedStatesString = allowedStatesString.replace(/,\s*$/, '.');
  allowedStatesString = allowedStatesString.replace(/,(?=[^,]*$)/, ' and');

  return allowedStatesString;
}

export { getActiveStatesLife, getErrorMessage, getAddressData };
