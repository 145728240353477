import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from "../../../components/lifescore-error-alert/lifescore-error-alert.component";
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { Subject } from 'rxjs';
const pc = require('bit-uibl-data-driver');

@Component({
  selector: 'app-wnsterms',
  templateUrl: './wnsterms.component.html',
  styleUrls: ['./wnsterms.component.css']
})
export class WnstermsComponent implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  @Output() trigerrconsentpopup = new EventEmitter();
  showLoader: boolean = true;
  uniqueId: any;
  clientId: any;
  docURLs: any;
  disablebutton: boolean;
  timeoutId: any;
  base64JsonPdf = {};
  combinedBase64DataString = "";
  pdfUrl: any;
  combinedPdfUrl: any;
  combinedPdfArray = [];
  userInactive: Subject<any> = new Subject();
  popupshowed: boolean;
  constructor(private pc: ProcessCacheService,
    private modalService: NgbModal,
    private activatedroute: ActivatedRoute, private router: Router, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    debugger
    this.disablebutton = true;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
    }
    );
    this.showLoader = true;
    let quickquote = new pc.Quotes(this.uniqueId);
    quickquote.wnsgetFORMS(1).then(response => {
      debugger
      console.log("wnsgetFORMS res", response);
      if (response.sbliError) {
        if(response.msgText==undefined){
          response.msgText='There seems to be an issue while processing your application. Please reach out to our licensed agents who will help you complete your purchase.'
        }
        this.openModal(response.msgText, "Sorry!");
      }
      else {
        this.docURLs = JSON.parse(response.formsJson);
        console.log("wnsgetFORMS res formsJson", JSON.parse(response.formsJson));
        this.docURLs.forEach(element => {
          console.log(element);
          for (const item in element) {
            console.log(item);
            this.base64JsonPdf[item.toString()] = element[item];
            // if (this.combinedBase64DataString  == ""){
            this.combinedBase64DataString = this.combinedBase64DataString + (atob(element[item]));
            this.combinedPdfArray.push(atob(element[item]));
            // }
            this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64,${element[item]}`);
          }

          // Define the string
          /* var decodedStringBtoA = 'Hello World!';

          // Encode the String
          var encodedStringBtoA = btoa(decodedStringBtoA);
          console.log("encodedStringBtoA", encodedStringBtoA);
          console.log("encodedStringAtoB", atob(encodedStringBtoA)); */
          //this.base64JsonPdf[k] = v
        });
        console.log("combinedPdfArray", this.combinedPdfArray);
        console.log("base64JsonPdf", this.base64JsonPdf);
        this.showLoader = false;
        this.checkTimeOut();
        this.userInactive.subscribe((message) => {
          // alert(message);
          console.log('message');
          if (!this.popupshowed) {
            this.trigerrconsentpopup.emit();
          }
          this.popupshowed = true;
        }
        );


        // this.combineBase64Data();
      }
    })
      .catch(error => {
        console.log("wnsnitApp Error:", error);
      });
  }





  openModal(msg, title) {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    let data = {
      head: 'Please Note',
      title: title,
      message: msg,
      image: 'assets/images/images/education2.png'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log("Modal Close Output: ", result);
    }, (reason) => {
      console.log("Modal Close Reason: ", reason);
    });
  }

  next() {
    this.pc.lifesummary({ 'consent_submit': 'submit' });
    let etty = {
      "next": 1,
      "previous": 0,
      "APICall": 0
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }

  public DownloadPDF(id) {
    debugger
    this.pc.lifesummary({ 'consent_click': 'Yes' });
    this.disablebutton = false;
    console.log("DownloadPDF", id);
    // window.location.href = 'data:application/octet-stream;base64,' + this.base64JsonPdf[id];
    const source = `data:application/pdf;base64,${this.base64JsonPdf[id]}`;
    let filename = "WNS PDF"
    const link = document.createElement("a");
    link.href = source;
    link.download = `${filename}.pdf`
    link.click();
    /* if(id==0){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["905"];
    }
    else if(id==0){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["904"];
    }
    else if(id==2){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["906"];
    }
    else if (id==3){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["911"];
    }
    else{
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["912"];
    }
    window.location.href = pdf; */
  }

  combineBase64Data() {
    console.log("combineBase64Data called", this.combinedBase64DataString);
    let newBase64 = btoa(this.combinedBase64DataString);
    console.log("combineBase64Data called", newBase64);
    // window.location.href = 'data:application/octet-stream;base64,' + this.base64JsonPdf[id];
    const source = `data:application/pdf;base64,${newBase64}`;
    let filename = "Combined";
    const link = document.createElement("a");
    link.href = source;
    link.download = `${filename}.pdf`
    link.click();

    let arrayBase64 = this.combinedPdfArray.join("");
    console.log("arrayBase64", arrayBase64);
    let combinedarrayBase64 = btoa(arrayBase64);
    console.log("combinedarrayBase64", combinedarrayBase64);

    const source2 = `data:application/pdf;base64,${combinedarrayBase64}`;
    let filename2 = "CombinedArray";
    const link2 = document.createElement("a");
    link2.href = source2;
    link2.download = `${filename2}.pdf`
    link2.click();

  }

  urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    console.log("outputArray", outputArray);
    return outputArray;
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(
      () =>



        this.userInactive.next(
          "User has been inactive for 5 seconds"), 5000
    );




  }



  @HostListener('window:keydown')
  @HostListener('window:mousedown')
  @HostListener('window:mouseover')





  checkUserActivity() {
    clearTimeout(this.timeoutId);
    this.checkTimeOut();
  }
}
