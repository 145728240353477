import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from './../../../components/lifescore-error-alert/lifescore-error-alert.component';

const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-sw4pt30',
  templateUrl: './sw4pt30.component.html',
  styleUrls: ['./sw4pt30.component.css']
})
export class Sw4pt30Component implements OnInit {

  @Output() nextquestion20 = new EventEmitter();
  @Output() amountChanged = new EventEmitter();

  dynamicdiv = [];
  errormessage: any;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  showoptions: string;
  ItemClassList: any = [];
  totalvalue: any;
  existingData: any;
  dynamicdivtoshow: any[];
  loader: boolean;
  existingAppData: any;
  includedCoverage: any;
  optionalCoverage: any;
  discounttable: any;
  myArray: any[];
  @Input() quoteAmount;
  discountQuestions: any;
  showerror: boolean;
  showmanditoryerror: any;
  sppList: any;
  displayModal: boolean;
  sw_agreeTerms: any;
  isedit: boolean;
  commonInput: any;
  constructor(private $gaService: GoogleAnalyticsService, private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private modalService: NgbModal) { }

  ngOnInit(): void {
    debugger;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    this.commonInput = JSON.parse(localStorage.getItem('commonInput'));
    // this.uniqueId = 'ndbrck-285-Bryant-St-Palo-Alto-CA-94301';
    // this.clientId = 'ndbrck';
    this.loader = true;
    this.totalvalue = 0;
    // this.pc.getSWSelectedFormattedQuote().then(data => {
    //   console.log("selectedHomeCarrier", data);
    //   localStorage.setItem('selectedHomeCarrier', JSON.stringify(data));
    //   let selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
    //   this.quoteAmount = selectedHome.head.quote_amount;
    // });

    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt30')[0];

    this.discountQuestions = getSWQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sw4pt28')[0];
    console.log('sw questions app-sw4pt30 : ', this.questions);
    ////debugger
    this.pc.getAppData(this.clientId).then(data => {
      ////debugger
      const dataFrom = JSON.parse(data.Payload);
      this.existingAppData = dataFrom.body.stillwater;
      console.log('data from getAppData sw : ', this.existingData);
    }).catch(error => {
      ////debugger
      console.log(error);
    });

    const quickquote = new pc.Quotes(this.uniqueId);
    this.ItemClassList = quickquote.getSWSPPOptions();
    if (this.commonInput['state'] == 'OH' || this.commonInput['state'] == 'WA') {
      this.ItemClassList = this.ItemClassList.filter(x => x.value != '71' && x.value != '335A' && x.value != '336A');
    }

    console.log('sw ItemClassList : ', this.ItemClassList);

    //this.dynamicdiv.length = 1;

    this.loader = false;
    this.getCustomiseValues();

  }

  getCustomiseValues() {
    debugger;
    this.loader = true;
    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.getSWCustomizeValues('y').then(data => {
      debugger;
      console.log('customise values : ', data);

      this.includedCoverage = data.includedCoverage;
      this.optionalCoverage = data.optionalCoverage;
      this.discounttable = data.discountApplied;
      this.sppList = data.sppList;






      this.quoteAmount = data.yearlyPremium;
      //this.quoteAmount = data.monthlyPremium;

      const hubSpotData = {
        customer_id: localStorage.getItem('customer_id'),
        email: localStorage.getItem('bubble-email'),
        //home_latest_premium_amount: data.quoteAmt
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

      // ////debugger
      // let userSelected = JSON.parse(localStorage.getItem('sw_discounts'));
      // for (let i = 0; i < userSelected.length; i++){
      //   let exists=this.discountQuestions.checkbox_options.filter(x=>x.sw_questionId==userSelected[i]);
      //   if (exists.length>0) {
      //     this.discounttable.push(exists[0].answer_label);
      //   }
      // }
      //this.discountQuestions=this.discountQuestions.filter(x=>x.sw_questionId==userSelected.includes());
      //let dis=

      this.myArray = [];
      for (const item in this.includedCoverage) {
        if (this.includedCoverage[item].title && this.includedCoverage[item].premium != 'Not Covered') {
          const stty = this.includedCoverage[item];
          if (stty.input_type == 'dropdown') {
            const trueValue = stty.dropdown_values.filter(x => x.selected == true);
            if (trueValue.length > 0) {
              var ett = {
                'title': stty.title,
                'value': trueValue[0].text,
                'help_text': stty.help_text,
                'premium': stty.premium,
                'answer_key': stty.answer_key
              };
              this.myArray.push(ett);
            }
          }
          else {
            // var ett = {
            //   'title': stty.title,
            //   'value': stty.premium
            // }
            // this.myArray.push(ett);
            var ett = {
              'title': stty.title,
              'value': stty.input_value,
              'help_text': stty.help_text,
              'premium': stty.premium,
              'answer_key': stty.answer_key
            };
            this.myArray.push(ett);
          }
        }
      }
      this.includedCoverage = this.myArray;

      this.myArray = [];
      for (const item in this.optionalCoverage) {
        //////debugger
        if (this.optionalCoverage[item].title && this.optionalCoverage[item].premium != 'Not Covered') {
          const stty = this.optionalCoverage[item];
          if (stty.input_type == 'dropdown') {
            const trueValue = stty.dropdown_values.filter(x => x.selected == true);
            if (trueValue.length > 0) {
              var ett = {
                'title': stty.title,
                'value': trueValue[0].text,
                'help_text': stty.help_text,
                'premium': stty.premium,
                'answer_key': stty.answer_key
              };
              this.myArray.push(ett);
            }
          }
          else {
            // var ett = {
            //   'title': stty.title,
            //   'value': stty.premium
            // }
            // this.myArray.push(ett);
            var ett = {
              'title': stty.title,
              'value': stty.input_value,
              'help_text': stty.help_text,
              'premium': stty.premium,
              'answer_key': stty.answer_key
            };
            this.myArray.push(ett);
          }
        }
      }
      ////debugger
      this.optionalCoverage = this.myArray;
      let Dwellingamount = this.includedCoverage.filter(item => item.answer_key == 'dwelling')[0].value;
      let otherstructureamount = this.includedCoverage.filter(item => item.answer_key == 'other_structure')[0].value;
      Dwellingamount = Dwellingamount.toString().replace(/,/g, '');
      Dwellingamount = Dwellingamount.toString().replace('$', '');
      Dwellingamount = Dwellingamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      Dwellingamount = '$' + Dwellingamount;
      otherstructureamount = otherstructureamount.toString().replace(/,/g, '');
      otherstructureamount = otherstructureamount.toString().replace('$', '');
      otherstructureamount = otherstructureamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      otherstructureamount = '$' + otherstructureamount;
      this.includedCoverage.filter(item => item.answer_key == 'dwelling')[0].value = Dwellingamount;
      this.includedCoverage.filter(item => item.answer_key == 'other_structure')[0].value = otherstructureamount;

      console.log('customise - includedCoverage : ', this.includedCoverage);
      console.log('customise - optionalCoverage : ', this.optionalCoverage);
      console.log('customise - discounttable : ', this.discounttable);

      this.loader = false;

    }).catch(error => {
      console.log('getSWCustomizeValues error : ', error);
    });
  }

  showcustomiseLife() {
    debugger;
    this.loader = true;
    this.errormessage = '';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('customiseHome').style.display = 'block';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
    this.displayModal = true;
    this.existingData = localStorage.getItem('scheduledPersonalProperty');
    this.existingData = JSON.parse(this.existingData);
    console.log('existing data:', this.existingData);
    ////debugger
    if (this.existingData == null || this.existingData == undefined || this.existingData == '' || this.existingData[0].spp_id == '') {
      this.dynamicdiv = [];
      const ettty = {
        'spp_id': '',
        'spp_value': '',
        'valuetoshow': '',
        'spp_desc': '',
        'Disable': false,
        'doNotEnable': false,
        'arrayLength': 1
      };
      this.dynamicdiv.push(ettty);
      this.isedit = false;
    }
    else {
      this.isedit = true;
      this.dynamicdiv = JSON.parse(localStorage.getItem('scheduledPersonalProperty'));
      // this.dynamicdivtoshow = [];
      // for (let i = 0; i < this.dynamicdiv.length; i++){
      //   debugger
      //   let asdsa = this.ItemClassList.find(x => x.value == this.dynamicdiv[i].spp_id);
      //   let text = "";
      //   if (asdsa == undefined) {
      //     text = "";
      //   }
      //   else {
      //     text = asdsa.text;
      //   }
      //   var entity = {
      //     'spp_id' : this.dynamicdiv[i].spp_id,
      //     'spp_value' : this.dynamicdiv[i].spp_value,
      //     'spp_desc' : this.dynamicdiv[i].spp_desc,
      //     'spp_class' : text
      //   }
      //   this.dynamicdivtoshow.push(entity);
      // }
      this.totalvalue = this.dynamicdiv.map(a => Number(a.spp_value)).reduce(function (a, b) {
        return a + b;
      });
      this.existingData = 1;
    }
    this.loader = false;
  }


  closeModal() {
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('customiseHome').style.display = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';
    this.displayModal = false;
  }

  AddDiv() {
    ////debugger;
    if (this.dynamicdiv[this.dynamicdiv.length - 1].spp_id == '' || this.dynamicdiv[this.dynamicdiv.length - 1].spp_value == '' || this.dynamicdiv[this.dynamicdiv.length - 1].spp_desc == '') {
      this.showmanditoryerror = true;
    }
    else {
      this.showmanditoryerror = false;
      this.dynamicdiv[this.dynamicdiv.length - 1].Disable = true;
      this.dynamicdiv[this.dynamicdiv.length - 1].doNotEnable = true;
      this.dynamicdiv[this.dynamicdiv.length - 1].Disable = true;
      const ettty = {
        'spp_id': '',
        'spp_value': '',
        'valuetoshow': '',
        'spp_desc': '',
        'Disable': false,
        'doNotEnable': false,
        'arrayLength': this.dynamicdiv.length
      };
      this.dynamicdiv.push(ettty);
    }

  }
  RemoveRow(divindex) {
    debugger;
    this.dynamicdiv.splice(divindex, 1);
    if (this.dynamicdiv.length != 0) {
      this.totalvalue = this.dynamicdiv.map(a => Number(a.spp_value)).reduce(function (a, b) {
        return a + b;
      });
      if (this.dynamicdiv.length == 1) {
        this.dynamicdiv[0].Disable = false;
      }
    }
    else {
      const ettty = {
        'spp_id': '',
        'spp_value': '',
        'spp_desc': '',
        'valuetoshow': '',
        'Disable': false,
        'arrayLength': this.dynamicdiv.length,
        'doNotEnable': this.isedit ? true : false
      };
      this.dynamicdiv.push(ettty);
      this.totalvalue = 0;
    }
  }
  enableAgreeTerms(id) {
    debugger;
    this.sw_agreeTerms = id;
  }
  next() {
    //////debugger
    const json = {
      'id': this.clientId,
      stillwater: this.existingAppData
    };
    json.stillwater['sw_spp'] = this.dynamicdiv;
    json.stillwater['sw_agreeTerms'] = this.sw_agreeTerms;
    this.pc.saveUpdateClientData(json).then(data => {
      const etty = {
        'next': 1,
        'previous': 0,
      };
      this.nextquestion20.emit(etty);
    }).catch(error => {
      console.log(error);
    });
    this.$gaService.event('Next', 'event', 'HomeAppFinalQuoteReview');
  }

  previous() {
    debugger
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    //commonInput['ssn'] = '';
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion20.emit(etty);
  }


  save() {
    ////debugger
    this.loader = true;
    this.dynamicdiv[this.dynamicdiv.length - 1].Disable = true;
    this.dynamicdiv[this.dynamicdiv.length - 1].doNotEnable = true;
    console.log('table data : ', this.dynamicdiv);
    localStorage.setItem('scheduledPersonalProperty', JSON.stringify(this.dynamicdiv));
    const quickquote = new pc.Quotes(this.uniqueId);
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    // commonInput['sw_spp'] = JSON.stringify(this.dynamicdiv);
    if (this.dynamicdiv[0].doNotEnable == true && this.dynamicdiv[this.dynamicdiv.length - 1].spp_id == '' && this.dynamicdiv[this.dynamicdiv.length - 1].spp_value == '' && this.dynamicdiv[this.dynamicdiv.length - 1].spp_desc == '') {
      commonInput['sw_spp'] = [];
      localStorage.setItem('scheduledPersonalProperty', '');
    }
    else {
      commonInput['sw_spp'] = this.dynamicdiv;
    }
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    console.log('custmise SWquote spp commonInput: ', commonInput);
    quickquote.getSWCustomizedQuote(commonInput).then(response => {
      debugger;
      if (response.hasError) {
        this.openModal(response.errorMessage.split(':')[1]);
        this.loader = false;
      }
      else {
        document.getElementById('makeblur').style.filter = 'none';
        document.getElementById('customiseHome').style.display = 'none';
        document.getElementById('makeblur').style.overflowY = 'auto';
        document.getElementById('makeblur').style.position = 'unset';
        const json = {
          'id': this.clientId,
          stillwater: this.existingAppData
        };
        json.stillwater['sw_spp'] = this.dynamicdiv;
        this.pc.saveUpdateClientData(json).then(data => {
          console.log('output getSWCustomizedQuote spp: ', response);
          localStorage.setItem('selectedHomeCarrier', JSON.stringify(response));
          console.log('table data : ', this.dynamicdiv);
          this.loader = false;
          this.existingData = 1;
          localStorage.setItem('scheduledPersonalProperty', JSON.stringify(this.dynamicdiv));
          // this.dynamicdivtoshow = this.dynamicdiv;
          // debugger
          // for (let i = 0; i < this.dynamicdivtoshow.length; i++){
          //   let asdsa = this.ItemClassList.find(x => x.value == this.dynamicdivtoshow[i].spp_id);
          //   let text = "";
          //   if (asdsa == undefined) {
          //     text = "";
          //   }
          //   else {
          //     text = asdsa.text;
          //   }
          //   this.dynamicdivtoshow[i].spp_class = text;
          // }
          this.amountChanged.emit(response);
          this.getCustomiseValues();
        }).catch(error => {
          console.log(error);
        });
      }
    })
      .catch(error => {
        console.log('getSWCustomizedQuote Error:', error);
        this.loader = false;
      });
  }

  openModal(msg) {
    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: msg
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
        // keyboard: false,
        // backdrop: 'static'
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: msg,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  TotalAmount(data) {
    debugger;
    this.totalvalue = 0;
    // this.totalvalue = this.dynamicdiv.map(a =>Number(a.spp_value)).reduce(function(a, b) {
    //   return a + b;
    // });
    for (let i = 0; i < this.dynamicdiv.length; i++) {
      let spvalue;
      spvalue = this.dynamicdiv[i].valuetoshow.toString().replace(/,/g, '');
      spvalue = spvalue.toString().replace('$', '');
      data.spp_value = spvalue;
      this.totalvalue = Number(this.totalvalue) + Number(spvalue);
    }
    //this.errormessage="";
    if (data.spp_value > 25000) {
      this.showerror = true;
      this.errormessage = 'Scheduled Personal Property exceeds the maximum allowed. Please adjust the value of the SPP item(s) to meet our Rules.';
    }
    else {
      this.showerror = false;
      this.errormessage = '';
    }
    data.valuetoshow = data.valuetoshow.toString().replace(/,/g, '');
    data.valuetoshow = data.valuetoshow.toString().replace('$', '');
    data.valuetoshow = data.valuetoshow.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    data.valuetoshow = '$' + data.valuetoshow;
    this.totalvalue = this.totalvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
}
