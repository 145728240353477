import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from "../../../appUtility/appUtility.js";
@Component({
  selector: 'app-wns00601020',
  templateUrl: './wns00601020.component.html',
  styleUrls: ['./wns00601020.component.css']
})
export class Wns00601020Component implements OnInit {

  @Output() changeQuestion = new EventEmitter();
  isdisable: boolean;
  userOccupation: any;
  userLookingFor: any;
  employee: any;
  questions: any;
  clientId: any;
  existingData: any;
  currentlyWorking = [];
  militaryworking = [];
  showLoader: boolean = true;
  military: any;
  income: any;
  annualIncome: any;
  test: any;
  ShowDollar_income: boolean;
  questions2: any;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');

    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        let que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == "app-wns00601020")[0];
        this.questions2 = que.filter(x => x.component_id == "app-sbli00501030")[0];
        console.log("app-sbli00601020 questions : ", this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-wns00601020")[0];
        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli00501030")[0];
        console.log("app-sbli00601020 questions : ", this.questions);
      }


      this.existingData = dataFrom.body.SBLI;

      if (this.existingData.sbli_employee != undefined) {
        debugger;
        this.employee = this.existingData.sbli_employee;
        this.military = this.existingData.sbli_military;
        this.isdisable = false;

        if (this.existingData.sbli_employee_retired != undefined && this.existingData.sbli_employee_nonworkinspouce != undefined && this.existingData.sbli_employee_fulltimestudent != undefined && this.existingData.sbli_employee_notworking != undefined) {
          if (this.existingData.sbli_employee_retired == 'Yes') {
            this.userOccupation = 'Retired';

          } else if (this.existingData.sbli_employee_nonworkinspouce == 'Yes') {
            this.userOccupation = 'Non-working spouse';

          } else if (this.existingData.sbli_employee_fulltimestudent == 'Yes') {
            this.userOccupation = 'Full-time Student';
            if (this.existingData.sbli_Student_Type_bachelordegree == 'Yes') {
              this.userLookingFor = 'Bachelor’s Degree';

            } else if (this.existingData.sbli_Student_Type_masterdegree == 'Yes') {
              this.userLookingFor = 'Master’s Degree';

            } else if (this.existingData.sbli_Student_Type_doctorate == 'Yes') {
              this.userLookingFor = 'Doctorate';
            } else if (this.existingData.sbli_Student_Type_other == 'Yes') {
              this.userLookingFor = 'Other Qualification';
            }
            this.getUserLookingFor(this.userLookingFor);

          } else if (this.existingData.sbli_employee_notworking == 'Yes') {
            this.userOccupation = 'Not actively working';

          }



        }

        if (this.existingData.sbli_annualIncome != undefined) {
          this.income = this.existingData.sbli_annualIncome;
          this.annualIncome = this.income;
          this.income = this.income.replace(/,/g, '');
          const ctd = this.income;
          this.income = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
          this.test = this.annualIncome.toString().length;

          if (this.test > 0) {
            this.ShowDollar_income = true;
          }
          else {
            this.ShowDollar_income = false;
          }

          if (Number(this.annualIncome) == 0) {
            this.isdisable = true;
          }
          else {
            this.isdisable = false;
          }
        }
        else {
          this.isdisable = true;
        }



      }

      this.showLoader = false;

    }).catch(error => {
      console.log(error);
    });
    this.employee = '';
    this.userOccupation = '';
    this.userLookingFor = '';
    this.isdisable = true;
  }

  formatIncome() {

    debugger;
    this.income = this.income.replace(/,/g, '');
    this.annualIncome = this.income;
    const ctd = this.income;
    this.income = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    this.test = this.annualIncome.toString().length;
    if (this.test > 0) {
      this.ShowDollar_income = true;
    }
    else {
      this.ShowDollar_income = false;
    }

    if (Number(this.annualIncome) == 0) {
      this.isdisable = true;
    }
    else {
      this.isdisable = false;
    }

    if (this.employee == '' && this.userOccupation == '' && this.userLookingFor == '') {
      this.isdisable = true;
    }


  }
  Employee(event) {
    debugger
    if (event == this.questions.radio_options[0].answer_text) {
      this.employee = event;
      this.currentlyWorking[this.questions.radio_options[0].answer_key] = event;
    }
    else if (event == this.questions.radio_options[1].answer_text) {
      this.employee = event;
      this.currentlyWorking[this.questions.radio_options[0].answer_key] = event;

      this.userOccupation = '';
      this.userLookingFor = ''
    }


    if (this.employee == this.questions.radio_options[1].answer_text && this.userOccupation != '') {
      this.isdisable = false;
    }
    else if (this.employee == this.questions.radio_options[0].answer_text && (this.military == "Yes" || this.military == "No") && this.annualIncome != undefined) {
      this.isdisable = false;
    }
    else {
      this.isdisable = true;
    }

  }

  Military(event) {
    debugger
    if (event == this.questions.radio_option_3[0].radio_options[0].answer_text) {
      this.military = event;
      this.militaryworking[this.questions.radio_option_3[0].radio_options[0].answer_key] = event;
    }
    else if (event == this.questions.radio_option_3[0].radio_options[1].answer_text) {
      this.military = event;
      this.militaryworking[this.questions.radio_option_3[0].radio_options[0].answer_key] = event;
    }
    if (this.annualIncome != undefined)
      this.isdisable = false;

  }
  getUserOccupation(event) {
    debugger;

    this.userOccupation = event;
    localStorage.setItem('userOccupation', this.userOccupation);
    if (event != this.questions.radio_options_1[2].answer_text) {
      this.userLookingFor = '';
    }
    if (this.employee != '' && this.userOccupation != '' && this.userOccupation != this.questions.radio_options_1[2].answer_text) {
      this.isdisable = false;
    }
    else {
      this.isdisable = true;
    }
    this.currentlyWorking[this.questions.radio_options_1[0].answer_key] = event;
  }

  getUserLookingFor(event) {
    debugger;

    this.userLookingFor = event;
    localStorage.setItem('userLookingFor', this.userLookingFor);


    if (this.employee != '' && this.userOccupation != '' && this.userLookingFor != "") {
      this.isdisable = false;
    }
    else {
      this.isdisable = true;
    }
    this.currentlyWorking[this.questions.radio_options_2[0].answer_key] = event;
  }



  next() {
    debugger;

    this.getSelecteddata();
    // json.SBLI[this.questions.radio_options_1[0].answer_key] = this.userOccupation;
    // json.SBLI[this.questions.radio_options_2[0].answer_key] = this.userLookingFor;

  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }

  jsonData: any;
  public getSelecteddata() {
    debugger;
    var json = {
      'id': this.clientId,
      SBLI: this.existingData

    }

    if (this.employee == 'No') {
      json.SBLI[this.questions.radio_options[0].answer_key] = this.employee;
      if (this.userOccupation.trim() == 'Retired') {
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';

      } else if (this.userOccupation.trim() == 'Non-working spouse' || this.userOccupation.trim() == 'A non-working spouse') {
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'Yes'
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';
      } else if (this.userOccupation.trim() == 'Full-time Student' || this.userOccupation.trim() == 'A full-time student') {
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';

        if (this.userLookingFor.trim() == 'Bachelor’s Degree' || this.userLookingFor.trim() == 'Bachelor’s or Associates Degree') {
          json.SBLI[this.questions.radio_options_2[0].answer_key] = 'Yes';
          json.SBLI[this.questions.radio_options_2[1].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[2].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[3].answer_key] = 'No';

        } else if (this.userLookingFor.trim() == 'Master’s Degree' || this.userLookingFor.trim() == "Master's degree") {
          json.SBLI[this.questions.radio_options_2[1].answer_key] = 'Yes'
          json.SBLI[this.questions.radio_options_2[0].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[2].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[3].answer_key] = 'No';

        } else if (this.userLookingFor.trim() == 'Doctorate' || this.userLookingFor.trim() == "Doctorate") {
          json.SBLI[this.questions.radio_options_2[2].answer_key] = 'Yes';
          json.SBLI[this.questions.radio_options_2[0].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[1].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[3].answer_key] = 'No';

        } else if (this.userLookingFor.trim() == 'Other Qualification' || this.userLookingFor.trim() == "Other qualification") {
          json.SBLI[this.questions.radio_options_2[3].answer_key] = 'Yes';
          json.SBLI[this.questions.radio_options_2[0].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[1].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[2].answer_key] = 'No';
        }

      } else if (this.userOccupation.trim() == 'Not actively working' || this.userOccupation.trim() == "Not actively working") {
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';

      }

      for (var propName in json.SBLI) {
        if (json.SBLI[propName] === null || json.SBLI[propName] === undefined || json.SBLI[propName] === "") {
          delete json.SBLI[propName];
        }
      }

      this.jsonData = json;
    } else {
      json.SBLI[this.questions.radio_options[0].answer_key] = this.employee;
      json.SBLI[this.questions.radio_option_3[0].radio_options[0].answer_key] = this.military;
      json.SBLI[this.questions2.input.answer_key] = this.income;
      this.jsonData = json;
    }

    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": this.jsonData,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);

  }

}
