import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { TrackerService } from 'src/app/shared/tracker.service';
import { getQuestionAnswerJson, getQuestionAnswerByPassingInput } from "../../../appUtility/appUtility";

@Component({
  selector: 'app-pac-life',
  templateUrl: './pac-life.component.html',
  styleUrls: ['./pac-life.component.css']
})
export class PacLifeComponent implements OnInit {
  showNumber: number;
  constructor(private pc: ProcessCacheService, private tracker: TrackerService, public router: Router) { }
  questionAnswersJSON: any;
  questions: any
  ssnInput: [];
  isActiveMilitaryInput: [];
  countryBornInput: [];
  isDependentInput: [];
  addBeneficiariesInput: [];
  insurancePurposeInput: [];
  policyOwnerInput: [];
  policyOwnerDetailsInput: [];
  insuredDetailsInput: [];
  applicationCompleteInput: [];
  isInsuredDependentInput: [];
  thirdPartyNotifiedInput: [];
  contingentBeneficiariesInput: [];
  hasDriverLicenseInput: [];
  hasPendingApplicationsInput: [];
  hasExistingLifeInsuranceInput: [];
  electronicConsentInput: [];
  ApplicationID: any;
  appdetails: boolean;
  welcomeToPacLife: boolean;
  applicationComplete: boolean;
  totalPages: number = 15 * 2; //change if number of pages changes
  currentPage: number = this.totalPages / 2 + 3;
  url: any;
  // clientId : string;
  ngOnInit(): void {
    debugger;
    this.showNumber = 0;
    this.url = window.location.href;
    if (this.url.includes('Appflowstart')) {
      localStorage.setItem('insurancetypeText', 'Life Application');
    }
    localStorage.setItem('editForPolicyOwner', 'false');
    localStorage.setItem('editContigentBeneficiaryInformation', 'false');
    localStorage.setItem('premilumDirtyFlag', 'false');
    // localStorage.setItem('appFlowResume', '1');

    this.applicationComplete = false;
    localStorage.setItem('applicationComplete', 'false');
    // this.clientId = localStorage.getItem("clientID");
    // if(!this.clientId) this.clientId = localStorage.getItem("clientId");
    //console.log("checkforResumeInit");
    this.ApplicationID = this.pc.getApplicationId(localStorage.getItem('quoteId'));
    localStorage.setItem('bubble-ApplicationID', this.ApplicationID);
    // this.appMidWay(localStorage.getItem('appFlowResume'), localStorage.getItem('questionId'));
    if (localStorage.getItem("appFlowLinkResume") == "1") {
      debugger;
      localStorage.setItem("appFlowLinkResume", "0");
      this.checkforResumeInit({}, "fetch").then(
        resumeData => {
          debugger
          console.log("checkforResumeInit:Resume Step 0", resumeData);
          console.log("checkforResumeInit:Resume Step 1");
          if (resumeData['questionStartStatus'] == true && resumeData['questionCompletedStatus'] == false) {
            console.log("Resume Step 2");
            this.appdetails = false;
            this.questions = this.pc.getQue();
            // display question screen
            this.appMidWay(Number(resumeData['questionSequence']));

          } else {
            console.log("Resume Step 2-");
            // display other appflow screen : welcome-to-pac-life, details-confirmation, e-consent
            // if (resumeData['otherAppflowScreen'] == "welcome-to-pac-life") {
            //   this.appdetails = true;
            // } else
            if (resumeData['otherAppflowScreen'] == "details-confirmation") {
              this.appdetails = true;
            } else if (resumeData['otherAppflowScreen'] == "e-consent") {
              //this.router.navigate(["EConsent"]);
              //this.appdetails=true;
              // this.router.navigate([resumeData['resumeUrl']]);
              this.questions = this.pc.getQue();
              this.appMidWay(Number(13));
            } else {
              this.appdetails = true;
            }
          }
        }
      );
    } else {
      this.appdetails = true;
    }
  }

  public appMidWay(questionId) {
    debugger
    console.log("this.appMidWay", questionId);
    // if(appFlowResume==1 && questionId>0){
    if (questionId == 1) {
      this.policyOwnerInput = this.questions.__zone_symbol__value[0];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    /* else if(questionId==2){
      this.insuredDetailsInput = this.questions.__zone_symbol__value[1];
      this.tracker.changeProgressStatus({ totalPages:this.totalPages, currentPage: this.currentPage });
    } */

    else if (questionId == 2) {
      this.isActiveMilitaryInput = this.questions.__zone_symbol__value[1];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 3) {
      this.countryBornInput = this.questions.__zone_symbol__value[2];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 4) {
      this.isInsuredDependentInput = this.questions.__zone_symbol__value[3];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 5) {
      this.thirdPartyNotifiedInput = this.questions.__zone_symbol__value[4];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 6) {
      this.addBeneficiariesInput = this.questions.__zone_symbol__value[5];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 7) {
      this.contingentBeneficiariesInput = this.questions.__zone_symbol__value[6];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 8) {
      this.hasDriverLicenseInput = this.questions.__zone_symbol__value[7];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 9) {
      this.hasPendingApplicationsInput = this.questions.__zone_symbol__value[8];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 10) {
      this.hasExistingLifeInsuranceInput = this.questions.__zone_symbol__value[9];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 11) {
      this.insurancePurposeInput = this.questions.__zone_symbol__value[10];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 12) {
      this.ssnInput = this.questions.__zone_symbol__value[11];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    else if (questionId == 13) {
      debugger
      this.electronicConsentInput = this.questions.__zone_symbol__value[7];
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage });
    }
    // }

  }

  // _welcomeToPacLife(data) {
  //   debugger;
  //   let createJson = {
  //     insuranceType: "life-appflow",
  //     carrierName: "paclife",
  //     appStartStatus: true,
  //     appCompletedStatus: false,
  //     questionStartStatus: false,
  //     questionCompletedStatus: false,
  //     questionSequence: "0",
  //     applicationId: this.ApplicationID,
  //     otherAppflowScreen: "welcome-to-pac-life",
  //     resumeUrl: this.router.url
  //   }
  //   this.checkforResumeInit(createJson, "create");

  //   const idMappingJson = {
  //     client_id: localStorage.getItem('clientID'),
  //     customer_id: localStorage.getItem('customer_id'),
  //     email_id: localStorage.getItem('bubble-email'),
  //     status: "ApplicationStarted"
  //   };
  //   // const Helper = require('bit-uibl-data-driver');
  //   this.pc.createIdMapping(idMappingJson);

  //   if (data.component_id == "welcome-to-pac-life") {
  //     this.appdetails = true;
  //     this.welcomeToPacLife = false;
  //     localStorage.setItem('premilumDirtyFlag', 'false');

  //   } else {
  //     this.appdetails = false;
  //     this.welcomeToPacLife = true;
  //   }

  // }


  _confirmDetails(data) {

    let createJson = {
      insuranceType: "life-appflow",
      carrierName: "paclife",
      appStartStatus: true,
      appCompletedStatus: false,
      questionStartStatus: false,
      questionCompletedStatus: false,
      questionSequence: "0",
      applicationId: this.ApplicationID,
      otherAppflowScreen: "details-confirmation",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(createJson, "create");

    const idMappingJson = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email_id: localStorage.getItem('bubble-email'),
      status: "ApplicationStarted"
    };
    // const Helper = require('bit-uibl-data-driver');
    this.pc.createIdMapping(idMappingJson);

    if (data.component_id == "details-confirmation") {
      this.appdetails = false;
      this.questions = this.pc.getQue();
      console.log(this.questions);
      this.policyOwnerInput = this.questions.__zone_symbol__value[0];
      console.log("policyOwner", this.policyOwnerInput);
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.currentPage })
    }
    else {
      this.policyOwnerInput = undefined;
      this.appdetails = false;
    }
  }



  //First Question
  dataArray5 = [];
  PolicyOwnerAnswer: any;
  _policyOwnerOutput(data) {
    debugger;
    this.dataArray5 = [];
    let updateJson = {
      questionStartStatus: true,
      questionSequence: "1",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");

    if (data.component_id.includes(',')) {
      this.dataArray5 = data.component_id.split(',');
    } else {
      this.dataArray5[0] = data.component_id;
    }


    this.PolicyOwnerAnswer = data.answer;
    if (this.dataArray5[1] == "Goback") {
      if (data.afterEdit == true) {
      } else {
        this.policyOwnerInput = undefined;
        this.appdetails = true;
      }

    } else {
      if (data.afterEdit == true) {
        this.policyOwnerInput = undefined;
        this.electronicConsentInput = this.questions.__zone_symbol__value[0 + 1];

      } else {
        let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray5[0]);
        if (index >= 0) {
          this.policyOwnerInput = undefined;
          this.isActiveMilitaryInput = this.questions.__zone_symbol__value[index + 1];
          this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
          console.log(this.ssnInput);
        } else {
          alert("Oops something went wrong!!");
        }

      }


    }
  }



  //Second Question
  dataArray8 = [];
  SSNAnswer: any;
  _ssnoutput(data) {
    debugger
    this.dataArray8 = [];
    let updateJson = {
      questionSequence: "2",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");
    if (data.component_id.includes(',')) {
      this.dataArray8 = data.component_id.split(',');
    }
    else {
      this.dataArray8[0] = data.component_id;
    }

    this.SSNAnswer = data.answer;
    if (this.dataArray8[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray8[0]);
      if (index >= 0) {
        this.ssnInput = undefined;
        this.insurancePurposeInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray8[0]);
      if (index >= 0) {
        this.ssnInput = undefined;
        this.electronicConsentInput = this.questions.__zone_symbol__value[index];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }



    }

  }


  //Third Question
  dataArray = [];
  isActiveMilitaryAnswer: any;
  _isActiveMilitaryOuput(data) {

    this.dataArray = [];
    let updateJson = {
      questionSequence: "3",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");
    if (data.component_id.includes(',')) {
      this.dataArray = data.component_id.split(',');
    }
    else {
      this.dataArray[0] = data.component_id;
    }

    this.isActiveMilitaryAnswer = data.answer;
    if (this.dataArray[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray[0]);
      if (index >= 0) {
        this.isActiveMilitaryInput = undefined;
        this.policyOwnerInput = this.questions.__zone_symbol__value[index - 1];
      } else {
        alert("Oops something went wrong !!");
      }
    } else {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray[0]);
      if (index >= 0) {
        this.isActiveMilitaryInput = undefined;
        this.countryBornInput = this.questions.__zone_symbol__value[index + 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
      } else {
        alert("Oops something went wrong !!");
      }
    }

  }


  //Fourth question
  dataArray1 = [];
  countryBornAnswer: any;
  _countryBornOuput(data) {
    debugger
    this.dataArray1 = [];
    let updateJson = {
      questionSequence: "4",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");
    if (data.component_id != undefined) {
      if (data.component_id.includes(',')) {
        this.dataArray1 = data.component_id.split(',');
      }
      else {
        this.dataArray1[0] = data.component_id;
      }
    }
    else {
      this.dataArray1[0] = data;
    }


    this.countryBornAnswer = data.answer;
    if (this.dataArray1[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray1[0]);
      if (index >= 0) {
        this.countryBornInput = undefined;
        this.isActiveMilitaryInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray1[0]);
      if (index >= 0) {
        this.countryBornInput = undefined;
        this.isInsuredDependentInput = this.questions.__zone_symbol__value[index + 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    }
  }


  //Fifth Question
  dataArray9 = [];
  isInsuredDependentOutputAnswer: any;
  _isInsuredDependentOutput(data) {
    this.dataArray9 = [];
    let updateJson = {
      questionSequence: "5",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");
    if (data.component_id.includes(',')) {
      this.dataArray9 = data.component_id.split(',');
    }
    else {
      this.dataArray9[0] = data.component_id;
    }

    this.isInsuredDependentOutputAnswer = data.answer;
    if (this.dataArray9[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray9[0]);
      if (index >= 0) {
        this.isInsuredDependentInput = undefined;
        this.countryBornInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray9[0]);
      if (index >= 0) {
        this.isInsuredDependentInput = undefined;
        this.thirdPartyNotifiedInput = this.questions.__zone_symbol__value[index + 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    }

  }


  //Sixth Question
  dataArray10 = [];
  thirdPartyAnswer: any;
  _thirdPartyNotifiedOutput(data) {
    this.dataArray10 = [];
    let updateJson = {
      questionSequence: "6",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");

    this.dataArray10 = data.component_id.split(',');
    if (data.component_id.includes(',')) {
      this.dataArray10 = data.component_id.split(',');
    }
    else {
      this.dataArray10[0] = data.component_id;
    }
    this.thirdPartyAnswer = data.answer;
    if (this.dataArray10[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray10[0]);
      if (index >= 0) {
        this.thirdPartyNotifiedInput = undefined;
        this.isInsuredDependentInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray10[0]);
      if (index >= 0) {
        this.thirdPartyNotifiedInput = undefined;
        localStorage.setItem('editBeneficiaryInformation', 'false')
        this.addBeneficiariesInput = this.questions.__zone_symbol__value[index + 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
        console.log(this.ssnInput);
      } else {
        alert("Oops something went wrong!!");
      }
    }

  }


  //Seventh Question
  dataArray3 = [];
  addBeneficiariesAnswer: any;
  _addBeneficiariesOutput(data) {
    this.dataArray3 = [];
    let updateJson = {
      questionSequence: "7",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");
    if (data.component_id.includes(',')) {
      this.dataArray3 = data.component_id.split(',');
    }
    else {
      this.dataArray3[0] = data.component_id;
    }

    this.addBeneficiariesAnswer = data.answer;
    if (this.dataArray3[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray3[0]);
      if (index >= 0) {
        this.addBeneficiariesInput = undefined;
        this.thirdPartyNotifiedInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {

      if (data.afterEdit == true) {
        this.addBeneficiariesInput = undefined;
        this.electronicConsentInput = this.questions.__zone_symbol__value[0 + 1];

      } else {
        let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray3[0]);
        if (index >= 0) {
          this.addBeneficiariesInput = undefined;
          this.contingentBeneficiariesInput = this.questions.__zone_symbol__value[index + 1];
          this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
        } else {
          alert("Oops something went wrong!!");
        }
      }




    }
  }


  //Eight Question
  dataArray11 = [];
  contingentBeneficiariesyAnswer: any;
  _contingentBeneficiariesOutput(data) {
    this.dataArray11 = [];
    let updateJson = {
      questionSequence: "8",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");

    //this.dataArray11 = data.component_id.split(',');

    if (data.component_id.includes(',')) {
      this.dataArray11 = data.component_id.split(',');
    }
    else {
      this.dataArray11[0] = data.component_id;
    }

    this.contingentBeneficiariesyAnswer = data.answer;
    if (this.dataArray11[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray11[0]);
      if (index >= 0) {
        this.contingentBeneficiariesInput = undefined;
        localStorage.setItem('editBeneficiaryInformation', 'false')
        this.addBeneficiariesInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {
      if (data.afterEdit == true) {
        this.contingentBeneficiariesInput = undefined;
        this.electronicConsentInput = this.questions.__zone_symbol__value[0 + 1];

      } else {
        let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray11[0]);
        if (index >= 0) {
          this.contingentBeneficiariesInput = undefined;
          localStorage.setItem('editDrivingLicense', 'false');
          this.hasDriverLicenseInput = this.questions.__zone_symbol__value[index + 1];
          this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
          console.log(this.ssnInput);
        } else {
          alert("Oops something went wrong!!");
        }
      }


    }

  }


  //Ninth Question
  dataArray12 = [];
  hasDriverLicenseAnswer: any;
  _hasDriverLicenseOutput(data) {
    this.dataArray12 = [];
    let updateJson = {
      questionSequence: "9",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");

    debugger;


    if (data.component_id.includes(',')) {
      this.dataArray12 = data.component_id.split(',');
    }
    else {
      this.dataArray12[0] = data.component_id;
    }
    this.hasDriverLicenseAnswer = data.answer;
    if (this.dataArray12[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray12[0]);
      if (index >= 0) {
        this.hasDriverLicenseInput = undefined;
        this.contingentBeneficiariesInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {

      if (data.afterEdit == true) {
        this.hasDriverLicenseInput = undefined;
        this.electronicConsentInput = this.questions.__zone_symbol__value[0 + 1];
      } else {
        let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray12[0]);
        if (index >= 0) {
          this.hasDriverLicenseInput = undefined;
          localStorage.setItem('editPendingPolicyInformation', 'false');
          this.hasPendingApplicationsInput = this.questions.__zone_symbol__value[index + 1];
          this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
          console.log(this.ssnInput);
        } else {
          alert("Oops something went wrong!!");
        }
      }



    }

  }



  //Tenth Question
  dataArray13 = [];
  hasPendingApplicationsAnswer: any;
  _hasPendingApplicationsOutput(data) {
    this.dataArray13 = [];
    let updateJson = {
      questionSequence: "10",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");
    debugger;

    if (data.component_id.includes(',')) {
      this.dataArray13 = data.component_id.split(',');
    }
    else {
      this.dataArray13[0] = data.component_id;
    }
    this.hasPendingApplicationsAnswer = data.answer;
    if (this.dataArray13[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray13[0]);
      if (index >= 0) {
        this.hasPendingApplicationsInput = undefined;
        localStorage.setItem('editDrivingLicense', 'false');
        this.hasDriverLicenseInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {
      if (data.afterEdit == true) {
        this.hasPendingApplicationsInput = undefined;
        this.electronicConsentInput = this.questions.__zone_symbol__value[0 + 1];

      } else {
        let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray13[0]);
        if (index >= 0) {
          this.hasPendingApplicationsInput = undefined;
          localStorage.setItem('editExistingPolicyInformation', 'false');
          this.hasExistingLifeInsuranceInput = this.questions.__zone_symbol__value[index + 1];
          this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
          console.log(this.ssnInput);
        } else {
          alert("Oops something went wrong!!");
        }
      }
    }

  }




  //twelfth Question
  dataArray4 = [];
  inSurancePurposeAnswer: any;
  _insurancePurposeOutput(data) {
    debugger
    this.dataArray4 = [];
    let updateJson = {
      questionSequence: "12",
      questionCompletedStatus: true,
      otherAppflowScreen: "e-consent",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");
    if (data.component_id.includes(',')) {
      this.dataArray4 = data.component_id.split(',');
    }
    else {
      this.dataArray4[0] = data.component_id;
    }
    this.inSurancePurposeAnswer = data.answer;
    if (this.dataArray4[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray4[0]);
      if (index >= 0) {
        this.insurancePurposeInput = undefined;
        this.hasExistingLifeInsuranceInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {

      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray4[0]);
      if (index >= 0) {
        this.insurancePurposeInput = undefined;
        this.ssnInput = this.questions.__zone_symbol__value[index + 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
        console.log(this.ssnInput);
      } else {
        alert("Oops something went wrong!!");
      }
    }
  }





  ElectricConsentdataArray = [];
  ElectricConsentAnswer: any;
  _electronicConsentOutput(data) {

    this.ElectricConsentdataArray = [];
    let updateJson = {
      otherAppflowScreen: "e-consent",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");
    debugger;
    if (data.component_id.includes(',')) {
      this.ElectricConsentdataArray = data.component_id.split(',');
    }
    else {
      this.ElectricConsentdataArray[0] = data.component_id;
    }


    if (this.ElectricConsentdataArray[1] == "Edit") {

      if (this.ElectricConsentdataArray[0] == "DrivingLicense") {
        localStorage.setItem('editDrivingLicense', 'true');
        this.electronicConsentInput = undefined;
        this.hasDriverLicenseInput = this.questions.__zone_symbol__value[7 + 1];
      } else if (this.ElectricConsentdataArray[0] == "BeneficiaryInformation") {
        localStorage.setItem('editBeneficiaryInformation', 'true');
        this.electronicConsentInput = undefined;
        this.addBeneficiariesInput = this.questions.__zone_symbol__value[4 + 1];
      }
      else if (this.ElectricConsentdataArray[0] == "PendingPolicyInformation") {
        localStorage.setItem('editPendingPolicyInformation', 'true');
        this.electronicConsentInput = undefined;
        this.hasPendingApplicationsInput = this.questions.__zone_symbol__value[7 + 1];
      }
      else if (this.ElectricConsentdataArray[0] == "ExistingPolicyInformation") {
        localStorage.setItem('editExistingPolicyInformation', 'true');
        this.electronicConsentInput = undefined;
        this.hasExistingLifeInsuranceInput = this.questions.__zone_symbol__value[8 + 1];
      }
      else if (this.ElectricConsentdataArray[0] == "ContigentBeneficiaryInformation") {
        localStorage.setItem('editContigentBeneficiaryInformation', 'true');
        this.electronicConsentInput = undefined;
        this.contingentBeneficiariesInput = this.questions.__zone_symbol__value[5 + 1];
      }
      else {
        localStorage.setItem('editForPolicyOwner', 'true');
        this.electronicConsentInput = undefined;
        this.policyOwnerInput = this.questions.__zone_symbol__value[0];
      }

    } else if (this.ElectricConsentdataArray[1] == "Goback") {
      debugger
      localStorage.setItem('editForPolicyOwner', 'false');
      localStorage.setItem('editDrivingLicense', 'false');
      localStorage.setItem('editBeneficiaryInformation', 'false');
      localStorage.setItem('editContigentBeneficiaryInformation', 'false');
      localStorage.setItem('editExistingPolicyInformation', 'false');

      this.electronicConsentInput = undefined;
      this.ssnInput = this.questions.__zone_symbol__value[12 - 1];
    } else {
      debugger
      localStorage.setItem('applicationComplete', 'true');
      localStorage.setItem('editForPolicyOwner', 'false');
      localStorage.setItem('editDrivingLicense', 'false');
      localStorage.setItem('editBeneficiaryInformation', 'false');
      localStorage.setItem('editContigentBeneficiaryInformation', 'false');
      localStorage.setItem('editExistingPolicyInformation', 'false');
      this.electronicConsentInput = undefined;
      this.applicationComplete = true;

    }



    // if(data.component_id=="ElectronicConsent"){
    //   this.applicationComplete = true;
    //   this.electronicConsentInput=undefined
    // }
    // else{
    //   this.insurancePurposeInput = this.questions.__zone_symbol__value[12 - 1];
    //   this.electronicConsentInput=undefined
    // }

  }






  dataArray7 = [];
  InsuredInormationAnswer: any;
  _insuredInformationOutput(data) {
    this.dataArray7 = [];
    if (data.component_id.includes(',')) {
      this.dataArray7 = data.component_id.split(',');
    }
    else {
      this.dataArray7[0] = data.component_id;
    }

    this.InsuredInormationAnswer = data.answer;
    if (this.dataArray7[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray7[0]);
      if (index >= 0) {
        this.insuredDetailsInput = undefined;
        this.policyOwnerDetailsInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray7[0]);
      if (index >= 0) {
        this.insuredDetailsInput = undefined;
        this.ssnInput = this.questions.__zone_symbol__value[index + 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
        console.log(this.ssnInput);
      } else {
        alert("Oops something went wrong!!");
      }
    }
  }


  dataArray2 = [];
  isDependentAnswer: any;
  _isDependentOutput(data) {

    this.dataArray2 = [];
    if (data.component_id.includes(',')) {
      this.dataArray2 = data.component_id.split(',');
    }
    else {
      this.dataArray2[0] = data.component_id;
    }

    this.isDependentAnswer = data.answer;
    if (this.dataArray2[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray2[0]);
      if (index >= 0) {
        this.isDependentInput = undefined;
        this.countryBornInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray2[0]);
      if (index >= 0) {
        this.isDependentInput = undefined;
        localStorage.setItem('editBeneficiaryInformation', 'false');
        this.addBeneficiariesInput = this.questions.__zone_symbol__value[index + 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    }

  }
  dataArray6 = [];
  policyOwnerInformationAnswer: any;
  _policyOwnerInformationOutput(data) {
    this.dataArray6 = [];
    if (data.component_id.includes(',')) {
      this.dataArray6 = data.component_id.split(',');
    }
    else {
      this.dataArray6[0] = data.component_id;
    }
    this.policyOwnerInformationAnswer = data.answer;
    if (this.dataArray6[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray6[0]);
      if (index >= 0) {
        this.policyOwnerDetailsInput = undefined;
        this.policyOwnerInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray6[0]);
      if (index >= 0) {
        this.policyOwnerDetailsInput = undefined;
        this.insuredDetailsInput = this.questions.__zone_symbol__value[index + 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
        console.log(this.insuredDetailsInput);
      } else {
        alert("Oops something went wrong!!");
      }
    }
  }


  //Eleventh Question

  dataArray14 = [];
  hasExistingLifeInsuranceAnswer: any;
  _hasExistingLifeInsuranceOutput(data) {
    this.dataArray14 = [];
    let updateJson = {
      questionSequence: "11",
      resumeUrl: this.router.url
    }
    this.checkforResumeInit(updateJson, "update");
    debugger
    if (data.component_id.includes(',')) {
      this.dataArray14 = data.component_id.split(',');
    }
    else {
      this.dataArray14[0] = data.component_id;
    }
    this.hasExistingLifeInsuranceAnswer = data.answer;
    if (this.dataArray14[1] == "Goback") {
      let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray14[0]);
      if (index >= 0) {
        this.hasExistingLifeInsuranceInput = undefined;
        this.hasPendingApplicationsInput = this.questions.__zone_symbol__value[index - 1];
        this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --this.currentPage })
      } else {
        alert("Oops something went wrong!!");
      }
    } else {

      if (data.afterEdit == true) {
        this.hasExistingLifeInsuranceInput = undefined;
        this.electronicConsentInput = this.questions.__zone_symbol__value[0 + 1];
      } else {
        let index = this.questions.__zone_symbol__value.findIndex(obj => obj.component_id == this.dataArray14[0]);
        if (index >= 0) {
          this.hasExistingLifeInsuranceInput = undefined;
          this.insurancePurposeInput = this.questions.__zone_symbol__value[index + 1];
          this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++this.currentPage })
          console.log(this.ssnInput);
        } else {
          alert("Oops something went wrong!!");
        }
      }
    }
  }


  async checkforResumeInit(inputJson, type) {
    // console.log("checkforResumeInit");
    /* let createJson = {insuranceType : "life", //string: Type of insurance to know about the flow
           carrierName : "paclife", //string: carrierName to fetch carrier specific questions and answers to populate
           appStartStatus : true, //boolean: Whether the Appflow has been initiated or not
           appCompletedStatus : false, //boolean: Whether the Appflow has been completed or not
           questionStartStatus : false, //boolean: If the resume has to be done on Questions
           questionCompletedStatus : false, //boolean: If all the questions are completed Questions
           questionSequence : "0", //number: Question number sequence to be displayed from the question json
           applicationId : this.ApplicationID, //string: Application Id for that specific appflow
           otherAppflowScreen : "welcome-to-pac-life", //string: Resume on Component other than the question component
           resumeUrl: this.router.url// string: Specific url to resume
    } */
    let clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
    /* await this.pc.checkforResumeInit({},"fetch");
    let updateJson = {
      questionStartStatus : true, //boolean: If the resume has to be done on Questions
      questionSequence : "1",
    }
    await this.pc.checkforResumeInit(updateJson, "update");
    await this.pc.checkforResumeInit({},"fetch"); */

    // console.log("checkforResumeInit appData: ", appData);
  }

  // ngAfterViewInit(): void {
  //   debugger
  //   document.body.addEventListener('mouseleave', (e) => { this.exitPopup(e); });
  // }

  exitPopup(e) {
    if (this.showNumber == 0 && e.clientY < -1) {
      document.getElementById('exiterrorModalwns').style.display = 'block';
    }
  }

  closePopup() {
    debugger;
    document.getElementById('exiterrorModalwns').style.display = 'none';
    this.showNumber = 1;
  }
}
