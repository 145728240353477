import { Component, OnInit } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { ProfileDataHandlerService } from 'src/app/shared/profile-data-handler.service';
import { StorageHandlerService } from "src/app/shared/storage-handler.service"
@Component({
  selector: 'app-quote-flow-v2',
  templateUrl: './quote-flow-v2.component.html',
  styleUrls: ['./quote-flow-v2.component.css']
})
export class QuoteFlowV2Component implements OnInit {
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  gender: any;
  age: any;
  lifeScoreData: any;
  allScores: any;
  insurancetype: any;
  question: number;
  progress: number = 1;
  sourcePageLink: any;

  mapLeadSource = [
    'Pacific Wholesale Mortgage',
    'Toner Group',
    'HomeSmart California',
    'Barnstable',
    'Ben Anderson 365',
    'PRMG',
    'Briggs Freeman',
    'Flyhomes',
    'Homeward',
    'Low Rate Co.',
    'Olympic Media',
    'Royal United Mortgage',
    'United Wholesale Mortgage',
    'corporate_site',
    'HomePros',
    'TheAgency',
    'SearchLight'
  ]

  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.activatedroute.params.subscribe(params => {
      let storageHandlerService = new StorageHandlerService();
      let paramsLocal = storageHandlerService.set2StorageFromQueryParams(params);
      this.uniqueId = paramsLocal['uniqueId'];
      this.clientId = paramsLocal['clientId'];
      this.displayAddress = paramsLocal['displayAddress'];
      this.insurancetype = paramsLocal['insurancetype'];
      this.sourcePageLink = paramsLocal['sourcePageLink'];
      console.log(this.sourcePageLink, "sourcePageLink" );
      console.log(this.insurancetype, "insurancetype");
      try{
        // Sometimes URL is encoded with %25 when the url is encoded multiple times
        this.sourcePageLink = this.sourcePageLink.replace(/25/g, '').replace(/%20/g, ' ');
      } catch {
        // empty
      }
      localStorage.setItem("insuranceType", this.insurancetype);
      console.log( this.sourcePageLink, "this.sourcePageLink" );
      if( this.mapLeadSource.includes( this.sourcePageLink ) ) {
        if( this.sourcePageLink === 'corporate_site' ){
          localStorage.setItem( 'lead_source', 'Bubble' );
        } else {
          localStorage.setItem( 'lead_source', this.sourcePageLink );
        }
      }
      else{
        localStorage.setItem( 'lead_source', this.sourcePageLink );
      }
      // 2ct217-Short-Hills-NJ-07078/2ct217/Short%20Hills%20NJ%2007078%20/life/Next%20Term%20Life
      // const profileDataHandlerService = new ProfileDataHandlerService();
      // const cleanedoutputJson = profileDataHandlerService.getSetCityZipStateFromURL(this.displayAddress);
      // this.displayAddress = cleanedoutputJson.displayAddress;

      this.displayAddress = this.displayAddress.replace('-USA', '');
      this.displayAddress = this.displayAddress.replace(/-/g, ' ');
      this.displayAddress = this.displayAddress.replace(/%20/g, ' ');
      this.displayAddress = this.displayAddress.replace(/  /g, ' ');
      this.displayAddress = this.displayAddress.trim();
      console.log("uniqueId : ", this.uniqueId);
      console.log("clientId : ", this.clientId);
      console.log("displayAddress : ", this.displayAddress);
      console.log("insurancetype : ", this.insurancetype);
    }
    )
    this.question = 1;

    setInterval(() => {
      this.progress = this.progress % 14 + 1;
      //console.log(this.progress, "progress")
    }, 1000);
    document.getElementById("index_title_id").innerText = "Life Insurance powered by Bubble";
  }


}
