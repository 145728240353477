import { Component, OnInit } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { response } from 'express';
import { environment } from './../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from './../../components/lifescore-error-alert/lifescore-error-alert.component';
import { TrackerService } from 'src/app/shared/tracker.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
const pc = require('bit-uibl-data-driver');

@Component({
  selector: 'app-select-home-carrierv2',
  templateUrl: './select-home-carrierv2.component.html',
  styleUrls: ['./select-home-carrierv2.component.css']
})
export class SelectHomeCarrierv2Component implements OnInit {

  loader: boolean;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  gender: any;
  age: any;
  lifeScoreData: any;
  allScores: any;
  insurancetype: any;
  deductible: any;
  replacementCost: any;
  swquote: any[];
  sw_essentials: any;
  sw_bestvalue: any;
  sw_expanded: any;

  selectedQuote: any;
  inputAddress: any;
  ci_address: string;
  ci_city: string;
  ci_state: string;
  ci_zip: string;
  selectedCard: number;
  imgwid: string;
  carrierName: string;
  carrierAmount: string;
  disableDiscount: number;
  previous_economy: any;
  previous_best: any;
  previous_expanded: any;
  showModal = false;
  modalImg: any;
  rightcardselected: number;
  modalLabel: any;
  modalText: any;
  quoteLength: any;
  errorMessage: any;
  selectedCarrierHead: string;
  disableBackButton: boolean;
  dwellingCoverage: any;
  homeURL: string;
  spinner: boolean;
  latest_premium: any;
  showpopup: boolean;
  showNumber: number;
  time: any;
  showCardOne: boolean;
  spinner2: boolean;
  showCardTwo: boolean;
  showCardThree: boolean;
  econsentvalue: any;
  parteners: any;
  firstname: any;
  lastname: any;
  email: any;
  phoneno: any;
  dyamicontent: string;
  constructor(private $gaService: GoogleAnalyticsService, private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private modalService: NgbModal, private tracker: TrackerService,
    private activatedroute: ActivatedRoute, private utilityService: UtilityFunctionsService) { }

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    lazyLoad: false,
    margin: 30,
    autoWidth: true,
    smartSpeed: 1200,
    autoplay: true,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };
  customOptions1: OwlOptions = {
    loop: true,
    dots: false,
    nav: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    lazyLoad: false,
    margin: 30,
    autoWidth: true,
    smartSpeed: 1200,
    autoplay: true,

    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    }
  };

  ngOnInit(): void {
    debugger;
    this.parteners = 1;
    this.econsentvalue = 'Yes';
    let hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      consent_to_contact: true
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.showNumber = 0;
    this.loader = true;
    this.homeURL = this.pc.envCred.url;
    ///////////////// GA pageview Implementation starts
    // import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
    // import { GoogleAnalyticsService } from 'ngx-google-analytics';
    //, private $gaService: GoogleAnalyticsService, private utilityService: UtilityFunctionsService
    let path, titleOfPage;
    path = 'SelectHomeCarrier';
    titleOfPage = 'SelectHomeCarrier';
    const subdomainDomain = this.utilityService.getSubdomainDomain(window.location.href);
    this.utilityService.setUTMInPageview(this.$gaService, path, titleOfPage, subdomainDomain);
    console.log('Done with this.utilityService.setUTMInPageview');
    ///////////////// GA pageview Implementation Ends

    this.activatedroute.params.subscribe(params => {

      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.pc.fullAddress = this.displayAddress;
      this.insurancetype = params['insurancetype'];
      localStorage.setItem('insuranceType', this.insurancetype);
      this.inputAddress = this.uniqueId.split('-');
      this.inputAddress.shift();
      this.inputAddress = this.inputAddress.join(' ');
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
    }
    );
    this.pc.hubSpotSyncAfterEmailFetch({
      email: localStorage.getItem('bubble-email'),
      user_landed_reco_page: 'Yes',
      application_stage: 'Lead'
    });
    console.log('envCred : ', this.pc.envCred);
    this.deductible = '$2,000';
    this.replacementCost = ' $393,750';
    this.selectedCard = 0;
    this.carrierName = '';
    this.carrierAmount = '';
    this.imgwid = '60px';
    this.disableDiscount = 0;
    this.pc.parseAddressLocalAndPatch(this.inputAddress, this.clientId).then(
      data => {
        this.ci_address = data.door_number + ' ' + data.street,// + " " + data.street_type;
          this.ci_city = data.city_name;
        this.ci_state = data.state_code;
        this.ci_zip = data.zip;

        let maxReplacementCost = 0;
        if (this.ci_state == 'CA') {
          maxReplacementCost = 10;
        }
        else {
          maxReplacementCost = 10;
        }

        this.replacementCost = localStorage.getItem('dwellingResult');
        this.replacementCost = this.replacementCost == 'No Data' || this.replacementCost == null ? maxReplacementCost : this.replacementCost;

        this.pc.getAppData(this.clientId).then(data => {
          const dataFrom = JSON.parse(data.Payload);
          this.inputAddress = dataFrom.body.formatted_address == undefined ? this.inputAddress : dataFrom.body.formatted_address;
          console.log('getAppData : ', dataFrom);
          if (localStorage.getItem('commonInput') === null) {
            localStorage.setItem('commonInput', JSON.stringify(dataFrom.body.commonInput));
            localStorage.setItem('propertyDetails', JSON.stringify(dataFrom.body.stillwater.propertyDetails));
          }
          const commonInput = JSON.parse(localStorage.getItem('commonInput'));
          console.log('Resume CommonInput 1', JSON.parse(localStorage.getItem('commonInput')));
          if (!commonInput['email']) {
            console.log('Resume CommonInput no email', JSON.parse(localStorage.getItem('commonInput')));
            commonInput['email'] = dataFrom.body.bubble_email;
            commonInput['phone'] = dataFrom.body.bubble_phone;
            localStorage.setItem('commonInput', JSON.stringify(commonInput));
            if (localStorage.getItem('propertyDetails') === null) {
              localStorage.setItem('propertyDetails', JSON.stringify(dataFrom.body.stillwater.propertyDetails));
            }
          }
          console.log('Resume CommonInput 2', JSON.parse(localStorage.getItem('commonInput')));
          this.getSwQuote();
        }).catch(error => {
          console.log(error);
        });

      }
    ).catch(error => console.log('parseAddressLocal error', error));
    // this.selectcarrier('0');
    // this.tracker.changeProgressStatus({ totalPages: 4, currentPage: 2 })

    debugger;
    this.getSwQuote();
  }



  ngAfterViewInit(): void {
    document.body.addEventListener('mouseleave', (e) => { this.exitPopup(e); });
  }

  exitPopup(e) {
    if (this.showNumber == 0 && e.clientY <= 0 && !this.loader && !this.spinner) {
      document.getElementById('exiterrorModalHome').style.display = 'block';
    }
  }

  closePopup() {
    debugger;
    document.getElementById('exiterrorModalHome').style.display = 'none';
    this.showNumber = 1;
  }

  closetab() {
    debugger;
    window.open('https://stage.getmybubble.co/', '_self');
  }

  idlePopup(e) {
    debugger;
    clearTimeout(this.time);
    this.time = setTimeout(() => { this.exitPopup(e); }, 15000);

  }
  sw: any;
  getSwQuote() {
    debugger;
    this.loader = true;
    this.disableBackButton = true;
    const quickquote = new pc.Quotes(this.uniqueId);
    // let commonInput = {
    //   address: this.ci_address,
    //   addressType: "PhysicalRisk",
    //   city: this.ci_city,
    //   coverage: this.replacementCost,
    //   dob: "01/01/2000",
    //   firstName: "Anonymous",
    //   lastName: "Lastname",
    //   middleName: "User",
    //   premiumFrequency: "m",
    //   state: this.ci_state,
    //   zip: this.ci_zip,
    //   replacementCost: this.replacementCost,
    //   yearbuilt: localStorage.getItem("sw_yearBuilt"),
    //   sw_propertyDetails:JSON.parse(localStorage.getItem("propertyDetails")),
    //   sw_discounts : [],//[4965,61, 4200, 4255, 4305, 9099],
    //   sw_appQuestions: [], //
    //   ca_builder: null,
    //   propertyDetails:''
    // }

    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['firstName'] = localStorage.getItem('bubble-fname');
    commonInput['lastName'] = localStorage.getItem('bubble-lname');
    if (commonInput['propertyDetails'] == undefined || commonInput['propertyDetails'] == null || commonInput['propertyDetails'] == '') {
      commonInput['propertyDetails'] = JSON.parse(localStorage.getItem('propertyDetails'));
    }

    this.disableBackButton = commonInput['disableBack'];
    console.log('disableBackButton:', this.disableBackButton);
    commonInput['coverage'] = '10';
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    console.log('getStillWaterQuote commonInput:', commonInput);

    quickquote.getStillWaterQuote(commonInput, false, true)
      .then(response => {
        debugger;
        console.log('output SWquote call1: ', response);
        if (response[0].hasError && response[0].hasError == true) {
          this.loader = false;
          this.sw = response;
          const msgwc = response[0].errorMessage.split(':');
          this.errorMessage = msgwc[1];
          console.log('errorMessage 1', this.errorMessage);
          this.openModal();
          return;
        }
        this.swquote = response;
        this.sw_essentials = response.filter(item => item.carrierId == 'economy')[0];
        this.swquote.push(this.sw_essentials);
        this.swquote.push(this.sw_essentials);
        this.swquote[1].carrierId = 'best';
        this.swquote[2].carrierId = 'expanded';
        this.selectedQuote = this.sw_essentials;
        this.showCardOne = true;
        this.showCardTwo = false;
        this.showCardThree = false;
        this.loader = false;
        this.spinner2 = true;


        quickquote.getStillWaterQuote(commonInput)
          .then(response => {

            console.log('output SWquote: ', response);
            if (response[0].hasError && response[0].hasError == true) {
              this.spinner2 = false;
              this.sw = response;

              // this.errorMessage = response[0].errorMessage;
              const msgwc = response[0].errorMessage.split(':');
              this.errorMessage = msgwc[1];
              console.log('errorMessage 1', this.errorMessage);
              this.openModal();
              return;
            }
            this.swquote = response;
            this.sw = response;
            this.dwellingCoverage = response[1].body.other_amounts.dwelling.text;
            this.replacementCost = response[1].replacementCost;
            this.quoteLength = this.swquote.length;
            if (this.quoteLength == 1) {
              this.quoteLength = 'one';
            }
            else if (this.quoteLength == 2) {
              this.quoteLength = 'two';
            }
            else {
              this.quoteLength = 'three';
            }

            for (let i = 0; i < this.swquote.length; i++) {
              if (this.swquote[i].planDetails.coverages.hcb.includes('$') && this.swquote[i].planDetails.coverages.id_protection.includes('$')) {
                this.swquote[i].highlightedcoverage = 'Home Cyber Protection';
                this.swquote[i].highlightedcoverageTwo = `${this.swquote[i].planDetails.coverages.id_protection_help_text.title}`;
              }
              else if (this.swquote[i].planDetails.coverages.id_protection.includes('$')) {
                this.swquote[i].highlightedcoverage = `${this.swquote[i].planDetails.coverages.id_protection_help_text.title}`;
              }
              else if (this.swquote[i].planDetails.coverages.hcb.includes('$')) {
                this.swquote[i].highlightedcoverage = 'Home Cyber Protection';
              }
            }

            this.sw_essentials = response.filter(item => item.carrierId == 'economy')[0];
            this.sw_bestvalue = response.filter(item => item.carrierId == 'best')[0];

            if (this.swquote.length == 3) {
              this.sw_expanded = response.filter(item => item.carrierId == 'expanded')[0];
            }
            else {
              this.previous_expanded = 0;
            }
            console.log('sw_essentials', this.sw_essentials);
            console.log('sw_bestvalue', this.sw_bestvalue);
            this.selectedQuote = this.sw_bestvalue;
            this.showCardTwo = true;
            this.showCardThree = true;
            //this.selectedQuote = "";
            this.selectedCarrierHead = '';
            if (localStorage.getItem('applyDiscount') == '0' || localStorage.getItem('applyDiscount') == null || localStorage.getItem('applyDiscount') == undefined || localStorage.getItem('applyDiscount') == '') {
              localStorage.setItem('applyDiscount', '0');
              this.disableDiscount = 0;
              //
              this.previous_economy = this.swquote[0].head.quote_amount;
              this.previous_best = this.swquote[1].head.quote_amount;

              localStorage.setItem('previous_economy', this.swquote[0].head.quote_amount);
              localStorage.setItem('previous_best', this.swquote[1].head.quote_amount);

              if (this.swquote.length == 3) {
                this.previous_expanded = this.swquote[2].head.quote_amount;
                localStorage.setItem('previous_expanded', this.previous_expanded);
              }
            }
            else {
              this.previous_economy = localStorage.getItem('previous_economy');
              this.previous_best = localStorage.getItem('previous_best');
              this.swquote[0]['previous_value'] = this.previous_economy;
              this.swquote[1]['previous_value'] = this.previous_best;
              if (this.swquote.length == 3) {
                this.previous_expanded = localStorage.getItem('previous_expanded');
                this.swquote[2]['previous_value'] = this.previous_expanded;
              }
              localStorage.setItem('applyDiscount', '1');
              this.disableDiscount = 1;
            }
            this.carrierAmount = '$' + this.selectedQuote.head.quote_amount;
            if (this.swquote[0].head.quote_amount.includes('00.00') || this.swquote[1].head.quote_amount.includes('00.00') || this.swquote[2].head.quote_amount.includes('00.00')) {
              this.errorMessage = 'Our servers are taking too long to respond!';
              this.openServerErrorModal();
            }
            let url;
            if (window && window.location && window.location.href) {
              url = window.location.href;
            }
            const dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
            let carrierLocal = this.selectedQuote.head.home_insurance_carrier;

            if (!carrierLocal && this.selectedQuote &&
              this.selectedQuote.planDetails && this.selectedQuote.planDetails.text &&
              this.selectedQuote.planDetails.text.includes('Stillwater')) carrierLocal = 'Stillwater';

            const hubSpotData = {

              client_id: localStorage.getItem('clientID'),
              email: localStorage.getItem('bubble-email'),
              // application_stage: "Quote",
              home_insurance_carrier: carrierLocal,
              home_quote_amount: this.carrierAmount,
              home_latest_premium_amount: this.carrierAmount,
              dropoff_url: dropoffURL
            };
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

            this.spinner2 = false;
            this.rightcardselected = 1;
          })
          .catch(error => {
            console.log('SW Quote Error 1:', error);
            this.spinner2 = false;
            this.errorMessage = 'Our Servers are taking too long to respond.';
            this.openServerErrorModal();
            return;
          });


      })
      .catch(error => {
        console.log('SW Quote Error 1:', error);
        this.loader = false;
        this.errorMessage = 'Our Servers are taking too long to respond.';
        this.openServerErrorModal();
        return;
      }
      );


  }

  assignModalContent(img, details) {
    this.modalImg = img;
    this.modalLabel = details.title;
    this.modalText = details.text;
    this.modalText = this.modalText.replace(/\n/g, '<br/>');

  }

  assignModalContent1(details) {
    debugger;
    this.modalLabel = details.title;
    this.modalText = details.text;
    this.modalText = this.modalText.replace(/\n/g, '<br/>');
  }


  selectcarrier(sw) {
    this.selectedQuote = sw;
    this.selectedCarrierHead = this.selectedQuote.head.carrier_name;
    this.carrierName = this.selectedQuote.head.carrier_name;
    this.carrierAmount = '$' + this.selectedQuote.head.quote_amount;
    this.replacementCost = this.selectedQuote.replacementCost;
    this.dwellingCoverage = this.selectedQuote.body.other_amounts.dwelling.text;
    this.selectedCard = 1;
    this.rightcardselected = 1;
    let url;
    if (window && window.location && window.location.href) {
      url = window.location.href;
    }
    const dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
    let carrierLocal = this.selectedQuote.head.home_insurance_carrier;

    if (!carrierLocal && this.selectedQuote &&
      this.selectedQuote.planDetails && this.selectedQuote.planDetails.text &&
      this.selectedQuote.planDetails.text.includes('Stillwater')) carrierLocal = 'Stillwater';

    const hubSpotData = {

      client_id: localStorage.getItem('clientID'),
      email: localStorage.getItem('bubble-email'),
      // application_stage: "Quote",
      home_insurance_carrier: carrierLocal,
      home_quote_amount: this.carrierAmount,
      home_latest_premium_amount: this.carrierAmount,
      dropoff_url: dropoffURL
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

  }


  bluronhow() {
    document.getElementById('we_are_sorry').style.display = 'block';
    //document.getElementById('headmakeblur').style.filter = "blur(10px)";
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
  }

  bluroffhow() {
    document.getElementById('we_are_sorry').style.display = 'none';
    //document.getElementById('headmakeblur').style.filter = "none";
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }


  modaltwoopen() {
    //document.getElementById('headmakeblur').style.filter = "blur(10px)";
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
    document.getElementById('selected_plan_details').style.display = 'block';
    document.getElementById('mod_body').style.height = '85vh';
    document.getElementById('mod_body').style.overflowY = 'auto';
    document.getElementById('mod_body').style.position = 'unset';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }


  modaltwoclose() {
    //document.getElementById('headmakeblur').style.filter = "none";
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';
    document.getElementById('selected_plan_details').style.display = 'none';
    document.getElementById('mod_body').style.overflowY = 'hidden';
    document.getElementById('mod_body').style.position = 'fixed';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }

  openDiscountModal() {

    // document.getElementById('makeblur').style.filter = "blur(10px)";
    // document.getElementById('makeblur').style.overflowY = 'hidden';
    // document.getElementById('makeblur').style.position = 'fixed';
    document.getElementById('exiterrorModalHome').style.display = 'none';
    document.getElementById('mainDiv').style.filter = 'blur(15px)';
    document.getElementById('discount_modal').style.display = 'block';
    document.getElementById('dis_mod_body').style.height = '90vh';
    document.getElementById('dis_mod_body').style.overflowY = 'auto';
    document.getElementById('dis_mod_body').style.position = 'unset';
    this.showNumber = 1;

  }



  //tobe triggered from child

  triggerParentFunc(even) {
    debugger;
    // document.getElementById('makeblur').style.filter = "none";
    // document.getElementById('makeblur').style.overflowY = 'hidden';
    // document.getElementById('makeblur').style.position = 'unset';
    this.showNumber = 0;
    document.getElementById('discount_modal').style.display = 'none';
    document.getElementById('dis_mod_body').style.overflowY = 'hidden';
    document.getElementById('dis_mod_body').style.position = 'fixed';
    this.loader = true;
    if (even.type == 1) {

      // let commonInput = {
      //   address: this.ci_address,
      //   addressType: "PhysicalRisk",
      //   city: this.ci_city,
      //   coverage: this.replacementCost,
      //   dob: "01/01/2000",
      //   firstName: "Anonymous",
      //   lastName: "Lastname",
      //   middleName: "User",
      //   premiumFrequency: "m",
      //   state: this.ci_state,
      //   zip: this.ci_zip,
      //   replacementCost: this.replacementCost,
      //   yearbuilt: localStorage.getItem("sw_yearBuilt"),
      //   sw_propertyDetails:JSON.parse(localStorage.getItem("propertyDetails")),
      //   sw_discounts : JSON.parse(even.selectedDiscounts),//[4965,61, 4200, 4255, 4305, 9099],
      //   sw_appQuestions: [], //
      //   ca_builder: even.builderId
      // }

      const dicountsSelected = JSON.parse(even.selectedDiscounts);
      if (dicountsSelected.length == 0 && this.disableDiscount == 1) {
        const quickquote = new pc.Quotes(this.uniqueId);
        const commonInput = JSON.parse(localStorage.getItem('commonInput'));
        commonInput['sw_discounts'] = JSON.parse(even.selectedDiscounts);
        commonInput['ca_builder'] = even.builderId;
        commonInput['certificateId'] = even.certificateId;
        commonInput['sprinklerId'] = even.sprinklerId;
        localStorage.setItem('commonInput', JSON.stringify(commonInput));
        console.log('getStillWaterQuote discount commonInput:', commonInput);
        console.log('discount SWquote input: ', commonInput);
        quickquote.getStillWaterQuote(commonInput)
          .then(response => {

            if (response[0].hasError && response[0].hasError == true) {
              this.loader = false;
              this.sw = response;


              // this.errorMessage = response[0].errorMessage;
              const msgwc = response[0].errorMessage.split(':');
              this.errorMessage = msgwc[1];
              console.log('errorMessage 2', this.errorMessage);
              this.openModal();
              return;
            }
            this.swquote = response;
            for (let i = 0; i < this.swquote.length; i++) {
              if (this.swquote[i].planDetails.coverages.hcb.includes('$') && this.swquote[i].planDetails.coverages.id_protection.includes('$')) {
                this.swquote[i].highlightedcoverage = 'Home Cyber Protection';
                this.swquote[i].highlightedcoverageTwo = `${this.swquote[i].planDetails.coverages.id_protection_help_text.title}`;
              }

              else if (this.swquote[i].planDetails.coverages.id_protection.includes('$')) {
                this.swquote[i].highlightedcoverage = `${this.swquote[i].planDetails.coverages.id_protection_help_text.title}`;
              }
              else if (this.swquote[i].planDetails.coverages.hcb.includes('$')) {
                this.swquote[i].highlightedcoverage = 'Home Cyber Protection';
              }
            }
            console.log('output SWquote: ', response);
            this.quoteLength = this.swquote.length;
            this.latest_premium = response[1].head['quote_amount'];
            const hubSpotData = {
              client_id: localStorage.getItem('clientID'),
              email: localStorage.getItem('bubble-email'),
              application_stage: 'Quote',
              home_latest_premium_amount: this.latest_premium
            };
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

            if (this.quoteLength == 1) {
              this.quoteLength = 'one';
            }
            else if (this.quoteLength == 2) {
              this.quoteLength = 'two';
            }
            else {
              this.quoteLength = 'three';
            }
            this.swquote = response;

            if (this.selectedQuote != '') {
              this.selectedQuote = response.filter(item => item.carrierId == this.selectedQuote.carrierId)[0];
              this.selectedCarrierHead = this.selectedQuote.head.carrier_name;
              this.rightcardselected = 1;
            }
            else {
              this.rightcardselected = 0;
            }


            this.previous_economy = localStorage.getItem('previous_economy');
            this.previous_best = localStorage.getItem('previous_best');
            this.swquote[0]['previous_value'] = this.previous_economy;
            this.swquote[1]['previous_value'] = this.previous_best;
            if (this.swquote.length == 3) {
              this.previous_expanded = localStorage.getItem('previous_expanded');
              this.swquote[2]['previous_value'] = this.previous_expanded;
            }
            localStorage.setItem('applyDiscount', '0');
            if (this.swquote[0].head.quote_amount.includes('00.00') || this.swquote[1].head.quote_amount.includes('00.00') || this.swquote[2].head.quote_amount.includes('00.00')) {
              this.errorMessage = 'Our servers are taking too long to respond!';
              this.openServerErrorModal();
            }

            this.loader = false;
            this.disableDiscount = 0;
            //this.saveDiscount();
          })
          .catch(error => { console.log('SW Quote Error 2:', error); });
      }
      else if (dicountsSelected.length != 0) {
        const quickquote = new pc.Quotes(this.uniqueId);
        const commonInput = JSON.parse(localStorage.getItem('commonInput'));
        commonInput['sw_discounts'] = JSON.parse(even.selectedDiscounts);
        commonInput['ca_builder'] = even.builderId;
        commonInput['certificateId'] = even.certificateId;
        commonInput['sprinklerId'] = even.sprinklerId;
        localStorage.setItem('commonInput', JSON.stringify(commonInput));
        console.log('getStillWaterQuote discount commonInput:', commonInput);
        console.log('discount SWquote input: ', commonInput);
        quickquote.getStillWaterQuote(commonInput)
          .then(response => {

            if (response[0].hasError && response[0].hasError == true) {
              this.loader = false;
              this.sw = response;



              // this.errorMessage = response[0].errorMessage;
              const msgwc = response[0].errorMessage.split(':');
              this.errorMessage = msgwc[1];
              console.log('errorMessage 3', this.errorMessage);
              this.openModal();
              return;
            }
            this.swquote = response;
            for (let i = 0; i < this.swquote.length; i++) {
              if (this.swquote[i].planDetails.coverages.hcb.includes('$') && this.swquote[i].planDetails.coverages.id_protection.includes('$')) {
                this.swquote[i].highlightedcoverage = 'Home Cyber Protection';
                this.swquote[i].highlightedcoverageTwo = `${this.swquote[i].planDetails.coverages.id_protection_help_text.title}`;
              }

              else if (this.swquote[i].planDetails.coverages.id_protection.includes('$')) {
                this.swquote[i].highlightedcoverage = `${this.swquote[i].planDetails.coverages.id_protection_help_text.title}`;
              }
              else if (this.swquote[i].planDetails.coverages.hcb.includes('$')) {
                this.swquote[i].highlightedcoverage = 'Home Cyber Protection';
              }
            }
            this.latest_premium = response[1].head['quote_amount'];
            this.quoteLength = this.swquote.length;
            if (this.quoteLength == 1) {
              this.quoteLength = 'one';
            }
            else if (this.quoteLength == 2) {
              this.quoteLength = 'two';
            }
            else {
              this.quoteLength = 'three';
            }
            this.swquote = response;

            if (this.selectedQuote != '') {
              this.selectedQuote = response.filter(item => item.carrierId == this.selectedQuote.carrierId)[0];
              this.selectedCarrierHead = this.selectedQuote.head.carrier_name;
              this.rightcardselected = 1;
            }
            else {
              this.rightcardselected = 0;
            }

            const hubSpotData = {
              client_id: localStorage.getItem('clientID'),
              email: localStorage.getItem('bubble-email'),
              application_stage: 'Quote',
              home_latest_premium_amount: this.latest_premium
            };
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
            this.previous_economy = localStorage.getItem('previous_economy');
            this.previous_best = localStorage.getItem('previous_best');
            this.swquote[0]['previous_value'] = this.previous_economy;
            this.swquote[1]['previous_value'] = this.previous_best;
            if (this.swquote.length == 3) {
              this.previous_expanded = localStorage.getItem('previous_expanded');
              this.swquote[2]['previous_value'] = this.previous_expanded;
            }

            localStorage.setItem('applyDiscount', '1');
            if (this.swquote[0].head.quote_amount.includes('00.00') || this.swquote[1].head.quote_amount.includes('00.00') || this.swquote[2].head.quote_amount.includes('00.00')) {
              this.errorMessage = 'Our servers are taking too long to respond!';
              this.openServerErrorModal();
            }
            this.loader = false;
            this.disableDiscount = 1;
            //this.saveDiscount();
          })
          .catch(error => { console.log('SW Quote Error 3:', error); });
      }
      else {
        this.closeDiscountModal();
      }


    }
    else {
      this.closeDiscountModal();
    }


  }

  closeDiscountModal() {
    // document.getElementById('makeblur').style.filter = "none";
    // document.getElementById('makeblur').style.overflowY = 'hidden';
    // document.getElementById('makeblur').style.position = 'unset';
    document.getElementById('discount_modal').style.display = 'none';
    document.getElementById('dis_mod_body').style.overflowY = 'hidden';
    document.getElementById('dis_mod_body').style.position = 'fixed';
    this.loader = false;
  }
  saveDiscount() {
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
    document.getElementById('discount_modal').style.display = 'none';
    document.getElementById('dis_mod_body').style.overflowY = 'hidden';
    document.getElementById('dis_mod_body').style.position = 'fixed';
    document.getElementById('congrats_discount').style.display = 'block';
  }



  closeCongratesModal() {
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';
    document.getElementById('congrats_discount').style.display = 'none';

  }

  openModal() {
    if (this.errorMessage.includes('Aerial') || this.errorMessage.includes('aerial')) {
      this.errorMessage = 'Our Aerial imagery shows the condition of the roof is unacceptable.';
    }
    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: this.errorMessage
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    document.getElementById('main').style.filter = 'blur(10px)';
    document.getElementById('main').style.overflowY = 'hidden';
    document.getElementById('main').style.position = 'fixed';
    console.log(' open modal errorMessage', this.errorMessage);
    document.getElementById('errorModal').style.display = 'block';
    document.getElementById('error_modal_text').innerText = this.errorMessage;
    this.showNumber = 1;
    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //     // keyboard: false,
    //     // backdrop: 'static'
    //   });

    // let data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   //message: "Our Servers are taking too long to respond please come back later.",
    //   message: this.errorMessage,
    //   image: 'assets/images/images/education2.png'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   //redirect url
    //   window.location.href = environment.carrierErrorRedirectURL;
    //   console.log("Modal Close Output: ", result);
    // }, (reason) => {
    //   console.log("Modal Close Reason: ", reason);
    // });
  }

  closeModal() {
    document.getElementById('main').style.filter = 'none';
    document.getElementById('main').style.overflowY = 'auto';
    document.getElementById('main').style.position = 'unset';
    document.getElementById('errorModal').style.display = 'none';
    this.showNumber = 0;
    //window.location.href = environment.carrierErrorRedirectURL;
    window.location.href = this.pc.envCred.url;
  }





  openServerErrorModal() {
    debugger;


    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: this.errorMessage
    };
    debugger;
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    document.getElementById('main').style.filter = 'blur(10px)';
    document.getElementById('main').style.overflowY = 'hidden';
    document.getElementById('main').style.position = 'fixed';
    console.log(' open modal errorMessage', this.errorMessage);
    document.getElementById('errorServer').style.display = 'block';
    this.showNumber = 1;
    // document.getElementById('error_modal_text').innerText = this.errorMessage;
    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //     // keyboard: false,
    //     // backdrop: 'static'
    //   });

    // let data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   //message: "Our Servers are taking too long to respond please come back later.",
    //   message: this.errorMessage,
    //   image: 'assets/images/images/education2.png'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   //redirect url
    //   window.location.href = environment.carrierErrorRedirectURL;
    //   console.log("Modal Close Output: ", result);
    // }, (reason) => {
    //   console.log("Modal Close Reason: ", reason);
    // });
  }


  async invokeSelect() {
    if (this.showCardOne == true && this.showCardTwo == true && this.showCardThree == true) {
      return true;
    }
    else {
      this.invokeSelect();
    }
  }


  async selectPlan(sw) {
    debugger
    //await this.invokeSelect();
    debugger
    this.selectcarrier(sw);
    const json = {
      'id': this.clientId,
      'sw_totalPremium': sw.head.quote_amount
    };
    this.pc.saveUpdateClientData(json);
    this.$gaService.gtag('event', this.insurancetype,);
    this.$gaService.event('Select Plan', 'event', 'HomePlanSelect');

    // this.$gaService.gtag('event', this.insurancetype, {
    //   'event_category': "ButtonClicked", 'event_label': "HomePlanSelect"
    // });
    ///////////////// GA pageview Implementation starts
    // import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
    // import { GoogleAnalyticsService } from 'ngx-google-analytics';
    //, private $gaService: GoogleAnalyticsService, private utilityService: UtilityFunctionsService
    let path, titleOfPage;
    path = 'SelectHomeCarrier';
    titleOfPage = 'SelectHomeCarrier';
    const subdomainDomain = this.utilityService.getSubdomainDomain(window.location.href);
    // this.utilityService.setUTMInPageview(this.$gaService, path, titleOfPage, subdomainDomain, this.insurancetype,
    //   {
    //     'event_category': "ButtonClicked", 'event_label': "HomePlanSelect"
    //   });
    console.log('Done with this.utilityService.setUTMInPageview');
    ///////////////// GA pageview Implementation Ends
    debugger;
    this.spinner = true;
    //quote id to be retrieved from respone of selected quote
    // const quoteId = this.processCache.getQuoteId(this.clientId);
    const quoteId = this.selectedQuote.quoteId;
    console.log('homeQuoteId : ', quoteId);
    localStorage.setItem('quoteId', quoteId);
    const details = JSON.stringify(this.selectedQuote);
    localStorage.setItem('selectedHomeCarrier', details);
    let dwelleing = this.selectedQuote.body.other_amounts.dwelling.text;
    dwelleing = dwelleing.replace('$', '').replace(/,/g, '');
    console.log('dwelleing :', dwelleing);

    let replacementCost = this.selectedQuote.replacementCost;
    replacementCost = replacementCost.replace('$', '').replace(/,/g, '');
    console.log('replacementCost :', replacementCost);
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['replacementCost'] = replacementCost;
    commonInput['coverage'] = dwelleing;
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    localStorage.setItem('minDwellingCost', dwelleing);

    this.carrierAmount = '$' + this.selectedQuote.head.quote_amount;
    let url;
    if (window && window.location && window.location.href) {
      url = window.location.href;
    }
    const dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
    let carrierLocal = this.selectedQuote.head.home_insurance_carrier;

    if (!carrierLocal && this.selectedQuote &&
      this.selectedQuote.planDetails && this.selectedQuote.planDetails.text &&
      this.selectedQuote.planDetails.text.includes('Stillwater')) carrierLocal = 'Stillwater';
    const hubSpotData = {

      client_id: localStorage.getItem('clientID'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Quote',
      home_insurance_carrier: carrierLocal,
      home_quote_amount: this.carrierAmount,
      home_latest_premium_amount: this.carrierAmount,
      dropoff_url: dropoffURL
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.swSaveSelectedPlanType(this.selectedQuote.carrierId).then(data => {
      const clientIdLocal = localStorage.getItem('clientID');
      const pacificLifeQuoteId = this.pc.getQuoteId('');// pass emptystring to get -wer324 kind string
      /////// id mapping
      const idMappingJson = {
        client_id: clientIdLocal,
        email_id: localStorage.getItem('bubble-email'),
        paclife_quote_id: pacificLifeQuoteId,
        status: 'LifeQuote',
        dob: localStorage.getItem('bubble-dob'),
      };
      localStorage.setItem('paclife_quote_id', pacificLifeQuoteId);

      this.pc.createIdMapping(idMappingJson);
      // const Helper = require('bit-uibl-data-driver');
      // Helper.Lead.createIdMapping(idMappingJson).then((data) => {
      //   if (data) { console.log("Lead:createIdMapping Successful:data: ", data) }
      // });
      /////// id mapping ends
      let carrierLocal = this.selectedQuote.head.home_insurance_carrier;

      if (!carrierLocal && this.selectedQuote &&
        this.selectedQuote.planDetails && this.selectedQuote.planDetails.text &&
        this.selectedQuote.planDetails.text.includes('Stillwater')) carrierLocal = 'Stillwater';
      const hubSpotData = {

        email: localStorage.getItem('bubble-email'),
        home_insurance_carrier: carrierLocal,
        application_stage: 'Quote',
        home_quote_amount: '$' + this.selectedQuote.head.quote_amount,
        home_latest_premium_amount: '$' + this.selectedQuote.head.quote_amount
      };
      try {
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      } catch (error) {
        console.error('hubSpotSyncAfterEmailFetch:hubSpotData: ', hubSpotData, ' # error:', error);
      }
      if (this.insurancetype == 'homeLife') {

        location.href = 'QuoteFlowV2Life/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
      }
      if (this.insurancetype == 'home') {
        location.href = `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      }

    }).catch(error => {
      console.log(error);
    });


  }

  previousscreen() {
    this.$gaService.gtag('event', this.insurancetype, {
      'event_category': 'BackButton', 'event_label': 'Back'
    });
    location.href = `QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/previous`;
    //this.router.navigate(['/QuoteFlowV2', this.uniqueId, this.clientId,this.displayAddress,'life','previous',1]);
  }




  SelectedCard(even) {
    this.selectedCard = even;
  }


  tryAgain() {
    document.getElementById('main').style.filter = 'none';
    document.getElementById('main').style.overflowY = 'auto';
    document.getElementById('main').style.position = 'unset';
    document.getElementById('errorServer').style.display = 'none';
    this.showNumber = 0;
    location.reload();
  }

  gotoHome() {
    document.getElementById('main').style.filter = 'none';
    document.getElementById('main').style.overflowY = 'auto';
    document.getElementById('main').style.position = 'unset';
    document.getElementById('errorServer').style.display = 'none';
    window.location.href = this.pc.envCred.url;
  }



  public showdrawer() {
    this.showpopup = true;
    document.getElementById('selected_plan_details').style.display = 'block';
    document.getElementById('selected_plan_details').style.overflow = 'scroll';
    this.showNumber = 1;
  }

  public closedrawer() {
    debugger;
    document.getElementById('selected_plan_details').style.display = 'none';
    this.showpopup = false;
    this.showNumber = 0;
  }
  public changeconsent(even) {
    debugger
    if (even.target.checked == true) {
      //this.econsentvalue = 'Yes';
      let hubSpotData = {
        email: localStorage.getItem('bubble-email'),
        consent_to_contact: true
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    else {
      //  this.econsentvalue = 'No';
      let hubSpotData = {
        email: localStorage.getItem('bubble-email'),
        consent_to_contact: "false"
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
  }

  contacttohubspot() {
    debugger
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const emailtest = this.email;
    if (this.email == null || this.email == undefined || this.email == '') {
      this.dyamicontent = 'Please enter a valid email.';
      document.getElementById('dynamicpopup').style.display = 'block';
    }
    else if (reg.test(emailtest) == false) {
      this.dyamicontent = 'Please enter a valid email';
      document.getElementById('dynamicpopup').style.display = 'block';
    }
    else if (this.firstname == null || this.firstname == undefined || this.firstname == '') {
      this.dyamicontent = 'Please enter a valid first name';
      document.getElementById('dynamicpopup').style.display = 'block';
    }
    else if (this.lastname == null || this.lastname == undefined || this.lastname == '') {
      this.dyamicontent = 'Please enter a valid last name';
      document.getElementById('dynamicpopup').style.display = 'block';
    }
    else if (this.phoneno == undefined || this.phoneno == '' || this.phoneno == null) {
      this.dyamicontent = 'Please enter a valid phone number';
      document.getElementById('dynamicpopup').style.display = 'block';
    }
    else if (this.phoneno.toString().length < 12) {
      this.dyamicontent = 'Please enter a valid phone number';
      document.getElementById('dynamicpopup').style.display = 'block';
    }
    else {
      let clientId = this.utilityService.getNewRandomToken();
      const clientDataJson = {
        'id': clientId,
        'bubble_email': this.email,
        'email': this.email,
        'bubble_fname': this.firstname,
        'bubble_lname': this.lastname,
      };
      this.pc.saveUpdateClientData(clientDataJson).then(data => {
        debugger;
        let hubSpotData = {
          client_id: clientId,
          email: this.email,
          firstname: this.firstname,
          lastname: this.lastname,
          phone: this.phoneno,
        };
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
        this.dyamicontent = 'Thank you for submitting the details.';
        document.getElementById('contactus').style.display = 'none';
        document.getElementById('dynamicpopup').style.display = 'block';
        this.parteners = 1;
        this.email = '';
        this.firstname = '';
        this.lastname = '';
        this.phoneno = '';
      }).catch(error => {
        console.log(error);
      });
    }


  }

  selectPartners(selectedpartner) {
    this.parteners = selectedpartner;
    if (this.parteners != '1') {
      document.getElementById('contactus').style.display = 'block';
      this.showNumber = 1;
    }
    else {
      document.getElementById('contactus').style.display = 'none';
    }
  }

  closepartnerpopup() {
    document.getElementById('contactus').style.display = 'none';
    this.parteners = 1;
  }
  closedynamicpopoup() {
    document.getElementById('dynamicpopup').style.display = 'none';
  }

  phonelength() {

    debugger;

    let count = 0;
    this.phoneno = this.phoneno.replace(/^0+/, '');
    const phonestring = this.phoneno.toString().slice(0, this.phoneno.length);
    this.phoneno = phonestring.replace(/(\d{3})-?/g, function (m, a) {
      return ++count <= 2 ? a + '-' : m;
    });
    this.phoneno = this.phoneno.slice(0, 12);

  }
}
