import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackerService } from 'src/app/shared/tracker.service';
import { environment } from './../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../components/lifescore-error-alert/lifescore-error-alert.component';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-quote-flow-v2-home',
  templateUrl: './quote-flow-v2-home.component.html',
  styleUrls: ['./quote-flow-v2-home.component.css']
})
export class QuoteFlowV2HomeComponent implements OnInit {
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  gender: any;
  age: any;
  lifeScoreData: any;
  allScores: any;
  insurancetype: any;
  question: number;
  sourcePageLink: any;
  totalPages = 4;
  inputAddress: any;
  selectedCard: number;
  previous: any;
  loader: boolean;
  knockOut: any;
  kopopup1list = [
    'QUA0007', 'SWI0276', 'QUA0132', 'SWI0281', 'SWI0277', 'SWI0278',
    'QUA0124', 'QUA0139', 'QUA0142', 'QUA0134'
  ];
  kopopup2list = [
    'QUA0028', 'PUB0028'
  ];
  kopopup3list = [
    'QUA0154', 'QUA0159', 'QUA0169'
  ];
  mapLeadSource = [
    'Pacific Wholesale Mortgage',
    'Toner Group',
    'HomeSmart California',
    'Barnstable',
    'Ben Anderson 365',
    'PRMG',
    'Briggs Freeman',
    'Flyhomes',
    'Homeward',
    'Low Rate Co.',
    'Olympic Media',
    'Royal United Mortgage',
    'United Wholesale Mortgage',
    'corporate_site'
  ];

  public isMobileResolution: boolean;
  public isDescktopResolution: boolean;
  selectLife: string;
  displayCostPredicter: boolean;
  errorMessage: any;
  spinner: boolean;
  showpopup1: any;
  showpopup2: boolean;
  showpopup3: boolean;
  koerrormsg: any;

  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private tracker: TrackerService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService,
    public router: Router,
    private modalService: NgbModal,
    private utilityService: UtilityFunctionsService) { }


  ngOnInit(): void {
    debugger;
    // document.getElementById('konmodel').style.display = 'block';
    // this.showpopup1 = true
    // const error = 'QUA0028';
    // if (this.kopopup1list.includes(error)) {
    //   document.getElementById('konmodel').style.display = 'block';
    // }

    // debugger
    if (window.innerWidth < 600) {
      this.isMobileResolution = true;
      this.isDescktopResolution = false;
    } else {
      this.isMobileResolution = false;
      this.isDescktopResolution = true;
    }
    this.displayCostPredicter = true;


    console.log('QuoteFlowV2HomeComponent');
    console.log('envCred : ', this.pc.envCred);

    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      this.sourcePageLink = params['sourcePageLink'];
      localStorage.setItem('clientID', this.clientId);
      //this.previous = params['previous'];

      if (this.mapLeadSource.includes(this.sourcePageLink.replace(/%20/g, ''))) {
        if (this.sourcePageLink === 'corporate_site') {
          localStorage.setItem('lead_source', 'Bubble');
        } else {
          localStorage.setItem('lead_source', this.sourcePageLink);
        }
      }

      this.inputAddress = this.uniqueId.split('-');
      this.inputAddress.shift();
      this.inputAddress = this.inputAddress.join(' ');
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
    }

    );

    this.selectedCard = 0;
    if (this.sourcePageLink == 'previous' &&
      localStorage.getItem('knockout') == '1' &&
      localStorage.getItem('KOType') != '' &&
      localStorage.getItem('KOType') != undefined) {
      if (localStorage.getItem('KOType') == 'pp') {
        this.question = 1;
        localStorage.setItem('KOType', '');
        localStorage.setItem('knockout', '');
        localStorage.setItem('KOerrorMessage', '');
      }
      else if (localStorage.getItem('KOType') == 'ko') {
        localStorage.setItem('KOType', '');
        localStorage.setItem('knockout', '');
        localStorage.setItem('KOerrorMessage', '');
        this.question = 2;
      }
      else {
        this.question = 3;
      }
    }
    else if (this.sourcePageLink == 'previous' && localStorage.getItem('knockout') != '1') {
      this.question = 3;
    }
    else {
      this.question = 1;
    }
    //this.question = 1;

    if (localStorage.getItem('appFlowLinkResume') == '1') {
      localStorage.setItem('appFlowLinkResume', '0');
      this.checkforResumeInit({}, 'fetch').then(
        resumeData => {
          console.log('checkforResumeInit home-life-quote', resumeData);
          if (resumeData['insuranceType'] == 'home-life-quote' || resumeData['insuranceType'] == 'home-quote') {
            if (resumeData['otherAppflowScreen'] == 'home-question1') {
              this.question = 1;
            } else if (resumeData['otherAppflowScreen'] == 'home-question2') {
              this.question = 2;
            } else {
              this.question = 1;
            }
          } else {
            console.log('checkforResumeInit home-life-quote');
            this.question = 1;
          }
        }).catch(error => console.log(error));
    } else {
      console.log('checkforResumeInit home-life-quote else');
      let resumeinsurancetype;
      if (this.insurancetype === 'home') {
        resumeinsurancetype = 'home-appflow';
      }
      else if (this.insurancetype === 'life') {
        resumeinsurancetype = 'life-appflow';
      }
      else {
        resumeinsurancetype = 'home-life-quote';
      }
      const createJson = {
        insuranceType: resumeinsurancetype,
        carrierName: 'stillwater',
        appStartStatus: false,
        appCompletedStatus: false,
        questionStartStatus: false,
        questionCompletedStatus: false,
        questionSequence: '0',
        applicationId: '',
        otherAppflowScreen: 'home-question1',
        resumeUrl: this.router.url
      };
      this.checkforResumeInit(createJson, 'create');
    }

    // this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 3 })
    this.tracker.changeTrackingType(this.insurancetype);
    this.pc.getAppData(this.clientId).then(data => {
      debugger;
      const dataFrom = JSON.parse(data.Payload);
      this.inputAddress = dataFrom.body.formatted_address == undefined ? this.inputAddress : dataFrom.body.formatted_address;
      console.log('getAppData : ', dataFrom);
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });


  }

  bluronhow() {
    document.getElementById('are_u_sure').style.display = 'flex';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
  }

  bluroffhow1() {
    //this.loader = true;
    this.displayCostPredicter = false;
    this.selectLife = '1';
    this.displayCostPredicter = true;
    localStorage.setItem('insuranceType', 'homeLife');
    this.pc.callComponentMethod();
    document.getElementById('are_u_sure').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
    this.insurancetype = 'homeLife';
    localStorage.setItem('insuranceType', 'homeLife');
    this.question = this.question + 1;
    this.loader = false;
    const eventOptionsPart1 = {
      'path': 'quoteFlowV2',
      'page_title': 'quoteFlowV2', 'url': window.location.href
    };
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/"+this.insurancetype ;
    this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed with Home and Life', 'event', 'ProceedWithHomeLife');
    this.$gaService.event('Proceed with Home and Life', 'event', 'ProceedWithHomeLife');
  }
  bluroffhow2() {
    const eventOptionsPart1 = {
      'path': 'quoteFlowV2',
      'page_title': 'quoteFlowV2', 'url': window.location.href
    };
    //this.loader = true;
    this.displayCostPredicter = false;
    this.selectLife = '0';
    this.displayCostPredicter = true;
    document.getElementById('are_u_sure').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
    this.insurancetype = 'home';
    this.question = this.question + 1;
    this.loader = false;
    this.utilityService.eventWrapped(eventOptionsPart1, 'Proceed with just Home', 'event', 'ProceedWithHome');
    this.$gaService.event('Proceed with Home and Life', 'event', 'ProceedWithHomeLife');
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/"+this.insurancetype ;
  }

  nextQuestion(even) {
    debugger;

    this.StatusTrackerPercentage(even.questionNo);
    //knockout logic
    console.log('sent object : ', even);

    if (even.knockOut == 1) {
      location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
    } else if (this.question == 1 && this.insurancetype == 'home') {
      this.bluronhow();
    } else if (this.question == 3) {
      if (this.insurancetype == 'homeLife') {
        this.loader = true;
        const updateJson = {
          otherAppflowScreen: 'select-home-carrier',
          resumeUrl: 'select-home-carrierv2/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype
        };
        this.checkforResumeInit(updateJson, 'update').then(data => {
          //this.loader = false;
          location.href = 'select-home-carrierv2/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
          //location.href = "ContactInformation/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
          this.question = this.question + 1;
        }).catch(error => {
          console.log(error);
        });
      }
      else {
        //   location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
        // this.bluronhow();

        const updateJson1 = {
          otherAppflowScreen: 'select-home-carrier',
          resumeUrl: 'select-home-carrierv2/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype
        };
        this.checkforResumeInit(updateJson1, 'update').then(data => {
          //this.loader = false;
          location.href = 'SelectHomeCarrier/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
          //location.href = "ContactInformation/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
        }).catch(error => {
          console.log(error);
        });

        // let updateJson = {
        //   otherAppflowScreen: "select-home-carrier",
        //   resumeUrl: "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype
        // }
        // this.checkforResumeInit(updateJson, "update");




      }
    }
    else {
      this.question = this.question + 1;
    }


    const updateJson = {
      otherAppflowScreen: 'home-question2',
      resumeUrl: this.router.url
    };
    this.checkforResumeInit(updateJson, 'update');
    // this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.question });

  }

  StatusTrackerPercentage(question) {
    if (question == '1') {
      this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 5 });
    }
  }

  invokeHomeQuote() {
    debugger;
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    document.getElementById('konmodel').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
    if (commonInput['swquote_question'].includes(111)) {
      localStorage.setItem('KOType', 'ko');
      localStorage.setItem('KOerrorMessage', 'Bubble only insures full-time Primary residences.');
      location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
    }
    else {
      const eventOptionsPart1 = {
        'path': 'quoteFlowV2invokeHomeQuote',
        'page_title': 'quoteFlowV2invokeHomeQuote', 'url': window.location.href
      };
      //alert("home quote invoked;");
      this.spinner = true;
      const commonInput = JSON.parse(localStorage.getItem('commonInput'));
      commonInput['firstName'] = localStorage.getItem('bubble-fname');
      commonInput['lastName'] = localStorage.getItem('bubble-lname');
      commonInput['email'] = localStorage.getItem('bubble-email');
      commonInput['phone'] = localStorage.getItem('bubble-phone');
      commonInput['dob'] = localStorage.getItem('bubble-dob');
      commonInput['disableBack'] = false;
      localStorage.setItem('commonInput', JSON.stringify(commonInput));
      localStorage.setItem('knockout', '0');
      console.log('SW quote invoke knockout commonInput:', commonInput);
      localStorage.setItem('KOerrorMessage', 'Unfortunately we require a couple more steps before completing your application.');
      const quickquote = new pc.Quotes(this.uniqueId);
      quickquote.getStillWaterQuote(commonInput, false, true)
        .then(response => {
          debugger;

          console.log('SW knockout output: ', response);
          if (response[0].hasError && response[0].hasError == true) {
            this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'KnockoutPopupQuoteFlowV2Home', {
              'event_category': 'Error', 'event_label': 'KnockoutPopup'
            });
            this.knockOut = 1;
            let errorCode = response[0].errorMessage;
            errorCode = errorCode.split(':');
            const errorMsg = errorCode[1];
            localStorage.setItem('KOerrorMessage', errorMsg);
            const clientIdLocal = localStorage.getItem('clientID');
            const hubSpotData = {
              client_id: clientIdLocal,
              customer_id: localStorage.getItem('customer_id'),
              email: localStorage.getItem('bubble-email'),
              application_stage: 'Policy Denied',
              home_knock_out_reason: errorMsg,

            };
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
            this.koerrormsg = errorMsg;
            errorCode = errorCode[0].replace(' ', '');

            if (errorCode == 'QUA0080') {
              localStorage.setItem('KOType', 'ko');
              localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because of prior damage to the house.');
              location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
            }
            else if (errorCode == 'QUA0118') {
              // document.getElementById('konmodel').style.display = 'block';
              // this.showpopup2 = false;
              // this.showpopup1 = true;
              // this.showpopup3 = false;
              // this.spinner = false;
              // document.getElementById('makeblur').style.filter = "blur(10px)";
              localStorage.setItem('KOType', 'ko');
              localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because 1 or more units are occupied by more than 2 families');
              location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
            }
            else if (errorCode == 'QUA0021') {
              localStorage.setItem('KOType', 'ko');
              localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because there is a business being run on property');
              location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
            }
            else if (errorCode == 'QUA0003') {
              localStorage.setItem('KOType', 'ko');
              localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because the house is vacant');
              location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
            }
            else if (errorCode == 'QUA0110') {
              localStorage.setItem('KOType', 'ko');
              localStorage.setItem('KOerrorMessage', 'Unfortunately we cannot insure you right now because applicant has experienced a lapse in coverage in the past 36 months.');
              location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
            }
            else if (errorCode == 'FNI0170') {
              localStorage.setItem('KOType', 'pp');
              localStorage.setItem('KOerrorMessage', 'The current address entered needs underwriting approval and cannot be issued with an online quote as it exceeds permissible square footage and/or coverage limit.');
              location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
            }
            else if (errorCode == 'FNI0052') {
              localStorage.setItem('KOType', 'ko');
              localStorage.setItem('KOerrorMessage', 'Unable to quote - Insurance Bureau Score not available.');
              this.openModalssn_number();
            }
            else if (this.kopopup1list.includes(errorCode) && localStorage.getItem('kopopshowed') != '1') {
              document.getElementById('konmodel').style.display = 'block';
              document.getElementById('makeblur').style.filter = 'blur(10px)';
              localStorage.setItem('kopopshowed', '1');
              this.showpopup1 = true;
              this.showpopup2 = false;
              this.showpopup3 = false;
              this.spinner = false;
            }
            else if (this.kopopup2list.includes(errorCode) && localStorage.getItem('kopopshowed') != '1') {
              document.getElementById('konmodel').style.display = 'block';
              document.getElementById('makeblur').style.filter = 'blur(10px)';
              localStorage.setItem('kopopshowed', '1');
              this.showpopup2 = true;
              this.showpopup1 = false;
              this.showpopup3 = false;
              this.spinner = false;
            }
            else if (this.kopopup3list.includes(errorCode) && localStorage.getItem('kopopshowed') != '1') {
              document.getElementById('konmodel').style.display = 'block';
              document.getElementById('makeblur').style.filter = 'blur(10px)';
              localStorage.setItem('kopopshowed', '1');
              this.showpopup3 = true;
              this.showpopup1 = false;
              this.showpopup2 = false;
              this.spinner = false;
            }
            else {
              localStorage.setItem('KOType', 'pp');
              localStorage.setItem('KOerrorMessage', errorMsg);
              location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
            }
            this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'KnockoutPopupQuoteFlowV2Home', {
              'event_category': 'Error', 'event_label': localStorage.getItem('KOerrorMessage')
            });
            // if (errorCode != "FNI0052") {
            //   location.href = "KnockOut/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
            // }
          }
          else {
            // if (this.insurancetype == "home") {
            //   this.bluronhow();
            // }
            // else {
            //   location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
            // }
            const updateJson1 = {
              otherAppflowScreen: 'select-home-carrier',
              resumeUrl: 'select-home-carrierv2/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype
            };
            this.checkforResumeInit(updateJson1, 'update').then(data => {
              //this.loader = false;
              // location.href = "select-home-carrierv2/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
              //location.href = "ContactInformation/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/" + this.insurancetype;
              const url = 'select-home-carrierv2/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
              this.router.navigate([url]);
            }).catch(error => {
              console.log(error);
            });



          }
          //this.loader = false;
          // let etty = {
          //   "questionNo": "1",
          //   "nextprev": 1,
          //   "knockOut": this.knockOut
          // }
          // this.nextQuestion(etty);
        })
        .catch(error => {
          console.log('SW knockout Error:', error);
          this.errorMessage = error;
          this.openModal();
        });
    }



  }


  openModal() {
    this.errorMessage = 'Our Servers are taking too long to respond.';
    console.log(' open modal errorMessage', this.errorMessage);
    document.getElementById('errorServer').style.display = 'block';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: this.errorMessage
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //     // keyboard: false,
    //     // backdrop: 'static'
    //   });

    // let data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   message: "Our Servers are taking too long to respond please come back later.",
    //   image: 'assets/images/images/education2.png'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   //redirect url
    //   //window.location.href = environment.carrierErrorRedirectURL;

    //   window.location.href = this.pc.envCred.url;
    //   console.log("Modal Close Output: ", result);
    // }, (reason) => {
    //   console.log("Modal Close Reason: ", reason);
    // });
  }


  openModalssn_number() {
    document.getElementById('ssn_number').style.display = 'flex';
    document.getElementById('makeblur').style.filter = 'blur(10px)';
  }


  bluroffhow3() {
    document.getElementById('ssn_number').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
  }

  mapLeadSourceToPartner = {
    'HomePros': 'HomePros',
  };

  previousQuestion() {
    const eventOptionsPart1 = {
      'path': 'quoteFlowV2previousQuestion',
      'page_title': 'quoteFlowV2previousQuestion', 'url': window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', this.insurancetype, {
      'event_category': 'BackButton', 'event_label': 'Back'
    });
    this.StatusTrackerPrev(this.question);
    if (this.question == 1) {
      const lead_source = localStorage.getItem('lead_source');
      location.href = this.pc.envCred.url + '/' + this.mapLeadSourceToPartner[lead_source] || '';
    }
    else if (this.question != 1) {
      this.question = this.question - 1;
      // this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.question })
    }
    else {

    }
  }

  StatusTrackerPrev(questionNo) {
    if (questionNo == '2') {
      this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 1 });
    }

  }


  insuranceTypeChange(even) {
    this.insurancetype = even.type;
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }


  SSN: any;
  ssnLength: any;
  SSNerror: boolean;
  ssnlength() {
    debugger;
    const j = this.SSN.replace(/-/g, '');
    const A = j.substring(0, 3);
    const B = j.substring(3, 5);
    const C = j.substring(5, 9);
    if (B == '') {
      this.SSN = A;
    }
    else if (B != '' && C == '') {
      this.SSN = A + '-' + B;
    }
    else if (B != '' && C != '') {
      this.SSN = A + '-' + B + '-' + C;
    }
    else {
      this.SSN = j;
    }

    this.ssnLength = this.SSN.toString().length;
    if (this.ssnLength > 10) {
      this.SSNerror = false;
    }
    else {
      this.SSNerror = true;
    }

  }

  public onSubmit() {
    this.loader = true;
    document.getElementById('ssn_number').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['firstName'] = localStorage.getItem('bubble-fname');
    commonInput['lastName'] = localStorage.getItem('bubble-lname');
    commonInput['email'] = localStorage.getItem('bubble-email');
    commonInput['phone'] = localStorage.getItem('bubble-phone');
    commonInput['disableBack'] = false;
    commonInput['ssn'] = this.SSN;

    localStorage.setItem('commonInput', JSON.stringify(commonInput));


    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.getStillWaterQuote(commonInput, false, true)
      .then(response => {
        debugger;

        const jsonhome = {
          'id': this.clientId,
          'ssn': this.SSN
        };
        console.log('json : ', jsonhome);
        this.pc.saveUpdateClientData(jsonhome).then(data => {
          this.loader = false;
          const hubSpotData = {
            client_id: localStorage.getItem('clientID'),
            customer_id: localStorage.getItem('customer_id'),
            email: localStorage.getItem('bubble-email'),
            application_stage: 'Policy Denied',
            home_knock_out_reason: localStorage.getItem('KOerrorMessage'),
            social_security_number__ssn_: this.SSN
          };
          this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
          location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
        }).catch(error => {
          console.log(error);
        });


      })
      .catch(error => {

      });



  }



  gotoHome() {
    document.getElementById('errorServer').style.display = 'none';
    window.location.href = this.pc.envCred.url;
  }

  tryAgain() {

    document.getElementById('errorServer').style.display = 'none';
    //window.location.href = environment.carrierErrorRedirectURL;
    // window.location.href = this.pc.envCred.url;
    location.reload();
  }

  closethemodel() {
    document.getElementById('konmodel').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
    localStorage.setItem('KOType', 'pp');
    localStorage.setItem('KOerrorMessage', this.koerrormsg);
    location.href = 'KnockOut/' + this.uniqueId + '/' + this.clientId + '/' + this.displayAddress + '/' + this.insurancetype;
  }

}
