import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sbli01601210',
  templateUrl: './sbli01601210.component.html',
  styleUrls: ['./sbli01601210.component.css']
})
export class Sbli01601210Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
