export class QuestionC3ValidateModel {
  group: string;
  value: number;
  groupCount: number;
  minGroupCount: number;

  constructor(group: string, value: number, groupCount: number, minGroupCount = 1 ) {
    this.group = group;
    this.value = value;
    this.groupCount = groupCount;
    this.minGroupCount = minGroupCount;
  }
}
