import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
var unflatten = require('flat').unflatten
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-sw4pt28',
  templateUrl: './sw4pt28.component.html',
  styleUrls: ['./sw4pt28.component.css']
})
export class Sw4pt28Component implements OnInit {

  @Output() nextquestion19 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  totalDiscount: any;
  disable: boolean;
  showBuilderCard: number;
  discountTwo: number;
  discountSelected = [];
  selectedBuilder: number;
  builder: any;
  discountOne: number;
  state: any;
  existingData: any;
  loader: boolean;
  builderList: any;
  yearBuiltCondition: boolean;
  discountlist: any;
  builderId: any;
  showCertificateCard: number;
  selectedCertificate: number;
  certificateId: any;
  certificate: string;
  showSprinklerCard: number;
  selectedSprinkler: number;
  sprinklerId: any;
  sprinkler: string;

  constructor(private $gaService: GoogleAnalyticsService, private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    // this.uniqueId = 'nkowu4-285-Bryant-St-Palo-Alto-CA-94301';
    // this.clientId = 'nkowu4';
    this.loader = true;
    this.builder = '';
    this.builderId = '';
    this.certificate = '';
    this.certificateId = '';
    this.sprinkler = '';
    this.sprinklerId = '';
    //debugger;
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt28')[0];
    console.log('sw questions app-sw4pt28 : ', this.questions);
    this.totalDiscount = 0;
    let fullAddress = this.uniqueId.split('-');
    fullAddress.shift();
    fullAddress = fullAddress.join(' ');
    this.pc.parseAddressLocalAndPatch(fullAddress, this.clientId).then(
      data => {
        console.log('splitAddressNew : ', data);
        this.state = data.state_code;
        //debugger;
        //let index = this.discountSelected.indexOf(list.sw_questionId);
        //this.discountSelected.splice(index, 1);
        //debugger;
        this.yearBuiltCondition = true;
        const yearBuilt = localStorage.getItem('sw_yearBuilt');
        const currentYear = new Date().getFullYear();
        const diff = Number(currentYear) - Number(yearBuilt);
        if (Number(diff) > 6) {
          this.yearBuiltCondition = false;
          //this.questions.checkbox_options.splice(1, 1);
          this.questions.checkbox_options = this.questions.checkbox_options.filter(x => x.sw_questionId != 61);
        }
        else if (this.state != 'CA') {
          //this.questions.checkbox_options.splice(1, 1);
          this.questions.checkbox_options = this.questions.checkbox_options.filter(x => x.sw_questionId != 61);
        }
        else {

        }

        // if(localStorage.getItem('selectedHomeCarrier') === null){
        //   this.pc.getSWSelectedFormattedQuote().then(data => {
        //     console.log("selectedHomeCarrier", data);
        //     localStorage.setItem('selectedHomeCarrier', JSON.stringify(data));
        //     let selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
        //     console.log("selectedHomeCarrier", selectedHome);
        //     if (selectedHome.carrierId=="expanded") {
        //       this.questions.checkbox_options = this.questions.checkbox_options.filter(x => x.sw_questionId != 4200);
        //     }
        //   });
        // }
        // else {
        //   let selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
        //   console.log("selectedHomeCarrier", selectedHome);
        //   if (selectedHome.carrierId=="expanded") {
        //     this.questions.checkbox_options = this.questions.checkbox_options.filter(x => x.sw_questionId != 4200);
        //   }
        // }






        this.pc.getAppData(this.clientId).then(data => {
          debugger
          const dataFrom = JSON.parse(data.Payload);
          this.existingData = dataFrom.body.stillwater;
          let selectedDiscount;
          if (dataFrom.body.pageDataJson) {
            let pagedatajson = unflatten(dataFrom.body.pageDataJson);
            if (pagedatajson["sw_discounts"]) {
              selectedDiscount = pagedatajson["sw_discounts"];
              localStorage.setItem('sw_discountsClientID', this.clientId);
            }
            else {
              selectedDiscount = localStorage.getItem('sw_discounts');
              selectedDiscount = JSON.parse(selectedDiscount);
            }
          }
          else {
            selectedDiscount = localStorage.getItem('sw_discounts');
            selectedDiscount = JSON.parse(selectedDiscount);
          }
          console.log('data from getAppData sw : ', this.existingData);
          const quickquote = new pc.Quotes(this.uniqueId);
          this.builderList = quickquote.getSWCABuilders();
          console.log('builderlist : ', this.builderList);
          this.discountlist = quickquote.getDiscountsSW(this.state, localStorage.getItem('sw_yearBuilt'));

          if (this.state != 'CA') {
            this.discountlist = this.discountlist.filter(x => x.answer_key != 'sw_DiscountsGatedCommunity');
          }
          if (this.state == 'TX') {
            this.discountlist = this.discountlist.filter(x => x.answer_key != 'sw_DiscountsBurglarAlarmSystem' && x.answer_key != 'sw_DiscountsLockingDevice');
          }

          console.log('discountlist : ', this.discountlist);
          const cid = localStorage.getItem('sw_discountsClientID');
          this.discountSelected = [];

          if (cid == this.clientId && selectedDiscount.length > 0) {
            for (let i = 0; i < this.discountlist.length; i++) {
              if (selectedDiscount.includes(this.discountlist[i].sw_questionId)) {
                const commonInput = JSON.parse(localStorage.getItem('commonInput'));
                if (this.discountlist[i].sw_questionId == 61) {
                  this.builderId = commonInput['ca_builder'];
                  this.builder = this.discountlist[i].drop_down_values.filter(x => x.id == this.builderId)[0].name;
                  this.selectedBuilder = 1;
                  this.showBuilderCard = 1;
                }
                else if (this.discountlist[i].sw_questionId == 1700) {
                  this.certificateId = commonInput['certificateId'];
                  this.certificate = this.discountlist[i].drop_down_values.filter(x => x.value == this.certificateId)[0].text;
                  this.selectedCertificate = 1;
                  this.showCertificateCard = 1;
                }
                else if (this.discountlist[i].sw_questionId == 2000) {
                  this.sprinklerId = commonInput['sprinklerId'];
                  this.sprinkler = this.discountlist[i].drop_down_values.filter(x => x.value == this.sprinklerId)[0].text;
                  this.selectedSprinkler = 1;
                  this.showSprinklerCard = 1;
                }
                else {
                  this.discountlist[i].selected = 1;
                }
                this.discountSelected.push(this.discountlist[i].sw_questionId);
              }
            }
          }
          this.loader = false;
          //this.afterInit();
        }).catch(error => {
          console.log(error);
        });
      }
    ).catch(error => {
      console.log('error', error);
      this.afterInit();
    });

  }

  afterInit() {
    this.loader = true;
    this.builder = '';
    const cid = localStorage.getItem('sw_discountsClientID');
    let selectedDiscount;
    if (cid == this.clientId) {
      selectedDiscount = localStorage.getItem('sw_discounts');
      selectedDiscount = JSON.parse(selectedDiscount);
    }
    else {
      selectedDiscount = [];
    }

    for (let i = 0; i < this.questions.checkbox_options.length; i++) {

      if (selectedDiscount.includes(this.questions.checkbox_options[i].sw_questionId)) {
        this.questions.checkbox_options[i][this.questions.checkbox_options[i].answer_key] = 'yes';
        this.questions.checkbox_options[i].value = 'yes';
        this.questions.checkbox_options[i].active = true;
        if (i == 1) {
          this.selectedBuilder = 1;
        }
      }
      else {
        this.questions.checkbox_options[i][this.questions.checkbox_options[i].answer_key] = 'no';
        this.questions.checkbox_options[i].value = 'no';
        this.questions.checkbox_options[i].active = false;
      }

    }

    for (let s = 0; s < selectedDiscount.length; s++) {
      this.discountSelected.push(selectedDiscount[s]);
    }
    console.log('discount questions : ', this.questions);
    console.log('discount selected : ', this.discountSelected);
    console.log('discount yearBuiltCondition : ', this.yearBuiltCondition);
    this.loader = false;
  }

  onBuilderSelect() {
    this.showBuilderCard = 1;
  }

  onBuilderUnSelect() {
    this.showBuilderCard = 0;
  }

  builderSelected(even, questionId, list) {
    console.log(even.target.value);
    if (even.target.value != '') {
      this.discountSelected.push(questionId);
      this.selectedBuilder = 1;
      this.builderId = even.target.value;
      if (this.builderId == '0') {
        this.builder = 'N/A';
      }
      else {
        this.builder = list.filter(x => x.id == this.builderId)[0].name;
      }
    }
  }

  editSelectedbuilder(questionId) {
    this.showBuilderCard = 1;
    this.selectedBuilder = 0;
    const index = this.discountSelected.indexOf(questionId);
    this.discountSelected.splice(index, 1);
    this.builderId = '';
  }

  onCertificateSelect() {
    this.showCertificateCard = 1;
  }

  onCertificateUnSelect() {
    this.showCertificateCard = 0;
  }

  certificateSelected(even, questionId, list) {
    console.log(even.target.value);
    if (even.target.value != '') {
      this.discountSelected.push(questionId);
      this.selectedCertificate = 1;
      this.certificateId = even.target.value;
      if (this.certificateId == '0') {
        this.certificate = 'N/A';
      }
      else {
        this.certificate = list.filter(x => x.value == this.certificateId)[0].text;
      }
    }
  }

  editSelectedCertificate(questionId) {
    this.showCertificateCard = 1;
    this.selectedCertificate = 0;
    const index = this.discountSelected.indexOf(questionId);
    this.discountSelected.splice(index, 1);
    this.certificateId = '';
  }


  onSprinklerSelect() {
    this.showSprinklerCard = 1;
  }

  onSprinklerUnSelect() {
    this.showSprinklerCard = 0;
  }

  sprinklerSelected(even, questionId, list) {
    console.log(even.target.value);
    if (even.target.value != '') {
      this.discountSelected.push(questionId);
      this.selectedSprinkler = 1;
      this.sprinklerId = even.target.value;
      if (this.sprinklerId == '0') {
        this.sprinkler = 'N/A';
      }
      else {
        this.sprinkler = list.filter(x => x.value == this.sprinklerId)[0].text;
      }
    }
  }

  editSelectedSprinkler(questionId) {
    this.showSprinklerCard = 1;
    this.selectedSprinkler = 0;
    const index = this.discountSelected.indexOf(questionId);
    this.discountSelected.splice(index, 1);
    this.sprinklerId = '';
  }


  selectDiscount(qid) {
    if (this.discountSelected.includes(qid.sw_questionId)) {
      const index = this.discountSelected.indexOf(qid.sw_questionId);
      this.discountSelected.splice(index, 1);
      qid.selected = 0;
    }
    else {
      this.discountSelected.push(qid.sw_questionId);
      qid.selected = 1;
    }
  }



  selectCoverage(list) {
    if (list.active == true) {
      list.active = false;
      list.value = 'no';
      this.totalDiscount = Number(this.totalDiscount) - Number(list.amount);
      const index = this.discountSelected.indexOf(list.sw_questionId);
      this.discountSelected.splice(index, 1);
    }
    else {
      list.active = true;
      list.value = 'yes';
      this.totalDiscount = Number(this.totalDiscount) + Number(list.amount);
      this.discountSelected.push(list.sw_questionId);
    }
  }

  next() {
    this.loader = true;
    localStorage.setItem('sw_discounts', JSON.stringify(this.discountSelected));
    localStorage.setItem('sw_discountsClientID', this.clientId);
    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };
    // for (let i = 0; i < this.questions.checkbox_options.length; i++){
    //   this.questions.checkbox_options[i][this.questions.checkbox_options[i].answer_key] = this.questions.checkbox_options[i].value;
    //   localStorage.setItem(this.questions.checkbox_options[i].answer_key, this.questions.checkbox_options[i][this.questions.checkbox_options[i].answer_key]);
    //   json.stillwater[this.questions.checkbox_options[i].answer_key] = this.questions.checkbox_options[i][this.questions.checkbox_options[i].answer_key];
    // }
    console.log('json : ', json);
    // this.loader = false;
    this.pc.saveUpdateClientData(json).then(data => {
      const quickquote = new pc.Quotes(this.uniqueId);
      const commonInput = JSON.parse(localStorage.getItem('commonInput'));
      commonInput['sw_discounts'] = this.discountSelected;
      commonInput['ca_builder'] = this.builderId;
      commonInput['certificateId'] = this.certificateId;
      commonInput['sprinklerId'] = this.sprinklerId;
      commonInput['credit_consent'] = true;
      localStorage.setItem('commonInput', JSON.stringify(commonInput));
      // console.log("getStillWaterQuote discount commonInput:", commonInput);
      // console.log("discount SWquote input: ", commonInput);
      // quickquote.getStillWaterQuote(commonInput)
      // .then(response => {
      //   //debugger
      //   console.log("output SWquote: ", response);
      //   let selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
      //   let sel = response.filter(x => x.head.carrier_name == selectedHome.head.carrier_name);
      //   localStorage.setItem('selectedHomeCarrier', JSON.stringify(sel[0]));
      //   this.loader = false;
      //   let etty = {
      //     "next": 1,
      //     "previous": 0,
      //   };
      //   this.nextquestion19.emit(etty);
      //   })
      // .catch(error => {console.log("SW Quote Error:", error)});
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0,
        'knockout': 1
      };
      this.nextquestion19.emit(etty);
    }).catch(error => {
      console.log(error);
    });
    this.$gaService.event('Next', 'event', 'HomeAppDiscountsEligible');
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion19.emit(etty);
  }

}
