import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-application-status-tracker-v2',
  templateUrl: './application-status-tracker-v2.component.html',
  styleUrls: ['./application-status-tracker-v2.component.css']
})
export class ApplicationStatusTrackerV2Component implements OnInit {

  email: string;
  dob: string;
  applicationNum: string;
  emailValid: boolean = false;
  applicatioValid: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  changeToDOB(){
    document.getElementById('tracker-dob').setAttribute("type", "date")
  }

}
