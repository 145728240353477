import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from "../../../components/lifescore-error-alert/lifescore-error-alert.component";
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-wnsreview',
  templateUrl: './wnsreview.component.html',
  styleUrls: ['./wnsreview.component.css']
})
export class WnsreviewComponent implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  showLoader: boolean = true;
  existingData: any;
  clientId: any;
  uniqueId: any;
  docURLs: any;
  pdfURL: any;
  sbligetFORMSres: any;
  constructor(private http: HttpClient,
    private pc: ProcessCacheService,
    private modalService: NgbModal,
    private activatedroute: ActivatedRoute, private router: Router, public sanitizer: DomSanitizer) { }

    CheckBox: boolean;
  ngOnInit(): void {
    this.CheckBox = false;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
    }
    );
    this.showLoader = true;


    // this.http.post("https://api3.getmybubble.io/merge-pdf-v2", { clientId: "fpw3uw" }).subscribe(
    //   (data: any) => {

    //     console.log("sbli Url Passed", data);

    //   },
    //   (error: any) => {
    //     console.log("sbli Url Failed", error);
    //   }
    // );




    let quickquote = new pc.Quotes(this.uniqueId);
    quickquote.wnsgetFORMS(2).then(response => {
      debugger
      console.log("wnsgetFORMS res", response);
      if (response.sbliError) {
        this.openModal(response.msgText, "Sorry!");
      }
      else {
        this.sbligetFORMSres = response;

        console.log("pdf : ", this.pdfURL);

        let uid;


        uid = response.sbliOutput.id;
        let envCred = this.pc.envCred;
        setTimeout(() => {
          this.http.post(this.sbligetFORMSres.sbliOutput["output.pdfBaseUrl"] + "/merge-pdf-v2", { clientId: uid }).subscribe(
            (data: any) => {
              console.log("merge pdf call", data);
              console.log("sbli Url Passed", data);

              // "https://stage.getmybubble.io/sbliPdfs/76g7x3_244703.pdf"
              // let domainURL = "https://stage.getmybubble.io/sbliPdfs/";
              // let fileName = data.data;
              // this.pdfURL = domainURL + fileName;
              let domainURL = this.sbligetFORMSres.sbliOutput['output.pdfBaseUrl'];

              //let domainURL = "https://api3.getmybubble.io";

              // domainURL = domainURL + "/sbliPdfs/";

              domainURL = domainURL + '/preview?filename=';

              const fileName = data.data;

              this.pdfURL = domainURL + fileName;

              this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfURL);

              console.log('pdf : ', this.pdfURL);

              this.showLoader = false;

            },
            (error: any) => {
              console.log("sbli Url Failed", error);
            }
          );
        }, 5000);
      }

    })
      .catch(error => {
        console.log("sbligetFORMS Error:", error);
        this.showLoader = false;
      });
  }



  public DownloadPDF(id) {
    console.log("DownloadPDF", id);
    // window.location.href = 'data:application/octet-stream;base64,' + this.base64JsonPdf[id];

    // const source = `data:application/pdf;base64,${this.base64JsonPdf[id]}`;
    // let filename = this.pdfMapping.filter(item => item.id == id)[0]['text'];
    // const link = document.createElement("a");
    // link.href = source;
    // link.download = `${filename}.pdf`
    // link.click();

    /* if(id==0){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["905"];
    }
    else if(id==0){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["904"];
    }
    else if(id==2){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["906"];
    }
    else if (id==3){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["911"];
    }
    else{
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["912"];
    }
    window.location.href = pdf; */
  }

  openModal(msg, title) {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    let data = {
      head: 'Please Note',
      title: title,
      message: msg,
      image: 'assets/images/images/education2.png'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log("Modal Close Output: ", result);
    }, (reason) => {
      console.log("Modal Close Reason: ", reason);
    });
  }

  next() {
    this.pc.lifesummary({'application_review': 'yes' });
    let etty = {
      "next": 1,
      "previous": 0,
      "APICall": 5
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }

}
