import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sbli01301160',
  templateUrl: './sbli01301160.component.html',
  styleUrls: ['./sbli01301160.component.css']
})
export class Sbli01301160Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
