import { Component, OnDestroy, OnInit,EventEmitter,Output,  ViewChild } from '@angular/core';

@Component({
  selector: 'app-question6',
  templateUrl: './question6.component.html',
  styleUrls: ['./question6.component.css']
})
export class Question6Component implements OnInit {

  @Output() nextquestion6 = new EventEmitter();
  yesicon: string;
  noicon: string;
  tobaccoSelected: string;
  tobacoUser: any;

  constructor() { }

  ngOnInit(): void {
    if(localStorage.getItem('bubble-tobacco') !== null) {
      this.tobaccoSelected = localStorage.getItem('bubble-tobacco');
    }
    if (this.tobaccoSelected==undefined||this.tobaccoSelected==null||this.tobaccoSelected=="undefined") {
      this.tobaccoSelected = "1";
    }
    this.yesicon="assets/images/images/ic-yes.png";
    this.noicon="assets/images/images/ic-no.png";
    if( this.tobaccoSelected=='1'){
      localStorage.setItem('bubble-tobacco',"1");
      document.getElementById("male_1").className="gender active";
      document.getElementById("female_1").className="gender";
      this.yesicon="assets/images/active/icon-tick-active.svg";
      this.noicon="assets/images/images/ic-no.png";
    }
    else{
      localStorage.setItem('bubble-tobacco',"0");
      document.getElementById("male_1").className="gender";
      document.getElementById("female_1").className="gender active";
      this.yesicon="assets/images/images/ic-yes.png";
      this.noicon="assets/images/active/icon-cross-active.svg";
    }
  }

  next() {    
    let etty = {
      "questionNo": "6",
      "tobacoUser": this.tobaccoSelected,
      "nextprev": 1
    }
    this.nextquestion6.emit(etty);
  }

  previous() {
     let etty = {
      "questionNo": "6",
      "tobacoUser": this.tobaccoSelected,
      "nextprev": 0
    }
    this.nextquestion6.emit(etty);
  }

  gender(gen) {
    this.tobaccoSelected = gen;
    if(gen=='1'){
      localStorage.setItem('bubble-tobacco',"1");
      document.getElementById("male_1").className="gender active";
      document.getElementById("female_1").className="gender";
      this.yesicon="assets/images/active/icon-tick-active.svg";
      this.noicon="assets/images/images/ic-no.png";
    }
    else{
      localStorage.setItem('bubble-tobacco',"0");
      document.getElementById("male_1").className="gender";
      document.getElementById("female_1").className="gender active";
      this.yesicon="assets/images/images/ic-yes.png";
      this.noicon="assets/images/active/icon-cross-active.svg";
    }
  }

}

