import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-ko-questions-popup',
  templateUrl: './ko-questions-popup.component.html',
  styleUrls: ['./ko-questions-popup.component.css']
})
export class KoQuestionsPopupComponent implements OnInit {


  koQuestions = new FormGroup({
    question_one: new FormControl(false),
    question_two: new FormControl(false),
    question_three: new FormControl(false),
    question_four: new FormControl(false)
  });


  question_one = false;
  question_two = false;
  question_three = false;
  question_four = false;
  @Input() clientid: any;

  constructor(config: NgbCarouselConfig, public pc: ProcessCacheService) {
    config.showNavigationIndicators = true;
    config.showNavigationArrows = false;
  }

  @Output() koQuestion = new EventEmitter();


  ngOnInit(): void {
    console.log('this.koQuestions', this.koQuestions.value);
  }

  selectQustions(question_number, value) {
    debugger;
    this.koQuestions.value[question_number] = value;
  }

  onSubmit() {
    debugger;
    const questionsSelected = {};
    for (const [key, value] of Object.entries(this.koQuestions.value)) {
      if (this.koQuestions.value[key] == true) {
        questionsSelected[key] = value;
      }
    }
    const savedata = {
      'id': this.clientid,
      'clientId': this.clientid,
      'koQuestionsShown': 'true',
      'filterSbliWns': Object.keys(questionsSelected).length === 0 && questionsSelected.constructor === Object ? 'false' : 'true'
    };
    if (Object.keys(questionsSelected).length === 0 && questionsSelected.constructor === Object) {
      this.pc.lifesummary({ 'ko_input': 'none' });
    }
    else {
      this.pc.lifesummary({ 'ko_input': JSON.stringify(questionsSelected) });
    }
    this.pc.saveUpdateClientData(savedata);
    this.hubspotApplicationStage();
    this.koQuestion.emit(savedata);
    console.log('this.koQuestions', questionsSelected);
  }

  hubspotApplicationStage() {
    this.pc.hubSpotSyncAfterEmailFetch({
      email: localStorage.getItem('bubble-email'),
      w_s__sbli_ko_popup_submission: 'Submit',
    });
  }

}
