import { Component, OnInit ,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-sbli01201150',
  templateUrl: './sbli01201150.component.html',
  styleUrls: ['./sbli01201150.component.css']
})
export class Sbli01201150Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  next() {
    debugger
    let etty = {
      "next": 1,
      "previous": 0,
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }


}
