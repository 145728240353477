import { Component, OnInit, Input, OnChanges, SimpleChanges, ElementRef } from '@angular/core';

@Component({
  selector: 'app-life-score-grade',
  templateUrl: './life-score-grade.component.html',
  styleUrls: ['./life-score-grade.component.css']
})
export class LifeScoreGradeComponent implements OnInit, OnChanges {

  constructor(private elRef: ElementRef) { }

  @Input() displayText;
  @Input() circleClass;
  @Input() circleTextClass;
  @Input() ringColor;

  ngOnInit(): void {


  }

  ngOnChanges(changes: SimpleChanges) {


    let circle_class = this.circleClass;
    let ring_color = this.ringColor;

    if (changes['circleClass']) {
      circle_class = changes['circleClass']['currentValue'];

    }

    if (changes['ringColor']) {
      ring_color = changes['ringColor']['currentValue'];
    }

    setTimeout(() => {

      let ringClass = this.elRef.nativeElement.getElementsByClassName(circle_class);

      ringClass = ringClass[0];

      if (circle_class == 'progress-20') {

        // ringClass.style.backgroundImage = `-webkit-linear-gradient(72deg, #ecf9ff 50%, transparent 50%), -webkit-linear-gradient(left, ${ring_color} 50%, #ecf9ff 50%)`;
        ringClass.style.backgroundImage = `linear-gradient(90deg, #ecf9ff 50%, transparent 50%), linear-gradient(162deg, ${ring_color} 50%, #ecf9ff 50%)`;

      } else if (circle_class == 'progress-40') {

        // ringClass.style.backgroundImage = `-webkit-linear-gradient(144deg, #ecf9ff 50%, transparent 50%), -webkit-linear-gradient(left, ${ring_color} 50%, #ecf9ff 50%)`;
        ringClass.style.backgroundImage = `linear-gradient(90deg, #ecf9ff 50%, transparent 50%), linear-gradient(234deg, ${ring_color} 50%, #ecf9ff 50%)`;

      } else if (circle_class == 'progress-60') {

        // ringClass.style.backgroundImage = `-webkit-linear-gradient(288deg, #ecf9ff 50%, transparent 50%), -webkit-linear-gradient(right, ${ring_color} 50%, #ecf9ff 50%)`;
        ringClass.style.backgroundImage = `linear-gradient(-45deg, ${ring_color} 50%, transparent 50%), linear-gradient(270deg, ${ring_color} 50%, #ecf9ff 50%)`;

      } else if (circle_class == 'progress-80') {


        // ringClass.style.backgroundImage = `-webkit-linear-gradient(288deg, #ecf9ff 50%, transparent 50%), -webkit-linear-gradient(right, ${ring_color} 50%, #ecf9ff 50%)`;
        ringClass.style.backgroundImage = `linear-gradient(-90deg, ${ring_color} 50%, transparent 50%), linear-gradient(360deg, ${ring_color} 50%, #ecf9ff 50%)`;

      } else if (circle_class == 'progress-100') {

        // ringClass.style.backgroundImage = `-webkit-linear-gradient(360deg, #ecf9ff 50%, transparent 50%), -webkit-linear-gradient(right, ${ring_color} 50%, #ecf9ff 50%)`;
        ringClass.style.backgroundImage = `linear-gradient(0deg, ${ring_color} 50%, transparent 50%), linear-gradient(180deg, ${ring_color} 50%, #ecf9ff 50%)`;

      }


      /* ringClass.style.backgroundImage = `linear-gradient(-90deg, #000000 50%, transparent 50%), linear-gradient(360deg, #000000 50%, #ecf9ff 50%)`; */

      /* ringClass.style.backgroundImage = `linear-gradient(-90deg, ${ring_color} 50%, transparent 50%), linear-gradient(360deg, ${ring_color} 50%, #ecf9ff 50%)`; */



      /* ringClass.style.background = `#ff0000`;
    console.log("Color class changed background: ", ringClass.style.background); */


      /* ringClass.style.backgroundImage = `-webkit-linear-gradient(288deg, #ecf9ff 50%, transparent 50%), -webkit-linear-gradient(right, ${ring_color} 50%, #ecf9ff 50%)`;
      ringClass.style.backgroundImage = `linear-gradient(-90deg, #00a12f 50%, transparent 50%), linear-gradient(360deg, ${ring_color} 50%, #ecf9ff 50%)`; */
    }, 2000);


  }


}
