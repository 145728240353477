import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ValidationService } from 'src/app/shared/validation.service';
import { StorageHandlerService } from 'src/app/shared/storage-handler.service';
import { constants } from 'src/app/shared/storage-handler.service';
import { getHubSpotInterestType } from 'src/app/shared/UtilityFunctions.js';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';


declare let require: any;
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-details-confirmation',
  templateUrl: './details-confirmation.component.html',
  styleUrls: ['./details-confirmation.component.css']
})
export class DetailsConfirmationComponent implements OnInit {
  message: string;
  ApllicationID: string;
  url: string;
  age: string;

  constructor(
    private pc: ProcessCacheService,
    public validationService: ValidationService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService,
    private storageService: StorageHandlerService,
    private utilityService: UtilityFunctionsService) { }
  @Output() public confirmDetails =
    new EventEmitter<{ component_id: any }>();
  FirstName: any;
  LastName: any;
  prevEmailId: any;
  EmailID: any;
  PhoneNumber: any;
  DateOfBirth: any;
  Gender: any;
  Height: any;
  Weight: any;
  maleIcon = 'assets/images/active/icon-male-active.svg';
  femaleIcon = 'assets/images/inactive/icon-female-inactive.svg';
  maleIconactive = true;
  femaleIconactive = false;
  inputJson: any;
  curDate: any;
  minDate;
  is_valid: boolean;
  heightFt: any;
  heightIn: any;
  clientId: any;
  insurancetype: any;
  gender;
  addressToShow: any;
  showloader: boolean;


  ngOnInit(): void {
    debugger;
    this.ApllicationID = localStorage.getItem('bubble-ApplicationID');
    this.url = window.location.href;
    if (this.url.includes('Appflowstart')) {
      localStorage.setItem('insurancetypeText', 'Life Application');
    }

    this.showloader = true;
    this.counter = 0;
    this.storageService.setItem('premilumDirtyFlag', 'false');
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];
      this.carrierName = params['carrierName'];
      this.addressToShow = this.displayAddress.replace(/-/g, ' ');
      this.storageService.setItem('displayAddress', this.displayAddress);
      this.storageService.setItem('carrierName', this.carrierName);
      this.showloader = false;
    });

    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Application',
      life_application_page: 'NextPL_0_VerifyDetails',
      life_insurance_carrier:'Next By Pacific Life'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.phoneerror = false;
    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear()) - 18;
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.minDate = new Date(month + '/' + day + '/' + year);
    this.DateOfBirth = new Date(localStorage.getItem(constants.bubble_dob));
    this.FirstName = localStorage.getItem(constants.bubble_fname) || '';
    this.LastName = localStorage.getItem(constants.bubble_lname) || '';
    console.log('this.LastName', this.LastName, localStorage.getItem(constants.bubble_lname));
    this.EmailID = localStorage.getItem(constants.bubble_email);
    this.prevEmailId = localStorage.getItem(constants.bubble_email);
    this.PhoneNumber = localStorage.getItem(constants.bubble_phone);
    this.gender = localStorage.getItem(constants.bubble_gender);
    this.Height = localStorage.getItem(constants.bubble_heightFt) + ' ft ' + localStorage.getItem(constants.bubble_heightIn) + ' in ';
    this.Weight = localStorage.getItem(constants.bubble_weight);
    this.heightFt = localStorage.getItem(constants.bubble_heightFt);
    this.heightIn = localStorage.getItem(constants.bubble_heightIn);
    if (this.FirstName == 'undefined') {
      this.FirstName = '';
    }
    if (this.LastName == 'undefined') {
      this.LastName = '';
    }
    // this.gender=null;
    this.phonelength();
    this.onDateChange(this.DateOfBirth);


  }

  verifyInch() {
    this.storageService.setItem('premilumDirtyFlag', 'true');
    //this.storageService.setItem('premiumAmount ','Recalculating');

    if (Number(this.heightIn) > 11) {
      this.heightIn = '11';
    }
    if (Number(this.heightIn) < 0) {
      this.heightIn = '1';
    }
  }
  inputdatechange() {
    debugger;
    const K = this.DateOfBirth.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.message = 'Invalid Date';
      }
      else {
        this.message = '';
      }
      this.DateOfBirth = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.message = 'Invalid Date';
        }
        else if (A <= 12) {
          this.DateOfBirth = A + '/' + B + '/';
          this.message = '';
        }
        else {
          this.message = 'Invalid Date';
        }

      }
      else {
        this.DateOfBirth = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.DateOfBirth = A + '/' + B + '/' + C;
    }
    else {
      this.DateOfBirth = this.DateOfBirth;
    }
    if (this.DateOfBirth.length >= 10 && this.message != 'Invalid Date') {
      this.onDateChange(this.DateOfBirth);
    }
    else {
      this.is_valid = false;
    }
  }
  genderClick(gender) {
    //debugger

    this.storageService.setItem('premilumDirtyFlag', 'true');
    if (gender == 1) {
      this.maleIconactive = !this.maleIconactive;
      this.maleIcon = this.maleIconactive ? 'assets/images/active/icon-male-active.svg' : 'assets/images/inactive/icon-male-inactive.svg';
      if (this.maleIconactive) {
        this.gender = '1';
        this.femaleIconactive = false;
        this.femaleIcon = 'assets/images/inactive/icon-female-inactive.svg';

      }
    }
    if (gender == 0) {
      debugger;
      this.femaleIconactive = !this.femaleIconactive;
      this.femaleIcon = this.femaleIconactive ? 'assets/images/active/icon-female-active.svg' : 'assets/images/inactive/icon-female-inactive.svg';
      if (this.femaleIconactive) {
        this.gender = '0';
        this.maleIconactive = false;
        this.maleIcon = 'assets/images/inactive/icon-male-inactive.svg';

      }
    }
    this.storageService.setItem('bubble-gender', this.gender);
  }

  WeightVerify() {
    console.log('WeightVerify');
    this.storageService.setItem('premilumDirtyFlag', 'true');
    // this.storageService.setItem('premiumAmount ','Recalculating');

  }

  heightVerify() {
    this.storageService.setItem('premilumDirtyFlag', 'true');
    //this.storageService.setItem('premiumAmount ','Recalculating');
  }

  genderVerify() {

    this.storageService.setItem('premilumDirtyFlag', 'true');
    // this.storageService.setItem('premiumAmount ','Recalculating');

  }

  counter: any;

  onDateChange(even) {
    debugger;
    //  this.storageService.setItem('premilumDirtyFlag','true');
    //this.storageService.setItem('premiumAmount ','Recalculating');
    if (even == null) {
      // this.curDate = new Date();
      // const year = Number(this.curDate.getFullYear()) - 18;
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.DateOfBirth = new Date(month + '/' + day + '/' + year);
      this.DateOfBirth = this.pc.momentdob(this.curDate);
    } else {

      // const cur_d = new Date();
      // let d = new Date(even),
      //   month = '' + (d.getMonth() + 1),
      //   day = '' + d.getDate(),
      //   year = d.getFullYear();
      // const curYear = cur_d.getFullYear();
      // year = d.getFullYear();
      // const ageYear = Number(curYear) - Number(year);
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.DateOfBirth = month + '/' + day + '/' + year;
      this.DateOfBirth = this.pc.momentdob(even);
    }
    if (this.DateOfBirth == 'NaN/NaN/NaN') {
      this.message = 'Invalid Date';
      this.is_valid = false;
      this.DateOfBirth = '';
    }
    else {
      const newDate = new Date(even);
      console.log('DOB entered: ', newDate);
      const checkAge = this.validationService.validate_dob('common_age', newDate);
      this.message = checkAge.message;
      this.is_valid = checkAge.is_valid;
    }

    //console.log("DOB entered: checkAge", checkAge);
    // let checkDob = this.validationService.validate_dob("beneficiary_dob", newDate);
    // console.log("DOB entered: checkDob", checkDob);

    this.counter = this.counter + 1;
    if (this.counter > 1) {
      this.storageService.setItem('premilumDirtyFlag', 'true');
    }

  }



  public async Submit() {
    debugger;
    this.pc.lifesummary({ 'verify_detail_page': 'submit' });
    const today = new Date();
    const birthDate = new Date(this.DateOfBirth);
    const age = today.getFullYear() - birthDate.getFullYear();
    if (localStorage.getItem('premilumDirtyFlag') == 'true') {
      document.getElementById('paclifeQuoteID').innerHTML = 'Recalculating';
      let smoking = localStorage.getItem('bubble-tobacco');
      if (smoking != '5') {
        smoking = localStorage.getItem('bubble-tobaccoUsageDuration');
      }
      const gender = localStorage.getItem('bubble-gender');
      const height = localStorage.getItem('bubble-heightFt') + 'ft ' + localStorage.getItem('bubble-heightIn') + 'inch';
      const weight = localStorage.getItem('bubble-weight');

      if (this.EmailID != this.prevEmailId) {
        // replicated data
        console.log('calling replicateHubspotData');
        await this.pc.replicateHubspotData(this.prevEmailId, this.EmailID);
      }

      this.pc.getPacLifeRiskClass(smoking, gender, height, weight).then(response => {
        debugger;
        let PacLifeRiskClass = response.data.output;
        //console.log('PacLifeRiskClass :', PacLifeRiskClass);
        const dp = JSON.parse(PacLifeRiskClass);
        console.log('PacLifeRiskClass :', dp.dput1);
        PacLifeRiskClass = dp.dput1;
        this.storageService.setItem('riskClass', PacLifeRiskClass);
        let splitname;
        if (this.FirstName) splitname = this.FirstName.split(' ');
        this.storageService.setItem('bubble-email', this.EmailID);
        this.storageService.setItem('bubble-fname', this.FirstName);
        this.storageService.setItem('bubble-lname', this.LastName);
        this.storageService.setItem('bubble-dob', this.DateOfBirth);
        this.invokeGetQuickQuote2(PacLifeRiskClass, splitname, this.gender, this.DateOfBirth, smoking);
        /////// id mapping
        const clientIdLocal = localStorage.getItem('clientID');

        const customerIdLocal = localStorage.getItem('customer_id');

        const emailLocal = this.EmailID;

        const interestType = getHubSpotInterestType(this.insurancetype);

        const idMappingJson = {
          client_id: clientIdLocal,
          customer_id: customerIdLocal,
          email_id: emailLocal,
          firstname: this.FirstName,
          lastname: this.LastName,
          status: interestType,
          interest_type: interestType,
          dob: this.DateOfBirth
        };

        this.pc.createIdMapping(idMappingJson);

        // const Helper = require('bit-uibl-data-driver');

        // Helper.Lead.createIdMapping(idMappingJson)
        //   .then((data) => {
        //     if (data) { console.log("Lead:createIdMapping:data: ", data) }
        //   });

        const life_quote_amount = localStorage.getItem('bubble-QouteAmount');
        let url;
        if (window && window.location && window.location.href) {
          url = window.location.href;
        }

        const dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
        const hubSpotData = {
          interest_type: interestType, customer_id: customerIdLocal,
          email: emailLocal,
          firstname: this.FirstName,
          lastname: this.LastName,
          dob_life: this.DateOfBirth,
          application_stage: 'Application',
          phone: this.PhoneNumber,
          age: age.toString(),
          // life_quote_amount: life_quote_amount,
          life_latest_premium_amount: life_quote_amount,
          dropoff_url: dropoffURL,

        };
        if (this.gender == '0' || this.gender == '1') {
          hubSpotData['gender'] = this.gender == '1' ? 'Male' : 'Female';
        }
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);


        console.log('Lead:createIdMapping:idMappingJson:', idMappingJson);
        /////// id mapping ends
        // <clientid>-email
        this.storageService.setItemReplace(constants.placeholder_clientid_email, clientIdLocal, this.EmailID);
        this.storageService.setItemReplace(constants.placeholder_customerid_email, customerIdLocal, this.EmailID);

      })
        .catch(error => {
          console.log(error);
        });


    }


    this.storageService.setItem('bubble-fname', this.FirstName);
    this.storageService.setItem('bubble-lname', this.LastName);
    this.storageService.setItem('bubble-heightFt', this.heightFt);
    this.storageService.setItem('bubble-heightIn', this.heightIn);
    this.storageService.setItem('bubble-weight', this.Weight);
    this.storageService.setItem('bubble-gender', this.gender);

    const heightFt = localStorage.getItem('bubble-heightFt'); //, this.heightFt);
    const heightIn = localStorage.getItem('bubble-heightIn'); //', this.heightIn);
    const weight = localStorage.getItem('bubble-weight');

    const hubSpotData = {
      email: this.EmailID,
      firstname: this.FirstName,
      lastname: this.LastName,
      dob_life: this.DateOfBirth,
      application_stage: 'Application',
      phone: this.PhoneNumber
    };
    if (heightFt && heightIn) {
      hubSpotData['height'] = `${heightFt}'${heightIn}"`;
    }

    if (weight) {
      hubSpotData['weight'] = weight;
    }
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    this.inputJson = {
      'id': localStorage.getItem('clientID'),
      'comfirmDetailsFirstName': this.FirstName,
      'comfirmDetailsLastName': this.LastName,
      'comfirmDetailsEmailID': this.EmailID,
      'comfirmDetailsPhoneNumber': this.PhoneNumber,
      'comfirmDetailsDateOfBirth': this.DateOfBirth,
      'comfirmDetailsGender': this.gender,
      'comfirmDetailsHeight': this.heightFt + ' ft ' + this.heightIn + 'in',
      'comfirmDetailsWeight': this.Weight + 'lbs',
    };


    this.submitdata(this.inputJson);
    this.confirmDetails.emit({ component_id: 'details-confirmation' });
    // this.pc.submitpaclifeanswer(localStorage.getItem('clientID'));

    this.$gaService.event('Next', 'event', 'LifeAppDetailsReview');
    const eventOptions: EventOption = {
      page_title: 'Details Review',
      path: 'AppFlowStart',
      url: window.location.href
    };

    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppDetailsReview');
  }


  commonAddress: any;
  city: any;
  displayAddress: any;
  termLength: any;
  state: any;
  zipcode: any;
  height: any;
  weight: any;
  coverageAmount: any;
  uniqueId: any;
  quickQuoteData: any;
  carrierName: any;
  selectedCardData: any;
  paclifeQuote: any;
  invokeGetQuickQuote2(riskClassInput, splitname, sbligender, sbliDob, sblitobacco) {
    debugger;
    document.getElementById('paclifeQuoteID').innerHTML = 'Recalculating';
    this.commonAddress = localStorage.getItem('bubble-street');
    this.city = localStorage.getItem('bubble-city');
    this.termLength = localStorage.getItem('bubble-termLength');
    this.displayAddress = localStorage.getItem('displayAddress');

    // Deal with special characters
    if (this.displayAddress.includes('%2520')) {
      this.displayAddress = this.displayAddress.replace(/%2520/g, ' ');
    }
    if (this.displayAddress.includes('%20')) {
      this.displayAddress = this.displayAddress.replace(/%20/g, ' ');
    }
    const splitAddress = this.displayAddress.split(' ');
    // if (splitAddress.length === 3) {
    // } else {
    //   this.city = splitAddress[(splitAddress.length - 3)];
    //   this.state = splitAddress[(splitAddress.length - 2)];
    //   this.zipcode = splitAddress[(splitAddress.length - 1)];
    // }

    if (splitAddress.length == 3) {
      this.city = splitAddress[(splitAddress.length - 3)];
      this.state = splitAddress[(splitAddress.length - 2)];
      this.zipcode = splitAddress[(splitAddress.length - 1)];
    }


    this.displayAddress = this.displayAddress.replace(/-/g, ' ');
    this.height = this.heightFt + 'ft ' + this.heightIn + 'inch';
    //   this.height = localStorage.getItem('bubble-height');
    this.weight = localStorage.getItem('bubble-weight');
    this.carrierName = localStorage.getItem('carrierName');
    splitname = splitname && splitname.length > 2 ? splitname : ['Anonymous', 'User', ''];
    this.coverageAmount = localStorage.getItem('bubble-coverage');
    // console.log(" Customized RiskClass =  "," Somking:", sblitobacco, this.smoker, " Gender:", sbligender, " Height:", this.height, " Weight:", this.weight, " Riskclass:", riskClassInput);

    let commonInput;

    let cc;
    cc = localStorage.getItem('commonInput');
    if (cc == null || cc == undefined) {
      commonInput = {
        lastName: splitname[2] ? splitname[2] : 'Lastname',
        firstName: splitname[0] ? splitname[0] : 'Firstname',
        middleName: splitname[1] ? splitname[1] : 'MiddleName',
        addressType: 'PhysicalRisk',
        address: this.commonAddress,//splitAddress[(splitAddress.length - 3)],
        city: this.city,
        gender: sbligender == '1' ? 'M' : 'F', //inputJson.gender
        dob: sbliDob,//inputJson.dob
        term: this.termLength,//inputJson.term
        zip: this.zipcode,//inputJson.zip
        state: this.state,//inputJson.state
        height: this.height,//inputJson.height
        weight: +this.weight,//inputJson.weight
        tobaccoUse: +sblitobacco,//inputJson.tobaccoUse
        riskClass: riskClassInput,
        coverage: this.coverageAmount,//inputJson.coverage
        premiumFrequency: 'm',
      };
    }
    else {
      cc = JSON.parse(cc);
      commonInput = {
        lastName: splitname[2] ? splitname[2] : 'Lastname',
        firstName: splitname[0] ? splitname[0] : 'Firstname',
        middleName: splitname[1] ? splitname[1] : 'MiddleName',
        addressType: 'PhysicalRisk',
        address: cc.address,//splitAddress[(splitAddress.length - 3)],
        city: cc.city,
        gender: sbligender == '1' ? 'M' : 'F', //inputJson.gender
        dob: cc.dob,//inputJson.dob
        term: this.termLength,//inputJson.term
        zip: cc.zip,//inputJson.zip
        state: cc.state,//inputJson.state
        height: this.height,//inputJson.height
        weight: +this.weight,//inputJson.weight
        tobaccoUse: +sblitobacco,//inputJson.tobaccoUse
        riskClass: riskClassInput,
        coverage: this.coverageAmount,//inputJson.coverage
        premiumFrequency: 'm',
      };
    }

    console.log('commonInput : ', commonInput);
    debugger;
    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.getQuickQuote2(commonInput)
      .then(response => {
        console.log('utput quickquote: ', response);
        this.quickQuoteData = response;
        if (this.quickQuoteData[0].head.carrier_name === 'Next Term Life' || this.quickQuoteData[0].head.carrier_name === 'Pac Life Next') {
          this.quickQuoteData[0].head.quote_amount = Number(this.quickQuoteData[0].head.quote_amount.replace('$', '')) / 30;
          this.quickQuoteData[0].head.quote_amount = (Math.round(this.quickQuoteData[0].head.quote_amount * 100) / 100).toFixed(2);
          document.getElementById('paclifeQuoteID').innerHTML = '$' + this.quickQuoteData[0].head.quote_amount + '/day';
          const formatedQuoteAmount = this.quickQuoteData[0].head.quote_amount.replace('$', '');
          const amount = `$${formatedQuoteAmount}`;
          this.storageService.setItem('bubble-QouteAmount', amount);
        }
        const amountwithoutdoller = this.quickQuoteData[0].head.quote_amount.replace('$', '');
        const amountwithdollar = '$' + amountwithoutdoller;
        debugger;
        const hubSpotData = {
          email: this.EmailID,
          application_stage: 'Application',
          //life_quote_amount: amountwithdollar,
          life_latest_premium_amount: amountwithdollar
        };
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

        debugger;
        // if (this.carrierName === "Pac Life Next" || this.carrierName === "Next Term Life") {
        //   this.selectedCardData = this.quickQuoteData[0];
        //   console.log('selectedCardData',this.selectedCardData );
        //   this.selectedCardData.head.quote_amount;

        //   this.pc.getAppData(localStorage.getItem('clientID')).then(
        //     data => {
        //       debugger
        //       let clientData = JSON.parse(data.Payload)
        //       console.log("this.pc.getAppData: ", clientData);
        //        this.paclifeQuote =  clientData.body.paclifeQuote.premiumAmount;
        //      document.getElementById('paclifeQuoteID').innerHTML=this.paclifeQuote+'/mo';
        //     }
        //   ).catch(error => {
        //     console.log(error);
        //   });
        //   debugger
        // }
        // else {
        //   this.selectedCardData = this.quickQuoteData[1];
        //   this.selectedCardData.head.quote_amount;
        //   console.log('selectedCardData',this.selectedCardData );
        //   debugger
        // }

      })
      .catch(error => {

      });
  }


  public submitdata(inputJson) {
    this.pc.saveUpdateClientData(inputJson);
  }

  public goBack() {
    //this.confirmDetails.emit({ component_id: 'GoToPrevPage' });
    location.href = `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
  }


  moveDatePicker() {
    setTimeout(() => {
      const x = document.getElementsByTagName('bs-datepicker-container')[0];
      console.log(x);
      document.getElementById('inputDatePicker').appendChild(x);
    }, 100);
  }

  phoneerror: boolean;

  phonelength() {
    // debugger
    if (this.PhoneNumber.length === 3) {
      this.PhoneNumber = this.PhoneNumber + '-';
      this.phoneerror = true;
    }
    else if (this.PhoneNumber.length === 7) {
      this.PhoneNumber = this.PhoneNumber + '-';
      this.phoneerror = true;
    }
    else if (this.PhoneNumber.length < 12) {
      this.phoneerror = true;
    }
    else {
      this.phoneerror = false;
    }
  }

  nameerror: boolean;
  checkfirstNamelength() {
    debugger;
    if (this.FirstName.length <= 1) {
      this.nameerror = true;
    }
    else if (this.FirstName === '') {
      this.nameerror = true;
    }
    else {
      this.nameerror = false;
    }
  }


}
