import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';

@Component({
  selector: 'app-sbli02001240',
  templateUrl: './sbli02001240.component.html',
  styleUrls: ['./sbli02001240.component.css']
})
export class Sbli02001240Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  isDisable: boolean;
  questions: any;
  drugAbuse: any;
  clientId: any;
  existingData: any;
  showLoader = true;
  questions2: any;
  guilty: any;
  questions3: any;
  convicted: any;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.clientId = localStorage.getItem('clientID');
    this.isDisable = true;
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli02001240')[0];
        this.questions2 = que.filter(x => x.component_id == 'app-sbli02101270')[0];
        this.questions3 = que.filter(x => x.component_id == 'app-sbli02701061')[0];
      }
      else {
        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli02001240')[0];
        this.questions2 = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli02101270')[0];
        this.questions3 = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli02701061')[0];
      }

      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_diagnosed_or_tratment_drug_abuse != undefined) {
        this.drugAbuse = this.existingData.sbli_diagnosed_or_tratment_drug_abuse;
      }
      if (this.existingData.sbli_guilty_felony != undefined) {
        this.guilty = this.existingData.sbli_guilty_felony;
      }
      if (this.existingData.sbli_convicted_reckless_driving != undefined) {
        this.convicted = this.existingData.sbli_convicted_reckless_driving;
      }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
  }

  capturedrugAbuse(value) {
    this.drugAbuse = value;
  }
  captureguilty(value) {
    this.guilty = value;
  }
  captureconvicted(value) {
    this.convicted = value;
  }

  next() {
    this.$gaService.event('SBLI LifeApplication Drugs', 'event','SBLI-LifeApplication-Drugs');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Drugs',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions,'SBLI LifeApplication Drugs', 'event','SBLI-LifeApplication-Drugs');
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    json.SBLI[this.questions.radio_options[0].answer_key] = this.drugAbuse;
    json.SBLI[this.questions2.radio_options[0].answer_key] = this.guilty;
    json.SBLI[this.questions3.radio_options[0].answer_key] = this.convicted;

    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

}
