import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sbli00901003',
  templateUrl: './sbli00901003.component.html',
  styleUrls: ['./sbli00901003.component.css']
})
export class Sbli00901003Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
