import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

@Component({
  selector: 'app-life-question2',
  templateUrl: './life-question2.component.html',
  styleUrls: ['./life-question2.component.css']
})
export class LifeQuestion2Component implements OnInit {

  @Output() nextquestion2 = new EventEmitter();
  height: string;
  weight: any;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  heightFt: string;
  heightIn: string;

  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    // window.addEventListener
    // ('beforeunload'
    //   , function (evt) {
    //     debugger
    //     evt.preventDefault();
    //     evt.returnValue = 'Hello';
    //     return "hello 2222"
    //   }
    // );
    this.pc.logPageLandingtoBO({ page_name: 'height_weight' });
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
    }
    );
    if (localStorage.getItem('bubble-heightFt') !== null) {
      this.heightFt = localStorage.getItem('bubble-heightFt');
      this.heightIn = localStorage.getItem('bubble-heightIn');
      this.weight = localStorage.getItem('bubble-weight');
    }
    if (this.heightFt == undefined || this.heightFt == null || this.heightFt == 'undefined') {
      this.heightFt = '5';
      localStorage.setItem('bubble-heightFt', this.heightFt);
    }
    if (this.heightIn == undefined || this.heightIn == null || this.heightIn == 'undefined') {
      this.heightIn = '10';
      localStorage.setItem('bubble-heightIn', this.heightIn);
    }
    if (this.weight == undefined || this.weight == null || this.weight == 'undefined') {
      this.weight = '150';//default weight "weight"
      localStorage.setItem('bubble-weight', this.weight);
    }

  }

  verifyFt() {
    // if (Number(this.heightFt)>6) {
    //   this.heightFt = '6';
    // }
    // if (Number(this.heightFt) < 4) {
    //   this.heightFt = '4';
    // }
  }

  verifyInch() {
    if (Number(this.heightIn) > 11) {
      this.heightIn = '11';
    }
    if (Number(this.heightIn) < 0) {
      this.heightIn = '1';
    }
  }

  next() {
    const json = {
      'id': this.clientId,
      // 'bubble-height': this.height,
      'bubble_heightFt': this.heightFt,
      'bubble_heightIn': this.heightIn,
      'bubble_weight': this.weight
    };
    this.pc.saveUpdateClientData(json);
    // localStorage.setItem('bubble-height',this.height);
    localStorage.setItem('bubble-heightFt', this.heightFt);
    localStorage.setItem('bubble-heightIn', this.heightIn);
    localStorage.setItem('bubble-weight', this.weight);
    const etty = {
      'questionNo': '2',
      // 'bubble-height': this.height,
      'bubble-heightFt': this.heightFt,
      'bubble-heightIn': this.heightIn,
      'weight': this.weight,
      'nextprev': 1
    };
    this.nextquestion2.emit(etty);

    this.$gaService.event('Proceed', 'event', 'MyLifeStep2Proceed');
    const eventOptions: EventOption = {
      path: 'QuoteFlowV2',
      page_title: 'Additional Details',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Proceed', 'event', 'MyLifeStep2Proceed');
  }

  previous() {
    const json = {
      'id': this.clientId,
      // 'bubble-height': this.height,
      'bubble_heightFt': this.heightFt,
      'bubble_heightIn': this.heightIn,
      'bubble_weight': this.weight
    };
    this.pc.saveUpdateClientData(json);
    // localStorage.setItem('bubble-height',this.height);
    localStorage.setItem('bubble-heightFt', this.heightFt);
    localStorage.setItem('bubble-heightIn', this.heightIn);
    localStorage.setItem('bubble-weight', this.weight);
    const etty = {
      'questionNo': '2',
      // 'bubble-height': this.height,
      'bubble-heightFt': this.heightFt,
      'bubble-heightIn': this.heightIn,
      'weight': this.weight,
      'nextprev': 0
    };
    this.nextquestion2.emit(etty);
  }
}
