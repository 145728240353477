import { Component, OnInit } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';

@Component({
  selector: 'app-customise',
  templateUrl: './customise.component.html',
  styleUrls: ['./customise.component.css']
})
export class CustomiseComponent implements OnInit {
  
  disableAddress = true;
  fullAddress: any;
  latitude: number = 39.7789467;
  longitude: number = -105.0477277;
  reqheight:any;
  addresstype = "Home Address";
  uniqueId: any;
  clientId: any;

  customQuestionsResponse: any = {
    livingSizeSQFT: "",
    homeType: ""
  };

  constructor(private pc: ProcessCacheService, private activatedroute: ActivatedRoute,private scoreApi: ScorecardApiService) { }

  customquestion: number;

  ngOnInit(): void { 
    if (window.innerWidth < 600) {
     this.reqheight = "height:40vh;";
    } else {
      this.reqheight = "height:100vh;";
    }
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.fullAddress = params['address'];
      console.log("uniqueId : ", this.uniqueId);
      console.log("clientId : ", this.clientId);
      console.log("displayAddress : ", this.fullAddress); 
      localStorage.setItem('bubble-gender',params['gender']);
      localStorage.setItem('bubble-age',params['age']);
    }
    )
    //this.processCache.initCaching(this.fullAddress);
    this.pc.getCachedData2();
    this.customquestion = 0;
    
  }

  public changeQuestion(even) {
    switch(even.questionNo) { 
      case "1": { 
        this.customQuestionsResponse.livingSizeSQFT = even.livingSizeSQFT;
        this.customQuestionsResponse.homeType = even.homeType;
        localStorage.setItem('customQuestionsResponse', this.customQuestionsResponse);
        console.log("stingyfy :", JSON.stringify(this.customQuestionsResponse));
        break; 
      }
      case "2": { 
        this.customQuestionsResponse.fullName = even.fullName;
        this.customQuestionsResponse.emailId = even.emailId;
        localStorage.setItem('customQuestionsResponse', this.customQuestionsResponse);
        console.log("stingyfy :", JSON.stringify(this.customQuestionsResponse));
        break; 
      } 
      case "3": { 
        this.customQuestionsResponse.dob = even.dob;
        localStorage.setItem('customQuestionsResponse', this.customQuestionsResponse);
        console.log("stingyfy :", JSON.stringify(this.customQuestionsResponse));
        break; 
      } 
      case "4": { 
        this.customQuestionsResponse.genderSelected = even.genderSelected;
        localStorage.setItem('customQuestionsResponse', this.customQuestionsResponse);
        console.log("stingyfy :", JSON.stringify(this.customQuestionsResponse));
        break; 
      } 
      case "5": { 
        this.customQuestionsResponse.height = even.height;
        this.customQuestionsResponse.weight = even.weight;
        localStorage.setItem('customQuestionsResponse', this.customQuestionsResponse);
        console.log("stingyfy :", JSON.stringify(this.customQuestionsResponse));
        break; 
      } 
      case "6": { 
        this.customQuestionsResponse.tobacoUser = even.tobacoUser;
        localStorage.setItem('customQuestionsResponse', this.customQuestionsResponse);
        console.log("stingyfy :", JSON.stringify(this.customQuestionsResponse));
        break; 
      } 
      case "7": { 
        this.customQuestionsResponse.coverage = even.coverage;
        this.customQuestionsResponse.termLength = even.termLength;
        localStorage.setItem('customQuestionsResponse', this.customQuestionsResponse);
        console.log("stingyfy :", JSON.stringify(this.customQuestionsResponse));
        location.href = "Quickquote/" + this.uniqueId + "/" + this.clientId + "/" + this.fullAddress;
        break; 
      } 
      default: { 
        //statements; 
        break; 
      } 
    }  
    if (even.nextprev==1) {
      this.customquestion = this.customquestion + 1;
    }
    else {
      this.customquestion = this.customquestion - 1;
    }
  }

  public next() {
    this.customquestion = this.customquestion + 1;
  }

  public previous() {
    this.customquestion = this.customquestion - 1;
  }

  public handleAddressChange(address: Address) {
    //this.processCache.getDataFromCache();
    // debugger
    console.log('address from google : ', address);
    console.log('formatted address : ', address.formatted_address);
    this.fullAddress = address.formatted_address;
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    this.pc.latitude = this.latitude;
    this.pc.longitude = this.longitude;
    /* console.log("@@---->", address.geometry.location);*/
    console.log("@@---->latitude", address.geometry.location.lat());
    console.log("@@---->longitude", address.geometry.location.lng());
    this.pc.initCaching(address.formatted_address);
    this.pc.getCachedData2();
  }

 

}
