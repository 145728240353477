import { Component, OnInit } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-app-flow-footer',
  templateUrl: './app-flow-footer.component.html',
  styleUrls: ['./app-flow-footer.component.css']
})
export class AppFlowFooterComponent implements OnInit {

  constructor(private pc: ProcessCacheService) { }
  public paclifeQuote: any;
  ngOnInit(): void {
    debugger
    let clientData = localStorage.getItem('clientdata');
    if (clientData) {
      this.setPremiumAndEtc(clientData);
    } else {
      this.pc.getAppData(localStorage.getItem('clientID')).then(
        data => {
          let clientData = JSON.parse(data.Payload);
          this.setPremiumAndEtc(clientData);
        }
      ).catch(error => {
        console.log("getAppData:error:", error);
      });
    }
  }
  setPremiumAndEtc(clientData) {
    debugger
    this.paclifeQuote = localStorage.getItem('bubble-QouteAmount');
    console.log("setPremiumAndEtc:this.paclifeQuote : ", this.paclifeQuote);
    if (!this.paclifeQuote) {
      console.log("setPremiumAndEtc:typeof clientData: ", typeof clientData);
      if (typeof clientData === "string") clientData = JSON.parse(clientData);
      console.log("clientData.body.paclifeQuote: ", clientData.body.paclifeQuote);
      console.log("clientData.body.paclifeQuote.premiumAmount: ", clientData.body.paclifeQuote.premiumAmount);
      if (clientData.body.paclifeQuote && clientData.body.paclifeQuote.premiumAmount) {
        this.paclifeQuote = clientData.body.paclifeQuote.premiumAmount;
        localStorage.setItem('bubble-QouteAmount', this.paclifeQuote);
      }
    }
    if (clientData && clientData.body && clientData.body.paclifeTicket) {
      localStorage.setItem('policyNo', clientData.body.paclifeTicket.policyNumber);
    }
    // Make sure we do not add a $ as the HTML had $ prefix
    if (this.paclifeQuote && typeof this.paclifeQuote === "string") {
      if (this.paclifeQuote.startsWith("$")) {
        this.paclifeQuote = this.paclifeQuote.replace('$', '');
      }
      if (this.paclifeQuote.endsWith("/day")) {
        this.paclifeQuote = this.paclifeQuote.replace('/day', '');
      }
      this.paclifeQuote = `${this.paclifeQuote}/day`;
    }
  }

}
