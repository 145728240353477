import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli02201930',
  templateUrl: './sbli02201930.component.html',
  styleUrls: ['./sbli02201930.component.css']
})
export class Sbli02201930Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  isDisable: boolean;
  questions: any;
  isCovid: any;
  isCovidRecent: string;
  isTreatment: any;
  clientId: any;
  existingData: any;
  showLoader = true;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');
    this.isDisable = true;
    // this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli02201930")[0];
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli02201930')[0];
        console.log('app-sbli02201930 questions : ', this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli02201930')[0];
        console.log('app-sbli02201930 questions : ', this.questions);
      }
      this.existingData = dataFrom.body.SBLI;

      if (this.existingData.sbli_diagnosed_or_treatment_Covid != undefined) {
        this.isCovid = this.existingData.sbli_diagnosed_or_treatment_Covid;
        if (this.isCovid == 'Yes' || this.isCovid == 'No') {
          this.isCovidDiagnosed(this.isCovid);
          this.isCovidRecent = this.existingData.sbli_covid_positive_last_30days;
          if (this.isCovidRecent == 'Yes' || this.isCovidRecent == 'No') {
            this.covidLast30Days(this.isCovidRecent);
            this.isTreatment = this.existingData.sbli_Covid_treatment;
            if (this.isTreatment == 'Yes' || this.isTreatment == 'No') {
              this.covidTreatment(this.isTreatment);
            }
            else {
              this.isDisable = false;
            }
          } else {
            this.isDisable = false;
          }
        } else {
          this.isDisable = false;
        }
      }


      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    console.log('app-sbli02201930 questions', this.questions);
  }


  isCovidDiagnosed(input) {
    this.isCovid = input;
    this.isCovidRecent = '';
    if (this.isCovid != '' && this.isCovid == this.questions.radio_options[1].answer_text) {
      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
  }


  covidLast30Days(input) {
    debugger;
    this.isCovidRecent = input;
    this.isTreatment = '';
    if (this.isCovid == this.questions.radio_options[0].answer_text && this.isCovidRecent != this.questions.radio_options_1[1].answer_text) {
      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
  }

  covidTreatment(input) {
    this.isTreatment = input;
    if (this.isTreatment != '') {
      this.isDisable = false;
    }
  }

  next() {
    this.$gaService.event('SBLI LifeApplication Covid', 'event', 'SBLI-LifeApplication-Covid');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Drugs',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Covid', 'event', 'SBLI-LifeApplication-Covid');
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };

    json.SBLI[this.questions.radio_options[0].answer_key] = this.isCovid;
    json.SBLI[this.questions.radio_options_1[0].answer_key] = this.isCovidRecent;
    json.SBLI[this.questions.radio_options_2[0].answer_key] = this.isTreatment;


    for (const propName in json.SBLI) {
      if (json.SBLI[propName] === null || json.SBLI[propName] === undefined || json.SBLI[propName] === '') {
        delete json.SBLI[propName];
      }
    }

    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

}
