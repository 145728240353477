import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { QuestionModel } from '../../models/question.model';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.css']
})
export class InputTextComponent implements OnInit {

  @Input() public question!: QuestionModel;

  childForm!: FormGroup;
  questionId: number | undefined;
  answerId!: string;
  formattedAmount: any;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    debugger;
    if (this.question['showamount']) {
      this.formattedAmount = localStorage.getItem('bubble-annualIncome');
      if (this.formattedAmount?.includes(',')) {
        this.formattedAmount = localStorage.getItem('bubble-annualIncome');
      }
      else {
        this.formattedAmount = localStorage.getItem('bubble-annualIncome');
        const ctd = this.formattedAmount;
        this.formattedAmount = ctd?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    }
    this.childForm = this.rootFormGroup.form as FormGroup;
    this.answerId = 'answer_value_' + this.childForm.value.question_id;
    this.formattedAmount = this.formattedAmount ? this.formattedAmount : this.childForm.value[this.answerId];
    this.childForm.controls['selected_answer'].setValue(this.formattedAmount);
    this.questionId = this.question.questionID;

    this.question['required'] = 1;
    this.question.validation ? this.question.validation : '^[a-zA-Z0-9_ -.]*$';
    const _validator: any[] = [];
    if (this.question?.required == 1) _validator.push(Validators.required);
    if (this.question?.validation != undefined) _validator.push(Validators.pattern(this.question?.validation));

    this.childForm.get(this.answerId)?.setValidators(_validator);
    this.childForm.get(this.answerId)?.updateValueAndValidity();
  }

  formatIncome() {
    debugger;
    this.formattedAmount = this.childForm.value[this.answerId].replace(/,/g, '');
    const ctd = this.formattedAmount;
    this.formattedAmount = ctd?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    console.log('formattedAmount : ', this.formattedAmount);
    if (this.formattedAmount?.includes(',')) {
      localStorage.setItem('bubble-annualIncome', this.formattedAmount?.replace(/,/g, ''));
    }
    else {
      localStorage.setItem('bubble-annualIncome', this.formattedAmount);
    }
    this.childForm.controls['selected_answer'].setValue(this.formattedAmount);
  }


}
