import { Component, OnInit, Output } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TrackerService } from 'src/app/shared/tracker.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { ProfileDataHandlerService } from 'src/app/shared/profile-data-handler.service';
import { PrefillserviceService } from '../../shared/prefillservice.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-quote-flow-v2-life',
  templateUrl: './quote-flow-v2-life.component.html',
  styleUrls: ['./quote-flow-v2-life.component.css']
})

export class QuoteFlowV2LifeComponent implements OnInit {
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  gender: any;
  age: any;
  lifeScoreData: any;
  allScores: any;
  insurancetype: any;
  customquestion: any;
  selectedCard: number;
  carrier: any;
  imgurl: any;
  amount: any;
  imgwid: any;
  stateCode: string;
  predictedScoreData: any;
  totalPages = 10;
  previous: any;
  addressToShow: any;
  isMobileResolution: boolean;
  isDescktopResolution: boolean;
  loader = false;
  carrierName: any;
  carrierAmount: any;
  homeselectedCard: number;
  spinner: boolean;

  constructor(private utilityService: UtilityFunctionsService,
    private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private profileService: ProfileDataHandlerService,
    private tracker: TrackerService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private Prefillservice: PrefillserviceService) { }


  @Output() loaderText = [
    {
      'title1': 'Calculating coverage amount',
      'Subtitle1': 'Add more at a later time if you need to',
      'title2': 'Calculating term',
      'Subtitle2': 'Extend it or reduce as you see needed',
      'title3': 'Estimating premium',
      'Subtitle3': 'Lock it in for decades'
    }];

  ngOnInit(): void {
    //;
    this.spinner = true;
    // this.processCache.invokeProcess("QuoteFlow");

    /* if((localStorage.getItem("accessChecked") !== null && localStorage.getItem("accessChecked") == "0") || localStorage.getItem("accessChecked") === null){
      // this.processCache.checkAccessibility();
      this.loader = true;
    } */

    if (window.innerWidth < 600) {
      this.isMobileResolution = true;
      this.isDescktopResolution = false;

    } else {
      this.isMobileResolution = false;
      this.isDescktopResolution = true;

    }

    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      localStorage.setItem('insuranceType', this.insurancetype);
      this.previous = params['previous'];
      this.addressToShow = this.displayAddress.replace(/-/g, ' ');
      this.addressToShow = this.addressToShow.replace(/%20/g, ' ');
      this.addressToShow = this.addressToShow.replace(/%252520/g, ' ');
      let appFlowDebug = params['appFlowDebug'];
      if (!appFlowDebug) {
        appFlowDebug = 0; // 0 -> No debug, 1-> Debug -> Show the Begin Application button
      }
      localStorage.setItem('clientID', this.clientId);
      localStorage.setItem('appFlowDebug', appFlowDebug);
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
      console.log('appFlowDebug : ', appFlowDebug);
      const { insuredZipcode: insuredZipCode } = this.profileService.getSetCityZipStateFromURL(this.displayAddress);
      localStorage.setItem('bubble-zip', insuredZipCode);
      this.Prefillservice.saveAddressDetails(insuredZipCode, this.clientId);
    }
    );
    this.pc.initCaching(this.displayAddress, 'QuoteFlow');
    if (this.previous == 1) {
      if (this.insurancetype == 'homeLife') {
        this.customquestion = 3;
      }
      else {
        this.customquestion = 4;
      }
    }
    else {
      this.customquestion = 0;
    }

    this.pc.castpredictedScores.subscribe(
      (data: any) => {
        this.predictedScoreData = data;
        this.amount = this.predictedScoreData.lifeInsuranceAmount;
        if (this.amount != 'No Data') {
          this.amount = '$' + this.amount;
        }
        localStorage.setItem('bubble_lifeInsuranceAmount', this.amount.replace('$', '').replace(/,/g, ''));
        console.log('this.predictedScoreData.lifeInsuranceAmount', this.predictedScoreData.lifeInsuranceAmount);
      },
      (error) => console.log(error)
    );

    const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
    if (selectedHome && selectedHome.head) {
      this.carrierName = selectedHome.head.carrier_name;
      this.carrierAmount = selectedHome.head.quote_amount;
    } else {
      console.log('ngOnInit selectedHomeCarrier not found in Cache');
    }
    this.homeselectedCard = 1;
    this.selectedCard = 0;
    this.imgwid = '40px';
    this.tracker.changeTrackingType('life');

    if (localStorage.getItem('appFlowLinkResume') == '1') {
      localStorage.setItem('appFlowLinkResume', '0');
      this.checkforResumeInit({}, 'fetch').then(
        resumeData => {
          console.log('checkforResumeInit home-life-quote', resumeData);
          if (resumeData['insuranceType'] == 'home-life-quote' || resumeData['insuranceType'] == 'life-quote') {
            if (resumeData['otherAppflowScreen'] == 'life-question1') {
              this.customquestion = 0;
            } else if (resumeData['otherAppflowScreen'] == 'life-question2') {
              this.customquestion = 1;
            } else if (resumeData['otherAppflowScreen'] == 'life-question3') {
              this.customquestion = 2;
            } else if (resumeData['otherAppflowScreen'] == 'life-question4') {
              this.customquestion = 3;
            } else if (resumeData['otherAppflowScreen'] == 'contact-information') {
              this.customquestion = 4;
            } else {
              this.customquestion = 0;
            }
          } else {
            this.customquestion = 0;
          }
        }).catch(error => console.log(error));
    } else {
      const createJson = {
        insuranceType: 'life-quote',
        carrierName: '',
        appStartStatus: false,
        appCompletedStatus: false,
        questionStartStatus: false,
        questionCompletedStatus: false,
        questionSequence: '0',
        applicationId: '',
        otherAppflowScreen: 'life-question1',
        resumeUrl: this.router.url
      };
      this.checkforResumeInit(createJson, 'create');
    }

    this.getcachedata();


    const insuranceType = localStorage.getItem('insuranceType');
    if (insuranceType == 'homeLife') {
      this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 6 });
    } else {
      this.StatusTrackerPercentage(1);
    }
    //this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.customquestion + 1 })
  }

  mapLeadSource = {
    'HomePros': 'HomePros',
    JPAR: 'JPAR',
    HomeSmart: 'HomeSmart',
    StarOne: 'StarOne'
  };

  changeQuestion(even) {

    const insuranceType = localStorage.getItem('insuranceType');
    if ((insuranceType == 'life' && even.questionNo == '5') || (insuranceType == 'homeLife' && even.questionNo == '4')) {
      this.spinner = true;
    }
    if (insuranceType == 'homeLife') {
      if (even.questionNo == '1')
        this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 6 });
      if (even.questionNo == '2')
        this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 7 });
      if (even.questionNo == '3')
        this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 8 });

    } else {
      this.StatusTrackerPercentage(even.questionNo);
    }

    if (even.questionNo == '5') {
      // this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 5 })
      localStorage.setItem('trackerPercent', '50');
      //   location.href = `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      const updateJson = {
        otherAppflowScreen: 'select-life-carrier',
        resumeUrl: `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`
        // resumeUrl: `/SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`
      };
      this.checkforResumeInit(updateJson, 'update').then(
        data => {
          this.router.navigateByUrl(`SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`);
          //this.router.navigateByUrl(`/back9-reco/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`);
        }
      ).catch(error => console.log(error));

    }
    else if (even.nextprev == 1) {

      if (this.customquestion == 3 && this.insurancetype == 'homeLife') {
        // this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 5 })
        localStorage.setItem('trackerPercent', '50');
        //   location.href = `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
        const updateJson = {
          otherAppflowScreen: 'select-life-carrier',
          resumeUrl: `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`
        };
        this.checkforResumeInit(updateJson, 'update').then(
          data => {
            this.router.navigateByUrl(`SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`);
          }
        ).catch(error => console.log(error));
      }
      else if (this.customquestion == 4 && this.insurancetype == 'life') {
        // this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 5 })
        localStorage.setItem('trackerPercent', '50');
        //   location.href = `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
        const updateJson = {
          otherAppflowScreen: 'select-life-carrier',
          resumeUrl: `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`
        };
        this.checkforResumeInit(updateJson, 'update').then(
          data => {
            this.router.navigateByUrl(`SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`);
          }
        ).catch(error => console.log(error));
      }
      else {
        this.customquestion = this.customquestion + 1;
      }


      // resume logic
      const cm = {
        '0': 'life-question1',
        '1': 'life-question2',
        '2': 'life-question3',
        '3': 'life-question4',
        '4': 'contact-information',
      };
      const updateJson = {
        otherAppflowScreen: cm[this.customquestion],
      };
      this.checkforResumeInit(updateJson, 'update');

    }
    else {
      this.customquestion = this.customquestion - 1;
    }
    // if (even.questionNo != "5")
    // this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 4 })
    this.getcachedata();

    if (even.questionNo == '3') {

      let smoking = localStorage.getItem('bubble-tobacco');
      if (smoking != '5') {
        smoking = localStorage.getItem('bubble-tobaccoUsageDuration');
      }
      const gender = localStorage.getItem('bubble-gender');
      const height = localStorage.getItem('bubble-heightFt') + 'ft ' + localStorage.getItem('bubble-heightIn') + 'inch';
      const weight = localStorage.getItem('bubble-weight');
      this.pc.getPacLifeRiskClass(smoking, gender, height, weight).then(response => {
        ////debugger
        let PacLifeRiskClass = response.data.output;
        //console.log('PacLifeRiskClass :', PacLifeRiskClass);
        const dp = JSON.parse(PacLifeRiskClass);
        console.log('PacLifeRiskClass :', dp.dput1);
        PacLifeRiskClass = dp.dput1;
        localStorage.setItem('riskClass', PacLifeRiskClass);
      })
        .catch(error => {
          console.log(error);
        });
    }
  }


  stopLoader(event) {
    this.spinner = false;
  }


  StatusTrackerPercentage(question) {

    if (question == '1') {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 2 });
    } else if (question == '2') {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 3 });
    }
    else if (question == '3') {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 4 });
    }
    else if (question == '4') {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 5 });
    }
    else if (question == '5') {
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: 6 });
    }
  }



  getcachedata() {
    this.selectedCard = 1;
    this.carrier = 'Life Insurance';
    this.imgurl = 'assets/images/active/icon-li-active.svg';
    setTimeout(() => {
      this.pc.getCostFromCache('lifeInsuranceAmount');
    }, 1000);
  }

  async setLeadSourceMap() {
    const resp = await fetch(environment.Nest_API_URL + '/get-lead-to-url');
    const data = await resp.json();
    this.mapLeadSource = data;
  }

  previousQuestion() {
    const eventOptionsPart1 = {
      'path': 'quoteFlowV2Life',
      'page_title': 'quoteFlowV2Life', 'url': window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', this.insurancetype, {
      'event_category': 'BackButton', 'event_label': 'Back'
    });


    if (this.customquestion == 0 && this.insurancetype == 'life') {
      const lead_source = localStorage.getItem('lead_source');
      console.log(this.pc.envCred.url, 'url', this.mapLeadSource[lead_source] || '');
      // location.href = this.pc.envCred.url + '/' + this.mapLeadSource[lead_source] || '';
    }
    if (this.customquestion == 0 && (this.insurancetype == 'home' || this.insurancetype == 'homeLife')) {
      document.getElementById('makeblur').style.filter = 'blur(10px)';
      document.getElementById('confirmHomeBack').style.display = 'block';
      //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/"+this.insurancetype ;
    }
    else {
      if (this.customquestion != 0) {
        this.customquestion = this.customquestion - 1;
      }
      else {
        location.href = this.pc.envCred.url;
      }

      // this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.customquestion })
    }
    // if (this.customquestion != 0) {
    //   this.customquestion = this.customquestion - 1;
    //   this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: this.customquestion })
    // }
  }

  closeConfirmHomeBack(type) {
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('confirmHomeBack').style.display = 'none';
    if (type == 'proceed') {
      this.loader = true;
      location.href = `select-home-carrierv2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
    }
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    this.stopLoader(0);
    return clientData;
  }
  changezipcode(address) {
    //  ;
    this.displayAddress = address;
    this.addressToShow = address;
  }
}
