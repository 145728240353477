import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from "../../../appUtility/appUtility.js";
@Component({
  selector: 'app-sbli02901680',
  templateUrl: './sbli02901680.component.html',
  styleUrls: ['./sbli02901680.component.css']
})
export class Sbli02901680Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  isDisable: boolean;
  isHospitalised: any;
  questions: any;
  showLoader: boolean = true;
  clientId: any;
  existingData: any;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');
    // this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli02901680")[0];
    // console.log("app-sbli02901680 questions", this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        let que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == "app-sbli02901680")[0];
        console.log("app-sbli02901680 questions : ", this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli02901680")[0];
        console.log("app-sbli02901680 questions : ", this.questions);
      }

      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_hospitalized_other_reason != undefined) {
        this.isHospitalised = this.existingData.sbli_hospitalized_other_reason;
      }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });

  }


  // hospitalised(input) {
  //   this.isHospitalised = input;
  //   let etty = {
  //     "next": 1,
  //     "previous": 0,
  //   };
  //   this.changeQuestion.emit(etty);
  // }

  hospitalised(input) {
    debugger;
    this.isHospitalised = input;
    var json = {
      'id': this.clientId,
      SBLI: this.existingData
    }

    json.SBLI[this.questions.radio_options[0].answer_key] = this.isHospitalised;


    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "APICall": 0,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }

}
