import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { TrackerService } from 'src/app/shared/tracker.service';

@Component({
  selector: 'app-pizza-tracker',
  templateUrl: './pizza-tracker.component.html',
  styleUrls: ['./pizza-tracker.component.css']
})
export class PizzaTrackerComponent implements OnInit, OnDestroy {

  percent;
  sub: Subscription;
  pathOrange: string;
  pathPaleGrey: string;
  ApllicationID: any;
  clientid: string;
  @Input() stillwater = false;
  constructor(private tracker: TrackerService, private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger;
    this.ApllicationID = this.pc.getApplicationId(localStorage.getItem('quoteId'));
    this.clientid = this.ApllicationID?.split('_')[0] || '';
    localStorage.setItem('bubble-ApplicationID', this.ApllicationID);
    this.ApllicationID = localStorage.getItem('bubble-ApplicationID');
    if (this.ApllicationID == null || this.ApllicationID == undefined || this.ApllicationID === 'undefined') {
      const random = this.randomnumber(5);
      this.clientid = localStorage.getItem('clientID');
      if (this.clientid == null) {
        this.clientid = localStorage.getItem('clientId');
      }
      this.ApllicationID = this.clientid + '-' + random;
    }
    else {
      if (this.ApllicationID.includes('-')) {
        const app = this.ApllicationID.split('-');
        if (app[0] === 'null' || app[0] == undefined || app[0] === 'undefined') {
          const clientid = localStorage.getItem('clientID');
          if (clientid == null) {
            const clientid = localStorage.getItem('clientId');
            app[0] = clientid;
          }
          else {
            app[0] = clientid;
          }
          this.ApllicationID = app[0] + '-' + app[1];
          localStorage.setItem('bubble-ApplicationID', this.ApllicationID);
        }
        else if (this.ApllicationID.includes('economy') || this.ApllicationID.includes('best') || this.ApllicationID.includes('expanded') || this.ApllicationID.includes('plan')) {
          this.ApllicationID = this.ApllicationID.replace('_economy', "").replace('_best', "").replace('_expanded', "").replace('_plan_4', "").replace(/_/g, "-");
        }
        else {
          this.ApllicationID = this.ApllicationID;
        }
        // else if (this.ApllicationID.includes('undefined')) {
        //   this.ApllicationID = this.ApllicationID.replace('-undefined', "");
        // }
        // else {
        //   const app1 = this.ApllicationID.split('_');
        //   this.ApllicationID = app1[0] + '-' + app1[1];
        // }
      }
    }
    this.sub = this.tracker.getProgressStatus().subscribe(value => {
      this.percent = value;
      if (this.percent > 100) this.percent = 100;
      this.pathOrange = this.describeArc(24, 24, 18, 0, this.percent * 3.6);
      this.pathPaleGrey = this.describeArc(24, 24, 18, this.percent * 3.6, 360);

    });

  }

  polarToCartesian(centerX: number, centerY: number, radius: number, angleInDegrees: number) {
    const angleInRadians = (angleInDegrees - 90) * Math.PI / 180.0;

    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }

  describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string {

    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const d = [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
    ].join(' ');

    return d;
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  public randomnumber(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }
}
