import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-question2',
  templateUrl: './question2.component.html',
  styleUrls: ['./question2.component.css']
})
export class Question2Component implements OnInit {

  @Output() nextquestion2 = new EventEmitter();

  fullName: string;
  emailId: string;

  constructor() { }

  ngOnInit(): void {
    if (localStorage.getItem('bubble-fullName') !== null) {
      this.fullName = localStorage.getItem('bubble-fullName');
      this.emailId = localStorage.getItem('bubble-email');
    }
    if (this.fullName == undefined || this.fullName == null || this.fullName == "undefined") {
      this.fullName = "";
    }
    if (this.emailId == undefined || this.emailId == null || this.emailId == "undefined") {
      this.emailId = "";
    }
  }

  next() {
    localStorage.setItem('bubble-fullName', this.fullName);
    localStorage.setItem('bubble-email', this.emailId);
    console.log("Question 1 : ", this.fullName, this.emailId);
    let etty = {
      "questionNo": "2",
      "fullName": this.fullName,
      "emailId": this.emailId,
      "nextprev": 1
    }
    this.nextquestion2.emit(etty);
  }

  previous() {
    let etty = {
      "questionNo": "2",
      "fullName": this.fullName,
      "emailId": this.emailId,
      "nextprev": 0
    }
    this.nextquestion2.emit(etty);
  }

}
