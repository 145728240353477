import { Injectable } from '@angular/core';
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "./../../environments/environment";
import { ProfileDataHandlerService } from 'src/app/shared/profile-data-handler.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { LifescoreErrorAlertComponent } from "../components/lifescore-error-alert/lifescore-error-alert.component";
import { ValidationService } from 'src/app/shared/validation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { ErroralertModalComponent } from '../components/erroralert-modal/erroralert-modal.component';
import { timeStamp } from 'console';
import { throwServerError } from '@apollo/client/core';
const axios = require("axios").default;

declare var require: any;
const pc = require('bit-uibl-data-driver');

@Injectable({
  providedIn: 'root'
})
export class QuinstreetService {
  email: any;
  firstname: any;
  lastname: any;
  address: any;
  address_line_2: any;
  city: any;
  country: any;
  state: any;
  zip: any;
  phone: any;
  date_of_birth: any;
  gender: any;
  marital_status: any;
  interest_type: any;
  home_type: any;
  dwelling_use: any;
  number_of_units: any;
  number_of_claims: any;
  property_year_built: any;
  stories: any;
  home_insurance_type: string;
  property_living_area: any;
  roof_updated_year: any;
  year_built: any;
  living_size: any;
  fullAddress: string;
  clientId: any;
  loader: boolean;
  prevEmail: any;
  dropoffURL: any;
  rooftype: any;
  clientData: any;
  uniqueId: any;
  insuranceType: string;
  iType: string;
  recoType: string;
  construction_type: any;
  swimming_pool: string;
  roof_type: string;
  credit_type: any;
  alternatephone: any;
  gendertohubspot: any;
  age: any;
  bundle_auto_with_home: any;
  pftoken: any;
  date_of_birthtohubspot: any;
  quinstreetData: Object;
  typeid: any;
  emailtoprefill: any;
  constructor(public router: Router, private http: HttpClient, private pc: ProcessCacheService, private modalService: NgbModal, private Http: HttpClient, private utilityService: UtilityFunctionsService) { }
  ngOnInit(incoimgid): void {
    console.log("Quinstreet pfid", incoimgid);
    debugger
    this.pftoken = incoimgid;
    this.http.get(`https://www.nextinsure.com/listingdisplay/prefill?pf=${incoimgid}`).subscribe(data => {
      debugger
      if (data['Status'] == 'success') {
        data = data;
      }
      else {
        location.href = `/prefillResumeFlowHoi?lead_source=Quinstreet_click&pftoken=${this.pftoken}`;
      }
      this.quinstreetData = data;
      localStorage.clear();
      localStorage.setItem("insuranceType", "home");
      this.clientId = this.utilityService.getNewRandomToken();
      let url = window.location.href;
      this.dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
      this.email = data['DataPassData']['Contact'].Email;
      localStorage.setItem("bubble-email", this.email);
      localStorage.setItem("bubble-emailid", this.email);
      this.firstname = data['DataPassData']['Contact'].FirstName;
      localStorage.setItem("bubble-fname", this.firstname);
      this.lastname = data['DataPassData']['Contact'].LastName;
      localStorage.setItem("bubble-lname", this.lastname);
      localStorage.setItem("bubble-fullName", `${this.firstname} ${this.lastname}`);
      this.address = data['DataPassData']['Contact'].Address;
      this.address_line_2 = data['DataPassData']['Contact'].Address2;
      this.city = data['DataPassData']['Contact'].City;
      this.state = data['DataPassData']['Contact'].StateCode;
      this.country = "US";
      this.zip = data['DataPassData']['Contact'].ZipCode;
      this.phone = data['DataPassData']['Contact'].HomePhone;
      this.alternatephone = data['DataPassData']['Contact'].WorkPhone;
      localStorage.setItem('bubble-zip', this.zip);
      if (data['DataPassData']['Individuals']) {
        this.age = data['DataPassData']['Individuals'];
        this.date_of_birth = data['DataPassData']['Individuals'][0].BirthDate;
        this.marital_status = data['DataPassData']['Individuals'][0].MaritalStatus;
        this.gender = data['DataPassData']['Individuals'][0].Gender;
        this.credit_type = data['DataPassData']['Individuals'][0].SelfCreditRating;
        if (this.gender != null && this.gender != undefined) {
          if (this.gender.toLowerCase() == 'm') {
            localStorage.setItem('bubble-gender', '1');
            this.gendertohubspot = '1';
          }
          else if (this.gender.toLowerCase() == 'f') {
            localStorage.setItem('bubble-gender', '0');
            this.gendertohubspot = '0';
          }
        }
        this.date_of_birthtohubspot = this.date_of_birth;
        let dobparam = new Date(this.date_of_birth);
        let year = Number(dobparam.getFullYear());
        let month = '' + (dobparam.getMonth() + 1);
        let day = '' + dobparam.getDate();
        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        this.date_of_birth = month + '/' + day + '/' + year;
      }
      if (data['DataPassData']['RequestedCoverage']) {
        this.bundle_auto_with_home = data['DataPassData']['RequestedCoverage'].BundleAutoInsurance;
      }
      if (data['DataPassData']['Claims']) {
        this.number_of_claims = data['DataPassData']['Claims'][0].MonthOfClaim;
      }
      if (data['DataPassData']['HomeProperties']) {
        this.property_year_built = data['DataPassData']['HomeProperties'][0].YearBuilt;
        this.property_living_area = data['DataPassData']['HomeProperties'][0].SquareFeet;
        this.stories = data['DataPassData']['HomeProperties'][0].Stories;
      }
      //this.home_insurance_type = 'home';

      localStorage.setItem('storiestoprefill', this.stories);
      this.roof_updated_year = '2020'
      this.year_built = this.property_year_built;
      this.living_size = this.property_living_area;
      this.construction_type = "F";
      this.roof_type = "ARCH";
      this.swimming_pool = "No";

      this.interest_type = 'Home';
      this.home_type = 'SingleFamilyHome';
      if (this.date_of_birth == undefined || this.date_of_birth == null || this.date_of_birth == '') {
        this.date_of_birth = this.date_of_birth;
        localStorage.setItem("bubble-dob", this.date_of_birth);
      } else {
        localStorage.setItem("bubble-dob", this.date_of_birth);
      }
      if (this.phone == undefined || this.phone == null || this.phone == '') {
        this.phone = '';
      } else {

        this.phone = this.phone;
      }
      if (this.address == undefined || this.address == null || this.address == '') {
        this.fullAddress = '';
      } else {
        this.fullAddress = this.address + "-" + this.city + "-" + this.state + "-" + this.zip;
        this.fullAddress = this.fullAddress.replace(/-/g, " ");
      }
      console.log("fullAddress fullAddress", this.fullAddress);
      this.pc.fullAddress = this.fullAddress;
      if (this.email == null || this.address == null || this.state == null || this.city == null || this.zip == null || this.email == '') {
        this.openModal();
      }
      else {
        this.generateClientData();
      }


    })


  }
  openModal() {
    debugger
    // var message = "Our servers are taking too long to respond.Please try after sometime";
    const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    if (this.email != null) {
      let hubSpotData = {
        client_id: this.clientId,
        customer_id: customerIdLocal,
        email: this.email
      };
      this.emailtoprefill = this.email;
      hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    else {
      let today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      let datetime = yyyy + '-' + mm + '-' + dd + ' ' + today.getHours() + ':' + today.getMinutes() + ':00';
      var prfix = Math.floor(Math.random() * 10000) + 1;
      this.email = prfix + '_' + datetime + '@getmybubble.com';

      // const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
      const idMappingJson = {
        client_id: this.clientId,
        customer_id: customerIdLocal,
        email_id: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        status: this.interest_type,
        interest_type: this.interest_type,
        dob: this.date_of_birth
      };
      this.pc.createIdMapping(idMappingJson);
      console.log("Lead:createIdMapping:idMappingJson:", idMappingJson);
    }

    let clientDataJson = {
      'id': this.clientId,
      "country": this.country,
      "bubble_email": this.email,
      "email": this.email,
      "bubble_fname": this.firstname,
      "bubble_lname": this.lastname,
      "address": (this.address) == null ? '' : this.address,
      "city_name": this.city,
      "stateCode": this.state,
      "state_name": this.state,
      "zip_code": this.zip,
      "bubble_phone": this.phone,
      "dob": this.date_of_birth,
      "gender": this.gender,
      "insurancetype": this.interest_type,
      "interest_type": this.interest_type,
      "yearBuilt": this.year_built,
      "livingArea": this.living_size,
      "roofBuiltYear": this.roof_updated_year,
      "fullAddress": this.fullAddress,
      "prefill_insurancetype": this.interest_type,
      "prefill_interest_type": this.interest_type,
      "prefill_yearBuilt": this.year_built,
      "prefill_livingArea": this.living_size,
      "prefill_roofBuiltYear": this.roof_updated_year,
      "prefill_marital_status": this.marital_status,
      "prefill_home_type": this.home_type,
      "prefill_dwelling_use": this.dwelling_use,
      "prefill_number_of_units": this.number_of_units,
      "prefill_number_of_claims": this.number_of_claims,
      "prefill_home_insurance_type": this.home_insurance_type,
      "prefill_stories": this.stories,
      "swquote_question": [],
      "roof_type": this.rooftype,
      "quinstreet_token": this.pftoken,
      'quinstreetData': JSON.stringify(this.quinstreetData)
    }

    this.pc.saveUpdateClientData(clientDataJson).then(data => {
      debugger
      location.href = `/prefillResumeFlowHoi?lead_source=Quinstreet_click&email=${this.emailtoprefill}&address=${this.address}&state=${this.state}&city=${this.city}&country=${this.country}&zip=${this.zip}&date_of_birth=${this.date_of_birth}&property_year_built=${this.year_built}&property_living_area=${this.living_size}&firstname=${this.firstname}&lastname=${this.lastname}&gender=${this.gender}&phone=${this.phone}&pftoken=${this.pftoken}`;
    }).catch(error => {
      console.log(error);
    });






    // const modalRef = this.modalService.open(ErroralertModalComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //   });

    // let data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   message: message,
    //   image: 'assets/images/images/education2.png'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    // }, (reason) => {
    // });
  }



  async generateClientData() {
    debugger
    let clientDataJson = {
      "client_id": this.clientId,
      "country": this.country,
      "bubble_email": this.email,
      "email": this.email,
      "bubble_fname": this.firstname,
      "bubble_lname": this.lastname,
      "address": this.address,
      "city_name": this.city,
      "stateCode": this.state,
      "state_name": this.state,
      "zip_code": this.zip,
      "bubble_phone": this.phone,
      "dob": this.date_of_birth,
      "gender": this.gender,
      "insurancetype": this.interest_type,
      "interest_type": this.interest_type,
      "yearBuilt": this.year_built,
      "livingArea": this.living_size,
      "roofBuiltYear": this.roof_updated_year,
      "fullAddress": this.fullAddress,
      "prefill_insurancetype": this.interest_type,
      "prefill_interest_type": this.interest_type,
      "prefill_yearBuilt": this.property_year_built,
      "prefill_livingArea": this.property_living_area,
      "prefill_roofBuiltYear": this.roof_updated_year,
      "prefill_marital_status": this.marital_status,
      "prefill_home_type": this.home_type,
      "prefill_dwelling_use": this.dwelling_use,
      "prefill_number_of_units": this.number_of_units,
      "prefill_number_of_claims": this.number_of_claims,
      "prefill_home_insurance_type": this.home_insurance_type,
      "prefill_stories": this.stories,
      "comfirmDetailsDateOfBirth": this.date_of_birth,
      "comfirmDetailsGender": this.gender,
    }
    console.log("clientDataJson", clientDataJson);
    await this.Http.get<any>(`${this.pc.envCred.api2Domain}?data_category=m1144188134176150116232225133160147155103`, { params: clientDataJson }).subscribe(async (data: any) => {
      let response = data;
      console.log("post res success : ", response);
      // this.clientId = response.clientId;
      localStorage.setItem("clientID", this.clientId);
      localStorage.setItem("clientId", this.clientId);
      let eventOptionsPart1 = {
        "path": "quinstreetService",
        "page_title": "quinstreetService", "url": window.location.href
      };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
        'event_category': "client_id", 'event_label': this.clientId
      });
      let quickquote = new pc.Quotes(this.clientId);
      let propertyresponse = quickquote.getSWKnockoutQuestionsByState(this.state, this.year_built);
      console.log("Knockout Questions: ", propertyresponse);

      console.log("generateClientData().then:this.clientId:", this.clientId);

      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
        'event_category': "Page", 'event_label': "PageLoadCompleted"
      });
      this.loader = false;
      await this.invokeBrushForest();

    });

  }


  cookupCustomerId(firstname, lastname, clientId) {
    let firstNameLocal, lastNameLocal;
    try { firstNameLocal = firstname[0].toLowerCase(); } catch (error) { firstNameLocal = 'a' };
    try { lastNameLocal = lastname[0].toLowerCase(); } catch (error) { lastNameLocal = 'b' };
    return `${firstNameLocal}${lastNameLocal}${clientId}`;
  }



  closeModal() {
    let eventOptionsPart1 = {
      "path": "prefillService",
      "page_title": "prefillService", "url": window.location.href
    };
    document.getElementById('errorModal').style.display = 'none';
    window.location.href = this.pc.envCred.url;
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
      'event_category': "Error", 'event_label': "Popup Closed"
    });
  }
  public async saveupdateclient() {
    debugger
    this.loader = true;
    // let eventOptionsPart1 = {
    //   "path": "prefillService",
    //   "page_title": "prefillService", "url": window.location.href
    // };

    // if (this.prevEmail != this.email) {
    //   const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    //   localStorage.setItem('customer_id', customerIdLocal);
    //   this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
    //     'event_category': "Edited", 'event_label': "Email"
    //   });
    //   let hubSpotData = {
    //     interest_type: this.interest_type,
    //     client_id: this.clientId,
    //     customer_id: customerIdLocal,
    //     application_stage: "Lead"
    //   };
    //   hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
    //   debugger;
    //   this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    // }
    let qid = [];

    let clientDataJson = {
      'id': this.clientId,
      "country": this.country,
      "bubble_email": this.email,
      "email": this.email,
      "bubble_fname": this.firstname,
      "bubble_lname": this.lastname,
      "address": this.address,
      "city_name": this.city,
      "stateCode": this.state,
      "state_name": this.state,
      "zip_code": this.zip,
      "bubble_phone": this.phone,
      "dob": this.date_of_birth,
      "gender": this.gender,
      "insurancetype": this.interest_type,
      "interest_type": this.interest_type,
      "yearBuilt": this.year_built,
      "livingArea": this.living_size,
      "roofBuiltYear": this.roof_updated_year,
      "fullAddress": this.fullAddress,
      "prefill_insurancetype": this.interest_type,
      "prefill_interest_type": this.interest_type,
      "prefill_yearBuilt": this.year_built,
      "prefill_livingArea": this.living_size,
      "prefill_roofBuiltYear": this.roof_updated_year,
      "prefill_marital_status": this.marital_status,
      "prefill_home_type": this.home_type,
      "prefill_dwelling_use": this.dwelling_use,
      "prefill_number_of_units": this.number_of_units,
      "prefill_number_of_claims": this.number_of_claims,
      "prefill_home_insurance_type": this.home_insurance_type,
      "prefill_stories": this.stories,
      "swquote_question": qid,
      "roof_type": this.rooftype,
      "quinstreet_token": this.pftoken,
      'quinstreetData': JSON.stringify(this.quinstreetData),
      "Qunistreet_Email": this.email
    }

    // let allData = clientDataJson;
    // clientDataJson["Quinstreet_tokenId"] = this.pftoken;
    // clientDataJson["Quinstreet_data"] = allData;



    let firstName, lastName;
    const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    localStorage.setItem("customer_id", customerIdLocal);
    const idMappingJson = {
      client_id: this.clientId,
      customer_id: customerIdLocal,
      email_id: (!this.email) ? 'leads_to_check_inDB@getmybubble.com' : this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      status: this.interest_type,
      interest_type: this.interest_type,
      dob: this.date_of_birth
    };

    this.pc.createIdMapping(idMappingJson);

    console.log("Lead:createIdMapping:idMappingJson:", idMappingJson);
    /////// id mapping ends
    this.pc.saveUpdateClientData(clientDataJson).then(data => {
      debugger
      this.getdata();
    }).catch(error => {
      console.log(error);
    });
  }

  setGAEvent() {
    let eventOptionsPart1 = {
      "path": "prefillService",
      "page_title": "prefillService", "url": window.location.href
    };
    //action, category, label
    if (this.email == null) {
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
        'event_category': "Error", 'event_label': "email"
      });
    }
    if (this.address == null) {
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
        'event_category': "Error", 'event_label': "address"
      });
    }
    // TODO Need to check with Rajaneesh why was it added and removed
    // if (this.country == null) {
    //   this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
    //     'event_category': "Error", 'event_label': "country"
    //   });
    // }
    if (this.state == null) {
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
        'event_category': "Error", 'event_label': "state"
      });
    }
    if (this.city == null) {
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
        'event_category': "Error", 'event_label': "city"
      });
    }
    if (this.zip == null) {
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "quinstreetService", {
        'event_category': "Error", 'event_label': "zip"
      });
    }

  }
  fillInHubspotDataFromFormData(hubSpotData) {
    const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    if (this.email) hubSpotData["email"] = this.email;
    if (this.firstname) hubSpotData["firstname"] = this.firstname;
    if (this.lastname) hubSpotData["lastname"] = this.lastname;
    if (this.address) hubSpotData["address"] = this.address;
    if (this.address_line_2) hubSpotData["address_line_2"] = this.address_line_2;
    if (this.city) hubSpotData["city"] = this.city;
    if (this.state) hubSpotData["state"] = this.state;
    if (this.country) hubSpotData["country"] = this.country;
    if (this.zip) hubSpotData["zip"] = this.zip;
    if (this.phone) hubSpotData["phone"] = this.phone;
    if (this.date_of_birth) hubSpotData["dob_home"] = this.date_of_birthtohubspot;
    if (this.gender) hubSpotData["gender"] = this.gendertohubspot;
    if (this.marital_status) hubSpotData["marital_status"] = this.marital_status;
    if (this.fullAddress) hubSpotData["full_address"] = this.fullAddress;
    if (this.dwelling_use) hubSpotData["dwelling_use"] = this.dwelling_use;
    if (this.number_of_units) hubSpotData["number_of_units"] = this.number_of_units;
    if (this.number_of_claims) hubSpotData["number_of_claims"] = this.number_of_claims;
    if (this.property_year_built) hubSpotData["property_year_built"] = this.property_year_built;
    if (this.property_living_area) hubSpotData["property_living_area"] = this.property_living_area;
    if (this.pftoken) hubSpotData["quinstreet_pf_token"] = this.pftoken;
    hubSpotData["home_insurance_type"] = 'Homeowner';
    if (this.stories) hubSpotData["stories"] = this.stories;
    if (this.roof_updated_year) hubSpotData["roof_updated_year"] = this.roof_updated_year;
    let today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    let datetime = yyyy + '-' + mm + '-' + dd + ' ' + today.getHours() + ':' + today.getMinutes() + ':00 PST';
    hubSpotData["lead_source"] = 'Quinstreet_click';
    hubSpotData["lead_form_url"] = 'insure.com';
    hubSpotData['home_type'] = this.home_type;
    hubSpotData["lead_source_time_stamp"] = datetime;
    hubSpotData['credit_rating'] = this.credit_type;
    hubSpotData['mobilephone'] = this.alternatephone;
    hubSpotData["dropoff_url"] = this.dropoffURL,
      hubSpotData['customer_id'] = customerIdLocal
    return hubSpotData;
  }

  async getdata() {
    debugger
    this.fullAddress = this.address + "-" + this.city + "-" + this.state + "-" + this.zip;
    this.fullAddress = this.fullAddress.replace(/ /g, "-");
    this.pc.getAppData(this.clientId).then(data => {
      debugger
      let dataFrom = JSON.parse(data.Payload);
      this.clientData = dataFrom.body;
      console.log("dataFrom get app data : ", this.clientData);
      debugger
      this.fullAddress = this.clientData.fullAddress;
      this.uniqueId = pc.getUniqueId4Property(this.clientId, this.fullAddress)
      this.insuranceType = "home";
      localStorage.setItem("insuranceType", this.insuranceType);
      this.iType = "home-quote";
      this.recoType = "SelectHomeCarrier";
      debugger
      let CommonInputHome = {};
      let localStories = {
        "1": "1",
        "1and1/2": "1H",
        "2": "2",
        "2and1/2": "2H",
        "3": "3",
        "4+": "4",
        "4": "4"
      }
      let propDetails = {
        "construction_type": this.construction_type,//no value
        "home_type": this.home_type,//no value
        "roof_type": this.roof_type,//no value
        "swimming_pool": this.swimming_pool,//no value
        "year_built": this.year_built,//no value
        "living_size": this.living_size,//no value
        "stories": localStories[this.stories] ? localStories[this.stories] : "1",
        "roof_constructed": this.roof_updated_year
      }
      CommonInputHome = {
        address: this.address,
        addressType: "PhysicalRisk",
        city: this.city,
        coverage: 10,
        dob: this.date_of_birth,
        firstName: this.firstname,
        lastName: this.lastname,
        middleName: "",
        premiumFrequency: 'm',
        state: this.state,
        zip: this.zip,
        replacementCost: 10,
        yearbuilt: this.year_built,
        sw_discounts: [],
        sw_appQuestions: [],
        ca_builder: "",
        propertyDetails: propDetails,
        swquote_question: this.clientData.swquote_question,
        email: this.email,
        phone: this.phone,
        disableBack: true
      }
      let google_address = {
        "street_number_ln": '',
        "street_number_sn": '',
        "route_ln": '',
        "route_sn": '',
        "neighborhood_ln": '',
        "neighborhood_sn": '',
        "locality_ln": '',
        "locality_sn": '',
        "administrative_area_level_2_ln": '',
        "administrative_area_level_2_sn": '',
        "administrative_area_level_1_ln": '',
        "administrative_area_level_1_sn": '',
        "country_ln": '',
        "country_sn": '',
        "postal_code_ln": '',
        "postal_code_sn": '',
        "postal_code_suffix_ln": '',
        "postal_code_suffix_sn": '',
        "formatted_address": ''
      };
      /* let address = {
        Addr1: '',
        addr: [],
        City: '',
        StateProvCd: '',
        PostalCode: ''
      }; */
      let address = {
        Addr1: "",
        Addr2: [],
        City: '',
        StateProvCd: '',
        PostalCode: ''
      };
      pc.BackendService.getGoogleAddressDetails(this.fullAddress).then(async (res) => {
        debugger
        let results = res.data.results;
        for (let i = 0; i < results[0].address_components.length; i++) {

          let type = results[0].address_components[i].types[0];
          switch (type) {
            case "street_number":
              google_address.street_number_ln = results[0].address_components[i].long_name;
              google_address.street_number_sn = results[0].address_components[i].short_name;
              address.Addr2[0] = results[0].address_components[i].short_name;
              break;
            case "route":
              google_address.route_ln = results[0].address_components[i].long_name;
              google_address.route_sn = results[0].address_components[i].short_name;
              address.Addr2[1] = results[0].address_components[i].short_name;
              break;
            case "neighborhood":
              google_address.neighborhood_ln = results[0].address_components[i].long_name;
              google_address.neighborhood_sn = results[0].address_components[i].short_name;
              address.Addr2[2] = results[0].address_components[i].short_name;
              break;
            case "locality":
              google_address.locality_ln = results[0].address_components[i].long_name;
              google_address.locality_sn = results[0].address_components[i].short_name;
              address.City = results[0].address_components[i].short_name;
              break;
            case "administrative_area_level_2":
              google_address.administrative_area_level_2_ln = results[0].address_components[i].long_name;
              google_address.administrative_area_level_2_sn = results[0].address_components[i].short_name;
              break;
            case "administrative_area_level_1":
              google_address.administrative_area_level_1_ln = results[0].address_components[i].long_name;
              google_address.administrative_area_level_1_sn = results[0].address_components[i].short_name;
              address.StateProvCd = results[0].address_components[i].short_name;
              break;
            case "country":
              google_address.country_ln = results[0].address_components[i].long_name;
              google_address.country_sn = results[0].address_components[i].short_name;
              break;
            case "postal_code":
              google_address.postal_code_ln = results[0].address_components[i].long_name;
              google_address.postal_code_sn = results[0].address_components[i].short_name;
              address.PostalCode = results[0].address_components[i].short_name;
              break;
            case "postal_code_suffix":
              google_address.postal_code_suffix_ln = results[0].address_components[i].long_name;
              google_address.postal_code_suffix_sn = results[0].address_components[i].short_name;
              break;
          }

        }
        address.Addr1 = address.Addr2.join(' ');
        delete address.Addr2;
        // let brushData = await pc.BackendService.getbrushForestAPI(JSON.stringify(address), this.uniqueId);
        // console.log("brushData", brushData);
        debugger;
        let swData = {
          "data_category": "getMinimalHomeQuoteFromStillWater",
          "fetchViaAPI": "true",
          "dataCategory": "StillWater_QuickQuote",
          "uid": this.uniqueId,
          "lastname": "T",
          "firstname": "Anonymous",
          "streetaddress": google_address.street_number_ln + " " + google_address.route_sn, //"353 Portland Cir",
          "city": google_address.locality_ln,
          "state": google_address.administrative_area_level_1_sn,
          "zipcode": google_address.postal_code_sn
        };
        let urlTemp = 'https://api2.getmybubble.io/' + 'fetch1';
        console.log("swData getMinimalHomeQuoteFromStillWater", swData, " # urlTemp: ", urlTemp);
        axios.get(urlTemp, { params: swData });
        localStorage.setItem('commonInput', JSON.stringify(CommonInputHome));
        const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
        let hubSpotData = {
          interest_type: this.interest_type,
          application_stage: "Lead",
          client_id: this.clientId,
          customer_id: customerIdLocal,
          // home_knock_out_reason: brushData.errorMessage ? brushData.errorMessage : ""
        };
        hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
        console.log("hubSpotData from fillInHubspotDataFromFormData", hubSpotData);
        debugger;
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
        localStorage.setItem("applyDiscount", "0");
        console.log("common input : ", CommonInputHome);
        console.log("CommonInputHome : ", CommonInputHome);
        let resumeURL = `prefill-QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.fullAddress}/${this.insuranceType}/${this.year_built}/${this.living_size}/${this.roof_updated_year}`;
        console.log("resumeURL generated: ", resumeURL);
        this.resumeFlow(resumeURL);
      }).catch(error => {
        console.log(error);
      });
    }).catch(error => {
      console.log(error);
    })
  }

  async resumeFlow(resumeURL) {
    this.loader = true;
    let createJson = {
      insuranceType: this.iType,
      carrierName: "stillwater",
      appStartStatus: false,
      appCompletedStatus: false,
      questionStartStatus: false,
      questionCompletedStatus: false,
      questionSequence: "0",
      applicationId: "",
      otherAppflowScreen: "home-question1",
      resumeUrl: resumeURL
    }
    let createResumeData = await this.pc.checkforResumeInit(createJson, "create");
    console.log("createResumeData: ", createResumeData);
    this.router.navigate(['/resumeFlow', this.clientId]);
  }
  async invokeBrushForest() {
    debugger
    this.loader = true;
    let addressJson = {
      Addr1: this.address,
      City: this.city,
      StateProvCd: this.state,
      PostalCode: this.zip
    };
    pc.BackendService.getbrushForestAPI(JSON.stringify(addressJson), this.clientId).then(
      Payload => {
        debugger;
        console.log("Brushforect api payload :", Payload);
        if (Payload.hasError == true) {
          // let message;
          // message = "We need some more information to give you a quote. Somebody from Bubble will get in touch with you or you can call us on (833) 900-BUBB or mail us at sales@getmybubble.com.";
          // let errorMessage = Payload.errorMessage;
          // console.log("Message 0:", errorMessage);
          // debugger
          // let sp = errorMessage.split("|");
          // if (sp.length > 1) {
          //   message = "The entered home address is not in the correct format. Please re-enter to continue.";
          // }

          this.openbrushModal();
          // this.loader = false;
          // this.city = "";
          // this.state = "";
          // this.zip = "";
          // this.address = "";
          // location.href = "/prefillResumeFlowHoi?lead_source=Quinstreet_click";
        }
        else {
          this.loader = false;
          this.saveupdateclient();
          // this.addressError = "";
        }
      }
    ).catch(error => { console.log(error) });
    // }

  }

  openbrushModal() {
    debugger
    let hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: this.email
    };
    hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
    console.log("hubSpotData from fillInHubspotDataFromFormData", hubSpotData);
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    const idMappingJson = {
      client_id: this.clientId,
      customer_id: customerIdLocal,
      email_id: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      status: this.interest_type,
      interest_type: this.interest_type,
      dob: this.date_of_birth
    };

    this.pc.createIdMapping(idMappingJson);
    console.log("Lead:createIdMapping:idMappingJson:", idMappingJson);
    let clientDataJson = {
      'id': this.clientId,
      "country": this.country,
      "bubble_email": this.email,
      "email": this.email,
      "bubble_fname": this.firstname,
      "bubble_lname": this.lastname,
      "address": this.address,
      "city_name": this.city,
      "stateCode": this.state,
      "state_name": this.state,
      "zip_code": this.zip,
      "bubble_phone": this.phone,
      "dob": this.date_of_birth,
      "gender": this.gender,
      "insurancetype": this.interest_type,
      "interest_type": this.interest_type,
      "yearBuilt": this.year_built,
      "livingArea": this.living_size,
      "roofBuiltYear": this.roof_updated_year,
      "fullAddress": this.fullAddress,
      "prefill_insurancetype": this.interest_type,
      "prefill_interest_type": this.interest_type,
      "prefill_yearBuilt": this.year_built,
      "prefill_livingArea": this.living_size,
      "prefill_roofBuiltYear": this.roof_updated_year,
      "prefill_marital_status": this.marital_status,
      "prefill_home_type": this.home_type,
      "prefill_dwelling_use": this.dwelling_use,
      "prefill_number_of_units": this.number_of_units,
      "prefill_number_of_claims": this.number_of_claims,
      "prefill_home_insurance_type": this.home_insurance_type,
      "prefill_stories": this.stories,
      "swquote_question": [],
      "roof_type": this.rooftype,
      "quinstreet_token": this.pftoken,
      'quinstreetData': JSON.stringify(this.quinstreetData),
      "Qunistreet_Email": this.email
    }
    this.pc.saveUpdateClientData(clientDataJson);
    this.pc.saveUpdateClientData(clientDataJson).then(data => {
      debugger
      location.href = `/prefillResumeFlowHoi?lead_source=Quinstreet_click&email=${this.email}&address=${this.address}&state=${this.state}&city=${this.city}&country=${this.country}&zip=${this.zip}&date_of_birth=${this.date_of_birth}&property_year_built=${this.year_built}&property_living_area=${this.living_size}&firstname=${this.firstname}&lastname=${this.lastname}&gender=${this.gender}&phone=${this.phone}&pftoken=${this.pftoken}`;

    }).catch(error => {
      console.log(error);
    });

    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //   });

    // let data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   message: msg,
    //   image: 'assets/images/images/education2.png'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {

    //   window.location.href = this.pc.envCred.url;

    //   this.loader = false;
    // }, (reason) => {
    //   console.log("Modal Close Reason: ", reason);
    //   this.loader = false;
    // });
  }
}