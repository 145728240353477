import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-insured-information',
  templateUrl: './insured-information.component.html',
  styleUrls: ['./insured-information.component.css']
})
export class InsuredInformationComponent implements OnInit {
  @Input() questionDetails: [];
  @Output() public insuredInformationOutput =
    new EventEmitter<{ component_id: any, answer: any }>();
  inputJson: any;
  heroForm: any;
  constructor(private pc: ProcessCacheService) { }
  FirstName: any;
  MiddleName: any;
  LastName: any;
  CountryOfBirth: any;
  SSN: any;
  EmailID: any;
  Currentstreetaddress: any;
  ApartmentOrSuite: any;
  City: any;
  State: any;
  Zipcode: any;
  PrimaryPhoneNumber: any;
  SecondaryPhoneNumber: any;

  ngOnInit(): void {
    console.log(this.questionDetails);
    this.heroForm = new FormGroup({
      EmailID: new FormControl(this.EmailID, Validators.required),
    });
  }

  public onSubmit(data) {
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);
      this.inputJson = {
        'id': localStorage.getItem('clientID'),
        'FirstName': this.FirstName,
        'MiddleName': this.MiddleName,
        'LastName': this.LastName,
        'CountryOfBirth': this.CountryOfBirth,
        'SSN': this.SSN,
        'EmailID': this.EmailID,
        'Currentstreetaddress': this.Currentstreetaddress,
        'ApartmentOrSuite': this.ApartmentOrSuite,
        'City': this.City,
        'State': this.State,
        'Zipcode': this.Zipcode,
        'PrimaryPhoneNumber': this.PrimaryPhoneNumber,
        'SecondaryPhoneNumber': this.SecondaryPhoneNumber
      }
      this.insuredInformationOutput.emit({ component_id: this.questionDetails["component_id"], answer: this.inputJson });
      this.submitdata(this.inputJson);
    } else {
      alert("Declined!");
    }
  }

  public submitdata(inputJson) {
    this.pc.saveUpdateClientData(inputJson);
  }

  public goBack() {
    debugger;
    this.insuredInformationOutput.emit({ component_id: this.questionDetails["component_id"] + ",Goback", answer: this.inputJson });
  }
}
