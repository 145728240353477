import { Component, OnInit ,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-sbli01901230',
  templateUrl: './sbli01901230.component.html',
  styleUrls: ['./sbli01901230.component.css']
})
export class Sbli01901230Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  next() {
    debugger
    let etty = {
      "next": 1,
      "previous": 0,
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
