import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component( {
  selector: 'app-home-score-modal',
  templateUrl: './home-score-modal.component.html',
  styleUrls: ['./home-score-modal.component.css']
} )
export class HomeScoreModalComponent implements OnInit {

  constructor( public activeModal: NgbActiveModal ) { }

  ngOnInit(): void { }

  closeModal() {
    this.activeModal.close();
  }
}
