import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli01401180',
  templateUrl: './sbli01401180.component.html',
  styleUrls: ['./sbli01401180.component.css']
})
export class Sbli01401180Component implements OnInit {
  existingData: any;
  isDisable: boolean;
  questions: any;
  isPlan: any;
  isRace: string;
  isUnderWater: string;
  clientId: any;
  showLoader = true;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }
  @Output() changeQuestion = new EventEmitter();
  ngOnInit(): void {
    this.isRace = '';
    this.isUnderWater = '';
    this.clientId = localStorage.getItem('clientID');
    this.isDisable = true;
    // this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli01401180")[0];
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli01401180')[0];
        console.log('app-sbli01401180 questions : ', this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli01401180')[0];
        console.log('app-sbli01401180 questions : ', this.questions);
      }


      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_two_year_plan != undefined) {
        this.isPlan = this.existingData.sbli_two_year_plan;
        if (this.isPlan == 'Yes') {
          this.twoYearPlan('Yes');
          this.isRace = this.existingData.sbli_two_year_plan_pilot;
          if (this.isRace == 'Yes') {
            this.twoYearPlanRace('Yes');
            this.isUnderWater = this.existingData.sbli_two_year_plan_underwater;
            if (this.isUnderWater == 'Yes') {
              this.twoYearPlanUnderWater('Yes');
            } else {
              this.isDisable = false;
            }
          } else {
            this.isDisable = false;
          }
        } else {
          this.isDisable = false;
        }
      }



      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    console.log('app-sbli01401180 questions', this.questions);
  }

  twoYearPlan(input) {
    this.isPlan = input;
    debugger;
    if(this.isPlan!='Yes')
    {
      this.isRace = '';
      this.isUnderWater = '';
    }

    if (this.isPlan != '' && this.isPlan == this.questions.radio_options[1].answer_text) {
      this.isDisable = false;
    }

    else {
      this.isDisable = true;
    }
  }


  twoYearPlanRace(input) {
    debugger;
    this.isRace = input;
    if (this.isUnderWater != ''&&  this.isRace != '' && this.isPlan != '') {
      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
  }
  next() {
    debugger;
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    this.$gaService.event( 'SBLI LifeApplication Scuba', 'event','SBLI-LifeApplication-Scuba');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Scuba',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions,'SBLI LifeApplication Scuba', 'event','SBLI-LifeApplication-Scuba');
    json.SBLI[this.questions.radio_options[0].answer_key] = this.isPlan;
    json.SBLI[this.questions.radio_options_1[0].answer_key] = this.isRace;
    json.SBLI[this.questions.radio_options_2[0].answer_key] = this.isUnderWater;

    for (const propName in json.SBLI) {
      if (json.SBLI[propName] === null || json.SBLI[propName] === undefined || json.SBLI[propName] === '') {
        delete json.SBLI[propName];
      }
    }


    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }
  twoYearPlanUnderWater(input) {
    this.isUnderWater = input;
    if (this.isUnderWater != '' && this.isPlan != '' &&  this.isRace != '') {
      this.isDisable = false;
    }
    else
    {
      this.isDisable = true;
    }
  }


}
