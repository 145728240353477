import { Component, OnDestroy, OnInit,EventEmitter,Output,  ViewChild } from '@angular/core';

@Component({
  selector: 'app-question5',
  templateUrl: './question5.component.html',
  styleUrls: ['./question5.component.css']
})
export class Question5Component implements OnInit {

  @Output() nextquestion5 = new EventEmitter();
  height: string;
  weight: any;

  constructor() { }

  ngOnInit(): void {
    if(localStorage.getItem('bubble-height') !== null) {
      this.height = localStorage.getItem('bubble-height');
      this.weight = localStorage.getItem('bubble-weight');
    }
    if (this.height==undefined||this.height==null||this.height=="undefined") {
      this.height = "";
    }
     if (this.weight==undefined||this.weight==null||this.weight=="undefined") {
      this.height = "";
    }
  }

  next() {
    localStorage.setItem('bubble-height',this.height);
    localStorage.setItem('bubble-weight',this.weight);
    let etty = {
      "questionNo": "5",
      "height": this.height,
      "weight": this.weight,
      "nextprev": 1
    }
    this.nextquestion5.emit(etty);
  }

  previous() {
    let etty = {
      "questionNo": "5",
      "height": this.height,
      "weight": this.weight,
      "nextprev": 0
    }
    this.nextquestion5.emit(etty);
  }
}
