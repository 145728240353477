import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getSWQuestionAnswerJson } from "../../appUtility/appUtility.js";
import { ActivatedRoute } from '@angular/router';
import { environment } from "./../../../environments/environment";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from "./../../components/lifescore-error-alert/lifescore-error-alert.component";
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { json } from 'express';


const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-customize-home',
  templateUrl: './customize-home.component.html',
  styleUrls: ['./customize-home.component.css']
})
export class CustomizeHomeComponent implements OnInit {

  @Output() invokeParentFunction = new EventEmitter();
  @Output() fetchnewValues = new EventEmitter();
  @Input() premiumAmount;

  premiumvalues: any;
  dwellingvalues: any;
  Deductiblevalues: any;
  otherstructurevalues: any;
  personelpropertyandreplacment: any;
  lossofusevalues: any;
  liabilityvalues: any;
  medicalpayments: any;
  actualcashvalue: any;
  buildingordiance: any;
  commanconstruction: any;
  extendedreplacment: any;
  animalliability: any;
  inflamationgaurd: any;
  discounttable: any;
  replacmentvalue: any;
  deductiblevale: any;
  lossvalue: any;
  liabiltyvalue: any;
  extendvalue: any;
  buildingvalue: any;
  commanvalue: any;
  animalvalue: any;
  medicalpaymentvalue: any;
  ItemClassList: any = [];
  questions: any;
  dynamicdiv = [];
  totalvalue: any;
  loader: boolean;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  inputAddress: any;
  ci_address: string;
  ci_city: any;
  ci_state: any;
  ci_zip: any;
  replacementCost: any;
  itemClassList: any;
  customizevalues: any;
  includedCoverage: any;
  optionalCoverage: any;
  myArray: any[];
  existingData: any;
  dynamicdivtoshow: any;
  existingAppData: any;
  maxDwellingCost: number;
  minDwellingCost: any;
  errorMessage: string;
  showerror: boolean;
  errormessage: any;
  showmanditoryerror: boolean;
  sppList: any;
  customizevaluesCopy: any;
  premium: any;
  sppModal: boolean;
  includedCoverageCopy: any;
  optionalCoverageCopy: any;
  premiumColor: string;
  isedit: boolean;
  windhailError: string;
  commonInput
  constructor(private utilityService: UtilityFunctionsService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    debugger
    this.loader = true;

    // this.errormessage='';
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      this.inputAddress = this.uniqueId.split("-");
      this.inputAddress.shift();
      this.inputAddress = this.inputAddress.join(" ");
      console.log("uniqueId : ", this.uniqueId);
      console.log("clientId : ", this.clientId);
      console.log("displayAddress : ", this.displayAddress);
      console.log("insurancetype : ", this.insurancetype);
    }
    );

    this.commonInput = JSON.parse(localStorage.getItem('commonInput'));
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == "app-sw4pt30")[0];
    console.log("sw questions app-sw4pt30 : ", this.questions);

    let selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
    console.log("selectedHome :", selectedHome);
    let dwelleing = selectedHome.body.other_amounts.dwelling.text;
    dwelleing = dwelleing.replace('$', '').replace(/,/g, '');
    console.log("dwelleing :", dwelleing);
    //this.premium = selectedHome.head.quote_amount;
    //this.minDwellingCost = dwelleing;
    this.minDwellingCost = localStorage.getItem('minDwellingCost');
    if (this.minDwellingCost == null || this.minDwellingCost == undefined || this.minDwellingCost == '') {
      this.minDwellingCost = dwelleing;
      localStorage.setItem("minDwellingCost", dwelleing);
    }
    this.errorMessage = "";
    this.windhailError = "";

    this.totalvalue = 0;

    let quickquote = new pc.Quotes(this.uniqueId);
    this.itemClassList = quickquote.getSWSPPOptions();
    if (this.commonInput['state'] == "OH" || this.commonInput['state'] == "WA") {
      this.itemClassList = this.itemClassList.filter(x => x.value != '71' && x.value != '335A' && x.value != '336A');
    }


    console.log("itemClassList : ", this.itemClassList);


    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);
      this.existingAppData = dataFrom.body.stillwater;
    }).catch(error => {
      console.log(error);
    })

    //let this.customizevalues = this.processCache.getSWCustomizeValues();
    //let this.customizevalues = quickquote.getSWCustomizeValues();
    // let this.customizevalues = '';




    this.getcustomisevalues();

    // let list = this.questions["group_of_inputs"]["answer_1"]["dropdown_options"];

    // for (let i = 0; i < list.length; i++){
    //   var etty = {
    //     "class":list[i]
    //   }
    //   this.ItemClassList.push(etty);
    // }

    // console.log("sw ItemClassList : ", this.ItemClassList);

  }
  // ngAfterViewInit() {
  //   this.getcustomisevalues();
  // }

  getcustomisevalues() {
    debugger
    this.loader = true;
    let quickquote = new pc.Quotes(this.uniqueId);

    let state_code = JSON.parse(localStorage.getItem('clientdata'))['body']['stateCode'];
    console.log('state_code', state_code)
    quickquote.getSWCustomizeValues().then(data => {

      this.customizevaluesCopy = data;
      this.customizevalues = data;
      console.log("customise values : ", this.customizevalues);

      this.includedCoverage = this.customizevalues.includedCoverage;
      this.optionalCoverage = this.customizevalues.optionalCoverage;
      this.discounttable = this.customizevalues.discountApplied;
      this.sppList = this.customizevalues.sppList;
      //this.premium = this.customizevalues.yearlyPremium;
      this.premium = this.customizevalues.monthlyPremium;
      //////debugger
      this.myArray = [];
      for (const item in this.includedCoverage) {
        if (this.includedCoverage[item].title) {
          let stty = this.includedCoverage[item];
          if (stty.input_type == 'dropdown' && (stty.input_value == '' || stty.input_value == undefined || stty.input_value == null)) {
            //debugger
            if (stty.dropdown_values[0].value == null) {
              stty.dropdown_values[0].value = "";
            }
            let trueValue = stty.dropdown_values.filter(x => x.selected == true);
            if (trueValue.length != 0) {
              stty.input_value = trueValue[0].value;
              if (stty.premium.includes('-')) {
                stty['minusIncludes'] = true;
              }
              if (state_code == "TX" && stty.answer_key == "building_ordinance") {
                if (trueValue[0].value == '') {
                  stty.premium = "";
                }
              }
            }
            else {
              stty.input_value = stty.dropdown_values[0].value;
              if (stty.premium.includes('-')) {
                stty['minusIncludes'] = true;
              }
            }
          }
          if (stty.input_type == 'checkbox') {
            //stty.input_value = stty.checked;
            if (state_code == "OH" && stty.answer_key == 'common_construction' && stty.checked == true) {
              stty.premium = "Included";
            } else {
              stty.premium = "Not Covered";
            }
          }
          if (stty.input_type == "text") {
            stty.input_value = stty.input_value = stty.input_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            stty.input_value = "$" + stty.input_value;
          }
          if (stty.input_type == 'none') {
            //////debugger
            stty.display = true;
            //stty.input_value = stty.checked;
          }
          stty.premiumColor = "black";
          this.myArray.push(stty);
        }
      }
      this.includedCoverage = this.myArray;
      this.includedCoverageCopy = this.includedCoverage;

      this.myArray = [];
      let selectedCyberBase = "home_cyberbase";
      for (const item in this.optionalCoverage) {
        if (this.optionalCoverage[item].title) {
          let stty = this.optionalCoverage[item];
          if (stty.answer_key == "home_cyberbase" || stty.answer_key == "hcbpackage_databreach" || stty.answer_key == "hcbbreach_bullying") {
            if (stty.dropdown_values[0].value == null) {
              stty.dropdown_values[0].value = "";
            }
            debugger
            let trueValue = stty.dropdown_values.filter(x => x.selected == true);
            if (trueValue.length != 0) {
              stty.input_value = trueValue[0].value;
            } else {
              stty.input_value = stty.dropdown_values[0].value;
            }
            stty.type = "cyber";
            if (stty.input_value != "") {
              selectedCyberBase = stty.answer_key;
              stty.display = true;
            } else {
              // selectedCyberBase = "";
              stty.display = false;
            }
            if (stty.answer_key == "home_cyberbase") {
              var etty = {
                "cyberBaseDropDown": true,
                "cyberBaseDropDownvalues": [
                  {
                    "value": "home_cyberbase",
                    "text": "Home Cyber Base Package"
                  },
                  {
                    "value": "hcbpackage_databreach",
                    "text": "Home Cyber Base Package  + Data Breach"
                  },
                  {
                    "value": "hcbbreach_bullying",
                    "text": "Home Cyber Base Package + Data Breach + Cyber Bullying"
                  }
                ],
                "selected": selectedCyberBase,
                "passForSave": 0
              }
              this.myArray.push(etty);
            }
            else {
              this.myArray.filter(x => x.passForSave == 0)[0].selected = selectedCyberBase;
            }
          } else if (stty.input_type == 'dropdown' && (stty.input_value == '' || stty.input_value == undefined || stty.input_value == null)) {
            //////debugger
            if (stty.dropdown_values[0].value == null) {
              stty.dropdown_values[0].value = "";
            }
            let trueValue = stty.dropdown_values.filter(x => x.selected == true);
            if (trueValue.length != 0) {
              stty.input_value = trueValue[0].value;
            } else {
              stty.input_value = stty.dropdown_values[0].value;
            }
            stty.display = true;
          } else if (stty.input_type == 'checkbox') {
            //////debugger
            stty.display = true;
            //stty.input_value = stty.checked;
          } else if (stty.input_type == 'none') {
            //////debugger
            stty.display = true;
            //stty.input_value = stty.checked;
          } else { }
          stty.premiumColor = "black";
          stty.passForSave = 1;
          this.myArray.push(stty);
        }
      }
      ////debugger
      this.optionalCoverage = this.myArray;
      this.optionalCoverage[2].selected = selectedCyberBase;
      this.optionalCoverageCopy = this.optionalCoverage;

      this.premiumColor = "black";

      this.loader = false;
      console.log("customise - includedCoverage : ", this.includedCoverage);
      console.log("customise - optionalCoverage : ", this.optionalCoverage);
      console.log("customise - discounttable : ", this.discounttable);
      for (let i = 0; i < this.optionalCoverage.length; i++) {
        if (this.optionalCoverage[i].answer_key == selectedCyberBase && this.optionalCoverage[i].type == "cyber") {
          this.optionalCoverage[i].display = true;
        }
        if (this.optionalCoverage[i].answer_key != selectedCyberBase && this.optionalCoverage[i].type == "cyber") {
          this.optionalCoverage[i].display = false;
        }
      }
      this.pc.parseAddressLocalAndPatch(this.inputAddress, this.clientId).then(
        data => {
          this.ci_address = data.door_number + " " + data.street,// + " " + data.street_type;
            this.ci_city = data.city_name;
          this.ci_state = data.state_code;

          this.ci_zip = data.zip;

          if (this.ci_state == "CA") {
            this.maxDwellingCost = 1500000;
          }
          else if (this.ci_state == "TX" || this.ci_state == "OH") {
            this.maxDwellingCost = Number(this.minDwellingCost) + (50 / 100) * Number(this.minDwellingCost);
          } else {
            this.maxDwellingCost = 1000000;
          }

          let selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
          console.log("selectedHome :", selectedHome);
          let dwelleing = selectedHome.body.other_amounts.dwelling.text;
          dwelleing = dwelleing.replace('$', '').replace(/,/g, '');
          console.log("dwelleing :", dwelleing);
          let maxReplacementCost = dwelleing;
          // let maxReplacementCost = 0;
          // if (this.ci_state == "CA") {
          //   maxReplacementCost = 10;
          // }
          // else {
          //   maxReplacementCost = 10;
          // }

          this.replacementCost = localStorage.getItem("dwellingResult");
          this.replacementCost = this.replacementCost == "No Data" || this.replacementCost == null ? maxReplacementCost : this.replacementCost;
          this.loader = false;
        }
      ).catch(error =>
        console.log("error", error)
      );
    }).catch(error => {
      console.log("error", error);
    })
  }

  checkDwellingCost(data) {
    debugger
    data.input_value = data.input_value.toString().replace(/,/g, '');
    data.input_value = data.input_value.toString().replace("$", '');

    if (Number(data.input_value) >= Number(this.minDwellingCost) && Number(data.input_value) <= Number(this.maxDwellingCost)) {
      this.errorMessage = "";
      let commonInput = JSON.parse(localStorage.getItem("commonInput"));
      commonInput['coverage'] = data.input_value;
      localStorage.setItem("commonInput", JSON.stringify(commonInput));
    }
    else {
      let minDwell = this.minDwellingCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      let maxDwell = this.maxDwellingCost.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.errorMessage = "Dwelling Coverage should be between $" + minDwell + " and $" + maxDwell;
    }

    data.input_value = data.input_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    data.input_value = "$" + data.input_value;
    this.premiumColor = "#d4d4d4";
  }

  changePremiumColor(list) {
    debugger;

    //list.premiumColor = "#d4d4d4";
    this.premiumColor = "#d4d4d4";
    if (list.answer_key == "wind_hail" && list.input_value != "") {
      //debugger
      let selectedCoverage = list.input_value;
      let policyDeductible = this.includedCoverage.filter(x => x.answer_key == "deductible")[0].input_value;
      let dwelleingCovergae = this.includedCoverage.filter(x => x.answer_key == "dwelling")[0].input_value;
      dwelleingCovergae = dwelleingCovergae.replace("$", "");
      dwelleingCovergae = dwelleingCovergae.replace(/,/g, "");
      if (selectedCoverage.includes("%")) {
        selectedCoverage = selectedCoverage.replace("%", "");
        selectedCoverage = selectedCoverage.replace(/,/g, "");
        let value = (Number(dwelleingCovergae) * Number(selectedCoverage)) / 100;
        if (Number(value) > Number(policyDeductible)) {
          this.windhailError = "";
        }
        else {
          this.windhailError = "Wind/Hail Deductible value should be greater than Policy Deductible.";
        }
      }
      else {
        selectedCoverage = selectedCoverage.replace("$", "");
        selectedCoverage = selectedCoverage.replace(/,/g, "");
        if (Number(selectedCoverage) > Number(policyDeductible)) {
          this.windhailError = "";
        }
        else {
          this.windhailError = "Wind/Hail Deductible value should be greater than Policy Deductible.";;
        }
      }
    }
    else {
      this.windhailError = "";
    }
  }

  checkBoxAction(even, list) {
    debugger;
    if (even.target.checked == true) {
      list.checked = true;
    }
    else {
      list.checked = false;
    }
    this.premiumColor = "#d4d4d4";
  }

  public GetPersonalValue(even) {
    var text = 'RC';
    if (even.target.value.indexOf(text) >= 0) {
      this.replacmentvalue = 'Replacement';
    }
    else {
      this.replacmentvalue = 'Actual Cash Value';
    }
  }

  public GetMedicalPaymentValue(even) {
    this.medicalpaymentvalue = even.target.value;
  }

  public GetDeductibleValue(even) {
    this.deductiblevale = even.target.value;
  }

  public GetLossValue(even) {
    this.lossvalue = even.target.value;
  }

  public GetliabiltyValue(even) {
    this.liabiltyvalue = even.target.value;
  }

  public GetExtendedValue(even) {
    this.extendvalue = even.target.value;
  }

  public GetBuildingValue(even) {
    this.buildingvalue = even.target.value;
  }

  public GetCommanValue(even) {
    //////debugger
    this.commanvalue = even.target.checked;
  }

  public GetAnimalValue(even) {
    //////debugger
    this.animalvalue = even.target.checked;
  }

  public Savechange() {
    var json = {
      'dwelling': this.dwellingvalues.input_value,
      'deductible': this.deductiblevale,
      'other_structure': this.otherstructurevalues.input_value,
      'personal_property': this.replacmentvalue,
      'loss_of_use': this.lossvalue,
      'liability': this.liabiltyvalue,
      'medical_payment': this.medicalpaymentvalue,
      'acc_rc': this.replacmentvalue,
      'building_ordinance': this.buildingvalue,
      'common_construction': this.commanvalue,
      'extended_replacement': this.extendvalue,
      'animal_liability': this.animalvalue,
      'inflation_guard': this.inflamationgaurd
    }
  }

  showcustomiseLife() {
    debugger
    this.sppModal = true;
    this.loader = true;
    this.showerror = false;
    this.errormessage = '';
    let existingSPP = localStorage.getItem("spp-clientId");
    if (existingSPP == this.clientId) {
      this.existingData = localStorage.getItem('scheduledPersonalProperty');
      this.existingData = JSON.parse(this.existingData);
    }
    else {
      this.existingData = '';
    }
    console.log('existing data:', this.existingData);
    // ////debugger
    if (this.existingData == null || this.existingData == undefined || this.existingData == '' || this.existingData[0].spp_id == '') {
      this.dynamicdiv = [];
      var ettty = {
        "spp_id": '',
        "spp_value": '',
        "valuetoshow": '',
        "spp_desc": '',
        "Disable": false,
        "doNotEnable": false,
        "arrayLength": 1
      }
      this.isedit = false;
      this.dynamicdiv.push(ettty);
      this.existingData = 0;
    }
    else {
      this.isedit = true;
      this.dynamicdiv = JSON.parse(localStorage.getItem('scheduledPersonalProperty'));
      debugger;

      this.dynamicdivtoshow = [];
      for (let i = 0; i < this.dynamicdiv.length; i++) {
        this.dynamicdiv[i].Disable = true;
        this.dynamicdiv[i].doNotEnable = true;
        this.dynamicdiv[i].arrayLength = this.dynamicdiv.length;
        let asdsa = this.itemClassList.find(x => x.value == this.dynamicdiv[i].spp_id);
        // var etty = {
        //   'spp_id' : this.dynamicdiv[i].spp_id,
        //   'spp_value' : this.dynamicdiv[i].spp_value,
        //   'spp_desc' : this.dynamicdiv[i].spp_desc,
        //   'spp_class' : asdsa.text
        // }
        // this.dynamicdivtoshow.push(etty);
      }
      this.totalvalue = this.dynamicdiv.map(a => Number(a.spp_value)).reduce(function (a, b) {
        return a + b;
      });
      this.dynamicdiv[0].doNotEnable = false;
      this.existingData = 1;
    }
    document.getElementById('makeblurcus').style.filter = "blur(10px)";
    document.getElementById("schedulepp").style.display = "block";
    document.getElementById('makeblurcus').style.overflowY = 'hidden';
    document.getElementById('makeblurcus').style.position = 'fixed';
    this.loader = false;
  }


  closeModal() {
    this.sppModal = false;
    document.getElementById('makeblurcus').style.filter = "none";
    document.getElementById("schedulepp").style.display = "none";
    document.getElementById('makeblurcus').style.overflowY = 'auto';
    document.getElementById('makeblurcus').style.position = 'unset';
  }



  AddDiv() {
    ////debugger
    if (this.dynamicdiv[this.dynamicdiv.length - 1].spp_id == '' || this.dynamicdiv[this.dynamicdiv.length - 1].spp_value == '' || this.dynamicdiv[this.dynamicdiv.length - 1].spp_desc == '') {
      this.showmanditoryerror = true;
    }
    else {
      this.showmanditoryerror = false;
      this.dynamicdiv[this.dynamicdiv.length - 1].Disable = true;
      this.dynamicdiv[this.dynamicdiv.length - 1].doNotEnable = true;
      this.dynamicdiv[0].doNotEnable = false;
      var ettty = {
        "spp_id": '',
        "spp_value": '',
        "valuetoshow": '',
        "spp_desc": '',
        "Disable": false,
        "doNotEnable": true,
        "arrayLength": this.dynamicdiv.length
      }
      this.dynamicdiv.push(ettty);
      this.dynamicdiv[0].arrayLength = this.dynamicdiv.length;

    }

    // this.dynamicdiv[this.dynamicdiv.length-1].Disable=true;
    // var ettty = {
    //   "spp_id": '',
    //   "spp_value": '',
    //   "valuetoshow":'',
    //   "spp_desc": '',
    //   "Disable":false
    // }
    // this.dynamicdiv.push(ettty);

    // this.totalvalue = this.dynamicdiv.map(a =>Number(a.spp_value)).reduce(function(a, b) {
    //   return a + b;
    // });
  }
  disable:boolean;
  RemoveRow(divindex) {
    debugger
    this.dynamicdiv[this.dynamicdiv.length - 1].doNotEnable = false;
    this.dynamicdiv.splice(divindex, 1);


    if (this.dynamicdiv.length != 0) {
      this.totalvalue = this.dynamicdiv.map(a => Number(a.spp_value)).reduce(function (a, b) {
        return a + b;
      });
      if (this.dynamicdiv.length == 1) {
        if (!this.dynamicdiv[0].doNotEnable) {
          this.dynamicdiv[0].Disable = false;
          // this.dynamicdiv[0].doNotEnable = false;
        }
        else {
          this.dynamicdiv[0].Disable = true;

        }
      }
      else {
        if (!this.dynamicdiv[this.dynamicdiv.length - 1].doNotEnable) {
          this.dynamicdiv[this.dynamicdiv.length - 1].Disable = true;
        }
        // else{
        //   this.dynamicdiv[this.dynamicdiv.length - 1].doNotEnable=true;
        // }
      }

      for (let i = 0; i < this.dynamicdiv.length; i++) {
        this.dynamicdiv[i].arrayLength = this.dynamicdiv.length;
      }
    }
    else {
      var ettty = {
        "spp_id": '',
        "spp_value": '',
        "valuetoshow": '',
        "spp_desc": '',
        "Disable": false,
        "arrayLength": 1,
        "doNotEnable": true
      }
      this.dynamicdiv.push(ettty);
      this.totalvalue = 0;
      this.disable=true;

    }


  }
  save() {
    ////debugger
    this.loader = true;
    // let commonInput = {
    //   address: this.ci_address,
    //   addressType: "PhysicalRisk",
    //   city: this.ci_city,
    //   coverage: this.replacementCost,
    //   dob: "01/01/2000",
    //   firstName: "Anonymous",
    //   lastName: "Lastname",
    //   middleName: "User",
    //   premiumFrequency: "m",
    //   state: this.ci_state,
    //   zip: this.ci_zip,
    //   replacementCost: this.replacementCost,
    //   yearbuilt: localStorage.getItem("sw_yearBuilt"),
    //   sw_discounts : [],//[4965,61, 4200, 4255, 4305, 9099],
    //   sw_appQuestions: [], //
    //   ca_builder: null,
    //   sw_spp: JSON.stringify(this.dynamicdiv)
    // }
    debugger
    let quickquote = new pc.Quotes(this.uniqueId);
    let commonInput = JSON.parse(localStorage.getItem("commonInput"));
    // commonInput['sw_spp'] = JSON.stringify(this.dynamicdiv);
    if (this.dynamicdiv[0].doNotEnable == true && this.dynamicdiv[this.dynamicdiv.length - 1].spp_id == '' && this.dynamicdiv[this.dynamicdiv.length - 1].spp_value == '' && this.dynamicdiv[this.dynamicdiv.length - 1].spp_desc == '') {
      commonInput['sw_spp'] = [];
      localStorage.setItem('scheduledPersonalProperty', '');
    }
    else {
      commonInput['sw_spp'] = this.dynamicdiv;
    }


    localStorage.setItem("commonInput", JSON.stringify(commonInput));
    console.log("custmise SWquote spp commonInput: ", commonInput);
    quickquote.getSWCustomizedQuote(commonInput).then(response => {
      debugger
      if (response.hasError) {
        this.openModal(response.errorMessage.split(":")[1]);
        this.loader = false;
      }
      else {
        document.getElementById('makeblurcus').style.filter = "none";
        document.getElementById("schedulepp").style.display = "none";
        document.getElementById('makeblurcus').style.overflowY = 'auto';
        document.getElementById('makeblurcus').style.position = 'unset';
        console.log("output getSWCustomizedQuote spp: ", response);
        localStorage.setItem('selectedHomeCarrier', JSON.stringify(response));
        console.log('table data : ', this.dynamicdiv);
        this.fetchnewValues.emit();
        this.existingData = 1;
        this.sppModal = false;
        this.dynamicdiv[this.dynamicdiv.length - 1].Disable = true;
        localStorage.setItem('scheduledPersonalProperty', JSON.stringify(this.dynamicdiv));
        localStorage.setItem('spp-clientId', this.clientId);;
        this.loader = false;
        this.getcustomisevalues();
        // var json = {
        //   'id': this.clientId,
        //   stillwater: this.existingAppData
        // }
        // json.stillwater['sw_spp'] = this.dynamicdiv;
        // this.pc.saveUpdateClientData(json).then(data => {
        //   debugger
        //   this.existingData = 1;
        //   this.sppModal = false;
        //   this.dynamicdiv[this.dynamicdiv.length - 1].Disable = true;
        //   localStorage.setItem('scheduledPersonalProperty', JSON.stringify(this.dynamicdiv));
        //   localStorage.setItem('spp-clientId', this.clientId);;
        //   // this.dynamicdivtoshow = this.dynamicdiv;
        //   // for (let i = 0; i < this.dynamicdivtoshow.length; i++){
        //   //   let asdsa = this.itemClassList.find(x => x.value == this.dynamicdivtoshow[i].spp_id);
        //   //   this.dynamicdivtoshow[i].spp_class = asdsa.text;
        //   // }
        //   //this.getcustomisevalues();
        //   this.loader = false;
        //   this.getcustomisevalues();
        //   this.fetchnewValues.emit();
        //   // var etty = {
        //   //   'save': 0,
        //   //   'close': 1
        //   // }
        //   // this.invokeParentFunction.emit(etty);
        // }).catch(error => {
        //   console.log(error);
        // }
        // );
      }
    })
      .catch(error => {
        console.log("SW Quote Error:", error);
        this.loader = false;
      });
  }
  openModal(msg) {
    let hubSpotData = {
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: "Policy Denied",
      home_knock_out_reason: msg
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
        // keyboard: false,
        // backdrop: 'static'
      });

    let data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: msg,
      image: 'assets/images/images/education2.png'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log("Modal Close Output: ", result);
    }, (reason) => {
      console.log("Modal Close Reason: ", reason);
    });
  }


  public TotalAmount(data) {
    ////debugger
    this.totalvalue = 0;
    this.dynamicdiv[this.dynamicdiv.length - 1].doNotEnable = false;
    for (let i = 0; i < this.dynamicdiv.length; i++) {
      let spvalue;
      spvalue = this.dynamicdiv[i].valuetoshow.toString().replace(/,/g, '');
      spvalue = spvalue.toString().replace('$', '');
      data.spp_value = spvalue;
      this.totalvalue = Number(this.totalvalue) + Number(spvalue);
    }
    //this.errormessage="";
    if (data.spp_value > 25000) {
      this.showerror = true;
      this.errormessage = "Scheduled Personal Property exceeds the maximum allowed. Please adjust the value of the SPP item(s) to meet our Rules."
    }
    else {
      this.showerror = false;
      this.errormessage = "";
      // if(this.dynamicdiv[0].doNotEnable==false){
      //   this.dynamicdiv[0].doNotEnable=true;
      // }
      // else{
      //   this.dynamicdiv[0].doNotEnable=true;
      // }
    }
    data.valuetoshow = data.valuetoshow.toString().replace(/,/g, '');
    data.valuetoshow = data.valuetoshow.toString().replace('$', '');
    data.valuetoshow = data.valuetoshow.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    data.valuetoshow = "$" + data.valuetoshow;
    this.totalvalue = this.totalvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  closeCustomizeModal() {
    var ettyyy = {
      'save': 0,
      'close': 1
    }
    this.invokeParentFunction.emit(ettyyy);
  }

  saveCustomizeModal() {
    debugger
    let eventOptionsPart1 = {
      path: "propertyDeatils",
      page_title: "propertyDeatils", url: window.location.href
    };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', "Save", {
      'event_category': "ButtonClicked", 'event_label': "Save"
    });

    this.loader = true;
    let selectedHomeCarrierData = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
    let carrierId = selectedHomeCarrierData.carrierId;

    let commonCoverage = {};

    for (let i = 0; i < this.includedCoverage.length; i++) {
      let res = '';
      if (this.includedCoverage[i].input_value == "") {
        res = null;
      }
      else {
        if (this.includedCoverage[i].input_type == "checkbox") {
          res = this.includedCoverage[i].checked;
        }
        else if (this.includedCoverage[i].input_type == "text") {
          this.includedCoverage[i].input_value = this.includedCoverage[i].input_value.toString().replace(/,/g, '');
          this.includedCoverage[i].input_value = this.includedCoverage[i].input_value.toString().replace('$', '');
          res = this.includedCoverage[i].input_value;
        }
        else {
          res = this.includedCoverage[i].input_value;
        }
      }
      commonCoverage[this.includedCoverage[i].answer_key] = res;
    }
    for (let i = 0; i < this.optionalCoverage.length; i++) {

      if (this.optionalCoverage[i].passForSave == 1) {
        let res = '';
        if (this.optionalCoverage[i].input_value == "") {
          // res = null;
        }
        else {
          if (this.optionalCoverage[i].input_type == "checkbox") {
            res = this.optionalCoverage[i].checked;
          }
          else if (this.optionalCoverage[i].input_type == "text") {
            this.optionalCoverage[i].input_value = this.optionalCoverage[i].input_value.toString().replace(/,/g, '');
            this.optionalCoverage[i].input_value = this.optionalCoverage[i].input_value.toString().replace('$', '');
            res = this.optionalCoverage[i].input_value;
          }
          else {
            res = this.optionalCoverage[i].input_value;
          }
        }
        commonCoverage[this.optionalCoverage[i].answer_key] = res;
      }
    }
    //debugger
    // var index = this.optionalCoverage.indexOf(x => x.passForSave == 0);
    // console.log("index is : " + index );

    let cyberdd = this.optionalCoverage.filter(x => x.passForSave == 0);

    if (cyberdd.length > 0) {
      if (cyberdd[0].selected == "home_cyberbase") {
        commonCoverage["hcbpackage_databreach"] = null;
        commonCoverage["hcbbreach_bullying"] = null;
      }
      else if (cyberdd[0].selected == "hcbpackage_databreach") {
        commonCoverage["home_cyberbase"] = null;
        commonCoverage["hcbbreach_bullying"] = null;
      }
      else {
        commonCoverage["home_cyberbase"] = null;
        commonCoverage["hcbpackage_databreach"] = null;
      }
    }



    console.log("custmise SWquote on save customise commonCoverage: ", commonCoverage);

    let commonInput = JSON.parse(localStorage.getItem("commonInput"));
    commonInput
    console.log("custmise SWquote on save customise commonInput: ", commonInput);
    // commonCoverage['animal_liability'] = true;
    let quickquote = new pc.Quotes(this.uniqueId);
    quickquote.getSWCustomizedQuote(commonInput, commonCoverage)
      .then(response => {
        console.log("output getSWCustomizedQuote on save customise: ", response);
        if (response.hasError) {
          this.closeCustomizeModal();
          this.openModal(response.errorMessage.split(":")[1]);
          this.loader = false;
        }
        else {
          selectedHomeCarrierData = response;
          localStorage.setItem('selectedHomeCarrier', JSON.stringify(selectedHomeCarrierData));
          this.fetchnewValues.emit();
          this.loader = false;
          this.getcustomisevalues();
        }
      })
      .catch(error => {
        console.log("SW Quote Error:", error);
        this.loader = false;
      });

  }

  changeCyberBase(even) {
    let selectedCyberHome = even.target.value;

    for (let i = 0; i < this.optionalCoverage.length; i++) {
      if (this.optionalCoverage[i].answer_key == selectedCyberHome && this.optionalCoverage[i].type == "cyber") {
        this.optionalCoverage[i].display = true;
      }
      if (this.optionalCoverage[i].answer_key != selectedCyberHome && this.optionalCoverage[i].type == "cyber") {
        this.optionalCoverage[i].display = false;
      }
    }
  }

  public FormatAmount(data) {
    ////debugger
    data.input_value = data.input_value.toString().replace(/,/g, '');
    data.input_value = data.input_value.toString().replace('$', '');
    data.input_value = data.input_value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    data.input_value = "$" + data.input_value;
    this.premiumColor = "#d4d4d4";

  }

}
