import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-has-peripheral-disease',
  templateUrl: './has-peripheral-disease.component.html',
  styleUrls: ['./has-peripheral-disease.component.css']
})
export class HasPeripheralDiseaseComponent implements OnInit {
  @Output() nextquestion9 = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  SelectedOption:any;
  public GetOptionsyesno(data){
 
    this.SelectedOption=data;
    if(this.SelectedOption==0){
      document.getElementById('PeripheralDiseaseNo').style.border='solid 2px orange';
      document.getElementById('PeripheralDiseaseyes').style.border='solid 1px #dae7ec';
     
    }else{
     document.getElementById('PeripheralDiseaseyes').style.border='solid 2px orange';
     document.getElementById('PeripheralDiseaseNo').style.border='solid 1px #dae7ec';
    }
  }


  next() {
    let etty = {
      "questionNo": "9",
      "nextprev": 1
    }
    this.nextquestion9.emit(etty);
  }
}
