import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const dataQuery = gql`query( $page_name: String! ){
  pageData(where: { page_name: $page_name }){
    id
    attribute_1_value
    attribute_2_value
    column
    content
    attribute_1_key
    attribute_2_key
    style_category
    data_category
  }
}`;


@Component({
  selector: 'app-assumptions-page',
  templateUrl: './assumptions-page.component.html',
  styleUrls: ['./assumptions-page.component.css']
})

export class AssumptionsPageComponent implements OnInit {

  riskClass = 'risk_class';
  assumptionsPage = 'assumptions_page';
  pageFormattedData: any;
  listItems = [];
  assumptionsText = [];
  toFetch: string = '';
  @Input() page;
  @Output() hideThisPopUp = new EventEmitter<string>();

  riskPage;

  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this.toFetch = this.page == 'riskPage' ? this.riskClass : this.assumptionsPage;
    this.getContent()
  }

  getFormattedData(data: Array<any>, sectionsArray: Array<any>) {
    let output: any = {};
    let section: any;
    let column: any;
    sectionsArray.forEach(thisSection => {
      output[thisSection] = {};
      section = data.filter((item) => item.section == thisSection);
      for (let col = 1; col < 5; col++) {
        output[thisSection]['column_' + col] = {};
        column = section.filter((item) => item.column == col);
        for (let j = 0; j < column.length; j++) {
          output[thisSection]['column_' + col][column[j].style] = column[j];
        }


        if (Object.keys(output[thisSection]['column_' + col]).length === 0) {
          delete output[thisSection]['column_' + col];
        }
      }
    })

    this.pageFormattedData = output;
    return output;
  }

  getContent() {
    this.apollo.watchQuery<any>({
      query: dataQuery,
      variables: {
        page_name: this.toFetch,
      }
    }).valueChanges
      .subscribe(
        ({ data }) => {
          let { pageData } = data;
          let allData = pageData.map(e => {
            return {
              content: e.content,
              section: e.data_category,
              style: e.style_category,
              column: e.column,
              src: e.attribute_1_value,
            }
          })

          let sectionArray = new Set();
          allData.map((e: any) => sectionArray.add(e.section))
          let pageFormattedData = this.getFormattedData(allData, [...sectionArray])

          Object.keys(pageFormattedData.section_1).forEach(e => {
            if (pageFormattedData.section_1[e])
              this.listItems.push(pageFormattedData.section_1[e].text);
          })
          console.log(this.listItems)

          if (this.toFetch == this.assumptionsPage) {
            Object.keys(pageFormattedData.section_2).forEach(e => {
              if (pageFormattedData.section_2[e])
                this.assumptionsText.push(pageFormattedData.section_2[e].text)
            })
            console.log(this.assumptionsText)
          }

        },
        (error) => {
          console.log(error);
        });
  }

  closeThisPopup() {
    console.log('closeEventEmiited')
    this.hideThisPopUp.emit()
  }

}
