import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-erroralert-modal',
  templateUrl: './erroralert-modal.component.html',
  styleUrls: ['./erroralert-modal.component.css']
})
export class ErroralertModalComponent implements OnInit {
  @ViewChild('img', { static: true }) image: ElementRef;
  @ViewChild('newImg', { static: true }) newImage: ElementRef;

  @Input() fromParent;
  constructor(public activeModal: NgbActiveModal, private pc: ProcessCacheService) { }

  ngOnInit(): void { }

  closeModal() {
    this.activeModal.close("Modal Closed");
    window.location.href = this.pc.envCred.url;

  }

}
