import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { environment } from '../../../environments/environment';
import { ProfileDataHandlerService } from 'src/app/shared/profile-data-handler.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
const pc = require('bit-uibl-data-driver');
import { ValidationService } from 'src/app/shared/validation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../components/lifescore-error-alert/lifescore-error-alert.component';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { constants } from 'src/app/shared/storage-handler.service';
import { json } from 'express';
import { StorageHandlerService } from 'src/app/shared/storage-handler.service';
import { PrefillserviceService } from '../../shared/prefillservice.service';
import axios from 'axios';
import { ElectronicConsentComponent } from 'src/app/applicationFlow/PacLife/electronic-consent/electronic-consent.component';

// eslint-disable-next-line no-var
declare var window: any;

@Component({
  selector: 'app-single-mother-life',
  templateUrl: './single-mother-life.component.html',
  styleUrls: ['./single-mother-life.component.css', '../single-parent-life/single-parent-life.component.css']
})
export class SingleMotherLifeComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    lazyLoad: false,
    margin: 30,
    smartSpeed: 1500,
    autoplay: false,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };


  email: any;
  phone: any;
  dob: any;
  gender: any;
  height: any;
  weight: any;
  tobaccoUsage: any;
  coverage: any;
  term: any;
  zip: any;
  genderforhbspot: string;
  firstname: any;
  lastname: any;
  state: any;
  interest_type: any;
  desired_life_coverage: any;
  life_insurance_type: any;
  lead_form_url: any;
  lead_source_time_stamp: any;
  loader: boolean;

  heightFT: any;
  heightIN: any;
  weightLBS: any;
  annual_income: any;
  has_medical_conditions: any;
  active_retired_military_personnel: any;
  lasttermLength: any;
  full_address: any;
  marital_status: any;
  number_of_children: any;
  alzheimers_or_dementia: any;
  cancer: any;
  heart_disease: any;

  utm_source: any;
  utm_medium: any;
  utm_campaign: any;
  dclid: any;
  utm_term: any;
  utm_content: any;
  activeImage: number;
  country: any;
  constructor(private storageService: StorageHandlerService, private location: Location, private utilityService: UtilityFunctionsService, public router: Router, private modalService: NgbModal, public validationService: ValidationService, private Http: HttpClient, private pc: ProcessCacheService, private activatedroute: ActivatedRoute, private Prefillservice: PrefillserviceService) { }
  clientId: any;
  dropoffURL: any;
  httpParamsGlobal: any;
  errormessage: any;
  lead_source: any;
  sub_id: any;
  lead_token_id: any;
  curDate: any;
  minDate: any;
  disableEmail: boolean;
  callingNavigate: boolean;
  termLength: any;

  emailError: any;
  phoneError: any;
  dobError: any;
  genderError: any;
  heightFTError: any;
  heightINError: any;
  weightLBSError: any;
  insurancetype: any;
  displayAddress: any;
  fullAddress: any;
  uniqueId: any;
  age: any;
  fullName: any;
  tobaccoUsageError: any;
  desired_life_coverageError: any;
  termError: any;
  backButtonEnable;


  message: any;
  is_valid: boolean;
  NewDate;
  Date;
  dateError: any;

  tobaccoUsageSError: any;

  gclid: any;

  sendToLO(data) {
    if (this.gclid) {
      data.gclid = this.gclid;
    }
    window._loq.push(['custom', data]);
  }

  ngOnInit(): void {
    debugger;
    window._loq = window._loq || [];
    this.ziperror = '';
    this.emailError = '';
    this.dateError == '';
    this.genderError == '';
    this.tobaccoUsageSError == '';
    this.heightFTError == '';
    this.weightLBSError == '';
    this.desired_life_coverageError == '';
    this.termError == '';
    this.activeImage = 1;
    this.loader = true;
    this.clientId = this.utilityService.getNewRandomToken();
    console.log('Client Id : ', this.clientId);
    let url;
    if (window && window.location && window.location.href) {
      url = window.location.href;
    }
    this.dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
    if (url.includes('?')) {
      sessionStorage.setItem('accessedLifeURL', url);
      sessionStorage.setItem('accessedLifeURL', url);
      this.httpParamsGlobal = new HttpParams({ fromString: url.split('?')[1] });
      localStorage.setItem('httpParamsGlobal', JSON.stringify(this.httpParamsGlobal));
      this.callingNavigate = false;
      setTimeout(() => {
        this.createLog();
      }, 2000);
    }
    else if (sessionStorage.getItem('accessedLifeURL') != undefined && sessionStorage.getItem('accessedLifeURL') != null && sessionStorage.getItem('accessedLifeURL') != '') {
      const uuu = sessionStorage.getItem('accessedLifeURL');
      this.httpParamsGlobal = new HttpParams({ fromString: uuu.split('?')[1] });
      this.callingNavigate = true;
      setTimeout(() => {
        this.createLog();
      }, 2000);
    }
    else {
      this.loader = false;
      this.disableEmail = false;
      this.tobaccoUsage = 'Yes';
      this.callingNavigate = false;
      this.ziperror = 'This field is required';
      this.emailError = 'This field is required';
      this.dateError = 'This field is required';
      this.genderError = 'This field is required';
      this.tobaccoUsageSError = 'This field is required';
      this.heightFTError = 'This field is required';
      this.heightINError = 'This field is required';
      this.weightLBSError = 'This field is required';
      this.desired_life_coverageError = 'This field is required';
      this.termError = 'This field is required';
      this.phoneError = 'This field is required';
      // this.errormessage = 'This URL is not valid anymore';
      // document.getElementById('errorModal').style.display = 'block';
    }

    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear()) - 18;
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.minDate = new Date(day + '/' + month + '/' + year);
  }


  ngAfterViewInit(): void {
    document.addEventListener('scroll', (e) => { this.scroll(); });

  }


  scroll() {
    const scroll = (document.documentElement.scrollTop + document.body.scrollTop) / (document.documentElement.scrollHeight - document.documentElement.clientHeight) * 100;
    console.log('scroll percentage', scroll);
    if (scroll > 73.77) {
      if (parseInt(this.getBrowserSize().width) < 600) {
        document.getElementById('mobbtnorng').style.display = 'block';
        document.getElementById('middles').style.position = 'relative';
      }
      else {
        document.getElementById('middles').style.position = 'fixed';
        document.getElementById('mobbtnorng').style.display = 'none';
      }

    }
    else {
      if (parseInt(this.getBrowserSize().width) < 600) {
        document.getElementById('middles').style.position = 'fixed';
        document.getElementById('mobbtnorng').style.display = 'none';
      }
      else {
        document.getElementById('mobbtnorng').style.display = 'block';
        document.getElementById('middles').style.position = 'relative';
      }
    }
  }


  getBrowserSize() {
    let w, h;

    if (typeof window.innerWidth != 'undefined') {
      w = window.innerWidth; //other browsers
      h = window.innerHeight;
    }
    else if (typeof document.documentElement != 'undefined' && typeof document.documentElement.clientWidth != 'undefined' && document.documentElement.clientWidth != 0) {
      w = document.documentElement.clientWidth; //IE
      h = document.documentElement.clientHeight;
    }
    else {
      w = document.body.clientWidth; //IE
      h = document.body.clientHeight;
    }
    return { 'width': w, 'height': h };
  }



  async createLog() {
    // await this.Prefillservice.createMaLog(this.clientId, 'life');
    this.doActivity();
  }
  async doActivity() {
    debugger;
    const httpParams = this.httpParamsGlobal;
    this.email = httpParams.get('email') ? httpParams.get('email') : '';
    if (this.email == undefined || this.email == '' || this.email == null) {
      this.disableEmail = false;
    }
    else {
      this.disableEmail = true;
    }
    const hubSpotData = {
      'email': this.email,
      client_id: this.clientId,
      'application_stage': 'Lead',
    };

    const customData = {
      client_id: this.clientId,
      email: this.email
    };
    this.gclid = httpParams.get('gclid');
    if (this.email != null && this.email != undefined && this.email != '') {
      this.sendToLO(customData);
      await this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    //debugger;
    //Non Mandatory Fields_start
    this.firstname = httpParams.get('firstname') ? httpParams.get('firstname') : '';
    this.lastname = httpParams.get('lastname') ? httpParams.get('lastname') : '';
    this.full_address = httpParams.get('full_address') ? httpParams.get('full_address') : '';
    this.annual_income = httpParams.get('annual_income') ? httpParams.get('annual_income') : '';
    this.marital_status = httpParams.get('marital_status') ? httpParams.get('marital_status') : '';
    this.number_of_children = httpParams.get('number_of_children') ? httpParams.get('number_of_children') : '';
    this.active_retired_military_personnel = httpParams.get('active_retired_military_personnel') ? httpParams.get('active_retired_military_personnel') : '';
    this.alzheimers_or_dementia = httpParams.get('alzheimers_or_dementia') ? httpParams.get('alzheimers_or_dementia') : '';
    this.cancer = httpParams.get('cancer') ? httpParams.get('cancer') : '';
    this.heart_disease = httpParams.get('heart_disease') ? httpParams.get('heart_disease') : '';
    //Non Mandatory Fields_end
    this.phone = httpParams.get('phone') ? httpParams.get('phone') : '';

    this.dob = httpParams.get('date_of_birth') ? httpParams.get('date_of_birth') : '';

    this.gender = httpParams.get('gender') ? httpParams.get('gender') : 'Female';
    this.height = httpParams.get('height') ? httpParams.get('height') : '';
    if (this.height != '') {
      this.heightFT = this.height.split('\'')[0];
      this.heightIN = this.height.split('\'')[1].replace('"', '');
      this.height = `${this.heightFT}'${this.heightIN}"`;
    }
    this.weight = httpParams.get('weight') ? httpParams.get('weight') : '';
    this.weightLBS = this.weight.split('\'')[0];
    this.tobaccoUsage = httpParams.get('tobacco_user') ? httpParams.get('tobacco_user') : '';
    this.coverage = httpParams.get('desired_life_coverage') ? httpParams.get('desired_life_coverage') : '';
    this.desired_life_coverage = httpParams.get('desired_life_coverage') ? httpParams.get('desired_life_coverage') : '';
    this.zip = httpParams.get('zip') ? httpParams.get('zip') : '';
    this.interest_type = httpParams.get('interest_type');
    this.life_insurance_type = httpParams.get('life_insurance_type') ? httpParams.get('life_insurance_type') : '';
    this.lead_source = httpParams.get('lead_source') ? httpParams.get('lead_source') : '';
    this.lead_form_url = httpParams.get('lead_form_url') ? httpParams.get('lead_form_url') : '';
    this.lead_source_time_stamp = httpParams.get('lead_source_time_stamp') ? httpParams.get('lead_source_time_stamp') : '';
    this.sub_id = httpParams.get('sub_id') ? httpParams.get('sub_id') : '';
    this.lead_token_id = httpParams.get('lead_token_id') ? httpParams.get('lead_token_id') : '';
    //debugger;
    this.utm_source = httpParams.get('utm_source') ? httpParams.get('utm_source') : '';
    this.utm_medium = httpParams.get('utm_medium') ? httpParams.get('utm_medium') : '';
    this.utm_campaign = httpParams.get('utm_campaign') ? httpParams.get('utm_campaign') : '';

    this.utm_term = httpParams.get('utm_term') ? httpParams.get('utm_term') : '';
    this.utm_content = httpParams.get('utm_content') ? httpParams.get('utm_content') : '';

    this.dclid = httpParams.get('dclid') ? httpParams.get('dclid') : '';
    this.term = httpParams.get('desired_life_term') ? httpParams.get('desired_life_term') : '';
    this.annual_income = httpParams.get('annual_income') ? httpParams.get('annual_income') : '';
    this.has_medical_conditions = httpParams.get('has_medical_conditions') ? httpParams.get('has_medical_conditions') : '';
    this.active_retired_military_personnel = httpParams.get('active_retired_military_personnel') ? httpParams.get('active_retired_military_personnel') : '';
    //this.Prefillservice.saveAddressDetails(this.zip, this.clientId);
    if (Number(this.coverage) < 25000) {
      this.coverage = '250000';
    }
    if (Number(this.coverage) > 10000000) {
      this.coverage = '10000000';
    }
    this.desired_life_coverage = this.coverage;
    if (this.gender != null && this.gender != undefined) {
      if (this.gender.toLowerCase() == 'male') {
        //debugger;
        localStorage.setItem('bubble-gender', '1');
        this.genderforhbspot = '1';
        this.gender = 'Male';
      }
      else if (this.gender.toLowerCase() == 'female') {
        localStorage.setItem('bubble-gender', '0');
        this.genderforhbspot = '0';
        this.gender = 'Female';
      }
      else {
        //debugger;
        this.genderError = 'This field is required';
      }
    }
    if (this.term != undefined && this.term != null && this.term != '') {
      //debugger;
      if (this.term > 20) {
        this.termLength = 65;
      }
      else {
        //debugger;
        this.termLength = this.term;
      }
      // if (Number(this.term) != 10 && Number(this.term) != 15 && Number(this.term) != 20) {
      //   // this.term = "10";
      // }
    }
    else {
      this.termError = 'This field is required';
    }

    if (this.tobaccoUsage == undefined || this.tobaccoUsage == null || this.tobaccoUsage == '') {

      this.tobaccoUsage = 'Yes';
      this.tobaccoUsageError = '';
    }


    if ((this.zip.length > 5 || this.zip.length < 5)) {
      if (this.zip != undefined && this.zip != null && this.zip != '') {
        this.errormessage = 'Invalid Zipcode';
        this.zip = '';
        this.ziperror = 'Enter a valid Zipcode';
      }


    }
    else if (this.zip.length == 5) {
      if (this.full_address && this.full_address.includes(this.zip)) {
        this.validateZip('0');
      }
      else {
        this.validateZip('1');
      }
    }
    else {
      //document.getElementById('errorModal').style.display = 'none';
      this.ziperror = 'This field is required';
      this.zip = '';
      this.loader = false;
    }
    this.validateEmail();
    this.phonelength();
    this.onDateChange(this.dob);
    this.changeGender(this.gender);
    this.ChangetobaccoUsage(this.tobaccoUsage);
    //this.ChangeheightFT();
    //this.ChangeweightLBS();
    this.Changedesired_life_coverage();
    this.Changeterm(this.termLength);
    //debugger;
    await this.setGAEvent();
    await this.generateClientData();
    this.location.go('SingleMotherLife');
    //debugger;

    if ((this.emailError == '' || this.emailError == undefined) && (this.dateError == '' || this.dateError == undefined) && (this.genderError == '' || this.genderError == undefined) && (this.ziperror == '' || this.ziperror == undefined) && (this.tobaccoUsageSError == '' || this.tobaccoUsageError == undefined) && (this.heightFTError == '' || this.heightFTError == undefined) && (this.weightLBSError == '' || this.weightLBSError == undefined) && (this.desired_life_coverageError == '' || this.desired_life_coverageError == undefined) && (this.termError == '' || this.termError == undefined)) {
      //debugger;
      if (this.callingNavigate == false) {
        setTimeout(() => {
          this.navigatetoLiferecoPage();
        }, 2000);
      }
      else {
        this.loader = false;
      }
    }
    else {
      //debugger;
      this.loader = false;
    }
  }
  //this.generateClientData();

  addresscomponent: object;
  async parseGoogleAddress(address) {
    this.displayAddress = address.formatted_address.replace(/#/g, '');
    const google_address = {
      'formatted_address': '',
      'policyOwnerInfo': {}
    };
    const results = [];
    results[0] = address;
    console.log('results address', results, results[0].address_components);
    for (let i = 0; i < results[0].address_components.length; i++) {
      // //debugger
      const type = results[0].address_components[i].types[0];
      // //debugger
      switch (type) {

      case 'street_number':
        google_address['street_number_ln'] = results[0].address_components[i].long_name;
        google_address['street_number_ln'] = results[0].address_components[i].short_name;
        break;
      case 'route':
        google_address['route_ln'] = results[0].address_components[i].long_name;
        google_address['route_sn'] = results[0].address_components[i].short_name;
        break;
      case 'neighborhood':
        google_address['neighborhood_ln'] = results[0].address_components[i].long_name;
        google_address['neighborhood_sn'] = results[0].address_components[i].short_name;
        // addressJson.Addr1[2] = results[0].address_components[i].short_name;
        break;
      case 'locality':
        google_address['locality_ln'] = results[0].address_components[i].long_name;
        google_address['locality_sn'] = results[0].address_components[i].short_name;
        this.city = results[0].address_components[i].short_name;
        break;
      case 'administrative_area_level_2':
        google_address['administrative_area_level_2_ln'] = results[0].address_components[i].long_name;
        google_address['administrative_area_level_2_sn'] = results[0].address_components[i].short_name;
        break;
      case 'administrative_area_level_1':
        google_address['administrative_area_level_1_ln'] = results[0].address_components[i].long_name;
        google_address['administrative_area_level_1_sn'] = results[0].address_components[i].short_name;
        this.state = results[0].address_components[i].short_name;
        break;
      case 'country':
        google_address['country_ln'] = results[0].address_components[i].long_name;
        google_address['country_sn'] = results[0].address_components[i].short_name;
        break;
      case 'postal_code':
        google_address['postal_code_ln'] = results[0].address_components[i].long_name;
        google_address['postal_code_sn'] = results[0].address_components[i].short_name;
        this.zip = results[0].address_components[i].short_name;
        break;
      case 'postal_code_suffix':
        // //debugger
        google_address['postal_code_suffix_ln'] = results[0].address_components[i].long_name;
        google_address['postal_code_suffix_sn'] = results[0].address_components[i].short_name;
        break;
      }

    }
    //debugger;
    google_address.formatted_address = results[0].formatted_address;
    //this.displayAddress = results[0].formatted_address;
    //this.full_address = this.displayAddress;
    google_address['id'] = this.clientId;
    //debugger;
    google_address.policyOwnerInfo['policyOwnerCity'] = this.city;
    google_address.policyOwnerInfo['policyOwnerZipcode'] = this.zip;
    google_address.policyOwnerInfo['policyOwnerState'] = this.state;

    console.log('google_address', google_address);
    //debugger;
    await this.pc.saveUpdateClientData(google_address);
    this.addresscomponent = google_address;
    //debugger;
    //this.handleAddressChange(this.displayAddress);
    //debugger;
    return true;
  }





  async generateClientData() {
    //debugger;
    this.loader = true;
    if (this.term == '65') {
      this.term = this.term - this.age;
    }
    const clientDataJson = {
      'client_id': this.clientId,
      'email': this.email,
      'phone': this.phone,
      'dob': this.dob,
      'gender': this.gender,
      'height': this.height,
      'weight': this.weight,
      'tobaccoUsage': this.tobaccoUsage,
      'coverage': this.coverage,
      'desired_life_term': this.term,
      'desired_life_coverage': this.coverage,
      'zip': this.zip,
      'insurancetype': this.interest_type,
      'interest_type': this.interest_type,
      'life_insurance_type': this.life_insurance_type,
      'lead_source': this.lead_source,
      'lead_form_url': this.lead_form_url,
      'lead_source_time_stamp': this.lead_source_time_stamp,
      'sub_id': this.sub_id,
      'lead_token_id': this.lead_token_id,
      'term': this.term,
      'stateCode': this.state,
      'prefillLifeURL': localStorage.getItem('accessedLifeURL'),
    };

    if (this.firstname && this.firstname != '') clientDataJson['bubble_fname'] = this.firstname;
    if (this.firstname && this.firstname != '') clientDataJson['firstname'] = this.firstname;
    if (this.lastname && this.lastname != '') clientDataJson['bubble_lname'] = this.lastname;
    if (this.lastname && this.lastname != '') clientDataJson['lastname'] = this.lastname;
    //debugger;
    if (this.full_address && this.full_address != '' && this.full_address != undefined) clientDataJson['full_address'] = this.full_address;
    if (this.annual_income && this.annual_income != '') clientDataJson['annual_income'] = this.annual_income;
    if (this.marital_status && this.marital_status != '') clientDataJson['marital_status'] = this.marital_status;
    if (this.number_of_children && this.number_of_children != '') clientDataJson['number_of_children'] = this.number_of_children;
    if (this.active_retired_military_personnel && this.active_retired_military_personnel != '') clientDataJson['active_retired_military_personnel'] = this.active_retired_military_personnel;
    if (this.alzheimers_or_dementia && this.alzheimers_or_dementia != '') clientDataJson['alzheimers_or_dementia'] = this.alzheimers_or_dementia;
    if (this.cancer && this.cancer != '') clientDataJson['cancer'] = this.cancer;
    if (this.heart_disease && this.heart_disease != '') clientDataJson['heart_disease'] = this.heart_disease;
    if (this.state && this.state != '') clientDataJson['stateCode'] = this.state;
    if (this.has_medical_conditions) clientDataJson['has_medical_conditions'] = this.has_medical_conditions;

    console.log('clientDataJson', clientDataJson);
    clientDataJson['id'] = this.clientId;
    await this.saveupdateclientdatacall(clientDataJson);
    const hubSpotData = this.fillInHubspotDataFromFormData(clientDataJson);
    debugger;

    if (this.email) {
      const customData = {
        client_id: this.clientId,
        email: this.email
      };
      this.sendToLO(customData);
    }
    if (this.email != null && this.email != undefined && this.email != '') {
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }

    //debugger;
    this.getdata();

  }


  async navigatetoLiferecoPage() {
    //debugger;
    if (this.email != undefined && this.email != '' && this.dob != undefined && this.dob != '' && this.gender != undefined && this.gender != '' && this.zip != undefined && this.zip != '' && this.tobaccoUsage != undefined && this.tobaccoUsage != '' && this.heightFT != undefined && this.heightFT != '' && this.heightIN != undefined && this.heightIN != '' && this.weight != undefined && this.weight != '' && this.desired_life_coverage != undefined && this.desired_life_coverage != '' && this.term != undefined && this.term != '') {
      //debugger;

      this.loader = true;
      this.GetQuote();
    }
    else {
      this.loader = false;
    }
    return true;
  }




  async saveupdateclientdatacall(json) {
    await this.pc.saveUpdateClientData(json);
    return;
  }

  fillInHubspotDataFromFormData(hubSpotData) {
    debugger;

    let hubSpot_tobacco_user;
    if (this.tobaccoUsage == '5') {
      hubSpot_tobacco_user = 'No';
    }
    else {
      hubSpot_tobacco_user = 'Yes';
    }
    if (this.term == '65') {
      this.term = this.term - this.age;
    }
    //debugger;
    if (this.email) hubSpotData['email'] = this.email;
    if (this.phone) hubSpotData['phone'] = this.phone;
    if (this.dob) hubSpotData['dob_life'] = this.dob;
    if (this.gender) hubSpotData['gender'] = this.gender == 'Male' ? '1' : '0';
    if (this.height) hubSpotData['height'] = this.pc.validateHeightHubspot(this.height);
    if (this.weight) hubSpotData['weight'] = this.weight;
    hubSpotData['tobacco_user'] = hubSpot_tobacco_user;
    hubSpotData['smoker'] = hubSpot_tobacco_user;
    //debugger;
    if (this.term) hubSpotData['desired_life_term'] = this.term;
    if (this.desired_life_coverage) hubSpotData['desired_life_coverage'] = this.desired_life_coverage;
    if (this.coverage) hubSpotData['desired_life_coverage'] = this.coverage;
    if (this.zip) hubSpotData['zip'] = this.zip;
    if (this.interest_type) hubSpotData['interest_type'] = this.interest_type;
    if (this.life_insurance_type) hubSpotData['life_insurance_type'] = this.life_insurance_type;
    if (this.lead_source) hubSpotData['lead_source'] = this.lead_source;
    if (this.lead_form_url) hubSpotData['lead_form_url'] = this.lead_form_url;
    if (this.lead_source_time_stamp) hubSpotData['lead_source_time_stamp'] = this.lead_source_time_stamp;
    if (this.sub_id) hubSpotData['sub_id'] = this.sub_id;
    if (this.lead_token_id) hubSpotData['lead_token_id'] = this.lead_token_id;
    if (this.utm_source) hubSpotData['utm_source'] = this.utm_source;
    if (this.utm_medium) hubSpotData['utm_medium'] = this.utm_medium;
    if (this.utm_campaign) hubSpotData['utm_campaign'] = this.utm_campaign;
    if (this.utm_term) hubSpotData['utm_term'] = this.utm_term;
    if (this.utm_content) hubSpotData['utm_content'] = this.utm_content;
    if (this.dclid) hubSpotData['dclid'] = this.dclid;
    if (this.annual_income) hubSpotData['annual_income'] = this.annual_income;
    if (this.has_medical_conditions) hubSpotData['has_medical_conditions'] = this.has_medical_conditions;
    if (this.active_retired_military_personnel) hubSpotData['active_retired_military_personnel'] = this.active_retired_military_personnel;

    //debugger;


    //Non Mandatory Fields_start


    if (this.firstname && this.firstname != '') hubSpotData['firstname'] = this.firstname;
    if (this.lastname && this.lastname != '') hubSpotData['lastname'] = this.lastname;
    //debugger;
    if (this.full_address && this.full_address != '' && this.full_address != undefined) hubSpotData['full_address'] = this.full_address;
    //debugger;
    if (this.annual_income && this.annual_income != '') hubSpotData['annual_income'] = this.annual_income;
    if (this.marital_status && this.marital_status != '') hubSpotData['marital_status'] = this.marital_status;
    if (this.number_of_children && this.number_of_children != '') hubSpotData['number_of_children'] = this.number_of_children;
    if (this.active_retired_military_personnel && this.active_retired_military_personnel != '') hubSpotData['active_retired_military_personnel'] = this.active_retired_military_personnel;
    if (this.alzheimers_or_dementia && this.alzheimers_or_dementia != '') hubSpotData['alzheimers_or_dementia'] = this.alzheimers_or_dementia;
    if (this.cancer && this.cancer != '') hubSpotData['cancer'] = this.cancer;
    if (this.heart_disease && this.heart_disease != '') hubSpotData['heart_disease'] = this.heart_disease;
    if (this.state && this.state != '' || this.state != undefined) hubSpotData['stateCode'] = this.state;
    if (this.state && this.state != '' || this.state != undefined) hubSpotData['state'] = this.state;
    hubSpotData['city'] = this.city;
    hubSpotData['country'] = this.country;
    if (this.age && this.age != '' && this.age != undefined && this.age != null) hubSpotData['age'] = this.age.toString();
    hubSpotData['application_stage'] = 'Lead';

    //Non Mandatory Fields_end



    //  this.Prefillservice.updateMaLog('req', hubSpotData, this.clientId);
    return hubSpotData;
  }

  changeGender(even) {
    //debugger;
    this.gender = even;
    if (this.gender == 'Male') {
      //debugger;
      localStorage.setItem('bubble-gender', '1');
      this.genderError = '';
    }
    else if (this.gender == 'Female') {
      //debugger;
      localStorage.setItem('bubble-gender', '0');
      this.genderError = '';
    }
    else {
      this.genderError = 'Select a valid Gender.';
    }
  }

  validateEmail() {
    debugger;
    if (this.email != undefined && this.email != null && this.email != '') {
      const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
      const emailtest = this.email;
      if (reg.test(emailtest) == false) {
        this.emailError = 'Please enter a valid email';
        this.disableEmail = false;
      }
      else {
        this.emailError = '';
      }
    }

  }

  async GetQuote() {
    debugger;

    this.backButtonEnable = 'true';
    const reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const emailtest = this.email;
    if (this.email == null || this.email == undefined || this.email == '') {
      this.emailError = 'This field is required';
      this.loader = false;
    }
    else if (reg.test(emailtest) == false) {
      this.emailError = 'Please enter a valid email';
      this.loader = false;
    }
    else if (this.dob == null || this.dob == undefined || this.dob == '') {
      this.dateError = 'Insured should be between 18 years and 55 years of age';
      this.loader = false;
    } else if (this.gender == null || this.gender == undefined || this.gender == '') {
      this.genderError = 'This field is required';
      this.loader = false;
    }
    else if (this.heightFT == null || this.heightFT == undefined || this.heightFT == '') {
      this.heightFTError = 'This field is required';
      this.loader = false;
    }
    else if (this.heightIN == null || this.heightIN == undefined || this.heightIN == '') {
      this.heightINError = 'This field is required';
      this.loader = false;
    }
    else if (this.weightLBS == null || this.weightLBS == undefined || this.weightLBS == '') {
      this.weightLBSError = 'This field is required';
      this.loader = false;
    }
    else if (this.tobaccoUsage == null || this.tobaccoUsage == undefined || this.tobaccoUsage == '' || this.tobaccoUsage == 'Yes') {
      this.tobaccoUsageError = 'This field is required';
      this.loader = false;
    }
    else if (this.desired_life_coverage == null || this.desired_life_coverage == undefined || this.desired_life_coverage == '') {
      this.desired_life_coverageError = 'This field is required';
      this.loader = false;
    }
    else if (this.term == null || this.term == undefined || this.term == '') {
      this.termError = 'This field is required';
      this.loader = false;
    }
    else if (this.zip == null || this.zip == undefined || this.zip == '' && this.ziperror == '') {
      this.ziperror = 'This field is required';
      this.loader = false;
    }
    else if (this.ziperror != '') {
      this.ziperror = 'This field is required';
      this.loader = false;
    }
    else if (this.tobaccoUsage == 'Yes') {
      this.tobaccoUsageSError = 'This field is required';
      this.loader = false;
    } else if (this.term == '0') {
      this.termError = 'This field is required';
      this.loader = false;
    }
    else if
    (this.dateError == 'Insured should be between 18 years and 55 years of age') {
      this.dateError == 'Insured should be between 18 years and 55 years of age';
      this.loader = false;
    } else if
    (this.phoneError == 'Enter valid phone number') {
      this.phoneError = 'Enter valid phone number';
      this.loader = false;
    }
    else {
      //debugger;
      this.loader = true;
      localStorage.setItem('clientID', this.clientId);
      localStorage.setItem('clientId', this.clientId);
      this.Prefillservice.saveAddressDetails(this.zip, this.clientId);
      const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
      let hubSpot_tobacco_user;
      if (this.tobaccoUsage == '5') {
        hubSpot_tobacco_user = 'No';
      }
      else {
        hubSpot_tobacco_user = 'Yes';
      }
      if (this.term == '65') {
        this.term = this.term - this.age;
      }
      const savedata = {
        'id': this.clientId,
        'email': this.email,
        'gender': this.gender == 'Male' ? '1' : '0',
        'bubble_fname': this.firstname,
        'bubble_lname': this.lastname,
        'bubble_email': this.email,
        'bubble_phone': this.phone,
        'dob': this.dob,
        'bubble_heightFt': this.heightFT,
        'bubble_heightIn': this.heightIN,
        'bubble_weight': this.weight,
        'zip': this.zip,
        'zip_code': this.zip,
        'stateCode': this.state,
        'city': this.city,
        'policyOwnerInfo': {}
      };
      savedata.policyOwnerInfo['policyOwnerCity'] = this.city;
      savedata.policyOwnerInfo['policyOwnerZipcode'] = this.zip;
      savedata.policyOwnerInfo['policyOwnerState'] = this.state;

      localStorage.setItem('policyOwnerCity_preFill', this.city);
      localStorage.setItem('policyOwnerZipcode_preFill', this.zip);
      localStorage.setItem('policyOwnerState_preFill', this.state);

      localStorage.setItem('bubble-zip', this.zip);
      localStorage.setItem('bubble-state', this.state);
      localStorage.setItem('bubble-city', this.city);


      this.pc.saveUpdateClientData(savedata);
      const clientDataJson = {
        //'id': this.clientId,
        'email': this.email,
        'consent_to_contact': true
      };
      //debugger;
      clientDataJson['email'] = this.email;
      clientDataJson['phone'] = this.phone;
      clientDataJson['dob_life'] = this.dob;
      clientDataJson['gender'] = this.gender == 'Male' ? '1' : '0';
      clientDataJson['height'] = this.pc.validateHeightHubspot(`${this.heightFT}'${this.heightIN}"`);
      clientDataJson['weight'] = this.weightLBS;
      clientDataJson['tobacco_user'] = hubSpot_tobacco_user;
      clientDataJson['smoker'] = hubSpot_tobacco_user;
      clientDataJson['desired_life_term'] = this.term;
      clientDataJson['desired_life_coverage'] = this.desired_life_coverage.toString();
      clientDataJson['interest_type'] = 'Life';
      clientDataJson['address'] = this.displayAddress;
      clientDataJson['customer_id'] = customerIdLocal;
      clientDataJson['application_stage'] = 'Lead',
      clientDataJson['consent_to_contact'] = true,
      clientDataJson['lead_source_time_stamp'] = new Date();
      clientDataJson['zip'] = this.zip;
      clientDataJson['dropoff_url'] = this.dropoffURL;
      clientDataJson['age'] = this.age.toString();
      //clientDataJson['user_clicked_the_landing_page_button'] = 'Yes';
      clientDataJson['lead_source'] = this.lead_source;
      clientDataJson['client_id'] = this.clientId;
      if (this.utm_source) clientDataJson['utm_source'] = this.utm_source;
      if (this.utm_medium) clientDataJson['utm_medium'] = this.utm_medium;
      if (this.utm_campaign) clientDataJson['utm_campaign'] = this.utm_campaign;
      if (this.utm_term) clientDataJson['utm_term'] = this.utm_term;
      if (this.utm_content) clientDataJson['utm_content'] = this.utm_content;

      console.log('HS 3');


      if (this.email) {
        const customData = {
          client_id: this.clientId,
          email: this.email
        };
        this.sendToLO(customData);
      }

      clientDataJson['customGAEvent'] = { 'event':'landingPage-Submit' };
      this.pc.hubSpotSyncAfterEmailFetch(clientDataJson);

      this.insurancetype = 'life';

      this.uniqueId = pc.getUniqueId4Property(this.clientId, this.displayAddress);
      //debugger;
      if (this.firstname == '' || this.firstname == null || this.firstname == undefined || this.firstname == 'false') {
        this.firstname = '';
        //debugger;
      }
      if (this.lastname == '' || this.lastname == undefined || this.lastname == null) {
        //debugger;
        this.lastname = '';
      }

      this.fullName = this.firstname + ' ' + this.lastname;
      localStorage.setItem('bubble-fullName', this.fullName);

      if (localStorage.getItem('bubble-fullName') !== null) {
        this.fullName = localStorage.getItem('bubble-fullName');

      }
      else {
        this.fullName = 'Anonymous User';
      }
      if (this.term == '65') {
        this.term = this.term - this.age;
      }

      const today = new Date();
      const birthDate = new Date(this.dob);
      this.age = today.getFullYear() - birthDate.getFullYear();
      //debugger;
      localStorage.setItem('bubble-tobacco', this.tobaccoUsage);
      localStorage.setItem('bubble-tobaccoUsageDuration', this.tobaccoUsage);
      localStorage.setItem('bubble-dob', this.dob);
      localStorage.setItem('bubble-lifejourneystart', 'true');
      localStorage.setItem('insuranceType', 'life');
      localStorage.setItem('bubble-age', this.age);
      localStorage.setItem('bubble-heightFt', this.heightFT);
      localStorage.setItem('bubble-heightIn', this.heightIN);
      localStorage.setItem('bubble-weight', this.weightLBS);
      localStorage.setItem('bubble-termLength', this.term);
      localStorage.setItem('bubble-coverage', this.desired_life_coverage);
      localStorage.setItem('bubble-fullName', this.fullName);
      localStorage.setItem('bubble_fname', this.firstname);
      localStorage.setItem('bubble_lname', this.lastname);
      localStorage.setItem('bubble-fname', this.firstname);
      localStorage.setItem('bubble-lname', this.lastname);

      this.storageService.setItem(constants.bubble_fname, this.firstname);
      this.storageService.setItem(constants.bubble_lname, this.lastname);

      localStorage.setItem('riskClass', 'Super Preferred Non-Tobacco');
      localStorage.setItem('bubble-mortgage', '0');
      localStorage.setItem('bubble-kids', this.number_of_children);
      localStorage.setItem('bubble-annualIncome', this.annual_income);
      localStorage.setItem('bubble-email', this.email);
      localStorage.setItem('bubble-street', '');
      localStorage.setItem('bubble-phone', this.phone);
      localStorage.setItem('backButtonEnable', this.backButtonEnable);
      localStorage.setItem('lifeRecoBackButton', 'SingleMotherLife');



      const smokings = localStorage.getItem('bubble-tobacco');
      const genders = localStorage.getItem('bubble-gender');
      const heights = localStorage.getItem('bubble-heightFt') + 'ft ' + localStorage.getItem('bubble-heightIn') + 'inch';
      const weights = localStorage.getItem('bubble-weight');
      this.pc.getPacLifeRiskClass(smokings, genders, heights, weights).then(response => {
        ////debugger
        let PacLifeRiskClass = response.data.output;
        //console.log('PacLifeRiskClass :', PacLifeRiskClass);
        const dp = JSON.parse(PacLifeRiskClass);
        console.log('PacLifeRiskClass :', dp.dput1);
        PacLifeRiskClass = dp.dput1;
        localStorage.setItem('riskClass', PacLifeRiskClass);
        this.getdata();
      })
        .catch(error => {
          console.log(error);
        });

    }
  }


  ChangeheightFT() {
    //debugger;
    if (this.heightFT == null || this.heightFT == undefined || this.heightFT == '') {
      this.heightFTError = 'This field is required';
    } else {
      //this.heightFTError = "";
      if (this.heightIN == null || this.heightIN == undefined || this.heightIN == '') {
        this.heightFTError = 'This field is required';
      } else {
        this.heightFTError = '';
      }
    }
  }


  ChangeweightLBS() {
    if (this.weightLBS == null || this.weightLBS == undefined || this.weightLBS == '') {
      this.weightLBSError = 'This field is required';
    } else {
      this.weightLBSError = '';
    }
  }


  Changedesired_life_coverage() {
    if (this.desired_life_coverage == null || this.desired_life_coverage == undefined || this.desired_life_coverage == '') {
      this.desired_life_coverageError = 'This field is required';
    } else {
      this.desired_life_coverageError = '';
    }

    if (Number(this.desired_life_coverage) < 25000) {
      this.coverage = '25000';
      this.desired_life_coverageError = 'Coverage cannot be less than 25,000';
    }
    else if (Number(this.desired_life_coverage) > 10000000) {
      this.coverage = '10000000';
      this.desired_life_coverageError = 'Coverage cannot be greater than 10,000,000';
    }
    else {
      this.desired_life_coverageError = '';
    }

  }

  ChangetobaccoUsage(even) {
    debugger;
    if (this.tobaccoUsage == 'Yes') {
      this.tobaccoUsageSError = '';
    } else if (this.tobaccoUsage == 'No') {
      // this.tobaccoUsage=even.target.value;
      //debugger;
      this.tobaccoUsage = 5;
      this.tobaccoUsageSError = '';
    }
    else {
      this.tobaccoUsage = even;
      this.tobaccoUsageSError = '';
    }
  }

  Changeterm(termLength) {
    //debugger;
    this.term = termLength;
    this.termError = '';
    // if (this.term == '0') {
    //   this.termError = "This field is required";
    // } else {
    //   this.termError = "";

    // }

  }


  insuranceType: any;
  recoType: any;
  commonAddress: any;
  async getdata() {
    //debugger;
    // this.handleAddressChange(this.displayAddress);
    // this.fullAddress = this.displayAddress + "-" + this.city + "-" + this.state + "-" + this.zip;
    // this.fullAddress = this.fullAddress.replace(/ /g, "-");
    // this.pc.fullAddress = this.fullAddress;
    this.pc.getAppData(this.clientId).then(data => {
      //debugger;
      const dataFrom = JSON.parse(data.Payload);
      const clientData = dataFrom.body;
      console.log('dataFrom get app data : ', clientData);
      //debugger;
      //this.fullAddress = clientData.fullAddress;
      //this.uniqueId = pc.getUniqueId4Property(this.clientId, this.fullAddress);
      this.insuranceType = 'life';
      localStorage.setItem('insuranceType', this.insuranceType);
      this.recoType = 'SelectLifeCarrierV2';
      //debugger;
      this.commonAddress = localStorage.getItem('bubble-street');
      if (this.fullName != undefined) {
        const splitname = this.fullName.split(' ');
        const commonInputLife = {
          lastName: splitname[2] ? splitname[2] : 'Lastname',
          firstName: splitname[0] ? splitname[0] : 'Firstname',
          middleName: splitname[1] ? splitname[1] : 'MiddleName',
          addressType: 'PhysicalRisk',
          address: this.commonAddress,//splitAddress[(splitAddress.length - 3)],
          city: this.city,
          gender: this.gender == '1' ? 'M' : 'F', //inputJson.gender
          dob: this.dob,//inputJson.dob
          term: this.term,//inputJson.term
          zip: this.zip,//inputJson.zip
          state: this.state,//inputJson.state
          height: this.height,//inputJson.height
          weight: +this.weight,//inputJson.weight
          tobaccoUse: +this.tobaccoUsage,//inputJson.tobaccoUse
          riskClass: '',
          coverage: this.coverage,//inputJson.coverage
          premiumFrequency: 'm',
        };

        //debugger;
        localStorage.setItem('commonInput', JSON.stringify(commonInputLife));
        localStorage.setItem('bubble-dob', commonInputLife['dob']);
        console.log('common input : ', commonInputLife);
        console.log('commonInputLife : ', commonInputLife);



        const resumeURL = `${this.recoType}/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insuranceType}`;
        console.log('resumeURL generated: ', resumeURL);
        this.resumeFlow(resumeURL);
      }
    }).catch(error => {
      console.log(error);
    });
  }
  async resumeFlow(resumeURL) {
    this.loader = true;
    const createJson = {
      insuranceType: 'life',
      carrierName: 'SBLI Term',
      appStartStatus: false,
      appCompletedStatus: false,
      questionStartStatus: false,
      questionCompletedStatus: false,
      questionSequence: '0',
      applicationId: '',
      otherAppflowScreen: '',
      resumeUrl: resumeURL
    };
    const createResumeData = await this.pc.checkforResumeInit(createJson, 'create');
    console.log('createResumeData: ', createResumeData);
    //this.router.navigate(['/resumeFlow', this.clientId]);
    this.router.navigate([resumeURL]);
  }



  latitude: any;
  longitude: any;
  placeId: any;
  addressChanged: any;
  stateLongName: any;
  city: any;
  public handleAddressChange(address: Address) {
    //debugger;
    if ('function' === typeof address.geometry.location.lat) {
      this.latitude = address.geometry.location.lat();
      this.longitude = address.geometry.location.lng();
    }
    this.placeId = address.place_id;

    this.pc.lat = this.latitude;
    this.pc.lng = this.longitude;
    this.pc.placeId = this.placeId;

    /* console.log("@@---->", address.geometry.location);*/
    console.log('@@---->latitude', this.latitude);
    console.log('@@---->longitude', this.longitude);
    this.fullAddress = address;
    console.log('formatted_address ', address.formatted_address);
    console.log('JSON.stringify:address.address_components ', JSON.stringify(address.address_components, null, 2));
    //debugger;

    this.addressChanged = true;
    const eventOptionsPart1 = {
      'path': 'homeScore',
      'page_title': 'homeScore', 'url': window.location.href
    };
    this.utilityService.eventWrapped(eventOptionsPart1, 'addressClick', 'event', `lifescore_page: addressChangedTo: ${this.displayAddress} `);



    let county = '';//'Autauga County';
    let stateCodeLocal = '';//'AL';
    let city = '';//'Autauga';
    let cityLongName = '';//'Autauga';
    let zip = '';
    let countyLongName = '';
    let street_address = '', street_ln = '', route_sn = '';
    let country = '';
    for (let i = 0; i < address.address_components.length; i++) {
      if (address.address_components[i].types) {
        if (address.address_components[i].types.includes('street_number')) {
          street_ln = address.address_components[i].long_name;
        } else if (address.address_components[i].types.includes('route')) {
          route_sn = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('administrative_area_level_2')) {
          county = address.address_components[i].short_name;
          countyLongName = address.address_components[i].long_name;
        } else if (address.address_components[i].types.includes('locality')) {
          city = address.address_components[i].short_name;
          cityLongName = address.address_components[i].long_name;
        } else if (address.address_components[i].types.includes('administrative_area_level_1')) {
          stateCodeLocal = address.address_components[i].short_name;
          this.stateLongName = address.address_components[i].long_name;
        } else if (address.address_components[i].types.includes('postal_code')) {
          zip = address.address_components[i].short_name;
        } else if (address.address_components[i].types.includes('country')) {
          country = address.address_components[i].short_name;
        }
      }
    }

    street_address = street_ln + ' ' + route_sn;
    localStorage.setItem('bubble-street', street_address);
    this.city = city;

    setTimeout(() => {

    }, 1000);
  }


  closeModal() {
    document.getElementById('errorModal').style.display = 'none';
    document.getElementById('PreFill_id').style.filter = 'none';

    //   window.location.href = this.pc.envCred.url;

  }

  inputdatechange() {
    debugger;
    const K = this.dob.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.dateError = 'Invalid Month';
      }
      else {
        this.dateError = '';
      }
      this.dob = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.dateError = 'Invalid Date';
        }
        else if (A <= 12) {
          this.dob = A + '/' + B + '/';
          this.dateError = '';
        }
        else {
          this.dateError = 'Invalid Date';
        }

      }
      else {
        this.dob = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.dob = A + '/' + B + '/' + C;
    }
    else {
      this.dob = this.dob;
    }
    if (this.dob.length >= 10 && this.dateError != 'Invalid Date') {
      this.onDateChange(this.dob);
    }
    else {
      this.is_valid = false;
    }
  }

  onDateChange(data) {
    debugger;
    if (data != undefined && data != null && data != '') {
      this.NewDate = data ? new Date(data) : new Date();
      const year = Number(this.NewDate.getFullYear());
      this.Date = new Date();
      const yearNewDate = Number(this.Date.getFullYear());
      this.age = yearNewDate - year;
      if (this.age > 18 && this.age < 55) {
        this.dateError = '';
        // const newdob = new Date(data);
        // const yearnew = Number(newdob.getFullYear());
        // let monthnew = '' + (newdob.getMonth() + 1);
        // let daynew = '' + newdob.getDate();
        // if (monthnew.length < 2)
        //   monthnew = '0' + monthnew;
        // if (daynew.length < 2)
        //   daynew = '0' + daynew;
        // this.dob = monthnew + '/' + daynew + '/' + yearnew;
        this.dob = this.pc.momentdob(data);
      } else {
        this.dob = '';
        this.dateError = 'Insured should be between 18 years and 55 years of age';
      }
    }


  }






  setGAEvent() {
    this.loader = true;
    //action, category, label
    if (this.email == null) {

      const eventOptionsPart1 = { 'path': 'SingleMotherLife', 'page_title': 'SingleMotherLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'email'
      });
    }
    if (this.dob == null) {
      const eventOptionsPart1 = { 'path': 'SingleMotherLife', 'page_title': 'SingleMotherLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'dob'
      });
    }
    if (this.gender == null) {
      const eventOptionsPart1 = { 'path': 'SingleMotherLife', 'page_title': 'SingleMotherLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'gender'
      });
    }
    if (this.height == null) {
      const eventOptionsPart1 = { 'path': 'SingleMotherLife', 'page_title': 'SingleMotherLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'height'
      });
    }
    if (this.weight == null) {
      const eventOptionsPart1 = { 'path': 'SingleMotherLife', 'page_title': 'SingleMotherLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'weight'
      });
    }
    if (this.tobaccoUsage == null) {
      const eventOptionsPart1 = { 'path': 'SingleMotherLife', 'page_title': 'SingleMotherLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'tobaccoUsage'
      });
    }
    if (this.term == null) {
      const eventOptionsPart1 = { 'path': 'SingleMotherLife', 'page_title': 'SingleMotherLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'term'
      });
    }
    if (this.coverage == null) {
      const eventOptionsPart1 = { 'path': 'SingleMotherLife', 'page_title': 'SingleMotherLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'coverage'
      });
    }
    if (this.zip == null) {
      const eventOptionsPart1 = { 'path': 'SingleMotherLife', 'page_title': 'SingleMotherLife', 'url': window.location.href }; this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'LifeLandingPage', {
        'event_category': 'Error', 'event_label': 'zip'
      });
    }
  }



  phonelength() {
    let count = 0;

    if (this.phone.length == 0 || this.phone.length == undefined) {
      this.phoneError = '';
    } else {
      this.phone = this.phone.replace(/^0+/, '');
      const phonestring = this.phone.toString().slice(0, this.phone.length);
      this.phone = phonestring.replace(/(\d{3})-?/g, function (m, a) {
        return ++count <= 2 ? a + '-' : m;
      });
      this.phone = this.phone.slice(0, 12);
      if (this.phone.length < 12) {
        this.phoneError = 'Enter valid phone number';
      }
      else {
        this.phoneError = '';
      }
    }


  }

  ziperror: any;

  async ziplength() {
    //debugger;
    if (this.zip.length > 5 || this.zip.length < 5) {
      //debugger;
      this.ziperror = 'Invalid zip code';
    } else {
      //debugger;
      this.loader = true;
      this.ziperror = '';
      await this.validateZip('2');

    }

  }

  async validateZip(even) {
    debugger;
    pc.BackendService.getGoogleAddressDetails(even == '0' ? this.full_address : this.zip).then(
      async (res) => {
        debugger;
        this.city = res.data.results[0].address_components[1].short_name;
        this.state = res.data.results[0].address_components[3].short_name;
        this.country = res.data.results[0].address_components[4].short_name;
        console.log('BackendService.getGoogleAddressDetails success', res);
        //debugger;
        if (res.data.results.length == 0) {
          this.errormessage = 'Please enter a zipcode inside USA.';
          document.getElementById('errorModal').style.display = 'block';
          this.loader = false;
          this.zip = '';
          return false;
        }
        else {
          this.displayAddress = res.data.results[0].formatted_address.replace(/#/g, '');
          const formatted_address = res.data.results[0].formatted_address;
          let checkCountry = formatted_address.split(',');
          checkCountry = checkCountry[checkCountry.length - 1];
          checkCountry = checkCountry.replace(/\s/g, '');

          if (checkCountry === 'USA') {
            const validateStateUrl = `${this.pc.envCred.api2Domain}?data_category=validateZip4LicensedState&dget0=life&dget1=${this.zip}`;
            console.log('validateStateUrl:', validateStateUrl);
            let validateState = await axios.get(validateStateUrl);
            //debugger;
            console.log('validateState 1: ', validateState);
            validateState = validateState.data;
            let validateStateError = false;
            if (validateState['dput2']) {
              // data: {dput2: "CA"}
              this.state = validateState['dput2'];
              validateStateError = false;
              this.displayAddress = res.data.results[0].formatted_address;
              if (even == '0' || even == '1') {
                //debugger;
                await this.parseGoogleAddress(res.data.results[0]);
                // await this.generateClientData();
                await this.handleAddressChange(res.data.results[0]);
              }
              if (even == '2') {
                //debugger;
                await this.parseGoogleAddress(res.data.results[0]);
                // await this.generateClientData();
                await this.handleAddressChange(res.data.results[0]);
                this.loader = false;
              }
              this.ziperror = '';

            }
            else if (validateState['dput1']) {
              const allowedStates = validateState['dput1'].filter(item => item['golivelife'] == 'Y');
              console.log('allowedStates', allowedStates);
              let allowedStatesString = '';
              if (allowedStates.length == 1) {
                allowedStatesString = allowedStates[0]['state'];
              } else {
                for (let i = 0; i < allowedStates.length; i++) {
                  allowedStatesString += allowedStates[i]['state'] + ', ';
                }
              }
              allowedStatesString = 'We currently serve only in ' + allowedStatesString;
              allowedStatesString = allowedStatesString.replace(/,\s*$/, '.');
              allowedStatesString = allowedStatesString.replace(/,(?=[^,]*$)/, ' and');
              console.log('allowedStatesString', allowedStatesString);
              validateStateError = true;
              console.log('BackendService.getGoogleAddressDetails success', res);
              this.errormessage = allowedStatesString;
              document.getElementById('errorModal').style.display = 'block';
              this.zip = '';
              this.loader = false;
              this.ziperror = 'Enter a valid Zip code';
            }
          }
          else {
            //debugger;
            this.errormessage = 'Please enter a zipcode inside USA.';
            document.getElementById('errorModal').style.display = 'block';
            this.loader = false;
            this.zip = '';
            this.ziperror = 'Enter a valid Zip code';
          }
        }
        return true;
      }
    );
  }

  cookupCustomerId(firstname, lastname, clientId) {
    let firstNameLocal, lastNameLocal;
    try { firstNameLocal = firstname[0].toLowerCase(); } catch (error) { firstNameLocal = 'a'; }
    try { lastNameLocal = lastname[0].toLowerCase(); } catch (error) { lastNameLocal = 'b'; }
    return `${firstNameLocal}${lastNameLocal}${clientId}`;
  }

  sliderImageChange(even) {
    if (even == 1) {
      this.activeImage = 1;
    }
    else if (even == 2) {
      this.activeImage = 2;
    }
    else {
      this.activeImage = 3;
    }
  }

  public test() {
    const key = '1a8c636c-1cec-4f08-b35d-7c7b2bd14016';
    const url = `https://api.hubapi.com/contacts/v1/contact/?hapikey=${key}`;
    console.log('email: ', 'lifetest1212@gmail.com');
    const hubspotData = {
      'properties': [
        {
          'property': 'email',
          'value': 'lifetest1212@gmail.com',
        }
      ]
    };
    //hubspotData = JSON.stringify(hubspotData)
    return new Promise((resolve, reject) => {
      axios({
        method: 'POST',
        url,
        headers: {
          'Content-Type': 'application/json',
        },
        data: hubspotData
      }).then(response => {
        if (response) {
          const { data, status, statusText } = response;
          console.log({ data, status, statusText });
          // resolve({ data, status, statusText });
          resolve({ 'request': JSON.stringify(hubspotData), 'response': JSON.stringify(data) });
        } else {
          console.log('error For data:', hubspotData);
          // resolve({ "data": { error: "Unknown Error" }, status: 200, statusText: "Error while invoke" });
          resolve({ 'request': JSON.stringify(hubspotData), 'response': 'Unknown Error' });
        }

      }).catch(error => {
        console.log('error:', error.response.data, ' For data:', hubspotData);
        resolve({ 'request': JSON.stringify(hubspotData), 'response': JSON.stringify(error.response.data) });
        // reject(error)
      });
    });


  }

}
