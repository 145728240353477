import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-greeting-notes',
  templateUrl: './greeting-notes.component.html',
  styleUrls: ['./greeting-notes.component.css']
})
export class GreetingNotesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
