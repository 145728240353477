import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-personal-details-question6',
  templateUrl: './personal-details-question6.component.html',
  styleUrls: ['./personal-details-question6.component.css']
})
export class PersonalDetailsQuestion6Component implements OnInit {
  @Output() nextquestion5 = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  public getCardID(){
    document.getElementById('justbasicid').style.borderColor="Orange";
    document.getElementById('justbasicid').style.borderWidth="2px";
  }

  next() {
  
    let etty = {
      "questionNo": "5",
      "nextprev": 1
    }
    this.nextquestion5.emit(etty);
  }
  
}
