import { Component, OnInit } from '@angular/core';
import { ImageCacheService } from '../../shared/image-cache.service';

@Component({
  selector: 'app-text-to-image',
  templateUrl: './text-to-image.component.html',
  styleUrls: ['./text-to-image.component.css']
})
export class TextToImageComponent implements OnInit {
  bloburl: any;
  blob: any;

  constructor(private pc: ImageCacheService) { }

  ngOnInit(): void {
    //this.createimage();
  }

  createimage() {
    
    let fontsize = "25";
    let fontcolor = "Green";
    let imgtext = "Hello World!";
    
    this.blob = this.pc.getImageBlob(fontsize, fontcolor, imgtext);
    this.bloburl = this.blob.src;
    //document.getElementById("boundimg").append = this.bloburl;
    console.log("blob : ", this.blob);
    console.log("blob src : ", this.bloburl);
  }

}
