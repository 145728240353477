import { ChangeDetectorRef, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { AnswerModel } from '../../models/answer.model';
import { FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { QuestionModel } from '../../models/question.model';

@Component({
  selector: 'app-input-select',
  templateUrl: './input-select.component.html',
  styleUrls: ['./input-select.component.css']
})
export class InputSelectComponent implements OnInit {

  @Output() onChange:EventEmitter<number | string> = new EventEmitter();
  @Input() public questionId: number | undefined;
  @Input() public optionValues: AnswerModel[] | undefined | null;
  @Input() public question!: QuestionModel;
  childForm!: FormGroup;
  answerId:any;
  constructor(private rootFormGroup: FormGroupDirective, private changeDetectorRef: ChangeDetectorRef) { }

  ngOnInit(): void {
    debugger
    this.question['required'] = 1;
    const answerControl = 'answer_value_' + this.questionId;
    this.childForm = this.rootFormGroup.form as FormGroup;
    const answerValue = this.childForm.get(answerControl)?.value;
    this.questionId = this.question.questionID;
    this.answerId = 'answer_value_' + this.questionId;
    const _validator: any[] = [];
    if (this.question?.required == 1) _validator.push(Validators.required);
    this.childForm.get(this.answerId)?.setValidators(_validator);
    if (answerValue == null) {
      this.childForm.get(answerControl)?.setValue(null);
    }
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
    // console.log('detact hanges');
  }

  changeValue(event: any,text) {
    debugger
    this.onChange.emit(event.target.value);
    this.childForm.get('answer_option_id_' + this.questionId)?.setValue(event.target.value);
    this.childForm.controls['selected_answer'].setValue(text.filter(x=>x.answer_option_id==event.target.value)[0].answer_text);
    this.childForm.value['selected_answer']=text.filter(x=>x.answer_option_id==event.target.value)[0].answer_text;
  }

}
