import { Component, OnInit } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from './../../components/lifescore-error-alert/lifescore-error-alert.component';
import { ToastService } from './../../shared/toast.service';
import { environment } from './../../../environments/environment';
import { formatAmount, formatAmountCommaDollar } from './../../shared/UtilityFunctions.js';
import { TrackerService } from 'src/app/shared/tracker.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
declare let require: any;
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-select-life-carrier',
  templateUrl: './select-life-carrier.component.html',
  styleUrls: ['./select-life-carrier.component.css'],

})
export class SelectLifeCarrierComponent implements OnInit {

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  gender: any;
  genderString: string;
  age: any;
  lifeScoreData: any;
  allScores: any;
  insurancetype: any;
  maxCoverageAmont: any;
  miniumTermLength: any;
  fullName: string;
  emailId: string;
  tobacco: string;
  termLength: string;
  dob: string;
  height: string;
  weight: string;
  coverage: string;
  commonAddress: string;
  city: string;
  state: any;
  zipcode: any;
  quickQuoteData: any;
  carrier1: any;
  carrier2: any;
  carrier3: any;
  carrierSelected = 'pac-life';
  loader: boolean;
  selectedCardData: any;
  selectedCard: number;
  showselectedcarrier: number;
  imgwid: any;
  calculatedTerm: number;
  mortgage: string;
  kids: string;
  annualIncome: string;
  riskClass: string;
  termLengthToDisplay: string;
  coveragetodisaply: any;
  coverageAmountText: string;
  disclaimerScript1: string;
  showSBLI: number;
  url: any;
  addressToShow: any;
  selectedCarrier: number;
  showModal = false;
  isMobileResolution: boolean;
  isDescktopResolution: boolean;
  carrierName: any;
  carrierAmount: any;
  homeselectedCard: number;
  pageToOpen = '';
  showAssumptions = false;
  homeURL:string;
  showPaclife: number;
  termLengthCopy: string;
  termLengthDisplay: string;
  backButton: string;
  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private modalService: NgbModal, private tracker: TrackerService,
    public toastService: ToastService, public router: Router, private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    this.loader = true;
    this.homeURL = this.pc.envCred.url;
    document.getElementById('index_title_id').innerText = 'Life Insurance powered by Bubble';
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      this.addressToShow = this.displayAddress.replace(/-/g, ' ');
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
    }
    );
    this.backButton = localStorage.getItem('lifeRecoBackButton');

    if (window.innerWidth < 600) {
      this.isMobileResolution = true;
      this.isDescktopResolution = false;

    } else {
      this.isMobileResolution = false;
      this.isDescktopResolution = true;

    }

    this.url = window.location.href;

    // this.loader = true;
    if (localStorage.getItem('selectedHomeCarrier') !== null) {
      const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
      this.carrierName = selectedHome.head.carrier_name;
      this.carrierAmount = selectedHome.head.quote_amount;
    }
    this.homeselectedCard = 1;
    this.imgwid = '60px';
    this.showSBLI = 0;

    this.age = localStorage.getItem('bubble-age');
    this.mortgage = localStorage.getItem('bubble-mortgage');
    this.kids = localStorage.getItem('bubble-kids');
    this.annualIncome = localStorage.getItem('bubble-annualIncome');

    this.mortgage = this.mortgage.split(',').join('');
    this.annualIncome = this.annualIncome.split(',').join('');
    this.age = this.age.split(',').join('');
    this.kids = this.kids.split(',').join('');

    this.pc.getCoverageRecommendation(this.mortgage, this.annualIncome, this.age, this.kids).then(response => {
      const recomendedCoverage = response.data.output;

      console.log('mortgage :', this.mortgage, '#this.annualIncome: ', this.annualIncome, '#this.age:', this.age, '#this.kids: ', this.kids);
      console.log('recomendedCoverage :', recomendedCoverage);
      if (localStorage.getItem('bubble-coverage') == null || localStorage.getItem('bubble-coverage') == undefined || localStorage.getItem('bubble-coverage') == '') {
        localStorage.setItem('bubble-coverage', recomendedCoverage);
      }
      this.age = localStorage.getItem('bubble-age');
      this.calculatedTerm = (65 - Number(this.age));
      this.termLength = this.calculatedTerm.toString();
      this.termLengthCopy = this.termLength;
      this.termLengthDisplay = this.termLength;
      localStorage.setItem('bubble-termLength', this.termLength);
      localStorage.setItem('bubble-termLengthCopy', this.termLength);
      this.getQuote();
    })
      .catch(error => {
        console.log('getCoverageRecommendation', error);
      });


    if (this.insurancetype == 'life') {
      this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 6 });
    } else {
      this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 9 });
    }


  }

  async handleSelect() {

  }

  getQuote() {
    debugger;
    console.log('loader : ', this.loader);
    if (localStorage.getItem('bubble-fullName') !== null) {
      this.fullName = localStorage.getItem('bubble-fullName');
      this.emailId = localStorage.getItem('bubble-email');
    }
    else {
      this.fullName = 'Anonymous User';
    }

    this.gender = localStorage.getItem('bubble-gender');
    // this.gender = this.gender == '1' ? "Male" : "Female";
    this.tobacco = localStorage.getItem('bubble-tobacco');
    this.tobacco = this.tobacco == '1' ? 'Yes' : 'No';
    this.dob = localStorage.getItem('bubble-dob');
    this.age = localStorage.getItem('bubble-age');
    this.height = localStorage.getItem('bubble-heightFt') + 'ft ' + localStorage.getItem('bubble-heightIn') + 'inch';
    this.weight = localStorage.getItem('bubble-weight');
    this.termLength = localStorage.getItem('bubble-termLength');
    this.coverage = localStorage.getItem('bubble-coverage');
    this.commonAddress = localStorage.getItem('bubble-street');

    // let splitAddress = this.displayAddress.split("-");
    // this.city = splitAddress[(splitAddress.length - 4)];
    // this.state = splitAddress[(splitAddress.length - 3)];
    // this.zipcode = splitAddress[(splitAddress.length - 2)];

    let fullAddress = this.uniqueId.split('-');
    fullAddress.shift();
    fullAddress = fullAddress.join(' ');
    this.pc.parseAddressLocalAndPatch(fullAddress, this.clientId).then(
      data => {
        console.log('splitAddressNew : ', data);
        this.state = data.state_code;
        this.city = data.city_name;
        this.zipcode = data.zip;
        console.log('fullAddressNew : ', fullAddress);
        //console.log("splitAddressNew : ", splitAddressNew);

        const sbligender = localStorage.getItem('bubble-gender');// == "Male" ? "1" : "0";
        const sblitobacco = localStorage.getItem('bubble-tobacco');
        const d = new Date(this.dob);
        const year = d.getFullYear();
        console.log('year : ', year, '# this.age:', this.age, '#this.dob:', this.dob);
        //let sbliDob = "01/01/" + year;
        const sbliDob = this.dob;
        const splitname = this.fullName.split(' ');
        // debugger

        const localterm = localStorage.getItem('bubble-termLength');

        if (localterm == null || localterm == undefined) {
          this.calculatedTerm = (65 - Number(this.age));
          this.termLength = this.calculatedTerm.toString();
        } else {
          this.termLength = localStorage.getItem('bubble-termLength');
        }


        localStorage.setItem('bubble-termLength', this.termLength);
        this.riskClass = localStorage.getItem('riskClass');

        const commonInput = {
          lastName: splitname[2] ? splitname[2] : 'Lastname',
          firstName: splitname[0] ? splitname[0] : 'Firstname',
          middleName: splitname[1] ? splitname[1] : 'MiddleName',
          addressType: 'PhysicalRisk',
          address: this.commonAddress,//splitAddress[(splitAddress.length - 3)],
          city: this.city,
          gender: sbligender == '1' ? 'M' : 'F', //inputJson.gender
          dob: sbliDob,//inputJson.dob
          term: this.termLength,//inputJson.term
          zip: this.zipcode,//inputJson.zip
          state: this.state,//inputJson.state
          height: this.height,//inputJson.height
          weight: +this.weight,//inputJson.weight
          tobaccoUse: +sblitobacco,//inputJson.tobaccoUse
          riskClass: this.riskClass,
          coverage: this.coverage,//inputJson.coverage
          premiumFrequency: 'm',
        };
        this.genderString = sbligender == '1' ? 'Male' : 'Female';

        console.log('commonInput before getQuickQuote2 invoke : ', commonInput);
        const quickquote = new pc.Quotes(this.uniqueId);
        // debugger
        //getQuickQuote2(commonInput, true) => to enable SBLI Quote
        //getQuickQuote2(commonInput, false) => to disable SBLI Quote
        //getQuickQuote2(commonInput) => to disable SBLI Quote
        quickquote.getQuickQuote2(commonInput, true)
          .then(response => {
            debugger;
            console.log('commonInput after getQuickQuote2 invoke : ', commonInput);
            console.log('output quickquote: ', response);
            this.quickQuoteData = response;
            this.carrier1 = this.quickQuoteData[0];
            this.carrier1['head']['quote_amount'] = formatAmount(this.carrier1['head']['quote_amount']);
            console.log('carrier1: ', this.carrier1);

            if ((this.carrier1.hasError && this.carrier1.hasError == true) ||
              (this.carrier1['head']['quote_amount'] && this.carrier1['head']['quote_amount'].includes('$0'))) {
              this.loader = false;
              this.showPaclife = 0;
              // this.openModal();
              // alert("Our Servers are not taking too long to respond please come back later.");
            }
            else {
              this.showPaclife = 1;
            }
            this.carrier2 = this.quickQuoteData[1];
            // this.carrier3 = this.quickQuoteData[2];
            this.carrier2['head']['quote_amount'] = formatAmount(this.carrier2['head']['quote_amount']);

            console.log('carrier2: ', this.carrier2);
            if (this.carrier2.sbliQuoteFlag && !this.carrier2.hasError) {
              this.showSBLI = 1;
              this.termLengthDisplay = this.carrier2.selectedDetails.term;
            } else {
              this.showSBLI = 0;
              this.termLengthDisplay = this.carrier1.selectedDetails.term;
            }
            // console.log("carrier3: ", this.carrier3);
            this.loader = false;
            debugger;
            this.selectedCardData = this.carrier1;
            this.coveragetodisaply = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
            this.selectedCard = 0;
            //document.getElementById("car1").click();
            //this.selectcarrier('0');
            if (document.getElementById('car1') !== null) {
              document.getElementById('car1').style.border = 'none';
              document.getElementById('car1').style.borderRadius = 'none';
            }
            if (document.getElementById('cari1') !== null) document.getElementById('cari1').style.display = 'none';
            if (document.getElementById('car2') !== null) {
              document.getElementById('car2').style.border = 'none';
              document.getElementById('car2').style.borderRadius = 'none';
            }
            if (document.getElementById('cari2') !== null) document.getElementById('cari2').style.display = 'none';
          })
          .catch(error => {
            if (error) console.log(error);
            else console.log('select-life-carrier\\select-life-carrier.component.ts:catch(error):getQuickQuote2;Unknown error');
          });
      }
    ).catch(error => console.log('error', error));

  }

  /**
     * @deprecated There is no dropdown for paclife
     * @param even
     */
  onTermChange(even) {

    this.loader = true;
    this.termLength = even;//.target.value
    localStorage.setItem('bubble-termLength', this.termLength);
    this.getQuote();
  }














  selectcarrier(eve) {
    if (eve == '1') {
      debugger;
      this.termLengthDisplay = this.termLengthCopy;
      this.selectedCardData = this.carrier1;
      this.selectedCard = 1;
      this.selectedCarrier = 1;
      const { carrierId } = this.carrier1;
      this.carrierSelected = carrierId;
      this.coveragetodisaply = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
      ////////////////Deal with term and coverageAmount for Life kind insuranceType
      // This block is to be updated in select-life-carrier and select-carriers
      if (this.insurancetype === 'homeLife' ||
        this.insurancetype === 'life') {
        if (this.selectedCardData.carrierId === 'paclife') {
          this.selectedCardData.selectedDetails.term = '65 Years';
          this.coverageAmountText = 'Min: $250,000 - Max: $10M';
          if (this.selectedCardData.selectedDetails.text.indexOf('*(1)') !== -1) {
            this.selectedCardData.selectedDetails.text = this.selectedCardData.selectedDetails.text.replace('*(1)', '');
            this.disclaimerScript1 = '1 ';
          }
        } else {
          this.coverageAmountText = this.coveragetodisaply;
          this.selectedCardData.selectedDetails.term = '' + this.selectedCardData.selectedDetails.term;
          if (this.selectedCardData.selectedDetails.term &&
            !this.selectedCardData.selectedDetails.term.endsWith('Years')) {
            this.selectedCardData.selectedDetails.term = `${this.selectedCardData.selectedDetails.term} Years`;
          }
        }
      }
      ////////////////Deal with term and coverageAmount for Life kind insuranceType Ended
    }
    else if (eve == '2') {
      debugger;
      this.selectedCardData = this.carrier2;
      this.termLengthDisplay = this.selectedCardData.selectedDetails.term;
      this.termLengthDisplay = this.termLengthDisplay.replace('Years', '').replace('years', '').replace(' ', '');
      this.selectedCard = 1;
      this.selectedCarrier = 2;
      const { carrierId } = this.carrier2;
      this.carrierSelected = carrierId;
      this.selectedCardData.selectedDetails.term = '' + this.selectedCardData.selectedDetails.term;
      if (this.selectedCardData.selectedDetails.term &&
        !this.selectedCardData.selectedDetails.term.endsWith('Years')) {
        this.selectedCardData.selectedDetails.term = `${this.selectedCardData.selectedDetails.term} Years`;
      }
      this.coveragetodisaply = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
      this.coverageAmountText = this.coveragetodisaply;
    }
    else if (eve == '3') {
      this.selectedCardData = this.carrier3;
      this.selectedCard = 1;
      console.log('carrier selected', 3);
      //  this.carrierSelected = 'sbli';
      // document.getElementById("rightcardunselected").style.display = "none";
      // document.getElementById("rightcardselected").style.display = "block";
      // document.getElementById("car1").style.border = "none";
      // document.getElementById("car1").style.borderRadius = "none";
      // document.getElementById("cari1").style.display = "none";
      // document.getElementById("car2").style.border = "none";
      // document.getElementById("car2").style.borderRadius = "none";
      // document.getElementById("cari2").style.display = "none";
      // document.getElementById("car3").style.border = "2px solid #ff7007";
      // document.getElementById("car3").style.borderRadius = "10px";
      // document.getElementById("cari3").style.display = "block";
    } else {
      this.selectedCardData = '';
      this.selectedCard = 0;
      this.selectedCarrier = 0;
      // document.getElementById("rightcardunselected").style.display = "none";
      // document.getElementById("rightcardselected").style.display = "block";
      document.getElementById('car1').style.border = 'none';
      document.getElementById('car1').style.borderRadius = 'none';
      document.getElementById('cari1').style.display = 'none';
      if (document.getElementById('car2')) {
        document.getElementById('car2').style.border = 'none';
        document.getElementById('car2').style.borderRadius = 'none';
        document.getElementById('cari2').style.display = 'none';
      }
      if (document.getElementById('car3')) {
        document.getElementById('car3').style.border = 'none';
        document.getElementById('car3').style.borderRadius = 'none';
        document.getElementById('cari3').style.display = 'none';
      }
    }


    const amountwithoutdoller = this.selectedCardData.head.quote_amount.replace('$', '');
    const amountwithdollar = '$' + amountwithoutdoller;
    const hubSpotData = {
      customer_id: localStorage.getItem('customer_id'),
      client_id: localStorage.getItem('clientID'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Quote',
      life_insurance_carrier: this.selectedCardData.head.carrier_name,
      life_quote_amount: amountwithdollar,
      life_latest_premium_amount: amountwithdollar
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    console.log('this.selectedCardData: ', this.selectedCardData);
  }


  bluronhow() {
    document.getElementById('we_are_sorry').style.display = 'block';
    //document.getElementById('headmakeblurs').style.filter = "blur(10px)";
    document.getElementById('makeblurs').style.filter = 'blur(10px)';
    document.getElementById('makeblurs').style.overflowY = 'hidden';
    document.getElementById('makeblurs').style.position = 'fixed';
  }

  bluroffhow() {
    document.getElementById('we_are_sorry').style.display = 'none';
    //document.getElementById('headmakeblurs').style.filter = "none";
    document.getElementById('makeblurs').style.filter = 'none';
    document.getElementById('makeblurs').style.overflowY = 'auto';
    document.getElementById('makeblurs').style.position = 'unset';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }


  modaltwoopen() {
    //debugger
    //document.getElementById('headmakeblurs').style.filter = "blur(10px)";
    this.showModal = true;
    document.getElementById('makeblurs').style.filter = 'blur(10px)';
    // document.getElementById('makeblurs').style.overflowY = 'hidden';
    document.getElementById('makeblurs').style.position = 'fixed';
    document.getElementById('selected_plan_details').style.display = 'block';
    document.getElementById('mod_body').style.height = '85vh';
    document.getElementById('mod_body').style.overflowY = 'auto';
    document.getElementById('mod_body').style.position = 'unset';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }


  modaltwoclose() {
    //document.getElementById('headmakeblurs').style.filter = "none";
    document.getElementById('makeblurs').style.filter = 'none';
    // document.getElementById('makeblurs').style.overflowY = 'auto';
    document.getElementById('makeblurs').style.position = 'unset';
    document.getElementById('selected_plan_details').style.display = 'none';
    document.getElementById('mod_body').style.overflowY = 'hidden';
    document.getElementById('mod_body').style.position = 'fixed';
    //location.href = "SelectHomeCarrier/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress + "/1" ;
  }

  previousscreen() {
    debugger;
    this.$gaService.gtag('event', this.insurancetype, {
      'event_category': 'BackButton', 'event_label': 'Back'
    });
    if (this.backButton == 'disable') {
      location.href = 'PrefillResumeFlowLife';
    }
    else {
      location.href = `QuoteFlowV2Life/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/previous/1`;
    }

    //this.router.navigate(['/QuoteFlowV2', this.uniqueId, this.clientId,this.displayAddress,'life','previous',1]);
  }

  async selectPlan() {

    if (this.selectedCardData.carrierId == 'sbli') {
      if (Number(this.termLength) >= 20) {
        this.termLength = '20';
      }
      else if (Number(this.termLength) >= 15 && Number(this.termLength) < 20) {
        this.termLength = '15';
      }
      else {
        this.termLength = '10';
      }
      localStorage.setItem('bubble-termLength', this.termLength);
      localStorage.setItem('bubble-termLengthCopy', this.termLength);
    }
    this.$gaService.event('SelectPlan', 'event', 'LifePlanSelect');
    this.$gaService.gtag('event', this.insurancetype, {
      'event_category': 'ButtonClicked', 'event_label': 'LifePlanSelect'
    });
    this.loader = true;
    const quoteId = this.pc.getQuoteId(this.clientId);
    console.log('quoteId : ', quoteId);
    localStorage.setItem('quoteId', quoteId);
    const details = JSON.stringify(this.selectedCardData);
    localStorage.setItem('selectedCarrier', details);
    localStorage.setItem('selectedLifeCarrier', details);
    /*
const selectedLifeCarrier = {
  "quoteId": "nsqs9n-h9sdiv",
  "carrierId": "paclife",
  "head": {
    "carrier_name": "Next Term Life",
    "quote_amount": "$66.27",
    "carrier_icon": "https://public-bubble.s3-us-west-2.amazonaws.com/images/images/paclife-logo.png"
  },
  "body": {
    "points": [{ "highlight": "One policy that keeps you covered to age 65 (typically when your mortgage is paid off and the kids are out of the house.)" },
    { "highlight": "Buy what you need today and increase or decrease your coverage in the future with a simple process and no additional underwriting.", "tooltip": "Locking in your health rating is beneficial because the younger and healthier you are, typically the lower the premium. When you purchase Next Term Life, your health rating is locked in. For every qualifying life event thereafter, you can increase coverage without additional medical underwriting and the premium for the increased coverage will be calculated at the original locked-in health rating and age at time of increase (up to attained age of 55). Qualifying life events require proof of life event & a signed change form, authorization, and terminal illness attestation." },
    { "highlight": "Innovative health lock feature allows you to add coverage in the future based on your original health rating.", "tooltip": "Locking in your health rating is beneficial because the younger and healthier you are, typically the lower the premium. When you purchase Next Term Life, your health rating is locked in. For every qualifying life event thereafter, you can increase coverage without additional medical underwriting and the premium for the increased coverage will be calculated at the original locked-in health rating and age at time of increase (up to attained age of 55). Qualifying life events require proof of life event & a signed change form, authorization, and terminal illness attestation." },
    { "highlight": "Online application and phone interview." }
    ]
  }
  ,
  "selectedDetails": {
    "term": "20", "coverage": "1200000",
    "title": "Key Benefits",
    "text": "With Next Term Life, protect your loved ones until you're age 65. And simply adjust your coverage as life changes along the way.",
    "key_benefits": [{ "title": "Scalable", "text": "Start with the coverage you need now and increase or decrease it to keep up with life changes." },
    { "title": "Simple", "text": "Take advantage of our “one and done” underwriting so you can increase or decrease coverage with a simple online process." },
    { "title": "Smart", "text": "Automatically lock in your health rating at purchase to help make increasing your coverage in the future easy and potentially more affordable." }],
    "footer_text": [{ "text": "Next Term Life is a term life insurance product–policy form series ICC18 P18IFT and ICC18 S18IFT or P18IFT and S18IFT. Policy form number may vary by state of issue." },
    {
      "text": "This rate is based on our Next Term Life Insurance policy with an assumed risk class of Super Preferred Non-Tobacco. This rate is also based on your selected criteria of Male, 18 years old, in CA, and the following assumptions. It is not a guarantee to offer or insure and may change based on the information gathered during the underwriting process."
    }]
  }, "hasError": false
};
*/
    const fullName = `${localStorage.getItem('bubble-fname')} ${localStorage.getItem('bubble-lname')}`;

    ///// Deal with selected Quote persistence
    let pacificLifeQuoteId = this.pc.getQuoteId('');// pass emptystring to get -wer324 kind string
    pacificLifeQuoteId = pacificLifeQuoteId.replace('-', '');
    const clientIdLocal = localStorage.getItem('clientID');
    const carrierLocal = this.selectedCardData['head']['carrier_name'];
    const quoteAmountLocal = this.selectedCardData['head']['quote_amount'];
    localStorage.setItem('bubble-QouteAmount', quoteAmountLocal);
    const inputJson4Db = {
      id: pacificLifeQuoteId,
      clientId: clientIdLocal,
      uid: pacificLifeQuoteId,
      carrier: 'Next',
      carrier_logo: this.selectedCardData['head']['carrier_icon'],
      policyName: 'Life Insurance',
      policyType: 'Life',
      policyAmount: quoteAmountLocal,
      policyNumber: '',
      coverageAmount: this.selectedCardData['selectedDetails']['coverage'],
      term: this.selectedCardData['selectedDetails']['term'],
      'policyDetails_PolicyName': carrierLocal,
      'policyDetails_HomeownerName': fullName,
      'policyDetails_ZIPCode': localStorage.getItem('bubble-zip'),
      'policyDetails_stateCode': localStorage.getItem('bubble-state'),
      'dataCategory': 'LifeQuote',
      'eventStr': 'Selected LifeQuote',
      Expires: '',
      selectedPlanDate: new Date().toISOString(),
    };
    /*
    const inputJson4Db = {
      id: 'dt6rgz',
      client_id: '7re83p',
      carrier: "Next",
      carrier_logo: "https://public-bubble.s3-us-west-2.amazonaws.com/images/images/paclife-logo.png",
      policyName: "Life Insurance",
      policyType: "Life",
      policyAmount: "$78.90",
      policyNumber: "",
      coverageAmount: "$1,200,000",
      term: "20 year",
      "policyDetails.PolicyName": "Term Life Next",
      "policyDetails.HomeownerName": "Test 1",
      "policyDetails.ZIPCode": "94301",
      "policyDetails.stateCode": "CA",
      "dataCategory": "LifeQuote",
      "eventStr": "Selected LifeQuote",
      Expires: "",
      selectedPlanDate: new Date().toISOString(),
      clientID: '7re83p'
    };
    */
    console.log('Lead:saveAppData:inputJson4Db:', inputJson4Db);
    debugger;
    pc.BackendService.saveAppData(inputJson4Db).then(saveResponse => {
      console.log('Lead:createIdMapping:saveResponse: ', saveResponse);
      /////// id mapping
      const idMappingJson = {
        client_id: clientIdLocal,
        customer_id: localStorage.getItem('customer_id'),
        email_id: localStorage.getItem('bubble-email'),
        paclife_quote_id: pacificLifeQuoteId,
        status: 'LifeQuote',
        dob: localStorage.getItem('bubble-dob'),
      };
      localStorage.setItem('paclife_quote_id', pacificLifeQuoteId);
      // const Helper = require('bit-uibl-data-driver');
      // Helper.Lead.createIdMapping(idMappingJson).then((data) => {
      //   if (data) { console.log("Lead:createIdMapping Successful:data: ", data) }
      // });

      this.pc.createIdMapping(idMappingJson);
      const amountwithoutdoller = quoteAmountLocal.replace('$', '');
      const amountwithdollar = '$' + amountwithoutdoller;
      /////// id mapping ends
      const hubSpotData = {
        customer_id: localStorage.getItem('customer_id'),
        email: localStorage.getItem('bubble-email'),
        life_insurance_carrier: carrierLocal,
        life_quote_amount: amountwithdollar,
        life_latest_premium_amount: amountwithdollar,
        application_stage: 'Quote'
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);//hubSpotSync(hubSpotData);
    }).catch(error => {
      console.log('Lead:createIdMapping:error: ', error);
    });
    ///// Deal with selected Quote persistence ends


    const updateJson = {
      otherAppflowScreen: 'selected-carriers',
      resumeUrl: `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.selectedCardData.head.carrier_name}`
    };
    this.checkforResumeInit(updateJson, 'update').then(
      data => {
        location.href = `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.selectedCardData.head.carrier_name}`;
      }
    ).catch(error => console.log(error));


    this.$gaService.event('Select Plan', 'event', 'LeadSelect');
  }

  openModal() {
    document.getElementById('makeblurs').style.filter = 'blur(10px)';
    document.getElementById('makeblurs').style.overflowY = 'hidden';
    //document.getElementById('makeblurs').style.position = 'fixed';
    document.getElementById('errorModal').style.display = 'block';

    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //     // keyboard: false,
    //     // backdrop: 'static'
    //   });

    // let data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   message: "Our Servers are taking too long to respond please come back later.",
    //   image: 'assets/images/images/education2.png'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   //redirect url
    //   //window.location.href = environment.carrierErrorRedirectURL;
    //   window.location.href = this.pc.envCred.url;
    //   console.log("Modal Close Output: ", result);
    // }, (reason) => {
    //   console.log("Modal Close Reason: ", reason);
    // });
  }

  closeModal() {
    document.getElementById('makeblurs').style.filter = 'none';
    document.getElementById('makeblurs').style.overflowY = 'auto';
    //document.getElementById('makeblurs').style.position = 'unset';
    document.getElementById('errorModal').style.display = 'none';
    //window.location.href = environment.carrierErrorRedirectURL;
    window.location.href = this.pc.envCred.url;
  }

  showToast() {
    // alert("Hi");
    this.toastService.show('For Pacific Life Next product Term is fixed based on Age');
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }
  openRiskClass() {
    this.pageToOpen = 'riskPage';
    this.showAssumptions = true;
  }

  openAssumptions() {
    this.pageToOpen = 'assumptions';
    this.showAssumptions = true;
  }

  closeAssumption() {
    debugger;
    this.showAssumptions = false;
    this.pageToOpen = '';
  }

}
