import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ValidationService } from './../../../shared/validation.service';
const pc = require('bit-uibl-data-driver');
import { getHubSpotInterestType, getHubSpotAppStage } from 'src/app/shared/UtilityFunctions.js';
import { TrackerService } from 'src/app/shared/tracker.service';

@Component({
  selector: 'app-sw4pt3',
  templateUrl: './sw4pt3.component.html',
  styleUrls: ['./sw4pt3.component.css']
})
export class Sw4pt3Component implements OnInit {

  @Output() contactInformation = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  FirstName: any;
  LastName: any;
  EmailID: any;
  PhoneNumber: any;
  homeAddress: any;
  city: any;
  state: any;
  zip: any;
  nameerror: boolean;
  lnameerror: boolean;
  phoneerror: boolean;
  existingData: any;
  dob: any;
  curDate: Date;
  maxDate: Date;
  minDate: Date;
  message: any;
  doberror: any;
  loader: boolean;
  url: any;
  totalPages: number = 22 * 2; //change if number of pages changes
  currentPage = 0;
  SSN: any;
  is_valid: boolean;

  constructor(private $gaService: GoogleAnalyticsService, private scoreApi: ScorecardApiService, private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    public validationService: ValidationService, private tracker: TrackerService) { }

  ngOnInit(): void {
    debugger;
    this.message = '';
    this.SSN = '';
    this.url = window.location.href;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    this.loader = true;
    let fullAddress = this.uniqueId.split('-');
    fullAddress.shift();
    fullAddress = fullAddress.join(' ');
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    this.state = commonInput.state;
    this.city = commonInput.city;
    this.zip = commonInput.zip;
    this.homeAddress = commonInput.address;// + " " + data.street_type;
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.stillwater;
      if (this.existingData == undefined || this.existingData == null || this.existingData == '') {
        this.existingData = {};
        this.existingData['propertyDetails'] = JSON.parse(localStorage.getItem('propertyDetails'));
      }
      console.log('data from getAppData payload : ', dataFrom);
      console.log('data from getAppData sw : ', this.existingData);
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });
    // this.pc.parseAddressLocalAndPatch(fullAddress, this.clientId).then(
    //   data => {
    //     console.log("splitAddressNew : ", data);
    //     this.state = data.state_code;
    //     this.city = data.city_name;
    //     this.zip = data.zip;
    //     this.homeAddress = data.door_number + " " + data.street;// + " " + data.street_type;


    //   }
    // ).catch(error => console.log("error", error));
    console.log('fullAddressNew : ', fullAddress);

    this.FirstName = localStorage.getItem('bubble-fname');
    if (this.FirstName && (this.FirstName == 'undefined' || this.FirstName == 'null' ||
      this.FirstName == '' || this.FirstName == undefined || this.FirstName == null || this.FirstName == 'firstname')) {
      this.FirstName = '';
      this.nameerror = true;
    }
    this.LastName = localStorage.getItem('bubble-lname');
    if (this.LastName && (this.LastName == 'undefined' || this.LastName == 'null' ||
      this.LastName == '' || this.LastName == undefined || this.LastName == null || this.LastName == 'lastname')) {
      this.LastName = '';
      this.lnameerror = true;
    }

    this.EmailID = localStorage.getItem('bubble-email');
    if (this.EmailID.startsWith('ma_')) {
      this.EmailID = '';
    }
    this.PhoneNumber = localStorage.getItem('bubble-phone');
    this.phonelength();
    let dobLocalDate;
    let dobLocal = localStorage.getItem('bubble-dob');
    dobLocalDate = new Date(dobLocal);
    if (!dobLocal || dobLocal === 'undefined') {
      const commonInputStr = localStorage.getItem('commonInput');
      let commonInput;
      if (commonInputStr) commonInput = JSON.parse(commonInputStr);
      if (commonInput && commonInput['dob']) {
        dobLocal = commonInput['dob'];
        dobLocalDate = new Date(dobLocal);
      }
    }
    if (!dobLocal && (this.dob == 'undefined' || this.dob == 'null' ||
      this.dob == '' || this.dob == undefined || this.dob == null)) {
      this.curDate = new Date();
      const cyear = Number(this.curDate.getFullYear()) - 18;
      let cmonth = '' + (this.curDate.getMonth() + 1);
      let cday = '' + this.curDate.getDate();
      if (cmonth.length < 2)
        cmonth = '0' + cmonth;
      if (cday.length < 2)
        cday = '0' + cday;
      dobLocalDate = new Date(cmonth + '/' + cday + '/' + cyear);
    }
    else {
      this.curDate = new Date(dobLocalDate);
      const cyear = Number(this.curDate.getFullYear());
      let cmonth = '' + (this.curDate.getMonth() + 1);
      let cday = '' + this.curDate.getDate();
      if (cmonth.length < 2)
        cmonth = '0' + cmonth;
      if (cday.length < 2)
        cday = '0' + cday;
      dobLocalDate = new Date(cmonth + '/' + cday + '/' + cyear);
    }
    this.dob = dobLocalDate;

    this.checkDate(this.dob);
    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear());
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.maxDate = new Date(month + '/' + day + '/' + year);

    const yearm = Number(this.curDate.getFullYear()) - 55;
    const monthm = '' + (this.curDate.getMonth() + 1);
    const daym = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.minDate = new Date(monthm + '/' + daym + '/' + yearm);


  }

  checknamelength() {
    if (this.FirstName.length < 4) {
      this.nameerror = true;
    }
    else if (this.FirstName == '') {
      this.nameerror = false;
    }
    else {
      this.nameerror = false;
    }
  }

  checklnamelength() {
    if (this.LastName.length < 1) {
      this.lnameerror = true;
    }
    else if (this.LastName == '') {
      this.lnameerror = false;
    }
    else {
      this.lnameerror = false;
    }
  }


  ssnLength: any;
  SSNerror: boolean;
  ssnlength() {
    debugger;
    const j = this.SSN.replace(/-/g, '');
    const A = j.substring(0, 3);
    const B = j.substring(3, 5);
    const C = j.substring(5, 9);
    if (B == '') {
      this.SSN = A;
    }
    else if (B != '' && C == '') {
      this.SSN = A + '-' + B;
    }
    else if (B != '' && C != '') {
      this.SSN = A + '-' + B + '-' + C;
    }
    else {
      this.SSN = j;
    }

    this.ssnLength = this.SSN.toString().length;
    if (this.ssnLength > 10) {
      this.SSNerror = false;
    } else if (this.ssnLength == 0) {
      this.SSNerror = false;
    }
    else {
      this.SSNerror = true;
    }

  }
  phonelength() {
    debugger;
    // if (this.PhoneNumber.length == 3) {
    //   this.PhoneNumber = this.PhoneNumber + "-";
    //   this.phoneerror = true;
    // }
    // else if (this.PhoneNumber.length == 7) {
    //   this.PhoneNumber = this.PhoneNumber + "-";
    //   this.phoneerror = true;
    // }
    // else if (this.PhoneNumber.length < 12) {
    //   this.phoneerror = true;
    // }
    // else {
    //   this.phoneerror = false;
    // }
    // debugger
    // let phonesplit = this.PhoneNumber.split('');
    // if (phonesplit[3] == '-' && phonesplit[7] == '-') {
    //   this.phoneerror = false;
    //   phonesplit[3] = '-';
    //   phonesplit[7] = '-';
    //   this.PhoneNumber = phonesplit.join('');
    // }
    // else {
    //   this.phoneerror = true;
    // }


    let count = 0;
    this.PhoneNumber = this.PhoneNumber.replace(/^0+/, '');
    const phonestring = this.PhoneNumber.toString().slice(0, this.PhoneNumber.length);
    this.PhoneNumber = phonestring.replace(/(\d{3})-?/g, function (m, a) {
      return ++count <= 2 ? a + '-' : m;
    });
    this.PhoneNumber = this.PhoneNumber.slice(0, 12);
    if (this.PhoneNumber.length < 12) {
      this.phoneerror = true;
    }
    else {
      this.phoneerror = false;
    }
  }



  inputdatechange() {
    debugger
    var K = this.dob.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.message = "Invalid Date"
      }
      else {
        this.message = "";
      }
      this.dob = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.message = 'Invalid Date';
        }
        else if (A <= 12) {
          this.dob = A + '/' + B + '/';
          this.message = '';
        }
        else {
          this.message = 'Invalid Date';
        }

      }
      else {
        this.dob = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.dob = A + '/' + B + '/' + C;
    }
    else {
      this.dob = this.dob;
    }
    if (this.dob.length >= 10 && this.message != "Invalid Date") {
      this.checkDate(this.dob);
    }
    else {
      this.is_valid = false;
    }

  }


  checkDate(even) {
    debugger;
    if (even == null) {
      // this.curDate = new Date();
      // const year = Number(this.curDate.getFullYear());
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.dob = new Date(month + '/' + day + '/' + year);
      this.dob = this.pc.momentdob(this.curDate);
    }
    else {
      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();

      const ageYear = Number(curYear) - Number(year);

      if (ageYear <= 0 || ageYear > 155) {
        this.message = 'Invalid Age';
        this.disable = false;
      }
      else {
        this.message = '';
        this.disable = true;
      }

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
        this.dob = this.pc.momentdob(even);

    }
    if (this.dob == 'NaN/NaN/NaN') {
      this.message = 'Invalid Date';
      this.is_valid = false;
      this.dob = '';
    }
    else {
      const newDate = new Date(even);
      console.log('DOB entered: ', newDate);
      const checkAge = this.validationService.validate_dob('common_age', newDate);
      console.log('DOB entered: checkAge', checkAge);
      this.message = checkAge.message;
      this.is_valid = checkAge.is_valid;
    }
  }


  async next() {


    let customerIdLocal;
    const x = ['email', 'website', 'company', 'phone', 'city', 'zip', 'state', 'address', 'firstname', 'lastname'];

    // Create customer_id only if the email id provided
    customerIdLocal = localStorage.getItem('customer_id');
    if (!customerIdLocal) {
      customerIdLocal = this.pc.getQuoteId('');// pass emptystring to get -wer324 kind string
      customerIdLocal = customerIdLocal.replace('-', '');
      localStorage.setItem('customer_id', customerIdLocal);
    }
    //  let interestType;
    //  if (this.insurancetype === "life") interestType = "LifeQuote";
    //  else if (this.insurancetype === "home") interestType = "HomeQuote";
    //  else interestType = "HomeLifeQuote";


    const interestType = getHubSpotInterestType(this.insurancetype);

    let tempDob, tempEmail;
    if (this.EmailID !== localStorage.getItem('bubble-email')) {
      tempEmail = this.EmailID;
      //TODO Create another contact in Hubspot with these details
      // copy data corresponding from old email to new email
      console.log('calling replicateHubspotData');
      //debugger;
      await this.pc.replicateHubspotData(localStorage.getItem('bubble-email'), this.EmailID);
    } else tempEmail = localStorage.getItem('bubble-email');
    if (this.dob !== localStorage.getItem('bubble-dob')) {
      tempDob = this.dob;
    } else tempDob = localStorage.getItem('bubble-dob');
    /////// id mapping
    const idMappingJson = {
      client_id: localStorage.getItem('clientID'),
      customer_id: customerIdLocal,
      email_id: tempEmail,
      dob: tempDob,
      firstname: this.FirstName,
      lastname: this.LastName,
      status: 'Application Email Captured',
      interest_type: interestType,
    };

    this.pc.createIdMapping(idMappingJson);
    // const Helper = require('bit-uibl-data-driver');
    // Helper.Lead.createIdMapping(idMappingJson)
    //   .then((data) => {
    //     if (data) { console.log("Lead:createIdMapping:data: ", data) }
    //   });
    console.log('Lead:createIdMapping:idMappingJson:', idMappingJson);
    /////// id mapping ends
    // let application_stage = getHubSpotAppStage(this.url);
    const hubSpotData = {
      interest_type: interestType, customer_id: customerIdLocal,
      email: tempEmail,
      firstname: this.FirstName,
      lastname: this.LastName,
      dob_home: tempDob,
      application_stage: 'Application',
      address: this.homeAddress,
      phone: this.PhoneNumber
    };
    x.forEach(key => {
      if (this[key])
        hubSpotData[key] = this[key];
    });

    debugger;
    console.log('this.pc.hubSpotSync:hubspotData:', hubSpotData);
    //make a request to server
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);




    this.loader = true;
    localStorage.setItem('bubble-fname', this.FirstName);
    localStorage.setItem('bubble-lname', this.LastName);
    localStorage.setItem('bubble-email', this.EmailID);
    localStorage.setItem('bubble-phone', this.PhoneNumber);
    localStorage.setItem('bubble-dob', this.dob);
    localStorage.setItem('bubble-address', this.homeAddress);
    localStorage.setItem('bubble-city', this.city);
    localStorage.setItem('bubble-state', this.state);
    localStorage.setItem('bubble-zip', this.zip);

    //debugger

    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    commonInput['firstName'] = this.FirstName;
    commonInput['lastName'] = this.LastName;
    commonInput['dob'] = this.dob;
    if (this.SSN != undefined) {
      commonInput['ssn'] = this.SSN.replaceAll('-', '');
      commonInput['credit_consent'] = false;
    }

    localStorage.setItem('commonInput', JSON.stringify(commonInput));

    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };

    json.stillwater['comfirmDetailsFirstName'] = this.FirstName;
    json.stillwater['comfirmDetailsLastName'] = this.LastName;
    json.stillwater['comfirmDetailsEmailID'] = this.EmailID;
    json.stillwater['comfirmDetailsPhoneNumber'] = this.PhoneNumber;
    json.stillwater['sw_FirstName'] = this.FirstName;
    json.stillwater['sw_LastName'] = this.LastName;
    json.stillwater['sw_EmailID'] = this.EmailID;
    json.stillwater['sw_dob'] = this.dob;
    json.stillwater['sw_PhoneNumber'] = this.PhoneNumber;
    json.stillwater['sw_address'] = this.homeAddress;
    json.stillwater['sw_city'] = this.city;
    json.stillwater['sw_state'] = this.state;
    json.stillwater['sw_zip'] = this.zip;
    json.stillwater['sw_SSN'] = this.zip;
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(data => {
      console.log(data);
      this.loader = true;
      const etty = {
        'next': 1,
        'previous': 0,
      };
      this.contactInformation.emit(etty);
    }).catch(error => {
      console.log(error);
    });


    this.$gaService.event('Next', 'event', 'HomeAppDetailsReview');

  }

  previous() {

    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.contactInformation.emit(etty);

  }

  onYes() {
    location.href = this.pc.envCred.url;//"https://stage-www.getmybubble.io";
  }


}
