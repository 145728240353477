import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-profile-home-page',
  templateUrl: './profile-home-page.component.html',
  styleUrls: ['./profile-home-page.component.css']
})
export class ProfileHomePageComponent implements OnInit {

  constructor( private route: ActivatedRoute) { }

  ngOnInit(): void {
    // this.route.params.subscribe(params => {
    //   this.clientID = params['clientID'];
    //   new Promise(resolve => setTimeout(resolve, 100)).then(() => {
    //     this.service.getAppData(this.clientID).then(object => {
    //       let clientData = JSON.parse(object.Payload);
    //       clientData = clientData.body;
    //       this.emailId = clientData.comfirmDetailsEmailID;
    //       let o = this.service.getAllIdMappings(this.emailId);
    //       o.then(response1 => {
    //         let tempArray = response1.data.output_data;
    //         this.idMappings = tempArray;
    //         new Promise(resolve => setTimeout(resolve, 100)).then(() => {
    //           this.service.getPreviousQuotesV3(this.idMappings).then(object => {
    //             this.previousQuotes = object;
    //             this.groupByKey(object);
    //             console.log("this.previousQuotes: ", this.previousQuotes);
    //           });
    //         });
    //       }).catch(error => {
    //         console.log("getAllIdMapping:error: ", error);
    //       });
    //     });
    //   });
    // }
    // )
  }

}
