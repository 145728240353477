import { Component, OnInit,Output,EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sbli02301940',
  templateUrl: './sbli02301940.component.html',
  styleUrls: ['./sbli02301940.component.css']
})
export class Sbli02301940Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  next() {
    debugger
    let etty = {
      "next": 1,
      "previous": 0,
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
