import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-quote-flow-status-tracker',
  templateUrl: './quote-flow-status-tracker.component.html',
  styleUrls: ['./quote-flow-status-tracker.component.css']
})
export class QuoteFlowStatusTrackerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
}
