import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-sw4pt43',
  templateUrl: './sw4pt43.component.html',
  styleUrls: ['./sw4pt43.component.css']
})
export class Sw4pt43Component implements OnInit {

  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  options = '{types: [\'address\', \'place\'], componentRestrictions: { country: \'USA\'}}';

  @Output() nextquestion26 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  consumerReports: number;
  underWriting: number;
  agree: number;
  paymentMethod: any;
  billingAddressType: any;
  paymentOption: string;
  paymentOptionPlan: string;
  eftconfirm: number;
  depositAmount: any;
  instalmentAmount: any;
  policyStartDate: any;
  paymentAmount: string;
  firstName: string;
  lastName: string;
  cardNumber: string;
  cardType: string;
  expiryDate: string;
  cvv: string;
  billingAddress: string;
  routing: string;
  accountNumber: string;
  accountType: string;
  confirmAccountNumber: string;
  otherbillingAddress: string;
  paymentPlanDetails: any;
  existingAppData: any;
  billingAddressObject: { fullAddress: string; address: string; state: any; city: any; zip: any; };
  totalPremium: any;
  disableCreditCard: boolean;
  cardNumberError: boolean;
  cvvError: boolean;
  disableEFT: boolean;
  cardNumberLength: number;
  loader: boolean;
  payType: any;
  carderror: boolean;
  carderrormessage: any;
  maxlength: any;
  customAddress: string;
  constructor(private $gaService: GoogleAnalyticsService, private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    this.cvv = '123';
    this.maxlength = 19;
    // this.uniqueId = 'nkowu4-285-Bryant-St-Palo-Alto-CA-94301';
    // this.clientId = 'nkowu4';
    this.loader = true;
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingAppData = dataFrom.body.stillwater;
      console.log('data from getAppData sw : ', this.existingAppData);
      this.eftconfirm = 0;
      this.paymentOption = localStorage.getItem('paymentOption');
      this.paymentOptionPlan = localStorage.getItem('paymentOptionPlan');
      this.paymentPlanDetails = this.existingAppData.paymentDetails;
      this.payType = this.paymentPlanDetails['payType'];
      console.log('paymentPlanDetails : ', this.paymentPlanDetails);
      this.depositAmount = this.paymentPlanDetails.depositAmount;
      this.totalPremium = this.paymentPlanDetails.totalPremium;
      debugger;
      const ia = this.depositAmount.replace(/,/g, '');
      const tp = this.totalPremium.replace(/,/g, '');

      //this.instalmentAmount = Number(tp) - Number(ia);
      this.instalmentAmount = this.paymentPlanDetails.installmentAmount;

      this.policyStartDate = this.existingAppData.sw_PolicyStartDate;
      const format = 'MM/dd/yyyy';
      const myDate = new Date(this.policyStartDate);
      const locale = 'en-US';
      this.policyStartDate = formatDate(myDate, format, locale);
      this.paymentAmount = this.depositAmount;
      this.firstName = '';
      this.lastName = '';
      this.cardNumber = '';
      this.cardNumberError = true;
      this.cvvError = true;
      this.cardType = '';
      this.expiryDate = '';
      this.cvv = '';
      let address = this.uniqueId.split('-');
      address.shift();
      address = address.join(' ');
      this.billingAddress = address;
      this.otherbillingAddress = '';
      this.routing = '';
      this.accountNumber = '';
      this.confirmAccountNumber = '';
      this.accountType = '';
      this.disableCreditCard = false;
      this.disableEFT = false;
      debugger;
      if (this.paymentPlanDetails.payType == 'com.stillwater_EF12') {
        this.paymentMethod = 'eft';
        this.disableCreditCard = true;
      }
      if (this.paymentPlanDetails.payType == 'com.stillwater_CC70') {
        this.paymentMethod = 'debitCredit';
        this.disableEFT = true;
      }
      this.billingAddressType = 'default';
      this.customAddress = '';
      this.pc.parseAddressLocalAndPatch(this.billingAddress, this.clientId).then(
        data => {
          console.log('split lenderaddress : ', data);
          const entity = {
            'fullAddress': this.billingAddress,
            'address': data.door_number + ' ' + data.street,// + " " + data.street_type,
            'state': data.state_code,
            'city': data.city_name,
            'zip': data.zip
          };
          console.log('addres object : ', entity);
          this.billingAddressObject = entity;
          this.loader = false;
        }
      ).catch(error => console.log('error', error));
    }).catch(error => {
      console.log(error);
    });
  }

  confirmEFT(val) {
    this.eftconfirm = val;
  }

  selectPaymentMethod(paymentMethod) {
    if (this.disableCreditCard == true) {
      this.paymentMethod = 'eft';
    }
    else if (this.disableEFT == true) {
      this.paymentMethod = 'debitCredit';
    }
    else {
      this.paymentMethod = paymentMethod;
    }
    this.billingAddressType = 'default';
  }

  selectAddress(billingAddressType) {
    this.billingAddressType = billingAddressType;
    if (billingAddressType == 'default') {
      this.customAddress='';
    }
  }

  public handleAddressChange(address: Address) {
    this.loader = true;
    console.log('billingaddresshandles', address);
    console.log('formatted_billing_address ', address.formatted_address);
    let addressToFormat = address.formatted_address;
    addressToFormat = addressToFormat.replace(/,/g, '');
    console.log('addressToFormat ', addressToFormat);
    this.pc.parseAddressLocalAndPatch(addressToFormat, this.clientId).then(
      data => {
        console.log('split lenderaddress : ', data);
        const entity = {
          'fullAddress': address.formatted_address,
          'address': data.door_number + ' ' + data.street,// + " " + data.street_type,
          'state': data.state_code,
          'city': data.city_name,
          'zip': data.zip
        };
        console.log('addres object : ', entity);
        this.billingAddressObject = entity;
        this.customAddress = address.formatted_address;
        this.loader = false;
      }
    ).catch(error => console.log('error', error));
  }

  formatCardExpiry() {
    const todayDate = new Date();
    const currentYear = todayDate.getFullYear();
    let currentMonth = todayDate.getMonth() + 1;
    if (currentMonth.toString().length == 1) {
      const str = '0' + currentMonth;
      currentMonth = Number(str);
    }

    if (this.expiryDate.length == 4) {
      if (Number(this.expiryDate) < Number(currentYear)) {
        this.expiryDate = '';
      }
      if (Number(this.expiryDate) > (Number(currentYear) + 20)) {
        this.expiryDate = '';
      }
      else {
        this.expiryDate = this.expiryDate + '-';
      }
    }

    if (this.expiryDate.length == 7) {
      const splitDetails = this.expiryDate.split('-');
      if (Number(this.expiryDate) == Number(currentYear) && Number(splitDetails[1]) < Number(currentMonth)) {
        splitDetails[1] = currentMonth.toString();
        this.expiryDate = splitDetails.join('-');
      }
      if (Number(splitDetails[1]) > 12) {
        splitDetails[1] = currentMonth.toString();
        this.expiryDate = splitDetails.join('-');
      }
    }

  }

  selectCard() {
    //debugger
    if (this.cardType == 'AMEX') {
      this.cardNumberLength = 15;
    }
    else {
      this.cardNumberLength = 16;
    }
  }

  checkCardNumber() {
    debugger;
    // if (this.cardNumber.length < Number(this.cardNumberLength)) {
    //   this.cardNumberError = true;
    // }
    // else {
    //   this.cardNumberError = false;
    // }
    const x = this.cardNumber.toString();
    if (x.charAt(0) == '4') {
      this.maxlength = 19;
      this.cardType = 'VISA';
      if (this.cardNumber.toString().length === 4) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length === 9) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length === 14) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length < 19) {
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else {
        this.cardNumberError = false;
        this.carderrormessage = '';

      }
    }
    else if (x.charAt(0) == '5') {
      this.maxlength = 19;
      this.cardType = 'MASTR';
      if (this.cardNumber.toString().length === 4) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length === 9) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length === 14) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length < 19) {
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else {
        this.cardNumberError = false;
        this.carderrormessage = '';

      }
    }
    else if (x.charAt(0) == '6') {
      this.maxlength = 19;
      this.cardType = 'DISCV';
      if (this.cardNumber.toString().length === 4) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length === 9) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length === 14) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length < 19) {
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else {
        this.cardNumberError = false;
        this.carderrormessage = '';

      }
    }
    else if ((x.charAt(0) == '3') && (x.charAt(1) == '4' || x.charAt(1) == '7')) {
      this.maxlength = 17;
      this.cardType = 'AMEX';
      if (this.cardNumber.toString().length === 4) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length === 11) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else if (this.cardNumber.toString().length < 17) {
        this.cardNumberError = true;
        this.carderrormessage = 'Invalid Card Number';
      }
      else {
        this.cardNumberError = false;
        this.carderrormessage = '';

      }
    }
    else {
      this.cardNumberError = true;
      this.carderrormessage = 'Invalid Card Number';
      this.cardType = '';
      if (this.cardNumber.toString().length === 4) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
      }
      else if (this.cardNumber.toString().length === 9) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
      }
      else if (this.cardNumber.toString().length === 14) {
        this.cardNumber = this.cardNumber + ' ';
        this.cardNumberError = true;
      }
      else if (this.cardNumber.toString().length < 19) {
        this.cardNumberError = true;
      }
      else {
        this.cardNumberError = true;
      }
    }






    //   if (this.cardType=='VISA') {
    //     var x = this.cardNumber.toString();
    //     if(x.charAt(0)=='4'){
    //    if(this.cardNumber.toString().length === 4){
    //  this.cardNumber = this.cardNumber + " ";
    //    this.carderror = true;
    //    this.carderrormessage='Invalid Card Number';
    //   }
    //   else if(this.cardNumber.toString().length === 9){
    //    this.cardNumber = this.cardNumber + " ";
    //     this.carderror = true;
    //     this.carderrormessage='Invalid Card Number';
    //   }
    //   else if(this.cardNumber.toString().length === 14){
    //    this.cardNumber = this.cardNumber + " ";
    //     this.carderror = true;
    //     this.carderrormessage='Invalid Card Number';
    //   }
    //   else if(this.cardNumber.toString().length < 19){
    //     this.carderror = true;
    //     this.carderrormessage='Invalid Card Number';
    //   }
    //   else{
    //     this.carderror = false;
    //     this.carderrormessage='';
    //   }
    //     }
    //     else{
    //       this.carderror = true;
    //       this.carderrormessage='Invalid Card Number';
    //       if(this.cardNumber.toString().length === 4){
    //        this.cardNumber = this.cardNumber + " ";
    //          this.carderror = true;
    //         }
    //         else if(this.cardNumber.toString().length === 9){
    //          this.cardNumber = this.cardNumber + " ";
    //           this.carderror = true;
    //         }
    //         else if(this.cardNumber.toString().length === 14){
    //          this.cardNumber = this.cardNumber + " ";
    //           this.carderror = true;
    //         }
    //         else if(this.cardNumber.toString().length < 19){
    //           this.carderror = true;
    //         }
    //         else{
    //           this.carderror = true;
    //         }
    //     }
    //   }
    //   else if(this.cardType=='MasterCard'){

    //   }
    //   else if(this.cardType=='MasterCard'){

    //   }
    //   else if(this.cardType=='MasterCard'){

    //   }



  }

  checkCardCVV() {
    if (this.cvv.length < 3) {
      this.cvvError = true;
    }
    else {
      this.cvvError = false;
    }
  }

  next() {
    debugger;
    this.loader = true;
    this.cardNumber = this.cardNumber.replace(/ /g, '');
    const paymentDetails = {
      'payType': this.paymentPlanDetails['payType'],
      'paymentOption': this.paymentOption,
      'paymentOptionPlan': this.paymentOptionPlan,
      'depositAmount': this.depositAmount,
      'paymentAmount': this.paymentAmount,
      'totalPremium': this.totalPremium,
      'installmentAmount': this.instalmentAmount,
      'policyStartDate': this.policyStartDate,
      'paymentMethod': this.paymentMethod,
      'firstName': this.firstName,
      'lastName': this.lastName,
      'cardNumber': this.cardNumber,
      'cardType': this.cardType,
      'expiryDate': this.expiryDate,
      'cvv': this.cvv,
      'billingAddress': this.billingAddressObject,
      'routing': this.routing,
      'accountNumber': this.accountNumber,
      'accountType': this.accountType,
      'termsAndCondtions': '',
      'paymentDueDay': '10',
    };
    localStorage.setItem('paymentDetails', JSON.stringify(paymentDetails));

    const json = {
      'id': this.clientId,
      stillwater: this.existingAppData
    };
    json.stillwater['paymentDetails'] = paymentDetails;
    json.stillwater['sw_totalPremium'] = this.totalPremium;
    json['sw_totalPremium'] = this.totalPremium;
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(
      data => {
        this.loader = false;
        const etty = {
          'next': 1,
          'previous': 0,
        };
        this.nextquestion26.emit(etty);
      }).catch(error => {
        console.log(error);
      });

    this.$gaService.event('Purchase Policy', 'event', 'HomeAppAddPaymentDetails');
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion26.emit(etty);
  }







}
