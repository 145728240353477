import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-kopopup2',
  templateUrl: './kopopup2.component.html',
  styleUrls: ['./kopopup2.component.css']
})
export class KOPopup2Component implements OnInit {
  foundationTypeList: any;
  selectfoundationtype: any;
  foundationtype: any;
  @Output() rooftypechanged = new EventEmitter<any>();
  @Output() marooftypechanged = new EventEmitter<any>();
  @Output() closemodel = new EventEmitter<any>();
  @Output() maclosemodel = new EventEmitter<any>();
  constructor() { }

  ngOnInit(): void {
    this.foundationtype='';
    this.foundationTypeList = [
      {
        "text": "Basement",
        "value": "BS"
      },
      {
        "text": "Closed",
        "value": "Closed"
      },
      {
        "text": "Continuous Masonry (w/substructure)",
        "value": "CMasonry"
      },
      {
        "text": "Continuous Masonry (w/o substructure)",
        "value": "CMasonry"
      },
      {
        "text": "Columns",
        "value": "COLMN"
      },
      {
        "text": "Crawl Space",
        "value": "Crawl"
      },
      {
        "text": "Finished Basement",
        "value": "Finished"
      },
      {
        "text": "Hillside Foundation",
        "value": "HillFnd"
      },
      {
        "text": "Elevated Post/Pier & Beam (Stilts)",
        "value": "Piers"
      },
      {
        "text": "Piers",
        "value": "PiersOnly"
      },
      {
        "text": "Piles",
        "value": "PILES"
      },
      {
        "text": "Post and Pier",
        "value": "Post"
      },
      {
        "text": "Posts",
        "value": "PostsOnly"
      },
      {
        "text": "Parallel Shear Walls",
        "value": "PRLSW"
      },
      {
        "text": "Slab on Grade (Concrete Slab)",
        "value": "Slab"
      },
      {
        "text": "Solid Perimeter Walls",
        "value": "SLDPW"
      },
      {
        "text": "Subgrade Crawl Space",
        "value": "Subgrade"
      },
      {
        "text": "Unfinished Basement",
        "value": "Unfinished"
      },
      {
        "text": "Walkout Basement",
        "value": "Walkout"
      },
      {
        "text": "Elevated Post/Pier & Beam (Stilts)",
        "value": "Suspended"
      },
      {
        "text": "None",
        "value": "NONE"
      },
      {
        "text": "Open",
        "value": "Open"
      },
      {
        "text": "Other",
        "value": "Other"
      }
    ];
  }


  public save() {
    debugger
    let commonInput = JSON.parse(localStorage.getItem("commonInput"));
    commonInput['propertyDetails'].foundationType = this.foundationtype;
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    this.rooftypechanged.emit();
    this.marooftypechanged.emit();
  }
  public closepopup(){
    this.closemodel.emit();
    this.maclosemodel.emit();
  }
}
