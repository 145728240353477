import { Component, Input, OnInit, Output, EventEmitter, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-input-yesno',
  templateUrl: './input-yesno.component.html',
  styleUrls: ['./input-yesno.component.css']
})
export class InputYesNoComponent implements OnInit, AfterViewInit {

  @Output() onChange: EventEmitter<number | string> = new EventEmitter();
  @Input() public questionId: number | undefined;
  @ViewChild('yes') yes: ElementRef<HTMLElement>;
  @ViewChild('no') no: ElementRef<HTMLElement>;
  childForm!: FormGroup;
  isChecked = false;
  carrier: string;

  constructor(
    private rootFormGroup: FormGroupDirective,
  ) {
  }

  ngOnInit(): void {
    debugger
    this.childForm = this.rootFormGroup.form as FormGroup;
    const formComponent = this.childForm.get(['answer_value_' + this.questionId]);
    const formValue = formComponent.value;
    formComponent.setErrors(null);
    if (formValue === null) {
      this.isChecked = this.questionId == 1260 || this.questionId == 1020;
    }
    else if (formValue == '1') {
      this.isChecked = true;
    }
    else if (formValue == '0') {
      this.isChecked = false;
    }
    else {
      this.isChecked = formValue === '1';

    }
  }

  setRadioChecked() {
    debugger
    if (this.isChecked) {
      document.getElementById('yes_none_' + this.questionId).classList.add('clicked');
      document.getElementById('no_none_' + this.questionId).classList.remove('not_clicked');
    }
    else {
      document.getElementById('no_none_' + this.questionId).classList.add('not_clicked');
      document.getElementById('yes_none_' + this.questionId).classList.remove('clicked');


    }
    // document.getElementById('yes_none_' + this.questionId).classList.toggle('clicked');
    // document.getElementById('no_none_' + this.questionId).classList.toggle('not_clicked');
  }

  ngAfterViewInit() {
    debugger
    // let cms = `answer_value_${this.childForm.value.question_id}`;
    // this.carrier = localStorage.getItem('carrierDQ');
    // if (this.childForm.value.applicable_parent_answer == null) {
    //   this.selectvalue()
    // }
    // else if (this.childForm.value.applicable_parent_answer != null && this.childForm.value[cms] != null) {
    //   this.selectvalue()
    // }
    this.selectvalue()
  }

  changeValue(event: any) {
    debugger
    this.isChecked = event.target.value == '1' ? true : false;;
    this.childForm.controls['selected_answer'].setValue(this.isChecked == true ? 'Yes' : 'No');
    this.setRadioChecked();
    this.onChange.emit(event.target.value);
    if (this.childForm.value.applicable_parent_answer == null) {
      for (let sub in this.childForm.value) {
        if (sub.startsWith('question_') && sub != 'question_id' && sub != 'question_text') {
          if (event.target.value != this.childForm.value[sub].applicable_parent_answer) {
            this.childForm.controls[sub]['controls']['selected_answer'].setValue('');
            this.childForm.controls[sub].value['old_answer_value'] = null;
          }

        }
      }
    }

  }

  public selectvalue() {
    debugger
    const formComponent = this.childForm.get(['answer_value_' + this.questionId]);
    if (this.isChecked) {
      document.getElementById('yes_none_' + this.questionId).classList.add('clicked');
      document.getElementById('no_none_' + this.questionId).classList.remove('not_clicked');
      formComponent.setValue('1');
      this.childForm.controls['selected_answer'].setValue('Yes');
      this.onChange.emit('1');

    } else {
      document.getElementById('no_none_' + this.questionId).classList.add('not_clicked');
      document.getElementById('yes_none_' + this.questionId).classList.remove('clicked');
      formComponent.setValue('0');
      this.childForm.controls['selected_answer'].setValue('No');
      this.onChange.emit('0');
    }
    formComponent.setErrors(null);
  }

}
