import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ValidationService } from 'src/app/shared/validation.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

@Component({
  selector: 'app-contingent-beneficiaries',
  templateUrl: './contingent-beneficiaries.component.html',
  styleUrls: ['./contingent-beneficiaries.component.css']
})
export class ContingentBeneficiariesComponent implements OnInit {
  @Input() questionDetails: [];
  @Output() public contingentBeneficiariesOutput =
    new EventEmitter();
  dynamicdiv = [];
  Grandtotal: any;
  ShowSubQuestion: boolean;
  ShowNextButton: boolean;
  selectedIdex: any;
  curDate: any;
  invalidDate: boolean;
  contingentBeneficiariesDetails: any;
  editForTheComponent: any;
  contingentBeneficiaryInfo: any;
  contingentBeneficiaries: any;
  showloader:boolean;
  constructor(
      private pc: ProcessCacheService,
      private utilityService:UtilityFunctionsService,
      public validationService: ValidationService,
      private $gaService: GoogleAnalyticsService,
      private service:ParentChildService) { }

  ngOnInit(): void {
    debugger;
    this.showloader=true;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_8_ContingentBeneficiary'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.service.callComponentMethod();
    document.getElementById('appFlowFooterID').style.display = 'block';
    this.editForTheComponent = localStorage.getItem('editContigentBeneficiaryInformation');

    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {
        debugger;
        const clientDataFiltered = JSON.parse(data.Payload);
        this.contingentBeneficiaryInfo = clientDataFiltered.body.contingentBeneficiaryInfo;
        this.contingentBeneficiaries = clientDataFiltered.body.contingentBeneficiaries;


        // this.contingentBeneficiariesDetails = localStorage.getItem("PacLife-contingentBeneficiaries");
        // this.contingentBeneficiariesDetails = JSON.parse(this.contingentBeneficiariesDetails);
        this.selectedIdex = 0;
        this.curDate = new Date();
        const year = Number(this.curDate.getFullYear()) - 18;
        let month = '' + (this.curDate.getMonth() + 1);
        let day = '' + this.curDate.getDate();
        if (month.length < 2)
          month = '0' + month;
        if (day.length < 2)
          day = '0' + day;
        this.ShowNextButton = false;
        console.log(this.questionDetails);
        this.InActiveTickIcon = true;
        this.InActiveCrossIcons = true;
        const qestentity = {
          'contingentBeneficiaryFirstName': '',
          'contingentBeneficiaryLastName': '',
          'contingentBeneficiaryRelationship': 'Choose',
          // "contingentBeneficiaryDob": month + '/' + day + '/' + year,
          'contingentBeneficiaryPercentage': '',
          'contingentBeneficiaryConfirm': false

        };
        this.dynamicdiv.push(qestentity);
        debugger;
        this.individualOptons = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_3']['dropdown_options'];
        this.result = this.individualOptons.reduce((resultArray, item, index) => {
          const chunkIndex = Math.floor(index / 2);
          if (!resultArray[chunkIndex]) {
            resultArray[chunkIndex] = []; // start a new chunk
          }
          resultArray[chunkIndex].push(item);
          return resultArray;
        }, []);


        let total1 = 0;
        this.dynamicdiv.forEach(element => {
          total1 += Number(element.contingentBeneficiaryPercentage);
        });
        this.Grandtotal = total1.toLocaleString();

        this.ShowSubQuestion = false;

        if (this.contingentBeneficiaries == null || this.contingentBeneficiaries == '' || this.contingentBeneficiaries == undefined) {

        } else {
          if (this.contingentBeneficiaries == 'Yes') {
            var data1 = 1;
          } else {
            var data1 = 0;
          }

          this.SelectedOption = data1;
          if (this.SelectedOption == 0) {
            this.selectedOptionValue = 'No';
            // document.getElementById('0').style.border='solid 2px orange';
            // document.getElementById('1').style.border='solid 1px #dae7ec';
            this.ActiveTickIcons = false;
            this.InActiveTickIcon = true;
            this.ActiveCrossIcons = true;
            this.InActiveCrossIcons = false;
            this.ShowSubQuestion = false;
            this.ShowNextButton = true;

          } else {
            this.selectedOptionValue = 'Yes';
            //  document.getElementById('1').style.border='solid 2px orange';
            //  document.getElementById('0').style.border='solid 1px #dae7ec';
            this.ActiveTickIcons = true;
            this.InActiveTickIcon = false;
            this.ActiveCrossIcons = false;
            this.InActiveCrossIcons = true;
            this.ShowSubQuestion = true;
            this.ShowNextButton = false;


            this.dynamicdiv =
              this.contingentBeneficiaryInfo.length != undefined ||
                this.contingentBeneficiaryInfo.length > 0 ?
                this.contingentBeneficiaryInfo : [];
            // for (let i = 0; i < this.BenefficiariesInfo.length; i++) {
            //   this.dynamicdiv.push(this.BenefficiariesInfo[i]);
            // }
            let total1 = 0;
            this.dynamicdiv.forEach(element => {
              total1 += Number(element.contingentBeneficiaryPercentage);
            });
            this.Grandtotal = total1.toLocaleString();
            localStorage.setItem('Bubble-contingentBeneficiariesGrandTotal', this.Grandtotal);

          }




        }
        this.showloader=false;
      }
    ).catch(error => {
      console.log(error);
    });




  }

  SelectedOption: any;
  SecondQuestion: any;
  ActiveTickIcons: boolean;
  InActiveTickIcon: boolean;
  ActiveCrossIcons: boolean;
  InActiveCrossIcons: boolean;
  selectedOptionValue: any;
  public GetOptions(data) {
    this.SelectedOption = data;
    if (this.SelectedOption == 0) {
      this.selectedOptionValue = 'No';
      //  document.getElementById('0').style.border='solid 2px orange';
      //  document.getElementById('1').style.border='solid 1px #dae7ec';
      this.ActiveTickIcons = false;
      this.InActiveTickIcon = true;
      this.ActiveCrossIcons = true;
      this.InActiveCrossIcons = false;
      this.ShowSubQuestion = false;
      this.ShowNextButton = true;

    } else {
      this.selectedOptionValue = 'Yes';
      // document.getElementById('1').style.border='solid 2px orange';
      // document.getElementById('0').style.border='solid 1px #dae7ec';
      this.ActiveTickIcons = true;
      this.InActiveTickIcon = false;
      this.ActiveCrossIcons = false;
      this.InActiveCrossIcons = true;
      this.ShowSubQuestion = true;
      this.ShowNextButton = false;

    }
  }


  onKey(event, index, property) {
    debugger;

    if (event.target.value > 100) {
      this.dynamicdiv[index][property] = 0;
    } else {
      this.dynamicdiv[index][property] = event.target.value;
    }

    let total1 = 0;
    this.dynamicdiv.forEach(element => {
      total1 += Number(element.contingentBeneficiaryPercentage);
    });
    this.Grandtotal = total1.toLocaleString();
  }

  message;
  is_valid: boolean;
  onKeyFordate(even, index, property) {
    debugger;
    //   var cur_d = new Date();
    //   var d = new Date(newDate),
    //   month = '' + (d.getMonth() + 1),
    //   day = '' + d.getDate(),
    //   year = d.getFullYear();
    //   if (month.length < 2)
    //   month = '0' + month;
    //  if (day.length < 2)
    //   day = '0' + day;
    //   var curYear =  cur_d.getFullYear();
    //   year = d.getFullYear();

    //   var ageYear= Number(curYear) - Number(year);
    //    this.dynamicdiv[index][property] =  month + '/'+ day + '/' + year;
    //    if(ageYear>=18){
    //     this.invalidDate=false;
    //    }else{
    //      this.invalidDate=true
    //    }
    //   let total1 = 0;
    //   this.dynamicdiv.forEach(element => {
    //     total1 += Number(element.contingentBeneficiaryPercentage);
    //   });
    //   this.Grandtotal = total1.toLocaleString();

    if (even == null) {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dynamicdiv[index][property] = month + '/' + day + '/' + year;

    } else {
      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();
      const ageYear = Number(curYear) - Number(year);
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dynamicdiv[index][property] = month + '/' + day + '/' + year;

    }
    const newDate = new Date(even);
    console.log('DOB entered: ', newDate);
    const checkAge = this.validationService.validate_dob('beneficiary_dob', newDate);
    this.message = checkAge.message;
    this.is_valid = checkAge.is_valid;
  }



  inputJson;
  public onSubmit(data) {
    debugger;
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);

      if (this.selectedOptionValue == 'No') {
        if (this.editForTheComponent == 'true') {
          this.inputJson = {};
          this.inputJson['id'] = localStorage.getItem('clientID');
          this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;
          this.contingentBeneficiariesOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson, afterEdit: true });
          this.submitdata(this.inputJson);
        } else {
          this.inputJson = {};
          this.inputJson['id'] = localStorage.getItem('clientID');
          this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;
          this.contingentBeneficiariesOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
          this.submitdata(this.inputJson);
        }


      } else {
        if (this.editForTheComponent == 'true') {
          this.inputJson = {};
          this.inputJson['id'] = localStorage.getItem('clientID');
          this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;
          this.inputJson[data.sub_questions.question_1.group_key] = this.dynamicdiv;
          this.contingentBeneficiariesOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson, afterEdit: true });
          this.submitdata(this.inputJson);
        } else {
          this.inputJson = {};
          this.inputJson['id'] = localStorage.getItem('clientID');
          this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;
          this.inputJson[data.sub_questions.question_1.group_key] = this.dynamicdiv;
          this.contingentBeneficiariesOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
          this.submitdata(this.inputJson);
        }

      }



    } else {
      alert('Declined!');
    }

    this.$gaService.event('Next', 'event', 'LifeAppContingentBeneficiary');
    const eventOptions: EventOption = {
      page_title: 'Add Contingent Beneficiary ',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppContingentBeneficiary');

  }

  public submitdata(inputJson) {

    const contingentBeneficiariesDetails = JSON.stringify(this.inputJson);
    // Remove from localStorage if No contingent beneficiaries
    if (this.selectedOptionValue == 'No') {
      localStorage.removeItem('PacLife-contingentBeneficiaries');
      inputJson.contingentBeneficiaryInfo = [];
      inputJson.contingentBeneficiaries = this.selectedOptionValue;
    } else {
      localStorage.setItem('PacLife-contingentBeneficiaries', contingentBeneficiariesDetails);
      // localStorage.setItem('PacLife-contingentBeneficiariesGrandTotal', contingentBeneficiariesDetails)
    }

    //This call internally does a getAppData call and sets localStorage with clientdata
    this.pc.saveUpdateClientData(inputJson);
  }


  public goBack() {
    // this.isDependentOutput.emit(this.questionDetails["component_id"]+",Goback");
    this.contingentBeneficiariesOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }



  adddiv() {
    debugger;
    if (this.Grandtotal < 100) {
      const qestentity = {
        'contingentBeneficiaryFirstName': '',
        'contingentBeneficiaryLastName': '',
        'contingentBeneficiaryRelationship': 'Choose',
        // "contingentBeneficiaryDob": new Date(),
        'contingentBeneficiaryPercentage': '',
        'contingentBeneficiaryConfirm': false
      };
      this.dynamicdiv.push(qestentity);
    } else {

    }



  }

  RemoveRow(divindex) {
    {
      const index = this.dynamicdiv.findIndex(x => x.firstname == divindex);
      this.dynamicdiv.splice(index, 1);

      let total1 = 0;
      this.dynamicdiv.forEach(element => {
        total1 += Number(element.contingentBeneficiaryPercentage);
      });
      this.Grandtotal = total1.toLocaleString();
    }

  }


  result: any;
  individualOptons: any;
  opendisc(index) {
    // debugger;
    this.selectedIdex = index;
    this.individualOptons = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_3']['dropdown_options'];
    this.result = this.individualOptons.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 2);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);
    console.log('contingent relation library:', this.individualOptons);
    console.log('contingent relation:', this.result);

    document.getElementById('disclaimer').style.display = 'block';
    document.getElementById('makeblur').style.filter = 'blur(10px)';


  }

  closedisc() {
    document.getElementById('disclaimer').style.display = 'none';
    document.getElementById('makeblur').style.filter = 'none';
  }


  public GetRadioOptionID(data) {
    debugger;
    this.dynamicdiv[this.selectedIdex].contingentBeneficiaryRelationship = data;
  }



}
