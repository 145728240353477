import { NgModule, CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';

import { QuestionsComponent } from './questions.component';
import { InputTextComponent } from './ui/input-text/input-text.component';
import { InputYesNoComponent } from './ui/input-yesno/input-yesno.component';
import { LabelTitleComponent } from './ui/label-title/label-title.component';
import { LabelSubTitleComponent } from './ui/label-subtitle/label-subtitle.component';
import { InputRadioComponent } from './ui/input-radio/input-radio.component';
import { InputCheckboxComponent } from './ui/input-checkbox/input-checkbox.component';
import { InputSelectComponent } from './ui/input-select/input-select.component';
import { InputRangeComponent } from './ui/input-range/input-range.component';
import { SbliAppFlowHeaderComponent } from 'src/app/SBLIAppFlow/sbli-app-flow-header/sbli-app-flow-header.component';
import { PizzatrackerComponent } from 'src/app/SBLIAppFlow/questions/pizzatracker/pizzatracker.component';
import { SbliStatustrackerComponent } from 'src/app/SBLIAppFlow/questions/sbli-statustracker/sbli-statustracker.component';
import { WnsAppFlowHeaderComponent } from 'src/app/SBLIAppFlow/wns-app-flow-header/wns-app-flow-header.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { LoaderComponent } from 'src/app/components/loader/loader.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LockConsentComponent } from 'src/app/pages/lock-consent/lock-consent.component';

export const routes = [
  { path: '', component: QuestionsComponent, pathMatch:'full' }
];


@NgModule({
  declarations: [
    QuestionsComponent,
    LabelTitleComponent,
    LabelSubTitleComponent,
    InputTextComponent,
    InputYesNoComponent,
    InputRadioComponent,
    InputCheckboxComponent,
    InputSelectComponent,
    InputRangeComponent,
    SbliAppFlowHeaderComponent,
    SbliStatustrackerComponent,
    PizzatrackerComponent,
    WnsAppFlowHeaderComponent,
    LoaderComponent,
    LockConsentComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule.forChild(routes),
    NgxSliderModule,
    NgbModule,

  ],
  exports: [ SbliAppFlowHeaderComponent ,SbliStatustrackerComponent,PizzatrackerComponent,WnsAppFlowHeaderComponent,LoaderComponent,LockConsentComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})

export class QuestionsModule {

}
