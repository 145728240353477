import { Component, OnInit, Input } from '@angular/core';
import { CustomizeHomeComponent } from '../customize-home/customize-home.component';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { formatDate } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from './../../components/lifescore-error-alert/lifescore-error-alert.component';
import { ToastService } from './../../shared/toast.service';
import { ValidationService } from 'src/app/shared/validation.service';
import { formatAmount, formatAmountCommaDollar } from './../../shared/UtilityFunctions.js';
import { TrackerService } from 'src/app/shared/tracker.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import pc from 'bit-uibl-data-driver';

@Component({
  selector: 'app-selected-carriers',
  templateUrl: './selected-carriers.component.html',
  styleUrls: ['./selected-carriers.component.css']
})
export class SelectedCarriersComponent implements OnInit {

  private CustomizeHome: CustomizeHomeComponent;

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  carrierName: any;
  selectedCarrierData: any;
  selectedCardData: any;
  disclaimerScript1: string;
  coverageAmountText: string;
  gender: any;
  smoker: any;
  dateOfBirth: any;
  age: any;
  height: any;
  weight: any;
  termLength: any;
  coverageAmount: any;
  heightFt: string;
  heightIn: string;
  usageDuration: string;
  commonAddress: string;
  city: any;
  state: any;
  zipcode: any;
  fullName: string;
  emailId: string;
  riskClass: string;
  loader: boolean;
  quickQuoteData: any;
  carrier1: any;
  coveragetodisaply: any;
  disableterm: boolean;
  tyod: any;
  addressToShow: any;
  carrierRules: any;
  beginAppDisabled = false;
  ShowNoChecked: boolean;
  ShowYesChecked: boolean;
  isDisable = true;
  curDate;
  minDate;
  selectedHomeCarrierData: string;
  selected: number;
  isMobileResolution: boolean;
  isDescktopResolution: boolean;
  modalImg: any;
  modalLabel: any;
  modalText: any;
  pageToOpen = '';
  showAssumptions = false;
  paclifeQuoteRiskClass: any;
  paclifeQuoteGender: any;
  paclifeQuoteIssueAge: any;
  insuredInfoInsuredState: any;
  showCustomiseHomeChild: boolean;
  homeURL: string;
  termLengthCopy: any;
  maxDate: any;
  termToShow: any;
  dateOfBirthDisplay: string;
  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private modalService: NgbModal,
    public toastService: ToastService,
    public validationService: ValidationService,
    private tracker: TrackerService,
    public router: Router,
    private $gaService: GoogleAnalyticsService,
    private utilityService: UtilityFunctionsService
  ) {
    this.CustomizeHome = new CustomizeHomeComponent(this.utilityService, this.pc, this.activatedroute, this.modalService);
  }

  ngOnInit(): void {
    debugger;
    ///////////////// GA pageview Implementation starts
    // import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
    // import { GoogleAnalyticsService } from 'ngx-google-analytics';
    //, private $gaService: GoogleAnalyticsService, private utilityService: UtilityFunctionsService
    let path, titleOfPage;
    path = 'SelectedCarrier';
    titleOfPage = 'SelectedCarrier';
    const subdomainDomain = this.utilityService.getSubdomainDomain(window.location.href);
    this.utilityService.setUTMInPageview(this.$gaService, path, titleOfPage, subdomainDomain);
    console.log('Done with this.utilityService.setUTMInPageview');
    ///////////////// GA pageview Implementation Ends
    this.homeURL = this.pc.envCred.url;
    document.getElementById('index_title_id').innerText = 'Life Insurance powered by Bubble';
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      this.carrierName = params['carrierName'];
      const add = this.uniqueId.split('-');
      add.shift();
      this.addressToShow = add.join(' ');
      localStorage.setItem('displayAddress', this.displayAddress);
      localStorage.setItem('carrierName', this.carrierName);
    }
    );
    // this.pc.getAppData(this.clientId).then(data => {
    //   let dataFrom = JSON.parse(data.Payload);
    //   this.addressToShow = dataFrom.body.formatted_address == undefined ? this.addressToShow : dataFrom.body.formatted_address;
    //   console.log("getAppData : ", dataFrom);
    // }).catch(error => {
    //   console.log(error);
    // });

    if (window.innerWidth < 600) {
      this.isMobileResolution = true;
      this.isDescktopResolution = false;

    } else {
      this.isMobileResolution = false;
      this.isDescktopResolution = true;

    }

    this.insurancetype = this.insurancetype.toString();

    if (this.insurancetype == 'homeLife' || this.insurancetype == 'home') {
      this.selected = 1;
    }
    else {
      this.selected = 2;
    }
    // this.beginAppDisabled = true;
    this.getValues();

    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {
        debugger;
        const clientDataFiltered = JSON.parse(data.Payload);
        this.paclifeQuoteRiskClass = clientDataFiltered.body.paclifeQuote['riskClass'];
        this.paclifeQuoteGender = clientDataFiltered.body.paclifeQuote['gender'];
        this.paclifeQuoteIssueAge = clientDataFiltered.body.paclifeQuote['issueAge'];
        this.insuredInfoInsuredState = clientDataFiltered.body.stateCode;
      }
    ).catch(error => {
      console.log('processCache.getAppData:error: ', error);
    });

    this.tracker.changeProgressStatus({ totalPages: 10, currentPage: 10 });

    this.curDate = new Date();
    const yearm = Number(this.curDate.getFullYear()) - 55;
    let monthm = '' + (this.curDate.getMonth() + 1);
    let daym = '' + this.curDate.getDate();
    if (monthm.length < 2)
      monthm = '0' + monthm;
    if (daym.length < 2)
      daym = '0' + daym;

    this.minDate = new Date(monthm + '/' + daym + '/' + yearm);

    const year = Number(this.curDate.getFullYear());
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.maxDate = new Date(month + '/' + day + '/' + year);

    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    const myDate = new Date(this.minDate);
    this.minDate = formatDate(myDate, format, locale);
    const myeDate = new Date(this.maxDate);
    this.maxDate = formatDate(myeDate, format, locale);

  }

  getValues() {
    debugger;
    this.loader = true;
    debugger;
    // if (this.insurancetype === 'homeLife' || this.insurancetype === 'life') {
    //   this.selectedCarrierData = localStorage.getItem('selectedCarrier');
    //   this.selectedCardData = JSON.parse(this.selectedCarrierData);
    //   if (this.selectedCardData.carrierId === 'paclife') {
    //     this.carrierName = 'Pacific Life';
    //     this.selectedCardData.selectedDetails.term = '65 Years';
    //     this.coverageAmountText = 'Min: $250,000 - Max: $10M';
    //     if (this.selectedCardData.selectedDetails.text.indexOf('*(1)') !== -1) {
    //       this.selectedCardData.selectedDetails.text = this.selectedCardData.selectedDetails.text.replace('*(1)', '');
    //       this.disclaimerScript1 = '1 ';
    //     }
    //   } else {
    //     this.carrierName = this.selectedCardData.head.carrier_name;
    //     this.coverageAmountText = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
    //     this.selectedCardData.selectedDetails.term = '' + this.selectedCardData.selectedDetails.term;
    //     if (this.selectedCardData.selectedDetails.term &&
    //       !this.selectedCardData.selectedDetails.term.endsWith('Years')) {
    //       this.selectedCardData.selectedDetails.term = `${this.selectedCardData.selectedDetails.term} Years`;
    //     }

    //   }
    // }
    ////////////////Deal with term and coverageAmount for Life kind insuranceType Ended
    if (this.insurancetype === 'homeLife') {

      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.minDate = new Date(month + '/' + day + '/' + year);

      if (this.carrierName === 'Pac Life Next' || this.carrierName === 'Next Term Life') {
        this.disableterm = true;
        const quickquotes = new pc.Quotes(this.uniqueId);
        this.carrierRules = quickquotes.getCarrierRules('paclife');
        console.log('carrier rules :', this.carrierRules);
      } else { this.disableterm = false; }
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);

      this.tyod = date;
      const format = 'yyyy-MM-dd';
      const myDate = this.tyod;
      const locale = 'en-US';
      this.tyod = formatDate(myDate, format, locale);


      this.selectedCarrierData = localStorage.getItem('selectedCarrier');
      this.selectedCarrierData = JSON.parse(this.selectedCarrierData);
      this.selectedCardData = this.selectedCarrierData;
      if(this.selectedCardData.carrierId=='sbli'||this.selectedCardData.carrierId=='wns'){
        this.termLength=this.selectedCardData.selectedDetails.term.replace(' Years');
        localStorage.setItem('bubble-termLength', this.termLength);
        const today = new Date();
        const birthDate = new Date(localStorage.getItem('bubble-dob'));
        this.age = today.getFullYear() - birthDate.getFullYear();
        const upto = Number(this.selectedCardData.selectedDetails.term.split(' ')[0]) + Number(this.age);
        this.selectedCardData.body.points[0].highlight = 'You are covered up to age ' + upto + ' (based on your current age)';
        this.termToShow = ' Age ' + upto ;
      }
      else {
        this.termToShow = 'Age 65';
      }

      console.log('selected life : ', this.selectedCardData);
      this.selectedCardData['head']['quote_amount'] = formatAmount(this.selectedCardData['head']['quote_amount']);
      console.log('selected life : ', this.selectedCardData);

      this.coverageAmountText = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
     // this.beginAppDisabled = true;

       //debugger
      this.gender = localStorage.getItem('bubble-gender');
      // this.gender = this.gender == '1' ? "Male" : "Female";
      this.smoker = localStorage.getItem('bubble-tobacco');
      //this.smoker = this.smoker == '1' ? "1" : "5";
      if (this.smoker != '5') {

        this.ShowYesChecked = true;
        this.ShowNoChecked = false;

        this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');
        this.smoker = '0';
      } else {

        this.ShowYesChecked = false;
        this.ShowNoChecked = true;

        this.usageDuration = '1';


      }

      //debugger;

      this.dateOfBirth = localStorage.getItem('bubble-dob');
      const formats = 'MM/dd/yyyy';
      const locales = 'en-US';
      const myDates = new Date(localStorage.getItem('bubble-dob'));
      this.dateOfBirthDisplay = formatDate(myDates, formats, locales);
      this.checkDateNew();
      this.age = localStorage.getItem('bubble-age');
      this.height = localStorage.getItem('bubble-height');
      this.heightFt = localStorage.getItem('bubble-heightFt');
      this.heightIn = localStorage.getItem('bubble-heightIn');
      this.weight = localStorage.getItem('bubble-weight');
      this.termLength = localStorage.getItem('bubble-termLength');

      this.termLengthCopy = this.termLength;
      this.coverageAmount = localStorage.getItem('bubble-coverage');

      const formatednumber = this.coverageAmount;
      this.coveragetodisaply = formatednumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      if (this.coverageAmount > 10000000) {
        this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
      } else if (this.coverageAmount < 250000) {
        this.showlabel = 'Coverage amount cannot be less than $250,000';
      } else {
        this.showlabel = '';
      }



      this.selectedHomeCarrierData = localStorage.getItem('selectedHomeCarrier');
      this.selectedHomeCarrierData = JSON.parse(this.selectedHomeCarrierData);
      console.log('selected home : ', this.selectedHomeCarrierData);
      this.selectedHomeCarrierData['head']['quote_amount'] = formatAmount(this.selectedHomeCarrierData['head']['quote_amount']);
      console.log('selected home : ', this.selectedHomeCarrierData);

      const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
      console.log('selectedHome :', selectedHome);

      let dwelleing = selectedHome.body.other_amounts.dwelling.text;
      dwelleing = dwelleing.replace('$', '').replace(/,/g, '');
      console.log('dwelleing :', dwelleing);

      let replacementCost = selectedHome.replacementCost;
      replacementCost = replacementCost.replace('$', '').replace(/,/g, '');
      console.log('replacementCost :', replacementCost);

      const commonInput = JSON.parse(localStorage.getItem('commonInput'));
      commonInput['replacementCost'] = replacementCost;
      commonInput['coverage'] = dwelleing;
      localStorage.setItem('commonInput', JSON.stringify(commonInput));

      this.initstyle();

    } else if (this.insurancetype === 'life') {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.minDate = new Date(month + '/' + day + '/' + year);

      if (this.carrierName === 'Pac Life Next' || this.carrierName === 'Next Term Life') {
        this.disableterm = true;
        const quickquotes = new pc.Quotes(this.uniqueId);
        this.carrierRules = quickquotes.getCarrierRules('paclife');
        console.log('carrier rules :', this.carrierRules);
      }
      else {
        this.disableterm = false;
      }
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);

      this.tyod = date;
      const format = 'MM/dd/YYYY';
      const myDate = this.tyod;
      const locale = 'en-US';
      this.tyod = formatDate(myDate, format, locale);

      this.selectedCarrierData = localStorage.getItem('selectedCarrier');
      this.selectedCarrierData = JSON.parse(this.selectedCarrierData);
      this.selectedCardData = this.selectedCarrierData;
      if (this.selectedCardData.carrierId == 'sbli' || this.selectedCardData.carrierId == 'wns') {
        debugger;
        this.termLength = this.selectedCardData.selectedDetails.term.replace(' Years', '');
        localStorage.setItem('bubble-termLength', this.termLength);
        const today = new Date();
        const birthDate = new Date(localStorage.getItem('bubble-dob'));
        this.age = today.getFullYear() - birthDate.getFullYear();
        const upto = Number(this.selectedCardData.selectedDetails.term.split(' ')[0]) + Number(this.age);
        this.selectedCardData.body.points[0].highlight = 'You are covered up to age ' + upto + ' (based on your current age)';
        this.termToShow = 'Age ' + upto;
        debugger;
      }
      else {
        this.termToShow = 'Age 65';
      }

      this.selectedCardData['head']['quote_amount'] = formatAmount(this.selectedCardData['head']['quote_amount']);

      console.log('selected life : ', this.selectedCardData);
      //  //debugger
      this.gender = localStorage.getItem('bubble-gender');
      // this.gender = this.gender == '1' ? "Male" : "Female";
      this.smoker = localStorage.getItem('bubble-tobacco');
      //this.smoker = this.smoker == '1' ? "1" : "5";
      if (this.smoker != '5') {

        this.ShowYesChecked = true;
        this.ShowNoChecked = false;

        this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');
        this.smoker = '0';

      }
      else {

        this.ShowYesChecked = false;
        this.ShowNoChecked = true;
        this.usageDuration = '1';

      }

      debugger;

      this.dateOfBirth = localStorage.getItem('bubble-dob');
      const formats = 'MM/dd/YYYY';
      const locales = 'en-US';
      const myDates = new Date(localStorage.getItem('bubble-dob'));
      this.dateOfBirthDisplay = formatDate(myDates, formats, locales);
      this.checkDateNew();
      this.age = localStorage.getItem('bubble-age');
      this.height = localStorage.getItem('bubble-height');
      this.heightFt = localStorage.getItem('bubble-heightFt');
      this.heightIn = localStorage.getItem('bubble-heightIn');
      this.weight = localStorage.getItem('bubble-weight');
      this.termLength = localStorage.getItem('bubble-termLength');


      this.termLengthCopy = this.termLength;
      this.coverageAmount = localStorage.getItem('bubble-coverage');

      const formatednumber = this.coverageAmount;
      this.coveragetodisaply = formatednumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

      if (this.selectedCardData.carrierId === 'sbli') {
        if (this.coverageAmount > 10000000) {
          this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
        } else if (this.coverageAmount < 25000) {
          this.showlabel = 'Coverage amount cannot be less than $25,000';
        } else {
          this.showlabel = '';
        }
      }
      else {
        if (this.coverageAmount > 10000000) {
          this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
        } else if (this.coverageAmount < 250000) {
          this.showlabel = 'Coverage amount cannot be less than $250,000';
        } else {
          this.showlabel = '';
        }
      }

      // if (this.selectedCardData.carrierId === "wns") {
      //   this.beginAppDisabled = true;
      // }
      //this.initstyle();
    } else if (this.insurancetype === 'home') {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.minDate = new Date(month + '/' + day + '/' + year);

      if (this.carrierName === 'Pac Life Next' || this.carrierName === 'Next Term Life') {
        this.disableterm = true;
        const quickquotes = new pc.Quotes(this.uniqueId);
        this.carrierRules = quickquotes.getCarrierRules('paclife');
        console.log('carrier rules :', this.carrierRules);
      }
      else {
        this.disableterm = false;
      }
      const date = new Date();
      date.setFullYear(date.getFullYear() - 18);

      this.tyod = date;
      const format = 'yyyy-MM-dd';
      const myDate = this.tyod;
      const locale = 'en-US';
      this.tyod = formatDate(myDate, format, locale);


      // this.selectedCarrierData = localStorage.getItem('selectedCarrier');
      // this.selectedCarrierData = JSON.parse(this.selectedCarrierData);
      // this.selectedCardData = this.selectedCarrierData;
      // // console.log("this.selectedCardData", this.selectedCardData);
      // this.beginAppDisabled = true;
      // if (this.selectedCardData.carrierId == "paclife") {
      //   // this.beginAppDisabled = false;
      // }

      this.selectedHomeCarrierData = localStorage.getItem('selectedHomeCarrier');
      this.selectedHomeCarrierData = JSON.parse(this.selectedHomeCarrierData);
      console.log('selected home : ', this.selectedHomeCarrierData);
      this.selectedHomeCarrierData['head']['quote_amount'] = formatAmount(this.selectedHomeCarrierData['head']['quote_amount']);
      console.log('selected home : ', this.selectedHomeCarrierData);


      const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
      console.log('selectedHome :', selectedHome);

      let dwelleing = selectedHome.body.other_amounts.dwelling.text;
      dwelleing = dwelleing.replace('$', '').replace(/,/g, '');
      console.log('dwelleing :', dwelleing);

      let replacementCost = selectedHome.replacementCost;
      replacementCost = replacementCost.replace('$', '').replace(/,/g, '');
      console.log('replacementCost :', replacementCost);

      const commonInput = JSON.parse(localStorage.getItem('commonInput'));
      commonInput['replacementCost'] = replacementCost;
      commonInput['coverage'] = dwelleing;
      localStorage.setItem('commonInput', JSON.stringify(commonInput));
    }
    console.log('selected ins type : ', this.selected);
    console.log(' ins type : ', this.insurancetype);
    this.loader = false;

  }

  updateQuoteValues() {
    //debugger
    this.selectedHomeCarrierData = localStorage.getItem('selectedHomeCarrier');
    this.selectedHomeCarrierData = JSON.parse(this.selectedHomeCarrierData);
    // Hubspot update quote
    const hubSpotData = {
      customer_id: localStorage.getItem('customer_id'),
      client_id: localStorage.getItem('clientID'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Quote',
      home_insurance_carrier: this.selectedHomeCarrierData['head']['home_insurance_carrier'],
      home_latest_premium_amount: '$' + this.selectedHomeCarrierData['head']['quote_amount'],
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    console.log('selected home : ', this.selectedHomeCarrierData);
    this.selectedHomeCarrierData['head']['quote_amount'] = formatAmount(this.selectedHomeCarrierData['head']['quote_amount']);
    console.log('selected home : ', this.selectedHomeCarrierData);

  }



  inputdatechange() {
    debugger;
    const K = this.dateOfBirthDisplay.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && Number(A) > 12) {
        this.message = 'Invalid Date';
        this.is_valid = false;
      }
      else {
        this.message = '';
        this.is_valid = true;
      }
      this.dateOfBirthDisplay = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (Number(B) > 31) {
          this.message = 'Invalid Date';
          this.is_valid = false;
        }
        else if (Number(A) <= 12) {
          this.dateOfBirthDisplay = A + '/' + B + '/';
          this.message = '';
          this.is_valid = true;
        }
        else {
          this.message = 'Invalid Date';
          this.is_valid = false;

        }


      }
      else {
        this.dateOfBirthDisplay = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.dateOfBirthDisplay = A + '/' + B + '/' + C;
    }
    else {
      this.dateOfBirthDisplay = this.dateOfBirthDisplay;
    }

    if (this.dateOfBirthDisplay.length >= 10 && this.message != 'Invalid Date') {
      this.is_valid = true;
      this.checkDateNew();
    }
    else {
      this.is_valid = false;
    }
  }


  message: string;
  is_valid: boolean;
  checkDateNew() {
    debugger;
    const dob = new Date(this.dateOfBirthDisplay);
    const year = Number(dob.getFullYear());
    let month = '' + (dob.getMonth() + 1);
    let day = '' + dob.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.dateOfBirth = new Date(month + '/' + day + '/' + year);
    localStorage.setItem('bubble-dob', this.dateOfBirth);
    if (month == 'NaN' || year.toString() == 'Nan' || month == 'NaN') {
      this.message = 'Invalid Date';
      this.is_valid = false;
    }
    else {
      this.dateOfBirth = new Date(month + '/' + day + '/' + year);
      localStorage.setItem('bubble-dob', this.dateOfBirth);
      console.log('DOB entered: ', dob);
      const checkAge = this.validationService.validate_dob('common_age', dob);
      console.log('DOB entered: checkAge', checkAge);
      this.message = checkAge.message;
      this.is_valid = checkAge.is_valid;
    }

  }






  checkDate(even) {
    debugger;
    if (even == null) {
      this.isDisable = false;
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dateOfBirth = new Date(month + '/' + day + '/' + year);
    }
    else {
      this.isDisable = false;
      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();

      if (ageYear >= 18 && ageYear <= 55) {
        //this.showspanfordob = false;
        this.isDisable = false;
      } else {
        //this.showspanfordob = true;
        this.isDisable = true;
      }
      var ageYear = Number(curYear) - Number(year);
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dateOfBirth = month + '/' + day + '/' + year;
      localStorage.setItem('bubble-dob', this.dateOfBirth);
    }

    const newDate = new Date(even);
    console.log('DOB entered: ', newDate);
    const checkAge = this.validationService.validate_dob('common_age', newDate);
    console.log('DOB entered: checkAge', checkAge);
    this.message = checkAge.message;
    this.is_valid = checkAge.is_valid;
  }

  invokeGetQuickQuote2(riskClassInput, splitname, sbligender, sbliDob, sblitobacco) {
    splitname = splitname && splitname.length > 2 ? splitname : ['Anonymous', 'User', ''];
    console.log(' Customized RiskClass =  ', ' Somking:', sblitobacco, this.smoker, ' Gender:', sbligender, ' Height:', this.height, ' Weight:', this.weight, ' Riskclass:', riskClassInput);
    this.termLength = localStorage.getItem('bubble-termLength');
    const commonInput = {
      lastName: splitname[2] ? splitname[2] : 'Lastname',
      firstName: splitname[0] ? splitname[0] : 'Firstname',
      middleName: splitname[1] ? splitname[1] : 'MiddleName',
      addressType: 'PhysicalRisk',
      address: this.commonAddress,//splitAddress[(splitAddress.length - 3)],
      city: this.city,
      gender: sbligender == '1' ? 'M' : 'F', //inputJson.gender
      dob: sbliDob,//inputJson.dob
      term: this.termLength,//inputJson.term
      zip: this.zipcode,//inputJson.zip
      state: this.state,//inputJson.state
      height: this.height,//inputJson.height
      weight: +this.weight,//inputJson.weight
      tobaccoUse: +sblitobacco,//inputJson.tobaccoUse
      riskClass: riskClassInput,
      coverage: this.coverageAmount,//inputJson.coverage
      premiumFrequency: 'm',
    };

    console.log('commonInput : ', commonInput);
    const quickquote = new pc.Quotes(this.uniqueId);

    if (this.selectedCardData.carrierId == 'paclife') {
      quickquote.getQuickQuote2(commonInput)
        .then(response => {
          console.log('utput quickquote: ', response);
          this.quickQuoteData = response;
          this.selectedCardData = this.quickQuoteData[0];
          const today = new Date();
          const birthDate = new Date(localStorage.getItem('bubble-dob'));
          this.age = today.getFullYear() - birthDate.getFullYear();
          const upto = Number(this.selectedCardData.selectedDetails.term.split(' ')[0]) + Number(this.age);
          this.selectedCardData.body.points[0].highlight = 'You are covered up to age ' + upto + ' (based on your current age)';
          localStorage.setItem('lifeCarrierName', 'Pac Life Next');
          localStorage.setItem('selectedCarrier', JSON.stringify(this.selectedCardData));
          this.coverageAmountText = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
          this.termToShow = this.selectedCardData.selectedDetails.term.split(' ')[0];
          this.termToShow = 'Age 65';
          const carrierLocal = this.selectedCardData['head']['carrier_name'];
          const quoteAmountLocal = this.selectedCardData['head']['quote_amount'];
          localStorage.setItem('bubble-QouteAmount', quoteAmountLocal);
          const hubSpotData = {
            customer_id: localStorage.getItem('customer_id'),
            email: localStorage.getItem('bubble-email'),
            life_insurance_carrier: carrierLocal,
            life_quote_amount: quoteAmountLocal,
            life_latest_premium_amount: quoteAmountLocal,
            application_stage: 'Quote'
          };
          try {
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
          } catch (error) {
            console.error('hubSpotSyncAfterEmailFetch:hubSpotData: ', hubSpotData, ' # error:', error);
          }

          // console.log("carrier3: ", this.carrier3);
          if (this.selectedCardData.hasError && this.selectedCardData.hasError == true) {
            this.loader = false;
            this.openModal();
            // alert("Our Servers are not taking too long to respond please come back later.");
          }
          this.loader = false;

          this.toastService.show('Quotes successfully updated!');
          // if (this.selectedCardData.carrierId == "sbli") {
          //   this.beginAppDisabled = true;
          // }
          // this.openModal();
          //////debugger
        })
        .catch(error => {
          if (error) console.log(error);
          else console.log('select-life-carrier\select-life-carrier.component.ts:catch(error):getQuickQuote2;Unknown error');
        });
    }
    if (this.selectedCardData.carrierId == 'sbli') {
      quickquote.getSbliQuote(commonInput)
        .then(response => {
          console.log('utput quickquote: ', response);
          this.quickQuoteData = response;
          this.selectedCardData = this.quickQuoteData[0];
          localStorage.setItem('lifeCarrierName', this.selectedCardData.head.carrier_name);
          localStorage.setItem('selectedCarrier', JSON.stringify(this.selectedCardData));
          this.coverageAmountText = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
          const carrierLocal = this.selectedCardData['head']['carrier_name'];
          const quoteAmountLocal = this.selectedCardData['head']['quote_amount'];
          const today = new Date();
          const birthDate = new Date(localStorage.getItem('bubble-dob'));
          this.age = today.getFullYear() - birthDate.getFullYear();
          const upto = Number(this.selectedCardData.selectedDetails.term.split(' ')[0]) + Number(this.age);
          this.selectedCardData.body.points[0].highlight = 'You are covered up to age ' + upto + ' (based on your current age)';

          this.termToShow = this.selectedCardData.selectedDetails.term.split(' ')[0];
          this.termToShow = 'Age  ' + upto;
          localStorage.setItem('bubble-QouteAmount', quoteAmountLocal);
          const hubSpotData = {
            customer_id: localStorage.getItem('customer_id'),
            email: localStorage.getItem('bubble-email'),
            life_insurance_carrier: carrierLocal,
            life_quote_amount: quoteAmountLocal,
            life_latest_premium_amount: quoteAmountLocal,
            application_stage: 'Quote'
          };
          try {
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
          } catch (error) {
            console.error('hubSpotSyncAfterEmailFetch:hubSpotData: ', hubSpotData, ' # error:', error);
          }

          // console.log("carrier3: ", this.carrier3);
          if (this.selectedCardData.hasError && this.selectedCardData.hasError == true) {
            this.loader = false;
            this.openModal();
            // alert("Our Servers are not taking too long to respond please come back later.");
          }
          this.loader = false;

          this.toastService.show('Quotes successfully updated!');
          // if (this.selectedCardData.carrierId == "sbli") {
          //   this.beginAppDisabled = true;
          // }
          // this.openModal();
          //////debugger
        })
        .catch(error => {
          if (error) console.log(error);
          else console.log('select-life-carrier\select-life-carrier.component.ts:catch(error):getQuickQuote2;Unknown error');
        });
    }
    if (this.selectedCardData.carrierId == 'wns') {
      quickquote.getWnsQuote(commonInput)
        .then(response => {
          console.log('utput quickquote: ', response);
          this.quickQuoteData = response;
          this.selectedCardData = this.quickQuoteData[0];
          localStorage.setItem('lifeCarrierName', this.selectedCardData.head.carrier_name);
          localStorage.setItem('selectedCarrier', JSON.stringify(this.selectedCardData));
          this.coverageAmountText = formatAmountCommaDollar(this.selectedCardData.selectedDetails.coverage);
          const carrierLocal = this.selectedCardData['head']['carrier_name'];
          const quoteAmountLocal = this.selectedCardData['head']['quote_amount'];
          const today = new Date();
          const birthDate = new Date(localStorage.getItem('bubble-dob'));
          this.age = today.getFullYear() - birthDate.getFullYear();
          const upto = Number(this.selectedCardData.selectedDetails.term.split(' ')[0]) + Number(this.age);
          this.selectedCardData.body.points[0].highlight = 'You are covered up to age ' + upto + ' (based on your current age)';

          this.termToShow = this.selectedCardData.selectedDetails.term.split(' ')[0];
          this.termToShow = 'Age  ' + upto;
          localStorage.setItem('bubble-QouteAmount', quoteAmountLocal);
          const hubSpotData = {
            customer_id: localStorage.getItem('customer_id'),
            email: localStorage.getItem('bubble-email'),
            life_insurance_carrier: carrierLocal,
            life_quote_amount: quoteAmountLocal,
            life_latest_premium_amount: quoteAmountLocal,
            application_stage: 'Quote'
          };
          try {
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
          } catch (error) {
            console.error('hubSpotSyncAfterEmailFetch:hubSpotData: ', hubSpotData, ' # error:', error);
          }

          // console.log("carrier3: ", this.carrier3);
          if (this.selectedCardData.hasError && this.selectedCardData.hasError == true) {
            this.loader = false;
            this.openModal();
            // alert("Our Servers are not taking too long to respond please come back later.");
          }
          this.loader = false;

          this.toastService.show('Quotes successfully updated!');
          // if (this.selectedCardData.carrierId === "wns") {
          //   this.beginAppDisabled = true;
          // }
          // this.openModal();
          //////debugger
        })
        .catch(error => {
          if (error) console.log(error);
          else console.log('select-life-carrier\select-life-carrier.component.ts:catch(error):getQuickQuote2;Unknown error');
        });
    }


  }

  save() {
    debugger;
    if (this.selectedCardData.carrierId == 'sbli' || this.selectedCardData.carrierId == 'wns') {
      // if (Number(this.termLength) <= Number(this.termLengthCopy)) {
      //   this.invokeSave();
      // }
      const today = new Date();
      const birthDate = new Date(this.dateOfBirth);
      this.age = today.getFullYear() - birthDate.getFullYear();
      if (Number(this.termLength) == 10 && (Number(this.age) >= 18 && Number(this.age) <= 55)) {
        this.invokeSave();
      }
      else if (Number(this.termLength) == 15 && (Number(this.age) >= 18 && Number(this.age) <= 50)) {
        this.invokeSave();
      }
      else if (Number(this.termLength) == 20 && (Number(this.age) >= 18 && Number(this.age) <= 50)) {
        this.invokeSave();
      }
      else {
        this.openModalTwo();
      }
    }
    else {
      this.invokeSave();
    }

  }

  invokeSave() {



    const json = {
      'id': this.clientId,
      'sw_questionNumber': 1,
      'dob': this.dateOfBirth,
      'gender': this.gender,
      'bubble_heightFt': this.heightFt,
      'bubble_heightIn': this.heightIn,
      'bubble_weight': this.weight
    };
    this.pc.saveUpdateClientData(json);

    //this.initstyle();

    this.coveragetodisaply = this.coveragetodisaply.replace(/,/g, '').replace('$', '');


    // if (this.coveragetodisaply > 10000000) {
    //   this.coveragetodisaply = '10,000,000';
    //   this.coverageAmount = 10000000;
    //   localStorage.setItem('bubble-coverage', this.coverageAmount);
    //   let ctd = this.coveragetodisaply;
    //   this.coveragetodisaply = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    //   // Swal.fire('Coverage amount cannot be greater than $10,000,000');

    // }
    // else if (this.coveragetodisaply < 250000) {
    //   this.coveragetodisaply = '250,000';
    //   this.coverageAmount = 250000;
    //   localStorage.setItem('bubble-coverage', this.coverageAmount);
    //   let ctd = this.coveragetodisaply;
    //   this.coveragetodisaply = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    //   // Swal.fire('Coverage amount cannot be less than $250,000');
    // }
    // else {

    //   this.coverageAmount = this.coveragetodisaply;
    //   localStorage.setItem('bubble-coverage', this.coverageAmount);
    //   let ctd = this.coveragetodisaply;
    //   this.coveragetodisaply = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    //   if (this.coverageAmount > 10000000) {
    //     this.showlabel = "Coverage amount cannot be greater than $10,000,000";
    //   } else if (this.coverageAmount < 250000) {
    //     this.showlabel = "Coverage amount cannot be less than $250,000";
    //   } else {
    //     this.showlabel = "";
    //   }

    //   this.loader = true;

    //   const today = new Date();
    //   const birthDate = new Date(this.dateOfBirth);
    //   this.age = today.getFullYear() - birthDate.getFullYear();
    //   console.log("showcustomiseLife  saved 1 gender:", this.gender);
    //   // this.gender = this.gender == "Male" ? "1" : "0";
    //   console.log("showcustomiseLife  saved 2 gender:", this.gender);
    //   localStorage.setItem('bubble-gender', this.gender);
    //   localStorage.setItem('bubble-tobacco', this.smoker);
    //   localStorage.setItem('bubble-dob', this.dateOfBirth);
    //   localStorage.setItem('bubble-age', this.age);
    //   localStorage.setItem('bubble-height', this.height);
    //   localStorage.setItem('bubble-heightFt', this.heightFt);
    //   localStorage.setItem('bubble-heightIn', this.heightIn);
    //   localStorage.setItem('bubble-weight', this.weight);
    //   localStorage.setItem('bubble-termLength', this.termLength);
    //   localStorage.setItem('bubble-coverage', this.coverageAmount);

    //   if (this.smoker == '0') {
    //     localStorage.setItem('bubble-tobacco', this.usageDuration);
    //     localStorage.setItem('bubble-tobaccoUsageDuration', this.usageDuration);
    //   }
    //   else {
    //     localStorage.setItem('bubble-tobacco', this.smoker);
    //   }

    //   document.getElementById('makeblur').style.filter = "none";
    //   //document.getElementById('headmakeblur').style.filter = "none";
    //   document.getElementById("customiseHome").style.display = "none";
    //   document.getElementById("customiseLife").style.display = "none";
    //   document.getElementById('makeblur').style.overflowY = 'auto';
    //   document.getElementById('makeblur').style.position = 'unset';


    //   // if (localStorage.getItem('bubble-fullName') !== null) {
    //   //   this.fullName = localStorage.getItem('bubble-fullName');
    //   //   this.emailId = localStorage.getItem('bubble-email');
    //   // }
    //   // else {
    //   //   this.fullName = 'Anonymous User';
    //   //   this.emailId = 'Info@GetMyBubble.com';
    //   // }
    //   // this.gender = localStorage.getItem('bubble-gender');
    //   // console.log("showcustomiseLife  getItem gender:", this.gender);
    //   // // this.gender = this.gender == '1' ? "Male" : "Female";
    //   // this.smoker = localStorage.getItem('bubble-tobacco');
    //   // // this.smoker = this.smoker != '5' ? "Yes" : "No";
    //   // this.dateOfBirth = localStorage.getItem('bubble-dob');
    //   // this.age = localStorage.getItem('bubble-age');
    //   this.height = this.heightFt + 'ft ' + this.heightIn + 'inch';;
    //   // this.weight = localStorage.getItem('bubble-weight');
    //   // this.termLength = localStorage.getItem('bubble-termLength');
    //   // this.coverageAmount = localStorage.getItem('bubble-coverage');
    //   this.commonAddress = localStorage.getItem('bubble-street');
    //   // this.commonAddress = this.displayAddress.replace(/-/g, ' ');
    //   // let splitAddress = this.displayAddress.split("-");
    //   // this.city = splitAddress[(splitAddress.length - 4)];
    //   // this.state = splitAddress[(splitAddress.length - 3)];
    //   // this.zipcode = splitAddress[(splitAddress.length - 2)];
    //   let fullAddress = this.uniqueId.split("-");
    //   fullAddress.shift();
    //   fullAddress = fullAddress.join(" ");
    //   this.pc.parseAddressLocalAndPatch(fullAddress).then(
    //     data => {
    //       console.log("splitAddressNew : ", data);
    //       this.state = data.state_code;
    //       this.city = data.city_name;
    //       this.zipcode = data.zip;

    //       console.log("fullAddressNew : ", fullAddress);
    //       let sbligender = this.gender;// localStorage.getItem('bubble-gender');// == "Male" ? "1" : "0";
    //       let sblitobacco = localStorage.getItem('bubble-tobacco'); //this.smoker;
    //       var d = new Date(this.dateOfBirth);
    //       var year = d.getFullYear();
    //       console.log("commonInput : ", year, this.age, this.dateOfBirth);
    //       let sbliDob = "01/01/" + year;
    //       let splitname;
    //       if (this.fullName) splitname = this.fullName.split(" ");
    //       // ////debugger

    //       if (this.selectedCardData.carrierId=="paclife") {
    //         this.pc.getPacLifeRiskClass(sblitobacco, this.gender, this.height, this.weight).then(response => {
    //           console.log("pacLifeRiskClass smoker: ", this.smoker);
    //           let pacLifeRiskClass = response.data.output;
    //           let dp = JSON.parse(pacLifeRiskClass);
    //           console.log('commonInput:pacLifeRiskClass :', dp.dput1);
    //           pacLifeRiskClass = dp.dput1;
    //           localStorage.setItem('riskClass', pacLifeRiskClass);
    //           this.invokeGetQuickQuote2(pacLifeRiskClass, splitname, sbligender, sbliDob, sblitobacco);
    //         })
    //         .catch(error => {
    //           console.log(error);
    //         });
    //       }
    //       if (this.selectedCardData.carrierId=="sbli") {
    //         this.invokeGetQuickQuote2("", splitname, sbligender, sbliDob, sblitobacco);
    //       }

    //     }
    //   ).catch(error => console.log("error", error));
    // }

    this.coverageAmount = this.coveragetodisaply;
    localStorage.setItem('bubble-coverage', this.coverageAmount);
    const ctd = this.coveragetodisaply;
    this.coveragetodisaply = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    if (this.selectedCardData.carrierId === 'sbli' || this.selectedCardData.carrierId == 'wns') {
      if (this.coverageAmount > 10000000) {
        this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
      } else if (this.coverageAmount < 25000) {
        this.showlabel = 'Coverage amount cannot be less than $25,000';
      } else {
        this.showlabel = '';
      }
    }
    else {
      if (this.coverageAmount > 10000000) {
        this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
      } else if (this.coverageAmount < 250000) {
        this.showlabel = 'Coverage amount cannot be less than $250,000';
      } else {
        this.showlabel = '';
      }
    }

    this.loader = true;

    const today = new Date();
    const birthDate = new Date(this.dateOfBirth);
    this.age = today.getFullYear() - birthDate.getFullYear();
    console.log('showcustomiseLife  saved 1 gender:', this.gender);
    // this.gender = this.gender == "Male" ? "1" : "0";
    console.log('showcustomiseLife  saved 2 gender:', this.gender);
    localStorage.setItem('bubble-gender', this.gender);
    localStorage.setItem('bubble-tobacco', this.smoker);
    localStorage.setItem('bubble-dob', this.dateOfBirth);
    localStorage.setItem('bubble-age', this.age);
    localStorage.setItem('bubble-height', this.height);
    localStorage.setItem('bubble-heightFt', this.heightFt);
    localStorage.setItem('bubble-heightIn', this.heightIn);
    localStorage.setItem('bubble-weight', this.weight);
    localStorage.setItem('bubble-termLength', this.termLength);
    localStorage.setItem('bubble-coverage', this.coverageAmount);

    if (this.smoker == '0') {
      localStorage.setItem('bubble-tobacco', this.usageDuration);
      localStorage.setItem('bubble-tobaccoUsageDuration', this.usageDuration);
    }
    else {
      localStorage.setItem('bubble-tobacco', this.smoker);
    }

    document.getElementById('makeblur').style.filter = 'none';
    //document.getElementById('headmakeblur').style.filter = "none";
    document.getElementById('customiseHome').style.display = 'none';
    document.getElementById('customiseLife').style.display = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';


    // if (localStorage.getItem('bubble-fullName') !== null) {
    //   this.fullName = localStorage.getItem('bubble-fullName');
    //   this.emailId = localStorage.getItem('bubble-email');
    // }
    // else {
    //   this.fullName = 'Anonymous User';
    //   this.emailId = 'Info@GetMyBubble.com';
    // }
    // this.gender = localStorage.getItem('bubble-gender');
    // console.log("showcustomiseLife  getItem gender:", this.gender);
    // // this.gender = this.gender == '1' ? "Male" : "Female";
    // this.smoker = localStorage.getItem('bubble-tobacco');
    // // this.smoker = this.smoker != '5' ? "Yes" : "No";
    // this.dateOfBirth = localStorage.getItem('bubble-dob');
    // this.age = localStorage.getItem('bubble-age');
    this.height = this.heightFt + 'ft ' + this.heightIn + 'inch';
    // this.weight = localStorage.getItem('bubble-weight');
    // this.termLength = localStorage.getItem('bubble-termLength');
    // this.coverageAmount = localStorage.getItem('bubble-coverage');
    this.commonAddress = localStorage.getItem('bubble-street');
    // this.commonAddress = this.displayAddress.replace(/-/g, ' ');
    // let splitAddress = this.displayAddress.split("-");
    // this.city = splitAddress[(splitAddress.length - 4)];
    // this.state = splitAddress[(splitAddress.length - 3)];
    // this.zipcode = splitAddress[(splitAddress.length - 2)];
    let fullAddress = this.uniqueId.split('-');
    fullAddress.shift();
    fullAddress = fullAddress.join(' ');
    this.pc.parseAddressLocalAndPatch(fullAddress, this.clientId).then(
      data => {
        console.log('splitAddressNew : ', data);
        this.state = data.state_code;
        this.city = data.city_name;
        this.zipcode = data.zip;

        console.log('fullAddressNew : ', fullAddress);
        const sbligender = this.gender;// localStorage.getItem('bubble-gender');// == "Male" ? "1" : "0";
        const sblitobacco = localStorage.getItem('bubble-tobacco'); //this.smoker;
        const d = new Date(this.dateOfBirth);
        const year = d.getFullYear();
        console.log('commonInput : ', year, this.age, this.dateOfBirth);
        // let sbliDob = "01/01/" + year;
        const sbliDob = this.dateOfBirth;
        let splitname;
        if (this.fullName) splitname = this.fullName.split(' ');
        // ////debugger

        if (this.selectedCardData.carrierId == 'paclife') {
          this.pc.getPacLifeRiskClass(sblitobacco, this.gender, this.height, this.weight).then(response => {
            console.log('pacLifeRiskClass smoker: ', this.smoker);
            let pacLifeRiskClass = response.data.output;
            const dp = JSON.parse(pacLifeRiskClass);
            console.log('commonInput:pacLifeRiskClass :', dp.dput1);
            pacLifeRiskClass = dp.dput1;
            localStorage.setItem('riskClass', pacLifeRiskClass);
            this.invokeGetQuickQuote2(pacLifeRiskClass, splitname, sbligender, sbliDob, sblitobacco);
          })
            .catch(error => {
              console.log(error);
            });
        }
        if (this.selectedCardData.carrierId == 'sbli' || this.selectedCardData.carrierId == 'wns') {
          this.invokeGetQuickQuote2('', splitname, sbligender, sbliDob, sblitobacco);
        }

      }
    ).catch(error => console.log('error', error));

    const hubSpotData = {
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      gender: localStorage.getItem('bubble-gender'),
      dob_life: localStorage.getItem('bubble-dob'),
      application_stage: 'Quote'
    };
    try {
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    } catch (error) {
      console.error('hubSpotSyncAfterEmailFetch:hubSpotData: ', hubSpotData, ' # error:', error);
    }


    /* // this.beginAppDisabled = false;
    if (localStorage.getItem("appFlowDebug") === "0"){
      this.beginAppDisabled = true;
    } */
  }

  initstyle() {
    // if (this.insurancetype == "homeLife") {
    //   if(document.getElementById("home_ins"))  document.getElementById("home_ins").style.border = "2px solid #ff7007";
    //   document.getElementById("home_ins").style.borderRadius = "10px";
    //   document.getElementById("home_ins_selected").style.display = "block";
    //   document.getElementById("life_ins_selected").style.display = "none";
    // }
    // else if (this.insurancetype == "home") {
    //   if(document.getElementById("home_ins")) document.getElementById("home_ins").style.display = "2px solid #ff7007";
    //   document.getElementById("home_ins").style.borderRadius = "10px";
    //   document.getElementById("home_ins_selected").style.display = "block";
    //   document.getElementById("life_ins").style.display = "none";
    //   document.getElementById("life_ins_selected").style.display = "none";
    // }
    // else {
    //   if(document.getElementById("home_ins")) document.getElementById("home_ins").style.display = "none";
    // //  document.getElementById("home_ins_selected").style.display = "none";
    //   document.getElementById("life_ins").style.border = "2px solid #ff7007";
    //   document.getElementById("life_ins").style.borderRadius = "10px";
    //   document.getElementById("life_ins_selected").style.display = "block";
    // }
  }

  errorMessage: any;
  openModal() {

    this.errorMessage = 'Our Servers are taking too long to respond.';
    console.log(' open modal errorMessage', this.errorMessage);
    document.getElementById('errorServer').style.display = 'block';
    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //     // keyboard: false,
    //     // backdrop: 'static'
    //   });

    // let data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   message: "Our Servers are taking too long to respond please come back later.",
    //   image: 'assets/images/images/education2.png'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   //redirect url
    //   //window.location.href = environment.carrierErrorRedirectURL;
    //   window.location.href = this.pc.envCred.url;
    //   console.log("Modal Close Output: ", result);
    // }, (reason) => {
    //   console.log("Modal Close Reason: ", reason);
    // });
  }




  gotoHome() {

    document.getElementById('errorServer').style.display = 'none';
    window.location.href = this.pc.envCred.url;
  }
  openModalTwo() {

    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
        // keyboard: false,
        // backdrop: 'static'
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: 'Product not available for the input age',
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  tryAgain() {

    document.getElementById('errorServer').style.display = 'none';
    //window.location.href = environment.carrierErrorRedirectURL;
    // window.location.href = this.pc.envCred.url;
    location.reload();
  }



  assignModalContent(img, details) {
    this.modalImg = img;
    this.modalLabel = details.title;
    this.modalText = details.text;
    this.modalText = this.modalText.replace(/\n/g, '<br/>');
  }

  assignModalContent1(details) {
    this.modalLabel = details.title;
    this.modalText = details.text;
    this.modalText = this.modalText.replace(/\n/g, '<br/>');
  }

  showhome() {
    this.selected = 1;
    // document.getElementById("home_ins").style.border = "2px solid #ff7007";
    // document.getElementById("home_ins").style.borderRadius = "10px";
    // document.getElementById("home_ins_selected").style.display = "block";
    // document.getElementById("life_ins").style.border = "none";
    // document.getElementById("life_ins").style.borderRadius = "10px";
    // document.getElementById("life_ins_selected").style.display = "none";
  }

  showlife() {
    this.selected = 2;
    // document.getElementById("home_ins").style.border = "none";
    // document.getElementById("home_ins").style.borderRadius = "10px";
    // document.getElementById("home_ins_selected").style.display = "none";
    // document.getElementById("life_ins").style.border = "2px solid #ff7007";
    // document.getElementById("life_ins").style.borderRadius = "10px";
    // document.getElementById("life_ins_selected").style.display = "block";
  }

  showcustomiseHome() {
    this.$gaService.gtag('event', 'CustomizePlan', {
      'event_category': 'ButtonClicked', 'event_label': 'Customize'
    });
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('customiseHome').style.display = 'block';
    document.getElementById('customiseLife').style.display = 'none';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';


    this.showCustomiseHomeChild = true;

    //this.CustomizeHome.getcustomisevalues();

  }

  showcustomiseLife() {
    this.$gaService.gtag('event', 'CustomizePlan', {
      'event_category': 'ButtonClicked', 'event_label': 'Customize'
    });
    this.gender = localStorage.getItem('bubble-gender');
    console.log('on modal open : ', this.gender);

    // this.gender = this.gender == '1' ? "Male" : "Female";
    this.smoker = localStorage.getItem('bubble-tobacco');
    //this.smoker = this.smoker == '1' ? "1" : "5";
    if (this.smoker != '5') {

      this.ShowYesChecked = true;
      this.ShowNoChecked = false;

      this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');
      this.smoker = '0';
    }
    else {
      this.ShowYesChecked = false;
      this.ShowNoChecked = true;
      this.usageDuration = '1';
    }

    debugger;

    this.dateOfBirth = localStorage.getItem('bubble-dob');
    this.age = localStorage.getItem('bubble-age');
    this.height = localStorage.getItem('bubble-height');
    this.heightFt = localStorage.getItem('bubble-heightFt');
    this.heightIn = localStorage.getItem('bubble-heightIn');
    this.weight = localStorage.getItem('bubble-weight');
    this.termLength = localStorage.getItem('bubble-termLength');
    this.termLength = Number(this.termLength);
    let selectedCarrier = localStorage.getItem('selectedCarrier');
    selectedCarrier = JSON.parse(selectedCarrier);

    this.coverageAmount = selectedCarrier['selectedDetails'].coverage;

    const formatednumber = this.coverageAmount;
    this.coveragetodisaply = formatednumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (this.selectedCardData.carrierId === 'sbli') {
      if (this.coverageAmount > 10000000) {
        this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
      } else if (this.coverageAmount < 25000) {
        this.showlabel = 'Coverage amount cannot be less than $25,000';
      } else {
        this.showlabel = '';
      }
    }
    else {
      if (this.coverageAmount > 10000000) {
        this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
      } else if (this.coverageAmount < 250000) {
        this.showlabel = 'Coverage amount cannot be less than $250,000';
      } else {
        this.showlabel = '';
      }
    }
    console.log('showcustomiseLife gender: ', this.gender);
    document.getElementById('makeblur').style.filter = 'blur(10px)';
    document.getElementById('customiseLife').style.display = 'block';
    document.getElementById('customiseHome').style.display = 'none';
    document.getElementById('makeblur').style.overflowY = 'hidden';
    document.getElementById('makeblur').style.position = 'fixed';
  }


  closeModal() {
    this.coveragetodisaply = localStorage.getItem('bubble-coverage');
    this.gender = localStorage.getItem('bubble-gender');
    console.log('on close : ', this.gender);
    this.smoker = localStorage.getItem('bubble-tobacco');
    if (this.smoker != '5') {
      this.ShowYesChecked = true;
      this.ShowNoChecked = false;
      this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');
      this.smoker = '0';
    }
    else {
      this.ShowYesChecked = false;
      this.ShowNoChecked = true;
      this.usageDuration = '1';
    }
    this.dateOfBirth = localStorage.getItem('bubble-dob');
    this.age = localStorage.getItem('bubble-age');
    this.height = localStorage.getItem('bubble-height');
    this.heightFt = localStorage.getItem('bubble-heightFt');
    this.heightIn = localStorage.getItem('bubble-heightIn');
    this.weight = localStorage.getItem('bubble-weight');
    debugger;
    this.termLength = localStorage.getItem('bubble-termLength');
    debugger;
    this.coverageAmount = localStorage.getItem('bubble-coverage');

    const formatednumber = this.coverageAmount;
    this.coveragetodisaply = formatednumber.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (this.selectedCardData.carrierId === 'sbli') {
      if (this.coverageAmount > 10000000) {
        this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
      } else if (this.coverageAmount < 25000) {
        this.showlabel = 'Coverage amount cannot be less than $25,000';
      } else {
        this.showlabel = '';
      }
    }
    else {
      if (this.coverageAmount > 10000000) {
        this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
      } else if (this.coverageAmount < 250000) {
        this.showlabel = 'Coverage amount cannot be less than $250,000';
      } else {
        this.showlabel = '';
      }
    }
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('customiseHome').style.display = 'none';
    document.getElementById('customiseLife').style.display = 'none';
    document.getElementById('makeblur').style.overflowY = 'auto';
    document.getElementById('makeblur').style.position = 'unset';
    //location.reload();
  }

  previousscreen() {
    if (this.insurancetype == 'homeLife' || this.insurancetype == 'life') {
      location.href = `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
    }
    if (this.insurancetype == 'home') {
      document.getElementById('makeblur').style.filter = 'blur(10px)';
      document.getElementById('confirmHomeBack').style.display = 'block';
      //location.href = `SelectHomeCarrier/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
    }
  }

  closeConfirmHomeBack(type) {
    document.getElementById('makeblur').style.filter = 'none';
    document.getElementById('confirmHomeBack').style.display = 'none';
    if (type == 'proceed') {
      //this.loader = true;
      //location.href = `select-home-carrierv2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      const url = `select-home-carrierv2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      this.router.navigate([url]);
    }
  }

  beginApplication() {
    debugger
    this.loader = true;
    if (this.insurancetype == 'home') {
      const hubSpotData = {
        customer_id: localStorage.getItem('customer_id'),
        email: localStorage.getItem('bubble-email'),
        application_stage: 'Application',
        home_latest_premium_amount: '$' + this.selectedHomeCarrierData['head']['quote_amount']
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      this.$gaService.event('Begin Application', 'event', 'HomeBeginApplication');
      const eventOptionsPart1 = {
        'path': 'selectedCarrier',
        'page_title': 'selectedCarrier', 'url': window.location.href
      };
      this.utilityService.eventWrapped(eventOptionsPart1, 'Begin Application', 'event', 'BeginApplication');
    }
    else {
      const hubSpotData = {
        customer_id: localStorage.getItem('customer_id'),
        email: localStorage.getItem('bubble-email'),
        life_quote_amount: this.selectedCardData['head']['quote_amount'],
        life_latest_premium_amount: this.selectedCardData['head']['quote_amount'],
        application_stage: 'Application',
        // life_insurance_carrier: this.selectedCardData.carrierId == 'sbli' ? 'SBLI' : 'Next by Pacific Life'
      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    document.getElementById('makeblur').style.filter = 'none';

    setTimeout(() => {
      let redirectUrl = '';
      let appflowScreen = '';
      if (this.insurancetype == 'homeLife' || this.insurancetype == 'home') {
        appflowScreen = 'stillwater-app-flow';
        localStorage.setItem('bubble-lifejourneystart', 'false');
        const json = {
          'id': this.clientId,
          'sw_questionNumber': 1,
          'selectedLife': 'paclife'
        };
        // if (this.insurancetype == 'homeLife' && this.selectedCardData.carrierId == 'sbli') {
        //   json['sbli_questionNumber'] = 1;
        //   json['sbli_term'] = localStorage.getItem('bubble-termLength');
        //   json['sbli_coverage'] = localStorage.getItem('bubble-coverage');
        //   json['sbli_appFlowBackButton'] = '0';
        //   json['selectedLife'] = 'sbli';
        // }
        // if (this.insurancetype == 'homeLife' && this.selectedCardData.carrierId == 'wns') {
        //   json['sbli_questionNumber'] = 1;
        //   json['sbli_term'] = this.selectedCardData.selectedDetails.term;
        //   json['sbli_coverage'] = this.selectedCardData.selectedDetails.coverage;
        //   json['sbli_appFlowBackButton'] = '0';
        //   json['selectedLife'] = 'wns';
        // }
        this.pc.saveUpdateClientData(json).then(data => {
        }).catch(error => {
          console.log(error);
        });
        redirectUrl = `HomeAppFlow/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      }


      // if (this.insurancetype == 'life') {
      //   this.$gaService.event('Begin Application', 'event', 'LifeBeginApplication');
      //   appflowScreen = 'app-flow-start';
      //   if (this.selectedCardData.carrierId == 'paclife') {
      //     const pacjson = {
      //       'id': this.clientId,
      //       'selectedLife': 'paclife'
      //     };
      //     this.pc.saveUpdateClientData(pacjson);
      //     redirectUrl = `Appflowstart/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${this.carrierName}`;
      //   }
      //   if (this.selectedCardData.carrierId == 'sbli') {
      //     const sblijson = {
      //       'id': this.clientId,
      //       'sbli_questionNumber': 1,
      //       'sbli_term': localStorage.getItem('bubble-termLength'),
      //       'sbli_coverage': localStorage.getItem('bubble-coverage'),
      //       'sbli_appFlowBackButton': '0',
      //       'selectedLife': 'sbli'
      //     };
      //     this.pc.saveUpdateClientData(sblijson).then(data => {
      //     }).catch(error => {
      //       console.log(error);
      //     });

      //     redirectUrl = `lifeCarrierTwo/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      //   }
      //   if (this.selectedCardData.carrierId == 'wns') {
      //     const wnsjson = {
      //       'id': this.clientId,
      //       'sbli_questionNumber': 1,
      //       'sbli_term': this.selectedCardData.selectedDetails.term,
      //       'sbli_coverage': this.selectedCardData.selectedDetails.coverage,
      //       'sbli_appFlowBackButton': '0',
      //       'selectedLife': 'wns'
      //     };
      //     this.pc.saveUpdateClientData(wnsjson).then(data => {
      //     }).catch(error => {
      //       console.log(error);
      //     });

      //     redirectUrl = `lifeCarrierThree/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      //   }

      // }

      const updateJson = {
        otherAppflowScreen: appflowScreen,
        resumeUrl: redirectUrl
      };
      this.checkforResumeInit(updateJson, 'update').then(
        data => {
          location.href = redirectUrl;
        }
      ).catch(error => console.log(error));
      const eventOptionsPart1 = {
        'path': 'selectedCarrier',
        'page_title': 'selectedCarrier', 'url': window.location.href
      };
      this.utilityService.eventWrapped(eventOptionsPart1, 'Begin Application', 'event', 'BeginApplication');
    }, 3000);

  }


  showlabel: any;
  formatCoverage() {
    debugger;
    this.coveragetodisaply = this.coveragetodisaply.replace(/,/g, '');

    this.coverageAmount = Number(this.coveragetodisaply);
    // localStorage.setItem('bubble-coverage', this.coverageAmount);
    const ctd = this.coveragetodisaply;
    this.coveragetodisaply = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    if (this.selectedCardData.carrierId === 'sbli') {
      if (this.coverageAmount > 1000000) {
        this.showlabel = 'Coverage amount cannot be greater than $1,000,000';
      } else if (this.coverageAmount < 25000) {
        this.showlabel = 'Coverage amount cannot be less than $25,000';
      } else {
        this.showlabel = '';
      }
    }
    else {
      if (this.coverageAmount > 10000000) {
        this.showlabel = 'Coverage amount cannot be greater than $10,000,000';
      } else if (this.coverageAmount < 250000) {
        this.showlabel = 'Coverage amount cannot be less than $250,000';
      } else {
        this.showlabel = '';
      }
    }




  }


  verifyFt() {
    // if (Number(this.heightFt)>6) {
    //   this.heightFt = '6';
    // }
    // if (Number(this.heightFt)<4) {
    //   this.heightFt = '4';
    // }
  }

  verifyInch() {
    debugger;
    if (Number(this.heightIn) > 11) {
      this.heightIn = '11';
    }
    if (Number(this.heightIn) < 0) {
      this.heightIn = '1';
    }
  }

  verifyweight() {
    if (Number(this.weight) > 999) {
      this.heightIn = '150';
    }
    if (Number(this.heightIn) == 0) {
      this.heightIn = '150';
    }
    if (Number(this.heightIn) == null || Number(this.heightIn) == undefined) {
      this.heightIn = '150';
    }
  }

  public genderClick(id) {
    debugger;
    this.gender = id;
    console.log('genderClick', this.gender);
  }
  public smokerClick(id) {
    this.smoker = id;
  }
  public getUsageDuration(id) {
    this.usageDuration = id;
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }
  public closeCustomizeModal(even) {
    if (even.save == 1) {
      // location.reload();
      this.getValues();
      document.getElementById('customiseHome').style.display = 'none';
      document.getElementById('makeblur').style.filter = 'none';
      document.getElementById('makeblur').style.overflowY = 'auto';
      document.getElementById('makeblur').style.position = 'unset';
    }
    else {
      document.getElementById('customiseHome').style.display = 'none';
      document.getElementById('makeblur').style.filter = 'none';
      document.getElementById('makeblur').style.overflowY = 'auto';
      document.getElementById('makeblur').style.position = 'unset';
    }
    this.showCustomiseHomeChild = false;
  }

  openRiskClass() {
    this.pageToOpen = 'riskPage';
    this.showAssumptions = true;
  }

  openAssumptions() {
    this.pageToOpen = 'assumptions';
    this.showAssumptions = true;
  }

  closeAssumption() {
    debugger;
    this.showAssumptions = false;
    this.pageToOpen = '';
  }

  getTermlength(even) {
    this.termLength = even;
  }
}
