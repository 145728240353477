import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { QuestionModel } from './models/question.model';
import { AnswerModel } from './models/answer.model';
import { AnswerReturnModel } from './models/answer.return.model';

import { QuestionService } from './services/question.service';

import _, { forEach } from 'lodash';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { TrackerService } from 'src/app/shared/tracker.service';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent implements OnInit {

  readonly DEFAULT_PAGE_INDEX: number = 0;

  questionList!: QuestionModel[];
  questionListGroupedListKey!: string[];
  questionListGroupedList!: _.Dictionary<QuestionModel[]>;
  currentGroupIndex!: number;
  currentGroupedList!: QuestionModel[];
  answerReturn!: Array<AnswerReturnModel>;
  backButtonColor!: string;
  sblihubspotvalues = ['SBLI_Eligibility', 'SBLI_Income', 'SBLI_PreviousInsurance', 'SBLI_Health', 'SBLI_Disability&Diseases', 'SBLI_Cancer&Surgery', 'SBLI_Diseases', 'SBLI_HIV&Hospitalization', 'SBLI_RiskActivity', 'SBLI_Crime', 'SBLI_Speeding'];
  wnshubspotvalues = ['W&S_pre-qual_set1', 'W&S_pre-qual_set2', 'W&S_pre-qual_set3', 'W&S_PreviousInsurance', 'WNS_Income', 'W&S_RiskActivity', 'W&S_Crime', 'W&S_Speeding', 'W&S_Stroke', 'W&S_Cancer', 'W&S_Diseases', 'W&S_Surgery', 'W&S_Covid', 'W&S_AIDS', 'W&S_VerifyDetails', 'W&S_SSN', 'W&S_Beneficiary', 'W&S_ConsentScreen', 'W&S_FinalConfirmation', 'W&S_Payment', 'W&S_ApplicationReview', 'W&S_PolicyIssuance'];
  boproperties = ['nationality', 'prev_insurance', 'employment_income', 'risk_activity', 'crime', 'speeding', 'stroke', 'cancer', 'health_q_group', 'diseases', 'surgery', 'covid', 'aids'];
  dqForm!: FormGroup;
  loader: boolean;
  carrier: any;
  totalPages: any;
  showNumber: any;
  knockout: boolean;
  clientId: any;

  constructor(
    private questionService: QuestionService,
    private formBuilder: FormBuilder,
    private router: Router,
    private pc: ProcessCacheService, private tracker: TrackerService, public scroller: ViewportScroller) {
    this.loader = true;
  }

  async onNext() {
    debugger;
    this.loader = true;
    const saveResponse = await this.pc.getAppData(this.clientId);
    const dataFrom = JSON.parse(saveResponse.Payload);
    this.totalPages = (this.carrier === 'WNS') ? 22 : 21;
    this.knockout = false;
    const elmnt = document.getElementById('scroll-top');
    elmnt.scrollIntoView({ behavior: 'smooth' });
    let curpage = this.dqForm.value.currentPage;
    this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++curpage });
    localStorage.setItem('dq_page_' + this.currentGroupIndex, JSON.stringify(this.dqForm.value));
    let dynamicjson = {}
    if (dataFrom.body.dynamic_questions) {
      dynamicjson = dataFrom.body.dynamic_questions;
    }
    let dqcolumn = `dq_page_${this.currentGroupIndex}`
    if ('question_1260' in this.dqForm.value === true) {
      const json = {
        'id': this.clientId,
        'USCitizen': this.dqForm.value.question_1260['selected_answer'] == 'Yes' ? true : false
      };
      this.pc.saveUpdateClientData(json);
    }
    dynamicjson[dqcolumn] = JSON.stringify(this.dqForm.value);
    if (this.currentGroupIndex <= 2 && this.carrier === 'WNS') {
      this.questionService.prequelquestions = await this.pushjsonarry();
      this.knockout = await this.checkknockout();
    }
    this.answerjson = {};
    let qns_awns;
    // if (this.carrier === 'WNS') {

    // }
    qns_awns = await this.prepareanswer(this.dqForm.value);
    if (this.dqForm.value.currentPage < this.dqForm.value.totalPages && !this.knockout) {
      this.currentGroupIndex = this.dqForm.value.currentPageIndex + 1;
      localStorage.setItem('currentGroupIndex', this.currentGroupIndex.toString());
      if (this.carrier === 'WNS') {
        await this.updateHubspot(this.wnshubspotvalues, 1, qns_awns);
      }
      else {
        await this.updateHubspot(this.sblihubspotvalues, 1, qns_awns);
      }
      this._FormBuilder();
    }
    else if (this.knockout) {
      debugger;
      localStorage.setItem('recopageheadtext', 'Based on your answers, we have narrowed down your recommendations to these policies for');
      const clientId = localStorage.getItem('clientIdDQ');
      const json = {
        id: clientId,
        makewnscall: 'false',
        makesblicall: 'false'
      };
      await this.pc.saveUpdateClientData(json);
      const hubSpotData = {
        email: localStorage.getItem('bubble-email'),
        application_stage: 'Pre-qual knockout',
        life_flow___user_response: JSON.stringify(qns_awns)
      };
      await this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      this.router.navigateByUrl(localStorage.getItem('recopageurl'));
    }
    else {
      const that = this;
      that.answerReturn = new Array<AnswerReturnModel>();
      Object.keys(localStorage).forEach(function (key) {
        let page_json: any;
        if (key.match('^dq_page')) {
          const _page_json = localStorage.getItem(key);
          if (_page_json != null) {
            page_json = JSON.parse(_page_json);
            that._getJson(page_json);
          }
        }
      });

      console.log('answer json -> ', that.answerReturn, JSON.stringify(that.answerReturn));
      const uniqueId = localStorage.getItem('uniqueIdDQ');
      const clientId = localStorage.getItem('clientIdDQ');
      const displayAddress = localStorage.getItem('displayAddressDQ');
      const insuranceType = localStorage.getItem('insuranceTypeDQ');
      let carrier;
      if (this.carrier === 'WNS') {
        carrier = 'wns_questionNumber';
        this.updateHubspot(this.wnshubspotvalues, 1, qns_awns);
      }
      else {
        carrier = 'sbli_questionNumber';
        this.updateHubspot(this.sblihubspotvalues, 1, qns_awns);
      }

      const json = {
        id: clientId,
        answerJSON: this.answerReturn.filter(x => x.questionId != 1000000 && x.questionId != 2000000),
        sbli_appFlowBackButton: '1',
        [carrier]: 1,
      };
      await this.pc.saveUpdateClientData(json);
      let redirectUrl;
      if (this.carrier === 'WNS') {
        redirectUrl = `lifeCarrierThree/${uniqueId}/${clientId}/${displayAddress}/${insuranceType}`;
      } else {
        redirectUrl = `lifeCarrierTwo/${uniqueId}/${clientId}/${displayAddress}/${insuranceType}`;
      }
      this.router.navigate([redirectUrl]);
    }
    setTimeout(() => {
      this.loader = false;
    }, 600);
    const updateJson = {
      questionSequence: this.currentGroupIndex,
      resumeUrl: this.router.url
    };
    this.checkforResumeInit(updateJson, 'update');
    let carrier;
    if (this.carrier === 'WNS') {
      carrier = 'wns_questionNumber';
    }
    else {
      carrier = 'sbli_questionNumber';
    }
    const json = {
      'id': localStorage.getItem('clientIdDQ'),
      dynamic_questions: dynamicjson,
      bubble_annualIncome: localStorage.getItem('bubble-annualIncome')
    };
    if (this.dqForm.value.currentPage < this.dqForm.value.totalPages && !this.knockout) {
      json[carrier] = this.currentGroupIndex
    }
    await this.pc.saveUpdateClientData(json);
  }

  private _getJson(pageJson: any, isChlid = false) {

    const that = this;
    Object.keys(pageJson).forEach(function (jsKey) {
      const __json = pageJson[jsKey] as JSON;
      const _json_object = that._getJsonObject(__json, jsKey);
      if (_json_object != null) {
        // console.log('json keys -> ', _json_object);
        if (!isChlid && (_json_object as any)['answer_category'] == 3) {
          that._getJsonAnswerCategory3(_json_object, (_json_object as any)['question_id'] as number);
          return;
        } else {
          that.makeAnswerJson(_json_object);
          that._getJson(_json_object, true);
        }
      }
    });
  }

  private _getJsonAnswerCategory3(pageJson: any, questionId: number, isChlid = false) {

    const that = this;
    Object.keys(pageJson).forEach(function (jsKey) {
      const __json = pageJson[jsKey] as JSON;
      const _json_object = that._getJsonObject(__json, jsKey);
      if (_json_object != null) {
        that.makeAnswerJson(_json_object, questionId);
        that._getJsonAnswerCategory3(_json_object, questionId, true);
      }
    });
  }

  private _getJsonObject(pageJson: JSON, jsonKey: string): JSON | null {
    console.log('page json -> ', pageJson);

    if (pageJson != null
      && Object.keys(pageJson).length > 0
      && jsonKey.match('^question')
      && ((Object.keys(pageJson).filter(x => x.match('^answer_value_')).length > 0)
        || (pageJson as any)['answer_category'] == 3)
    )
      return pageJson;

    return null;
  }

  private makeAnswerJson(pageJson: any, questionId: number | undefined = undefined) {

    const _question_id: number = pageJson['question_id'] as number;
    const _answer_return: AnswerReturnModel = new AnswerReturnModel(_question_id);
    _answer_return.answerValue = pageJson['answer_value_' + _question_id];
    if (_answer_return.answerValue != undefined && _answer_return.answerValue != null) {
      _answer_return.answerOptionId = pageJson['answer_option_id_' + _question_id];
      if (_answer_return.answerValue == _answer_return.answerOptionId)
        _answer_return.answerValue = '1';
      if (_answer_return.answerValue == true)
        _answer_return.answerValue = '1';

      if (questionId != undefined)
        _answer_return.questionId = questionId as number;
      if (_answer_return.questionId == 1030 || _answer_return.questionId == 1980) {
        const answer_value = _answer_return.answerValue.toString();
        if (answer_value.includes(',')) {
          _answer_return.answerValue = answer_value.replace(/,/g, '');
        }
      }
      this.answerReturn.push(_answer_return);
    }
  }
  async onPrevious() {
    debugger;
    this.loader = true;
    const saveResponse = await this.pc.getAppData(this.clientId);
    const dataFrom = JSON.parse(saveResponse.Payload);
    this.totalPages = (this.carrier === 'WNS') ? 22 : 21;
    const elmnt = document.getElementById('scroll-top');
    elmnt.scrollIntoView({ behavior: 'smooth' });
    const curpage = this.dqForm.value.currentPage - 1;
    this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: curpage });
    if (this.dqForm.value.currentPage > 1) {
      this.currentGroupIndex = this.dqForm.value.currentPageIndex - 1;
      localStorage.setItem('currentGroupIndex', this.currentGroupIndex.toString());
      if (this.carrier === 'WNS') {
        this.updateHubspot(this.wnshubspotvalues, 2);
      }
      else {
        this.updateHubspot(this.sblihubspotvalues, 2);
      }

      this._FormBuilder();
    }
    else {
      const uniqueId = localStorage.getItem('uniqueIdDQ');
      const clientId = localStorage.getItem('clientIdDQ');
      const displayAddress = localStorage.getItem('displayAddressDQ');
      const insuranceType = localStorage.getItem('insuranceTypeDQ');
      let carrier;
      if (this.carrier === 'WNS') {
        carrier = 'wns_questionNumber';
      }
      else {
        carrier = 'sbli_questionNumber';
      }
      const json = {
        id: clientId,
        [carrier]: 1,
      };
      await this.pc.saveUpdateClientData(json);
      if (this.carrier === 'WNS') {
        this.updateHubspot(this.wnshubspotvalues, 2);
      }
      else {
        this.updateHubspot(this.sblihubspotvalues, 2);
      }

      let redirectUrl;
      if (this.carrier === 'WNS') {
        redirectUrl = `lifeCarrierThree/${uniqueId}/${clientId}/${displayAddress}/${insuranceType}`;
      } else {
        redirectUrl = `lifeCarrierTwo/${uniqueId}/${clientId}/${displayAddress}/${insuranceType}`;
      }
      this.router.navigate([redirectUrl]);
    }
    // let dqcolumn = `dq_page_${this.currentGroupIndex + 1}`
    const updateJson = {
      questionSequence: this.currentGroupIndex,
      resumeUrl: this.router.url,
    };
    this.checkforResumeInit(updateJson, 'update');
    // let dynamicjson
    // if (dataFrom.body.dynamic_questions) {
    //   dynamicjson = dataFrom.body.dynamic_questions;
    // }
    // dynamicjson[dqcolumn] = JSON.stringify(this.dqForm.value);
    // const json = {
    //   'id': localStorage.getItem('clientIdDQ'),
    //   dynamic_questions: dynamicjson
    // };
    // await this.pc.saveUpdateClientData(json);
    setTimeout(() => {
      this.loader = false;
    }, 600);
  }

  ngOnInit(): void {
    debugger;
    this.carrier = localStorage.getItem('carrierDQ');
    this.clientId = localStorage.getItem('clientIdDQ');
    this.totalPages = (this.carrier === 'WNS') ? 22 : 21;
    const curpage = localStorage.getItem('currentGroupIndex') ? Number(localStorage.getItem('currentGroupIndex')) + 1 : this.dqForm.value.currentPage + 1;
    this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: curpage });
    this.loader = true;
    (async () => {
      const data = await this.questionService.getData();
      this.questionList = data;
      console.log('question list', this.questionList);
      this.questionListGroupedList = _.groupBy(this.questionList, (question: any) => question.group);
      this.questionListGroupedListKey = Object.keys(this.questionListGroupedList);
      const currentpage = localStorage.getItem('currentGroupIndex');
      if (currentpage == null) {
        this.currentGroupIndex = this.DEFAULT_PAGE_INDEX;
      }
      else {
        this.currentGroupIndex = Number(currentpage);
      }
      if (this.carrier === 'WNS') {
        await this.updateHubspot(this.wnshubspotvalues, 1);
      }
      else {
        await this.updateHubspot(this.sblihubspotvalues, 1);
      }
      const updateJson = {
        questionSequence: this.currentGroupIndex,
        resumeUrl: this.router.url
      };
      this.checkforResumeInit(updateJson, 'update');
      this._FormBuilder();
      this.loader = false;
    })();
  }

  private _FormBuilder() {
    debugger
    this.backButtonColor = (this.currentGroupIndex + 1) == 1 ? '#40454C' : '#FF7007';
    const _group_key = this.questionListGroupedListKey[this.currentGroupIndex];
    this.currentGroupedList = this.questionListGroupedList[_group_key];
    this.currentGroupedList = _.sortBy(this.currentGroupedList, (ord: any) => ord.question_sequence_number);
    const _form_group: any = {};
    let _savedForm: any = {};
    _savedForm = localStorage.getItem('dq_page_' + this.currentGroupIndex);
    if (_savedForm != null)
      _savedForm = JSON.parse(_savedForm);
    this.currentGroupedList.map((question: QuestionModel) => {
      _form_group['question_' + question.questionID] = this._FormGroup(question, _savedForm, false);
    });
    this.dqForm = this.formBuilder.group(_form_group);
    this.dqForm.addControl('currentPage', new FormControl(this.currentGroupIndex + 1, null));
    this.dqForm.addControl('currentPageIndex', new FormControl(this.currentGroupIndex, null));
    this.dqForm.addControl('currentPageKey', new FormControl(_group_key, null));
    this.dqForm.addControl('totalPages', new FormControl(this.questionListGroupedListKey.length, null));
  }

  private _FormGroup(question: QuestionModel, savedForm: any, isChild: boolean): FormGroup {
    debugger
    const _question_control: any = {};
    let _savedValue: any = null; let _savedOptionId: any = null; let _childSavedForm: any = null;

    if (savedForm != null && savedForm['question_' + question.questionID] != null) {
      _childSavedForm = savedForm['question_' + question.questionID];
      _savedValue = _childSavedForm['answer_value_' + question.questionID];
      _savedOptionId = _childSavedForm['answer_option_id_' + question.questionID];
    }

    _question_control['question_id'] = new FormControl(question.questionID, null);
    _question_control['answer_option_id_' + question.questionID] = new FormControl(_savedOptionId, null);
    _question_control['answer_category'] = new FormControl(question.answer_category, null);
    _question_control['applicable_parent_answer'] = new FormControl(question.applicable_parent_answer, null);
    _question_control['display_in'] = new FormControl(question.display_in, null);
    _question_control['question_text'] = new FormControl(question.question_text, null);
    _question_control['selected_answer'] = new FormControl('');

    if (question?.required == 1) {
      _question_control['answer_value_' + question.questionID] = new FormControl(_savedValue, [Validators.required]);
    } else {
      _question_control['answer_value_' + question.questionID] = new FormControl(_savedValue, null);
    }

    _question_control['old_answer_value'] = new FormControl(_savedValue, null);

    if ((question.answer_category == 1 || question.answer_category == 2) && (question.child?.length != undefined && question.child?.length > 0)) {
      question.child.map((childQuestion) => {
        _question_control['question_' + childQuestion.questionID] = this._FormGroup(childQuestion, _childSavedForm, true);
      });
    }
    if (question.answer_category == 3 && question.answers?.length != undefined && question.answers.length > 0) {
      question.answers.map((answer: AnswerModel) => {
        _question_control['question_' + answer.answer_option_id] = this._AnswerFormGroup(answer, _childSavedForm, false, question?.questionID);
      });
    }
    return new FormGroup(_question_control);
  }

  private _AnswerFormGroup(answer: AnswerModel, savedForm: any, isChild: boolean, parentQuestionId: number | undefined): FormGroup {

    const _question_control: any = {};
    let _savedValue: any = null; let _savedOptionId: any = null; let _childSavedForm: any = null;

    if (savedForm != null && savedForm['question_' + answer.answer_option_id] != null) {
      _childSavedForm = savedForm['question_' + answer.answer_option_id];
      _savedValue = _childSavedForm['answer_value_' + answer.answer_option_id];
      _savedOptionId = _childSavedForm['answer_option_id_' + answer.answer_option_id];
    }

    _question_control['super_parent_id'] = new FormControl(parentQuestionId, null);
    _question_control['question_id'] = new FormControl(answer.answer_option_id, null);
    _question_control['answer_option_id_' + answer.answer_option_id] = new FormControl(answer.answer_option_id, null);
    _question_control['answer_category'] = new FormControl(answer.answer_category, null);

    _question_control['answer_value_' + answer.answer_option_id] = new FormControl(_savedValue, null);
    _question_control['old_answer_value'] = new FormControl(_savedValue, null);
    _question_control['applicable_parent_answer'] = new FormControl(answer.applicable_parent_answer, null);


    if (answer.child?.length != undefined && answer.child?.length > 0) {
      _question_control['has_child'] = new FormControl(true, null);
      answer.child.map((childAnswer: AnswerModel) => {
        _question_control['question_' + childAnswer.answer_option_id] = this._AnswerFormGroup(childAnswer, _childSavedForm, true, parentQuestionId);
      });
    }
    return new FormGroup(_question_control);
  }

  // private _setFormValue(pageJson: any) {
  //   let that = this;
  //   Object.keys(pageJson).forEach(function (jsKey) {
  //     let __json = pageJson[jsKey] as JSON;
  //     let _json_object = that._getJsonObject(__json, jsKey);
  //     if (_json_object != null) {
  //       // console.log('json keys -> ', jsKey, _json_object);
  //       that.makeAnswerJson(_json_object);
  //       that._getJson(_json_object);
  //     }
  //   });
  // }
  public async updateHubspot(hubspotvalues, type, qns_awns = null) {
    debugger;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: hubspotvalues[(type == 2) ? this.currentGroupIndex : this.currentGroupIndex]
    };
    if (qns_awns != null) {
      hubSpotData['life_flow___user_response'] = JSON.stringify(qns_awns);
    }
    await this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    const boprty = this.boproperties[this.currentGroupIndex];
    this.pc.lifesummary({ [boprty]: 'yes' });
  }


  // ngAfterViewInit(): void {

  //   document.body.addEventListener('mouseleave', (e) => { this.exitPopup(e); });
  // }

  exitPopup(e) {
    if (sessionStorage.getItem('showNumber')) {
      this.showNumber = sessionStorage.getItem('showNumber');
    }
    else {
      this.showNumber = 0;
    }
    if (Number(this.showNumber) == 0 && e.clientY <= 0 && !this.loader) {
      document.getElementById('exiterrorModalsbli').style.display = 'block';
    }
  }

  closePopup() {

    document.getElementById('exiterrorModalsbli').style.display = 'none';
    this.showNumber = 1;
    sessionStorage.setItem('showNumber', this.showNumber);
  }

  public async pushjsonarry() {
    debugger;
    this.questionService.prequelquestions = [];
    if (this.carrier === 'WNS') {
      if (this.currentGroupIndex == 0) {
        this.questionService.prequelquestions.push(JSON.parse(localStorage.getItem('dq_page_0')).question_2000000, JSON.parse(localStorage.getItem('dq_page_0')).question_1011, JSON.parse(localStorage.getItem('dq_page_0')).question_1260);
      }
      else if (this.currentGroupIndex == 1) {
        this.questionService.prequelquestions.push(JSON.parse(localStorage.getItem('dq_page_1')).question_1240, JSON.parse(localStorage.getItem('dq_page_1')).question_1270);
      }
      else {
        this.questionService.prequelquestions.push(JSON.parse(localStorage.getItem('dq_page_2')).question_1680, JSON.parse(localStorage.getItem('dq_page_2')).question_1000000);
      }

    }
    else {
      if (this.currentGroupIndex == 0) {
        this.questionService.prequelquestions.push(
          JSON.parse(localStorage.getItem('dq_page_0')).question_2000000, JSON.parse(localStorage.getItem('dq_page_0')).question_1011, JSON.parse(localStorage.getItem('dq_page_0')).question_1260);
      }
      else if (this.currentGroupIndex == 1) {
        this.questionService.prequelquestions.push(JSON.parse(localStorage.getItem('dq_page_1')).question_1240, JSON.parse(localStorage.getItem('dq_page_1')).question_1270);
      }
      else {
        this.questionService.prequelquestions.push(JSON.parse(localStorage.getItem('dq_page_2')).question_1680, JSON.parse(localStorage.getItem('dq_page_2')).question_1000000);
      }
    }
    this.questionService.prequelquestions.filter(elements => { return elements !== null; });
    this.questionService.prequelquestions.filter(elements => { return elements !== undefined; });
    return this.questionService.prequelquestions;

  }

  public async checkknockout() {
    debugger;
    for (let i = 0; i < this.questionService.prequelquestions.length; i++) {
      const column = `answer_value_${this.questionService.prequelquestions[i].question_id}`;
      if (this.questionService.prequelquestions[i].question_id == 1260) {
        if (this.questionService.prequelquestions[i][column] == '0') {
          this.knockout = true;
        }

      }
      else if (this.questionService.prequelquestions[i].question_id == 1000000) {
        if (!this.questionService.prequelquestions[i].question_10000008['answer_value_10000008']) {
          this.knockout = true;
        }

      }
      else if (this.questionService.prequelquestions[i].question_id == 2000000) {
        if (this.questionService.prequelquestions[i][column] === '1') {
          this.knockout = true;
        }

      }
      else {
        if (this.carrier === 'WNS' && column === 'answer_value_1020' && this.questionService.prequelquestions[i][column] == '1') {
          if (this.questionService.prequelquestions[i].question_1120 !== undefined) {
            if (this.questionService.prequelquestions[i].question_1120['answer_value_1120'] != '0') {
              this.knockout = true;
            }
          }
        }
        else {
          if (this.questionService.prequelquestions[i][column] == '1') {
            this.knockout = true;
          }
        }
      }

    }
    return this.knockout;
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }
  public answerjson = {};
  public async prepareanswer(dqcolumn) {
    debugger
    for (const item in dqcolumn) {
      if (item.startsWith('question_') && dqcolumn[item].question_text && item != 'question_id' && item != 'question_text') {
        let dqcms = `answer_value_${dqcolumn[item].question_id}`;
        this.answerjson[dqcolumn[item].question_text] = dqcolumn[item].selected_answer ? dqcolumn[item].selected_answer : dqcolumn[item][dqcms] === '1' ? 'Yes' : dqcolumn[item][dqcms] === '0' ? 'No' : dqcolumn[item][dqcms];
        for (let sub in dqcolumn[item]) {
          if (sub.startsWith('question_') && sub != 'question_id' && sub != 'question_text' && dqcolumn[item].answer_category != 3) {
            if (dqcolumn[item][sub].selected_answer != '') {
              await this.preparesubanswer(dqcolumn[item][sub])
            }

          }
        }
      }
    }
    return this.answerjson;
  }

  public preparesubanswer(dqcolumn) {
    for (const sub in dqcolumn) {
      let cms = `answer_value_${dqcolumn.question_id}`
      if (dqcolumn.selected_answer || dqcolumn[cms]) {
        this.answerjson[dqcolumn.question_text] = dqcolumn.selected_answer ? dqcolumn.selected_answer : dqcolumn[cms] == '1' ? 'Yes' : 'No';
        if (sub.startsWith('question_') && sub != 'question_id' && sub != 'question_text') {
          this.preparesubanswer(dqcolumn[sub])
        }
      }


    }
    return this.answerjson;
  }
}
