import { Component, OnDestroy, OnInit,EventEmitter,Output,  ViewChild } from '@angular/core';

@Component({
  selector: 'app-question4',
  templateUrl: './question4.component.html',
  styleUrls: ['./question4.component.css']
})
export class Question4Component implements OnInit {

  @Output() nextquestion4 = new EventEmitter();
  maleicon: string;
  femaleicon: string;
  genderSelected: string;
  constructor() { }

  ngOnInit(): void {
    if(localStorage.getItem('bubble-gender') !== null) {
      this.genderSelected = localStorage.getItem('bubble-gender');
    }
    this.maleicon="assets/images/images/icon-male.png";
    this.femaleicon="assets/images/images/ic-female.png";
    if (this.genderSelected==undefined||this.genderSelected==null||this.genderSelected=="undefined") {
      this.genderSelected = "1";
    }
    if(this.genderSelected=='1'){
      localStorage.setItem('bubble-gender',"1");
      document.getElementById("male_1").className="gender active";
      document.getElementById("female_1").className="gender";
      this.maleicon="assets/images/active/icon-male-active.svg";
      this.femaleicon="assets/images/images/ic-female.png";
    }
    else{
      localStorage.setItem('bubble-gender',"0");
      document.getElementById("male_1").className="gender";
      document.getElementById("female_1").className="gender active";
      this.maleicon="assets/images/images/icon-male.png";
      this.femaleicon="assets/images/active/icon-female-active.svg";
    }
  }

  next() {
    let etty = {
      "questionNo": "4",
      "genderSelected": this.genderSelected,
      "nextprev": 1
    }
    this.nextquestion4.emit(etty);
  }

  previous() {
   let etty = {
      "questionNo": "4",
      "genderSelected": this.genderSelected,
      "nextprev": 0
    }
    this.nextquestion4.emit(etty);
  }

  gender(gen){
    if(gen=='1'){
      localStorage.setItem('bubble-gender',"1");
      document.getElementById("male_1").className="gender active";
      document.getElementById("female_1").className="gender";
      this.maleicon="assets/images/active/icon-male-active.svg";
      this.femaleicon="assets/images/images/ic-female.png";
    }
    else{
      localStorage.setItem('bubble-gender',"0");
      document.getElementById("male_1").className="gender";
      document.getElementById("female_1").className="gender active";
      this.maleicon="assets/images/images/icon-male.png";
      this.femaleicon="assets/images/active/icon-female-active.svg";
    }
  }

}
