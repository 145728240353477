import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const dataQuery = gql`query( $page_name: String! ){
  pageData(where: { page_name: $page_name }){
    id
    attribute_1_value
    attribute_2_value
    column
    content
    attribute_1_key
    attribute_2_key
    style_category
    data_category
  }
}`;

@Component({
  selector: 'app-consent-terms-page',
  templateUrl: './consent-terms-page.component.html',
  styleUrls: ['./consent-terms-page.component.css']
})
export class ConsentTermsPageComponent implements OnInit {

  consentTermsPage = 'consent_terms_page';
  pageFormattedData: any;
  section_1 : any;
  section_2 : any;
  section_3 : any;
  section_4 : any;
  @Output() closeTermsPage = new EventEmitter<string>();

  constructor(private apollo: Apollo) { }

  ngOnInit(): void {
    this.getPageContent()
  }

  getPageContent() {
    this.apollo.watchQuery<any>({
      query: dataQuery,
      variables: {
        page_name: this.consentTermsPage,
      }
    }).valueChanges
      .subscribe(
        ({ data }) => {
          let { pageData } = data;
          let allData = pageData.map(e => {
            return {
              content: e.content,
              section: e.data_category,
              style: e.style_category,
              column: e.column,
              src: e.attribute_1_value,
            }
          })
          
          let sectionArray = new Set();
          allData.map((e: any) => sectionArray.add(e.section))
          let formattedData = this.getFormattedData(allData, [...sectionArray])
          console.log(formattedData)
          this.section_2 = []
          this.section_3 = []
          Object.keys(formattedData.section_2).forEach((element) => {
            this.section_2.push(formattedData.section_2[element])
          });
          Object.keys(formattedData.section_3.column_1).forEach((element) => {
            console.log(element)
            if(`${element}`.includes('list_item')){
              this.section_3.push(formattedData.section_3.column_1[element])
            }
          });
          console.log(this.section_3)
        },
        (error) => {
          console.log(error);
        });
  }


  close(){
    this.closeTermsPage.emit()
  }
  
  getFormattedData(data: Array<any>, sectionsArray: Array<any>) {
    let output: any = {};
    let section: any;
    let column: any;
    sectionsArray.forEach(thisSection => {
      output[thisSection] = {};
      section = data.filter((item) => item.section == thisSection);
      for (let col = 1; col < 5; col++) {
        output[thisSection]['column_' + col] = {};
        column = section.filter((item) => item.column == col);
        for (let j = 0; j < column.length; j++) {
          output[thisSection]['column_' + col][column[j].style] = column[j];
        }
      }
    })

    this.pageFormattedData = output;
    return output;
  }

}