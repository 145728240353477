import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli01801220',
  templateUrl: './sbli01801220.component.html',
  styleUrls: ['./sbli01801220.component.css']
})
export class Sbli01801220Component implements OnInit {
  questions: any;
  isDisable: boolean;
  isCancer: any;
  isCarcinoma: string;
  clientId: any;
  existingData: any;
  @Output() changeQuestion = new EventEmitter();
  showLoader = true;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');
    this.isDisable = true;
    //  this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli01801220")[0];
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);


      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli01801220')[0];
        console.log('app-sbli01801220 questions : ', this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli01801220')[0];
        console.log('app-sbli01801220 questions : ', this.questions);
      }
      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_diagnosed_or_tratment_cancer != undefined) {
        this.isCancer = this.existingData.sbli_diagnosed_or_tratment_cancer;
        if (this.isCancer == 'Yes') {
          this.diagnosedCancer('Yes');
          this.isCarcinoma = this.existingData.sbli_carcinoma_skin;
          if (this.isCarcinoma == 'Yes') {
            this.skinCarcinoma('Yes');
          } else {
            this.isDisable = false;
          }
        } else {

          this.isDisable = false;
        }

      } else {
        this.isDisable = true;
      }



      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    console.log('app-sbli01801220 questions', this.questions);
  }


  diagnosedCancer(input) {
    this.isCancer = input;
    this.isCarcinoma = '';
    if (this.isCancer != '' && this.isCancer == this.questions.radio_options[1].answer_text) {
      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
  }


  skinCarcinoma(input) {
    debugger;
    this.isCarcinoma = input;
    if (this.isCarcinoma != '') {
      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
  }

  next() {
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    this.$gaService.event('SBLI LifeApplication Cancer', 'event','SBLI-LifeApplication-Cancer');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Cancer',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions,'SBLI LifeApplication Cancer', 'event','SBLI-LifeApplication-Cancer');
    json.SBLI[this.questions.radio_options[0].answer_key] = this.isCancer;
    json.SBLI[this.questions.radio_options_1[0].answer_key] = this.isCarcinoma;

    for (const propName in json.SBLI) {
      if (json.SBLI[propName] === null || json.SBLI[propName] === undefined || json.SBLI[propName] === '') {
        delete json.SBLI[propName];
      }
    }



    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

}
