import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormGroupDirective } from '@angular/forms';

import { QuestionModel } from '../../models/question.model';
import { AnswerValueModel } from '../../models/answer.value.model';
import { QuestionC3ValidateModel } from '../../models/question.c3.validate.model';
import _ from 'lodash';

@Component({
  selector: 'app-label-title',
  templateUrl: './label-title.component.html',
  styleUrls: ['./label-title.component.css']
})
export class LabelTitleComponent implements OnInit {

  @Input()
  public question!: QuestionModel;

  @Input()
  public index!: number;

  @Input()
  public formGroupName!: string;

  childForm!: FormGroup;
  // mainForm!: FormGroup;
  iconFound = true;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    debugger
    console.log('question', this.question);
    this.childForm = this.rootFormGroup.control.get(this.formGroupName) as FormGroup;
    const _answer_category = this.childForm.value['answer_category'];
    if (_answer_category != '3') {
      this.disableFormGroup(this.childForm);
      this.enableFormGroup(this.childForm);
    } else {
      this.disableOnlyChildFormGroup(this.childForm);
      this.enableFormGroupCategory3(this.childForm);
    }
    this.childForm.updateValueAndValidity();
  }

  changeValueCategory3(value: AnswerValueModel) {
    debugger;
    if (value.last_key && value.checked == true) {
      this.disableFormGroup(value.form_group, value.answer_option_id);
      this.validateRequiredCategory3(value, true);
    } else if (value.last_key && value.checked == false) {
      this.enableFormGroupCategory3(value.form_group, value.last_key && value.checked == false);
      this.validateRequiredCategory3(value, false);
    } else {
      if (value.form_group.controls['question_373012']) {
        let ctrl = value.form_group.controls['question_373012'] as FormGroup;
        ctrl.controls['answer_value_373012'].setValue(null);
        value.form_group.controls['question_373012'].value['answer_value_373012'] = null;
      }
      else if (value.form_group.controls['question_37203']) {
        let ctrl = value.form_group.controls['question_37203'] as FormGroup;
        ctrl.controls['answer_value_37203'].setValue(null);
        value.form_group.controls['question_37203'].value['answer_value_37203'] = null;
      }
      const _form_group = this.getFormGroup(this.childForm, value.answer_option_id);
      if (_form_group != null) {
        if (!value.checked) this.disableFormGroup(_form_group);
        if (value.checked) this.enableFormGroupCategory3(_form_group);
      }
      this.validateRequiredCategory3(value);
    }
  }

  validateRequiredCategory3(value: AnswerValueModel, isNonOfAbove = false) {
    debugger;
    let _parent_question_id = null;

    const groupWiseCount: Array<QuestionC3ValidateModel> = new Array<QuestionC3ValidateModel>();
    this.groupWiseCount(this.childForm, groupWiseCount, value);

    if (value.form_group.get('super_parent_id')?.value != undefined) {
      _parent_question_id = value.form_group.get('super_parent_id')?.value;
    } else if (value.form_group.get('super_parent_id')?.value == undefined) {
      _parent_question_id = this.childForm.get('question_id')?.value;
    } else if (isNonOfAbove) {
      _parent_question_id = this.childForm.get('question_id')?.value;
    }
    const _groupValid: boolean = this.getGroupValid(groupWiseCount);
    console.log('selecedChildCount -> ', groupWiseCount, _groupValid, isNonOfAbove);
    // if (_groupValid && value.answer_option_id == 3730111113) {
    //   this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (false ? 1 : null)));
    // }
    // else if (_groupValid && value.answer_option_id == 37301111131) {
    //   if (value.form_group.value.question_37301111131.answer_value_37301111131 != '' && value.form_group.value.question_37301111131.answer_value_37301111131 != null) {
    //     this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (true ? 1 : null)));
    //   }
    //   else {
    //     this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (false ? 1 : null)));
    //   }
    // }
    // else if (value.form_group.value.question_id == 3730111) {
    //   if (value.form_group.value.question_37301111.answer_value_37301111 == 1) {
    //     this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (_groupValid ? 1 : null)));
    //   }
    //   else {
    //     if (value.form_group.value.question_37301111.answer_value_37301111 && value.form_group.value.question_373011112.answer_value_373011112 && value.form_group.value.question_373011113.answer_value_373011113) {
    //       this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (true ? 1 : null)));
    //     }
    //     else {
    //       this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (false ? 1 : null)));
    //     }
    //   }

    // }
    // else if ((value.form_group.value.question_id == 373081 || value.form_group.value.question_id == 373081623 || value.form_group.value.question_id == 37308162) && (value.answer_option_id == 3730816 || value.answer_option_id == 373081621 || value.answer_option_id == 373081622 || value.answer_option_id == 373081623 || value.answer_option_id == 3730816211 || value.answer_option_id == 3730816231)) {
    //   if (this.rootFormGroup.form.controls.question_3730.value.question_37308.question_373081.question_3730816.question_3730816211.answer_value_3730816211 != '' && this.rootFormGroup.form.controls.question_3730.value.question_37308.question_373081.question_3730816.question_3730816211.answer_value_3730816211 != null) {
    //     if (this.rootFormGroup.form.controls.question_3730.value.question_37308.question_373081.question_3730816.question_37308162) {
    //       if (this.rootFormGroup.form.controls.question_3730.value.question_37308.question_373081.question_3730816.question_37308162.question_373081621.answer_value_373081621 || this.rootFormGroup.form.controls.question_3730.value.question_37308.question_373081.question_3730816.question_37308162.question_373081622.answer_value_373081622 || this.rootFormGroup.form.controls.question_3730.value.question_37308.question_373081.question_3730816.question_37308162.question_373081623.answer_value_373081623) {
    //         this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (true ? 1 : null)));
    //         if (this.rootFormGroup.form.controls.question_3730.value.question_37308.question_373081.question_3730816.question_37308162.question_373081623.answer_value_373081623) {
    //           if (this.rootFormGroup.form.controls.question_3730.value.question_37308.question_373081.question_3730816.question_37308162.question_373081623.question_3730816231.answer_value_3730816231 != '' && this.rootFormGroup.form.controls.question_3730.value.question_37308.question_373081.question_3730816.question_37308162.question_373081623.question_3730816231.answer_value_3730816231 != null) {
    //             this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (true ? 1 : null)));
    //           }
    //           else {
    //             this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (false ? 1 : null)));
    //           }
    //         }
    //       }
    //     }
    //   }
    //   else {
    //     this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (false ? 1 : null)));
    //   }

    // }
    // else {
    //   this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (_groupValid ? 1 : null)));
    // }
    this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (_groupValid ? 1 : null)));
    this.childForm.updateValueAndValidity();
  }

  groupWiseCount(form: FormGroup, count: Array<QuestionC3ValidateModel>, answerValue: AnswerValueModel, isChild = false) {
    if (form != undefined) {
      Object.keys(form.controls).map(ctrlName => {
        if (form.get(ctrlName) instanceof FormGroup) {
          let _selfCheck = 0;
          const _subFormGroup = form.get(ctrlName) as FormGroup;
          const _question_id: number = _subFormGroup.value['question_id'] as number;
          const _has_child: boolean = _subFormGroup.value['has_child'] as boolean;
          const _answer_category: any = _subFormGroup.value['answer_category'] as any;
          let _answer_value: any;
          if (_subFormGroup.get('answer_value_' + _question_id)?.enabled == true) _answer_value = _subFormGroup.get('answer_value_' + _question_id)?.value;
          if (_answer_value == true || (_answer_value === '0' || _answer_value === '1')) _selfCheck = 1;
          const groupChildCount = { value: 0, minValue: 0 };
          if (_has_child) this.checkRequiredCategory3(_subFormGroup, groupChildCount, answerValue, true);
          if (!_has_child && _selfCheck == 1) groupChildCount.value = 1;
          if (_selfCheck == 1) groupChildCount.minValue = this.howManyChildShowOnGroup(_subFormGroup);
          if (_selfCheck == 1 && groupChildCount.minValue == 0) groupChildCount.value = 1;
          count.push(new QuestionC3ValidateModel(ctrlName, _selfCheck, groupChildCount.value, groupChildCount.minValue));
          if (_has_child) this.groupWiseCount(_subFormGroup, count, answerValue, true);
          // if (_has_child && _answer_category == 3) this.subGroupWiseCount(_subFormGroup, count);
        }
      });
    }
  }

  subGroupWiseCount(form: FormGroup, count: Array<QuestionC3ValidateModel>) {
    Object.keys(form.controls).map(ctrlName => {
      if (form.get(ctrlName) instanceof FormGroup) {
        const _subFormGroup = form.get(ctrlName) as FormGroup;
        const _has_child: boolean = _subFormGroup.value['has_child'] as boolean;
        if (_has_child == true) {
          const _question_id: number = _subFormGroup.value['question_id'] as number;
          const _answer_value: boolean = _subFormGroup.value['answer_value_' + _question_id] as boolean;
          let _selfCheck = 0;
          if (_answer_value == true) _selfCheck = 1;
            const subFormGroup = this.getFirstFormGroup(_subFormGroup);
          const _sub_group_question_id: number = subFormGroup?.value['question_id'] as number;
          const _sub_group_answer_value: string = subFormGroup?.value['answer_value_' + _sub_group_question_id] as string;
          let _is_text_value = 0;
          if (_sub_group_answer_value?.length > 0) _is_text_value = 1;
          count.push(new QuestionC3ValidateModel('question_' + _sub_group_question_id, _selfCheck, _is_text_value, _selfCheck));
        }
      }
    });
  }

  getFirstFormGroup(form: FormGroup): FormGroup | null {
    let _form: FormGroup | null = null;
    Object.keys(form.controls).map(ctrlName => {
      if (form.get(ctrlName) instanceof FormGroup) _form = form.get(ctrlName) as FormGroup;
    });
    return _form;
  }

  checkRequiredCategory3(form: FormGroup, selectedCount: { value: number, minValue: number }, answerValue: AnswerValueModel, isNested = false) {
    if (form != undefined) {
      Object.keys(form.controls).map(ctrlName => {
        if (form.get(ctrlName) instanceof FormGroup) {
          const _subFormGroup = form.get(ctrlName) as FormGroup;
          const _question_id: number = _subFormGroup.value['question_id'] as number;
          const _answer_category: any = _subFormGroup.value['answer_category'] as any;
          let _answer_value: any;
          if (_subFormGroup.get('answer_value_' + _question_id)?.enabled == true) _answer_value = _subFormGroup.get('answer_value_' + _question_id)?.value;
          if (_answer_category == null && (_answer_value == true || (_answer_value === '0' || _answer_value === '1'))) selectedCount.value++;
          if (_answer_category == 1 && (_answer_value === '0' || _answer_value === '1')) selectedCount.value++;
          if (_answer_category == 4 && _answer_value) selectedCount.value++;
          if (isNested) this.checkRequiredCategory3(_subFormGroup, selectedCount, answerValue, isNested);
        }
      });
    }
  }

  howManyChildFormGroup(form: FormGroup): number {
    let _form_group_count = 0;
    Object.keys(form.controls).map(ctrlName => {
      if (form.get(ctrlName) instanceof FormGroup) _form_group_count++;
    });
    return _form_group_count;
  }

  howManyChildShowOnGroup(form: FormGroup): number {
    const _question_id: number = form.value['question_id'] as number;
    const _answer_value: any = form.value['answer_value_' + _question_id] as any;
    const _answer_category: any = form.value['answer_category'] as any;
    let _child_open_count = 0;

    Object.keys(form.controls).map(ctrlName => {
      if (form.get(ctrlName) instanceof FormGroup) {
        const _subFormGroup = form.get(ctrlName) as FormGroup;
        const _applicable_parent_answer: any = _subFormGroup.value['applicable_parent_answer'] as any;
        if (_answer_category != 3 && _answer_value == _applicable_parent_answer) _child_open_count++;
        if (_answer_category == 3) _child_open_count = 1;
      }
    });
    return _child_open_count;
  }

  getGroupValid(count: Array<QuestionC3ValidateModel>): boolean {
    let result = true;
    let result_allzero = true;
    for (const arr of count) {
      let current_result = true;
      if (arr.value == 0 && arr.groupCount == 0) {
        current_result = true;
      } else {
        if (arr.minGroupCount > 0) {
          current_result = arr.value > 0 ? (arr.groupCount >= arr.minGroupCount ? true : false) : true;
        } else {
          current_result = arr.value > 0 ? (arr.groupCount > 0 ? true : false) : true;
        }
        result_allzero = false;
      }
      result = result && current_result;
    }
    // if (count.filter(x => x.group == 'question_373081' && x.groupCount >= 2).length > 0) {
    //   result = true;
    // }
   // return result_allzero || count.filter(x => x.group == 'question_373011' && x.groupCount <= 4 && x.groupCount != 0).length > 0 ? false : result;
   return result_allzero ? false : result;
  }

  changeValue(value: number | string) {
    debugger
    this.disableFormGroup(this.childForm);
    this.enableFormGroup(this.childForm);
  }

  findRangeOption(answer_option_id: number, range: any): boolean {
    const _range_array: Array<string> = range.replace('[', '').replace(']', '').split(',');
    return _range_array?.indexOf(answer_option_id?.toString()) > -1;
  }

  disableOnlyChildFormGroup(form: FormGroup) {
    if (form != undefined) {
      Object.keys(form.controls).forEach(ctrlName => {
        if (form.get(ctrlName) instanceof FormGroup) {
          const subFormGroup = form.get(ctrlName) as FormGroup;
          this.disableFormGroup(subFormGroup);
        }
      });
    }
  }

  disableFormGroup(form: FormGroup, except_id: number | undefined = undefined) {
    debugger;
    if (form != undefined) {
      Object.keys(form.controls).forEach(ctrlName => {
        if (form.get(ctrlName) instanceof FormGroup) {
          const subFormGroup = form.get(ctrlName) as FormGroup;
          const _question_id = subFormGroup.value['question_id'];
          if (_question_id != except_id) {
            subFormGroup.controls['answer_value_' + _question_id].setValue(null);
            if (form.value.answer_category != 3) {
              subFormGroup.controls['answer_value_' + _question_id].disable();
            }
            // subFormGroup.controls['answer_value_' + _question_id].disable();
          }
          this.disableFormGroup(subFormGroup);
        }
      });
    }
  }

  enableFormGroup(form: FormGroup) {
    debugger
    if (form != undefined) {
      Object.keys(form.controls).forEach(ctrlName => {
        const _parent_question_id = form.value['question_id'];
        let _parent_question_value = null;
        if (form.value['display_in'] != 'range') {
          _parent_question_value = form.value['answer_value_' + _parent_question_id];
        } else {
          _parent_question_value = form.value['answer_option_id_' + _parent_question_id];
          if(form.value.selected_answer==0 && ctrlName.includes('question_')&&ctrlName != 'question_id' && ctrlName != 'question_text'){
             form.value[ctrlName].selected_answer='';
             form.controls[ctrlName]['controls']['selected_answer'].setValue('');
          }
        }
        if (form.get(ctrlName) instanceof FormGroup) {
          const subFormGroup = form.get(ctrlName) as FormGroup;
          const _applicable_parent_answer = subFormGroup.value['applicable_parent_answer'];
          const _question_id = subFormGroup.value['question_id'];
          if (form.value['display_in'] != 'range') {
            if (_applicable_parent_answer == _parent_question_value) {
              subFormGroup.controls['answer_value_' + _question_id].setValue(subFormGroup.controls['old_answer_value'].value);
              subFormGroup.controls['answer_value_' + _question_id].enable();
            }
          } else {
            if (this.findRangeOption(_parent_question_value, _applicable_parent_answer)) {
              subFormGroup.controls['answer_value_' + _question_id].setValue(subFormGroup.controls['old_answer_value'].value);
              subFormGroup.controls['answer_value_' + _question_id].enable();
            }
          }
          this.enableFormGroup(subFormGroup);
        }
      });
    }
  }

  enableFormGroupCategory3(form: FormGroup, last_key?: any, value?: any) {
    debugger;
    if (form != undefined) {
      Object.keys(form.controls).forEach(ctrlName => {
        if (form.get(ctrlName) instanceof FormGroup) {
          const subFormGroup = form.get(ctrlName) as FormGroup;
          const _question_id = subFormGroup.value['question_id'];
          const _answer_category = subFormGroup.value['answer_category'];
          if (_answer_category != 3) {
            last_key ? subFormGroup.controls['old_answer_value'].setValue(value) : 0;
            subFormGroup.controls['answer_value_' + _question_id].setValue(subFormGroup.controls['old_answer_value'].value);
            subFormGroup.controls['answer_value_' + _question_id].enable();
          }
          this.enableFormGroupCategory3(subFormGroup);
        }
      });
    }
  }

  getFormGroup(
    form: FormGroup,
    question_id: number | string | undefined,
    is_parent = false,
    is_nested_search = false): FormGroup | null {
    let returnForm!: FormGroup | null;

    Object.keys(form.controls).every((ctrlName: string) => {
      if (form.get(ctrlName) instanceof FormGroup) {
        const subFormGroup = form.get(ctrlName) as FormGroup;
        const _question_id = subFormGroup.value['question_id'];

        if (_question_id == question_id) {
          returnForm = subFormGroup;
          if (is_parent) returnForm = form;
          is_nested_search = false;
          return false;
        } else if (is_nested_search) {
          returnForm = this.getFormGroup(subFormGroup, question_id, is_parent, is_nested_search);
        }
      }
      return returnForm != null ? false : true;
    });
    return returnForm;
  }

  handleMissingImage(event: any) {
    this.iconFound = false;
  }

}
