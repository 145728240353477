import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-knock-out',
  templateUrl: './knock-out.component.html',
  styleUrls: ['./knock-out.component.css']
})
export class KnockOutComponent implements OnInit {

  @Input() previousType;
  @Input() flagType;
  @Output() closeKnock = new EventEmitter();
  @Output() redirectToAuto = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  errorMessage: string;
  hcarrierName: any;
  hcarrierAmount: string;
  homeselectedCard: number;
  lifeselectedCard: number;
  clientData: any;

  constructor(private activatedroute: ActivatedRoute, public pc: ProcessCacheService) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    localStorage.setItem("knockout", "0");
    this.errorMessage = localStorage.getItem("KOerrorMessage");
    if (this.errorMessage == null || this.errorMessage == undefined || this.errorMessage == '') {
      this.errorMessage = "Unfortunately we require a couple more steps before completing your application."
    }
    this.homeselectedCard = 0;
    this.lifeselectedCard = 0;

    let clientIdLocal = localStorage.getItem('clientID');
    let customerIdLocal = localStorage.getItem('customer_id');
    let emailLocal = localStorage.getItem('bubble-email');
    let home_knock_out_reason = this.errorMessage;
    let hubSpotData = {
      client_id: clientIdLocal,
      customer_id: customerIdLocal,
      email: emailLocal,
      application_stage: "Policy Denied",
      home_knock_out_reason: home_knock_out_reason
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);


  }

  async continueLife() {
    const idData = await this.pc.getAppData(this.clientId);
    this.clientData = JSON.parse(idData?.Payload)?.body;
    let carrier = localStorage.getItem("lifeCarrierName");
    if (this.previousType == "previous") {
      let selectedLife = JSON.parse(localStorage.getItem('selectedCarrier'));
      console.log("selectedCarrier life : ", JSON.parse(localStorage.getItem('selectedCarrier')));
      let lifeCarrier = selectedLife.carrierId;
      localStorage.setItem('bubble-lifejourneystart', 'true');
      if (lifeCarrier == "paclife") {
        location.href = this.clientData['lifeflowurl'];
      }
      else {
        location.href = this.clientData['lifeflowurl'];
      }
      //location.href = `Appflowstart/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/${carrier}`;
    }
    else {
      localStorage.setItem('bubble-lifejourneystart', 'true');
      location.href = `QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/life/knockout`;
    }
  }

  checkOutLife() {
    localStorage.setItem('insuranceType', 'life');
    location.href = `QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/life/knockout`;
  }

  mayBeLater() {
    location.href = this.pc.envCred.url; //"https://stage-www.getmybubble.io";
  }

  previous() {
    localStorage.setItem("knockout", "1");
    if (this.previousType == "previous") {
      this.closeKnock.emit();
    }
    else {
      location.href = `QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}/previous`;
    }

  }


  continueAuto() {
    this.redirectToAuto.emit();
  }

}
