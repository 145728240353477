import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-is-insured-dependent',
  templateUrl: './is-insured-dependent.component.html',
  styleUrls: ['./is-insured-dependent.component.css']
})
export class IsInsuredDependentComponent implements OnInit {
  @Input() questionDetails: [];
  FormVisible: boolean;
  showloader:boolean;
  isInsuredDependentDetails: any;
  @Output() public isInsuredDependentOutput =
    new EventEmitter<{ component_id: any, answer: any }>();
  constructor(
    private pc: ProcessCacheService,
    private $gaService: GoogleAnalyticsService,
    private utilityService:UtilityFunctionsService,
    private service:ParentChildService) { }

  ngOnInit(): void {
    this.service.callComponentMethod();
    this.showloader=true;
    if (localStorage.getItem('bubble-isInsuredDependent') == null || localStorage.getItem('bubble-isInsuredDependent') == undefined) {
      this.InActiveTickIcon = true;
      this.InActiveCrossIcons = true;
      this.showloader=false;
    } else {

      this.pc.getAppData(localStorage.getItem('clientID')).then(
        data => {
          debugger;
          const clientDataFiltered = JSON.parse(data.Payload);
          this.isInsuredDependentDetails = clientDataFiltered.body.isInsuredDependent;

          // this.isInsuredDependentDetails = localStorage.getItem("bubble-isInsuredDependent");
          if (this.isInsuredDependentDetails == 'No') {
            var data1 = 0;
          } else {
            var data1 = 1;
          }
          this.SelectedOption = data1;
          if (this.SelectedOption == 0) {
            this.selectedOptionValue = 'No';

            this.FormVisible = true;
            this.ActiveTickIcons = false;
            this.InActiveTickIcon = true;
            this.ActiveCrossIcons = true;
            this.InActiveCrossIcons = false;
          } else {
            this.selectedOptionValue = 'Yes';

            this.FormVisible = false;
            this.ActiveTickIcons = true;
            this.InActiveTickIcon = false;
            this.ActiveCrossIcons = false;
            this.InActiveCrossIcons = true;

          }
          this.showloader=false;
        }
      ).catch(error => {
        console.log(error);
      });


    }
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_5_FinancialSupport'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
  }


  SelectedOption: any;
  SecondQuestion: any;
  ActiveTickIcons: boolean;
  InActiveTickIcon: boolean;
  ActiveCrossIcons: boolean;
  InActiveCrossIcons: boolean;
  selectedOptionValue: any;
  public GetOptions(data) {

    this.SelectedOption = data;
    if (this.SelectedOption == 0) {
      this.selectedOptionValue = 'No';
      //  document.getElementById('0').style.border='solid 2px orange';
      //  document.getElementById('1').style.border='solid 1px #dae7ec';
      this.FormVisible = true;
      this.ActiveTickIcons = false;
      this.InActiveTickIcon = true;
      this.ActiveCrossIcons = true;
      this.InActiveCrossIcons = false;

    } else {
      this.selectedOptionValue = 'Yes';
      // document.getElementById('1').style.border='solid 2px orange';
      // document.getElementById('0').style.border='solid 1px #dae7ec';
      this.FormVisible = false;
      this.ActiveTickIcons = true;
      this.InActiveTickIcon = false;
      this.ActiveCrossIcons = false;
      this.InActiveCrossIcons = true;

    }
  }



  inputJson;
  public onSubmit(data) {
    if (data.on_submit == 'proceed') {
      //  this.inputJson={
      //    'id': localStorage.getItem('clientID'),
      //    'isInsuredDependent':this.selectedOptionValue
      //  }
      localStorage.setItem('questionId', data.question_sequence);
      this.inputJson = {};
      this.inputJson['id'] = localStorage.getItem('clientID');
      this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;
      this.isInsuredDependentOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
      this.submitdata(this.inputJson);
    } else {
      alert('Declined!');
    }

    this.$gaService.event('Next', 'event', 'LifeAppFinancialSupport');
    const eventOptions: EventOption = {
      page_title: 'Applicant Financial Support ',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppFinancialSupport');
  }

  public submitdata(inputJson) {
    localStorage.setItem('bubble-isInsuredDependent', this.selectedOptionValue);
    this.pc.saveUpdateClientData(inputJson);
  }


  public goBack() {
    // this.isDependentOutput.emit(this.questionDetails["component_id"]+",Goback");
    this.isInsuredDependentOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }

}
