import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-lifescore-error-alert',
  templateUrl: './lifescore-error-alert.component.html',
  styleUrls: ['./lifescore-error-alert.component.css']
})
export class LifescoreErrorAlertComponent implements OnInit {

  @ViewChild('img', { static: true }) image: ElementRef;
  @ViewChild('newImg', { static: true }) newImage: ElementRef;

  @Input() fromParent;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {}

  closeModal() {
    this.activeModal.close("Modal Closed");
  }

}
