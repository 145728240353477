import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

@Component({
  selector: 'app-insurance-purpose',
  templateUrl: './insurance-purpose.component.html',
  styleUrls: ['./insurance-purpose.component.css']
})
export class InsurancePurposeComponent implements OnInit {
  inputJson: {};
  InActiveDollar: boolean;
  InActiveHome: boolean;
  InactiveMan: boolean;
  ActiveDollar: boolean;
  ActiveHome: boolean;
  ActiveMan: boolean;
  constructor(
    private pc: ProcessCacheService,
    private utilityService:UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,
    private service:ParentChildService) { }
  @Output() insurancePurposeOutput = new EventEmitter();

  @Input() questionDetails: [];
  ngOnInit(): void {
    this.InActiveDollar = true;
    this.InActiveHome = true;
    this.InactiveMan = true;
    this.ActiveDollar = false;
    this.ActiveHome = false;
    this.ActiveMan = false;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_12_InsuranceNeed'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.service.callComponentMethod();
    console.log('insurancepurpose', this.questionDetails);

    if (localStorage.getItem('bubble-insurancePurpose') != null || localStorage.getItem('bubble-insurancePurpose') != undefined) {


      this.pc.getAppData(localStorage.getItem('clientID')).then(
        data => {
          debugger;
          const clientDataFiltered = JSON.parse(data.Payload);
          this.SelectedOption = clientDataFiltered.body.insurancePurpose;
          if (this.SelectedOption == this.questionDetails['radio_options'][0]['answer_text']) {
            this.SelectedValue = this.questionDetails['radio_options'][0]['answer_text'];
            document.getElementById('0').style.border = 'solid 2px orange';
            document.getElementById('1').style.border = 'solid 1px #dae7ec';
            document.getElementById('2').style.border = 'solid 1px #dae7ec';
            document.getElementById('ir').style.fontWeight = 'bold';
            document.getElementById('ep').style.fontWeight = 'normal';
            document.getElementById('op').style.fontWeight = 'normal';
            this.InActiveDollar = false;
            this.InActiveHome = true;
            this.InactiveMan = true;
            this.ActiveDollar = true;
            this.ActiveHome = false;
            this.ActiveMan = false;

          } else if (this.SelectedOption == this.questionDetails['radio_options'][1]['answer_text']) {
            this.SelectedValue = this.questionDetails['radio_options'][1]['answer_text'];
            document.getElementById('0').style.border = 'solid 1px #dae7ec';
            document.getElementById('1').style.border = 'solid 2px orange';
            document.getElementById('2').style.border = 'solid 1px #dae7ec';
            document.getElementById('ir').style.fontWeight = 'normal';
            document.getElementById('ep').style.fontWeight = 'bold';
            document.getElementById('op').style.fontWeight = 'normal';
            this.InActiveDollar = true;
            this.InActiveHome = false;
            this.InactiveMan = true;
            this.ActiveDollar = false;
            this.ActiveHome = true;
            this.ActiveMan = false;
          }
          else if (this.SelectedOption == this.questionDetails['radio_options'][2]['answer_text']) {
            this.SelectedValue = this.questionDetails['radio_options'][2]['answer_text'];
            document.getElementById('0').style.border = 'solid 1px #dae7ec';
            document.getElementById('1').style.border = 'solid 1px #dae7ec';
            document.getElementById('2').style.border = 'solid 2px orange';
            document.getElementById('ir').style.fontWeight = 'normal';
            document.getElementById('ep').style.fontWeight = 'normal';
            document.getElementById('op').style.fontWeight = 'bold';
            this.InActiveDollar = true;
            this.InActiveHome = true;
            this.InactiveMan = false;
            this.ActiveDollar = false;
            this.ActiveHome = false;
            this.ActiveMan = true;

          }


        }
      ).catch(error => {
        console.log(error);
      });







    }



  }


  SelectedOption: any;
  SelectedValue: any;
  public GetOptions(data) {

    this.SelectedOption = data;
    if (this.SelectedOption == '0') {
      this.SelectedValue = this.questionDetails['radio_options'][0]['answer_text'];
      document.getElementById('0').style.border = 'solid 2px orange';
      document.getElementById('1').style.border = 'solid 1px #dae7ec';
      document.getElementById('2').style.border = 'solid 1px #dae7ec';
      document.getElementById('ir').style.fontWeight = 'bold';
      document.getElementById('ep').style.fontWeight = 'normal';
      document.getElementById('op').style.fontWeight = 'normal';
      this.InActiveDollar = false;
      this.InActiveHome = true;
      this.InactiveMan = true;
      this.ActiveDollar = true;
      this.ActiveHome = false;
      this.ActiveMan = false;

    } else if (this.SelectedOption == '1') {
      this.SelectedValue = this.questionDetails['radio_options'][1]['answer_text'];
      document.getElementById('0').style.border = 'solid 1px #dae7ec';
      document.getElementById('1').style.border = 'solid 2px orange';
      document.getElementById('2').style.border = 'solid 1px #dae7ec';
      document.getElementById('ir').style.fontWeight = 'normal';
      document.getElementById('ep').style.fontWeight = 'bold';
      document.getElementById('op').style.fontWeight = 'normal';
      this.InActiveDollar = true;
      this.InActiveHome = false;
      this.InactiveMan = true;
      this.ActiveDollar = false;
      this.ActiveHome = true;
      this.ActiveMan = false;
    }
    else if (this.SelectedOption == '2') {
      this.SelectedValue = this.questionDetails['radio_options'][2]['answer_text'];
      document.getElementById('0').style.border = 'solid 1px #dae7ec';
      document.getElementById('1').style.border = 'solid 1px #dae7ec';
      document.getElementById('2').style.border = 'solid 2px orange';
      document.getElementById('ir').style.fontWeight = 'normal';
      document.getElementById('ep').style.fontWeight = 'normal';
      document.getElementById('op').style.fontWeight = 'bold';
      this.InActiveDollar = true;
      this.InActiveHome = true;
      this.InactiveMan = false;
      this.ActiveDollar = false;
      this.ActiveHome = false;
      this.ActiveMan = true;

    }

  }



  public onSubmit(data) {
    debugger;
    if (data.on_submit == 'proceed') {
      // this.inputJson={
      //   'id': localStorage.getItem('clientID'),
      //   'insurancePurpose':this.SelectedValue
      // }
      localStorage.setItem('questionId', data.question_sequence);
      this.inputJson = {};
      this.inputJson['id'] = localStorage.getItem('clientID');
      this.inputJson[data.radio_options[0].answer_key] = this.SelectedValue;

      this.insurancePurposeOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
      this.submitdata(this.inputJson);

    } else {
      alert('Declined!');
    }

    this.$gaService.event('Next', 'event', 'LifeAppInsuranceNeed');
    const eventOptions: EventOption = {
      page_title: 'Existing Policy',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppInsuranceNeed');
  }

  public submitdata(inputJson) {
    localStorage.setItem('bubble-insurancePurpose', this.SelectedValue);
    this.pc.saveUpdateClientData(inputJson);
  }



  public goBack() {
    this.insurancePurposeOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
    // this.insurancePurposeOutput.emit(this.questionDetails["component_id"]+",Goback");
  }
}
