import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ValidationService } from './../../../shared/validation.service';

@Component({
  selector: 'app-sw4pt38',
  templateUrl: './sw4pt38.component.html',
  styleUrls: ['./sw4pt38.component.css']
})
export class Sw4pt38Component implements OnInit {

  @Output() nextquestion22 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  showoptions: string;
  firstName: any;
  lastName: any;
  dob: any;
  curDate: Date;
  maxDate: Date;
  minDate: Date;
  message: any;
  is_valid: any;
  existingData: any;
  vestingDetails: string;
  showvestinginfo: number;
  loader: boolean;
  errorMessage: string;
  constructor(private $gaService: GoogleAnalyticsService, private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService, private validationService: ValidationService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    debugger;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    //  this.uniqueId = 'fi7e81-285-Bryant-St-Palo-Alto-CA-94301';
    //  this.clientId = 'fi7e81';
    this.loader = true;
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt38')[0];
    console.log('sw questions app-sw4pt38 : ', this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.stillwater;
      console.log('data from getAppData sw : ', this.existingData);

      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear() - 18);
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.maxDate = new Date(month + '/' + day + '/' + year);

      const yearm = Number(this.curDate.getFullYear() - 120);
      const monthm = '' + (this.curDate.getMonth() + 1);
      const daym = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      this.minDate = new Date(monthm + '/' + daym + '/' + yearm);
      debugger;
      if (this.existingData['sw_secondOwner'] == 'yes') {
        this.questions.radio_options[0].active = true;
        this.questions.radio_options[1].active = false;
        this.showoptions = '1';
        if (this.existingData['sw_secondOwnerFirstName'] == null || this.existingData['sw_secondOwnerFirstName'] == 'undefined' || this.existingData['sw_secondOwnerFirstName'] == undefined || this.existingData['sw_secondOwnerFirstName'] == '' || this.existingData['sw_secondOwnerFirstName'] == 'undefined' || this.existingData['sw_secondOwnerFirstName'] == 'null') {
          this.firstName = '';
        }
        else {
          this.firstName = this.existingData['sw_secondOwnerFirstName'];
        }

        if (this.existingData['sw_secondOwnerLastName'] == null || this.existingData['sw_secondOwnerLastName'] == 'undefined' || this.existingData['sw_secondOwnerLastName'] == undefined || this.existingData['sw_secondOwnerLastName'] == '' || this.existingData['sw_secondOwnerLastName'] == 'null' || this.existingData['sw_secondOwnerLastName'] == 'undefined') {
          this.lastName = '';
        }
        else {
          this.lastName = this.existingData['sw_secondOwnerLastName'];
        }
        debugger;
        if (this.existingData['sw_secondOwnerDOB'] == null || this.existingData['sw_secondOwnerDOB'] == 'undefined' || this.existingData['sw_secondOwnerDOB'] == undefined || this.existingData['sw_secondOwnerDOB'] == '' || this.existingData['sw_secondOwnerDOB'] == 'undefined' || this.existingData['sw_secondOwnerDOB'] == 'null') {
          // this.dob = new Date();
          // this.curDate = this.dob;
          // let years = Number(this.curDate.getFullYear() - 18);
          // let months = '' + (this.curDate.getMonth() + 1);
          // let days = '' + this.curDate.getDate();
          // if (months.length < 2)
          //  months = '0' + months;
          // if (days.length < 2)
          //  days = '0' + days;
          // this.dob=new Date(months + '/'+ days + '/' + years);
          this.dob = '';
        }
        else {
          this.dob = new Date(this.existingData['sw_secondOwnerDOB']);
          this.curDate = this.dob;
          const years = Number(this.curDate.getFullYear());
          let months = '' + (this.curDate.getMonth() + 1);
          let days = '' + this.curDate.getDate();
          if (months.length < 2)
            months = '0' + months;
          if (days.length < 2)
            days = '0' + days;
          this.dob = new Date(months + '/' + days + '/' + years);
        }





      }
      else if (this.existingData['sw_secondOwner'] == 'no') {
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = true;
        this.disable = false;
        this.dob = new Date();
        this.curDate = this.dob;
        const years = Number(this.curDate.getFullYear() - 18);
        let months = '' + (this.curDate.getMonth() + 1);
        let days = '' + this.curDate.getDate();
        if (months.length < 2)
          months = '0' + months;
        if (days.length < 2)
          days = '0' + days;
        this.dob = new Date(months + '/' + days + '/' + years);
      }
      else {
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = false;
        this.disable = true;
        this.dob = new Date();
        this.curDate = this.dob;
        const years = Number(this.curDate.getFullYear() - 18);
        let months = '' + (this.curDate.getMonth() + 1);
        let days = '' + this.curDate.getDate();
        if (months.length < 2)
          months = '0' + months;
        if (days.length < 2)
          days = '0' + days;
        this.dob = new Date(months + '/' + days + '/' + years);
      }


      if (this.existingData['sw_vesting'] == 'yes') {
        this.questions.radio_options_two[0].active = true;
        this.questions.radio_options_two[1].active = false;
        this.vestingDetails = this.existingData['sw_vestingDetails'];
        this.showvestinginfo = 1;
        this.disable = false;
      }
      else if (this.existingData['sw_vesting'] == 'no') {
        this.questions.radio_options_two[0].active = false;
        this.questions.radio_options_two[1].active = true;
        this.showvestinginfo = 0;
        this.disable = false;
        this.vestingDetails = '';
      }
      else {
        this.questions.radio_options_two[0].active = false;
        this.questions.radio_options_two[1].active = false;
        this.disable = true;
        this.showvestinginfo = 0;
        this.vestingDetails = '';
      }
      this.checkDate(this.dob);
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });



    // if (localStorage.getItem('sw_secondOwner') == 'yes') {
    //   this.questions.radio_options[0].active = true;
    //   this.questions.radio_options[1].active = false;
    //   this.showoptions = '1';
    //   if (localStorage.getItem('sw_secondOwnerFirstName') == null || localStorage.getItem('sw_secondOwnerFirstName') == undefined || localStorage.getItem('sw_secondOwnerFirstName') == '') {
    //     this.firstName = '';
    //   }
    //   else {
    //     this.firstName = localStorage.getItem('sw_secondOwnerFirstName');
    //   }

    //   if (localStorage.getItem('sw_secondOwnerLastName') == null || localStorage.getItem('sw_secondOwnerLastName') == undefined || localStorage.getItem('sw_secondOwnerLastName') == '') {
    //     this.lastName = '';
    //   }
    //   else {
    //     this.lastName = localStorage.getItem('sw_secondOwnerLastName');
    //   }

    //   if (localStorage.getItem('sw_secondOwnerDOB') == null || localStorage.getItem('sw_secondOwnerDOB') == undefined || localStorage.getItem('sw_secondOwnerDOB') == '') {
    //     this.dob = '';
    //   }
    //   else {
    //     this.dob = localStorage.getItem('sw_secondOwnerDOB');
    //   }


    //   this.curDate = new Date(this.dob);
    //   let years = Number(this.curDate.getFullYear());
    //   let months = '' + (this.curDate.getMonth() + 1);
    //   let days = '' + this.curDate.getDate();
    //   if (months.length < 2)
    //    months = '0' + months;
    //   if (days.length < 2)
    //    days = '0' + days;
    //   this.dob=new Date(months + '/'+ days + '/' + years);


    // }
    // else if (localStorage.getItem('sw_secondOwner') == 'no'){
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = true;
    //   this.disable = false;
    // }
    // else {
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = false;
    //   this.disable = true;
    // }


    // if (localStorage.getItem('sw_vesting') == 'yes') {
    //   this.questions.radio_options_two[0].active = true;
    //   this.questions.radio_options_two[1].active = false;
    //   this.vestingDetails = localStorage.getItem("sw_vestingDetails");
    //   this.showvestinginfo = 1;
    //   this.disable = false;
    // }
    // else if (localStorage.getItem('sw_vesting') == 'no'){
    //   this.questions.radio_options_two[0].active = false;
    //   this.questions.radio_options_two[1].active = true;
    //   this.showvestinginfo = 0;
    //   this.disable = false;
    //   this.vestingDetails = "";
    // }
    // else {
    //   this.questions.radio_options_two[0].active = false;
    //   this.questions.radio_options_two[1].active = false;
    //   this.disable = true;
    //   this.showvestinginfo = 0;
    //   this.vestingDetails = "";
    // }

  }

  onYes() {
    debugger;
    this.questions.radio_options[0].active = true;
    this.questions.radio_options[1].active = false;
    localStorage.setItem('sw_secondOwner', 'yes');
    this.showoptions = '1';

    if (this.existingData['sw_secondOwnerFirstName'] == null || this.existingData['sw_secondOwnerFirstName'] == undefined || this.existingData['sw_secondOwnerFirstName'] == '') {
      this.firstName = '';
    }
    else {
      this.firstName = this.existingData['sw_secondOwnerFirstName'];
    }

    if (this.existingData['sw_secondOwnerLastName'] == null || this.existingData['sw_secondOwnerLastName'] == undefined || this.existingData['sw_secondOwnerLastName'] == '') {
      this.lastName = '';
    }
    else {
      this.lastName = this.existingData['sw_secondOwnerLastName'];
    }

    if (this.existingData['sw_secondOwnerDOB'] == null || this.existingData['sw_secondOwnerDOB'] == undefined || this.existingData['sw_secondOwnerDOB'] == '') {
      this.dob = '';
      this.dob = new Date();
      const year = Number(this.dob.getFullYear() - 18);
      let month = '' + (this.dob.getMonth() + 1);
      let day = '' + this.dob.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dob =month + '/' + day + '/' + year;
    }
    else {
      this.dob = this.existingData['sw_secondOwnerDOB'];
      this.dob = new Date(this.dob);
      const year = Number(this.dob.getFullYear());
      let month = '' + (this.dob.getMonth() + 1);
      let day = '' + this.dob.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dob = month + '/' + day + '/' + year;
    }
    if ((this.questions.radio_options[0].active == true || this.questions.radio_options[1].active == true) && (this.questions.radio_options_two[0].active == true || this.questions.radio_options_two[1].active == true)) {
      this.disable = false;
    }
    else {
      this.disable = true;
    }

  }

  onNo() {
    debugger;
    this.questions.radio_options[0].active = false;
    this.questions.radio_options[1].active = true;
    localStorage.setItem('sw_secondOwner', 'no');
    this.showoptions = '0';
    localStorage.setItem('sw_secondOwnerFirstName', '');
    localStorage.setItem('sw_secondOwnerLastName', '');
    localStorage.setItem('sw_secondOwnerDOB', '');
    if ((this.questions.radio_options[0].active == true || this.questions.radio_options[1].active == true) && (this.questions.radio_options_two[0].active == true || this.questions.radio_options_two[1].active == true)) {
      this.disable = false;
    }
    else {
      this.disable = true;
    }
    //this.disable = false;
  }

  onSubYes() {
    debugger;
    this.questions.radio_options_two[0].active = true;
    this.questions.radio_options_two[1].active = false;
    this.showvestinginfo = 1;
    localStorage.setItem('sw_vesting', 'yes');
    if ((this.questions.radio_options[0].active == true || this.questions.radio_options[1].active == true) && (this.questions.radio_options_two[0].active == true || this.questions.radio_options_two[1].active == true)) {
      this.disable = false;
    }
    else {
      this.disable = true;
    }
    if (this.vestingDetails == '') {
      this.disable = true;
    }
    else {
      this.disable = false;
    }
  }

  onSubNo() {
    debugger;
    this.questions.radio_options_two[0].active = false;
    this.questions.radio_options_two[1].active = true;
    this.showvestinginfo = 0;
    localStorage.setItem('sw_vesting', 'no');
    this.vestingDetails = '';
    if ((this.questions.radio_options[0].active == true || this.questions.radio_options[1].active == true) && (this.questions.radio_options_two[0].active == true || this.questions.radio_options_two[1].active == true)) {
      this.disable = false;
    }
    else {
      this.disable = true;
    }
  }

  vestingDetailsChange() {
    if (this.vestingDetails == '') {
      this.disable = true;
    }
    else {
      this.disable = false;
    }
  }

  next() {
    this.loader = true;
    localStorage.setItem('sw_secondOwnerFirstName', this.firstName);
    localStorage.setItem('sw_secondOwnerLastName', this.lastName);
    localStorage.setItem('sw_secondOwnerDOB', this.dob);
    localStorage.setItem('sw_vestingDetails', this.vestingDetails);

    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };
    json.stillwater['sw_secondOwner'] = localStorage.getItem('sw_secondOwner');
    json.stillwater['sw_vesting'] = localStorage.getItem('sw_vesting');
    json.stillwater['sw_vestingDetails'] = localStorage.getItem('sw_vestingDetails');
    json.stillwater['sw_secondOwnerFirstName'] = this.firstName;
    json.stillwater['sw_secondOwnerLastName'] = this.lastName;
    json.stillwater['sw_secondOwnerDOB'] = this.dob;
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(data => {
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0,
      };
      this.nextquestion22.emit(etty);
    }).catch(error => {
      console.log(error);
    });

    const updateJson = {
      questionCompletedStatus: true,
    };
    this.checkforResumeInit(updateJson, 'update');
    this.$gaService.event('Next', 'event', 'HomeAppSecondOwnerDetails');
  }

  async checkforResumeInit(inputJson, type) {
    debugger;
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion22.emit(etty);
  }


  inputdatechange() {
    debugger
    var K = this.dob.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.errorMessage = "Invalid Date"
      }
      else {
        this.errorMessage = "";
      }
      this.dob = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.errorMessage = 'Invalid Date';
        }
        else if(A<=12) {
          this.dob = A + '/' + B + '/';
          this.errorMessage = '';
        }
        else{
          this.errorMessage = 'Invalid Date';
        }
      }
      else {
        this.dob = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.dob = A + '/' + B + '/' + C;
    }
    else {
      this.dob = this.dob;
    }
    if (this.dob.length >= 10 && this.errorMessage != "Invalid Date") {
      this.checkDate(this.dob)
    }

  }

  checkDate(even) {
    debugger;
    if (even == null) {
      // this.curDate = new Date();
      // let year = Number(this.curDate.getFullYear());
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate() + 30;
      // if (month.length < 2)
      //  month = '0' + month;
      // if (day.length < 2)
      //  day = '0' + day;
      // this.dob=new Date(month + '/'+ day + '/' + year);
      this.dob = '';
      this.disable = true;
    }
    else {
      // const cur_d = new Date();
      // let d = new Date(even),
      //   month = '' + (d.getMonth() + 1),
      //   day = '' + d.getDate(),
      //   year = d.getFullYear();
      // const curYear = cur_d.getFullYear();
      // year = d.getFullYear();

      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.dob = month + '/' + day + '/' + year;
      this.dob = this.pc.momentdob(even);
    }
    debugger;
    if (this.dob == 'NaN/NaN/NaN') {
      this.errorMessage = 'Invalid Date';
      this.disable = false;
      this.dob = '';
    }
    else {
      const newDate = new Date(this.dob);
      console.log('DOB entered: ', newDate);
      const checkAge = this.validationService.validate_dob('oneTwentyAge', newDate);
      console.log('DOB entered: checkAge', checkAge);
      this.errorMessage = checkAge.message;
      this.disable = !checkAge.is_valid;
    }


  }

}
