import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-ko-personal-popup',
  templateUrl: './ko-personal-popup.component.html',
  styleUrls: ['./ko-personal-popup.component.css']
})
export class KoPersonalPopupComponent implements OnInit {

  personalForm = new FormGroup({
    bubble_email: new FormControl('', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'), Validators.required]),
    bubble_phone: new FormControl('', Validators.required),
    dob: new FormControl('', Validators.required,),
    tobaccoUsage: new FormControl('', Validators.required),
    bubble_heightFt: new FormControl('', [Validators.pattern('[1-9]{1}'), Validators.required]),
    bubble_heightIn: new FormControl('', [Validators.max(11), Validators.required]),
    bubble_weight: new FormControl('', [Validators.pattern('[0-9]{2,3}'), Validators.required]),
    desired_life_coverage: new FormControl('', [Validators.required, Validators.min(25000)]),
    desired_life_term: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    zipcode: new FormControl('', Validators.required),
  });
  phoneEvent: any;
  DateOfBirth: any;
  formLength: number;
  clientData: any;
  pd_pop_up: boolean;
  showloader: boolean;
  heightAvailable: boolean;
  height: string;
  tobacoselected: string;
  tobacoUsageDuration: Event;
  savedata: any;

  constructor(public pc: ProcessCacheService, public modal: NgbModal) { }

  @Input() personalDetails: any;
  @Input() detailsAvailable: any;
  @Input() summary = false;
  @Input() clientid: any;
  @Output() callApi: EventEmitter<any> = new EventEmitter();
  async ngOnInit(): Promise<void> {

    this.showloader = true;
    const idData = await this.pc.getAppData(this.clientid);
    this.clientData = JSON.parse(idData?.Payload)?.body;
    this.pd_pop_up = this.clientData.pd_pop_up == 'true' ? true : false;
    this.personalForm.setValue({ bubble_email: this.personalDetails.emailid ?? null, bubble_phone: this.phonelength(this.personalDetails?.phone) ?? null, dob: this.personalDetails.dob ?? null, gender: this.personalDetails.sbligender ?? null, zipcode: this.personalDetails.zipcode ?? null, tobaccoUsage: this.personalDetails.tobaccoUsage ?? null, bubble_heightFt: this.personalDetails.heightFt ?? null, bubble_heightIn: this.personalDetails.heightIn ?? null, bubble_weight: this.personalDetails.weight ?? null, desired_life_coverage: this.personalDetails.coverage ?? null, desired_life_term: this.personalDetails.termLength ?? null });
    this.summary ? (this.personalForm.removeControl('desired_life_coverage'), this.personalForm.removeControl('desired_life_term')) : 0;
    this.detailsAvailable ? 0 : (this.removeControls(), this.hubspotApplicationStage('Lead'));
    this.showloader = false;
    console.log('this.personalForm this.personalForm', this.personalForm);
  }

  selectgender(event) {
    this.personalForm.patchValue({ gender: event });
  }

  public phonelength(phonenumber) {

    let count = 0;
    phonenumber = phonenumber?.replace(/[^\d\+]/g, '');
    const phonestring = phonenumber?.toString().slice(0, phonenumber?.length);
    phonenumber = phonestring?.replace(/(\d{3})-?/g, function (m, a) {
      return ++count <= 2 ? a + '-' : m;
    });
    phonenumber = phonenumber?.slice(0, 12);
    //this.personalForm.controls['phone'].setValue(phonenumber);
    return phonenumber;
  }

  public phoneValidation(event) {

    this.phoneEvent = event.target.value;
    let count = 0;
    let phonenumber;
    if (this.phoneEvent[this.phoneEvent.length - 1] != '-' && (this.phoneEvent.length != 7 && this.phoneEvent.length != 3)) {
      phonenumber = this.personalForm.value.bubble_phone?.replace(/[^\d\+]/g, '');
      const phonestring = phonenumber?.toString().slice(0, this.personalForm.value.bubble_phone?.length);
      phonenumber = phonestring?.replace(/(\d{3})-?/g, function (m, a) {
        return ++count <= 2 ? a + '-' : m;
      });
      phonenumber = phonenumber?.slice(0, 12);
      this.personalForm.patchValue({ bubble_phone: phonenumber, onlySelf: true, emitEvent: false });
    }
    else {
      const index = this.personalForm.value.bubble_phone.toString().lastIndexOf('-');
      const removed = this.phoneEvent.substring(0, index - 1);
      this.personalForm.value.bubble_phone = removed;
    }
  }

  dateValidation(event) {

    this.DateOfBirth = event.target.value;
    if (this.DateOfBirth[this.DateOfBirth.length - 1] != '/') {
      const inputLength = event.target.value.length;
      if (event.keyCode != 8) {
        if (inputLength === 2 || inputLength === 5) {
          var thisVal = event.target.value;
          thisVal += '/';
          this.personalForm.patchValue({
            dob: thisVal
          });
        }
        else if (inputLength == 10) {
          var thisVal = event.target.value;
          const dob = thisVal.split('/');
          if (Number(dob[0] > 12) || Number(dob[1] > 31 || Number(dob[2]) >= Number(new Date().getFullYear()))) {
            this.personalForm.controls.dob.setErrors({ 'incorrect': true });
          }
          else {
            this.personalForm.patchValue({
              dob: this.pc.momentdob(thisVal)
            });
          }
        }
      }
      // const K = this.DateOfBirth.replace(/[{(/)}]/g, '');
      // const A = K.substring(0, 2);
      // const B = K.substring(2, 4);
      // const C = K.substring(4, 8);
      // if (A != '' && A.length == 2 && B == '') {
      //   this.DateOfBirth = A + '/';
      // }
      // else if (B != '' && C == '' && A.length == 2) {
      //   if (B.length == 2) {
      //     if (B < 31) {
      //       this.DateOfBirth = A + '/' + B + '/';
      //     }
      //     else {
      //       this.DateOfBirth = A + '/';
      //     }
      //   }
      // }
      // else if (C != '' && B.length == 2) {
      //   this.DateOfBirth = A + '/' + B + '/' + C;
      // }
      // this.personalForm.patchValue({
      //   dob: this.DateOfBirth.slice(0, 12)
      // });
      //this.personalForm.value.dob = this.DateOfBirth.slice(0, 12);
    }
    else {
      const index = this.personalForm.value.dob.toString().lastIndexOf('/');
      const removed = this.DateOfBirth.substring(0, index);
      this.personalForm.value.dob = removed;
    }

  }

  ChangetobaccoUsage(even) {

    this.tobacoselected = even.target.value === '5' ? 'No' : 'Yes';
    this.tobacoUsageDuration = even.target.value;

    if (even.target.value === '5') {
      this.personalForm.addControl('tobacoUser', new FormControl('No', Validators.required));
    }
    else {
      this.personalForm.addControl('tobacoUser', new FormControl('Yes', Validators.required));
    }
  }

  async removeControls() {
    for (const [key, value] of Object.entries(this.personalForm.value)) {
      if (value != undefined) {
        await this.personalForm.removeControl(key);
      }
    }
    this.formLength = Object.keys(this.personalForm.value).length;
    console.log(' this.formLength ', this.formLength);
  }

  onSubmit() {

    const savedata = {
      'id': this.clientid,
      'clientId': this.clientid,
    };
    this.summary ? 0 : (savedata['pd_pop_up'] = 'true', this.pd_pop_up = true);
    for (const [key, value] of Object.entries(this.personalForm.value)) {
      savedata[key] = value;
      key == 'tobacoUser' ? (savedata['tobacoselected'] = this.tobacoselected, savedata['tobacoUsageDuration'] = this.tobacoUsageDuration) : 0;
      if (key == 'dob') {
        savedata['age'] = Number(Math.floor((Number(Math.abs(Date.now() - new Date(savedata['dob']).getTime())) / (1000 * 3600 * 24)) / 365));
      }

    }
    console.log(savedata);
    this.savedata = savedata;
    this.pc.saveUpdateClientData(savedata);
    this.hubspotApplicationStage('Quote');
    this.callApi.emit();
    this.modal.dismissAll();
  }

  dismissModal() {
    this.modal.dismissAll();
  }

  hubspotApplicationStage(event) {

    this.heightAvailable = false;
    const hubspotKeysMap = {
      bubble_email: 'email',
      bubble_phone: 'phone',
      dob: 'dob_life',
      tobaccoUsage: 'tobacco_user',
      height: 'height',
      bubble_weight: 'weight',
      desired_life_coverage: 'desired_life_coverage',
      desired_life_term: 'desired_life_term',
      gender: 'gender',
      zipcode: 'zip'
    };
    const hubspotData = {
      email: this.clientData.bubble_email,
      user_landed_reco_page: event == 'Quote' ? 'Yes' : '',
      application_stage: event,
      pd_pop_up: this.pd_pop_up ? 'Submit' : '',
    };
    for (const [key, value] of Object.entries(this.personalForm.value)) {

      ['bubble_heightFt', 'bubble_heightIn'].includes(key) ? this.heightAvailable = true : hubspotData[hubspotKeysMap[key]] = value;
      key == 'tobaccoUsage' && value == '5' ? hubspotData['smoker'] = 'No' : key == 'tobaccoUsage' && value != '5' ? hubspotData['smoker'] = 'Yes' : 0;
    }
    this.heightAvailable ? (this.height = this.pc.validateHeightHubspot(this.personalForm.value.bubble_heightFt + '\'' + this.personalForm.value.bubble_heightIn + '"'), hubspotData['height'] = this.height) : 0;
    if (this.savedata != undefined) {
      if (this.savedata['age']) {
        hubspotData['age'] = this.savedata['age'];
      }
    }
    hubspotData['zip'] = this.clientData['zip'] ? this.clientData['zip'] : this.clientData['zip_code'];
    hubspotData['desired_life_coverage'] = this.clientData['desired_life_coverage'];
    hubspotData['coverage_term_life'] = this.clientData['desired_life_coverage'];
    hubspotData['desired_life_term'] = this.clientData['desired_life_term'];
    if (this.clientData['tobaccoUsage']) {
      hubspotData['tobacco_user'] = this.clientData['tobaccoUsage'] == '5' ? 'No' : 'Yes';
    }
    if (this.clientData['lead_source']) {
      hubspotData['lead_source'] = this.clientData['lead_source'];
    }
    if (this.clientData['short_url']) {
      hubspotData['short_url'] = this.clientData['short_url'];
    }
    if (this.clientData['dropoffURL']) {
      hubspotData['dropoff_url'] = this.clientData['dropoffURL'];
    }
    // if (this.clientData['gender']) {
    //   hubspotData['gender'] = this.clientData['gender'];
    // }

    if (this.personalForm.value.bubble_email) {
      localStorage.setItem('bubble-email', this.personalForm.value.bubble_email);
    }
    this.pc.hubSpotSyncAfterEmailFetch(hubspotData);
  }

}
