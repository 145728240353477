import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-sbli-app-flow-footer',
  templateUrl: './sbli-app-flow-footer.component.html',
  styleUrls: ['./sbli-app-flow-footer.component.css']
})
export class SbliAppFlowFooterComponent implements OnInit {
  @Input() selected;
  @Input() carrier;
  @Input() imgURL;
  @Input() amount;
  @Input() imgwidth;
  @Input() insuranceType;
  @Input() hselected;
  @Input() hcarrier;
  @Input() himgURL;
  @Input() hamount;
  @Input() himgwidth;
  constructor() { }

  ngOnInit(): void {
    debugger
    this.insuranceType = this.insuranceType;
    this.selected = this.selected;
    this.carrier = this.carrier;
    this.imgURL = this.imgURL;
    this.amount = this.amount;
    this.imgwidth = this.imgwidth;  
    this.hselected = this.hselected;
    this.hcarrier = this.hcarrier;
    this.himgURL = this.himgURL;
    this.hamount = this.hamount;
    this.himgwidth = this.himgwidth;
  }

}
