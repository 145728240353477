import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli-payment',
  templateUrl: './sbli-payment.component.html',
  styleUrls: ['./sbli-payment.component.css']
})
export class SbliPaymentComponent implements OnInit {
  showLoader = true;
  isDisable: boolean;
  questions: any;
  paymentType: any;
  premium: number;
  sameBillingAddress: any;
  // lastName: any;
  // firstName: any;
  address: any;
  expirationDate: any;
  cvv: any;
  @Output() changeQuestion = new EventEmitter();
  clientId: string;
  existingData: any;
  Show: boolean;
  bankRoutingNo: any;
  bankAccountType: any;
  Bankname: any;
  bankAccountNumber: any;
  anualPremium: string;
  monthlyPremium: string;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      application_stage: 'SBLI_Payment'
    }
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    //this.isDisable = true;
    this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli-payment')[0];
    console.log('app-sbli6pt54 questions : ', this.questions);
    this.paymentType = 'EFT';
    this.bankAccountType = 'Bank Acoount Type';


    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      // this.anualPremium = "Annually ($" + dataFrom.body["sbli_premiumAnnually"] + ")";

      this.anualPremium = parseFloat(dataFrom.body['sbli_premiumAnnually']).toFixed(2);
      this.anualPremium = 'Annually ($' + this.anualPremium + ')';


      this.monthlyPremium = parseFloat(dataFrom.body['sbli_premiumMonthly']).toFixed(2);
      this.monthlyPremium = 'Monthly ($' + this.monthlyPremium + ')';

      this.premium = 0;

      this.existingData = dataFrom.body.SBLI;
      // if (this.existingData.sbli_paymentDetails.sbli_paymentFirstName != undefined) {
      //   this.premium = this.existingData.sbli_paymentDetails.sbli_paymentFrequency;
      //   this.firstName = this.existingData.sbli_paymentDetails.sbli_paymentFirstName;
      //   this.lastName = this.existingData.sbli_paymentDetails.sbli_paymentLastName;
      //   this.bankAccountType = this.existingData.sbli_paymentDetails.sbli_bankAccountType;
      //   this.bankRoutingNo = this.existingData.sbli_paymentDetails.sbli_bankRoutingNo;
      //   this.Bankname = this.existingData.sbli_paymentDetails.sbli_bankName;
      //   this.bankAccountNumber = this.existingData.sbli_paymentDetails.sbli_bankAccountNumber;
      // }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });

  }






  selectpayment(event) {
    this.paymentType = 'EFT';
  }

  selectpremium(event) {
    debugger;
    this.premium = event;
  }


  slectBankType(event) {
    this.bankAccountType = event.target.value;
  }




  next() {
    debugger
    this.pc.lifesummary({ 'payment' :'submit' });
    this.$gaService.event('SBLI LifeApplication Payment Method', 'event', 'SBLI-LifeApplication-Payment-Method');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Payment',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Payment Method', 'event', 'SBLI-LifeApplication-Payment-Method');
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };


    const sbli_paymentDetails = {
      // 'sbli_paymentType': this.existingData.sbli_paymentDetails.sbli_paymentType,
      'sbli_payMethod': this.paymentType,
      'sbli_paymentFrequency': this.premium,
      // 'sbli_paymentFirstName': this.firstName,
      // 'sbli_paymentLastName': this.lastName,
      'sbli_bankAccountType': this.bankAccountType,
      'sbli_bankRoutingNo': this.bankRoutingNo,
      'sbli_bankName': this.Bankname.replace(/(^\s+|\s+$)/g, ''),
      'sbli_bankAccountNumber': this.bankAccountNumber
    };

    json.SBLI['sbli_paymentDetails'] = sbli_paymentDetails;


    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1,
      'APICall': 6
    };


    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }


}
