import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-status-completion',
  templateUrl: './status-completion.component.html',
  styleUrls: ['./status-completion.component.css']
})
export class StatusCompletionComponent implements OnInit {
  circleTextClass
  circlePercentage
  circleText
  circleClass
  constructor() { }

  ngOnInit(): void {
    this.circleTextClass = 'text-40';
    this.circlePercentage = 'progress-60';
    this.circleText = 'A-';
    this.circleClass=90;

  }

  
}
