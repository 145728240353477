import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild, HostListener } from '@angular/core';
import { formatDate, Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileDataHandlerService } from 'src/app/shared/profile-data-handler.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ValidationService } from './../../../shared/validation.service';
import { getHubSpotInterestType, getHubSpotAppStage } from 'src/app/shared/UtilityFunctions.js';
import { HttpClient } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import * as LogRocket from 'logrocket';
@Component({
  selector: 'app-life-question1',
  templateUrl: './life-question1.component.html',
  styleUrls: ['./life-question1.component.css']
})
export class LifeQuestion1Component implements OnInit {
  @HostListener('document:backbutton', ['$event'])
  onPopState(event) {
    alert('Start page');
  }
  @Output() nextquestion1 = new EventEmitter();
  @Output() changezipcode = new EventEmitter();
  maleicon: any;
  femaleicon: any;
  genderSelected: any;
  age: any;
  fullName: any;
  //tyod: any;
  dob: any;
  curDate: any;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  isdisable = true;
  insurancetype: any;
  showspanfordob;
  minDate;
  maxDate;
  message: string;
  is_valid: boolean;
  zipcode: any;
  inputAddress: any;
  state: any;
  url: any;
  validateState: Object;
  loader: boolean;
  display: string;
  allowedStatesString: any;
  validaddress: any;
  showerror: boolean;
  constructor(private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    public validationService: ValidationService,
    private utilityService: UtilityFunctionsService,
    private http: HttpClient,
    private modalService: NgbModal,
    private location: Location,
    private $gaService: GoogleAnalyticsService) { }
  ngOnInit(): void {
    debugger;
    localStorage.removeItem("bubble-coverage");
    localStorage.removeItem("bubble-termLength");
    localStorage.removeItem("bubble-age");
    localStorage.removeItem('recopageheadtext')
    this.display = 'none';
    this.url = window.location.href;
    this.isdisable = true;
    history.pushState(null, '');

    this.pc.logPageLandingtoBO({ page_name: 'dob_gender' });


    // debugger

    // window.addEventListener
    // ('beforeunload'
    //   , function (evt) {
    //     debugger
    //     evt.preventDefault();
    //     evt.returnValue = 'Hello';
    //     return "hello 2222"
    //   }
    // );


    // this.curDate = new Date();
    // let year = Number(this.curDate.getFullYear()) - 18;
    // let month = '' + (this.curDate.getMonth() + 1);
    // let day = '' + this.curDate.getDate();
    // if (month.length < 2)
    //   month = '0' + month;
    // if (day.length < 2)
    //   day = '0' + day;

    // this.maxDate = new Date(month + '/' + day + '/' + year);

    this.curDate = new Date();
    const yearm = Number(this.curDate.getFullYear()) - 55;
    let monthm = '' + (this.curDate.getMonth() + 1);
    let daym = '' + this.curDate.getDate();
    if (monthm.length < 2)
      monthm = '0' + monthm;
    if (daym.length < 2)
      daym = '0' + daym;

    this.minDate = new Date(monthm + '/' + daym + '/' + yearm);

    const year = Number(this.curDate.getFullYear());
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.maxDate = new Date(month + '/' + day + '/' + year);


    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      this.inputAddress = this.uniqueId.split('-');
      this.inputAddress.shift();
      this.inputAddress = this.inputAddress.join(' ');
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
      // LogRocket.identify(this.clientId);
    }
    );
    if (localStorage.getItem('bubble-zip') == null || localStorage.getItem('bubble-zip') == undefined || localStorage.getItem('bubble-zip') == '' || localStorage.getItem('bubble-zip') == 'undefined') {
      this.zipcode = '';
    }
    else {
      this.zipcode = localStorage.getItem('bubble-zip');
    }

    this.pc.parseAddressLocalAndPatch(this.inputAddress, this.clientId).then(
      data => {
        this.state = data.state_code;
      }
    ).catch(error => console.log('error', error));

    this.fullName = localStorage.getItem('bubble-fullName');
    if (localStorage.getItem('bubble-dob') !== null && localStorage.getItem('bubble-dob') !== '' && localStorage.getItem('bubble-dob') !== undefined &&localStorage.getItem('bubble-dob') !== 'undefined') {
      this.showspanfordob = false;
      this.dob = new Date(localStorage.getItem('bubble-dob'));
      this.checkDate(this.dob);
    }
    if (this.dob == undefined || this.dob == null || this.dob == 'undefined') {

      this.showspanfordob = false;

      // this.curDate = new Date();
      // let year = Number(this.curDate.getFullYear()) - 18;
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.dob = new Date(month + '/' + day + '/' + year);

      this.isdisable = true;

    }

    if (localStorage.getItem('bubble-gender') !== null && localStorage.getItem('bubble-gender') !== 'null' && localStorage.getItem('bubble-gender') !== 'undefined') {
      if (localStorage.getItem('bubble-gender').toLowerCase() == 'female') {
        this.genderSelected = '0';
      }
      else if (localStorage.getItem('bubble-gender').toLowerCase() == 'male') {
        this.genderSelected = '1';
      }
      else {
        this.genderSelected = localStorage.getItem('bubble-gender');
      }

    }
    else {
      this.genderSelected = '1';
    }
    this.maleicon = 'assets/images/images/icon-male.png';
    this.femaleicon = 'assets/images/images/ic-female.png';
    if (this.genderSelected == undefined || this.genderSelected == null || this.genderSelected == 'undefined' || this.genderSelected == 'null') {
      //this.genderSelected = "1";
      this.isdisable = true;
    }
    if (this.genderSelected == '1') {
      localStorage.setItem('bubble-gender', '1');
      document.getElementById('male_1').className = 'gender active';
      document.getElementById('female_1').className = 'gender';
      this.maleicon = 'assets/images/active/icon-male-active.svg';
      this.femaleicon = 'assets/images/images/ic-female.png';
      this.isdisable = false;
    }
    if (this.genderSelected == '0') {
      localStorage.setItem('bubble-gender', '0');
      document.getElementById('male_1').className = 'gender';
      document.getElementById('female_1').className = 'gender active';
      this.maleicon = 'assets/images/images/icon-male.png';
      this.femaleicon = 'assets/images/active/icon-female-active.svg';
      this.isdisable = false;
    }
    this.age = '';
  }

  next() {
    debugger;
    this.loader = true;
    localStorage.removeItem('bubble-termLength');
    if (this.insurancetype == 'homeLife') {
      this.http.get(this.pc.envCred.api2Domain + '?data_category=validateZip4LicensedState&dget0=life' + '&dget1=' + this.zipcode).subscribe(data => {

        this.validateState = data;
        if (this.validateState['dput2']) {
          getHubSpotAppStage(window.location.href);
          const today = new Date();
          const birthDate = new Date(this.dob);
          const ageLocal = today.getFullYear() - birthDate.getFullYear();
          console.log('age : ', ageLocal);
          localStorage.setItem('bubble-dob', this.dob);
          localStorage.setItem('bubble-age', ageLocal.toString());
          localStorage.setItem('bubble-gender', this.genderSelected);
          localStorage.setItem('Pagetype', 'Q2');
          localStorage.setItem('bubble-zip', this.zipcode);
          // const profileDataHandlerService = new ProfileDataHandlerService();
          console.log('Before:insuredInfo:getSetInsuredInfoFromZip:this.zipcode:', this.zipcode);
          debugger;
          const application_stage = getHubSpotAppStage(this.url);
          const json = {
            'id': this.clientId,
            'clientId': this.clientId,
            'dob': this.dob,
            'age': ageLocal,
            'gender': this.genderSelected,
            'application_stage': application_stage,
          };
          if (this.zipcode) {
            new ProfileDataHandlerService().getSetInsuredInfoFromZip(this.zipcode).then(insuredInfo => {
              console.log('insuredInfo: ', insuredInfo);
              json['insuredInfo'] = insuredInfo;
              json['state'] = insuredInfo.insuredState;
              json['zip'] = insuredInfo.insuredZipcode;
              json['sbli_questionNumber'] = 0;
              json['wns_questionNumber'] = 0;
              json['dob'] = this.dob;
              json['gender'] = this.genderSelected;
              localStorage.setItem('bubble-state', insuredInfo.insuredState);
              localStorage.setItem('bubble-zip', insuredInfo.insuredZipcode);
              localStorage.setItem('bubble-city', insuredInfo.insuredCity);
              this.pc.saveUpdateClientData(json);
            });
          }
          else {
            this.pc.saveUpdateClientData(json);
          }
          const etty = {
            'questionNo': '1',
            'genderSelected': this.genderSelected,
            'bubble-gender': this.genderSelected,
            'nextprev': 1
          };
          this.nextquestion1.emit(etty);
          this.loader = false;
        }
        else {
          const today = new Date();
          const birthDate = new Date(this.dob);
          const ageLocal = today.getFullYear() - birthDate.getFullYear();
          const json = {
            'id': this.clientId,
            'clientId': this.clientId,
            'dob': this.dob,
            'age': ageLocal,
            'gender': this.genderSelected
          };
          this.pc.saveUpdateClientData(json);
          const allowedStates = this.validateState['dput1'].filter(item => item['golivelife'] == 'Y');
          this.allowedStatesString = '';
          if (allowedStates.length == 1) {
            this.allowedStatesString = allowedStates[0]['state'];
          } else {
            for (let i = 0; i < allowedStates.length; i++) {
              this.allowedStatesString += allowedStates[i]['state'] + ', ';
            }
          }
          this.allowedStatesString = 'We currently serve only in ' + this.allowedStatesString;
          this.allowedStatesString = this.allowedStatesString.replace(/,\s*$/, '.');
          this.allowedStatesString = this.allowedStatesString.replace(/,(?=[^,]*$)/, ' and');
          console.log('allowedStatesString', this.allowedStatesString);
          this.Openmodel();
          this.loader = false;
        }
      });
    }
    else {
      getHubSpotAppStage(window.location.href);
      const today = new Date();
      const birthDate = new Date(this.dob);
      const ageLocal = today.getFullYear() - birthDate.getFullYear();
      console.log('age : ', ageLocal);
      localStorage.setItem('bubble-dob', this.dob);
      localStorage.setItem('bubble-age', ageLocal.toString());
      localStorage.setItem('bubble-gender', this.genderSelected);
      localStorage.setItem('Pagetype', 'Q2');
      localStorage.setItem('bubble-zip', this.zipcode);
      // const profileDataHandlerService = new ProfileDataHandlerService();
      console.log('Before:insuredInfo:getSetInsuredInfoFromZip:this.zipcode:', this.zipcode);
      debugger;
      const application_stage = getHubSpotAppStage(this.url);
      const json = {
        'id': this.clientId,
        'clientId': this.clientId,
        'dob': this.dob,
        'age': ageLocal,
        'gender': this.genderSelected,
        'application_stage': application_stage,
      };

      if (this.zipcode) {
        new ProfileDataHandlerService().getSetInsuredInfoFromZip(this.zipcode).then(insuredInfo => {
          console.log('insuredInfo: ', insuredInfo);
          json['insuredInfo'] = insuredInfo;
          json['state'] = insuredInfo.insuredState;
          json['zip'] = insuredInfo.insuredZipcode;
          json['sbli_questionNumber'] = 0;
          json['wns_questionNumber'] = 0;
          localStorage.setItem('bubble-state', insuredInfo.insuredState);
          localStorage.setItem('bubble-zip', insuredInfo.insuredZipcode);
          localStorage.setItem('bubble-city', insuredInfo.insuredCity);
          this.pc.saveUpdateClientData(json);
        });
      }
      else {
        this.pc.saveUpdateClientData(json);
      }

      const etty = {
        'questionNo': '1',
        'genderSelected': this.genderSelected,
        'bubble-gender': this.genderSelected,
        'nextprev': 1
      };
      this.nextquestion1.emit(etty);
      this.loader = false;
    }


    /////// id mapping
    const clientIdLocal = localStorage.getItem('clientID');
    const customerIdLocal = localStorage.getItem('customer_id');
    const interestType = getHubSpotInterestType(this.insurancetype);

    const idMappingJson = {
      client_id: clientIdLocal,
      customer_id: customerIdLocal,
      status: interestType,
      interest_type: interestType,
      dob: this.dob
    };
    debugger;
    this.pc.createIdMapping(idMappingJson);

    const emailLocal = localStorage.getItem('bubble-email');
    const today = new Date();
    const birthDate = new Date(this.dob);
    const ageLocal = today.getFullYear() - birthDate.getFullYear();
    if (emailLocal) {
      const hubSpotData = {
        customer_id: localStorage.getItem('customer_id'),
        email: emailLocal,
        dob_life: localStorage.getItem('bubble-dob'),
        gender: localStorage.getItem('bubble-gender'),
        postal_code_life: localStorage.getItem('bubble-zip'),
        application_stage: 'Quote',
        'age': ageLocal,
      };
      try {
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      } catch (error) {
        console.error('hubSpotSyncAfterEmailFetch:hubSpotData: ', hubSpotData, ' # error:', error);
      }
    }
    //////// GTM Tag Manager event creation /////////
    this.$gaService.event('Proceed', 'event', 'MyLifeStep1Proceed');
    const eventOptions: EventOption = {
      path: 'QuoteFlowV2',
      page_title: 'Personal Details',
      url: window.location.href
    };

    this.utilityService.eventWrapped(eventOptions, 'Proceed', 'event', 'MyLifeStep1Proceed');
  }

  previous() {
    const today = new Date();
    const birthDate = new Date(this.dob);
    const ageLocal = today.getFullYear() - birthDate.getFullYear();

    localStorage.setItem('bubble-dob', this.dob);
    localStorage.setItem('bubble-age', ageLocal.toString());
    localStorage.setItem('bubble-gender', this.genderSelected);

    const etty = {
      'questionNo': '1',
      'genderSelected': this.genderSelected,
      'bubble-gender': this.genderSelected,
      'nextprev': 0
    };
    this.nextquestion1.emit(etty);
  }

  gender(gen) {
    this.genderSelected = gen;
    if (gen == '1') {
      localStorage.setItem('bubble-gender', '1');
      document.getElementById('male_1').className = 'gender active';
      document.getElementById('female_1').className = 'gender';
      this.maleicon = 'assets/images/active/icon-male-active.svg';
      this.femaleicon = 'assets/images/images/ic-female.png';
    }
    else {
      localStorage.setItem('bubble-gender', '0');
      document.getElementById('male_1').className = 'gender';
      document.getElementById('female_1').className = 'gender active';
      this.maleicon = 'assets/images/images/icon-male.png';
      this.femaleicon = 'assets/images/active/icon-female-active.svg';
    }
  }


  inputdatechange() {
    const K = this.dob.replace(/[{(/)}]/g, '');
    const A = K.substring(0, 2);
    const B = K.substring(2, 4);
    const C = K.substring(4, 8);
    if (A != '' && A.length == 2 && B == '') {
      if (A.length == 2 && A > 12) {
        this.message = 'Invalid Date';
      }
      else {
        this.message = '';
      }
      this.dob = A + '/';
    }
    else if (B != '' && C == '' && A.length == 2) {
      if (B.length == 2) {
        if (B > 31) {
          this.message = 'Invalid Date';
        }
        else if (A <= 12) {
          this.dob = A + '/' + B + '/';
          this.message = '';
        }
        else {
          this.message = 'Invalid Date';
        }

      }
      else {
        this.dob = A + '/' + B;
      }
    }
    else if (C != '' && B.length == 2) {
      this.dob = A + '/' + B + '/' + C;
    }
    else {
      this.dob = this.dob;
    }

    if (this.dob.length >= 10 && this.message != 'Invalid Date') {
      this.isdisable = false;
      this.checkDate(this.dob);
    }
    else {
      this.isdisable = true;
    }
  }


  checkDate(even) {
    debugger;
    if (even == null) {
      this.isdisable = true;
      // this.isdisable = false;
      // this.curDate = new Date();
      // let year = Number(this.curDate.getFullYear()) - 18;
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.dob = new Date(month + '/' + day + '/' + year);
      this.dob = this.pc.momentdob(this.curDate);
    }
    else {
      this.isdisable = false;
      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();

      const ageYear = Number(curYear) - Number(year);
      if (ageYear >= 18 && ageYear <= 55) {
        this.showspanfordob = false;
        this.isdisable = false;
        this.message = '';
      } else {
        this.showspanfordob = true;
        this.isdisable = true;
      }
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.dob = this.pc.momentdob(even);
    }
    if (this.dob == 'NaN/NaN/NaN') {
      this.dob = '';
      this.message = 'Invalid Date';
      this.is_valid = false;
    }
    else {
      const newDate = new Date(even);
      console.log('DOB entered: ', newDate);
      const checkAge = this.validationService.validate_dob('common_age', newDate);
      console.log('DOB entered: checkAge', checkAge);
      this.message = checkAge.message;
      this.is_valid = checkAge.is_valid;
    }

    // let checkDob = this.validationService.validate_dob("beneficiary_dob", newDate);
    // console.log("DOB entered: checkDob", checkDob);
    // const format = 'yyyy-MM-dd';
    // const myDate = new Date();
    // const locale = 'en-US';
    // let ctyod = formatDate(myDate, format, locale);

    // let date = new Date(this.dob);
    // let enteredYear = date.getFullYear();
    // let dates = new Date();
    // let currentYear = dates.getFullYear();

    // if (this.dob >= ctyod) {
    //   this.dob = this.tyod;
    // }
    // else if ((Number(currentYear)-Number(enteredYear))<18) {
    //   this.dob = this.tyod;
    // }



  }



  openModal(msg) {

    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
        // keyboard: false,
        // backdrop: 'static'
      });

    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: msg,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }


  Openmodel() {
    debugger;
    //this.display = "block";

    document.getElementById('are_u_sure').style.display = 'block';

  }

  onCloseHandled() {
    //this.display = "none";
    document.getElementById('are_u_sure').style.display = 'none';
  }

  skipLife() {
    this.loader = true;
    this.display = 'none';
    location.href = `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/home`;
  }

  public changeAddress() {
    if (this.zipcode.toString().length == 5) {
      this.http.get(`https://api2.getmybubble.io/fetch1?data_category=ZipCityCountyState&version=v1&dget1=${this.zipcode}`).subscribe(data => {
        debugger;
        if (data != null) {
          this.validaddress = JSON.parse(data['output']);
          this.displayAddress = this.validaddress['dput5'] + '-' + this.validaddress['dput2'] + '-' + this.validaddress['dget1'];
          this.location.go(`QuoteFlowV2Life/${this.uniqueId}/${this.clientId}/${this.displayAddress}/homeLife`);
          this.showerror = false;
          this.changezipcode.emit(this.displayAddress);
        }
        else {
          this.showerror = true;
        }

      });
    }

  }

}
