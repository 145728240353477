import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs'
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-score-card',
  templateUrl: './score-card.component.html',
  styleUrls: ['./score-card.component.css']
})

export class ScoreCardComponent implements OnInit, OnDestroy {

  @Output() customise = new EventEmitter();

  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  options = "{types: ['address'], componentRestrictions: { country: 'USA'}}";
  scoreCardJSON: any;
  tipsandInsights: any;
  reqheight: string;
  formattedAddress: string;
  displayAddress: any;
  uniqueId: any;
  clientId: any;
  disableAddress = false;
  allScores: any;

  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  lifeScoreData: any;
  hazardScoreData: any;
  houseHoldScoreData: any;
  UrbanPopulationScoreData: any;
  mortgageAmount: any;
  gender = "1";
  age = "30";
  latitude: number = 39.7789467;
  longitude: number = -105.0477277;
  addresstype = "Home Address";

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.gender = params['gender'];
      this.age = params['age'];
      console.log("uniqueId : ", this.uniqueId);
      console.log("clientId : ", this.clientId);
      console.log("displayAddress : ", this.displayAddress);
      console.log("gender : ", this.gender);
      console.log("age : ", this.age);
      this.pc.getCachedData2(); let county = 'Autauga County';
      let state = 'AL';
      this.scoreApi.getLifeScoreWithGenderAge(county, state, this.gender.toString(), this.age.toString());
      this.scoreApi.castLifescore.subscribe(
        (data: any) => {
          this.lifeScoreData = data;
          this.lifeScoreData.showScore = true;
          this.allScores = this.lifeScoreData.scores;
          console.log("all scores for score card : ", this.allScores);
        },
        (error) => console.log(error)
      );

    }
    )
    if (window.innerWidth < 600) {
      this.reqheight = "height:40vh;";
    } else {
      this.reqheight = "height:100vh;";
    }
    this.scoreApi.castLifescore.subscribe(
      (data: any) => {
        this.lifeScoreData = data;
        console.log("lifeScoreData: ", this.lifeScoreData);
      },
      (error) => console.log(error)
    );
    //this.latitude = 39.7789467;
    //this.longitude = -105.0477277;
    // this.hazardScoreData = {
    //   success: false,
    //   hazardScore: "NA",
    //   grade: "Average"
    // }
    // this.houseHoldScoreData = {
    //   success: false,
    //   houseHoldScore: 0.00,
    //   grade: "Average"
    // }
    // this.UrbanPopulationScoreData = {
    //   success: false,
    //   UrbanPopulationScore: 0.00,
    //   grade: "Average"
    // }
    this.scoreCardJSON = [
      {
        scoreTitle: "Life Score",
        success: false,
        score: "NA",
        gradetext: "(600-1000)",
        grade: "Average",
        accordianTitle: "Better than 90% of the rest of California"
      },
      {
        scoreTitle: "Hazard Score",
        success: false,
        score: "NA",
        gradetext: "Calculated from HazardHub",
        grade: "Average",
        accordianTitle: "Better than 90% of the rest of California"
      },
      {
        scoreTitle: "House Hold Score",
        success: false,
        score: "NA",
        gradetext: "Income",
        grade: "Average",
        accordianTitle: "Better than 90% of the rest of California"
      }
    ];
  }

  public handleAddressChange(address: Address) {
    //this.processCache.getDataFromCache();
    console.log('address from google : ', address);
    console.log('formatted address : ', address.formatted_address);
    this.formattedAddress = address.formatted_address;
    this.latitude = address.geometry.location.lat();
    this.longitude = address.geometry.location.lng();
    this.pc.latitude = this.latitude;
    this.pc.longitude = this.longitude;
    /* console.log("@@---->", address.geometry.location);*/
    console.log("@@---->latitude", address.geometry.location.lat());
    console.log("@@---->longitude", address.geometry.location.lng());
    this.pc.initCaching(address.formatted_address);
    this.pc.getCachedData2();
    let dataObserver = this.scoreApi.getLifeScore(address.address_components[0].short_name, address.address_components[0].short_name);

    let county = 'Autauga County';
    let state = 'AL';
    this.scoreApi.getLifeScoreWithGenderAge(county, state, this.gender.toString(), this.age.toString());
    // for (let i = 0; i < address.address_components.length; i++) {
    //   if (address.address_components[i].types) {
    //     if (address.address_components[i].types.includes('administrative_area_level_2')) {
    //       county = address.address_components[i].short_name;
    //     }
    //     if (address.address_components[i].types.includes('administrative_area_level_1')) {
    //       state = address.address_components[i].short_name;
    //     }
    //   }
    // }
    // // county = county.replace('County', '').replace(/\s/g, '');
    // console.log("County:", county, "State:", state);
    // dataObserver = this.scoreApi.getLifeScore(county, state);
    // // debugger
    // // console.log('All scores:', dataObserver);
    // if (dataObserver === undefined) {
    //   this.lifeScoreData = {
    //     success: false,
    //     lifeScore: 0.00,
    //     percentile: 0.00,
    //     showScore: true
    //   }
    // }
    debugger
    console.log('LifeScore Data:', this.lifeScoreData);
    console.log("all scores for score card : ", this.lifeScoreData);

  }

  public acc() {
    var acc = document.getElementsByClassName("accordion")
    var i;
    for (i = 0; i < acc.length; i++) {
      acc[i].addEventListener("click", function () {
        this.classList.toggle("active");
        var panel = this.nextElementSibling;
        if (panel.style.display === "block") {
          panel.style.display = "none";
        } else {
          panel.style.display = "block";
        }
      });
    }
  }

  ngOnDestroy() {
  }

  navigateTo() {
    var etty = {
      id: this.uniqueId,
      clientId: this.clientId,
      displayAddress: this.displayAddress
    }
    this.customise.emit(etty);
    //location.href = "Customise/" + this.uniqueId + "/" + this.clientId + "/" + this.displayAddress;
  }

}
