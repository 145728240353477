import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-life-question4',
  templateUrl: './life-question4.component.html',
  styleUrls: ['./life-question4.component.css']
})
export class LifeQuestion4Component implements OnInit {

  @Output() nextquestion4 = new EventEmitter();
  mortgage: any;
  kids: any;
  annualIncome: any;
  nextquestion3: any;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  mortgagetoshow: any;
  ShowDollar_income: boolean;
  ShowDollar: boolean;
  annualIncometoshow: any;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    debugger
    this.pc.logPageLandingtoBO({ page_name: 'income_expenses' });
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
    }
    );
    if (localStorage.getItem('bubble-mortgage') !== null && localStorage.getItem('bubble-mortgage') !== undefined && localStorage.getItem('bubble-mortgage') !== 'undefined') {
      this.ShowDollar = true;
      this.mortgage = localStorage.getItem('bubble-mortgage');
      this.mortgagetoshow = this.mortgage.replace(/,/g, '');
      const ctd = this.mortgagetoshow;
      this.mortgagetoshow = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    else {
      // this.mortgage = "500000";
      // this.mortgagetoshow = this.mortgage.replace(/,/g, '');
      // let ctd = this.mortgagetoshow;
      //  this.mortgagetoshow = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.ShowDollar = true;
      this.mortgagetoshow = '0';
      this.mortgage = 0;
    }
    if (localStorage.getItem('bubble-kids') !== null && localStorage.getItem('bubble-kids') !== undefined && localStorage.getItem('bubble-kids') !== 'undefined') {
      this.kids = localStorage.getItem('bubble-kids');
    }
    else {
      this.kids = '0';
    }
    if (localStorage.getItem('bubble-annualIncome') !== null && localStorage.getItem('bubble-annualIncome') !== undefined && localStorage.getItem('bubble-annualIncome') !== 'undefined') {
      this.ShowDollar_income = true;
      this.annualIncome = localStorage.getItem('bubble-annualIncome');
      this.annualIncometoshow = this.annualIncome.replace(/,/g, '');
      const ctd = this.annualIncometoshow;
      this.annualIncometoshow = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    }
    else {
      // this.annualIncome = "250000";
      // this.annualIncometoshow = this.annualIncome.replace(/,/g, '');
      // let ctd = this.annualIncometoshow;
      // this.annualIncometoshow = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      this.ShowDollar_income = true;
      this.annualIncometoshow = '0';
      this.annualIncome = 0;
    }
  }

  next() {
    debugger
    localStorage.setItem('bubble-coverage', '');
    const json = {
      'id': this.clientId,
      'bubble_mortgage': this.mortgage,
      'bubble_kids': this.kids,
      'bubble_annualIncome': this.annualIncome,
      'sbli_questionNumber': 0,
      'wns_questionNumber':0
    };
    this.pc.saveUpdateClientData(json);
    localStorage.setItem('bubble-mortgage', this.mortgage);
    localStorage.setItem('bubble-kids', this.kids);
    localStorage.setItem('bubble-annualIncome', this.annualIncome.toString().replace(/,/g, ''));
    localStorage.setItem('lifeRecoBackButton', 'enable');
    const etty = {
      'questionNo': '4',
      'mortgage': this.mortgage,
      'kids': this.kids,
      'annualIncome': this.annualIncome,
      'nextprev': 1
    };
    this.nextquestion4.emit(etty);

    this.$gaService.event('Proceed', 'event', 'MyLifeStep4Proceed');
    const eventOptions: EventOption = {
      path: 'QuoteFlowV2',
      page_title: 'MyLifeStep4Proceed',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Proceed', 'event', 'MyLifeStep4Proceed');

  }

  previous() {
    localStorage.setItem('bubble-mortgage', this.mortgage);
    localStorage.setItem('bubble-kids', this.kids);
    localStorage.setItem('bubble-annualIncome', this.annualIncome);
    const etty = {
      'questionNo': '4',
      'mortgage': this.mortgage,
      'kids': this.kids,
      'annualIncome': this.annualIncome,
      'nextprev': 0
    };
    this.nextquestion4.emit(etty);
  }
  formatCoverage() {
    debugger;
    if (this.mortgagetoshow == null || this.mortgagetoshow == undefined || this.mortgagetoshow == '') {
      // this.mortgagetoshow = 0;
      // this.mortgage = this.mortgagetoshow;
      this.ShowDollar = false;
    }
    else {

      this.mortgage = this.mortgagetoshow;
      this.mortgagetoshow = this.mortgagetoshow.replace(/,/g, '');
      const ctd = this.mortgagetoshow;
      this.mortgagetoshow = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.ShowDollar = true;
    }



  }

  checkchildren(id) {
    debugger;
    //  if (this.kids==null || this.kids==undefined || this.kids=='') {
    //   this.kids = 0;
    // }
    if (id == 0 && this.kids > 0) {
      this.kids = Number(this.kids) - 1;
    }
    else if (id == 1 && this.kids < 9) {
      this.kids = Number(this.kids) + 1;
    }
    else if (id == 1 && this.kids == 9) {
      this.kids = this.kids;
    }
    else {
      this.kids = 0;
    }
  }
  formatAnnual() {
    debugger;
    if (this.annualIncometoshow == null || this.annualIncometoshow == undefined || this.annualIncometoshow == '') {
      this.ShowDollar_income = false;
    } else {
      this.annualIncome = this.annualIncometoshow;
      this.annualIncometoshow = this.annualIncometoshow.replace(/,/g, '');
      const ctd = this.annualIncometoshow;
      this.annualIncometoshow = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.ShowDollar_income = true;
    }

  }

}
