import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { CustomiseComponent } from './pages/customise/customise.component';
import { EdLifescoreComponent } from './components/ed-lifescore/ed-lifescore.component';
import { QuoteFlowComponent } from './pages/quote-flow/quote-flow.component';
import { ScoreCardComponent } from './pages/score-card/score-card.component';
import { LifescoreComponent } from './lifescore/lifescore.component';
import { QuickquoteComponent } from './pages/quickquote/quickquote.component';
import { LifescoreEmptyPageComponent } from './pages/lifescore-empty-page/lifescore-empty-page.component';
import { LeadNurtureComponent } from './pages/lead-nurture/lead-nurture.component';
import { TextToImageComponent } from './components/text-to-image/text-to-image.component';
import { QuoteFlowV2Component } from './v2/quote-flow-v2/quote-flow-v2.component';
import { SelectHomeCarrierComponent } from './v2/select-home-carrier/select-home-carrier.component';
import { AppFlowComponent } from './applicationFlow/pages/app-flow/app-flow.component';
import { PersonalDetailsQuestion1Component } from './applicationFlow/pages/personalDetails/personal-details-question1/personal-details-question1.component';
import { GeneralQuestionsComponent } from './applicationFlow/general-questions/general-questions.component';
import { EmploymentComponent } from './applicationFlow/pages/questions/employment/employment.component';
import { DisabilityBenefitsComponent } from './applicationFlow/pages/questions/disability-benefits/disability-benefits.component';
import { QuoteFlowV2LifeComponent } from './v2/quote-flow-v2-life/quote-flow-v2-life.component';
import { SelectLifeCarrierComponent } from './v2/select-life-carrier/select-life-carrier.component';
import { SelectedCarriersComponent } from './v2/selected-carriers/selected-carriers.component';
import { ContactInformationComponent } from './v2/contact-information/contact-information.component';
import { AppflowstartComponent } from './applicationFlow/appflowstart/appflowstart.component';
import { ApplicationStatusTrackerComponent } from './components/application-status-tracker/application-status-tracker.component';
import { PacLifeComponent } from './applicationFlow/PacLife/pac-life/pac-life.component';
import { LifeInsuranceDetailsComponent } from './v2/life-insurance-details/life-insurance-details.component';
import { SbliCarrierPlanComponent } from './components/carrier-plan/carrier-plan.component';
import { ElectronicConsentComponent } from './applicationFlow/PacLife/electronic-consent/electronic-consent.component';
import { Details4idsComponent } from './v2/details4ids/details4ids.component';
import { EConsentComponent } from './applicationFlow/PacLife/e-consent/e-consent.component';
import { GeneraldatepickerComponent } from './applicationFlow/PacLife/generaldatepicker/generaldatepicker.component';
import { FirewallScreenComponent } from './pages/firewall-screen/firewall-screen.component';
import { FirewallGuard } from './shared/firewall.guard';
import { StateCountrySearchComponent } from './components/state-country-search/state-country-search.component';
import { StillWaterAppFlowComponent } from './SWApplicationFlow/still-water-app-flow/still-water-app-flow.component';
import { Sw4pt1Component } from './SWApplicationFlow/questions/sw4pt1/sw4pt1.component';
import { Sw4pt3Component } from './SWApplicationFlow/questions/sw4pt3/sw4pt3.component';
import { Sw4pt6Component } from './SWApplicationFlow/questions/sw4pt6/sw4pt6.component';
import { Sw4pt7Component } from './SWApplicationFlow/questions/sw4pt7/sw4pt7.component';
import { Sw4pt12Component } from './SWApplicationFlow/questions/sw4pt12/sw4pt12.component';
import { Sw4pt14Component } from './SWApplicationFlow/questions/sw4pt14/sw4pt14.component';
import { Sw4pt20Component } from './SWApplicationFlow/questions/sw4pt20/sw4pt20.component';
import { Sw4pt28Component } from './SWApplicationFlow/questions/sw4pt28/sw4pt28.component';
import { Sw4pt30Component } from './SWApplicationFlow/questions/sw4pt30/sw4pt30.component';
import { Sw4pt35Component } from './SWApplicationFlow/questions/sw4pt35/sw4pt35.component';
import { Sw4pt38Component } from './SWApplicationFlow/questions/sw4pt38/sw4pt38.component';
import { Sw4pt40Component } from './SWApplicationFlow/questions/sw4pt40/sw4pt40.component';
import { Sw4pt41Component } from './SWApplicationFlow/questions/sw4pt41/sw4pt41.component';
import { Sw4pt43Component } from './SWApplicationFlow/questions/sw4pt43/sw4pt43.component';
import { HomeInsuranceCardComponent } from './LeadNurtureAngular/home-insurance-card/home-insurance-card.component';
import { ProfileHomePageComponent } from './LeadNurtureAngular/profile-home-page/profile-home-page.component';
import { CustomizeHomeComponent } from './v2/customize-home/customize-home.component';
import { FlowResumeComponent } from './pages/flow-resume/flow-resume.component';
import { AddBeneficiariesComponent } from './applicationFlow/PacLife/add-beneficiaries/add-beneficiaries.component';
import { KnockOutComponent } from './SWApplicationFlow/knock-out/knock-out.component';
import { HomeInsuranceDiscountsComponent } from './v2/home-insurance-discounts/home-insurance-discounts.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { AuthGuard } from './shared/auth.guard';
import { Sw4pt45Component } from './SWApplicationFlow/questions/sw4pt45/sw4pt45.component';
import { Sw4pt46Component } from './SWApplicationFlow/questions/sw4pt46/sw4pt46.component';
import { HomeInsuranceDetailsComponent } from './v2/home-insurance-details/home-insurance-details.component';
import { Sbli0015ssnComponent } from './SBLIAppFlow/questions/sbli0015ssn/sbli0015ssn.component';
import { Sbli00101260Component } from './SBLIAppFlow/questions/sbli00101260/sbli00101260.component';
import { Sbli00201010Component } from './SBLIAppFlow/questions/sbli00201010/sbli00201010.component';
import { Sbli00301011Component } from './SBLIAppFlow/questions/sbli00301011/sbli00301011.component';
import { Sbli00401120Component } from './SBLIAppFlow/questions/sbli00401120/sbli00401120.component';
import { Sbli00501030Component } from './SBLIAppFlow/questions/sbli00501030/sbli00501030.component';
import { Sbli00601020Component } from './SBLIAppFlow/questions/sbli00601020/sbli00601020.component';
import { Sbli00701001Component } from './SBLIAppFlow/questions/sbli00701001/sbli00701001.component';
import { Sbli01001080Component } from './SBLIAppFlow/questions/sbli01001080/sbli01001080.component';
import { Sbli01051040Component } from './SBLIAppFlow/questions/sbli01051040/sbli01051040.component';
import { Sbli01101140Component } from './SBLIAppFlow/questions/sbli01101140/sbli01101140.component';
import { Sbli01401180Component } from './SBLIAppFlow/questions/sbli01401180/sbli01401180.component';
import { Sbli01801220Component } from './SBLIAppFlow/questions/sbli01801220/sbli01801220.component';
import { Sbli02001240Component } from './SBLIAppFlow/questions/sbli02001240/sbli02001240.component';
import { Sbli02101270Component } from './SBLIAppFlow/questions/sbli02101270/sbli02101270.component';
import { Sbli02201930Component } from './SBLIAppFlow/questions/sbli02201930/sbli02201930.component';
import { Sbli02701061Component } from './SBLIAppFlow/questions/sbli02701061/sbli02701061.component';
import { Sbli02801170Component } from './SBLIAppFlow/questions/sbli02801170/sbli02801170.component';
import { Sbli02901680Component } from './SBLIAppFlow/questions/sbli02901680/sbli02901680.component';
import { Sbli02501250Component } from './SBLIAppFlow/questions/sbli02501250/sbli02501250.component';
import { HomeKnockOutComponent } from './v2/questions/home-knock-out/home-knock-out.component';
import { Sbli02601100Component } from './SBLIAppFlow/questions/sbli02601100/sbli02601100.component';
import { Sbli6pt54Component } from './SBLIAppFlow/questions/sbli6pt54/sbli6pt54.component';
import { SbliappflowComponent } from './SBLIAppFlow/sbliappflow/sbliappflow.component';
import { HomeCarrierRecommendationComponent } from './v2/home-carrier-recommendation/home-carrier-recommendation.component';
import { LifeCarrierRecommendationComponent } from './v2/life-carrier-recommendation/life-carrier-recommendation.component';
import { Sw4pt10Component } from './SWApplicationFlow/questions/sw4pt10/sw4pt10.component';
import { SbliPaymentComponent } from './SBLIAppFlow/questions/sbli-payment/sbli-payment.component';
import { PrefillResumeFlowComponent } from './pages/prefill-resume-flow/prefill-resume-flow.component';
import { LifeClickComponent } from './v2/life-click/life-click.component';
import { HomeClickComponent } from './v2/home-click/home-click.component';
import { PrefillResumeFlowHoiComponent } from './pages/prefill-resume-flow-hoi/prefill-resume-flow-hoi.component';
import { SbliCongratsComponent } from './SBLIAppFlow/questions/sbli-congrats/sbli-congrats.component';
import { Sbli6pt58Component } from './SBLIAppFlow/questions/sbli6pt58/sbli6pt58.component';
import { SbliTermsComponent } from './SBLIAppFlow/questions/sbli-terms/sbli-terms.component';
import { SbliReviewComponent } from './SBLIAppFlow/questions/sbli-review/sbli-review.component';
import { SbliWelcomeComponent } from './SBLIAppFlow/questions/sbli-welcome/sbli-welcome.component';
import { SbliDetailsConfirmationComponent } from './SBLIAppFlow/questions/sbli-details-confirmation/sbli-details-confirmation.component';
import { SbliSummaryComponent } from './SBLIAppFlow/questions/sbli-summary/sbli-summary.component';

import { LeadComponent } from './pages/lead/lead.component';
import { SbliStatustrackerComponent } from './SBLIAppFlow/questions/sbli-statustracker/sbli-statustracker.component';
import { SbliknockoutComponent } from './SBLIAppFlow/sbliknockout/sbliknockout.component';
import { HomeQuestion1V2Component } from './v2/questions/home-question1-v2/home-question1-v2.component';
import { PrefillQuoteFlowV2Component } from './v2/prefill-quote-flow-v2/prefill-quote-flow-v2.component';
import { SelectHomeCarrierv2Component } from './v2/select-home-carrierv2/select-home-carrierv2.component';
import { CompareHomePlansComponent } from './v2/compare-home-plans/compare-home-plans.component';
import { PrefillToPropertyDetailsComponent } from './pages/prefill-to-property-details/prefill-to-property-details.component';
import { PrefillQuinstreetComponent } from './pages/prefill-quinstreet/prefill-quinstreet.component';
import { PrefillResumeFlowLifeComponent } from './pages/prefill-resume-flow-life/prefill-resume-flow-life.component';
import { QuinstreetLifeComponent } from './pages/quinstreet-life/quinstreet-life.component';
import { WnsappflowComponent } from './SBLIAppFlow/wnsappflow/wnsappflow.component';
import { HomeInsuranceComponent } from './Prefill pages/home-insurance/home-insurance.component';
import { LifeInsuranceComponent } from './Prefill pages/life-insurance/life-insurance.component';
import { SingleMotherHOIComponent } from './Prefill pages/single-mother-hoi/single-mother-hoi.component';
import { CoupleMarriedHOIComponent } from './Prefill pages/couple-married-hoi/couple-married-hoi.component';
import { CoupleBuyingHomeHOIComponent } from './Prefill pages/couple-buying-home-hoi/couple-buying-home-hoi.component';
//import { InterstitialLoaderComponent } from './components/interstitial-loader/interstitial-loader.component';
import { SingleParentLifeComponent } from './Prefill pages/single-parent-life/single-parent-life.component';
import { CoupleWithChildrenLifeComponent } from './Prefill pages/couple-with-children-life/couple-with-children-life.component';
import { CoupleAboutToGetMArriedLifeComponent } from './Prefill pages/couple-about-to-get-married-life/couple-about-to-get-married-life.component';
import { QuoteWidgetComponent } from './components/quote-widget/quote-widget.component';
import { WnspaymentComponent } from './SBLIAppFlow/wnsQuestions/wnspayment/wnspayment.component';
import { SingleMotherLifeComponent } from './Prefill pages/single-mother-life/single-mother-life.component';
import { PrefillToRecoPageComponent } from './Prefill pages/prefill-to-reco-page/prefill-to-reco-page.component';
import { Back9QuoteComponent } from './components/back9-quote/back9-quote.component';
import { WnsComponent } from './pages/wns/wns.component';
import { WNSback9Component } from './components/wnsback9/wnsback9.component';
import { HomescoreNewComponent } from './app/homescore-new/homescore-new.component';
import { PrefillHomeKVComponent } from './Prefill pages/prefill-home-kv/prefill-home-kv.component';
import { PrefillLifeKVComponent } from './Prefill pages/prefill-life-kv/prefill-life-kv.component';
import { Back9AllQuotesComponent } from './components/back9-all-quotes/back9-all-quotes.component';
import { LifeRecommendationScreenComponent } from './life-recommendation-screen/life-recommendation-screen.component';
import{ InterstitialLoaderComponent } from './components/interstitial-loader/interstitial-loader.component';

import { DrawerComponent } from './components/drawer/drawer.component';
import { HomescoreWithCoBrandedComponent } from './homescore-with-co-branded/homescore-with-co-branded.component';

const routes: Routes = [
  { path: '', redirectTo: 'lifescore', pathMatch: 'full' },
  { path: 'QuoteFlow/:uniqueId/:clientId/:address/:gender/:age', component: QuoteFlowComponent, canActivate: [FirewallGuard, AuthGuard] },
  { path: 'QuoteFlowV2/:uniqueId/:clientId/:address/:insurancetype/:sourcePageLink', component: QuoteFlowV2Component, canActivate: [FirewallGuard, AuthGuard], },
  { path: 'QuoteFlowV2/:uniqueId/:clientId/:address/:insurancetype/:sourcePageLink/:previous', component: QuoteFlowV2Component, canActivate: [FirewallGuard, AuthGuard] },
  { path: 'QuoteFlowV2/:uniqueId/:clientId/:address/:insurancetype/:sourcePageLink/:previous/:appFlowDebug', component: QuoteFlowV2Component, canActivate: [FirewallGuard, AuthGuard] },
  { path: 'QuoteFlowV2Life/:uniqueId/:clientId/:address/:insurancetype', component: QuoteFlowV2LifeComponent, canActivate: [FirewallGuard, AuthGuard] },
  { path: 'QuoteFlowV2Life/:uniqueId/:clientId/:address/:insurancetype/:sourcePageLink/:previous', component: QuoteFlowV2LifeComponent, canActivate: [FirewallGuard, AuthGuard] },
  { path: 'SelectHomeCarrier/:uniqueId/:clientId/:address/:insurancetype', component: SelectHomeCarrierComponent, canActivate: [FirewallGuard] },
  { path: 'StillWaterAppFlow/:uniqueId/:clientId/:address/:insurancetype', component: StillWaterAppFlowComponent, canActivate: [FirewallGuard] },
  { path: 'HomeAppFlow/:uniqueId/:clientId/:address/:insurancetype', component: StillWaterAppFlowComponent },
  { path: 'SelectLifeCarrier/:uniqueId/:clientId/:address/:insurancetype', component: SelectLifeCarrierComponent },
  { path: 'SelectedCarriers/:uniqueId/:clientId/:address/:insurancetype/:carrierName', component: SelectedCarriersComponent },
  { path: 'SelectedCarriers/:uniqueId/:clientId/:address/:insurancetype', component: SelectedCarriersComponent },
  { path: 'ScoreCard', component: ScoreCardComponent },
  { path: 'ScoreCard/:uniqueId/:clientId/:address', component: ScoreCardComponent },
  { path: 'edcsv', component: EdLifescoreComponent },
  { path: 'Customise', component: CustomiseComponent },
  { path: 'Customise/:uniqueId/:clientId/:address', component: CustomiseComponent },
  { path: 'Quickquote/:uniqueId/:clientId/:address', component: QuickquoteComponent },
  { path: 'lifescore/:utm_source/:utm_medium/:utm_campaign/:utm_term', component: LifescoreComponent },
  { path: 'lifescore/:utm_source/:utm_medium/:utm_campaign/:utm_term/:utm_content', component: LifescoreComponent },
  { path: 'lifescore', component: LifescoreComponent, canActivate: [AuthGuard] },
  { path: 'Maintenance', component: MaintenanceComponent },
  {
    path: 'homescore/:partner_id', component:HomescoreWithCoBrandedComponent
  },
  {
    path: 'homescore',
    children: [
      {
        path: '**',
        component: HomescoreNewComponent
      }]
  },

  { path: 'landing', component: LifescoreEmptyPageComponent },
  { path: 'leadnurture', component: LeadNurtureComponent },
  { path: 'TextToImage', component: TextToImageComponent },
  { path: 'AppFlow', component: AppFlowComponent },
  { path: 'appstatus', component: ApplicationStatusTrackerComponent },
  { path: 'PersonalDetailsQuestion1', component: PersonalDetailsQuestion1Component },
  { path: 'GeneralQuestions', component: GeneralQuestionsComponent },
  { path: 'Employment', component: EmploymentComponent },
  { path: 'DisabilityBenefits', component: DisabilityBenefitsComponent },
  { path: 'PacLife', component: PacLifeComponent },
  { path: 'terms/sbli', component: SbliCarrierPlanComponent },
  { path: 'life', component: LifeInsuranceDetailsComponent },
  { path: 'ContactInformation/:uniqueId/:clientId/:address/:insuranceType', component: ContactInformationComponent },
  { path: 'Appflowstart/:uniqueId/:clientId/:address/:insuranceType/:carrierName', component: AppflowstartComponent, canActivate: [FirewallGuard] },
  { path: 'details4ids/:id', component: Details4idsComponent },
  { path: 'consent', component: ElectronicConsentComponent },
  { path: 'EConsent', component: EConsentComponent },
  { path: 'Generaldatepicker', component: GeneraldatepickerComponent },
  { path: 'accessTest', component: FirewallScreenComponent },
  { path: 'StateCountrySearch', component: StateCountrySearchComponent },
  { path: 'Sw4pt1', component: Sw4pt1Component },
  { path: 'Sw4pt3', component: Sw4pt3Component },
  { path: 'Sw4pt6', component: Sw4pt6Component },
  { path: 'Sw4pt7', component: Sw4pt7Component },
  { path: 'Sw4pt10', component: Sw4pt10Component },
  { path: 'Sw4pt12', component: Sw4pt12Component },
  { path: 'Sw4pt14', component: Sw4pt14Component },
  { path: 'Sw4pt20', component: Sw4pt20Component },
  { path: 'Sw4pt28', component: Sw4pt28Component },
  { path: 'Sw4pt30', component: Sw4pt30Component },
  { path: 'Sw4pt35', component: Sw4pt35Component },
  { path: 'Sw4pt38', component: Sw4pt38Component },
  { path: 'Sw4pt40', component: Sw4pt40Component },
  { path: 'Sw4pt41', component: Sw4pt41Component },
  { path: 'Sw4pt43', component: Sw4pt43Component },
  { path: 'Sw4pt45', component: Sw4pt45Component },
  { path: 'Sw4pt46', component: Sw4pt46Component },
  { path: 'KnockOut/:uniqueId/:clientId/:address/:insurancetype', component: KnockOutComponent },
  { path: 'HomeKnockOut/:uniqueId/:clientId/:address/:insurancetype', component: HomeKnockOutComponent },
  { path: 'HomeInsuranceCard', component: HomeInsuranceCardComponent },
  { path: 'ProfileHomePage', component: ProfileHomePageComponent },
  { path: 'ProfileHomePage/:clientID', component: ProfileHomePageComponent },
  { path: 'CustomizeHome', component: CustomizeHomeComponent },
  { path: 'AddBeneficiaries', component: AddBeneficiariesComponent },
  { path: 'HomeInsuranceDiscounts/:uniqueId/:clientId/:address/:insurancetype', component: HomeInsuranceDiscountsComponent },
  { path: 'resumeFlow/:clientID', component: FlowResumeComponent, canActivate: [FirewallGuard] },
  { path: 'resumeFlow', component: FlowResumeComponent, canActivate: [FirewallGuard] },
  { path: 'prefillResumeFlow/:email/:insuranceType', component: PrefillResumeFlowComponent, canActivate: [FirewallGuard] },
  { path: 'HomeInsuranceDetails', component: HomeInsuranceDetailsComponent },
  { path: 'Sblissn', component: Sbli0015ssnComponent },
  { path: 'sbli0010', component: Sbli00101260Component },
  { path: 'sbli0020', component: Sbli00201010Component },
  { path: 'sbli0030', component: Sbli00301011Component },
  { path: 'sbli0040', component: Sbli00401120Component },
  { path: 'sbli0050', component: Sbli00501030Component },
  { path: 'sbli0060', component: Sbli00601020Component },
  { path: 'sbli0070', component: Sbli00701001Component },
  { path: 'sbli0100', component: Sbli01001080Component },
  { path: 'sbli0105', component: Sbli01051040Component },
  { path: 'sbli0110', component: Sbli01101140Component },
  { path: 'sbli0140', component: Sbli01401180Component },
  { path: 'sbli0180', component: Sbli01801220Component },
  { path: 'sbli0200', component: Sbli02001240Component },
  { path: 'sbli0210', component: Sbli02101270Component },
  { path: 'sbli0220', component: Sbli02201930Component },
  { path: 'sbli0270', component: Sbli02701061Component },
  { path: 'sbli0280', component: Sbli02801170Component },
  { path: 'sbli0290', component: Sbli02901680Component },
  { path: 'sbli-payment', component: SbliPaymentComponent },
  { path: 'has_medical_professional', component: Sbli02501250Component },
  { path: 'Sbliappflow/:uniqueId/:clientId/:address/:insuranceType', component: SbliappflowComponent },
  { path: 'lifeCarrierTwo/:uniqueId/:clientId/:address/:insuranceType', component: SbliappflowComponent },
  { path: 'Sbliappflow', component: SbliappflowComponent },
  { path: 'has_diseasesordisorder', component: Sbli02601100Component },
  { path: 'sbli6pt54', component: Sbli6pt54Component },
  { path: 'hi-recommendation', component: HomeCarrierRecommendationComponent },
  { path: 'li-recommendation', component: LifeCarrierRecommendationComponent },
  { path: 'lifeclick', component: LifeClickComponent },
  { path: 'homeclick', component: HomeClickComponent },
  { path: 'sbli-congrats', component: SbliCongratsComponent },
  { path: 'sbli0058', component: Sbli6pt58Component },
  { path: 'sbli-terms', component: SbliTermsComponent },
  { path: 'sbli-review', component: SbliReviewComponent },
  { path: 'sbli-welcome', component: SbliWelcomeComponent },
  { path: 'sbli-details', component: SbliDetailsConfirmationComponent },
  { path: 'sbli-summary', component: SbliSummaryComponent },
  { path: 'lead/:client_id/:mode/:insuranceType', component: LeadComponent },
  { path: 'SbliStatustrackerComponent', component: SbliStatustrackerComponent },
  { path: 'Sbliknockout', component: SbliknockoutComponent },
  { path: 'qwidget', component: QuoteWidgetComponent },
  { path: 'home-question1V2', component: HomeQuestion1V2Component },
  { path: 'prefill-QuoteFlowV2/:uniqueId/:clientId/:address/:insurancetype/:yearbuilt/:livingArea/:roofupdated', component: PrefillQuoteFlowV2Component, canActivate: [FirewallGuard, AuthGuard], },
  { path: 'prefill-QuoteFlowV2/:uniqueId/:clientId/:address/:insurancetype/:sourcePageLink/:previous', component: PrefillQuoteFlowV2Component, canActivate: [FirewallGuard, AuthGuard] },
  { path: 'prefill-QuoteFlowV2/:uniqueId/:clientId/:address/:insurancetype/:sourcePageLink/:previous/:appFlowDebug', component: PrefillQuoteFlowV2Component, canActivate: [FirewallGuard, AuthGuard] },
  { path: 'prefill-QuoteFlowV2/:uniqueId/:clientId/:address', component: PrefillQuoteFlowV2Component, canActivate: [FirewallGuard, AuthGuard], },
  { path: 'select-home-carrierv2', component: SelectHomeCarrierv2Component },
  { path: 'select-home-carrierv2/:uniqueId/:clientId/:address/:insurancetype', component: SelectHomeCarrierv2Component },
  { path: 'compare-home-plans', component: CompareHomePlansComponent },
  { path: 'PrefillToPropertyDetails', component: PrefillToPropertyDetailsComponent },
  { path: 'lifescoreV/:uniqueId/:clientId/:address/:insurancetype?', component: LifescoreComponent },
  { path: 'PrefillQuinstreet', component: PrefillQuinstreetComponent },
  { path: 'QuinstreetLife', component: QuinstreetLifeComponent },
  { path: 'HomescoreNew', component: HomescoreNewComponent },

  //{ path: 'SelectLifeCarrierV2/:uniqueId/:clientId/:address/:insurancetype', component: SelectLifeCarrierV2Component },
  //{ path: 'prefillResumeFlowHoi', component: PrefillResumeFlowHoiComponent },
  { path: 'prefillResumeFlowHome', component: PrefillResumeFlowHoiComponent },
  { path: 'prefillResumeFlowHoi', component: CoupleBuyingHomeHOIComponent },
  { path: 'PrefillResumeFlowLife', component: PrefillResumeFlowLifeComponent },
  { path: 'life-insurance', component: LifeInsuranceComponent },
  { path: 'home-insurance', component: HomeInsuranceComponent },
  { path: 'lifeCarrierThree/:uniqueId/:clientId/:address/:insuranceType', component: WnsappflowComponent },
  { path: 'single-motherhoi', component: SingleMotherHOIComponent },
  { path: 'couple-marriedhoi', component: CoupleMarriedHOIComponent },
  { path: 'couple-buyingHomehoi', component: CoupleBuyingHomeHOIComponent },
  { path: 'SingleParentLife', component: SingleParentLifeComponent },
  { path: 'CoupleWithChildrenLife', component: CoupleWithChildrenLifeComponent },
  { path: 'CoupleAboutToGetMArriedLife', component: CoupleAboutToGetMArriedLifeComponent },
  { path: 'WnspaymentComponent', component: WnspaymentComponent },
  { path: 'SingleMotherLife', component: SingleMotherLifeComponent },
  { path: 'singleMotherLife', component: SingleMotherLifeComponent },
  { path: 'prefillToRecoPage', component: PrefillToRecoPageComponent },
  { path: 'dynamic-questions', loadChildren: () => import('./dq-questions/dq-questions.module').then(m => m.DqQuestionsModule) },
  //{ path: 'SelectLifeCarrierV2/:uniqueId/:clientId/:address/:insuranceType', component: Back9RecoScreenComponent },
  { path: 'applicationflow', component: Back9QuoteComponent },
  { path: 'wnspage', component: WnsComponent },
  { path: 'WNSback9', component: WNSback9Component },
  { path: 'bubble-Home-kellerwilliams', component: PrefillHomeKVComponent },
  { path: 'bubble-Life-kellerwilliams', component: PrefillLifeKVComponent },
  { path: 'Back9AllQuotes', component: Back9AllQuotesComponent },
  { path: 'SelectLifeCarrierV2/:uniqueId/:clientId/:address/:insuranceType', component: LifeRecommendationScreenComponent },
  { path: 'loader', component: InterstitialLoaderComponent },
  { path: 'compare-plan', component: DrawerComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, initialNavigation: 'enabled' }), HttpClientModule],
  exports: [RouterModule]
})
export class AppRoutingModule { }
