import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli00101260',
  templateUrl: './sbli00101260.component.html',
  styleUrls: ['./sbli00101260.component.css']
})
export class Sbli00101260Component implements OnInit {

  uscitizen: any;
  questions: any;
  existingData: any;
  @Output() changeQuestion = new EventEmitter();
  sbli_answer_data = {};
  clientId: any;
  showLoader = true;
  questions2: any;
  military: any;
  activeduty: any;
  reserve_nation: any;
  isdisable: boolean;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    debugger
    this.clientId = localStorage.getItem('clientID');
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli00101260')[0];
        this.questions2 = que.filter(x => x.component_id == 'app-sbli00401120')[0];
        console.log('questions2.radio_options_2[0].answer_text', this.questions2.radio_options_2[0].answer_text);
      } else {
        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli00101260')[0];
        this.questions2 = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli00401120')[0];
      }
      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_us != undefined) {
        this.uscitizen = this.existingData.sbli_us;
      }
      if (this.existingData.sbli_military != undefined) {
        this.military = this.existingData.sbli_military;
        if (this.existingData.sbli_active_duty == 'Yes') {
          this.activeduty = 'On active duty';
        }
        else if (this.existingData.sbli_reserve_national_guard == 'Yes') {
          this.activeduty = 'In the Reserve or National Guard';
          if (this.existingData.sbli_troubled_areas == 'Yes') {
            this.reserve_nation = 'Serving in or under orders to  proceed to any troubled areas'
          }
          else if (this.existingData.sbli_hazardous_activity == 'Yes') {
            this.reserve_nation = 'Engaged in any hazardous activity'
          }
          else if (this.existingData.sbli_neither_of_these == 'Yes') {
            this.reserve_nation = 'Neither of these'
          }

        }
      }

      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
  }

  captureCitizen(value) {
    this.uscitizen = value;
  }
  captureMilitary(value) {
    debugger
    this.military = value;
    if (this.military == 'Yes') {
      this.isdisable = true;
    }
    else {
      this.isdisable = false;
      this.activeduty = '';
      this.reserve_nation = '';
    }
  }
  getactiveduty(event) {
    debugger;
    this.activeduty = event;
    localStorage.setItem('activeduty', this.activeduty);
    if ((this.activeduty == 'In the Reserve or National Guard') && (this.reserve_nation == '' || this.reserve_nation == undefined)) {
      this.isdisable = true;
    }
    else {
      this.isdisable = false;
    }
  }
  public getreserve_nation(event) {
    debugger;
    this.reserve_nation = event;
    localStorage.setItem('reserve_nation', this.reserve_nation);
    this.isdisable = false;
  }
  next() {
    debugger
    //this.uscitizen = value;
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    json.SBLI[this.questions.radio_options[0].answer_key] = this.uscitizen;
    json.SBLI[this.questions2.radio_options[0].answer_key] = this.military;
    // if (this.military == 'Yes') {
    //   json.SBLI[this.questions2.radio_options_1[0].answer_key] = this.activeduty;
    //   json.SBLI[this.questions2.radio_options_2[0].answer_key] = this.reserve_nation;
    // }
    if (this.activeduty == 'On active duty') {
      json.SBLI[this.questions2.radio_options_1[0].answer_key] = 'Yes';
      json.SBLI[this.questions2.radio_options_1[1].answer_key] = 'No';
    }
    else if (this.activeduty == 'In the Reserve or National Guard') {
      json.SBLI[this.questions2.radio_options_1[0].answer_key] = 'No';
      json.SBLI[this.questions2.radio_options_1[1].answer_key] = 'Yes';
    }
    if (this.reserve_nation == 'Serving in or under orders to  proceed to any troubled areas') {
      json.SBLI[this.questions2.radio_options_2[0].answer_key] = 'Yes';
      json.SBLI[this.questions2.radio_options_2[1].answer_key] = 'No';
      json.SBLI[this.questions2.radio_options_2[2].answer_key] = 'No';
    }
    else if (this.reserve_nation == 'Engaged in any hazardous activity') {
      json.SBLI[this.questions2.radio_options_2[0].answer_key] = 'No';
      json.SBLI[this.questions2.radio_options_2[1].answer_key] = 'Yes';
      json.SBLI[this.questions2.radio_options_2[2].answer_key] = 'No';
    }
    else if (this.reserve_nation == 'Neither of these') {
      json.SBLI[this.questions2.radio_options_2[0].answer_key] = 'No';
      json.SBLI[this.questions2.radio_options_2[1].answer_key] = 'No';
      json.SBLI[this.questions2.radio_options_2[2].answer_key] = 'Yes';
    }
    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
    this.$gaService.event('SBLI LifeApplication Citizen', 'event', 'SBLI-LifeApplication-Citizen');
    const eventOptions: EventOption = {
      page_title: 'SBLI-LifeApplication-T&C',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Citizen', 'event', 'SBLI-LifeApplication-Citizen');
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

}
