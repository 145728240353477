import { Component, OnInit } from '@angular/core';
import { getQuestionAnswerJson,getQuestionAnswerByPassingInput} from "../../appUtility/appUtility";
import { ProcessCacheService } from '../../shared/process-cache.service';
@Component({
  selector: 'app-general-questions',
  templateUrl: './general-questions.component.html',
  styleUrls: ['./general-questions.component.css']
})
export class GeneralQuestionsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   
  }

 


}
