import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getQuestionAnswerJson,getQuestionAnswerByPassingInput} from "../../../../appUtility/appUtility";
import { ProcessCacheService } from '../../../../shared/process-cache.service';
@Component({
  selector: 'app-risk-level',
  templateUrl: './risk-level.component.html',
  styleUrls: ['./risk-level.component.css']
})
export class RiskLevelComponent implements OnInit {
  @Output() nextquestion3 = new EventEmitter();

  constructor() { }
  ShowYesOrNo:boolean;
  ShowYesOrNo1:boolean;
  questionAnswersJSON:[];
  ngOnInit(): void {
    this.ShowYesOrNo=true;
    this.ShowYesOrNo1=false;
    this.questionAnswersJSON= getQuestionAnswerJson;
  
  }

  SelectedOption:any;
  SecondQuestion:any;
  public GetOptions(data){
    debugger;
    this.SelectedOption=data;
    if(this.SelectedOption==0){
      document.getElementById('riskNo').style.border='solid 2px orange';
      document.getElementById('riskYes').style.border='solid 1px #dae7ec';
      this.SecondQuestion=this.questionAnswersJSON["questions"]["q2"][3]["No"][0]["q"];
      this.ShowYesOrNo1=false;
    
    }else{
     document.getElementById('riskYes').style.border='solid 2px orange';
     document.getElementById('riskNo').style.border='solid 1px #dae7ec';
     this.ShowYesOrNo1=true;
  
    }
  }

  SelectLeveleOption:any;
  ShowYesOrNo2:boolean;
  public GetLevelOptions(data){
    this.SelectLeveleOption=data;
    if(this.SelectLeveleOption==0){
      document.getElementById('LevelriskNo').style.border='solid 2px orange';
      document.getElementById('LevelriskYes').style.border='solid 1px #dae7ec';
      this.ShowYesOrNo2=false;
    }else{
      document.getElementById('LevelriskYes').style.border='solid 2px orange';
      document.getElementById('LevelriskNo').style.border='solid 1px #dae7ec';
      this.ShowYesOrNo2=true;
    }

  }


  SelectLeveletwoOption:any;
  public GetLevelTwoOptions(data){
    this.SelectLeveletwoOption=data;
    if(this.SelectLeveletwoOption==0){
      document.getElementById('LeveltworiskNo').style.border='solid 2px orange';
      document.getElementById('LeveltworiskYes').style.border='solid 1px #dae7ec';

    }else{
      document.getElementById('LeveltworiskYes').style.border='solid 2px orange';
      document.getElementById('LeveltworiskNo').style.border='solid 1px #dae7ec';
    }

  }


  next() {
    let etty = {
      "questionNo": "3",
      "nextprev": 1
    }
    this.nextquestion3.emit(etty);
  }

  

}
