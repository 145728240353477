import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ValidationService } from './../../../shared/validation.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-has-driver-license',
  templateUrl: './has-driver-license.component.html',
  styleUrls: ['./has-driver-license.component.css']
})
export class HasDriverLicenseComponent implements OnInit, AfterViewInit {
  @Input() questionDetails: [];
  @Output() public hasDriverLicenseOutput =
    new EventEmitter();
  FormVisible: boolean;
  StateOptions: any;
  driverLicenseNumber: any;
  driverLicenseIssuedState: any;
  editForTheComponent: any;
  DrivingLicense: any;
  issueDate: any;
  expiryDate: any;
  curDate: Date;
  maxDate: Date;
  minDate: Date;
  imessage: string;
  i_is_valid: boolean;
  emessage: string;
  e_is_valid: boolean;
  showloader: boolean;

  constructor(
    private pc: ProcessCacheService,
    private ValidationService: ValidationService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,
    private service: ParentChildService
  ) { }
  ngOnInit(): void {
    this.showloader = true;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_9_DL'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.service.callComponentMethod();
    document.getElementById('appFlowFooterID').style.display = 'block';
    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear());
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.maxDate = new Date(month + '/' + day + '/' + year);

    const yearm = Number(this.curDate.getFullYear());
    const monthm = '' + (this.curDate.getMonth() + 1);
    const daym = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    this.minDate = new Date(monthm + '/' + daym + '/' + yearm);

    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {
        debugger;
        const clientDataFiltered = JSON.parse(data.Payload);
        this.driverLicenseInfo = clientDataFiltered.body.driverLicenseInfo;
        this.hasDriverLicense = clientDataFiltered.body.hasDriverLicense;

        this.editForTheComponent = localStorage.getItem('editDrivingLicense');
        if (this.editForTheComponent == 'true') {
          this.InActiveTickIcon = true;
          this.InActiveCrossIcons = true;
          this.StateOptions = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_2']['dropdown_options'];
          if (this.hasDriverLicense == 'Yes') {
            this.selectedOptionValue = 'Yes';
            // document.getElementById('1').style.border='solid 2px orange';
            // document.getElementById('0').style.border='solid 1px #dae7ec';
            this.FormVisible = false;
            this.ActiveTickIcons = true;
            this.InActiveTickIcon = false;
            this.ActiveCrossIcons = false;
            this.InActiveCrossIcons = true;
            this.SubmitTwo = true;
            this.SubmitOne = false;
            this.driverLicenseNumber = this.driverLicenseInfo.driverLicenseNumber;
            this.driverLicenseIssuedState = this.driverLicenseInfo.driverLicenseIssuedState;
            //this.issueDate=this.DrivingLicense.driverLicenseInfo.driverLicenseIssueDate;
            //this.expiryDate=this.DrivingLicense.driverLicenseInfo.driverLicenseExpiryDate;


          } else {
            this.selectedOptionValue = 'No';
            //  document.getElementById('0').style.border='solid 2px orange';
            //  document.getElementById('1').style.border='solid 1px #dae7ec';
            this.FormVisible = true;
            this.ActiveTickIcons = false;
            this.InActiveTickIcon = true;
            this.ActiveCrossIcons = true;
            this.InActiveCrossIcons = false;
            this.SubmitTwo = false;
            this.SubmitOne = true;
          }

        } else {
          this.InActiveTickIcon = true;
          this.InActiveCrossIcons = true;
          this.StateOptions = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_2']['dropdown_options'];




          if (this.hasDriverLicense == 'Yes') {
            var data1 = 1;
            this.driverLicenseNumber = this.driverLicenseInfo.driverLicenseNumber;
            this.driverLicenseIssuedState = this.driverLicenseInfo.driverLicenseIssuedState;
            //this.issueDate=this.DrivingLicense.driverLicenseInfo.driverLicenseIssueDate;
            //this.expiryDate=this.DrivingLicense.driverLicenseInfo.driverLicenseExpiryDate;
          } else {
            var data1 = 0;
          }
          this.SelectedOption = data1;
          if (this.SelectedOption == 0) {
            this.selectedOptionValue = 'No';
            // document.getElementById('0').style.border='solid 2px orange';
            // document.getElementById('1').style.border='solid 1px #dae7ec';
            this.FormVisible = true;
            this.ActiveTickIcons = false;
            this.InActiveTickIcon = true;
            this.ActiveCrossIcons = true;
            this.InActiveCrossIcons = false;
            this.SubmitTwo = true;
            this.SubmitOne = false;

          } else {
            this.selectedOptionValue = 'Yes';
            //  document.getElementById('1').style.border='solid 2px orange';
            //  document.getElementById('0').style.border='solid 1px #dae7ec';
            this.FormVisible = false;
            this.ActiveTickIcons = true;
            this.InActiveTickIcon = false;
            this.ActiveCrossIcons = false;
            this.InActiveCrossIcons = true;
            this.SubmitTwo = false;
            this.SubmitOne = true;

          }

        }
        this.showloader = false;

      }
    ).catch(error => {
      console.log(error);
    });

  }

  driverLicenseInfo: any;
  hasDriverLicense: any;
  ngAfterViewInit(): void {
    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {
        debugger;
        const clientDataFiltered = JSON.parse(data.Payload);
        this.driverLicenseInfo = clientDataFiltered.body.driverLicenseInfo;
        this.hasDriverLicense = clientDataFiltered.body.hasDriverLicense;
        this.editForTheComponent = localStorage.getItem('editDrivingLicense');
        if (this.editForTheComponent == 'true') {

          if (this.hasDriverLicense == 'Yes') {
            this.driverLicenseNumber = this.driverLicenseInfo.driverLicenseNumber;
            this.driverLicenseIssuedState = this.driverLicenseInfo.driverLicenseIssuedState;
            //this.issueDate=this.DrivingLicense.driverLicenseInfo.driverLicenseIssueDate;
            //this.expiryDate=this.DrivingLicense.driverLicenseInfo.driverLicenseExpiryDate;
            document.getElementById('driverLicenseIssuedState').innerHTML = this.driverLicenseInfo.driverLicenseIssuedState;
          } else {
            this.selectedOptionValue = 'No';
          }
        } else {
          // this.DrivingLicense = localStorage.getItem("PacLife-driverLicenseDetails");
          // this.DrivingLicense = JSON.parse(this.DrivingLicense);
          if (this.hasDriverLicense == 'Yes') {
            this.driverLicenseNumber = this.driverLicenseInfo.driverLicenseNumber;
            document.getElementById('driverLicenseIssuedState').innerHTML = this.driverLicenseInfo.driverLicenseIssuedState;
          } else {
          }
        }


      }
    ).catch(error => {
      console.log(error);
    });










  }







  SelectedOption: any;
  SecondQuestion: any;
  ActiveTickIcons: boolean;
  InActiveTickIcon: boolean;
  ActiveCrossIcons: boolean;
  InActiveCrossIcons: boolean;
  selectedOptionValue: any;
  SubmitTwo: boolean;
  SubmitOne: boolean;
  public GetOptions(data) {

    this.SelectedOption = data;
    if (this.SelectedOption == 0) {
      this.selectedOptionValue = 'No';
      //  document.getElementById('0').style.border='solid 2px orange';
      //  document.getElementById('1').style.border='solid 1px #dae7ec';
      this.FormVisible = true;
      this.ActiveTickIcons = false;
      this.InActiveTickIcon = true;
      this.ActiveCrossIcons = true;
      this.InActiveCrossIcons = false;
      this.SubmitTwo = true;
      this.SubmitOne = false;

    } else {
      this.selectedOptionValue = 'Yes';
      // document.getElementById('1').style.border='solid 2px orange';
      // document.getElementById('0').style.border='solid 1px #dae7ec';
      this.FormVisible = false;
      this.ActiveTickIcons = true;
      this.InActiveTickIcon = false;
      this.ActiveCrossIcons = false;
      this.InActiveCrossIcons = true;
      this.SubmitTwo = false;
      this.SubmitOne = true;
      this.driverLicenseIssuedState = '';
      this.driverLicenseNumber = '';

    }
  }


  checkIssueDate(even) {
    if (even == null) {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear());
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.issueDate = new Date(month + '/' + day + '/' + year);
    }
    else {
      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.issueDate = month + '/' + day + '/' + year;
    }

    const newDate = new Date(this.issueDate);
    console.log('issue date entered: ', newDate);
    const checkAge = this.ValidationService.validate_licenseDate('issueDate', newDate);
    this.imessage = checkAge.message;
    this.i_is_valid = checkAge.is_valid;
  }
  checkExpiryDate(even) {
    if (even == null) {
      // this.curDate = new Date();
      // const year = Number(this.curDate.getFullYear());
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.expiryDate = new Date(month + '/' + day + '/' + year);
      this.expiryDate = this.pc.momentdob(this.curDate);
    }
    else {
      // const cur_d = new Date();
      // let d = new Date(even),
      //   month = '' + (d.getMonth() + 1),
      //   day = '' + d.getDate(),
      //   year = d.getFullYear();
      // const curYear = cur_d.getFullYear();
      // year = d.getFullYear();

      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.expiryDate = month + '/' + day + '/' + year;
      this.expiryDate = this.pc.momentdob(even);
    }
    const newDate = new Date(this.expiryDate);
    console.log('expiry date entered: ', newDate);
    const checkAge = this.ValidationService.validate_licenseDate('expiryDate', newDate);
    this.emessage = checkAge.message;
    this.e_is_valid = checkAge.is_valid;
  }


  inputJson;
  public onSubmit(data) {
    debugger;
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);
      this.inputJson = {};
      const driverLicenseInfo = {};

      if (this.selectedOptionValue == 'Yes') {
        driverLicenseInfo[data.sub_questions.question_1.group_of_inputs.answer_1.answer_key] = this.driverLicenseNumber;
        driverLicenseInfo[data.sub_questions.question_1.group_of_inputs.answer_2.answer_key] = this.driverLicenseIssuedState;
        //driverLicenseInfo['driverLicenseIssueDate']=this.issueDate;
        //driverLicenseInfo['driverLicenseExpiryDate'] = this.expiryDate;

        this.inputJson['id'] = localStorage.getItem('clientID');
        this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;
        this.inputJson[data.sub_questions.question_1.group_key] = driverLicenseInfo;
        // this.hasDriverLicenseOutput.emit({component_id:this.questionDetails["component_id"], answer:this.inputJson});
        this.submitdata(this.inputJson);

      } else {
        this.inputJson['id'] = localStorage.getItem('clientID');
        this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;
        // this.hasDriverLicenseOutput.emit({component_id:this.questionDetails["component_id"], answer:this.inputJson});
        this.submitdata(this.inputJson);
      }







    } else {
      alert('Declined!');
    }

    this.$gaService.event('Next', 'event', 'LifeAppDriverLicense');
    const eventOptions: EventOption = {
      page_title: 'Driver License',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppDriverLicense');

  }

  public submitdata(inputJson) {
    debugger;
    if (this.editForTheComponent == 'true') {
      const driverLicense = JSON.stringify(this.inputJson);
      localStorage.setItem('PacLife-driverLicenseDetails', driverLicense);
      this.pc.saveUpdateClientData(inputJson);
      this.hasDriverLicenseOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson, afterEdit: true });

    } else {
      const driverLicense = JSON.stringify(this.inputJson);
      localStorage.setItem('PacLife-driverLicenseDetails', driverLicense);
      this.hasDriverLicenseOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
      this.pc.saveUpdateClientData(inputJson);
    }

  }


  public goBack() {
    // this.isDependentOutput.emit(this.questionDetails["component_id"]+",Goback");
    this.hasDriverLicenseOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }

  states(LicenseIssuedState) {
    document.getElementById('driverLicenseIssuedState').innerHTML = LicenseIssuedState;
    this.driverLicenseIssuedState = LicenseIssuedState;
  }

}
