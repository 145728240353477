import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

import { FormGroup, FormGroupDirective, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { AnswerModel } from '../../models/answer.model';
import { AnswerValueModel } from '../../models/answer.value.model';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.css']
})
export class InputCheckboxComponent implements OnInit {

  @Output() onChange: EventEmitter<AnswerValueModel>;
  @Input() public questionId: number | undefined;
  @Input() public optionValues: AnswerModel[] | undefined | null;
  @Input() public option: AnswerModel | undefined | null;
  @Input() public isChild = false;
  @Input() public childFormGroupName!: string;

  childForm!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) {
    this.onChange = new EventEmitter();
  }

  ngOnInit(): void {
    debugger;
    this.childForm = this.rootFormGroup.control.get(this.childFormGroupName) as FormGroup;
    console.log('questionId', this.questionId);
    console.log('option', this.option);
    console.log('isChild', this.isChild)
    const defaultValue = localStorage.getItem('answer_value_' + this.option?.answer_option_id);
    let column = `answer_value_${this.childForm.value.question_id}`
    if (this.childForm.value[column]) {
      this.rootFormGroup.form.controls['selected_answer']?.setValue(this.option.answer_text)
    }
    if (JSON.parse(defaultValue)) {
      const _model: AnswerValueModel = new AnswerValueModel();
      _model.answer_option_id = this.option?.answer_option_id;
      _model.checked = JSON.parse(defaultValue);
      _model.last_key = (this.option?.last_key == undefined || this.option?.last_key == null ? false : this.option?.last_key);
      _model.form_group = this.childForm.parent as FormGroup;
      _model.hasChild = this.option?.child?.length !== 0;
      this.onChange.emit(_model);
    }

  }

  changeValue(event: any, last_key: boolean | undefined | null, hasChild: boolean, option) {
    debugger;

    const _model: AnswerValueModel = new AnswerValueModel();
    _model.answer_option_id = event.target.value;
    _model.checked = event.target.checked;
    _model.last_key = (last_key == undefined || last_key == null ? false : last_key);
    _model.form_group = this.childForm.parent as FormGroup;
    // _model.form_group.value.selected_answer = option.answer_text;
    if (option != undefined) {
      if (option.answer_text == 'None of the above') {
        _model.form_group.controls['selected_answer'].setValue(option.answer_text);
      }
      else {
        if (event.target.checked) {
          let allvalues = _model.form_group.controls['selected_answer'].value;
          if (allvalues == 'None of the above') {
            _model.form_group.controls['selected_answer'].setValue('');
          }
          let values = _model.form_group.controls['selected_answer'].value;
          if (values != '') {
            _model.form_group.controls['selected_answer'].setValue(`${values}_${option.answer_text}`);
          }
          else {
            _model.form_group.controls['selected_answer'].setValue(option.answer_text);
          }
        }
        else {
          let allvalues = _model.form_group.controls['selected_answer'].value.split('_');
          const index = allvalues.indexOf(option.answer_text);
          if (index > -1) {
            allvalues.splice(index, 1);
          }
          _model.form_group.controls['selected_answer'].setValue(allvalues.join("_"));
        }
      }

    }

    _model.hasChild = hasChild;
    // last_key ? localStorage.setItem('answer_value_' + this.option?.answer_option_id, event.target.checked) : 0;

    console.log('clickoption', this.option);
    this.onChange.emit(_model);


  }

  changeValueChild(value: AnswerValueModel): void {
    this.onChange.emit(value);
  }

  changeValueRadio(event: any, answer_option_id: number | undefined, hasChild: boolean) {

    const _model: AnswerValueModel = new AnswerValueModel();
    _model.answer_option_id = answer_option_id;
    _model.checked = event.target.value;
    _model.last_key = false;
    _model.form_group = this.childForm.parent as FormGroup;
    _model.hasChild = hasChild;

    this.onChange.emit(_model);
  }

  changeValueText(event: any, answer_option_id: number | undefined) {
    const _model: AnswerValueModel = new AnswerValueModel();
    _model.answer_option_id = answer_option_id;
    _model.checked = event.target.value?.length > 0 ? true : false;
    _model.last_key = false;
    _model.form_group = this.childForm.parent as FormGroup;
    _model.hasChild = false;

    this.onChange.emit(_model);
  }
}
