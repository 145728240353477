import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

@Component({
  selector: 'app-life-question3',
  templateUrl: './life-question3.component.html',
  styleUrls: ['./life-question3.component.css']
})
export class LifeQuestion3Component implements OnInit {

  @Output() nextquestion3 = new EventEmitter();
  yesicon: string;
  noicon: string;
  tobaccoSelected: string;
  tobacoUser: any;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  usageDuration: string;
  isdisable = true;
  showfirstradio: any;
  showsecondradio: any;
  showthirdradio: any;
  showfourthradio: any;
  tobacoselected: any;
  constructor(
    private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    debugger;
    // this.showfirstradio=0;
    // this.showsecondradio=0;
    // this.showthirdradio=0;
    // this.showfourthradio=0;
    this.pc.logPageLandingtoBO({ page_name: 'tobacco' });
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
      console.log('uniqueId : ', this.uniqueId);
      console.log('clientId : ', this.clientId);
      console.log('displayAddress : ', this.displayAddress);
      console.log('insurancetype : ', this.insurancetype);
    }
    );

    const tobaccoSelectedLocal = localStorage.getItem('bubble-tobacco');
    if (tobaccoSelectedLocal !== null) this.tobaccoSelected = tobaccoSelectedLocal;

    if (this.tobaccoSelected == undefined || this.tobaccoSelected == null || this.tobaccoSelected == 'undefined') {
      this.tobaccoSelected = '5';
      this.usageDuration = '5';
      this.tobacoselected = 'No';
      this.pc.lifesummary({'tobacco_user':'No'});
    } else if (this.tobaccoSelected != '5') {
      this.tobaccoSelected = '0';
      this.tobacoselected = 'Yes';
      this.pc.lifesummary({'tobacco_user':'Yes'});
    }

    if (localStorage.getItem('bubble-tobaccoUsageDuration') !== null) {
      this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');
    }
    else {

      localStorage.setItem('bubble-tobaccoUsageDuration', '5');
      this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');

    }

    if (['1', '2', '3', '4', '4.1', '5'].indexOf(this.usageDuration) !== -1) {
      this.isdisable = false;
    }

    localStorage.setItem('bubble-tobaccoUsageDuration', this.usageDuration);
    this.yesicon = 'assets/images/images/ic-yes.png';
    this.noicon = 'assets/images/images/ic-no.png';
    if (this.tobaccoSelected == '0') {
      localStorage.setItem('bubble-tobacco', '0');
      document.getElementById('male_1').className = 'gender active';
      document.getElementById('female_1').className = 'gender';
      this.yesicon = 'assets/images/active/icon-tick-active.svg';
      this.noicon = 'assets/images/images/ic-no.png';
    }
    else {
      this.isdisable = false;
      localStorage.setItem('bubble-tobacco', '5');
      document.getElementById('male_1').className = 'gender';
      document.getElementById('female_1').className = 'gender active';
      this.yesicon = 'assets/images/images/ic-yes.png';
      this.noicon = 'assets/images/active/icon-cross-active.svg';
      this.isdisable = false;
    }
  }



  getUsageDuration(event) {
    debugger;
    this.isdisable = false;
    this.usageDuration = event;
    localStorage.setItem('bubble-tobacco', this.usageDuration);
  }

  next() {

    if (this.tobaccoSelected == '0') {
      localStorage.setItem('bubble-tobacco', this.usageDuration);
    }
    else {
      localStorage.setItem('bubble-tobacco', this.tobaccoSelected);
    }
    localStorage.setItem('bubble-tobaccoUsageDuration', this.usageDuration);
    const json = {
      'id': this.clientId,
      'tobacoUser': (Number(this.tobaccoSelected) == 5) ? 'No' : 'Yes',
      'tobacoUsageDuration': this.usageDuration,
      'tobacoselected': (Number(this.tobaccoSelected) == 5) ? 'No' : 'Yes',
      'tobaccoUsage': this.usageDuration
    };
    this.pc.saveUpdateClientData(json);
    const etty = {
      'questionNo': '3',
      'tobacoUser': (Number(this.tobaccoSelected) == 5) ? 'No' : 'Yes',
      'tobacoUsageDuration': this.usageDuration,
      'nextprev': 1,
      'tobacoselected': (Number(this.tobaccoSelected) == 5) ? 'No' : 'Yes'
    };
    this.nextquestion3.emit(etty);

    this.$gaService.event('Proceed', 'event', 'MyLifeStep3Proceed');
    const eventOptions: EventOption = {
      path: 'QuoteFlowV2',
      page_title: 'Tobacco Use',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Proceed', 'event', 'MyLifeStep3Proceed');
  }

  previous() {
    //debugger;
    if (this.tobaccoSelected == '0') {
      localStorage.setItem('bubble-tobacco', this.usageDuration);
    }
    else {
      localStorage.setItem('bubble-tobacco', this.tobaccoSelected);
    }
    localStorage.setItem('bubble-tobaccoUsageDuration', this.usageDuration);
    const json = {
      'id': this.clientId,
      'tobacoUser': this.tobaccoSelected,
      'tobacoUsageDuration': this.usageDuration,
    };
    this.pc.saveUpdateClientData(json);
    const etty = {
      'questionNo': '3',
      'tobacoUser': this.tobaccoSelected,
      'tobacoUsageDuration': this.usageDuration,
      'nextprev': 0
    };
    this.nextquestion3.emit(etty);
  }

  gender(gen) {
    debugger;
    this.tobaccoSelected = gen;
    if (gen == '0') {
      if (['1', '2', '3', '4', '5'].indexOf(this.usageDuration) !== -1) {
        this.isdisable = false;
      } else this.isdisable = true;
      this.tobacoselected = 'Yes';
      localStorage.setItem('bubble-tobacco', '0');
      document.getElementById('male_1').className = 'gender active';
      document.getElementById('female_1').className = 'gender';
      this.yesicon = 'assets/images/active/icon-tick-active.svg';
      this.noicon = 'assets/images/images/ic-no.png';
      if (localStorage.getItem('bubble-tobaccoUsageDuration') !== null) {
        this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');
      }
      else {
        localStorage.setItem('bubble-tobaccoUsageDuration', '5');
        this.usageDuration = localStorage.getItem('bubble-tobaccoUsageDuration');
        this.isdisable = false;
      }
      // if (this.usageDuration == "0 Months"){

      // }
      // else{
      //   this.isdisable = false;
      // }
      this.pc.lifesummary({'tobacco_user':'No'});
    }
    else {
      this.isdisable = false;
      this.usageDuration = '0';
      this.tobacoselected = 'No';
      localStorage.setItem('bubble-tobaccoUsageDuration', this.usageDuration);
      localStorage.setItem('bubble-tobacco', '5');
      document.getElementById('male_1').className = 'gender';
      document.getElementById('female_1').className = 'gender active';
      this.yesicon = 'assets/images/images/ic-yes.png';
      this.noicon = 'assets/images/active/icon-cross-active.svg';
      this.pc.lifesummary({'tobacco_user':'Yes'});
    }
  }


}
