import { Injectable } from '@angular/core';
import { Location } from "@angular/common";
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from "./../../environments/environment";
import { ProfileDataHandlerService } from 'src/app/shared/profile-data-handler.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
const pc = require('bit-uibl-data-driver');
import { ValidationService } from 'src/app/shared/validation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { BehaviorSubject, Subject } from 'rxjs';
import { ErroralertModalComponent } from '../components/erroralert-modal/erroralert-modal.component';
import { formatDate } from "@angular/common";
const axios = require("axios").default;
const flatten = require('flat');
var unflatten = require('flat').unflatten
@Injectable({
  providedIn: 'root'
})
export class PrefillserviceService {
  private PrefillToPropertyDetailsComponent = new Subject<boolean>();

  // Observable string streams
  email: any;
  insuranceType: any;
  loader: boolean;
  clientId: any;
  iType: string;
  recoType: string;
  hs_preview: string;
  firstname: string;
  address: string;
  address_line_2: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone: string;
  date_of_birth: any;
  gender: string;
  marital_status: string;
  interest_type: string;
  home_type: string;
  dwelling_use: string;
  number_of_units: string;
  lead_source: string;
  lead_form_url: string;
  lead_source_time_stamp: string;
  newly_acquired_property: string;
  number_of_claims: string;
  property_year_built: string;
  property_living_area: string;
  home_insurance_type: string;
  credit_rating: string;
  bundle_auto_with_home: string;
  stories: string;
  roof_updated_year: any;
  lastname: string;
  construction_type: string;
  roof_type: string;
  swimming_pool: string;
  year_built: any;
  living_size: string;
  fullAddress: string;
  uniqueId: string;
  httpParamsGlobal: any;
  domain: any;
  dropoffURL: any;
  curDate: any;
  minDate;
  minArea: number;
  yearBuiltError: any;
  constructionList: any;
  storiesList: any;
  roofTypeList: any;
  propertdetails = [];
  rooftype: any;
  constructiontype: any;
  message: string;
  is_valid: boolean;
  roofupdatedyearError: string;
  livingSizeError: string;
  selectedConstructionType: any;
  selectedStories: any;
  selectedRoofType: any;
  phoneerror: any;
  prevEmail: any;
  errormessage: any;
  utm_source: any;
  utm_medium: any;
  utm_campaign: any;
  utm_term: any;
  utm_content: any;
  sub_id: any;
  clientData: any;
  timeStamp: string;
  clientIdNew: string;
  clientIdForLogs: any;

  constructor(private location: Location, private $gaService: GoogleAnalyticsService, private utilityService: UtilityFunctionsService, public router: Router, private modalService: NgbModal, public validationService: ValidationService, private Http: HttpClient, private pc: ProcessCacheService, private activatedroute: ActivatedRoute) { }

  async ngOnInit(router): Promise<void> {
    debugger;
    this.loader = true;
    localStorage.clear();
    localStorage.setItem("insuranceType", "home");

    this.clientId = this.utilityService.getNewRandomToken();
    this.storiesList = [
      { value: "1", text: "1" },
      { value: "1and1/2", text: "1and1/2" },
      { value: "2", text: "2" },
      { value: "2and1/2", text: "2and1/2" },
      { value: "3", text: "3" },
      { value: "4", text: "4+" },
    ]
    this.phoneerror = "";
    this.roofupdatedyearError = '';
    this.livingSizeError = '';
    this.yearBuiltError = '';
    this.curDate = new Date();
    let year = Number(this.curDate.getFullYear()) - 18;
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.minDate = new Date(month + '/' + day + '/' + year);
    let url = router;
    if (window && window.location && window.location.href) {
      url = window.location.href;
    }
    // this.dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
    // this.dropoffURL = `${this.domain}/resumeFlow/${this.clientId}`;
    localStorage.setItem('accessedURL', url);
    if (url.includes('?')) {
      this.httpParamsGlobal = new HttpParams({ fromString: url.split('?')[1] });
      const httpParams = this.httpParamsGlobal;//new HttpParams({ fromString: url.split('?')[1] });
      localStorage.setItem('lead_source', httpParams.get("lead_source"));
      this.hs_preview = httpParams.get("hs_preview");
      this.email = httpParams.get("email");
      this.prevEmail = httpParams.get("email");
      localStorage.setItem("bubble-email", httpParams.get("email"));
      localStorage.setItem("bubble-emailid", httpParams.get("email"));
      this.firstname = httpParams.get("firstname");
      localStorage.setItem("bubble-fname", httpParams.get("firstname"));
      this.lastname = httpParams.get("lastname");
      localStorage.setItem("bubble-lname", httpParams.get("lastname"));
      localStorage.setItem("bubble-fullName", `${httpParams.get("firstname")} ${httpParams.get("lastname")}`);
      this.address = httpParams.get("address");
      this.address_line_2 = httpParams.get("address_line_2");
      this.city = httpParams.get("city");
      this.state = httpParams.get("state");
      this.country = httpParams.get("country");
      this.zip = httpParams.get("zip");

      localStorage.setItem('bubble-zip', this.zip);
      this.phone = httpParams.get("phone");
      this.date_of_birth = httpParams.get("date_of_birth");

      this.gender = httpParams.get("gender");
      if (this.gender != null && this.gender != undefined) {
        if (this.gender.toLowerCase() == 'male') {
          localStorage.setItem('bubble-gender', '1');
        }
        else if (this.gender.toLowerCase() == 'female') {
          localStorage.setItem('bubble-gender', '0');
        }
      }

      this.marital_status = httpParams.get("marital_status");
      this.interest_type = httpParams.get("interest_type");
      this.home_type = httpParams.get("home_type");
      this.dwelling_use = httpParams.get("dwelling_use");
      this.number_of_units = httpParams.get("number_of_units");
      this.lead_source = httpParams.get("lead_source");
      this.lead_form_url = httpParams.get("lead_form_url");
      this.lead_source_time_stamp = httpParams.get("lead_source_time_stamp");
      this.newly_acquired_property = httpParams.get("newly_acquired_property");
      this.number_of_claims = httpParams.get("number_of_claims");
      this.property_year_built = httpParams.get("property_year_built");
      this.property_living_area = httpParams.get("property_living_area");
      this.home_insurance_type = httpParams.get("home_insurance_type");
      this.credit_rating = httpParams.get("credit_rating");
      this.bundle_auto_with_home = httpParams.get("bundle_auto_with_home");
      this.stories = httpParams.get("stories");
      localStorage.setItem('storiestoprefill', this.stories);
      this.roof_updated_year = httpParams.get("roof_updated_year");
      let tempValue = httpParams.get("utm_source");
      if (tempValue) this.utm_source = tempValue;
      tempValue = httpParams.get("utm_medium");
      if (tempValue) this.utm_medium = tempValue;
      tempValue = httpParams.get("utm_campaign");
      if (tempValue) this.utm_campaign = tempValue;
      tempValue = httpParams.get("utm_term");
      if (tempValue) this.utm_term = tempValue;
      tempValue = httpParams.get("utm_content");
      if (tempValue) this.utm_content = tempValue;
      localStorage.setItem("utm_source", this.utm_source);
      localStorage.setItem("utm_medium", this.utm_medium);
      localStorage.setItem("utm_campaign", this.utm_campaign);
      localStorage.setItem("utm_term", this.utm_term);
      localStorage.setItem("utm_content", this.utm_content);
      tempValue = httpParams.get("sub_id");
      if (tempValue) this.sub_id = tempValue;
      this.year_built = this.property_year_built;
      this.living_size = this.property_living_area;
      this.construction_type = "F";
      this.roof_type = "ARCH";
      this.swimming_pool = "No";


      if (this.date_of_birth == undefined || this.date_of_birth == null || this.date_of_birth == '') {
        this.date_of_birth = this.date_of_birth;
        localStorage.setItem("bubble-dob", this.date_of_birth);
      } else {
        let datearray = this.date_of_birth.split('-');
        this.date_of_birth = datearray[1] + '-' + datearray[2] + '-' + datearray[0];
        localStorage.setItem("bubble-dob", this.date_of_birth);
      }
      if (this.phone == undefined || this.phone == null || this.phone == '') {
        this.phone = '';
      } else {
        var count = 0;
        this.phone = this.phone.replace(/^0+/, '');
        let phonestring = this.phone.toString().slice(0, this.phone.length);
        this.phone = phonestring.replace(/(\d{3})-?/g, function (m, a) {
          return ++count <= 2 ? a + "-" : m;
        });
        this.phone = this.phone.slice(0, 12);
      }
      if (this.address == undefined || this.address == null || this.address == '') {
        this.fullAddress = '';
      } else {
        let addressDetails = {
          address: this.address, city: this.city, state: this.state, zip: this.zip,
          fullAddress: this.fullAddress
        };
        let cleanAddressDetails = await this.utilityService.cleanupAddressFields(addressDetails);
        this.address = cleanAddressDetails.finaladdress;
        this.fullAddress = this.address + "-" + this.city + "-" + this.state + "-" + this.zip;
        this.fullAddress = this.fullAddress.replace(/-/g, " ");
      }
      console.log("fullAddress fullAddress", this.fullAddress);
      this.pc.fullAddress = this.fullAddress;
      // console.log("thisthisthisthis:", this);
      if (this.email == null || this.address == null || this.state == null || this.city == null || this.zip == null) {
        this.openModal();
      }
      else {
        this.generateClientData();
      }

    }

  }
  /**
   * This method should be changed as per mandatory fields in the URL
   * Otherwise it will send incorrect data for Google Analytics
   */
  setGAEvent() {
    //action, category, label
    if (this.email == null) {
      this.$gaService.gtag('event', "HomeLandingPage", {
        'event_category': "Error", 'event_label': "email"
      });
    }
    if (this.address == null) {
      this.$gaService.gtag('event', "HomeLandingPage", {
        'event_category': "Error", 'event_label': "address"
      });
    }
    // TODO Need to check with Rajaneesh why was it added and removed
    // if (this.country == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "country"
    //   });
    // }
    if (this.state == null) {
      this.$gaService.gtag('event', "HomeLandingPage", {
        'event_category': "Error", 'event_label': "state"
      });
    }
    if (this.city == null) {
      this.$gaService.gtag('event', "HomeLandingPage", {
        'event_category': "Error", 'event_label': "city"
      });
    }
    if (this.zip == null) {
      this.$gaService.gtag('event', "HomeLandingPage", {
        'event_category': "Error", 'event_label': "zip"
      });
    }
    // if (this.home_type == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "home_type"
    //   });
    // }
    // if (this.dwelling_use == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "dwelling_use"
    //   });
    // }
    // if (this.interest_type == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "interest_type"
    //   });
    // }
    // if (this.home_insurance_type == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "home_insurance_type"
    //   });
    // }
    // if (this.lead_source == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "lead_source"
    //   });
    // }
    // if (this.lead_form_url == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "lead_form_url"
    //   });
    // }
    // if (this.lead_source_time_stamp == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "lead_source_time_stamp"
    //   });
    // }
  }
  fillInHubspotDataFromFormData(hubSpotData) {
    if (this.email) hubSpotData["email"] = this.email;
    if (this.firstname) hubSpotData["firstname"] = this.firstname;
    if (this.lastname) hubSpotData["lastname"] = this.lastname;
    if (this.address) hubSpotData["address"] = this.address;
    if (this.address_line_2) hubSpotData["address_line_2"] = this.address_line_2;
    if (this.city) hubSpotData["city"] = this.city;
    if (this.state) hubSpotData["state"] = this.state;
    if (this.country) hubSpotData["country"] = this.country;
    if (this.zip) hubSpotData["zip"] = this.zip;
    if (this.phone) hubSpotData["phone"] = this.phone;
    if (this.date_of_birth) hubSpotData["dob_life"] = this.date_of_birth;
    if (this.gender) hubSpotData["gender"] = this.gender;
    if (this.marital_status) hubSpotData["marital_status"] = this.marital_status;
    if (this.fullAddress) hubSpotData["full_address"] = this.fullAddress;
    // Problem if we use this property Hubspot throws error
    // if (this.home_type) hubSpotData["home_type"] = this.home_type;
    if (this.dwelling_use) hubSpotData["dwelling_use"] = this.dwelling_use;
    if (this.number_of_units) hubSpotData["number_of_units"] = this.number_of_units;
    if (this.lead_source) hubSpotData["lead_source"] = this.lead_source;
    if (this.lead_form_url) hubSpotData["lead_form_url"] = this.lead_form_url;
    if (this.lead_source_time_stamp) hubSpotData["lead_source_time_stamp"] = this.lead_source_time_stamp;
    if (this.newly_acquired_property) hubSpotData["newly_acquired_property"] = this.newly_acquired_property;
    if (this.number_of_claims) hubSpotData["number_of_claims"] = this.number_of_claims;
    if (this.property_year_built) hubSpotData["property_year_built"] = this.property_year_built;
    if (this.property_living_area) hubSpotData["property_living_area"] = this.property_living_area;
    if (this.home_insurance_type) hubSpotData["home_insurance_type"] = this.home_insurance_type;
    if (this.credit_rating) hubSpotData["credit_rating"] = this.credit_rating;
    if (this.bundle_auto_with_home) hubSpotData["bundle_auto_with_home"] = this.bundle_auto_with_home;
    if (this.stories) hubSpotData["stories"] = this.stories;
    if (this.roof_updated_year) hubSpotData["roof_updated_year"] = this.roof_updated_year;
    if (this.utm_source) hubSpotData["utm_source"] = this.utm_source;
    if (this.utm_medium) hubSpotData["utm_medium"] = this.utm_medium;
    if (this.utm_campaign) hubSpotData["utm_campaign"] = this.utm_campaign;
    if (this.utm_term) hubSpotData["utm_term"] = this.utm_term;
    if (this.utm_content) hubSpotData["utm_content"] = this.utm_content;
    if (this.sub_id) hubSpotData["sub_id"] = this.sub_id;
    let url;
    if (window && window.location && window.location.href) {
      url = window.location.href;
    }
    let dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
    hubSpotData['dropoff_url'] = dropoffURL;
    return hubSpotData;
  }

  async getdata() {
    debugger
    //this.clientId = await this.generateClientData();
    // this.uniqueId = "g2ov6i-1341-Dolanna-Dr-Compton-CA-90221";
    // this.fullAddress = "Compton-CA-90221-US";
    // // this.clientId = this.getClientId();
    this.fullAddress = this.address + "-" + this.city + "-" + this.state + "-" + this.zip;
    this.fullAddress = this.fullAddress.replace(/ /g, "-");
    // this.uniqueId = this.clientId + "-" + this.fullAddress;

    this.pc.getAppData(this.clientId).then(async data => {
      debugger
      let dataFrom = JSON.parse(data.Payload);
      this.clientData = dataFrom.body;
      console.log("dataFrom get app data : ", this.clientData);
      debugger
      this.fullAddress = this.clientData.fullAddress;
      this.uniqueId = pc.getUniqueId4Property(this.clientId, this.fullAddress)
      this.insuranceType = "home";
      localStorage.setItem("insuranceType", this.insuranceType);
      this.iType = "home-quote";
      this.recoType = "SelectHomeCarrier";
      debugger
      let CommonInputHome = {};
      let localStories = {
        "1": "1",
        "1and1/2": "1H",
        "2": "2",
        "2and1/2": "2H",
        "3": "3",
        "4+": "4",
        "4": "4"
      }
      let propDetails = {
        "construction_type": this.construction_type,//no value
        "home_type": this.home_type,//no value
        "roof_type": this.roof_type,//no value
        "swimming_pool": this.swimming_pool,//no value
        "year_built": this.year_built,//no value
        "living_size": this.living_size,//no value
        "stories": localStories[this.stories] ? localStories[this.stories] : "1",
        "roof_constructed": this.roof_updated_year
      }
      debugger
      CommonInputHome = {
        address: this.address,
        addressType: "PhysicalRisk",
        city: this.city,
        coverage: 10,
        dob: this.date_of_birth,
        firstName: this.firstname,
        lastName: this.lastname,
        middleName: "",
        premiumFrequency: 'm',
        state: this.state,
        zip: this.zip,
        replacementCost: 10,
        yearbuilt: this.year_built,
        sw_discounts: this.marital_status == "Married" ? [4965] : [],
        sw_appQuestions: [],
        ca_builder: "",
        propertyDetails: propDetails,
        swquote_question: this.clientData.swquote_question,
        email: this.email,
        phone: this.phone,
        disableBack: true
      }
      let google_address = {
        "street_number_ln": '',
        "street_number_sn": '',
        "route_ln": '',
        "route_sn": '',
        "neighborhood_ln": '',
        "neighborhood_sn": '',
        "locality_ln": '',
        "locality_sn": '',
        "administrative_area_level_2_ln": '',
        "administrative_area_level_2_sn": '',
        "administrative_area_level_1_ln": '',
        "administrative_area_level_1_sn": '',
        "country_ln": '',
        "country_sn": '',
        "postal_code_ln": '',
        "postal_code_sn": '',
        "postal_code_suffix_ln": '',
        "postal_code_suffix_sn": '',
        "formatted_address": ''
      };
      let address = {
        Addr1: '',
        addr: [],
        City: '',
        StateProvCd: '',
        PostalCode: ''
      };
      let addressDetails = {
        address: this.address, city: this.city, state: this.state, zip: this.zip,
        fullAddress: this.fullAddress
      };
      let cleanAddressDetails = await this.utilityService.cleanupAddressFields(addressDetails);
      //Overwriting with cleaned up address fields
      this.address = cleanAddressDetails.finaladdress;
      this.fullAddress = cleanAddressDetails.fullAddress;
      pc.BackendService.getGoogleAddressDetails(cleanAddressDetails.fullAddress).then(async (res) => {
        debugger
        let results = res.data.results;
        for (let i = 0; i < results[0].address_components.length; i++) {

          let type = results[0].address_components[i].types[0];
          switch (type) {
            case "street_number":
              google_address.street_number_ln = results[0].address_components[i].long_name;
              google_address.street_number_sn = results[0].address_components[i].short_name;
              address.addr[0] = results[0].address_components[i].short_name;
              break;
            case "route":
              google_address.route_ln = results[0].address_components[i].long_name;
              google_address.route_sn = results[0].address_components[i].short_name;
              address.addr[1] = results[0].address_components[i].short_name;
              break;
            case "neighborhood":
              google_address.neighborhood_ln = results[0].address_components[i].long_name;
              google_address.neighborhood_sn = results[0].address_components[i].short_name;
              //address.addr[2] = results[0].address_components[i].short_name;
              break;
            case "locality":
              google_address.locality_ln = results[0].address_components[i].long_name;
              google_address.locality_sn = results[0].address_components[i].short_name;
              address.City = results[0].address_components[i].short_name;
              break;
            case "administrative_area_level_2":
              google_address.administrative_area_level_2_ln = results[0].address_components[i].long_name;
              google_address.administrative_area_level_2_sn = results[0].address_components[i].short_name;
              break;
            case "administrative_area_level_1":
              google_address.administrative_area_level_1_ln = results[0].address_components[i].long_name;
              google_address.administrative_area_level_1_sn = results[0].address_components[i].short_name;
              address.StateProvCd = results[0].address_components[i].short_name;
              break;
            case "country":
              google_address.country_ln = results[0].address_components[i].long_name;
              google_address.country_sn = results[0].address_components[i].short_name;
              break;
            case "postal_code":
              google_address.postal_code_ln = results[0].address_components[i].long_name;
              google_address.postal_code_sn = results[0].address_components[i].short_name;
              address.PostalCode = results[0].address_components[i].short_name;
              break;
            case "postal_code_suffix":
              google_address.postal_code_suffix_ln = results[0].address_components[i].long_name;
              google_address.postal_code_suffix_sn = results[0].address_components[i].short_name;
              break;
          }

        }
        address.Addr1 = address.addr.join(' ');
        delete address.addr;
        let brushData = {"errorMessage": "Api failed, Redirected to user to home page."};
        try {
          brushData = await pc.BackendService.getbrushForestAPI(JSON.stringify(address), this.uniqueId);
        } catch (error) {
          console.log(error)
        }
        console.log("brushData", brushData);
        debugger;
        let swData = {
          "data_category": "getMinimalHomeQuoteFromStillWater",
          "fetchViaAPI": "true",
          "dataCategory": "StillWater_QuickQuote",
          "uid": this.uniqueId,
          "lastname": "T",
          "firstname": "Anonymous",
          "streetaddress": google_address.street_number_ln + " " + google_address.route_sn, //"353 Portland Cir",
          "city": google_address.locality_ln,
          "state": google_address.administrative_area_level_1_sn,
          "zipcode": google_address.postal_code_sn,
          "resultformat": "yes"

        };
        localStorage.setItem("swMinQuoteData", JSON.stringify(swData));
        // Make yes to disable popup
        // Make no to enable popup
        localStorage.setItem("swMinQuotePopupShown", "yes");
        /* let urlTemp = 'https://api2.getmybubble.io/' + 'fetch1';
        console.log("swData getMinimalHomeQuoteFromStillWater", swData, " # urlTemp: ", urlTemp);
        axios.get(urlTemp, { params: swData }); */
        localStorage.setItem('commonInput', JSON.stringify(CommonInputHome));
        const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
        let hubSpotData = {
          interest_type: this.interest_type,
          application_stage: "Lead",
          client_id: this.clientId,
          customer_id: customerIdLocal,
          home_knock_out_reason: this.clientData['brushForestErrorDescription'] ? this.clientData['brushForestErrorDescription'] : ''
        };
        hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
        console.log("hubSpotData from fillInHubspotDataFromFormData", hubSpotData);
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
        localStorage.setItem("applyDiscount", "0");
        console.log("common input : ", CommonInputHome);
        console.log("CommonInputHome : ", CommonInputHome);
        let resumeURL = `prefill-QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.fullAddress}/${this.insuranceType}/${this.year_built}/${this.living_size}/${this.roof_updated_year}`;
        console.log("resumeURL generated: ", resumeURL);
        this.resumeFlow(resumeURL);
      }).catch(error => {
        console.log(error);
      });
    }).catch(error => {
      console.log(error);
    })
  }

  async resumeFlow(resumeURL) {
    this.loader = true;
    let createJson = {
      insuranceType: this.iType,
      carrierName: "stillwater",
      appStartStatus: false,
      appCompletedStatus: false,
      questionStartStatus: false,
      questionCompletedStatus: false,
      questionSequence: "0",
      applicationId: "",
      otherAppflowScreen: "home-question1",
      resumeUrl: resumeURL
    }
    let createResumeData = await this.pc.checkforResumeInit(createJson, "create");
    console.log("createResumeData: ", createResumeData);
    this.router.navigate(['/resumeFlow', this.clientId]);
  }

  async generateClientData() {
    debugger
    console.log("generateClientData httpParamsGlobal", this.httpParamsGlobal);
    let clientDataJson = {
      "client_id": this.clientId,
      "country": this.country,
      "bubble_email": (this.email) == null ? '' : this.email,
      "email": (this.email) == null ? '' : this.email,
      "bubble_fname": this.firstname,
      "bubble_lname": this.lastname,
      "address": this.address,
      "city_name": this.city,
      "stateCode": this.state,
      "state_name": this.state,
      "zip_code": this.zip,
      "bubble_phone": this.phone,
      "dob": this.date_of_birth,
      "gender": this.gender,
      "insurancetype": this.interest_type,
      "interest_type": this.interest_type,
      "yearBuilt": this.year_built,
      "livingArea": this.living_size,
      "dataPrefilled": this.living_size == null || this.living_size == undefined || this.living_size == "" ? "no" : "yes",
      "roofBuiltYear": this.roof_updated_year,
      "fullAddress": this.fullAddress,
      "prefill_insurancetype": this.interest_type,
      "prefill_interest_type": this.interest_type,
      "prefill_yearBuilt": this.property_year_built,
      "prefill_livingArea": this.property_living_area,
      "prefill_roofBuiltYear": this.roof_updated_year,
      "prefill_marital_status": this.marital_status,
      "prefill_home_type": this.home_type,
      "prefill_dwelling_use": this.dwelling_use,
      "prefill_number_of_units": this.number_of_units,
      "prefill_lead_form_url": this.lead_form_url,
      "prefill_lead_source_time_stamp": this.lead_source_time_stamp,
      "prefill_newly_acquired_property": this.newly_acquired_property,
      "prefill_number_of_claims": this.number_of_claims,
      "prefill_home_insurance_type": this.home_insurance_type,
      "prefill_credit_rating": this.credit_rating,
      "prefill_bundle_auto_with_home": this.bundle_auto_with_home,
      "prefill_stories": this.stories,
      "comfirmDetailsDateOfBirth": this.date_of_birth,
      "comfirmDetailsGender": this.gender,
      "lead_source": this.lead_source

    }
    console.log("clientDataJson", clientDataJson);
    await this.Http.get<any>(`${this.pc.envCred.api2Domain}?data_category=m1144188134176150116232225133160147155103`, { params: clientDataJson }).subscribe((data: any) => {
      let response = data;
      console.log("post res success : ", response);
      // this.clientId = response.clientId;
      localStorage.setItem("clientID", this.clientId);
      localStorage.setItem("clientId", this.clientId);
      this.$gaService.gtag('event', "HomeLandingPage", {
        'event_category': "client_id", 'event_label': this.clientId
      });
      this.minArea = 250;
      this.yearBuiltError = "";
      let quickquote = new pc.Quotes(this.clientId);
      let optionsList = quickquote.getSWPropertyOptions();
      this.constructionList = optionsList.construction_type;
      console.log("constructionList : ", this.constructionList);
      // this.storiesList = optionsList.stories;
      // console.log("storiesList : ", this.storiesList);
      this.roofTypeList = optionsList.roof_type;
      console.log("roofTypeList : ", this.roofTypeList);
      let propertyresponse = quickquote.getSWKnockoutQuestionsByState(this.state, this.year_built);
      console.log("Knockout Questions: ", propertyresponse);
      this.propertdetails = propertyresponse;
      debugger
      for (let i = 0; i < this.propertdetails.length; i++) {
        if (i == 0) {
          this.propertdetails[i].value = 'yes';
        }
        else {
          this.propertdetails[i].value = 'no';
        }

        // this.getdata();
      }

      console.log("generateClientData().then:this.clientId:", this.clientId);

      this.$gaService.gtag('event', "HomeLandingPage", {
        'event_category': "Page", 'event_label': "PageLoadCompleted"
      });
      this.loader = false;
      this.saveupdateclient();
    });

  }








  public async saveupdateclient() {
    this.loader = true;
    // Replicating old email id data to new email, if email id is changed
    if (this.prevEmail != this.email) {
      const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
      this.$gaService.gtag('event', "HomeLandingPage", {
        'event_category': "Edited", 'event_label': "Email"
      });
      let hubSpotData = {
        interest_type: this.interest_type,
        client_id: this.clientId,
        customer_id: customerIdLocal,
        application_stage: "Lead",
        "dropoff_url": this.dropoffURL
      };
      this.fillInHubspotDataFromFormData(hubSpotData);
      debugger;
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    let qid = [];
    for (let i = 1; i < this.propertdetails.length; i++) {
      if (this.propertdetails[i].value == 'yes') {
        qid.push(this.propertdetails[i].sw_questionId)
      }
    }
    let clientDataJson = {
      'id': this.clientId,
      "country": this.country,
      "bubble_email": (this.email) == null ? '' : this.email,
      "email": (this.email) == null ? '' : this.email,
      "bubble_fname": this.firstname,
      "bubble_lname": this.lastname,
      "address": this.address,
      "city_name": this.city,
      "stateCode": this.state,
      "state_name": this.state,
      "zip_code": this.zip,
      "bubble_phone": this.phone,
      "dob": this.date_of_birth,
      "gender": this.gender,
      "insurancetype": this.interest_type,
      "interest_type": this.interest_type,
      "yearBuilt": this.year_built,
      "livingArea": this.living_size,
      "dataPrefilled": this.living_size == null || this.living_size == undefined || this.living_size == "" ? "no" : "yes",
      "roofBuiltYear": this.roof_updated_year,
      "fullAddress": this.fullAddress,
      "prefill_insurancetype": this.interest_type,
      "prefill_interest_type": this.interest_type,
      "prefill_yearBuilt": this.year_built,
      "prefill_livingArea": this.living_size,
      "prefill_roofBuiltYear": this.roof_updated_year,
      "prefill_marital_status": this.marital_status,
      "prefill_home_type": this.home_type,
      "prefill_dwelling_use": this.dwelling_use,
      "prefill_number_of_units": this.number_of_units,
      "prefill_lead_form_url": this.lead_form_url,
      "prefill_lead_source_time_stamp": this.lead_source_time_stamp,
      "prefill_newly_acquired_property": this.newly_acquired_property,
      "prefill_number_of_claims": this.number_of_claims,
      "prefill_home_insurance_type": this.home_insurance_type,
      "prefill_credit_rating": this.credit_rating,
      "prefill_bundle_auto_with_home": this.bundle_auto_with_home,
      "prefill_stories": this.stories,
      "swquote_question": qid,
      "roof_type": this.rooftype
    }
    /////// id mapping
    //const aa = {firstname:"Adsf", lastname:"Ssdfds", clientId:"w2e3"};
    let firstName, lastName;
    const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    localStorage.setItem("customer_id", customerIdLocal);
    const idMappingJson = {
      client_id: this.clientId,
      customer_id: customerIdLocal,
      email_id: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      status: this.interest_type,
      interest_type: this.interest_type,
      dob: this.date_of_birth
    };

    this.pc.createIdMapping(idMappingJson);

    console.log("Lead:createIdMapping:idMappingJson:", idMappingJson);
    /////// id mapping ends
    this.pc.saveUpdateClientData(clientDataJson).then(data => {
      debugger
      this.getdata();
    }).catch(error => {
      console.log(error);
    });
  }
  cookupCustomerId(firstname, lastname, clientId) {
    let firstNameLocal, lastNameLocal;
    try { firstNameLocal = firstname[0].toLowerCase(); } catch (error) { firstNameLocal = 'a' };
    try { lastNameLocal = lastname[0].toLowerCase(); } catch (error) { lastNameLocal = 'b' };
    return `${firstNameLocal}${lastNameLocal}${clientId}`;
  }



  closeModal() {
    document.getElementById('errorModal').style.display = 'none';
    window.location.href = this.pc.envCred.url;
    this.$gaService.gtag('event', "HomeLandingPage", {
      'event_category': "Error", 'event_label': "Popup Closed"
    });
  }
  openModal() {
    var message = "Our servers are taking too long to respond.Please try after sometime";

    const modalRef = this.modalService.open(ErroralertModalComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    let data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: message,
      image: 'assets/images/images/education2.png'
    }

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
    }, (reason) => {
    });
  }





  //urgent fix for uat
  saveAddressDetails(zip,clientId) {
    pc.BackendService.getGoogleAddressDetails(zip).then(
      async (res) => {
        console.log("BackendService.getGoogleAddressDetails success", res);
        await this.parseGoogleAddress(res.data.results[0],clientId);
      }
    ).catch(error => {
      console.log("BackendService.getGoogleAddressDetails error", error);
    })
  }

  async parseGoogleAddress(address,clientId) {
    let google_address = {
      "street_number_ln": '',
      "street_number_sn": '',
      "route_ln": '',
      "route_sn": '',
      "neighborhood_ln": '',
      "neighborhood_sn": '',
      "locality_ln": '',
      "locality_sn": '',
      "administrative_area_level_2_ln": '',
      "administrative_area_level_2_sn": '',
      "administrative_area_level_1_ln": '',
      "administrative_area_level_1_sn": '',
      "country_ln": '',
      "country_sn": '',
      "postal_code_ln": '',
      "postal_code_sn": '',
      "postal_code_suffix_ln": '',
      "postal_code_suffix_sn": '',
      "formatted_address": ''
    };
    let results = []
    results[0] = address;
    console.log("results address", results, results[0].address_components);
    for (let i = 0; i < results[0].address_components.length; i++) {
      // debugger
      let type = results[0].address_components[i].types[0];
      // debugger
      switch (type) {

        case "street_number":
          google_address.street_number_ln = results[0].address_components[i].long_name;
          google_address.street_number_sn = results[0].address_components[i].short_name;
          break;
        case "route":
          google_address.route_ln = results[0].address_components[i].long_name;
          google_address.route_sn = results[0].address_components[i].short_name;
          break;
        case "neighborhood":
          google_address.neighborhood_ln = results[0].address_components[i].long_name;
          google_address.neighborhood_sn = results[0].address_components[i].short_name;
          // addressJson.Addr1[2] = results[0].address_components[i].short_name;
          break;
        case "locality":
          google_address.locality_ln = results[0].address_components[i].long_name;
          google_address.locality_sn = results[0].address_components[i].short_name;
          break;
        case "administrative_area_level_2":
          google_address.administrative_area_level_2_ln = results[0].address_components[i].long_name;
          google_address.administrative_area_level_2_sn = results[0].address_components[i].short_name;
          break;
        case "administrative_area_level_1":
          google_address.administrative_area_level_1_ln = results[0].address_components[i].long_name;
          google_address.administrative_area_level_1_sn = results[0].address_components[i].short_name;
          break;
        case "country":
          google_address.country_ln = results[0].address_components[i].long_name;
          google_address.country_sn = results[0].address_components[i].short_name;
          break;
        case "postal_code":
          google_address.postal_code_ln = results[0].address_components[i].long_name;
          google_address.postal_code_sn = results[0].address_components[i].short_name;
          break;
        case "postal_code_suffix":
          // debugger
          google_address.postal_code_suffix_ln = results[0].address_components[i].long_name;
          google_address.postal_code_suffix_sn = results[0].address_components[i].short_name;
          break;
      }
    }
    google_address.formatted_address = results[0].formatted_address;
    console.log("google_address", google_address)
    google_address['id'] = clientId;
    await this.pc.saveUpdateClientData(google_address);
  }

  async createMaLog(cid, type) {
    this.convertDate();
    var currentdate = new Date();
    this.timeStamp = currentdate.getHours().toString() + currentdate.getMinutes().toString() + currentdate.getSeconds().toString() + "_" + (currentdate.getMonth() + 1).toString() + currentdate.getDate().toString() + currentdate.getFullYear().toString();
    let ddd = await this.convertDate();
    this.clientIdNew = "maclk" + ddd;
    console.log("timeStamp : ", this.timeStamp);
    let cdata = {
      'id': this.clientIdNew
    }
    let data = {
      'timeStamp': this.timeStamp,
      'ma_home_url': type == "home" ? localStorage.getItem("accessedHomeURL") : "NA",
      'ma_life_url': type == "life" ? localStorage.getItem("accessedLifeURL") : "NA",
      'ma_cid': cid,
      'ma_type': type,
    };
    cdata["ma_" + this.timeStamp.toString()] = JSON.stringify(data);
    console.log("timeStampObj : ", cdata);
    this.pc.saveUpdateClientData(cdata).then(data => {
      //console.log("timeStampObj data : ", data);
      return;
    }).catch(error => {
      console.log(error);
    });
  }

  async updateMaLog(type, input, cid) {
    console.log("timeStamp : ", this.timeStamp);
    let cdata = {
      'id': this.clientIdNew
    }
    this.pc.getAppData(this.clientIdNew).then(data => {
      debugger
      let dataFrom = JSON.parse(data.Payload);
      let existingData = dataFrom.body;
      let iddata = JSON.parse(existingData["ma_" + this.timeStamp.toString()]);

      iddata["hs_request"] = JSON.stringify(input);
      iddata["ma_cid"] = cid;

      cdata["ma_" + this.timeStamp.toString()] = iddata;
      console.log("timeStampObj : ", cdata);
      this.pc.saveUpdateClientData(cdata).then(data => {
        //console.log("timeStampObj data : ", data);
        return;
      }).catch(error => {
        console.log(error);
      });

    }).catch(error => {
      console.log(error);
    });
  }

  async convertDate() {
    const format = 'yyyy-MM-dd';
    const locale = 'en-US';
    const myDate = new Date();
    let date_str = formatDate(myDate, format, locale);
    var months = ["jan", "feb", "mar", "apr", "may", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];
    var days = ["one", "two", "three", "four", "five", "six", "seven", "eight", "nine", "ten", "eleven", "twelve","threett","fourt","fivet","sixt","sevent","eightt","ninet","twenty","tone","ttwo","tthree","tfour","tfive","tsix","tseven","teight","tnine","thirty","thirtyone"];
    let temp_date = date_str.split("-");
    let res = days[Number(temp_date[2]) - 1] + months[Number(temp_date[1]) - 1] + "twotwo";
    console.log("today", res);
    return res;
  }


}
