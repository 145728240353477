import { Component, EventEmitter, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { LifescoreErrorAlertComponent } from "./../../../components/lifescore-error-alert/lifescore-error-alert.component";
import { environment } from "./../../../../environments/environment";

@Component({
  selector: 'app-electronic-consent',
  templateUrl: './electronic-consent.component.html',
  styleUrls: ['./electronic-consent.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ElectronicConsentComponent implements OnInit {

  constructor(private pc: ProcessCacheService, private modalService: NgbModal) { }
  aboutApplicationDetails: any;
  phoneNumber: any;
  driverLicenseDetails: any;
  isActiveMilitary: any;
  isInsuredDependent: any;
  thirdPartyNotified: any;
  hasExistingLifeInsurance: any;
  insurancePurpose: any;
  //beneficieries:any;
  ownerInsuredDifferent: any;
  insuredLegalNameChanged: any;
  policyOwnerFirstName: any;
  policyOwnerLastName: any;
  policyOwnerDob: any;
  policyOwnerState: any;
  policyOwnerSSN: any;
  policyOwnerEmail: any;
  policyOwnerStreetAddress: any;
  policyOwnerZipcode: any;
  policyOwnerCity: any;
  hasDriverLicense: any;
  driverLicenseNumber: any;
  driverLicenseIssuedState: any;
  showTerms = false;
  showAssumptions: boolean = false;
  pageToOpen: string = '';
  paclifeQuote = "0";
  termLength = "0";
  coverageAmt = "0";
  loader = false;
  terminatingExistingPolicies: any;
  hasPendingApplications: any;
  hasExistingLifeInsuranceDetails: any;
  existingPolicyInfo
  useFundsFromExistingPolicies
  hasPendingApplicationDetails: any;
  hasPendingApplicationList = [];
  policyOwnerInfo = [];
  driverLicenseInfo = [];
  beneficieries = [];
  authorised
  ngOnInit(): void {
    debugger;
    this.loader = true;
    this.termLength = localStorage.getItem('bubble-termLength');
    this.coverageAmt = localStorage.getItem('bubble-coverage');
    this.coverageAmt = this.numberWithCommas(this.coverageAmt);

    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {
        let clientDataFiltered = JSON.parse(data.Payload)
        this.authorised = false;
        this.loader = false;
        this.policyOwnerInfo = clientDataFiltered.body.policyOwnerInfo;
        this.ownerInsuredDifferent = clientDataFiltered.body.ownerInsuredDifferent;
        this.insuredLegalNameChanged = clientDataFiltered.body.insuredLegalNameChanged;

        this.phoneNumber = localStorage.getItem('bubble-phone');

        this.isActiveMilitary = clientDataFiltered.body.isActiveMilitary;

        this.isInsuredDependent = clientDataFiltered.body.isInsuredDependent;

        this.thirdPartyNotified = clientDataFiltered.body.thirdPartyNotified;

        this.hasExistingLifeInsurance = clientDataFiltered.body.hasExistingLifeInsurance;

        this.insurancePurpose = clientDataFiltered.body.insurancePurpose;

        this.beneficieries = clientDataFiltered.body.addBeneficiaryInfo;

        this.hasPendingApplications = clientDataFiltered.body.hasPendingApplications;
        this.hasPendingApplicationList = clientDataFiltered.body.pendingApplicationInfo;


        this.terminatingExistingPolicies = clientDataFiltered.body.terminatingExistingPolicies;
        this.useFundsFromExistingPolicies = clientDataFiltered.body.useFundsFromExistingPolicies;

        this.existingPolicyInfo = clientDataFiltered.body.existingPolicyInfo;



        this.driverLicenseInfo = clientDataFiltered.body.driverLicenseInfo;
        this.hasDriverLicense = clientDataFiltered.body.hasDriverLicense;

        this.paclifeQuote = clientDataFiltered.body.paclifeQuote.premiumAmount;
        localStorage.setItem('policyNo', clientDataFiltered.body.paclifeTicket[0].policyNumber);



      }
    ).catch(error => {
      console.log(error);
    });

    //let clientData=localStorage.getItem('clientdata');
    //let clientDataFiltered= JSON.parse(clientData);


    // this.aboutApplicationDetails=localStorage.getItem('PacLife-PolicyOwnerDetails');
    // this.aboutApplicationDetails = JSON.parse(this.aboutApplicationDetails);
    // this.aboutApplicationDetails = this.aboutApplicationDetails;


    // Driver License Details
    // this.driverLicenseDetails = localStorage.getItem('PacLife-driverLicenseDetails');
    // this.driverLicenseDetails = JSON.parse(this.driverLicenseDetails);
    // this.driverLicenseDetails = this.driverLicenseDetails;

    // this.hasDriverLicense=clientDataFiltered.body.hasDriverLicense;
    // this.driverLicenseInfo=clientDataFiltered.body.driverLicenseInfo;



    //isMilitary Details
    //this.isActiveMilitary = localStorage.getItem('bubble-isActiveMilitary');



    //Insured Details
    // this.isInsuredDependent = localStorage.getItem('bubble-isInsuredDependent');


    //Third Party Notified


    //hasExistingLifeInsurance


    //insurancePurpose


    //beneficieries

    // this.beneficieries = localStorage.getItem('bubble-primarybeneficiariesDetails');
    // this.beneficieries = JSON.parse(this.beneficieries);
    // this.beneficieries = this.beneficieries;



    //Pending Application

    // this.hasPendingApplicationDetails= localStorage.getItem("PacLife-hasPendingApplicationDetails");
    // this.hasPendingApplicationDetails = JSON.parse(this.hasPendingApplicationDetails);
    // this.hasPendingApplicationList=this.hasPendingApplicationDetails.pendingApplicationInfo;
    // this.hasPendingApplications=this.hasPendingApplicationDetails.hasPendingApplications;




    // this.hasExistingLifeInsuranceDetails= localStorage.getItem("PacLife-hasExistingLifeInsuranceDetails");
    // this.hasExistingLifeInsuranceDetails = JSON.parse(this.hasExistingLifeInsuranceDetails);
    // this.terminatingExistingPolicies=this.hasExistingLifeInsuranceDetails.terminatingExistingPolicies;
    // this.useFundsFromExistingPolicies=this.hasExistingLifeInsuranceDetails.useFundsFromExistingPolicies;
    // this.existingPolicyInfo=this.hasExistingLifeInsuranceDetails.existingPolicyInfo





    // this.pc.getAppData(localStorage.getItem('clientID')).then(
    //   data => {
    //     let clientData = JSON.parse(data.Payload)
    //     console.log("this.pc.getAppData: ", clientData);
    //     this.paclifeQuote =  parseInt(clientData.body.paclifeQuote.premiumAmount).toString();
    //     localStorage.setItem('policyNo', clientData.body.paclifeTicket[0].policyNumber);


    //   }
    // ).catch(error => {
    //   console.log(error);
    // });
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    // return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

}
