import { Component, OnInit } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-back9-header',
  templateUrl: './back9-header.component.html',
  styleUrls: ['./back9-header.component.css']
})
export class Back9HeaderComponent implements OnInit {
  homeURL: any;

  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    this.homeURL = this.pc.envCred.url;
  }

}
