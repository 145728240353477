import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ProcessCacheService } from './process-cache.service';

@Injectable({
  providedIn: 'root'
})
export class TrackerService {

  percentFromLocal = localStorage.getItem('trackerPercent');

  percent: number = this.percentFromLocal ? Number(this.percentFromLocal) : 10;
  trackingType = 'life';

  private progressPercentage = new BehaviorSubject<number>(this.percent);
  private trackingTypeSub = new BehaviorSubject<string>(this.trackingType);

  constructor(private pc: ProcessCacheService) {
    if (!this.pc.clientId) {
      this.pc.getClientId();
    } if (this.percentFromLocal) {
      localStorage.removeItem('trackerPercent');
    }
  }

  saveProgress({ totalPages, currentPage }) {
    debugger
    const json: any = {
      totalPages,
      currentPage
    };
    json.id = this.pc.clientId;
    console.log(json, 'json');
    this.pc.saveUpdateClientData(json);
  }

  getProgressStatus(): Observable<any> {
    return this.progressPercentage.asObservable();
  }

  getTrackingType(): Observable<any> {
    return this.trackingTypeSub.asObservable();
  }

  getClientProgessFromCache() {
    this.pc.getAppDataFromLocal(this.pc.clientId)
      .then(clientData => {
        if (clientData.Payload.currentPage) {
          console.log(JSON.parse(clientData.Payload).body, 'clientData');
        }
      });
  }

  changeTrackingType(trackerType: string) {
    this.trackingType = trackerType;
    this.trackingTypeSub.next(this.trackingType);
  }

  changeProgressStatus({ totalPages, currentPage }) {
    debugger
    const data = { totalPages, currentPage };
    this.percent = this.pc.getpercent(data);
    console.log(this.percent, data, 'tracker service');
    this.saveProgress(data);
    if (this.percent > 100) {
      if (localStorage.getItem('insuranceType') == 'homeLife' || localStorage.getItem('insuranceType')=='life' ||localStorage.getItem('insuranceType') == 'home' )  {
        this.percent = this.percent;
      }else{
        this.percent = 100;
      }

    }

    this.progressPercentage.next(this.percent);
  }
}
