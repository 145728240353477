import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-personal-details-question3',
  templateUrl: './personal-details-question3.component.html',
  styleUrls: ['./personal-details-question3.component.css']
})
export class PersonalDetailsQuestion3Component implements OnInit {
  @Output() nextquestion2 = new EventEmitter();
  PhoneNumber:any;
  heroForm:any;
  phoneerror: any;  
  constructor() { }

  ngOnInit(): void {
    this.PhoneNumber='+1 (408) 679-3049';
    this.heroForm = new FormGroup({
      PhoneNumber: new FormControl(this.PhoneNumber),
     
    });
  }
  next() {
  
    let etty = {
      "questionNo": "2",
      "nextprev": 1
    }
    this.nextquestion2.emit(etty);
  }

 
}
