import { Component, OnInit } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-app-flow-header',
  templateUrl: './app-flow-header.component.html',
  styleUrls: ['./app-flow-header.component.css']
})
export class AppFlowHeaderComponent implements OnInit {

  constructor(private pc: ProcessCacheService) { }
  homeURL:string;
  ngOnInit(): void {
    // debugger;
    this.homeURL = this.pc.envCred.url;

  }


  hideTooltip(  ) {
    ( document.getElementById( 'contactTooltip' ) as HTMLElement ).style.visibility = 'hidden';
  }

  showTooltip(  ) {
    ( document.getElementById( 'contactTooltip' ) as HTMLElement ).style.visibility = 'visible';
  }

}
