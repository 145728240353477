import { Component, OnDestroy, OnInit,EventEmitter,Output,  ViewChild } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-question1',
  templateUrl: './question1.component.html',
  styleUrls: ['./question1.component.css']
})
export class Question1Component implements OnInit {

  @Output() nextquestion1 = new EventEmitter();
  livingsizeSqft: any;
  homeType: any;

  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {     
    this.pc.castpredictedScores.subscribe(
      (data: any) => {
        this.livingsizeSqft = data.attomLivingsizeSqft;
        if (this.livingsizeSqft==null||this.livingsizeSqft==undefined) {
          this.livingsizeSqft = 0;
        }
      },
      (error) => console.log(error)
    );
    this.homeType = "DW";
  }

  next() {
    localStorage.setItem("bubble-livingSizeSQFT", this.livingsizeSqft);
    localStorage.setItem("bubble-homeType", this.homeType);
    let etty = {
      "questionNo": "1",
      "livingSizeSQFT": this.livingsizeSqft,
      "homeType": this.homeType,
      "nextprev": 1
    }
    this.nextquestion1.emit(etty);
  }

}
