import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-has-chronic-disease',
  templateUrl: './has-chronic-disease.component.html',
  styleUrls: ['./has-chronic-disease.component.css']
})
export class HasChronicDiseaseComponent implements OnInit {
  @Output() nextquestion11 = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  SelectedOption:any;
  public GetOptionsyesno(data){
    
    this.SelectedOption=data;
    if(this.SelectedOption==0){
      document.getElementById('ChronicDiseaseNo').style.border='solid 2px orange';
      document.getElementById('ChronicDiseaseyes').style.border='solid 1px #dae7ec';
     
    }else{
     document.getElementById('ChronicDiseaseyes').style.border='solid 2px orange';
     document.getElementById('ChronicDiseaseNo').style.border='solid 1px #dae7ec';
    }
  }

  
  next() {
    let etty = {
      "questionNo": "11",
      "nextprev": 1
    }
    this.nextquestion11.emit(etty);
  }

}
