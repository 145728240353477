import { Component, OnInit  } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-cost-predictor',
  templateUrl: './cost-predictor.component.html',
  styleUrls: ['./cost-predictor.component.css']
})
  
export class CostPredictorComponent implements OnInit {

  tipsandInsights: any;
  predictedScoreData: any;
  homeownercost: number;
  insurancecost: number;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  
  constructor(private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) {  
  }

  storedPosts = []

  onPostAdded(event) {
    // debugger
    this.storedPosts.push(event);
    // debugger
  }

  ngOnInit(): void { 
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      console.log("uniqueId : ", this.uniqueId);
      console.log("clientId : ", this.clientId);
      console.log("displayAddress : ", this.displayAddress);      
      this.pc.castpredictedScores.subscribe(
        (data: any) => {
          this.predictedScoreData = data;
           //console.log('values :', this.predictedScoreData);
           this.homeownercost = Number(this.predictedScoreData.mortgageAmount)+Number(this.predictedScoreData.utilityAmount)+Number(this.predictedScoreData.propertyTaxAmount);
           this.insurancecost = Number(this.predictedScoreData.homeInsuranceAmount)+Number(this.predictedScoreData.lifeInsuranceAmount);
        },
        (error) => console.log(error)
      );

      this.pc.getquickquotes(this.uniqueId);
      // let inputJson = {
      //   "lastName": "shetty",
      //   "firstName": "sanath",
      //   "middleName": "",
      //   "addressType": "PhysicalRisk",
      //   "address": "4529 winona ct",
      //   "city": "Denver",
      //   "state": "Colorado",
      //   "zipcode": "80212"
      // };
      // this.processCache.getQuickQuotes(this.uniqueId,"stillWater");
      // //this.processCache.invokeStillWater(this.uniqueId, "home");      
    }
    )
    this.tipsandInsights = {
      tip: "That's a great place to live!",
      insightTitle: "Location Matters",
      insightText:"Inyo has a quality of life score that's better than 90% of neighbouring counties in California."
    };    
  }

}