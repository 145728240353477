import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-kopopup3',
  templateUrl: './kopopup3.component.html',
  styleUrls: ['./kopopup3.component.css']
})
export class KOpopup3Component implements OnInit {
  uniqueId: any;
  constructionList: any;
  constructiontype: any;
  @Output() rooftypechanged = new EventEmitter<any>();
  @Output() marooftypechanged = new EventEmitter<any>();
  @Output() closemodel = new EventEmitter<any>();
  @Output() maclosemodel = new EventEmitter<any>();
  selectconstructiontype: any;
  constructor(private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.constructiontype = '';
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      let quickquote = new pc.Quotes(this.uniqueId);
      let optionsList = quickquote.getSWPropertyOptions();
      this.constructionList = optionsList.construction_type;
    }

    )
  }

  public save() {
    debugger
    let commonInput = JSON.parse(localStorage.getItem("commonInput"));
    commonInput['propertyDetails'].roof_constructed = this.constructiontype;
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    this.marooftypechanged.emit();
  }
  public closepopup(){
    this.closemodel.emit();
    this.maclosemodel.emit();
  }
}
