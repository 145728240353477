import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-diabetes',
  templateUrl: './diabetes.component.html',
  styleUrls: ['./diabetes.component.css']
})
export class DiabetesComponent implements OnInit {
  @Output() nextquestion5 = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  next() {
    let etty = {
      "questionNo": "5",
      "nextprev": 1
    }
    this.nextquestion5.emit(etty);
  }

}
