import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-kopopup1',
  templateUrl: './kopopup1.component.html',
  styleUrls: ['./kopopup1.component.css']
})
export class Kopopup1Component implements OnInit {
  uniqueId: any;
  roofTypeList: any;
  rooftype: any;
  roofupdatedyear: any;
  yearbuilt: any;
  roofupdatedyearerror: any;
  @Output() rooftypechanged = new EventEmitter<any>();
  @Output() marooftypechanged = new EventEmitter<any>();
  @Output() closemodel = new EventEmitter<any>();
  @Output() maclosemodel = new EventEmitter<any>();
  constructor(private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    debugger
    this.rooftype = '';
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      let quickquote = new pc.Quotes(this.uniqueId);
      let optionsList = quickquote.getSWPropertyOptions();
      this.roofTypeList = optionsList.roof_type;
      let commonInput = JSON.parse(localStorage.getItem("commonInput"));
      this.yearbuilt = commonInput['propertyDetails'].year_built;
    }

    )


  }

  public roofupdated() {
    debugger
    if (this.roofupdatedyear < Number(this.yearbuilt)) {
      this.roofupdatedyearerror = "Roof Updated Year Should be in Between " + this.yearbuilt + " And " + new Date().getFullYear();
    }
    else if (this.roofupdatedyear > Number(new Date().getFullYear())) {
      this.roofupdatedyearerror = "Roof Updated Year can not be  greater than " + new Date().getFullYear();
    }
    else {
      this.roofupdatedyearerror = ''
    }

  }

  public save() {
    debugger
    let commonInput = JSON.parse(localStorage.getItem("commonInput"));
    commonInput['propertyDetails'].roofUpdateddd = this.roofupdatedyear;
    commonInput['propertyDetails'].roofType = this.rooftype;
    localStorage.setItem('commonInput', JSON.stringify(commonInput));
    this.rooftypechanged.emit();
    this.marooftypechanged.emit();
  }
  public closepopup() {
    this.closemodel.emit();
    this.maclosemodel.emit();
  }
}
