import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-personal-details-question4',
  templateUrl: './personal-details-question4.component.html',
  styleUrls: ['./personal-details-question4.component.css']
})
export class PersonalDetailsQuestion4Component implements OnInit {
  @Output() nextquestion3 = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  next() {
  
    let etty = {
      "questionNo": "3",
      "nextprev": 1
    }
    this.nextquestion3.emit(etty);
  }

}
