import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import e from 'express';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-has-existing-life-insurance',
  templateUrl: './has-existing-life-insurance.component.html',
  styleUrls: ['./has-existing-life-insurance.component.css']
})
export class HasExistingLifeInsuranceComponent implements OnInit {
  companyList: any;
  existingPolicyInfo: any;
  hasExistingLifeInsurance: any;
  terminatingExistingPolicies: any;
  useFundsFromExistingPolicies: any;
  constructor(
    private pc: ProcessCacheService,
    private utilityService:UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,
    private service:ParentChildService) { }
  @Input() questionDetails: [];
  dynamicdiv = [];
  existingPolicyProductTypeOptions: any;
  existingPolicyTypeOptions: any;
  existingPolicynPurposeOptions: any;
  existingPolicyIssueYearOptions: any;
  OptionsForContracts: boolean;
  OptionsForDiscontinous: boolean;
  editForTheComponent: any;
  dropdownlist: any;
  hasExistingLifeInsuranceDetails: any;
  showloader:boolean;
  @Output() public hasExistingLifeInsuranceOutput =
    new EventEmitter();
  checkyes: any;

  ngOnInit(): void {
    this.showloader=true;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_11_ExisitingLI'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    this.service.callComponentMethod();
    document.getElementById('appFlowFooterID').style.display = 'block';
    this.dropdownlist = [
      {
        'Ext_Carr_Name': 'ACACIA',
        'Ext_Carr_Id': 127
      },
      {
        'Ext_Carr_Name': 'Academy Life Insurance',
        'Ext_Carr_Id': 128
      },
      {
        'Ext_Carr_Name': 'AEGON',
        'Ext_Carr_Id': 129
      },
      {
        'Ext_Carr_Name': 'AETNA LIFE ',
        'Ext_Carr_Id': 130
      },
      {
        'Ext_Carr_Name': 'AFLAC LIFE INSURANCE',
        'Ext_Carr_Id': 131
      },
      {
        'Ext_Carr_Name': 'AICPA',
        'Ext_Carr_Id': 132
      },
      {
        'Ext_Carr_Name': 'AMERICAN GENERAL',
        'Ext_Carr_Id': 133
      },
      {
        'Ext_Carr_Name': 'AIG',
        'Ext_Carr_Id': 134
      },
      {
        'Ext_Carr_Name': 'AIG SUN AMERICA',
        'Ext_Carr_Id': 135
      },
      {
        'Ext_Carr_Name': 'ALEXANDER HAMILTON LIFE',
        'Ext_Carr_Id': 136
      },
      {
        'Ext_Carr_Name': 'ALFA Insurance',
        'Ext_Carr_Id': 137
      },
      {
        'Ext_Carr_Name': 'All American Life',
        'Ext_Carr_Id': 138
      },
      {
        'Ext_Carr_Name': 'ALLIANZ INSURANCE',
        'Ext_Carr_Id': 139
      },
      {
        'Ext_Carr_Name': 'Allied Life',
        'Ext_Carr_Id': 141
      },
      {
        'Ext_Carr_Name': 'ALLMERICA FINANCIAL',
        'Ext_Carr_Id': 142
      },
      {
        'Ext_Carr_Name': 'ALLSTATE LIFE',
        'Ext_Carr_Id': 143
      },
      {
        'Ext_Carr_Name': 'ALLSTATE BENEFITS',
        'Ext_Carr_Id': 144
      },
      {
        'Ext_Carr_Name': 'American Amicable Life',
        'Ext_Carr_Id': 145
      },
      {
        'Ext_Carr_Name': 'American Bankers',
        'Ext_Carr_Id': 146
      },
      {
        'Ext_Carr_Name': 'American Community',
        'Ext_Carr_Id': 147
      },
      {
        'Ext_Carr_Name': 'AMERIPRISE ANNUITIES',
        'Ext_Carr_Id': 148
      },
      {
        'Ext_Carr_Name': 'American Equity',
        'Ext_Carr_Id': 149
      },
      {
        'Ext_Carr_Name': 'IDS AMERICAN EXPRESS',
        'Ext_Carr_Id': 150
      },
      {
        'Ext_Carr_Name': 'AMERICAN FAMILY INSURANCE COMPANY',
        'Ext_Carr_Id': 151
      },
      {
        'Ext_Carr_Name': 'American Fidelity Life',
        'Ext_Carr_Id': 152
      },
      {
        'Ext_Carr_Name': 'American Founders Life',
        'Ext_Carr_Id': 153
      },
      {
        'Ext_Carr_Name': 'American General Life & Accident',
        'Ext_Carr_Id': 155
      },
      {
        'Ext_Carr_Name': 'American Guaranty Life',
        'Ext_Carr_Id': 156
      },
      {
        'Ext_Carr_Name': 'American Guardian Life Assurance',
        'Ext_Carr_Id': 157
      },
      {
        'Ext_Carr_Name': 'American Health and Life Insurance',
        'Ext_Carr_Id': 158
      },
      {
        'Ext_Carr_Name': 'American Heritage Life',
        'Ext_Carr_Id': 159
      },
      {
        'Ext_Carr_Name': 'American Income Life',
        'Ext_Carr_Id': 160
      },
      {
        'Ext_Carr_Name': 'American Investors',
        'Ext_Carr_Id': 161
      },
      {
        'Ext_Carr_Name': 'American Investors Life Insurance Co.',
        'Ext_Carr_Id': 163
      },
      {
        'Ext_Carr_Name': 'American Legacy',
        'Ext_Carr_Id': 164
      },
      {
        'Ext_Carr_Name': 'American Life and Casualty',
        'Ext_Carr_Id': 165
      },
      {
        'Ext_Carr_Name': 'American Mutual Life',
        'Ext_Carr_Id': 166
      },
      {
        'Ext_Carr_Name': 'American National Family',
        'Ext_Carr_Id': 167
      },
      {
        'Ext_Carr_Name': 'AMERICAN NATIONAL ',
        'Ext_Carr_Id': 168
      },
      {
        'Ext_Carr_Name': 'AMERICAN UNITED LIFE',
        'Ext_Carr_Id': 169
      },
      {
        'Ext_Carr_Name': 'American Mayflower Life',
        'Ext_Carr_Id': 170
      },
      {
        'Ext_Carr_Name': 'AMERICAN MEMORIAL LIFE',
        'Ext_Carr_Id': 171
      },
      {
        'Ext_Carr_Name': 'American Merchants',
        'Ext_Carr_Id': 172
      },
      {
        'Ext_Carr_Name': 'American Pioneer Life',
        'Ext_Carr_Id': 173
      },
      {
        'Ext_Carr_Name': 'American Republic Insurance Company',
        'Ext_Carr_Id': 174
      },
      {
        'Ext_Carr_Name': 'AMERICAN SKANDIA LIFE',
        'Ext_Carr_Id': 175
      },
      {
        'Ext_Carr_Name': 'American States Life',
        'Ext_Carr_Id': 176
      },
      {
        'Ext_Carr_Name': 'American Underwriters Life',
        'Ext_Carr_Id': 177
      },
      {
        'Ext_Carr_Name': 'Americo',
        'Ext_Carr_Id': 178
      },
      {
        'Ext_Carr_Name': 'Americo Financial Life & Annuity',
        'Ext_Carr_Id': 179
      },
      {
        'Ext_Carr_Name': 'AMERIPRISE',
        'Ext_Carr_Id': 180
      },
      {
        'Ext_Carr_Name': 'AMERITAS LIFE',
        'Ext_Carr_Id': 181
      },
      {
        'Ext_Carr_Name': 'AMERUS LIFE',
        'Ext_Carr_Id': 182
      },
      {
        'Ext_Carr_Name': 'AMICA LIFE INSURANCE',
        'Ext_Carr_Id': 183
      },
      {
        'Ext_Carr_Name': 'Amours Annuity Group',
        'Ext_Carr_Id': 184
      },
      {
        'Ext_Carr_Name': 'Anchor National Life',
        'Ext_Carr_Id': 185
      },
      {
        'Ext_Carr_Name': 'Central Life Assurance Company',
        'Ext_Carr_Id': 186
      },
      {
        'Ext_Carr_Name': 'Central Security Life',
        'Ext_Carr_Id': 187
      },
      {
        'Ext_Carr_Name': 'Central States Health and Life of Omaha',
        'Ext_Carr_Id': 188
      },
      {
        'Ext_Carr_Name': 'CENTURY LIFE OF AMERICA',
        'Ext_Carr_Id': 189
      },
      {
        'Ext_Carr_Name': 'ANCHOR NATIONAL SUN LIFE',
        'Ext_Carr_Id': 190
      },
      {
        'Ext_Carr_Name': 'Armed Forces Benefit Association',
        'Ext_Carr_Id': 191
      },
      {
        'Ext_Carr_Name': 'ARMY AF MUTUAL AID ASSOCIATION (AAFMAA)',
        'Ext_Carr_Id': 192
      },
      {
        'Ext_Carr_Name': 'Assurant',
        'Ext_Carr_Id': 193
      },
      {
        'Ext_Carr_Name': 'ASSURITY LIFE',
        'Ext_Carr_Id': 194
      },
      {
        'Ext_Carr_Name': 'ATLA Insurance Plans',
        'Ext_Carr_Id': 195
      },
      {
        'Ext_Carr_Name': 'ATLAS Life Insurance',
        'Ext_Carr_Id': 196
      },
      {
        'Ext_Carr_Name': 'Aurora National Life',
        'Ext_Carr_Id': 197
      },
      {
        'Ext_Carr_Name': 'Auto Club Life ',
        'Ext_Carr_Id': 198
      },
      {
        'Ext_Carr_Name': 'Auto Owners Life Insurance Co.',
        'Ext_Carr_Id': 199
      },
      {
        'Ext_Carr_Name': 'AVIVA',
        'Ext_Carr_Id': 200
      },
      {
        'Ext_Carr_Name': 'AXA',
        'Ext_Carr_Id': 201
      },
      {
        'Ext_Carr_Name': 'AXA - EVLICO',
        'Ext_Carr_Id': 202
      },
      {
        'Ext_Carr_Name': 'AXA-Equitable',
        'Ext_Carr_Id': 203
      },
      {
        'Ext_Carr_Name': 'Baltimore Life Ins. Co.',
        'Ext_Carr_Id': 204
      },
      {
        'Ext_Carr_Name': 'BANKERS LIFE AND CASUALTY',
        'Ext_Carr_Id': 205
      },
      {
        'Ext_Carr_Name': 'BANKERS NATIONAL LIFE',
        'Ext_Carr_Id': 206
      },
      {
        'Ext_Carr_Name': 'BANKERS SECURITY LIFE',
        'Ext_Carr_Id': 207
      },
      {
        'Ext_Carr_Name': 'Banner Life Insurance Co.',
        'Ext_Carr_Id': 208
      },
      {
        'Ext_Carr_Name': 'BAYSTATE LIFE ',
        'Ext_Carr_Id': 209
      },
      {
        'Ext_Carr_Name': 'Beneficial Life',
        'Ext_Carr_Id': 210
      },
      {
        'Ext_Carr_Name': 'Berkshire Life Insurance Co.',
        'Ext_Carr_Id': 211
      },
      {
        'Ext_Carr_Name': 'BOLLINGER INSURANCE',
        'Ext_Carr_Id': 212
      },
      {
        'Ext_Carr_Name': 'Boston Mutual Life',
        'Ext_Carr_Id': 213
      },
      {
        'Ext_Carr_Name': 'BUSINESSMENS ASSURANCE',
        'Ext_Carr_Id': 214
      },
      {
        'Ext_Carr_Name': 'Calfarm Life',
        'Ext_Carr_Id': 215
      },
      {
        'Ext_Carr_Name': 'CANADA LIFE',
        'Ext_Carr_Id': 216
      },
      {
        'Ext_Carr_Name': 'Capital Bankers Life',
        'Ext_Carr_Id': 217
      },
      {
        'Ext_Carr_Name': 'CAPITOL LIFE INSURANCE CO.',
        'Ext_Carr_Id': 218
      },
      {
        'Ext_Carr_Name': 'CATHOLIC UNITED FINANCIAL ',
        'Ext_Carr_Id': 219
      },
      {
        'Ext_Carr_Name': 'Catholic Life Insurance',
        'Ext_Carr_Id': 220
      },
      {
        'Ext_Carr_Name': 'CATHOLIC FINANCIAL LIFE INSURANCE',
        'Ext_Carr_Id': 221
      },
      {
        'Ext_Carr_Name': 'Catholic Knights Insurance',
        'Ext_Carr_Id': 222
      },
      {
        'Ext_Carr_Name': 'Catholic Order of Foresters',
        'Ext_Carr_Id': 223
      },
      {
        'Ext_Carr_Name': 'Catholic Workman Life Insurance',
        'Ext_Carr_Id': 224
      },
      {
        'Ext_Carr_Name': 'Central United Life',
        'Ext_Carr_Id': 225
      },
      {
        'Ext_Carr_Name': 'CGU Life Insurance',
        'Ext_Carr_Id': 226
      },
      {
        'Ext_Carr_Name': 'Champions Pacific Standard Life of Texas',
        'Ext_Carr_Id': 227
      },
      {
        'Ext_Carr_Name': 'Charter National Life',
        'Ext_Carr_Id': 228
      },
      {
        'Ext_Carr_Name': 'Chase Insurance',
        'Ext_Carr_Id': 229
      },
      {
        'Ext_Carr_Name': 'CHUBB LIFE',
        'Ext_Carr_Id': 230
      },
      {
        'Ext_Carr_Name': 'CHUBB SOVEREIGN',
        'Ext_Carr_Id': 231
      },
      {
        'Ext_Carr_Name': 'CIGNA',
        'Ext_Carr_Id': 232
      },
      {
        'Ext_Carr_Name': 'WOODMEN OF THE WORLD ASSURED LIFE ASSOCIATION',
        'Ext_Carr_Id': 233
      },
      {
        'Ext_Carr_Name': 'First Catholic Slovak Union',
        'Ext_Carr_Id': 234
      },
      {
        'Ext_Carr_Name': 'First Life America',
        'Ext_Carr_Id': 235
      },
      {
        'Ext_Carr_Name': 'First Life Assurance Company',
        'Ext_Carr_Id': 236
      },
      {
        'Ext_Carr_Name': 'FIRST PENN-PACIFIC',
        'Ext_Carr_Id': 237
      },
      {
        'Ext_Carr_Name': 'First Sun America',
        'Ext_Carr_Id': 238
      },
      {
        'Ext_Carr_Name': 'Cincinnati Life Insurance Company',
        'Ext_Carr_Id': 239
      },
      {
        'Ext_Carr_Name': 'Citi Assurance Services',
        'Ext_Carr_Id': 240
      },
      {
        'Ext_Carr_Name': 'Citizen Insurance Company of America',
        'Ext_Carr_Id': 241
      },
      {
        'Ext_Carr_Name': 'CLARICA',
        'Ext_Carr_Id': 242
      },
      {
        'Ext_Carr_Name': 'College Life Insurance',
        'Ext_Carr_Id': 243
      },
      {
        'Ext_Carr_Name': 'COLONIAL LIFE & ACCIDENT',
        'Ext_Carr_Id': 244
      },
      {
        'Ext_Carr_Name': 'Colorado Bankers Life Insurance Company',
        'Ext_Carr_Id': 245
      },
      {
        'Ext_Carr_Name': 'COLUMBIAN FINANCIAL ',
        'Ext_Carr_Id': 247
      },
      {
        'Ext_Carr_Name': 'COLUMBIAN MUTUAL',
        'Ext_Carr_Id': 248
      },
      {
        'Ext_Carr_Name': 'Columbus Life Insurance Co.',
        'Ext_Carr_Id': 249
      },
      {
        'Ext_Carr_Name': 'Combined Insurance Co. of America',
        'Ext_Carr_Id': 251
      },
      {
        'Ext_Carr_Name': 'COMPANION LIFE INSURANCE CO.',
        'Ext_Carr_Id': 253
      },
      {
        'Ext_Carr_Name': 'Concord Life Insurance Co.',
        'Ext_Carr_Id': 254
      },
      {
        'Ext_Carr_Name': 'Confederation  Life',
        'Ext_Carr_Id': 255
      },
      {
        'Ext_Carr_Name': 'Connecticut General',
        'Ext_Carr_Id': 256
      },
      {
        'Ext_Carr_Name': 'Constitution Life',
        'Ext_Carr_Id': 257
      },
      {
        'Ext_Carr_Name': 'Continental General Ins. Co.',
        'Ext_Carr_Id': 258
      },
      {
        'Ext_Carr_Name': 'Cotton States Insurance ',
        'Ext_Carr_Id': 259
      },
      {
        'Ext_Carr_Name': 'CONSECO',
        'Ext_Carr_Id': 260
      },
      {
        'Ext_Carr_Name': 'Country Financial',
        'Ext_Carr_Id': 261
      },
      {
        'Ext_Carr_Name': 'COUNTRY INVESTORS INSURANCE',
        'Ext_Carr_Id': 262
      },
      {
        'Ext_Carr_Name': 'COUNTRY LIFE INSURANCE',
        'Ext_Carr_Id': 263
      },
      {
        'Ext_Carr_Name': 'Croatian Fraternal Union',
        'Ext_Carr_Id': 264
      },
      {
        'Ext_Carr_Name': 'Crown Life',
        'Ext_Carr_Id': 265
      },
      {
        'Ext_Carr_Name': 'CSA Fraternal',
        'Ext_Carr_Id': 266
      },
      {
        'Ext_Carr_Name': 'CUNA Life Insurance Company',
        'Ext_Carr_Id': 267
      },
      {
        'Ext_Carr_Name': 'CUNA MUTUAL',
        'Ext_Carr_Id': 268
      },
      {
        'Ext_Carr_Name': 'DEGREE OF HONOR ',
        'Ext_Carr_Id': 269
      },
      {
        'Ext_Carr_Name': 'Delta Financial Benefit',
        'Ext_Carr_Id': 270
      },
      {
        'Ext_Carr_Name': 'DEPARTMENT OF VETERAN\'S AFFAIRS',
        'Ext_Carr_Id': 271
      },
      {
        'Ext_Carr_Name': 'EMC National Life',
        'Ext_Carr_Id': 272
      },
      {
        'Ext_Carr_Name': 'Empire General Life Insurance Company',
        'Ext_Carr_Id': 273
      },
      {
        'Ext_Carr_Name': 'Employers Modern Life',
        'Ext_Carr_Id': 274
      },
      {
        'Ext_Carr_Name': 'Equitable Life  ',
        'Ext_Carr_Id': 275
      },
      {
        'Ext_Carr_Name': 'Equitable Life of Washington DC',
        'Ext_Carr_Id': 276
      },
      {
        'Ext_Carr_Name': 'Equitable of Colorado',
        'Ext_Carr_Id': 277
      },
      {
        'Ext_Carr_Name': 'EQUITABLE OF IOWA',
        'Ext_Carr_Id': 278
      },
      {
        'Ext_Carr_Name': 'First Catholic Slovak at Ladies Association',
        'Ext_Carr_Id': 280
      },
      {
        'Ext_Carr_Name': 'Equitable Reserve',
        'Ext_Carr_Id': 281
      },
      {
        'Ext_Carr_Name': 'Erie Family Life Insurance Co.',
        'Ext_Carr_Id': 282
      },
      {
        'Ext_Carr_Name': 'Family Circle Insurance Services',
        'Ext_Carr_Id': 283
      },
      {
        'Ext_Carr_Name': 'Family Life & Investment Life Insurance',
        'Ext_Carr_Id': 284
      },
      {
        'Ext_Carr_Name': 'FARM BUREAU LIFE',
        'Ext_Carr_Id': 285
      },
      {
        'Ext_Carr_Name': 'FARM BUREAU LIFE INS. CO. OF MISSOURI',
        'Ext_Carr_Id': 286
      },
      {
        'Ext_Carr_Name': 'Farm Bureau of Michigan',
        'Ext_Carr_Id': 287
      },
      {
        'Ext_Carr_Name': 'Farm Family Life',
        'Ext_Carr_Id': 288
      },
      {
        'Ext_Carr_Name': 'Farmers and Traders Life Insurance Co.',
        'Ext_Carr_Id': 289
      },
      {
        'Ext_Carr_Name': 'FARMERS',
        'Ext_Carr_Id': 290
      },
      {
        'Ext_Carr_Name': 'Federal Home Life Insurance Co.',
        'Ext_Carr_Id': 292
      },
      {
        'Ext_Carr_Name': 'Federal Kemper Life',
        'Ext_Carr_Id': 293
      },
      {
        'Ext_Carr_Name': 'FEDERAL LIFE INSURANCE CO',
        'Ext_Carr_Id': 294
      },
      {
        'Ext_Carr_Name': 'Federated Insurance',
        'Ext_Carr_Id': 295
      },
      {
        'Ext_Carr_Name': 'Fidelity Mutual',
        'Ext_Carr_Id': 296
      },
      {
        'Ext_Carr_Name': 'Fidelity Security Life Insurance Co.',
        'Ext_Carr_Id': 297
      },
      {
        'Ext_Carr_Name': 'Old Fidelity Union ',
        'Ext_Carr_Id': 298
      },
      {
        'Ext_Carr_Name': 'FIDELITY AND GUARANTY LIFE',
        'Ext_Carr_Id': 299
      },
      {
        'Ext_Carr_Name': 'Fidelity Bankers',
        'Ext_Carr_Id': 300
      },
      {
        'Ext_Carr_Name': 'Fidelity Future Reserve',
        'Ext_Carr_Id': 301
      },
      {
        'Ext_Carr_Name': 'FIRST COLONY LIFE',
        'Ext_Carr_Id': 302
      },
      {
        'Ext_Carr_Name': 'FLIAC',
        'Ext_Carr_Id': 303
      },
      {
        'Ext_Carr_Name': 'FIRST VARIABLE LIFE',
        'Ext_Carr_Id': 304
      },
      {
        'Ext_Carr_Name': 'Forethought Life Insurance Co.',
        'Ext_Carr_Id': 305
      },
      {
        'Ext_Carr_Name': 'Fort Dearborn Life Insurance Co.',
        'Ext_Carr_Id': 306
      },
      {
        'Ext_Carr_Name': 'Fortis/Time Management ',
        'Ext_Carr_Id': 307
      },
      {
        'Ext_Carr_Name': 'Franklin Life  ',
        'Ext_Carr_Id': 308
      },
      {
        'Ext_Carr_Name': 'Garden State Life',
        'Ext_Carr_Id': 309
      },
      {
        'Ext_Carr_Name': 'GE Capital Assurance',
        'Ext_Carr_Id': 310
      },
      {
        'Ext_Carr_Name': 'GENERAL AMERICAN LIFE',
        'Ext_Carr_Id': 311
      },
      {
        'Ext_Carr_Name': 'General Life',
        'Ext_Carr_Id': 312
      },
      {
        'Ext_Carr_Name': 'GENWORTH LIFE INSURANCE',
        'Ext_Carr_Id': 313
      },
      {
        'Ext_Carr_Name': 'Gerber Life Insurance',
        'Ext_Carr_Id': 314
      },
      {
        'Ext_Carr_Name': 'Germantown Insurance Company',
        'Ext_Carr_Id': 315
      },
      {
        'Ext_Carr_Name': 'Gleaner Life',
        'Ext_Carr_Id': 316
      },
      {
        'Ext_Carr_Name': 'GLOBE LIFE',
        'Ext_Carr_Id': 317
      },
      {
        'Ext_Carr_Name': 'Golden Rule Insurance',
        'Ext_Carr_Id': 318
      },
      {
        'Ext_Carr_Name': 'Golden State Life Insurance',
        'Ext_Carr_Id': 319
      },
      {
        'Ext_Carr_Name': 'Grand Lodge Sons of Hermann',
        'Ext_Carr_Id': 321
      },
      {
        'Ext_Carr_Name': 'Grand Pacific Life Insurance',
        'Ext_Carr_Id': 322
      },
      {
        'Ext_Carr_Name': 'Grange Mutual Life',
        'Ext_Carr_Id': 323
      },
      {
        'Ext_Carr_Name': 'Great American Life Ins. Co.',
        'Ext_Carr_Id': 324
      },
      {
        'Ext_Carr_Name': 'Great American Reserve',
        'Ext_Carr_Id': 325
      },
      {
        'Ext_Carr_Name': 'Great Southern Life',
        'Ext_Carr_Id': 326
      },
      {
        'Ext_Carr_Name': 'Great West Life',
        'Ext_Carr_Id': 327
      },
      {
        'Ext_Carr_Name': 'Great Western Life and Annuities',
        'Ext_Carr_Id': 328
      },
      {
        'Ext_Carr_Name': 'Greek Catholic Union of USA',
        'Ext_Carr_Id': 329
      },
      {
        'Ext_Carr_Name': 'Group Health Mutual',
        'Ext_Carr_Id': 330
      },
      {
        'Ext_Carr_Name': 'Guarantee Life ',
        'Ext_Carr_Id': 331
      },
      {
        'Ext_Carr_Name': 'Guarantee Security Life',
        'Ext_Carr_Id': 332
      },
      {
        'Ext_Carr_Name': 'GUARANTEE TRUST LIFE',
        'Ext_Carr_Id': 333
      },
      {
        'Ext_Carr_Name': 'HARTFORD LIFE ',
        'Ext_Carr_Id': 334
      },
      {
        'Ext_Carr_Name': 'GUARDIAN LIFE INSURANCE',
        'Ext_Carr_Id': 335
      },
      {
        'Ext_Carr_Name': 'Guide One Life Insurance',
        'Ext_Carr_Id': 336
      },
      {
        'Ext_Carr_Name': 'HANOVER INSURANCE GROUP',
        'Ext_Carr_Id': 337
      },
      {
        'Ext_Carr_Name': 'HARLEYSVILLE LIFE',
        'Ext_Carr_Id': 338
      },
      {
        'Ext_Carr_Name': 'HCC Life',
        'Ext_Carr_Id': 341
      },
      {
        'Ext_Carr_Name': 'Hibernian Life Insurance',
        'Ext_Carr_Id': 342
      },
      {
        'Ext_Carr_Name': 'Home Beneficial Life',
        'Ext_Carr_Id': 343
      },
      {
        'Ext_Carr_Name': 'Horace Mann Life',
        'Ext_Carr_Id': 344
      },
      {
        'Ext_Carr_Name': 'Idealife',
        'Ext_Carr_Id': 345
      },
      {
        'Ext_Carr_Name': 'Illinois Mutual Life and Casualty',
        'Ext_Carr_Id': 346
      },
      {
        'Ext_Carr_Name': 'Independence Life and Annuity',
        'Ext_Carr_Id': 347
      },
      {
        'Ext_Carr_Name': 'INDIANA FARM BUREAU INSURANCE',
        'Ext_Carr_Id': 348
      },
      {
        'Ext_Carr_Name': 'AMERPRISE FINANCIAL/IDS LIFE INSURANCE',
        'Ext_Carr_Id': 349
      },
      {
        'Ext_Carr_Name': 'IDS/AMERIPRISE LIFE OF NEW YORK',
        'Ext_Carr_Id': 350
      },
      {
        'Ext_Carr_Name': 'INDIANAPOLIS LIFE',
        'Ext_Carr_Id': 351
      },
      {
        'Ext_Carr_Name': 'Voya',
        'Ext_Carr_Id': 352
      },
      {
        'Ext_Carr_Name': 'ING USA',
        'Ext_Carr_Id': 353
      },
      {
        'Ext_Carr_Name': 'Insurance Company of North America',
        'Ext_Carr_Id': 354
      },
      {
        'Ext_Carr_Name': 'Integon',
        'Ext_Carr_Id': 355
      },
      {
        'Ext_Carr_Name': 'Integrity Life',
        'Ext_Carr_Id': 356
      },
      {
        'Ext_Carr_Name': 'Investors Heritage Life',
        'Ext_Carr_Id': 357
      },
      {
        'Ext_Carr_Name': 'FORESTERS',
        'Ext_Carr_Id': 358
      },
      {
        'Ext_Carr_Name': 'JACKSON NATIONAL LIFE',
        'Ext_Carr_Id': 359
      },
      {
        'Ext_Carr_Name': 'Jardin Group',
        'Ext_Carr_Id': 360
      },
      {
        'Ext_Carr_Name': 'JC Penny Life',
        'Ext_Carr_Id': 361
      },
      {
        'Ext_Carr_Name': 'JEFFERSON PILOT',
        'Ext_Carr_Id': 362
      },
      {
        'Ext_Carr_Name': 'John Alden Life',
        'Ext_Carr_Id': 363
      },
      {
        'Ext_Carr_Name': 'JOHN HANCOCK ESTATE LIFE',
        'Ext_Carr_Id': 364
      },
      {
        'Ext_Carr_Name': 'JOHN HANCOCK LIFE',
        'Ext_Carr_Id': 365
      },
      {
        'Ext_Carr_Name': 'JP Morgan ACH',
        'Ext_Carr_Id': 366
      },
      {
        'Ext_Carr_Name': 'Kanawha',
        'Ext_Carr_Id': 367
      },
      {
        'Ext_Carr_Name': 'Kansas City Life',
        'Ext_Carr_Id': 368
      },
      {
        'Ext_Carr_Name': 'Kemper Investors',
        'Ext_Carr_Id': 369
      },
      {
        'Ext_Carr_Name': 'Kentucky Central Life Insurance',
        'Ext_Carr_Id': 370
      },
      {
        'Ext_Carr_Name': 'Keyport Life Insurance ',
        'Ext_Carr_Id': 371
      },
      {
        'Ext_Carr_Name': 'National Heritage Life',
        'Ext_Carr_Id': 373
      },
      {
        'Ext_Carr_Name': 'Keystone State Life',
        'Ext_Carr_Id': 374
      },
      {
        'Ext_Carr_Name': 'KNIGHTS OF COLUMBUS',
        'Ext_Carr_Id': 375
      },
      {
        'Ext_Carr_Name': 'Lafayette Life',
        'Ext_Carr_Id': 376
      },
      {
        'Ext_Carr_Name': 'Lamar Life Insurance Co.',
        'Ext_Carr_Id': 377
      },
      {
        'Ext_Carr_Name': 'Leaders Life',
        'Ext_Carr_Id': 378
      },
      {
        'Ext_Carr_Name': 'LEGAL & GENERAL OF AMERICA',
        'Ext_Carr_Id': 379
      },
      {
        'Ext_Carr_Name': 'Liberty Bankers Life',
        'Ext_Carr_Id': 380
      },
      {
        'Ext_Carr_Name': 'ATHENE ANNUITY & LIFE ASSURANCE ',
        'Ext_Carr_Id': 381
      },
      {
        'Ext_Carr_Name': 'LIBERTY MUTUAL LIFE ',
        'Ext_Carr_Id': 382
      },
      {
        'Ext_Carr_Name': 'Liberty National Life',
        'Ext_Carr_Id': 383
      },
      {
        'Ext_Carr_Name': 'Life and Health Insurance Co. of America',
        'Ext_Carr_Id': 384
      },
      {
        'Ext_Carr_Name': 'Life Insurance of Illinois',
        'Ext_Carr_Id': 385
      },
      {
        'Ext_Carr_Name': 'Life Investors',
        'Ext_Carr_Id': 386
      },
      {
        'Ext_Carr_Name': 'LIFE OF GEORGIA',
        'Ext_Carr_Id': 387
      },
      {
        'Ext_Carr_Name': 'LIFE OF THE SOUTHWEST',
        'Ext_Carr_Id': 388
      },
      {
        'Ext_Carr_Name': 'Life of Virginia',
        'Ext_Carr_Id': 389
      },
      {
        'Ext_Carr_Name': 'Life USA',
        'Ext_Carr_Id': 390
      },
      {
        'Ext_Carr_Name': 'LINCOLN BENEFIT',
        'Ext_Carr_Id': 391
      },
      {
        'Ext_Carr_Name': 'LINCOLN NATIONAL LIFE ',
        'Ext_Carr_Id': 392
      },
      {
        'Ext_Carr_Name': 'Lincoln Heritage Life Insurance Co.',
        'Ext_Carr_Id': 393
      },
      {
        'Ext_Carr_Name': 'London Pacific',
        'Ext_Carr_Id': 394
      },
      {
        'Ext_Carr_Name': 'Maine Fidelity',
        'Ext_Carr_Id': 395
      },
      {
        'Ext_Carr_Name': 'MASS INDEMNITY',
        'Ext_Carr_Id': 397
      },
      {
        'Ext_Carr_Name': 'Lincoln Liberty Life',
        'Ext_Carr_Id': 398
      },
      {
        'Ext_Carr_Name': 'Lincoln Mutual Life & Casualty Insurance Co.',
        'Ext_Carr_Id': 399
      },
      {
        'Ext_Carr_Name': 'Loyal American Life Insurance',
        'Ext_Carr_Id': 400
      },
      {
        'Ext_Carr_Name': 'Loyalty Life',
        'Ext_Carr_Id': 401
      },
      {
        'Ext_Carr_Name': 'LUTHERAN BROTHERHOOD',
        'Ext_Carr_Id': 402
      },
      {
        'Ext_Carr_Name': 'Madison National Life',
        'Ext_Carr_Id': 404
      },
      {
        'Ext_Carr_Name': 'Manhattan National Life',
        'Ext_Carr_Id': 406
      },
      {
        'Ext_Carr_Name': 'MANULIFE',
        'Ext_Carr_Id': 407
      },
      {
        'Ext_Carr_Name': 'Massachusetts General Life',
        'Ext_Carr_Id': 408
      },
      {
        'Ext_Carr_Name': 'MASS MUTUAL ',
        'Ext_Carr_Id': 409
      },
      {
        'Ext_Carr_Name': 'National Integrity Life',
        'Ext_Carr_Id': 410
      },
      {
        'Ext_Carr_Name': 'National Service Life Insurance',
        'Ext_Carr_Id': 411
      },
      {
        'Ext_Carr_Name': 'NATIONAL WESTERN LIFE',
        'Ext_Carr_Id': 412
      },
      {
        'Ext_Carr_Name': 'Medical Life Insurance',
        'Ext_Carr_Id': 413
      },
      {
        'Ext_Carr_Name': 'Mega Life & Health',
        'Ext_Carr_Id': 414
      },
      {
        'Ext_Carr_Name': 'MERRILL LYNCH',
        'Ext_Carr_Id': 415
      },
      {
        'Ext_Carr_Name': 'MET LIFE',
        'Ext_Carr_Id': 416
      },
      {
        'Ext_Carr_Name': 'Met Life Investors',
        'Ext_Carr_Id': 417
      },
      {
        'Ext_Carr_Name': 'Mid West National Life of Tennessee',
        'Ext_Carr_Id': 418
      },
      {
        'Ext_Carr_Name': 'Mid-America Life Insurance',
        'Ext_Carr_Id': 419
      },
      {
        'Ext_Carr_Name': 'Midland Life ',
        'Ext_Carr_Id': 420
      },
      {
        'Ext_Carr_Name': 'MIDLAND NATIONAL LIFE ',
        'Ext_Carr_Id': 421
      },
      {
        'Ext_Carr_Name': 'MINNESOTA LIFE',
        'Ext_Carr_Id': 423
      },
      {
        'Ext_Carr_Name': 'Modern Woodmen of America',
        'Ext_Carr_Id': 424
      },
      {
        'Ext_Carr_Name': 'Monarch Life',
        'Ext_Carr_Id': 425
      },
      {
        'Ext_Carr_Name': 'MONUMENTAL LIFE INSURANCE CO.',
        'Ext_Carr_Id': 426
      },
      {
        'Ext_Carr_Name': 'MONY',
        'Ext_Carr_Id': 427
      },
      {
        'Ext_Carr_Name': 'Motorists Life Insurance Co.',
        'Ext_Carr_Id': 428
      },
      {
        'Ext_Carr_Name': 'Mutual of America Life Insurance',
        'Ext_Carr_Id': 429
      },
      {
        'Ext_Carr_Name': 'Mutual of Omaha',
        'Ext_Carr_Id': 430
      },
      {
        'Ext_Carr_Name': 'Mutual Trust Co.',
        'Ext_Carr_Id': 431
      },
      {
        'Ext_Carr_Name': 'NACOLAH LIFE',
        'Ext_Carr_Id': 432
      },
      {
        'Ext_Carr_Name': 'National Benefit Life',
        'Ext_Carr_Id': 433
      },
      {
        'Ext_Carr_Name': 'National Farm Life',
        'Ext_Carr_Id': 434
      },
      {
        'Ext_Carr_Name': 'National Fraternal Society of the Deaf',
        'Ext_Carr_Id': 435
      },
      {
        'Ext_Carr_Name': 'National Foundation Life',
        'Ext_Carr_Id': 436
      },
      {
        'Ext_Carr_Name': 'NATIONAL GUARDIAN LIFE',
        'Ext_Carr_Id': 437
      },
      {
        'Ext_Carr_Name': 'NATIONAL LIFE OF VERMONT',
        'Ext_Carr_Id': 438
      },
      {
        'Ext_Carr_Name': 'National Mutual Benefit',
        'Ext_Carr_Id': 439
      },
      {
        'Ext_Carr_Name': 'National Travelers Life',
        'Ext_Carr_Id': 440
      },
      {
        'Ext_Carr_Name': 'Nationwide Insurance',
        'Ext_Carr_Id': 441
      },
      {
        'Ext_Carr_Name': 'NATIONWIDE PROVIDENT',
        'Ext_Carr_Id': 443
      },
      {
        'Ext_Carr_Name': 'Navy Mutual Aid Association',
        'Ext_Carr_Id': 444
      },
      {
        'Ext_Carr_Name': 'NEW ENGLAND FINANCIAL',
        'Ext_Carr_Id': 445
      },
      {
        'Ext_Carr_Name': 'North American Benefit Association',
        'Ext_Carr_Id': 447
      },
      {
        'Ext_Carr_Name': 'NORTH AMERICAN CO. ',
        'Ext_Carr_Id': 448
      },
      {
        'Ext_Carr_Name': 'North Carolina Mutual Life Insurance Co.',
        'Ext_Carr_Id': 449
      },
      {
        'Ext_Carr_Name': 'North Coast Life Insurance Co.',
        'Ext_Carr_Id': 450
      },
      {
        'Ext_Carr_Name': 'Northbrook Life',
        'Ext_Carr_Id': 451
      },
      {
        'Ext_Carr_Name': 'ZURICH KEMPER LIFE ASSURANCE',
        'Ext_Carr_Id': 452
      },
      {
        'Ext_Carr_Name': 'Northern Life',
        'Ext_Carr_Id': 453
      },
      {
        'Ext_Carr_Name': 'Northwest Life',
        'Ext_Carr_Id': 454
      },
      {
        'Ext_Carr_Name': 'NORTHWESTERN MUTUAL LIFE',
        'Ext_Carr_Id': 455
      },
      {
        'Ext_Carr_Name': 'Northwestern National Life',
        'Ext_Carr_Id': 456
      },
      {
        'Ext_Carr_Name': 'Occidental Life',
        'Ext_Carr_Id': 457
      },
      {
        'Ext_Carr_Name': 'Occidental of North Carolina',
        'Ext_Carr_Id': 458
      },
      {
        'Ext_Carr_Name': 'OFFICE OF SERVICE MEMBERS GROUP LIFE INSURANCE',
        'Ext_Carr_Id': 459
      },
      {
        'Ext_Carr_Name': 'Ohio Casualty Group',
        'Ext_Carr_Id': 460
      },
      {
        'Ext_Carr_Name': 'Ohio Life',
        'Ext_Carr_Id': 461
      },
      {
        'Ext_Carr_Name': 'Ohio National Life',
        'Ext_Carr_Id': 462
      },
      {
        'Ext_Carr_Name': 'Ohio State Life Insurance',
        'Ext_Carr_Id': 463
      },
      {
        'Ext_Carr_Name': 'Old Line Life Insurance',
        'Ext_Carr_Id': 464
      },
      {
        'Ext_Carr_Name': 'Old Republic Insurance Co.',
        'Ext_Carr_Id': 465
      },
      {
        'Ext_Carr_Name': 'Old West Annuity & Life Insurance',
        'Ext_Carr_Id': 466
      },
      {
        'Ext_Carr_Name': 'OXFORD LIFE INSURANCE',
        'Ext_Carr_Id': 467
      },
      {
        'Ext_Carr_Name': 'Ozark National Life',
        'Ext_Carr_Id': 468
      },
      {
        'Ext_Carr_Name': 'Pacific Fidelity Life',
        'Ext_Carr_Id': 469
      },
      {
        'Ext_Carr_Name': 'PACIFIC LIFE',
        'Ext_Carr_Id': 470
      },
      {
        'Ext_Carr_Name': 'PACIFIC LIFE & ANNUITY',
        'Ext_Carr_Id': 471
      },
      {
        'Ext_Carr_Name': 'Pacific Standard Life',
        'Ext_Carr_Id': 472
      },
      {
        'Ext_Carr_Name': 'Paul Revere Life',
        'Ext_Carr_Id': 473
      },
      {
        'Ext_Carr_Name': 'Pacific Guardian Life Insurance',
        'Ext_Carr_Id': 474
      },
      {
        'Ext_Carr_Name': 'Pan American Life',
        'Ext_Carr_Id': 475
      },
      {
        'Ext_Carr_Name': 'PARAGON LIFE',
        'Ext_Carr_Id': 476
      },
      {
        'Ext_Carr_Name': 'Pekin Life Insurance Co.',
        'Ext_Carr_Id': 477
      },
      {
        'Ext_Carr_Name': 'Penn Mutual Life',
        'Ext_Carr_Id': 478
      },
      {
        'Ext_Carr_Name': 'Pennsylvania Life Insurance',
        'Ext_Carr_Id': 479
      },
      {
        'Ext_Carr_Name': 'Peoples Benefits',
        'Ext_Carr_Id': 480
      },
      {
        'Ext_Carr_Name': 'Pershing LLC',
        'Ext_Carr_Id': 481
      },
      {
        'Ext_Carr_Name': 'Philadelphia Life',
        'Ext_Carr_Id': 482
      },
      {
        'Ext_Carr_Name': 'PHYSICIANS LIFE INSURANCE CO.',
        'Ext_Carr_Id': 484
      },
      {
        'Ext_Carr_Name': 'Pioneer America',
        'Ext_Carr_Id': 485
      },
      {
        'Ext_Carr_Name': 'Pioneer Life Insurance of Illinois',
        'Ext_Carr_Id': 486
      },
      {
        'Ext_Carr_Name': 'Pioneer Mutual Life',
        'Ext_Carr_Id': 487
      },
      {
        'Ext_Carr_Name': 'Pioneer Security Life',
        'Ext_Carr_Id': 488
      },
      {
        'Ext_Carr_Name': 'Police and Fireman\'s Insurance',
        'Ext_Carr_Id': 489
      },
      {
        'Ext_Carr_Name': 'Polish National Alliance',
        'Ext_Carr_Id': 490
      },
      {
        'Ext_Carr_Name': 'Presidential Life',
        'Ext_Carr_Id': 491
      },
      {
        'Ext_Carr_Name': 'PRIME AMERICA INSURANCE',
        'Ext_Carr_Id': 492
      },
      {
        'Ext_Carr_Name': 'PRIMERICA LIFE INSURANCE',
        'Ext_Carr_Id': 493
      },
      {
        'Ext_Carr_Name': 'PRINCIPAL LIFE',
        'Ext_Carr_Id': 494
      },
      {
        'Ext_Carr_Name': 'SECURITY LIFE OF DENVER',
        'Ext_Carr_Id': 495
      },
      {
        'Ext_Carr_Name': 'SECURITY MUTUAL LIFE OF NEW YORK',
        'Ext_Carr_Id': 496
      },
      {
        'Ext_Carr_Name': 'Sentry Life',
        'Ext_Carr_Id': 497
      },
      {
        'Ext_Carr_Name': 'PROTECTIVE LIFE',
        'Ext_Carr_Id': 498
      },
      {
        'Ext_Carr_Name': 'Protective Life & Accident ',
        'Ext_Carr_Id': 499
      },
      {
        'Ext_Carr_Name': 'Provident Life & Accident',
        'Ext_Carr_Id': 500
      },
      {
        'Ext_Carr_Name': 'Provident Mutual Life',
        'Ext_Carr_Id': 501
      },
      {
        'Ext_Carr_Name': 'Providian Life & Health Insurance',
        'Ext_Carr_Id': 502
      },
      {
        'Ext_Carr_Name': 'PRUDENTIAL LIFE INSURANCE',
        'Ext_Carr_Id': 503
      },
      {
        'Ext_Carr_Name': 'Puritan Life',
        'Ext_Carr_Id': 504
      },
      {
        'Ext_Carr_Name': 'Pyramid Life Insurance Grp.',
        'Ext_Carr_Id': 505
      },
      {
        'Ext_Carr_Name': 'RBC Life Insurance Co.',
        'Ext_Carr_Id': 506
      },
      {
        'Ext_Carr_Name': 'REASSURE AMERICA LIFE INSURANCE',
        'Ext_Carr_Id': 508
      },
      {
        'Ext_Carr_Name': 'Reliance Standard',
        'Ext_Carr_Id': 509
      },
      {
        'Ext_Carr_Name': 'RELIASTAR',
        'Ext_Carr_Id': 510
      },
      {
        'Ext_Carr_Name': 'Reliastar Life of New York',
        'Ext_Carr_Id': 511
      },
      {
        'Ext_Carr_Name': 'SAVINGS BANK LIFE INSURANCE OF NJ',
        'Ext_Carr_Id': 512
      },
      {
        'Ext_Carr_Name': 'SAVINGS BANK LIFE INSURANCE OF CT',
        'Ext_Carr_Id': 513
      },
      {
        'Ext_Carr_Name': 'Scottish Provident',
        'Ext_Carr_Id': 514
      },
      {
        'Ext_Carr_Name': 'Royal Neighbors of America',
        'Ext_Carr_Id': 515
      },
      {
        'Ext_Carr_Name': 'SAVINGS BANK LIFE INSURANCE OF MA',
        'Ext_Carr_Id': 516
      },
      {
        'Ext_Carr_Name': 'SECURITY BENEFIT LIFE',
        'Ext_Carr_Id': 517
      },
      {
        'Ext_Carr_Name': 'Security Connecticut Life',
        'Ext_Carr_Id': 518
      },
      {
        'Ext_Carr_Name': 'Security Financial Life Insurance Co.',
        'Ext_Carr_Id': 519
      },
      {
        'Ext_Carr_Name': 'Security Life of America',
        'Ext_Carr_Id': 520
      },
      {
        'Ext_Carr_Name': 'Serb National Federation',
        'Ext_Carr_Id': 521
      },
      {
        'Ext_Carr_Name': 'SERVICE MEMBERS GROUP LIFE INSURANCE',
        'Ext_Carr_Id': 522
      },
      {
        'Ext_Carr_Name': 'Shelter Life Insurance Company',
        'Ext_Carr_Id': 523
      },
      {
        'Ext_Carr_Name': 'Shenandoah Life',
        'Ext_Carr_Id': 524
      },
      {
        'Ext_Carr_Name': 'Slavonic Benevolent Order of State of Texas',
        'Ext_Carr_Id': 525
      },
      {
        'Ext_Carr_Name': 'Sons of Norway',
        'Ext_Carr_Id': 526
      },
      {
        'Ext_Carr_Name': 'Southern Farm Bureau Life',
        'Ext_Carr_Id': 527
      },
      {
        'Ext_Carr_Name': 'Southern Security Life',
        'Ext_Carr_Id': 528
      },
      {
        'Ext_Carr_Name': 'SOUTHLAND LIFE INSURANCE',
        'Ext_Carr_Id': 529
      },
      {
        'Ext_Carr_Name': 'Southland National Insurance Co.',
        'Ext_Carr_Id': 530
      },
      {
        'Ext_Carr_Name': 'Southwestern Life',
        'Ext_Carr_Id': 531
      },
      {
        'Ext_Carr_Name': 'Standard Life of Indiana',
        'Ext_Carr_Id': 532
      },
      {
        'Ext_Carr_Name': 'WOMAN\'S LIFE INSURANCE SOCIETY',
        'Ext_Carr_Id': 533
      },
      {
        'Ext_Carr_Name': 'Woodbury Financial Services',
        'Ext_Carr_Id': 534
      },
      {
        'Ext_Carr_Name': 'WOODMEN OF THE WORLD',
        'Ext_Carr_Id': 535
      },
      {
        'Ext_Carr_Name': 'World Insurance',
        'Ext_Carr_Id': 536
      },
      {
        'Ext_Carr_Name': 'STATE FARM INSURANCE ',
        'Ext_Carr_Id': 538
      },
      {
        'Ext_Carr_Name': 'State Mutual Life Insurance Co.',
        'Ext_Carr_Id': 539
      },
      {
        'Ext_Carr_Name': 'Stonebridge Life Insurance Co.',
        'Ext_Carr_Id': 540
      },
      {
        'Ext_Carr_Name': 'Summit National Life',
        'Ext_Carr_Id': 541
      },
      {
        'Ext_Carr_Name': 'Sun America Life',
        'Ext_Carr_Id': 542
      },
      {
        'Ext_Carr_Name': 'SUN LIFE',
        'Ext_Carr_Id': 543
      },
      {
        'Ext_Carr_Name': 'Sun Life of America',
        'Ext_Carr_Id': 544
      },
      {
        'Ext_Carr_Name': 'SUN LIFE OF CANADA',
        'Ext_Carr_Id': 545
      },
      {
        'Ext_Carr_Name': 'Sunset Life',
        'Ext_Carr_Id': 546
      },
      {
        'Ext_Carr_Name': 'Symetra Financial',
        'Ext_Carr_Id': 547
      },
      {
        'Ext_Carr_Name': 'TENNESSEE FARM BUREAU',
        'Ext_Carr_Id': 548
      },
      {
        'Ext_Carr_Name': 'Texas Farm Bureau',
        'Ext_Carr_Id': 549
      },
      {
        'Ext_Carr_Name': 'Texas Life',
        'Ext_Carr_Id': 550
      },
      {
        'Ext_Carr_Name': 'Texas Medical Association Insurance Trust (TMAIT)',
        'Ext_Carr_Id': 551
      },
      {
        'Ext_Carr_Name': 'THE AMERICAN HOME LIFE INSURANCE COMPANY',
        'Ext_Carr_Id': 552
      },
      {
        'Ext_Carr_Name': 'TIME INSURANCE COMPANY',
        'Ext_Carr_Id': 553
      },
      {
        'Ext_Carr_Name': 'Tower Life Insurance',
        'Ext_Carr_Id': 554
      },
      {
        'Ext_Carr_Name': 'TRANSAMERICA FINANCIAL LIFE',
        'Ext_Carr_Id': 556
      },
      {
        'Ext_Carr_Name': 'The Midland',
        'Ext_Carr_Id': 557
      },
      {
        'Ext_Carr_Name': 'THRIVENT FINANCIAL FOR LUTHERANS',
        'Ext_Carr_Id': 558
      },
      {
        'Ext_Carr_Name': 'TIAA-CREF',
        'Ext_Carr_Id': 559
      },
      {
        'Ext_Carr_Name': 'TRANSAMERICA LIFE INSURANCE',
        'Ext_Carr_Id': 560
      },
      {
        'Ext_Carr_Name': 'MET LIFE INSURANCE OF CONNECTICUT',
        'Ext_Carr_Id': 561
      },
      {
        'Ext_Carr_Name': 'Unified Life Insurance',
        'Ext_Carr_Id': 563
      },
      {
        'Ext_Carr_Name': 'UNION CENTRAL',
        'Ext_Carr_Id': 564
      },
      {
        'Ext_Carr_Name': 'UNION SECURITY LIFE INSURANCE',
        'Ext_Carr_Id': 565
      },
      {
        'Ext_Carr_Name': 'United Commercial Travelers',
        'Ext_Carr_Id': 566
      },
      {
        'Ext_Carr_Name': 'UNITED FARM BUREAU LIFE',
        'Ext_Carr_Id': 567
      },
      {
        'Ext_Carr_Name': 'United Fidelity',
        'Ext_Carr_Id': 568
      },
      {
        'Ext_Carr_Name': 'UNITED HERITAGE LIFE',
        'Ext_Carr_Id': 569
      },
      {
        'Ext_Carr_Name': 'UNITED INVESTORS LIFE',
        'Ext_Carr_Id': 570
      },
      {
        'Ext_Carr_Name': 'UNITED LIFE & ACCIDENT',
        'Ext_Carr_Id': 571
      },
      {
        'Ext_Carr_Name': 'United Life Insurance Co.',
        'Ext_Carr_Id': 572
      },
      {
        'Ext_Carr_Name': 'UNITED OF OMAHA LIFE ',
        'Ext_Carr_Id': 573
      },
      {
        'Ext_Carr_Name': 'United Pacific Life',
        'Ext_Carr_Id': 574
      },
      {
        'Ext_Carr_Name': 'United Presidential',
        'Ext_Carr_Id': 575
      },
      {
        'Ext_Carr_Name': 'United Security Life & Health',
        'Ext_Carr_Id': 576
      },
      {
        'Ext_Carr_Name': 'Western Security Life',
        'Ext_Carr_Id': 577
      },
      {
        'Ext_Carr_Name': 'WESTERN & SOUTHERN LIFE',
        'Ext_Carr_Id': 578
      },
      {
        'Ext_Carr_Name': 'William Penn',
        'Ext_Carr_Id': 579
      },
      {
        'Ext_Carr_Name': 'WISCONSIN NATIONAL LIFE',
        'Ext_Carr_Id': 580
      },
      {
        'Ext_Carr_Name': 'UNITED SERVICES LIFE INSURANCE CO.',
        'Ext_Carr_Id': 581
      },
      {
        'Ext_Carr_Name': 'United States Life',
        'Ext_Carr_Id': 582
      },
      {
        'Ext_Carr_Name': 'United Transportation Union Insurance Association',
        'Ext_Carr_Id': 583
      },
      {
        'Ext_Carr_Name': 'United Trust Group',
        'Ext_Carr_Id': 584
      },
      {
        'Ext_Carr_Name': 'Unity Mutual Life',
        'Ext_Carr_Id': 585
      },
      {
        'Ext_Carr_Name': 'Universal Guaranty',
        'Ext_Carr_Id': 586
      },
      {
        'Ext_Carr_Name': 'Universal Underwriters Group',
        'Ext_Carr_Id': 587
      },
      {
        'Ext_Carr_Name': 'UNUM',
        'Ext_Carr_Id': 588
      },
      {
        'Ext_Carr_Name': 'US Financial',
        'Ext_Carr_Id': 589
      },
      {
        'Ext_Carr_Name': 'US Life Insurance Services',
        'Ext_Carr_Id': 590
      },
      {
        'Ext_Carr_Name': 'USA Life One Insurance Co. of Indiana',
        'Ext_Carr_Id': 591
      },
      {
        'Ext_Carr_Name': 'USA/DEPARTMENT OF VETERANS AFFAIRS',
        'Ext_Carr_Id': 592
      },
      {
        'Ext_Carr_Name': 'USAA Life Insurance Co.',
        'Ext_Carr_Id': 593
      },
      {
        'Ext_Carr_Name': 'USG ANNUITY AND LIFE CO.',
        'Ext_Carr_Id': 594
      },
      {
        'Ext_Carr_Name': 'Utica National Life Insurance Co.',
        'Ext_Carr_Id': 595
      },
      {
        'Ext_Carr_Name': 'VALLEY FORGE LIFE',
        'Ext_Carr_Id': 596
      },
      {
        'Ext_Carr_Name': 'Veterans Life Insurance Company',
        'Ext_Carr_Id': 597
      },
      {
        'Ext_Carr_Name': 'Washington National Life',
        'Ext_Carr_Id': 598
      },
      {
        'Ext_Carr_Name': 'West Coast Life Insurance',
        'Ext_Carr_Id': 599
      },
      {
        'Ext_Carr_Name': 'Western Fraternal Life',
        'Ext_Carr_Id': 600
      },
      {
        'Ext_Carr_Name': 'Western Life',
        'Ext_Carr_Id': 601
      },
      {
        'Ext_Carr_Name': 'WESTERN RESERVE LIFE',
        'Ext_Carr_Id': 602
      },
      {
        'Ext_Carr_Name': 'Western Farm Bureau Life',
        'Ext_Carr_Id': 603
      },
      {
        'Ext_Carr_Name': 'Western National Life',
        'Ext_Carr_Id': 604
      },
      {
        'Ext_Carr_Name': 'WESTERN UNITED LIFE',
        'Ext_Carr_Id': 605
      },
      {
        'Ext_Carr_Name': 'MANHATTAN LIFE INSURANCE',
        'Ext_Carr_Id': 606
      },
      {
        'Ext_Carr_Name': 'Surety Life',
        'Ext_Carr_Id': 607
      },
      {
        'Ext_Carr_Name': 'MUTUAL OF NEW YORK',
        'Ext_Carr_Id': 608
      },
      {
        'Ext_Carr_Name': 'NEW YORK LIFE',
        'Ext_Carr_Id': 609
      },
      {
        'Ext_Carr_Name': 'SAVINGS BANK LIFE INSURANCE OF NY',
        'Ext_Carr_Id': 611
      },
      {
        'Ext_Carr_Name': 'LINCOLN LIFE ',
        'Ext_Carr_Id': 612
      },
      {
        'Ext_Carr_Name': 'PRESIDENTIAL LIFE INSURANCE ',
        'Ext_Carr_Id': 613
      },
      {
        'Ext_Carr_Name': 'Greater Beneficial Union',
        'Ext_Carr_Id': 614
      },
      {
        'Ext_Carr_Name': 'UNIFORMED SERVICES BENEFIT ASSOCIATION',
        'Ext_Carr_Id': 615
      },
      {
        'Ext_Carr_Name': 'Jefferson National Life',
        'Ext_Carr_Id': 616
      },
      {
        'Ext_Carr_Name': 'Investors Life ',
        'Ext_Carr_Id': 618
      },
      {
        'Ext_Carr_Name': 'American Fidelity Assurance',
        'Ext_Carr_Id': 619
      },
      {
        'Ext_Carr_Name': 'CONNECTICUT MUTUAL',
        'Ext_Carr_Id': 621
      },
      {
        'Ext_Carr_Name': 'GOVERNMENT PERSONNEL MUTUAL ',
        'Ext_Carr_Id': 622
      },
      {
        'Ext_Carr_Name': 'MINNESOTA MUTUAL',
        'Ext_Carr_Id': 623
      },
      {
        'Ext_Carr_Name': 'MET LIFE OF CONNECTICUT/TRAVELERS',
        'Ext_Carr_Id': 624
      },
      {
        'Ext_Carr_Name': 'FIDELITY LIFE ',
        'Ext_Carr_Id': 627
      },
      {
        'Ext_Carr_Name': 'TRANS WORLD ASSURANCE COMPANY',
        'Ext_Carr_Id': 628
      },
      {
        'Ext_Carr_Name': 'Ladies Pennsylvania Slovak Catholic Union ',
        'Ext_Carr_Id': 630
      },
      {
        'Ext_Carr_Name': 'Trustmark Companies',
        'Ext_Carr_Id': 632
      },
      {
        'Ext_Carr_Name': 'COMMONWEALTH',
        'Ext_Carr_Id': 633
      },
      {
        'Ext_Carr_Name': 'UNION SECURE ',
        'Ext_Carr_Id': 634
      },
      {
        'Ext_Carr_Name': 'ALLMERICA',
        'Ext_Carr_Id': 635
      },
      {
        'Ext_Carr_Name': 'State Life ',
        'Ext_Carr_Id': 636
      },
      {
        'Ext_Carr_Name': 'SMA LIFE ASSURANCE COMPANY ',
        'Ext_Carr_Id': 637
      },
      {
        'Ext_Carr_Name': 'Great Republic Life Insurance Company',
        'Ext_Carr_Id': 638
      },
      {
        'Ext_Carr_Name': 'Bankers Life of New York',
        'Ext_Carr_Id': 639
      },
      {
        'Ext_Carr_Name': 'Riversource',
        'Ext_Carr_Id': 641
      },
      {
        'Ext_Carr_Name': 'Riversource Life of New York',
        'Ext_Carr_Id': 642
      },
      {
        'Ext_Carr_Name': 'Regence Life & Health Insurance ',
        'Ext_Carr_Id': 643
      },
      {
        'Ext_Carr_Name': 'IDS LIFE INSURANCE/RIVERSOURCE',
        'Ext_Carr_Id': 644
      },
      {
        'Ext_Carr_Name': 'INDUSTRIAL ALLIANCE PACIFIC ',
        'Ext_Carr_Id': 645
      },
      {
        'Ext_Carr_Name': 'UPEC (Supreme Council)',
        'Ext_Carr_Id': 646
      },
      {
        'Ext_Carr_Name': 'ONE AMERICA ',
        'Ext_Carr_Id': 647
      },
      {
        'Ext_Carr_Name': 'Best of America ',
        'Ext_Carr_Id': 648
      },
      {
        'Ext_Carr_Name': 'GOLDEN AMERICAN LIFE INSURANCE CO.',
        'Ext_Carr_Id': 649
      },
      {
        'Ext_Carr_Name': 'OM FINANCIAL ',
        'Ext_Carr_Id': 650
      },
      {
        'Ext_Carr_Name': 'FIRST ALLMERICA LIFE',
        'Ext_Carr_Id': 651
      },
      {
        'Ext_Carr_Name': 'Military Benefit Association',
        'Ext_Carr_Id': 652
      },
      {
        'Ext_Carr_Name': 'Wilton RE',
        'Ext_Carr_Id': 653
      },
      {
        'Ext_Carr_Name': 'Mennonite Mutual Aid',
        'Ext_Carr_Id': 654
      },
      {
        'Ext_Carr_Name': 'Promark Financial',
        'Ext_Carr_Id': 655
      },
      {
        'Ext_Carr_Name': 'LINCOLN FINANCIAL',
        'Ext_Carr_Id': 656
      },
      {
        'Ext_Carr_Name': 'First Great West ',
        'Ext_Carr_Id': 657
      },
      {
        'Ext_Carr_Name': 'Vision Financial ',
        'Ext_Carr_Id': 658
      },
      {
        'Ext_Carr_Name': 'United Teacher Associates Insurance Company ',
        'Ext_Carr_Id': 659
      },
      {
        'Ext_Carr_Name': 'AMA Insurance Agency Inc.',
        'Ext_Carr_Id': 660
      },
      {
        'Ext_Carr_Name': 'Milwaukee Life ',
        'Ext_Carr_Id': 662
      },
      {
        'Ext_Carr_Name': 'LIFE SECURE',
        'Ext_Carr_Id': 663
      },
      {
        'Ext_Carr_Name': 'LifeSecure',
        'Ext_Carr_Id': 664
      },
      {
        'Ext_Carr_Name': 'Catholic Fraternal Life',
        'Ext_Carr_Id': 665
      },
      {
        'Ext_Carr_Name': 'Colonial Penn',
        'Ext_Carr_Id': 667
      },
      {
        'Ext_Carr_Name': 'IdeaLife Insurance',
        'Ext_Carr_Id': 668
      },
      {
        'Ext_Carr_Name': 'Old Mutual Financial ',
        'Ext_Carr_Id': 669
      },
      {
        'Ext_Carr_Name': 'THE CHESAPEAKE LIFE INSURANCE COMPANY',
        'Ext_Carr_Id': 670
      },
      {
        'Ext_Carr_Name': 'Gilsbar',
        'Ext_Carr_Id': 671
      },
      {
        'Ext_Carr_Name': 'EquiTrust Life Insurance Company',
        'Ext_Carr_Id': 672
      },
      {
        'Ext_Carr_Name': 'Annuity and Life Reassurance Inc.',
        'Ext_Carr_Id': 673
      },
      {
        'Ext_Carr_Name': 'Scudder Investments ',
        'Ext_Carr_Id': 674
      },
      {
        'Ext_Carr_Name': 'Assurant Solutions Preneed Insurance',
        'Ext_Carr_Id': 675
      },
      {
        'Ext_Carr_Name': 'Banc of America Insurance Services',
        'Ext_Carr_Id': 676
      },
      {
        'Ext_Carr_Name': 'London Life ',
        'Ext_Carr_Id': 677
      },
      {
        'Ext_Carr_Name': 'SECURITY BENEFIT GROUP OF COMPANIES',
        'Ext_Carr_Id': 678
      },
      {
        'Ext_Carr_Name': '5 STAR LIFE INSURANCE COMPANY',
        'Ext_Carr_Id': 680
      },
      {
        'Ext_Carr_Name': 'Sagicore Life Insurance',
        'Ext_Carr_Id': 682
      },
      {
        'Ext_Carr_Name': 'Marsh',
        'Ext_Carr_Id': 683
      },
      {
        'Ext_Carr_Name': 'Nebco ',
        'Ext_Carr_Id': 684
      },
      {
        'Ext_Carr_Name': 'Cigna Group Life',
        'Ext_Carr_Id': 685
      },
      {
        'Ext_Carr_Name': 'UNITED FARM FAMILY LIFE ',
        'Ext_Carr_Id': 686
      },
      {
        'Ext_Carr_Name': 'American Progressive',
        'Ext_Carr_Id': 687
      },
      {
        'Ext_Carr_Name': 'new york life annuities',
        'Ext_Carr_Id': 688
      },
      {
        'Ext_Carr_Name': 'Fidelity Investment Life Insurance',
        'Ext_Carr_Id': 689
      },
      {
        'Ext_Carr_Name': 'STANDARD INSURANCE CO',
        'Ext_Carr_Id': 690
      },
      {
        'Ext_Carr_Name': 'Harvey Watt Insurance Company',
        'Ext_Carr_Id': 691
      },
      {
        'Ext_Carr_Name': 'Insurance Specialists, Inc.',
        'Ext_Carr_Id': 692
      },
      {
        'Ext_Carr_Name': 'Ohio State Bar Association ',
        'Ext_Carr_Id': 693
      },
      {
        'Ext_Carr_Name': 'Life Insurance Company of Boston and New York',
        'Ext_Carr_Id': 694
      },
      {
        'Ext_Carr_Name': 'SEGUROS MONTERREY NEW YORK LIFE ',
        'Ext_Carr_Id': 695
      },
      {
        'Ext_Carr_Name': 'AIA INSURANCE',
        'Ext_Carr_Id': 696
      },
      {
        'Ext_Carr_Name': 'Statesman Life Insurance ',
        'Ext_Carr_Id': 697
      },
      {
        'Ext_Carr_Name': 'AARP Life Insurance ',
        'Ext_Carr_Id': 699
      },
      {
        'Ext_Carr_Name': 'Bank of Montreal',
        'Ext_Carr_Id': 700
      },
      {
        'Ext_Carr_Name': 'ULLICO Inc.',
        'Ext_Carr_Id': 701
      },
      {
        'Ext_Carr_Name': 'Anthem Life',
        'Ext_Carr_Id': 702
      },
      {
        'Ext_Carr_Name': 'Standard Life of Canada ',
        'Ext_Carr_Id': 703
      },
      {
        'Ext_Carr_Name': 'Philadelphia United Life Insurance Company',
        'Ext_Carr_Id': 704
      },
      {
        'Ext_Carr_Name': 'The Argus Group ',
        'Ext_Carr_Id': 705
      },
      {
        'Ext_Carr_Name': 'HUMANA LIFE INSURANCE',
        'Ext_Carr_Id': 706
      },
      {
        'Ext_Carr_Name': 'Church Life',
        'Ext_Carr_Id': 707
      },
      {
        'Ext_Carr_Name': 'Vanguard',
        'Ext_Carr_Id': 708
      },
      {
        'Ext_Carr_Name': 'GENWORTH LIFE (aka Riversource)',
        'Ext_Carr_Id': 709
      },
      {
        'Ext_Carr_Name': 'LOMBARD INTERNATIONAL LIFE ASSURANCE COMPANY',
        'Ext_Carr_Id': 710
      },
      {
        'Ext_Carr_Name': 'PEMCO Insurance Company',
        'Ext_Carr_Id': 711
      },
      {
        'Ext_Carr_Name': 'United Family Life Insurance Co',
        'Ext_Carr_Id': 712
      },
      {
        'Ext_Carr_Name': 'DEARBORN NATIONAL',
        'Ext_Carr_Id': 713
      },
      {
        'Ext_Carr_Name': 'Star Insurance Company "Cayman" Limited',
        'Ext_Carr_Id': 714
      },
      {
        'Ext_Carr_Name': 'IEEE',
        'Ext_Carr_Id': 715
      },
      {
        'Ext_Carr_Name': 'Natwest',
        'Ext_Carr_Id': 716
      },
      {
        'Ext_Carr_Name': 'United Insurance Company of America',
        'Ext_Carr_Id': 717
      },
      {
        'Ext_Carr_Name': 'Starmount Life Insurance Co. ',
        'Ext_Carr_Id': 718
      },
      {
        'Ext_Carr_Name': 'Household Life Insurance Company',
        'Ext_Carr_Id': 719
      },
      {
        'Ext_Carr_Name': 'Commonwealth Annuity & Life',
        'Ext_Carr_Id': 720
      },
      {
        'Ext_Carr_Name': 'TRANSAMERICA ADVISORS LIFE',
        'Ext_Carr_Id': 722
      },
      {
        'Ext_Carr_Name': 'Security National Life Insurance Company',
        'Ext_Carr_Id': 723
      },
      {
        'Ext_Carr_Name': 'Trinity Life Insurance Company',
        'Ext_Carr_Id': 724
      },
      {
        'Ext_Carr_Name': 'National States Life',
        'Ext_Carr_Id': 725
      },
      {
        'Ext_Carr_Name': 'Tennessee Farm Bureau',
        'Ext_Carr_Id': 726
      },
      {
        'Ext_Carr_Name': 'TRANSAMERICA GROUP LIFE INSURANCE',
        'Ext_Carr_Id': 727
      },
      {
        'Ext_Carr_Name': 'Geneva Life Insurance',
        'Ext_Carr_Id': 728
      },
      {
        'Ext_Carr_Name': 'Union Bankers Insurance Company',
        'Ext_Carr_Id': 729
      },
      {
        'Ext_Carr_Name': 'AETNA',
        'Ext_Carr_Id': 730
      },
      {
        'Ext_Carr_Name': 'STANDARD LIFE AND ACCIDENT',
        'Ext_Carr_Id': 731
      },
      {
        'Ext_Carr_Name': 'PHILADELPHIA FINANCIAL LIFE INSURANCE',
        'Ext_Carr_Id': 732
      },
      {
        'Ext_Carr_Name': 'Acadia Financial ',
        'Ext_Carr_Id': 733
      },
      {
        'Ext_Carr_Name': 'Great-West Life & Annuity Insurance Company',
        'Ext_Carr_Id': 734
      },
      {
        'Ext_Carr_Name': 'GREAT WEST LIFE ',
        'Ext_Carr_Id': 735
      },
      {
        'Ext_Carr_Name': 'U. S. OFFICE OF PERSONNEL MANAGEMENT',
        'Ext_Carr_Id': 736
      },
      {
        'Ext_Carr_Name': 'NATIONAL LIFE GROUP ',
        'Ext_Carr_Id': 737
      },
      {
        'Ext_Carr_Name': 'Military Officers Association of America',
        'Ext_Carr_Id': 738
      },
      {
        'Ext_Carr_Name': 'Interstate Assurance ',
        'Ext_Carr_Id': 739
      },
      {
        'Ext_Carr_Name': 'Counsumer Life Insurance of North Carolina ',
        'Ext_Carr_Id': 740
      },
      {
        'Ext_Carr_Name': 'Consumer Life Insurance of North Carolina ',
        'Ext_Carr_Id': 741
      },
      {
        'Ext_Carr_Name': 'Edward Jones',
        'Ext_Carr_Id': 742
      },
      {
        'Ext_Carr_Name': 'Wescom Credit Union',
        'Ext_Carr_Id': 743
      },
      {
        'Ext_Carr_Name': 'Atlanta Life Insurance Company',
        'Ext_Carr_Id': 744
      },
      {
        'Ext_Carr_Name': 'Investors of North America',
        'Ext_Carr_Id': 745
      },
      {
        'Ext_Carr_Name': 'Colonial First State',
        'Ext_Carr_Id': 746
      },
      {
        'Ext_Carr_Name': 'Ocwen',
        'Ext_Carr_Id': 747
      },
      {
        'Ext_Carr_Name': 'American General Life Insurance Company of Delaware',
        'Ext_Carr_Id': 748
      },
      {
        'Ext_Carr_Name': 'Old American Life Insurance ',
        'Ext_Carr_Id': 749
      },
      {
        'Ext_Carr_Name': 'Sears Financial',
        'Ext_Carr_Id': 750
      },
      {
        'Ext_Carr_Name': 'AMERICAN NATIONAL PROPERTY AND CASUALTY COMPANY ',
        'Ext_Carr_Id': 751
      },
      {
        'Ext_Carr_Name': 'United American',
        'Ext_Carr_Id': 752
      },
      {
        'Ext_Carr_Name': 'CMFG',
        'Ext_Carr_Id': 753
      },
      {
        'Ext_Carr_Name': 'Bankers Fidelity Life Insurance Co',
        'Ext_Carr_Id': 754
      },
      {
        'Ext_Carr_Name': 'Desjardins Life Insurance',
        'Ext_Carr_Id': 755
      },
      {
        'Ext_Carr_Name': 'ADA INSURANCE PLANS',
        'Ext_Carr_Id': 756
      },
      {
        'Ext_Carr_Name': 'Guggenheim Life Insurance',
        'Ext_Carr_Id': 757
      },
      {
        'Ext_Carr_Name': 'Heidelberger Lebensversicherung AG',
        'Ext_Carr_Id': 758
      },
      {
        'Ext_Carr_Name': 'AFT Plus',
        'Ext_Carr_Id': 759
      },
      {
        'Ext_Carr_Name': 'Portuguese Fraternal Society of America ',
        'Ext_Carr_Id': 760
      },
      {
        'Ext_Carr_Name': 'Germania Insurance ',
        'Ext_Carr_Id': 761
      },
      {
        'Ext_Carr_Name': 'Wilcac Life Insurance Company ',
        'Ext_Carr_Id': 762
      },
      {
        'Ext_Carr_Name': 'American Savings Life insurance Company',
        'Ext_Carr_Id': 763
      },
      {
        'Ext_Carr_Name': 'Paragon International Insurance Limited',
        'Ext_Carr_Id': 764
      },
      {
        'Ext_Carr_Name': 'ZURICH AMERICAN',
        'Ext_Carr_Id': 765
      },
      {
        'Ext_Carr_Name': 'Armed Services Mutual Benefit Association ',
        'Ext_Carr_Id': 766
      },
      {
        'Ext_Carr_Name': 'Transamerica Life Canada',
        'Ext_Carr_Id': 767
      },
      {
        'Ext_Carr_Name': 'Lloyds of London ',
        'Ext_Carr_Id': 768
      },
      {
        'Ext_Carr_Name': 'VETERANS LIFE GROUP INSURANCE',
        'Ext_Carr_Id': 769
      },
      {
        'Ext_Carr_Name': 'National Slovak Society',
        'Ext_Carr_Id': 770
      },
      {
        'Ext_Carr_Name': 'KENTUCKY FARM BUREAU',
        'Ext_Carr_Id': 771
      },
      {
        'Ext_Carr_Name': 'Best Meridian Insurance Company',
        'Ext_Carr_Id': 772
      },
      {
        'Ext_Carr_Name': 'MED AMERICA INSURANCE COMPANY',
        'Ext_Carr_Id': 773
      },
      {
        'Ext_Carr_Name': 'Luso-American Life Insurance Society',
        'Ext_Carr_Id': 774
      },
      {
        'Ext_Carr_Name': 'AVMA GHLIT',
        'Ext_Carr_Id': 776
      },
      {
        'Ext_Carr_Name': 'Pavonia Life Insurance',
        'Ext_Carr_Id': 777
      },
      {
        'Ext_Carr_Name': 'Penn Treaty Life Insurance ',
        'Ext_Carr_Id': 778
      },
      {
        'Ext_Carr_Name': 'Industrial Alliance American Life Insurance Company',
        'Ext_Carr_Id': 779
      },
      {
        'Ext_Carr_Name': 'Delaware Life',
        'Ext_Carr_Id': 780
      },
      {
        'Ext_Carr_Name': 'NEA INSURANCE                                                    ',
        'Ext_Carr_Id': 781
      },
      {
        'Ext_Carr_Name': 'VALIC ',
        'Ext_Carr_Id': 782
      },
      {
        'Ext_Carr_Name': 'Securian Life',
        'Ext_Carr_Id': 783
      },
      {
        'Ext_Carr_Name': 'Pharmacist Life Ins Co',
        'Ext_Carr_Id': 784
      },
      {
        'Ext_Carr_Name': 'Worldwide Assurance for Employees of Public Agencies',
        'Ext_Carr_Id': 785
      },
      {
        'Ext_Carr_Name': 'Loyal Christian Benefit Association',
        'Ext_Carr_Id': 786
      },
      {
        'Ext_Carr_Name': 'ALLIANCE ONE',
        'Ext_Carr_Id': 787
      },
      {
        'Ext_Carr_Name': 'Lincoln Financial Group',
        'Ext_Carr_Id': 788
      },
      {
        'Ext_Carr_Name': 'Sisa Vida',
        'Ext_Carr_Id': 789
      },
      {
        'Ext_Carr_Name': 'national Income Life',
        'Ext_Carr_Id': 790
      },
      {
        'Ext_Carr_Name': 'Security Plus Insurance',
        'Ext_Carr_Id': 791
      },
      {
        'Ext_Carr_Name': 'All Life',
        'Ext_Carr_Id': 792
      },
      {
        'Ext_Carr_Name': 'Wilton Reassurance Life Company of New York',
        'Ext_Carr_Id': 793
      },
      {
        'Ext_Carr_Name': 'ACCORDIA LIFE',
        'Ext_Carr_Id': 794
      },
      {
        'Ext_Carr_Name': 'TRANSAMERICA PREMIER LIFE',
        'Ext_Carr_Id': 795
      },
      {
        'Ext_Carr_Name': 'Security Plan Life Insurance',
        'Ext_Carr_Id': 796
      },
      {
        'Ext_Carr_Name': 'American Continental Insurance Co',
        'Ext_Carr_Id': 797
      },
      {
        'Ext_Carr_Name': 'Vantis Life',
        'Ext_Carr_Id': 798
      },
      {
        'Ext_Carr_Name': 'Ashley Cooper Life Insurance',
        'Ext_Carr_Id': 799
      },
      {
        'Ext_Carr_Name': 'AAA',
        'Ext_Carr_Id': 800
      },
      {
        'Ext_Carr_Name': 'Lombard International',
        'Ext_Carr_Id': 801
      },
      {
        'Ext_Carr_Name': 'Federal Employee Group Life Insurance ',
        'Ext_Carr_Id': 802
      },
      {
        'Ext_Carr_Name': 'Family Heritage Life Insurance Company of America',
        'Ext_Carr_Id': 803
      },
      {
        'Ext_Carr_Name': 'RELIABLE LIFE ',
        'Ext_Carr_Id': 804
      },
      {
        'Ext_Carr_Name': 'Senior Health Insurance Company of Pennsylvania',
        'Ext_Carr_Id': 805
      },
      {
        'Ext_Carr_Name': 'zurich American Life',
        'Ext_Carr_Id': 806
      },
      {
        'Ext_Carr_Name': 'Baptist Life Association ',
        'Ext_Carr_Id': 807
      },
      {
        'Ext_Carr_Name': 'JP MORGAN CHASE ',
        'Ext_Carr_Id': 808
      },
      {
        'Ext_Carr_Name': 'United Home Life',
        'Ext_Carr_Id': 810
      },
      {
        'Ext_Carr_Name': 'IA American Life Insurance',
        'Ext_Carr_Id': 811
      },
      {
        'Ext_Carr_Name': 'american academy of orthodontic',
        'Ext_Carr_Id': 812
      },
      {
        'Ext_Carr_Name': 'Brighthouse Financial ',
        'Ext_Carr_Id': 813
      },
      {
        'Ext_Carr_Name': 'Mercer Consumer',
        'Ext_Carr_Id': 814
      },
      {
        'Ext_Carr_Name': 'US Alliance',
        'Ext_Carr_Id': 815
      },
      {
        'Ext_Carr_Name': 'Settlers Life Insurance Company',
        'Ext_Carr_Id': 818
      },
      {
        'Ext_Carr_Name': 'Wilco Life Insurance Company',
        'Ext_Carr_Id': 819
      },
      {
        'Ext_Carr_Name': 'OTHER',
        'Ext_Carr_Id': 820
      },
      {
        'Ext_Carr_Name': 'Grupo Nacional Provincial',
        'Ext_Carr_Id': 821
      },
      {
        'Ext_Carr_Name': 'SECU Life',
        'Ext_Carr_Id': 822
      },
      {
        'Ext_Carr_Name': 'Catholic Ladies of Columbia',
        'Ext_Carr_Id': 823
      },
      {
        'Ext_Carr_Name': 'Lumico Life',
        'Ext_Carr_Id': 825
      },
      {
        'Ext_Carr_Name': 'Sammons Retirement Solutions',
        'Ext_Carr_Id': 826
      },
      {
        'Ext_Carr_Name': 'Sentinal Security Life',
        'Ext_Carr_Id': 827
      },
      {
        'Ext_Carr_Name': 'Commonwealth Financial Network',
        'Ext_Carr_Id': 828
      },
      {
        'Ext_Carr_Name': 'Teacher’s Retirement System of Texas',
        'Ext_Carr_Id': 829
      },
      {
        'Ext_Carr_Name': 'Voya Retirement & Annuity Company',
        'Ext_Carr_Id': 830
      },
      {
        'Ext_Carr_Name': 'Haven Life ',
        'Ext_Carr_Id': 831
      },
      {
        'Ext_Carr_Name': 'Commonwealth Direct',
        'Ext_Carr_Id': 832
      },
      {
        'Ext_Carr_Name': 'Friends Provident UK',
        'Ext_Carr_Id': 833
      },
      {
        'Ext_Carr_Name': 'Catholic Holy Family Society ',
        'Ext_Carr_Id': 834
      },
      {
        'Ext_Carr_Name': 'TruStage',
        'Ext_Carr_Id': 835
      },
      {
        'Ext_Carr_Name': 'Catamaran Insurance of Ohio',
        'Ext_Carr_Id': 836
      },
      {
        'Ext_Carr_Name': 'UNION NATIONAL LIFE INSURANCE',
        'Ext_Carr_Id': 837
      },
      {
        'Ext_Carr_Name': 'TCA by E*Trade',
        'Ext_Carr_Id': 838
      },
      {
        'Ext_Carr_Name': 'LICOA',
        'Ext_Carr_Id': 839
      },
      {
        'Ext_Carr_Name': 'RESOLUTION LIFE HOLDING ',
        'Ext_Carr_Id': 840
      },
      {
        'Ext_Carr_Name': 'SPJST',
        'Ext_Carr_Id': 842
      },
      {
        'Ext_Carr_Name': 'Metropolitan Tower Life Ins Co',
        'Ext_Carr_Id': 843
      },
      {
        'Ext_Carr_Name': 'Family Benefit Life Insurance',
        'Ext_Carr_Id': 844
      },
      {
        'Ext_Carr_Name': 'PAN AMERICAN ASSURRANCE COMPANY',
        'Ext_Carr_Id': 845
      },
      {
        'Ext_Carr_Name': 'CONTINENTAL WESTERN GROUP',
        'Ext_Carr_Id': 846
      },
      {
        'Ext_Carr_Name': 'Nassau Re',
        'Ext_Carr_Id': 847
      },
      {
        'Ext_Carr_Name': 'American Life & Security Corp',
        'Ext_Carr_Id': 848
      },
      {
        'Ext_Carr_Name': 'Liberty Life Assurance Co',
        'Ext_Carr_Id': 849
      },
      {
        'Ext_Carr_Name': 'MINISTERS LIFE INSURANCE COMPANY',
        'Ext_Carr_Id': 850
      },
      {
        'Ext_Carr_Name': 'American Fidelity International (Bermuda) Ltd.',
        'Ext_Carr_Id': 851
      },
      {
        'Ext_Carr_Name': 'MONY LIFE INSURANCE COMPANY OF THE AMERICAS',
        'Ext_Carr_Id': 852
      },
      {
        'Ext_Carr_Name': 'KJZT Family Life',
        'Ext_Carr_Id': 853
      },
      {
        'Ext_Carr_Name': 'S.USA Life Insurance Company',
        'Ext_Carr_Id': 854
      },
      {
        'Ext_Carr_Name': 'PRUDENTIAL GROUP',
        'Ext_Carr_Id': 855
      },
      {
        'Ext_Carr_Name': 'USAble Life',
        'Ext_Carr_Id': 856
      },
      {
        'Ext_Carr_Name': 'Hermann Sons Life',
        'Ext_Carr_Id': 857
      },
      {
        'Ext_Carr_Name': 'Western Catholic Union',
        'Ext_Carr_Id': 858
      },
      {
        'Ext_Carr_Name': 'ACCORDIA LIFE & ANNUITY CO',
        'Ext_Carr_Id': 859
      },
      {
        'Ext_Carr_Name': 'FORETHOUGHT LIFE INS CO',
        'Ext_Carr_Id': 860
      },
      {
        'Ext_Carr_Name': 'BLUE CROSS BLUE SHIELD ',
        'Ext_Carr_Id': 861
      },
      {
        'Ext_Carr_Name': 'National Financial Services',
        'Ext_Carr_Id': 862
      },
      {
        'Ext_Carr_Name': 'Talcott Resolution Life & Annuity Insurance Company',
        'Ext_Carr_Id': 863
      },
      {
        'Ext_Carr_Name': 'Florida Farm Bureau',
        'Ext_Carr_Id': 864
      },
      {
        'Ext_Carr_Name': 'Eagle Life ',
        'Ext_Carr_Id': 865
      },
      {
        'Ext_Carr_Name': 'WOODMEN LIFE ',
        'Ext_Carr_Id': 866
      },
      {
        'Ext_Carr_Name': 'Federated Guaranty Life Insurance',
        'Ext_Carr_Id': 867
      },
      {
        'Ext_Carr_Name': 'Cetera Financial Institutions',
        'Ext_Carr_Id': 868
      },
      {
        'Ext_Carr_Name': 'First Command ',
        'Ext_Carr_Id': 869
      },
      {
        'Ext_Carr_Name': 'EVEREST',
        'Ext_Carr_Id': 870
      },
      {
        'Ext_Carr_Name': 'American National Life of New York ',
        'Ext_Carr_Id': 871
      },
      {
        'Ext_Carr_Name': 'COMBINED INSURANCE',
        'Ext_Carr_Id': 872
      },
      {
        'Ext_Carr_Name': 'GLOBAL ATLANTIC',
        'Ext_Carr_Id': 873
      },
      {
        'Ext_Carr_Name': 'New York LIfe Securities',
        'Ext_Carr_Id': 874
      },
      {
        'Ext_Carr_Name': 'UKRAINIAN NATIONAL ASSOCIATION',
        'Ext_Carr_Id': 876
      },
      {
        'Ext_Carr_Name': 'Royal Alliance',
        'Ext_Carr_Id': 878
      },
      {
        'Ext_Carr_Name': 'TIAA-CREF',
        'Ext_Carr_Id': 879
      }
    ];
    this.companyList = this.dropdownlist.map(x => x.Ext_Carr_Name);
    this.SubmitTwo = true;
    this.InActiveTickIcon = true;
    this.InActiveCrossIcons = true;
    this.InActiveTickIconForDiscontinous = true;
    this.InActiveCrossIconsForDiscontinous = true;
    this.InActiveCrossIconsForcontracts = true;
    this.InActiveTickIconForcontracts = true;
    this.OptionsForContracts = false;
    this.OptionsForDiscontinous = false;
    this.existingPolicyProductTypeOptions = this.questionDetails['sub_questions']
      ['question_2']['sub_questions']['question_1']['group_of_inputs']['answer_4']['dropdown_options'];


    this.existingPolicyTypeOptions = this.questionDetails['sub_questions']
      ['question_2']['sub_questions']['question_1']['group_of_inputs']['answer_5']['dropdown_options'];


    this.existingPolicynPurposeOptions = this.questionDetails['sub_questions']
      ['question_2']['sub_questions']['question_1']['group_of_inputs']['answer_6']['dropdown_options'];


    this.existingPolicyIssueYearOptions = this.questionDetails['sub_questions']['question_2']['sub_questions']['question_1']['group_of_inputs']['answer_7']['dropdown_options'];


    const qestentity = {
      'existingPolicyCompany': '',
      'existingPolicyNumber': '',
      'existingPolicyBenefitAmount': '',
      'existingPolicyProductType': 'Choose',
      'existingPolicyType': 'Choose',
      'existingPolicynPurpose': 'Choose',
      'existingPolicyIssueYear': '',
      'existingPolicyConfirm': ''
    };
    this.dynamicdiv.push(qestentity);
    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {
        debugger;
        const clientDataFiltered = JSON.parse(data.Payload);
        this.existingPolicyInfo = clientDataFiltered.body.existingPolicyInfo;
        this.hasExistingLifeInsurance = clientDataFiltered.body.hasExistingLifeInsurance;
        this.terminatingExistingPolicies = clientDataFiltered.body.terminatingExistingPolicies;
        this.useFundsFromExistingPolicies = clientDataFiltered.body.useFundsFromExistingPolicies;
        this.editForTheComponent = localStorage.getItem('editExistingPolicyInformation');
        if (this.editForTheComponent == 'true') {
          // this.hasExistingLifeInsuranceDetails= localStorage.getItem("PacLife-hasExistingLifeInsuranceDetails");
          // this.hasExistingLifeInsuranceDetails = JSON.parse(this.hasExistingLifeInsuranceDetails);
          if(this.existingPolicyInfo!=undefined)
          {
            for (let i = 0; i < this.existingPolicyInfo.length; i++) {
              const ctd = this.existingPolicyInfo[i].existingPolicyBenefitAmount;
              this.existingPolicyInfo[i].existingPolicyBenefitAmount = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
          if (this.hasExistingLifeInsurance == 'Yes') {
            var data1 = 1;
          } else {
            var data1 = 0;
          }

          this.SelectedOption = data1;
          if (this.SelectedOption == 0) {
            this.selectedOptionValue = 'No';
            this.ActiveTickIcons = false;
            this.InActiveTickIcon = true;
            this.ActiveCrossIcons = true;
            this.InActiveCrossIcons = false;
            this.PolicySubQuestion = false;
            this.SubmitOne = true;
            this.SubmitTwo = false;
            this.OptionsForContracts = false;

          } else {
            this.selectedOptionValue = 'Yes';

            this.ActiveTickIcons = true;
            this.InActiveTickIcon = false;
            this.ActiveCrossIcons = false;
            this.InActiveCrossIcons = true;
            this.PolicySubQuestion = true;

            if (this.terminatingExistingPolicies == 'Yes') {
              var data1 = 1;
            } else {
              var data1 = 0;
            }

            this.SelectedOptionForDiscontinous = data1;
            if (this.SelectedOptionForDiscontinous == 0) {
              this.SelectedOptionForDiscontinous = 'No';
              this.ActiveTickIconsForDiscontinous = false;
              this.InActiveTickIconForDiscontinous = true;
              this.ActiveCrossIconsForDiscontinous = true;
              this.InActiveCrossIconsForDiscontinous = false;
              this.OptionsForDiscontinous = false;

            } else {
              this.SelectedOptionForDiscontinous = 'Yes';
              this.ActiveTickIconsForDiscontinous = true;
              this.InActiveTickIconForDiscontinous = false;
              this.ActiveCrossIconsForDiscontinous = false;
              this.InActiveCrossIconsForDiscontinous = true;
              this.OptionsForDiscontinous = true;
            }


            if (this.useFundsFromExistingPolicies == 'Yes') {
              var data2 = 1;
            } else {
              var data2 = 0;
            }

            this.SelectedOptionForContracts = data2;
            if (this.SelectedOptionForContracts == 0) {
              this.SelectedOptionForContracts = 'No';

              this.ActiveTickIconsForcontracts = false;
              this.InActiveTickIconForcontracts = true;
              this.ActiveCrossIconsForcontracts = true;
              this.InActiveCrossIconsForcontracts = false;
              this.OptionsForContracts = false;
              this.SubmitOne = false;
              this.SubmitTwo = true;

            } else {
              this.SelectedOptionForContracts = 'Yes';

              this.ActiveTickIconsForcontracts = true;
              this.InActiveTickIconForcontracts = false;
              this.ActiveCrossIconsForcontracts = false;
              this.InActiveCrossIconsForcontracts = true;
              this.OptionsForContracts = true;
              this.SubmitOne = true;
              this.SubmitTwo = false;


              this.dynamicdiv =
                this.existingPolicyInfo.length != undefined ||
                  this.existingPolicyInfo.length > 0 ?
                  this.existingPolicyInfo : [];

            }



          }

        } else {

          // this.hasExistingLifeInsuranceDetails = localStorage.getItem("PacLife-hasExistingLifeInsuranceDetails");
          // this.hasExistingLifeInsuranceDetails = JSON.parse(this.hasExistingLifeInsuranceDetails);
          if(this.existingPolicyInfo!=undefined)
          {

            for (let i = 0; i < this.existingPolicyInfo.length; i++) {
              const ctd = this.existingPolicyInfo[i].existingPolicyBenefitAmount;
              this.existingPolicyInfo[i].existingPolicyBenefitAmount = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }

          }

          if (this.hasExistingLifeInsurance == 'Yes') {
            var data3 = 1;
          } else {
            var data3 = 0;
          }

          this.SelectedOption = data3;
          if (this.SelectedOption == 0) {
            this.selectedOptionValue = 'No';
            this.ActiveTickIcons = false;
            this.InActiveTickIcon = true;
            this.ActiveCrossIcons = true;
            this.InActiveCrossIcons = false;
            this.PolicySubQuestion = false;
            this.SubmitOne = true;
            this.SubmitTwo = false;
            this.OptionsForContracts = false;

          } else {
            this.selectedOptionValue = 'Yes';

            this.ActiveTickIcons = true;
            this.InActiveTickIcon = false;
            this.ActiveCrossIcons = false;
            this.InActiveCrossIcons = true;
            this.PolicySubQuestion = true;

            if (this.terminatingExistingPolicies == 'Yes') {
              var data4 = 1;
            } else {
              var data4 = 0;
            }

            this.SelectedOptionForDiscontinous = data4;
            if (this.SelectedOptionForDiscontinous == 0) {
              this.SelectedOptionForDiscontinous = 'No';
              this.ActiveTickIconsForDiscontinous = false;
              this.InActiveTickIconForDiscontinous = true;
              this.ActiveCrossIconsForDiscontinous = true;
              this.InActiveCrossIconsForDiscontinous = false;
              this.OptionsForDiscontinous = false;

            } else {
              this.SelectedOptionForDiscontinous = 'Yes';
              this.ActiveTickIconsForDiscontinous = true;
              this.InActiveTickIconForDiscontinous = false;
              this.ActiveCrossIconsForDiscontinous = false;
              this.InActiveCrossIconsForDiscontinous = true;
              this.OptionsForDiscontinous = true;
            }


            if (this.useFundsFromExistingPolicies == 'Yes') {
              var data5 = 1;
            } else {
              var data5 = 0;
            }

            this.SelectedOptionForContracts = data5;
            if (this.SelectedOptionForContracts == 0) {
              this.SelectedOptionForContracts = 'No';

              this.ActiveTickIconsForcontracts = false;
              this.InActiveTickIconForcontracts = true;
              this.ActiveCrossIconsForcontracts = true;
              this.InActiveCrossIconsForcontracts = false;
              this.OptionsForContracts = false;
              this.SubmitOne = false;
              this.SubmitTwo = true;

            } else {
              this.SelectedOptionForContracts = 'Yes';

              this.ActiveTickIconsForcontracts = true;
              this.InActiveTickIconForcontracts = false;
              this.ActiveCrossIconsForcontracts = false;
              this.InActiveCrossIconsForcontracts = true;
              this.OptionsForContracts = true;
              this.SubmitOne = true;
              this.SubmitTwo = false;


              this.dynamicdiv =
                this.existingPolicyInfo.length != undefined ||
                  this.existingPolicyInfo.length > 0 ?
                  this.existingPolicyInfo : [];

            }


          }


        }





        this.showloader=false;

      }
    ).catch(error => {
      console.log(error);
    });







  }






  public adddiv() {
    if (this.dynamicdiv.length < 7) {
      this.checkyes = undefined;
      const qestentity = {
        'existingPolicyCompany': '',
        'existingPolicyNumber': '',
        'existingPolicyBenefitAmount': '',
        'existingPolicyProductType': 'Choose',
        'existingPolicyType': 'Choose',
        'existingPolicynPurpose': 'Choose',
        'existingPolicyIssueYear': '',
        'existingPolicyConfirm': ''
      };
      this.dynamicdiv.push(qestentity);
    } else {
    }





  }


  SubmitOne: boolean;
  SubmitTwo: boolean;
  SelectedOption: any;
  SecondQuestion: any;
  ActiveTickIcons: boolean;
  InActiveTickIcon: boolean;
  ActiveCrossIcons: boolean;
  InActiveCrossIcons: boolean;
  selectedOptionValue: any;
  PolicySubQuestion: boolean;
  PolicyOwnerInformation: boolean;
  public GetOptions(data) {
    this.SelectedOption = data;
    if (this.SelectedOption == 0) {
      this.selectedOptionValue = 'No';
      // document.getElementById('0').style.border='solid 2px orange';
      // document.getElementById('1').style.border='solid 1px #dae7ec';
      this.ActiveTickIcons = false;
      this.InActiveTickIcon = true;
      this.ActiveCrossIcons = true;
      this.InActiveCrossIcons = false;
      this.PolicySubQuestion = false;
      this.SubmitOne = true;
      this.SubmitTwo = false;
      this.OptionsForContracts = false;
      this.dynamicdiv = [];
      const qestentity = {
        'existingPolicyCompany': '',
        'existingPolicyNumber': '',
        'existingPolicyBenefitAmount': '',
        'existingPolicyProductType': 'Choose',
        'existingPolicyType': 'Choose',
        'existingPolicynPurpose': 'Choose',
        'existingPolicyIssueYear': '',
        'existingPolicyConfirm': ''
      };
      this.dynamicdiv.push(qestentity);

    } else {
      this.selectedOptionValue = 'Yes';
      //  document.getElementById('1').style.border='solid 2px orange';
      //  document.getElementById('0').style.border='solid 1px #dae7ec';
      this.ActiveTickIcons = true;
      this.InActiveTickIcon = false;
      this.ActiveCrossIcons = false;
      this.InActiveCrossIcons = true;
      this.PolicySubQuestion = true;

      this.SubmitOne = false;
      this.SubmitTwo = true;
    }
  }


  SelectedOptionForDiscontinous: any;
  ActiveTickIconsForDiscontinous: boolean;
  InActiveTickIconForDiscontinous: boolean;
  ActiveCrossIconsForDiscontinous: boolean;
  InActiveCrossIconsForDiscontinous: boolean;
  public GetOptionsForDiscontinous(data) {
    this.SelectedOptionForDiscontinous = data;
    if (this.SelectedOptionForDiscontinous == 0) {
      this.SelectedOptionForDiscontinous = 'No';
      // document.getElementById('D0').style.border='solid 2px orange';
      // document.getElementById('D1').style.border='solid 1px #dae7ec';
      this.ActiveTickIconsForDiscontinous = false;
      this.InActiveTickIconForDiscontinous = true;
      this.ActiveCrossIconsForDiscontinous = true;
      this.InActiveCrossIconsForDiscontinous = false;
      this.OptionsForDiscontinous = false;
    } else {
      this.SelectedOptionForDiscontinous = 'Yes';
      // document.getElementById('D1').style.border='solid 2px orange';
      // document.getElementById('D0').style.border='solid 1px #dae7ec';
      this.ActiveTickIconsForDiscontinous = true;
      this.InActiveTickIconForDiscontinous = false;
      this.ActiveCrossIconsForDiscontinous = false;
      this.InActiveCrossIconsForDiscontinous = true;
      this.OptionsForDiscontinous = true;
    }

  }



  SelectedOptionForContracts: any;
  ActiveTickIconsForcontracts: boolean;
  InActiveTickIconForcontracts: boolean;
  ActiveCrossIconsForcontracts: boolean;
  InActiveCrossIconsForcontracts: boolean;
  public GetOptionsForcontracts(data) {
    this.SelectedOptionForContracts = data;
    if (this.SelectedOptionForContracts == 0) {
      this.SelectedOptionForContracts = 'No';
      // document.getElementById('C0').style.border='solid 2px orange';
      // document.getElementById('C1').style.border='solid 1px #dae7ec';
      this.ActiveTickIconsForcontracts = false;
      this.InActiveTickIconForcontracts = true;
      this.ActiveCrossIconsForcontracts = true;
      this.InActiveCrossIconsForcontracts = false;
      this.OptionsForContracts = false;
      // this.SubmitOne=true;
      // this.SubmitTwo=false;

    } else {
      this.SelectedOptionForContracts = 'Yes';
      // document.getElementById('C1').style.border='solid 2px orange';
      // document.getElementById('C0').style.border='solid 1px #dae7ec';
      this.ActiveTickIconsForcontracts = true;
      this.InActiveTickIconForcontracts = false;
      this.ActiveCrossIconsForcontracts = false;
      this.InActiveCrossIconsForcontracts = true;
      this.OptionsForContracts = true;
      // this.SubmitOne=false;
      // this.SubmitTwo=true;

    }

  }

  existingPolicyBenefitAmount;
  onKey(event, index) {
    const existingPolicyBenefitAmount = event.target.value;
    this.existingPolicyBenefitAmount = existingPolicyBenefitAmount;
    this.existingPolicyBenefitAmount = this.existingPolicyBenefitAmount.replace(/,/g, '');
    const ctd = this.existingPolicyBenefitAmount;
    this.existingPolicyBenefitAmount = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    this.dynamicdiv[index]['existingPolicyBenefitAmount'] = this.existingPolicyBenefitAmount;
  }




  inputJson;
  public onSubmit(data) {
    debugger;

    if (data.on_submit == 'proceed') {

      for (let i = 0; i < this.dynamicdiv.length; i++) {
        this.dynamicdiv[i].existingPolicyBenefitAmount = this.dynamicdiv[i].existingPolicyBenefitAmount.replace(/,/g, '');
      }

      if (this.editForTheComponent == 'true') {
        localStorage.setItem('questionId', data.question_sequence);
        this.inputJson = {};
        this.inputJson['id'] = localStorage.getItem('clientID');
        this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue,
        this.inputJson[data.sub_questions.question_1.radio_options[0].answer_key] = this.SelectedOptionForDiscontinous;
        this.inputJson[data.sub_questions.question_2.radio_options[0].answer_key] = this.SelectedOptionForContracts;
        this.inputJson[data.sub_questions.question_2.sub_questions.question_1.group_key] = this.dynamicdiv;

        this.hasExistingLifeInsuranceOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson, afterEdit: true });
        this.submitdata(this.inputJson);
      } else {
        localStorage.setItem('questionId', data.question_sequence);
        this.inputJson = {};
        this.inputJson['id'] = localStorage.getItem('clientID');
        this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue,
        this.inputJson[data.sub_questions.question_1.radio_options[0].answer_key] = this.SelectedOptionForDiscontinous;
        this.inputJson[data.sub_questions.question_2.radio_options[0].answer_key] = this.SelectedOptionForContracts;
        this.inputJson[data.sub_questions.question_2.sub_questions.question_1.group_key] = this.dynamicdiv;

        this.hasExistingLifeInsuranceOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson, afterEdit: false });
        this.submitdata(this.inputJson);
      }




    } else {
      alert('Declined!');
    }

    this.$gaService.event('Next', 'event', 'LifeAppExistingPolicy');
    const eventOptions: EventOption = {
      page_title: 'Existing Policy',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppExistingPolicy');
  }

  public submitdata(inputJson) {
    //debugger;

    const hasExistingLifeInsuranceDetails = JSON.stringify(this.inputJson);
    localStorage.setItem('PacLife-hasExistingLifeInsuranceDetails', hasExistingLifeInsuranceDetails);
    localStorage.setItem('bubble-hasExistingLifeInsurance', this.selectedOptionValue);
    this.pc.saveUpdateClientData(inputJson);
  }


  public goBack() {
    // this.isDependentOutput.emit(this.questionDetails["component_id"]+",Goback");
    this.hasExistingLifeInsuranceOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }


  companyError: boolean;
  checkCompany(data) {
    // debugger
    if (data.existingPolicyCompany.length < 1) {
      this.companyError = true;
    }
    else if (data.existingPolicyCompany == '') {
      this.companyError = false;
    }
    else {
      this.companyError = false;
    }
  }


  RemoveRow(divindex) {
    const index = this.dynamicdiv.findIndex(x => x.firstname == divindex);
    this.dynamicdiv.splice(index, 1);
  }

  checkNumberYear(HPdiv) {
    const year = HPdiv.existingPolicyIssueYear;
    if (year.length == 4 && (Number(year) < 1900 || Number(year) > 2100)) {
      HPdiv.existingPolicyIssueYear = '';
    }
  }
  public CheckYesOrNo(list, even, i) {
    debugger;
    if (even == 'Yes' && i == this.dynamicdiv.length - 1) {
      this.checkyes = 'Yes';
      list.existingPolicyConfirm = 'Yes';
    }
    if (even == 'uncheckyes' && i == this.dynamicdiv.length - 1) {
      this.checkyes = undefined;
      list.existingPolicyConfirm = undefined;
    }
  }

  // let qestentity = {
  //   "existingPolicyCompany": "Choose",
  //   "existingPolicyNumber": "",
  //   "existingPolicyBenefitAmount": "",
  //   "existingPolicyProductType": "Choose",
  //   "existingPolicyType": "Choose",
  //   "existingPolicynPurpose": "Choose",
  //   "existingPolicyIssueYear":"",
  //   "existingPolicyConfirm":""
  // };
  // this.dynamicdiv.push(qestentity);

  existingPolicyProductType(company, list) {
    list.existingPolicyProductType = company;
    //document.getElementById("existingPolicyProductType").innerHTML=company;
  }
  existingPolicyType(product, list) {
    list.existingPolicyType = product;
    //document.getElementById("existingPolicyType").innerHTML=product;
  }
  existingPolicynPurpose(type, list) {
    list.existingPolicynPurpose = type;
    //document.getElementById("existingPolicynPurpose").innerHTML=type;
  }
  pendingApplicationCompany(purpose, list) {
    list.existingPolicyCompany = purpose;
    //document.getElementById("pendingApplicationCompany").innerHTML=purpose;
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),

      distinctUntilChanged(),
      map(term => term.length < 1 ? [] : this.companyList.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    );


}
