import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { TrackerService } from 'src/app/shared/tracker.service';

@Component({
  selector: 'app-appflowstart',
  templateUrl: './appflowstart.component.html',
  styleUrls: ['./appflowstart.component.css']
})
export class AppflowstartComponent implements OnInit {

  constructor(private activatedroute: ActivatedRoute, private tracker: TrackerService, private service: ParentChildService) { }
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  carrierName: any;
  showNumber: any;
   time :any;

  ngOnInit(): void {
    this.showNumber = 0;
    document.getElementById('index_title_id').innerText = 'Life Insurance powered by Bubble';
    this.tracker.changeProgressStatus({ totalPages: 30, currentPage: 17 });
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];
      this.carrierName = params['carrierName'];
      localStorage.setItem('clientID', this.clientId);
    }
    );


    this.service.componentMethodCalled$.subscribe(() => {
      this.showNumber = 0;
    });

  }


//   ngAfterViewInit(): void {
//     document.body.addEventListener("mouseleave", (e) => { this.exitPopup(); });
//     document.body.addEventListener("mousemove", (e) => { this.idlePopup(); });
//     document.body.addEventListener("keypress", (e) => { this.idlePopup(); });
//   }

//   exitPopup() {
//     if (this.showNumber == 0) {
//       document.getElementById("errorModal").style.display = "block";
//       this.showNumber = 1;
//     }
//   }

//   closePopup() {
//     document.getElementById("errorModal").style.display = "none";
//   }

//   closetab() {
//     debugger;
//     window.open("https://stage.getmybubble.co/", "_self");
//   }

//   idlePopup () {
//     debugger;

//     clearTimeout(this.time);
//     this.time =  setTimeout(()=>{ this.exitPopup(); },15000);

// }


}
