import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from "../../../appUtility/appUtility.js";
@Component({
  selector: 'app-sbli02701061',
  templateUrl: './sbli02701061.component.html',
  styleUrls: ['./sbli02701061.component.css']
})
export class Sbli02701061Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  isDisable: boolean;
  questions: any;
  convicted: any;
  clientId: any;
  existingData: any;
  showLoader = true;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    this.clientId = localStorage.getItem('clientID');
    this.isDisable = true;
 //   this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli02701061")[0];
    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        let que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == "app-sbli02701061")[0];
        console.log("app-sbli02701061 questions : ", this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli02701061")[0];
        console.log("app-sbli02701061 questions : ", this.questions);
      }

      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_convicted_reckless_driving != undefined) {
        this.convicted = this.existingData.sbli_convicted_reckless_driving;
      }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    console.log("app-sbli02701061 questions", this.questions);


  }

  convictedRecklessdriving(input) {
    this.convicted = input;

    var json = {
      'id': this.clientId,
      SBLI: this.existingData
    }

    json.SBLI[this.questions.radio_options[0].answer_key] = input;


    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);
  }



  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
