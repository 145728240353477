import { Component, OnInit } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-quote-flow-header',
  templateUrl: './quote-flow-header.component.html',
  styleUrls: ['./quote-flow-header.component.css']
})
export class QuoteFlowHeaderComponent implements OnInit {

  public isMobileResolution: boolean;
  public isDescktopResolution: boolean;
  homeURL: string;

  constructor(private pc: ProcessCacheService) { }

  callIcon: '';

  ngOnInit(): void {
    this.homeURL = this.pc.envCred.url;
    if (window.innerWidth < 600) {
      this.isMobileResolution = true;
      this.isDescktopResolution = false;
    } else {
      this.isMobileResolution = false;
      this.isDescktopResolution = true;
    }
    this.setLeadSourceMap();
  }

  mapLeadSource = {};

  async setLeadSourceMap() {
    const resp = await fetch(environment.Nest_API_URL + '/get-lead-to-url');
    const data = await resp.json();
    console.log(data, 'lead-sources');
    this.mapLeadSource = data;

    const lead_source = localStorage.getItem('lead_source');
    this.homeURL += '/' + (this.mapLeadSource[lead_source] || '');
    console.log(this.homeURL);
  }

  hideTooltip() {
    (document.getElementById('contactTooltip') as HTMLElement).style.visibility = 'hidden';
  }

  showTooltip() {
    (document.getElementById('contactTooltip') as HTMLElement).style.visibility = 'visible';
  }

}
