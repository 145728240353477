import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-home-insurance-discounts',
  templateUrl: './home-insurance-discounts.component.html',
  styleUrls: ['./home-insurance-discounts.component.css']
})
export class HomeInsuranceDiscountsComponent implements OnInit {

  @Output() triggerParent = new EventEmitter();
  discountOne: number;
  discountTwo: number;
  discountThree: number;
  discountFour: number;
  discountFive: number;
  discountSix: number;
  disountSelected: number;
  discountSelected = [];
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  state: any;
  city: any;
  zip: any;
  homeAddress: any;
  showBuilderCard: number;
  selectedBuilder: number;
  builder: any;
  builderId: any;
  builderList: any;
  yearBuiltCondition: boolean;
  discountlist: any;
  showCertificateCard: number;
  selectedCertificate: number;
  certificateId: any;
  certificate: string;
  showSprinklerCard: number;
  selectedSprinkler: number;
  sprinklerId: any;
  sprinkler: string;

  constructor(private pc: ProcessCacheService, private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    debugger
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );

    let fullAddress = this.uniqueId.split("-");
    fullAddress.shift();
    fullAddress = fullAddress.join(" ");
    this.pc.parseAddressLocalAndPatch(fullAddress, this.clientId).then(
      data => {
        debugger
        console.log("splitAddressNew : ", data);
        this.state = data.state_code;
        this.city = data.city_name;
        this.zip = data.zip;
        this.homeAddress = data.door_number + data.street;// + data.street_type;
        let quickquote = new pc.Quotes(this.uniqueId);
        this.builderList = quickquote.getSWCABuilders();
        console.log("builderlist : ", this.builderList);
        this.discountlist = quickquote.getDiscountsSW(this.state, localStorage.getItem("sw_yearBuilt"));

        if (this.state != "CA") {
          this.discountlist = this.discountlist.filter(x => x.answer_key != 'sw_DiscountsGatedCommunity')
        }
        if (this.state == "TX") {
          this.discountlist = this.discountlist.filter(x => x.answer_key != 'sw_DiscountsBurglarAlarmSystem' && x.answer_key != 'sw_DiscountsLockingDevice')
        }
        console.log("discountlist : ", this.discountlist);

        let selectedDiscounts = JSON.parse(localStorage.getItem('sw_discounts'));
        // this.discountSelected = selectedDiscounts;
        // if (this.discountSelected == null) {
        //   this.discountSelected = [];
        // }
        this.discountSelected = [];
        let selectedDiscountsID = localStorage.getItem('sw_discountsClientID');
        let applyDiscount = localStorage.getItem("applyDiscount");
        debugger
        if (selectedDiscountsID == this.clientId && selectedDiscounts.length > 0 && applyDiscount == "1") {
          for (let i = 0; i < this.discountlist.length; i++) {
            if (selectedDiscounts.includes(this.discountlist[i].sw_questionId)) {
              let commonInput = JSON.parse(localStorage.getItem("commonInput"));
              if (this.discountlist[i].sw_questionId == 61) {
                this.builderId = commonInput['ca_builder'];
                this.builder = this.discountlist[i].drop_down_values.filter(x => x.id == this.builderId)[0].name;
                this.selectedBuilder = 1;
                this.showBuilderCard = 1;
              }
              else if (this.discountlist[i].sw_questionId == 1700) {
                this.certificateId = commonInput['certificateId'];
                this.certificate = this.discountlist[i].drop_down_values.filter(x => x.value == this.certificateId)[0].text;
                this.selectedCertificate = 1;
                this.showCertificateCard = 1;
              }
              else if (this.discountlist[i].sw_questionId == 2000) {
                this.sprinklerId = commonInput['sprinklerId'];
                this.sprinkler = this.discountlist[i].drop_down_values.filter(x => x.value == this.sprinklerId)[0].text;
                this.selectedSprinkler = 1;
                this.showSprinklerCard = 1;
              }
              else {
                this.discountlist[i].selected = 1;
              }
              this.discountSelected.push(this.discountlist[i].sw_questionId);
            }
          }
          // for (let i = 0; i < selectedDiscounts.length; i++) {
          //   switch (selectedDiscounts[i]) {
          //     case 4965:
          //       this.selectedCard('1', 4965);
          //       break;
          //     case 61:
          //       this.selectedCard('2', 61);
          //       break;
          //     case 4200:
          //       this.selectedCard('3', 4200);
          //       break;
          //     case 4255:
          //       this.selectedCard('4', 4255);
          //       break;
          //     case 4305:
          //       this.selectedCard('5', 4305);
          //       break;
          //     case 9099:
          //       this.selectedCard('6', 9099);
          //       break;
          //   }
          // }
        }

      }
    ).catch(error => console.log("error", error));

    console.log("fullAddressNew : ", fullAddress);
    this.builder = "";
    this.builderId = "";
    this.certificate = "";
    this.certificateId = "";
    this.sprinkler = "";
    this.sprinklerId = "";

    this.yearBuiltCondition = false;

    let yearBuilt = localStorage.getItem("sw_yearBuilt");
    let currentYear = new Date().getFullYear();
    let diff = Number(currentYear) - Number(yearBuilt);
    if (Number(diff) <= 6) {
      this.yearBuiltCondition = true;
    }


  }

  onBuilderSelect() {
    this.showBuilderCard = 1;
  }

  onBuilderUnSelect() {
    this.showBuilderCard = 0;
  }

  builderSelected(even, questionId, list) {
    debugger
    console.log(even.target.value);
    if (even.target.value != "") {
      this.discountSelected.push(questionId);
      this.selectedBuilder = 1;
      this.builderId = even.target.value;
      if (this.builderId == "0") {
        this.builder = "N/A";
      }
      else {
        this.builder = list.filter(x => x.id == this.builderId)[0].name;
      }
    }
  }

  editSelectedbuilder(questionId) {
    debugger
    this.showBuilderCard = 1;
    this.selectedBuilder = 0;
    let index = this.discountSelected.indexOf(questionId);
    this.discountSelected.splice(index, 1);
    this.builderId = "";
  }

  onCertificateSelect() {
    this.showCertificateCard = 1;
  }

  onCertificateUnSelect() {
    this.showCertificateCard = 0;
  }

  certificateSelected(even, questionId, list) {
    debugger
    console.log(even.target.value);
    if (even.target.value != "") {
      this.discountSelected.push(questionId);
      this.selectedCertificate = 1;
      this.certificateId = even.target.value;
      if (this.certificateId == "0") {
        this.certificate = "N/A";
      }
      else {
        this.certificate = list.filter(x => x.value == this.certificateId)[0].text;
      }
    }
  }

  editSelectedCertificate(questionId) {
    debugger
    this.showCertificateCard = 1;
    this.selectedCertificate = 0;
    let index = this.discountSelected.indexOf(questionId);
    this.discountSelected.splice(index, 1);
    this.certificateId = "";
  }


  onSprinklerSelect() {
    this.showSprinklerCard = 1;
  }

  onSprinklerUnSelect() {
    this.showSprinklerCard = 0;
  }

  sprinklerSelected(even, questionId, list) {
    debugger
    console.log(even.target.value);
    if (even.target.value != "") {
      this.discountSelected.push(questionId);
      this.selectedSprinkler = 1;
      this.sprinklerId = even.target.value;
      if (this.sprinklerId == "0") {
        this.sprinkler = "N/A";
      }
      else {
        this.sprinkler = list.filter(x => x.value == this.sprinklerId)[0].text;
      }
    }
  }

  editSelectedSprinkler(questionId) {
    debugger
    this.showSprinklerCard = 1;
    this.selectedSprinkler = 0;
    let index = this.discountSelected.indexOf(questionId);
    this.discountSelected.splice(index, 1);
    this.sprinklerId = "";
  }




  selectedCard(cardnumber, questionId) {
    debugger
    if (cardnumber == '1') {
      if (this.discountOne == 1) {
        this.discountOne = 0;
        let index = this.discountSelected.indexOf(questionId);
        this.discountSelected.splice(index, 1);
      }
      else {
        this.discountOne = 1;
        this.discountSelected.push(questionId);
      }
    }
    if (cardnumber == '2') {
      if (this.discountTwo == 1) {
        this.discountTwo = 0;
        let index = this.discountSelected.indexOf(questionId);
        this.discountSelected.splice(index, 1);
      }
      else {
        this.discountTwo = 1;
        this.discountSelected.push(questionId);
      }
    }
    if (cardnumber == '3') {
      if (this.discountThree == 1) {
        this.discountThree = 0;
        let index = this.discountSelected.indexOf(questionId);
        this.discountSelected.splice(index, 1);
      }
      else {
        this.discountThree = 1;
        this.discountSelected.push(questionId);
      }
    }
    if (cardnumber == '4') {
      if (this.discountFour == 1) {
        this.discountFour = 0;
        let index = this.discountSelected.indexOf(questionId);
        this.discountSelected.splice(index, 1);
      }
      else {
        this.discountFour = 1;
        this.discountSelected.push(questionId);
      }
    }
    if (cardnumber == '5') {
      if (this.discountFive == 1) {
        this.discountFive = 0;
        let index = this.discountSelected.indexOf(questionId);
        this.discountSelected.splice(index, 1);
      }
      else {
        this.discountFive = 1;
        this.discountSelected.push(questionId);
      }
    }
    if (cardnumber == '6') {
      if (this.discountSix == 1) {
        this.discountSix = 0;
        let index = this.discountSelected.indexOf(questionId);
        this.discountSelected.splice(index, 1);
      }
      else {
        this.discountSix = 1;
        this.discountSelected.push(questionId);
      }
    }

    if (this.discountOne == 1 || this.discountTwo == 1 || this.discountThree == 1 || this.discountFour == 1 || this.discountFive == 1 || this.discountSix == 1) {
      this.disountSelected = 1;
    }
    else {
      this.disountSelected = 0;
    }
    console.log('selected discount : ', this.discountSelected);
  }

  selectDiscount(qid) {
    debugger
    if (this.discountSelected.includes(qid.sw_questionId)) {
      let index = this.discountSelected.indexOf(qid.sw_questionId);
      this.discountSelected.splice(index, 1);
      qid.selected = 0;
    }
    else {
      this.discountSelected.push(qid.sw_questionId);
      qid.selected = 1;
    }
  }

  backButton() {
    debugger;
    document.getElementById('mainDiv').style.filter = 'none';
    document.getElementById('discount_modal').style.display = 'none';
    let selectedDiscounts = JSON.parse(localStorage.getItem('sw_discounts'));
    let selectedDiscountsID = localStorage.getItem('sw_discountsClientID');
    let applyDiscount = localStorage.getItem("applyDiscount");
    if (selectedDiscountsID == this.clientId && selectedDiscounts.length > 0 && applyDiscount == "1") {
      for (let i = 0; i < this.discountlist.length; i++) {
        if (selectedDiscounts.includes(this.discountlist[i].sw_questionId)) {
          let commonInput = JSON.parse(localStorage.getItem("commonInput"));
          if (this.discountlist[i].sw_questionId == 61) {
            this.builderId = commonInput['ca_builder'];
            this.builder = this.discountlist[i].drop_down_values.filter(x => x.id == this.builderId)[0].name;
            this.selectedBuilder = 1;
            this.showBuilderCard = 1;
          }
          else if (this.discountlist[i].sw_questionId == 1700) {
            this.certificateId = commonInput['certificateId'];
            this.certificate = this.discountlist[i].drop_down_values.filter(x => x.value == this.certificateId)[0].text;
            this.selectedCertificate = 1;
            this.showCertificateCard = 1;
          }
          else if (this.discountlist[i].sw_questionId == 2000) {
            this.sprinklerId = commonInput['sprinklerId'];
            this.sprinkler = this.discountlist[i].drop_down_values.filter(x => x.value == this.sprinklerId)[0].text;
            this.selectedSprinkler = 1;
            this.showSprinklerCard = 1;
          }
          else {
            this.discountlist[i].selected = 1;
          }

        }
      }
      // this.discountOne = 0;
      // this.discountTwo = 0;
      // this.discountThree = 0;
      // this.discountFour = 0;
      // this.discountFive = 0;
      // this.discountSix = 0;
      // for (let i = 0; i < selectedDiscounts.length; i++) {
      //   switch (selectedDiscounts[i]) {
      //     case 4965:
      //       //this.selectedCard('1', 4965);
      //       this.discountOne = 1;
      //       break;
      //     case 61:
      //       //this.selectedCard('2', 61);
      //       this.discountTwo = 1;
      //       break;
      //     case 4200:
      //       //this.selectedCard('3', 4200);
      //       this.discountThree = 1;
      //       break;
      //     case 4255:
      //       //this.selectedCard('4', 4255);
      //       this.discountFour = 1;
      //       break;
      //     case 4305:
      //       //this.selectedCard('5', 4305);
      //       this.discountFive = 1;
      //       break;
      //     case 9099:
      //       //this.selectedCard('6', 9099);
      //       this.discountSix = 1;
      //       break;
      //   }
      // }
    }
    else {
      // this.discountOne = 0;
      // this.discountTwo = 0;
      // this.discountThree = 0;
      // this.discountFour = 0;
      // this.discountFive = 0;
      // this.discountSix = 0;
      // this.selectedBuilder = 0;
      // this.showBuilderCard = 0;
      for (let i = 0; i < this.discountlist.length; i++) {
        this.discountlist[i].selected = 0;
      }
      this.builderId = "";
      this.sprinklerId = "";
      this.certificateId = "";
      this.showBuilderCard = 0;
      this.showCertificateCard = 0;
      this.showSprinklerCard = 0;
      this.selectedBuilder = 0;
      this.selectedCertificate = 0;
      this.selectedSprinkler = 0;
    }
    //this.discountSelected = selectedDiscounts;
    var etty = {
      "type": 0
    }
    this.triggerParent.emit(etty);
  }

  saveButton() {
    debugger
    document.getElementById('mainDiv').style.filter = 'none';
    localStorage.setItem('sw_discounts', JSON.stringify(this.discountSelected));
    localStorage.setItem('sw_discountsClientID', this.clientId);
    var etty = {
      "type": 1,
      "selectedDiscounts": JSON.stringify(this.discountSelected),
      "builderId": this.builderId,
      "certificateId": this.certificateId,
      "sprinklerId": this.sprinklerId
    }
    this.triggerParent.emit(etty);
  }

}
