import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-sw-app-flow-footer',
  templateUrl: './sw-app-flow-footer.component.html',
  styleUrls: ['./sw-app-flow-footer.component.css']
})
export class SwAppFlowFooterComponent implements OnInit {

  @Input() insuranceType;
  @Input() selected;
  @Input() carrier;
  @Input() imgURL;
  @Input() amount;
  @Input() imgwidth;
  insurancetype: any;

  constructor(private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.insurancetype = params['insurancetype'];
    }
    );
  }

}
