import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from "../../../appUtility/appUtility.js";
@Component({
  selector: 'app-sbli02101270',
  templateUrl: './sbli02101270.component.html',
  styleUrls: ['./sbli02101270.component.css']
})
export class Sbli02101270Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  guilty: any;
  isDisable: boolean;
  questions: any;
  clientId: any;
  existingData: any;
  showLoader: boolean = true;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    this.clientId = localStorage.getItem('clientID');
    this.isDisable = true;
    //this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli02101270")[0];
    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        let que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == "app-sbli02101270")[0];
        console.log("app-sbli02101270 questions : ", this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli02101270")[0];
        console.log("app-sbli02101270 questions : ", this.questions);
      }
      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_guilty_felony != undefined) {
        this.guilty = this.existingData.sbli_guilty_felony;
      }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    console.log("app-sbli02101270 questions", this.questions);
  }

  next(input) {
    debugger;
    this.guilty = input;
    var json = {
      'id': this.clientId,
      SBLI: this.existingData
    }

    json.SBLI[this.questions.radio_options[0].answer_key] = this.guilty;
    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
