import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';


@Component({
  selector: 'app-sw4pt12',
  templateUrl: './sw4pt12.component.html',
  styleUrls: ['./sw4pt12.component.css']
})
export class Sw4pt12Component implements OnInit {

  @Output() nextquestion6 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  showoptions: any;
  swimmingPools: any;
  swimmingPoolInfo: any;
  existingData: any;
  loader: boolean;

  constructor(private $gaService: GoogleAnalyticsService,private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    this.loader = true;
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt12')[0];
    console.log('sw questions app-sw4pt12 : ', this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.stillwater;
      console.log('data from getAppData sw : ', this.existingData);

      if (this.existingData['sw_SwimmingPool'] == 'yes') {
        this.swimmingPools = this.existingData['sw_SwimmingPool'];
        this.questions.radio_options[0].active = true;
        this.questions.radio_options[1].active = false;
        this.showoptions = '1';
      }
      else if (this.existingData['sw_SwimmingPool'] == 'no'){
        this.swimmingPools = this.existingData['sw_SwimmingPool'];
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = true;
        this.showoptions = '0';
      }
      else {
        this.swimmingPools = '';
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = false;
        this.showoptions = '0';
      }

      if (this.existingData['sw_SwimmingPoolInfo'] == null || this.existingData['sw_SwimmingPoolInfo'] == undefined || this.existingData['sw_SwimmingPoolInfo'] == '') {
        this.swimmingPoolInfo = [];
        for (let i = 1; i < this.questions.sub_questions.length; i++){
          this.questions.sub_questions[i].active = false;
        }
      }
      else {
        // this.questions.sub_questions.find(item => item.answer_key == this.existingData['sw_SwimmingPoolInfo']).active = true;
        // this.swimmingPoolInfo = this.existingData['sw_SwimmingPoolInfo'];
        this.swimmingPoolInfo = this.existingData['sw_SwimmingPoolInfo'];
        for (let i = 1; i < this.questions.sub_questions.length; i++){
          if (this.swimmingPoolInfo.includes(this.questions.sub_questions[i].answer_key)) {
            this.questions.sub_questions[i].active = true;
          }
          else {
            this.questions.sub_questions[i].active = false;
          }
        }
      }
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });

    // if (localStorage.getItem('sw_SwimmingPool') == 'yes') {
    //   this.swimmingPools = localStorage.getItem('sw_SwimmingPool');
    //   this.questions.radio_options[0].active = true;
    //   this.questions.radio_options[1].active = false;
    //   this.showoptions = '1';
    // }
    // else if (localStorage.getItem('sw_SwimmingPool') == 'no'){
    //   this.swimmingPools = localStorage.getItem('sw_SwimmingPool');
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = true;
    //   this.showoptions = '0';
    // }
    // else {
    //   this.swimmingPools = '';
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = false;
    //   this.showoptions = '0';
    // }

    // if (localStorage.getItem('sw_SwimmingPoolInfo') == null || localStorage.getItem('sw_SwimmingPoolInfo') == undefined || localStorage.getItem('sw_SwimmingPoolInfo') == '') {
    //   this.swimmingPoolInfo = '';
    // }
    // else {
    //   this.questions.sub_questions.find(item => item.answer_key == localStorage.getItem('sw_SwimmingPoolInfo')).active = true;
    //   this.swimmingPoolInfo = localStorage.getItem('sw_SwimmingPoolInfo');
    // }

  }

  onYes() {
    this.showoptions = '1';
    this.questions.radio_options[0].active = true;
    this.questions.radio_options[1].active = false;
    this.swimmingPools = 'yes';
  }

  onNo() {
    this.showoptions = '0';
    this.questions.radio_options[0].active = false;
    this.questions.radio_options[1].active = true;
    this.swimmingPools = 'no';
  }

  selectRadio(list) {
    const selectedCard = list;
    if (selectedCard.active == true) {
      selectedCard.active = false;
    }
    else {
      selectedCard.active = true;
    }

    if (selectedCard.answer_key=='sw_SwimmingPoolInfoNone') {
      for (let i = 1; i < this.questions.sub_questions.length; i++){
        this.questions.sub_questions[i].active = false;
      }
      this.questions.sub_questions[0].active = true;
    }
    else {
      this.questions.sub_questions[0].active = false;
      // for (let i = 0; i < this.questions.sub_questions.length; i++){
      //   if (this.questions.sub_questions[i].answer_key == selectedCard.answer_key) {
      //     this.questions.sub_questions[i].active = true;
      //   }
      //   else {
      //     this.questions.sub_questions[i].active = false;
      //   }
      // }
    }


    this.swimmingPoolInfo = [];
    for (let i = 0; i < this.questions.sub_questions.length; i++){
      if (this.questions.sub_questions[i].active == true) {
        this.swimmingPoolInfo.push(this.questions.sub_questions[i].answer_key);
      }
    }
    console.log('this.swimmingPoolInfo : ', this.swimmingPoolInfo);

    // localStorage.setItem('sw_SwimmingPoolInfo', selectedCard.answer_key);
    // this.swimmingPoolInfo = selectedCard.answer_key;
    // for (let i = 0; i < this.questions.sub_questions.length; i++){
    //   if (this.questions.sub_questions[i].answer_key == selectedCard.answer_key) {
    //     this.questions.sub_questions[i].active = true;
    //   }
    //   else {
    //     this.questions.sub_questions[i].active = false;
    //   }
    // }
  }

  next() {
    this.loader = true;
    localStorage.setItem('sw_SwimmingPool', this.swimmingPools);

    for (let i = 0; i < this.questions.sub_questions.length; i++){
      localStorage.setItem(this.questions.sub_questions[i].answer_key, this.questions.sub_questions[i].active);
    }
    //debugger
    let knockout = 0;
    if (this.swimmingPools=='yes') {
      knockout = 1;
    }

    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };
    json.stillwater['sw_SwimmingPool'] = this.swimmingPools;
    json.stillwater['sw_SwimmingPoolInfo'] = this.swimmingPoolInfo;
    console.log('json : ', json);

    this.pc.saveUpdateClientData(json).then(data => {
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0,
        'knockout': knockout
      };
      this.nextquestion6.emit(etty);
    }).catch(error => {
      console.log(error);
    });
    this.$gaService.event('Next', 'event', 'HomeAppSwimmingPools');
  }

  previous() {
    this.loader = true;
    if(localStorage.getItem('RemoveKOData')=='1'){
      const json = {
        'id': this.clientId,
        stillwater: this.existingData
      };
      json.stillwater['sw_SwimmingPool'] = '';
      json.stillwater['sw_SwimmingPoolInfo'] = '';
      this.pc.saveUpdateClientData(json).then(data => {
        this.loader = false;
        const etty = {
          'next': 0,
          'previous': 1
        };
        this.nextquestion6.emit(etty);
      }).catch(error => {
        console.log(error);
      });
    }
    else {
      this.loader = false;
      const etty = {
        'next': 0,
        'previous': 1,
      };
      this.nextquestion6.emit(etty);
    }
  }

}
