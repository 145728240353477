import { Component, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { DomSanitizer } from '@angular/platform-browser';
import pc from 'bit-uibl-data-driver';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sbli-terms',
  templateUrl: './sbli-terms.component.html',
  styleUrls: ['./sbli-terms.component.css']
})
export class SbliTermsComponent implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  @Output() trigerrconsentpopup = new EventEmitter();
  showLoader = true;
  uniqueId: any;
  clientId: any;
  timeoutId:any;
  docURLs: any;
  userInactive: Subject<any> = new Subject();
  pdfMapping = [
    { text: 'HIPAA Authorization to Collect and Disclose Information', id: '905' },
    { text: 'Notice of Information Practices', id: '904' },
    { text: 'Privacy Policy', id: '906' },
    { text: 'Policy E-Application and E-Delivery Authorization and Disclosure', id: '911' },
    { text: 'Customer Notice', id: '912' },
  ];
  base64JsonPdf = {};
  combinedBase64DataString = '';
  pdfUrl: any;
  combinedPdfUrl: any;
  combinedPdfArray = [];
  constructor(
    private pc: ProcessCacheService,
    private modalService: NgbModal,
    private $gaService: GoogleAnalyticsService,
    private activatedroute: ActivatedRoute,
    private utilityService: UtilityFunctionsService,
    private router: Router,
    public sanitizer:DomSanitizer) { }

  ngOnInit(): void {
    debugger;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
    }
    );
    this.showLoader = true;
    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.sbligetFORMS(1).then(response => {
      debugger;
      console.log('sbligetFORMS res', response);
      if (response.sbliError) {
        document.getElementById('servererrorModal').style.display = 'block';
        //this.openModal(response.msgText, "Sorry!");
      }
      else {
        this.docURLs = JSON.parse(response.formsJson);
        console.log('sbligetFORMS res formsJson', JSON.parse(response.formsJson));
        this.docURLs.forEach(element => {
          console.log(element);
          for (const item in element) {
            console.log(item);
            this.base64JsonPdf[item.toString()] = element[item];
            // if (this.combinedBase64DataString  == ""){
            this.combinedBase64DataString = this.combinedBase64DataString+(atob(element[item]));
            this.combinedPdfArray.push(atob(element[item]));
            // }
            this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64,${element[item]}`);
          }

          // Define the string
          /* var decodedStringBtoA = 'Hello World!';

          // Encode the String
          var encodedStringBtoA = btoa(decodedStringBtoA);
          console.log("encodedStringBtoA", encodedStringBtoA);
          console.log("encodedStringAtoB", atob(encodedStringBtoA)); */
          //this.base64JsonPdf[k] = v
        });
        console.log('combinedPdfArray', this.combinedPdfArray);
        console.log('base64JsonPdf', this.base64JsonPdf);
        this.showLoader = false;
        this.checkTimeOut();
        this.userInactive.subscribe((message) => {
          // alert(message);
          console.log('message');
          this.trigerrconsentpopup.emit();
        }
        );
        // this.combineBase64Data();
      }
    })
      .catch(error => {
        console.log('sbliinitApp Error:', error);
        document.getElementById('servererrorModal').style.display = 'block';
      });
  }

  tryAgain() {
    document.getElementById('servererrorModal').style.display = 'none';
    location.reload();
  }

  gotoHome() {
    document.getElementById('servererrorModal').style.display = 'none';
    window.location.href = this.pc.envCred.url;
  }

  openModal(msg, title) {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    const data = {
      head: 'Please Note',
      title: title,
      message: msg,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  next() {
    this.pc.lifesummary({'consent_submit': 'submit' });
    this.$gaService.event('SBLI-LifeApplication-T&C','event','SBLI-LifeApplication-T&C');
    const eventOptions: EventOption = {
      page_title: 'SBLI-LifeApplication-T&C',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions,'SBLI-LifeApplication-T&C','event','SBLI-LifeApplication-T&C');

    const etty = {
      'next': 1,
      'previous': 0,
      'APICall': 0
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

  public DownloadPDF(id) {
    console.log('DownloadPDF', id);
    // window.location.href = 'data:application/octet-stream;base64,' + this.base64JsonPdf[id];
    const source = `data:application/pdf;base64,${this.base64JsonPdf[id]}`;
    const filename = this.pdfMapping.filter(item => item.id == id)[0]['text'];
    const link = document.createElement('a');
    link.href = source;
    link.download = `${filename}.pdf`;
    link.click();
    /* if(id==0){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["905"];
    }
    else if(id==0){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["904"];
    }
    else if(id==2){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["906"];
    }
    else if (id==3){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["911"];
    }
    else{
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["912"];
    }
    window.location.href = pdf; */
  }

  combineBase64Data(){
    console.log('combineBase64Data called', this.combinedBase64DataString);
    const newBase64 = btoa(this.combinedBase64DataString);
    console.log('combineBase64Data called', newBase64);
    // window.location.href = 'data:application/octet-stream;base64,' + this.base64JsonPdf[id];
    const source = `data:application/pdf;base64,${newBase64}`;
    const filename = 'Combined';
    const link = document.createElement('a');
    link.href = source;
    link.download = `${filename}.pdf`;
    link.click();

    const arrayBase64 = this.combinedPdfArray.join('');
    console.log('arrayBase64',arrayBase64);
    const combinedarrayBase64 = btoa(arrayBase64);
    console.log('combinedarrayBase64',combinedarrayBase64);

    const source2 = `data:application/pdf;base64,${combinedarrayBase64}`;
    const filename2 = 'CombinedArray';
    const link2 = document.createElement('a');
    link2.href = source2;
    link2.download = `${filename2}.pdf`;
    link2.click();

  }

  urlB64ToUint8Array(base64String) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    console.log('outputArray', outputArray);
    return outputArray;
  }

  checkTimeOut() {
    this.timeoutId = setTimeout(
      () =>



       this.userInactive.next(
          "User has been inactive for 5 seconds"), 5000
    );




  }



 @HostListener('window:keydown')
  @HostListener('window:mousedown')
  @HostListener('window:mouseover')





 checkUserActivity() {
    clearTimeout(this.timeoutId);
    this.checkTimeOut();
  }
}
