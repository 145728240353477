import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-quinstreet-life',
  templateUrl: './quinstreet-life.component.html',
  styleUrls: ['./quinstreet-life.component.css']
})
export class QuinstreetLifeComponent implements OnInit {
  httpParamsGlobal: any;
  loader: boolean;
  pftoken: any;
  quinstreetData: any;
  clientId: any;
  dropoffURL: any;
  email: any;
  firstname: any;
  lastname: any;
  city: any;
  state: any;
  country: string;
  zip: any;
  phone: any;
  gender: any;
  age: any;
  date_of_birth: any;
  marital_status: any;
  gendertohubspot: string;
  date_of_birthtohubspot: any;
  address: any;
  displayAddress: any;
  fullAddress: Address;
  full_address: any;
  clientData: any;
  uniqueId: any;
  insuranceType: string;
  constructor(private router: Router, private pc: ProcessCacheService, private http: HttpClient, private utilityService: UtilityFunctionsService) { }

  ngOnInit(): void {
    this.loader = true;
    if (this.router.url.includes('?')) {
      this.httpParamsGlobal = new HttpParams({ fromString: this.router.url.split('?')[1] });
      const httpParams = this.httpParamsGlobal;
      this.pftoken = httpParams.get('pf');
      this.http.get(`https://www.nextinsure.com/listingdisplay/prefill?pf=${this.pftoken}`).subscribe(data => {
        debugger
        if (data['Status'] == 'success') {
          data = data;
        }
        else {
          //location.href = `/prefillResumeFlowHoi?lead_source=Quinstreet_click&pftoken=${this.pftoken}`;
        }
        this.quinstreetData = data;
        localStorage.clear();
        localStorage.setItem("insuranceType", "life");
        this.clientId = this.utilityService.getNewRandomToken();
        let url = window.location.href;
        this.dropoffURL = this.utilityService.getDropOffURL(url, this.clientId);
        this.email = data['DataPassData']['Contact'].Email;
        localStorage.setItem("bubble-email", this.email);
        localStorage.setItem("bubble-emailid", this.email);
        this.firstname = data['DataPassData']['Contact'].FirstName;
        localStorage.setItem("bubble-fname", this.firstname);
        this.lastname = data['DataPassData']['Contact'].LastName;
        localStorage.setItem("bubble-lname", this.lastname);
        localStorage.setItem("bubble-fullName", `${this.firstname} ${this.lastname}`);
        this.address = data['DataPassData']['Contact'].Address;
        //this.address_line_2 = data['DataPassData']['Contact'].Address2;
        this.city = data['DataPassData']['Contact'].City;
        this.state = data['DataPassData']['Contact'].StateCode;
        this.country = "US";
        this.zip = data['DataPassData']['Contact'].ZipCode;
        this.phone = data['DataPassData']['Contact'].HomePhone;
        // this.alternatephone = data['DataPassData']['Contact'].WorkPhone;
        localStorage.setItem('bubble-zip', this.zip);
        if (data['DataPassData']['Individuals']) {
          this.age = data['DataPassData']['Individuals'];
          this.date_of_birth = data['DataPassData']['Individuals'][0].BirthDate;
          this.marital_status = data['DataPassData']['Individuals'][0].MaritalStatus;
          this.gender = data['DataPassData']['Individuals'][0].Gender;
          // this.credit_type = data['DataPassData']['Individuals'][0].SelfCreditRating;
          if (this.gender != null && this.gender != undefined) {
            if (this.gender.toLowerCase() == 'm') {
              localStorage.setItem('bubble-gender', '1');
              this.gendertohubspot = '1';
            }
            else if (this.gender.toLowerCase() == 'f') {
              localStorage.setItem('bubble-gender', '0');
              this.gendertohubspot = '0';
            }
          }
          this.date_of_birthtohubspot = this.date_of_birth;
          let dobparam = new Date(this.date_of_birth);
          let year = Number(dobparam.getFullYear());
          let month = '' + (dobparam.getMonth() + 1);
          let day = '' + dobparam.getDate();
          if (month.length < 2)
            month = '0' + month;
          if (day.length < 2)
            day = '0' + day;
          this.date_of_birth = month + '/' + day + '/' + year;
        }
        if (this.date_of_birth == undefined || this.date_of_birth == null || this.date_of_birth == '') {
          this.date_of_birth = this.date_of_birth;
          localStorage.setItem("bubble-dob", this.date_of_birth);
        } else {
          localStorage.setItem("bubble-dob", this.date_of_birth);
        }
        if (this.phone == undefined || this.phone == null || this.phone == '') {
          this.phone = '';
        } else {

          this.phone = this.phone;
        }
        if (this.address == undefined || this.address == null || this.address == '') {
          //this.fullAddress = '';
        } else {
          // this.fullAddress = this.address + "-" + this.city + "-" + this.state + "-" + this.zip;
          // this.fullAddress = this.fullAddress.replace(/-/g, " ");
        }
        // console.log("fullAddress fullAddress", this.fullAddress);
        // this.pc.fullAddress = this.fullAddress;
        if (this.email == null || this.address == null || this.state == null || this.city == null || this.zip == null || this.email == '') {
          //this.openModal();
        }
        else {
          //this.generateClientData();
        }


      })






    }
  }
 




  fillInHubspotDataFromFormData(hubSpotData) {
    const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    if (this.email) hubSpotData["email"] = this.email;
    if (this.firstname) hubSpotData["firstname"] = this.firstname;
    if (this.lastname) hubSpotData["lastname"] = this.lastname;
    if (this.address) hubSpotData["address"] = this.address;
    if (this.city) hubSpotData["city"] = this.city;
    if (this.state) hubSpotData["state"] = this.state;
    if (this.country) hubSpotData["country"] = this.country;
    if (this.zip) hubSpotData["zip"] = this.zip;
    if (this.phone) hubSpotData["phone"] = this.phone;
    if (this.date_of_birth) hubSpotData["dob_home"] = this.date_of_birthtohubspot;
    if (this.gender) hubSpotData["gender"] = this.gendertohubspot;
    if (this.marital_status) hubSpotData["marital_status"] = this.marital_status;
    if (this.fullAddress) hubSpotData["full_address"] = this.fullAddress;
    if (this.pftoken) hubSpotData["quinstreet_pf_token"] = this.pftoken;
    hubSpotData["home_insurance_type"] = 'Homeowner';
    let today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    let datetime = yyyy + '-' + mm + '-' + dd + ' ' + today.getHours() + ':' + today.getMinutes() + ':00 PST';
    hubSpotData["lead_source"] = 'Quinstreet_click';
    hubSpotData["lead_form_url"] = 'insure.com';
    hubSpotData["lead_source_time_stamp"] = datetime;
    hubSpotData["dropoff_url"] = this.dropoffURL,
    hubSpotData['customer_id'] = customerIdLocal
    return hubSpotData;
  }

  
  cookupCustomerId(firstname, lastname, clientId) {
    let firstNameLocal, lastNameLocal;
    try { firstNameLocal = firstname[0].toLowerCase(); } catch (error) { firstNameLocal = 'a' };
    try { lastNameLocal = lastname[0].toLowerCase(); } catch (error) { lastNameLocal = 'b' };
    return `${firstNameLocal}${lastNameLocal}${clientId}`;
  }
  public async saveupdateclient() {
    debugger
    this.loader = true;    
    let qid = [];
    let clientDataJson = {
      'id': this.clientId,
      "country": this.country,
      "bubble_email": this.email,
      "email": this.email,
      "bubble_fname": this.firstname,
      "bubble_lname": this.lastname,
      "address": this.address,
      "city_name": this.city,
      "stateCode": this.state,
      "state_name": this.state,
      "zip_code": this.zip,
      "bubble_phone": this.phone,
      "dob": this.date_of_birth,
      "gender": this.gender,    
      "fullAddress": this.fullAddress,      
      "prefill_marital_status": this.marital_status,  
      "swquote_question": qid,
      "quinstreet_token": this.pftoken,
      'quinstreetData': JSON.stringify(this.quinstreetData)
    } 
   const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    localStorage.setItem("customer_id", customerIdLocal);
    const idMappingJson = {
      client_id: this.clientId,
      customer_id: customerIdLocal,
      email_id: (!this.email) ? 'leads_to_check_inDB@getmybubble.com' : this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      dob: this.date_of_birth
    };
    this.pc.createIdMapping(idMappingJson);
    console.log("Lead:createIdMapping:idMappingJson:", idMappingJson);    
    this.pc.saveUpdateClientData(clientDataJson).then(data => {
      debugger
     // this.getdata();
    }).catch(error => {
      console.log(error);
    });
  }
  async getdata() {
    debugger
   // this.fullAddress = this.address + "-" + this.city + "-" + this.state + "-" + this.zip;
   // this.fullAddress = this.fullAddress.replace(/ /g, "-");
    this.pc.getAppData(this.clientId).then(data => {
      debugger
      let dataFrom = JSON.parse(data.Payload);
      this.clientData = dataFrom.body;
      console.log("dataFrom get app data : ", this.clientData);
      debugger
      this.fullAddress = this.clientData.fullAddress;
      this.uniqueId = pc.getUniqueId4Property(this.clientId, this.fullAddress)
      this.insuranceType = "life";
      localStorage.setItem("insuranceType", this.insuranceType);    
      debugger
    
      let google_address = {
        "street_number_ln": '',
        "street_number_sn": '',
        "route_ln": '',
        "route_sn": '',
        "neighborhood_ln": '',
        "neighborhood_sn": '',
        "locality_ln": '',
        "locality_sn": '',
        "administrative_area_level_2_ln": '',
        "administrative_area_level_2_sn": '',
        "administrative_area_level_1_ln": '',
        "administrative_area_level_1_sn": '',
        "country_ln": '',
        "country_sn": '',
        "postal_code_ln": '',
        "postal_code_sn": '',
        "postal_code_suffix_ln": '',
        "postal_code_suffix_sn": '',
        "formatted_address": ''
      };
      /* let address = {
        Addr1: '',
        addr: [],
        City: '',
        StateProvCd: '',
        PostalCode: ''
      }; */
      let address = {
        Addr1: "",
        Addr2: [],
        City: '',
        StateProvCd: '',
        PostalCode: ''
      };
      pc.BackendService.getGoogleAddressDetails(this.fullAddress).then(async (res) => {
        debugger
        let results = res.data.results;
        for (let i = 0; i < results[0].address_components.length; i++) {

          let type = results[0].address_components[i].types[0];
          switch (type) {
            case "street_number":
              google_address.street_number_ln = results[0].address_components[i].long_name;
              google_address.street_number_sn = results[0].address_components[i].short_name;
              address.Addr2[0] = results[0].address_components[i].short_name;
              break;
            case "route":
              google_address.route_ln = results[0].address_components[i].long_name;
              google_address.route_sn = results[0].address_components[i].short_name;
              address.Addr2[1] = results[0].address_components[i].short_name;
              break;
            case "neighborhood":
              google_address.neighborhood_ln = results[0].address_components[i].long_name;
              google_address.neighborhood_sn = results[0].address_components[i].short_name;
              address.Addr2[2] = results[0].address_components[i].short_name;
              break;
            case "locality":
              google_address.locality_ln = results[0].address_components[i].long_name;
              google_address.locality_sn = results[0].address_components[i].short_name;
              address.City = results[0].address_components[i].short_name;
              break;
            case "administrative_area_level_2":
              google_address.administrative_area_level_2_ln = results[0].address_components[i].long_name;
              google_address.administrative_area_level_2_sn = results[0].address_components[i].short_name;
              break;
            case "administrative_area_level_1":
              google_address.administrative_area_level_1_ln = results[0].address_components[i].long_name;
              google_address.administrative_area_level_1_sn = results[0].address_components[i].short_name;
              address.StateProvCd = results[0].address_components[i].short_name;
              break;
            case "country":
              google_address.country_ln = results[0].address_components[i].long_name;
              google_address.country_sn = results[0].address_components[i].short_name;
              break;
            case "postal_code":
              google_address.postal_code_ln = results[0].address_components[i].long_name;
              google_address.postal_code_sn = results[0].address_components[i].short_name;
              address.PostalCode = results[0].address_components[i].short_name;
              break;
            case "postal_code_suffix":
              google_address.postal_code_suffix_ln = results[0].address_components[i].long_name;
              google_address.postal_code_suffix_sn = results[0].address_components[i].short_name;
              break;
          }

        }
        address.Addr1 = address.Addr2.join(' ');
        delete address.Addr2;
        // let brushData = await pc.BackendService.getbrushForestAPI(JSON.stringify(address), this.uniqueId);
        // console.log("brushData", brushData);
        debugger;
        let swData = {
          "data_category": "getMinimalHomeQuoteFromStillWater",
          "fetchViaAPI": "true",
          "dataCategory": "StillWater_QuickQuote",
          "uid": this.uniqueId,
          "lastname": "T",
          "firstname": "Anonymous",
          "streetaddress": google_address.street_number_ln + " " + google_address.route_sn, //"353 Portland Cir",
          "city": google_address.locality_ln,
          "state": google_address.administrative_area_level_1_sn,
          "zipcode": google_address.postal_code_sn
        };
      
    
        const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
        let hubSpotData = {
          application_stage: "Lead",
          client_id: this.clientId,
          customer_id: customerIdLocal,
        };
        hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
        console.log("hubSpotData from fillInHubspotDataFromFormData", hubSpotData);
        debugger;
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
        localStorage.setItem("applyDiscount", "0");       
        let resumeURL = `prefill-QuoteFlowV2/${this.uniqueId}/${this.clientId}/${this.fullAddress}/${this.insuranceType}`;
        console.log("resumeURL generated: ", resumeURL);
        //this.resumeFlow(resumeURL);
      }).catch(error => {
        console.log(error);
      });
    }).catch(error => {
      console.log(error);
    })
  }

  async resumeFlow(resumeURL) {
    this.loader = true;
    let createJson = {
     // insuranceType: this.iType,
      carrierName: "stillwater",
      appStartStatus: false,
      appCompletedStatus: false,
      questionStartStatus: false,
      questionCompletedStatus: false,
      questionSequence: "0",
      applicationId: "",
      otherAppflowScreen: "home-question1",
      resumeUrl: resumeURL
    }
    let createResumeData = await this.pc.checkforResumeInit(createJson, "create");
    console.log("createResumeData: ", createResumeData);
    this.router.navigate(['/resumeFlow', this.clientId]);
  }
}
