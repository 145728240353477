import { Component, OnInit, HostListener, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { environment } from '../../../environments/environment';
import { ProfileDataHandlerService } from 'src/app/shared/profile-data-handler.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
const pc = require('bit-uibl-data-driver');
import { ValidationService } from 'src/app/shared/validation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../components/lifescore-error-alert/lifescore-error-alert.component';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { PrefillserviceService } from '../../shared/prefillservice.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import axios from 'axios';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { DOCUMENT } from '@angular/common';

// eslint-disable-next-line no-var
declare var window: any;

@Component({
  selector: 'app-prefill-to-reco-page',
  templateUrl: './prefill-to-reco-page.component.html',
  styleUrls: ['./prefill-to-reco-page.component.css']
})
export class PrefillToRecoPageComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    lazyLoad: false,
    margin: 30,
    smartSpeed: 1500,
    autoplay: false,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    navText: ['<i class="fa fa-arrow-left"></i>', '<i class="fa fa-arrow-right"></i>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };
  email: any;
  policyvalue: any;
  insuranceType: any;
  loader: boolean;
  clientId: any;
  iType: string;
  recoType: string;
  hs_preview: string;
  firstname: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone: string;
  date_of_birth: any;
  gender: string;
  marital_status: string;
  interest_type: string;
  home_type: string;
  dwelling_use: string;
  number_of_units: string;
  lead_source: string;
  lead_form_url: string;
  lead_source_time_stamp: string;
  newly_acquired_property: string;
  number_of_claims: string;
  property_year_built: string;
  property_living_area: string;
  home_insurance_type: string;
  credit_rating: string;
  bundle_auto_with_home: string;
  stories: string;
  roof_updated_year: any;
  lastname: string;
  construction_type: string;
  roof_type: string;
  swimming_pool: string;
  year_built: any;
  living_size: string;
  fullAddress: string;
  uniqueId: string;
  httpParamsGlobal: any;
  domain: any;
  dropoffURL: any;
  curDate: any;
  minDate;
  minArea: number;
  yearBuiltError: any;
  constructionList: any;
  storiesList: any;
  roofTypeList: any;
  propertdetails = [];
  rooftype: any;
  constructiontype: any;
  message: string;
  is_valid: boolean;
  roofupdatedyearError: string;
  livingSizeError: string;
  selectedConstructionType: any;
  selectedStories: any;
  selectedRoofType: any;
  phoneerror: any;
  prevEmail: any;
  errormessage: any;
  utm_source: any;
  utm_medium: any;
  utm_campaign: any;
  utm_term: any;
  utm_content: any;
  sub_id: any;
  addresstoshow: any;
  roofUpdated: string;
  addresscomponent: object;
  disableForm = true;
  emailerror: any;
  addressError: string;
  roofDD: any;
  selectedRoofUpdated: any;
  leadtokenid: any;
  genderforhbspot: any;
  address_line_2: any;
  applicationStage: string;
  mobilephone: any;
  homeTypeList: { text: string; value: string; }[];
  selectedHomeType: string;
  year_built_copy: string;
  living_size_copy: string;
  emailprefillerror: any;
  pftoken: any;
  timeStamp: any;
  phoneDisplay: string;
  spinner: boolean;
  homeQuotePremium: number;
  kopopup1list = [
    'QUA0007', 'SWI0276', 'QUA0132', 'SWI0281', 'SWI0277', 'SWI0278',
    'QUA0124', 'QUA0139', 'QUA0142', 'QUA0134'
  ];
  kopopup2list = [
    'QUA0028', 'PUB0028'
  ];
  kopopup3list = [
    'QUA0154', 'QUA0159', 'QUA0169'
  ];
  showpopup1: boolean;
  showpopup2: boolean;
  showpopup3: boolean;
  koerrormessge: any;
  redirectUrl: any;
  url: any;
  gclid: any;
  buttonsticky: boolean;
  disable: boolean;

  sendToLO(data) {
    if (this.gclid) {
      data.gclid = this.gclid;
    }
    window._loq.push(['custom', data]);
  }


  @HostListener('window:scroll', [])
  onWindowScroll() {
   // debugger
    if (document.body.scrollTop > 3300 ||
      document.documentElement.scrollTop > 401 && document.documentElement.scrollTop < 890) {
      this.buttonsticky = true;
    }
    else {
      this.buttonsticky = false;
    }
  }
  constructor(
    private location: Location,
    private utilityService: UtilityFunctionsService,
    public router: Router,
    private modalService: NgbModal,
    public validationService: ValidationService,
    private Http: HttpClient,
    private $gaService: GoogleAnalyticsService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private Prefillservice: PrefillserviceService) { }

  ngOnInit(): void {
    debugger;
    this.policyvalue = "No";
    window._loq = window._loq || [];
    this.roofDD = [
      {
        text: 'Within last 1 year',
        value: '6m'
      },
      {
        text: 'Within last 10 years',
        value: '5'
      },
      {
        text: 'Within last 20 years',
        value: '15'
      },
      {
        text: 'More than 20 years ago',
        value: 'yb'
      },
      {
        text: 'I don’t know',
        value: ''
      }
    ];
    this.homeTypeList = [
      {
        'text': 'Single Family Home',
        'value': 'DW'
      },
      {
        'text': 'Townhouse',
        'value': 'Townhouse'
      },
      {
        'text': 'Row house',
        'value': 'Rowhouse'
      },
      {
        'text': 'Condo',
        'value': 'Condo'
      },
      {
        'text': 'Apartment',
        'value': 'Apartment'
      },
      {
        'text': 'Duplex',
        'value': 'Duplex'
      },
      {
        'text': 'Mobile Home',
        'value': 'MobileHome'
      },
      {
        'text': 'Manufactured Home',
        'value': 'ManufacturedHome'
      }
    ];

    this.loader = true;
    this.emailerror = '';
    this.addressError = '';
    //localStorage.clear();
    localStorage.setItem('insuranceType', 'home');
    this.roofUpdated = '6m';
    this.clientId = this.utilityService.getNewRandomToken();
    this.storiesList = [
      { value: '1', text: '1' },
      { value: '1and1/2', text: '1and1/2' },
      { value: '2', text: '2' },
      { value: '2and1/2', text: '2and1/2' },
      { value: '3', text: '3' },
      { value: '4', text: '4+' },
    ];
    this.phoneerror = '';
    this.roofupdatedyearError = '';
    this.livingSizeError = '';
    this.yearBuiltError = '';
    this.applicationStage = 'Lead';
    this.curDate = new Date();
    const year = Number(this.curDate.getFullYear()) - 18;
    let month = '' + (this.curDate.getMonth() + 1);
    let day = '' + this.curDate.getDate();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    this.minDate = new Date(month + '/' + day + '/' + year);
    this.url;
    if (window && window.location && window.location.href) {
      this.url = window.location.href;
    }
    this.dropoffURL = this.utilityService.getDropOffURL(this.url, this.clientId);
    // this.dropoffURL = `${this.domain}/resumeFlow/${this.clientId}`;
    if (this.url.includes('?')) {
      this.disable = true;
      sessionStorage.setItem('accessedURL', this.url);
      sessionStorage.setItem('accessedHomeURL', this.url);
      this.httpParamsGlobal = new HttpParams({ fromString: this.url.split('?')[1] });
      localStorage.setItem('httpParamsGlobal', JSON.stringify(this.httpParamsGlobal));
      //this.doActivisty();
      setTimeout(() => {
        this.createLog();
      }, 2000);

    }
    else if (sessionStorage.getItem('accessedURL') != undefined && sessionStorage.getItem('accessedURL') != null && sessionStorage.getItem('accessedURL') != '') {
      this.disable = true;
      const uuu = sessionStorage.getItem('accessedURL');
      this.httpParamsGlobal = new HttpParams({ fromString: uuu.split('?')[1] });
      //this.doActivisty();
      setTimeout(() => {
        this.createLog();
      }, 2000);
    }
    else {
      this.disable = false;
      this.phone = '';
      this.phoneerror = '';
      this.minArea = 500;
      this.loader = false;
      this.emailprefillerror = true;
      this.home_type = '';
      this.date_of_birth = '';
      this.selectedHomeType = this.homeTypeList[0].value;
      this.home_type = 'DW';
      this.construction_type = 'F';
      this.roof_type = 'ARCH';
      this.swimming_pool = 'No';
      this.selectedRoofUpdated = this.roofDD[2].value;
      const currentYear = new Date().getFullYear();
      const currentMonth = new Date().getMonth() + 1;
      this.selectedRoofUpdated = this.roofDD.filter(x => x.value == this.roofUpdated)[0].value;
      switch (this.roofUpdated) {
        case '6m':
          if (Number(currentMonth) >= 6) {
            this.roof_updated_year = Number(currentYear);
          }
          else {
            this.roof_updated_year = Number(currentYear) - 1;
          }
          break;
        case '5':
          this.roof_updated_year = Number(currentYear) - 5;
          break;
        case '15':
          this.roof_updated_year = Number(currentYear) - 15;
          break;
        case 'yb':
          this.roof_updated_year = this.year_built;
          break;
        case '':
          this.roof_updated_year = '';
          break;
      }
      if (this.date_of_birth == undefined || this.date_of_birth == null || this.date_of_birth == '') {
        this.date_of_birth = '';
      }
      const today = new Date();
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      const datetime = yyyy + '-' + mm + '-' + dd + ' ' + today.getHours() + ':' + today.getMinutes() + ':00 PST';
      this.lead_source_time_stamp = datetime;
      localStorage.setItem('bubble-fname', 'firstname');
      localStorage.setItem('bubble-lname', 'lastname');
    }


  }

  async createLog() {
    this.redirectUrl = this.pc.envCred.url;
    this.doActivisty();
  }

  async getHomeQuote() {
    try {
      let baseUrl = environment.envObject.stageapp.api2Domain;
      if (environment.production) {
        baseUrl = environment.envObject.app.api2Domain;
      }

      const area = Number(this.living_size);

      if (!this.zip || !this.living_size || this.livingSizeError || area > 10000) {
        this.homeQuotePremium = 0;
        return;
      }

      const response = await fetch(`${baseUrl}?data_category=getHomeQuote&dget0=${this.zip}&dget1=${this.living_size}`);
      const data = await response.json();

      if (!this.zip || !this.living_size || this.livingSizeError || area > 10000) {
        this.homeQuotePremium = 0;
        return;
      }

      this.homeQuotePremium = (data.premium && (data.premium / 12)) || 0;
      this.homeQuotePremium = Math.round(this.homeQuotePremium);
    } catch {
      this.homeQuotePremium = 0;
    }
  }

  async doActivisty() {
    debugger
    const httpParams = this.httpParamsGlobal;//new HttpParams({ fromString: url.split('?')[1] });
    this.hs_preview = httpParams.get('hs_preview');
    this.email = httpParams.get('email');
    this.lead_source = httpParams.get('lead_source');
    this.prevEmail = httpParams.get('email');
    if (this.email == 'undefined') {
      this.email = '';
    }
    const hubSpotData = {
      'email': this.email,
      client_id: this.clientId,
      'home_landing_page_variant': 'Couple Buying Home',
      'application_stage': 'Lead',
    };
    const customData = {
      client_id: this.clientId,
      email: this.email
    };
    this.gclid = httpParams.get('gclid');

    localStorage.setItem('bubble-email', httpParams.get('email'));
    localStorage.setItem('bubble-emailid', httpParams.get('email'));
    this.firstname = httpParams.get('firstname') ? httpParams.get('firstname') : 'firstname';
    this.lastname = httpParams.get('lastname') ? httpParams.get('lastname') : 'lastname';
    localStorage.setItem('bubble-fname', this.firstname);
    localStorage.setItem('bubble-lname', this.lastname);
    localStorage.setItem('bubble-fullName', `${httpParams.get('firstname')} ${httpParams.get('lastname')}`);
    this.address = httpParams.get('address');
    this.city = httpParams.get('city');
    this.state = httpParams.get('state');
    this.country = httpParams.get('country');
    this.address_line_2 = httpParams.get('address_line_2');
    this.zip = httpParams.get('zip');
    this.phone = httpParams.get('phone') ? httpParams.get('phone') : '';
    this.mobilephone = httpParams.get('mobilephone') ? httpParams.get('mobilephone') : '';
    localStorage.setItem('bubble-phone', this.phone);
    this.date_of_birth = httpParams.get('date_of_birth') ? httpParams.get('date_of_birth') : '';
    localStorage.setItem('bubble-dob', httpParams.get('date_of_birth'));
    this.gender = httpParams.get('gender');
    if (this.gender != null && this.gender != undefined) {
      if (this.gender.toLowerCase() == 'male') {
        localStorage.setItem('bubble-gender', '1');
        this.genderforhbspot = '1';
      }
      else if (this.gender.toLowerCase() == 'female') {
        localStorage.setItem('bubble-gender', '0');
        this.genderforhbspot = '0';
      }
    }
    this.marital_status = httpParams.get('marital_status');
    this.interest_type = (!httpParams.get('interest_type')) ? 'Home' : httpParams.get('interest_type'); //httpParams.get("interest_type");
    this.home_type = httpParams.get('home_type');
    this.dwelling_use = httpParams.get('dwelling_use');
    this.number_of_units = httpParams.get('number_of_units');
    this.lead_form_url = httpParams.get('lead_form_url');
    this.lead_source_time_stamp = httpParams.get('lead_source_time_stamp');
    this.newly_acquired_property = httpParams.get('newly_acquired_property');
    this.number_of_claims = httpParams.get('number_of_claims');
    this.property_year_built = httpParams.get('property_year_built');
    this.property_living_area = httpParams.get('property_living_area');
    this.home_insurance_type = (!httpParams.get('home_insurance_type')) ? 'Homeowner' : httpParams.get('home_insurance_type'); //httpParams.get("home_insurance_type");
    this.credit_rating = httpParams.get('credit_rating');
    this.bundle_auto_with_home = httpParams.get('bundle_auto_with_home');
    this.leadtokenid = httpParams.get('lead_token_id') ? httpParams.get('lead_token_id') : '';
    this.stories = httpParams.get('stories');
    this.roof_updated_year = httpParams.get('roof_updated_year') ? httpParams.get('roof_updated_year') : new Date().getFullYear() - 15;
    let tempValue = httpParams.get('utm_source');
    if (tempValue) this.utm_source = tempValue;
    tempValue = httpParams.get('utm_medium');
    if (tempValue) this.utm_medium = tempValue;
    tempValue = httpParams.get('utm_campaign');
    if (tempValue) this.utm_campaign = tempValue;
    tempValue = httpParams.get('utm_term');
    if (tempValue) this.utm_term = tempValue;
    tempValue = httpParams.get('utm_content');
    if (tempValue) this.utm_content = tempValue;
    tempValue = httpParams.get('sub_id');

    if (tempValue) this.sub_id = tempValue;
    this.year_built = this.property_year_built;
    this.living_size = this.property_living_area;
    this.year_built_copy = this.property_year_built;
    this.living_size_copy = this.property_living_area;

    this.construction_type = 'F';
    this.roof_type = 'ARCH';
    this.swimming_pool = 'No';

    if (this.email == null || this.address == null || this.state == null || this.city == null || this.zip == null || this.address == 'undefined' || this.state == 'undefined' || this.city == 'undefined' || this.zip == 'undefined') {

      this.errormessage = 'Our servers are taking too long to respond.Please try after sometime';

      if (this.address == null || this.state == null || this.city == null || this.zip == null || this.address == 'undefined' || this.state == 'undefined' || this.city == 'undefined' || this.zip == 'undefined') {
        this.address = '';
        this.state = '';
        this.city = '';
        this.zip = '';
        this.year_built = '';
        this.living_size = '';
      }
      this.setGAEvent();
    } else {
      document.getElementById('errorModal').style.display = 'none';
    }
    if (this.date_of_birth == undefined || this.date_of_birth == null || this.date_of_birth == '') {
      this.date_of_birth = '';
    }
    if (this.phone == undefined || this.phone == null || this.phone == '') {
      this.phone = '';
      this.phoneerror = '';
      this.phoneDisplay = this.phone;
    } else {
      let count = 0;
      this.phone = this.phone.replace(/^0+/, '');
      const phonestring = this.phone.toString().slice(0, this.phone.length);
      this.phone = phonestring.replace(/(\d{3})-?/g, function (m, a) {
        return ++count <= 2 ? a + '-' : m;
      });
      this.phone = this.phone.slice(0, 12);
      this.phoneDisplay = this.phone;
      this.phonelength();
    }
    this.addresstoshow = this.address + ' ' + this.city + ' ' + this.state + ' ' + this.zip;
    if (this.email != null && this.email != undefined && this.email != '') {
      this.sendToLO(customData);
      await this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    if (this.address == undefined || this.address == null || this.address == '') {
      this.fullAddress = '';
    } else {
      this.fullAddress = this.address + '-' + this.city + '-' + this.state + '-' + this.zip;
      this.fullAddress = this.fullAddress.replace(/-/g, ' ');
      //this.address = this.fullAddress;
    }
    const reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    const emailtest = this.email;
    if (this.email == null || this.email == undefined) {
      this.emailprefillerror = true;
      this.emailerror = "";

    }
    else if (reg.test(emailtest) == false) {
      this.emailprefillerror = "Please enter a valid email";
      this.emailerror = "Please enter a valid email";

    }
    this.pftoken = httpParams.get('pftoken');
    console.log('homeTypeList : ', this.homeTypeList);
    this.selectedHomeType = this.homeTypeList[0].value;
    this.home_type = 'DW';
    this.selectedRoofUpdated = this.roofDD[2].value;
    console.log('thisthisthisthis:', this);
    console.log(' this.addresstoshow', this.addresstoshow);
    console.log(' this.fullAddress', this.fullAddress);
    this.checkyearbuilt();
    this.checkArea();
    if (this.address != '' && this.city != '' && this.state != '' && this.zip != '') {
      pc.BackendService.getGoogleAddressDetails(this.fullAddress).then(
        (res) => {
          console.log('BackendService.getGoogleAddressDetails success', res);
          this.parseGoogleAddress(res.data.results[0]);
          this.generateClientData();
        }
      ).catch(error => {
        console.log('BackendService.getGoogleAddressDetails error', error);
      });
    }
    else {
      this.generateClientData();
    }

  }

  setGAEvent() {
    //action, category, label
    if (this.email == null) {

      const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'email'
      });
    }
    if (this.address == null) {
      const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'address'
      });
    }

    if (this.state == null) {
      const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'state'
      });
    }
    if (this.city == null) {
      const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'city'
      });
    }
    if (this.zip == null) {
      const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'zip'
      });
    }
  }
  fillInHubspotDataFromFormData(hubSpotData) {

    if (this.email) hubSpotData['email'] = this.email;
    if (this.firstname) hubSpotData['firstname'] = this.firstname;
    if (this.lastname) hubSpotData['lastname'] = this.lastname;
    if (this.address) hubSpotData['address'] = this.address;
    if (this.city) hubSpotData['city'] = this.city;
    if (this.state) hubSpotData['state'] = this.state;
    hubSpotData['country'] = this.country ? this.country : 'USA';
    if (this.zip) hubSpotData['zip'] = this.zip;
    if (this.phone) hubSpotData['phone'] = this.phone;
    if (this.date_of_birth) hubSpotData['dob_life'] = this.date_of_birth;
    if (this.gender) hubSpotData['gender'] = this.genderforhbspot;
    if (this.marital_status) hubSpotData['marital_status'] = this.marital_status;
    // Problem if we use this property Hubspot throws error
    if (this.home_type) hubSpotData['home_type'] = this.home_type == 'DW' ? 'SingleFamilyHome' : this.home_type;
    hubSpotData['dwelling_use'] = this.dwelling_use ? this.dwelling_use : 'primary_fulltime_residence';
    if (this.number_of_units) hubSpotData['number_of_units'] = this.number_of_units;
    hubSpotData['lead_source'] = this.lead_source ? this.lead_source : '';
    if (this.lead_form_url) hubSpotData['lead_form_url'] = this.lead_form_url;
    if (this.lead_source_time_stamp) hubSpotData['lead_source_time_stamp'] = this.lead_source_time_stamp;
    if (this.newly_acquired_property) hubSpotData['newly_acquired_property'] = this.newly_acquired_property;
    if (this.number_of_claims) hubSpotData['number_of_claims'] = this.number_of_claims;
    if (this.year_built) hubSpotData['property_year_built'] = this.year_built;
    if (this.living_size) hubSpotData['property_living_area'] = this.living_size;
    hubSpotData['home_landing_page_variant'] = 'Couple Buying Home',
      hubSpotData['home_insurance_type'] = this.home_insurance_type ? this.home_insurance_type : 'Homeowner';
    if (this.credit_rating) hubSpotData['credit_rating'] = this.credit_rating;
    if (this.bundle_auto_with_home) hubSpotData['bundle_auto_with_home'] = this.bundle_auto_with_home;
    if (this.stories) hubSpotData['stories'] = this.stories;
    if (this.roof_updated_year) hubSpotData['roof_updated_year'] = this.roof_updated_year;
    if (this.utm_source) hubSpotData['utm_source'] = this.utm_source;
    if (this.utm_medium) hubSpotData['utm_medium'] = this.utm_medium;
    if (this.utm_campaign) hubSpotData['utm_campaign'] = this.utm_campaign;
    if (this.utm_term) hubSpotData['utm_term'] = this.utm_term;
    if (this.utm_content) hubSpotData['utm_content'] = this.utm_content;
    if (this.sub_id) hubSpotData['sub_id'] = this.sub_id;
    if (this.address_line_2) hubSpotData['address_line_2'] = this.address_line_2;
    hubSpotData['interest_type'] = this.interest_type ? this.interest_type : 'Home';
    hubSpotData['dropoff_url'] = this.dropoffURL;
    if (this.leadtokenid != '') {
      if (this.leadtokenid) hubSpotData['lead_token_id'] = this.leadtokenid;
    }
    if (this.mobilephone != '') {
      hubSpotData['mobilephone'] = this.mobilephone;
    }

    if (this.address != '' && this.city != '' && this.state != '' && this.zip != '') {
      hubSpotData['full_address'] = this.address + ',' + this.city + ',' + this.state + ',' + this.zip;
    }
    if (this.lead_source == 'Quinstreet_click') {
      hubSpotData['quinstreet_pf_token'] = this.pftoken;

    }
    hubSpotData['application_stage'] = this.applicationStage;
    hubSpotData['current_hoi_policy_expires_in_3_mo'] = this.policyvalue
    return hubSpotData;
  }

  async getdata() {
    this.fullAddress = this.address + '-' + this.city + '-' + this.state + '-' + this.zip;
    this.fullAddress = this.fullAddress.replace(/ /g, '-');
    this.pc.fullAddress = this.fullAddress;
    this.uniqueId = pc.getUniqueId4Property(this.clientId, this.fullAddress);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      const clientData = dataFrom.body;
      console.log('dataFrom get app data : ', clientData);
      this.insuranceType = 'home';
      localStorage.setItem('insuranceType', this.insuranceType);
      this.iType = 'home-quote';
      this.recoType = 'select-home-carrierv2';
      let CommonInputHome = {};
      const localStories = {
        '1': '1',
        '1and1/2': '1H',
        '2': '2',
        '2and1/2': '2H',
        '3': '3',
        '4+': '4',
        '4': '4'
      };
      const propDetails = {
        'construction_type': this.construction_type,//no value
        'home_type': this.home_type,//no value
        'roof_type': this.roof_type,//no value
        'swimming_pool': this.swimming_pool,//no value
        'year_built': this.year_built,//no value
        'living_size': this.living_size,//no value
        'stories': localStories[this.stories] ? localStories[this.stories] : '1',
        'roof_constructed': this.roof_updated_year
      };
      CommonInputHome = {
        address: this.address,
        addressType: 'PhysicalRisk',
        city: this.city,
        coverage: 10,
        dob: this.date_of_birth,
        firstName: this.firstname ? this.firstname : 'firstname',
        lastName: this.lastname ? this.lastname : 'lastname',
        middleName: '',
        premiumFrequency: 'm',
        state: this.state,
        zip: this.zip,
        replacementCost: 10,
        yearbuilt: this.year_built,
        sw_discounts: [],
        sw_appQuestions: [],
        ca_builder: '',
        propertyDetails: propDetails,
        swquote_question: clientData.swquote_question,
        email: this.email,
        phone: this.phone,
        disableBack: true
      };

      localStorage.setItem('commonInput', JSON.stringify(CommonInputHome));
      localStorage.setItem('bubble-dob', CommonInputHome['dob']);
      localStorage.setItem('applyDiscount', '0');
      console.log('common input : ', CommonInputHome);
      console.log('CommonInputHome : ', CommonInputHome);
      const resumeURL = `${this.recoType}/${this.uniqueId}/${this.clientId}/${this.fullAddress}/${this.insuranceType}`;
      console.log('resumeURL generated: ', resumeURL);
      this.resumeFlow(resumeURL);
    }).catch(error => {
      console.log(error);
    });
  }

  async resumeFlow(resumeURL) {
    const createJson = {
      insuranceType: this.iType,
      carrierName: 'stillwater',
      appStartStatus: false,
      appCompletedStatus: false,
      questionStartStatus: false,
      questionCompletedStatus: false,
      questionSequence: '0',
      applicationId: '',
      otherAppflowScreen: 'home-question1',
      resumeUrl: resumeURL
    };
    const createResumeData = await this.pc.checkforResumeInit(createJson, 'create');
    console.log('createResumeData: ', createResumeData);
    //this.router.navigate(['/resumeFlow', this.clientId]);
    this.router.navigate([resumeURL]);
  }

  async generateClientData() {

    console.log('generateClientData httpParamsGlobal', this.httpParamsGlobal);
    const clientDataJson = {
      'client_id': this.clientId,
      'country': this.country,
      'bubble_email': this.email,
      'email': this.email,
      'bubble_fname': this.firstname,
      'bubble_lname': this.lastname,
      'address': this.address,
      'city_name': this.city,
      'stateCode': this.state,
      'state_name': this.state,
      'zip_code': this.zip,
      'bubble_phone': this.phone,
      'dob': this.date_of_birth,
      'gender': this.gender,
      'insurancetype': this.interest_type,
      'interest_type': this.interest_type,
      'yearBuilt': this.year_built,
      'livingArea': this.living_size,
      'roofBuiltYear': this.roof_updated_year,
      'fullAddress': this.fullAddress,
      'prefill_insurancetype': this.interest_type,
      'prefill_interest_type': this.interest_type,
      'prefill_yearBuilt': this.property_year_built,
      'prefill_livingArea': this.property_living_area,
      'prefill_roofBuiltYear': this.roof_updated_year,
      'prefill_marital_status': this.marital_status,
      'prefill_home_type': this.home_type,
      'prefill_dwelling_use': this.dwelling_use,
      'prefill_number_of_units': this.number_of_units,
      'prefill_lead_form_url': this.lead_form_url,
      'prefill_lead_source_time_stamp': this.lead_source_time_stamp,
      'prefill_newly_acquired_property': this.newly_acquired_property,
      'prefill_number_of_claims': this.number_of_claims,
      'prefill_home_insurance_type': this.home_insurance_type,
      'prefill_credit_rating': this.credit_rating,
      'prefill_bundle_auto_with_home': this.bundle_auto_with_home,
      'prefill_stories': this.stories
    };
    console.log('clientDataJson', clientDataJson);
    await this.Http.get<any>(`${this.pc.envCred.api2Domain}?data_category=m1144188134176150116232225133160147155103`, { params: clientDataJson }).subscribe((data: any) => {
      const response = data;
      console.log('post res success : ', response);
      //this.clientId = response.clientId;
      localStorage.setItem('clientID', this.clientId);
      localStorage.setItem('clientId', this.clientId);
      let eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'client_id', 'event_label': this.clientId
      });
      this.minArea = 500;
      // this.yearBuiltError = '';
      const quickquote = new pc.Quotes(this.clientId);
      const optionsList = quickquote.getSWPropertyOptions();
      this.constructionList = optionsList.construction_type;
      console.log('constructionList : ', this.constructionList);
      // this.storiesList = optionsList.stories;
      // console.log("storiesList : ", this.storiesList);
      this.roofTypeList = optionsList.roof_type;
      console.log('roofTypeList : ', this.roofTypeList);
      const propertyresponse = quickquote.getSWKnockoutQuestionsByState(this.state, this.year_built);
      console.log('Knockout Questions: ', propertyresponse);
      this.propertdetails = propertyresponse;

      for (let i = 0; i < this.propertdetails.length; i++) {
        if (i == 0) {
          this.propertdetails[i].value = 'yes';
        }
        else {
          this.propertdetails[i].value = 'no';
        }

        // this.getdata();
      }

      const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
      let hubSpotData = {
        interest_type: this.interest_type,
        application_stage: this.applicationStage,
        client_id: this.clientId,
        customer_id: customerIdLocal
      };
      hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
      if (this.email != null && this.email != undefined && this.email != '') {
        console.log('HS 2');
        const customData = {
          client_id: this.clientId,
          email: this.email
        };
        this.sendToLO(customData);
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      }
      eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'Page', 'event_label': 'PageLoadCompleted'
      });


      this.loader = false;
      // this.location.go("couple-buyingHomehoi");
      this.location.go('prefillToRecoPage');
      this.invokeBrushForest_onload();
    });

  }

  validateForm() {

    const errors = ['phoneerror', 'roofupdatedyearError', 'livingSizeError', 'yearBuiltError', 'emailerror', 'addressError'];
    console.log('validate form');
    this.disableForm = errors.some(key => this[key]);
  }


  phonelength() {
    debugger
    let count = 0;
    this.phone = this.phoneDisplay;
    this.phone = this.phone.replace(/^0+/, '');
    this.phone = this.phone.replace('+1', '').replace('(', '').replace(')', '');
    const phonestring = this.phone.toString().slice(0, this.phone.length);
    this.phone = phonestring.replace(/(\d{3})-?/g, function (m, a) {
      return ++count <= 2 ? a + '-' : m;
    });
    this.phone = this.phone.slice(0, 12);
    if (this.phone.length < 12) {
      this.phoneerror = 'Invalid Phone number';
    }
    else {
      this.phoneerror = '';
    }
// this.validateForm();
    this.phoneDisplay = this.phone;

    const j = this.phoneDisplay.replace(/-/g, '');
    var K = j.replace(/[{()}]/g, '');
    var K = K.replace(/ /g, '');
    const L = K.replace('+1', '');
    const A = L.substring(0, 3);
    const B = L.substring(3, 6);
    const C = L.substring(6, 10);
    if (A != '' && K.length <= 3) {
      if (A.length == 3) {
        this.phoneDisplay = '+1(' + A + ') ';
      }
      else {
        this.phoneDisplay = '+1(' + A;
      }
    }
    else if (B != '' && K.length > 3 && K.length < 7) {
      this.phoneDisplay = '+1(' + A + ')' + B;
    }
    else if (B != '' && K.length >= 7) {
      this.phoneDisplay = '+1(' + A + ')' + B + '-' + C;
    }
    else {
      if (L == '') {
        this.phoneDisplay = '';
      }
      else {
        this.phoneDisplay = this.phoneDisplay;
      }

    }
    console.log('this.phone : ', this.phone);
  }

  public SelectCard(list) {

    if (list.value == 'no') {
      list.value = 'yes';
      const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'Checked', 'event_label': list.answer_label
      });
    }
    else {
      list.value = 'no';
      const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'Unchecked', 'event_label': list.answer_label
      });
    }
  }

  public GetStories(even) {
    this.selectedStories = even.text;
    this.stories = even.value;
    const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'stories'
    });
  }

  public GetRooftype(even) {
    this.selectedRoofType = even.text;
    if (this.selectedRoofType.length > 20) {
      this.selectedRoofType = this.selectedRoofType.substring(0, 20) + '...';
    }
    this.roof_type = even.value;
    const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'roof_type'
    });
  }

  public GetConstructiontype(even) {
    this.selectedConstructionType = even.text;
    this.construction_type = even.value;
    const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'construction_type'
    });
  }

  public GetHometype(even) {

    this.selectedHomeType = even.target.value;
    this.home_type = even.target.value;
    const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'construction_type'
    });
  }


  onDateChange(even) {

    if (even == null) {
      this.curDate = new Date();
      const year = Number(this.curDate.getFullYear()) - 18;
      let month = '' + (this.curDate.getMonth() + 1);
      let day = '' + this.curDate.getDate();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.date_of_birth = month + '/' + day + '/' + year;
    } else {

      const cur_d = new Date();
      let d = new Date(even),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();
      const curYear = cur_d.getFullYear();
      year = d.getFullYear();
      const ageYear = Number(curYear) - Number(year);
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      this.date_of_birth = month + '/' + day + '/' + year;
    }

    const newDate = new Date(even);
    console.log('DOB entered: ', newDate);
    const checkAge = this.validationService.validate_dob('common_age', newDate);
    this.message = checkAge.message;
    this.is_valid = checkAge.is_valid;
    const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'DoB'
    });
  }


  checkyearbuilt() {
    debugger
    const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'YearBuilt'
    });
    const todayDate = new Date();
    const currentYear = todayDate.getFullYear();
    if (this.year_built && this.year_built.toString().length == 4) {
      if (Number(this.year_built) > currentYear || Number(this.year_built) < 1800) {
        this.yearBuiltError = 'Year Built value should be between 1800 and ' + currentYear;
        // this.yearbuilt = currentYear;
        const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
        this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
          'event_category': 'Error', 'event_label': 'YearBuilt'
        });
      } else {
        this.yearBuiltError = '';
      }
    } else {
      if (this.year_built != undefined && this.year_built != null && this.year_built != '') {
        this.yearBuiltError = 'Enter Valid Year';
      }

    }

    if (this.roof_updated_year && this.roof_updated_year.toString().length == 4) {
      if (Number(this.year_built) > Number(this.roof_updated_year)) {
        this.roofupdatedyearError = 'The value should be greater than or equal to the Year Built';
      } else if (Number(this.roof_updated_year) > currentYear) {
        this.roofupdatedyearError = 'Roof constructed cannot be greater than ' + currentYear;

      } else {
        this.roofupdatedyearError = '';
      }
    } else {
      if (this.roofupdatedyearError != undefined && this.roofupdatedyearError != null && this.roofupdatedyearError != '') {
        this.roofupdatedyearError = 'Enter Valid Year';
      }

    }
    //this.validateForm();
  }

  checkRoofYear() {

    const todayDate = new Date();
    const currentYear = todayDate.getFullYear();
    if (this.roof_updated_year && this.roof_updated_year.toString().length == 4) {
      if (Number(this.roof_updated_year >= Number(this.year_built))) {
        if (Number(this.roof_updated_year) < this.year_built) {
          this.roof_updated_year = this.year_built;
        }
        else if (Number(this.roof_updated_year) > currentYear) {
          this.roofupdatedyearError = 'Roof constructed cannot be greater than ' + currentYear;

        }
        else {
          this.roofupdatedyearError = '';
          this.yearBuiltError = '';
        }
      }
      else {

        this.roofupdatedyearError = 'The value should be greater than or equal to the Year Built';
      }
    }
    else {
      this.roofupdatedyearError = 'Enter Valid Year';

    }

    if (this.year_built && this.year_built.toString().length == 4) {
      this.yearBuiltError = '';
    }
    else {
      this.yearBuiltError = 'Enter Valid Year';
    }
    //this.validateForm();
  }

  checkArea() {

    if (this.living_size && this.living_size.toString().length >= 2) {
      if (Number(this.living_size) < this.minArea) {
        this.livingSizeError = `Total Living Area cannot be less than ${this.minArea} Sq Ft`;
        this.homeQuotePremium = 0;
      }
      else if (Number(this.living_size) > 10000) {
        this.livingSizeError = 'Total Living Area cannot be greater than 10,000 Sq Ft';
        this.homeQuotePremium = 0;
      }
      else {
        this.livingSizeError = '';

      }
    }
    else {
      if (this.livingSizeError != undefined && this.livingSizeError != null && this.livingSizeError != '') {
        this.livingSizeError = `Total Living Area cannot be less than ${this.minArea} Sq Ft`;
        this.homeQuotePremium = 0;
      }

    }
    const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'SqftArea'
    });
    //this.getHomeQuote();
    //this.validateForm();
  }

  validatedEmail() {
    this.emailerror = '';
    const reg = /^([A-Za-z0-9_+\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
    const emailtest = this.email;
    if (reg.test(emailtest) == false) {
      this.emailerror = 'Please enter a valid email';
    }
   // this.validateForm();
  }

  public async saveupdateclient() {
debugger
    const qid = [];
    for (let i = 1; i < this.propertdetails.length; i++) {
      if (this.propertdetails[i].value == 'yes') {
        qid.push(this.propertdetails[i].sw_questionId);
      }
    }
    this.message = '';
    const reg = /^([A-Za-z0-9_+\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    const emailtest = this.email;
    if (this.email == null || this.email == undefined || this.email == '') {
      this.emailerror = 'This field is required';
      this.loader=false;
    }
    else if (reg.test(emailtest) == false) {
      this.emailerror = 'Please enter a valid email';
      this.loader=false;
    }
    else if (this.state == '' || this.city == '' || this.zip == '' || this.address == '' || this.addresstoshow == '' || this.address == undefined || this.address == null) {
      this.addressError = 'This field is required';
      this.loader=false;
    }
    else if (this.year_built == null || this.year_built == undefined || this.year_built == '' || this.yearBuiltError != '') {
      if (this.yearBuiltError == 'Year Built value should be between 1800 and 2021') {
        this.yearBuiltError = this.yearBuiltError;
        this.loader=false;
      }
      else if (this.yearBuiltError == 'Enter Valid Year') {
        this.yearBuiltError = this.yearBuiltError;
        this.loader=false;
      }
      else {
        this.yearBuiltError = 'This field is required';
        this.loader=false;
      }

    }
    else if (this.living_size == null || this.living_size == undefined || this.living_size == '' || this.livingSizeError != '') {
      if (this.livingSizeError == 'Total Living Area cannot be greater than 10,000 Sq Ft') {
        this.livingSizeError = this.livingSizeError;
        this.loader=false;
      }
      else if (this.livingSizeError == 'Total Living Area cannot be less than 500 Sq Ft') {
        this.livingSizeError = this.livingSizeError;
        this.loader=false;
      }
      else {
        this.livingSizeError = 'This field is required';
        this.loader=false;
      }
    }
    else if (this.roof_updated_year == null || this.roof_updated_year == undefined) {
      this.roofupdatedyearError = 'This field is required';
      this.loader=false;
    }
    else if (this.phone == '' || this.phone == null || this.phone == undefined || this.phoneerror != '') {
      this.phoneerror = 'This field is required';
      this.loader=false;
    }
    else if (!this.home_type.startsWith('DW')) {
      this.message = '<p>Bubble only insures Single Family Homes online. <br><br>Please get in touch with us on (833) 900-BUBB or hello@getmybubble.com</p>';

      const blurOut = document.getElementById('blurout');
      const knockOut = document.getElementById('knockOutModal');

      if (blurOut) {
        blurOut.style.filter = 'blur(10px)';
        blurOut.style.overflowY = 'hidden';
        blurOut.style.position = 'fixed';
      }

      if (knockOut) {
        knockOut.style.display = 'block';
      }
      const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
      const hubSpotData = {
        'email': this.email,
        interest_type: this.interest_type,
        client_id: this.clientId,
        customer_id: customerIdLocal,
        'home_type': this.home_type,
        'dropoff_url': this.dropoffURL,
        'application_stage': 'Policy Denied',
        'home_knock_out_reason': 'Bubble only insures Single Family Homes online',
        'address': this.address,
        'home_landing_page_variant': 'Couple Buying Home',
        'city': this.city,
        'state': this.state,
        'zip': this.zip,
        'full_address': this.address + ',' + this.city + ',' + this.state + ',' + this.zip,
        'lead_source': this.lead_source
      };
      //hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
      if (this.email != null && this.email != undefined && this.email != '') {
        console.log('HS 3');
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      }
      //this.openModal(this.message);
    }
    else if (qid.includes(111)) {
      this.message = 'Bubble only insures full-time Primary residences.  Please get in touch with us on (833) 900-BUBB or hello@getmybubble.com';

      const blurOut = document.getElementById('blurout');
      const knockOut = document.getElementById('knockOutModal');

      if (blurOut) {
        document.getElementById('blurout').style.filter = 'blur(10px)';
        document.getElementById('blurout').style.overflowY = 'hidden';
        document.getElementById('blurout').style.position = 'fixed';
      }

      if (knockOut) {
        document.getElementById('knockOutModal').style.display = 'block';
      }

      const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
      const hubSpotData = {
        'email': this.email,
        interest_type: this.interest_type,
        client_id: this.clientId,
        customer_id: customerIdLocal,
        'dropoff_url': this.dropoffURL,
        'application_stage': 'Policy Denied',
        'home_knock_out_reason': 'Bubble only insures full-time Primary residences',
        'address': this.address,
        'home_landing_page_variant': 'Couple Buying Home',
        'city': this.city,
        'state': this.state,
        'zip': this.zip,
        'full_address': this.address + ',' + this.city + ',' + this.state + ',' + this.zip,
        'lead_source': this.lead_source
      };
      //hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
      if (this.email != null && this.email != undefined && this.email != '') {
        console.log('HS 3');
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      }
    }
    else {
      this.spinner = true;
      localStorage.setItem('bubble-email', this.email);
      localStorage.setItem('bubble-phone', this.phone);
      // Replicating old email id data to new email, if email id is changed
      if (this.prevEmail != this.email) {
        const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);

        const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
        this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
          'event_category': 'Edited', 'event_label': 'Email'
        });
        let hubSpotData = {
          interest_type: this.interest_type,
          client_id: this.clientId,
          customer_id: customerIdLocal,
          application_stage: this.applicationStage,
          'home_landing_page_variant': 'Couple Buying Home',
          'dropoff_url': this.dropoffURL,
          consent_to_contact: true,
          // user_clicked_the_landing_page_button:'Yes'
        };
        hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);

        if (this.email != null && this.email != undefined && this.email != '') {
          console.log('HS 3');

          this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
        }
      }
      const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
      this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
        'event_category': 'ButtonClicked', 'event_label': 'HomeGetQuote'
      });
      this.$gaService.event('Get quote', 'event', 'HomeGetQuote');
      const clientDataJson = {
        'id': this.clientId,
        'country': this.country,
        'bubble_email': this.email,
        'email': this.email,
        'bubble_fname': this.firstname,
        'bubble_lname': this.lastname,
        'address': this.address,
        'city_name': this.city,
        'stateCode': this.state,
        'state_name': this.state,
        'zip_code': this.zip,
        'bubble_phone': this.phone,
        'dob': this.date_of_birth,
        'gender': this.gender,
        'insurancetype': this.interest_type,
        'interest_type': this.interest_type,
        'yearBuilt': this.year_built,
        'livingArea': this.living_size,
        'roofBuiltYear': this.roof_updated_year,
        'fullAddress': this.fullAddress,
        'prefill_insurancetype': this.interest_type,
        'prefill_interest_type': this.interest_type,
        'prefill_yearBuilt': this.year_built,
        'prefill_livingArea': this.living_size,
        'prefill_roofBuiltYear': this.roof_updated_year,
        'prefill_marital_status': this.marital_status,
        'prefill_home_type': this.home_type,
        'prefill_dwelling_use': this.dwelling_use,
        'prefill_number_of_units': this.number_of_units,
        'prefill_lead_form_url': this.lead_form_url,
        'prefill_lead_source_time_stamp': this.lead_source_time_stamp,
        'prefill_newly_acquired_property': this.newly_acquired_property,
        'prefill_number_of_claims': this.number_of_claims,
        'prefill_home_insurance_type': this.home_insurance_type,
        'prefill_credit_rating': this.credit_rating,
        'prefill_bundle_auto_with_home': this.bundle_auto_with_home,
        'prefill_stories': this.stories,
        'swquote_question': qid,
        'roof_type': this.rooftype,
        // "Qunistreet_Email":(this.lead_source)=='Quinstreet_click'
      };
      /////// id mapping
      //const aa = {firstname:"Adsf", lastname:"Ssdfds", clientId:"w2e3"};
      let firstName, lastName;
      const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
      localStorage.setItem('customer_id', customerIdLocal);
      const idMappingJson = {
        client_id: this.clientId,
        customer_id: customerIdLocal,
        email_id: this.email,
        firstname: this.firstname,
        lastname: this.lastname,
        status: this.interest_type,
        interest_type: this.interest_type,
        dob: this.date_of_birth
      };

      this.pc.createIdMapping(idMappingJson);
      let hubSpotData = {
        client_id: this.clientId,
        customer_id: customerIdLocal,
        email: this.email,
        dropoff_url: this.dropoffURL,
        consent_to_contact: true
      };

      hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
      if (this.email != null && this.email != undefined && this.email != '') {
        console.log('HS 4');
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      }

      console.log('Lead:createIdMapping:idMappingJson:', idMappingJson);
      /////// id mapping ends
      this.pc.saveUpdateClientData(clientDataJson).then(data => {

        this.getdata();
      }).catch(error => {
        console.log(error);
      });
    }

  }
  cookupCustomerId(firstname, lastname, clientId) {
    let firstNameLocal, lastNameLocal;
    try { firstNameLocal = firstname[0].toLowerCase(); } catch (error) { firstNameLocal = 'a'; }
    try { lastNameLocal = lastname[0].toLowerCase(); } catch (error) { lastNameLocal = 'b'; }
    return `${firstNameLocal}${lastNameLocal}${clientId}`;
  }



  closeModal() {
    document.getElementById('errorModal').style.display = 'none';
    this.message = '';
    // window.location.href = this.pc.envCred.url;
    const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'Error', 'event_label': 'Popup Closed'
    });
  }

  closeknockOutModal() {
    document.getElementById('blurout').style.filter = 'none';
    document.getElementById('blurout').style.overflowY = 'auto';
    document.getElementById('blurout').style.position = 'unset';
    document.getElementById('knockOutModal').style.display = 'none';
    this.message = '';
    // window.location.href = this.pc.envCred.url;
    const eventOptionsPart1 = { 'path': 'couple-buyingHomehoi', 'page_title': 'couple-buyingHomehoi', 'url': window.location.href };
    this.utilityService.gtagWrapped(eventOptionsPart1, 'event', 'HomeLandingPage', {
      'event_category': 'Error', 'event_label': 'Popup Closed'
    });
    window.location.href = this.pc.envCred.url;
  }

  roofUpdatedSelected(even) {

    this.roofUpdated = even.target.value;
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
    this.selectedRoofUpdated = even.target.value;
    switch (this.roofUpdated) {
      case '6m':
        if (Number(currentMonth) >= 6) {
          this.roof_updated_year = Number(currentYear);
        }
        else {
          this.roof_updated_year = Number(currentYear) - 1;
        }
        break;
      case '5':
        this.roof_updated_year = Number(currentYear) - 5;
        break;
      case '15':
        this.roof_updated_year = Number(currentYear) - 15;
        break;
      case 'yb':
        this.roof_updated_year = this.year_built;
        break;
      case '':
        this.roof_updated_year = '';
        break;
    }
    console.log('roof_updated_year :', this.roof_updated_year);
  }

  public async handleAddressChange(address: Address) {

    this.loader = true;
    await this.parseGoogleAddress(address);
    this.parseAddressLocalAndPatchLocal(address);


  }

  async parseGoogleAddress(address) {
    const google_address = {
      'street_number_ln': '',
      'street_number_sn': '',
      'route_ln': '',
      'route_sn': '',
      'neighborhood_ln': '',
      'neighborhood_sn': '',
      'locality_ln': '',
      'locality_sn': '',
      'administrative_area_level_2_ln': '',
      'administrative_area_level_2_sn': '',
      'administrative_area_level_1_ln': '',
      'administrative_area_level_1_sn': '',
      'country_ln': '',
      'country_sn': '',
      'postal_code_ln': '',
      'postal_code_sn': '',
      'postal_code_suffix_ln': '',
      'postal_code_suffix_sn': '',
      'formatted_address': ''
    };
    const results = [];
    results[0] = address;
    console.log('results address', results, results[0].address_components);
    for (let i = 0; i < results[0].address_components.length; i++) {
      const type = results[0].address_components[i].types[0];
      switch (type) {

        case 'street_number':
          google_address.street_number_ln = results[0].address_components[i].long_name;
          google_address.street_number_sn = results[0].address_components[i].short_name;
          break;
        case 'route':
          google_address.route_ln = results[0].address_components[i].long_name;
          google_address.route_sn = results[0].address_components[i].short_name;
          break;
        case 'neighborhood':
          google_address.neighborhood_ln = results[0].address_components[i].long_name;
          google_address.neighborhood_sn = results[0].address_components[i].short_name;
          // addressJson.Addr1[2] = results[0].address_components[i].short_name;
          break;
        case 'locality':
          google_address.locality_ln = results[0].address_components[i].long_name;
          google_address.locality_sn = results[0].address_components[i].short_name;
          break;
        case 'administrative_area_level_2':
          google_address.administrative_area_level_2_ln = results[0].address_components[i].long_name;
          google_address.administrative_area_level_2_sn = results[0].address_components[i].short_name;
          break;
        case 'administrative_area_level_1':
          google_address.administrative_area_level_1_ln = results[0].address_components[i].long_name;
          google_address.administrative_area_level_1_sn = results[0].address_components[i].short_name;
          break;
        case 'country':
          google_address.country_ln = results[0].address_components[i].long_name;
          google_address.country_sn = results[0].address_components[i].short_name;
          break;
        case 'postal_code':
          google_address.postal_code_ln = results[0].address_components[i].long_name;
          google_address.postal_code_sn = results[0].address_components[i].short_name;
          this.zip = results[0].address_components[i].short_name;
          break;
        case 'postal_code_suffix':
          google_address.postal_code_suffix_ln = results[0].address_components[i].long_name;
          google_address.postal_code_suffix_sn = results[0].address_components[i].short_name;
          break;
      }
    }
    google_address.formatted_address = results[0].formatted_address;
    console.log('google_address', google_address);
    google_address['id'] = this.clientId;
    this.addresscomponent = google_address;
    await this.pc.saveUpdateClientData(this.addresscomponent);
    const quickquote = new pc.Quotes(this.clientId);
    const propertyresponse = quickquote.getSWKnockoutQuestionsByState(this.state, this.year_built);
    console.log('Knockout Questions: ', propertyresponse);
    this.propertdetails = propertyresponse;
    //this.getHomeQuote();
    return true;
  }

  async parseAddressLocalAndPatchLocal(address) {
    debugger
    console.log('parseAddressLocalAndPatchLocal', address.formatted_address, this.clientId);
    this.pc.parseAddressLocalAndPatch(address.formatted_address, this.clientId).then(
      async data => {
        debugger
        console.log('split lenderaddress : ', data);

        const validateStateUrl = `${this.pc.envCred.api2Domain}?data_category=validateZip4LicensedState&dget0=life&dget1=${this.zip}`;
        console.log('validateStateUrl:', validateStateUrl);
        let validateState = await axios.get(validateStateUrl);
        validateState = validateState.data;
        console.log('allowedStates', validateState);

        // if (validateState["dput2"]) {
        //   // data: {dput2: "CA"}
        //   this.state = validateState["dput2"];
        // }

        if (this.addresscomponent['country_sn'] != 'USA' && this.addresscomponent['country_sn'] != 'US') {
          const em = 'Please enter address inside USA.';
          this.addressError = em;
          //this.openModal(em);
          this.addresstoshow = '';
          this.city = '';
          this.state = '';
          this.zip = '';
          this.address = '';
          this.loader = false;

        }
        else if (validateState['dput1']) {
          const allowedStates = validateState['dput1'].filter(item => item['golivelife'] == 'Y');
          console.log('allowedStates', allowedStates);
          let allowedStatesString = '';
          if (allowedStates.length == 1) {
            allowedStatesString = allowedStates[0]['state'];
          } else {
            for (let i = 0; i < allowedStates.length; i++) {
              allowedStatesString += allowedStates[i]['state'] + ', ';
            }
          }
          allowedStatesString = 'We currently serve only in ' + allowedStatesString;
          allowedStatesString = allowedStatesString.replace(/,\s*$/, '.');
          allowedStatesString = allowedStatesString.replace(/,(?=[^,]*$)/, ' and');
          console.log('allowedStatesString', allowedStatesString);
          this.errormessage = allowedStatesString;
          //this.openModal(this.errormessage);
          this.addressError = this.errormessage;
          this.zip = '';

        }
        else if (data.door_number == undefined || data.street == undefined ||
          data.state_code == undefined || data.city_name == undefined || data.zip == undefined) {
          //this.openModal();
          this.addressError = 'The entered home address is not in the correct format. Please re-enter to continue.';
          this.addresstoshow = '';
          this.city = '';
          this.state = '';
          this.zip = '';
          this.address = '';
          this.loader = false;
          this.email.toString;

        }
        else {
          const entity = {
            'fullAddress': address.formatted_address,
            'address': data.door_number + ' ' + data.street, // + " " + data.street_type,
            'state': data.state_code,
            'city': data.city_name,
            'zip': data.zip
          };
          console.log('addres object : ', entity);
          this.address = data.door_number + ' ' + data.street, // + " " + data.street_type;
            this.state = data.state_code;
          this.city = data.city_name;
          this.zip = data.zip;
          this.addresstoshow = address.formatted_address;
          this.loader = false;
          this.invokeBrushForest();
        }
      }
    ).catch(error => {
      this.loader = false;
      console.log('error', error);
    });
  }


  invokeBrushForest() {
    debugger
    if (this.address != '' && this.city != '' && this.state != '' && this.zip != '') {
      this.disable = true;
      //this.loader = true;
      const addressJson = {
        Addr1: this.address,
        City: this.city,
        StateProvCd: this.state,
        PostalCode: this.zip
      };
      pc.BackendService.getbrushForestAPI(JSON.stringify(addressJson), this.clientId).then(
        async (Payload) => {

          console.log('Brushforect api payload :', Payload);
          if (Payload.hasError == true) {
            // let message = Payload.errorMessage ? Payload.errorMessage : "We are currently unable to quote in this area. Please contact (833) 900-BUBB."
            let message;
            message = 'We need some more information to give you a quote. Somebody from Bubble will get in touch with you or you can call us on (833) 900-BUBB or mail us at sales@getmybubble.com.';
            const errorMessage = Payload.errorMessage;
            console.log('Message 0:', errorMessage);
            this.disable = false;
            const sp = errorMessage.split('|');
            if (sp.length > 1) {

              message = 'The entered home address is not in the correct format. Please re-enter to continue.';
            }
            // else {
            //   message = errorMessage;
            // }
            console.log('Message 1:', message);
            await this.pc.saveUpdateClientData(this.addresscomponent).then(data => {
              console.log('data saved successfully', data);
              // this.parseAddressLocalAndPatchLocal(address);
            }).catch(error => {
              console.log(error);
            });
            //this.openModal(message);
            this.loader = false;
            this.addresstoshow = '';
            this.city = '';
            this.state = '';
            this.zip = '';
            this.address = '';
            this.addressError = message;
            this.year_built = '';
            this.living_size = '';

            this.checkyearbuilt();
            this.checkArea();
          }
          else {
            this.addressError = '';
            setTimeout(() => {
              this.pc.getAppData(this.clientId).then(data => {
                debugger
                console.log('getAppData ', data);
                const dataFrom = JSON.parse(data.Payload);
                const clientData = dataFrom.body;
                this.living_size = clientData.livingArea;
                if (this.living_size == '0' || this.living_size == null || this.living_size == undefined) {
                  this.living_size = '';
                }
                this.year_built = clientData.yearBuilt;
                if (this.year_built == '0' || this.year_built == null || this.year_built == undefined) {
                  this.year_built = '';
                }
                this.checkyearbuilt();
                this.checkArea();
                const quickquote = new pc.Quotes(this.clientId);
                const propertyresponse = quickquote.getSWKnockoutQuestionsByState(this.state, this.year_built);
                console.log('Knockout Questions: ', propertyresponse);
                this.propertdetails = propertyresponse;
                this.disable = false;
                // this.loader = false;
                // this.parseAddressLocalAndPatchLocal(address);
              }).catch(error => {
                //this.loader = false;
                this.disable = false;
                console.log(error);
              });
            }, 2000);
          }
        }
      ).catch(error => { console.log(error); });
    }
    else {
      this.addresstoshow = '';
      this.loader = false;
      this.disable = false;
      this.addressError = 'The entered home address is not in the correct format. Please re-enter to continue.';

    }

  }

  invokeBrushForest_onload() {
    debugger
    if (this.address != '' && this.city != '' && this.state != '' && this.zip != '') {
      this.disable = true;
       this.loader = true;
      const addressJson = {
        Addr1: this.address,
        City: this.city,
        StateProvCd: this.state,
        PostalCode: this.zip
      };
      pc.BackendService.getbrushForestAPI(JSON.stringify(addressJson), this.clientId).then(
        async (Payload) => {

          console.log('Brushforect api payload :', Payload);
          if (Payload.hasError == true) {
            // let message = Payload.errorMessage ? Payload.errorMessage : "We are currently unable to quote in this area. Please contact (833) 900-BUBB."
            let message;
            message = 'We need some more information to give you a quote. Somebody from Bubble will get in touch with you or you can call us on (833) 900-BUBB or mail us at sales@getmybubble.com.';
            const errorMessage = Payload.errorMessage;
            console.log('Message 0:', errorMessage);
            this.disable = false;
            const sp = errorMessage.split('|');
            if (sp.length > 1) {

              message = 'The entered home address is not in the correct format. Please re-enter to continue.';
            }
            // else {
            //   message = errorMessage;
            // }
            console.log('Message 1:', message);
            await this.pc.saveUpdateClientData(this.addresscomponent).then(data => {
              console.log('data saved successfully', data);
              // this.parseAddressLocalAndPatchLocal(address);
            }).catch(error => {
              console.log(error);
            });
            //this.openModal(message);
            this.loader = false;
            this.addresstoshow = '';
            this.city = '';
            this.state = '';
            this.zip = '';
            this.address = '';
            this.year_built = '';
            this.living_size = '';
            this.addressError = message;
            this.checkyearbuilt();
            this.checkArea();
          }
          else {
            this.addressError = '';
            setTimeout(() => {
              this.pc.getAppData(this.clientId).then(data => {
                console.log('getAppData ', data);
                const dataFrom = JSON.parse(data.Payload);
                const clientData = dataFrom.body;
                this.living_size = clientData.livingArea;
                if (this.living_size == '0' || this.living_size == null || this.living_size == undefined) {
                  this.living_size = this.living_size_copy;
                }
                this.year_built = clientData.yearBuilt;
                if (this.year_built == '0' || this.year_built == null || this.year_built == undefined) {
                  this.year_built = this.year_built_copy;
                }
                this.checkyearbuilt();
                this.checkArea();
                this.disable = false;
                this.saveupdateclient();
                //this.loader = false;
                // this.parseAddressLocalAndPatchLocal(address);
              }).catch(error => {
                this.disable = false;
                // this.loader = false;
                console.log(error);
              });
            }, 3000);
          }
        }
      ).catch(error => { this.loader = false; this.disable = false; console.log(error); });
    }
    else {
      this.disable = false;
      this.addresstoshow = '';
      this.loader = false;
      this.addressError = 'The address you entered could not be validated. Please enter a full address.';
    }

  }

  checkAddress() {
    if (this.addresstoshow == '') {
      this.addressError = 'Enter a valid Address';
    }
    else {
      this.addressError = '';
    }
  }

  openModal(msg = 'The address you entered could not be validated. Please enter a full address.') {
    console.log('Message 2:', msg);

    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      'home_landing_page_variant': 'Couple Buying Home',
      home_knock_out_reason: msg,
      lead_source: this.lead_source
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });
    const data = {
      head: 'Please Note',
      title: 'Sorry!',
      message: msg,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      console.log('Modal Close Output: ', result);
      this.loader = false;
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
      this.loader = false;
    });
  }


  changetoyes(value) {
    debugger
    if (value == "Yes") {
      this.policyvalue = value;
    }
    else {
      this.policyvalue = value;
    }

  }



}
