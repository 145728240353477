import { Component, OnInit,Output,EventEmitter,Input } from '@angular/core';

@Component({
  selector: 'app-number-input',
  templateUrl: './number-input.component.html',
  styleUrls: ['./number-input.component.css']
})
export class NumberInputComponent implements OnInit {

  @Input() type: number;

  @Output() liabilityLosses = new EventEmitter();
  @Output() waterLosses = new EventEmitter();
  @Output() weatherLosses = new EventEmitter();
  @Output() otherLosses = new EventEmitter();

  losses:any;
  constructor() { }
disable:boolean;
  ngOnInit(): void {
    this.losses=0;
  }

  public Increment(){
    debugger
    if(this.losses>=9){
      this.losses=9;
    }
    else{
      this.losses=Number(this.losses)+1;
      let etty = {
        "Value": this.losses,
      }
      if(this.type===1){
        this.liabilityLosses.emit(etty);
      }
     else if(this.type===2){
      this.waterLosses.emit(etty);
     }
     else if(this.type===3){
      this.weatherLosses.emit(etty);
     }
     else if(this.type===4){
      this.otherLosses.emit(etty);
     }
    } 
  
  }

  public Decrement(){
    if(this.losses==0){
      this.losses=0;
    }
    else{
      this.losses=Number(this.losses)-1;
      let etty = {
        "Value": this.losses,
      }
      if(this.type===1){
        this.liabilityLosses.emit(etty);
      }
     else if(this.type===2){
      this.waterLosses.emit(etty);
     }
     else if(this.type===3){
      this.weatherLosses.emit(etty);
     }
     else if(this.type===4){
      this.otherLosses.emit(etty);
     }
    } 
  
  }

}
