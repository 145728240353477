import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormGroupDirective, FormBuilder, Validators } from '@angular/forms';

import { QuestionModel } from '../../models/question.model';
import { AnswerValueModel } from '../../models/answer.value.model';
import { QuestionC3ValidateModel } from '../../models/question.c3.validate.model';;

@Component({
  selector: 'app-label-subtitle',
  templateUrl: './label-subtitle.component.html',
  styleUrls: ['./label-subtitle.component.css'],
})
export class LabelSubTitleComponent implements OnInit {
  @Input()
  public childQuestion!: QuestionModel;

  @Input()
  public index: number | undefined;

  @Input()
  public subFormGroupName!: string;

  childForm!: FormGroup;
  iconFound = true;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    debugger
    console.log('subtitlechildQuestion', this.childQuestion);
    this.childForm = this.rootFormGroup.control.get(
      this.subFormGroupName
    ) as FormGroup;
    const _answer_category = this.childForm.value['answer_category'];
    if (_answer_category != '3') {
      this.disableFormGroup(this.childForm);
      this.enableFormGroup(this.childForm);
    } else {
      this.disableOnlyChildFormGroup(this.childForm);
      this.enableFormGroupCategory3(this.childForm);
    }
    this.childForm.updateValueAndValidity();
  }

  changeValue(value: number | string) {
    this.disableFormGroup(this.childForm);
    this.enableFormGroup(this.childForm);
  }

  disableFormGroup(form: FormGroup) {
    Object.keys(form.controls).forEach((ctrlName) => {
      if (form.get(ctrlName) instanceof FormGroup) {
        const subFormGroup = form.get(ctrlName) as FormGroup;
        const _question_id = subFormGroup.value['question_id'];
        subFormGroup.controls['answer_value_' + _question_id].setValue(null);
        subFormGroup.controls['answer_value_' + _question_id].disable();
        this.disableFormGroup(subFormGroup);
      }
    });
  }

  enableFormGroup(form: FormGroup) {
    Object.keys(form.controls).forEach((ctrlName) => {
      const _parent_question_id = form.value['question_id'];
      const _parent_question_value =
        form.value['answer_value_' + _parent_question_id];
      if (form.get(ctrlName) instanceof FormGroup) {
        const subFormGroup = form.get(ctrlName) as FormGroup;
        const _applicable_parent_answer =
          subFormGroup.value['applicable_parent_answer'];
        const _question_id = subFormGroup.value['question_id'];
        if (
          this.findRangeOption(
            _parent_question_value,
            _applicable_parent_answer
          )
        ) {
          subFormGroup.controls['answer_value_' + _question_id].setValue(
            subFormGroup.controls['old_answer_value'].value
          );
          subFormGroup.controls['answer_value_' + _question_id].enable();
        }
        this.enableFormGroup(subFormGroup);
      }
    });
  }

  handleMissingImage(event: any) {
    this.iconFound = false;
    // console.log('image not found');
  }

  findRangeOption(answer_option_id: number, range: any): boolean {
    if (range) {
      let _range_array: Array<string> = range
        .replace('[', '')
        .replace(']', '')
        .split(',');
      _range_array = _range_array.map((value) => value.trim());
      return _range_array?.indexOf(answer_option_id?.toString()) > -1;
    }

  }


  changeValueCategory3(value: AnswerValueModel) {
    debugger;
    if (value.last_key && value.checked == true) {
      this.disableFormGroup(value.form_group);
      this.validateRequiredCategory3(value, true);
    } else if (value.last_key && value.checked == false) {
      this.enableFormGroupCategory3(value.form_group, value.last_key && value.checked == false);
      this.validateRequiredCategory3(value, false);
    } else {
      const _form_group = this.getFormGroup(this.childForm, value.answer_option_id);
      if (_form_group != null) {
        if (!value.checked) this.disableFormGroup(_form_group);
        if (value.checked) this.enableFormGroupCategory3(_form_group);
      }
      this.validateRequiredCategory3(value);
    }
  }

  validateRequiredCategory3(value: AnswerValueModel, isNonOfAbove = false) {
    debugger;
    let _parent_question_id = null;

    const groupWiseCount: Array<QuestionC3ValidateModel> = new Array<QuestionC3ValidateModel>();
    this.groupWiseCount(this.childForm, groupWiseCount, value);

    if (value.form_group.get('super_parent_id')?.value != undefined) {
      _parent_question_id = value.form_group.get('super_parent_id')?.value;
    } else if (value.form_group.get('super_parent_id')?.value == undefined) {
      _parent_question_id = this.childForm.get('question_id')?.value;
    } else if (isNonOfAbove) {
      _parent_question_id = this.childForm.get('question_id')?.value;
    }
    const _groupValid: boolean = this.getGroupValid(groupWiseCount);
    let valid;
    console.log('selecedChildCount -> ', groupWiseCount, _groupValid, isNonOfAbove);
    // if (value.answer_option_id == 370361) {
    //   if (value.form_group.value.question_370361.answer_value_370361) {
    //     valid = true;
    //   }
    //   else {
    //     valid = false
    //   }
    // }
    this.childForm.get('answer_value_' + _parent_question_id)?.setValue((isNonOfAbove ? 1 : (_groupValid ? 1 : null)));
    this.childForm.updateValueAndValidity();

  }

  groupWiseCount(form: FormGroup, count: Array<QuestionC3ValidateModel>, answerValue: AnswerValueModel, isChild = false) {

    if (form != undefined) {
      Object.keys(form.controls).map(ctrlName => {
        if (form.get(ctrlName) instanceof FormGroup) {
          let _selfCheck = 0;
          const _subFormGroup = form.get(ctrlName) as FormGroup;
          const _question_id: number = _subFormGroup.value['question_id'] as number;
          const _has_child: boolean = _subFormGroup.value['has_child'] as boolean;
          const _answer_category: any = _subFormGroup.value['answer_category'] as any;
          let _answer_value: any;
          if (_subFormGroup.get('answer_value_' + _question_id)?.enabled == true) _answer_value = _subFormGroup.get('answer_value_' + _question_id)?.value;
          if (_answer_value == true || (_answer_value === '0' || _answer_value === '1')) _selfCheck = 1;
          const groupChildCount = { value: 0, minValue: 0 };
          if (_has_child) this.checkRequiredCategory3(_subFormGroup, groupChildCount, answerValue, true);
          if (!_has_child && _selfCheck == 1) groupChildCount.value = 1;
          if (_selfCheck == 1) groupChildCount.minValue = this.howManyChildShowOnGroup(_subFormGroup);
          if (_selfCheck == 1 && groupChildCount.minValue == 0) groupChildCount.value = 1;
          count.push(new QuestionC3ValidateModel(ctrlName, _selfCheck, groupChildCount.value, groupChildCount.minValue));
          if (_has_child && _answer_category == null||_answer_category==2) this.groupWiseCount(_subFormGroup, count, answerValue, true);
          if (_has_child && _answer_category == 3) this.subGroupWiseCount(_subFormGroup, count);
        }
      });
    }
  }

  subGroupWiseCount(form: FormGroup, count: Array<QuestionC3ValidateModel>) {
    Object.keys(form.controls).map(ctrlName => {
      if (form.get(ctrlName) instanceof FormGroup) {
        const _subFormGroup = form.get(ctrlName) as FormGroup;
        const _has_child: boolean = _subFormGroup.value['has_child'] as boolean;
        if (_has_child == true) {
          const _question_id: number = _subFormGroup.value['question_id'] as number;
          const _answer_value: boolean = _subFormGroup.value['answer_value_' + _question_id] as boolean;
          let _selfCheck = 0;
          if (_answer_value == true) _selfCheck = 1;
          const subFormGroup = this.getFirstFormGroup(_subFormGroup);
          const _sub_group_question_id: number = subFormGroup?.value['question_id'] as number;
          const _sub_group_answer_value: string = subFormGroup?.value['answer_value_' + _sub_group_question_id] as string;
          let _is_text_value = 0;
          if (_sub_group_answer_value?.length > 0) _is_text_value = 1;
          count.push(new QuestionC3ValidateModel('question_' + _sub_group_question_id, _selfCheck, _is_text_value, _selfCheck));
        }
      }
    });
  }

  getFirstFormGroup(form: FormGroup): FormGroup | null {
    let _form: FormGroup | null = null;
    Object.keys(form.controls).map(ctrlName => {
      if (form.get(ctrlName) instanceof FormGroup) _form = form.get(ctrlName) as FormGroup;
    });
    return _form;
  }

  checkRequiredCategory3(form: FormGroup, selectedCount: { value: number, minValue: number }, answerValue: AnswerValueModel, isNested = false) {

    if (form != undefined) {
      Object.keys(form.controls).map(ctrlName => {
        if (form.get(ctrlName) instanceof FormGroup) {
          const _subFormGroup = form.get(ctrlName) as FormGroup;
          const _question_id: number = _subFormGroup.value['question_id'] as number;
          const _answer_category: any = _subFormGroup.value['answer_category'] as any;
          let _answer_value: any;
          if (_subFormGroup.get('answer_value_' + _question_id)?.enabled == true) _answer_value = _subFormGroup.get('answer_value_' + _question_id)?.value;
          if (_answer_category == null && (_answer_value == true || (_answer_value === '0' || _answer_value === '1'))) selectedCount.value++;
          if (_answer_category == 1 && (_answer_value === '0' || _answer_value === '1')) selectedCount.value++;
          if (_answer_category == 4 && _answer_value) selectedCount.value++;
          if (isNested) this.checkRequiredCategory3(_subFormGroup, selectedCount, answerValue, isNested);
        }
      });
    }
  }

  howManyChildFormGroup(form: FormGroup): number {
    let _form_group_count = 0;
    Object.keys(form.controls).map(ctrlName => {
      if (form.get(ctrlName) instanceof FormGroup) _form_group_count++;
    });
    return _form_group_count;
  }

  howManyChildShowOnGroup(form: FormGroup): number {
    const _question_id: number = form.value['question_id'] as number;
    const _answer_value: any = form.value['answer_value_' + _question_id] as any;
    const _answer_category: any = form.value['answer_category'] as any;
    let _child_open_count = 0;

    Object.keys(form.controls).map(ctrlName => {
      if (form.get(ctrlName) instanceof FormGroup) {
        const _subFormGroup = form.get(ctrlName) as FormGroup;
        const _applicable_parent_answer: any = _subFormGroup.value['applicable_parent_answer'] as any;
        if (_answer_category != 3 && _answer_value == _applicable_parent_answer) _child_open_count++;
        if (_answer_category == 3) _child_open_count = 1;
      }
    });
    return _child_open_count;
  }

  getGroupValid(count: Array<QuestionC3ValidateModel>): boolean {
    let result = true;
    let result_allzero = true;
    for (const arr of count) {
      let current_result = true;
      if (arr.value == 0 && arr.groupCount == 0) {
        current_result = true;
      } else {
        if (arr.minGroupCount > 0) {
          current_result = arr.value > 0 ? (arr.groupCount >= arr.minGroupCount ? true : false) : true;
        } else {
          current_result = arr.value > 0 ? (arr.groupCount > 0 ? true : false) : true;
        }
        result_allzero = false;
      }
      result = result && current_result;
    }
   // return result_allzero || count.filter(x => x.group == 'question_377131' && x.groupCount == 1).length > 0 ? false : result;
   return result_allzero ? false : result;
  }


  disableOnlyChildFormGroup(form: FormGroup) {
    if (form != undefined) {
      Object.keys(form.controls).forEach(ctrlName => {
        if (form.get(ctrlName) instanceof FormGroup) {
          const subFormGroup = form.get(ctrlName) as FormGroup;
          this.disableFormGroup(subFormGroup);
        }
      });
    }
  }





  enableFormGroupCategory3(form: FormGroup, last_key?: any, value?: any) {
    debugger;
    if (form != undefined) {
      Object.keys(form.controls).forEach(ctrlName => {
        if (form.get(ctrlName) instanceof FormGroup) {
          const subFormGroup = form.get(ctrlName) as FormGroup;
          const _question_id = subFormGroup.value['question_id'];
          const _answer_category = subFormGroup.value['answer_category'];
          if (_answer_category != 3) {
            last_key ? subFormGroup.controls['old_answer_value'].setValue(value) : 0;
            subFormGroup.controls['answer_value_' + _question_id].setValue(subFormGroup.controls['old_answer_value'].value);
            subFormGroup.controls['answer_value_' + _question_id].enable();
          }
          this.enableFormGroupCategory3(subFormGroup);
        }
      });
    }
  }

  getFormGroup(
    form: FormGroup,
    question_id: number | string | undefined,
    is_parent = false,
    is_nested_search = false): FormGroup | null {
    let returnForm!: FormGroup | null;

    Object.keys(form.controls).every((ctrlName: string) => {
      if (form.get(ctrlName) instanceof FormGroup) {
        const subFormGroup = form.get(ctrlName) as FormGroup;
        const _question_id = subFormGroup.value['question_id'];
        if (_question_id == question_id) {
          returnForm = subFormGroup;
          if (is_parent) returnForm = form;
          is_nested_search = false;
          return false;
        } else if (is_nested_search) {
          returnForm = this.getFormGroup(subFormGroup, question_id, is_parent, is_nested_search);
        }
      }
      return returnForm != null ? false : true;
    });
    return returnForm;
  }


}
