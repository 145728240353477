import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { TrackerService } from 'src/app/shared/tracker.service';
@Component({
  selector: 'app-homelifeappflows',
  templateUrl: './homelifeappflows.component.html',
  styleUrls: ['./homelifeappflows.component.css']
})
export class HomelifeappflowsComponent implements OnInit {
  sub: Subscription;
  percent: number;
  trackerType: string;
  insuranceType: string;
  trackingTypeSub: Subscription;
  lifejoueneystart;
  insurancetypeText: any;
  applicationComplete: any;
  orangeCircle: boolean;
  orangeLine: boolean;
  grayCircle: boolean;
  grayLine: boolean;
  percentage: any;
  showProgress: boolean;
  constructor(private tracker: TrackerService) { }

  ngOnInit(): void {
    debugger;

    this.lifejoueneystart = localStorage.getItem('bubble-lifejourneystart');
    this.insurancetypeText = localStorage.getItem('insurancetypeText');
    this.applicationComplete = localStorage.getItem('applicationComplete');


    this.sub = this.tracker.getProgressStatus().subscribe(percent => {
      debugger;
      this.percent = percent;
      localStorage.setItem('trackerPercent', ''+this.percent );
    });
    const insuranceType = localStorage.getItem('insuranceType');


    switch (insuranceType) {
    case 'homeLife':
      console.log('insuranceType', insuranceType);
      this.insuranceType = 'Home + Life Insurance';
      break;
    case 'home':
      console.log('insuranceType', insuranceType);
      this.insuranceType = 'Home Insurance';
      break;
    case 'life':
      console.log('insuranceType', insuranceType);
      this.insuranceType = 'Life Insurance';
      // this.percent = Number(localStorage.getItem('Percentage'));
      break;
    default:
      break;
    }

    document.title = this.insuranceType + ' powered By Bubble';

    this.trackingTypeSub = this.tracker.getTrackingType().subscribe(type => {
      this.trackerType = type;
      console.log(this.trackerType == 'life');
      console.log('trackertype : ', this.trackerType);
    });
  }

  ngAfterContentChecked() {
    if (this.percent == 102) {
      this.orangeCircle = true;
      this.orangeLine = true;
      this.grayCircle = false;
      this.grayLine = false;
    }
    else {
      this.orangeCircle = false;
      this.orangeLine = false;
      this.grayCircle = true;
      this.grayLine = true;
    }
    this.percentage = this.percent < 100 ? this.percent < 50 ? 0 : (this.percent - 50) * 2 : 100;
    this.percentage = this.percentage + '%';
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
    this.trackingTypeSub.unsubscribe();
  }

}
