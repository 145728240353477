import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

@Component({
  selector: 'app-sw4pt20',
  templateUrl: './sw4pt20.component.html',
  styleUrls: ['./sw4pt20.component.css']
})
export class Sw4pt20Component implements OnInit {
  showoptions: any;

  @Output() nextquestion13 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  existingData: any;
  loader: boolean;
  sw_solidFuelburner: any;
  sw_solidFuelburnerProfessionallyInstalled: any;
  sw_solidFuelburnerPrimarySourceOfHeat: any;

  constructor(private $gaService: GoogleAnalyticsService,private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    this.loader = true;
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt20')[0];
    console.log('sw questions app-sw4pt20 : ', this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.stillwater;
      console.log('data from getAppData sw : ', this.existingData);

      if (this.existingData['sw_solidFuelburner'] == 'yes') {
        this.questions.radio_options[0].active = true;
        this.questions.radio_options[1].active = false;
        this.showoptions = '1';
        this.sw_solidFuelburner = 'yes';
        if (this.existingData['sw_solidFuelburnerProfessionallyInstalled'] == 'yes') {
          this.questions.sub_questions_1[0].active = true;
          this.questions.sub_questions_1[1].active = false;
          this.disable = false;
          this.sw_solidFuelburnerProfessionallyInstalled = 'yes';
          if (this.existingData['sw_solidFuelburnerPrimarySourceOfHeat'] == 'yes') {
            this.questions.sub_questions_2[0].active = true;
            this.questions.sub_questions_2[1].active = false;
            this.disable = false;
            this.sw_solidFuelburnerPrimarySourceOfHeat = 'yes';
          }
          else if (this.existingData['sw_solidFuelburnerPrimarySourceOfHeat'] == 'no'){
            this.questions.sub_questions_2[0].active = false;
            this.questions.sub_questions_2[1].active = true;
            this.disable = false;
            this.sw_solidFuelburnerPrimarySourceOfHeat = 'no';
          }
          else {
            this.questions.sub_questions_2[0].active = false;
            this.questions.sub_questions_2[1].active = false;
            this.disable = true;
            this.sw_solidFuelburnerPrimarySourceOfHeat = '';
          }
        }
        else if (this.existingData['sw_solidFuelburnerProfessionallyInstalled'] == 'no'){
          this.questions.sub_questions_1[0].active = false;
          this.questions.sub_questions_1[1].active = true;
          this.disable = false;
          this.sw_solidFuelburnerProfessionallyInstalled = 'no';
        }
        else {
          this.questions.sub_questions_1[0].active = false;
          this.questions.sub_questions_1[1].active = false;
          this.disable = true;
          this.sw_solidFuelburnerProfessionallyInstalled = '';
        }
      }
      else if (this.existingData['sw_solidFuelburner'] == 'no'){
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = true;
        this.disable = false;
        this.sw_solidFuelburner = 'no';
      }
      else {
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = false;
        this.disable = true;
        this.sw_solidFuelburner = '';
      }
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });

    // if (localStorage.getItem('sw_solidFuelburning') == 'yes') {
    //   this.questions.radio_options[0].active = true;
    //   this.questions.radio_options[1].active = false;
    //   this.showoptions = '1';
    //   if (localStorage.getItem('sw_solidFuelburningPrimarySourceOfHeat') == 'yes') {
    //     this.questions.sub_questions[0].active = true;
    //     this.questions.sub_questions[1].active = false;
    //     this.disable = false;
    //   }
    //   else if (localStorage.getItem('sw_solidFuelburningPrimarySourceOfHeat') == 'no'){
    //     this.questions.sub_questions[0].active = false;
    //     this.questions.sub_questions[1].active = true;
    //     this.disable = false;
    //   }
    //   else {
    //     this.questions.sub_questions[0].active = false;
    //     this.questions.sub_questions[1].active = false;
    //     this.disable = true;
    //   }
    // }
    // else if (localStorage.getItem('sw_solidFuelburning') == 'no'){
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = true;
    //   this.disable = false;
    // }
    // else {
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = false;
    //   this.disable = true;
    // }

  }

  onYes() {
    debugger;
    this.questions.radio_options[0].active = true;
    this.questions.radio_options[1].active = false;
    //localStorage.setItem('sw_solidFuelburning', 'yes');
    this.showoptions = '1';
    this.sw_solidFuelburner = 'yes';
    this.sw_solidFuelburnerProfessionallyInstalled = '';
    this.sw_solidFuelburnerPrimarySourceOfHeat = '';
    this.questions.sub_questions_1[0].active = false;
    this.questions.sub_questions_1[1].active = false;
    this.questions.sub_questions_2[0].active = false;
    this.questions.sub_questions_2[1].active = false;
    if (this.sw_solidFuelburnerProfessionallyInstalled == '' || this.sw_solidFuelburnerProfessionallyInstalled == null|| this.sw_solidFuelburnerProfessionallyInstalled == undefined) {
      this.disable = true;
    }
    else {
      this.disable = false;
    }
    // if (localStorage.getItem('sw_solidFuelburningPrimarySourceOfHeat') == 'yes') {
    //   this.questions.sub_questions[0].active = true;
    //   this.questions.sub_questions[1].active = false;
    //   this.disable = false;
    // }
    // else if (localStorage.getItem('sw_solidFuelburningPrimarySourceOfHeat') == 'no'){
    //   this.questions.sub_questions[0].active = false;
    //   this.questions.sub_questions[1].active = true;
    //   this.disable = false;
    // }
    // else {
    //   this.questions.sub_questions[0].active = false;
    //   this.questions.sub_questions[1].active = false;
    //   this.disable = true;
    // }
  }

  onNo() {
    debugger;
    this.questions.radio_options[0].active = false;
    this.questions.radio_options[1].active = true;
    //localStorage.setItem('sw_solidFuelburning', 'no');
    this.sw_solidFuelburner = 'no';
    this.sw_solidFuelburnerProfessionallyInstalled = '';
    this.sw_solidFuelburnerPrimarySourceOfHeat = '';
    this.showoptions = '0';
    this.disable = false;
    this.questions.sub_questions_1[0].active = false;
    this.questions.sub_questions_1[1].active = false;
    this.questions.sub_questions_2[0].active = false;
    this.questions.sub_questions_2[1].active = false;
  }

  onSubYes() {
    this.questions.sub_questions_1[0].active = true;
    this.questions.sub_questions_1[1].active = false;
    //localStorage.setItem('sw_solidFuelburningPrimarySourceOfHeat', 'yes');
    this.sw_solidFuelburnerProfessionallyInstalled = 'yes';
    this.sw_solidFuelburnerPrimarySourceOfHeat = '';
    if (this.sw_solidFuelburnerPrimarySourceOfHeat == '' || this.sw_solidFuelburnerPrimarySourceOfHeat == null|| this.sw_solidFuelburnerPrimarySourceOfHeat == undefined) {
      this.disable = true;
    }
    else {
      this.disable = false;
    }
    this.questions.sub_questions_2[0].active = false;
    this.questions.sub_questions_2[1].active = false;
  }

  onSubNo() {
    this.questions.sub_questions_1[0].active = false;
    this.questions.sub_questions_1[1].active = true;
    //localStorage.setItem('sw_solidFuelburningPrimarySourceOfHeat', 'no');
    this.sw_solidFuelburnerProfessionallyInstalled = 'no';
    this.disable = false;
    this.sw_solidFuelburnerPrimarySourceOfHeat = '';
    this.questions.sub_questions_2[0].active = false;
    this.questions.sub_questions_2[1].active = false;
  }

  onSubSubYes() {
    this.questions.sub_questions_2[0].active = true;
    this.questions.sub_questions_2[1].active = false;
    //localStorage.setItem('sw_solidFuelburningPrimarySourceOfHeat', 'yes');
    this.sw_solidFuelburnerPrimarySourceOfHeat = 'yes';
    this.disable = false;
  }

  onSubSubNo() {
    this.questions.sub_questions_2[0].active = false;
    this.questions.sub_questions_2[1].active = true;
    //localStorage.setItem('sw_solidFuelburningPrimarySourceOfHeat', 'no');
    this.sw_solidFuelburnerPrimarySourceOfHeat = 'no';
    this.disable = false;
  }

  next() {
    this.loader = true;
    let knockout = 0;
    if (this.sw_solidFuelburner == 'yes') {
      knockout = 1;
    }
    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };
    // json.stillwater['sw_solidFuelburning'] = localStorage.getItem('sw_solidFuelburning');
    // json.stillwater['sw_solidFuelburningPrimarySourceOfHeat'] = localStorage.getItem('sw_solidFuelburningPrimarySourceOfHeat');
    json.stillwater['sw_solidFuelburner'] = this.sw_solidFuelburner;
    json.stillwater['sw_solidFuelburnerProfessionallyInstalled'] = this.sw_solidFuelburnerProfessionallyInstalled;
    json.stillwater['sw_solidFuelburnerPrimarySourceOfHeat'] = this.sw_solidFuelburnerPrimarySourceOfHeat;
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(data => {
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0,
        'knockout': knockout
      };
      this.nextquestion13.emit(etty);
    }).catch(error => {
      console.log(error);
    });
    this.$gaService.event('Next', 'event', 'HomeAppFireplacesInfo');

  }

  previous() {
    this.loader = true;
    if(localStorage.getItem('RemoveKOData')=='1'){
      const json = {
        'id': this.clientId,
        stillwater: this.existingData
      };
      json.stillwater['sw_solidFuelburner'] = '';
      json.stillwater['sw_solidFuelburnerProfessionallyInstalled'] = '';
      json.stillwater['sw_solidFuelburnerPrimarySourceOfHeat'] = '';
      this.pc.saveUpdateClientData(json).then(data => {
        this.loader = false;
        const etty = {
          'next': 0,
          'previous': 1
        };
        this.nextquestion13.emit(etty);
      }).catch(error => {
        console.log(error);
      });
    }
    else {
      this.loader = false;
      const etty = {
        'next': 0,
        'previous': 1,
      };
      this.nextquestion13.emit(etty);
    }
  }

  public GetOptions(id){
    this.showoptions=id;
  }

}
