import { Component } from '@angular/core';
import { PlatformLocation } from '@angular/common';
import { LocationStrategy } from '@angular/common';
import { ProcessCacheService } from '../app/shared/process-cache.service';
import { PrefillserviceService } from '../app/shared/prefillservice.service';
var bitubil = require('./../../package.json');
import * as LogRocket from 'logrocket';
console.log("bit-uibl-data-driver: ", bitubil.dependencies['bit-uibl-data-driver']);
//Temp solution to load AWS configs in actual flow as library could not read the accessKeyId
// const criticalInputLocal = require("./../../critical-input.json");
// console.log("criticalInputLocal: ", criticalInputLocal);
/*
const AWS = require("aws-sdk");
// console.log("CriticalInput Local : ", criticalInputLocal);
console.log("criticalInputLocal.accessKeyId: ", criticalInputLocal.accessKeyId);
AWS.config.credentials = new AWS.Credentials(criticalInputLocal.accessKeyId, criticalInputLocal.secretAccessKey);
console.log("From local AWS.config.credentials: ", AWS.config.credentials);
*/
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  // debugger;
  constructor(location: PlatformLocation,
    private locationStrategy: LocationStrategy,
    private pc: ProcessCacheService,
    private ps: PrefillserviceService) {
      // LogRocket.init('er6fto/test');
    const hash_code = localStorage.getItem( 'hash_code');
    const count = localStorage.getItem('ping_count');
   // LogRocket.init('er6fto/test');
    console.log( 'new email created', `https://api.getmybubble.com/log?url=${'hubspoturl'}&hash_code=${hash_code}&count=${count}&page_load=${count}` );
    fetch(`https://api.getmybubble.com/log?url=${'hubspoturl'}&hash_code=${hash_code}&count=${count}&page_load=${count}`);

    this.pc.getEnvCred();


  }
  title = 'Home+Life Flow';



  // @HostListener('contextmenu', ['$event'])
  // onRightClick(event) {
  //   event.preventDefault();
  // }

  // @HostListener('window:popstate', ['$event'])
  // onPopState(event) {

  //   alert('Back button pressed');
  // }

  // @HostListener('window:unload', ['$event'])
  // unloadHandler(event) {

  //   alert('unloadHandler called');
  // }

  // @HostListener('window:beforeunload', ['$event'])
  // beforeUnloadHandler(event) {

  //   alert('beforeUnloadHandler called');
  // }
}
