import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
const pc = require('bit-uibl-data-driver');

@Component({
  selector: 'app-sbli-congrats',
  templateUrl: './sbli-congrats.component.html',
  styleUrls: ['./sbli-congrats.component.css']
})
export class SbliCongratsComponent implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  uniqueId: any;
  clientId: any;
  policyNumber: any;
  emailId: string;
  existingData: any;
  interest_type: any;
  constructor(private pc: ProcessCacheService,
    private modalService: NgbModal,
    private $gaService: GoogleAnalyticsService,
    private activatedroute: ActivatedRoute,
    private utilityService: UtilityFunctionsService) { }
  showLoader = true;

  ngOnInit(): void {
    this.$gaService.event('SBLI LifeApplication Policy Number', 'event', 'SBLI-LifeApplication-Policy-Number');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Policy Number',
      path: 'lifeCarrierTwo',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Policy Number', 'event', 'SBLI-LifeApplication-Policy-Number');
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.interest_type = params['insuranceType'];
    }
    );
    this.showLoader = true;
    this.emailId = localStorage.getItem('bubble-email');
    const quickquote = new pc.Quotes(this.uniqueId);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.SBLI;
      const pn = this.existingData['sbli_PolicyNumber'];
      if (pn == null || pn == undefined || pn == '') {

        quickquote.sbligetPOLICYNUMBER().then(response => {
          debugger;
          console.log('sbligetPOLICYNUMBER res', response);
          if (response.sbliError) {
            this.openModal(response.msgText, 'Sorry!');
          }
          else {
            this.pc.lifesummary({ 'policy_number': this.policyNumber, 'policy_issuance': 'yes' });
            this.policyNumber = response.policyNumber;

            localStorage.setItem('sbli_PolicyNumber', this.policyNumber);
            const json = {
              'id': this.clientId,
              SBLI: this.existingData,
              'sbli_PolicyNumber': this.policyNumber
            };
            json.SBLI['sbli_PolicyNumber'] = this.policyNumber;
            json['sbli_PolicyNumber'] = this.policyNumber;
            this.pc.saveUpdateClientData(json).then(data => {
              this.showLoader = false;
            }).catch(error => {
              console.log(error);
            });

            const hubSpotData = {
              email: localStorage.getItem('bubble-email'),
              life_policy_number: this.policyNumber,
              application_stage: 'Application complete'
            };
            this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

            /////// id mapping
            const customerIdLocal = localStorage.getItem('customer_id');
            const idMappingJson = {
              client_id: this.clientId,
              customer_id: customerIdLocal,
              email_id: this.emailId,
              status: this.interest_type,
              interest_type: this.interest_type,
              sbli_policy_id: this.policyNumber
            };
            this.pc.createIdMapping(idMappingJson);
            console.log('Lead:createIdMapping:idMappingJson:', idMappingJson);
            /////// id mapping ends


            quickquote.sbliupdateSESSION(100).then(response => {
              debugger;
              console.log('sbliupdateSESSION res', response);
            })
              .catch(error => {
                console.log('sbliupdateSESSION Error:', error);
              });


            const updateJson = {
              appCompletedStatus: true
            };
            this.checkforResumeInit(updateJson, 'update');
          }
        })
          .catch(error => {
            console.log('sbligetPOLICYNUMBER Error:', error);
            this.showLoader = false;
          });
      }
      else {
        this.policyNumber = pn;
        this.showLoader = false;
        const hubSpotData = {
          email: localStorage.getItem('bubble-email'),
          application_stage: 'Application complete'
        };
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
        quickquote.sbliupdateSESSION(100).then(response => {
          debugger;
          console.log('sbliupdateSESSION res', response);
        })
          .catch(error => {
            console.log('sbliupdateSESSION Error:', error);
          });
      }

    }).catch(error => {
      console.log(error);
    });
  }

  openModal(msg, title) {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    const data = {
      head: 'Please Note',
      title: title,
      message: msg,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  // next() {
  //   let etty = {
  //     "next": 1,
  //     "previous": 0,
  //   };
  //   this.changeQuestion.emit(etty);
  // }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

  async checkforResumeInit(inputJson, type) {
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }


}
