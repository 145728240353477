import { Component, OnInit } from '@angular/core';
import{ProfileDataHandlerService} from '../../shared/profile-data-handler.service'
@Component({
  selector: 'app-home-insurance-card',
  templateUrl: './home-insurance-card.component.html',
  styleUrls: ['./home-insurance-card.component.css']
})
export class HomeInsuranceCardComponent implements OnInit {

  constructor(public pdserive:ProfileDataHandlerService) { }

  ngOnInit(): void {
    debugger
   let test= this.pdserive.GetPolicyData();
  }

}
