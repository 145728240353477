import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from '../../../../environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const pc = require('bit-uibl-data-driver');
@Component({
  selector: 'app-sw4pt40',
  templateUrl: './sw4pt40.component.html',
  styleUrls: ['./sw4pt40.component.css']
})
export class Sw4pt40Component implements OnInit {

  @Output() nextquestion23 = new EventEmitter();
  @Output() preBindKnockOut = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  consumerReports: number;
  underWriting: number;
  agree: number;
  prebindData: any;
  loader: boolean;
  pdfSrc: any;
  private _sanitizer: any;
  prebindDataLabels: any;
  errorMessage: any;

  constructor(private $gaService: GoogleAnalyticsService, private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private modalService: NgbModal,
    public sanitizer: DomSanitizer,
    private activatedroute: ActivatedRoute) { }

  async ngOnInit() {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    // this.uniqueId = 'nkowu4-285-Bryant-St-Palo-Alto-CA-94301';
    // this.clientId = 'nkowu4';
    //this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://qua.stillwaterinsurance.com/edocs/view/kAyaoxWJkMM7HQzZiWAF5h3EQfV9TPW6DOGdFfK2N2m5koRcCCkpKxvjchQaCZeGWZbHZO2p9mPIhIw1SveTDnuqplTpR_23WVgKa_i69Q28Dv4VQA2IBkXWrCzLXNxmb8Ud0S99Ptc9xOQdB_AwR1KACXS6uoL-");
    this.disable = true;
    this.consumerReports = 0;
    this.underWriting = 0;
    this.agree = 0;
    //this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl("https://qua.stillwaterinsurance.com/edocs/view/kAyaoxWJkMM7HQzZiWAF5h3EQfV9TPW6DOGdFfK2N2m5koRcCCkpKxvjchQaCZeGWZbHZO2p9mPIhIw1SveTDnuqplTpR_23WVgKa_i69Q28Dv4VQA2IBkXWrCzLXNxmb8Ud0S99Ptc9xOQdB_AwR1KACXS6uoL-");
    this.loader = true;
    const quickquote = new pc.Quotes(this.uniqueId);
    const commonInput = JSON.parse(localStorage.getItem('commonInput'));
    console.log('getSWPreBindData commonInput: ', commonInput);
    await quickquote.getSWPreBindData(commonInput).then(response => {
      console.log('getSWPreBindData response:', response);
      this.prebindData = response;
      // if (response[0].hasOwnProperty("hasError")) {
      //   if (response[0].hasError) {
      //     this.loader = false;
      //     this.openModal();
      //   }
      // }
      if (response[0].errorStatus == 'Rejected') {
        localStorage.setItem('KOerrorMessage', response[0].errorMessage);
        this.preBindKnockOut.emit();
      }
      else {
        for (let i = 0; i < this.prebindData.length; i++) {
          if (this.prebindData[i].statementText.includes('Consumer Report')) {
            this.prebindData[i].label = 'Consumer Report';
          }
          else if (this.prebindData[i].statementText.includes('Occupancy Endorsement')) {
            this.prebindData[i].label = 'Occupancy Endorsement';
          }
          else if (this.prebindData[i].statementText.includes('Credit Information Disclosure')) {
            this.prebindData[i].label = 'Credit Information Disclosure';
          }
          else if (this.prebindData[i].statementText.includes('Underwriting Confirmation')) {
            this.prebindData[i].label = 'Underwriting Confirmation';
          }
          else if (this.prebindData[i].statementText.includes('Consumer Report')) {
            this.prebindData[i].label = 'Consumer Report';
          }
          else if (this.prebindData[i].statementText.includes('Fair Plan acknowledgement')) {
            this.prebindData[i].label = 'Fair Plan acknowledgement';
          }
          else if (this.prebindData[i].statementText.includes('Occupency Endorsement')) {
            this.prebindData[i].label = 'Occupency Endorsement';
          }
          else if (this.prebindData[i].statementText.includes('Exclusion of Cosmetic Roof Damage')) {
            this.prebindData[i].label = 'Exclusion of Cosmetic Roof Damage';
          }
          else if (this.prebindData[i].statementText.includes('Roof Covering Credit Certificate')) {
            this.prebindData[i].label = 'Roof Covering Credit Certificate';
          }
          else {
            this.prebindData[i].label = 'Download PDF';
          }
        }

        if (this.prebindData.length == 2) {
          this.prebindDataLabels = ['Consumer Report', 'Underwriting Confirmation'];
        }
        else {
          this.prebindDataLabels = ['Fair Plan acknowledgement', 'Consumer Report', 'Underwriting Confirmation'];
        }
      }



      //this.pdfSrc = this.sanitizer.bypassSecurityTrustResourceUrl(response[1].statementDocument);
      //this.pdfSrc = response[1].statementDocument;
      this.loader = false;
      console.log('getSWPreBindData response:', this.prebindData);
    })
      .catch(error => {
        debugger
        console.log('getSWPreBindData Error:', error);
        this.openModal();
      });

  }

  onDataCheck(list, val) {
    //debugger
    if (val == 1) {
      list.check = 1;
    }
    else {
      list.check = 0;
    }
    const allCheck = this.prebindData.filter(x => x.check != 1);
    if (allCheck.length > 0) {
      this.disable = true;
    }
    else {
      this.disable = false;
    }
  }

  onConsumer(val) {
    this.consumerReports = val;
  }
  onUnderWriting(val) {
    this.underWriting = val;
  }
  onAgree(val) {
    this.agree = val;
  }

  next() {
    const etty = {
      'next': 1,
      'previous': 0,
    };
    this.nextquestion23.emit(etty);
    this.$gaService.event('Next', 'event', 'HomeAppPolicyAgreement');
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion23.emit(etty);
  }

  openModal() {
    const hubSpotData = {
      client_id: localStorage.getItem('clientID'),
      customer_id: localStorage.getItem('customer_id'),
      email: localStorage.getItem('bubble-email'),
      application_stage: 'Policy Denied',
      home_knock_out_reason: 'Server Error'
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    this.errorMessage = 'Our Servers are taking too long to respond.';
    console.log(' open modal errorMessage', this.errorMessage);
    document.getElementById('errorServer').style.display = 'block';

    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: 'sm',
    //     centered: true,
    //   });

    // let data = {
    //   head: 'Please Note',
    //   title: 'Sorry!',
    //   message: "Our Servers are taking too long to respond please come back later.",
    //   image: 'assets/images/images/education2.png'
    // }

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   //redirect url
    //   //window.location.href = environment.carrierErrorRedirectURL;
    //   window.location.href = this.pc.envCred.url;
    //   console.log("Modal Close Output: ", result);
    // }, (reason) => {
    //   console.log("Modal Close Reason: ", reason);
    // });
  }


  gotoHome() {

    document.getElementById('errorServer').style.display = 'none';
    window.location.href = environment.carrierErrorRedirectURL;
  }

  tryAgain() {

    document.getElementById('errorServer').style.display = 'none';
    //window.location.href = environment.carrierErrorRedirectURL;
    // window.location.href = this.pc.envCred.url;
    location.reload();
  }
}
