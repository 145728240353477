import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
import { Options } from '@angular-slider/ngx-slider';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
@Component({
  selector: 'app-sbli02801170',
  templateUrl: './sbli02801170.component.html',
  styleUrls: ['./sbli02801170.component.css']
})
export class Sbli02801170Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  isDisable: boolean;
  questions: any;
  violation: any;
  existingData: any;
  showLoader = true;
  clientId: any;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }
  value = 0;
  title = 'ngx-slider';

  options: Options = {
    showTicksValues: false,
    showSelectionBar: true,
    showTicks: true,
    hidePointerLabels: true,
    hideLimitLabels: true,
    keyboardSupport: false,
    animateOnMove: true,
    getPointerColor: (value: number): string => {
      if (value <= 5) {
        return '#ff7007';
      }
    },
    getSelectionBarColor: (value: number): string => {
      if (value <= 5) {
        return '#ff7007';
      }
    },
    stepsArray: [{
      value: 0,
      legend: '0'
    }, {
      value: 1,
      legend: '1'
    }, {
      value: 2,
      legend: '2'
    }, {
      value: 3,
      legend: '3'
    }, {
      value: 4,
      legend: '4'
    },
    {
      value: 5,
      legend: '5 or more'
    }]
  };

  ngOnInit(): void {
    this.clientId = localStorage.getItem('clientID');
    this.violation = '';
    this.isDisable = true;
    // this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli02801170")[0];
    // console.log("app-sbli02801170 questions", this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli02801170')[0];
        console.log('app-sbli02801170 questions : ', this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli02801170')[0];
        console.log('app-sbli02801170 questions : ', this.questions);
      }


      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_moving_violations_convicted_count != undefined || this.existingData.sbli_moving_violations_convicted) {
        this.sliderValueSelected = this.existingData.sbli_moving_violations_convicted_count;
        this.sliderEvent();
        if (this.sliderValueSelected > 0) {
          this.violation = this.existingData.sbli_moving_violations_convicted;
          this.movingViolations(this.violation);
        }
      }

      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });

  }

  movingViolations(input) {
    this.violation = input;
    if (this.violation != '') {
      this.isDisable = false;
    }

  }

  sliderValueSelected: any;
  public sliderEvent() {
    debugger;
    const numverSelected = this.sliderValueSelected;
  }

  next() {
    this.$gaService.event( 'SBLI LifeApplication Speeding', 'event', 'SBLI-LifeApplication-Speeding');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Speeding',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions,'SBLI LifeApplication Speeding', 'event', 'SBLI-LifeApplication-Speeding');

    const json = {
      'id': this.clientId,
      SBLI: this.existingData,

    };

    json.SBLI[this.questions.answer_key] = this.sliderValueSelected;
    if (this.sliderValueSelected > 0) {
      json.SBLI[this.questions.radio_options[0].answer_key] = this.violation;
    } else {
      json.SBLI[this.questions.radio_options[0].answer_key] = 'No';
    }



    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }
}
