import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { LifescoreErrorAlertComponent } from './../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { environment } from './../../../../environments/environment';
import { formatAmount } from '../../../shared/UtilityFunctions.js';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

const pc = require('bit-uibl-data-driver');

@Component({
  selector: 'app-e-consent',
  templateUrl: './e-consent.component.html',
  styleUrls: ['./e-consent.component.css']
})
export class EConsentComponent implements OnInit {
  errorMessage: string;

  constructor(
    private pc: ProcessCacheService,
    private utilityService:UtilityFunctionsService,
    private modalService: NgbModal,
    private $gaService: GoogleAnalyticsService,
    private service: ParentChildService) { }
  aboutApplicationDetails: any;
  phoneNumber: any;
  email: string;
  countryBorn: string;
  driverLicenseDetails: any;
  isActiveMilitary: any;
  isInsuredDependent: any;
  thirdPartyNotified: any;
  hasExistingLifeInsurance: any;
  insurancePurpose: any;
  //beneficieries:any;
  ownerInsuredDifferent: any;
  insuredLegalNameChanged: any;
  policyOwnerFirstName: any;
  policyOwnerLastName: any;
  policyOwnerDob: any;
  policyOwnerState: any;
  policyOwnerSSN: any;
  policyOwnerEmail: any;
  policyOwnerStreetAddress: any;
  policyOwnerZipcode: any;
  policyOwnerCity: any;
  hasDriverLicense: any;
  driverLicenseNumber: any;
  driverLicenseIssuedState: any;
  showTerms: boolean;
  showAssumptions = false;
  pageToOpen = '';
  paclifeQuote = '0.00';
  termLength = '0';
  coverageAmt = '0';
  loader = false;
  terminatingExistingPolicies: any;
  hasPendingApplications: any;
  hasExistingLifeInsuranceDetails: any;
  existingPolicyInfo;
  useFundsFromExistingPolicies;
  hasPendingApplicationDetails: any;
  hasPendingApplicationList = [];
  policyOwnerInfo = [];
  driverLicenseInfo = [];
  beneficieries = [];
  insuredInfo = {};
  contingentBeneficiaryInfo = [];
  contingentBeneficiaries: any;
  stateCodeAtBirth: any;
  paclifeQuoteRiskClass: any;
  paclifeQuoteGender: any;
  paclifeQuoteIssueAge: any;
  maskedSSN = 'xxx-xx-';
  insuredInfoInsuredState: any;
  @Output() public electronicConsentOutput =
    new EventEmitter<{ component_id: any }>();

  ngOnInit(): void {
    debugger;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_13_ReviewScreen'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    // this.service.callComponentMethod();
    document.getElementById('appFlowFooterID').style.display = 'none';
    document.getElementById('body').style.overflowY = 'hidden';
    this.loader = true;
    this.showTerms = false;
    //this.termLength = localStorage.getItem('bubble-termLength');
    this.coverageAmt = localStorage.getItem('bubble-coverage');
    this.coverageAmt = this.numberWithCommas(this.coverageAmt);
    // this.paclifeQuote=localStorage.getItem('bubble-QouteAmount');

    this.pc.getAppData(localStorage.getItem('clientID')).then(
      data => {
        debugger;
        const clientDataFiltered = JSON.parse(data.Payload);
        console.log('clientDataFiltered.body: ', clientDataFiltered.body);
        this.authorised = false;
        this.loader = false;
        this.insuredInfoInsuredState = clientDataFiltered.body.stateCode;
        if (clientDataFiltered.body.ssn &&
          clientDataFiltered.body.ssn.split('-') != null) {
          let ssnLast4 = '';
          ssnLast4 = clientDataFiltered.body.ssn.split('-')[2];
          this.maskedSSN = `${this.maskedSSN}${ssnLast4}`;
        }

        this.paclifeQuote = clientDataFiltered.body.paclifeQuote.premiumAmount != 0 ? clientDataFiltered.body.paclifeQuote.premiumAmount : localStorage.getItem('bubble-QouteAmount');
        this.paclifeQuote = formatAmount(this.paclifeQuote);
        this.paclifeQuoteRiskClass = clientDataFiltered.body.paclifeQuote['riskClass'];
        this.paclifeQuoteGender = clientDataFiltered.body.paclifeQuote['gender'];
        this.paclifeQuoteIssueAge = clientDataFiltered.body.paclifeQuote['issueAge'];

        this.countryBorn = clientDataFiltered.body.countryBorn;
        this.stateCodeAtBirth = clientDataFiltered.body.stateCodeAtBirth != undefined ? clientDataFiltered.body.stateCodeAtBirth : 'Other';
        this.ownerInsuredDifferent = clientDataFiltered.body.ownerInsuredDifferent;
        if (this.ownerInsuredDifferent == 'Yes') {
          this.policyOwnerInfo = clientDataFiltered.body.policyOwnerInfo;
        } else {
          this.policyOwnerInfo = undefined;
        }

        this.insuredLegalNameChanged = clientDataFiltered.body.insuredLegalNameChanged;

        this.driverLicenseInfo = clientDataFiltered.body.driverLicenseInfo;
        this.hasDriverLicense = clientDataFiltered.body.hasDriverLicense;

        this.phoneNumber = clientDataFiltered.body.comfirmDetailsPhoneNumber;
        this.email = clientDataFiltered.body.comfirmDetailsEmailID;

        this.isActiveMilitary = clientDataFiltered.body.isActiveMilitary;

        this.isInsuredDependent = clientDataFiltered.body.isInsuredDependent;

        this.thirdPartyNotified = clientDataFiltered.body.thirdPartyNotified;

        this.hasExistingLifeInsurance = clientDataFiltered.body.hasExistingLifeInsurance;

        this.insurancePurpose = clientDataFiltered.body.insurancePurpose;
        this.insurancePurpose = this.insurancePurpose && this.insurancePurpose.length > 0 ? this.insurancePurpose : localStorage.getItem('bubble-insurancePurpose');
        this.beneficieries = clientDataFiltered.body.addBeneficiaryInfo;
        for (let b = 0; b < this.beneficieries.length; b++) {
          this.beneficieries[b]['fullname'] = this.beneficieries[b].firstname + ' ' + this.beneficieries[b].lastname;
        }

        //Contingent Beneficiary
        // Logic Attempt 1: Get it from direct onSubmit localStorage Variable
        // Attempt 2: Get it from saveUpdateClientData-> getAppData -> clientData
        // Attempt 3: Get it from ngInit -> getAppData -> clientData
        const tempVar = localStorage.getItem('PacLife-contingentBeneficiaries');
        if (tempVar != null) {
          const tempVar2 = JSON.parse(tempVar);
          this.contingentBeneficiaryInfo = tempVar2['contingentBeneficiaryInfo'];
          this.contingentBeneficiaries = tempVar2['contingentBeneficiaries'];
        } else {
          console.log('Not in localStorage tempVar');
          const clientData = localStorage.getItem('clientdata');
          const clientDataFilteredTemp = JSON.parse(clientData);
          this.contingentBeneficiaries = clientDataFilteredTemp.body.contingentBeneficiaries;
          this.contingentBeneficiaryInfo = clientDataFilteredTemp.body.contingentBeneficiaryInfo;
        }
        if (this.contingentBeneficiaries == null) this.contingentBeneficiaries = clientDataFiltered.body.contingentBeneficiaries;
        if (this.contingentBeneficiaryInfo == null) this.contingentBeneficiaryInfo = clientDataFiltered.body.contingentBeneficiaryInfo;
        // if (this.contingentBeneficiaryInfo != undefined)
        //   for (let c = 0; c < this.contingentBeneficiaryInfo.length; c++) {
        //     this.contingentBeneficiaryInfo[c]['contigentBenfullname'] = this.contingentBeneficiaryInfo[c].contingentBeneficiaryFirstName + " " + this.contingentBeneficiaryInfo[c].contingentBeneficiaryLastName;
        //   }
        // Check if received from getAppData
        if (this.contingentBeneficiaries === 'Yes' && this.contingentBeneficiaryInfo !== null) {
          console.log('if (this.contingentBeneficiaries === "Yes" && this.contingentBeneficiaryInfo)');
          console.log('this.contingentBeneficiaryInfo:', this.contingentBeneficiaryInfo);
          // If not try to get it from localStorage
        }
        // Got values somehow process to fill in
        if (this.contingentBeneficiaries === 'Yes' && this.contingentBeneficiaryInfo !== null) {
          for (let c = 0; c < this.contingentBeneficiaryInfo.length; c++) {
            this.contingentBeneficiaryInfo[c]['contigentBenfullname'] = this.contingentBeneficiaryInfo[c].contingentBeneficiaryFirstName + ' ' + this.contingentBeneficiaryInfo[c].contingentBeneficiaryLastName;
          }
          this.contingentBeneficiaries = 'Yes';
          localStorage.setItem('PacLife-contingentBeneficiaries', JSON.stringify(this.contingentBeneficiaryInfo));
        } else {
          this.contingentBeneficiaries = 'No';
          localStorage.removeItem('PacLife-contingentBeneficiaries');
        }
        this.hasPendingApplications = clientDataFiltered.body.hasPendingApplications;
        this.hasPendingApplicationList = clientDataFiltered.body.pendingApplicationInfo;

        const hasPendingLength = this.hasPendingApplicationList ? this.hasPendingApplicationList.length : 0;
        for (let i = 0; i < hasPendingLength; i++) {
          const ctd = this.hasPendingApplicationList[i].pendingApplicationFaceAmount;
          this.hasPendingApplicationList[i].pendingApplicationFaceAmount = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }


        this.terminatingExistingPolicies = clientDataFiltered.body.terminatingExistingPolicies;
        this.useFundsFromExistingPolicies = clientDataFiltered.body.useFundsFromExistingPolicies;

        this.existingPolicyInfo = clientDataFiltered.body.existingPolicyInfo;

        this.insuredInfo = clientDataFiltered.body.insuredInfo;

        // if (this.insuredInfo != null) {
        //   this.insuredInfo = {};
        // }
        this.insuredInfo['comfirmDetailsFirstName'] = clientDataFiltered.body.comfirmDetailsFirstName;
        const name = clientDataFiltered.body.comfirmDetailsFirstName;
        const lastPart = name !== null && name.indexOf(' ') !== -1 ? ((name.split(' ')).shift()).join(' ') : '';
        this.insuredInfo['middleName'] = lastPart;
        this.insuredInfo['comfirmDetailsLastName'] = clientDataFiltered.body.comfirmDetailsLastName;


        // this.paclifeQuote = clientDataFiltered.body.paclifeQuote.premiumAmount;
        // localStorage.setItem('policyNo', clientDataFiltered.body.paclifeTicket[0].policyNumber);
        //   this.paclifeQuote = clientDataFiltered.body.paclifeQuote.premiumAmount;
        if (clientDataFiltered.body.paclifeTicket) {
          localStorage.setItem('policyNo', clientDataFiltered.body.paclifeTicket.policyNumber);
        }
        // let smoking = localStorage.getItem('bubble-tobacco');
        // if (smoking != '5') {
        //   smoking = localStorage.getItem('bubble-tobaccoUsageDuration');
        // }
        // let gender = localStorage.getItem('bubble-gender');
        // let height = localStorage.getItem('bubble-heightFt') + 'ft ' + localStorage.getItem('bubble-heightIn') + 'inch';
        // let weight = localStorage.getItem('bubble-weight');
        // this.pc.getPacLifeRiskClass(smoking, gender, height, weight).then(response => {
        //   let PacLifeRiskClass = response.data.output;
        //   let dp = JSON.parse(PacLifeRiskClass);
        //   console.log('PacLifeRiskClass :', dp.dput1);
        //   PacLifeRiskClass = dp.dput1;
        //   localStorage.setItem('riskClass', PacLifeRiskClass);
        //   let splitname;
        //   if (clientDataFiltered.body.comfirmDetailsFirstName) splitname = clientDataFiltered.body.comfirmDetailsFirstName.split(" ");
        //   this.invokeGetQuickQuote2(PacLifeRiskClass, splitname, gender, clientDataFiltered.body.comfirmDetailsDateOfBirth, smoking);
        // })
        //   .catch(error => {
        //     console.log(error);
        //   });


      }
    ).catch(error => {
      console.log(error);
    });

    // this.aboutApplicationDetails=localStorage.getItem('PacLife-PolicyOwnerDetails');
    // this.aboutApplicationDetails = JSON.parse(this.aboutApplicationDetails);
    // this.aboutApplicationDetails = this.aboutApplicationDetails;


    // Driver License Details
    // this.driverLicenseDetails = localStorage.getItem('PacLife-driverLicenseDetails');
    // this.driverLicenseDetails = JSON.parse(this.driverLicenseDetails);
    // this.driverLicenseDetails = this.driverLicenseDetails;

    // this.hasDriverLicense=clientDataFiltered.body.hasDriverLicense;
    // this.driverLicenseInfo=clientDataFiltered.body.driverLicenseInfo;



    //isMilitary Details
    //this.isActiveMilitary = localStorage.getItem('bubble-isActiveMilitary');



    //Insured Details
    // this.isInsuredDependent = localStorage.getItem('bubble-isInsuredDependent');


    //Third Party Notified


    //hasExistingLifeInsurance


    //insurancePurpose


    //beneficieries

    // this.beneficieries = localStorage.getItem('bubble-primarybeneficiariesDetails');
    // this.beneficieries = JSON.parse(this.beneficieries);
    // this.beneficieries = this.beneficieries;



    //Pending Application

    // this.hasPendingApplicationDetails= localStorage.getItem("PacLife-hasPendingApplicationDetails");
    // this.hasPendingApplicationDetails = JSON.parse(this.hasPendingApplicationDetails);
    // this.hasPendingApplicationList=this.hasPendingApplicationDetails.pendingApplicationInfo;
    // this.hasPendingApplications=this.hasPendingApplicationDetails.hasPendingApplications;




    // this.hasExistingLifeInsuranceDetails= localStorage.getItem("PacLife-hasExistingLifeInsuranceDetails");
    // this.hasExistingLifeInsuranceDetails = JSON.parse(this.hasExistingLifeInsuranceDetails);
    // this.terminatingExistingPolicies=this.hasExistingLifeInsuranceDetails.terminatingExistingPolicies;
    // this.useFundsFromExistingPolicies=this.hasExistingLifeInsuranceDetails.useFundsFromExistingPolicies;
    // this.existingPolicyInfo=this.hasExistingLifeInsuranceDetails.existingPolicyInfo





    // this.pc.getAppData(localStorage.getItem('clientID')).then(
    //   data => {
    //     let clientData = JSON.parse(data.Payload)
    //     console.log("this.pc.getAppData: ", clientData);
    //     this.paclifeQuote =  parseInt(clientData.body.paclifeQuote.premiumAmount).toString();
    //     localStorage.setItem('policyNo', clientData.body.paclifeTicket[0].policyNumber);


    //   }
    // ).catch(error => {
    //   console.log(error);
    // });
  }

  inputJson: any;
  public onSubmit() {
    debugger;
    this.loader = true;
    this.pc.submitpaclifeanswer(localStorage.getItem('clientID')).then(
      data => {
        debugger;
        const policyNo = localStorage.getItem('policyNo');
        if (policyNo == 'undefined' || policyNo == null) {
          this.openModal();
        } else {
          this.electronicConsentOutput.emit({ component_id: 'ElectronicConsent' });
        }
      }
    ).catch(
      error => {
        debugger;
        this.electronicConsentOutput.emit({ component_id: 'ElectronicConsent' });
      }
    );

    this.$gaService.event('Next', 'event', 'LifeAppPolicySubmit');
    const eventOptions: EventOption = {
      page_title: 'Policy Review & Submit',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppPolicySubmit');
  }


  openModal() {
    this.errorMessage = 'We are facing some problem in reaching our server, please come back later.';
    document.getElementById('errorServer').style.display = 'block';
    document.getElementById('makeblur').style.filter='blur(10px)';
    // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
    //   {
    //     scrollable: true,
    //     size: "sm",
    //     centered: true,
    //     // keyboard: false,
    //     // backdrop: 'static'
    //   });

    // const data = {
    //   head: "Please Note",
    //   title: "Sorry!",
    //   message: "We are facing some problem in reaching our server, please come back later.",
    //   image: "assets/images/images/education2.png"
    // };

    // modalRef.componentInstance.fromParent = data;
    // modalRef.result.then((result) => {
    //   //redirect url
    //   //window.location.href = environment.carrierErrorRedirectURL;
    //   window.location.href = this.pc.envCred.url;
    //   console.log("Modal Close Output: ", result);
    // }, (reason) => {
    //   console.log("Modal Close Reason: ", reason);
    // });
  }
  tryAgain() {
    document.getElementById('errorServer').style.display = 'none';
    location.reload();
  }

  gotoHome() {
    document.getElementById('errorServer').style.display = 'none';
    window.location.href = this.pc.envCred.url;
  }
  public GoBack() {
    debugger;

    this.electronicConsentOutput.emit({ component_id: 'ElectronicConsent,Goback' });
  }

  public GoToPolicyOwner() {
    debugger;
    this.electronicConsentOutput.emit({ component_id: 'PolicyOwner,Edit' });
  }

  public GoToDriverLicence() {
    debugger;
    this.electronicConsentOutput.emit({ component_id: 'DrivingLicense,Edit' });
  }

  public GoToBeneficiaryInformation() {
    debugger;
    this.electronicConsentOutput.emit({ component_id: 'BeneficiaryInformation,Edit' });
  }

  public GoToContigentBeneficiaryInformation() {
    debugger;
    this.electronicConsentOutput.emit({ component_id: 'ContigentBeneficiaryInformation,Edit' });
  }

  public GoToPendingPolicyInformation() {
    debugger;
    this.electronicConsentOutput.emit({ component_id: 'PendingPolicyInformation,Edit' });
  }

  public GoToExistingPolicyInformation() {
    debugger;
    this.electronicConsentOutput.emit({ component_id: 'ExistingPolicyInformation,Edit' });
  }



  onKeyisActiveMilitary(event) {
    debugger;
    this.inputJson = {};
    this.inputJson['id'] = localStorage.getItem('clientID');
    this.inputJson['isActiveMilitary'] = event.target.value.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());
    localStorage.setItem('bubble-isActiveMilitary', event.target.value.replace(/^(.)|\s+(.)/g, c => c.toUpperCase()));
    this.pc.saveUpdateClientData(this.inputJson);


  }


  onKeyisInsuredDependent(event) {
    this.inputJson = {};
    this.inputJson['id'] = localStorage.getItem('clientID');
    this.inputJson['isInsuredDependent'] = event.target.value.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

    localStorage.setItem('bubble-isInsuredDependent', event.target.value.replace(/^(.)|\s+(.)/g, c => c.toUpperCase()));
    this.pc.saveUpdateClientData(this.inputJson);
  }


  onKeythirdPartyNotified(event) {
    this.inputJson = {};
    this.inputJson['id'] = localStorage.getItem('clientID');
    this.inputJson['thirdPartyNotified'] = event.target.value.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

    localStorage.setItem('bubble-thirdPartyNotified', event.target.value.replace(/^(.)|\s+(.)/g, c => c.toUpperCase()));
    this.pc.saveUpdateClientData(this.inputJson);
  }

  onKeyhasExistingLifeInsurance(event) {
    this.inputJson = {};
    this.inputJson['id'] = localStorage.getItem('clientID');
    this.inputJson['hasExistingLifeInsurance'] = event.target.value.replace(/^(.)|\s+(.)/g, c => c.toUpperCase());

    localStorage.setItem('bubble-hasExistingLifeInsurance', event.target.value.replace(/^(.)|\s+(.)/g, c => c.toUpperCase()));
    this.pc.saveUpdateClientData(this.inputJson);
  }

  openTermsDrawer() {
    debugger;
    this.showTerms = this.showTerms == false ? true : false;
    console.log('open Terms ', this.showTerms);
    //document.getElementById('mainScrollID').style.overflowY="hidden";
  }

  authorised: boolean;
  ChangeForChecBox(even) {
    if (even == '1') {
      this.authorised = true;
    }
    else {
      this.authorised = false;
    }
  }

  openRiskClass() {
    this.pageToOpen = 'riskPage';
    this.showAssumptions = true;
  }

  openAssumptions() {
    this.pageToOpen = 'assumptions';
    this.showAssumptions = true;
  }

  closeAssumption() {
    debugger;
    this.showAssumptions = false;
    this.pageToOpen = '';
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    // return x.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }



  //quote Amount

  commonAddress: any;
  city: any;
  displayAddress: any;

  state: any;
  zipcode: any;
  height: any;
  weight: any;
  coverageAmount: any;
  uniqueId: any;
  quickQuoteData: any;
  carrierName: any;
  selectedCardData: any;

  invokeGetQuickQuote2(riskClassInput, splitname, sbligender, sbliDob, sblitobacco) {
    debugger;
    this.commonAddress = localStorage.getItem('bubble-street');
    this.city = localStorage.getItem('bubble-city');
    this.termLength = localStorage.getItem('bubble-termLength');
    this.displayAddress = localStorage.getItem('displayAddress');
    const splitAddress = this.displayAddress.split('-');
    this.city = splitAddress[(splitAddress.length - 4)];
    this.state = splitAddress[(splitAddress.length - 3)];
    this.zipcode = splitAddress[(splitAddress.length - 2)];

    this.height = localStorage.getItem('bubble-heightIn') + 'ft ' + localStorage.getItem('bubble-heightFt') + 'inch';
    //   this.height = localStorage.getItem('bubble-height');
    this.weight = localStorage.getItem('bubble-weight');
    this.carrierName = localStorage.getItem('carrierName');
    splitname = splitname && splitname.length > 2 ? splitname : ['Anonymous', 'User', ''];
    this.coverageAmount = localStorage.getItem('bubble-coverage');
    // console.log(" Customized RiskClass =  "," Somking:", sblitobacco, this.smoker, " Gender:", sbligender, " Height:", this.height, " Weight:", this.weight, " Riskclass:", riskClassInput);
    const commonInput = {
      lastName: splitname[2] ? splitname[2] : 'Lastname',
      firstName: splitname[0] ? splitname[0] : 'Firstname',
      middleName: splitname[1] ? splitname[1] : 'MiddleName',
      addressType: 'PhysicalRisk',
      address: this.commonAddress,//splitAddress[(splitAddress.length - 3)],
      city: this.city,
      gender: sbligender == '1' ? 'M' : 'F', //inputJson.gender
      dob: sbliDob,//inputJson.dob
      term: this.termLength,//inputJson.term
      zip: this.zipcode,//inputJson.zip
      state: this.state,//inputJson.state
      height: this.height,//inputJson.height
      weight: +this.weight,//inputJson.weight
      tobaccoUse: +sblitobacco,//inputJson.tobaccoUse
      riskClass: riskClassInput,
      coverage: this.coverageAmount,//inputJson.coverage
      premiumFrequency: 'm',
    };

    console.log('commonInput : ', commonInput);
    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.getQuickQuote2(commonInput)
      .then(response => {
        debugger;
        console.log('utput quickquote: ', response);
        this.quickQuoteData = response;
        if (this.quickQuoteData[0].head.carrier_name === 'Next Term Life' || this.quickQuoteData[0].head.carrier_name === 'Pac Life Next') {
          this.paclifeQuote = this.quickQuoteData[0].head.quote_amount;
          this.paclifeQuote = formatAmount(this.paclifeQuote);
        }




      })
      .catch(error => {

      });
  }

}
