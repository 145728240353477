import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-interstitial-loader',
  templateUrl: './interstitial-loader.component.html',
  styleUrls: ['./interstitial-loader.component.css']
})
export class InterstitialLoaderComponent implements OnInit {

  @Input() loder1time;
  @Input() loder2time;
  @Input() loder3time;
  @Input() loder4time;
  url: string[];
  @Input() loaderText: string;

  constructor(private router: Router) { }
  @Input() ModalText = [];
  loader1: boolean;
  loader2: boolean;
  loader3: boolean;
  loader4: boolean;
  @Input() itype;
  @Input() numberofloaders;
  ngOnInit(): void {
    debugger;
    if (this.ModalText.length == 0) {
      this.ModalText = [
        {
          'title1': 'Fetching home details',
          'Subtitle1': 'Your home’s build and make-up matter',
          'title2': 'Analyzing risks',
          'Subtitle2': 'Triangulating across your home, location and you',
          'title3': 'Compiling quotes',
          'Subtitle3': 'Putting together best possible coverages & premiums',
          'title4': 'Compiling quotes',
          'Subtitle4': 'Putting together best possible coverages & premiums'
        }];
      if (!this.loder1time) {
        this.loder1time = 4000;
      }
      else {
        this.loder1time = 30000;
      }
      if (!this.loder2time) {
        this.loder2time = 4000;
      }
      else {
        this.loder2time = 30000;
      }
      if (!this.loder3time) {
        this.loder3time = 4000;
      }
      else {
        this.loder3time = 30000;
      }
    }

    this.loader1 = false;
    this.loader2 = false;
    this.loader3 = false;
    this.loader4 = false;
    setTimeout(() => {

      this.loader1 = true;
      setTimeout(() => {

        this.loader2 = true;
        setTimeout(() => {

          this.loader3 = true;
          if (this.numberofloaders == 4) {

            setTimeout(() => {

              this.loader4 = true;
            }, this.loder4time);
          }
        }, this.loder3time);
      }, this.loder2time);
    }, this.loder1time);



    // this.url = this.router.url.split('/');
    // console.log('life url',this.url);
    // this.loaderText = this.url[1] == 'SelectLifeCarrierV2' ? 'You are just seconds away from getting personalized life insurance quotes to protect your loved ones':'Please wait...';
    if (this.loaderText == undefined || this.loaderText == null || this.loaderText == '') {
      this.loaderText = 'Please wait...';
    }
  }




}
