import { Component, Input, OnInit, Output, EventEmitter, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { FormGroup, FormGroupDirective, FormBuilder } from '@angular/forms';
import { Options, ChangeContext } from '@angular-slider/ngx-slider';

import { AnswerModel } from '../../models/answer.model';

@Component({
  selector: 'app-input-range',
  templateUrl: './input-range.component.html',
  styleUrls: ['./input-range.component.css']
})
export class InputRangeComponent implements OnInit {

  @Output() onChange: EventEmitter<number | string> = new EventEmitter();
  @Input() public questionId: number | undefined;
  @Input() public optionValues: AnswerModel[] | undefined | null;

  childForm!: FormGroup;

  startValue: number = 0;
  options: Options = {
    floor: 0,
    showTicks: true,
    showSelectionBar: true,
    selectionBarGradient: {
      from: '#ff8e12',
      to: '#ff8e12',
    },
    getPointerColor: (value: number): string => {
      if (value <= 0) {
        return '#ff8e12';
      }
      if (value <= 5) {
        return '#ff8e12';
      }
      return '#ff8e12';
    },
  };

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    debugger
    this.childForm = this.rootFormGroup.form as FormGroup;
    let _currentValue = this.childForm.get('answer_value_' + this.questionId)?.value;
    if (_currentValue == undefined) {
      this.childForm.get('answer_value_' + this.questionId)?.setValue(0);
      this.childForm.get('answer_option_id_' + this.questionId)?.setValue(this.getAnswerOptionId(0));
    }

    let _stepsArray: any = [];
    this.options.ceil = this.optionValues?.length;
    this.optionValues?.forEach((r, i) => {
      let _obj = {
        value: i,
        legend: r.answer_text
      }
      _stepsArray.push(_obj);
    });
    this.options.stepsArray = _stepsArray;
    this.childForm.controls['selected_answer'].setValue(_currentValue);
  }

  getAnswerOptionId(index: number) {
    if (this.optionValues != null) {
      return this.optionValues[index].answer_option_id
    }
    return null;
  }

  onUserChange(changeContext: ChangeContext): void {
    let _answer_option_id = this.getAnswerOptionId(changeContext.value);
    this.childForm.get('answer_option_id_' + this.questionId)?.setValue(_answer_option_id);
    this.childForm.controls['selected_answer'].setValue(changeContext.value);
    this.onChange.emit(changeContext.value);

  }

}
