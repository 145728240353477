import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sbli00661135',
  templateUrl: './sbli00661135.component.html',
  styleUrls: ['./sbli00661135.component.css']
})
export class Sbli00661135Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
