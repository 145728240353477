import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-life-carrier-recommendation',
  templateUrl: './life-carrier-recommendation.component.html',
  styleUrls: ['./life-carrier-recommendation.component.css']
})
export class LifeCarrierRecommendationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
