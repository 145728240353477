import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-flow-resume',
  templateUrl: './flow-resume.component.html',
  styleUrls: ['./flow-resume.component.css']
})
export class FlowResumeComponent implements OnInit {

  loader = true;
  accessedUrl: string = localStorage.getItem('accessedURL');// http://localhost:4200/resumeFlow/g8ul2b;
  clientId: string;
  httpParamsGlobal: any;
  email: any;
  utm_campaign: any;
  utm_medium: any;
  _hsmi: any;
  _hsenc: any;
  utm_content: any;
  utm_source: any;
  utm_term: any;
  constructor(public router: Router, private pc: ProcessCacheService, private $gaService: GoogleAnalyticsService, private utilityService: UtilityFunctionsService, private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    const url = window.location.href;
    console.log('url : ', url);
    localStorage.setItem('accessedURL', url);
    ///////////////// GA pageview Implementation starts
    // import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
    // import { GoogleAnalyticsService } from 'ngx-google-analytics';
    //, private $gaService: GoogleAnalyticsService, private utilityService: UtilityFunctionsService
    let path, titleOfPage;
    path = 'resumeFlow';
    titleOfPage = 'ResumeFlow';
    const subdomainDomain = this.utilityService.getSubdomainDomain(window.location.href);
    this.utilityService.setUTMInPageview(this.$gaService, path, titleOfPage, subdomainDomain);
    ///////////////// GA pageview Implementation Ends

    if (url.includes('?')) {
      this.httpParamsGlobal = new HttpParams({ fromString: url.split('?')[1] });
      const httpParams = this.httpParamsGlobal;
      this.clientId = httpParams.get('client_id');
      if(!this.clientId)
      {
        const match = url.match(/\/resumeFlow\/([a-zA-Z0-9]+)/);
        // Check if there is a match and retrieve the captured group
        const result = match ? match[1] : '';
        this.clientId = result;
      }
      localStorage.setItem('clientID', this.clientId);
      this.email = httpParams.get('email');
      this._hsmi = httpParams.get('_hsmi');
      this._hsenc = httpParams.get('_hsenc');
      this.utm_campaign = httpParams.get('utm_campaign');
      this.utm_medium = httpParams.get('utm_medium');
      this.utm_content = httpParams.get('utm_content');
      this.utm_source = httpParams.get('utm_source');
      this.utm_term = httpParams.get('utm_term');
      if (!this.utm_source) {
        this.utm_source = localStorage.getItem('utm_source');
        if (this.utm_source) {
          this.utm_medium = localStorage.getItem('utm_medium');
          this.utm_campaign = localStorage.getItem('utm_campaign');
          this.utm_term = localStorage.getItem('utm_term');
          this.utm_content = localStorage.getItem('utm_content');
        }
      }
      const hubSpotData = {
        interest_type: 'Life',
        client_id: this.clientId,
        customer_id: '',
        application_stage: 'Lead',
        email: this.email,
        utm_campaign: this.utm_campaign,
        utm_medium: this.utm_medium,
        _hsmi: this._hsmi,
        _hsenc: this._hsenc,
        utm_content: this.utm_content,
        utm_source: this.utm_source

      };
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      this.processResume();
    }
    else {
      this.activatedroute.params.subscribe(params => {
        this.clientId = params['clientID'];
        localStorage.setItem('clientID', this.clientId);
      });
      this.processResume();
    }
  }

  async processResume() {
    const resumeData = await this.pc.checkforResumeInit({}, 'fetch');
    // If not valid clientId OR or client-data doesn't exist -> send user to Home page
    if (!resumeData) {
      //window.location.href = environment.carrierErrorRedirectURL;
      window.location.href = this.pc.envCred.url;
      return;
    }
    console.log('resumeData:', resumeData);
    localStorage.setItem('appFlowLinkResume', '1');
    if (resumeData['appStartStatus'] == true) { // check if Appflow has started
      if (resumeData['appCompletedStatus'] == false) { // check if AppFlow has completed
        // redirect to given url
        this.router.navigate([resumeData['resumeUrl']]);
      } else {
        // redirect to HomeScreen
        if (localStorage.getItem('insuranceType') == 'homeLife') {
          this.router.navigate([resumeData['resumeUrl']]);
        } else {
          location.href = 'https://development.d81dpjw7shcd2.amplifyapp.com/profile-home';
        }

      }
    } else {
      //redirect to HomeScreen
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      this.router.navigate([resumeData['resumeUrl']]);
    }
    this.loader = false;
  }

  gotoApp() {
    const nextURL = localStorage.getItem('accessedURL');
    this.router.navigate([nextURL]);
  }

}
