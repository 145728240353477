import { Component, OnInit } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { ProfileDataHandlerService } from 'src/app/shared/profile-data-handler.service';
import { StorageHandlerService } from "src/app/shared/storage-handler.service";
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-prefill-quote-flow-v2',
  templateUrl: './prefill-quote-flow-v2.component.html',
  styleUrls: ['./prefill-quote-flow-v2.component.css']
})
export class PrefillQuoteFlowV2Component implements OnInit {
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  gender: any;
  age: any;
  lifeScoreData: any;
  allScores: any;
  insurancetype: any;
  question: number;
  progress: number = 1;
  sourcePageLink: any;
  constructor(private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute,
    private $gaService: GoogleAnalyticsService,
    private utilityService: UtilityFunctionsService) { }

  ngOnInit(): void {
    ///////////////// GA pageview Implementation starts
    // import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
    // import { GoogleAnalyticsService } from 'ngx-google-analytics'; 
    //, private $gaService: GoogleAnalyticsService, private utilityService: UtilityFunctionsService
    let path, titleOfPage;
    path = "prefill-QuoteFlowV2";
    titleOfPage = "prefill-QuoteFlowV2";
    const subdomainDomain = this.utilityService.getSubdomainDomain(window.location.href);
    this.utilityService.setUTMInPageview(this.$gaService, path, titleOfPage, subdomainDomain);
    console.log("Done with this.utilityService.setUTMInPageview");
    ///////////////// GA pageview Implementation Ends
    console.log("location.href: ", location.href);
    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };
    this.activatedroute.params.subscribe(params => {
      let storageHandlerService = new StorageHandlerService();
      let paramsLocal = storageHandlerService.set2StorageFromQueryParams(params);
      this.uniqueId = paramsLocal['uniqueId'];
      this.clientId = paramsLocal['clientId'];
      this.displayAddress = paramsLocal['displayAddress'];
      this.insurancetype = paramsLocal['insurancetype'];
      this.sourcePageLink = paramsLocal['sourcePageLink'];
      console.log(this.insurancetype, "insurancetype");
      localStorage.setItem("insuranceType", this.insurancetype)
      // 2ct217-Short-Hills-NJ-07078/2ct217/Short%20Hills%20NJ%2007078%20/life/Next%20Term%20Life
      // const profileDataHandlerService = new ProfileDataHandlerService();
      // const cleanedoutputJson = profileDataHandlerService.getSetCityZipStateFromURL(this.displayAddress);
      // this.displayAddress = cleanedoutputJson.displayAddress;

      this.displayAddress = this.displayAddress.replace('-USA', '');
      this.displayAddress = this.displayAddress.replace(/-/g, ' ');
      this.displayAddress = this.displayAddress.replace(/%20/g, ' ');
      this.displayAddress = this.displayAddress.replace(/  /g, ' ');
      this.displayAddress = this.displayAddress.trim();
      console.log("uniqueId : ", this.uniqueId);
      console.log("clientId : ", this.clientId);
      console.log("displayAddress : ", this.displayAddress);
      console.log("insurancetype : ", this.insurancetype);
    }
    )
    this.question = 1;

    setInterval(() => {
      this.progress = this.progress % 14 + 1;
      //console.log(this.progress, "progress")
    }, 1000);
    document.getElementById("index_title_id").innerText = "Home + Life Insurance powered by Bubble";
  }

}
