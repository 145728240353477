import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { formatAmount, formatAmountCommaDollar } from './../../../shared/UtilityFunctions.js';
@Component({
  selector: 'app-sbli-summary',
  templateUrl: './sbli-summary.component.html',
  styleUrls: ['./sbli-summary.component.css']
})
export class SbliSummaryComponent implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  @Output() callgetpremium = new EventEmitter();
  sblimaxAllowedCoverage: any;
  selectedterm: any;
  selectedcov: any;
  buttontext: string;
  constructor(
    private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,
    private activatedroute: ActivatedRoute) { }
  coverage: any;
  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  amount: any;
  maxct = [];
  existingData: any;

  ngOnInit(): void {
    debugger
    this.showerrormessage = false;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];

    }
    );
    this.buttontext = 'Next';
    this.sblimaxAllowedCoverage = JSON.parse(localStorage.getItem('sblimaxAllowedCoverage'));
    console.log('this.sblimaxAllowedCoverage :', this.sblimaxAllowedCoverage);


    const maxCoverageAllowed = [];
    if (this.sblimaxAllowedCoverage != '') {
      const coverageTermDetails = this.sblimaxAllowedCoverage.split(',');
      for (let i = 0; i < coverageTermDetails.length; i++) {
        let rep = coverageTermDetails[i];
        rep = rep.replace('[', '');
        rep = rep.replace(']', '');
        rep = rep.replace('{', '');
        rep = rep.replace('}', '');
        rep = rep.replace(/ /g, '');
        rep = rep.replace(/'/g, '');
        coverageTermDetails[i] = rep;
      }
      console.log('sssss :', coverageTermDetails);
      for (let j = 0; j < coverageTermDetails.length; j++) {
        const t = coverageTermDetails[j].split(':');
        const c = coverageTermDetails[j + 1].split(':');
        const json = {
          term: t[1],
          coverage_limit: c[1]
        };
        maxCoverageAllowed.push(json);
        j = j + 1;
      }
    }
    else {
      let commonInput = localStorage.getItem('sbliCommonInput');
      commonInput = JSON.parse(commonInput);
      const json = {
        term: commonInput['term'],
        coverage_limit: commonInput['coverage']
      };
      maxCoverageAllowed.push(json);
    }


    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);

    this.maxct = maxCoverageAllowed;
    this.coverage = commonInput['coverage'];
    this.coverage = formatAmountCommaDollar(this.coverage).replace('$', '');

    this.term = commonInput['term'];
    this.selectedterm = this.term;
    this.selectedcov = this.coverage;

    this.amount = commonInput['premium'];


    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.SBLI;

    }).catch(error => {
      console.log(error);
    });

  }




  checkchildren(term, id) {
    debugger;

    if (id == 0) {
      const index = this.maxct.findIndex(x => x.term == term);
      this.coverage = this.maxct[index - 1].coverage_limit;
      this.term = this.maxct[index - 1].term;
    }
    else if (id == 1) {
      const index = this.maxct.findIndex(x => x.term == term);
      this.coverage = this.maxct[index + 1].coverage_limit;
      this.term = this.maxct[index + 1].term;
    }


  }


  async next() {
    debugger
    this.pc.lifesummary({ 'final_confirmation': 'submit' });
    this.$gaService.event('SBLI LifeApplication Premium', 'event', 'SBLI-LifeApplication-Premium');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Premium',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Premium', 'event', 'SBLI-LifeApplication-Premium');
    const covTest = Number(this.coverage.replace(/,/g, ''));
    const json = {
      'id': this.clientId,
      SBLI: this.existingData,
      'sbli_coverage': covTest.toString(),
      'sbli_term': this.term,
      'sbli_PolicyNumber': ''
    };


    //json.SBLI["sbli_PolicyNumber"] = '';

    if (Number(this.selectedcov.replace(/,/g, '')) != Number(this.coverage.replace(/,/g, '')) || Number(this.selectedterm != Number(this.term))) {
      await this.pc.saveUpdateClientData(json);
      this.callgetpremium.emit();

    }
    else {
      const etty = {
        'next': 1,
        'previous': 0,
        'APICall': 4,
        'sbli_answer_data': json,
        'saveData': 1

      };
      this.changeQuestion.emit(etty);
    }

  }


  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }


  term: any;
  Getterm(event) {
    debugger;
    this.term = event.term;
    if (Number(this.selectedcov.replace(/,/g, '')) != Number(this.coverage.replace(/,/g, '')) || Number(this.selectedterm != Number(this.term))) {
      this.buttontext = 'Refresh';
    }
    else {
      this.buttontext = 'Next';
    }
    // this.coverage = event.coverage_limit;
  }

  errormessage: any;
  showerrormessage: boolean;
  coverageMaxAmount() {
    debugger;
    const coverage = Number(this.coverage.replace(/,/g, ''));

    const index = this.maxct.findIndex(x => x.term == this.term);
    const checkCoverage = this.maxct[index].coverage_limit;
    const checkCoverageDisplayText = formatAmountCommaDollar(checkCoverage).replace('$', '');

    if (Number(coverage) <= Number(checkCoverage)) {
      this.coverage = this.coverage;
      const matchcoverage = Number(this.coverage.replace(',', ''));
      this.coverage = formatAmountCommaDollar(this.coverage).replace('$', '');
      this.showerrormessage = false;
      if (matchcoverage < 25000) {
        this.errormessage = 'Minimum coverage amount is 25000';
        this.showerrormessage = true;
      }
    } else {
      // this.coverage = 0;
      this.showerrormessage = true;
      this.errormessage = 'Maximum coverage amount is ' + '$' + checkCoverageDisplayText;
      this.coverage = formatAmountCommaDollar(this.coverage).replace('$', '');
    }
    if (Number(this.selectedcov.replace(/,/g, '')) != Number(this.coverage.replace(/,/g, '')) || Number(this.selectedterm != Number(this.term))) {
      this.buttontext = 'Refresh';
    }
    else {
      this.buttontext = 'Next';
    }
  }

  public quoteAmount() {
    debugger
    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);
    this.coverage = commonInput['coverage'];
    this.coverage = formatAmountCommaDollar(this.coverage).replace('$', '');
    this.term = commonInput['term'];
    this.selectedterm = this.term;
    this.selectedcov = this.coverage;
    this.amount = commonInput['premium'];
    this.buttontext = 'Next';
  }


}
