import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';

interface EventOption{
  path: string
  url: string
  page_title: string
}

@Injectable({
  providedIn: 'root'
})
export class UtilityFunctionsService {

  constructor(private $gaService: GoogleAnalyticsService) { }

  public getHubSpotInterestType(insurancetype) {
    let interestTypeOutput;
    if (!insurancetype) interestTypeOutput = 'Life';
    if (insurancetype === 'life') interestTypeOutput = 'Life';
    else if (insurancetype === 'home') interestTypeOutput = 'Home';
    else interestTypeOutput = 'Home+Life';
    return interestTypeOutput;
  }
  //output = formatAmountLocal(0);
  // output = formatAmountLocal(2);
  // output = formatAmountLocal(12.3);
  // output = formatAmountLocal("$123");
  // output = formatAmountLocal("90.9");
  // output = formatAmountLocal("88.99");
  // output = formatAmountLocal("1.1");

  public formatAmountCommaDollarLocal(coverage) {
    let outputVar;
    coverage = coverage.toString() || '';
    coverage = coverage.replace('$', '').replace(/,/g, '');
    outputVar = coverage.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    outputVar = `$${outputVar}`;
    return outputVar;
  }

  public formatAmountLocal(amountData) {
    let outputAmountStr = '' + amountData;
    if (outputAmountStr.indexOf('.') !== -1) {
      const splitedAmount = outputAmountStr.split('.');
      switch (splitedAmount[1].length) {
        case 0:
          outputAmountStr += '.00';
          break;
        case 1:
          outputAmountStr += '0';
          break;
        case 2:
        default:
          break;
      }
    } else {
      outputAmountStr += '.00';
    }
    return outputAmountStr;
  }


  public getHubSpotAppStage(url) {
    let application_stage;
    const QuoteFlow = url.toString().includes('QuoteFlowV2');
    const Appflowstart = url.toString().includes('Appflowstart');
    const HomeAppFlow = url.toString().includes('HomeAppFlow');

    if (Appflowstart == true || HomeAppFlow == true) {
      application_stage = 'Application';
    } else {
      application_stage = 'Quote';
    }
    // if (QuoteFlow) application_stage = "Quote"; else application_stage = "Application"
    return application_stage;
  }
  getNewRandomToken() {
    const outputArray = [];
    let arr = 'abcdefghijklmnopqrstuvwxyz';
    const randomAlpha = [];
    const randomNumber = [];
    const TOTAL_LENGTH = 6;
    const loopLimit = TOTAL_LENGTH / 2;
    const excludeList = [];
    let tempVar;
    for (var i = loopLimit; i > 0; i--) {
      tempVar = arr[Math.floor(Math.random() * arr.length)];
      randomAlpha.push(tempVar);
    }
    arr = '123456789';
    for (var i = loopLimit; i > 0; i--) {
      tempVar = arr[Math.floor(Math.random() * arr.length)];
      if (excludeList.indexOf(tempVar) !== -1) {
        tempVar = arr[Math.floor(Math.random() * arr.length)];
      }
      randomNumber.push(tempVar);
      excludeList.push(tempVar);
    }
    if (randomNumber[randomNumber.length - 2] == tempVar) {
      tempVar = arr[Math.floor(Math.random() * arr.length)];
      randomNumber[randomNumber.length - 1] = tempVar;
    }
    for (let i = 0; i < loopLimit; i++) {
      outputArray.push(randomAlpha[i]);
      outputArray.push(randomNumber[i]);
    }
    return outputArray.join('');
  }//end of getNewRandomToken
  getSubdomainDomain(url) {
    let subdomainDomain;
    if (url) {
      console.log('getDropOffURL:url : ', url);
      // fetch part 1
      // url = "https://track2-app.getmybubble.co/prefillResumeFlowHoi?sdfdsfdsf";
      let urlParts = url.split('?');
      const domainPart = urlParts[0];
      urlParts = domainPart.split('/');
      subdomainDomain = `${urlParts[0]}//${urlParts[2]}`;
      console.log('subdomainDomain : ', subdomainDomain);
    }
    return subdomainDomain;
  }
  getDropOffURL(url, clientId) {
    let dropoffURL;
    if (url) {
      console.log('getDropOffURL:url : ', url);
      const domain = this.getSubdomainDomain(url);
      dropoffURL = `${domain}/resumeFlow/${clientId}`;
      console.log('getDropOffURL:dropoffURL : ', dropoffURL);
    }
    return dropoffURL;
  }
  /**
   *  let path, titleOfPage, url;
      path = "PrefillToPropertyDetails";
      titleOfPage = "PrefillToPropertyDetails";
      const subdomainDomain = this.utilityService.getSubdomainDomain(this.url);
      this.utilityService.setUTMInPageview(this.$gaService, path, titleOfPage, subdomainDomain);

   * @param gaService
   * @param path
   * @param titleOfPage
   * @param subdomainDomain
   */
  setUTMInPageview(gaService, path, titleOfPage, subdomainDomain, eventName = 'page_view', eventOptions = {}) {
    let utm_source, utm_medium, utm_campaign, utm_term, utm_content, url;
    // path = "PrefillToPropertyDetails";
    // titleOfPage = "PrefillToPropertyDetails";

    utm_source = localStorage.getItem('utm_source');
    if (utm_source) {
      utm_medium = localStorage.getItem('utm_medium');
      utm_campaign = localStorage.getItem('utm_campaign');
      utm_term = localStorage.getItem('utm_term');
      utm_content = localStorage.getItem('utm_content');
      const virtualPath = `${path}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_source=${utm_source}&utm_term=${utm_term}&utm_content=${utm_content}`;
      url = `${subdomainDomain}/${virtualPath}`;
      //pageView(path: string, title?: string, location?: string, options?: Object): void;
      console.log('setUTMInPageview:url:', url, ' # path:', path, ' # titleOfPage:', titleOfPage);
      debugger;
      // gaService.gtag('set', { 'page_location': url, 'page_referrer': null, 'page_path': path });
      eventOptions['page_path'] = virtualPath;
      eventOptions['page_title'] = titleOfPage;
      eventOptions['page_location'] = url;

      gaService.gtag('event', eventName, eventOptions);
    } else {
      console.log('Error:setUTMInPageview:utm_source is null localStorage.getItem');
    }
  }
  setUTMFromLocalStorage(eventOptions) {
    //mandatory in eventOptions: path, page_title url
    let utm_source, utm_medium, utm_campaign, utm_term, utm_content, url;
    utm_source = localStorage.getItem('utm_source');
    if (utm_source) {
      utm_source = localStorage.getItem('utm_source');
      utm_medium = localStorage.getItem('utm_medium');
      utm_campaign = localStorage.getItem('utm_campaign');
      utm_term = localStorage.getItem('utm_term');
      utm_content = localStorage.getItem('utm_content');
      const subdomain_domain = this.getSubdomainDomain(url);
      const virtualPath = `${eventOptions.path}?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}&utm_source=${utm_source}&utm_term=${utm_term}&utm_content=${utm_content}`;
      url = `${subdomain_domain}/${virtualPath}`;
      //pageView(path: string, title?: string, location?: string, options?: Object): void;
      console.log('setUTMInPageview:url:', url, ' # path:', eventOptions.path, ' # page_title:', eventOptions.page_title);
      debugger;
      // gaService.gtag('set', { 'page_location': url, 'page_referrer': null, 'page_path': path });
      eventOptions['page_path'] = virtualPath;
      eventOptions['page_title'] = eventOptions.page_title;
      eventOptions['page_location'] = url;
    }
    return eventOptions;
  }
  gtagWrapped(eventOptionsPart1, eventType, eventName, eventOptions) {
    //mandatory: path, page_title url
    eventOptions['path'] = eventOptionsPart1['path'];
    eventOptions['page_title'] = eventOptionsPart1['page_title'];
    eventOptions['url'] = eventOptionsPart1['url'];
    eventOptions = this.setUTMFromLocalStorage(eventOptions);
    this.$gaService.gtag(eventType, eventName, eventOptions);
  }

  //mandatory in eventOptions: path, page_title, url
  eventWrapped(eventOptions: EventOption, event_action, event_category, event_label) {
    eventOptions['event_label'] = event_label;
    eventOptions['event_category'] = event_category;
    eventOptions = this.setUTMFromLocalStorage(eventOptions);
    /*
      Send an event trigger to GA.It is the same as call:
     * gtag('event', 'video_auto_play_start', {
     *   'event_label': 'My promotional video',
     *   'event_category': 'video_auto_play'
     * });
     * @param action 'video_auto_play_start'
      * @param category 'video_auto_play'
        * @param label 'My promotional video'
          * @param value An value to measure something
          * */
    this.$gaService.gtag('event', event_action, eventOptions);
  }

  /**
   *
   * @param inputAddressJson inputs are address, city, zip, state
   * @returns output is json with clean address, fullAddress
   */
  async cleanupAddressFields(inputAddressJson) {
    debugger;
    const checkhascomma = inputAddressJson.address.split(',');
    if (checkhascomma.length <= 1) {
      inputAddressJson.replacedaddress = inputAddressJson.address.replace(/ /g, ',');
      const correctaddress = inputAddressJson.replacedaddress.split(',');
      let finaladdress = '';
      for (let i = 0; i < correctaddress.length; i++) {
        if (correctaddress[i] == inputAddressJson.city) {
          correctaddress.splice(i, 1);
        }
        if (correctaddress[i] == inputAddressJson.state) {
          correctaddress.splice(i, 1);
        }
        if (correctaddress[i] == inputAddressJson.zip) {
          correctaddress.splice(i, 1);
        }
        if (i == 0) {
          finaladdress =  correctaddress[i];
        }
        else {
          if(correctaddress[i]!=undefined){
            finaladdress = finaladdress + ' ' + correctaddress[i];
          }

        }
        inputAddressJson.finaladdress = finaladdress;
      }

    }
    else {
      //let correctaddress = inputAddressJson.address.split(',');
      for (let i = 0; i < checkhascomma.length; i++) {
        if (checkhascomma[i] == inputAddressJson.city) {
          checkhascomma.splice(i, 1);
        }
        if (checkhascomma[i] == inputAddressJson.state) {
          checkhascomma.splice(i, 1);
        }
        if (checkhascomma[i] == inputAddressJson.zip) {
          checkhascomma.splice(i, 1);
        }
      }
      inputAddressJson.finaladdress=checkhascomma[0];
    }


    const outputAddressJson = inputAddressJson;
    return outputAddressJson;
  }
}
