import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-has-coronavirus',
  templateUrl: './has-coronavirus.component.html',
  styleUrls: ['./has-coronavirus.component.css']
})
export class HasCoronavirusComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
