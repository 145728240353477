import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
 import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-sw4pt6',
  templateUrl: './sw4pt6.component.html',
  styleUrls: ['./sw4pt6.component.css']
})
export class Sw4pt6Component implements OnInit {

  @Output() nextquestion1 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  disable: boolean;
  existingData: any;
  loader: boolean;

  constructor(private $gaService: GoogleAnalyticsService,private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    this.loader = true;
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt6')[0];
    console.log('sw questions app-sw4pt6 : ', this.questions);

    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.stillwater;
      console.log('data from getAppData sw : ', this.existingData);
      debugger;
      if (this.existingData[this.questions.radio_options[0].answer_key] == 'yes') {
        this.questions.radio_options[0].active = true;
        this.questions.radio_options[1].active = false;
        this.disable = false;
      }
      else if (this.existingData[this.questions.radio_options[0].answer_key] == 'no'){
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = true;
        this.disable = false;
      }
      else {
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = false;
        this.disable = true;
      }
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });
    // if (localStorage.getItem('sw_primaryResidence') == 'yes') {
    //   this.questions.radio_options[0].active = true;
    //   this.questions.radio_options[1].active = false;
    //   this.disable = false;
    // }
    // else if (localStorage.getItem('sw_primaryResidence') == 'no'){
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = true;
    //   this.disable = false;
    // }
    // else {
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = false;
    //   this.disable = true;
    // }

  }

  onYes() {
    this.questions.radio_options[0].active = true;
    this.questions.radio_options[1].active = false;
    localStorage.setItem('sw_primaryResidence', 'yes');
    this.disable = false;
  }

  onNo() {
    this.questions.radio_options[0].active = false;
    this.questions.radio_options[1].active = true;
    localStorage.setItem('sw_primaryResidence', 'no');
    this.disable = false;
  }


  next() {
    this.loader = true;
    let knockout = 0;
    if (localStorage.getItem('sw_primaryResidence') == 'no') {
      knockout = 1;
    }
    //debugger
    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };
    json.stillwater[this.questions.radio_options[0].answer_key] = localStorage.getItem(this.questions.radio_options[0].answer_key);
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(
      data => {
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0,
        'knockout': knockout
      };
      this.nextquestion1.emit(etty);
    }).catch(error => {
      console.log(error);
    });
    this.$gaService.event('Next', 'event', 'HomeAppPrimaryResidence');
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.nextquestion1.emit(etty);
  }

}
