import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-is-dependent',
  templateUrl: './is-dependent.component.html',
  styleUrls: ['./is-dependent.component.css']
})
export class IsDependentComponent implements OnInit {
  FormVisible: boolean;
  heroForm: any;
  thirdPartyFirstName: any;
  thirdPartyLastName: any;
  constructor(private pc: ProcessCacheService) { }
  @Input() questionDetails: [];
  // @Output() isDependentOutput = new EventEmitter();

  @Output() public isDependentOutput =
    new EventEmitter<{ component_id: any, answer: any }>();

  SSN: any;
  Relationship: any;
  EmailID: any;
  Address: any;
  RelationShipOptions: any;
  ngOnInit(): void {
    this.heroForm = new FormGroup({
      thirdPartyFirstName: new FormControl(this.thirdPartyFirstName),
      thirdPartyLastName: new FormControl(this.thirdPartyLastName)

    });
    this.InActiveTickIcon = true;
    this.InActiveCrossIcons = true;
    this.SSN = "";
    this.Relationship = "";
    this.EmailID = "";
    this.Address = "";
    this.RelationShipOptions = this.questionDetails["child_question_2"]["answer_options"];

  }

  inputJson
  public onSubmit(data) {
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);
      // this.isDependentOutput.emit(this.questionDetails["component_id"]);
      this.inputJson = {
        'id': localStorage.getItem('clientID'),
        'areYouDependent': this.selectedOptionValue,
        'address': this.Address,
        'relationship': this.Relationship,
        'EmailID': this.EmailID,
        'SSN': this.SSN
      }
      this.isDependentOutput.emit({ component_id: this.questionDetails["component_id"], answer: this.inputJson });
      this.submitdata(this.inputJson);
    } else {
      alert("Declined!");
    }
  }

  public submitdata(inputJson) {

    this.pc.saveUpdateClientData(inputJson);
  }

  SelectedOption: any;
  SecondQuestion: any;
  ActiveTickIcons: boolean;
  InActiveTickIcon: boolean;
  ActiveCrossIcons: boolean;
  InActiveCrossIcons: boolean;
  selectedOptionValue: any;
  public GetOptions(data) {

    this.SelectedOption = data;
    if (this.SelectedOption == 0) {
      this.selectedOptionValue = "No";
      document.getElementById('0').style.border = 'solid 2px orange';
      document.getElementById('1').style.border = 'solid 1px #dae7ec';
      this.FormVisible = true;
      this.ActiveTickIcons = false;
      this.InActiveTickIcon = true;
      this.ActiveCrossIcons = true;
      this.InActiveCrossIcons = false;

    } else {
      this.selectedOptionValue = "Yes";
      document.getElementById('1').style.border = 'solid 2px orange';
      document.getElementById('0').style.border = 'solid 1px #dae7ec';
      this.FormVisible = false;
      this.ActiveTickIcons = true;
      this.InActiveTickIcon = false;
      this.ActiveCrossIcons = false;
      this.InActiveCrossIcons = true;

    }
  }


  public goBack() {

    // this.isDependentOutput.emit(this.questionDetails["component_id"]+",Goback");

    this.isDependentOutput.emit({ component_id: this.questionDetails["component_id"] + ",Goback", answer: this.inputJson });
  }

}
