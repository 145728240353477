import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css']
})
export class DrawerComponent implements OnInit {

  constructor(private modalService: NgbModal) { }
  @Input() carrierData :any;
  @Input() payfrequency :any;
  ngOnInit(): void {
  }

  closeDrawer() {

    this.modalService.dismissAll();
  }

}
