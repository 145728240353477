import { Injectable } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ProcessCacheService } from './process-cache.service';
declare var require: any;
//const sbliQuestionhelper = require('./../jsHelpers/Sblihelper.js');
var sbliQuestions = require('../../assets/SBLI_getAPPQ.json');
var bubbleJson = require('../../assets/questionAnswers.json');

@Injectable({
  providedIn: 'root'
})
export class SbliHelperService {
  sbliHelper: any;
  finalJSON = [];
  questionAnserkeyMapping: any;
  questionComponentMapping: any;

  constructor(private http: HttpClientModule, private pc: ProcessCacheService,) {
    //debugger
    // this.sbliHelper = new sbliQuestionhelper.SbliQuestionHelper();
    // let finalJSON = this.sbliHelper.getSbliQuestionAnswerJson()
    // console.log("finalJSON:", finalJSON);
  }

  sbliJsonStatic: any;

  async getSbliQuestionAnswerJson(state = "CA", sbliQuestion = sbliQuestions, clientId) {
    debugger
    let stateId = state;
    this.sbliJsonStatic = sbliQuestion;

    this.questionComponentMapping = [
      {
        "ca_questionID": 1001,
        "icc_questionID": 1001,
        "component_id": "app-sbli00701001",
        "answers_type": "input"
      },
      {
        "ca_questionID": 1002,
        "icc_questionID": 1002,
        "component_id": "app-sbli00701001",
        "answers_type": "input"
      },
      {
        "ca_questionID": 1003,
        "icc_questionID": 1003,
        "component_id": "app-sbli00701001",
        "answers_type": "input"
      },
      {
        "ca_questionID": 1010,
        "icc_questionID": 1010,
        "component_id": "app-sbli00201010",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1011,
        "icc_questionID": 1011,
        "component_id": "app-sbli00301011",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1020,
        "icc_questionID": 1020,
        "component_id": "app-sbli00601020",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1030,
        "icc_questionID": 1030,
        "component_id": "app-sbli00501030",
        "answers_type": "input"
      },
      {
        "ca_questionID": 1980,
        "icc_questionID": 1980,
        "component_id": "app-sbli00501030",
        "answers_type": "input"
      },
      {
        "ca_questionID": 1990,
        "icc_questionID": 1990,
        "component_id": "app-sbli01051040",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 2000,
        "icc_questionID": 2000,
        "component_id": "app-sbli01051040",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1040,
        "icc_questionID": 1040,
        "component_id": "app-sbli01051040",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 2020,
        "icc_questionID": 2020,
        "component_id": "app-sbli01051040",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1061,
        "icc_questionID": 1061,
        "component_id": "app-sbli02701061",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1080,
        "icc_questionID": 1080,
        "component_id": "app-sbli01001080",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "component_id": "app-sbli02601100",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1120,
        "icc_questionID": 1120,
        "component_id": "app-sbli00401120",
        "answers_type": "radio"
      },

      {
        "ca_questionID": 1960,
        "icc_questionID": 1960,
        "component_id": "app-sbli00401120",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1970,
        "icc_questionID": 1970,
        "component_id": "app-sbli00401120",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1130,
        "icc_questionID": 1130,
        "component_id": "app-sbli00601020",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1141,
        "icc_questionID": 1140,
        "component_id": "app-sbli01101140",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1150,
        "icc_questionID": 1150,
        "component_id": "app-sbli01101140",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1171,
        "icc_questionID": 1170,
        "component_id": "app-sbli02801170",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1175,
        "icc_questionID": 1175,
        "component_id": "app-sbli02801170",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1180,
        "icc_questionID": 1180,
        "component_id": "app-sbli01401180",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1190,
        "icc_questionID": 1190,
        "component_id": "app-sbli01401180",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1210,
        "icc_questionID": 1210,
        "component_id": "app-sbli01401180",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1220,
        "icc_questionID": 1220,
        "component_id": "app-sbli01801220",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1230,
        "icc_questionID": 1230,
        "component_id": "app-sbli01801220",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1240,
        "icc_questionID": 1240,
        "component_id": "app-sbli02001240",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "component_id": "app-sbli02501250",
        "answers_type": ""
      },
      {
        "ca_questionID": 1260,
        "icc_questionID": 1260,
        "component_id": "app-sbli00101260",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1270,
        "icc_questionID": 1270,
        "component_id": "app-sbli02101270",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1680,
        "icc_questionID": 1680,
        "component_id": "app-sbli02901680",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 2010,
        "icc_questionID": 2010,
        "component_id": "app-sbli02901680",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 2015,
        "icc_questionID": 2015,
        "component_id": "app-sbli02901680",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1930,
        "icc_questionID": 1930,
        "component_id": "app-sbli02201930",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1940,
        "icc_questionID": 1940,
        "component_id": "app-sbli02201930",
        "answers_type": "radio"
      },
      {
        "ca_questionID": 1950,
        "icc_questionID": 1950,
        "component_id": "app-sbli02201930",
        "answers_type": "radio"
      }
    ];

    this.questionAnserkeyMapping = [
      {
        "ca_questionID": 1002,
        "icc_questionID": 1002,
        "answerOptionId": null,
        "component_id": "app-sbli00701001",
        "answer_key": "sbli_height"
      },
      {
        "ca_questionID": 1003,
        "icc_questionID": 1003,
        "answerOptionId": null,
        "component_id": "app-sbli00701001",
        "answer_key": "sbli_weight"
      },
      {
        "ca_questionID": 1010,
        "icc_questionID": 1010,
        "answerOptionId": null,
        "component_id": "app-sbli00201010",
        "answer_key": "sbli_hasPending"
      },
      {
        "ca_questionID": 1011,
        "icc_questionID": 1011,
        "answerOptionId": null,
        "component_id": "app-sbli00301011",
        "answer_key": "sbli_annuity"
      },
      {
        "ca_questionID": 1020,
        "icc_questionID": 1020,
        "answerOptionId": null,
        "component_id": "app-sbli00601020",
        "answer_key": "sbli_employee"
      },
      {
        "ca_questionID": 1030,
        "icc_questionID": 1030,
        "answerOptionId": null,
        "component_id": "app-sbli00501030",
        "answer_key": "sbli_annualIncome"
      },
      {
        "ca_questionID": 1980,
        "icc_questionID": 1980,
        "answerOptionId": 1130,
        "component_id": "app-sbli00501030",
        "answer_key": "sbli_householdIncome"
      },
      {
        "ca_questionID": 1990,
        "icc_questionID": 1990,
        "answerOptionId": 1130,
        "component_id": "app-sbli00501030",
        "answer_key": "sbli_actively_seeking_work"
      },
      {
        "ca_questionID": 2000,
        "icc_questionID": 2000,
        "answerOptionId": 1990,
        "component_id": "app-sbli00501030",
        "answer_key": "sbli_last_actively_working"
      },
      {
        "ca_questionID": 1040,
        "icc_questionID": 1040,
        "answerOptionId": null,
        "component_id": "app-sbli01051040",
        "answer_key": "sbli_disablility_benefits"
      },
      {
        "ca_questionID": 2020,
        "icc_questionID": 2020,
        "answerOptionId": 1040,
        "component_id": "app-sbli01051040",
        "answer_key": "last_disablity_claim"
      },
      {
        "ca_questionID": 1061,
        "icc_questionID": 1061,
        "answerOptionId": null,
        "component_id": "app-sbli02701061",
        "answer_key": "sbli_convicted_reckless_driving"
      },
      {
        "ca_questionID": 1120,
        "icc_questionID": 1120,
        "answerOptionId": null,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_military"
      },
      {
        "ca_questionID": 19601,
        "icc_questionID": 19601,
        "answerOptionId": 1120,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_active_duty"
      },
      {
        "ca_questionID": 19602,
        "icc_questionID": 19602,
        "answerOptionId": 1120,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_active_duty"
      },
      {
        "ca_questionID": 19701,
        "icc_questionID": 19701,
        "answerOptionId": 1960,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_trusted_area"
      },
      {
        "ca_questionID": 19702,
        "icc_questionID": 19702,
        "answerOptionId": 1960,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_trusted_area"
      },
      {
        "ca_questionID": 19703,
        "icc_questionID": 19703,
        "answerOptionId": 1960,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_trusted_area"
      },
      {
        "ca_questionID": 1141,
        "icc_questionID": 1141,
        "answerOptionId": null,
        "component_id": "app-sbli01101140",
        "answer_key": "sbli_parentsibling_disease"
      },
      {
        "ca_questionID": 1150,
        "icc_questionID": 1150,
        "answerOptionId": null,
        "component_id": "app-sbli01101140",
        "answer_key": "sbli_disease_sibling_died"
      },
      {
        "ca_questionID": 1160,
        "icc_questionID": 1160,
        "answerOptionId": null,
        "component_id": "app-sbli01101140",
        "answer_key": "sbli_disease_two_or_more_sibling_died"
      },
      {
        "ca_questionID": 1180,
        "icc_questionID": 1180,
        "answerOptionId": null,
        "component_id": "app-sbli01401180",
        "answer_key": "sbli_two_year_plan"
      },
      {
        "ca_questionID": 1190,
        "icc_questionID": 1190,
        "answerOptionId": null,
        "component_id": "app-sbli01401180",
        "answer_key": "sbli_two_year_plan_pilot"
      },
      {
        "ca_questionID": 1210,
        "icc_questionID": 1210,
        "answerOptionId": null,
        "component_id": "app-sbli01401180",
        "answer_key": "sbli_two_year_plan_underwater"
      },
      {
        "ca_questionID": 1220,
        "icc_questionID": 1220,
        "answerOptionId": null,
        "component_id": "app-sbli01801220",
        "answer_key": "sbli_diagnosed_or_tratment_cancer"
      },
      {
        "ca_questionID": 1230,
        "icc_questionID": 1230,
        "answerOptionId": null,
        "component_id": "app-sbli01801220",
        "answer_key": "sbli_carcinoma_skin"
      },
      {
        "ca_questionID": 1240,
        "icc_questionID": 1240,
        "answerOptionId": null,
        "component_id": "app-sbli02001240",
        "answer_key": "sbli_diagnosed_or_tratment_drug_abuse"
      },
      {
        "ca_questionID": 1260,
        "icc_questionID": 1260,
        "answerOptionId": null,
        "component_id": "app-sbli00101260",
        "answer_key": "sbli_us"
      },
      {
        "ca_questionID": 1270,
        "icc_questionID": 1270,
        "answerOptionId": null,
        "component_id": "app-sbli02101270",
        "answer_key": "sbli_guilty_felony"
      },
      {
        "ca_questionID": 1680,
        "icc_questionID": 1680,
        "answerOptionId": null,
        "component_id": "app-sbli02901680",
        "answer_key": "sbli_hospitalized_other_reason"
      },
      {
        "ca_questionID": 2010,
        "icc_questionID": 2010,
        "answerOptionId": 1680,
        "component_id": "app-sbli02901680",
        "answer_key": "sbli_chronic_illness"
      },
      {
        "ca_questionID": 2015,
        "icc_questionID": 2015,
        "answerOptionId": 1680,
        "component_id": "app-sbli02901680",
        "answer_key": "sbli_hospitalized_reason"
      },
      {
        "ca_questionID": 1930,
        "icc_questionID": 1930,
        "answerOptionId": null,
        "component_id": "app-sbli02201930",
        "answer_key": "sbli_diagnosed_or_treatment_Covid"
      },
      {
        "ca_questionID": 1940,
        "icc_questionID": 1940,
        "answerOptionId": null,
        "component_id": "app-sbli02201930",
        "answer_key": "sbli_covid_positive_last_30days"
      },
      {
        "ca_questionID": 1950,
        "icc_questionID": 1950,
        "answerOptionId": null,
        "component_id": "app-sbli02201930",
        "answer_key": "sbli_Covid_treatment"
      },
      {
        "ca_questionID": 1080,
        "icc_questionID": 1080,
        "answerOptionId": 10801,
        "component_id": "app-sbli01001080",
        "answer_key": "tobacco_usage_one"
      },
      {
        "ca_questionID": 1080,
        "icc_questionID": 1080,
        "answerOptionId": 10802,
        "component_id": "app-sbli01001080",
        "answer_key": "tobacco_usage_two"
      },
      {
        "ca_questionID": 1080,
        "icc_questionID": 1080,
        "answerOptionId": 10803,
        "component_id": "app-sbli01001080",
        "answer_key": "tobacco_usage_three"
      },
      {
        "ca_questionID": 1080,
        "icc_questionID": 1080,
        "answerOptionId": 10804,
        "component_id": "app-sbli01001080",
        "answer_key": "tobacco_usage_four"
      },
      {
        "ca_questionID": 1080,
        "icc_questionID": 1080,
        "answerOptionId": 10805,
        "component_id": "app-sbli01001080",
        "answer_key": "tobacco_usage_five"
      },
      {
        "ca_questionID": 1130,
        "icc_questionID": 1130,
        "answerOptionId": 11301,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_employee_retired"
      },
      {
        "ca_questionID": 1130,
        "icc_questionID": 1130,
        "answicc_questionIDerOptionId": 11302,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_employee_nonworkinspouce"
      },
      {
        "ca_questionID": 1130,
        "icc_questionID": 1130,
        "answerOptionId": 11303,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_employee_fulltimestudent"
      },
      {
        "ca_questionID": 1130,
        "icc_questionID": 1130,
        "answerOptionId": 11304,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_employee_notworking"
      },
      {
        "ca_questionID": 1135,
        "icc_questionID": 1135,
        "answerOptionId": 11351,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_Student_Type_bachelordegree"
      },
      {
        "ca_questionID": 1135,
        "icc_questionID": 1135,
        "answerOptionId": 11352,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_Student_Type_masterdegree"
      },
      {
        "ca_questionID": 1135,
        "icc_questionID": 1135,
        "answerOptionId": 11353,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_Student_Type_doctorate"
      },
      {
        "ca_questionID": 1135,
        "icc_questionID": 1135,
        "answerOptionId": 11354,
        "component_id": "app-sbli00401120",
        "answer_key": "sbli_Student_Type_other"
      },
      {
        "ca_questionID": 1175,
        "icc_questionID": 1175,
        "answerOptionId": null,
        "component_id": "app-sbli02801170",
        "answer_key": "sbli_moving_violations_convicted"
      },
      {
        "ca_questionID": 1170,
        "icc_questionID": 1171,
        "answerOptionId": 11701,
        "component_id": "app-sbli02801170",
        "answer_key": "sbli_moving_violations_convicted_count_one"
      },
      {
        "ca_questionID": 1170,
        "icc_questionID": 1171,
        "answerOptionId": 11702,
        "component_id": "app-sbli02801170",
        "answer_key": "sbli_moving_violations_convicted_count_two"
      },
      {
        "ca_questionID": 1170,
        "icc_questionID": 1171,
        "answerOptionId": 11703,
        "component_id": "app-sbli02801170",
        "answer_key": "sbli_moving_violations_convicted_count_three"
      },
      {
        "ca_questionID": 1170,
        "icc_questionID": 1171,
        "answerOptionId": 11704,
        "component_id": "app-sbli02801170",
        "answer_key": "sbli_moving_violations_convicted_count_four"
      },
      {
        "ca_questionID": 1170,
        "icc_questionID": 1171,
        "answerOptionId": 11705,
        "component_id": "app-sbli02801170",
        "answer_key": "sbli_moving_violations_convicted_count_five"
      },
      {
        "ca_questionID": 1170,
        "icc_questionID": 1171,
        "answerOptionId": 11706,
        "component_id": "app-sbli02801170",
        "answer_key": "sbli_moving_violations_convicted_count_six"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250111,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option1"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250112,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option2"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250113,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option3"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250114,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option4"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250115,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option5"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250116,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option6"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250117,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option7"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250118,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option8"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250119,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option9"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 12501110,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option10"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 12501111,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_surgeryInfo_Option11"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250211,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option1"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250212,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option2"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250213,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option3"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250214,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option4"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250215,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option5"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250216,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option6"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250217,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option7"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250218,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option8"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 1250219,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option9"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 12502110,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option10"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 12502111,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option11"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 12502112,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_anyTestInfo_Option12"
      },
      {
        "ca_questionID": 1250,
        "icc_questionID": 1250,
        "answerOptionId": 12503,
        "component_id": "app-sbli02501250",
        "answer_key": "sbli_Surgery_noneOfTheAbove"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 110053,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_depression"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 110041,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_diabetes_treated"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 1100412,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_oral_medication"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 1100413,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Hemoglobin_below7"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 110011,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Emphysema"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 110019,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Peripheral"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 11007,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_HIV"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 11008,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Kidneydisease"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 11009,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Livercirrhosis"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 110015,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_ALS"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 110018,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Stroke"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 110012,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Rheumatoidarthritis"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 1100131,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_SleepApnea"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 110021,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Asthma"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 11006,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Heartdisease"
      },
      {
        "ca_questionID": 1100,
        "icc_questionID": 1100,
        "answerOptionId": 110014,
        "component_id": "app-sbli01001080",
        "answer_key": "sbli_Noneoftheabove"
      }
    ];

    let questtions = this.sbliJsonStatic;
    let AnswerJson = bubbleJson;

    //sriknaths code
    let questionIDarray = [];
    for (let i = 0; i < questtions.length; i++) {
      if (questtions[i].Parent_question_id == null) {
        questionIDarray.push(questtions[i]);
      }
    }

    questionIDarray = questionIDarray.filter(x => x.questionID != 1002);
    questionIDarray = questionIDarray.filter(x => x.questionID != 1003);
    questionIDarray = questionIDarray.filter(x => x.questionID != 1130);
    questionIDarray = questionIDarray.filter(x => x.questionID != 1135);
    questionIDarray = questionIDarray.filter(x => x.questionID != 1150);
    questionIDarray = questionIDarray.filter(x => x.questionID != 1160);
    questionIDarray = questionIDarray.filter(x => x.questionID != 1230);

    for (let j = 0; j < questionIDarray.length; j++) {

      let simpleQuestions = ["app-sbli0015ssn", "app-sbli00101260", "app-sbli00201010", "app-sbli00301011", "app-sbli02001240", "app-sbli02101270", "app-sbli02701061", "app-sbli02801170"];
      let hwQuestions = "app-sbli00701001";
      let incomeQue = "app-sbli00501030";
      let workingQue = "app-sbli00601020";
      let smokingQue = "app-sbli01001080";
      let cancerQue = "app-sbli01101140";
      let cancerTreatQue = "app-sbli01801220";
      let nextTwoYearQue = "app-sbli01401180";
      let covidQue = "app-sbli02201930";
      let surgeryQueOne = "app-sbli02501250";
      let diseaseQue = "app-sbli02601100";
      let military = "app-sbli00401120";
      let disablity = "app-sbli01051040";
      let hopitalized = "app-sbli02901680";


      let component_details;
      if (stateId == "CA") {
        component_details = this.questionComponentMapping.filter(x => x.ca_questionID == questionIDarray[j].questionID);
      }
      else {
        component_details = this.questionComponentMapping.filter(x => x.icc_questionID == questionIDarray[j].questionID);
      }
      //debugger
      let sbliJson_details = this.sbliJsonStatic.filter(x => x.questionID == questionIDarray[j].questionID)[0];
      let bubbleJson_details = bubbleJson.questions.filter(x => x.component_id == component_details[0].component_id)[0];

      if (simpleQuestions.includes(component_details[0].component_id)) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == hwQuestions) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == incomeQue) {
        bubbleJson_details["question_sub_text"] = sbliJson_details["question_text"];
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == workingQue) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        let subq1 = questtions.filter(x => x.questionID == 1130)[0].answers;
        bubbleJson_details.radio_options_1[0]["answer_text"] = subq1[0]["answer_text"];
        bubbleJson_details.radio_options_1[1]["answer_text"] = subq1[1]["answer_text"];
        bubbleJson_details.radio_options_1[2]["answer_text"] = subq1[2]["answer_text"];
        bubbleJson_details.radio_options_1[3]["answer_text"] = subq1[3]["answer_text"];
        let subq2 = questtions.filter(x => x.questionID == 1135)[0].answers;
        bubbleJson_details.radio_options_2[0]["answer_text"] = subq2[0]["answer_text"];
        bubbleJson_details.radio_options_2[1]["answer_text"] = subq2[1]["answer_text"];
        bubbleJson_details.radio_options_2[2]["answer_text"] = subq2[2]["answer_text"];
        bubbleJson_details.radio_options_2[3]["answer_text"] = subq2[3]["answer_text"];
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == smokingQue) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        let subq1 = sbliJson_details["answers"];
        bubbleJson_details.radio_options_1[0]["answer_text"] = subq1[0]["answer_text"];
        bubbleJson_details.radio_options_1[1]["answer_text"] = subq1[1]["answer_text"];
        bubbleJson_details.radio_options_1[2]["answer_text"] = subq1[2]["answer_text"];
        bubbleJson_details.radio_options_1[3]["answer_text"] = subq1[3]["answer_text"];
        bubbleJson_details.radio_options_1[4]["answer_text"] = subq1[4]["answer_text"];
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == cancerQue) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        bubbleJson_details["question_sub_text_1"] = questtions.filter(x => x.questionID == 1150)[0].question_text;
        bubbleJson_details["question_sub_text_2"] = questtions.filter(x => x.questionID == 1160)[0].question_text;
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == cancerTreatQue) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        bubbleJson_details["question_sub_text_1"] = questtions.filter(x => x.questionID == 1230)[0].question_text;
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == nextTwoYearQue) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        bubbleJson_details["question_sub_text_1"] = questtions.filter(x => x.questionID == 1190)[0].question_text;
        bubbleJson_details["question_sub_text_2"] = questtions.filter(x => x.questionID == 1210)[0].question_text;
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == covidQue) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        bubbleJson_details["question_sub_text_1"] = questtions.filter(x => x.questionID == 1940)[0].question_text;
        bubbleJson_details["question_sub_text_2"] = questtions.filter(x => x.questionID == 1950)[0].question_text;
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == military) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        bubbleJson_details["question_sub_text_1"] = questtions.filter(x => x.questionID == 1120)[0].question_text;
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == disablity) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        bubbleJson_details["question_sub_text_1"] = questtions.filter(x => x.questionID == 1040)[0].question_text;
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == hopitalized) {
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];
        bubbleJson_details["question_sub_text_1"] = questtions.filter(x => x.questionID == 1680)[0].question_text;
        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == surgeryQueOne) {
        debugger
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];

        let subq = sbliJson_details["answers"];

        bubbleJson_details.sub_questions.question_1.question_text = subq.filter(x => x.answer_option_id == 12501)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.question_sub_text = subq.filter(x => x.answer_option_id == 125011)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[0].answer_label = subq.filter(x => x.answer_option_id == 1250111)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[1].answer_label = subq.filter(x => x.answer_option_id == 1250112)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[2].answer_label = subq.filter(x => x.answer_option_id == 1250113)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[3].answer_label = subq.filter(x => x.answer_option_id == 1250114)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[4].answer_label = subq.filter(x => x.answer_option_id == 1250115)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[5].answer_label = subq.filter(x => x.answer_option_id == 1250116)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[6].answer_label = subq.filter(x => x.answer_option_id == 1250117)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[7].answer_label = subq.filter(x => x.answer_option_id == 1250118)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[8].answer_label = subq.filter(x => x.answer_option_id == 1250119)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[9].answer_label = subq.filter(x => x.answer_option_id == 12501110)[0].answer_text;
        bubbleJson_details.sub_questions.question_1.checkbox_options[10].answer_label = subq.filter(x => x.answer_option_id == 12501111)[0].answer_text;

       if(stateId=='CA'){ bubbleJson_details.sub_questions.question_2.question_text = subq.filter(x => x.answer_option_id == 12504)[0].answer_text;}
        bubbleJson_details.sub_questions.question_2.question_sub_text = subq.filter(x => x.answer_option_id == 125021)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[0].answer_label = subq.filter(x => x.answer_option_id == 1250211)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[1].answer_label = subq.filter(x => x.answer_option_id == 1250212)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[2].answer_label = subq.filter(x => x.answer_option_id == 1250213)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[3].answer_label = subq.filter(x => x.answer_option_id == 1250214)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[4].answer_label = subq.filter(x => x.answer_option_id == 1250215)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[5].answer_label = subq.filter(x => x.answer_option_id == 1250216)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[6].answer_label = subq.filter(x => x.answer_option_id == 1250217)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[7].answer_label = subq.filter(x => x.answer_option_id == 1250218)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[8].answer_label = subq.filter(x => x.answer_option_id == 1250219)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[9].answer_label = subq.filter(x => x.answer_option_id == 12502110)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[10].answer_label = subq.filter(x => x.answer_option_id == 12502111)[0].answer_text;
        bubbleJson_details.sub_questions.question_2.checkbox_options[11].answer_label = subq.filter(x => x.answer_option_id == 12502112)[0].answer_text;


        this.finalJSON.push(bubbleJson_details);
      }
      else if (component_details[0].component_id == diseaseQue) {
        debugger
        bubbleJson_details["question_text"] = sbliJson_details["question_text"];

        let subq = sbliJson_details["answers"];

        bubbleJson_details.checkbox_options[0].answer_label = subq.filter(x => x.answer_option_id == 11005)[0].answer_text;
        bubbleJson_details.checkbox_options[0].question_sub_text = subq.filter(x => x.answer_option_id == 110053)[0].answer_text;

        bubbleJson_details.checkbox_options[1].answer_label = subq.filter(x => x.answer_option_id == 11004)[0].answer_text;
        bubbleJson_details.checkbox_options[1].sub_questions[0].question_text = subq.filter(x => x.answer_option_id == 110041)[0].answer_text;
        bubbleJson_details.checkbox_options[1].sub_questions[1].question_text = subq.filter(x => x.answer_option_id == 1100412)[0].answer_text;
        bubbleJson_details.checkbox_options[1].sub_questions[2].question_text = subq.filter(x => x.answer_option_id == 1100413)[0].answer_text;

        bubbleJson_details.checkbox_options[2].answer_label = subq.filter(x => x.answer_option_id == 110011)[0].answer_text;
        bubbleJson_details.checkbox_options[3].answer_label = subq.filter(x => x.answer_option_id == 110019)[0].answer_text;
        if (stateId == 'CA') {
          bubbleJson_details.checkbox_options[4].answer_label = subq.filter(x => x.answer_option_id == 11007)[0].answer_text;
        }
        bubbleJson_details.checkbox_options[5].answer_label = subq.filter(x => x.answer_option_id == 11008)[0].answer_text;
        bubbleJson_details.checkbox_options[6].answer_label = subq.filter(x => x.answer_option_id == 11009)[0].answer_text;
        bubbleJson_details.checkbox_options[7].answer_label = subq.filter(x => x.answer_option_id == 110015)[0].answer_text;
        bubbleJson_details.checkbox_options[8].answer_label = subq.filter(x => x.answer_option_id == 110018)[0].answer_text;

        bubbleJson_details.checkbox_options[9].answer_label = subq.filter(x => x.answer_option_id == 110012)[0].answer_text;
        bubbleJson_details.checkbox_options[9].question_sub_text = subq.filter(x => x.answer_option_id == 1100121)[0].answer_text;

        bubbleJson_details.checkbox_options[10].answer_label = subq.filter(x => x.answer_option_id == 110013)[0].answer_text;
        bubbleJson_details.checkbox_options[10].question_sub_text = subq.filter(x => x.answer_option_id == 1100131)[0].answer_text;

        bubbleJson_details.checkbox_options[11].answer_label = subq.filter(x => x.answer_option_id == 11002)[0].answer_text;
        bubbleJson_details.checkbox_options[11].question_sub_text = subq.filter(x => x.answer_option_id == 110021)[0].answer_text;

        bubbleJson_details.checkbox_options[12].answer_label = subq.filter(x => x.answer_option_id == 11006)[0].answer_text;

        bubbleJson_details.checkbox_options[13].answer_label = subq.filter(x => x.answer_option_id == 110014)[0].answer_text;

        this.finalJSON.push(bubbleJson_details);
      }
      else {
        this.finalJSON.push({});
      }



    }



    console.log("finalJSON:", this.finalJSON);
    let sbli_answer_data = {
      'id': clientId,
      'SBLI_QuestionAnswerJson': this.finalJSON
    };
    let save = await this.pc.saveUpdateClientData(sbli_answer_data);

    return this.finalJSON;

  }

}
