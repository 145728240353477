import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-has-liver-pancrease-disease',
  templateUrl: './has-liver-pancrease-disease.component.html',
  styleUrls: ['./has-liver-pancrease-disease.component.css']
})
export class HasLiverPancreaseDiseaseComponent implements OnInit {
  @Output() nextquestion12 = new EventEmitter();
  constructor() { }
  ngOnInit(): void {
  }
  SelectedOption:any;
  public GetOptionsyesno(data){
  
    this.SelectedOption=data;
    if(this.SelectedOption==0){
      document.getElementById('LiverDiseaseNo').style.border='solid 2px orange';
      document.getElementById('LiverDiseaseyes').style.border='solid 1px #dae7ec';
     
    }else{
     document.getElementById('LiverDiseaseyes').style.border='solid 2px orange';
     document.getElementById('LiverDiseaseNo').style.border='solid 1px #dae7ec';
    }
  }

  next() {
    let etty = {
      "questionNo": "12",
      "nextprev": 1
    }
    this.nextquestion12.emit(etty);
  }



}
