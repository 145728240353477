import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-personal-details-question7',
  templateUrl: './personal-details-question7.component.html',
  styleUrls: ['./personal-details-question7.component.css']
})
export class PersonalDetailsQuestion7Component implements OnInit {
  @Output() nextquestion6 = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }


  next() {
    let etty = {
      "questionNo": "6",
      "nextprev": 1
    }
    this.nextquestion6.emit(etty);
  }
}
