import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-life-compare-plans',
  templateUrl: './life-compare-plans.component.html',
  styleUrls: ['./life-compare-plans.component.css']
})
export class LifeComparePlansComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private modal: NgbModal) { }
  @Input() carrierData: any;
  @Input() payfrequency: any;
  compareValues = [];
  @Input() public user;
  @Output() continueJourney: EventEmitter<any> = new EventEmitter();

  coverages = [
    'A.M. Best Rating',
    'Your Coverage',
    'Your Term',
    'Policy Name',
    'Length of Coverage',
    'Renewable?',
    'Convertible to Permanent Life Insurance?',
    'Riders Available',
    'Return of Premium?',
    'Minimum Coverage Amount',
    'Maximum Coverage Amount',
    'Medical Exam Required?',
    'Best for?',
    'Underwriting Strengths',
  ];

  keys = [
    'rating',
    'coverage',
    'term',
    'policy_name',
    'length_of_coverage',
    'renewable',
    'convertible',
    '',
    'return_of_premium',
    'min_coverage',
    'max_coverage',
    'medical_exam_required',
    'best_for',
    'underwriting_strengths',
  ];

  ridersToShow=[
    'Accelerated death benefit rider',
    ' Chronic Illness Rider',
    ' Waiver of premium rider'
  ];

  tooltipText = {
    'Accidental Death Benefit':'An accidental death benefit rider (also known as accidental death rider) in life insurance is an add-on to a life insurance policy that provides an additional payment, over and above the basic death benefit, if the insured dies due to an accident.',
    'Accidental Death Benefit (ADB) Rider':'An accidental death benefit rider (also known as accidental death rider) in life insurance is an add-on to a life insurance policy that provides an additional payment, over and above the basic death benefit, if the insured dies due to an accident.',
    'Waiver of Premium Rider':'Waives premiums for the base policy, and any attached riders, upon the total disability of the insured after a waiting period',
    'Waiver of Premium Disability Rider':'Waives premiums for the base policy, and any attached riders, upon the total disability of the insured after a waiting period',
    'Disability Waiver of Premium Rider':'Waives premiums for the base policy, and any attached riders, upon the total disability of the insured after a waiting period',
    'Children’s Life Insurance Rider':'Children’s Rider adds life insurance coverage for your minor children. Pays out death benefit in case your child/children pass away',
    'Child Rider':'Children’s Rider adds life insurance coverage for your minor children. Pays out death benefit in case your child/children pass away',
    'Children’s Insurance Rider':'Children’s Rider adds life insurance coverage for your minor children. Pays out death benefit in case your child/children pass away',
    'Children’s Benefit Rider/ Children’s Insurance Rider (CBR/CIR)':'Children’s Rider adds life insurance coverage for your minor children. Pays out death benefit in case your child/children pass away',
    'Children’s Protection Rider':'Children’s Rider adds life insurance coverage for your minor children. Pays out death benefit in case your child/children pass away',
    'Child benefit Rider':'Children’s Rider adds life insurance coverage for your minor children. Pays out death benefit in case your child/children pass away',
    'Terminal Illness Rider':'The terminal illness rider allows you to “accelerate” a percentage of the face amount of your life insurance policy while you are alive if you are diagnosed with a terminal illness.',
    'Terminal Illness Benefit':'The terminal illness rider allows you to “accelerate” a percentage of the face amount of your life insurance policy while you are alive if you are diagnosed with a terminal illness.',
    'Living Needs Benefit':'The terminal illness rider allows you to “accelerate” a percentage of the face amount of your life insurance policy while you are alive if you are diagnosed with a terminal illness.',
    'Terminal Illness Accelerated Death Benefit Rider':'The terminal illness rider allows you to “accelerate” a percentage of the face amount of your life insurance policy while you are alive if you are diagnosed with a terminal illness.',
    'Accelerated Death Benefit Rider':'The terminal illness rider allows you to “accelerate” a percentage of the face amount of your life insurance policy while you are alive if you are diagnosed with a terminal illness.',
    'Accelerated death benefit rider (at no extra cost, not available in CA)':'Access part of the death benefit if diagnosed with a qualifying serious illness',
    'Term Rider':'An additional insurance rider that provides temporary coverage for a period shorter than what the base policy provides',
    'Monthly Disability Income Rider':'Disability income riders pay a life insurance policyholder a monthly income if they become sick or injured and cannot work.',
    'Income Protection Option (IPO)': 'An Income Protection Option (IPO) rider is a type …ess that prevents the policyholder from working. ',
    // 'Child Ride': 'Pays a death benefit to the insured parent upon the death of an insured child.',
    // 'Policy Conversion': 'This option allows you to exchange your term policy for a universal life insurance policy.',
    // 'Death benefit': 'The policy pays out a lump sum of money to the beneficiaries upon the death of the policyholder.',
    // 'Flexible Payment Schedule': 'Annually, semi-annually, quarterly and monthly',
    // 'Level premium guarantee': 'Your premium amount will not change during the period you select',
    // 'Chronic Illness Benefit': 'Allows you to request a portion of your death bene…ly if diagnosed with a permanent chronic illness.',
    // 'Owner-directed settlement options': 'Enable you to choose how and when the death benefit is paid from your policy',
    // 'Accidental Death Benefit Rider': 'Accidental Death Benefit provides an additional de…if you are in an accident that causes your death.',
    // 'Coverage from $25,000 to $1M': 'Early access to a portion of the death benefit if the insured is diagnosed with a terminal illness',
    // 'Children’s Benefit Rider/ Children’s Insurance Rider (CBR/CIR)**':'Pays out death benefit in case your child/children pass away',
  };


  ngOnInit(): void {
    debugger;
    console.log('carrierData', this.carrierData);
    console.log(this.user);
    this.compareStructure();
  }


  compareStructure() {
    debugger;
    this.compareValues = [];
    const tooltip = [];

    for (let i = 0; i < this.coverages.length; i++) {
      const obj = {};
      const val = [];
      for (let j = 0; j < this.carrierData.length; j++) {
        debugger;
        const coverage_Value = i == 0 ? this.carrierData[j].carrier_details[0].rating : i == 1 ? `$${this.carrierData[j].coverage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}${this.carrierData[j].carrier.name == 'W&S' ? '/monthly' : ' lump-sum'}`
          : i == 2 ? this.carrierData[j].term : this.carrierData[j].carrier_details[0][this.keys[i]];
          //val.push(i == 8 ? coverage_Value?.split(',') : coverage_Value);
        val.push(coverage_Value);
      }
      obj['coverage_name'] = this.coverages[i].trim();
      console.log('this.coverages[i].trim();',this.coverages[i].trim());
      obj['coverage_value'] = val;
      this.compareValues.push(obj);
    }
    console.log(' const toolip', tooltip.flat);
    const first = this.compareValues.slice(0, 8);
    const second = this.compareValues.slice(8, this.compareValues.length - 1);
    this.compareValues = first.concat(this.carrierData[0].Allridersdata, second);
    const finalTooltip = [];
    for (const key in this.tooltipText) {
      const lowercaseKey = String(key).toLowerCase();
      const lowercaseValue = String(this.tooltipText[key]).toLowerCase();
      finalTooltip[lowercaseKey.toString()] = lowercaseValue;
    }
    console.log('finalTooltip',finalTooltip);
    this.compareValues.forEach((element) => {
      console.log('trim()',finalTooltip[element['coverage_name'].trim().toLowerCase()]);
      const tooltip = finalTooltip[element['coverage_name'].trim().toLowerCase()] || '';
      element['tooltip'] = this.capitalizeSentences(tooltip);
    });
    const i = this.compareValues.findIndex(item => item.coverage_name.toLowerCase() === 'Riders Available'.toLowerCase());
    console.log('compareValuesBefore', this.compareValues);
    this.compareValues = this.changeIndexByKey(this.compareValues,'Medical Exam Required?',i);
    console.log('compareValuesAfter', this.compareValues);

  }



  capitalizeSentences(inputString) {
    debugger;
    const sentences = inputString.split('. ');

    for (let i = 0; i < sentences.length; i++) {
      sentences[i] = sentences[i].charAt(0).toUpperCase() + sentences[i].slice(1);
    }

    const modifiedString = sentences.join('. ');

    return modifiedString;
  }


  changeIndexByKey(array, keyToFind, newIndex) {
    // Find the index of the object with the specified key
    const indexToMove = array.findIndex(item => item.coverage_name.toLowerCase() === keyToFind.toLowerCase());

    // If the key is not found, return the original array
    if (indexToMove === -1) {
      return array;
    }

    // Remove the object from its current position
    const removedItem = array.splice(indexToMove, 1)[0];

    // Insert the object at the new index
    array.splice(newIndex, 0, removedItem);

    return array;
  }

  continueApplicationJourney(quote) {
    this.activeModal.close(quote);
  }


}
