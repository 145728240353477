import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getQuestionAnswerJson } from "../../../appUtility/appUtility.js";
@Component({
  selector: 'app-sbli00501030',
  templateUrl: './sbli00501030.component.html',
  styleUrls: ['./sbli00501030.component.css']
})
export class Sbli00501030Component implements OnInit {
  income: any;
  test: any;
  questions: any;
  clientId: any;
  @Output() changeQuestion = new EventEmitter();
  annualIncome: any;
  ShowDollar_income: boolean;
  existingData: any;
  showLoader: boolean = true;
  disableButton: boolean;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger
    this.clientId = localStorage.getItem('clientID');

    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);

      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        let que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == "app-sbli00501030")[0];
        console.log("app-sbli00501030 questions : ", this.questions);
      } else {

        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli00501030")[0];
        console.log("app-sbli00501030 questions : ", this.questions);
      }


      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_annualIncome != undefined) {
        this.income = this.existingData.sbli_annualIncome;
        this.annualIncome = this.income;
        this.income = this.income.replace(/,/g, '');
        let ctd = this.income;
        this.income = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.test = this.annualIncome.toString().length;

        if (this.test > 0) {
          this.ShowDollar_income = true;
        }
        else {
          this.ShowDollar_income = false;
        }

        if (Number(this.annualIncome) == 0) {
          this.disableButton = true;
        }
        else {
          this.disableButton = false;
        }
      }


      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
  }


  formatIncome() {

    debugger;
    this.income = this.income.replace(/,/g, '');
    this.annualIncome = this.income;
    let ctd = this.income;
    this.income = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    this.test = this.annualIncome.toString().length;
    if (this.test > 0) {
      this.ShowDollar_income = true;
    }
    else {
      this.ShowDollar_income = false;
    }

    if (Number(this.annualIncome) == 0) {
      this.disableButton = true;
    }
    else {
      this.disableButton = false;
    }


  }


  next() {
    debugger;
    var json = {
      'id': this.clientId,
      SBLI: this.existingData
    }
    json.SBLI[this.questions.input.answer_key] = this.income;
    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }
}
