import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-app-flow',
  templateUrl: './app-flow.component.html',
  styleUrls: ['./app-flow.component.css']
})
export class AppFlowComponent implements OnInit {
  question: number;
  constructor() { }
  ShowYesOrNo: boolean;
  ngOnInit(): void {
    document.getElementById("index_title_id").innerText = "Life Insurance powered by Bubble";
    this.question = 0;
    this.ShowYesOrNo = true;


  }


  public changeQuestion(even) {
    if (even.nextprev == 1) {
      this.question = this.question + 1;
    }
  }



}
