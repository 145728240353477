import { Component, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
const pc = require('bit-uibl-data-driver');
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from './../../components/lifescore-error-alert/lifescore-error-alert.component';
import { TrackerService } from 'src/app/shared/tracker.service';
import { SbliHelperService } from '../../shared/sbli-helper.service';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { formatDate } from '@angular/common';
import { Sbli0015ssnComponent } from 'src/app/SBLIAppFlow/questions/sbli0015ssn/sbli0015ssn.component';
import { DetailsConfirmationAndSSNComponent } from 'src/app/SBLIAppFlow/questions/details-confirmation-and-ssn/details-confirmation-and-ssn.component'
import { SbliSummaryComponent } from '../questions/sbli-summary/sbli-summary.component';

@Component({
  selector: 'app-sbliappflow',
  templateUrl: './sbliappflow.component.html',
  styleUrls: ['./sbliappflow.component.css']
})

export class SbliappflowComponent implements OnInit {
  question: number;
  clientId: any;
  existingData: any;
  uniqueId: any;
  displayAddress: any;
  insurancetype: any;
  lcarrierName: any;
  lcarrierAmount: any;
  lcarriericon: any;
  hcarrierName: any;
  hcarrierAmount: any;
  lifeselectedCard: any;
  homeselectedCard: any;
  imgwid: string;
  showLoader: boolean;
  totalPages: number = 21; //change if number of pages changes
  currentPage: number = 14;
  ApplicationID: any;
  disableBackButton: boolean;
  arcId: any;
  knockOut: boolean;
  errorMessage: any;
  Footer: boolean;
  counter: any;
  backButtonDisplay: number;
  showNumber: any;
  time: any;
  showform: any;
  firstname: any;
  lastname: any;
  emailid: any;
  phone: any;
  hours: string[];
  minutes: string[];
  merdiaum: string[];
  selectedmeridum: string;
  callback_minutes: string;
  callback_hour: string;
  appointmentdate: any;
  callback_time: string;
  poupopened: any;
  state: string;
  @ViewChild(Sbli0015ssnComponent, { static: false }) Sbli0015ssnComponent: Sbli0015ssnComponent;
  @ViewChild(SbliSummaryComponent, { static: false }) SbliSummaryComponent: SbliSummaryComponent;
  @ViewChild(DetailsConfirmationAndSSNComponent, { static: false }) DetailsConfirmationAndSSNComponent: DetailsConfirmationAndSSNComponent;
  decisioncounter: any;
  dateValidation: string;
  Online: boolean;
  decisionloader: boolean;
  mindate = new Date();
  @Output() loaderText = [
    {
      'title1': 'Analyzing your profile',
      'Subtitle1': 'Just a moment…we’re analyzing your details for the next steps',
      'title2': 'Stay with us, a few more seconds',
      'Subtitle2': 'Looks like it’s taking a bit longer than usual to go over the data we have to help you avoid any medical exam…stay with us!',
      'title3': 'You are almost there!',
      'Subtitle3': 'Almost there…in a few moments you will move on to the next stage',
      'title4': 'We are working on it',
      'Subtitle4': 'We’re almost done retrieving and analyzing your details so you can get the right policy at the right price!'
    }];
  decisiontext = 'You are just seconds away from getting your application approved!';
  whiteLoader: boolean;
  submitbenecalled: any;
  sbligetdecisonapisucess: boolean;
  sblibenedata: any;
  policyNumber: any;
  emailId: any;
  appDecisonCode: any;
  showdetailssndssnpage: boolean;
  constructor(private pc: ProcessCacheService,
    private modalService: NgbModal,
    private activatedroute: ActivatedRoute, private router: Router, private tracker: TrackerService, private SbliHelperService: SbliHelperService, private service: ParentChildService) {

  }
  hubspotvalues = ['SBLI_VerifyDetails', 'SBLI_SSN', 'SBLI_Beneficiary', 'SBLI_ConsentScreen', 'SBLI_FinalConfirmation', 'SBLI_Payment', 'SBLI_ApplicationReview', 'SBLI_PolicyIssuance'];
  ngOnInit(): void {
    debugger;
    this.whiteLoader = true;
    this.firstname = localStorage.getItem('bubble-name');
    this.showNumber = 0;
    this.counter = 0;
    this.decisioncounter = 0;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insuranceType'];
      localStorage.setItem('insuranceType', this.insurancetype);
    }
    );
    this.knockOut = false;
    this.Footer = true;
    this.showLoader = true;
    this.backButtonDisplay = 0;
    this.pc.getAppData(this.clientId).then(async data => {
      debugger;
      const dataFrom = JSON.parse(data.Payload);
      console.log('get app data: ', dataFrom);
      const qno = dataFrom.body;

      this.arcId = qno['arcId'];
      const lastQuestion = qno['sbli_questionNumber'];
      let commonInput = localStorage.getItem('sbliCommonInput');
      if (commonInput == null || commonInput == undefined || commonInput === 'undefined' || commonInput === 'null') {
        await this.getCommonInput();
      }
      if (lastQuestion == undefined || lastQuestion == null || lastQuestion == '') {
        this.question = 1;
        this.updateHubspot(1);
      }
      else if (dataFrom['body'].sbliknockout == 'true') {
        this.knockOut = true;
        this.errorMessage = dataFrom['body'].sbli_knockout_message;
      }
      else {
        this.question = lastQuestion;
        this.updateHubspot(1);
      }

      if (this.question == 28 || this.question == 25) {
        this.Footer = false;
      }
      else {
        this.Footer = true;
      }
      this.showdetailssndssnpage = qno['showdetailsblissndssnpage'] ? qno['showdetailsblissndssnpage'] : false;

      const curpage = this.question + 13;
      this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: curpage });
      if (dataFrom.body['sbli_appFlowBackButton'] == '1') {
        this.disableBackButton = true;
      }
      else {
        this.disableBackButton = false;
      }

      this.imgwid = '60px';


      if (this.insurancetype == 'home') {
        const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
        console.log('selectedHomeCarrier', selectedHome);
        this.hcarrierName = selectedHome.head.carrier_name;
        this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
        this.homeselectedCard = 1;
        this.lifeselectedCard = 0;
      }
      else if (this.insurancetype == 'life') {
        const selectedLife = JSON.parse(localStorage.getItem('selectedCarrier'));
        this.lcarrierName = selectedLife.head.carrier_name;
        this.lcarrierAmount = selectedLife.head.quote_amount;
        this.lcarriericon = selectedLife.head.carrier_icon;
        this.lifeselectedCard = 1;
        this.homeselectedCard = 0;
      }
      else {
        const selectedLife = JSON.parse(localStorage.getItem('selectedCarrier'));
        this.lcarrierName = selectedLife.head.carrier_name;
        this.lcarrierAmount = selectedLife.head.quote_amount;
        this.lcarriericon = selectedLife.head.carrier_icon;
        this.lifeselectedCard = 1;
        const selectedHome = JSON.parse(localStorage.getItem('selectedHomeCarrier'));
        console.log('selectedHomeCarrier', selectedHome);
        this.hcarrierName = selectedHome.head.carrier_name;
        this.hcarrierAmount = '$' + selectedHome.head.quote_amount;
        this.homeselectedCard = 1;
      }


      if (localStorage.getItem('bubble-ApplicationID') != null) {
        this.ApplicationID = localStorage.getItem('bubble-ApplicationID');
      } else {
        this.ApplicationID = this.pc.getApplicationId(localStorage.getItem('quoteId'));
        localStorage.setItem('bubble-ApplicationID', this.ApplicationID);
      }







      if (localStorage.getItem('appFlowLinkResume') == '1') {
        localStorage.setItem('appFlowLinkResume', '0');
        this.checkforResumeInit({}, 'fetch').then(
          resumeData => {
            //debugger;
            if (resumeData['questionStartStatus'] == true && resumeData['questionCompletedStatus'] == false) {
              console.log('Resume Step 2 question no: ', +resumeData['questionSequence']);
              this.question = +resumeData['questionSequence'];
            }

          }
        );
      } else {
        const createJson = {
          insuranceType: 'life-appflow',
          carrierName: 'SBLI',
          appStartStatus: true,
          appCompletedStatus: false,
          questionStartStatus: true,
          questionCompletedStatus: false,
          questionSequence: '1',
          applicationId: '',
          otherAppflowScreen: '',
          resumeUrl: this.router.url
        };
        this.checkforResumeInit(createJson, 'create');
      }
      this.showLoader = false;
    }).catch(error => {
      console.log(error);
      this.showLoader = false;
    });

    this.service.componentMethodCalled$.subscribe(() => {
      this.showNumber = 0;
    });

    // this.pc.invokeslisubmitAPPQ.subscribe(value => {
    //   debugger
    //   this.changeQuestion(value);
    // });

  }


  //   ngAfterViewInit(): void {
  //     document.body.addEventListener("mouseleave", (e) => { this.exitPopup(); });
  //     document.body.addEventListener("mousemove", (e) => { this.idlePopup(); });
  //     document.body.addEventListener("keypress", (e) => { this.idlePopup(); });
  //   }

  //   exitPopup() {

  //     if (this.showNumber == 0) {
  //       document.getElementById("errorModal").style.display = "block";
  //       this.showNumber = 1;
  //     }
  //   }

  //   closePopup() {
  //     document.getElementById("errorModal").style.display = "none";
  //   }


  //   closetab() {
  //     debugger;
  //     window.open("https://stage.getmybubble.co/","_self");
  // }
  // idlePopup () {
  //   debugger;

  //   clearTimeout( this.time);
  //   this.time = setTimeout(()=>{ this.exitPopup(); },15000);

  // }


  async checkforResumeInit(inputJson, type) {
    await this.getCommonInput();
    const clientData = await this.pc.checkforResumeInit(inputJson, type);
    return clientData;
  }

  async changeQuestion(even) {
    debugger;
    if (even.APICall != 2 && even.sbli_answer_data != '' && even.APICall != 0 && even.APICall != 8) {
      this.showLoader = true;
    }

    // if (this.question < 20) {
    //   this.showNumber = 0;
    // }
    // else {
    //   this.showNumber = 1;
    // }

    if (even.next == '1') {

      if (even.saveData == 1) {
        debugger;
        const saveResponse = await this.pc.saveUpdateClientData(even.sbli_answer_data);
        console.log('save app data saveResponse : ', saveResponse);
        const ci = await this.getCommonInput();
      }

      if (even.APICall == 1) {
        let commonInput = localStorage.getItem('sbliCommonInput');
        commonInput = JSON.parse(commonInput);
        console.log('sbligetAPPQ call commonInput: ', commonInput);
        const quickquote = new pc.Quotes(this.uniqueId);
        quickquote.sbligetAPPQ(commonInput).then(response => {
          console.log('sbligetAPPQ res', response);
          if (response.sbliError) {
            this.openModal(response.msgText, 'Sorry!');
            // location.href = "/Sbliknockout";
            this.showLoader = false;
          }
          else {
            debugger;
            const state = commonInput['state'];
            let json = JSON.parse(response.appQJson);
            json = JSON.parse(json[0].uwqs);
            console.log('SBLIQuestions', json);
            this.SbliHelperService.getSbliQuestionAnswerJson(state, json, this.clientId).then(response => {
              console.log('getSbliQuestionAnswerJson res', response);
              setTimeout(() => {
                this.openModal('InitAPP and getAPPQ API call successfull for arcId : ' + this.arcId, 'Success');
                this.incrementQuestion();
              }, 2000);
            })
              .catch(error => {
                console.log('getSbliQuestionAnswerJson Error:', error);
                this.openModal('', 'error');
              });

          }
        })
          .catch(error => {
            console.log('sbligetAPPQ Error:', error);
            this.openModal('', 'error');
          });
      }
      else if (even.APICall == 2) {
        this.poupopened = true;
        let commonInput = localStorage.getItem('sbliCommonInput');
        commonInput = JSON.parse(commonInput);
        const quickquote = new pc.Quotes(this.uniqueId);
        quickquote.sblisubmitAPPQA(commonInput).then(async response => {
          debugger;
          console.log('sblisubmitAPPQA res', response);
          if (response.sbliError && response.msgCode != 'ARER-10013') {
            this.openModal(response.msgText, 'Sorry!');
            // location.href = "/Sbliknockout";
            this.showLoader = false;
          }
          else {
            const json = {
              'id': this.clientId,
              'SBLIsubmitAPPQCalled': true
            };
            await this.pc.saveUpdateClientData(json);
            this.showLoader = false;
            this.incrementQuestion();
            // this.Sbli0015ssnComponent.stoploader();
            //this.openModal("UW submitted successfully.", "Success");
            if (response.msgCode == 'ARER-10013') {
              this.disableBackButton = true;
            }
            // quickquote.sblisubmitAUTH().then(response => {
            //   debugger
            //   console.log('sblisubmitAUTH res', response);
            //   if (response.sbliError) {
            //     this.openModal(response.msgText, 'Sorry!');

            //     this.showLoader = false;
            //   }
            //   else {
            //     setTimeout(() => {
            //       this.getdecisioncall();
            //     }, 2000);
            //   }
            // })
            //   .catch(error => {
            //     console.log('sbliinitApp Error:', error);
            //     this.openModal('', 'error');
            //   });
          }
        })
          .catch(error => {
            console.log('sbliinitApp Error:', error);
            this.openModal('', 'error');
          });

      }
      else if (even.APICall == 3) {
        debugger
        const quickquote = new pc.Quotes(this.uniqueId);
        if (even.Benecalled && even.calledfrombene) {
          this.submitbenecalled = true;
          this.sblibenedata = even.sbli_answer_data;
        }
        else {
          this.submitbenecalled = false;
        }
        if (this.sbligetdecisonapisucess == true && even.Benecalled) {
          quickquote.sblisubmitBENE().then(response => {
            console.log('sblisubmitBENE res', response);
            if (response.sbliError) {
              if (response.msgCode == 'ARGM-20016') {
                this.openBeneModal(response.msgText, 'Sorry!');
              }
              else {
                this.openModal(response.msgText, 'Sorry!');
              }
            }
            else {
              this.getPremiumcall(false);
            }
          })
            .catch(error => {
              console.log('sblisubmitBENE Error:', error);
              this.openModal('', 'error');
            });
        }
        else if (this.sbligetdecisonapisucess == true && !even.Benecalled) {
          this.showLoader = false;
        }
        else {
          if (this.decisioncounter < 8 && this.sbligetdecisonapisucess == false && Number(this.appDecisonCode) == 99) {
            this.showLoader = false;
            this.decisionloader = true;
          }
          else if (this.decisioncounter <= 9 && this.sbligetdecisonapisucess == false && Number(this.appDecisonCode) != 99 && even.calledfrombene) {
            this.decisionloader = false;
            this.openModal('Based on the information you have given we could not offer you a policy', 'Sorry!');
          }
          else if (this.decisioncounter == 9 && Number(this.appDecisonCode) == 99 && even.calledfrombene) {
            this.decisionloader = false;
            this.openModal('Based on the information you have given we could not offer you a policy', 'Sorry!');
          }
          else if ((this.decisioncounter <= 9 && !even.calledfrombene) && (Number(this.appDecisonCode) == 99 || Number(this.appDecisonCode) != 1)) {
            this.showLoader = false;
          }
          else if (this.decisioncounter < 8 && this.sbligetdecisonapisucess == undefined) {
            this.getdecisioncall();
          }
          else {
            this.decisionloader = false;
            quickquote.sblisubmitBENE().then(response => {
              console.log('sblisubmitBENE res', response);
              if (response.sbliError) {
                if (response.msgCode == 'ARGM-20016') {
                  this.openBeneModal(response.msgText, 'Sorry!');
                }
                else {
                  this.openModal(response.msgText, 'Sorry!');
                }
              }
              else {
                this.getPremiumcall(false);
              }
            })
              .catch(error => {
                console.log('sblisubmitBENE Error:', error);
                this.openModal('', 'error');
              });
          }
        }

      }
      else if (even.APICall == 4) {
        debugger;
        this.showLoader = true;
        this.offerAcceptance();
        // setTimeout(() => {
        //   this.offerAcceptance();
        // }, 2100);
      }
      else if (even.APICall == 5) {
        const quickquote = new pc.Quotes(this.uniqueId);
        quickquote.sbliappSIGN().then(response => {
          console.log('sbliappSIGN res', response);
          if (response.sbliError) {
            this.openModal(response.msgText, 'Sorry!');
            // location.href = "/Sbliknockout";
          }
          else {
            this.openModal('appSIGN API call successfull for arcId : ' + this.arcId, 'Success');
            this.incrementQuestion();
            this.showLoader = false;
          }
        })
          .catch(error => {
            console.log('sbliappSIGN Error:', error);
            this.openModal('', 'error');
          });
      }
      else if (even.APICall == 6) {
        const quickquote = new pc.Quotes(this.uniqueId);
        quickquote.sblisubmitPAYINFO().then(response => {
          console.log('sblisubmitPAYINFO res', response);
          if (response.sbliError) {
            this.backButtonDisplay = 1;
            this.openModal(response.msgText, 'Sorry!');
            // location.href = "/Sbliknockout";
          }
          else {
            this.openModal('sblisubmitPAYINFO API call successfull for arcId : ' + this.arcId, 'Success');
            this.incrementQuestion();
            this.showLoader = false;
          }
        })
          .catch(error => {
            console.log('sblisubmitPAYINFO Error:', error);
            this.openModal('', 'error');
          });
      }
      else if (even.APICall == 7) {
        debugger;

        // if (this.counter == 0) {
        let commonInput = localStorage.getItem('sbliCommonInput');
        commonInput = JSON.parse(commonInput);
        console.log('sbliinitApp call commonInput: ', commonInput);
        const quickquote = new pc.Quotes(this.uniqueId);
        quickquote.sbliinitApp(commonInput).then(async response => {
          debugger;
          console.log('sbliinitApp res', response);
          if (response.sbliError) {

            // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
            //   {
            //     scrollable: true,
            //     size: 'sm',
            //     centered: true,
            //   });

            // const data = {
            //   head: 'Please Note',
            //   title: 'Sorry!',
            //   message: 'Please update your details and try again!',
            //   image: 'assets/images/images/education2.png'
            // };

            // modalRef.componentInstance.fromParent = data;
            // modalRef.result.then((result) => {
            //   console.log('Modal Close Output: ', result);
            // }, (reason) => {
            //   console.log('Modal Close Reason: ', reason);
            // });

            this.pc.lifesummary({ 'ssn': 'failure' });
            document.getElementById('ssnerrorModal').style.display = 'block';
            this.showLoader = false;
          }
          else {
            this.pc.lifesummary({ 'ssn': 'success' });
            let json = {
              'id': this.clientId,
              'showdetailsblissndssnpage': false
            };
            await this.pc.saveUpdateClientData(json);
            this.pc
              .getAppData(this.clientId)
              .then((data) => {
                const dataFrom = JSON.parse(data.Payload);
                this.existingData = dataFrom.body.SBLI;
                if (this.existingData == undefined) {
                  this.existingData = {};
                }
                quickquote.sblisubmitAUTH().then(response => {
                  debugger;
                  console.log('sblisubmitAUTH res', response);
                  if (response.sbliError) {
                    this.openModal(response.msgText, 'Sorry!');

                    this.showLoader = false;
                  }
                  else {
                    // setTimeout(() => {
                    //   this.getdecisioncall();
                    // }, 2000);
                    this.incrementQuestion();
                    this.getdecisioncall();
                  }
                })
                  .catch(error => {
                    console.log('sbliinitApp Error:', error);
                    this.openModal('', 'error');
                  });
              })
              .catch((error) => {
                console.log(error);
              });

          }
        })
          .catch(error => {
            console.log('sbliinitApp Error:', error);
            this.openModal('', 'error');
          });
        // }
        // else {
        //   this.counter = this.counter + 1;
        //   this.updatePIcall();
        // }
      }
      else if (even.APICall == 8) {
        this.updatePIcall()
      }
      else {
        this.incrementQuestion();
      }
    }
    else {
      this.decrementQuestion();
    }
  }

  async updatePIcall() {
    debugger;
    this.counter = this.counter + 1;
    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);
    console.log('sbliinitApp call commonInput: ', commonInput);
    const quickquote = new pc.Quotes(this.uniqueId);
    if (this.counter == 1) {
      quickquote.sblisubmitAUTH().then(response => {
        debugger;
        console.log('sblisubmitAUTH res', response);
        if (response.sbliError) {
          this.openModal(response.msgText, 'Sorry!');

          this.showLoader = false;
        }
        else {
          this.sbliPII();
        }
      })
        .catch(error => {
          console.log('sbliinitApp Error:', error);
          this.openModal('', 'error');
        });
    }
    else {
      this.sbliPII()
    }
  }





  getdecisioncall() {
    debugger;
    if (this.submitbenecalled == true) {
      this.showLoader = false;
      this.decisionloader = true;
    }
    if (this.sbligetdecisonapisucess != true) {
      this.decisioncounter = this.decisioncounter + 1;
      const quickquote = new pc.Quotes(this.uniqueId);
      quickquote.sbligetDECISION().then(async response => {
        debugger;
        console.log('sbligetDECISION res', response);
        const hubSpotData = {
          email: localStorage.getItem('bubble-email'),
          api_appdecisoncode: response.sbliOutput['output.appDecisonCode']
        };
        this.appDecisonCode = response.sbliOutput['output.appDecisonCode']
        this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
        if (response.sbliError) {
          this.sbligetdecisonapisucess = false;
          if (response.sbliOutput['output.appDecisonCode'] == 99 && this.decisioncounter <= 8) {
            this.pc.lifesummary({ 'getdescision_api_code': '99' });
            setTimeout(() => {
              this.getdecisioncall();
            }, 15000);
          }
          else {
            if (this.submitbenecalled == true) {
              this.decisionloader = false;
              this.openModal(response.msgText, 'Sorry!');
            }
            this.decisionloader = false;
            this.showLoader = false;
            this.pc.lifesummary({ 'getdescision_api_code': response.sbliOutput['output.appDecisonCode'] });
          }
        }
        else {
          debugger;
          if (response.sbliOutput['output.appDecisonCode'] == 99 && this.decisioncounter <= 8) {
            this.sbligetdecisonapisucess = false;
            setTimeout(() => {
              this.getdecisioncall();
            }, 15000);
            this.pc.lifesummary({ 'getdescision_api_code': response.sbliOutput['output.appDecisonCode'] });
          }
          else {

            let allowedCoverage;
            // this.showLoader = true;
            // this.decisionloader = false;
            if (response.maxAllowedCoverage == null || response.maxAllowedCoverage == '') {
              allowedCoverage = '';
            }
            else {
              allowedCoverage = response.maxAllowedCoverage;
            }
            localStorage.setItem('sblimaxAllowedCoverage', JSON.stringify(allowedCoverage));
            this.disableBackButton = true;
            if (response.sbliOutput['output.appDecisonCode'] == 99) {
              this.sbligetdecisonapisucess = false;
              if (this.submitbenecalled == true) {
                this.decisionloader = false;
                this.openModal('Based on the information you have given we could not offer you a policy', 'Sorry!');
              }
            }
            else if (response.sbliOutput['output.appDecisonCode'] == 1) {
              //this.getPremiumcall(false);
              const json = {
                'id': this.clientId,
                'sblimaxAllowedCoverage': allowedCoverage
              };
              await this.pc.saveUpdateClientData(json);
              this.sbligetdecisonapisucess = true;
            }
            else {
              this.sbligetdecisonapisucess = false;
              if (this.submitbenecalled == true) {
                this.decisionloader = false;
                this.openModal('Based on the information you have given we could not offer you a policy', 'Sorry!');
              }
            }
            const etty = {
              'next': 1,
              'previous': 0,
              'saveData': 0,
              'sbli_answer_data': '',
              'APICall': 3,
              "calledfrombene": false,
              "Benecalled": (this.submitbenecalled == true) ? true : false
            };
            this.changeQuestion(etty)
            this.pc.lifesummary({ 'getdescision_api_code': response.sbliOutput['output.appDecisonCode'] });
          }
        }
      })
        .catch(error => {
          console.log('sbliinitApp Error:', error);
          this.openModal('', 'error');
        });
    }
    else {
      const etty = {
        'next': 1,
        'previous': 0,
        'saveData': 0,
        'sbli_answer_data': '',
        'APICall': 3,
        "calledfrombene": false,
        "Benecalled": (this.submitbenecalled == true) ? true : false
      };
      this.changeQuestion(etty)
    }

  }



  async getPremiumcall(type = true) {
    debugger
    this.decisionloader = false;
    this.showLoader = true;
    if (type == true) {
      this.showLoader = true;
      await this.getCommonInput();
    }
    const quickquote = new pc.Quotes(this.uniqueId);
    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);
    quickquote.sbligetPREMIUM(commonInput).then(response => {
      debugger;
      console.log('sbligetPREMIUM res', response);

      if (response.sbliError) {
        this.openModal(response.msgText, 'Sorry!');
      }

      else {
        debugger;
        this.lcarrierAmount = response.premiumMonthly;
        //localStorage.setItem('sblimaxAllowedCoverage', JSON.stringify(response.sbliOutput['output.maxAllowedCoverage']));
        commonInput['premium'] = response.premiumMonthly;
        commonInput['premiumQuaterly'] = response.premiumQuaterly;
        commonInput['premiumSemiAnnually'] = response.premiumSemiAnnually;
        commonInput['premiumAnnually'] = response.premiumAnnually;
        console.log('sbliCommonInput :', commonInput);
        localStorage.setItem('sbliCommonInput', JSON.stringify(commonInput));
        const json = {
          'id': this.clientId,
          'sbli_totalPremium': response.premiumAnnually
        };
        this.pc.saveUpdateClientData(json);
        if (type != true) {
          this.incrementQuestion();
        }
        else {
          this.showLoader = false;
          this.SbliSummaryComponent.quoteAmount()
        }
        // if (type) {
        //   setTimeout(() => {
        //     quickquote.sbliofferACCEPTANCE(commonInput).then(response => {
        //       console.log('sbliofferACCEPTANCE res', response);
        //       if (response.sbliError) {
        //         this.openModal(response.msgText, 'Sorry!');
        //         this.showLoader = false;
        //       }
        //       else {

        //         this.openModal('getPREMIUM and offerACCEPTANCE API call successfull for arcId : ' + this.arcId, 'Success');
        //         this.incrementQuestion();
        //         this.showLoader = false;
        //       }
        //     }).catch(error => {
        //       console.log('sbliofferACCEPTANCE Error:', error);
        //       this.openModal('', 'error');
        //     });
        //   }, 2000);
        // }
        // else {
        //   this.openModal('getPREMIUM API call successfull for arcId : ' + this.arcId, 'Success');
        //   this.incrementQuestion();
        //   this.showLoader = false;
        // }

      }
    }).catch(error => {
      console.log('sbligetPREMIUM Error:', error);
      this.openModal('', 'error');
    });
  }



  public offerAcceptance() {
    debugger
    this.showLoader = true;
    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);
    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.sbliofferACCEPTANCE(commonInput).then(async response => {
      console.log('sbliofferACCEPTANCE res', response);
      if (response.sbliError) {
        this.openModal(response.msgText, 'Sorry!');
        this.showLoader = false;
      }
      else {
        localStorage.setItem('sblimaxAllowedCoverage', JSON.stringify(response.maxAllowedCoverage));
        await this.pc.getAppData(this.clientId).then(async data => {
          const dataFrom = JSON.parse(data.Payload);
          this.existingData = dataFrom.body.SBLI;
          const pn = this.existingData['sbli_PolicyNumber'];
          if (pn == null || pn == undefined || pn == '') {
            const quickquote = new pc.Quotes(this.uniqueId);
            await quickquote.sbligetPOLICYNUMBER().then(async response => {
              debugger;
              console.log('sbligetPOLICYNUMBER res', response);
              if (response.sbliError) {
                this.openModal(response.msgText, 'Sorry!');
              }
              else {
                this.pc.lifesummary({ 'policy_number': this.policyNumber, 'policy_issuance': 'yes' });
                this.policyNumber = response.policyNumber;

                localStorage.setItem('sbli_PolicyNumber', this.policyNumber);
                const json = {
                  'id': this.clientId,
                  SBLI: this.existingData,
                  'sbli_PolicyNumber': this.policyNumber
                };
                json.SBLI['sbli_PolicyNumber'] = this.policyNumber;
                json['sbli_PolicyNumber'] = this.policyNumber;
                await this.pc.saveUpdateClientData(json).then(data => {
                  this.showLoader = false;
                }).catch(error => {
                  console.log(error);
                });

                const hubSpotData = {
                  email: localStorage.getItem('bubble-email'),
                  life_policy_number: this.policyNumber,
                  application_stage: 'Application complete'
                };
                this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

                /////// id mapping
                const customerIdLocal = localStorage.getItem('customer_id');
                const idMappingJson = {
                  client_id: this.clientId,
                  customer_id: customerIdLocal,
                  email_id: localStorage.getItem('bubble-email'),
                  sbli_policy_id: this.policyNumber
                };
                this.pc.createIdMapping(idMappingJson);
                console.log('Lead:createIdMapping:idMappingJson:', idMappingJson);
                this.incrementQuestion();
              }
            })
              .catch(error => {
                console.log('sbligetPOLICYNUMBER Error:', error);
                this.showLoader = false;
              });
            // })
            // .catch(error => {
            //   console.log("sbliupdateSESSION Error:", error);
            // });





          }
          else {
            this.policyNumber = pn;
            this.incrementQuestion();
          }

        }).catch(error => {
          console.log(error);
        });
        this.showLoader = false;
      }
    }).catch(error => {
      this.showLoader = false;
      console.log('sbliofferACCEPTANCE Error:', error);
      this.openModal('', 'error');
    });
  }

  async getCommonInput() {
    debugger;
    const saveResponse = await this.pc.getAppData(this.clientId);

    const dataFrom = JSON.parse(saveResponse.Payload);
    console.log('get app data: ', dataFrom);

    if (dataFrom.body['sbli_premiumMonthly']) {
      this.lcarrierAmount = '$' + dataFrom.body['sbli_premiumMonthly'];
    }

    this.existingData = dataFrom.body.SBLI;
    let maxcvg;
    if (dataFrom.body.sblimaxAllowedCoverage) {
      maxcvg = await this.getMaximumCoverageallowed(dataFrom.body.sbli_coverage);
    }

    if (this.existingData != null && this.existingData != undefined && this.existingData != '') {
      const commonInput = {
        'lastName': this.existingData.SBLI_comfirmDetailsLastName,
        'firstName': this.existingData.SBLI_comfirmDetailsFirstName,
        'middleName': '',
        'addressType': 'PhysicalRisk',
        'address': this.existingData.SBLI_confirmDetailsHomeAddress,
        'city': this.existingData.SBLI_confirmDetailsCity,
        'gender': this.existingData.SBLI_comfirmDetailsGender == '1' ? 'M' : 'F',
        'dob': this.existingData.SBLI_comfirmDetailsDateOfBirth,
        'term': dataFrom.body.sbli_term,
        'zip': this.existingData.SBLI_confirmDetailsZipCode,
        'state': this.existingData.SBLI_confirmDetailsState,
        'height': this.existingData.SBLI_comfirmDetailsHeight,
        'weight': this.existingData.SBLI_comfirmDetailsWeight,
        'tobaccoUse': dataFrom.body.tobacoUser,
        'riskClass': '',
        'coverage': dataFrom.body.sblimaxAllowedCoverage ? maxcvg : dataFrom.body.sbli_coverage,
        'premiumFrequency': 'm',
        'email': this.existingData.SBLI_comfirmDetailsEmailID,
        'phone': this.existingData.SBLI_comfirmDetailsPhoneNumber,
        'premium': dataFrom.body.sbli_premiumMonthly,
        'uscitizen': dataFrom.body['USCitizen']
      };
      localStorage.setItem('sbliCommonInput', JSON.stringify(commonInput));
      console.log('sbli commonInput: ', commonInput);
    }
    else {
      const commonInput = {};
      localStorage.setItem('sbliCommonInput', JSON.stringify(commonInput));
      console.log('sbli commonInput: ', commonInput);
    }
    return true;

    // this.pc.getAppData(this.clientId).then(data => {
    //   debugger;
    //   let dataFrom = JSON.parse(data.Payload);
    //   console.log("get app data: ", dataFrom);

    //   if (dataFrom.body["sbli_premiumMonthly"]) {
    //     this.lcarrierAmount = '$' + dataFrom.body["sbli_premiumMonthly"];
    //   }

    //   this.existingData = dataFrom.body.SBLI;
    //   if (this.existingData != null && this.existingData != undefined && this.existingData != '') {
    //     let commonInput = {
    //       "lastName": this.existingData.SBLI_comfirmDetailsLastName,
    //       "firstName": this.existingData.SBLI_comfirmDetailsFirstName,
    //       "middleName": "",
    //       "addressType": "PhysicalRisk",
    //       "address": this.existingData.SBLI_confirmDetailsHomeAddress,
    //       "city": this.existingData.SBLI_confirmDetailsCity,
    //       "gender": this.existingData.SBLI_comfirmDetailsGender == '1' ? "M" : "F",
    //       "dob": this.existingData.SBLI_comfirmDetailsDateOfBirth,
    //       "term": dataFrom.body.sbli_term,
    //       "zip": this.existingData.SBLI_confirmDetailsZipCode,
    //       "state": this.existingData.SBLI_confirmDetailsState,
    //       "height": this.existingData.SBLI_comfirmDetailsHeight,
    //       "weight": this.existingData.SBLI_comfirmDetailsWeight,
    //       "tobaccoUse": dataFrom.body.tobacoUser,
    //       "riskClass": "",
    //       "coverage": dataFrom.body.sbli_coverage,
    //       "premiumFrequency": "m",
    //       "email": this.existingData.SBLI_comfirmDetailsEmailID,
    //       "phone": this.existingData.SBLI_comfirmDetailsPhoneNumber
    //     };
    //     localStorage.setItem("sbliCommonInput", JSON.stringify(commonInput));
    //     console.log("sbli commonInput: ", commonInput);
    //   }
    //   else {
    //     let commonInput = {};
    //     localStorage.setItem("sbliCommonInput", JSON.stringify(commonInput));
    //     console.log("sbli commonInput: ", commonInput);
    //   }
    //   return true;
    // }).catch(error => {
    //   console.log(error);
    //   this.showLoader = false;
    // })

  }

  async incrementQuestion() {
    debugger;
    this.decisionloader = false;
    let curpage = this.question + 13;
    this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: ++curpage });
    this.question = this.question + 1;
    this.showLoader = false;

    if (this.question == 20 || this.question == 17) {
      this.Footer = false;
    }
    else {
      this.Footer = true;
    }
    this.updateHubspot(1);
    const sbli_answer_data = {
      'id': this.clientId,
      'sbli_questionNumber': this.question,
      'sbli_CommonInput': JSON.parse(localStorage.getItem('sbliCommonInput')),
      'sbli_appFlowBackButton': this.disableBackButton ? '1' : '0'
    };
    const save = await this.pc.saveUpdateClientData(sbli_answer_data);

    const updateJson = {
      questionSequence: this.question,
      resumeUrl: this.router.url
    };

    this.checkforResumeInit(updateJson, 'update');
  }

  async decrementQuestion() {
    debugger
    let curpage = this.question + 13;
    this.updateHubspot(2);
    this.tracker.changeProgressStatus({ totalPages: this.totalPages, currentPage: --curpage });
    if (this.question == 1) {
      debugger;
      if (this.insurancetype == 'life') {
        location.href = `SelectLifeCarrierV2/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      }
      else {
        location.href = `SelectedCarriers/${this.uniqueId}/${this.clientId}/${this.displayAddress}/${this.insurancetype}`;
      }

    }
    else {
      // if (this.disableBackButton != true) {

      // }
      // else {
      //   this.showLoader = false;
      // }
      this.showLoader = false;
      this.question = this.question - 1;
      if (this.question == 20 || this.question == 17) {
        this.Footer = false;
      }
      else {
        this.Footer = true;
      }
      const sbli_answer_data = {
        'id': this.clientId,
        'sbli_questionNumber': this.question
      };
      const save = await this.pc.saveUpdateClientData(sbli_answer_data);

      const updateJson = {
        questionSequence: this.question,
        resumeUrl: this.router.url
      };
      this.checkforResumeInit(updateJson, 'update');
    }
  }

  openModal(msg, title) {
    if (title == 'Success') {
      // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      //   {
      //     scrollable: true,
      //     size: 'sm',
      //     centered: true,
      //   });

      // let data = {
      //   head: 'Please Note',
      //   title: title,
      //   message: msg,
      //   image: 'assets/images/images/education2.png'
      // }

      // modalRef.componentInstance.fromParent = data;
      // modalRef.result.then((result) => {
      //   //redirect url
      //   //window.location.href = environment.carrierErrorRedirectURL;
      //   //window.location.href = this.pc.envCred.url;
      //   console.log("Modal Close Output: ", result);
      // }, (reason) => {
      //   console.log("Modal Close Reason: ", reason);
      // });
    }
    else if (title == 'error') {
      this.showLoader = true;
      document.getElementById('servererrorModal').style.display = 'block';
      // const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      //   {
      //     scrollable: true,
      //     size: 'sm',
      //     centered: true,
      //   });

      // const data = {
      //   head: 'Please Note',
      //   title: title,
      //   message: 'We are facing some problem in reaching our server, please come back later.',
      //   image: 'assets/images/images/education2.png'
      // };

      // modalRef.componentInstance.fromParent = data;
      // modalRef.result.then((result) => {
      //   console.log('Modal Close Output: ', result);
      // }, (reason) => {
      //   console.log('Modal Close Reason: ', reason);
      // });
    }
    else {
      this.showLoader = false;
      this.errorMessage = msg;
      this.knockOut = true;
    }
  }
  openBeneModal(msg, title) {
    debugger;
    this.showLoader = true;
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    const data = {
      head: 'Please Note',
      title: title,
      message: msg,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      this.showLoader = false;
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  tryAgain() {
    document.getElementById('servererrorModal').style.display = 'none';
    location.reload();
  }

  gotoHome() {
    document.getElementById('servererrorModal').style.display = 'none';
    window.location.href = this.pc.envCred.url;
  }

  closeKnockout() {
    this.knockOut = false;
    this.backButtonDisplay = 0;
  }


  public updateHubspot(type) {
    debugger;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: this.hubspotvalues[this.question - type]

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
  }


  // ngAfterViewInit(): void {
  //   debugger
  //   document.body.addEventListener('mouseleave', (e) => { this.exitPopup(e); });
  // }

  // exitPopup(e) {
  //   debugger
  //   if (sessionStorage.getItem('showNumber')) {
  //     this.showNumber = sessionStorage.getItem('showNumber');
  //   }
  //   else {
  //     this.showNumber = 0
  //   }
  //   if (this.showNumber == 0 && e.clientY <= -1 && !this.showLoader) {
  //     document.getElementById('exiterrorModalsbli').style.display = 'block';
  //   }
  // }

  // closePopup() {
  //   debugger;
  //   document.getElementById('exiterrorModalsbli').style.display = 'none';
  //   this.showNumber = 1;
  //   sessionStorage.setItem('showNumber', this.showNumber);
  // }
  public closecallpopup() {
    this.poupopened = false;
    this.showform = false;
    document.getElementById('schedulecallmodal').style.display = 'none';
  }
  public schedulecall() {
    this.showform = true;
    this.appointmentdate = '';
    this.dateValidation = '';
  }

  showschedulepopup() {
    debugger;

    const d = new Date();
    const day = d.getDay();
    const hours = new Date().getHours();
    const minutes = new Date().getMinutes();
    if (day < 6 && day > 0 && hours >= 8 && hours < 20) {
      if (hours == 19 && minutes >= 30) {
        this.Online = false;
      } else if (hours == 8 && minutes >= 30) {
        this.Online = true;
      }
      else {
        this.Online = true;
      }
    } else {
      this.Online = false;
    }
    this.poupopened = true;
    let commonInput = localStorage.getItem('sbliCommonInput');
    commonInput = JSON.parse(commonInput);
    document.getElementById('schedulecallmodal').style.display = 'block';
    document.getElementById('ssnerrorModal').style.display = 'none';
    document.getElementById('consentscreenModal').style.display = 'none';
    this.firstname = commonInput['firstName'];
    this.lastname = commonInput['lastName'];
    this.emailid = commonInput['email'];
    this.phone = commonInput['phone'];
    this.hours = ['08', '09', '10', '11', '12', '01', '02', '03', '04', '05', '06', '07'];
    this.minutes = ['00', '30'];
    this.merdiaum = ['AM', 'PM'];
    this.selectedmeridum = 'PM';
    // const format = "yyyy-MM-dd";
    // const myDate = new Date();
    // const locale = "en-US";
    //this.appointmentdate = formatDate(myDate, format, locale);
    this.callback_hour = 'hrs';
    this.callback_minutes = 'min';

  }
  callBack() {
    debugger;
    this.poupopened = true;
    this.callback_time = `${this.callback_hour}:${this.callback_minutes}:${this.selectedmeridum}`;
    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      callback_request__life_: 'Yes',
      appointment_date: this.appointmentdate,
      callback_time: this.callback_time,
      firstname: this.firstname,
      lastname: this.lastname,
      phone: this.phone
    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    document.getElementById('schedulecallmodal').style.display = 'none';
    document.getElementById('thankyouModal').style.display = 'block';
    const json = {
      'id': this.clientId,
      'bubble_fname': this.firstname,
      'bubble_lname': this.lastname,
      'bubble_phone': this.phone,
    };
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(data => {
    }).catch(error => {
      console.log(error);
    });
  }

  public closethankyou() {
    document.getElementById('thankyouModal').style.display = 'none';
  }

  public async hideloader() {
    this.showLoader = false;
    let json = {
      'id': this.clientId,
      'showdetailsblissndssnpage': true
    };
    await this.pc.saveUpdateClientData(json);
    this.showdetailssndssnpage = true;
    document.getElementById('ssnerrorModal').style.display = 'none';
  }

  closesecondssn() {
    this.showLoader = false;
    document.getElementById('ssnerrorModal').style.display = 'none';
    this.DetailsConfirmationAndSSNComponent.showcallbutton();
  }

  public getHours(even) {
    debugger;
    if (even.target.value == '10' || even.target.value == '11' || even.target.value == '08' || even.target.value == '09') {
      if (even.target.value == '08') {
        this.minutes = ['30'];
      }
      else {
        this.minutes = ['00', '30'];
      }
      this.selectedmeridum = 'AM';
    }
    else {
      if (even.target.value == '07') {
        this.minutes = ['00'];
      }
      else {
        this.minutes = ['00', '30'];
      }
      this.selectedmeridum = 'PM';
    }
  }

  public showconsentpopup() {
    debugger;
    if (!this.poupopened) {
      document.getElementById('consentscreenModal').style.display = 'block';
    }
  }

  _appointmentdate(appointmentdate) {
    debugger;
    const day = new Date(appointmentdate).getDay();
    if (day == 6 || day == 0) {
      this.dateValidation = 'Sat/Sun (Not Available)';
    } else {
      this.dateValidation = '';
    }

  }

  sbliPII() {
    if (this.counter <= 2) {
      debugger;
      let commonInput = localStorage.getItem('sbliCommonInput');
      commonInput = JSON.parse(commonInput);
      console.log('sbliinitApp call commonInput: ', commonInput);
      const quickquote = new pc.Quotes(this.uniqueId);
      quickquote.sbliupdatePII(commonInput).then(async response => {
        debugger;
        console.log('sbliupdatePII res', response);
        if (response.sbliError) {
          if (this.counter == 2) {
            const json = {
              'id': this.clientId,
              'maxsblissncallexceed': true
            };
            await this.pc.saveUpdateClientData(json);

            document.getElementById('ssnerrorModal').style.display = 'block';
            this.showLoader = false;
          }

          else {
            document.getElementById('ssnerrorModal').style.display = 'block';
            // this.openModal(response.msgText, 'Sorry!');
            this.DetailsConfirmationAndSSNComponent.stoploader();
            this.showLoader = false;
          }
        }
        else {
          this.incrementQuestion();
        }
      });
    }
    else {
      this.DetailsConfirmationAndSSNComponent.showcallbutton();
      this.showLoader = false;
    }
  }

  async getMaximumCoverageallowed(data) {
    debugger
    let sblimaxAllowedCoverage;
    sblimaxAllowedCoverage = JSON.parse(localStorage.getItem('sblimaxAllowedCoverage'));
    let maxCoverageAllowed = [];
    if (sblimaxAllowedCoverage != '') {
      const coverageTermDetails = sblimaxAllowedCoverage.split(',');
      for (let i = 0; i < coverageTermDetails.length; i++) {
        let rep = coverageTermDetails[i];
        rep = rep.replace('[', '');
        rep = rep.replace(']', '');
        rep = rep.replace('{', '');
        rep = rep.replace('}', '');
        rep = rep.replace(/ /g, '');
        rep = rep.replace(/'/g, '');
        coverageTermDetails[i] = rep;
      }
      for (let j = 0; j < coverageTermDetails.length; j++) {
        const t = coverageTermDetails[j].split(':');
        const c = coverageTermDetails[j + 1].split(':');
        const json = {
          term: t[1],
          coverage_limit: c[1]
        };
        maxCoverageAllowed.push(json);
        j = j + 1;
      }

    }
    let allowedcvg;
    if (Number(data) >= Number(maxCoverageAllowed[0].coverage_limit)) {
      allowedcvg = maxCoverageAllowed[0].coverage_limit
    }
    else {
      allowedcvg = data;
    }
    return allowedcvg;
  }

}




