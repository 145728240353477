import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormGroupDirective, FormBuilder } from '@angular/forms';
import { AnswerModel } from '../../models/answer.model';

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['./input-radio.component.css']
})
export class InputRadioComponent implements OnInit {

  @Output() onChange: EventEmitter<number | string> = new EventEmitter();
  @Input() public questionId: number | undefined;
  @Input() public optionValues: AnswerModel[] | undefined | null;

  childForm!: FormGroup;

  constructor(private rootFormGroup: FormGroupDirective) { }

  ngOnInit(): void {
    debugger
    this.optionValues;
    this.questionId;
    this.childForm = this.rootFormGroup.form as FormGroup;
    let cmn = `answer_value_${this.questionId}`;
    this.childForm.controls['selected_answer'].setValue(this.optionValues.filter(x => x.answer_option_id == Number(this.childForm.value[cmn]))[0]?.answer_text)
  }

  changeValue(event: any, value) {
    debugger
    let _answer_value = this.childForm.get('answer_value_' + this.questionId)?.value;
    this.childForm.get('answer_option_id_' + this.questionId)?.setValue(_answer_value);
    this.childForm.controls['selected_answer'].setValue(value.answer_text);
    this.onChange.emit(event.target.value);
  }

}
