import { Component, OnInit } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-sbli-app-flow-header',
  templateUrl: './sbli-app-flow-header.component.html',
  styleUrls: ['./sbli-app-flow-header.component.css']
})
export class SbliAppFlowHeaderComponent implements OnInit {

  constructor(private pc: ProcessCacheService) { }
  homeURL: string;
  ngOnInit(): void {
    debugger;
    const domain = window.location.hostname;
    if (domain === 'app.getmybubble.co') {
      this.homeURL = 'https://www.getmybubble.co';
    }
    else if (domain === 'uat-app.getmybubble.co') {
      this.homeURL = 'https://uat.getmybubble.co';
    }
    else if (domain === 'app.getmybubble.com') {
      this.homeURL = 'https://www.getmybubble.com';
    }
    else {
      this.homeURL = 'http://localhost:4200/lifescore?customizeV2=true';
    }

  }

  hideTooltip(  ) {
    ( document.getElementById( 'contactTooltip' ) as HTMLElement ).style.visibility = 'hidden';
  }

  showTooltip(  ) {
    ( document.getElementById( 'contactTooltip' ) as HTMLElement ).style.visibility = 'visible';
  }

}
