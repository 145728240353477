import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli00201010',
  templateUrl: './sbli00201010.component.html',
  styleUrls: ['./sbli00201010.component.css']
})
export class Sbli00201010Component implements OnInit {
  haspending: any;
  questions: any;
  clientId: any;
  existingData: any;
  @Output() changeQuestion = new EventEmitter();
  showLoader = true;
  questions2: any;
  annuity: any;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');
    // this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == "app-sbli00201010")[0];
    // console.log("app-sbli00201010 questions : ", this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli00201010')[0];
        this.questions2 = que.filter(x => x.component_id == 'app-sbli00301011')[0];
      } else {
        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli00201010')[0];
        this.questions2 = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli00301011')[0];
      }


      this.existingData = dataFrom.body.SBLI;
      if (this.existingData.sbli_hasPending != undefined) {
        this.haspending = this.existingData.sbli_hasPending;
      }
      if (this.existingData.sbli_annuity != undefined) {
        this.annuity = this.existingData.sbli_annuity;
      }

      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
  }

  capturehaspending(value) {
    this.haspending = value;
  }
  captureannuity(value) {
    this.annuity = value;
  }

  next() {
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    json.SBLI[this.questions.radio_options[0].answer_key] = this.haspending;
    json.SBLI[this.questions2.radio_options[0].answer_key] = this.annuity;
    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': json,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);
    this.$gaService.event('SBLI LifeApplication Pending', 'event', 'SBLI-LifeApplication-Pending');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Pending',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions,'SBLI LifeApplication Pending', 'event', 'SBLI-LifeApplication-Pending');

  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

}
