import { Component, OnInit } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
@Component({
  selector: 'app-wns-app-flow-header',
  templateUrl: './wns-app-flow-header.component.html',
  styleUrls: ['./wns-app-flow-header.component.css']
})
export class WnsAppFlowHeaderComponent implements OnInit {


  constructor(private pc: ProcessCacheService) { }
  homeURL:string;
  ngOnInit(): void {
    this.homeURL = this.pc.envCred.url;
  }


  hideTooltip(  ) {
    ( document.getElementById( 'contactTooltip' ) as HTMLElement ).style.visibility = 'hidden';
  }

  showTooltip(  ) {
    ( document.getElementById( 'contactTooltip' ) as HTMLElement ).style.visibility = 'visible';
  }


}
