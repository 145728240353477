import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-policy-owner-information',
  templateUrl: './policy-owner-information.component.html',
  styleUrls: ['./policy-owner-information.component.css']
})
export class PolicyOwnerInformationComponent implements OnInit {
  @Input() questionDetails: [];
  @Output() public policyOwnerInformationOutput =
    new EventEmitter<{ component_id: any, answer: any }>();
  heroForm: any;
  constructor(private pc: ProcessCacheService) { }
  FirstName: string;
  MiddleName: string;
  LastName: string;
  DateOfBirth: any;
  SSN: any;
  EmailID: any;
  City: any;
  State: any;
  Zipcode: any;
  Currentstreetaddress: any;
  Relationshiptoproposedinsured: any;
  ApartmentOrSuite: any;
  RelationshiptopropList: any;
  inputJson: any;
  ngOnInit(): void {
    console.log(this.questionDetails);
    this.RelationshiptopropList = this.questionDetails["child_question_12"]["answer_options"];

    this.heroForm = new FormGroup({
      EmailID: new FormControl(this.EmailID, Validators.required),
    });
  }


  public onSubmit(data) {
    debugger
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);
      this.inputJson = {
        'id': localStorage.getItem('clientID'),
        'FirstName': this.FirstName,
        'MiddleName': this.MiddleName,
        'LastName': this.LastName,
        'DateOfBirth': this.DateOfBirth,
        'SSN': this.SSN,
        'EmailID': this.EmailID,
        'Currentstreetaddress': this.Currentstreetaddress,
        'ApartmentOrSuite': this.ApartmentOrSuite,
        'City': this.City,
        'State': this.State,
        'Zipcode': this.Zipcode,
        'Relationshiptoproposedinsured': this.Relationshiptoproposedinsured
      }
      this.policyOwnerInformationOutput.emit({ component_id: this.questionDetails["component_id"], answer: this.inputJson });
      this.submitdata(this.inputJson);
    } else {
      alert("Declined!");
    }
  }

  public submitdata(inputJson) {
    this.pc.saveUpdateClientData(inputJson);
  }


  public goBack() {
    debugger;
    this.policyOwnerInformationOutput.emit({ component_id: this.questionDetails["component_id"] + ",Goback", answer: this.inputJson });
  }

  
  hometypeDD() {
    var v = document.getElementById("hometype");
    var w = document.getElementById("stories");
    w.style.display = "none";
    var x = document.getElementById("swimmingpool");
    x.style.display = "none";
    var y = document.getElementById("construction");
    y.style.display = "none";
    var z = document.getElementById("rooftype");
    z.style.display = "none";
    if (v.style.display === "none") {
      v.style.display = "block";
    } else {
      v.style.display = "none";
    }
  }

}
