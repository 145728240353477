import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { ProfileDataHandlerService } from 'src/app/shared/profile-data-handler.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
const pc = require('bit-uibl-data-driver');
import { ValidationService } from 'src/app/shared/validation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
@Component({
  selector: 'app-lead',
  templateUrl: './lead.component.html',
  styleUrls: ['./lead.component.css']
})
export class LeadComponent implements OnInit {


  constructor(private activatedroute: ActivatedRoute, private pc: ProcessCacheService, private location: Location, private $gaService: GoogleAnalyticsService, private utilityService: UtilityFunctionsService,public router: Router, private modalService: NgbModal, public validationService: ValidationService, private Http: HttpClient) { }
  email: any;
  insuranceType: any;
  loader: boolean;
  clientId: any;
  iType: string;
  recoType: string;
  hs_preview: string;
  firstname: string;
  address: string;
  city: string;
  state: string;
  country: string;
  zip: string;
  phone: string;
  date_of_birth: any;
  gender: string;
  marital_status: string;
  interest_type: string;
  home_type: string;
  dwelling_use: string;
  number_of_units: string;
  lead_source: string;
  lead_form_url: string;
  lead_source_time_stamp: string;
  newly_acquired_property: string;
  number_of_claims: string;
  property_year_built: string;
  property_living_area: string;
  home_insurance_type: string;
  credit_rating: string;
  bundle_auto_with_home: string;
  stories: string;
  roof_updated_year: any;
  lastname: string;
  construction_type: string;
  roof_type: string;
  swimming_pool: string;
  year_built: any;
  living_size: string;
  fullAddress: string;
  uniqueId: string;
  httpParamsGlobal: any;
  curDate: any;
  minDate;
  minArea: number;
  yearBuiltError: any;
  constructionList: any;
  storiesList: any;
  roofTypeList: any;
  propertdetails = [];
  rooftype: any;
  constructiontype: any;
  message: string;
  is_valid: boolean;
  roofupdatedyearError: string;
  livingSizeError: string;
  selectedConstructionType: any;
  selectedStories: any;
  selectedRoofType: any;
  phoneerror: any;
  prevEmail: any;
  errormessage: any;
  mode: any;

  ngOnInit(): void {
    this.activatedroute.params.subscribe(params => {
      this.clientId = params['client_id'];
      this.mode = params['mode'];
      this.insuranceType = params['insuranceType'];
      debugger;

      this.pc.getAppData(this.clientId).then(
        data => {
          debugger;
          const clientData = JSON.parse(data.Payload);
          debugger;
          console.log('this.pc.getAppData: ', clientData);
          this.email=clientData.body.commonInput['email'];
          this.phone=clientData.body.commonInput['phone'];
          this.date_of_birth=clientData.body.commonInput['dob'];
          this.address=clientData.body.commonInput['address'];
          this.city=clientData.body.commonInput['city'];
          this.state=clientData.body.commonInput['state'];
          this.zip=clientData.body['input.zip'];
          this.year_built=clientData['body'].yearBuilt;
          this.living_size=clientData.body.commonInput['propertyDetails.living_size'];
          this.selectedConstructionType=clientData.body.commonInput['propertyDetails.construction_type'];
          this.roof_type=clientData.body.commonInput['propertyDetails.roof_type'];
          this.selectedRoofType=this.roof_type;
          this.selectedStories=clientData.body.commonInput['propertyDetails.stories'];
          this.roof_updated_year=clientData.body['updatedAtYear'];
          debugger;
          //    this.paclifeQuote =  clientData.body.paclifeQuote.premiumAmount;
          //  document.getElementById('paclifeQuoteID').innerHTML=this.paclifeQuote+'/mo';
        }
      ).catch(error => {
        console.log(error);
      });

    }
    );

    this.storiesList = [
      { value: '1', text: '1' },
      { value: '1and1/2', text: '1and1/2' },
      { value: '2', text: '2' },
      { value: '2and1/2', text: '2and1/2' },
      { value: '3', text: '3' },
      { value: '4', text: '4+' },
    ];

    const quickquote = new pc.Quotes(this.clientId);
    const optionsList = quickquote.getSWPropertyOptions();
    this.constructionList = optionsList.construction_type;
    console.log('constructionList : ', this.constructionList);
    // this.storiesList = optionsList.stories;
    // console.log("storiesList : ", this.storiesList);
    this.roofTypeList = optionsList.roof_type;
    console.log('roofTypeList : ', this.roofTypeList);
    const propertyresponse = quickquote.getSWKnockoutQuestionsByState(this.state, this.year_built);
    console.log('Knockout Questions: ', propertyresponse);
    this.propertdetails = propertyresponse;
  }





  setGAEvent() {
    //action, category, label
    if (this.email == null) {
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'email'
      });
    }
    if (this.address == null) {
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'address'
      });
    }
    // TODO Need to check with Rajaneesh why was it added and removed
    // if (this.country == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "country"
    //   });
    // }
    if (this.state == null) {
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'state'
      });
    }
    if (this.city == null) {
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'city'
      });
    }
    if (this.zip == null) {
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'Error', 'event_label': 'zip'
      });
    }
    // if (this.home_type == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "home_type"
    //   });
    // }
    // if (this.dwelling_use == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "dwelling_use"
    //   });
    // }
    // if (this.interest_type == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "interest_type"
    //   });
    // }
    // if (this.home_insurance_type == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "home_insurance_type"
    //   });
    // }
    // if (this.lead_source == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "lead_source"
    //   });
    // }
    // if (this.lead_form_url == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "lead_form_url"
    //   });
    // }
    // if (this.lead_source_time_stamp == null) {
    //   this.$gaService.gtag('event', "HomeLandingPage", {
    //     'event_category': "Error", 'event_label': "lead_source_time_stamp"
    //   });
    // }
  }
  fillInHubspotDataFromFormData(hubSpotData) {
    if (this.email) hubSpotData['email'] = this.email;
    if (this.firstname) hubSpotData['firstname'] = this.firstname;
    if (this.lastname) hubSpotData['lastname'] = this.lastname;
    if (this.address) hubSpotData['address'] = this.address;
    if (this.city) hubSpotData['city'] = this.city;
    if (this.state) hubSpotData['state'] = this.state;
    if (this.country) hubSpotData['country'] = this.country;
    if (this.zip) hubSpotData['zip'] = this.zip;
    if (this.phone) hubSpotData['phone'] = this.phone;
    if (this.date_of_birth) hubSpotData['dob_life'] = this.date_of_birth;
    if (this.gender) hubSpotData['gender'] = this.gender;
    if (this.marital_status) hubSpotData['marital_status'] = this.marital_status;
    // Problem if we use this property Hubspot throws error
    // if (this.home_type) hubSpotData["home_type"] = this.home_type;
    if (this.dwelling_use) hubSpotData['dwelling_use'] = this.dwelling_use;
    if (this.number_of_units) hubSpotData['number_of_units'] = this.number_of_units;
    if (this.lead_source) hubSpotData['lead_source'] = this.lead_source;
    if (this.lead_form_url) hubSpotData['lead_form_url'] = this.lead_form_url;
    if (this.lead_source_time_stamp) hubSpotData['lead_source_time_stamp'] = this.lead_source_time_stamp;
    if (this.newly_acquired_property) hubSpotData['newly_acquired_property'] = this.newly_acquired_property;
    if (this.number_of_claims) hubSpotData['number_of_claims'] = this.number_of_claims;
    if (this.property_year_built) hubSpotData['property_year_built'] = this.property_year_built;
    if (this.property_living_area) hubSpotData['property_living_area'] = this.property_living_area;
    if (this.home_insurance_type) hubSpotData['home_insurance_type'] = this.home_insurance_type;
    if (this.credit_rating) hubSpotData['credit_rating'] = this.credit_rating;
    if (this.bundle_auto_with_home) hubSpotData['bundle_auto_with_home'] = this.bundle_auto_with_home;
    if (this.stories) hubSpotData['stories'] = this.stories;
    if (this.roof_updated_year) hubSpotData['roof_updated_year'] = this.roof_updated_year;
    return hubSpotData;
  }

  async getdata() {
    debugger;
    //this.clientId = await this.generateClientData();
    // this.uniqueId = "g2ov6i-1341-Dolanna-Dr-Compton-CA-90221";
    // this.fullAddress = "Compton-CA-90221-US";
    // // this.clientId = this.getClientId();
    this.fullAddress = this.address + '-' + this.city + '-' + this.state + '-' + this.zip;
    this.fullAddress = this.fullAddress.replace(/ /g, '-');
    // this.uniqueId = this.clientId + "-" + this.fullAddress;

    this.pc.getAppData(this.clientId).then(data => {
      debugger;
      const dataFrom = JSON.parse(data.Payload);
      const clientData = dataFrom.body;
      console.log('dataFrom get app data : ', clientData);
      debugger;
      this.uniqueId = clientData.unique_id;
      this.fullAddress = clientData.fullAddress;
      this.insuranceType = 'home';
      localStorage.setItem('insuranceType', this.insuranceType);
      this.iType = 'home-quote';
      this.recoType = 'SelectHomeCarrier';
      debugger;
      let CommonInputHome = {};
      const localStories = {
        '1': '1',
        '1and1/2': '1H',
        '2': '2',
        '2and1/2': '2H',
        '3': '3',
        '4+': '4',
        '4': '4'
      };
      const propDetails = {
        'construction_type': this.construction_type,//no value
        'home_type': this.home_type,//no value
        'roof_type': this.roof_type,//no value
        'swimming_pool': this.swimming_pool,//no value
        'year_built': this.year_built,//no value
        'living_size': this.living_size,//no value
        'stories': localStories[this.stories] ? localStories[this.stories] : '1',
        'roof_constructed': this.roof_updated_year
      };
      CommonInputHome = {
        address: this.address,
        addressType: 'PhysicalRisk',
        city: this.city,
        coverage: 10,
        dob: this.date_of_birth,
        firstName: this.firstname,
        lastName: this.lastname,
        middleName: '',
        premiumFrequency: 'm',
        state: this.state,
        zip: this.zip,
        replacementCost: 10,
        yearbuilt: this.year_built,
        sw_discounts: [],
        sw_appQuestions: [],
        ca_builder: '',
        propertyDetails: propDetails,
        swquote_question: clientData.swquote_question,
        email: this.email,
        phone: this.phone,
        disableBack: true
      };
      debugger;
      localStorage.setItem('commonInput', JSON.stringify(CommonInputHome));
      localStorage.setItem('bubble-dob', CommonInputHome['dob']);
      localStorage.setItem('applyDiscount', '0');
      console.log('common input : ', CommonInputHome);
      console.log('CommonInputHome : ', CommonInputHome);
      const resumeURL = `${this.recoType}/${this.uniqueId}/${this.clientId}/${this.fullAddress}/${this.insuranceType}`;
      console.log('resumeURL generated: ', resumeURL);
      this.resumeFlow(resumeURL);
    }).catch(error => {
      console.log(error);
    });
  }

  async resumeFlow(resumeURL) {
    this.loader = true;
    const createJson = {
      insuranceType: this.iType,
      carrierName: 'stillwater',
      appStartStatus: false,
      appCompletedStatus: false,
      questionStartStatus: false,
      questionCompletedStatus: false,
      questionSequence: '0',
      applicationId: '',
      otherAppflowScreen: 'home-question1',
      resumeUrl: resumeURL
    };
    const createResumeData = await this.pc.checkforResumeInit(createJson, 'create');
    console.log('createResumeData: ', createResumeData);
    this.router.navigate(['/resumeFlow', this.clientId]);
  }

  async generateClientData() {
    debugger;
    console.log('generateClientData httpParamsGlobal', this.httpParamsGlobal);
    const clientDataJson = {
      'client_id': this.clientId,
      'country': this.country,
      'bubble_email': this.email,
      'email': this.email,
      'bubble_fname': this.firstname,
      'bubble_lname': this.lastname,
      'address': this.address,
      'city_name': this.city,
      'stateCode': this.state,
      'state_name': this.state,
      'zip_code': this.zip,
      'bubble_phone': this.phone,
      'dob': this.date_of_birth,
      'gender': this.gender,
      'insurancetype': this.interest_type,
      'interest_type': this.interest_type,
      'yearBuilt': this.year_built,
      'livingArea': this.living_size,
      'roofBuiltYear': this.roof_updated_year,
      'fullAddress': this.fullAddress,
      'prefill_insurancetype': this.interest_type,
      'prefill_interest_type': this.interest_type,
      'prefill_yearBuilt': this.property_year_built,
      'prefill_livingArea': this.property_living_area,
      'prefill_roofBuiltYear': this.roof_updated_year,
      'prefill_marital_status': this.marital_status,
      'prefill_home_type': this.home_type,
      'prefill_dwelling_use': this.dwelling_use,
      'prefill_number_of_units': this.number_of_units,
      'prefill_lead_form_url': this.lead_form_url,
      'prefill_lead_source_time_stamp': this.lead_source_time_stamp,
      'prefill_newly_acquired_property': this.newly_acquired_property,
      'prefill_number_of_claims': this.number_of_claims,
      'prefill_home_insurance_type': this.home_insurance_type,
      'prefill_credit_rating': this.credit_rating,
      'prefill_bundle_auto_with_home': this.bundle_auto_with_home,
      'prefill_stories': this.stories
    };
    console.log('clientDataJson', clientDataJson);
    await this.Http.get<any>(`${this.pc.envCred.api2Domain}?data_category=m1144188134176150116232225133160147155103`, { params: clientDataJson }).subscribe((data: any) => {
      const response = data;
      console.log('post res success : ', response);
      this.clientId = response.clientId;
      localStorage.setItem('clientID', this.clientId);
      localStorage.setItem('clientId', this.clientId);
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'client_id', 'event_label': this.clientId
      });
      this.minArea = 250;
      this.yearBuiltError = '';
      const quickquote = new pc.Quotes(this.clientId);
      const optionsList = quickquote.getSWPropertyOptions();
      this.constructionList = optionsList.construction_type;
      console.log('constructionList : ', this.constructionList);
      // this.storiesList = optionsList.stories;
      // console.log("storiesList : ", this.storiesList);
      this.roofTypeList = optionsList.roof_type;
      console.log('roofTypeList : ', this.roofTypeList);
      const propertyresponse = quickquote.getSWKnockoutQuestionsByState(this.state, this.year_built);
      console.log('Knockout Questions: ', propertyresponse);
      this.propertdetails = propertyresponse;
      debugger;
      for (let i = 0; i < this.propertdetails.length; i++) {
        if (i == 0) {
          this.propertdetails[i].value = 'yes';
        }
        else {
          this.propertdetails[i].value = 'no';
        }

        // this.getdata();
      }
      if (this.stories == null || this.stories == undefined || this.stories == '') {
        this.selectedStories = this.storiesList[0].value;
        this.stories = this.storiesList[0].value;
      }
      else {
        const storieslength = this.storiesList.filter(x => x.value == this.stories).length;
        if (storieslength >= 1) {
          this.selectedStories = this.storiesList.filter(x => x.value == this.stories)[0].text;
        }
        else {
          this.selectedStories = this.storiesList[0].value;
          this.stories = this.storiesList[0].value;
        }

      }
      if (this.construction_type != null || this.construction_type != undefined || this.construction_type != '') {
        const constructionlength = this.constructionList.filter(x => x.value == this.construction_type).length;
        if (constructionlength >= 1) {
          this.selectedConstructionType = this.constructionList.filter(x => x.value == this.construction_type)[0].text;
        }
        else {
          this.selectedConstructionType = this.constructionList[0].value;
          this.construction_type = this.constructionList[0].value;
        }

      }
      else {
        this.selectedConstructionType = this.constructionList[0].value;
        this.construction_type = this.constructionList[0].value;
      }
      if (this.selectedConstructionType.length > 18) {
        this.selectedConstructionType = this.selectedConstructionType.substring(0, 18) + '...';
      }
      if (this.roof_type != undefined || this.roof_type != null || this.roof_type != '') {
        const rooflength = this.roofTypeList.filter(x => x.value == this.roof_type).length;
        if (rooflength >= 1) {
          this.selectedRoofType = this.roofTypeList.filter(x => x.value == this.roof_type)[0].text;
        }
        else {
          this.selectedRoofType = this.roofTypeList[0].value;
          this.roof_type = this.roofTypeList[0].value;
        }

      }
      else {
        this.selectedRoofType = this.roofTypeList[0].value;
        this.roof_type = this.roofTypeList[0].value;
      }
      if (this.selectedRoofType.length > 20) {
        this.selectedRoofType = this.selectedRoofType.substring(0, 20) + '...';
      }
      console.log('generateClientData().then:this.clientId:', this.clientId);
      const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
      let hubSpotData = {
        interest_type: this.interest_type,
        application_stage: 'Lead',
        client_id: this.clientId,
        customer_id: customerIdLocal
      };
      hubSpotData = this.fillInHubspotDataFromFormData(hubSpotData);
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'Page', 'event_label': 'PageLoadCompleted'
      });
      this.loader = false;
      this.location.go('prefillResumeFlowHoi');
    });

  }




  phonelength() {
    debugger;
    // var j = this.phone.replace(/-/g, '');
    // var K = j.replace(/[{()}]/g, '');
    // var K = K.replace(/ /g, '');
    // var A = K.substring(0, 3);
    // var B = K.substring(3, 6);
    // var C = K.substring(6, 10);
    // if(A!="" &&K.length<=3){
    //   if(A.length==3){
    //     this.phone='('+ A+') ';
    //   }
    //   else{
    //     this.phone='('+ A;
    //   }
    // }
    // else if(B!="" && K.length>3 && K.length<7) {
    //   this.phone='('+ A+')'+B;
    // }
    // else if(B!="" && K.length>=7){
    //   this.phone='('+ A+')'+B+'-'+C;
    // }
    // else{
    //   this.phone=K;
    // }
    let count = 0;
    this.phone = this.phone.replace(/^0+/, '');
    const phonestring = this.phone.toString().slice(0, this.phone.length);
    this.phone = phonestring.replace(/(\d{3})-?/g, function (m, a) {
      return ++count <= 2 ? a + '-' : m;
    });
    this.phone = this.phone.slice(0, 12);
    if (this.phone.length != 12) {
      this.phoneerror = 'Invalid Phone number';
    }
    else {
      this.phoneerror = '';
    }
  }

  public SelectCard(list) {
    if (list.value == 'no') {
      list.value = 'yes';
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'Checked', 'event_label': list.answer_label
      });
    }
    else {
      list.value = 'no';
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'Unchecked', 'event_label': list.answer_label
      });
    }
  }

  public GetStories(even) {
    this.selectedStories = even.text;
    this.stories = even.value;
    this.$gaService.gtag('event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'stories'
    });
  }

  public GetRooftype(even) {
    this.selectedRoofType = even.text;
    if (this.selectedRoofType.length > 20) {
      this.selectedRoofType = this.selectedRoofType.substring(0, 20) + '...';
    }
    this.roof_type = even.value;
    this.$gaService.gtag('event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'roof_type'
    });
  }

  public GetConstructiontype(even) {
    this.selectedConstructionType = even.text;
    this.construction_type = even.value;
    this.$gaService.gtag('event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'construction_type'
    });
  }


  onDateChange(even) {
    debugger;
    if (even == null) {
      // this.curDate = new Date();
      // const year = Number(this.curDate.getFullYear()) - 18;
      // let month = '' + (this.curDate.getMonth() + 1);
      // let day = '' + this.curDate.getDate();
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.date_of_birth = month + '/' + day + '/' + year;
      this.date_of_birth = this.pc.momentdob(this.curDate);
    } else {

      // const cur_d = new Date();
      // let d = new Date(even),
      //   month = '' + (d.getMonth() + 1),
      //   day = '' + d.getDate(),
      //   year = d.getFullYear();
      // const curYear = cur_d.getFullYear();
      // year = d.getFullYear();
      // const ageYear = Number(curYear) - Number(year);
      // if (month.length < 2)
      //   month = '0' + month;
      // if (day.length < 2)
      //   day = '0' + day;
      // this.date_of_birth = month + '/' + day + '/' + year;
      this.date_of_birth = this.pc.momentdob(even);
    }

    const newDate = new Date(even);
    console.log('DOB entered: ', newDate);
    const checkAge = this.validationService.validate_dob('common_age', newDate);
    this.message = checkAge.message;
    this.is_valid = checkAge.is_valid;
    this.$gaService.gtag('event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'DoB'
    });
  }


  checkyearbuilt() {
    debugger;
    this.$gaService.gtag('event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'YearBuilt'
    });
    const todayDate = new Date();
    const currentYear = todayDate.getFullYear();
    if (this.year_built.toString().length == 4) {
      if (Number(this.year_built) > currentYear || Number(this.year_built) < 1800) {
        this.yearBuiltError = 'Year Built value should be between 1800 and ' + currentYear;
        // this.yearbuilt = currentYear;
        this.$gaService.gtag('event', 'HomeLandingPage', {
          'event_category': 'Error', 'event_label': 'YearBuilt'
        });
      } else {
        this.yearBuiltError = '';
      }
    } else {
      this.yearBuiltError = 'Enter Valid Year';
    }

    if (this.roof_updated_year.toString().length == 4) {
      if (Number(this.year_built) > Number(this.roof_updated_year)) {
        this.roofupdatedyearError = 'The value should be greater than or equal to the Year Built';
      } else if (Number(this.roof_updated_year) > currentYear) {
        this.roofupdatedyearError = 'Roof constructed cannot be greater than ' + currentYear;

      } else {
        this.roofupdatedyearError = '';
      }
    } else {
      this.roofupdatedyearError = 'Enter Valid Year';
    }
  }

  checkRoofYear() {
    debugger;
    const todayDate = new Date();
    const currentYear = todayDate.getFullYear();
    if (this.roof_updated_year.toString().length == 4) {
      if (Number(this.roof_updated_year >= Number(this.year_built))) {
        if (Number(this.roof_updated_year) < this.year_built) {
          this.roof_updated_year = this.year_built;
        }
        else if (Number(this.roof_updated_year) > currentYear) {
          this.roofupdatedyearError = 'Roof constructed cannot be greater than ' + currentYear;

        }
        else {
          this.roofupdatedyearError = '';
          this.yearBuiltError = '';
        }
      }
      else {

        this.roofupdatedyearError = 'The value should be greater than or equal to the Year Built';
      }
    }
    else {
      this.roofupdatedyearError = 'Enter Valid Year';

    }

    if (this.year_built.toString().length == 4) {
      this.yearBuiltError = '';
    }
    else {
      this.yearBuiltError = 'Enter Valid Year';
    }
  }

  checkArea() {
    debugger;
    if (this.living_size.toString().length >= 2) {
      if (Number(this.living_size) < this.minArea) {
        this.livingSizeError = 'Total Living Area cannot be less than 250 Sq Ft';
      }
      else if (Number(this.living_size) > 10000) {
        this.livingSizeError = 'Total Living Area cannot be greater than 10000 Sq Ft';
      }
      else {
        this.livingSizeError = '';

      }
    }
    else {
      this.livingSizeError = 'Total Living Area cannot be less than 250 Sq Ft';
    }
    this.$gaService.gtag('event', 'HomeLandingPage', {
      'event_category': 'Edited', 'event_label': 'SqftArea'
    });
  }

  public async saveupdateclient() {
    this.loader = true;
    // Replicating old email id data to new email, if email id is changed
    if (this.prevEmail != this.email) {
      const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
      this.$gaService.gtag('event', 'HomeLandingPage', {
        'event_category': 'Edited', 'event_label': 'Email'
      });
      const hubSpotData = {
        interest_type: this.interest_type,
        client_id: this.clientId,
        customer_id: customerIdLocal,
        application_stage: 'Lead'
      };
      this.fillInHubspotDataFromFormData(hubSpotData);
      debugger;
      this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);
    }
    this.$gaService.gtag('event', 'HomeLandingPage', {
      'event_category': 'ButtonClicked', 'event_label': 'HomeGetQuote'
    });
    this.$gaService.event('Get quote', 'event', 'HomeGetQuote');
    const eventOptions: EventOption = {
      page_title: 'PreFill Lead Form',
      url: window.location.href,
      path: 'prefillResumeFlowHoi'
    };
    this.utilityService.eventWrapped(eventOptions, 'Get quote', 'event', 'HomeGetQuote');
    const qid = [];
    for (let i = 1; i < this.propertdetails.length; i++) {
      if (this.propertdetails[i].value == 'yes') {
        qid.push(this.propertdetails[i].sw_questionId);
      }
    }
    const clientDataJson = {
      'id': this.clientId,
      'country': this.country,
      'bubble_email': this.email,
      'email': this.email,
      'bubble_fname': this.firstname,
      'bubble_lname': this.lastname,
      'address': this.address,
      'city_name': this.city,
      'stateCode': this.state,
      'state_name': this.state,
      'zip_code': this.zip,
      'bubble_phone': this.phone,
      'dob': this.date_of_birth,
      'gender': this.gender,
      'insurancetype': this.interest_type,
      'interest_type': this.interest_type,
      'yearBuilt': this.year_built,
      'livingArea': this.living_size,
      'roofBuiltYear': this.roof_updated_year,
      'fullAddress': this.fullAddress,
      'prefill_insurancetype': this.interest_type,
      'prefill_interest_type': this.interest_type,
      'prefill_yearBuilt': this.year_built,
      'prefill_livingArea': this.living_size,
      'prefill_roofBuiltYear': this.roof_updated_year,
      'prefill_marital_status': this.marital_status,
      'prefill_home_type': this.home_type,
      'prefill_dwelling_use': this.dwelling_use,
      'prefill_number_of_units': this.number_of_units,
      'prefill_lead_form_url': this.lead_form_url,
      'prefill_lead_source_time_stamp': this.lead_source_time_stamp,
      'prefill_newly_acquired_property': this.newly_acquired_property,
      'prefill_number_of_claims': this.number_of_claims,
      'prefill_home_insurance_type': this.home_insurance_type,
      'prefill_credit_rating': this.credit_rating,
      'prefill_bundle_auto_with_home': this.bundle_auto_with_home,
      'prefill_stories': this.stories,
      'swquote_question': qid,
      'roof_type': this.rooftype
    };
    /////// id mapping
    //const aa = {firstname:"Adsf", lastname:"Ssdfds", clientId:"w2e3"};
    let firstName, lastName;
    const customerIdLocal = this.cookupCustomerId(this.firstname, this.lastname, this.clientId);
    localStorage.setItem('customer_id', customerIdLocal);
    const idMappingJson = {
      client_id: this.clientId,
      customer_id: customerIdLocal,
      email_id: this.email,
      firstname: this.firstname,
      lastname: this.lastname,
      status: this.interest_type,
      interest_type: this.interest_type,
      dob: this.date_of_birth
    };

    this.pc.createIdMapping(idMappingJson);
    const hubSpotData = {
      client_id: this.clientId,
      customer_id: customerIdLocal,
      email: this.email
    };
    debugger;
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

    console.log('Lead:createIdMapping:idMappingJson:', idMappingJson);
    /////// id mapping ends
    this.pc.saveUpdateClientData(clientDataJson).then(data => {
      debugger;
      this.getdata();
    }).catch(error => {
      console.log(error);
    });
  }
  cookupCustomerId(firstname, lastname, clientId) {
    let firstNameLocal, lastNameLocal;
    try { firstNameLocal = firstname[0].toLowerCase(); } catch (error) { firstNameLocal = 'a'; }
    try { lastNameLocal = lastname[0].toLowerCase(); } catch (error) { lastNameLocal = 'b'; }
    return `${firstNameLocal}${lastNameLocal}${clientId}`;
  }



  closeModal() {
    document.getElementById('errorModal').style.display = 'none';
    window.location.href = this.pc.envCred.url;
    this.$gaService.gtag('event', 'HomeLandingPage', {
      'event_category': 'Error', 'event_label': 'Popup Closed'
    });
  }

}
