import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ParentChildService } from 'src/app/shared/parent-child.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';

@Component({
  selector: 'app-third-party-notified',
  templateUrl: './third-party-notified.component.html',
  styleUrls: ['./third-party-notified.component.css']
})
export class ThirdPartyNotifiedComponent implements OnInit, AfterViewInit {
  FormVisible: boolean;
  @Input() questionDetails: [];
  @Output() public thirdPartyNotifiedOutput =
    new EventEmitter<{ component_id: any, answer: any }>();
  SSN: any;
  Relationship: any;
  EmailID: any;
  Address: any;
  RelationShipOptions: any;
  heroForm: any;
  thirdPartyFirstName: any;
  thirdPartyLastName: any;
  thirdPartyPhone: any;
  thirdPartyStreetAddress: any;
  thirdPartyCity: any;
  thirdPartyState: any;
  thirdPartyZipcode: any;
  StateLists: any;
  Lnameerror: boolean;
  StateValidation: boolean;
  thirdPartyNotifiedDetails: any;
  thirdPartyInfo: any;
  thirdPartyNotified: any;
  showloader:boolean;
  constructor(private pc: ProcessCacheService,
    private utilityService:UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,
    private service:ParentChildService) { }
  ngOnInit(): void {
    debugger
    this.showloader=true;
    this.service.callComponentMethod();
    document.getElementById('appFlowFooterID').style.display = 'block';
    this.SubmitOne = true;
    this.SubmitTwo = false;
    this.StateValidation = true;
    if (localStorage.getItem('bubble-thirdPartyNotifiedDetails') == null || localStorage.getItem('bubble-thirdPartyNotifiedDetails') == undefined) {
      this.StateValidation = true;
      this.InActiveTickIcon = true;
      this.InActiveCrossIcons = true;
      this.thirdPartyState = 'Choose';
      this.StateLists = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_6']['dropdown_options'];
      this.showloader=false;
    } else {


      this.pc.getAppData(localStorage.getItem('clientID')).then(
        data => {
          debugger;
          const clientDataFiltered = JSON.parse(data.Payload);
          this.thirdPartyNotified = clientDataFiltered.body.thirdPartyNotified;
          this.thirdPartyInfo = clientDataFiltered.body.thirdPartyInfo;

          this.StateLists = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_6']['dropdown_options'];
          // this.thirdPartyNotifiedDetails = localStorage.getItem("bubble-thirdPartyNotifiedDetails");
          // this.thirdPartyNotifiedDetails = JSON.parse(this.thirdPartyNotifiedDetails);

          if (this.thirdPartyNotified == 'Yes') {
            var data1 = 1;
            this.thirdPartyFirstName = this.thirdPartyInfo.thirdPartyFirstName;
            this.thirdPartyLastName = this.thirdPartyInfo.thirdPartyLastName;
            this.thirdPartyPhone = this.thirdPartyInfo.thirdPartyPhone;
            this.thirdPartyStreetAddress = this.thirdPartyInfo.thirdPartyStreetAddress;
            this.thirdPartyCity = this.thirdPartyInfo.thirdPartyCity;
            this.thirdPartyState = this.thirdPartyInfo.thirdPartyState;
            this.thirdPartyZipcode = this.thirdPartyInfo.thirdPartyZipcode;
            this.StateValidation = false;

          } else {
            var data1 = 0;
          }

          this.SelectedOption = data1;
          if (this.SelectedOption == 0) {
            this.selectedOptionValue = 'No';
            this.FormVisible = true;
            this.ActiveTickIcons = false;
            this.InActiveTickIcon = true;
            this.ActiveCrossIcons = true;
            this.InActiveCrossIcons = false;
            this.SubmitOne = true;
            this.SubmitTwo = false;

          } else {
            this.selectedOptionValue = 'Yes';
            this.FormVisible = false;
            this.ActiveTickIcons = true;
            this.InActiveTickIcon = false;
            this.ActiveCrossIcons = false;
            this.InActiveCrossIcons = true;
            this.SubmitOne = false;
            this.SubmitTwo = true;

          }



          this.showloader=false;
        }
      ).catch(error => {
        console.log(error);
      });










    }


    const hubSpotData = {
      email: localStorage.getItem('bubble-email'),
      life_application_page: 'NextPL_6_ThirdParty'

    };
    this.pc.hubSpotSyncAfterEmailFetch(hubSpotData);

  }

  ngAfterViewInit(): void {
    debugger;
    // this.thirdPartyState = document.getElementById('thirdPartyState').innerHTML;
    if (localStorage.getItem('bubble-thirdPartyNotifiedDetails') == null || localStorage.getItem('bubble-thirdPartyNotifiedDetails') == undefined) {

    } else {
      this.StateLists = this.questionDetails['sub_questions']['question_1']['group_of_inputs']['answer_6']['dropdown_options'];
      this.thirdPartyNotifiedDetails = localStorage.getItem('bubble-thirdPartyNotifiedDetails');
      this.thirdPartyNotifiedDetails = JSON.parse(this.thirdPartyNotifiedDetails);
      if (this.thirdPartyNotified == 'Yes') {
        this.thirdPartyState = this.thirdPartyInfo.thirdPartyState;
        // document.getElementById("thirdPartyState").innerHTML = this.thirdPartyState;
      }

    }


  }

  //this.StateValidation= document.getElementById('thirdPartyStateH4').innerHTML

  SelectedOption: any;
  SecondQuestion: any;
  ActiveTickIcons: boolean;
  InActiveTickIcon: boolean;
  ActiveCrossIcons: boolean;
  InActiveCrossIcons: boolean;
  selectedOptionValue: any;
  nameerror: boolean;
  phoneerror: boolean;
  SubmitOne: boolean;
  SubmitTwo: boolean;
  public GetOptions(data) {
    debugger;
    this.SelectedOption = data;
    if (this.SelectedOption == 0) {
      this.selectedOptionValue = 'No';
      this.FormVisible = true;
      this.ActiveTickIcons = false;
      this.InActiveTickIcon = true;
      this.ActiveCrossIcons = true;
      this.InActiveCrossIcons = false;
      this.SubmitOne = true;
      this.SubmitTwo = false;

    } else {

      this.selectedOptionValue = 'Yes';
      this.FormVisible = false;
      this.ActiveTickIcons = true;
      this.InActiveTickIcon = false;
      this.ActiveCrossIcons = false;
      this.InActiveCrossIcons = true;
      this.SubmitOne = false;
      this.SubmitTwo = true;

    }
  }

  inputJson;
  public onSubmit(data) {
    if (data.on_submit == 'proceed') {
      localStorage.setItem('questionId', data.question_sequence);
      if (this.selectedOptionValue == 'Yes') {
        this.inputJson = {};
        const thirdPartyInfo = {
        };

        thirdPartyInfo[data.sub_questions.question_1.group_of_inputs.answer_1.answer_key] = this.thirdPartyFirstName;
        thirdPartyInfo[data.sub_questions.question_1.group_of_inputs.answer_2.answer_key] = this.thirdPartyLastName;
        thirdPartyInfo[data.sub_questions.question_1.group_of_inputs.answer_3.answer_key] = this.thirdPartyPhone;
        thirdPartyInfo[data.sub_questions.question_1.group_of_inputs.answer_4.answer_key] = this.thirdPartyStreetAddress;
        thirdPartyInfo[data.sub_questions.question_1.group_of_inputs.answer_5.answer_key] = this.thirdPartyCity;
        thirdPartyInfo[data.sub_questions.question_1.group_of_inputs.answer_6.answer_key] = this.thirdPartyState;
        thirdPartyInfo[data.sub_questions.question_1.group_of_inputs.answer_7.answer_key] = this.thirdPartyZipcode;

        this.inputJson['id'] = localStorage.getItem('clientID');
        this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;
        this.inputJson[data.sub_questions.question_1.group_key] = thirdPartyInfo;
        // this.inputJson={
        //   'id': localStorage.getItem('clientID'),
        //   'thirdPartyNotified':this.selectedOptionValue,
        //   'thirdPartyFirstName':this.thirdPartyFirstName,
        //   'thirdPartyLastName':this.thirdPartyLastName,
        //   'thirdPartyPhone':this.thirdPartyPhone,
        //   'thirdPartyStreetAddress':this.thirdPartyStreetAddress,
        //   'thirdPartyCity':this.thirdPartyCity,
        //   'thirdPartyState':this.thirdPartyState,
        //   'thirdPartyZipcode':this.thirdPartyZipcode

        // }
        this.thirdPartyNotifiedOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
        this.submitdata(this.inputJson);

      } else {
        debugger;
        //  this.inputJson={
        //    'id': localStorage.getItem('clientID'),
        //    'thirdPartyNotified':this.selectedOptionValue
        //  }
        this.inputJson = {};
        this.inputJson['id'] = localStorage.getItem('clientID');
        this.inputJson[data.radio_options[0].answer_key] = this.selectedOptionValue;

        this.thirdPartyNotifiedOutput.emit({ component_id: this.questionDetails['component_id'], answer: this.inputJson });
        this.submitdata(this.inputJson);

      }
    } else {
      alert('Declined!');
    }

    this.$gaService.event('Next', 'event', 'LifeAppThirdPartyNotify');
    const eventOptions: EventOption = {
      page_title: ' Applicant Third Party Notify',
      path: 'AppFlowStart',
      url: window.location.href
    };
    this.utilityService.eventWrapped(eventOptions, 'Next', 'event', 'LifeAppThirdPartyNotify');

  }

  public submitdata(inputJson) {
    localStorage.setItem('bubble-thirdPartyNotified', this.selectedOptionValue);
    const thirdPartyNotified = JSON.stringify(this.inputJson);

    localStorage.setItem('bubble-thirdPartyNotifiedDetails', thirdPartyNotified);

    this.pc.saveUpdateClientData(inputJson);
  }


  checkfirstNamelength() {
    debugger;
    if (this.thirdPartyFirstName.length <= 1) {
      this.nameerror = true;
    }
    else if (this.thirdPartyFirstName == '') {
      this.nameerror = true;
    }
    else {
      this.nameerror = false;
    }
  }


  checklastNamelength() {
    debugger;
    if (this.thirdPartyLastName.length <= 1) {
      this.Lnameerror = true;
    }
    else if (this.thirdPartyLastName == '') {
      this.Lnameerror = true;
    }
    else {
      this.Lnameerror = false;
    }
  }

  thirdPartyCityerror: boolean;
  checkCitylength() {
    debugger;
    if (this.thirdPartyCity.length <= 1) {
      this.thirdPartyCityerror = true;
    }
    else if (this.thirdPartyCity == '') {
      this.thirdPartyCityerror = true;
    }
    else {
      this.thirdPartyCityerror = false;
    }
  }



  phonelength() {
    // debugger
    if (this.thirdPartyPhone.length === 3) {
      this.thirdPartyPhone = this.thirdPartyPhone + '-';
      this.phoneerror = true;
    }
    else if (this.thirdPartyPhone.length === 7) {
      this.thirdPartyPhone = this.thirdPartyPhone + '-';
      this.phoneerror = true;
    }
    else if (this.thirdPartyPhone.length < 12) {
      this.phoneerror = true;
    }
    else {
      this.phoneerror = false;
    }
  }



  public goBack() {
    // this.isDependentOutput.emit(this.questionDetails["component_id"]+",Goback");
    this.thirdPartyNotifiedOutput.emit({ component_id: this.questionDetails['component_id'] + ',Goback', answer: this.inputJson });
  }


  states(Soptions) {
    // document.getElementById("thirdPartyStateInput").innerHTML = Soptions;
    // document.getElementById("thirdPartyState").innerHTML = Soptions;
    this.thirdPartyState = Soptions;
    this.StateValidation = false;
  }
}
