import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { getQuestionAnswerJson,getQuestionAnswerByPassingInput} from "../../../../appUtility/appUtility";

@Component({
  selector: 'app-asthma',
  templateUrl: './asthma.component.html',
  styleUrls: ['./asthma.component.css']
})
export class AsthmaComponent implements OnInit {
  @Output() nextquestion7 = new EventEmitter();
  questionAnswersJSON=[];
  ShowYesOrNo:boolean;
  ShowYesOrNo1:boolean;
  constructor() { }

  ngOnInit(): void {
    this.ShowYesOrNo=true;
    this.ShowYesOrNo1=false;
    this.questionAnswersJSON= getQuestionAnswerJson;
  }

  SelectedOption:any;
  SecondQuestion:any;
  public GetOptions(data){
    this.SelectedOption=data;
    if(this.SelectedOption==0){
      document.getElementById('AsthmaNo').style.border='solid 2px orange';
      document.getElementById('Asthmayes').style.border='solid 1px #dae7ec';
      this.SecondQuestion=this.questionAnswersJSON["questions"]["q2"][3]["No"][0]["q"];
      this.ShowYesOrNo1=false;
    
    }else{
     document.getElementById('Asthmayes').style.border='solid 2px orange';
     document.getElementById('AsthmaNo').style.border='solid 1px #dae7ec';
     this.ShowYesOrNo1=true;
  
    }
  }




  Selectedlevel1Option:any;
  public GetLevelOptions(data){
    this.Selectedlevel1Option=data;
    if(this.Selectedlevel1Option==0){
      document.getElementById('Level1AsthmaNo').style.border='solid 2px orange';
      document.getElementById('Level1Asthmayes').style.border='solid 1px #dae7ec';
    }else{
      document.getElementById('Level1Asthmayes').style.border='solid 2px orange';
      document.getElementById('Level1AsthmaNo').style.border='solid 1px #dae7ec';
    }
  }


  next() {
    let etty = {
      "questionNo": "7",
      "nextprev": 1
    }
    this.nextquestion7.emit(etty);
  }
}
