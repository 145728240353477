import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { TrackerService } from 'src/app/shared/tracker.service';

@Component({
  selector: 'app-sbli-statustracker',
  templateUrl: './sbli-statustracker.component.html',
  styleUrls: ['./sbli-statustracker.component.css']
})
export class SbliStatustrackerComponent implements OnInit {
  sub: Subscription;
  percent: number;
  trackerType: string;
  insuranceType: string;
  trackingTypeSub: Subscription;
  lifejoueneystart;
  insurancetypeText: any;
  applicationComplete: any;
  constructor(private tracker: TrackerService) { }

  ngOnInit(): void {
    debugger
    this.lifejoueneystart = localStorage.getItem('bubble-lifejourneystart');
    this.insurancetypeText = localStorage.getItem('insurancetypeText');
    this.applicationComplete = localStorage.getItem('applicationComplete');

    this.sub = this.tracker.getProgressStatus().subscribe(percent => {
      debugger
      this.percent = percent;
    });
    const insuranceType = localStorage.getItem("insuranceType");

    switch (insuranceType) {
      case 'homeLife':
        console.log("insuranceType", insuranceType);
        this.insuranceType = 'Home + Life Insurance';
        break;
      case 'home':
        console.log("insuranceType", insuranceType);
        this.insuranceType = 'Home Insurance';
        break;
      case 'life':
        console.log("insuranceType", insuranceType);
        this.insuranceType = 'Life Insurance';
        // this.percent = Number(localStorage.getItem('Percentage'));
        break;
      default:
        break;
    }

    document.title = this.insuranceType + ' powered By Bubble';

    this.trackingTypeSub = this.tracker.getTrackingType().subscribe(type => {
      this.trackerType = type;
      console.log(this.trackerType == 'life');
      console.log('trackertype : ', this.trackerType);
    })
  }

}
