import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-wns',
  templateUrl: './wns.component.html',
  styleUrls: ['./wns.component.css']
})
export class WnsComponent implements OnInit {

  constructor() { }

  customOptions: OwlOptions = {
    loop: true,
    dots: false,
    nav: true,
    mouseDrag: true,
    touchDrag: false,
    pullDrag: false,
    lazyLoad: false,
    margin: 30,
    smartSpeed: 500,
    autoplay: false,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    navText: ['<img src="/assets/images/leftarrow.svg" alt="arrow" />', '<img src="/assets/images/rightarrow.svg" alt="arrow" />'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 1
      },
      740: {
        items: 1
      },
      940: {
        items: 1
      }
    }
  };

  slides = [
    { id: 1, img: '/assets/images/slide1.svg' },
    { id: 2, img: '/assets/images/slide2.svg' }
  ];

  ngOnInit(): void {
  }

}
