import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
import { getQuestionAnswerJson } from '../../../appUtility/appUtility.js';
@Component({
  selector: 'app-sbli00601020',
  templateUrl: './sbli00601020.component.html',
  styleUrls: ['./sbli00601020.component.css', '../sbli00501030/sbli00501030.component.css']
})
export class Sbli00601020Component implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  isdisable: boolean;
  userOccupation: any;
  userLookingFor: any;
  employee: any;
  questions: any;
  clientId: any;
  existingData: any;
  currentlyWorking = [];
  showLoader = true;
  questions2: any;
  income: any;
  annualIncome: any;
  test: any;
  ShowDollar_income: boolean;
  disableButton: boolean;
  householdincome: any;
  annualhouseholdIncome: any;
  ShowDollar__household_income: boolean;
  actively_seeking_work: any;
  last_actively_work: any;
  constructor(private pc: ProcessCacheService,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');

    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        const que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == 'app-sbli00601020')[0];
        this.questions2 = que.filter(x => x.component_id == 'app-sbli00501030')[0];
      }
      else {
        this.questions = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli00601020')[0];
        this.questions2 = getQuestionAnswerJson.questions.filter(x => x.component_id == 'app-sbli00501030')[0];
      }


      this.existingData = dataFrom.body.SBLI;

      if (this.existingData.sbli_employee != undefined) {
        debugger;
        this.employee = this.existingData.sbli_employee;
        this.isdisable = false;

        if (this.existingData.sbli_employee_retired != undefined && this.existingData.sbli_employee_nonworkinspouce != undefined && this.existingData.sbli_employee_fulltimestudent != undefined && this.existingData.sbli_employee_notworking != undefined) {
          if (this.existingData.sbli_employee_retired == 'Yes') {
            this.userOccupation = 'Retired';

          } else if (this.existingData.sbli_employee_nonworkinspouce == 'Yes') {
            this.userOccupation = 'Non-working spouse';
            this.householdincome = this.existingData.sbli_householdIncome;

          } else if (this.existingData.sbli_employee_fulltimestudent == 'Yes') {
            this.userOccupation = 'Full-time Student';
            if (this.existingData.sbli_Student_Type_bachelordegree == 'Yes') {
              this.userLookingFor = 'Bachelor’s Degree';

            } else if (this.existingData.sbli_Student_Type_masterdegree == 'Yes') {
              this.userLookingFor = 'Master’s Degree';

            } else if (this.existingData.sbli_Student_Type_doctorate == 'Yes') {
              this.userLookingFor = 'Doctorate';
            } else if (this.existingData.sbli_Student_Type_other == 'Yes') {
              this.userLookingFor = 'Other Qualification';
            }
            this.getUserLookingFor(this.userLookingFor);

          } else if (this.existingData.sbli_employee_notworking == 'Yes') {
            this.userOccupation = 'Not actively working';
            this.actively_seeking_work = this.existingData.sbli_actively_seeking_work;
            this.last_actively_work = this.existingData.sbli_last_actively_working;
          }
        }
        console.log('useroccupatio', this.userOccupation)
      }

      if (this.existingData.sbli_annualIncome != undefined) {
        this.income = this.existingData.sbli_annualIncome;
        this.annualIncome = this.income;
        this.income = this.income.replace(/,/g, '');
        const ctd = this.income;
        this.income = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        this.test = this.annualIncome.toString().length;

        if (this.test > 0) {
          this.ShowDollar_income = true;
        }
        else {
          this.ShowDollar_income = false;
        }

        if (Number(this.annualIncome) == 0) {
          this.disableButton = true;
        }
        else {
          this.disableButton = false;
        }
      }
      else {
        this.disableButton = true;
      }

      this.showLoader = false;

    }).catch(error => {
      console.log(error);
    });
    this.employee = '';
    this.userOccupation = '';
    this.userLookingFor = '';
    this.actively_seeking_work = '';
    this.last_actively_work = '';
    this.isdisable = true;
  }


  Employee(event) {
    debugger
    if (event == this.questions.radio_options[0].answer_text) {
      this.employee = event;
      this.currentlyWorking[this.questions.radio_options[0].answer_key] = event;
    }
    else if (event == this.questions.radio_options[1].answer_text) {
      this.employee = event;
      this.currentlyWorking[this.questions.radio_options[0].answer_key] = event;

      this.userOccupation = '';
      this.userLookingFor = '';
    }


    if (this.employee == this.questions.radio_options[1].answer_text && this.userOccupation != '') {
      this.isdisable = false;
    }
    else if (this.employee == this.questions.radio_options[0].answer_text) {
      this.isdisable = false;
    }
    else {
      this.isdisable = true;
    }

  }


  getUserOccupation(event) {
    debugger;

    this.userOccupation = event;
    localStorage.setItem('userOccupation', this.userOccupation);
    if (event != this.questions.radio_options_1[2].answer_text) {
      this.userLookingFor = '';
      this.householdincome = '';
      this.actively_seeking_work = '';
      this.last_actively_work = '';
    }
    if (event != this.questions.radio_options_1[3].answer_value) {
      this.householdincome = '';
    }
    if (event != this.questions.input.answer_key) {
      this.actively_seeking_work = '';
    }
    if (event != this.questions.input1.answer_value) {
      this.last_actively_work = '';
    }
    if (event != this.questions.input1.answer_value) {
      this.last_actively_work = '';
    }
    if ((this.employee != '' && this.userOccupation != '' && this.userOccupation != this.questions.radio_options_1[2].answer_text && this.last_actively_work != '' && this.actively_seeking_work != '' && this.userOccupation != '') || (this.userOccupation == this.questions.radio_options_1[0].answer_value)) {
      this.isdisable = false;
    }
    else {
      this.isdisable = true;
    }
    this.currentlyWorking[this.questions.radio_options_1[0].answer_key] = event;
  }

  getUserLookingFor(event) {
    debugger;

    this.userLookingFor = event;
    localStorage.setItem('userLookingFor', this.userLookingFor);


    if (this.employee != '' && this.userOccupation != '' && this.userLookingFor != '') {
      this.isdisable = false;
    }
    else {
      this.isdisable = true;
    }
    this.currentlyWorking[this.questions.radio_options_2[0].answer_key] = event;
    console.log('useroccupatio', this.userOccupation)
  }

  formatIncome(even) {
    debugger;
    if (even == '1') {
      this.income = this.income.replace(/,/g, '');
      this.annualIncome = this.income;
      const ctd = this.income;
      this.income = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.test = this.annualIncome.toString().length;
      if (this.test > 0) {
        this.ShowDollar_income = true;
      }
      else {
        this.ShowDollar_income = false;
      }

      if (Number(this.annualIncome) == 0) {
        this.disableButton = true;
      }
      else {
        this.disableButton = false;
      }

      if (this.employee == '' && this.userOccupation == '' && this.userLookingFor == '') {
        this.isdisable = true;
      }
    }
    else {
      this.householdincome = this.householdincome.replace(/,/g, '');
      this.annualhouseholdIncome = this.householdincome;
      const ctd = this.householdincome;
      this.householdincome = ctd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      this.test = this.annualhouseholdIncome.toString().length;
      if (this.test > 0) {
        this.ShowDollar__household_income = true;
      }
      else {
        this.ShowDollar__household_income = false;
      }

      if (Number(this.annualhouseholdIncome) == 0) {
        this.disableButton = true;
      }
      else {
        this.disableButton = false;
      }

      if (this.employee == '' && this.userOccupation == '') {
        this.isdisable = true;
      }
      else {
        this.isdisable = false;
      }
    }




  }
  public activelyseekingwork(event) {
    debugger
    this.actively_seeking_work = event;
    if (this.actively_seeking_work == 'Yes') {
      this.isdisable = true;
    }
    else {
      this.isdisable = false;
      this.disableButton = false;
      this.last_actively_work = '';
    }
  }
  public lastactivelywork() {
    debugger
    if (this.last_actively_work.length > 0) {
      this.isdisable = false;
      this.disableButton = false;
    }

  }


  next() {
    debugger
    this.$gaService.event('SBLI LifeApplication Income', 'event', 'SBLI-LifeApplication-Income');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Income',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions, 'SBLI LifeApplication Income', 'event', 'SBLI-LifeApplication-Income');

    this.getSelecteddata();
    // json.SBLI[this.questions.radio_options_1[0].answer_key] = this.userOccupation;
    // json.SBLI[this.questions.radio_options_2[0].answer_key] = this.userLookingFor;

  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

  jsonData: any;
  public getSelecteddata() {
    debugger;
    const json = {
      'id': this.clientId,
      SBLI: this.existingData
    };
    json.SBLI[this.questions2.input.answer_key] = this.income;

    if (this.employee == 'No') {
      json.SBLI[this.questions.radio_options[0].answer_key] = this.employee;
      if (this.userOccupation.trim() == 'Retired') {
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';

      } else if (this.userOccupation.trim() == 'Non-working spouse' || this.userOccupation.trim() == 'A non-working spouse') {
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';
        json.SBLI[this.questions.input.answer_key] = this.householdincome;

      } else if (this.userOccupation.trim() == 'Full-time Student' || this.userOccupation.trim() == 'A full-time student') {
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'No';

        if (this.userLookingFor.trim() == 'Bachelor’s Degree' || this.userLookingFor.trim() == 'Bachelor’s or Associates Degree') {
          json.SBLI[this.questions.radio_options_2[0].answer_key] = 'Yes';
          json.SBLI[this.questions.radio_options_2[1].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[2].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[3].answer_key] = 'No';

        } else if (this.userLookingFor.trim() == 'Master’s Degree' || this.userLookingFor.trim() == 'Master\'s degree') {
          json.SBLI[this.questions.radio_options_2[1].answer_key] = 'Yes';
          json.SBLI[this.questions.radio_options_2[0].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[2].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[3].answer_key] = 'No';

        } else if (this.userLookingFor.trim() == 'Doctorate' || this.userLookingFor.trim() == 'Doctorate') {
          json.SBLI[this.questions.radio_options_2[2].answer_key] = 'Yes';
          json.SBLI[this.questions.radio_options_2[0].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[1].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[3].answer_key] = 'No';

        } else if (this.userLookingFor.trim() == 'Other Qualification' || this.userLookingFor.trim() == 'Other qualification') {
          json.SBLI[this.questions.radio_options_2[3].answer_key] = 'Yes';
          json.SBLI[this.questions.radio_options_2[0].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[1].answer_key] = 'No';
          json.SBLI[this.questions.radio_options_2[2].answer_key] = 'No';
        }

      } else if (this.userOccupation.trim() == 'Not actively working' || this.userOccupation.trim() == 'Not actively working') {
        json.SBLI[this.questions.radio_options_1[3].answer_key] = 'Yes';
        json.SBLI[this.questions.radio_options_1[0].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[1].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_1[2].answer_key] = 'No';
        json.SBLI[this.questions.radio_options_3[0].answer_key] = this.actively_seeking_work;
        json.SBLI[this.questions.input1.answer_key] = this.last_actively_work;

      }

      for (const propName in json.SBLI) {
        if (json.SBLI[propName] === null || json.SBLI[propName] === undefined || json.SBLI[propName] === '') {
          delete json.SBLI[propName];
        }
      }

      this.jsonData = json;
    } else {
      json.SBLI[this.questions.radio_options[0].answer_key] = this.employee;
      this.jsonData = json;
    }

    const etty = {
      'next': 1,
      'previous': 0,
      'sbli_answer_data': this.jsonData,
      'saveData': 1
    };
    this.changeQuestion.emit(etty);

  }

}
