import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';

@Component({
  selector: 'app-sbli-welcome',
  templateUrl: './sbli-welcome.component.html',
  styleUrls: ['./sbli-welcome.component.css']
})
export class SbliWelcomeComponent implements OnInit {

  constructor(private pc: ProcessCacheService) { }
  clientId: any;
  existingData: any;
  @Output() changeQuestion = new EventEmitter();
  ngOnInit(): void {
    this.clientId = localStorage.getItem('clientID');
    this.pc.getAppData(this.clientId).then(data => {
      debugger
      let dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.SBLI;

    }).catch(error => {
      console.log(error);
    });
  }


  next() {
    debugger;
    var json = {
      'id': this.clientId,
      SBLI: this.existingData
    }
    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "saveData": 0
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);

  }
}
