import { Component, OnDestroy, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { ScorecardApiService } from 'src/app/shared/scorecard-api.service';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { ActivatedRoute } from '@angular/router';
import { getSWQuestionAnswerJson } from '../../../appUtility/appUtility.js';
 import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-sw4pt14',
  templateUrl: './sw4pt14.component.html',
  styleUrls: ['./sw4pt14.component.css']
})
export class Sw4pt14Component implements OnInit {
 @Output() nextquestion8 = new EventEmitter();

  uniqueId: any;
  clientId: any;
  displayAddress: any;
  insurancetype: any;
  questions: any;
  showoptions: any;
  trampolines: any;
  trampolinesPlacement: any;
  existingData: any;
  loader: boolean;

  constructor(private $gaService: GoogleAnalyticsService,private scoreApi: ScorecardApiService,
    private pc: ProcessCacheService,
    private activatedroute: ActivatedRoute) { }

  ngOnInit(): void {
    debugger;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
      this.displayAddress = params['address'];
      this.insurancetype = params['insurancetype'];
    }
    );
    // this.uniqueId = 'nkowu4-285-Bryant-St-Palo-Alto-CA-94301';
    // this.clientId = 'nkowu4';
    this.loader = true;
    this.questions = getSWQuestionAnswerJson.questions;
    this.questions = this.questions.filter(x => x.component_id == 'app-sw4pt14')[0];
    console.log('sw questions app-sw4pt14 : ', this.questions);
    this.pc.getAppData(this.clientId).then(data => {
      const dataFrom = JSON.parse(data.Payload);
      this.existingData = dataFrom.body.stillwater;
      console.log('data from getAppData sw : ', this.existingData);

      if (this.existingData['sw_trampolines'] == 'yes') {
        this.trampolines = this.existingData['sw_trampolines'];
        this.questions.radio_options[0].active = true;
        this.questions.radio_options[1].active = false;
        this.showoptions = '1';
      }
      else if (this.existingData['sw_trampolines'] == 'no'){
        this.trampolines = this.existingData['sw_trampolines'];
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = true;
        this.showoptions = '0';
      }
      else {
        this.trampolines = '';
        this.questions.radio_options[0].active = false;
        this.questions.radio_options[1].active = false;
        this.showoptions = '0';
      }

      if (this.existingData['sw_trampolinesPlacement'] == null || this.existingData['sw_trampolinesPlacement'] == undefined || this.existingData['sw_trampolinesPlacement'] == '') {
        this.trampolinesPlacement = '';
      }
      else {
        debugger;
        this.trampolinesPlacement = this.existingData['sw_trampolinesPlacement'];
        for (let j = 0; j < this.trampolinesPlacement.length; j++){
          debugger;
          this.questions.sub_questions.find(item => item.answer_key == this.trampolinesPlacement[j]).active = true;
        }
      }
      this.loader = false;
    }).catch(error => {
      console.log(error);
    });
    // if (localStorage.getItem('sw_trampolines') == 'yes') {
    //   this.trampolines = localStorage.getItem('sw_trampolines');
    //   this.questions.radio_options[0].active = true;
    //   this.questions.radio_options[1].active = false;
    //   this.showoptions = '1';
    // }
    // else if (localStorage.getItem('sw_trampolines') == 'no'){
    //   this.trampolines = localStorage.getItem('sw_trampolines');
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = true;
    //   this.showoptions = '0';
    // }
    // else {
    //   this.trampolines = '';
    //   this.questions.radio_options[0].active = false;
    //   this.questions.radio_options[1].active = false;
    //   this.showoptions = '0';
    // }

    // if (localStorage.getItem('sw_trampolinesPlacement') == null || localStorage.getItem('sw_trampolinesPlacement') == undefined || localStorage.getItem('sw_trampolinesPlacement') == '') {
    //   this.trampolinesPlacement = '';
    // }
    // else {
    //   this.trampolinesPlacement = JSON.parse(localStorage.getItem('sw_trampolinesPlacement'));
    //   for (let j = 0; j < this.trampolinesPlacement.length; j++){
    //     this.questions.radio_options.find(item => item.answer_key == this.trampolinesPlacement[j]).active = true;
    //   }
    // }

  }



  onYes() {
    this.showoptions = '1';
    this.questions.radio_options[0].active = true;
    this.questions.radio_options[1].active = false;
    this.trampolines = 'yes';
  }

  onNo() {
    this.showoptions = '0';
    this.questions.radio_options[0].active = false;
    this.questions.radio_options[1].active = true;
    this.trampolines = 'no';
  }

  selectRadio(list) {
    //debugger
    const selectedCard = list;
    // localStorage.setItem('sw_trampolinesPlacement', selectedCard.answer_key);
    this.trampolinesPlacement = selectedCard.answer_key;


    if (this.trampolinesPlacement=='sw_trampolinesPlacementNone') {
      this.questions.sub_questions[0].active = true;
      for (let i = 1; i < this.questions.sub_questions.length; i++){
        this.questions.sub_questions[i].active = false;
      }
    }
    else {
      this.questions.sub_questions[0].active = false;
      for (let i = 1; i < this.questions.sub_questions.length; i++){
        if (this.questions.sub_questions[i].answer_key == selectedCard.answer_key) {
          this.questions.sub_questions[i].active = true;
        }
        // else {
        //   this.questions.radio_options[i].active = false;
        // }
      }
    }



    // for (let i = 0; i < this.questions.sub_questions.length; i++){
    //   if (this.questions.sub_questions[i].answer_key == selectedCard.answer_key) {
    //     this.questions.sub_questions[i].active = true;
    //   }
    //   else {
    //     this.questions.sub_questions[i].active = false;
    //   }
    // }
  }

  next() {
    this.loader = true;
    const tramp = [];
    for (let i = 0; i < this.questions.sub_questions.length; i++){
      if (this.questions.sub_questions[i].active == true) {
        tramp.push(this.questions.sub_questions[i].answer_key);
      }
    }
    localStorage.setItem('sw_trampolinesPlacement', JSON.stringify(tramp));
    localStorage.setItem('sw_trampolines', this.trampolines);

    let knockout = 0;
    if (this.trampolines == 'yes') {
      knockout = 1;
    }
    const json = {
      'id': this.clientId,
      stillwater: this.existingData
    };
    json.stillwater['sw_trampolines'] = this.trampolines;
    json.stillwater['sw_trampolinesPlacement'] = tramp;
    console.log('json : ', json);
    this.pc.saveUpdateClientData(json).then(data => {
      this.loader = false;
      const etty = {
        'next': 1,
        'previous': 0,
        'knockout': knockout
      };
      this.nextquestion8.emit(etty);
    }).catch(error => {
      console.log(error);
    });
    this.$gaService.event('Next', 'event', 'HomeAppTrampolinesInfo');
  }

  previous() {
    this.loader = true;
    if(localStorage.getItem('RemoveKOData')=='1'){
      const json = {
        'id': this.clientId,
        stillwater: this.existingData
      };
      json.stillwater['sw_trampolines'] = '';
      json.stillwater['sw_trampolinesPlacement'] = '';
      this.pc.saveUpdateClientData(json).then(data => {
        this.loader = false;
        const etty = {
          'next': 0,
          'previous': 1
        };
        this.nextquestion8.emit(etty);
      }).catch(error => {
        console.log(error);
      });
    }
    else {
      this.loader = false;
      const etty = {
        'next': 0,
        'previous': 1,
      };
      this.nextquestion8.emit(etty);
    }
  }

}
