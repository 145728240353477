import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { getWNSQuestionAnswerJson } from "../../../appUtility/appUtility.js";
@Component({
  selector: 'app-wns1282',
  templateUrl: './wns1282.component.html',
  styleUrls: ['./wns1282.component.css']
})
export class Wns1282Component implements OnInit {

  @Output() changeQuestion = new EventEmitter();
  isDisable: boolean;
  questions: any;
  isCovid: any;
  isCovidRecent: string;
  isTreatment: any;
  clientId: any;
  existingData: any;
  showLoader: boolean = true;
  constructor(private pc: ProcessCacheService) { }

  ngOnInit(): void {
    debugger;
    this.clientId = localStorage.getItem('clientID');
    this.isDisable = true;
    this.pc.getAppData(this.clientId).then(data => {
      let dataFrom = JSON.parse(data.Payload);
      if (dataFrom.body.SBLI_QuestionAnswerJson != null && dataFrom.body.SBLI_QuestionAnswerJson != undefined) {
        let que = dataFrom.body.SBLI_QuestionAnswerJson;
        this.questions = que.filter(x => x.component_id == "app-wns1282")[0];
        console.log("app-wns1282 questions : ", this.questions);
      }
      else {
        this.questions = getWNSQuestionAnswerJson.questions.filter(x => x.component_id == "app-wns1282")[0];
        console.log("app-wns1282 questions : ", this.questions);
      }
      this.existingData = dataFrom.body.SBLI;

      if (this.existingData.sbli_HIVtest != undefined) {
        this.isCovid = this.existingData.sbli_HIVtest;
        this.isCovidDiagnosed(this.isCovid);
      }


      this.showLoader = false;
    }).catch(error => {
      console.log(error);
    });
    console.log("app-wns02201930 questions", this.questions);
  }


  isCovidDiagnosed(input) {
    this.isCovid = input;
    this.isCovidRecent = '';
    if (this.isCovid) {
      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
  }


  covidLast30Days(input) {
    debugger
    this.isCovidRecent = input;
    this.isTreatment = '';
    if (this.isCovid == this.questions.radio_options[0].answer_text && this.isCovidRecent != this.questions.radio_options_1[0].answer_text) {
      this.isDisable = false;
    }
    else {
      this.isDisable = true;
    }
  }

  covidTreatment(input) {
    this.isTreatment = input;
    if (this.isTreatment != '') {
      this.isDisable = false;
    }
  }

  next() {
    debugger;
    var json = {
      'id': this.clientId,
      SBLI: this.existingData
    }

    json.SBLI[this.questions.radio_options[0].answer_key] = this.isCovid;
    // json.SBLI[this.questions.radio_options_1[0].answer_key] = this.isCovidRecent;
    // json.SBLI[this.questions.radio_options_2[0].answer_key] = this.isTreatment;


    for (var propName in json.SBLI) {
      if (json.SBLI[propName] === null || json.SBLI[propName] === undefined || json.SBLI[propName] === "") {
        delete json.SBLI[propName];
      }
    }

    let etty = {
      "next": 1,
      "previous": 0,
      "sbli_answer_data": json,
      "saveData": 1
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    let etty = {
      "next": 0,
      "previous": 1,
    };
    this.changeQuestion.emit(etty);
  }

}
