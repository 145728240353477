import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProcessCacheService } from 'src/app/shared/process-cache.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LifescoreErrorAlertComponent } from '../../../components/lifescore-error-alert/lifescore-error-alert.component';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { UtilityFunctionsService } from 'src/app/shared/utility-functions.service';
const pc = require('bit-uibl-data-driver');

@Component({
  selector: 'app-sbli-review',
  templateUrl: './sbli-review.component.html',
  styleUrls: ['./sbli-review.component.css']
})
export class SbliReviewComponent implements OnInit {
  @Output() changeQuestion = new EventEmitter();
  showLoader = true;
  existingData: any;
  clientId: any;
  uniqueId: any;
  docURLs: any;
  pdfURL: any;
  sbligetFORMSres: any;
  constructor(private http: HttpClient,
    private pc: ProcessCacheService,
    private modalService: NgbModal,
    private utilityService: UtilityFunctionsService,
    private $gaService: GoogleAnalyticsService,
    private activatedroute: ActivatedRoute, private router: Router, public sanitizer: DomSanitizer) { }

  CheckBox: boolean;

  ngOnInit(): void {
    this.CheckBox = false;
    this.activatedroute.params.subscribe(params => {
      this.uniqueId = params['uniqueId'];
      this.clientId = params['clientId'];
    }
    );
    this.showLoader = true;


    // this.http.post("https://api3.getmybubble.io/merge-pdf-v2", { clientId: "fpw3uw" }).subscribe(
    //   (data: any) => {

    //     console.log("sbli Url Passed", data);

    //   },
    //   (error: any) => {
    //     console.log("sbli Url Failed", error);
    //   }
    // );




    const quickquote = new pc.Quotes(this.uniqueId);
    quickquote.sbligetFORMS(2).then(response => {
      debugger;
      console.log('sbligetFORMS res', response);

      // if (response.sbliError && response.msgCode!="ARSY-9002") {
      //   this.openModal(response.msgText, "Sorry!");
      // }
      if (response.sbliError) {
        document.getElementById('servererrorModal').style.display = 'block';
        //this.openModal(response.msgText, "Sorry!");
      }
      else {
        this.sbligetFORMSres = response;
        // "https://stage.getmybubble.io/sbliPdfs/76g7x3_244703.pdf"
        // let domainURL = "https://stage.getmybubble.io/sbliPdfs/";
        // let fileName = response.sbliOutput.output;
        // this.pdfURL = domainURL + fileName;
        // this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfURL);
        console.log('pdf : ', this.pdfURL);
        // this.showLoader = false;
        let uid;

        // if (response.formsJson) {
        //   uid = response.formsJson;
        // }
        // else {
        //   uid = response.sbliOutput.id;
        // }
        // uid = uid.split("_")[0];
        uid = response.sbliOutput.id;
        const envCred = this.pc.envCred;
        setTimeout(() => {
          this.http.post(this.sbligetFORMSres.sbliOutput['output.pdfBaseUrl'] + '/merge-pdf-v2', { clientId: uid }).subscribe(
            (data: any) => {
              console.log('merge pdf call', data);
              console.log('sbli Url Passed', data);

              // "https://stage.getmybubble.io/sbliPdfs/76g7x3_244703.pdf"
              // let domainURL = "https://stage.getmybubble.io/sbliPdfs/";
              // let fileName = data.data;
              // this.pdfURL = domainURL + fileName;
              let domainURL = this.sbligetFORMSres.sbliOutput['output.pdfBaseUrl'];
              //let domainURL = "https://api3.getmybubble.io";
              // domainURL = domainURL + "/sbliPdfs/";
              domainURL = domainURL + '/preview?filename=';
              const fileName = data.data;
              this.pdfURL = domainURL + fileName;
              this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.pdfURL);
              console.log('pdf : ', this.pdfURL);
              this.showLoader = false;

            },
            (error: any) => {
              console.log('sbli Url Failed', error);
              document.getElementById('servererrorModal').style.display = 'block';
            }
          );
        }, 5000);
      }

    })
      .catch(error => {
        console.log('sbligetFORMS Error:', error);
        document.getElementById('servererrorModal').style.display = 'block';
      });

  }

  tryAgain() {
    document.getElementById('servererrorModal').style.display = 'none';
    location.reload();
  }

  gotoHome() {
    document.getElementById('servererrorModal').style.display = 'none';
    window.location.href = this.pc.envCred.url;
  }

  public DownloadPDF(id) {
    console.log('DownloadPDF', id);
    // window.location.href = 'data:application/octet-stream;base64,' + this.base64JsonPdf[id];

    // const source = `data:application/pdf;base64,${this.base64JsonPdf[id]}`;
    // let filename = this.pdfMapping.filter(item => item.id == id)[0]['text'];
    // const link = document.createElement("a");
    // link.href = source;
    // link.download = `${filename}.pdf`
    // link.click();

    /* if(id==0){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["905"];
    }
    else if(id==0){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["904"];
    }
    else if(id==2){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["906"];
    }
    else if (id==3){
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["911"];
    }
    else{
      var pdf = 'data:application/octet-stream;base64,' + this.docURLs[id]["912"];
    }
    window.location.href = pdf; */
  }

  openModal(msg, title) {
    const modalRef = this.modalService.open(LifescoreErrorAlertComponent,
      {
        scrollable: true,
        size: 'sm',
        centered: true,
      });

    const data = {
      head: 'Please Note',
      title: title,
      message: msg,
      image: 'assets/images/images/education2.png'
    };

    modalRef.componentInstance.fromParent = data;
    modalRef.result.then((result) => {
      //redirect url
      //window.location.href = environment.carrierErrorRedirectURL;
      //window.location.href = this.pc.envCred.url;
      console.log('Modal Close Output: ', result);
    }, (reason) => {
      console.log('Modal Close Reason: ', reason);
    });
  }

  next() {
    this.pc.lifesummary({'application_review': 'yes' });
    this.$gaService.event( 'SBLI LifeApplication Agreement', 'event','SBLI-LifeApplication-Agreement');
    const eventOptions: EventOption = {
      page_title: 'SBLI LifeApplication Agreement',
      url: window.location.href,
      path: 'lifeCarrierTwo'
    };
    this.utilityService.eventWrapped(eventOptions,'SBLI LifeApplication Agreement', 'event','SBLI-LifeApplication-Agreement');
    const etty = {
      'next': 1,
      'previous': 0,
      'APICall': 5
    };
    this.changeQuestion.emit(etty);
  }

  previous() {
    const etty = {
      'next': 0,
      'previous': 1,
    };
    this.changeQuestion.emit(etty);
  }

}
